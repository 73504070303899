import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';

@Component({
  selector: 'cprt-custom-action-cell',
  templateUrl: './custom-action-cell.component.html',
  styleUrls: ['./custom-action-cell.component.sass']
})
export class CustomActionCellComponent implements ICellRendererAngularComp {

  params: any
  agInit(params: any) {
    this.params = params
  }
  refresh(): boolean {
    return false;
  }
  onActionClick(type) {
    this.params.context.componentParent.onActionClick(type, this.params.data)
  }
}
