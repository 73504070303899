import { PipeTransform, Pipe } from '@angular/core';
@Pipe({ name: 'converToEncode', pure: false })
export class ConverToEncode implements PipeTransform {
  /* transform(value: any, args: any[] = null): any {
        return Object.keys(value)//.map(key => value[key]);
    } */

  transform(input, args=null): any {
      let result = '--'
    if (typeof input === 'string') {
        result = decodeURIComponent(input);
    }
    return result;
  }
}
