import { Component, OnInit, Input } from '@angular/core';
import { NavBarService } from 'app/modules/common/nav-bar.service';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { BaseService } from 'app/modules/common/base.service';

@Component({
  selector: 'nav-tab-bar',
  moduleId: module.id,
  templateUrl: 'nav-tab-bar.component.html',
  styleUrls: ['nav-tab-bar.component.sass'],
  providers: [NavBarService],
})
export class NavTabBarComponent implements OnInit {
  public tabsList: any;
  @Input() public target: any;
  userInfo: any;
  constructor(
    private baseService: BaseService,
    private navBarService: NavBarService,
    private scopeDataService: ScopeDataService
  ) {}

  public ngOnInit() {
    this.userInfo = this.baseService.getUserInformation();
    this.updateTabs();
    this.scopeDataService.getFocus().subscribe((val) => {
      this.updateTabs();
    });
  }
  public updateTabs() {
    if (this.target instanceof Object) {
      this.tabsList = this.target;
    } else {
      this.tabsList = JSON.parse(
        JSON.stringify(this.navBarService.getNavBarList(this.target))
      );
    }
    if (this.userInfo.superAdmin) {
      if (this.target === 'mi') {
        const filterTabs = this.tabsList.filter(
          (tab) => tab.view === 'ebenchmark-mpn'
        );
        if (!filterTabs.length) {
          this.tabsList.push({
            id: 575,
            order: 0,
            title: 'eBenchmark',
            view: 'ebenchmark-mpn',
            level: 0,
            privilegeMap: null,
          });
        }
      }
    }
  }
  public onTabClick() {
    this.scopeDataService.settype('manual');
  }
}
