import { Injectable } from '@angular/core';
import { ApiServiceProvider } from 'app/api-service';
import { BaseService } from 'app/modules/common/base.service';

@Injectable()
export class TaskManagementService {
  constructor(
    private apiService: ApiServiceProvider,
    private baseService: BaseService
  ) {}

  getTaskList(input) {
    let payload;
    payload = {
      targetedType: 'Collaboration',
      method: 'POST',
      payload: input,
      formParams: {
        userId: this.baseService.getUserInformation().userId,
      },
      servicePath: '/task/getTasks',
    };
    return this.apiService.post(payload);
  }
  getStatusList() {
    let payload;
    payload = {
      targetedType: 'Collaboration',
      method: 'GET',
      servicePath: '/task/getStatus',
    };
    return this.apiService.post(payload);
  }
  getUsersList() {
    let payload;
    payload = {
      targetedType: 'Collaboration',
      method: 'GET',
      servicePath: '/task/getUsers',
    };
    return this.apiService.post(payload);
  }
  getFilters() {
    let payload;
    payload = {
      targetedType: 'Collaboration',
      method: 'GET',
      servicePath: '/task/getTaskFilters',
    };
    return this.apiService.post(payload);
  }
  getViewTypes() {
    let payload;
    payload = {
      targetedType: 'Collaboration',
      method: 'GET',
      servicePath: '/task/getViewTypes',
    };
    return this.apiService.post(payload);
  }
  saveTask(input) {
    const inp = {
      targetedType: 'Collaboration',
      payload: input,
      method: 'POST',
      servicePath: '/task/createTask',
    };
    return this.apiService.post(inp);
  }
  sendEmailNotification(input, created) {
    const inp = {
      targetedType: 'Collaboration',
      payload: input,
      method: 'POST',
      formParams: {
        isForCreate: created,
      },
      servicePath: '/task/workFlowEmailNotification',
    };
    return this.apiService.post(inp);
  }
  updateTask(input) {
    const inp = {
      targetedType: 'Collaboration',
      payload: input,
      method: 'PUT',
      servicePath: '/task/updateTask',
    };
    return this.apiService.post(inp);
  }
  deleteTask(input) {
    const payload = {
      targetedType: 'Collaboration',
      formParams: input,
      method: 'PUT',
      servicePath: '/task/deleteTask',
    };

    return this.apiService.post(payload);
  }
}
