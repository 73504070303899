import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '../../common/configservice';
import { LoginService } from '../login/login.component.service';
import { CustomerConfigurationService } from '../../common/customerConfigurationService';
import { BaseService } from '../../common/base.service';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import {SharedService} from '../../shared/services/shared.service';
import {
  CUSTOMER_CURRENT_USER,
  CUSTOMER_CUSTOMER_DATA, CUSTOMER_EMAIL, CUSTOMER_ENCRYPTED_STRING_TASK_ID,
  CUSTOMER_LOGIN_RESPONSE,
  CUSTOMER_NAVIGATE_ROUTE_EXISTS,
  CUSTOMER_SESSION_EXPIRED,
  CUSTOMER_TOKEN_INFO
} from '../../shared/services/shared-consts';

@Component({
  moduleId: module.id,
  selector: 'sso-openidconnect',
  templateUrl: './sso-openidconnect.component.html',
  styleUrls: ['./sso-openidconnect.component.sass']
})
export class SsoOpenidconnectComponent implements OnInit {
  public loading = true;
  public tokenResponse;
  public responseData;
  public returnUrl;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private configService: ConfigService,
    private loginService: LoginService,
    private customerConfigurationService: CustomerConfigurationService,
    private baseService: BaseService,
    private dataStorageService: DataStorageService,
    private sharedService: SharedService
  ) { }

  public ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.getToken(params);
    });
  }
  private getToken(openIdParams) {
    this.configService
      .getAuthenticationToken(
        'authorization_code',
        false,
        JSON.parse(localStorage.getItem(CUSTOMER_EMAIL)),
        null,
        null,
        openIdParams.code,
        openIdParams.session_state,
        openIdParams.state
      )
      .subscribe(data => {
        this.tokenResponse = data;
        this.sharedService.setLocalStorageData(CUSTOMER_TOKEN_INFO, data);
        this.staticUserInfo();
      });
  }
  private staticUserInfo() {
    this.configService.getUserInfo().subscribe(data => {
      this.sharedService.setLocalStorageData(CUSTOMER_SESSION_EXPIRED, false);
      this.responseData = data;
      this.responseData.userInfo = Object.assign(
        this.responseData.user,
        this.responseData.customer
      );
      this.baseService.setUserInformation(this.responseData.userInfo);
      this.sharedService.setLocalStorageData(
        CUSTOMER_LOGIN_RESPONSE,
        this.responseData
      );
      this.sharedService.setLocalStorageData(
        CUSTOMER_CURRENT_USER,
        this.responseData.userInfo
      );
      this.getcustomerdata();
    });
  }
  private getcustomerdata() {
    if (this.responseData['customer']['tenantId'] !== 0) {
      this.configService
        .getUserConfiguration(
          this.responseData['customer']['tenantId'],
          this.responseData['user']['userId']
        )
        .subscribe(
          val => {
            if (val && val.responseStatus && val.responseStatus.code === 200) {
              if (val.status === 500 || val.result === null) {
              }
              // this.baseService.setAppLoader(false);
              val = this.loginService.setupUserConfiguration(
                val,
                this.responseData
              );
              this.customerConfigurationService.setLoginCustResponse(
                val.result
              );
              this.sharedService.setLocalStorageData(
                CUSTOMER_CUSTOMER_DATA,
                val.result
              );
              if (localStorage.getItem(CUSTOMER_NAVIGATE_ROUTE_EXISTS)) {
                const navigateRouteObj = JSON.parse(localStorage.getItem(CUSTOMER_NAVIGATE_ROUTE_EXISTS))
                this.dataStorageService.setQueryParams(navigateRouteObj.queryParams)
                this.router.navigate([navigateRouteObj.route])
              } else {
                this.getHomePage()
              }
            } else {
              // this.showLogin = true;
              // this.errorMsgShow = true;
              // this.errorMsg = 'Failed While Getting User Configuration Data';
            }
          },
          error => {
            console.log(error);
          }
        );
    } else {
      // this.setCSApp();
    }
  }
  private getHomePage() {
    if (this.redirectedFromTaskEmailLink()) {
      this.redirectToContextLandingPage();
    } else {
      const homepage = this.loginService.setupHomePage();
      this.returnUrl = `/app/${homepage}`;
      this.router.navigate([this.returnUrl]);
    }
  }
  redirectedFromTaskEmailLink() {
    return !!localStorage.getItem(CUSTOMER_ENCRYPTED_STRING_TASK_ID)
  }
  redirectToContextLandingPage() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['../contextlandingpage', JSON.parse(localStorage.getItem(CUSTOMER_ENCRYPTED_STRING_TASK_ID)).taskId], { relativeTo: this.route }
    ).then(() => {
      this.router.onSameUrlNavigation = 'ignore';
    });
  }
}
