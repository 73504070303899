import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.sass'],
  encapsulation: ViewEncapsulation.None
})

export class HelpCenterComponent implements OnInit {

  constructor() { }
  items: Array<object>;
  rm = true;
  show = false;
  accordion = true;
  scope: string;
  addNvigations: any;
  subTitles: any;
  subSubTitles: any;
  addFeatures: any;
  subTitle: any;
  desc: string;
  title: any;
  heading: any;
  examples: any;
  commands: Array<any>;
  ngOnInit() {
    this.items = [{
      Dashboard: {

        Dashboard: {
          heading: 'Dashboard',
          desc: 'The Dashboard is a configurable summary-screen which provides at-a-glance view of KPIs. Each thumbnail depicts the performance based on a particular dimension. For eg, Supplier / Manufacturer, CM/ODM, Opportunity by Drivers, Spend Pareto etc',
          scope: 'NA',
          commands: [
            '<ul>' +
            '<li class=\'help-sub-title font-size-15\'><p>Show Manufacturer view in Dashboard</p></li>' +
            '<li class=\'help-sub-title font-size-15\'><p>View Dashboard</p></li>' +
            '<li class=\'help-sub-title font-size-15\'><p>Configure Dashboard</p></li>' +
            '</ul>'
          ],
          addnavigations: [
            '<ul>' +
            '<li class=\'help-sub-title font-size-15\'><p>None</p></li>' +
            '</ul>'
          ],
          addfeatures: [
            '<ul>' +
            '<li class=\'help-sub-title font-size-15\'><p>None</p></li>' +
            '</ul>'
          ],
          examples: [
            '<ul>' +
            '<li class=\'help-sub-title font-size-15\'><p>Show Supplier view in Dashboard</p></li>' +
            '<li class=\'help-sub-title font-size-15\'><p>View Dashboard</p></li>' +
            '</ul>'
          ]

        }
      },
      'Spend Analytics': {

        Spend:
          {
            'Spend Overview': {
              heading: 'Spend Overview',
              desc: 'Spend Overview showcases the historic, current and future spend trends and savings based on Customer data for the selected time period. The data can be filtered using multiple dimensions such as Manufacturer, Supplier, CMODM, CPN, MPN etc',
              scope: 'Product, Commodity',
              commands: [
                '<ul>' +
                '<li class=\'help-sub-title font-size-15\'><p>Show spend of &lt;Supplier Name&gt;</p></li>' +
                '<li class=\'help-sub-title font-size-15\'><p>Show Spend</p></li>' +
                '<li class=\'help-sub-title font-size-15\'><p>View savings for Q2 2018 to Q4 2019</p></li>' +
                '</ul>'
              ],
              addnavigations: [
                '<ul>' +
                '<li class=\'help-sub-title font-size-15\'><p>Graph View</p></li>' +
                '<li class=\'help-sub-title font-size-15\'><p>Summary Table View</p></li>' +
                '<li class=\'help-sub-title font-size-15\'><p>Detailed Table View</p></li>' +
                '</ul>'
              ],
              addfeatures: [
                '<ul>' +
                '<li class=\'help-sub-title font-size-15\'><p>Print Graph</p></li>' +
                '<li class=\'help-sub-title font-size-15\'><p>Export Table to Excel</p></li>' +
                '</ul>'
              ],
              examples: [
                '<ul>' +
                '<li class=\'help-sub-title font-size-15\'><p>Show spend of Murata;</p></li>' +
                '<li class=\'help-sub-title font-size-15\'><p>Show spend for WESTRON</p></li>' +
                '<li class=\'help-sub-title font-size-15\'><p>Show spend for MTEK</p></li>' +
                '</ul>'
              ]
            },
            'Spend Drivers': {
              heading: 'Spend Drivers',
              desc: 'Spend Drivers shows the factors that drive the Customer spend for the selected Time Period.  The data can be filtered using multiple dimensions such as Manufacturer, Supplier, CMODM, CPN, MPN etc',
              scope: 'Product, Commodity',
              commands: [
                '<ul>' +
                '<li class=\'help-sub-title font-size-15\'><p>Show spend driver of &lt;Supplier Name&gt;</p></li>' +
                '<li class=\'help-sub-title font-size-15\'><p>View spend driver for Q2 2018 to Q4 2019</p></li>' +
                '</ul>'
              ],
              addnavigations: [
                '<ul>' +
                '<li class=\'help-sub-title font-size-15\'><p>Graph View</p></li>' +
                '<li class=\'help-sub-title font-size-15\'><p>Summary Table View</p></li>' +
                '</ul>'
              ],
              addfeatures: [
                '<ul>' +
                '<li class=\'help-sub-title font-size-15\'><p>Print Graph</p></li>' +
                '<li class=\'help-sub-title font-size-15\'><p>Export Table to Excel</p></li>' +
                '</ul>'
              ],
              examples: [
                '<ul>' +
                '<li class=\'help-sub-title font-size-15\'><p>Show spend driver of AKM</p></li>' +
                '<li class=\'help-sub-title font-size-15\'><p>Show spend drivers of bat54c in 2018 Q4</p></li>' +
                '</ul>'
              ]
            },
            'Savings Drivers': {
              heading: 'Savings Drivers',
              desc: 'Savings Drivers shows the factors that drive the Customer Savings for the selected Time Period.  The data can be filtered using multiple dimensions such as Manufacturer, Supplier, CMODM, CPN, MPN etc',
              scope: 'Product, Commodity',
              commands: [
                '<ul>' +
                '<li class=\'help-sub-title font-size-15\'><p>Show Savings driver of &lt;Supplier Name&gt;</p></li>' +
                '<li class=\'help-sub-title font-size-15\'><p>View savings driver for Q2 2018 to Q4 2019</p></li>' +
                '</ul>'
              ],
              addnavigations: [
                '<ul>' +
                '<li class=\'help-sub-title font-size-15\'><p>Graph View</p></li>' +
                '<li class=\'help-sub-title font-size-15\'><p>Summary Table View</p></li>' +
                '</ul>'
              ],
              addfeatures: [
                '<ul>' +
                '<li class=\'help-sub-title font-size-15\'><p>Print Graph</p></li>' +
                '<li class=\'help-sub-title font-size-15\'><p>Export Table to Excel</p></li>' +
                '</ul>'
              ],
              examples: [
                '<ul>' +
                '<li class=\'help-sub-title font-size-15\'><p>Show Savings driver for AME</p></li>' +
                '<li class=\'help-sub-title font-size-15\'><p>Show MPN Savings Drivers view Print</p></li>' +
                '</ul>'
              ]
            },
            'Spend Elements': {
              heading: 'Spend Elements',
              desc: 'Spend Elements shows the Product Level Spend breakdown (BOM vs Transformations vs Cost Adders) for a selected Time Period.',
              scope: 'Product',
              commands: [
                '<ul>' +
                '<li class=\'help-sub-title font-size-15\'><p>Show Spend Elements of &lt;Supplier Name&gt;</p></li>' +
                '<li class=\'help-sub-title font-size-15\'><p>View spend elements for Q2 2018 to Q4 2019</p></li>' +
                '</ul>'
              ],
              addnavigations: [
                '<ul>' +
                '<li class=\'help-sub-title font-size-15\'><p>Graph View</p></li>' +
                '<li class=\'help-sub-title font-size-15\'><p>Summary Table View</p></li>' +
                '<li class=\'help-sub-title font-size-15\'><p>Detailed Table View</p></li>' +

                '</ul>'
              ],
              addfeatures: [
                '<ul>' +
                '<li class=\'help-sub-title font-size-15\'><p>Print Graph</p></li>' +
                '<li class=\'help-sub-title font-size-15\'><p>Export Table to Excel</p></li>' +
                '</ul>'
              ],
              examples: [
                '<ul>' +
                '<li class=\'help-sub-title font-size-15\'><p>Show Spend Elements for Murata</p></li>' +
                '<li class=\'help-sub-title font-size-15\'><p>Show Spend Elements for Hybrid</p></li>' +
                '</ul>'
              ]
            }


          },
        Cost: {
          'MPN Cost': {
            heading: 'MPN Cost',
            desc: 'The Cost data can be viewed for a selected time period along with the Savings % at the MPN Part level. Additionally, LevaData’s Predictive Costing BETA  shows the cost trend based on Pre-Awarded Customer Cost versus LevaData Predicted Cost for each part',
            scope: 'Commodity',
            commands: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Cost for &lt;MPN ID&gt;</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>View Predictive Costing for &lt;MPN ID&gt;</p></li>' +
              '</ul>'
            ],
            addnavigations: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Graph View</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Summary Table View</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Detailed Table View</p></li>' +

              '</ul>'
            ],
            addfeatures: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Print Graph</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Export Table to Excel</p></li>' +
              '</ul>'
            ],
            examples: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Cost for bat54c</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>View Predictive Costing for bat54s</p></li>' +
              '</ul>'
            ]
          },
          'CPN Cost': {
            heading: 'CPN Cost',
            desc: 'The Cost data can be viewed for a selected time period along with the Savings % at the CPN Part level. Additionally, LevaData’s Predictive Costing BETA  shows the CPN cost trend (Aggregated MPN View) based on Pre-Awarded Customer Cost versus LevaData Predicted Cost for each part',
            scope: 'Commodity',
            commands: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Cost for &lt;CPN ID&gt;</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>View Predictive Costing for &lt;CPN ID&gt;</p></li>' +
              '</ul>'
            ],
            addnavigations: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Graph View</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Summary Table View</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Detailed Table View</p></li>' +

              '</ul>'
            ],
            addfeatures: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Print Graph</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Export Table to Excel</p></li>' +
              '</ul>'
            ],
            examples: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Cost for 079-00165</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>View Predictive Costing for 079-00165</p></li>' +
              '</ul>'
            ]
          },
          'Product Allocations': {
            heading: 'Product Allocations',
            desc: 'Product Allocations shows what percentage of Total Product Cost is Managed BOM Cost for a selected period of Time.',
            scope: 'Commodity',
            commands: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>View Product Allocation for &lt;Product ID&gt;</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Allocation for &lt;Product ID&gt;</p></li>' +
              '</ul>'
            ],
            addnavigations: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Graph View</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Summary Table View</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Detailed Table View</p></li>' +

              '</ul>'
            ],
            addfeatures: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Print Graph</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Export Table to Excel</p></li>' +
              '</ul>'
            ],
            examples: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>View Product Allocation for Amplifier</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Allocation for Jock</p></li>' +
              '</ul>'
            ]
          },
          'Master Cost Table': {
            heading: 'Master Cost Table',
            desc: 'Master Cost Table shows the CPNs, in the Customer Data, detailing the MPN splits for each CMODM.',
            scope: 'Commodity',
            commands: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>View master Cost Table for &lt;Supplier&gt;</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Master Cost for <CPN ID></p></li>' +
              '</ul>'
            ],
            addnavigations: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Detailed Table View</p></li>' +
              '</ul>'
            ],
            addfeatures: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Export Table to Excel</p></li>' +
              '</ul>'
            ],
            examples: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>View Master Cost Table for Murata</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Master Cost for 079-00165</p></li>' +
              '</ul>'
            ]
          },
          'Product Cost': {
            heading: 'Product Cost',
            desc: 'The Cost data can be viewed for a selected time period along with the Breakdown (BOM vs Transformations vs Cost Adders) at the Product level. Additionally, LevaData’s Predictive Costing BETA  shows the Product cost trend (Aggregated CPN View) based on Pre-Awarded Customer Cost versus LevaData Predicted Cost for each Product',
            scope: 'Product',
            commands: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>View Cost for &lt;Product ID&gt;</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Product Cost for 2018, 2019</p></li>' +
              '</ul>'
            ],
            addnavigations: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Graph View</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Summary Table View</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Detailed Table View</p></li>' +
              '</ul>'
            ],
            addfeatures: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Print Graph</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Export Table to Excel</p></li>' +
              '</ul>'
            ],
            examples: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>View Cost for Amplifier</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Product Cost for 2018, 2019</p></li>' +
              '</ul>'
            ]
          },
          'Costed BOM': {
            heading: 'Costed BOM',
            desc: 'Costed BOM depicts the unique Product BOM costs for each quarter in the time period selection',
            scope: 'Product',
            commands: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Costed BOM for &lt;Product ID&gt;</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Costed BOM for &lt;Product Family&gt;</p></li>' +
              '</ul>'
            ],
            addnavigations: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Graph View</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Summary Table View</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Detailed Table View</p></li>' +
              '</ul>'
            ],
            addfeatures: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Print Graph</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Export Table to Excel</p></li>' +
              '</ul>'
            ],
            examples: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Costed BOM for Amplifier</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Costed BOM for Hybrid</p></li>' +
              '</ul>'
            ]
          },
          'Transformation Cost': {
            heading: 'Transformation Cost',
            desc: 'Transformation Cost depicts the Product Transformation costs for each quarter in the time period selection',
            scope: 'Product',
            commands: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Transformation Cost for &lt;Product ID&gt;</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Transformation Cost for &lt;Product Family&gt;</p></li>' +
              '</ul>'
            ],
            addnavigations: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Graph View</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Summary Table View</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Detailed Table View</p></li>' +
              '</ul>'
            ],
            addfeatures: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Print Graph</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Export Table to Excel</p></li>' +
              '</ul>'
            ],
            examples: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Transformation Cost for Amplifier</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Transformation Cost for Hybrid</p></li>' +
              '</ul>'
            ]
          },
          'Cost Adders': {
            heading: 'Cost Adders',
            desc: 'Cost Adders depicts the Product level Cost Adders for each quarter in the time period selection',
            scope: 'Product',
            commands: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Cost Adders for &lt;Product ID&gt;</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Transformation Cost for &lt;Product Family&gt;</p></li>' +
              '</ul>'
            ],
            addnavigations: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Graph View</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Summary Table View</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Detailed Table View</p></li>' +
              '</ul>'
            ],
            addfeatures: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Print Graph</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Export Table to Excel</p></li>' +
              '</ul>'
            ],
            examples: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Cost Adders for Amplifier</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Transformation Cost for Hybrid</p></li>' +
              '</ul>'
            ]
          },
        },
        Forecast: {
          'Forecast Lookup': {
            heading: 'Forecast Lookup',
            desc: 'Forecast Lookup shows the demand / forecast at the MPN, CPN or Product level based on the selected Time Period and Scope.',
            scope: 'Product, Commodity',
            commands: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Forecast for &lt;CPN ID&gt;</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Lookup Forecast &lt;Product ID&gt;</p></li>' +
              '</ul>'
            ],
            addnavigations: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Graph View</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Summary Table View</p></li>' +
              '</ul>'
            ],
            addfeatures: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Print Graph</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Export Table to Excel</p></li>' +
              '</ul>'
            ],
            examples: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Forecast for 079-000025</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Lookup Forecast Amplifier</p></li>' +
              '</ul>'
            ]
          },
          'Forecast Drivers': {
            heading: 'Forecast Drivers',
            desc: 'Forecast Lookup shows the percentage of MPNs, CPNs or Products that have been forecasted based on the selected Time Period and Scope',
            scope: 'Product, Commodity',
            commands: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Forecast Driver for &lt;CPN ID&gt;</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Forecast Driver &lt;Product ID&gt;</p></li>' +
              '</ul>'
            ],
            addnavigations: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Graph View</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Summary Table View</p></li>' +
              '</ul>'
            ],
            addfeatures: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Print Graph</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Export Table to Excel</p></li>' +
              '</ul>'
            ],
            examples: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Forecast Driver for 079-00165</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Forecast Driver Jock</p></li>' +
              '</ul>'
            ]
          },
          'Forecast Change': {
            heading: 'Forecast Change',
            desc: 'Forecast Change shows the delta in Forecast when compared between 2 quarters and how that change impacts Spend.',
            scope: 'Product, Commodity',
            commands: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Forecast Change for &lt;CPN ID&gt;</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Forecast Change Q3 2018 and Q4 2018</p></li>' +
              '</ul>'
            ],
            addnavigations: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Graph View</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Summary Table View</p></li>' +
              '</ul>'
            ],
            addfeatures: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Print Graph</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Export Table to Excel</p></li>' +
              '</ul>'
            ],
            examples: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Forecast Change for 079-00165</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Forecast Change Q3 2018 and Q4 2018</p></li>' +
              '</ul>'
            ]
          },
          'Forecast vs Actual': {
            heading: 'Forecast vs Actual',
            desc: 'Forecast vs Actual shows the planned versus actual forecast and how it impacts the Spend / Savings performance.',
            scope: 'Product, Commodity',
            commands: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Forecast vs Actual for &lt;CPN ID&gt;</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Forecast vs Actual Q3 2018</p></li>' +
              '</ul>'
            ],
            addnavigations: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Graph View</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Summary Table View</p></li>' +
              '</ul>'
            ],
            addfeatures: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Print Graph</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Export Table to Excel</p></li>' +
              '</ul>'
            ],
            examples: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Forecast vs Actual for 079-00165</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Forecast vs Actual Q3 2018</p></li>' +
              '</ul>'
            ]
          },

        },
      },
      'Market Intelligence': {
        Benchmarking: {
          Benchmarking: {
            heading: 'Benchmarking',
            desc: 'Benchmarking shows the Benchmark cost (Internal as well as external) for MPN(s) with the option to view by multiple dimensions such as MPN, Supplier, Manufacturer, Sub Commodity etc.',
            scope: 'NA',
            commands: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Benchmark for &lt;MPN ID&gt;</p></li>' +
              '</ul>'
            ],
            addnavigations: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Opportunities</p></li>' +
              '</ul>'
            ],
            addfeatures: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Export Table to Excel</p></li>' +
              '</ul>'
            ],
            examples: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Benchmark for bat54c</p></li>' +
              '</ul>'
            ]
          }

        },
        Competitiveness: {
          Competitiveness: {
            heading: 'Competitiveness',
            desc: 'Competitiveness plots how competitive Customer is when compared to others within the same industry versus other industries.',
            scope: 'NA',
            commands: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>View Competitiveness for &lt;Sub Commodity&gt;</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Competitiveness for &lt;Sub Commodity&gt; for last 3 months</p></li>' +
              '</ul>'
            ],
            addnavigations: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Opportunities</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Graph View</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Summary Table View</p></li>' +
              '</ul>'
            ],
            addfeatures: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Export Table to Excel</p></li>' +
              '</ul>'
            ],
            examples: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>View Competitiveness for Antenna</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Competitiveness for Cable for last 3 months</p></li>' +
              '</ul>'
            ]
          }

        },

        'Part Matching': {
          'Part Matching': {
            heading: 'Part Matching',
            desc: 'Part Matching allows the user to find a similar part by MPN or CPN.',
            scope: 'NA',
            commands: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show matching part for &lt;CPN ID&gt;</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Part matching &lt;MPN ID&gt;</p></li>' +
              '</ul>'
            ],
            addnavigations: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>None</p></li>' +
              '</ul>'
            ],
            addfeatures: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>None</p></li>' +
              '</ul>'
            ],
            examples: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show matching part for bat54c</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Part matching bat54s</p></li>' +
              '</ul>'
            ]
          }
        },
        'Raw Materials': {

          Trends: {
            heading: 'Trends',
            desc: 'Raw Materials Trends shows the price trends (historic as well as future) of Raw Materials that have an impact on the Customer Sub Commodities over a selected period of time.',
            scope: 'NA',
            commands: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Copper Trends</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Cobalt trend for 2017 to 2019</p></li>' +
              '</ul>'
            ],
            addnavigations: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Graph View</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Table View</p></li>' +
              '</ul>'
            ],
            addfeatures: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Print Graph</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Export Table to Excel</p></li>' +
              '</ul>'
            ],
            examples: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Copper Trends</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Cobalt trend for 2017 to 2019</p></li>' +
              '</ul>'
            ]
          },
          'Opportunities / Risks': {
            heading: 'Opportunities / Risks',
            desc: 'Raw Materials Opportunities / Risks shows a multi-dimensional and filtered view of the spend impact and opportunities / risks based on the impact percentage of each Raw Material on each CPN.',
            scope: 'NA',
            commands: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Cu Opportunities</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Cobalt Risk for 2017 to 2019</p></li>' +
              '</ul>'
            ],
            addnavigations: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>NA</p></li>' +
              '</ul>'
            ],
            addfeatures: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Export Table to Excel</p></li>' +
              '</ul>'
            ],
            examples: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Copper Opportunities</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Cobalt Risk for 2017 to 2019</p></li>' +
              '</ul>'
            ]
          },
          Mappings: {
            heading: 'Mappings',
            desc: 'Raw Materials Mappings shows the mappings between Customer Raw Material and LevaData Raw Material, which have been imported from various market data sources.',
            scope: 'NA',
            commands: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Raw Materials Mappings</p></li>' +
              '</ul>'
            ],
            addnavigations: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>NA</p></li>' +
              '</ul>'
            ],
            addfeatures: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Export to Excel</p></li>' +
              '</ul>'
            ],
            examples: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Show Raw Materials Mappings</p></li>' +
              '</ul>'
            ]
          },

        },


      },
      Opportunites: {
        Opportunities: {
          heading: 'Opportunities',
          desc: 'Opportunities depicts the available opportunity filtered by various dimensions such as Manufacturer, Supplier, Sub Commodity etc. with a multiple view option - Benchmark, Part Matching, Multi Source, Competitiveness, Raw Materials etc, including LevaData’s Predictive Costing opportunity.',
          scope: 'Product, Commodity',
          commands: [
            '<ul>' +
            '<li class=\'help-sub-title font-size-15\'><p>Show Opportunities for &lt;Manufacturer ID&gt;</p></li>' +
            '<li class=\'help-sub-title font-size-15\'><p>Show Opportunities for &lt;MPN ID&gt;</p></li>' +
            '</ul>'
          ],
          addnavigations: [
            '<ul>' +
            '<li class=\'help-sub-title font-size-15\'><p>Graph View (In Expanded View)</p></li>' +
            '<li class=\'help-sub-title font-size-15\'><p>Table View (In Expanded View)</p></li>' +
            '</ul>'
          ],
          addfeatures: [
            '<ul>' +
            '<li class=\'help-sub-title font-size-15\'><p>Print Graph</p></li>' +
            '<li class=\'help-sub-title font-size-15\'><p>Export to Excel</p></li>' +

            '</ul>'
          ],
          examples: [
            '<ul>' +
            '<li class=\'help-sub-title font-size-15\'><p>Show Opportunities for Murata</p></li>' +
            '<li class=\'help-sub-title font-size-15\'><p>Show Opportunities for Bat54c</p></li>' +
            '</ul>'
          ]

        }
      },
      'Negotiation Playbook': {
        'Negotiation Playbook': {
          heading: 'Negotiation Playbook',
          desc: 'Negotiation Playbook allows the user to create a portfolio with data points such as State of Business insight, Opportunities / Risks, Negotiation Levers for negotiation with Supplier or Manufacturer.',
          scope: 'NA',
          commands: [
            '<ul>' +
            '<li class=\'help-sub-title font-size-15\'><p>Create Negotiation Playbook for &lt;Supplier&gt;</p></li>' +
            '<li class=\'help-sub-title font-size-15\'><p>NP for &lt;Manufacturer&gt;</p></li>' +
            '</ul>'
          ],
          addnavigations: [
            '<ul>' +
            '<li class=\'help-sub-title font-size-15\'><p>NA</p></li>' +
            '</ul>'
          ],
          addfeatures: [
            '<ul>' +
            '<li class=\'help-sub-title font-size-15\'><p>NA</p></li>' +

            '</ul>'
          ],
          examples: [
            '<ul>' +
            '<li class=\'help-sub-title font-size-15\'><p>Create Negotiation Playbook for AKM</p></li>' +
            '<li class=\'help-sub-title font-size-15\'><p>NP for AKM</p></li>' +
            '</ul>'
          ]

        }

      },
      'Cost Lookup':
        {
          'Cost Lookup': {
            heading: 'Cost Lookup',
            desc: 'Cost Lookup enables the user to lookup the cost of an MPN, CPN or Product for the selected Time Period along with details such as where the MPN or CPN is used, Lowest Cost Source etc.',
            scope: 'Product, Commodity',
            commands: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Lookup Cost for &lt;MPN ID&gt;</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Cost Lookup &lt;Product ID&gt;</p></li>' +
              '</ul>'
            ],
            addnavigations: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>NA</p></li>' +
              '</ul>'
            ],
            addfeatures: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>NA</p></li>' +

              '</ul>'
            ],
            examples: [
              '<ul>' +
              '<li class=\'help-sub-title font-size-15\'><p>Lookup Cost for Bat54c</p></li>' +
              '<li class=\'help-sub-title font-size-15\'><p>Cost Lookup for Amplifier</p></li>' +
              '</ul>'
            ]

          }
        },
    }
    ]
    console.log(this.items[0])
    this.title = Object.keys(this.items[0]);
    this.commands = this.items[0]['Dashboard']['Dashboard'].commands;
    this.addNvigations = this.items[0]['Dashboard']['Dashboard'].addnavigations;
    this.heading = this.items[0]['Dashboard']['Dashboard'].heading;
    this.desc = this.items[0]['Dashboard']['Dashboard'].desc;
    this.scope = this.items[0]['Dashboard']['Dashboard'].scope;
    this.addFeatures = this.items[0]['Dashboard']['Dashboard'].addfeatures;
    this.examples = this.items[0]['Dashboard']['Dashboard'].examples;

  }

  search(event) {
  }
  onClick(event, item) {
    if (item === 'Spend Analytics' || item === 'Market Intelligence') {
      this.accordion = false;
    }
    else {
      this.accordion = true;
    }
    this.subTitles = [];
    this.subTitles = Object.keys(this.items[0][item]);
  }
  onSubItemClick(event, item) {
    this.subSubTitles = [];
    this.subSubTitles = Object.keys(this.items[0][event][item]);
  }
  onSubSubItemClick(item, subtitle, subsubtitle) {
    this.show = true;
    this.heading = this.items[0][item][subtitle][subsubtitle].heading;
    this.desc = this.items[0][item][subtitle][subsubtitle].desc;
    this.scope = this.items[0][item][subtitle][subsubtitle].scope;
    this.commands = this.items[0][item][subtitle][subsubtitle].commands;
    this.addNvigations = this.items[0][item][subtitle][subsubtitle].addnavigations;
    this.addFeatures = this.items[0][item][subtitle][subsubtitle].addfeatures;
    this.examples = this.items[0][item][subtitle][subsubtitle].examples;
  }
  onSingleClick(event, item) {
    this.subTitles = [];
    this.subTitles = Object.keys(this.items[0][item]);

  }
  onSingleItemClick(event,item, subtitle) {
    this.title = Object.keys(this.items[0]);
    this.commands = this.items[0][item][subtitle].commands;
    this.addNvigations = this.items[0][item][subtitle].addnavigations;
    this.addFeatures = this.items[0][item][subtitle].addfeatures;
    this.heading = this.items[0][item][subtitle].heading;
    this.desc = this.items[0][item][subtitle].desc;
    this.scope = this.items[0][item][subtitle].scope;
    this.examples = this.items[0][item][subtitle].examples;
    // this.addActive(event);
  }
  addActive(event) {
    const elements: NodeListOf<Element> = document.querySelectorAll('.sub-items-list .sub-item');
    for (let i = 0; i < elements.length; i++) {
      const classes: DOMTokenList = elements[i].classList;
      classes.remove('active');
    }
    event.currentTarget.classList.add('active');
  }
  getDescription(title, subtitle) {
    // this.desc = this.items[]
  }

}
