import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes, NoPreloading } from '@angular/router';
import { BaseAppComponent } from 'app/modules/base-app/base-app.component';
import { LoginComponent } from 'app/modules/authentication/login/login.component';
import { ForgotComponent } from 'app/modules/authentication/forgot/forgot.component';
import { AuthGuard } from 'app/modules/common/auth.guard';
import { ChangePasswordComponent } from 'app/modules/authentication/change-password/change-password.component';
import { HelpCenterComponent } from 'app/modules/help-center/help-center.component';
import { PageNotFoundComponent } from './modules/authentication/page-not-found/page-not-found.component';
import { LoginErrorPageComponent } from './modules/authentication/login-error-page/login-error-page.component';
import { SsoOpenidconnectComponent } from './modules/authentication/sso-openidconnect/sso-openidconnect.component';
import { ShowMyAlertsComponent } from './modules/core/show-my-alerts/show-my-alerts.component';
import { ChatBotComponent } from './modules/chatbot/chatbot.component';
import { ContextLandingPageComponent } from './modules/context-landing-page/context-landing-page.component';
import { AUTHENTICATION_CONSTANT } from './modules/authentication/shared/authentication.constants';

const appRoutes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Login' },
  },
  {
    path: 'oidc',
    component: SsoOpenidconnectComponent,
    data: { title: 'Open ID Connect' },
  },
  {
    path: 'resetPassword',
    component: ChangePasswordComponent,
    data: { title: 'Reset Password', key: AUTHENTICATION_CONSTANT.resetPassword },
  },
  {
    path: 'forgot',
    component: ForgotComponent,
    data: { title: 'Forgot Password' },
  },
  {
    path: 'changepassword',
    component: ChangePasswordComponent,
    data: { title: 'Change Password', key: AUTHENTICATION_CONSTANT.changePassword },
  },
  {
    path: 'help',
    component: HelpCenterComponent,
    data: { title: 'Help & Usage Documentation' },
  },
  {
    path: 'loginerror',
    component: LoginErrorPageComponent,
    data: { title: 'Login Error' },
    canActivate: [AuthGuard],
  },
  {
    path: 'defaultlandingpage',
    component: ChatBotComponent,
    data: { title: 'Home Page' },
    canActivate: [AuthGuard]
  },
  {
    path: 'contextlandingpage/:taskId',
    component: ContextLandingPageComponent,
  },
  {
    path: 'app',
    component: BaseAppComponent,
    canActivate: [AuthGuard],
    data: { title: '' },
    children: [
      { path: '', redirectTo: 'spendintelligence', pathMatch: 'full' },

      {
        path: 'alerts',
        component: ShowMyAlertsComponent
      },
      {
        path: 'coverage-profile',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/coverage-dashboard/coverage-dashboard.module').then((mod) => mod.CoverageDashboardModule)
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((mod) => mod.DashboardModule)
      },
      {
        path: 'alerts',
        component: ShowMyAlertsComponent
      },
      {
        path: 'pagenotfound',
        component: PageNotFoundComponent,
        data: { title: 'Page Not Found' },
      },
      {
        path: 'mi',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('app/modules/mi/mi.module').then((mod) => mod.MiModule),
        data: { title: 'Market Intelligence' },
      },
      {
        path: 'spendintelligence',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('app/modules/spend-intelligence/spend-intelligence.module').then(
            mod => mod.SpendIntelligenceModule
          ),
        data: { title: 'Spend Intelligence' }
      },
      {
        path: 'opportunities',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('app/modules/opportunities/opportunities.module').then(
            mod => mod.OpportunitiesModule
          ),
        data: { title: 'Opportunities' }
      },
      {
        path: 'savingsopportunities',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('app/modules/opportunities-new/opportunities-new.module').then(
            mod => mod.OpportunitiesNewModule
          ),
        data: { title: 'Savings Opportunities' }
      },
      {
        path: 'np',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('app/modules/np/np.module').then((mod) => mod.NpModule),
        data: { title: 'Negotiation Playbook' },
      },
      {
        path: 'costlookup',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('app/modules/costlookup/costlookup.module').then(
            (mod) => mod.CostlookupModule
          ),
        data: { title: 'Cost Lookup' },
      },
      {
        path: 'curatorengine',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('app/modules/curator/curator.module').then(
            (mod) => mod.CuratorModule
          ),
        data: { title: 'Curator Engine' },
      },
      {
        path: 'administration',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('app/modules/administration/administration.module').then(
            (mod) => mod.AdministrationModule
          ),
        data: { title: 'Administration' },
      },
      {
        path: 'reports',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('app/modules/reports/reports.module').then(
            (mod) => mod.ReportsModule
          ),
        data: { title: 'Reports' },
      },
      {
        path: 'srfx',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('app/modules/srfq/srfq.module').then((mod) => mod.SrfqModule),
        data: { title: 'SRFQ' },
      },
      {
        path: 'templates',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('app/modules/rfq-configurations/rfq-config.module').then(
            (mod) => mod.RfqConfigModule
          ),
        data: { title: 'RFQ' },
      },
      {
        path: 'interactiveedit',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('app/modules/interactive-edit/interactive-edit.module').then(
            (mod) => mod.InteractiveEditModule
          ),
        data: { title: 'Interactive Edit' },
      },
      {
        path: 'npi',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('app/modules/npi/npi.module').then((mod) => mod.NpiModule),
        data: { title: 'Npi' },
      },
      {
        path: 'partsdatamanagement',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('app/modules/npi-parts-workbench/npi-parts-workbench.module').then((mod) => mod.NpiPartsWorkbenchModule),
        data: { title: 'Parts Data Management' },
      },
      {
        path: 'contentlabinsights',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            'app/modules/content-lab-insights/content-lab-insights.module'
          ).then((mod) => mod.ContentLabInsightsModule),
        data: { title: 'Insights' },
      },
      // {
      //   path: 'scenarioworkbench',
      //   canActivate: [AuthGuard],
      //   loadChildren: () =>
      //     import(
      //       'app/modules/scenario-workbench/scenario-workbench.module'
      //     ).then((mod) => mod.ScenarioWorkbenchModule),
      //   data: { title: 'Scenario Workbench' },
      // },
      {
        path: 'risk-rfi',
        loadChildren: () =>
          import('app/modules/risk-rfi/risk-rfi.module').then(
            (mod) => mod.RiskRfiModule
          ),
        data: { title: 'Risk RFI' },
      },
      {
        path: 'supplyrisk',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('app/modules/supply-risk/supply-risk.module').then(
            (mod) => mod.SupplyRiskModule
          ),
        data: { title: 'Legacy Supply Risk' },
      },
      // {
      //   path: 'scenariocomparison',
      //   canActivate: [AuthGuard],
      //   loadChildren: () =>
      //     import(
      //       'app/modules/scenario-comparison/scenario-comparison.module'
      //     ).then((mod) => mod.ScenarioComparisonModule),
      //   data: { title: 'Scenario Comparison' },
      // },
      {
        path: 'loadingestiondata',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('app/modules/load-data/load-data.module').then(
            (mod) => mod.LoadDataModule
          ),
        data: { title: 'Load Data' },
      },
      {
        path: 'review-opportunities',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('app/modules/insight-to-action/insight-to-action.module').then(
            (mod) => mod.InsightToActionModule
          ),
        data: { title: 'Review Opportunities' },
      },
      {
        path: 'supplyriskassessment',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            'app/modules/supply-risk-assessment/supply-risk-assessment.module'
          ).then((mod) => mod.SupplyRiskAssessmentModule),
        data: { title: 'Proactive Risk Assessment' },
      },
      {
        path: 'newsEvents',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            'app/modules/news-events/news-events.module'
          ).then((mod) => mod.NewsEventsModule),
        data: { title: 'News and Events' },
      },
      {
        path: 'dashboard-metrics',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/dashboard-metrics/dashboard-metrics.module').then((mod) => mod.DashboardMetricsModule)
      },
    ],
  },
];
// tslint:disable-next-line: variable-name
export const AppRoutingModule: ModuleWithProviders = RouterModule.forRoot(
  appRoutes,
  {
    preloadingStrategy: NoPreloading,
    useHash: true,
  }
);
