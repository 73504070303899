
export const attrLabelMap = {
    vendorImpacted: {
        displayName: 'Impacted Suppliers',
        count: 0,
        type: 'number',
        data: [],
        tooltip: true
    },
    status: {
        displayName: 'Status',
        count: 0,
        type: 'text',
        data: null,
        tooltip: true
    },
    companies: {
        displayName: 'Companies',
        count: 0,
        type: 'number',
        data: [],
        tooltip: true
    },
    deaths: {
        displayName: 'Deaths',
        count: 0,
        type: 'number',
        data: [],
        tooltip: true
    },
    facilities: {
        displayName: 'Facilities',
        count: 0,
        type: 'number',
        data: [],
        tooltip: true
    },
    injuries: {
        displayName: 'Injuries',
        count: 0,
        type: 'number',
        data: [],
        tooltip: true
    },
    reasons: {
        displayName: 'Reasons',
        count: 0,
        type: 'number',
        data: [],
        tooltip: true
    },
    action_type: {
        displayName: 'Action Type',
        count: 0,
        type: 'text',
        data: null,
        tooltip: false
    },
    companies_candidates: {
        displayName: 'Companies Candidates',
        count: 0,
        type: 'number',
        data: [],
        tooltip: true
    },
    terms: {
        displayName: 'Terms',
        count: 0,
        type: 'number',
        data: [],
        tooltip: true
    }
}
export const mainProperties = ['status', 'vendorImpacted']
