import { CustomerConfigurationService } from './customerConfigurationService';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import 'rxjs/add/operator/map';
import { BaseService } from 'app/modules/common/base.service';
import { Router } from '@angular/router';
import { ScopeDataService } from './scope.data.service';
import { TimeSelectionDataService } from '../core/time-selection/shared/time-selectiondata.service';
import {CUSTOMER_CURRENT_USER} from '../shared/services/shared-consts';
@Injectable()
export class DropDownListService {

  constructor(
    private baseService: BaseService,
    private scopeDataService: ScopeDataService,
    private timeSelectionDataService: TimeSelectionDataService,
    private router: Router,
    private customerConfigurationService: CustomerConfigurationService
  ) {
    this.obj = this.baseService.getUserInformation();
    if (this.obj === undefined) {
      this.obj = JSON.parse(localStorage.getItem(CUSTOMER_CURRENT_USER));
    }
    if (
      this.customerConfigurationService instanceof CustomerConfigurationService
    ) {
      if (
        this.customerConfigurationService.getConfData('Commodity') !== undefined
      ) {
        this.dropDownList = this.customerConfigurationService.getConfData(
          'Commodity'
        ).viewByData;
      }
      if (this.customerConfigurationService.getConfData('Product')) {
        this.productDropDownList = this.customerConfigurationService.getConfData(
          'Product'
        ).viewByData;
      }
      if (this.dropDownList) {
        if (this.dropDownList['forecastchange']) {
          this.dropDownList['forecastchange'] = this.Dropdownvalues(
            'forecastchange'
          );
        }
        if (this.dropDownList['forecastvsactual']) {
          this.dropDownList['forecastvsactual'] = this.Dropdownvalues(
            'forecastchange'
          );
        }
      }

      if (this.productDropDownList) {
        if (this.productDropDownList['forecastchange']) {
          this.productDropDownList['forecastchange'] = this.Dropdownvalues(
            'forecastchange'
          );
        }
        if (this.productDropDownList['forecastvsactual']) {
          this.productDropDownList['forecastvsactual'] = this.Dropdownvalues(
            'forecastchange'
          );
        }
      }
    }
  }
  myCustomSubject = new Subject<any>();

  botDropdownview = new Subject<any>();

  obj: any;
  quarters: Array<object> = [];
  dropDownList: Array<object> = [];
  productDropDownList: Array<object> = [];

  public _currentValue = new BehaviorSubject<any>(undefined);

  public Dropdownvalues(targets) {
    let data;
    data = this.timeSelectionDataService.getFullTimePeriodObject();
    if (data && data.timePeriod) {
      data = data.timePeriod;
    }
    this.quarters = [];
    let years = [];
    if (data) {
      years = Object.getOwnPropertyNames(data);
    }
    if (years.length > 0) {
      years.forEach(item => {
        if (
          data &&
          data[item] &&
          data[item][0] &&
          Object.getOwnPropertyNames(data[item][0]).length > 0
        ) {
          Object.getOwnPropertyNames(data[item][0]).forEach(quarterItem => {
            if (data[item][0][quarterItem]) {
              this.quarters.push({
                title: `${quarterItem}'${item.toString().substring(2, 4)}`
              });
            }
          });
        }
      });
    }
    this.dropDownList['forecastchange'] = [];
    // if (targets === "forecastchange") {

    if (this.quarters !== undefined) {
      let count = 0;
      for (const i of this.quarters) {
        count++;
        let mQuarters: object;
        if (count === 0) {
          mQuarters = {
            view: i['title'],
            text: i['title'],
            isdefault: true
          };
        } else {
          mQuarters = {
            view: i['title'],
            text: i['title']
          };
        }
        if (mQuarters !== undefined) {
          this.dropDownList['forecastchange'].push(mQuarters);
        }
      }
    }
    // }
    return this.dropDownList['forecastchange'];
  }

  public getDropDownOption(target) {
    let dropdownValues = this.dropDownList[target];
    switch (target) {
      case 'summary':
      case 'benchmark': {
        let miviews;
        if (this.dropDownList) {
          miviews = this.dropDownList.filter(
            item => item['mi'] instanceof Object
          );
        }
        if (
          miviews instanceof Array &&
          miviews.length > 0 &&
          miviews[0]['mi'][target]
        ) {
          dropdownValues = miviews[0]['mi'][target];
        }
        break;
      }
    }

    if (dropdownValues !== undefined) {
      return dropdownValues;
    }
  }
  public getProductDropDownOption(target) {
    let dropdownValues = this.productDropDownList[target];
    switch (target) {
      case 'forecastvsactual':
        dropdownValues = this.Dropdownvalues(target) || [];
        if (dropdownValues instanceof Array && this.Dropdownvalues.length > 0) {
          const currQuarterData = this.timeSelectionDataService.getFullTimePeriodObject()['currentQuarter'];
          const currQuarter = `${currQuarterData.quarter}'${currQuarterData.year.toString().substring(2, 4)}`;
          dropdownValues[dropdownValues.findIndex(i => i.view === currQuarter)]['isdefault'] = true;
        }
        break;
      case 'transformationcost':
      case 'componentcost':
      case 'benchmark': {
        let miviews;
        if (this.productDropDownList) {
          miviews = this.dropDownList.filter(
            item => item['mi'] instanceof Object
          );
        }
        if (
          miviews instanceof Array &&
          miviews.length > 0 &&
          miviews[0]['mi'][target]
        ) {
          dropdownValues = miviews[0]['mi'][target];
        }
        break;
      }
    }

    if (dropdownValues !== undefined) {
      return dropdownValues;
    }
  }


  public setSelectedDropDownValue(obj) {
    this.myCustomSubject.next(obj);
  }

  public getSelectedDropDownValue() {
    return this.myCustomSubject.asObservable();
  }

  setSelectedValue(obj: object, update?: boolean): void {
    if (obj !== undefined) {
      if (!update || update === undefined) {
        this._currentValue.next(obj);
      } else {
        this._currentValue.next(this.getDropDownValue(obj));
      }
    }
  }
  getSelectedValue() {
    return this._currentValue.asObservable();
  }

  getDropDownValue(obj: object) {
    let dropdownArray: any;
    if (this.scopeDataService.currentFocus.toLowerCase() === 'product') {
      // if (obj['target'] === "benchmark" || obj['target'] === "transformationcost" || obj['target'] === "componentcost") {
      //   dropdownArray = this.productDropDownList['mi'][obj['target']];
      // } else {
      //   dropdownArray = this.productDropDownList[obj['target']];
      // }
      dropdownArray = this.getProductDropDownOption(obj['target']);
    } else {
      // if (obj['target'] === "benchmark") {
      //   dropdownArray = this.dropDownList['mi'][obj['target']];
      // } else if (obj['target'] === "summary") {
      //   dropdownArray = this.dropDownList['mi'][obj['target']];
      // } else {
      dropdownArray = this.getDropDownOption(obj['target']);
      // }
    }
    if (dropdownArray !== undefined) {
      for (const list of dropdownArray) {
        if (list['dbcolumnname'] === obj['selectedList']) {
          return list;
        }
      }
    }
  }
}
