import { Component, OnInit } from '@angular/core';

import { Notes } from './shared/notes.model';
import { NotesService } from './shared/notes.service';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';

@Component({
  selector: 'notes',
  templateUrl: 'notes.component.html',
  styleUrls : ['./notes.component.sass'],
  providers: [NotesService]
})

export class NotesComponent implements OnInit {
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  notes: Notes[] = [];

  constructor(private notesService: NotesService) { }

  ngOnInit() {

  }
}
