import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

import { RelatedNews } from './related-news.model';

@Injectable()
export class RelatedNewsService {

  constructor(private http: HttpClient) { }

}
