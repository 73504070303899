import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Router } from '@angular/router';
import { DataStorageService } from 'app/modules/common/data-storage.service';

@Component({
  selector: 'child-cell',
  template: `
    {{ statusMap[val] }}
  `
})
export class SupplierStatusComponent {
  public params: any;
  val: any;
  statusTemplate: any;
  colorCode: any = '';
  supplierStatusArray: any = [];
  statusMap: any ;
  statuskeyMap: any ;
  constructor(
    private router: Router,
    private dataStorageService: DataStorageService
  ) {
    dataStorageService.getStatusValue().subscribe(value => {
      this.statuskeyMap = value;
      for (const key of Object.keys(value)) {
        this.supplierStatusArray = value[key];
      }
    });
  }
  agInit(params: any): void {
    this.supplierStatusArray = this.statuskeyMap[params.colDef.field];
    if (
      this.supplierStatusArray !== undefined &&
      this.supplierStatusArray.length > 0
    ) {
      for (const i of this.supplierStatusArray) {
        this.statusMap[i['id']] = i['value'];
      }
    }
    this.val = params.data[params.colDef.field];
    this.params = params;
  }
}
