export const countryListConfiguration = {
  label: 'Country',
  type: 'newautosearch',
  filterConfig: {
    multiSelect: false,
    targetedType: 'riskManagementService',
    servicePath: 'risk/search/country',
    defaultItemSelection: false,
    defaultSelectedItem: null,
  },
  showFixedDropdown: true,
  hideLabel: true,
  val: 'country',
  data: [],
  id: `country-auto-select`,
  defaultParameter: null,
  isDisable: false
}
export const stateListConfiguration = {
  label: 'State',
  type: 'newautosearch',
  filterConfig: {
    multiSelect: false,
    targetedType: 'riskManagementService',
    servicePath: 'risk/search/state',
    defaultItemSelection: false,
    defaultSelectedItem: null,
  },
  showFixedDropdown: true,
  hideLabel: true,
  val: 'state',
  data: [],
  id: `state-auto-select`,
  defaultParameter: null,
  isDisable: false
}
export const cityListConfiguration = {
  label: 'City',
  type: 'newautosearch',
  filterConfig: {
    multiSelect: false,
    targetedType: 'riskManagementService',
    servicePath: 'risk/search/city',
    defaultItemSelection: false,
    defaultSelectedItem: null,
  },
  showFixedDropdown: true,
  hideLabel: true,
  val: 'city',
  data: [],
  id: `city-auto-select`,
  defaultParameter: null,
  isDisable: false
}
export const siteListConfiguration = {
  label: 'Sites Impacted',
  type: 'newautosearch',
  filterConfig: {
    multiSelect: false,
    targetedType: 'riskManagementService',
    servicePath: 'risk/search/sites',
    defaultItemSelection: false,
    defaultSelectedItem: null,
  },
  showFixedDropdown: true,
  hideLabel: true,
  val: 'siteImpacted',
  data: [],
  id: `site-auto-select`,
  defaultParameter: null,
  isDisable: false
}
export const supplierListConfiguration = {
  label: 'Suppliers',
  type: 'newautosearch',
  filterConfig: {
    multiSelect: true,
    targetedType: 'riskManagementService',
    servicePath: 'events/suppliers',
    defaultItemSelection: false,
    defaultSelectedItem: null,
  },
  showFixedDropdown: true,
  hideLabel: true,
  val: 'vendorImpacted',
  showCustomToolTip: true,
  showSelectAll: true,
  data: [],
  id: `vendor-auto-select`,
  defaultParameter: null,
  isDisable: false
}
