import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  Pipe,
  PipeTransform,
  ViewChild,
  OnChanges,
  forwardRef,
  Directive,
  AfterViewChecked
} from '@angular/core'
import { FilterElementService } from './filter-element.service'
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service'
import { BaseService } from 'app/modules/common/base.service'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { LdCustomTagTypes } from 'app/modules/leva-ui-library/components/custom-tag/custom-tag.service'
import { DataStorageService } from 'app/modules/common/data-storage.service'
const dropdownHeight = 214;

@Pipe({
  name: 'myfilter',
  pure: false,
})
export class MyFilterPipe implements PipeTransform {
  transform(items: any[], filter: any): any {
    if (!items || !filter) {
      return items
    }
    return items.filter(
      (item) =>
        item.displayName
          .toLowerCase()
          .indexOf(filter.displayName.toLowerCase()) !== -1
    )
  }
}
@Directive({
  selector: '[appSearchFocus]'
})
export class AutoSearchFocusDirective implements AfterViewChecked {
  constructor(
    private elementRef: ElementRef
  ) {
  }
  ngAfterViewChecked() {
    this.elementRef.nativeElement.focus();
  }
}
@Component({
  selector: 'app-filter-element',
  templateUrl: './filter-element.component.html',
  styleUrls: ['./filter-element.component.sass'],
  providers: [
    MyFilterPipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterElementComponent),
      multi: true,
    },
  ],
})
export class FilterElementComponent
  implements OnInit, OnChanges, ControlValueAccessor {
  @Input() configuration: any = {}
  @Input() type: any
  @Input() defaultValue: any
  @Input() ebenchmarkDropdown = true;
  @Output() filterSelect = new EventEmitter<any>()
  @Output() onDefaultSelection = new EventEmitter<any>()
  @Output() optionHover = new EventEmitter<any>()
  @Output() onOptionOut = new EventEmitter<any>()
  @ViewChild('multiSelectDropdown', { static: false })
  elementView: ElementRef
  @ViewChild('customTooltip', { static: false })
  customTooltip: ElementRef
  @Input() keepSelected = false
  showDropUp: boolean;
  virtualDropdown: any = [];
  @Input()
  set reset(val) {
    this._reset = val
  }
  get reset() {
    return this._reset
  }
  filterSelectEmitObj = null
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION
  customDropDown: ElementRef
  selectedValue = null
  selectedValues = []
  filterArgs = {
    displayName: '',
  };

  userTag = [{
    type: LdCustomTagTypes.TAG_CONTEXT_USER,
    displayName: this.selectedValue,
    otherInformation: []
  }];
  appSearchFocus = true

  tempFilterOptions = {
    data: [],
    length: 0,
    selectAll: false,
  }
  totalItems = []
  selectedItemVal = null
  selectedItems = []
  autoFilter = {
    config: null,
    data: [],
    length: 0,
  }
  filterParameters = null
  disableClearAll = true
  disableSelectAll = true
  showAll = true
  _reset: any
  tooltipInformation
  tooltipInformationIncludeKeys = []
  tooltipInformationExcludeKeys = []
  tooltipInformationOptimizeKeys = []

  dimensionDisplayNames = {
    ld_manufacturer: 'Manufacturer',
    ld_manufacturer_preference: 'Manufacturer',
    ld_supplier: 'Supplier',
    ld_supplier_preference: 'Supplier',
    ld_cm_odm: 'CM/ODM',
    ld_cm_odm_preference: 'CM/ODM',
    mpn: 'MPN',
    mpn_preference: 'MPN',
    site_name_preference: 'Manufacturer Site',
    site_name: 'Manufacturer Site',
    impacted: 'Disrupted',
    country: 'Country',
    city: 'City',
    cost: 'Cost Impact',
    state: 'State',
    cost_impact: 'Cost Impact',
    lead_time_impact: 'Lead Time Impacted Days',
    cost_impact_pct: 'Cost Impact',
    leadtime_impact_days: 'Lead Time Impacted Days',
    covid19_index: 'Covid19 Index',
    lead_time: 'Lead Time Impacted Days',
    risk_index: 'Risk Index',
  }

  userSessionData = null
  tenantId;

  @ViewChild('staticDropdownMenu', { static: false }) staticDropdownMenu: ElementRef;

  propagateChange = (_: any) => { }
  constructor(
    private filterElementService: FilterElementService,
    private baseService: BaseService,
    private filterPipe: MyFilterPipe,
    private dataStorageService: DataStorageService
  ) {
    this.userSessionData = this.baseService.getUserInformation()
    if (this.userSessionData) {
      this.tenantId = this.userSessionData.tenantId
    }
  }

  ngOnInit() {
    if (this.configuration.defaultSelectedView) {
      this.selectedItem(this.configuration.defaultSelectedView)
    }
  }

  onClickedOutside(event) {
    this.filterArgs.displayName = '';
    if (this.staticDropdownMenu &&
      this.staticDropdownMenu.nativeElement &&
      this.staticDropdownMenu.nativeElement.children &&
      this.staticDropdownMenu.nativeElement.children.length > 0) {
      this.staticDropdownMenu.nativeElement.children[0].scrollTop = 0
    }
  }
  ngOnChanges() {
    switch (this.configuration.type) {
      case 'multiselect':
      case 'multiselectvirtualscroll':
        if (
          this.configuration.hasOwnProperty('showAll') &&
          this.configuration.showAll === false
        ) {
          this.showAll = false
        } else {
          this.showAll = true
        }
        if (this.configuration.type === 'multiselectvirtualscroll') {
          this.virtualDropdown = this.tempFilterOptions.data = JSON.parse(
            JSON.stringify(this.configuration.data)
          )
        }
        if (!this.configuration.isSelectAll) {
          const selectedItems = this.configuration.data.filter(
            (filterItem: any) => filterItem.selected
          )
          this.selectedItems = selectedItems
          this.selectedValues = selectedItems.map((item) => item.displayName)
        } else if (this.configuration.type === 'multiselectvirtualscroll' && this.configuration.isSelectAll) {
          this.selectedItems = this.virtualDropdown
        }
        break
      case 'autosearch':
      case 'newautosearch':
        this.autoFilter.config = this.configuration.filterConfig
        this.filterParameters =
          this.autoFilter.config.hasOwnProperty('isParameterEnabled') &&
            this.autoFilter.config.isParameterEnabled
            ? this.autoFilter.config.parameterDetails
            : null
        if (!this.keepSelected) {
          this.selectedValue = null
          this.selectedItems = []
          this.selectedValues = []
        }
        if (!this.configuration.isDisable) {
          if (this.configuration.specialVersion) {
            this.getSourceData()
          } else {
            this.getFilterData()
          }
        }
        break
      case 'static':
        this.autoFilter.config = this.configuration.filterConfig
        if (!!this.configuration.defaultSelectedItem) {
          let selectedItem = null
          switch (typeof this.configuration.defaultSelectedItem) {
            case 'string':
              this.selectedValue = this.configuration.defaultSelectedItem
              selectedItem = this.configuration.defaultSelectedItem
              if (!!this.configuration.defaultItemSelection) {
                this.selectedValue = selectedItem
                this.selectedItemVal = {
                  val: selectedItem,
                  displayName: selectedItem,
                }
              }
              break
            case 'object':
              selectedItem = JSON.parse(JSON.stringify(this.configuration.defaultSelectedItem))
              this.selectedValue = this.configuration.defaultSelectedItem.displayName
              if (!!this.configuration.defaultItemSelection) {
                this.selectedItemVal = {
                  val: selectedItem.val,
                  displayName: this.selectedValue,
                }
              }
              break
          }
          this.selectedItem(this.selectedItemVal)
        }
        if (this.configuration.filterConfig && this.configuration.filterConfig.servicePath) {
          this.getStaticData()
        }
        break;
      default:
        this.selectedValue = null
        break
    }
  }

  inputSearch() {
    const data = this.virtualDropdown
    if (!this.filterArgs.displayName) {
      this.tempFilterOptions.data = data
    } else {
      this.tempFilterOptions.data = this.filterPipe.transform(
        data,
        this.filterArgs
      )
    }
  }

  returnSelectedCount() {
    return this.configuration.data.filter(
      (filterItem) => filterItem.selected === true
    ).length;
  }

  onAutoSearch() {
    if (!this.filterArgs.displayName.length) {
      this.tempFilterOptions.data = JSON.parse(
        JSON.stringify(this.autoFilter.data)
      )
      this.tempFilterOptions.length = this.autoFilter.length
      this.setSelectedItems()
    } else {
      // this.getFilterData()
      if (this.configuration.specialVersion) {
        this.getSourceData()
      } else {
        this.getFilterData()
      }
    }
  }

  clearAutoSearch($event) {
    $event.stopPropagation()
    this.filterArgs.displayName = ''
    this.tempFilterOptions.data = JSON.parse(
      JSON.stringify(this.autoFilter.data)
    )
    this.tempFilterOptions.length = this.autoFilter.length
    this.tempFilterOptions.selectAll = false
    this.setSelectedItems()
    this.toggleActionButtons()
  }

  setSelectedItems() {
    if (this.autoFilter.config.multiSelect) {
      if (this.selectedItems.length) {
        this.tempFilterOptions.data.forEach((val) => {
          const selectedItem: any[] = this.selectedItems.filter(
            (item) => item.displayName === val.displayName
          )
          if (selectedItem.length) {
            val.selected = selectedItem[0].selected
          }
        })
      } else {
        this.tempFilterOptions.data.forEach((val) => {
          val.selected = false
        })
      }
    }
  }

  getSourceData() {
    const paramsValueObj = {}
    const formParams
      = this.filterArgs.displayName
        ? this.autoFilter.config.paramsKey
          ? (paramsValueObj[this.autoFilter.config.paramsKey] = this.filterArgs.displayName, paramsValueObj)
          : paramsValueObj
        : paramsValueObj
    const requestPayload = {
      targetedType: this.autoFilter.config.targetedType,
      servicePath: this.autoFilter.config.servicePath,
      formParams
    }
    this.filterElementService.getFilterData(requestPayload).subscribe((val) => {
      if (val && val.result) {
        const dimResult = val.result
        this.tempFilterOptions.length = val.result.length
        this.tempFilterOptions.data =
          val.result instanceof Object
            ? this.tempFilterOptions.length
              ? this.convertToLocalFormat(dimResult, true)
              : []
            : []
        if (!this.filterArgs.displayName.length) {
          this.autoFilter.data = JSON.parse(
            JSON.stringify(this.tempFilterOptions.data)
          )
          this.autoFilter.length = this.tempFilterOptions.length
          this.disableSelectAll = dimResult.length ? false : true
        } else {
          this.disableSelectAll = dimResult.length > 500 ? true : false
        }
        this.emitDefaultSelection()
      }
    })
  }
  getStaticData() {
    const requestPayload = {
      targetedType: this.configuration.filterConfig.targetedType,
      formParams: {
        count: 100,
      },
      servicePath: this.configuration.filterConfig.servicePath,
    }
    requestPayload.formParams['tenantId'] = this.configuration.defaultParameter ? this.configuration.defaultParameter['tenantId'] || this.tenantId : this.tenantId
    requestPayload.formParams = {
      ...this.configuration.defaultParameter,
      ...requestPayload.formParams,
    }
    if (this.filterArgs.displayName) {
      requestPayload['formParams']['key'] = this.filterArgs.displayName
    }
    if (this.filterParameters !== null) {
      requestPayload['formParams'][
        this.filterParameters['key']
      ] = this.filterParameters['value']
    }
    this.filterElementService.getFilterData(requestPayload).subscribe((val) => {
      if (val && val.result) {
        const value = val.result.suggestions || val.result
        this.configuration.data = this.convertToLocalFormat(value, true)
      }
    })
    this.selectedItem(this.selectedItemVal)

  }
  getFilterData() {
    // console.log(this.configuration)
    const requestPayload = {
      targetedType: this.autoFilter.config.targetedType,
      formParams: {
        count: 100,
      },
      servicePath: this.autoFilter.config.servicePath,
    }
    requestPayload.formParams['tenantId'] = this.configuration.defaultParameter ? this.configuration.defaultParameter['tenantId'] || this.tenantId : this.tenantId
    requestPayload.formParams = {
      ...this.configuration.defaultParameter,
      ...requestPayload.formParams,
    }
    if (this.filterArgs.displayName) {
      requestPayload['formParams']['key'] = this.filterArgs.displayName
    }
    if (this.filterParameters !== null) {
      // console.log(this.filterParameters);
      requestPayload['formParams'][
        this.filterParameters['key']
      ] = this.filterParameters['value']
    }
    // console.log(this.configuration.defaultParameter, this.filterParameters);
    this.filterElementService.getFilterData(requestPayload).subscribe((val) => {
      if (val && val.result) {
        const dimResult = val.result
        this.tempFilterOptions.length = dimResult.numberOfHits
        this.tempFilterOptions.data =
          val.result instanceof Object
            ? dimResult.numberOfHits
              ? this.convertToLocalFormat(dimResult.suggestions)
              : []
            : []
        if (!this.filterArgs.displayName.length) {
          if (this.configuration.selectAllDefault) {
            this.tempFilterOptions.selectAll = true
            this.tempFilterOptions.data.forEach((item) => (item.selected = true))
            this.selectedItems = this.tempFilterOptions.data
            this.selectedValues = this.selectedItems.map((item) => item.displayName)
            this.filterSelectEmitObj = {
              label: this.configuration['label'],
              configuration: this.configuration,
              selectedItems: ['ALL'],
              type: this.type,
              actionType: 'add',
            }
            this.filterSelect.emit(this.filterSelectEmitObj)
            this.propagateChange(this.selectedValues)
          }
          this.autoFilter.data = JSON.parse(
            JSON.stringify(this.tempFilterOptions.data)
          )
          this.autoFilter.length = this.tempFilterOptions.length
          this.disableSelectAll = dimResult.numberOfHits ? false : true
        } else {
          this.disableSelectAll = dimResult.numberOfHits > 500 ? true : false
        }

        this.emitDefaultSelection()
      }
    })
  }

  emitDefaultSelection() {
    if (
      this.autoFilter.config.hasOwnProperty('defaultItemSelection') &&
      this.autoFilter.config['defaultItemSelection']
    ) {
      // let onDefaultSelectionEmitObj = null;
      switch (this.autoFilter.config.multiSelect) {
        case true:
          this.filterSelectEmitObj = {
            label: this.configuration['label'],
            configuration: this.configuration,
            selectedItems: this.selectedValues,
            type: this.type,
            actionType: 'selection',
          }
          this.onDefaultSelection.emit(this.filterSelectEmitObj)
          this.propagateChange(this.selectedValues)
          break
        case false:
          // this.configuration.hideLabel = this.configuration.specialVersion && this.selectedValue ? true : false
          this.filterSelectEmitObj = {
            label: this.configuration['label'],
            configuration: this.configuration,
            selectedItem: this.selectedItemVal,
            type: this.type,
            actionType: 'selection',
          }
          this.onDefaultSelection.emit(this.filterSelectEmitObj)
          this.propagateChange(this.selectedValues)
          break
      }

      this.autoFilter.config['defaultItemSelection'] = false
    }
  }

  setDefaultSelection(selectedItem) {
    if (
      this.autoFilter.config.hasOwnProperty('defaultItemSelection') &&
      this.autoFilter.config['defaultItemSelection']
    ) {
      switch (this.autoFilter.config.multiSelect) {
        case true:
          this.selectedItems.push({
            val: selectedItem,
            displayName: selectedItem,
            selected: true,
          })
          this.selectedValues = this.selectedItems.map(
            (item) => item.displayName
          )
          break
        case false:
          if (this.configuration.specialVersion) {
            this.selectedValue = selectedItem[this.autoFilter.config.valueKey]
            this.selectedItemVal = {
              val: selectedItem[this.autoFilter.config.idKey],
              displayName: selectedItem[this.autoFilter.config.valueKey]
            }
            // this.configuration.hideLabel = this.selectedValue ? true : false
          } else {
            this.selectedValue = selectedItem
            this.selectedItemVal = {
              val: selectedItem,
              displayName: selectedItem,
            }
          }
      }
    }
  }

  convertToLocalFormat(data, specialVersion = false) {
    const localArray = []
    const defaultSelectedItems
      = (this.autoFilter.config.hasOwnProperty('defaultSelectedItem') &&
        this.autoFilter.config['defaultSelectedItem'])
        ? this.autoFilter.config.defaultSelectedItem
        : data[0]

    if (defaultSelectedItems instanceof Array) {
      defaultSelectedItems.forEach(item => {
        this.setDefaultSelection(item)
      })
    } else {
      this.setDefaultSelection(defaultSelectedItems)
    }

    if (!specialVersion) {
      switch (this.autoFilter.config.multiSelect) {
        case true:
          if (this.selectedItems) {
            data.forEach((val) => {
              const selectedItem: any[] = this.selectedItems.filter(
                (item) => item.displayName === val
              )
              const isSelected = selectedItem.length ? true : false
              localArray.push({
                val,
                displayName: val,
                selected: isSelected,
              })
            })
          } else {
            data.forEach((val) => {
              localArray.push({
                val,
                displayName: val,
                selected: false,
              })
            })
          }
          break
        case false:
          data.forEach((val) => {
            localArray.push({
              val,
              displayName: val,
            })
          })
          break
      }
    } else {
      data.forEach((obj) => {
        if (typeof obj === 'string') {
          localArray.push({
            val: obj,
            displayName: obj
          })
        } else {
          localArray.push({
            val: obj[this.autoFilter.config.idKey],
            displayName: obj[this.autoFilter.config.valueKey]
          })
        }
      })
    }

    return localArray
  }

  selectedItem(item) {
    if (this.configuration.type !== 'multiselect') {
      this.selectedValue = item.displayName
    }

    if (this.configuration.specialVersion) {
      this.configuration.hideLabel = this.selectedValue ? true : false
    }


    else if (this.configuration && this.configuration.defaultSelectedView && this.configuration.defaultSelectedView.val && this.configuration.defaultSelectedView.val.length === 0) {
      this.configuration.isSelectAll = true;
      this.configuration.data.map(x => x.selected = true);
    }
    this.userTag[0].displayName = item.displayName;
    this.filterSelectEmitObj = {
      label: this.configuration['label'],
      selectedItem: item,
      type: this.type,
      actionType: 'add',
      configuration: this.configuration,
    }
    this.filterSelect.emit(this.filterSelectEmitObj);

    this.filterArgs.displayName = '';
    this.propagateChange(item.val)
  }

  removeItem($event) {
    this.selectedValue = null
    // this.elementView.nativeElement.classList.remove('open');
    this.filterSelectEmitObj = {
      label: this.configuration['label'],
      type: this.type,
      actionType: 'remove',
      configuration: this.configuration,
    }
    if (this.configuration.specialVersion) {
      this.configuration.hideLabel = this.selectedValue ? true : false
    }

    this.filterSelect.emit(this.filterSelectEmitObj)
    this.propagateChange(null)
    $event.stopPropagation()
  }

  removeItemFromList($event, selectedItem) {
    $event.stopPropagation()
    this.filterSelectEmitObj = {
      label: this.configuration['label'],
      type: this.type,
      actionType: 'remove',
      selectedItem,
    }
    this.filterSelect.emit(this.filterSelectEmitObj)
    this.propagateChange(null)
  }

  inputClear($event) {
    $event.stopPropagation()
    this.filterArgs.displayName = ''
    if (this.configuration.type === 'multiselectvirtualscroll') {
      this.tempFilterOptions.data = this.virtualDropdown
    }
  }

  onClickSelectAll($event) {
    $event.stopPropagation()
    this.elementView.nativeElement.classList.add('open')
    if (!this.filterArgs.displayName.length) {
      this.tempFilterOptions.selectAll = true
      this.tempFilterOptions.data.forEach((item) => (item.selected = true))
      this.selectedItems = []
      this.selectedValues = []
    } else {
    }
    this.toggleActionButtons()
  }

  toggleActionButtons() {
    this.disableClearAll = this.tempFilterOptions.selectAll
      ? false
      : this.setSelectedItems.length
        ? false
        : true
  }

  onClearAll($event) {
    $event.stopPropagation()
    this.elementView.nativeElement.classList.add('open')
    if (!this.filterArgs.displayName.length) {
      this.tempFilterOptions.selectAll = false
      this.tempFilterOptions.data.forEach((item) => (item.selected = false))
      this.selectedItems = []
      this.selectedValues = []
      this.filterSelectEmitObj = {
        label: this.configuration['label'],
        configuration: this.configuration,
        selectedItems: this.selectedItems,
        type: this.type,
        actionType: 'add',
      }
      this.filterSelect.emit(this.filterSelectEmitObj)
      this.propagateChange(this.selectedItems)
    }
    this.toggleActionButtons()
  }

  onCheckAll($event) {
    $event.stopPropagation()
    this.elementView.nativeElement.classList.add('open')
    if (!this.filterArgs.displayName.length) {
      this.tempFilterOptions.selectAll = true
      this.tempFilterOptions.data.forEach((item) => (item.selected = true))
      this.selectedItems = this.tempFilterOptions.data
      this.selectedValues = this.selectedItems.map((item) => item.displayName)
      this.filterSelectEmitObj = {
        label: this.configuration['label'],
        configuration: this.configuration,
        selectedItems: ['ALL'],
        type: this.type,
        actionType: 'add',
      }
      this.filterSelect.emit(this.filterSelectEmitObj)
      this.propagateChange(this.selectedValues)
    }
    this.toggleActionButtons()
  }

  onAutoSelectItem($event, selectedItem) {
    this.elementView.nativeElement.classList.add('open')
    $event.stopPropagation()
    this.totalItems = this.autoFilter.data.filter(
      (filterItem: any) => filterItem.selected
    )

    if (selectedItem.selected) {
      this.selectedItems.push(selectedItem)
    } else {
      this.tempFilterOptions.selectAll = false
      this.selectedItems = this.selectedItems.filter(
        (filterItem) => filterItem.displayName !== selectedItem.displayName
      )
    }

    this.selectedValues = this.selectedItems.map((item) => item.displayName)
    this.filterSelectEmitObj = {
      label: this.configuration['label'],
      configuration: this.configuration,
      selectedItems: this.selectedValues,
      type: this.type,
      actionType: 'add',
    }
    this.filterSelect.emit(this.filterSelectEmitObj)
    this.propagateChange(this.selectedValues)
  }

  onVirtualSelectItem($event, selectedItem) {
    this.elementView.nativeElement.classList.add('open')
    $event.stopPropagation()

    if (selectedItem.selected) {
      this.selectedItems.push(selectedItem)
    } else {
      this.tempFilterOptions.selectAll = false
      this.selectedItems = this.selectedItems.filter(
        (filterItem) => filterItem.displayName !== selectedItem.displayName
      )
    }
    if (this.showAll) {
      this.configuration.isSelectAll =
        this.selectedItems.length === this.configuration.data.length ? true : false
    }

    this.selectedValues = this.selectedItems.map((item) => item.displayName)
    if (!this.configuration.isSelectAll) {
      this.filterSelectEmitObj = {
        label: this.configuration['label'],
        configuration: this.configuration,
        selectedItems: this.selectedItems,
        type: this.type,
        actionType: 'selection',
      }
      this.filterSelect.emit(this.filterSelectEmitObj)
      this.propagateChange(this.selectedItems.map(item => item.displayName))
    } else {
      this.filterSelectEmitObj = {
        label: this.configuration['label'],
        configuration: this.configuration,
        selectedItems: [],
        type: this.type,
        actionType: 'selection',
      }
      this.filterSelect.emit(this.filterSelectEmitObj)
      this.propagateChange(this.filterSelectEmitObj.selectedItems)
    }
  }

  onSelectItem($event, selectedItem) {
    this.elementView.nativeElement.classList.add('open')
    $event.stopPropagation()
    const dropdownData =
      this.configuration.type === 'multiselectvirtualscroll'
        ? JSON.parse(JSON.stringify(this.tempFilterOptions.data))
        : JSON.parse(JSON.stringify(this.configuration.data))

    const selectedItems = dropdownData.filter(
      (filterItem: any) => filterItem.selected
    )
    this.selectedValues = selectedItems.map((item) => item.displayName)

    if (this.showAll) {
      if (this.configuration.type === 'multiselectvirtualscroll') {
        this.configuration.isSelectAll =
          selectedItems.length === this.configuration.data.length ? true : false
      } else {
        this.configuration.isSelectAll =
          selectedItems.length === dropdownData.length ? true : false
      }
    }

    // console.log(selectedItems.map(item => item.displayName))
    if (!this.configuration.isSelectAll) {
      this.filterSelectEmitObj = {
        label: this.configuration['label'],
        configuration: this.configuration,
        selectedItems,
        type: this.type,
        actionType: 'selection',
      }
      this.filterSelect.emit(this.filterSelectEmitObj)
      this.propagateChange(selectedItems.map(item => item.displayName))
    } else {
      this.filterSelectEmitObj = {
        label: this.configuration['label'],
        configuration: this.configuration,
        selectedItems: [],
        type: this.type,
        actionType: 'selection',
      }
      this.filterSelect.emit(this.filterSelectEmitObj)
      this.propagateChange(this.filterSelectEmitObj.selectedItems)
    }
  }

  onVirtualSelectAllClick() {
    this.elementView.nativeElement.classList.add('open')
    const dropdownData = this.virtualDropdown

    dropdownData.forEach((item) => {
      item.selected = this.configuration.isSelectAll
    })
    this.selectedItems = this.virtualDropdown.filter(
      (filterItem: any) => filterItem.selected
    )
    this.selectedValues = this.selectedItems.map((item) => item.displayName)
    this.filterSelectEmitObj = {
      label: this.configuration['label'],
      configuration: this.configuration,
      selectedItems: [],
      type: this.type,
      actionType: 'selection',
    }
    this.filterSelect.emit(this.filterSelectEmitObj)
    this.propagateChange(this.filterSelectEmitObj.selectedItems)
  }

  onSelectAllClick() {
    this.elementView.nativeElement.classList.add('open')
    const dropdownData =
      this.configuration.type === 'multiselectvirtualscroll'
        ? this.tempFilterOptions.data
        : this.configuration.data

    dropdownData.forEach((item) => {
      item.selected = this.configuration.isSelectAll
    })
    this.configuration.data.forEach((item) => {
      item.selected = this.configuration.isSelectAll
    })
    const selectedItems = dropdownData.filter(
      (filterItem: any) => filterItem.selected
    )
    this.selectedValues = selectedItems.map((item) => item.displayName)
    this.filterSelectEmitObj = {
      label: this.configuration['label'],
      configuration: this.configuration,
      selectedItems: [],
      type: this.type,
      actionType: 'selection',
    }
    this.filterSelect.emit(this.filterSelectEmitObj)
    this.propagateChange(this.filterSelectEmitObj.selectedItems)
  }

  clearSelection(event) {
    event.stopPropagation()
    const selectedItems = []
    const defaultSelectedView = this.configuration.defaultSelectedView
    const dropdownData =
      this.configuration.type === 'multiselectvirtualscroll'
        ? this.tempFilterOptions.data
        : this.configuration.data
    if (this.showAll) {
      this.configuration.isSelectAll = true
      dropdownData.forEach((item) => {
        item.selected = this.configuration.isSelectAll
      })
    } else {
      if (defaultSelectedView) {
        selectedItems.push(defaultSelectedView)
        dropdownData.forEach((item) => {
          item.selected =
            item.displayName === defaultSelectedView.displayName ? true : false
        })
      } else {
        dropdownData.forEach(item => item.selected = false)
      }
      this.selectedValues = selectedItems.map((item) => item.displayName)
    }
    this.filterSelectEmitObj = {
      label: this.configuration['label'],
      configuration: this.configuration,
      selectedItems,
      type: this.type,
      actionType: 'selection',
    }
    this.filterSelect.emit(this.filterSelectEmitObj)
    this.propagateChange(selectedItems)
  }

  clearVirtualSelection(event) {
    event.stopPropagation()
    const selectedItems = []
    const defaultSelectedView = this.configuration.defaultSelectedView
    this.virtualDropdown = this.tempFilterOptions.data = JSON.parse(
      JSON.stringify(this.configuration.data)
    )
    const dropdownData = this.virtualDropdown
    if (this.showAll) {
      this.configuration.isSelectAll = true
      dropdownData.forEach((item) => {
        item.selected = this.configuration.isSelectAll
      })
      this.selectedItems = dropdownData.filter(
        (filterItem: any) => filterItem.selected
      )
    } else {
      if (defaultSelectedView) {
        dropdownData.forEach((item) => {
          item.selected = false
          if (item.displayName === defaultSelectedView.displayName) {
            item.selected = true
            selectedItems.push(item)
          }
        })
      } else {
        dropdownData.forEach(item => item.selected = false)
      }
      this.selectedValues = selectedItems.map((item) => item.displayName)
      this.selectedItems = selectedItems
    }
    this.filterSelectEmitObj = {
      label: this.configuration['label'],
      configuration: this.configuration,
      selectedItems,
      type: this.type,
      actionType: 'selection',
    }
    this.filterSelect.emit(this.filterSelectEmitObj)
    this.propagateChange(selectedItems)
  }

  onHoverListItem($event, item) {
    if (!this.configuration.hideTooltip) {
      this.tooltipInformation = item
      this.tooltipInformationIncludeKeys = Object.keys(item.val.includeCriteria)
      this.tooltipInformationExcludeKeys = Object.keys(item.val.excludeCriteria)
      this.tooltipInformationOptimizeKeys = Object.keys(
        item.val.optimizeCriteria
      )
      const topIndex = this.tooltipInformationOptimizeKeys.indexOf('top')
      const sortTypeIndex = this.tooltipInformationOptimizeKeys.indexOf(
        'sort_type'
      )
      if (topIndex !== -1) {
        this.tooltipInformationOptimizeKeys.splice(topIndex, 1)
      }
      if (sortTypeIndex !== -1) {
        this.tooltipInformationOptimizeKeys.splice(sortTypeIndex, 1)
      }
      const el = this.customTooltip.nativeElement
      let positionStyle = 'left:600px'
      if (this.configuration.right) {
        positionStyle = `right:${this.configuration.right}`
      }
      el.setAttribute(
        'style',
        `${positionStyle}; top: ${$event.pageY - 20}px; display: block;`
      )
      if (this.customTooltip.nativeElement.clientHeight + $event.pageY > Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)) {
        const offset = this.customTooltip.nativeElement.clientHeight + $event.pageY - Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
        el.setAttribute(
          'style',
          `${positionStyle}; top: ${$event.pageY - 20 - offset}px; display: block;`
        );
      }
    } else if (this.configuration.parentComponent && this.configuration.parentComponent === 'leva-quick-filter') {
      this.optionHover.emit({ event: $event, item })
    }
  }

  onOutListItem($event) {
    const el = this.customTooltip.nativeElement
    el.removeAttribute('style')
    if (this.configuration.parentComponent && this.configuration.parentComponent === 'leva-quick-filter') {
      this.onOptionOut.emit($event)
    }
  }

  public onClickDropdown(event) {
    if (window.innerHeight - event.clientY > dropdownHeight) {
      this.showDropUp = false;
      if (this.configuration.showFixedDropdown) {
        let dropdownEl = this.getDropdownEl(event.target.parentElement)
        const boundary = dropdownEl.parentElement.getBoundingClientRect()
        dropdownEl = dropdownEl.parentElement.querySelector('.dropdown-menu')
        dropdownEl.style.cssText = `
          position: fixed;
          left: ${boundary.x}px;
          top: ${boundary.y + 28}px;
          width: ${boundary.width}px;
        `
      }
    } else {
      this.showDropUp = true;
      if (this.configuration.showFixedDropdown) {
        let dropdownEl = this.getDropdownEl(event.target.parentElement)
        const boundary = dropdownEl.parentElement.getBoundingClientRect()
        dropdownEl = dropdownEl.parentElement.querySelector('.dropdown-menu')
        dropdownEl.style.cssText = `
          position: fixed;
          left: ${boundary.x}px;
          top: auto;
          bottom: ${window.innerHeight - boundary.y}px;
          width: ${boundary.width}px;
        `
      }
    }
  }

  getDropdownEl(dropdownEl) {
    while (!dropdownEl.parentElement.querySelector('.dropdown-menu')) {
      dropdownEl = dropdownEl.parentElement
    }
    return dropdownEl
  }

  clearAutoSelection(event) {
    event.stopPropagation()
    this.selectedItems = []
    this.selectedValues = []
    this.tempFilterOptions.selectAll = false
    this.tempFilterOptions.data.forEach((item) => (item.selected = false))
    this.elementView.nativeElement.classList.add('open')
    this.filterSelectEmitObj = {
      label: this.configuration['label'],
      configuration: this.configuration,
      selectedItems: [],
      type: this.type,
      actionType: 'add',
    }
    this.filterSelect.emit(this.filterSelectEmitObj)
    this.propagateChange(this.filterSelectEmitObj.selectedItems)
  }

  /*  ControlValueAccessorFunctionImplementation */
  writeValue(value: any) {
    return value
  }

  registerOnChange(fn) {
    this.propagateChange = fn
  }

  registerOnTouched() { }
}
