import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-community/core';
@Component({
  selector: 'child-cell',
  templateUrl: './content-auto-search.component.html',
  styleUrls: ['./content-auto-search.component.sass']
})
export class ContentAutoSearchComponent {
  public params: ICellRendererParams;

  public agInit(params: any): void {
    this.params = params;
  }

  public stopEditing() {
    this.params.api.stopEditing(false);
  }

  public onValueChanged(e) {
    // this.params.api.updateRowData(this.params.data);
    this.params.node.setDataValue(this.params.colDef.field, this.params.value);
    this.stopEditing();
  }
}
