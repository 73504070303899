import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';

export function fileExtenssionValidator(validation: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!!!control.value) { return null };
    const fileNameWithExtenssion = control.value.match(
      /\.([^\./\?\#]+)($|\?|\#)/
    );
    const ext =
      !!fileNameWithExtenssion &&
      fileNameWithExtenssion instanceof Array &&
      fileNameWithExtenssion.length > 1
        ? fileNameWithExtenssion[1]
        : null;

    if (!!ext && validation.indexOf(ext.toLowerCase()) === -1) {
      return { allowedextensions: true };
    }
    return null;
  };

}
export function numberValidator(validation: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    console.log(/\d/.test(control.value));
    if (!!!control.value) { return null };
    return {number: /\d/.test(control.value)}
  }
}

export function ValidateNumber(fieldControl: FormControl) {
  if (!fieldControl.value) { return null };
  return /\d/.test(fieldControl.value) ? null : { number: true };
}

export function ValidateUpperCase(fieldControl: FormControl) {
  if (!fieldControl.value) { return null };
  return /[A-Z]/.test(fieldControl.value) ? null : { upperCase: true };
}

export function ValidateLowerCase(fieldControl: FormControl) {
  if (!fieldControl.value) { return null };
  return /[a-z]/.test(fieldControl.value) ? null : { lowerCase: true };
}

export function ValidateSpecialCharacter(fieldControl: FormControl) {
  if (!fieldControl.value) { return null };
  return /[~`!@#$%^&*<>?/,.:;'"()\-+=|{}\[\]\\\_]/.test(fieldControl.value) ? null : { specialChar: true }
}

export function ValidateNoSpaces(fieldControl: FormControl) {
  if((fieldControl.value as string).indexOf(' ') >= 0){
    return {noWhiteSpace: true}
  }
  return null;
}

