import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  Input,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { RfqConfigService } from '../../shared/rfq-config.service';
import {
  TYPES,
  DEPENDENCY_CONDITIONS,
  DROPDOWN_VALUES_FOR_ADD_NEW_FIELD_PAGE,
  TOASTER_MSG_TEMPLATE_FORM_INCOMPLETE
} from '../../shared/constants';
import { dateKeys } from '../../shared/validationUtility';
import { DateUtils } from '../../../common/utills/DateUtils.service';
import { ValidationsComponent } from './validations/validations.component';
import { ToastService } from 'app/modules/srfq/shared/toaster.service';
import { Subject, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'add-new-template-feild',
  templateUrl: './add-new-template-feild.component.html',
  styleUrls: ['./add-new-template-feild.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class addNewTemplateFeild implements OnInit, OnDestroy {
  private subject$: Subject<any> = new Subject();
  constructor(
    private rfqConfigService: RfqConfigService,
    private _cdr: ChangeDetectorRef,
    private toast: ToastService
  ) {
    this.refSetConstantsSubscription = this.rfqConfigService
      .getRefSetConstants(DROPDOWN_VALUES_FOR_ADD_NEW_FIELD_PAGE)
      .subscribe(res => {
        const dropdownRefSet = {};
        if (res && !res.error && res instanceof Array) {
          res.map(dropdown => {
            dropdownRefSet[dropdown.refSetKey] = dropdown.refSetTerms;
          });
          this.allDropdowns = dropdownRefSet;
          this._cdr.detectChanges();
        }
        this.allDropdowns['HEADER_DEV_TYP'] &&
          this.allDropdowns['HEADER_DEV_TYP'].reverse();
      });
  }
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  @Input() templatefieldData: any;
  @Input() isEditingField = false;
  @ViewChild(ValidationsComponent, { static: false })
  validationsComponent: ValidationsComponent;
  allDropdowns: any = {};
  public customFieldToBeAdded;
  existingDBFields = [
    {
      refTermKey: '0',
      refTermDisplayValue: 'New custom field',
      refTermActualValue: ''
    }
  ];
  permissions;
  isEditablePermission = false;
  saveButtonText = 'Add new field';
  templateId = 0;
  headerType;
  public reservedColumnList = [];
  @Output() onNewFieldAdded: EventEmitter<Object> = new EventEmitter<Object>();

  fieldDropdownValues = [];
  dependencyFeildsConditions = DEPENDENCY_CONDITIONS;
  otherConfiguration = { maxQuarters: 4 };
  fieldInfo;
  inputFieldSubscription: Subscription;
  refSetConstantsSubscription: Subscription;
  existingDBFieldsSubscription: Subscription;
  templateIdSubscription: Subscription;
  FieldDetailsSubscription: Subscription;

  formValidationFunctions = {
    metaInfo: this.checkTemplateNameInValid,
    formattingOptions: this.checkTemplateFieldsFormat,
    calculation: this.checkTemplateCalculation,
    dependency: this.checkTemplateDependency,
    deviations: this.checkTemplateDeviations,
    otherConfiguration: this.checkTemplateOtherConfiguration
  };
  formCorrection = false;
  // @Riya : Hiding dependency for temporary
  hideDependency = false;
  initialFormData: any;
  isMappingExistingField = false;
  errorClass;

  getExistingDbFields() {
    this.existingDBFieldsSubscription = this.rfqConfigService
      .getExistingDBFields(this.templateId)
      .subscribe(res => {
        if (!res || !(res instanceof Array)) { return; }
        res.map(dropdownValue => {
          dropdownValue['refTermDisplayValue'] = dropdownValue[
            'refTermActualValue'
          ] = dropdownValue['headerName'];
        });

        this.existingDBFields = this.existingDBFields.concat(
          res && res.length ? res : []
        );
      });
  }
  ngOnInit() {
    this.formCorrection = false;
    this.templateIdSubscription = this.rfqConfigService
      .getTemplateId()
      .subscribe(id => {
        this.templateId = id;
        this.getDropdownValuesForfield();
        this.getExistingDbFields();
      });
    if (!this.isEditingField) {
      this.resetCustomFieldData();
    } else {
      this.setCustomFieldData(this.templatefieldData);
    }
    this.initialFormData = _.cloneDeep(this.customFieldToBeAdded);
  }

  getDropdownValuesForfield() {
    this.inputFieldSubscription = this.rfqConfigService
      .getDropdownValuesForfield(this.templateId)
      .subscribe(res => {
        if (res && !res.error && res instanceof Array) {
          res.map(dropdownValue => {
            dropdownValue['refTermDisplayValue'] = dropdownValue[
              'refTermActualValue'
            ] =
              (dropdownValue.metaInfo &&
                dropdownValue.metaInfo.custDisplayName) ||
              '';
          });
        }
        const defaultInput = {
          refTermKey: '0',
          refTermDisplayValue: 'select field',
          refTermActualValue: ''
        };
        if (res instanceof Array) {
          res.unshift(defaultInput);
          this.fieldDropdownValues = res;
        } else {
          this.fieldDropdownValues = [];
        }
        this._cdr.detectChanges();
      });
  }

  prepareOtherConfigurationObject(FieldObject) {
    return {
      timeSeries:
        FieldObject &&
        FieldObject['metaInfo'] &&
        FieldObject['metaInfo'].timeSeries,
      applyFilter:
        FieldObject &&
        FieldObject['metaInfo'] &&
        FieldObject['metaInfo'].applyFilter,
      unitCostCol:
        FieldObject &&
        FieldObject['metaInfo'] &&
        FieldObject['metaInfo'].unitCostCol,
      quarters:
        FieldObject &&
        FieldObject['metaInfo'] &&
        FieldObject['metaInfo'].quarters,
      futureQuarter:
        FieldObject &&
        FieldObject['metaInfo'] &&
        FieldObject['metaInfo'].futureQuarter,
      maxQuarters:
        FieldObject &&
        FieldObject['metaInfo'] &&
        FieldObject['metaInfo'].maxQuarters,
      postRfqUpdate:
        FieldObject &&
        FieldObject['metaInfo'] &&
        FieldObject['metaInfo'].postRfqUpdate,
      enablePostRfqUpdate:
        FieldObject &&
        FieldObject['metaInfo'] &&
        FieldObject['metaInfo'].enablePostRfqUpdate
    };
  }
  prepareValidationsArray(validations, type) {
    validations.map(validation => {
      validation.info = validation.info
        ? JSON.parse(validation.info)
        : validation.info;
    });
    if (type !== TYPES.DATE) {
      return validations;
    }
    for (let i = 0; i < validations.length; i++) {
      if (
        validations[i].type === dateKeys.lessThan ||
        validations[i].type === dateKeys.greaterThan
      ) {
        validations[i].info.value = DateUtils.getMomentTimeInCurrentTimeZone(
          validations[i].info.value
        );
      } else {
        validations[i].info.min = DateUtils.getMomentTimeInCurrentTimeZone(
          validations[i].info.min
        );
        validations[i].info.max = DateUtils.getMomentTimeInCurrentTimeZone(
          validations[i].info.max
        );
      }
    }
  }
  makeDateBackendCompatible(validations) {
    for (let i = 0; i < validations.length; i++) {
      if (
        validations[i].type === dateKeys.lessThan ||
        validations[i].type === dateKeys.greaterThan
      ) {
        validations[i].info.value = new Date(
          validations[i].info.value
        ).getTime();
      } else {
        validations[i].info.min = new Date(validations[i].info.min).getTime();
        validations[i].info.max = new Date(validations[i].info.max).getTime();
      }
    }
  }
  getDBfieldDetails(tmplHeaderId) {
    if (!tmplHeaderId) {
      this.isMappingExistingField = false;
      this.resetCustomFieldData();
      return;
    }
    this.FieldDetailsSubscription = this.rfqConfigService
      .getFieldDetails(tmplHeaderId)
      .subscribe(res => {
        // this.isEditingField = true;
        this.isMappingExistingField = true;
        this.setCustomFieldData(res);
      });
  }

  fieldChange(event) {
    this.getDBfieldDetails(event.tmplHeaderId);
  }

  resetCustomFieldData() {
    this.customFieldToBeAdded = this.getResetCustomField();
    this.permissions = null;
    this.isEditablePermission = false;
  }

  setCustomFieldData(data) {
    this.customFieldToBeAdded = _.cloneDeep(data);
    this.headerType = data.headerType;
    this.permissions = data.permissions;
    this.checkPermissionEditable();
    this.otherConfiguration = this.prepareOtherConfigurationObject(data);
    this.prepareValidationsArray(
      this.customFieldToBeAdded.validations,
      data.metaInfo['datatype']
    );
    this._cdr.detectChanges();
  }

  getResetCustomField() {
    return {
      metaInfo: {
        datatype: 'TEXT'
      },
      formattingOptions: {
        customer: {
          type: 'TEXT',
          adhocCellType: '',
          adhocEligible: false,
          toolTip: ''
        },
        supplier: {
          type: 'TEXT',
          adhocCellType: '',
          adhocEligible: false,
          toolTip: ''
        }
      },
      calculation: {},
      dependency: {
        type: 'BLANK',
        context: 'supplier'
      },
      deviations: {
        type: 'NOTREQUIRED'
      },
      validations: []
    };
  }

  formValuesChanged(key, value) {
    if (key === 'otherConfiguration') {
      for (const property in this.otherConfiguration) {
        this.customFieldToBeAdded['metaInfo'][
          property
        ] = this.otherConfiguration[property];
      }
    }
    if (this.customFieldToBeAdded['metaInfo'].fieldType === 'read-only') {
      if (!this.reservedColumnList.length) {
        this.getDropdownValuesBasedOnInputFieldType();
      }
    }
    if (
      this.formCorrection &&
      this.formValidationFunctions &&
      this.formValidationFunctions[key]
    ) {
      this.formValidationFunctions[key].call(this);
    }
  }

  private getDropdownValuesBasedOnInputFieldType() {
    this.rfqConfigService
      .getReservedColumnsData()
      .pipe(takeUntil(this.subject$))
      .subscribe(res => {
        if (res && Object.keys(res).length) {
          this.reservedColumnList = [];
          this.reservedColumnList.push({
            refTermKey: '0',
            refTermDisplayValue: 'select column',
            refTermActualValue: ''
          });
          for (const column in res) {
            if (Object.prototype.hasOwnProperty.call(res, column)) {
              this.reservedColumnList.push({
                refTermKey: 0,
                refTermDisplayValue: `${res[column]}(${column})`,
                refTermActualValue: column
              })
            }
          }
        }
      });
  }

  onFieldTypeChange() { }

  permissionChange(evt) {
    if (!evt) {
      return;
    }
    this.permissions = evt;
    this.checkPermissionEditable();
    if (this.formCorrection) {
      this.checkTemplatePermissions();
    }
  }

  checkPermissionEditable() {
    if (
      Object.values(this.permissions.customerPrivileges).indexOf('EDITABLE') >=
      0 ||
      Object.values(this.permissions.supplierPrivileges).indexOf('EDITABLE') >=
      0 ||
      Object.values(this.permissions.customerPrivileges).indexOf('UPDATABLE') >=
      0 ||
      Object.values(this.permissions.supplierPrivileges).indexOf('UPDATABLE') >=
      0
    ) {
      this.isEditablePermission = true;
    } else {
      this.isEditablePermission = false;
    }
  }

  getNewFieldFormData() {
    let data,
      valid = this.validateNewFieldFormData();
    this.formCorrection = true;
    if (valid) {
      this.formCorrection = false;
      this.populatePayloadData();
      data = this.customFieldToBeAdded;
    }
    return data;
  }

  populatePayloadData() {
    this.customFieldToBeAdded['permissions'] = this.permissions;
    if (
      ['INPUT', 'INITIALVALUE'].indexOf(
        this.permissions.defaults.customer.defaulValueOption
      ) === -1
    ) {
      delete this.permissions.defaults.customer.value;
    }
    if (
      ['INPUT', 'INITIALVALUE'].indexOf(
        this.permissions.defaults.supplier.defaulValueOption
      ) === -1
    ) {
      delete this.permissions.defaults.supplier.value;
    }
    this.customFieldToBeAdded['validations'] = this.getUpdatedValidation();
    this.customFieldToBeAdded['custId'] = null;
    this.customFieldToBeAdded['custTmplId'] = this.templateId;

    if (!this.customFieldToBeAdded['headerName']) {
      this.customFieldToBeAdded['headerName'] = this.customFieldToBeAdded[
        'metaInfo'
      ]['custDisplayName'];
    }

    if (!this.customFieldToBeAdded['headerType']) {
      this.customFieldToBeAdded['headerType'] = 'CUSTOM';
    }
  }

  getUpdatedValidation() {
    const formattedValidations = this.customFieldToBeAdded.validations;
    const backEndValidations = [];
    for (let i = 0; i < formattedValidations.length; i++) {
      const validationItem = {
        type: formattedValidations[i].type,
        info: _.cloneDeep(formattedValidations[i].info),
        errorMessage: formattedValidations[i].errorMessage
      };
      backEndValidations.push(validationItem);
    }
    if (this.customFieldToBeAdded['metaInfo']['datatype'] === TYPES.DATE) {
      this.makeDateBackendCompatible(backEndValidations);
    }
    backEndValidations.map(item => {
      item.info =
        item.info instanceof Object && Object.keys(item.info).length > 0
          ? JSON.stringify(item.info)
          : '';
    });
    return backEndValidations;
  }

  validateNewFieldFormData() {
    const nameValid = this.checkTemplateNameInValid();
    const formatValid = this.checkTemplateFieldsFormat();
    const calculationValid = this.checkTemplateCalculation();
    const otherConfigurationValid = this.checkTemplateOtherConfiguration();
    const permissionsValid = this.checkTemplatePermissions();
    let valid =
      nameValid &&
      formatValid &&
      permissionsValid &&
      calculationValid &&
      otherConfigurationValid;
    if (this.isEditablePermission) {
      const dependencyValid = this.checkTemplateDependency();
      const deviationsValid = this.checkTemplateDeviations();
      valid = valid && dependencyValid && deviationsValid;
    }
    const validationsValid = this.checkTemplateFieldsValidation(valid);
    this._cdr.detectChanges();
    valid = valid && validationsValid;
    if (!valid) {
      this.toast.populateToaster('error', TOASTER_MSG_TEMPLATE_FORM_INCOMPLETE);
    }
    return valid;
  }
  checkTemplateNameInValid() {
    this.errorClass = '';
    if (
      this.customFieldToBeAdded['metaInfo'] &&
      this.customFieldToBeAdded['metaInfo']['custDisplayName'] &&
      this.customFieldToBeAdded['metaInfo']['custDisplayName'].trim().length > 0
    ) {
      this._cdr.detectChanges();
      return true;
    }
    this.errorClass = 'error';
    this._cdr.detectChanges();
    return false;
  }

  checkTemplateFieldsValidation(nameValid) {
    // If validations component doesn't exits no error, hence true
    if (!this.validationsComponent) {
      return true;
    }
    const validations = this.validationsComponent.onSave();
    if (!validations) {
      return false;
    } else if (nameValid) {
      this.customFieldToBeAdded.validations = validations;
      return true;
    }
  }

  checkTemplateDependency() {
    let error = false;
    if (
      this.customFieldToBeAdded.dependency.type &&
      this.customFieldToBeAdded.dependency.type !== 'BLANK'
    ) {
      if (!this.customFieldToBeAdded.dependency.condition) {
        this.customFieldToBeAdded.dependency['conditionError'] = true;
        error = true;
      } else {
        delete this.customFieldToBeAdded.dependency['conditionError'];
      }
      if (!this.customFieldToBeAdded.dependency.field) {
        this.customFieldToBeAdded.dependency['fieldError'] = true;
        error = true;
      } else {
        delete this.customFieldToBeAdded.dependency['fieldError'];
      }
      if (
        !this.customFieldToBeAdded.dependency.value ||
        (this.customFieldToBeAdded.dependency.value &&
          this.customFieldToBeAdded.dependency.value.trim().length === 0)
      ) {
        this.customFieldToBeAdded.dependency['valueError'] = true;
        error = true;
      } else {
        delete this.customFieldToBeAdded.dependency['valueError'];
      }
    }
    if (!error) {
      delete this.customFieldToBeAdded.dependency['conditionError'];
      delete this.customFieldToBeAdded.dependency['fieldError'];
      delete this.customFieldToBeAdded.dependency['valueError'];
    }
    this._cdr.detectChanges();
    return !error;
  }

  checkTemplateDeviations() {
    let error = false;
    if (
      this.customFieldToBeAdded.deviations.type &&
      this.customFieldToBeAdded.deviations.type !== 'NOTREQUIRED'
    ) {
      if (!this.customFieldToBeAdded.deviations.increase) {
        this.customFieldToBeAdded.deviations['increaseError'] = true;
        error = true;
      } else {
        delete this.customFieldToBeAdded.deviations['increaseError'];
      }
      if (
        !this.customFieldToBeAdded.deviations.formula ||
        (this.customFieldToBeAdded.deviations.formula &&
          this.customFieldToBeAdded.deviations.formula.trim().length === 0)
      ) {
        this.customFieldToBeAdded.deviations['formulaError'] = true;
        error = true;
      } else {
        delete this.customFieldToBeAdded.deviations['formulaError'];
      }
    }
    if (!error) {
      delete this.customFieldToBeAdded.deviations['increaseError'];
      delete this.customFieldToBeAdded.deviations['formulaError'];
    }
    this._cdr.detectChanges();
    return !error;
  }

  checkTemplateOtherConfiguration() {
    let error = false;
    if (
      this.otherConfiguration['timeSeries'] &&
      (this.otherConfiguration['quarters'] === undefined ||
        isNaN(this.otherConfiguration['quarters']) ||
        Number(this.otherConfiguration['quarters']) < 0)
    ) {
      this.otherConfiguration['timeSeriesValueError'] = true;
      error = true;
    } else if (
      this.otherConfiguration['timeSeries'] &&
      Number(
        this.otherConfiguration['quarters'] >
        this.otherConfiguration['maxQuarters']
      )
    ) {
      this.otherConfiguration['maxQuartersError'] = true;
      error = true;
    } else {
      delete this.otherConfiguration['timeSeriesValueError'];
      delete this.otherConfiguration['maxQuartersError'];
    }
    this._cdr.detectChanges();
    return !error;
  }

  checkTemplateFieldsFormat() {
    let error = false;
    this.setSupplierFormattingOptionsToCustomer(this.customFieldToBeAdded);
    if (this.customFieldToBeAdded.metaInfo.datatype === 'DROPDOWN') {
      if (
        this.customFieldToBeAdded.formattingOptions.supplier &&
        this.customFieldToBeAdded.formattingOptions.supplier.selectedValues &&
        (this.customFieldToBeAdded.formattingOptions.supplier.selectedValues
          .length === 0 ||
          this.customFieldToBeAdded.formattingOptions.supplier.selectedValues[0].trim()
            .length === 0)
      ) {
        error = true;
        this.customFieldToBeAdded.formattingOptions.supplier.error = true;
      } else {
        delete this.customFieldToBeAdded.formattingOptions.supplier['error'];
      }
      if (
        this.customFieldToBeAdded.formattingOptions.customer &&
        this.customFieldToBeAdded.formattingOptions.customer.selectedValues &&
        (this.customFieldToBeAdded.formattingOptions.customer.selectedValues
          .length === 0 ||
          this.customFieldToBeAdded.formattingOptions.customer.selectedValues[0].trim()
            .length === 0)
      ) {
        error = true;
        this.customFieldToBeAdded.formattingOptions.customer.error = true;
      } else {
        delete this.customFieldToBeAdded.formattingOptions.customer['error'];
      }
    }
    if (!error) {
      delete this.customFieldToBeAdded.formattingOptions.customer['error'];
      delete this.customFieldToBeAdded.formattingOptions.supplier['error'];
    }
    this._cdr.detectChanges();
    return !error;
  }

  checkTemplateCalculation() {
    let error = false;
    if (
      this.customFieldToBeAdded.calculation &&
      this.customFieldToBeAdded.calculation.calculationType &&
      this.customFieldToBeAdded.calculation.calculationType === 'CALCULATED' &&
      (!this.customFieldToBeAdded.calculation.formula ||
        (this.customFieldToBeAdded.calculation.formula &&
          this.customFieldToBeAdded.calculation.formula.trim().length === 0))
    ) {
      this.customFieldToBeAdded.calculation.error = true;
      error = true;
    } else {
      delete this.customFieldToBeAdded.calculation['error'];
    }
    this._cdr.detectChanges();
    return !error;
  }

  checkTemplatePermissions() {
    let error = false;
    // if(this.permissions && this.permissions.defaults.customer.defaulValueOption
    //   && (this.permissions.defaults.customer.defaulValueOption === 'INPUT' || this.permissions.defaults.customer.defaulValueOption === 'INITIALVALUE')
    //   && (!this.permissions.defaults.customer.value || this.permissions.defaults.customer.value.trim().length === 0)) {
    //     this.permissions.defaults.customer.error = true;
    //     error = true;
    // } else {
    //   delete this.permissions.defaults.customer["error"];
    // }
    if (
      this.permissions &&
      this.permissions.defaults.supplier.defaulValueOption &&
      ['INPUT', 'INITIALVALUE'].includes(
        this.permissions.defaults.supplier.defaulValueOption
      )
    ) {
      if (!this.permissions.defaults.supplier.value) {
        this.permissions.defaults.supplier.error = true;
        error = true;
      } else if (
        this.customFieldToBeAdded.metaInfo.datatype !== 'NUMERIC' &&
        this.permissions.defaults.supplier.value.trim().length === 0
      ) {
        this.permissions.defaults.supplier.error = true;
        error = true;
      } else if (
        this.customFieldToBeAdded.metaInfo.datatype === 'NUMERIC' &&
        this.permissions.defaults.supplier.value.length === 0
      ) {
        this.permissions.defaults.supplier.error = true;
        error = true;
      } else {
        delete this.permissions.defaults.supplier['error'];
      }
    } else {
      if (this.permissions && this.permissions.defaults) {
        delete this.permissions.defaults.supplier['error'];
      }
    }
    this._cdr.detectChanges();
    return !error;
  }

  setSupplierFormattingOptionsToCustomer(data) {
    if (!data['formattingOptions']['supplier']['adhocCellType']) {
      data['formattingOptions']['supplier']['adhocCellType'] = '';
    }
    if (!data['formattingOptions']['customer']['adhocCellType']) {
      data['formattingOptions']['customer']['adhocCellType'] = '';
    }
    for (const property in data['formattingOptions']['supplier']) {
      const val = data['formattingOptions']['customer'][property];
      if (property !== 'adhocCellType') {
        data['formattingOptions']['customer'][property] =
          data['formattingOptions']['supplier'][property];
      }
    }
  }

  ngOnDestroy() {
    this.inputFieldSubscription && this.inputFieldSubscription.unsubscribe();
    this.refSetConstantsSubscription &&
      this.refSetConstantsSubscription.unsubscribe();
    this.existingDBFieldsSubscription &&
      this.existingDBFieldsSubscription.unsubscribe();
    this.templateIdSubscription && this.templateIdSubscription.unsubscribe();
    this.FieldDetailsSubscription &&
      this.FieldDetailsSubscription.unsubscribe();
    this.subject$.next();
    this.subject$.complete();
  }
}
