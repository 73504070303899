import { Injectable } from '@angular/core';
import { GridOptions } from '@ag-grid-community/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class GridConfiguration {
  private gridOptions: GridOptions;
  public pageConfiguration: any = 2000;
  private selectableStatus: any;
  private rfqId: any;
  private payload: any;
  private editableColumnFlag = 'custEdit';
  hideCheckBoxForRowSelection: Function = null;
  rowNodeIdFn: Function = null;
  cellEditValidator: Function = null;
  cellColorClassFn: Function = null;
  enableEditModeFn: Function = null;
  onCellMouseOver: Function = null;
  onCellMouseOut: Function = null;
  constructor() {
    this.gridOptions = ({} as GridOptions);
  }

  localJson = {
    supressAutoFit: false,
    floatingFilter: false,
    rowGrouping: false,
    columnGrouping: false,
    headerSource: 'local',
    headerData: null,
    rowData: null,
    editable: false,
    checkbox: false,
    pagination: true,
    filter: true,
    clientSidePagination: true,
    clientSidePaginationMaxRecords: 200,
    pageSize: 2000,
    dropdownPageSize: 100,
    primaryIdentifier: '',
    paginationWithGotoPage: false,
    enableServerSidePagination: false,
    suppressRowClickSelection: true,
    rowSelection: 'multiple',
    enableCellChangeFlash: true,
    columnConfigurations: null,
    suppressMenuHide: false
  };

  rowData = new BehaviorSubject<any>(null);

  public isEnableServerSidePagination() {
    return this.localJson.enableServerSidePagination;
  }

  public setEnableServerSidePagination(serverFlag: boolean) {
    this.localJson.enableServerSidePagination = serverFlag;
  }

  public getDropDownPageSize() {
    return this.localJson.dropdownPageSize;
  }

  public setDropDownPageSize(dropdownPageSize) {
    this.localJson.dropdownPageSize = dropdownPageSize;
  }

  public getPaginationWithGotoPage() {
    return this.localJson.paginationWithGotoPage;
  }

  public setPaginationWithGotoPage(option: boolean) {
    this.localJson.paginationWithGotoPage = option;
  }

  public getPrimaryId() {
    return this.localJson.primaryIdentifier;
  }

  public setPrimaryId(primaryIdentifier) {
    this.localJson.primaryIdentifier = primaryIdentifier;
  }
  public getGridSettings() {
    return this.localJson;
  }

  public setPageConfiguration(pageConfiguration: any) {
    this.pageConfiguration = pageConfiguration;
  }

  public getPageConfiguration() {
    return this.pageConfiguration;
  }

  public getPageSize() {
    return this.localJson.pageSize;
  }

  public setPayload(payload: any) {
    this.payload = payload;
  }

  public getPayload() {
    return this.payload;
  }

  public setPageSize(value) {
    this.localJson.pageSize = value;
  }

  public getEnableFilter() {
    return this.localJson.filter;
  }

  public setEnableFilter(option: boolean) {
    this.localJson.filter = option;
  }

  public setPagination(option: boolean) {
    this.localJson.pagination = option;
  }

  public getPagination() {
    return this.localJson.pagination;
  }

  public setClientSidePagination(option: boolean) {
    this.localJson.clientSidePagination = option;
  }

  public getClientSidePagination() {
    return this.localJson.clientSidePagination;
  }

  public setClientSideRecords(count: number) {
    this.localJson.clientSidePaginationMaxRecords = count;
  }

  public getClientSideRecords() {
    return this.localJson.clientSidePaginationMaxRecords;
  }

  public getlocalJson() {
    return this.localJson;
  }

  public getRowGrouping() {
    return this.localJson.rowGrouping;
  }

  public setRowGrouping(rowOption: boolean) {
    this.localJson.rowGrouping = rowOption;
  }

  public getHeaderSource() {
    return this.localJson.headerSource;
  }

  public setHeaderSource(option: string) {
    this.localJson.headerSource = option;
  }

  public getHeaderObject() {
    return this.localJson.headerData;
  }

  public setHeaderObject(headerJson: object) {
    this.localJson.headerData = headerJson;
  }

  public getSuppressAutoFit() {
    return this.localJson.supressAutoFit;
  }
  public setSuppressAutoFit(supressAutoFit: boolean) {
    this.localJson.supressAutoFit = supressAutoFit;
  }

  public getColumnGrouping() {
    return this.localJson.columnGrouping;
  }

  public setColumnGrouping(option: boolean) {
    this.localJson.columnGrouping = option;
  }

  public getRowData() {
    return this.localJson.rowData;
  }

  public setRowData(rowDataJson: object) {
    this.localJson.rowData = rowDataJson;
    this.rowData.next(rowDataJson);
    this.rowDataChanged();
  }

  public getEditable() {
    return this.localJson.editable;
  }

  public setEditable(option: boolean) {
    this.localJson.editable = option;
  }

  public getEditableColumn() {
    return this.editableColumnFlag;
  }

  public setEditableColumn(option: string) {
    this.editableColumnFlag = option;
  }

  public getCheckBox() {
    return this.localJson.checkbox;
  }

  public setCheckbox(option: boolean) {
    this.localJson.checkbox = option;
  }

  public refreshData() {
    try {
      console.log(this.localJson.rowData);
      this.gridOptions.api.setRowData(this.localJson.rowData);
    } catch (e) {}
  }
  public setSuppressRowClickSelection(option: boolean) {
    this.localJson.suppressRowClickSelection = option;
  }
  public getSuppressRowClickSelection() {
    return this.localJson.suppressRowClickSelection;
  }
  public getRowSelection() {
    return this.localJson.rowSelection;
  }
  public setRowSelection(selection) {
    this.localJson.rowSelection = selection;
  }

  public setMethodForSelectableStatus(cb) {
    this.selectableStatus = cb;
  }
  public getMethodForSelectableStatus(editRowNodes, editRfqid: any = null) {
    this.selectableStatus(editRowNodes, editRfqid);
  }

  public setHideCheckBoxForRowSelection(val) {
    if (val instanceof Function) {
      this.hideCheckBoxForRowSelection = val;
    } else {
      this.hideCheckBoxForRowSelection = null;
    }
  }
  public getHideCheckBoxForRowSelection() {
    return this.hideCheckBoxForRowSelection;
  }

  public rowDataChanged() {
    return this.rowData;
  }

  public getRowNodeId() {
    return this.rowNodeIdFn;
  }

  public setRowNodeId(fn) {
    if (fn instanceof Function) {
      this.rowNodeIdFn = fn;
    } else {
      this.rowNodeIdFn = null;
    }
  }

  public getCellEditValidator() {
    return this.cellEditValidator;
  }

  public setCellEditValidator(fn) {
    if (fn instanceof Function) {
      this.cellEditValidator = fn;
    } else {
      this.cellEditValidator = null;
    }
  }

  public getCellColorClass() {
    return this.cellColorClassFn;
  }

  public setCellColorClass(fn) {
    if (fn instanceof Function) {
      this.cellColorClassFn = fn;
    } else {
      this.cellColorClassFn = null;
    }
  }

  public getEnableEditMode() {
    return this.enableEditModeFn;
  }

  public setEnableEditMode(fn) {
    if (fn instanceof Function) {
      this.enableEditModeFn = fn;
    } else {
      this.enableEditModeFn = null;
    }
  }

  public getCellMouseOver() {
    return this.onCellMouseOver;
  }
  public setCellMouseOver(fn) {
    if (fn instanceof Function) {
      this.onCellMouseOver = fn;
    } else {
      this.onCellMouseOver = null;
    }
  }

  public getCellMouseOut() {
    return this.onCellMouseOut;
  }
  public setCellMouseOut(fn) {
    if (fn instanceof Function) {
      this.onCellMouseOut = fn;
    } else {
      this.onCellMouseOut = null;
    }
  }

  public setColumnConfigurations(config) {
    this.localJson.columnConfigurations = config;
  }
  public getColumnConfigurations() {
    return this.localJson.columnConfigurations;
  }
  public setFloatingFilter(config) {
    this.localJson.floatingFilter = config;
  }
  public getFloatingFilter() {
    return this.localJson.floatingFilter;
  }

  public setSuppressMenuhide(value) {
    this.localJson.suppressMenuHide = value;
  }
  public getSuppressMenuhide() {
    return this.localJson.suppressMenuHide;
  }
}
