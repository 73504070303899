import { LoginService } from './login/login.component.service';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LoginComponent } from 'app/modules/authentication/login/login.component';
import { ForgotComponent } from 'app/modules/authentication/forgot/forgot.component';
import { ChangePasswordComponent } from 'app/modules/authentication/change-password/change-password.component';
import { NgxLoadingModule } from 'ngx-loading';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoginErrorPageComponent } from './login-error-page/login-error-page.component';
import { SsoOpenidconnectComponent } from './sso-openidconnect/sso-openidconnect.component';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    CoreModule,
    NgxLoadingModule.forRoot({}),
    SharedModule,
    RouterModule
  ],
  declarations: [
    LoginComponent,
    ForgotComponent,
    ChangePasswordComponent,
    PageNotFoundComponent,
    LoginErrorPageComponent,
    SsoOpenidconnectComponent
  ],

  exports: [
    LoginComponent,
    ForgotComponent,
    PageNotFoundComponent,
    FormsModule,
    ChangePasswordComponent,
    LoginErrorPageComponent,
    SsoOpenidconnectComponent
  ],
  providers: [LoginService]
})
export class LoginAuthentication { }
