import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  HostListener,
} from '@angular/core';
import { GenericChartService } from 'app/modules/high-charts/generic-chart/shared/generic-chart.service';
import { Chart, StockChart, MapChart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import { CoverageDashboardService } from 'app/modules/coverage-dashboard/coverage-dashboard.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'generic-chart',
  templateUrl: './generic-chart.component.html',
  styleUrls: ['./generic-chart.component.sass'],
  providers: [GenericChartService],
})
export class GenericChartComponent implements OnInit {
  chart: Chart | StockChart | MapChart;
  options: any;
  type: any;
  @Input('Options') customGraphOptions;
  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    this.loadGraph();
    this.replotBarPercent = true;
    this.plotBarChart();
  }

  @Input('Type') customGraphType = 'chart';
  dataFindInterval;
  nodata: boolean;
  replotBarPercent: boolean;
  private subject$: Subject<any> = new Subject();
  constructor(private genericChartService: GenericChartService,
    private coverageGridService: CoverageDashboardService) { }

  ngOnInit() {

    this.options = this.customGraphOptions;
    this.type = this.customGraphType;
    this.loadGraph();

    this.replotBarPercent = true;
    Highcharts.setOptions({
      lang: {
        thousandsSep: ','
      }
    })
    this.subscribeToBarEvents();
  }
  private subscribeToBarEvents(): void {
    this.coverageGridService.getDashboardDataChangeObservable()
      .pipe(takeUntil(this.subject$))
      .subscribe((response: any) => {
        if (response.type === 'BAR_UPDATE') {
          this.replotBarPercent = true;
          this.plotBarChart();
        }
      })
  }
  plotBarChart() {
    this.dataFindInterval = setInterval(() => {
      if (
        this.replotBarPercent
      ) {
        this.replotGraph();
        this.clearDataInterval()
      }
    }, 10)
  }
  clearDataInterval() {
    clearInterval(this.dataFindInterval)
  }
  loadGraph() {
    try {
      if (!this.options) {
        this.nodata = true;
      } else if (Object.keys(this.options).length === 0) {
        this.nodata = true;
      } else if (this.options.series.length === 0) {
        this.nodata = true;
      } else {
        this.customGraphOptions['exporting'] = {
          enabled: false,
        };
        this.nodata = false;
      }
      switch (this.type.toLowerCase()) {
        case 'stockchart':
          if (this.customGraphOptions.series[0] && this.customGraphOptions.series[0].data instanceof Array && this.customGraphOptions.series[0].data.length === 0) {
            this.customGraphOptions.chart.events = {
              load() {
                const chart = this;
                chart.renderer.text('No data available to display', 150, 150)
                  .css({
                    fontSize: '16px',
                    color: 'rgba(13, 36, 60, 0.5) !important',
                    'font-weight': '600',
                    'line-height': '24px'
                  })
                  .add();
              }
            }
          }
          this.chart = new StockChart(this.customGraphOptions);
          break;
        case 'mapchart':
          this.chart = new MapChart(this.customGraphOptions);
          break;
        default:
          this.chart = new Chart(this.customGraphOptions);
          break;
      }
    } catch (e) {
      console.error(e);
    }
  }

  private replotGraph() {
    var index = 0;
    var noCoverage = false;
    var arr = Highcharts.charts.filter(chart => chart);
    let mapArr = new Map<String, String>();
    var index = 0;
    if (arr && arr.length > 0 && this.replotBarPercent) {
      arr.forEach(f => {
        if (f !== undefined && f.axes !== undefined && f.axes.length == 3
          && f.series !== undefined) {
          f.series.forEach(serie => {
            serie.visible = true;
            (serie.chart.axes[0] as any).visible = true;
            if (serie !== undefined && serie.name == "No Coverage") {
              this.replotBarPercent = false;
              noCoverage = true;
              if (serie.points && serie.points.length) {
                serie.points.forEach(point => {
                  mapArr.set(point.category, (point as any).shapeArgs.height);
                });
              }
            }
          });
          this.axisUpdate(noCoverage, arr, index, mapArr, f);
          this.axisUpdate(noCoverage, arr, index, mapArr, f);
        }
        index++;
      });

    }
  }

  private axisUpdate(noCoverage: boolean, arr: Highcharts.Chart[], index: number, mapArr: Map<String, String>, f: Highcharts.Chart) {
    if (noCoverage) {
      arr[index].xAxis[1].update({
        labels: {
          style: {
            fontSize: '10px !important'
          },
          align: 'right',

          y: -10,
          useHTML: true,
          formatter: function () {
            var label = this.axis.defaultLabelFormatter.call(this);
            var browserZoomLevel = Math.round(window.devicePixelRatio * 100);
            var lab = label.split('$$')[1];
            var wid = Number(mapArr.get(lab));
            var valuedisplay = Number(label.split('%$$')[0]);
            if (browserZoomLevel > 100) {
              wid = wid - (browserZoomLevel - 100);
            }
            if (valuedisplay < 10) {
              wid = f.plotWidth - 32;
            }

            else if (valuedisplay < 30 && browserZoomLevel > 80) {
              wid = f.plotWidth - 40;
            }
            var valueLeft = "position: relative !important; font-size: 9px !important;left : -" + wid + "px !important;z-index: -1;";

            if (f.series && f.series.length && lab == f.series[0].points[0].category)
              return '<div class="tip"  style="' + valueLeft + '">' + valuedisplay.toFixed(2) + '%<span  style="top: 8% !important;right:-40px !important";><a class="tooltip-title">' + lab + '</a><br><a class="tooltip-content">Coverage&nbsp; &nbsp; &nbsp;' + valuedisplay.toFixed(2) + '%</a></span></div>';
            if (valuedisplay > 80)
              return '<div class="tip"  style="' + valueLeft + '">' + valuedisplay.toFixed(2) + '%<span  style="right:-25px !important"><a class="tooltip-title">' + lab + '</a><br><a class="tooltip-content">Coverage&nbsp; &nbsp; &nbsp;' + valuedisplay.toFixed(2) + '%</a></span></div>';

            return '<div class="tip"  style="' + valueLeft + '">' + valuedisplay.toFixed(2) + '%<span ><a class="tooltip-title">' + lab + '</a><br><a class="tooltip-content">Coverage&nbsp; &nbsp; &nbsp;' + valuedisplay.toFixed(2) + '%</a></span></div>';

          }
        }
      }, true);
    }
  }

  ngOnDestroy() {
    this.subject$.next();
    this.subject$.complete();
    this.clearDataInterval();
  }

}
