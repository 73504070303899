import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnChanges,
  ElementRef,
  Pipe,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import {
  ToasterService,
  Toast,
  BodyOutputType,
  ToasterConfig
} from 'angular2-toaster';
import { Fileupload } from 'app/modules/core/fileupload/shared/fileupload.model';
import { FileuploadService } from 'app/modules/core/fileupload/shared/fileupload.service';
import { ApiServiceProvider } from 'app/api-service';
import { COLLABORATION_CONSTS } from '../../collaborationIcon/collaboration.constants';

@Component({
  selector: 'collabfileupload',
  templateUrl: 'collabfileupload.component.html',
  styleUrls: ['./collabfileupload.component.sass'],
  providers: [FileuploadService]
})
export class CollabFileuploadComponent
  implements OnInit, OnDestroy, ICellRendererAngularComp {
  @ViewChild('myInput', { static: false })
  public myInputVariable: any;
  public focus: string;
  public fileupload: Fileupload[] = [];
  public selection: any;
  public response: any;
  public uploadData: any;
  public message: string;
  public filename: string;
  public testFileName: string;
  public successMsg= '';
  public errorMsg= '';
  public successMsgShow = false;
  public errorMsgShow = false;
  public fileSize= 'S';
  public file = true;
  @Input() public fileDataObject: any;
  @Output() public onFileUpload = new EventEmitter<any>();
  @ViewChild('fileInput', { static: false }) public fileInput;
  @ViewChild('inputText', { static: false }) public inputText;
  @ViewChild('myInputrfqVariable', { static: false }) public myInputrfqVariable;
  public subscriptions;
  public showLoading = false;
  public result: any;
  public params: ICellRendererParams;
  public config1: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-right',
    timeout: 20000,
    limit: 1
  });
  private toasterService: ToasterService;

  constructor(
    private apiService: ApiServiceProvider,
    toasterService: ToasterService
  ) {
    this.toasterService = toasterService;
  }
  public refresh(params: any): boolean {
    this.params = params;
    return;
  }
  public agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  public ngOnInit() {}

  public clearSubscriptions(key?: string) {
    if (typeof key === 'string') {
      this.subscriptions[key].unsubscribe();
      return;
    }
    for (const eachKey in this.subscriptions) {
      this.subscriptions[eachKey].unsubscribe();
    }
  }
  public popToastS(data) {
    const toast: Toast = {
      type: 'success',
      showCloseButton: true,
      body: '<div class="toast-msg success-msg">' + data + '</div>',
      bodyOutputType: BodyOutputType.TrustedHtml
    };
    this.toasterService.pop(toast);
  }

  public popToastF(data) {
    const toast: Toast = {
      type: 'error',
      showCloseButton: true,
      body: '<div class="toast-msg error-msg">' + data + '</div>',
      bodyOutputType: BodyOutputType.TrustedHtml
    };
    this.toasterService.pop(toast);
  }
  onUploadCollabFile(event) {
    if (event instanceof Object) {
      if (event.target instanceof HTMLElement) {
        if (event.target.files instanceof FileList) {
          if (event.target.files.length > 0) {
            const files = event.target.files;
            const fileName = files[0].name;
            try {
              if (this.myInputrfqVariable.nativeElement.files) {
                const files = this.myInputrfqVariable.nativeElement.files;
                const formData = new FormData();
                formData.append('filename', files[0]);
                const queryparams: any = {};
                this.apiService
                  .fileUpload(
                    formData,
                    COLLABORATION_CONSTS.upload,
                    '',
                    'Collaboration',
                    queryparams
                  )
                  .subscribe(
                    res => {
                      if (res.responseStatus.code === 413) {
                        this.popToastF(res.message);
                      } else {
                        this.onFileUpload.emit(res.result);
                      }
                    },
                    error => {
                      this.popToastF('File upload failed.');
                    },
                    () => {}
                  );
              }
            } catch (e) {
              console.log('Exception in File Onchange Method ', e);
            }
          }
        }
      }
    }
  }

  public ngOnDestroy() {
    this.clearSubscriptions();
  }
}
