import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { OpportunitiesListService } from './shared/opportunities-list.service';
import { Router, NavigationEnd } from '@angular/router';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { SubscriptionLike } from 'rxjs';

import { SocketService } from 'app/modules/common/socket/scoket.service';
import { SubscriptionService } from 'app/modules/common/subscription.service';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';
@Component({
  selector: 'opportunities-list',
  templateUrl: 'opportunities-list.component.html',
  styleUrls: ['./opportunities-list.component.sass'],
  providers: []
})
export class OpportunitiesListComponent implements OnInit, OnDestroy {
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  @Output() totalValues = new EventEmitter<any>();
  constructor(
    private opportunitiesListService: OpportunitiesListService,
    private scopeDataService: ScopeDataService,
    private subscriptionService: SubscriptionService,
    private socketService: SocketService,
    private router: Router
  ) {
    this.activeClassSubscription = this.subscriptionService
      .getOppActiveClass()
      .subscribe(status => {
        if (
          status !== undefined &&
          window.location.href.indexOf('dashboard') === -1
        ) {
          this.currentSelectedAlertId = status.selectedAlertId;
          this.subscriptionService.setOppActiveClass(undefined);
        }
      });

    // For same page updating
    this.socketService.onGetSelectedOpportunites().subscribe(message => {
      if (message !== undefined) {
        if (message instanceof Object) {
          if (this.opportunitiesList !== undefined) {
            this.onUpdateMessage(message);
            this.socketService.onSetSelectedOpportunites(undefined);
          } else {
          }
        }
      }
    });
    this.socketService.getAlertIdFromChatBot().subscribe(alertId => {
      if (alertId !== undefined) {
        this.checkBotNotification(alertId);
        this.socketService.setAlertIdFromChatBot(undefined);
      }
    });
  }
  opportunitiesList: any = {};
  relevant: number;
  irrelevant: number;
  isRead = false;
  hoverMsg = '';
  scopeSubscription: SubscriptionLike;
  oppListSubscription: SubscriptionLike;
  oppLikedSubscription: SubscriptionLike;
  oppUnlikedSubscription: SubscriptionLike;
  activeClassSubscription: SubscriptionLike;
  oppReadSubscription: SubscriptionLike;
  alertId: number;
  selectedId = 0;
  loading = true;
  appScope: string;
  selectedAlert: any;
  chatBotAlertId: number;
  noOpportunitiesMessage = false;
  currentSelectedAlertId = '';

  inputObj = {
    context: 'test context',
    topic: 'test_subject',
    userEmail: 'fitbit.user2@fbit.com',
    needCalendar: true,
    needTitle: true,
    defaultType: 'TYPE_1'
  };
  findElement: any;

  ngOnInit() {
    // if (window.location.href.indexOf("dashboard") !== -1) {
    // 	this.hoverMsg = "Please go to Opportunities screen to view the details"
    // }
    this.scopeSubscription = this.scopeDataService
      .getFocus()
      .subscribe(value => {
        this.appScope = value;
        this.subscribe();
      });

    this.selectedAlert = this.socketService.onNormalGetSelectedOpportunites();
    this.chatBotAlertId = this.socketService.getNormalAlertIdFromChatBot();

    this.socketService.onNormalSetSelectedOpportunites(undefined);
    this.socketService.setNormalAlertIdFromChatBot(undefined);

    this.appScope = this.scopeDataService.getFocusValue();
    this.subscribe();
    this.opportunitiesList = this.opportunitiesListService.getOpportunitiesList();
    this.totalValues.emit(this.opportunitiesList);
    this.router.events.subscribe(val => {
      try {
        if (val instanceof NavigationEnd) {
          if (val['url'] === '/app/opportunities') {
            const selector: any = '.opportunities-list ul li';
            const liItems: any = document.querySelectorAll(selector);
            for (let i = 0; i < liItems.length; i++) {
              liItems[i].classList.remove('selected');
            }
          }
        }
      } catch (e) { }
    });
  }

  public onUpdateMessage(message) {
    if (message !== undefined) {
      if (message instanceof Object) {
        if (message.alertId !== undefined) {
          this.addClassSelectedAlert(message.alertId || undefined);
        }
      }
    }
  }
  public moveAlertToFirst(alertId) {
    if (alertId !== undefined) {
      if (this.opportunitiesList instanceof Object) {
        const arrayOfAlerts = this.opportunitiesList['alerts'].slice();
        let selectedAlertIdIndex;
        Object.keys(arrayOfAlerts).forEach(key => {
          if (arrayOfAlerts[key].alertId === alertId) {
            selectedAlertIdIndex = key;
            const selectedAlertObject = arrayOfAlerts[selectedAlertIdIndex];
            // Delete Alert
            if (selectedAlertIdIndex !== undefined) {
              this.opportunitiesList['alerts'].slice(selectedAlertIdIndex, 1);
            }
            // Add Alert to first
            if (selectedAlertObject !== undefined) {
              this.opportunitiesList['alerts'].unshift(selectedAlertObject);
              document.getElementById('alert-' + alertId).click();
            }
            return true;
          }
        });
      }
    }
  }

  public addClassSelectedAlert(alertId) {
    if (alertId !== undefined) {
      if (document.getElementById('alert-' + alertId) !== null) {
        this.moveAlertToFirst(alertId);
      }
    }
  }

  public removeSelectClass() {
    document.getElementsByClassName('opportunity-item');
  }
  public checkBotNotification(alertId) {
    if (alertId !== undefined) {
      this.findElement = setInterval(() => {
        if (document.getElementById('alert-' + alertId) !== undefined) {
          this.stopInterVal();
          this.moveAlertToFirst(alertId);
        }
      }, 100);
    }
  }
  stopInterVal() {
    clearInterval(this.findElement);
  }
  subscribe() {
    this.loading = true;
    let orderBy;
    if (this.chatBotAlertId !== undefined) {
      orderBy = 'UNREAD';
    }
    this.oppListSubscription = this.opportunitiesListService
      .getOpportunityListData(this.appScope, orderBy)
      .subscribe(
        data => {
          if (data !== undefined) {
            if (data instanceof Object) {
              if (data['responseStatus'] instanceof Object) {
                if (data['responseStatus']['code'] !== undefined) {
                  switch (data['responseStatus']['code']) {
                    case 200:
                      this.loading = false;
                      this.opportunitiesList = data.result;
                      this.totalValues.emit(this.opportunitiesList);
                      // this.opportunitiesList = [];
                      if (
                        this.opportunitiesList.alerts instanceof Array &&
                        this.opportunitiesList.alerts.length > 0
                      ) {
                        if (this.selectedAlert !== undefined) {
                          if (this.selectedAlert.alertId !== undefined) {
                            this.checkBotNotification(
                              this.selectedAlert.alertId
                            );
                          }
                        }
                        if (this.chatBotAlertId !== undefined) {
                          this.checkBotNotification(this.chatBotAlertId);
                        }
                      } else {
                        this.noOpportunitiesMessage = true;
                      }

                      break;
                    default:
                      this.loading = false;
                      this.opportunitiesList = [];
                      this.totalValues.emit(this.opportunitiesList);
                      break;
                  }
                } else {
                  this.loading = false;
                  this.opportunitiesList = [];
                  this.totalValues.emit(this.opportunitiesList);
                }
              } else {
                this.loading = false;
                this.opportunitiesList = [];
                this.totalValues.emit(this.opportunitiesList);
              }
            } else {
              this.loading = false;
              this.opportunitiesList = [];
              this.totalValues.emit(this.opportunitiesList);
            }
          } else {
            this.loading = false;
            this.opportunitiesList = [];
            this.totalValues.emit(this.opportunitiesList);
          }
          /* if (data.responseStatus !== undefined) {
					this.loading = false;
					if (data.responseStatus.code === 200) {
						this.opportunitiesList = data.result;
					}
				} */
        },
        error => {
          console.log('error in getting opportunity alerts');
          this.loading = false;
          this.opportunitiesList = [];
          this.totalValues.emit(this.opportunitiesList);
        }
      );
  }

  addActive(event, selectedObj, type) {
    event.stopPropagation();
    if (selectedObj instanceof Object) {
      if (
        typeof selectedObj.alertId === 'number' &&
        !isNaN(selectedObj.alertId)
      ) {
        const alertId = selectedObj['alertId'];
        const relevant = type === 'like' ? 1 : 0;
        const btn = event.currentTarget || event.target.parentElement;
        this.oppLikedSubscription = this.opportunitiesListService
          .getRelevantData(alertId, relevant)
          .subscribe(
            data => {
              if (data.responseStatus !== undefined) {
                if (data.responseStatus.code === 200) {
                  console.log(
                    'successfully got the ' + relevant
                      ? 'relevant'
                      : 'irrelavant' + ' data '
                  );
                  if (data.result ) {
                    const elements: NodeListOf<Element> =
                      btn.parentElement.children;
                    for (let i = 0; i < elements.length; i++) {
                      const classes: DOMTokenList = elements[i].classList;
                      if (elements[i].classList.contains('highlight')) {
                        classes.remove('highlight');
                      }
                    }
                    btn.classList.add('highlight');
                  }
                }
              }
            },
            error =>
              console.log(
                'unable to get ' + relevant
                  ? 'relevant'
                  : 'irrelavant' + ' data',
                error
              )
          );
      }
    }
  }
  opportunityClicked(event, selectedObj: object) {
    if (selectedObj['driver'] === `Supplier Score Insight`) {
      this.navigateToSupplierScoreCard(selectedObj);
    }
    else {
      try {
        this.hoverMsg = '';
        this.currentSelectedAlertId = '';
        this.scopeDataService.resetDefaultDimensionDataObject();
        const alertId = selectedObj['alertId'];
        this.isRead = true;
        this.oppReadSubscription = this.opportunitiesListService
          .getReadData(alertId)
          .subscribe(
            data => {
              if (data !== undefined) {
                console.log('sucessfully got the opportunity read data');
              }
            },
            error => console.log('unable to get opportunity read data')
          );

        if (selectedObj['show'] !== 'none') {
          this.opportunitiesListService.setCurrentOpportunityId(selectedObj);
          // event.stopPropagation();
          const selector: any = '.opportunities-list ul li';
          const liItems: any = document.querySelectorAll(selector);
          for (let i = 0; i < liItems.length; i++) {
            liItems[i].classList.remove('selected');
          }
        }
        // if(event.currentTarget.querySelector('.title-val').classList.add('font-normal')
        const titleFont: any = event.currentTarget.querySelector('.title-val')
          .classList;
        if (titleFont.contains('font-bold')) {
          titleFont.remove('font-bold');
          titleFont.add('font-regular');
        }
        event.currentTarget.classList.add('selected');

        if (window.location.href.indexOf('dashboard') !== -1) {
          this.router.navigate(['/app/opportunities/details']);
          this.subscriptionService.setOppActiveClass({
            selectedAlertId: alertId
          });
          // this.hoverMsg = "Please go to Opportunities screen to view the details"
        }
      } catch (error) {
        console.log('Error in opportunityClicked ' + error);
      }
    }
  }
  ngOnDestroy() {
    // this.subscriptionService.setOppActiveClass(undefined);
    if (this.activeClassSubscription) {
      this.activeClassSubscription.unsubscribe();
    }
    if (this.scopeSubscription) {
      this.scopeSubscription.unsubscribe();
    }
    if (this.oppListSubscription) {
      this.oppListSubscription.unsubscribe();
    }
    if (this.oppLikedSubscription) {
      this.oppLikedSubscription.unsubscribe();
    }
    if (this.oppUnlikedSubscription) {
      this.oppUnlikedSubscription.unsubscribe();
    }
    if (this.oppReadSubscription) {
      this.oppReadSubscription.unsubscribe();
    }
  }
  showGreenFirst() {
    const greenArr = [];
    const redArr = [];
    this.opportunitiesList.alerts.forEach(i => {
      i.type === 'O' ? greenArr.push(i) : redArr.push(i);
    });
    this.opportunitiesList.alerts = greenArr.concat(redArr);
  }
  showRedFirst() {
    const greenArr = [];
    const redArr = [];
    this.opportunitiesList.alerts.forEach(i => {
      i.type === 'O' ? greenArr.push(i) : redArr.push(i);
    });
    this.opportunitiesList.alerts = redArr.concat(greenArr);
  }

  private navigateToSupplierScoreCard(selectedOpportunity: any) {
    this.subscriptionService.setSupplierScoreAlert(selectedOpportunity);
    this.router.navigate(['/app/mi/supplierscorecard']);
  }

}
