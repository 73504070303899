import { Injectable } from '@angular/core';
import * as jwt from 'jsonwebtoken';

@Injectable()
export class ClientTokenService {
  private clientTokenMap: any = {
    [ClientTokenType.ZENDESK]: 'de773db2101f29a5ee6e4682b32cf2fa',
    // [ClientTokenType.SISENSE]: '123456789', // reserved for sisense
    [ClientTokenType.SEGMENT]: '123456789', // reserved for segment
    // [ClientTokenType.WOOTRIC]: "123456789" // reserved for wootric
  };

  private defaultClientTokenOptions: ClientTokenOptions = {
    algorithm: 'HS256',
    expiresIn: Math.floor(Date.now() / 1000) + 60 * 60,
    notBefore: Math.floor(Date.now() / 1000) + 3000,
    audience: 'Zendesk',
    issuer: 'Levadata',
    jwtid: this.generateJwtId(),
    subject: 'None',
    header: {
      alg: 'HS256',
      type: 'JWT',
    },
    payload: {},
  };

  constructor() {}

  public issue(
    clientType: string | ClientTokenType,
    options?: ClientTokenOptions
  ) {
    const tokenOptions = Object.assign(
      this.defaultClientTokenOptions,
      options ? options : {}
    );
    const signedJwt = jwt.sign(
      tokenOptions.payload,
      this.clientTokenMap[clientType]
    );
    return signedJwt;
  }

  public verify() {
    // TODO implement verify
  }

  public decode() {
    // TODO implement decode
  }

  public generateJwtId() {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 10; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }
}

export enum ClientTokenType {
  ZENDESK = 'zendesk' as any,
  // SISENSE = 'sisense' as any,
  // WOOTRIC = <any>"wootric",
  SEGMENT = 'segment' as any,
}

export interface ClientTokenOptions {
  algorithm?: string;
  expiresIn?: number;
  notBefore?: number;
  audience?: string;
  issuer?: string;
  jwtid?: string;
  subject?: string;
  noTimestamp?: string;
  keyid?: string;
  payload?: any;
  header?: JwtHeader;
}

export interface JwtHeader {
  alg: string;
  type: string;
  kid?: string;
}
