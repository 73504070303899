import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { NpiAdminRiskService } from '../../npi-admin/npi-admin-risk/shared/npi-admin-risk.service';
import { SubscriptionService } from '../../../common/subscription.service';
import { PayLoad } from '../../../common/models/payload.model';
import { RequestData } from '../../../common/models/request.model';

@Component({
  moduleId: module.id,
  selector: 'psl-risk-search',
  templateUrl: './psl-risk-search.component.html',
  styleUrls: ['./psl-risk-search.component.css']
})
export class PslRiskSearchComponent implements OnInit {
  public _index: any;
  public _item: any;
  @Output() public onClick: EventEmitter<any> = new EventEmitter<any>();
  public request: any;
  public requestPayload: any;
  public indexForSupplier: any;
  @Input() set index(val) {
    if (typeof val === 'number') {
      this._index = val;
    }
  }
  @Input() set item(val) {
    if (val instanceof Object) {
      this._item = val;
      this.getAutosearchData();
    }
  }
  constructor(
    private npiAdminRiskService: NpiAdminRiskService,
    private subscriptionService: SubscriptionService
  ) {}
  public ngOnInit() {
    this.getAutosearchData();
    this.subscriptionService.getSelectedPSL().subscribe(index => {
      this.indexForSupplier = index;
    });
    this.subscriptionService.getAutoSearchText().subscribe(value => {
      if (value && value.length) {
        if (
          this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap &&
          this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.hasOwnProperty(
            'NON_PREFERRED_SUPPLIER_RISK'
          )
        ) {
          if (
            typeof this.indexForSupplier === 'number' &&
            this.indexForSupplier === this._index
          ) {
            this.npiAdminRiskService.bomRiskUpdated = true;
            this.npiAdminRiskService.enableReset = true;
            this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls[
              this.indexForSupplier
            ]['name'] = value[0].value;
            this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls.forEach(
              element => {
                element.error = false;
              }
            );
            this.npiAdminRiskService.errors['psl'] = [];
            this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls.forEach(
              element => {
                if(element.name){
                const commonSupp = this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls.filter(
                  obj => obj.name.toUpperCase() === element.name.toUpperCase()
                );
                if (commonSupp && commonSupp.length > 1) {
                  element.error = true;
                  this.npiAdminRiskService.errors['psl'].push('Error');
                }
              }
              }
            );
            this.npiAdminRiskService.saveButtonStatus();
          }
        }
      }
    });
  }

  public getAutosearchData() {
    const payload = new PayLoad();
    payload.dimension = ['SUPPLIER_NAME'];
    payload.isWildCardSearch = true;
    this.request = new RequestData();
    this.request.targetedType = 'partsMasterService';
    this.request.method = 'GET';
    this.request.servicePath = 'search/part/attributes';
    this.request.placeHolder = 'Enter value';
    this.request.type = 'multi';
    this.request.formParams = {
      dimensions: ['supplier_name'],
      type: 'DATA'
    };
    if (this._item.name) {
      this.request.defaultSelection = this._item.name;
    } else {
      this.request.defaultSelection = 'Select';
    }
    this.request.typeOfSelectSingle = true;
    this.request.payload = payload;
  }
  public autoSearchClicked(i) {
    this.subscriptionService.setSelectedPSL(this._index);
  }
}
