import { Injectable } from '@angular/core';
import { ToastService } from 'app/modules/srfq/shared/toaster.service';
import { SHARED_CONSTANT } from '../constants/shared.constants';

@Injectable()
export class SharedService {

  constructor(private toasterService: ToastService) { }

  public getLocalStorageData(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  public setLocalStorageData(key: string, value: any): void {
    if(typeof Storage !== 'undefined' || typeof Storage !== void(0)) {
      try {
        localStorage.setItem(key, JSON.stringify(value));
      } catch(e) {
        if (this.isQuotaExceeded(e)) {
          console.log(SHARED_CONSTANT.errors.cacheFull)
          localStorage.clear();
        } else {
          this.toasterService.populateToaster('error', e.message);
        }
      }
    } else {
      this.toasterService.populateToaster('error', SHARED_CONSTANT.errors.enableLocalStorage);
    }
  }

  public isQuotaExceeded(e): boolean {
    console.log(e);
    let quotaExceeded = false;
    if (e) {
      if (e.code) {
        switch (e.code) {
          case 22:
            // everything except Firefox
            if (e.name === 'QuotaExceededError') {
              quotaExceeded = true;
            }
            break;
          case 1014:
            // Firefox
            if (e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
              quotaExceeded = true;
            }
            break;
        }
      } else if (e.number === -2147024882) {
        // Internet Explorer 8
        quotaExceeded = true;
      }
    }
    return quotaExceeded;
  }

  public modifyStringWithHtmlElement(str, wordsToReplace, ele: string): string {
    str = str.replace(wordsToReplace, ele);
    return str;
  }
}
