import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter
} from '@angular/core';
import { CompleterService, CompleterData } from 'ng2-completer';
import { Autopopulate } from './shared/autopopulate.model';
import { AutopopulateService } from './shared/autopopulate.service';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import 'rxjs/Rx';

@Component({
  selector: 'autopopulate',
  moduleId: module.id,
  templateUrl: 'autopopulate.component.html',
  styleUrls: ['./autopopulate.component.sass'],
  encapsulation: ViewEncapsulation.None,
  providers: [AutopopulateService]
})
export class AutopopulateComponent implements OnInit {
  constructor(
    private autopopulateService: AutopopulateService,
    private completerService: CompleterService
  ) {
    this.items = [
      {
        id: 1,
        text: 'Eswar'
      }
    ];
  }
  autopopulate: Autopopulate[] = [];
  searchString: string;
  @Input() choice: string;
  targetedView = this.choice;
  userSelectedObject: object;
  localData: any;

  public items: Array<any> = [];

  @Output() onAutoPopulationValueSelected: EventEmitter<
    boolean
  > = new EventEmitter<boolean>();

  searchStr: string;
  dataService: CompleterData;

  ngOnInit() {
    switch (this.choice.trim()) {
      case 'costlookup':
        break;
      default:
        break;
    }
  }

  public show() {
    this.items = [];

    this.items = [
      {
        id: 1,
        text: 'Eswar'
      }
    ];
    // this.localData.forEach((item:{searchResult:string},index) => {
    //   this.items.push({
    //     id :index+1,
    //     text:item.searchResult
    //   });
    // });

    // let timedRes = Observable.from([this.localData]).delay(3000)
    // this.dataService = this.completerService.local(this.localData, this.searchStr, 'searchResult')
  }

  public keyup($event) {
    // this.autopopulateService.onSearch().subscribe(
    //   (data) => this.localData = data["result"],
    //   (error) => console.log("Search not working"),
    //   () => this.show()
    // );
    // this.dataService = this.completerService.remote('http://localhost:3000/result?searchResult=', null, "searchResult");
  }

  public onSelected($event) {
    if ($event !== null) {
      this.onAutoPopulationValueSelected.emit($event['originalObject']);
    }
  }
}
