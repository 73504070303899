import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';

@Component({
    selector: 'child-cell',
    template: `<div class="position-relative status-tooltip-value">
     <p class="block-title full-width pull-left margin-bottom-0">
    <a class="text-decoration-none"><!-- data-toggle="modal" data-target="#helpPopUp"-->
        <b class="question-mark" (mouseenter)="showTooltip($event)" (mouseleave)="hideTooltip($event)">?</b>
    </a>
</p>

<div class="custom-tooltip right" id='helpToolTip{{val}}' [perfectScrollbar]="scrollBarConfiguration" >
	<div class="tip-title">Tip</div>
	<div class="tip-content">{{val}}</div>
</div> </div>
`,
})
export class HelpRendererComponent {

    scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
    public params: any;
    val: any;
    message = '';

    constructor() {


    }
    agInit(params: any): void {
        this.val = params.value;

    }

    OnClick() {

    }

    showTooltip(event) {
        this.message = this.val;

        console.log(this.message);
        document.getElementById('helpToolTip' + this.val).classList.add('show');
        // document.getElementById('helpToolTip').style.display='block';
    }
    hideTooltip(event) {
        document.getElementById('helpToolTip' + this.val).classList.remove('show');
        // document.getElementById('helpToolTip').style.display='none';
    }
}
