import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms'
import { Toast, BodyOutputType, ToasterService } from 'angular2-toaster'
import { IndustryNewsEventsService } from '../../shared/industry-news-events.service'
import { EventMetaInfoService } from '../event-management/event-meta-info.service'
import { statusListConfiguration, typeListConfiguration, vendorListConfiguration } from './create-event.constants'
import { CreateEventService } from './create-event.service'
import { cloneDeep } from 'lodash'
import { countryListConfiguration, stateListConfiguration, cityListConfiguration, siteListConfiguration, supplierListConfiguration } from 'app/modules/core/site-locations/site-locations.constants'
import { LocationDetails } from '../detail-location-cell-renderer/detail-location-cell-renderer.component'

export function rangeValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (control.value < 0 || control.value > 10) {
    return { rangeError: true }
  }
  return null
}

export function locationMandatoryCheckValidator(firstControl, secondControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const vendorImpacted = control.get(firstControl).value;
    const locations = control.get(secondControl).value;
    if (!vendorImpacted.length && !locations.length) { return { locationMandatoryCheck: true } }
    return null
  }
}
export interface EventPayload {
  eventId?: string
  articleIds: string[]
  title: string
  description: string
  severity: string
  type: string
  activeState: string
  vendorImpacted: string[]
  locations: []
  customerCreatedEvent: boolean
  additionalInfo?: any
}

interface EventDetails {
  eventId?: string
  title?: string
  description?: string
  type?: string
  vendorImpacted?: string[]
  activeState?: string
  severity?: number
  locations?: any[]
  articles?: any[]
}

const mergeDedupe = (arr) => {
  return [...new Set([].concat(...arr))];
}
@Component({
  selector: 'cprt-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.sass'],
  providers: [IndustryNewsEventsService],
})
export class CreateEventComponent implements OnInit {

  loaderConfig = {
    backdropBackgroundColour: 'transparent',
    fullScreenBackdrop: 'true',
    primaryColour: '#1C4E84',
    secondaryColour: '#1C4E84',
    tertiaryColour: '#1C4E84'
  }
  showPreloader = true
  showClonePreloader = false
  showCreatePreloader = false
  newsWidgetExpanded = false
  locationWidgetExpanded = false
  metaInfo = null
  @Input('showCustomerEventOnly') showCustomerEventOnly = false
  @Output() onCloseDialog: EventEmitter<any> = new EventEmitter<any>()
  @Output() onSubmitEventForm: EventEmitter<any> = new EventEmitter<any>()

  @ViewChild('newEventForm', { static: true })
  newEventForm: FormGroup
  defaultFormValues = null
  modifiedFormValues = null
  formValuesChanged = false

  locationMandatoryFlag = false
  showLocationSearch = false

  eventProperties = {} as EventDetails
  locations = []
  newsArticles = []

  vendorListConfiguration = JSON.parse(JSON.stringify(vendorListConfiguration))
  statusListConfiguration = JSON.parse(JSON.stringify(statusListConfiguration))
  typeListConfiguration = JSON.parse(JSON.stringify(typeListConfiguration))

  eventListConfiguration = {
    label: 'Select Global Event to clone',
    type: 'newautosearch',
    filterConfig: {
      multiSelect: false,
      targetedType: 'riskManagementService',
      servicePath: 'events/global',
      defaultItemSelection: false,
      defaultSelectedItem: null,
      paramsKey: 'titleLike',
      idKey: 'eventId',
      valueKey: 'title'
    },
    specialVersion: true,
    val: 'events',
    data: [],
    id: `global-event-select`,
    defaultParameter: null,
    resetValues: null,
    name: 'events'
  }
  selectedGlobalEvent: any;
  searchDialogPosition = {
    left: null,
    top: null,
    width: 0,
    height: 0
  }
  searchLocations = []
  disableApply = true

  constructor(
    private createEventService: CreateEventService,
    private eventMetaInfoService: EventMetaInfoService,
    private eventService: IndustryNewsEventsService,
    private toasterService: ToasterService
  ) {
    this.metaInfo = this.eventMetaInfoService.getEventMetaInfo()
    this.setStaticDropdownData()
    this.initModuleDetails()
    this.initSearchLocations()
  }

  ngOnInit() {
    if (!this.showCustomerEventOnly) {
      this.vendorListConfiguration.defaultParameter = {
        tenantId: -100
      }
    }
    this.getRelevantNews()
    this.getEventDetails()
  }

  initFormBuilder() {
    this.configureForm()
  }

  setStaticDropdownData() {
    this.statusListConfiguration.data = Object.assign([], this.metaInfo.statusList)
    this.typeListConfiguration.data = Object.assign([], this.metaInfo.typesList)
  }

  initModuleDetails() {
    this.eventProperties.title = null
    this.eventProperties.description = null
    this.eventProperties.severity = null
    this.eventProperties.activeState = null
    this.eventProperties.type = null
    this.eventProperties.vendorImpacted = []
    this.eventProperties.locations = []
    this.eventProperties.articles = []
  }

  initLocationDetails(location) {
    location.country = null
    location.activeState = null
    location.city = null
    location.siteImpacted = null
    return location
  }

  initSearchLocationDetails(location) {
    location.country = null
    location.state = null
    location.city = null
    location.vendorImpacted = []
    return location
  }

  configureForm() {
    this.newEventForm = new FormGroup({
      userData: new FormGroup({
        title: new FormControl(null, [Validators.required]),
        description: new FormControl(null, [Validators.required]),
        severity: this.showCustomerEventOnly ? new FormControl(null) : new FormControl(null, [Validators.required, rangeValidator]),
        activeState: this.showCustomerEventOnly ? new FormControl(null) : new FormControl(null, [Validators.required]),
        type: new FormControl(null, [Validators.required]),
        vendorImpacted: new FormControl([]),
        locations: new FormControl([]),
        articles: new FormControl([])
      }),
    })
    this.newEventForm.setValue({
      userData: JSON.parse(JSON.stringify(this.eventProperties)),
    })
  }

  get f() {
    return this.newEventForm.controls
  }

  getEventDetails() {
    if (this.metaInfo.eventData) {
      this.eventProperties.title = this.metaInfo.eventData.title
      this.eventProperties.description = this.metaInfo.eventData.body
      this.eventProperties.severity = null
      this.metaInfo.eventData.vendorImpacted = this.metaInfo.eventData.vendorImpacted ? this.metaInfo.eventData.vendorImpacted : []
      this.eventProperties.locations = this.eventProperties.locations ? this.eventProperties.locations : []
      this.eventProperties.vendorImpacted = this.metaInfo.eventData.vendorImpacted.map(vendor => vendor.toUpperCase())
      this.eventProperties.locations = this.metaInfo.eventData.locations.map(location => {
        location.siteImpacted = location.siteImpacted || 'ALL'
        location.city = location.city && location.city.toUpperCase()
        location.state = location.state && location.state.toUpperCase()
        location.country = location.country && location.country.toUpperCase()
        return location
      })
      this.locations = cloneDeep(this.eventProperties.locations)
      this.eventProperties.type = this.metaInfo.eventData.primaryCategory[0].toLowerCase()
      this.eventProperties.articles = [{
        articleId: this.metaInfo.eventData.documentId || this.metaInfo.eventData.resourceId,
        articleTitle: this.metaInfo.eventData.title,
        articleSentiment: this.metaInfo.eventData.sentiment,
        url: this.metaInfo.eventData.newsUrl,
        provider: this.metaInfo.eventData.provider,
        publishedDate: this.metaInfo.eventData.published,
        validData: true
      }]
      this.newsArticles = cloneDeep(this.eventProperties.articles)
      this.eventProperties.activeState = 'Active'
      this.setSelectedValue(this.vendorListConfiguration.filterConfig, this.eventProperties.vendorImpacted)
      this.setSelectedValue(this.typeListConfiguration, this.eventProperties.type)

      this.initFormBuilder()
      setTimeout(() => {
        this.showPreloader = false
        this.showClonePreloader = false
      }, 100)
      return
    }
    if (this.metaInfo.eventId === null) {
      this.initFormBuilder()
      setTimeout(() => {
        this.showClonePreloader = false
        this.showPreloader = false
      }, 100)
      return
    }
    const payload = {
      eventIds: [this.metaInfo.eventId],
      filter: []
    }
    this.createEventService.getEventDetails(payload).subscribe(
      (eventDetailResponse) => {
        if (eventDetailResponse && eventDetailResponse.result) {
          const detailsResult = eventDetailResponse.result.length > 0 ? (eventDetailResponse.result[0] || null) : null
          if (detailsResult !== null) {
            this.eventProperties.title = detailsResult.title
            if (this.metaInfo.isClone) {
              this.eventProperties.title = 'Clone: ' + this.eventProperties.title
            }
            this.eventProperties.description = detailsResult.description
            this.eventProperties.type = detailsResult.type
            this.eventProperties.activeState = detailsResult.activeState
            this.eventProperties.severity = detailsResult.severity
            this.eventProperties.vendorImpacted = detailsResult.vendorImpacted
            this.eventProperties.locations = detailsResult.locations
            this.eventProperties.articles = detailsResult.eventDetails.articles.sort((article1: any, article2: any) => {
              return new Date(article2.publishedDate).valueOf() - new Date(article1.publishedDate).valueOf();
            });
            this.locations = cloneDeep(this.eventProperties.locations)
            this.newsArticles = cloneDeep(this.eventProperties.articles)
            this.setSelectedValue(this.vendorListConfiguration.filterConfig, detailsResult.vendorImpacted)
            this.setSelectedValue(this.statusListConfiguration, detailsResult.activeState)
            this.setSelectedValue(this.typeListConfiguration, detailsResult.type)
            this.initFormBuilder()
            setTimeout(() => {
              this.showPreloader = false
              this.showClonePreloader = false
            }, 100)
          }
        }
      })
  }

  setSelectedValue(filterConfig, value) {
    filterConfig.defaultItemSelection = true
    filterConfig.defaultSelectedItem = value
  }

  toggleExpansion(type) {
    switch (type) {
      case 'news':
        this.newsWidgetExpanded = !this.newsWidgetExpanded
        break
      case 'locations':
        this.locationWidgetExpanded = !this.locationWidgetExpanded
    }
    this.setSelectedValue(this.vendorListConfiguration.filterConfig, this.newEventForm.value.userData.vendorImpacted)
    this.setSelectedValue(this.statusListConfiguration, this.newEventForm.value.userData.activeState)
    this.setSelectedValue(this.typeListConfiguration, this.newEventForm.value.userData.type)
  }

  getRelevantNews() {
    const formParams = {
      limit: 50,
      search: false
    }
    if (this.metaInfo.eventId !== null) { formParams['eventId'] = this.metaInfo.eventId }
    this.createEventService.getNewsArticles(formParams).subscribe((articlesResponse) => {
      if (articlesResponse && articlesResponse.articles) {
        if (this.metaInfo.eventData !== null) {
          const associatedArticle = articlesResponse.articles.find(article => article.documentId === this.metaInfo.eventData.documentId)
          if (associatedArticle) {
            associatedArticle.associated = true
          }
          this.eventMetaInfoService.setEventData(null)
        }
        this.eventMetaInfoService.setNewsList(articlesResponse.articles)
      } else {
        this.eventMetaInfoService.setNewsList([])
      }
    })
  }

  onCloseEventDialog() {
    this.onCloseDialog.emit()
  }

  onConfirmLocationDetails(locations) {
    this.locations = Object.assign([], locations)
    const resultSupplierList = []
    resultSupplierList.push(this.newEventForm.value.userData.vendorImpacted)
    locations.forEach(loc => {
      if (loc.vendorImpacted) {
        resultSupplierList.push(...loc.vendorImpacted)
      }
    })
    // console.log(mergeDedupe(resultSupplierList))
    this.setSelectedValue(this.vendorListConfiguration.filterConfig, mergeDedupe(resultSupplierList))
    this.locationWidgetExpanded = false
  }

  onEventPropSelect(params) {
  }

  onSubmitLinkNews(articles) {
    this.newsArticles = Object.assign([], articles)
    this.newsWidgetExpanded = false;
  }
  isValidEventForm() {
    const modifiedDetails = JSON.parse(JSON.stringify(this.newEventForm.value.userData))
    this.locationMandatoryFlag = !modifiedDetails.vendorImpacted.length && !modifiedDetails.locations.length ? true : false
    return this.newEventForm.valid && !this.locationMandatoryFlag
  }
  onClickSubmit() {
    if (this.isValidEventForm()) {

      const eventPayload = {} as EventPayload
      eventPayload.title = this.newEventForm.value.userData.title
      eventPayload.description = this.newEventForm.value.userData.description
      eventPayload.severity = this.newEventForm.value.userData.severity
      eventPayload.type = this.newEventForm.value.userData.type
      eventPayload.activeState = this.newEventForm.value.userData.activeState
      eventPayload.vendorImpacted = this.newEventForm.value.userData.vendorImpacted
      eventPayload.locations = this.newEventForm.value.userData.locations
      eventPayload.articleIds = this.newEventForm.value.userData.articles.map(article => article.articleId)
      if (this.metaInfo.isClone) {
        eventPayload.additionalInfo = {
          parentId: this.metaInfo.eventId
        }
      } else {
        eventPayload.eventId = this.metaInfo.eventId
      }
      this.createEvent(eventPayload)
    }
    this.newEventForm.markAllAsTouched()
  }

  onEventClonePropSelect(params) {
    if (params.selectedItem) {
      this.showClonePreloader = true
      this.selectedGlobalEvent = params.selectedItem
      this.eventMetaInfoService.setEventId(this.selectedGlobalEvent.val)
      this.ngOnInit()
    } else {
      this.selectedGlobalEvent = null
    }
  }
  createEvent(eventPayload) {

    // in-case of customer created events set default status as active
    if (this.showCustomerEventOnly) {
      eventPayload.customerCreatedEvent = true;
      eventPayload.activeState = 'Active';
    }

    this.showCreatePreloader = true
    if (!!eventPayload.eventId) {
      this.eventService
        .updateEvent([eventPayload])
        .subscribe((updateEventResponse) => {
          if (updateEventResponse && updateEventResponse.result) {
            if (updateEventResponse.responseStatus.code === 200) {
              this.message('success', 'Event details have been updated successfully')
              this.onSubmitEventForm.emit(true)
            } else {
              this.message('warning', 'Event details updating is not successful')
            }
          }
          if (updateEventResponse && updateEventResponse.responseStatus.code === 200 && !updateEventResponse.result) {
            this.message('warning', updateEventResponse.message || 'Event Modification Failed')
          }
          this.showCreatePreloader = false
        })
    } else {
      this.eventService
        .createNewEvent(eventPayload)
        .subscribe((newEventResponse) => {
          if (newEventResponse && newEventResponse.result) {
            if (newEventResponse.responseStatus.code === 200) {
              const logMessage
                = this.metaInfo.isClone
                  ? 'Event is cloned successfully'
                  : 'Event is created successfully'
              this.message('success', logMessage)
              this.onSubmitEventForm.emit(true)
            } else {
              const logMessage
                = this.metaInfo.isClone
                  ? 'Event cloning is not successful'
                  : 'Event is not created'
              this.message('warning', logMessage)
            }
          }
          if (newEventResponse && newEventResponse.responseStatus.code === 200 && !newEventResponse.result) {
            const logMessage
              = this.metaInfo.isClone
                ? 'Event Cloning Failed'
                : 'Event Creation Failed'
            this.message('warning', newEventResponse.message || logMessage)
          }
          this.showCreatePreloader = false
        })
    }
  }
  message(type: string, message: string) {
    const toast: Toast = {
      type,
      body: '<div vertical-align=middle>' + message + '</div>',
      bodyOutputType: BodyOutputType.TrustedHtml,
    }
    this.toasterService.pop(toast)
  }

  initSearchLocations() {
    this.searchLocations = []
    const location = JSON.parse(JSON.stringify(this.getLocationObject(true)))
    this.searchLocations.push(location)
  }

  getLocationObject(isSearch = false) {
    const locObj = {
      countryObject: {
        name: 'country',
        config: JSON.parse(JSON.stringify(countryListConfiguration)),
        resetValues: this.generateRandomKey()
      },
      stateObject: {
        name: 'state',
        config: JSON.parse(JSON.stringify(stateListConfiguration)),
        resetValues: this.generateRandomKey()
      },
      cityObject: {
        name: 'city',
        config: JSON.parse(JSON.stringify(cityListConfiguration)),
        resetValues: this.generateRandomKey()
      },
      mandatoryFilledCheck: false
    }

    const siteObj = {
      siteObject: {
        name: 'siteImpacted',
        config: JSON.parse(JSON.stringify(siteListConfiguration)),
        resetValues: this.generateRandomKey()
      },
      properties: this.initLocationDetails({} as LocationDetails)
    }

    const supplierObj = {
      supplierObject: {
        name: 'vendorImpacted',
        config: JSON.parse(JSON.stringify(supplierListConfiguration)),
        resetValues: this.generateRandomKey(),
      },
      properties: this.initSearchLocationDetails({} as LocationDetails)
    }

    let filterObj = {}
    if (isSearch) {
      filterObj = { ...locObj, ...supplierObj }
    } else {
      filterObj = { ...locObj, ...siteObj }
    }
    return filterObj
  }

  generateRandomKey() {
    const crypto = window.crypto
    const array = new Uint32Array(1)
    return crypto.getRandomValues(array)[0]
  }

  clickSupplierSearch(event) {
    this.showLocationSearch = true
    this.searchDialogPosition.left = (window.innerWidth * 30 / 100) + 30
    this.searchDialogPosition.top = event.clientY + 30
    this.searchDialogPosition.width = window.innerWidth - event.clientX
    this.searchDialogPosition.height = window.innerHeight - event.clientY - 150
  }

  onLocationPropSelect(params, locationRow?, locationIndex?) {
    if (locationRow && locationIndex !== undefined) {
      const selectedValues = params.selectedItem
        ? (params.selectedItem.val || null)
        : params.selectedItems
          ? params.selectedItems
          : null
      if (locationRow.properties.hasOwnProperty(params.type)) {
        locationRow.properties[params.type] = selectedValues
      }
      switch (params.type) {
        case 'country':
          locationRow.stateObject.config.defaultParameter = {
            country: selectedValues,
          }
          locationRow.stateObject.config.isDisable = false
          locationRow.stateObject.resetValues = this.generateRandomKey()
          locationRow.cityObject.resetValues = this.generateRandomKey()
          locationRow.supplierObject.resetValues = this.generateRandomKey()
          break
        case 'state':
          locationRow.cityObject.config.defaultParameter = {
            state: selectedValues,
          }
          locationRow.cityObject.config.isDisable = false
          locationRow.cityObject.resetValues = this.generateRandomKey()
          locationRow.supplierObject.resetValues = this.generateRandomKey()
          break
        case 'city':
          locationRow.supplierObject.config.defaultParameter = {
            country: locationRow.properties.country,
            state: locationRow.properties.state,
            city: locationRow.properties.city,
          }
          locationRow.supplierObject.config.isDisable = false
          locationRow.supplierObject.resetValues = this.generateRandomKey()
          break
      }
      this.searchLocations[locationIndex].properties = JSON.parse(
        JSON.stringify(locationRow.properties)
      )
      this.searchLocations[locationIndex].mandatoryFilledCheck
        = !!locationRow.properties.country && !!locationRow.properties.city && !!locationRow.properties.state && locationRow.properties.vendorImpacted.length
          ? true
          : false
      this.mandatoryFilledCheck()
    }
  }
  mandatoryFilledCheck() {
    const validLocations = this.searchLocations.filter(location => location.mandatoryFilledCheck).map(location => location.properties)
    this.disableApply
      = (this.searchLocations.length === validLocations.length)
        ? false
        : true
  }

  addLocation(rowIndex, event) {
    event.stopPropagation()
    this.searchLocations.push(JSON.parse(JSON.stringify(this.getLocationObject(true))))
    this.mandatoryFilledCheck()
  }

  deleteLocation(rowIndex, selectedLocation, event) {
    event.stopPropagation()
    this.searchLocations.splice(rowIndex, 1)
    this.mandatoryFilledCheck()
  }

  onClearAllLocations() {
    this.searchLocations = []
    this.initSearchLocations()
    this.mandatoryFilledCheck()
  }

  onApplyClick(event) {
    event.stopPropagation()
    const addedSuppliers = []
    addedSuppliers.push(this.newEventForm.value.userData.vendorImpacted)
    this.searchLocations.forEach(location => {
      if (location.mandatoryFilledCheck) {
        addedSuppliers.push(location.properties.vendorImpacted)
      }
    })

    // console.log(mergeDedupe(addedSuppliers))
    this.vendorListConfiguration.resetValues = this.generateRandomKey()
    this.setSelectedValue(this.vendorListConfiguration.filterConfig, mergeDedupe(addedSuppliers))
    this.closeSearchSuppliers()
  }

  closeSearchSuppliers() {
    this.showLocationSearch = false
    this.disableApply = true
    this.searchLocations = []
    const location = JSON.parse(JSON.stringify(this.getLocationObject(true)))
    this.searchLocations.push(location)
  }

}
