export const AUTHENTICATION_CONSTANT = {
    urls: {
        login: '/login',
        dashboard: '/app/dashboard',
        forgot: '/forgot',
        changePassword: '/changepassword',
        forgotPassword: '/forgotUserPassword',
        resetPassword: '/resetPassword',
        landingPage: 'defaultlandingpage'
    },
    titles: {
        forgotPasswordTitle: 'Forgot your password? Enter your email address to reset it.',
        enterPassword: 'Enter your password and confirm password.',
        redirectMsg: 'Redirecting to login page...',
        enterResetPassword: 'Enter your new and confirm password.',
        enterChangePassword: 'Enter your current and new passwords.',
        firstTimePasswordChange: 'Your password must be changed. Enter your current and new passwords.',
        linkExpired: 'Sorry, the link has expired. Please contact administrator',
        passwordChangeSuccess: 'Your password has been changed successfully.',
        passwordUpdateSuccess: 'Your password has been successfully updated.',
        forgotEmailSent: 'Email sent successfully.'
    },
    buttons: {
        next: 'Next',
        login: 'Login',
        setPassword: 'Set Password',
        resetPassword: 'Reset Password',
        backToLogin: 'Back to Login',
        forgotPassword: 'Forgot Password?',
        changePassword: 'Change Password'
    },
    inputFields: {
        email: {
            placeholder: 'Email',
            title: 'Email'
        },
        password: {
            placeholder: 'Password',
            title: 'Password'
        },
        confirmPassword: {
            placeholder: 'Confirm Password',
            title: 'Confirm Password'
        },
        currentPassword: {
            placeholder: 'Current Password',
            title: 'Current Password'
        },
        newPassword: {
            placeholder: 'New Password',
            title: 'New Password'
        },
    },
    errors: {
        userConfig: 'Failed While Getting User Configuration Data',
        accessDenied: 'Access Denied',
        invalidAuthCode: 'Invalid Authentication Code. Please try again!!!',
        userActivationPending: 'User Activation is Pending',
        invalidEmailAddress: 'Please enter valid Email Address',
        invalidEmail: 'Invalid email.',
        passwordRequirement: 'Password does not meet requirements.',
        samePasswords: 'Current and new passwords are identical.',
        differentPasswords: 'Passwords do not match.',
        min8Char: 'Minimum 8 characters',
        number: 'Number (0-9)',
        specialChar: 'Special character (!@#$...)',
        upperCaseLtr: 'Uppercase letter (A-Z)',
        lowerCaseLtr: 'Lowercase letter (a-z)',
        noSpace: 'No spaces',
        somethingWrong: 'Something went wrong, Please contact administrator.',
        errorToSetPassword: 'Error in Setting The Password, Please Try Again.',
        activeUser: 'User Already Activated.',
        linkExpired: 'Sorry, the link has expired.'
    },
    tooltips: {
        min8CharMet: 'Minimum length requirement met',
        numberMet: 'Number requirement met',
        specialCharMet: 'Special character requirement met',
        upperCaseLtrMet: 'Uppercase letter requirement met',
        lowerCaseLtrMet: 'Lowercase letter requirement met',
        noSpaceMet: 'No spaces requirement met',
        min8CharUnmet: 'Minimum length requirement unmet',
        numberUnmet: 'Number requirement unmet',
        specialCharUnmet: 'Special character requirement unmet',
        upperCaseLtrUnmet: 'Uppercase letter requirement unmet',
        lowerCaseLtrUnmet: 'Lowercase letter requirement unmet',
        noSpaceUnmet: 'No spaces requirement unmet',
    },
    type: {
        forgotPassword : 'forgotpassword',
        userActivation : 'useractivation'
    },
    changePassword: 'change-password',
    resetPassword: 'reset-password',
    MAX_PASSWORD_LENGTH: 128,
}
