import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges
} from '@angular/core';

@Component({
  selector: 'calculation',
  templateUrl: './calculation.component.html',
  styleUrls: ['./calculation.component.sass'],
  providers: []
})

export class Calculation {
  @Output() calculationChanged: EventEmitter<Object> = new EventEmitter<Object>();
  @Input() calculationTypes = [];
  @Input() calculation: any = {
    formula: '',
  };
  selectedCalculationType ;

  ngOnChanges(changes: SimpleChanges) {
    if(this.calculationTypes &&
      this.calculationTypes.length
    ) {
      if(this.calculation['calculationType'] ) {
        for(let i=0; i< this.calculationTypes.length; i++){
          if(this.calculationTypes[i].refTermActualValue === this.calculation['calculationType']) {
          this.selectedCalculationType = this.calculationTypes[i];
          }
        }
      } else {
        this.selectedCalculationType = this.calculationTypes[0];
      }
    }
  }


  calculationChange(key, value) {
    this.calculation[key] = value
    if(this.calculation[key] === 'BLANK'){
      this.calculation.formula = '';
    }
    this.calculationChanged.emit(true);
  }

  onBlur() {
    this.calculationChanged.emit(true);
  }

}
