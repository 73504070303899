export const isSelectionParentOfTarget = (
  selectedNode,
  targetNode,
  val?: boolean
) => {
  if (val) {
    return val;
  }
  const children = selectedNode.childrenAfterGroup;
  let returnVal = false;
  for (let i = 0; i < children.length; i++) {
    if (targetNode && children[i].key === targetNode.key) {
      returnVal = true;
      break;
    } else {
      returnVal = isSelectionParentOfTarget(children[i], targetNode, returnVal);
    }
  }
  return returnVal;
};
