import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { SocketService } from 'app/modules/common/socket/scoket.service';
import { ScopeDataService } from 'app/modules/common/scope.data.service';

@Component({
  selector: 'app-bot-viewdetails',
  templateUrl: './app-bot-viewdetails.component.html',
  styleUrls: ['./app-bot-viewdetails.component.sass']
})

export class AppBotViewdetailsComponent implements OnInit {

  constructor(
    private router: Router,
    private socketService: SocketService,
    private scopeDataService: ScopeDataService
  ) {

  }
  optionMessage: Array<any> = ['You have viewed this alert', 'You have ignored it'];
  @Input() parameters: any;
  showMessage = false;
  question: string;
  options: Array<string> = [];
  actionMessgae= '';

  ngOnInit() {
    this.question = '<spna>' + this.parameters['alert']['SUMMARY'] + '</spna> : ' + '<b>' + this.parameters['alert']['displayOpportunityCost'] + '</b>';
    this.options = this.parameters.options;
  }

  onOptionClick(target) {

    if (target !== undefined) {
      switch (target.toLowerCase()) {
        case ('view_details'):
        this.actionMessgae = this.optionMessage[0];
          const dataObject: {} = this.parameters.alert;
          if (dataObject instanceof Object) {
            if (dataObject['SCOPE'] !== undefined) {
              if (dataObject['SCOPE'] !== this.scopeDataService.getFocusValue()) {
                this.scopeDataService.setFocus(dataObject['SCOPE']);
              }
            }
            if (dataObject['COMPONENT_URL'] !== undefined) {
              if (window.location.href.indexOf('opportunities') == -1) {
                this.router.navigate(['/app/' + dataObject['COMPONENT_URL']]);
                this.socketService.onNormalSetSelectedOpportunites(dataObject);
              }else{
                this.socketService.onSetSelectedOpportunites(dataObject);
              }
            }
          }
          break;
        case ('no'):
          break;

      }
    }
    this.showMessage = true;
  }
}
