import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core'
import { DashboardMetricTrendsService } from './dashboard-metric-trends.service';
import * as moment from 'moment-timezone-all';
@Component({
  selector: 'app-dashboard-metric-trends',
  templateUrl: './dashboard-metric-trends.component.html',
  styleUrls: ['./dashboard-metric-trends.component.sass'],
  encapsulation: ViewEncapsulation.None,
  providers: [DashboardMetricTrendsService],
})
export class DashboardMetricTrendsComponent implements OnInit {
  @Input() configuration

  @ViewChild('graphContainer', { static: true }) graphContainer: ElementRef;
  @ViewChild('customTooltip', { static: false }) customTooltip: ElementRef;

  minValue = null
  maxValue = null
  graphContainerPXWidth = 0
  rowHeight = '100%'
  subscriptionRequests = [];
  clientWidth = 0;
  pointsDifferenceWidth = 0;
  leftOffset = 0;
  month;
  constructor(
    private dashboardMetricTrendsService: DashboardMetricTrendsService
  ) {
  }
  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    if (this.configuration && this.configuration.data && this.configuration.data.data.length
      && this.configuration.data.data[0].value
      && Object.keys(this.configuration.data.data[0].value).length) {
      this.findMaxValue();
    }
  }

  ngOnInit() {
    const graphRequestPayload = {
      targetedType: 'riskManagementService',
      method: 'GET',
      servicePath: `trends/dashboard/summary`,
    }
    this.getCardData(graphRequestPayload);
    this.month = this.threeMonthsPrior();
  }
  getCardData(requestPayload) {
    this.configuration.data.status = 'in-progress'
    this.subscriptionRequests[
      `card${requestPayload.id}`
    ] = this.dashboardMetricTrendsService
      .getDashboardCardDetails(requestPayload)
      .subscribe((details) => {
        if (details && details.status === 200) {
          /* details.result.data[0].values[0] = 26.75;
          details.result.data[0].values[1] = 21.666666666666668;
          details.result.data[0].values[2] = -2.2098765432098766;
          details.result.data[0].values[3] = 1; */
          if (details.result !== null) {
            this.configuration.data.data[0].value = details && details.result ? details.result : [];
            this.rowHeight = `${100 / (details.result.data.length)}%`;
            this.findMaxValue();
          } else {
            this.configuration.data = [];
            this.configuration.data.status = 'done';
          }
        }
      })
  }
  findMaxValue() {
    this.configuration.data.data[0].value.data.forEach((element) => {
      if (element) {
        if (this.maxValue === null) {
          this.maxValue = element.values[0];
        }
        if (Math.abs(element.values[0]) > this.maxValue) {
          this.maxValue = Math.abs(element.values[0]);
        }
        if (Math.abs(element.values[1]) > this.maxValue) {
          this.maxValue = Math.abs(element.values[1]);
        }
        if (this.minValue === null) {
          this.minValue = element.values[0];
        }
        if (Math.abs(element.values[0]) < this.minValue) {
          this.minValue = Math.abs(element.values[0]);
        }
        if (Math.abs(element.values[1]) < this.minValue) {
          this.minValue = Math.abs(element.values[1]);
        }
      }
    });
    this.clientWidth = this.graphContainer.nativeElement.clientWidth - 120;
    this.graphContainerPXWidth = this.clientWidth / (this.maxValue - this.minValue);
    this.leftOffset = Math.round(this.minValue * this.graphContainerPXWidth);
    this.configuration.data.status = 'done';
  }
  makePositiveNumber(number) {
    return Math.abs(number)
  }
  findPosition(trend, target?) {
    let position = 0;
    if (trend && target) {
      if (target === 'min') {
        position = Math.round(Number(trend.values[0]) * this.graphContainerPXWidth) - this.leftOffset;
      } else if (target === 'max') {
        position = Math.round(Number(trend.values[1]) * this.graphContainerPXWidth) - this.leftOffset;
      } else if (target === 'diff') {
        if (trend.values[3] === -1) {
          position = (Math.round(Number(trend.values[0]) * this.graphContainerPXWidth) + 40) - this.leftOffset;
        } else if (trend.values[3] === 1) {
          position = (Math.round(Number(trend.values[1]) * this.graphContainerPXWidth) + 30) - this.leftOffset;
        }
      }
    }
    return `${position}px`
  }
  findPositionTool(trend, target?) {
    let position = 0;
    if (trend && target) {
      if (target === 'min') {
        position = Math.round(Number(trend.values[0]) * this.graphContainerPXWidth) - this.leftOffset;
        return `calc(1% - ${position * 2 / this.graphContainerPXWidth}px)`
      } else if (target === 'max') {
        position = Math.round(Number(trend.values[1]) * this.graphContainerPXWidth) - this.leftOffset;
      }
    }
    return `calc(1% - ${position * 2 / this.graphContainerPXWidth}px)`
  }

  findWidth(point1, point2) {
    this.pointsDifferenceWidth = (Math.abs(point1 - point2) * this.graphContainerPXWidth - 40);
    return this.pointsDifferenceWidth > 0 ? `${this.pointsDifferenceWidth}px` : '0px;'
  }

  public threeMonthsPrior() {
    return moment().subtract(4, 'months').format("MMMM");
  }
}
