import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseService } from 'app/modules/common/base.service';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationError
} from '@angular/router';
import { environment } from 'environments/environment';
import { Angulartics2Piwik } from 'angulartics2/piwik';
import { Angulartics2Segment } from 'angulartics2/segment';
import { Angulartics2 } from 'angulartics2';
import { Title } from '@angular/platform-browser';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { SubscriptionLike, Observable, Subject } from 'rxjs';
import { ngxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import { UserIdleService } from 'angular-user-idle';
import { UtilService } from 'app/modules/common/utills/util.service';
import { WindowService } from './modules/common/window.service';
import {
  ClientTokenOptions,
  ClientTokenService,
  ClientTokenType,
} from './modules/common/client-token.service';
import { ToasterConfig } from 'angular2-toaster';
import { PageLoaderService } from './modules/common/utills/page-loader.service';
import { DataStorageService } from './modules/common/data-storage.service';
import {SharedService} from './modules/shared/services/shared.service';
import {
  CUSTOMER_CURRENT_USER,
  CUSTOMER_CUSTOMER_DATA,
  CUSTOMER_LOGIN_RESPONSE,
  CUSTOMER_NAVIGATE_ROUTE_EXISTS,
  CUSTOMER_SESSION_EXPIRED,
  CUSTOMER_TOKEN_INFO
} from './modules/shared/services/shared-consts';
import { OLD_URL_MAP } from './modules/shared/constants/shared.constants';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  providers: [],
})

export class AppComponent implements OnInit, OnDestroy {
  private subject$: Subject<any> = new Subject();
  public appLoaderSubscription: SubscriptionLike;
  public title = 'app';
  public loading = false;
  public customerName = 'levadata';
  public _scope;
  public userInfo;
  public pageUrl = '';
  public activityEvents$: any;
  public idle$: any;
  public progressBarConfig = true;
  public customerConfiguration: any;
  bannerMassages = '';
  showMaintenanceBanner = false;
  showMinimizedView = false;
  isMinimizedFirst = false;
  set scope(val) {
    this._scope = val;
  }
  get scope() {
    return this._scope;
  }
  public detectBrowser = false;
  public configToaster: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-right',
    timeout: 20000,
    limit: 1,
  });


  constructor(
    private baseService: BaseService,
    private titleService: Title,
    private scopeDataService: ScopeDataService,
    private router: Router,
    private customerConfigurationService: CustomerConfigurationService,
    private angulartics2Segment: Angulartics2Segment,
    private angulartics2Piwik: Angulartics2Piwik,
    private angulartics2: Angulartics2,
    private utilService: UtilService,
    private ngZendeskWebwidgetService: ngxZendeskWebwidgetService,
    private userIdle: UserIdleService,
    private windowService: WindowService,
    private clientTokenService: ClientTokenService,
    private pageLoaderService: PageLoaderService,
    private dataStorageService: DataStorageService,
    private sharedService: SharedService
  ) {
    this.detectBrowser = this.utilService.detectBrowser();

    /**
     * Page Loading code....
     */

    /* this.pageLoaderService.isLoading.subscribe((v) => {
        this.loading = v;
      }); */
    angulartics2Piwik.startTracking();
    sessionStorage.setItem('customer_loaded', JSON.stringify(false));
    let isTabsOpened;
    if (Object.keys(localStorage).length > 0) {
      isTabsOpened = Object.keys(localStorage).find((key) =>
        key.includes('customer_tab_id_')
      );
    }
    if (
      !isTabsOpened &&
      JSON.parse(sessionStorage.getItem('customer_isPageRefreshed')) !== true
    ) {
      console.log("monitoring the session expired pop up issue, 3")
      this.baseService.setUnAuthrizePopup(true);
      this.sharedService.setLocalStorageData(CUSTOMER_SESSION_EXPIRED, true);
    }
    this.customerConfiguration = this.customerConfigurationService.getBaseCustomerInfo();
    this.router.errorHandler = (error: any) => {
      const routerError = error.toString();
      if (routerError.indexOf('Cannot match any routes') >= 0) {
        this.router.navigate(['/app/pagenotfound']);
        this.loading = false;
      } else {
        throw error;
      }
    };
    /*private logger:Logger */
    if (environment['errorLogLevel'] !== undefined) {
      // this.logger.level = environment["errorLogLevel"];
    }
    this.appLoaderSubscription = this.baseService
      .getAppLoader()
      .subscribe((val) => {
        if (val || !val) {
          this.loading = val;
        }
      });
    this.baseService.getProgressBar().subscribe((val) => {
      if (typeof val === 'boolean') {
        this.progressBarConfig = val;
      }
    });
    this.angulartics2.settings.pageTracking.autoTrackVirtualPages = false;
    this.scope = this.scopeDataService.getFocusValue();
    this.setCustomerName();
    this.scopeDataService.getFocus().subscribe((value) => {
      this.setCustomerName();
      if (typeof value === 'string' && value.length > 0) {
        if (value === this.scope) {
        } else {
          this.scope = value;
          if (typeof this.pageUrl === 'string' && this.pageUrl.length > 0) {
            if (this.baseService.getCsApp()) {
              this.titleService.setTitle(this.customerName + ' | ');
            } else {
              this.titleService.setTitle(
                this.customerName + ' | ' + this.scope
              );
            }
            this.pageTrack(this.pageUrl);
          }
        }
      }
    });
    const loggedIn = this.baseService.getLogout();
    if (this.baseService.getLogout()) {
      this.userInfo = this.baseService.getUserInformation();
      if (
        this.userInfo instanceof Object &&
        JSON.parse(localStorage.getItem(CUSTOMER_TOKEN_INFO)) instanceof Object
      ) {
        if (
          typeof this.userInfo.email === 'string' &&
          this.userInfo.email.toString().trim().length > 0
        ) {
          this.angulartics2.setUsername.next(this.userInfo.email);
          this.angulartics2.setUserProperties.next({
            dimension1: JSON.parse(localStorage.getItem(CUSTOMER_TOKEN_INFO))
              .session_state,
          });
        }
      }
    }
    this.clearAjaxCalls('userLoggedIn');
    this.ajaxRequests['userLoggedIn'] = this.baseService.userLoggedIn.pipe(takeUntil(this.subject$)).subscribe(
      (val) => {
        this.userInfo = this.baseService.getUserInformation();
        if (val !== '' && val !== null) {
          /* setInterval(() => {
            this.checkApplicationStatus(this.userInfo.tenantId);
             this.checkApplicationStatus(225);
          }, 15000); */

          this.userIdle.startWatching();
          this.userIdle.onTimerStart().pipe(takeUntil(this.subject$)).subscribe((count) => { });
          this.userIdle.onTimeout().pipe(takeUntil(this.subject$)).subscribe(() => {
            this.baseService.setAlertPopupAfterTimeout(true);
          });
        }
        if (
          this.userInfo instanceof Object &&
          JSON.parse(localStorage.getItem(CUSTOMER_TOKEN_INFO)) instanceof
          Object
        ) {
          if (
            typeof this.userInfo.email === 'string' &&
            this.userInfo.email.toString().trim().length > 0
          ) {
            this.angulartics2.setUsername.next(this.userInfo.email);
            this.angulartics2.setUserProperties.next({
              dimension1: JSON.parse(localStorage.getItem(CUSTOMER_TOKEN_INFO))
                .session_state,
            });

            /*
             * Zendesk settings
             */
            this.ngZendeskWebwidgetService.hide();
            this.ngZendeskWebwidgetService.identify({
              name: this.userInfo.fullName,
              email: this.userInfo.email,
            });
            this.angulartics2.eventTrack.next({
              action: 'demoAction',
              properties: { category: 'myCategory' },
            });

            const zendeskTokenOptions: ClientTokenOptions = {
              payload: {
                iat: Math.floor(Date.now() / 1000),
                jti: this.clientTokenService.generateJwtId(),
                name: this.userInfo.fullName,
                email: this.userInfo.email,
              },
            };
            this.ngZendeskWebwidgetService.setSettings({
              authenticate: {
                jwt: this.clientTokenService.issue(
                  ClientTokenType.ZENDESK,
                  zendeskTokenOptions
                ),
              },
              webWidget: {
                offset: {
                  vertical: '-8px',
                },
              },
            });
          }
        }
      }
    );
  }

  ajaxRequests: any = {};
  clearAjaxCalls(key?: string) {
    if (typeof key === 'string') {
      if (this.ajaxRequests[key] instanceof Object) {
        this.ajaxRequests[key].unsubscribe();
        delete this.ajaxRequests[key];
      }
      return;
    }
    for (const req in this.ajaxRequests) {
      if (this.ajaxRequests[req] instanceof Object) {
        this.ajaxRequests[req].unsubscribe();
        delete this.ajaxRequests[req];
      }
    }
  }

  checkApplicationStatus(tenantId) {
    this.ajaxRequests[
      'applicationStatus'
    ] = this.pageLoaderService
      .getApplicationStatus(tenantId)
      .subscribe((response) => {
        if (response.status === 200 && response.result instanceof Object) {
          if (response.result.bannerEnabled) {
            this.showMinimizedView = true;
            if (!this.isMinimizedFirst) {
              this.showMaintenanceBanner = true;
            }
          } else {
            this.showMaintenanceBanner = false;
            this.showMinimizedView = false;
            this.isMinimizedFirst = false;
          }
          this.bannerMassages = response.result || {};
        }
      });
  }

  minimize($event) {
    this.isMinimizedFirst = true;
    this.showMaintenanceBanner = false;
  }
  onMaximize($event) {
    this.showMaintenanceBanner = true;
  }

  public getTitleForRoute(url: string, level: number) {
    let title = '';
    let currentRoute: any = [];
    if (
      this.getTitleForRoute['currentRoute'] instanceof Object &&
      this.getTitleForRoute['currentRoute'][level] instanceof Object
    ) {
      currentRoute = this.getTitleForRoute['currentRoute'][level];
    } else {
      currentRoute = this.router.config;
    }
    if (this.getTitleForRoute['currentRoute'] === undefined) {
      this.getTitleForRoute['currentRoute'] = [];
    }
    let children: any = [];
    if (currentRoute instanceof Array) {
      children = currentRoute;
    } else {
      children = currentRoute.children;
    }
    if (children !== undefined) {
      children.forEach((e) => {
        if (e.path === url) {
          if (level === 2) {
            this.getTitleForRoute['currentRoute'][++level] = e._loadedConfig
              ? e._loadedConfig.routes[0]
              : '';
          } else {
            this.getTitleForRoute['currentRoute'][++level] = e;
          }
          title = (e.data || {}).title || '';
        }
      });
    }
    return title;
  }

  public checkStorage() {
    window.addEventListener(
      'storage',
      (storageEvent) => {
        const splittedArray = storageEvent.url.split('/');
        if (
          storageEvent.key === CUSTOMER_CURRENT_USER &&
          storageEvent.newValue === null &&
          this.router.url.includes('app')
        ) {
          window.location.reload();
          this.router.navigate(['/login']);
        } else if (
          (storageEvent.oldValue || storageEvent.newValue) &&
          (splittedArray[splittedArray.length - 1] === 'login' ||
            this.router.url.includes('changepassword')) &&
          storageEvent.key &&
          storageEvent.key.substring(0, 2) !== 'ZD' &&
          storageEvent.key === CUSTOMER_LOGIN_RESPONSE &&
          storageEvent.newValue !== null
        ) {
          setTimeout(() => {
            this.baseService.setRefreshPage(true);
          }, 500);
        } else if (
          this.router.url.includes('login') &&
          storageEvent.key &&
          storageEvent.key === CUSTOMER_CUSTOMER_DATA &&
          storageEvent.newValue !== null
        ) {
          this.baseService.setRefreshPage(true);
        }
      },
      false
    );
  }

  queryStringToJSON(queryString) {
    if (queryString.indexOf('?') > -1) {
      queryString = queryString.split('?')[1];
    }
    const pairs = queryString.split('&');
    const result = {};
    pairs.forEach((pair) => {
      pair = pair.split('=');
      result[pair[0]] = decodeURIComponent(pair[1] || '');
    });
    return result;
  }
  getURLQueryParams() {
    const urlPart = window.location.href.split('#/')[1]
    const activeRoute = OLD_URL_MAP[urlPart.split('?')[0]] ||  urlPart.split('?')[0]
    console.log('url')
    let queryParams = null
    switch (activeRoute) {
      case 'login':
      case 'oidc':
      case 'resetPassword':
      case 'forgot':
      case 'changepassword':
        break
      default:
        queryParams = urlPart.split('?').length > 1 ? this.queryStringToJSON(urlPart.split('?')[1]) : null
        if (localStorage.getItem(CUSTOMER_CUSTOMER_DATA)) {
          this.dataStorageService.setQueryParams(queryParams);
          this.router.navigate([activeRoute])
        } else {
          if (activeRoute.indexOf('contextlandingpage') !== -1) { } else {
            const navigateRouteObj = {
              route: activeRoute,
              queryParams
            }
            this.sharedService.setLocalStorageData(CUSTOMER_NAVIGATE_ROUTE_EXISTS, navigateRouteObj)
          }
        }
        break
    }
  }

  public ngOnInit() {

    this.getURLQueryParams()

    this.checkStorage();

    /* session timeout code*/

    this.activityEvents$ = Observable.merge(
      Observable.fromEvent(window, 'mousemove'),
      Observable.fromEvent(window, 'resize'),
      Observable.fromEvent(document, 'keydown'),
      Observable.fromEvent(document, 'click')
    );

    this.idle$ = Observable.from(this.activityEvents$);

    this.idle$.subscribe(() => {
      this.userIdle.resetTimer();
    });

    // ---- end of session timeout code
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loading = true;
        const userInfo = JSON.parse(
          localStorage.getItem(CUSTOMER_CURRENT_USER)
        );
        if (userInfo instanceof Object && userInfo.email) {
          const isSisenseCookiePresent = this.baseService.isCookiePresent(
            'username'
          );
          if (!isSisenseCookiePresent) {
            this.baseService.setCookie(
              'username',
              encodeURIComponent(userInfo.email),
              30,
              '/'
            );
          }
        }
      } else if (event instanceof NavigationEnd) {
        /* checking sisense cookie is present in storage else expiring the session*/
        const isSisenseCookiePresent = this.baseService.isCookiePresent(
          'username'
        );
        if (
          !isSisenseCookiePresent ||
          JSON.parse(localStorage.getItem(CUSTOMER_SESSION_EXPIRED))
        ) {
          console.log("monitoring the session expired pop up issue, 4")
          this.baseService.setUnAuthrizePopup(true);
          this.baseService.setAppLoader(false);
          this.sharedService.setLocalStorageData(
            CUSTOMER_SESSION_EXPIRED,
            true
          );
        }
        // ----- end of sisense cookie testing code
        this.loading = false;
        const parts = event.urlAfterRedirects;
        this.pageUrl = parts;
        if (typeof parts === 'string' && parts.length > 0) {
          if (this.userInfo instanceof Object) {
            if (this.baseService.getCsApp()) {
              this.titleService.setTitle(this.customerName);
            } else {
              this.titleService.setTitle(
                this.customerName + ' | ' + this.scope
              );
            }
          } else {
            this.titleService.setTitle(this.customerName);
          }
          this.pageTrack(parts);
        }
      } else if (event instanceof NavigationError) {
        try {
          this.angulartics2.eventTrack.next({
            action: 'navigationError',
            properties: JSON.stringify(event),
          });
          this.angulartics2.pageTrack.next({
            path: window.location.href,
          });
        } catch (e) {
          console.error('ERROR: page track error.');
        }
      }
    });
  }
  public setCustomerName() {
    const customerInfo = this.customerConfigurationService.getBaseCustomerInfo();
    if (customerInfo instanceof Object) {
      if (
        typeof customerInfo.actualCustomerName === 'string' &&
        customerInfo.actualCustomerName.length > 0
      ) {
        this.customerName = customerInfo.actualCustomerName;
      }
    }
  }
  public pageTrack(parts: string) {
    const splits = parts.split('/');
    if (splits instanceof Array && splits.length > 0) {
      for (let i = 0; i < splits.length; i++) {
        if (splits[i].length > 0) {
          const title = this.getTitleForRoute(splits[i], i) || '';
          if (typeof title === 'string' && title.length > 0) {
            this.titleService.setTitle(
              this.titleService.getTitle() + ' | ' + title
            );
          }
        }
      }
      this.angulartics2.pageTrack.next({
        path: window.location.href,
      });
    }
  }
  ngOnDestroy(){
    this.subject$.next();
    this.subject$.complete();
  }
}
