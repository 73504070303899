import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, RouterState, ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
// import { RouterState } from '@angular/router';
import { FeatureService } from 'app/modules/common/feature.service';
import { Location } from '@angular/common'
import { BaseService } from 'app/modules/common/base.service';
import { CuratorOpprisksComponent } from 'app/modules/curator/curatorOpprisks/curatorOpprisks.component';
import { SubscriptionService } from 'app/modules/common/subscription.service';
import { Observable } from 'rxjs';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CuratorOpprisksComponent> {
  constructor(private subscriptionService: SubscriptionService) { }

  canDeactivate(
    component: CuratorOpprisksComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return component.canDeactivate();

    // this.permissions.canDeactivate(this.currentUser, route.params.id);
  }
}




