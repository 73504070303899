import { Injectable } from '@angular/core'
import { ApiServiceProvider } from 'app/api-service';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
import { RequestData } from 'app/modules/common/models/request.model';

@Injectable()
export class LevaColumnLevelFilterService {
  public pmRequestData = new BehaviorSubject<any>('');
  public autoSearchText = new BehaviorSubject<any>('');
  public autoSearchSelected = new BehaviorSubject<any>(undefined);
  public clearFlag = new Subject<any>();
  public singleSelectSearchText = new BehaviorSubject<any>('');
  public autoSearchHit = new BehaviorSubject<any>(undefined);
  constructor(private apiServiceProvider: ApiServiceProvider) {}

  searchCustom(terms: Observable<string>, requestData: RequestData) {
    let custtrm;
    if (
      requestData.formParams &&
      requestData.formParams['userInput'].length > 0
    ) {
      custtrm = requestData.formParams['userInput'];
    }
    if (requestData.payload && requestData.payload['userInput'].length > 0) {
      custtrm = requestData.payload['userInput'];
    }
    return (
      terms
        .debounceTime(400)
        .distinctUntilKeyChanged(custtrm)
        // .distinctUntilChanged()
        .switchMap((term) => this.searchCustomEntries(term, requestData))
    );
  }

  searchCustomEntries(term, requestData) {
    if (term === '') {
      term = '55';
    }
    // requestData.payload.inputs = [term];
    if (requestData.formParams !== undefined) {
      if (
        requestData.formParams.hasOwnProperty('dimension') ||
        requestData.formParams.hasOwnProperty('dimensions')
      ) {
        if (term === '0' || term === '' || term === '55') {
          term = '';
        }
      }
      requestData.formParams['userInput'] = term;
      requestData.formParams['inputs'] = [];
      requestData.formParams['inputs'].push(term);
    }
    if (requestData.payload !== undefined) {
      if (term === '0' || term === '' || term === '55') {
        term = '';
      }
      requestData.payload['userInput'] = term;
    }

    return this.apiServiceProvider.post(requestData);
  }

  onConvertCustomMulti(source, dimension) {
    const valuArray: any[] = new Array();
    let i = 0;
    for (const item of source) {
      const obj = {
        value: '',
        check: false,
        dimension,
      };
      obj.value = item;
      valuArray.push(obj);
      i = i + 1;
      if (i === 100) {
        break;
      }
    }
    return valuArray;
  }

  public setPMRequestData(field: any) {
    this.pmRequestData.next(field);
  }

  public getPMRequestData() {
    return this.pmRequestData.asObservable();
  }

  public setAutoSearchText(field: any) {
    this.autoSearchText.next(field);
  }

  public getAutoSearchText() {
    return this.autoSearchText.asObservable();
  }

  public setAutoSearchSelected(field: any) {
    this.autoSearchSelected.next(field);
  }

  public getAutoSearchSelected() {
    return this.autoSearchSelected.asObservable();
  }

  public getClearflag() {
    return this.clearFlag.asObservable();
  }
  public setClearflag(val) {
    this.clearFlag.next(val);
  }

  public setSingleSearchText(field: any) {
    this.singleSelectSearchText.next(field);
  }

  public getSingleSearchText() {
    return this.singleSelectSearchText.asObservable();
  }

  setautoSearchHit(field: any) {
    this.autoSearchHit.next(field);
  }
  getautoSearchHit() {
    return this.autoSearchHit.asObservable();
  }
}
