import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';

@Component({
  selector: 'child-cell',
  template: `
    <div class="center-cell">
      <input
        class="form-control margin-top-0"
        type="checkbox"
        [(ngModel)]="params.value"
        (change)="invokeParentMethod()"
      />
    </div>
  `
  //   <p-checkbox
  //         [(ngModel)]="params.value"
  //         [binary]="true"
  //         [disabled]="false"
  //         (onChange)="invokeParentMethod()"
  //       >
  //       </p-checkbox>

  //   < input type="checkbox"(change) = "invokeParentMethod()" data- md - icheck class= "form-control border-checkbox" name = "value"[(ngModel)] = "params.value" >
})
export class BooleanRendererComponent implements ICellRendererAngularComp {
  public params: any;
  public originalValue: boolean;

  currentRow: any;

  agInit(params: any): void {
    this.params = params;
    this.originalValue = params.value;
  }

  refresh(): boolean {
    return false;
  }

  public invokeParentMethod() {
    const currentField = this.params.colDef.field;
    this.currentRow = Object.assign({}, this.params.data);
    this.currentRow[currentField] = this.params.value;

    const changedResult = {
      rowIndex: this.params.node.rowIndex,
      fieldName: this.params.colDef.field,
      currentRow: this.currentRow,
      previousValue: this.originalValue
    };

    this.params.context.componentParent.onRelevanceChange(changedResult);
  }
}
