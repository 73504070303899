import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { ApiServiceProvider } from 'app/api-service';
import { HTTP_REQUEST_TYPES } from 'app/modules/npi/npiconstants';

@Injectable()
export class DataVisualizationService {
    public env = environment;
    constructor(private apiService: ApiServiceProvider) { }
    public getDashboardUrl(dashboardId: string,disableReset): Observable<any> {
        // dashboardId = dashboardId || this.env.content.visualizationDashboardId;
        const payload = {
            targetedType: `${this.env.content.contentManagementService}`,
            method: HTTP_REQUEST_TYPES.GET,
            servicePath: `${this.env.content.dashboardService}/dashboards/${dashboardId}/${disableReset}`
        };
        return this.apiService.post(payload);
    }
}
