import { ContextLandingPageComponent } from './context-landing-page.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxLoadingModule } from 'ngx-loading';


@NgModule({
  declarations: [ContextLandingPageComponent],
  exports: [ContextLandingPageComponent],
  imports: [
    CommonModule,
    NgxLoadingModule.forRoot({})
  ]
})
export class ContextLandingPageModule { }
