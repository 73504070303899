import { ColumnApi, GridApi, IDatasource, IGetRowsParams, Module } from '@ag-grid-community/all-modules';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CustomColDef, CustomGridOption } from 'app/modules/leva-ui-library/modules/ld-grid/interfaces/ld-grid.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { CoverageDashboardService } from 'app/modules/coverage-dashboard/coverage-dashboard.service';

@Component({
  selector: 'app-dashboard-grid',
  templateUrl: './dashboard-grid.component.html',
  styleUrls: ['./dashboard-grid.component.sass']
})
export class DashboardGridComponent implements OnInit, OnDestroy {
  @Input() configuration: any;
  @Input() filterData: any;
  @Output() filterSelection: EventEmitter<any> = new EventEmitter();
  private gridApi: GridApi;
  private columnApi: ColumnApi;
  public modules: Module[] = AllModules;
  public gridOptions: CustomGridOption = {
    columnDefs: null,
    rowData: null,
    cacheBlockSize: 4,
    cacheOverflowSize: 2,
    infiniteInitialRowCount: 10,
    maxBlocksInCache: 4,
    gridHeight: '100%',
    headerHeight: 40,
    suppressPropertyNamesCheck: true,
    sideBar: false,
    onColumnMoved: (event) => {
      const newColDef = [];
      this.columnApi.getAllGridColumns().forEach((col: any) => {
        const columnDef: any = this.gridOptions.columnDefs.find(colDef => colDef.field === col.colId)
        if (columnDef && columnDef.refetchOnChange) {
          this.gridOptions.columnDefs.splice(this.gridOptions.columnDefs.indexOf(columnDef), 1);
          newColDef.push(columnDef);
        }
      });
      this.coverageGridService.setDashboardDataChangeObservable({
        type: 'GRID_COL_ORDER_CHANGE',
        data: newColDef
      });
      this.gridOptions.columnDefs = [...newColDef, ...this.gridOptions.columnDefs];
      this.isReorder = true;
    },
    defaultColDef: {
      menuTabs: ['generalMenuTab']
    }
  }
  public buttonConfig;
  public rowDetails = {
    users: [],
    totalCount: 0
  };
  downloadPayload: null;
  excelInfo: null;
  public isReorder = false;
  private subject$: Subject<any> = new Subject();

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.gridApi.sizeColumnsToFit();
  }
  constructor(private coverageGridService: CoverageDashboardService) { }

  ngOnInit() {
    this.subscribeToGridEvents();
    this.convertToColumDef(this.configuration);
    this.buttonConfig = this.configuration.buttonConfig;
  }

  private subscribeToGridEvents(): void {
    this.coverageGridService.getDashboardDataChangeObservable()
      .pipe(takeUntil(this.subject$))
      .subscribe((response: any) => {
        if (response.type === 'GRID_UPDATE') {
          this.convertToColumDef(this.configuration);
          if (!response.data) {
            this.buttonConfig = undefined;
            this.gridApi.setDatasource(this.dataSource);
            this.gridApi.setRowData([]);
            return;
          }
          if (!this.isReorder) {
            this.gridOptions = {
              ...this.gridOptions,
              columnDefs: this.createColumnHeaders(response.data)
            }
          }
          this.gridApi.setDatasource(this.dataSource);
          this.showHideColumns();
          this.gridApi.sizeColumnsToFit();
          this.reorderOptions(this.configuration.buttonConfig);
          this.isReorder = false;
        }
      })
  }

  private reorderOptions(buttonConfig) {
    let dropDownMenu = buttonConfig.find(item => item.type === 'menu');
    let tags = [];
    this.gridOptions.columnDefs.forEach((col: any) => {
      dropDownMenu.tags.forEach(tag => {
        tag.name === col.headerName ? tags.push(tag) : null;
      });
    });
    const remainingTags = dropDownMenu.tags.filter(tag => !tags.includes(tag));
    dropDownMenu.tags = [...tags, ...remainingTags];
    this.buttonConfig = [...buttonConfig];
  }

  convertToColumDef(data: any) {
    if (data.type !== 'GRID_TILE') { return; }
    data.row.forEach(row => {
      row.content.forEach(content => {
        if (content.type === 'GRID') {
          if (!content.data[0]) {
            this.gridOptions.rowData = [];
            this.rowDetails.users = [];
            this.rowDetails.totalCount = 0;
            this.gridOptions.columnDefs = [];
            return;
          }
          this.rowDetails.users = content.data[0].data;
          this.rowDetails.totalCount = content.data[0].data.length;
          this.gridOptions.columnDefs = this.createColumnHeaders(content.data[0]);
        }
      });
    });
  }

  private createColumnHeaders(data: any) {
    const colDefs: any[] = [];
    data.metaData.forEach((data: CustomColDef) => {
      if (colDefs.length < 1) {
        colDefs.push(this.getHeaderObject(data));
      } else {
        let test: any = colDefs.find(item => item.headerName === data.key);
        if (test) {
          !(test.children && test.children.length) ? test.children = [] : null;
          if (test.children.length === 0) {
            test.children.push(this.getHeaderObject(test));
          }
          test.children.push(this.getHeaderObject(data));
          test.field ? delete test.field : null;
          test.displayName ? delete test.displayName : null;
        } else {
          colDefs.push(this.getHeaderObject(data));
        }
      }
    });
    return colDefs;
  }

  private getHeaderObject(data: any) {
    return {
      headerName: data.key,
      field: data.field,
      displayName: data.displayName,
      headerClass: data.headerClass,
      cellStyle: data.cellStyle,
      visible: data.visible,
      refetchOnChange: data.refetchOnChange,
      tags: data.tags,
      pinned: data.pinned,
      minWidth: data.minWidth,
      suppressMovable: !data.allowMove,
      dataType: data.dataType
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.setDatasource(this.dataSource);
    this.showHideColumns();
    this.gridApi.sizeColumnsToFit();
  }

  private showHideColumns(tag: any = null, tagsList?: any) {
    this.gridOptions.columnDefs.forEach((col: any) => {
      if (col && col.children && col.children.length > 0) {
        col.children.forEach((child: any) => {
          this.setColumnVisibilityBasedOnTags(child, tagsList);
        });
      } else {
        if (tag && col.tags.includes(tag.name)) {
          this.filterSelection.emit(tag)
        } else {
          this.columnApi.setColumnVisible(col.field, col.visible);
        }
      }
    });
    this.gridApi.sizeColumnsToFit();
  }


  private setColumnVisibilityBasedOnTags(col, tagsList) {
    let checkedTagLength = 0;
    if (tagsList) {
      col.tags.forEach(tagName => {
        if (tagsList.find(tag => tag.name === tagName).checked) {
          checkedTagLength++;
        }
      });
      this.columnApi.setColumnVisible(col.field, checkedTagLength === col.tags.length);
    } else {
      this.columnApi.setColumnVisible(col.field, col.visible);
    }
  }

  onTagSelection(tag, tagList) {
    tagList.forEach(t => {
      if (t.name === tag.name) {
        t.checked = !t.checked;
      }
    });
    this.showHideColumns(tag, tagList);
  }

  public actionButtonClick(button) {
    switch (button.action) {
      case 'download-excel':
        this.downloadExcel();
        break;
      case 'expand':
        const buttons = this.buttonConfig.filter(b => (b.action !== 'expand'));
        buttons.push({
          action: "expand-shrink",
          class: "ld-icon-collapse leva-small-icon-styles",
          label: "expand-shrink",
          type: "button",
        })
        this.gridOptions.gridHeight = '90vh';
        setTimeout(() => {
          this.buttonConfig = [...buttons];
          this.scrollToGrid();
        }, 0);
        break;
      case 'expand-shrink':
        const buttons1 = this.buttonConfig.filter(b => (b.action !== 'expand-shrink'));
        buttons1.push({
          type: 'button',
          label: 'expand',
          class: 'ld-icon-expanded',
          action: 'expand'
        })
        this.buttonConfig = [...buttons1];
        this.gridOptions.gridHeight = '100%';
        break;
    }
  }

  private downloadExcel() {
    this.gridApi.exportDataAsExcel();
  }

  private scrollToGrid() {
    document.getElementById("dashboard-grid").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }


  dataSource: IDatasource = {
    getRows: (params: IGetRowsParams) => {
      this.rowDetails.users = this.sortData(params.sortModel, this.rowDetails.users);
      const rowData = {
        users: this.rowDetails.users.slice(params.startRow, params.endRow),
        totalCount: this.rowDetails.totalCount
      };
      if (rowData.totalCount) {
        params.successCallback(rowData.users, rowData.totalCount)
        if (rowData.totalCount === 0) {
          this.gridApi.showNoRowsOverlay();
        } else {
          this.gridApi.hideOverlay();
        }
      } else {
        params.successCallback([], 0);
        this.gridApi.showNoRowsOverlay();
      }
    }
  };

  private sortData(sortModel: any, data: any[]) {
    const sortPresent = sortModel && sortModel.length > 0;
    if (!sortPresent) {
      return data;
    }
    let dataType: string;
    for (const col of this.gridOptions.columnDefs) {
      if (col.field === sortModel[0].colId) {
        dataType = col.dataType;
        break;
      } else if (col.children && col.children.length > 0) {
        const child = col.children.find(child => child.field === sortModel[0].colId);
        child ? dataType = child.dataType : null;
      }
    }
    const resultOfSort = data.slice();
    resultOfSort.sort((a, b) => {
      for (let k = 0; k < sortModel.length; k++) {
        const sortColModel = sortModel[k];
        let valueA = a[sortColModel.colId];
        let valueB = b[sortColModel.colId];
        if (dataType === 'percentage') {
          if (valueA == null || valueA == undefined) {
            valueA = 0;
          }
          if (valueB == null || valueB == undefined) {
            valueB = 0;
          }
          valueA = parseFloat(valueA.toString().replace(/[^0-9\.]+/g, ""));
          valueB = parseFloat(valueB.toString().replace(/[^0-9\.]+/g, ""));
        } else if (dataType === 'currency') {
          valueA = this.convertNumberToCurrency(valueA);
          valueB = this.convertNumberToCurrency(valueB);
        }
        if (valueA == valueB) {
          continue;
        }
        const sortDirection = sortColModel.sort === 'asc' ? 1 : -1;
        if (valueA > valueB) {
          return sortDirection;
        } else {
          return sortDirection * -1;
        }
      }
      return 0;
    });
    return resultOfSort;
  }

  private convertNumberToCurrency(number): number {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1000, symbol: "K" },
      { value: 1000000, symbol: "M" },
      { value: 1000000000, symbol: "G" },
      { value: 1000000000000, symbol: "T" },
      { value: 1000000000000000, symbol: "P" },
      { value: 1000000000000000000, symbol: "E" }
    ];
    let numVal = number.split(/ /)[0].replace(/[^\d\.]/g, '');
    const isNumber = /^\d+$/.test(number.substring(number.length - 1))
    if (isNumber) {
      return +numVal;
    }
    let item = lookup.find(item => number.substring(number.length - 1).toUpperCase() === item.symbol);
    if (!item) {
      return 0;
    }
    return +numVal * item.value;
  }

  ngOnDestroy() {
    this.subject$.next();
    this.subject$.complete();
  }

}
