import {
  Component,
  ViewChild,
  ViewContainerRef,
  AfterViewInit,
} from '@angular/core';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import * as _ from 'lodash';

@Component({
  selector: 'child-cell',
  template: `
    <div tabindex="0">
      <multi-select-dropdown
        [items]="dropDownValues"
        [styleForGrid]="true"
        readKey="tenants"
        [defaultSelected]="defaultSelectedValues"
        [dropdownTitle]="'Please Select a Tenant'"
        (onSelectionChange)="onViewSelectionChange($event)"
        (onUnCheckAll)="multiselectCheckandUncheck()"
      ></multi-select-dropdown>
    </div>
  `,
})
export class MultiSelectTenantComponent {
  public dropDownValues: any;
  public defaultSelectedValues = [];
  public request: any;
  public cmList: any;
  public params: any;
  public autosearchShow = false;
  private selectedGroups = {};
  constructor(private dataStorageService: DataStorageService) {}

  public agInit(params: any): void {
    this.params = params;
    this.dropDownValues = [];
    this.params.dataByUser.forEach((value) => {
      let obj = {};
      obj = {
        tenants: value,
        checked: false,
      };
      this.dropDownValues.push(obj);
    });
    this.defaultSelectedValues = [];
    if (params.value) {
      this.params.value.forEach((element) => {
        let obj = {};
        obj = {
          checked: true,
        };
        if (element instanceof Object) {
          obj['tenants'] = element.tenantName;
        } else {
          obj['tenants'] = element;
        }
        this.defaultSelectedValues.push(obj);
      });
    }
  }
  public onViewSelectionChange(event) {
    const newparam = this.params;
    if (event instanceof Array) {
      newparam.value = [];
      if (event.length > 0) {
        event.forEach((value) => {
          newparam.value.push(value.tenants);
        });
      }
      this.params.data.tenants = newparam.value;
      this.selectedGroups[this.params.data.keyId] = newparam;
      this.dataStorageService.setCheckedUserGroupsInGrid(this.selectedGroups);
    }
  }
  public multiselectCheckandUncheck() {}
}
