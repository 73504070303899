export class StringUtils {
    public static getRandom() {
        return (
            this.chr4() +
            this.chr4() +
            '-' +
            this.chr4() +
            '-' +
            this.chr4() +
            '-' +
            this.chr4() +
            '-' +
            this.chr4() +
            this.chr4() +
            this.chr4()
        );
    }
    public static getRandomString(m?: number) {
        const len = m || 9;
        let s = '';
        const r = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < len; i++) {
            s += r.charAt(Math.floor(Math.random() * r.length));
        }
        return s;
    }
    public static quote(regex) {
        return regex.replace(/([()[{*+.$^\\|?])/g, '\\$1');
    }
    private static chr4() {
        return Math.random()
            .toString(16)
            .slice(-4);
    }
}
