export class GridFormatterService {
    public htmlTextSort(valueA, valueB, nodeA, nodeB, isInverted) {
      if (valueA === 'N/A' || valueA.length === 0) {
        valueA = -100000000000000000000;
      }
      if (
        valueA === null ||
        valueA === undefined ||
        valueA instanceof Object ||
        valueA instanceof Function
      ) {
        valueA = '0';
      }
      if (valueA instanceof Array) {
        valueA = valueA.toString();
      }
      if (typeof valueA === 'boolean') {
        valueA = '' + (valueA ? 1 : 0);
      }

      if (valueB === 'N/A' || valueB.length === 0) {
        valueB = -100000000000000000000;
      }
      if (
        valueB === null ||
        valueB === undefined ||
        valueB instanceof Object ||
        valueB instanceof Function
      ) {
        valueB = '0';
      }
      if (valueB instanceof Array) {
        valueB = valueB.toString();
      }
      if (typeof valueB === 'boolean') {
        valueB = '' + (valueB ? 1 : 0);
      }

      const divA = document.createElement('div');
      divA.innerHTML = valueA;
      let numA: any = 0;
      if ((divA.getElementsByClassName('text-danger') || []).length > 0) {
        numA = -1;
      } else if ((divA.getElementsByClassName('text-success') || []).length > 0) {
        numA = 1;
      } else {
        numA = 1;
      }
      valueA = parseFloat(
        (divA.textContent.trim() || '0')
          .toString()
          .trim()
          .replace('%', '')
          .trim()
          .replace('$', '')
          .trim()
          .replace(/,/g, '')
          .trim()
      );
      isNaN(valueA) ? '' : (numA = numA * valueA);

      const divB = document.createElement('div');
      divB.innerHTML = valueB;
      let numB: any = 0;
      if ((divB.getElementsByClassName('text-danger') || []).length > 0) {
        numB = -1;
      } else if ((divB.getElementsByClassName('text-success') || []).length > 0) {
        numB = 1;
      } else {
        numB = 1;
      }
      valueB = parseFloat(
        (divB.textContent.trim() || '0')
          .toString()
          .trim()
          .replace('%', '')
          .trim()
          .replace('$', '')
          .trim()
          .replace(/,/g, '')
          .trim()
      );
      isNaN(valueA) ? '' : (numB = numB * valueB);
      if (isNaN(numA)) {
        numA = 0;
      }
      if (isNaN(numB)) {
        numB = 0;
      }
      return numA - numB;
      /* if (value !== 'N/A') {
              console.log(ind, value, item.data);
              i[item.data] = parseFloat(value.replace(/,/g, ''));
          } else {
              i[item.data] = 0;
          } */
    }

    public formattedCurrencySort(valueA, valueB, nodeA, nodeB, isInverted) {
      if (valueA === 'N/A'  || valueA.length === 0) {
        valueA = -100000000000000000000;
      }
      if (
        valueA === null ||
        valueA === undefined ||
        valueA instanceof Object ||
        valueA instanceof Function
      ) {
        valueA = '0';
      }
      if (valueA instanceof Array) {
        valueA = valueA.toString();
      }
      if (typeof valueA === 'boolean') {
        valueA = '' + (valueA ? 1 : 0);
      }
      if (valueB === 'N/A' || valueB.length === 0) {
        valueB = -100000000000000000000;
      }
      if (
        valueB === null ||
        valueB === undefined ||
        valueB instanceof Object ||
        valueB instanceof Function
      ) {
        valueB = '0';
      }
      if (valueB instanceof Array) {
        valueB = valueB.toString();
      }
      if (typeof valueB === 'boolean') {
        valueB = '' + (valueB ? 1 : 0);
      }
      let numA = parseFloat(
        (valueA || '0')
          .toString()
          .replace('%', '')
          .trim()
          .replace('%', '')
          .trim()
          .replace('$', '')
          .trim()
          .replace(/,/g, '')
          .trim()
      );
      let numB = parseFloat(
        (valueB || '0')
          .toString()
          .replace('%', '')
          .trim()
          .replace('%', '')
          .trim()
          .replace('$', '')
          .trim()
          .replace(/,/g, '')
          .trim()
      );
      if (isNaN(numA)) {
        numA = 0;
      }
      if (isNaN(numB)) {
        numB = 0;
      }
      return numA - numB;
    }

    public formattedPercentageSort(valueA, valueB, nodeA, nodeB, isInverted) {
      if (valueA === 'N/A') {
        valueA = '0';
      }
      if (
        valueA === null ||
        valueA === undefined ||
        valueA instanceof Object ||
        valueA instanceof Function
      ) {
        valueA = '0';
      }
      if (valueA instanceof Array) {
        valueA = valueA.toString();
      }
      if (typeof valueA === 'boolean') {
        valueA = '' + (valueA ? 1 : 0);
      }
      if (valueB === 'N/A') {
        valueB = '0';
      }
      if (
        valueB === null ||
        valueB === undefined ||
        valueB instanceof Object ||
        valueB instanceof Function
      ) {
        valueB = '0';
      }
      if (valueB instanceof Array) {
        valueB = valueB.toString();
      }
      if (typeof valueB === 'boolean') {
        valueB = '' + (valueB ? 1 : 0);
      }
      let numA = parseFloat(
        (valueA || '0')
          .toString()
          .replace('%', '')
          .trim()
          .replace('%', '')
          .trim()
          .replace('$', '')
          .trim()
          .replace(/,/g, '')
          .trim()
      );
      let numB = parseFloat(
        (valueB || '0')
          .toString()
          .replace('%', '')
          .trim()
          .replace('%', '')
          .trim()
          .replace('$', '')
          .trim()
          .replace(/,/g, '')
          .trim()
      );
      if (isNaN(numA)) {
        numA = 0;
      }
      if (isNaN(numB)) {
        numB = 0;
      }
      return numA - numB;
    }
  }

