import { Injectable } from '@angular/core';
import { ApiServiceProvider } from 'app/api-service';

@Injectable({
  providedIn: 'root',
})
export class WorkbenchService extends ApiServiceProvider {
  getHeaders(payload) {
    const servicePayload = {};
    return this.post(servicePayload);
  }

  getRowData(payload) {
    const servicePayload = {};
    return this.post(servicePayload);
  }

  getScenarioHeaders(queryParams) {
    const payload = {
      targetedType: 'CustomerConfigService',
      method: 'POST',
      generic: true,
      formParams: queryParams,
      servicePath: `/widgetManagement/getWidgetTabHeaders`,
    };
    return this.post(payload);
  }

  getDimensionData(type, tenantId) {
    const payload = {
      targetedType: 'riskManagementService',
      method: 'GET',
      servicePath: `risk/filters/${type}?tenantId=${tenantId}&`,
    };
    return this.post(payload);
  }

  getELSDimensionData(dimensionName, tenantId) {
    const payload = {
      targetedType: 'ELS',
      method: 'POST',
      payload: {
        dimension: [dimensionName],
        isWildCardSearch: true,
        inputs: [''],
      },
      servicePath: `MultiValueSearch`,
    };
    return this.post(payload);
  }

  getScenarios(tenantId) {
    const payload = {
      targetedType: 'riskManagementService',
      method: 'GET',
      servicePath: `scenarios/?tenantId=${tenantId}&`,
    };
    return this.post(payload);
  }

  getBaseScenario(requestPayload, tenantId) {
    const payload = {
      targetedType: 'riskManagementService',
      method: 'POST',
      payload: requestPayload,
      formParams: {
        tenantId,
      },
      servicePath: `scenarios/data/base`,
    };
    return this.post(payload);
  }

  loadScenarios(tenantId, requestPayload, scenarioIds) {
    const payload = {
      targetedType: 'riskManagementService',
      method: 'POST',
      formParams: {
        tenantId,
      },
      payload: requestPayload,
      servicePath: `scenarios/data`,
    };
    payload.formParams['scenarioId'] = scenarioIds
      ? encodeURIComponent(scenarioIds)
      : undefined;
    return this.post(payload);
  }

  saveScenario(payload, tenantId) {
    const requestPayload = {
      targetedType: 'riskManagementService',
      method: 'POST',
      payload,
      formParams: { tenantId },
      servicePath: 'scenarios/run/save',
    };
    return this.post(requestPayload);
  }

  runScenario(payload, tenantId) {
    const runRequestPayload = {
      targetedType: 'riskManagementService',
      method: 'POST',
      payload,
      formParams: {
        tenantId,
      },
      servicePath: 'scenarios/run/preview',
    };
    return this.post(runRequestPayload);
  }

  removeScenario(id, tenantId) {
    const removePayload = {
      targetedType: 'riskManagementService',
      method: 'DELETE',
      payload: {},
      formParams: {
        tenantId,
      },
      servicePath: `scenarios/${id}`,
    };
    return this.post(removePayload);
  }
}
