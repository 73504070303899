import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NavAdvBarService } from 'app/modules/common/nav-adv-bar.service';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { InteractiveEditService } from 'app/modules/interactive-edit/shared/interactive-edit.service';
@Component({
  selector: 'nav-adv-tab-bar',
  moduleId: module.id,
  templateUrl: 'nav-adv-tab-bar.component.html',
  styleUrls: ['nav-adv-tab-bar.component.sass'],
  providers: [NavAdvBarService]
})

export class NavAdvTabBarComponent implements OnInit,OnChanges {

  tabsList: any;
  @Input() target: string;
  constructor(
    private navBarService: NavAdvBarService,
    private scopeDataService: ScopeDataService,
    private interactiveEditService:InteractiveEditService
  ) { }

  ngOnInit() {
    this.tabsList = this.navBarService.getNavBarList(this.target);
  }
  onTabClick() {
    this.scopeDataService.settype('manual');
  }
  ngOnChanges(changes: SimpleChanges) {
    this.interactiveEditService.getTotalNotifications().subscribe(
      result => {
        this.navBarService.constructNavBar('ietabs','notifications',result);
        this.tabsList = this.navBarService.getNavBarList(this.target);
      }
    )
  }
}
