import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective implements AfterContentInit {
  @Input() appAutofocus = true;

  constructor(private el: ElementRef) { }

  public ngAfterContentInit() {
    setTimeout(() => {
      if (this.appAutofocus) {
        this.el.nativeElement.focus();
      }
    }, 0);
  }
}
