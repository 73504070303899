import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Router } from '@angular/router';
import { DataStorageService } from 'app/modules/common/data-storage.service';

@Component({
    selector: 'child-cell',
    template: `<a (click) = onClick()>{{ this.val }} </a>`,
})
export class RfxListComponent {
    public params: any;
    val: any;

    constructor(private router: Router, private dataStorageService: DataStorageService, ) {

    }
    agInit(params: any): void {
        this.val = params.value;
        this.params = params;
    }
    onClick() {
        this.dataStorageService.setClikedRFQforDetails(this.params.data);
        this.dataStorageService.setSeletedRFQName(this.params.data.rfqCustDesc);

        if (!(this.params.data.rfqStage === 'Draft')) {
            this.router.navigate(['/app/srfx/r/']);
        }
        else {
            this.router.navigate(['/app/srfx/create/']);
        }
    }

}
