import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

import { TimeSelection } from './time-selection.model';

@Injectable()
export class TimeSelectionService {

  constructor(private http: HttpClient) { }


}
