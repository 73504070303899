import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Router } from '@angular/router';
import { DataStorageService } from 'app/modules/common/data-storage.service';

@Component({
  selector: 'child-cell',
  template: `<div title = "\$ {{ this.val | number }} ">\$ {{ this.val | number }} </div>`,
})
export class CurrencyFormatterComponent {
    public params: any;
    val: any;

    constructor(private router: Router, private dataStorageService: DataStorageService) {

    }
    agInit(params: any): void {
       this.val =params.value

     // this.val =   shortenLargeNumber(params.value)

/**
*  Get a value deep inside an object or array
*
* @param {number} num Number to find decimals
* @returns {number}
*
*/
function decimalPlaces(num) {
  const match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  if (!match) {
    return 0;
  }
  return Math.max(0,
    // Number of digits right of decimal point.
    (match[1] ? match[1].length : 0)
    // Adjust for scientific notation.
    - (match[2] ? +match[2] : 0));
}
/**
* Shorten number to thousands, millions, billions, etc.
*
* @param {number} num Number to shorten.
* @param {number:Optional} [digits=0] The number of digits to appear after the decimal point. default is 2
* @returns {string|number}
*
*/
function shortenLargeNumber(num, digits?: any) {
  if (!digits) {
    digits = 2;
  }
  let units = ['K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'],
    decimal;
  for (let i = units.length - 1; i >= 0; i--) {
    decimal = Math.pow(1000, i + 1);
    if (num <= -decimal || num >= decimal) {
      return +(num / decimal).toFixed(digits) + units[i];
    }
  }
  if (digits < decimalPlaces(num) && num < 1000) {
    num = parseFloat(num).toFixed(digits);
  }
  return num;
}
















            // if (this.val < 1000) {
            //     this.val =  '$' + (this.val)
            // }

            // else if (this.val > 1000 && this.val < 1000000) {
            //     this.val = '$' + (this.val/1000) + 'k'
            // }

            // else
            // this.val = '$' + (this.val/1000000) + 'M';

        this.params = params;
    }
}
