import { Component } from '@angular/core';
import { DataStorageService } from 'app/modules/common/data-storage.service';

@Component({
  selector: 'action-cell-renderer',
  template: `
    <div *ngIf="component.showBtn">
      <button id="editBtn" class="btn btn-link" (click)="onActionChanged()">
        Edit
      </button>
    </div>
    <div *ngIf="component.showChkbox">
      <input
        id="chkBox"
        type="checkbox"
        class="form-control border-checkbox"
        [disabled]="disableChange"
        [checked]="params.value"
        (change)="onActionChanged()"
      />
    </div>
  `,
})
export class ActionCellRendererComponent {
  params: any;
  component: any;
  disableChange = false;

  constructor(private dataStorageService: DataStorageService) {}

  agInit(params): void {
    this.params = params;
    this.component = {
      showBtn: false,
      showChkbox: false,
    };
    switch (params.type) {
      case 'button':
        this.component.showBtn = true;
        break;
      case 'checkbox':
        this.component.showChkbox = true;
        this.disableChange = params.data[params.colDef.field];
        break;
    }
  }

  onActionChanged(): void {
    this.dataStorageService.setActionChanged({
      field: this.params.colDef.field,
      currentRow: this.params.data,
      type: this.params.type,
      value: this.params.value,
    });
  }
}
