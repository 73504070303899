import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable()
export class ExportLoaderService {
  public showExcelLoader = new Subject<any>();

  public getExcelLoader() {
    return this.showExcelLoader.asObservable();
  }

  public setExcelLoader(showExcelLoader) {
    this.showExcelLoader.next(showExcelLoader);
  }
}
