import { Component, OnInit } from '@angular/core';
import { NpiCommonApiService } from '../../npi-common-api.service';
import { API_SERVICE_CONSTANTS, DATE_PROPERTIES } from '../../npiconstants';
import { NpiAdminRiskService } from '../../npi-admin/npi-admin-risk/shared/npi-admin-risk.service';
import { DateUtils } from '../../../common/utills/DateUtils.service';
import * as moment from 'moment-timezone-all';
import { ECalendarValue, IDatePickerConfig } from 'ng2-date-picker';
import { ObjectUtils } from '../../../common/utills/ObjectUtils.service';

@Component({
  selector: 'app-maturity-risk',
  templateUrl: './maturity-risk.component.html',
  styleUrls: ['./maturity-risk.component.sass']
})
export class MaturityRiskComponent implements OnInit {
  programKeyInfo: any;
  headers: any;
  showHeaders = false;
  data: any;
  sortedData: any;
  displayMessage = 'No Program Configured Yet ';
  minValuesArray = {};
  configsArray = [];
  FileData: any;

  showNotConfiguredMessage = false;
  constructor(
    private npiCommonApiService: NpiCommonApiService,
    public npiAdminRiskService: NpiAdminRiskService
  ) {
    this.programKeyInfo = this.npiCommonApiService.getSelectedDropdownObject();
  }

  ngOnInit() {
    this.getRiskInfo();
    this.FileData = {
      title: 'NPI',
      RFQ: false,
      servicePath: `/ProgramManagementService/api/programservice/risks/uploadpsl`
    };
  }

  public getRiskInfo() {
    if (this.npiAdminRiskService.programKeyInfo !== undefined) {
      if (
        this.npiCommonApiService.validateServiceCallData(
          this.npiAdminRiskService.riskInfo
        ) !== 'error'
      ) {
        this.data = this.npiAdminRiskService.riskInfo.result.generalInfo.maturityLevelDefinition;
        this.data.forEach(element => {
          if (!element.hasOwnProperty('riskDate')) {
            element.riskDate = '';
            element.isDefault = false;
          } else {
            if (element.riskDate !== 0) {
              if (moment.isMoment(element.riskDate)) {
                element.riskDate;
              } else {
                element.riskDate = DateUtils.getMomentFromEpoch(
                  element.riskDate
                );
                element.isDefault = true;
              }
            } else {
              element.riskDate = '';
            }
          }
        });
        this.sortedData = this.data.sort(function(a, b) {
          return a.level - b.level;
        });
        this.showNotConfiguredMessage = false;
        this.updateMinMaxValues();
      } else {
        this.showNotConfiguredMessage = true;
      }
    } else {
      this.showNotConfiguredMessage = true;
    }
  }

  updateDate(event, i) {
    if (moment.isMoment(event)) {
      let object: any;
      this.npiAdminRiskService.riskInfo.result.generalInfo.maturityLevelDefinition[i].riskDate = event;
      this.npiAdminRiskService.riskInfo.result.generalInfo.maturityLevelDefinition[i].dateTouched = true;
      object = ObjectUtils.copy(
        this.npiAdminRiskService.riskInfo.result.generalInfo
          .maturityLevelDefinition[i]
      );
      object.riskDate = DateUtils.getUTCTimeByMoment(
        event.clone().endOf('day')
      );
      if (
        !this.npiAdminRiskService.riskInfo.result.generalInfo
          .maturityLevelDefinition[i].isDefault
      ) {
        this.npiAdminRiskService.riskInfo.result.generalInfo.maturityLevelDefinition[i].dateTouched = true;
        this.npiAdminRiskService.maturityRiskUpdated = true;
        this.npiAdminRiskService.enableReset = true;
        this.updateMinMaxValues();
        if (this.npiAdminRiskService.maturityRiskInfo.length >= 1) {
          this.npiAdminRiskService.maturityRiskInfo.forEach(
            (element, index) => {
              if (element.level === object.level) {
                this.npiAdminRiskService.maturityRiskInfo[index] = object;
              } else {
                this.npiAdminRiskService.maturityRiskInfo.push(object);
              }
            }
          );
        } else {
          this.npiAdminRiskService.maturityRiskInfo.push(object);
        }
      } else {
        this.npiAdminRiskService.riskInfo.result.generalInfo.maturityLevelDefinition[i].isDefault = false;
      }
    }
  }

  updateMinMaxValues() {
    this.minValuesArray = {};
    this.npiAdminRiskService.riskInfo.result.generalInfo.maturityLevelDefinition.forEach((element, index) => {
      if (index === 0) {
        this.minValuesArray[index] = DateUtils.getCurrentDateAsMoment();
      } else {
        if (
          moment.isMoment(
            this.npiAdminRiskService.riskInfo.result.generalInfo
              .maturityLevelDefinition[index - 1].riskDate
          )
        ) {
          this.minValuesArray[index] = this.npiAdminRiskService.riskInfo.result.generalInfo.maturityLevelDefinition[index - 1].riskDate;
        } else {
          this.minValuesArray[index] = DateUtils.getCurrentDateAsMoment();
        }
      }
    });
    this.getDateConfig();
  }

  public getDateConfig() {
    this.data.forEach((element, index) => {
      const config = {
        format: DATE_PROPERTIES.FORMAT,
        returnedValueType: ECalendarValue.Moment,
        drops: 'down'
      } as IDatePickerConfig;
      config.min = this.minValuesArray[index];
      config.max = DateUtils.getMomentFromEpoch(
        this.npiAdminRiskService.riskInfo.result.generalInfo
          .expectedRollOutDate
      );
      this.configsArray[index] = config;
      this.validateCompleteionDate();
    });
  }

  validateCompleteionDate() {
    this.data.forEach((element, index) => {
      if (index > 0) {
        if (moment.isMoment(element.riskDate)) {
          if (
            DateUtils.getUTCTimeByMoment(element.riskDate.clone()) <
              DateUtils.getUTCTimeByMoment(this.data[index - 1].riskDate) &&
            !this.data[index].isDefault
          ) {
            this.data[index].error = true;
            if (this.npiAdminRiskService.errors.maturity.includes(index)) {
              this.npiAdminRiskService.errors.maturity.push(index);
            }
          } else {
            this.data[index].error = false;
            if (this.npiAdminRiskService.errors.maturity.includes(index)) {
              const presenIndex = this.npiAdminRiskService.errors.maturity.findIndex(item => item === index);
              this.npiAdminRiskService.errors.maturity.splice(
                presenIndex,
                1
              );
            }
          }
        }
      }
    });
    this.npiAdminRiskService.saveButtonStatus();
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    if (this.npiAdminRiskService.riskInfo instanceof Object) {
      this.npiAdminRiskService.riskInfo.result.generalInfo.maturityLevelDefinition.forEach(element => {
        element.isDefault = true;
      });
    }
  }
}
