import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { CustReportsGrid } from './shared/custReports-grid.model';
import { GridOptions } from '@ag-grid-community/core';
import { CustReportsGridService } from './shared/custReports-grid.service';
import { AllModules, Module } from '@ag-grid-enterprise/all-modules';
import { CommentsTooltipComponent } from 'app/modules/npi-bom-repository/npi-bom-repository-overview/comments-tooltip.component';

@Component({
  selector: 'custReports-grid',
  templateUrl: 'custReports-grid.component.html',
  styleUrls: ['./custReports-grid.component.sass'],
  providers: [CustReportsGridService],
})
export class CustReportsGridComponent implements OnInit {
  public modules: Module[] = AllModules;
  custReportsGrid: CustReportsGrid[] = [];
  private gridOptions: GridOptions;
  @Output() public columnChanged: EventEmitter<any> = new EventEmitter<any>();
  public size = 50;
  sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressSideButtons: true,
          // suppressColumnExpandAll: true
        },
      },
    ],
    // defaultToolPanel: "filters"
  };
  public frameworkComponents = {
    commentsTooltipComponent: CommentsTooltipComponent,
  };
  @Input() customGridOptions: object;
  @Input() gridData: GridOptions;
  @Input() customOptions: object;
  @Input() custReportsGridService: CustReportsGridService;
  overlayNoRowsTemplate: any;
  gridCustomOptions = {} as GridOptions;
  public prevState;
  constructor() {
    this.gridOptions = {} as GridOptions;
    this.overlayNoRowsTemplate =
      '<span style="padding: 10px; border: 2px solid #444; background: white;">There are no results that match your search</span>';
  }

  ngOnInit() {
    this.gridOptions = this.gridData;
    this.gridCustomOptions = {
      floatingFilter: false,
      pagination: false,
      paginationPageSize: 20,
      rowHeight: 30,
      suppressExcelExport: true,
      groupHeaderHeight: 30,
      headerHeight: 30,
      domLayout: '',
      rowSelection: 'multiple',
      suppressColumnVirtualisation: true,
      suppressCsvExport: true,
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      // rowModelType: 'inMemory'
    };
    if (this.customOptions !== undefined) {
      for (const key of Object.keys(this.customOptions)) {
        switch (key.trim()) {
          case 'floatingFilter':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
          case 'pagination':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
          case 'paginationPageSize':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
          case 'toolPanel':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
          case 'rowHeight':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
          case 'headerHeight':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
          case 'groupHeaderHeight':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
          case 'domLayout':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
          case 'rowSelection':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
          case 'suppressColumnVirtualisation':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
          case 'rowModelType':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
          case 'suppressExcelExport':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
          case 'suppressCsvExport':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
            break;
          default:
            break;
        }
      }
    }
  }

  previousPage() {
    const payload = this.custReportsGridService.getPayload();
    payload.pagingConfiguration.pageNumber =
      payload.pagingConfiguration.pageNumber - 1;
    payload.pagingConfiguration.pullRecordCount = this.custReportsGridService.getPageConfiguration().pullRecordCount;
    payload.pagingConfiguration.totalRecords = this.custReportsGridService.getPageConfiguration().totalRecords;
    this.getData(payload);
  }

  public nextPage() {
    const payload = this.custReportsGridService.getPayload();
    if (+payload.pagingConfiguration.pageNumber < +this.custReportsGridService.getPageConfiguration().pageCount){

    payload.pagingConfiguration.pageNumber = +payload.pagingConfiguration.pageNumber + 1;
    payload.pagingConfiguration.pullRecordCount = this.custReportsGridService.getPageConfiguration().pullRecordCount;
    payload.pagingConfiguration.totalRecords = this.custReportsGridService.getPageConfiguration().totalRecords;

    this.getData(payload);
  }
  }
  public first() {
    const payload = this.custReportsGridService.getPayload();
    payload.pagingConfiguration.pageNumber = 1;
    payload.pagingConfiguration.pullRecordCount = this.custReportsGridService.getPageConfiguration().pullRecordCount;
    payload.pagingConfiguration.totalRecords = this.custReportsGridService.getPageConfiguration().totalRecords;

    this.getData(payload);
  }

  public last() {
    const payload = this.custReportsGridService.getPayload();
    payload.pagingConfiguration.pageNumber = this.custReportsGridService.getPageConfiguration().pageCount;
    payload.pagingConfiguration.pullRecordCount = this.custReportsGridService.getPageConfiguration().pullRecordCount;
    payload.pagingConfiguration.totalRecords = this.custReportsGridService.getPageConfiguration().totalRecords;

    this.getData(payload);
  }

  public goToPage() {
    const payload = this.custReportsGridService.getPayload();
    payload.pagingConfiguration.pageNumber = this.custReportsGridService.getPageConfiguration().pageNumber;
    payload.pagingConfiguration.pullRecordCount = this.custReportsGridService.getPageConfiguration().pullRecordCount;
    payload.pagingConfiguration.totalRecords = this.custReportsGridService.getPageConfiguration().totalRecords;

    this.getData(payload);
  }

  public onChange(size) {
    this.gridOptions.api.paginationSetPageSize(Number(size));
    this.gridOptions.cacheBlockSize = size;
  }

  public itemsPerPage() {
    const payload = this.custReportsGridService.getPayload();
    payload.pagingConfiguration.pageNumber = 1;
    payload.pagingConfiguration.pageSize = this.custReportsGridService.getPageConfiguration().pageSize;
    payload.pagingConfiguration.pullRecordCount = this.custReportsGridService.getPageConfiguration().pullRecordCount;
    payload.pagingConfiguration.totalRecords = this.custReportsGridService.getPageConfiguration().totalRecords;

    this.getData(payload);
  }

  public onColumnsChanged(event) {
    if (this.gridOptions.columnApi) {
      const currState = this.gridOptions.columnApi.getColumnState();
      currState.forEach((col) => delete col.width);
      if (JSON.stringify(currState) === JSON.stringify(this.prevState)) {
        return;
      }
      this.prevState = JSON.parse(JSON.stringify(currState));
      this.columnChanged.emit();
    }
  }
  public getData(payload: any) {
    if (this.gridOptions['paginationChanged'] instanceof Function) {
      this.gridOptions['paginationChanged'](
        payload.pagingConfiguration.pageNumber,
        payload.pagingConfiguration.pageSize,
        payload.pagingConfiguration.pullRecordCount,
        payload.pagingConfiguration.totalRecords
      );
    }
  }
}
