import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../shared/authentication.service';

@Component({
  moduleId: module.id,
  selector: 'login-error-page',
  templateUrl: './login-error-page.component.html',
  styleUrls: ['./login-error-page.component.sass'],
  providers: [AuthenticationService]
})
export class LoginErrorPageComponent implements OnInit {
  constructor(private authenticationService: AuthenticationService) {}

  public ngOnInit() {}

  public redirectToLogin() {
    this.authenticationService.logOutExecution();
  }
}
