import { DataStorageService } from 'app/modules/common/data-storage.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GridOptions } from '@ag-grid-community/core';
import { ICustomGridOptions } from 'app/modules/common/models/customGrid';
import { GridFormatterService } from 'app/grid.formatter.service';
import { GridConfiguration } from 'app/modules/core/nxt-grid/gridconfiguration';
import { BACKGROUND_COLOR_OBJECT } from '../../srfq/create/adhoc-rfx/shared/adhoc-rfx.constants';
import { CustomFilterComponent } from '../custom-filter/custom-filter.component';
import { AllModules, Module } from '@ag-grid-enterprise/all-modules';
import { GridEmitterService } from 'app/modules/common/grid.emitter.service';
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.sass'],
})
export class GridComponent implements OnInit {
  public modules: Module[] = AllModules;
  @Input() public msgOverlay: string;
  @Input() type?: string = undefined;
  @Input() public gridConfig: GridConfiguration;
  @Input() public customGridOptions: any;
  @Input() public customOptions: object;
  // @Input() public CustomEvent: any
  @Input() public numberRangeValidation: boolean;
  @Input() public paginationPageSizeList;
  @Output() public columnChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public gridFiltersChanged: EventEmitter<any> = new EventEmitter<any>();
  public errorMap: any = [];
  public gridCustomOptions = {} as GridOptions;
  public overlayNoRowsTemplate: any;
  public size = 50;
  @Input() public enablePagesizeDropdown = false;
  @Input() customPageConfig = null;
  public prevState;
  public showCustomPaginationDropdown = false;
  private gridOptions: GridOptions;
  private gridApi: any;

  pageConfig = {
    pageNumber: 1,
    pageCount: 0,
    pageSize: 50,
    totalRecords: 0,
  };

  @Input()
  set additionalGridConfig(val) {
    this._additionalGridConfig = val;
    this.pageConfig = val.pageConfig ? val.pageConfig : this.pageConfig;
  }
  get additionalGridConfig() {
    return this._additionalGridConfig;
  }
  _additionalGridConfig = null;

  rowDrag = false;
  updatedRowOrder = [];

  sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressSideButtons: true,
          // suppressColumnExpandAll: true
        },
      },
    ],
    // defaultToolPanel: "filters"
  };
  constructor(
    private dataStorageService: DataStorageService,
    private gridFormatter: GridFormatterService,
    private gridEmitterService: GridEmitterService
  ) {
    this.gridOptions = {} as GridOptions;
  }
  public onCellValueChanged(params) {
    if (this.gridConfig instanceof Object) {
      if (this.gridConfig.getCellEditValidator() instanceof Function) {
        try {
          if (params instanceof Object) {
            this.gridConfig.cellEditValidator(params);
          }
        } catch (e) {
          // console.log(e);
        }
      }
    } else {
      if (params.oldValue !== params.newValue) {
        this.dataStorageService.setnewValue(params);
      }
    }
  }

  public ngOnInit() {
    // console.log(this.customGridOptions);
    this.updatedRowOrder = this.customGridOptions.rowData || [];
    if (
      this.paginationPageSizeList instanceof Array &&
      this.paginationPageSizeList.length > 0
    ) {
      this.showCustomPaginationDropdown = true;
      this.size = this.paginationPageSizeList[0];
    } else {
      this.showCustomPaginationDropdown = false;
    }
    if (this.customOptions) {
      if (this.customOptions['showCustomNoRowsOverlay']) {
        this.overlayNoRowsTemplate =
          this.customOptions['overlayNoRowsTemplate'];
      } else if (this.msgOverlay !== '' && this.msgOverlay !== undefined) {
        this.overlayNoRowsTemplate =
          '<span style="padding: 10px; border: 2px solid #444; background: white;">' +
          this.msgOverlay +
          '</span>';
      } else {
        this.overlayNoRowsTemplate =
          '<span style="padding: 10px; border: 2px solid #444; background: white;">There are no results that match your Search Criteria</span>';
      }
    }
    this.gridOptions = this.customGridOptions;
    // if (this.CustomEvent !== undefined) {
    // }
    this.gridCustomOptions = {
      floatingFilter: false,
      pagination: true,
      paginationPageSize: 20,
      rowHeight: 30,
      suppressExcelExport: true,
      groupHeaderHeight: 30,
      headerHeight: 30,
      domLayout: 'autoHeight',
      rowSelection: 'multiple',
      suppressColumnVirtualisation: true,
      suppressCsvExport: true,
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
      },
      // rowModelType: 'inMemory',
      suppressRowClickSelection: false,
      suppressMenuHide: false,
      isRowSelectable: () => true,
    };
    if (this.customOptions !== undefined) {
      for (const key of Object.keys(this.customOptions)) {
        switch (key.trim()) {
          case 'suppressMenuHide':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
            break;
          case 'floatingFilter':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
            break;
          case 'pagination':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
            break;
          case 'paginationPageSize':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
            break;
          case 'toolPanel':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
            break;
          case 'rowHeight':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
            break;
          case 'headerHeight':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
            break;
          case 'groupHeaderHeight':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
            break;
          case 'domLayout':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
            break;
          case 'rowSelection':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
            break;
          case 'suppressColumnVirtualisation':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
            break;
          case 'rowModelType':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
            break;
          case 'suppressExcelExport':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
            break;
          case 'suppressCsvExport':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
            break;
          case 'suppressRowClickSelection':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
            break;
          case 'isRowSelectable':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
            break;
          default:
            break;
        }
      }
    }
    if (this.customGridOptions instanceof Object) {
      this.customGridOptions['columnTypes'] = {
        HTMLText: {
          comparator: this.gridFormatter.htmlTextSort,
        },
        FormattedCurrency: {
          comparator: this.gridFormatter.formattedCurrencySort,
        },
        FormattedPercentage: {
          comparator: this.gridFormatter.formattedPercentageSort,
        },
      };
    }
    // this.gridCustomOptions
    if (this.enablePagesizeDropdown) {
      this.gridOptions.paginationPageSize = this.size;
      this.gridCustomOptions.paginationPageSize = this.size;
    }
    // uncomment below line to see custom filter in ag-grid's column menu menu
    // this.customGridOptions["columnDefs"][0]["filterFramework"] = PartialMatchFilterComponent;
    if (
      this.customGridOptions instanceof Object &&
      this.customGridOptions.hasOwnProperty('columnDefs') &&
      this.customGridOptions['columnDefs'] instanceof Array
    ) {
      this.customGridOptions['columnDefs'].forEach((columnDef) => {
        if (
          columnDef.hasOwnProperty('datatype') ||
          columnDef.hasOwnProperty('type')
        ) {
          switch (columnDef.datatype) {
            case 'DECIMAL':
            case 'NUMERIC':
            case 'INT':
              if (
                columnDef['displayModifier'] instanceof Object &&
                columnDef['displayModifier'].hasOwnProperty('stringModifier') &&
                columnDef['displayModifier']['stringModifier'] instanceof Array
              ) {
                columnDef['filterFramework'] = CustomFilterComponent;
                columnDef['filterParams'] = {
                  applyButton: true,
                  stringModifier: true,
                  data: columnDef['displayModifier']['stringModifier'],
                };
              } else {
                columnDef['filter'] = 'agNumberColumnFilter';
                columnDef['filterParams'] = {
                  filterOptions: ['inRange'],
                  apply: true,
                };
              }
              columnDef['menuTabs'] = ['filterMenuTab', 'generalMenuTab'];
              break;
            case 'STRING':
            case 'SINGLESELECT':
            case 'TEXT':
              columnDef['filterFramework'] = CustomFilterComponent;
              columnDef['filterParams'] = {
                applyButton: true,
                stringModifier: false,
              };
              columnDef['menuTabs'] = ['filterMenuTab', 'generalMenuTab'];
              break;
            case 'DATE':
            case 'BIGINT':
            default:
              columnDef['filter'] = false;
              columnDef['menuTabs'] = ['generalMenuTab'];
              break;
          }
        }
      });
    }

    if (
      this.additionalGridConfig &&
      this.additionalGridConfig.enableCustomPaginationPanel
    ) {
      this.customPaginationMethod();
    }
  }

  customPaginationMethod() {
    this.customGridOptions.paginationPageSize = this.pageConfig.pageSize;
    this.gridCustomOptions.paginationPageSize = this.pageConfig.pageSize;
    this.setPageConfig();
  }

  setPageConfig() {
    switch (this.additionalGridConfig.enableServerSidePagination) {
      case true:
        break;
      case false:
        this.customGridOptions.onPaginationChanged =
          this.onPaginationChanged.bind(this);
        break;
    }
  }

  onRowDragStart($event) {
    if ($event) {
      this.rowDrag = true;
    }
  }

  getRowDrag() {
    return this.rowDrag;
  }

  onRowDragMove(event) {
    if (this.customOptions && this.customOptions['isReorderingEnabled']) {
      const movingNode = event.node;
      const overNode = event.overNode;
      const rowNeedsToMove = true;
      if (rowNeedsToMove) {
        const movingData = movingNode.data;
        const overData = overNode.data;
        const fromIndex = this.updatedRowOrder.indexOf(movingData);
        const toIndex = this.updatedRowOrder.indexOf(overData);
        const newStore = this.updatedRowOrder.slice();
        this.moveInArray(newStore, fromIndex, toIndex);
        this.updatedRowOrder = newStore;
        this.gridApi.setRowData(newStore);
        this.gridApi.clearRangeSelection();
      }
    }
  }

  moveInArray(arr, fromIndex, toIndex) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  getUpdatedRowOrder() {
    return this.updatedRowOrder;
  }

  public adhocRowStyle(params) {
    try {
      if (params.data) {
        if (typeof params['data']['IS_ADHOC_ITEM'] === 'number') {
          if (params['data']['IS_ADHOC_ITEM'] === 1) {
            return BACKGROUND_COLOR_OBJECT.duplicateBackground;
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  public onGridReady(params) {
    this.gridApi = params.api;
    if (
      this.customOptions &&
      this.customOptions instanceof Object &&
      this.customOptions['filterModel'] &&
      this.customOptions['filterModel'] instanceof Object
    ) {
      params.api.setFilterModel(this.customOptions['filterModel']);
      params.api.onFilterChanged();
    }
  }

  public onSelectionChanged(event) {
    if (!this.type) {
      this.dataStorageService.setSelectionChanged(
        this.gridApi.getSelectedRows()
      );
    } else {
      const selectedRows = {
        type: this.type,
        rows: this.gridApi.getSelectedRows(),
      };
      this.dataStorageService.setSelectionChanged(selectedRows);
    }
  }

  public onColumnsChanged(event) {
    if (this.gridOptions.columnApi) {
      const currState = this.gridOptions.columnApi.getColumnState();
      currState.forEach((col) => delete col.width);
      if (JSON.stringify(currState) === JSON.stringify(this.prevState)) {
        return;
      }
      this.prevState = JSON.parse(JSON.stringify(currState));
      this.columnChanged.emit();
    }
  }
  public onChange(size) {
    this.gridOptions.api.paginationSetPageSize(Number(size));
    this.gridOptions.cacheBlockSize = size;
  }

  public onFilterChanged(event) {
    const model = this.gridOptions.api.getFilterModel();
    this.gridFiltersChanged.emit(model);
  }

  first() {
    this.customGridOptions.api.paginationGoToFirstPage();
  }
  previousPage() {
    this.customGridOptions.api.paginationGoToPreviousPage();
  }
  nextPage() {
    this.customGridOptions.api.paginationGoToNextPage();
  }
  last() {
    this.customGridOptions.api.paginationGoToLastPage();
  }
  itemsPerPage() {
    this.customGridOptions.api.paginationSetPageSize(
      Number(this.pageConfig.pageSize)
    );
  }
  goToPage(pageNumber) {
    this.customGridOptions.api.paginationGoToPage(pageNumber - 1);
  }
  onPaginationChanged() {
    this.pageConfig.totalRecords =
      this.customGridOptions.api.paginationGetRowCount();
    this.pageConfig.pageCount =
      this.customGridOptions.api.paginationGetTotalPages();
    this.pageConfig.pageSize =
      this.customGridOptions.api.paginationGetPageSize();
    this.pageConfig.pageNumber =
      this.customGridOptions.api.paginationGetCurrentPage() + 1;
  }
  onPaginationPanelButtonClick(buttonType) {
    if (this.additionalGridConfig.enableServerSidePagination) {
      this.gridEmitterService.emitPaginationResult({
        buttonType,
        additionalGridConfig: this.additionalGridConfig,
      });
    } else {
      switch (buttonType) {
        case 'first':
          this.customGridOptions.api.paginationGoToFirstPage();
          break;
        case 'last':
          this.customGridOptions.api.paginationGoToLastPage();
          break;
        case 'next':
          this.customGridOptions.api.paginationGoToNextPage();
          break;
        case 'previous':
          this.customGridOptions.api.paginationGoToPreviousPage();
          break;
        case 'pagesizechange':
          this.customGridOptions.api.paginationSetPageSize(
            Number(this.pageConfig.pageSize)
          );
          break;
        case 'goto':
          const pageNumber = this.pageConfig.pageNumber;
          this.customGridOptions.api.paginationGoToPage(pageNumber - 1);
          break;
      }
    }
  }
}
