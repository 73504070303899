import {
  Component,
  AfterViewInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ECalendarValue, IDatePickerConfig, DatePickerComponent } from 'ng2-date-picker';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';

@Component({
  moduleId: module.id,
  selector: 'cprt-date-eidtor-cell',
  template: `
    <dp-date-picker
      #datePicker
      theme="dp-material grid-date"
      mode="day"
      [config]="datePickerConfig"
      [(ngModel)]="value"
      (onSelect)="onDateSelect()"
    ></dp-date-picker>
  `,
})
export class GridDateEditorComponent
  implements ICellEditorAngularComp, AfterViewInit {
  public datePickerConfig: IDatePickerConfig = {
    format: 'DD-MMM-YYYY',
    disableKeypress: true,
    closeOnSelect: true,
    returnedValueType: ECalendarValue.String,
    drops: 'down',
    opens: 'right'
  };
  params: any;
  @ViewChild('datePicker', { static: true })
  datePicker: DatePickerComponent;
  cancelBeforeStart = false;
  value;
  type = null;
  agInit(params: any): void {
    this.params = params;
    this.value = params.value || null;
  }

  getValue(): any {
    return this.value;
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
    this.datePicker.api.open()
  }

  isPopup(): boolean {
    return true;
  }

  setValue(value?): void {
    this.value = value;
  }

  onDateSelect() {
    this.setValue(this.value)
  }

}
