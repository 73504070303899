import {Component} from '@angular/core';

@Component({
    selector: 'app-red-component',

    template: `<div *ngIf="costnotAvailble"><p >Cost Not Available</p></div>
    <div  *ngIf="!costnotAvailble" >{{this.value}}</div>
    <div *ngIf="doller">{{this.val}}</div>
    <div *ngIf="percentage">{{this.percValue}}</div>`,
})
export class GridvalueComponent {
    private params: any;
    costnotAvailble = false;
    doller = false;
    percentage = false;
    value: any;
    member: any;
    dollerValue: any;
    percValue: any;
    val:any;

    agInit(params: any): void {
       if(params.value === params.data.mpnCost)
       {
       if(params.value===0){
       this.costnotAvailble = true;
       this.doller =false;
       this.percentage =false;

       }
       else{

           this.value=params.value;
           this.value=this.value.toFixed(6);
           this.doller =false;
           this.percentage =false;
       }
    }
    if(params.value === params.data.ebenchmarkFFFTargetCost)
    {
        if(params.data.ebenchmarkFFFTargetCost !== 'Cost Not Available')
        {
            this.value=params.value;
            this.value=this.value.toFixed(6);
            this.doller =false;
            this.percentage =false;
        }
        else
        {
           this.costnotAvailble = true;
        }
    }
    if(params.value === params.data.percentile50)
    {
        if(params.data.percentile50 !== 'Cost Not Available')
        {
            this.value=params.value;
            this.value=this.value.toFixed(6);
            this.doller =false;
            this.percentage =false;
        }
        else
        {
           this.costnotAvailble = true;
        }
    }
    if(params.value === params.data.percentile70)
    {
        if(params.data.percentile70 !== 'Cost Not Available')
        {
            this.value=params.value;
            this.value=this.value.toFixed(6);
            this.doller =false;
            this.percentage =false;
        }
        else
        {
           this.costnotAvailble = true;
        }
    }
    if(params.value === params.data.percentile90)
    {
        if(params.data.percentile90 !== 'Cost Not Available')
        {
            this.value=params.value;
            this.value=this.value.toFixed(6);
            this.doller =false;
            this.percentage =false;
        }
        else
        {
           this.costnotAvailble = true;
        }
    }
       if(params.value === params.data.overallspend || params.value === params.data.spend){
        this.doller = true;
        this.costnotAvailble = false;
        this.percentage =false;
        this.dollerValue = params.value;
         const usdFormate = new Intl.NumberFormat('en-US', {
            // style: 'currency',
             currency: 'USD',
            });
            this.val = usdFormate.format(this.dollerValue);
         this.val = '$' + this.val;


       }
    //    if(params.value === params.data.overallsavingsPercentage || params.value === params.data.vertpercentile70 || params.value === params.data.vertbestInClass || params.value === params.data.spendpercentile70 || params.value === params.data.spendbestInClass || params.value === params.data.allpercentile70 || params.value === params.data.allbestInClass){
    //              this.percentage =true;
    //              this.doller = false;
    //              this.costnotAvailble = false;
    //              this.percValue = params.value + "%";

    //    }
    if(params.value === params.data.overallsavingsPercentage || params.colDef.headerName ==='70th Percentile Savings %' || params.colDef.headerName === 'Best in Class Percentile Savings %' || params.colDef.headerName === 'Sub Commodity Savings % Last 12 Months' || params.colDef.headerName === 'Sub Commodity Savings % Last 3 Months'){
        this.percentage =true;
                     this.doller = false;
                     this.costnotAvailble = false;
                     this.percValue = params.value + '%';
    }
    }
}
