import {
  BodyOutputType,
  Toast,
  ToasterConfig,
  ToasterService
} from 'angular2-toaster';

/*
Purpose : This is the application level toaster which will help to show the toaster to user.
Usage : NpiToasterService.populateToaster(TOASTER_MESSAGE_TYPES.INFO, <message to display|HTML message to display>, [<false|true>]);'
*/
export class NpiToasterService {
  public static configToaster: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-right',
    timeout: 5000,
    limit: 1
  });

  private static toasts = [];
  private static toasterService;

  public static populateToaster(
    toastertype: string,
    toasterMessage: string,
    toastWithBodyFlag?: boolean
  ) {
    const toast: Toast = {
      type: toastertype,
      timeout: 5000
    };
    if (toastWithBodyFlag) {
      toast.body = `<div class="toast-msg ${toastertype}-msg">${toasterMessage}</div>`;
      toast.bodyOutputType = BodyOutputType.TrustedHtml;
    } else {
      toast.title = toasterMessage;
    }
    toast.showCloseButton = true;
    return this.toasts[this.toasts.push(this.toasterService.pop(toast))];
  }
  public static setToasterService(toasterService: ToasterService) {
    this.toasterService = toasterService;
  }

  public static clearAllToasts() {
    if (this.toasts instanceof Array && this.toasts.length > 0) {
      this.toasts.forEach(this.clearLastToast.bind(this));
    }
  }
  public static clearLastToast() {
    if (this.toasts instanceof Array && this.toasts.length > 0) {
      this.toasts.pop();
      this.toasterService.clear();
    }
  }
}
