import { Component, Input, OnInit } from '@angular/core'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import { Module } from '@ag-grid-community/core'
import { MAP_CHART } from '../graph-item/configuration'
import _ from 'lodash'
import { attrLabelMap, mainProperties } from './event-detail.constants'

export interface PropMetaInfo {
  displayName: string
  count: number
  data: any
  type: string
  tooltip: boolean
}
@Component({
  selector: 'cprt-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.sass'],
})
export class EventDetailsComponent implements OnInit {
  @Input() currentEvent: any = {}
  @Input() hideHeader
  @Input() hideTitle
  graphOptions = null
  modules: Module[] = AllModules
  showPreloader = true
  loaderConfig = {
    backdropBackgroundColour: 'rgba(255,255,255,0.3)',
    fullScreenBackdrop: 'true',
    primaryColour: '#1C4E84',
    secondaryColour: '#1C4E84',
    tertiaryColour: '#1C4E84',
  }

  additionalProperties = []
  mainProperties = []
  impactGraphMetadata = {
    visualCardId: 9,
    targetedType: 'RiskManagementService',
    displayName: 'Impact By(next quarter)',
    visualCardKey: 'IMPACT_VIEW_BY',
    endPoint: '',
    metaData:
      '{"chartType":"Bar","subtype":"grouped","filterOptions":{"label":"View By","data":[{"displayName":"Product Family","val":"product"},{"displayName":"Commodity","val":"commodity"}]},"viewBy":true}',
    order: 1,
    dataItems: {},
  }
  siteMap = {
    visualCardId: 7,
    displayName: 'Events By',
    visualCardKey: 'MAP_VIEW_BY',
    metaData: `{"chartType":"Map","filterOptions":{"label":"View By","data":[{"displayName":"All Community Sites","val":"All Community Sites"},{"displayName":"My Portfolio Sites","val":"My Portfolio Sites"},{"displayName":"My Sites","val":"My Sites"}]},"tooltip":{"headerFormat":"","pointFormat":"{point.siteImpacted} <br>{point.city} {point.options.state} {point.country} <br>Supplier: {point.vendorName}<br>Latitude: {point.lat}<br>Longitude: {point.lon}"},"viewBy":true,"hideLegend":true,"drillDown":false}`,
    endPoint: '/events/sites/affected',
    order: 2,
    dataItems: {},
  }
  expandVal = '';

  constructor() { }

  setExpandVal(expandValue) {
    this.expandVal = expandValue
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    })
  }
  onSupplierGridReady(params) {
    params.api.sizeColumnsToFit()
    params.api.setDomLayout('autoHeight')
  }
  ngOnChanges() {
    this.ngOnInit()
  }

  ngOnInit() {
    this.processMainProperties()
    this.processAdditionalProperties()
    this.generateLocationGraph()
      ; (this.impactGraphMetadata.endPoint = `RiskManagementService/insight/events/impact?eventId=${this.currentEvent.eventId}&`),
        (this.impactGraphMetadata.metaData = JSON.parse(
          this.impactGraphMetadata.metaData
        ))
    this.siteMap.endPoint = `/RiskManagementService/insight/sites/impacted?eventId=${this.currentEvent.eventId}&`
    this.siteMap.metaData = JSON.parse(this.siteMap.metaData)

    setTimeout(() => {
      this.showPreloader = false
    }, 500)
  }

  processMainProperties() {
    this.mainProperties = []
    mainProperties.forEach((property) => {
      const propObject: PropMetaInfo = _.cloneDeep(attrLabelMap[property])
      propObject.data = this.currentEvent[property]
      propObject.count = this.currentEvent[property].length
      propObject.tooltip =
        propObject.type === 'text'
          ? false
          : propObject.type === 'number' && propObject.count !== 0
            ? true
            : false
      this.mainProperties.push(propObject)
      console.log(this.mainProperties)
    })
  }
  capitalizeFirstLetter(value) {
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
  processAdditionalProperties() {
    this.additionalProperties = []
    const attrPropertiesInfo =
      _.cloneDeep(this.currentEvent.additionalInfo) || {}
    Object.keys(attrPropertiesInfo).forEach((property) => {
      const propObject: PropMetaInfo = _.cloneDeep(attrLabelMap[property]) || {
        displayName: this.capitalizeFirstLetter(property),
        count: 0,
        type:
          typeof attrPropertiesInfo[property] === 'string' ? 'text' : 'number',
        data: [],
        tooltip: true,
      }
      propObject.data = attrPropertiesInfo[property]
      propObject.count = attrPropertiesInfo[property].length
      propObject.tooltip =
        propObject.type === 'text'
          ? false
          : propObject.type === 'number' && propObject.count !== 0
            ? true
            : false
      if (propObject.type === 'text' && propObject.count === 0) {
      } else {
        this.additionalProperties.push(propObject)
      }
    })
    console.log(this.additionalProperties)
  }

  generateLocationGraph() {
    if (!this.currentEvent.locations) {
      return
    }
    this.graphOptions = _.cloneDeep(MAP_CHART)
    this.graphOptions.tooltip = {
      headerFormat: '',
      pointFormat:
        '<br>{point.location}<br>Latitude: {point.lat}<br>Longitude: {point.lon}',
    }
    const locationData = this.currentEvent.locations.map((x) => {
      return {
        location: `${x.city}, ${x.state}, ${x.country}`,
        lat: x.latitude,
        lon: x.longitude,
      }
    })
    const mapPoint = {
      // Specify points using lat/lon
      type: 'mappoint',
      cursor: 'pointer',
      name: 'Location',
      marker: {
        radius: 5,
        symbol: 'circle',
        fillColor: 'orange',
      },
      allAreas: false,
      data: locationData,
    }
    this.graphOptions.series[1] = mapPoint
  }
}
