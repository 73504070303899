import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })

export class GraphUtilService {

  private htmlBody;

  setGraphEvents(){

  }

  numberWithCommas(x){
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  numberAsItIs(x){
    return x.toString();
  }

  setTooltip(params: any): void {
      this.htmlBody =
      `<div class="graph-container-tooltip">
        <div class="tooltip-title text-overflow">
        <p style="padding:0" title="${params.title}">${params.title}</p>
        </div>
        <div class="tooltip-target">
          <i class="material-icons brightness_1" style="font-size: 12px; line-height: 10px; padding-right: 5px; color: ${params.indicatorColor}"></i> <span class="text-overflow" style="height: 20px; line-height: 20px" title="${params.targetName}"><b>${params.targetName}</b></span>
        </div>
        <div class="tooltip-content">
          <div class="tooltip-content-row">
              <div class="tooltip-content-row-label" title="${params.data[0].title}">${params.data[0].title}</div>
              <div class="tooltip-content-row-data"> ${params.data[0].value}</div>
          </div>
          <div class="tooltip-content-row">
          <div class="tooltip-content-row-label"title="${ params.data[1] && params.data[1].title}">${params.data[1] && params.data[1].title}</div>
          <div class="tooltip-content-row-data"> ${params.data[1] && params.data[1].value}</div>
          </div>
        </div>
      </div>`;
   }

  getTooltip(){
    return  this.htmlBody;
  }
}
