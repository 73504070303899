import { Injectable } from '@angular/core';
import { BaseService } from 'app/modules/common/base.service';
import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService';
import { ScopeDataService } from './scope.data.service';
import { csAppTabFeatures } from './CSAppConfiguration.constants';
@Injectable()
export class NavBarService {
    scope: any;
    obj: any;
    subfeatures: any;
    menuMap: any = [];
    titleObj: any;
    protected navBarList: any;
    constructor(private baseService: BaseService,
        private customerConfigurationService: CustomerConfigurationService,
        private scopedataService: ScopeDataService) {
        this.titleObj = this.customerConfigurationService.getRFQCustomDimensions();
        this.obj = this.baseService.getUserInformation();
        // this.subfeatures = this.obj.userFeatureDetailsDTO.features;
        this.scope = this.scopedataService.getFocusValue();
        this.scopedataService.getFocus().subscribe((val) => {
            this.scope = val;
        });

    }


    public getNavBarList(target) {
        if (this.baseService.getCsApp()) {
            this.menuMap = csAppTabFeatures;
            const navList = this.menuMap.find(item => item[target] instanceof Object);
            return navList[target];
        }
        if (this.customerConfigurationService.getConfData(this.scope)
            && this.customerConfigurationService.getConfData(this.scope).mainTabFeatures) {
            this.menuMap = this.customerConfigurationService.getConfData(this.scope).mainTabFeatures;
        }
        /**
         * For Enable New Landing page tab
         */
        if (target === 'administration') {
            let checkElement;
            if (this.obj.groupNames[0] === 'Super Admin') {
                this.menuMap.forEach((element, index) => {
                    if (element) {
                        if (element.hasOwnProperty('administration')) {
                            checkElement = element['administration'].find(i => i.id === 27);
                            if (!checkElement) {
                                element['administration'].push({
                                    id: 27,
                                    order: 2,
                                    view: 'new-landing-page-configuration',
                                    title: 'Landing Page Configuration'
                                });
                            }
                        }
                    }
                }
                );
            }
        } else if (target === 'insight-to-action') {

            this.menuMap.push({
                'insight-to-action': [
                    {
                        id: 100,
                        order: 1,
                        view: 'insights',
                        title: 'Insight'
                    },
                    {
                        id: 102,
                        order: 2,
                        view: 'insight-action',
                        title: 'Action'
                    },
                    /*  {
                         id: 103,
                         order: 3,
                         view: 'portfolio-summary',
                         title: 'Portfolio Summary'
                     } */
                ]
            });
        }
        const navBarList = this.menuMap.find(item => item[target] instanceof Object);
        if (navBarList && navBarList[target]) {
            navBarList[target] = navBarList[target].filter((i) => i.view !== 'null');
        }
        if (navBarList) {
            return navBarList[target];
        } else {
            return [];
        }

    }
}
