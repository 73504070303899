import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

import { AppHeader } from './app-header.model';
import { ApiServiceProvider } from 'app/api-service';
import { BaseService } from 'app/modules/common/base.service';
import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService';
import { Subject } from 'rxjs';
import { AuthenticationService } from 'app/modules/authentication/shared/authentication.service';
import { Router } from '@angular/router';
import { ScopeDataService } from 'app/modules/common/scope.data.service';

@Injectable()
export class AppHeaderService {

  constructor(private http: HttpClient, private apiService: ApiServiceProvider,
    private baseService: BaseService,
    private customerConfigurationService: CustomerConfigurationService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private scopeDataService: ScopeDataService
  ) {}
  envDetails: any;
  targetType: any;
  msgpanel = new Subject<boolean>();
  public getTimePeriodData() {
    try {
      // var data: any = {
      // 	"servicePath": "tsdata"
      // };

      this.envDetails = this.customerConfigurationService.getEnvironmentDetails();
      this.targetType = this.envDetails.coreServices;

      const userName = this.baseService.getUserInformation().email
      const object: any = {
        targetedType: 'CommonService',
        formParams: {
          userEmail: userName,
        },
        method: 'GET',
        servicePath: '/tsdata'
      };


      return this.apiService.post(object);
    } catch (exception) {
      console.log('Exception ins TimeSelection upload Service ', exception);
    }
  }

  public getDataLoadStatus() {
    const envDetails = this.customerConfigurationService.getEnvironmentDetails();
    const tenantId = envDetails.tenantId;
    const object: any = {
      targetedType: 'CustomReports',
      formParams: {
        tenantId
      },
      method: 'GET',
      servicePath: '/postdata'
    };
    return this.apiService.post(object);
  }
  logoutServiceCall() {
    const obj: any = {
      targetedType: 'CMS',
      serviceType: 'logOut',
      servicePath: 'AuthService/logout'
    };
    this.apiService.post(obj).subscribe(val => {
      console.log('HELLLLLOOOOO');
    });
  }

  public getUnreadSubjects() {
    const useremail = this.baseService.getUserInformation().email
    const object: any = {
      targetedType: 'Messages',
      formParams: {
        tenantType: 'CUSTOMER',
        userEmail: useremail,
        scope: this.scopeDataService.getFocusValue().toUpperCase()
      },
      method: 'GET',
      servicePath: 'unreadMessagesByScope'
    };
    return this.apiService.post(object);

  }

  setMsgPanel(val) {
    this.msgpanel.next(val);
  }
  getMsgPanel() {
    return this.msgpanel.asObservable();
  }

  getRFQTitleObject() {
    return this.customerConfigurationService.getRFQCustomDimensions();
  }


  public getAlertsCount(scope?: string) {
    let templateType = 'COMMODITY';
    if (scope === 'Commodity') {
      templateType = 'COMMODITY';
    } else if (scope === 'Product') {
      templateType = 'PRODUCT';
    }

    const object: any = {
      targetedType: 'AlertsService',
      formParams: {
        alertType: 'RFQ',
        templateType,
        tenantType: 'CUSTOMER',
        sort: '-DATE'
      },
      method: 'GET',
      servicePath: '/user/alertsCount'
    };
    return this.apiService.post(object);
  }
}
