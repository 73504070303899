import { PipeTransform, Pipe } from '@angular/core';
@Pipe({ name: 'ShortAnumber', pure: false })
export class ShortAnumber implements PipeTransform {
  /* transform(value: any, args: any[] = null): any {
        return Object.keys(value)//.map(key => value[key]);
    } */
  transform(input, digits?: any): any {
    let returnVal;
    let isNegative = false;
    if (input < 0) {
      isNegative = true;
      input = -input;
    } else {
      isNegative = false;
    }
    if (input !== undefined) {
      if (input >= 1000) {
        const units = ['K', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'];
        let decimal;
        for (let i = units.length - 1; i >= 0; i--) {
          decimal = Math.pow(1000, i + 1);
          if (input <= -decimal || input >= decimal) {
            if (isNegative) {
              return `- ${window['currencySymbol']}${(input / decimal).toFixed(
                2
              )}${units[i]}`;
              // return ("-$" + (input / decimal).toFixed(2) + " " + units[i]);
            } else {
              return `${window['currencySymbol']}${(input / decimal).toFixed(
                2
              )}${units[i]}`;
            }
          }
        }
        // returnVal = input;
      } else {
        if (isNegative) {
          returnVal = `-${window['currencySymbol']}${Number(input).toFixed(
            2
          )}`;
        } else {
          returnVal = `${window['currencySymbol']}${Number(input).toFixed(
            typeof digits === 'number' ? digits : 2
          )}`;
        }
      }
      return returnVal;
    }
  }
}
