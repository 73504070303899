import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core'

import { AboutLevadata } from './shared/about-levadata.model'
import { AboutLevadataService } from './shared/about-levadata.service'
import { DateUtils } from 'app/modules/common/utills/DateUtils.service'
import { SubscriptionLike } from 'rxjs'
import { CustomerConfigurationService } from '../../common/customerConfigurationService'
import { ScopeDataService } from '../../common/scope.data.service'

@Component({
  moduleId: module.id,
  selector: 'about-levadata',
  templateUrl: 'about-levadata.component.html',
  styleUrls: ['about-levadata.component.sass'],
  encapsulation: ViewEncapsulation.None,
  providers: [AboutLevadataService],
})
export class AboutLevadataComponent implements OnInit {
  aboutLevadata: AboutLevadata[] = []
  releaseVersion: string
  appLastUpdate: Array<any> = []
  viewDetails = false
  dataLoadArray: Array<any> = []
  @Output() closeIcon = new EventEmitter<any>()
  applicationLastUpdateDate: string
  scopeList: Array<any> = []
  lastDataRefreshDate: string
  changeIcon = false
  bOMData: string
  custConfname: any
  costData: string
  showCostAdders = false
  showRawMaterials = false
  showPredictivecost = false
  showTransformation = false
  dataMap = {
    bOMData: 'BOM',
    costAddersData: 'Cost Adders',
    costData: 'Cost',
    forecastDemandData: 'Forecast/Demand',
    marketIntelligenceLastRefreshed: 'Market Intelligence',
    opportunitiesDataLastRefreshed: 'Opportunities',
    predictiveCostLastUpdated: 'Predictive Cost',
    rawMaterialMappingData: 'Raw Material Mapping',
    transformationData: 'Transformation',
  }
  constructor(
    private aboutLevadataService: AboutLevadataService,
    private customerConfigurationService: CustomerConfigurationService,
    private scopeDataService: ScopeDataService
  ) { }

  ngOnInit() {
    try {
      this.custConfname = this.customerConfigurationService.getConfData(
        'Product'
      )
      if (this.custConfname && this.custConfname.subTabFeatures) {
        let caList = []
        caList = this.custConfname.subTabFeatures.filter(
          (item) => item['cost'] instanceof Array
        )
        if (caList.length > 0) {
          caList[0]['cost'].forEach((item) => {
            if (item.view.includes('costadders')) {
              this.showCostAdders = true
            }
          })
        }
      }
      const focus = this.scopeDataService.getFocusValue()
      this.scopeList = this.customerConfigurationService.getScopeList()
      for (const item of this.scopeList) {
        if (item['view'].toLowerCase() === 'product') {
          this.showTransformation = true
        }
      }
      const configName = this.customerConfigurationService.getConfData(focus)
      if (configName && configName.subTabFeatures) {
        let rmList = []
        rmList = configName.subTabFeatures.filter(
          (item) => item['rawmaterial'] instanceof Array
        )
        this.showRawMaterials = rmList.length > 0 ? true : false
      }

      this.showPredictivecost = this.customerConfigurationService.getConfData(
        focus
      ).features.costPredictiveCost

      this.aboutLevadataService.getAboutScreenDetails().subscribe((val) => {
        if (val) {
          try {
            if (val && val.responseStatus && val.responseStatus.code === 200) {
              if (val.result instanceof Object) {
                this.releaseVersion = val.result.release_version
                this.applicationLastUpdateDate = this.getDate(
                  val.result.application_last_update
                )
                this.lastDataRefreshDate = this.getDate(
                  val.result.last_data_refresh
                )
                this.viewDetails = true
              }
            }
          } catch (e) {
            console.log(e)
          }
        }
      })
    } catch (e) {
      console.log('error in ngOninit', e)
    }
  }

  onClick() {
    this.changeIcon = this.changeIcon ? false : true
  }
  onClickCloseIcon() {
    this.closeIcon.emit(false)
  }
  getDate(epochDate) {
    try {
      const convertDate = DateUtils.format(epochDate)
      const objDate = new Date(convertDate),
        locale = 'en-us',
        month = objDate.toLocaleString(locale, { month: 'long' })
      const dateArray = convertDate.split('/')
      const formatDate = `${dateArray[2]} ${month} ${dateArray[1]}`
      return formatDate
    } catch (e) {
      console.log(e)
    }
  }
}
