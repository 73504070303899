import { BaseService } from 'app/modules/common/base.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextLandingPageService } from './context-landing-page.service';
import { ApiServiceProvider } from 'app/api-service';
import { DataStorageService } from '../common/data-storage.service';
import {SharedService} from '../shared/services/shared.service';
import {CUSTOMER_CURRENT_USER, CUSTOMER_ENCRYPTED_STRING_TASK_ID} from '../shared/services/shared-consts';
import { UtilService } from 'app/modules/common/utills/util.service';
import { OLD_URL_MAP } from '../shared/constants/shared.constants';

@Component({
  selector: 'app-context-landing-page',
  templateUrl: './context-landing-page.component.html',
  styleUrls: ['./context-landing-page.component.sass']
})
export class ContextLandingPageComponent implements OnInit {
  showTaskLoader = true;
  taskId;
  contextid;
  topicName;
  userEmail;
  queryString;
  queryParams;
  constructor(private router: Router,
    private contextLandingPageService: ContextLandingPageService,
    private baseService: BaseService,
    public dataStorageService: DataStorageService,
    private apiService: ApiServiceProvider,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private utilService: UtilService) {
    this.route.params.subscribe((params) => {
      console.log(params);
      this.queryParams = params;
      this.queryString = encodeURIComponent(params.taskId);
      // this.queryString = encodeURIComponent(this.queryString);
    });
  }

  ngOnInit() {
    if (this.baseService.getUserInformation()) {
      this.userEmail = this.baseService.getUserInformation().email;
    }
    else {
      const data = JSON.parse(localStorage.getItem(CUSTOMER_CURRENT_USER));
      if (data) {
        this.userEmail = data.email;
      }
    }
    if (!this.userEmail) {
      this.dataStorageService.encrptedStringTaskId = this.queryParams;
      this.sharedService.setLocalStorageData(CUSTOMER_ENCRYPTED_STRING_TASK_ID, this.queryParams)
      this.router.navigate(['']);
    }
    this.dataStorageService.encrptedStringTaskId = this.queryParams;
    this.apiService.fromTaskManagement = true;
    this.contextLandingPageService.getContextDetail(this.queryString, this.userEmail).subscribe(data => {
      if (data && data.result) {
        this.taskId = data.result.partNumber || data.result.taskId;
        this.contextid = data.result.partNumber || data.result.id;
        this.topicName= data.result.topicName;
        this.showTaskLoader = false;
        this.apiService.fromTaskManagement = false;
        this.updateTaskDetails(data)
      } else {
        this.contextLandingPageService.getMailAuth(this.queryString, this.userEmail).subscribe(res => {
          if (res && res.result) {
            this.taskId = res.result.taskId;
            this.contextid = res.result.id;
            this.topicName= res.result.topicName;
            this.contextLandingPageService.getTaskList(this.taskId, this.userEmail).subscribe(data => {
              if (data && data.result) {
                this.updateTaskDetails(data)
              } else {
                this.router.navigate(['']);
              }
            })
          }
        })
      }
    })
  }

  updateTaskDetails(data) {
    this.showTaskLoader = false;
    this.apiService.fromTaskManagement = false;
    if (this.contextid) {
      if (this.queryParams) {
        this.dataStorageService.encrptedStringTaskId = this.queryParams;
      }
      data.result.contextDetails.forEach(element => {
        if (element.id === this.contextid || element.value === this.contextid) {
          element.type = element.iconType
          this.router.navigate([element.url],
            {
              state:
              {
                clickedItem: element,
                data: data.result
              }
            });
        }
      });
    }
    else if (this.topicName) {
      if (this.queryParams) {
        this.dataStorageService.encrptedStringTaskId = this.queryParams;
      }
      const url = OLD_URL_MAP[data.result.locationDetails.url.split('?')[0]] || data.result.locationDetails.url.split('?')[0]
      this.router.navigate([url],
        {
          queryParams: data.result.locationDetails.url.split('?')[1] ? this.utilService.queryStringToJSON(data.result.locationDetails.url.split('?')[1]) : null,
          state:
          {
            clickedItem: 'topicName',
            data: null,
          }
        }).then(() => {
          this.dataStorageService.showTaskPane = false;
          this.dataStorageService.taskActionInput = true;
          this.dataStorageService.openEdittaskPayload = data.result;
          this.dataStorageService.setEditTask(true);
        });
    }
    else {
      if (this.queryParams) {
        this.dataStorageService.encrptedStringTaskId = this.queryParams;
      }
      const url = OLD_URL_MAP[data.result.locationDetails.url.split('?')[0]] || data.result.locationDetails.url.split('?')[0]
      this.router.navigate([url],
        {
          queryParams: data.result.locationDetails.url.split('?')[1] ? this.utilService.queryStringToJSON(data.result.locationDetails.url.split('?')[1]) : null,
          state:
          {
            clickedItem: data.result.locationDetails,
            data: data.result
          }
        });
    }
  }

}
