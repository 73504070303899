// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`

export const environment = {
  production: false,
  zuulApiEndPointPath:
    'https://dev-zuul-service.v1.nonprod.levadata.org/ZuulService/',
  bypassedServices: [],
  webSocket: false,
  webSocketInterval: 60000,
  scoket: {
    path: 'ws://frontdooruat.levadata.net/LevaAlertsService/notificationSocketHandler?userEmail=',
    timeDelay: 10000,
  },
  NpWaitingTime: 30000,
  subscriptionType: 'All',
  NpProcessingPopup: true,
  isProductRfxEnabled: true,
  API_ENDPOINT_LOCAL_PATH: 'assets/api/',
  API_ENDPOINT_CONFIG_PATH: 'assets/api/config/',
  userServiceApiPath: 'UserManagementService/api/userManagementService',
  authenticationService: 'AuthenticationService/api/authService',
  authenticationServiceApiPath: 'AuthenticationService/api',
  userService: 'UserManagementService',
  octapartDistributorLink: 'https://api.levadata.net/v1/get-ds-link',
  customerConfigurationServiceApiPath:
    'CustomerConfigurationService/api/customerConfiguration',
  customerConfigurationGenericServiceApiPath:
    'CustomerConfigurationService/api',
  partWorkbenchServiceApiPath: 'PartWorkbenchService/api/partworkbench',
  customerConfigurationService: 'CustomerConfigurationService',
  printGraphsHeight: '600',
  content: {
    contentManagementService: 'ContentManagementService',
    dataVisualization: 'DataVisualization',
    visualizationDashboardId: 'f702b16d-c636-468f-b961-e08b0811239f',
    dashboardService: 'DataVisualizationService',
  },
  risk: 'riskManagementService',
  printGraphsWidth: '1300',
  sessionTimeoutProperties: {
    idle: 1800,
    ping: 1,
    timeUp: 20,
  },
  dynamicData: true,
  acceptedDomain: [],
  ssoEnabledApp: true,
  showTrend: true,
  zebraSingleLogoutUrl: 'https://pi.zebra.com/ext/logout',
  zebraTenantId: 999,

  environmentDetails: {
    tenantId: null,
    customerId: null,
    ieTenantId: null,
    sharedSecretKey: 'TGV2QGRAdEBhczNjcjN0Mg===',
    ALERTS: 'AlertsService',
    RFQ: 'CRMService',
    SchedulerService: 'SchedulerService',
    Messages: 'MessageService',
    alertsServicePath: 'AlertsService',
    zuulApiEndPointPath:
      'https://dev-zuul-service.v1.nonprod.levadata.org/ZuulService/',
    crmSrvcPath: '/CRMService/',
    elasticSearch: 'ElasticSearch',
    ieService: 'InteractiveEdit',
    envName: 'prod',
    jobType: 'USER_EDIT',
    levaAlertsServices: 'LevaAlertsService',
    negotiationServiceApiPath: 'NegotiationService/api/negotiationService',
    negotiationService: 'NegotiationService',
    analyticsServiceApiPath: 'AnalyticsService/api/analyticsService',
    sisenseSevicePath: '/SisenseService/api/sisenseservice',
    postDataRefreshService: '/PostDataRefreshService',
    analyticsService: 'AnalyticsService',
    miService: 'MITenantService',
    platformServices: 'PlatformServices',
    riskManagementService: 'RiskManagementService',
    eBenchmarkService: 'EBenchMarkService',
    miServiceApipath: 'MITenantService/api/MIService',
    commonService: 'CommonService',
    commonServiceApipath: 'CommonService/api/commonService',
    customReports: 'CustomReports',
    chatbotService: 'ChatBotService',
    contentManagementService: 'ContentManagementService',
    industryNewsPlatformService: 'IndustryNewsPlatformService',
    IndustryNewsTenantService: 'IndustryNewsTenantService',
    templateConfig: {
      service: '/TemplateService',
    },
    alertsConfig: {
      service: 'AlertsService',
    },
    productRFQConfig: {
      service: '/CustomerRfxService',
    },
    partsMasterService: 'PartMasterService/api/partmaster/',
    programManagementServicePath:
      'ProgramManagementService/api/programservice/',
    programManagementService: 'programservice',
    bomManagementServicePath: 'BomManagementService/api/bommanagement/',
    bomManagementFlattenedServicePath:
      'BomManagementService/api/bommanagement/flattened/',
    stageGateManagementServicePath:
      'StageGateManagementService/api/stagegateservice/',
    templateManagementServicePath:
      'TemplateManagementService/api/templatemanagementservice',
    collabService: 'CollaborationService',
    PartWorkbenchService: 'PartWorkbenchService/api/partworkbench',
  },
  postDataRefreshSteps: {
    executableSteps: [
      'step1_OPP_ALERTS',
      'step2_GENERATE_LEVERS_CMS',
      'step5_ES_CMS',
    ],
    jobExecutionType: 'FULL',
    tenantID: ':tenantId',
    userEmail: ':userEmail',
    time: ':time',
    steps: [
      {
        step2_GENERATE_LEVERS_CMS: {
          userEmail: ':userEmail',
          leverType: 'All',
        },
      },
      {
        step5_ES_CMS: {
          indexKey: 'cms_data,fuzzy_cms_data',
          tenantId: ':tenantId',
        },
      },
      {
        step6_RFQ_SYNC: {
          synchcmsrfqcommpartsdata: 'synchcmsrfqcommpartsdata',
          getOpportunities: 'getOpportunities',
          oppservicetype: 'full',
          customerName: ':customerName',
          serviceNames: 'RFQ,OPP',
          tenantID: ':tenantId',
        },
      },
      {
        step7_ES_RFQ: {
          indexKeys: 'rfq_data,com_parts_data,bot_entities_data',
          tenantId: ':tenantId',
        },
      },
      {
        step9_Np_Cache: {
          tenantID: ':tenantId',
          userEmail: ':userEmail',
        },
      },
      {
        step8_Supplier_Score_Card_Alerts: {
          userEmail: ':userEmail',
          driver: 'Supplier Score Insight',
          sequential: 'false',
        },
      },
      {
        step12_RM_Refresh: {
          tenantID: ':tenantId',
          userEmail: ':userEmail',
        },
      },
      {
        step13_Content_Ingestion: {
          tenantID: ':tenantId',
          userEmail: ':userEmail',
        },
      },
      {
        step14_New_Custom_Report: {
          tenantID: ':tenantId',
          userEmail: ':userEmail',
          newReportName: '',
          newReportJson: '',
        },
      },
    ],
  },
};
