import { SubscriptionService } from 'app/modules/common/subscription.service'
import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  OnDestroy,
} from '@angular/core'

import { GenericAccordionService } from 'app/modules/core/generic-accordion/shared/generic-accordion.service'
import { UploadDownloadService } from '../../srfq/shared/upload-download.service'
import { API_SERVICE_CONSTANTS } from '../../npi/npiconstants'
import { IfObservable } from 'rxjs/observable/IfObservable'

@Component({
  selector: 'genericaccordion',
  templateUrl: 'generic-accordion.component.html',
  styleUrls: ['./generic-accordion.component.sass'],
  providers: [GenericAccordionService],
})
export class GenericAccordion implements OnInit, OnChanges {
  public accordion: GenericAccordion[] = []
  public _bomId: number
  public _revisionId: number
  public _bomVersionId: number
  public downloadPayload
  public _downloadGenericExcelPayload
  @Input() public showGenericExport = false
  @Input() public isDropdown = false;
  @Input() public isCollaboration = true;
  @Input() public isOpen: boolean
  @Input() public showAccordionIcon = false
  @Input() public heading: string
  @Input() public classList = {}
  @Input() public showExport = false
  @Input() public bomHistoryObject: any
  @Input() public colab: any
  @Input() public gridApi
  @Input() public gridType
  @Input() public errorMessage: string
  @Input() public showErrorMessage = false
  @Input() set bomId(val) {
    if (typeof val === 'number') {
      this._bomId = val
    }
  }
  @Input() set revisionId(val) {
    if (typeof val === 'number') {
      this._revisionId = val
    }
  }
  @Input() set versionId(val) {
    if (typeof val === 'number') {
      this._bomVersionId = val
    }
  }
  @Input() set downloadGenericExcelPayload(val) {
    if (val) {
      this._downloadGenericExcelPayload = val
    }
  }

  @Input() public fixedHeight
  @Output()
  onToggleAccordion: EventEmitter<boolean> = new EventEmitter<boolean>()

  public ngClass
  public excelInfo = {
    method: 'GET',
    type: 'NPI',
  }
  constructor(
    private accordionService: GenericAccordionService,
    private uploadDownloadService: UploadDownloadService
  ) {
    this.ngClass = { 'panel-open': this.isOpen }
  }

  public ngOnInit() {
    if (Object.keys(this.classList || {}).length > 0) {
      for (const key in this.classList) {
        if (key === 'full-height') {
          this.classList['full-height'] = this.isOpen
        }
        this.ngClass[key] = this.classList[key]
      }
    }
  }
  public ngOnChanges() {
    if (this._bomId && this._revisionId && this._bomVersionId) {
      this.formExcelPayload()
    }
  }
  // toggleOpen(event: MouseEvent): void{
  // console.log(event)
  // 	 event.preventDefault();
  // 	// this.options.isOpen = !this.options.isOpen;
  // }
  public toggleOpen(event: MouseEvent): void {
    event.preventDefault()
    this.isOpen = !this.isOpen
    if (
      this.isOpen &&
      this.gridApi &&
      this.gridType &&
      this.gridApi[this.gridType]
    ) {
      this.gridApi[this.gridType].sizeColumnsToFit()
    }
    if (Object.keys(this.classList || {}).length > 0) {
      for (const key in this.classList) {
        if (key === 'full-height') {
          this.classList['full-height'] = this.isOpen
        }
        this.ngClass[key] = this.classList[key]
      }
    }
    this.onToggleAccordion.emit(this.isOpen)
  }
  // public downloadExcelFile() {
  //   const formParams = {};
  //   formParams['bomId'] = this._bomId;
  //   formParams['revisionId'] = this._revisionId;
  //   formParams['versionId'] = this._bomVersionId;
  //   return this.uploadDownloadService.downloadToExcel(
  //     '/api/bommanagement/downloadbomaudit',
  //     formParams,
  //     'npi',
  //     'bomManagement'
  //   );
  // }
  public formExcelPayload() {
    this.downloadPayload = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      formParams: {
        bomId: this._bomId,
        revisionId: this._revisionId,
        versionId: this._bomVersionId,
      },
      servicePath: '/downloadbomaudit',
    }
  }
}
