import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/operator/map';
import { Fileupload } from './fileupload.model';
import { ApiServiceProvider } from 'app/api-service';
import { BaseService } from 'app/modules/common/base.service';

@Injectable()
export class FileuploadService {
  public responseData: any;
  public josnObject: any;

  constructor(
    private http: HttpClient,
    private apiService: ApiServiceProvider
  ) { }
  public getUploadfile(formData: FormData, inputfile, files): Observable<any> {
    try {
      switch (inputfile.title) {
        case 'costlookup':
          return this.apiService.fileUpload(
            formData,
            inputfile.servicePath,
            inputfile.timeperiod,
            inputfile.title,
            files
          );
        case 'partmatch':
        case 'benchmark':
        case 'tc':
        case 'curator':
        case 'thresholdUpload':
        case 'bm-percentile':
          return this.apiService.fileUpload(
            formData,
            inputfile.servicePath,
            inputfile.quarter,
            inputfile.title
          );
        case 'NPI':
        case 'content':
        case 'partSearch':
        case 'commodityManager':
        case 'bomtransformationsfileupdate':
        case 'Collaboration':
          return this.apiService.fileUpload(
            formData,
            inputfile.servicePath,
            '',
            inputfile.title,
            inputfile.formParams
          );
        case 'SUPPLIER_RFX_DETAILS':
          return this.apiService.fileUpload(
            formData,
            inputfile.servicePath,
            inputfile.quarter,
            inputfile.title,
            '',
            inputfile.custRfqId,
            inputfile.templateId,
            inputfile.suppId
          );
        case 'VENDOR_RFX_DETAILS':
          return this.apiService.fileUpload(
            formData,
            inputfile.servicePath,
            inputfile.quarter,
            inputfile.title
          );
      }
    } catch (Exception) {
      console.error('Exception ins File upload Service ', Exception);
    }
  }
}
