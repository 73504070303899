import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Router } from '@angular/router';
import { DataStorageService } from 'app/modules/common/data-storage.service';

@Component({
    selector: 'child-cell',
    template: `<div class="text-{{colorCode}}">{{val}}</div>`,
})
export class FontComponent {
    public params: any;
    val: any;
    colorCode: any = ''

    constructor(private router: Router, private dataStorageService: DataStorageService) {

    }
    agInit(params: any): void {
        this.val = params.value;
        this.params = params;
        if (this.val === '0 days left') {
            this.colorCode = 'danger'
        }
        else {
            this.colorCode = 'success'
        }
    }

}
