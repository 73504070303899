import { Component, OnDestroy } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-distributer-link',
    template: `<a *ngIf ="params" href="{{params}}" style="text-decoration: underline;" target="_blank">{{show}}</a>
    <span *ngIf ="!params">{{show}}</span>`,

})
export class DistributerLinkComponent {
    // url:any;
    params: any;
    show ='';
    agInit(params: any): void {
        if(params.value){
        this.params = `${environment.octapartDistributorLink}?dsUrl=${encodeURIComponent(params.value)}&mpn=${encodeURIComponent(params.data.currentDimension.MPN)}`
        this.show ='See Latest Price and Availability'
        }else{
            this.show = 'NA';
        }
    }
}
