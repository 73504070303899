import { CollaborationSummaryModel } from 'app/modules/core/collaborations/shared/collaborationsummary-model';
import { ConversationModel } from 'app/modules/core/collaborations/shared/conversation-model';

export class CollaborationRequestModel {

    public collaborationSummary : CollaborationSummaryModel;

    public conversation : ConversationModel;

    constructor() {

    }

    public setCollaborationSummery(collaborationSummery:CollaborationSummaryModel) {
        this.collaborationSummary = collaborationSummery;
    }

    public getCollaborationSummery() {
        return this.collaborationSummary;
    }

    public setConversation(conversation:ConversationModel) {
        this.conversation = conversation;
    }

    public getConversation() {
        return this.conversation;
    }
}
