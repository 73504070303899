export class DropDownClass {
    view: string;
    id: string;
    text: string;
    dim: string;
    hirearchies: string;
    dbcolumnname: string;
    spendDim: string;
    savingsDim: string;
    opportunitydimension: string;
    isdefault: any;
    mpndimension: any;
    opportunitycolumn: any;
    spend_dim: any;
    title: string;
    flagcolumn: string;
    flagdimension: string;
    KeyDim: string;
    constructor(id: string, text: string, dim: string, hirearchies: string, dbcolumnname: string,
        spendDim: string, savingsDim: string, KeyDim: string, view: string, opportunitydimension: string, isdefault: any, flagdimension: any,
        flagcolumn: any, spend_dim: any, opportunitycolumn: any, mpndimension: any, title: any) {
        this.id = id;
        this.text = text;
        this.dim = dim;
        this.hirearchies = hirearchies;
        this.dbcolumnname = dbcolumnname;
        this.spendDim = spendDim;
        this.savingsDim = savingsDim;
        this.KeyDim = KeyDim;
        this.view = view;
        this.opportunitydimension = opportunitydimension;
        this.isdefault = isdefault;
        this.flagdimension = flagdimension
        this.flagcolumn = flagcolumn;
        this.spend_dim = spend_dim;
        this.opportunitycolumn = opportunitycolumn;
        this.mpndimension = mpndimension;
        this.title = title;
    }
}
