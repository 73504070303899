import { Component, OnInit } from '@angular/core';
import { RecommendationsMessages } from './shared/recommendations-messages.model';
import { RecommendationsMessagesService } from './shared/recommendations-messages.service';
import { DataStorageService } from 'app/modules/common/data-storage.service';

@Component({
  selector: 'recommendations-messages',
  templateUrl: 'recommendations-messages.component.html',
  styleUrls: ['./recommendations-messages.component.sass'],
  providers: [RecommendationsMessagesService]
})

export class RecommendationsMessagesComponent implements OnInit {
  recommendationsMessages: RecommendationsMessages[] = [];
  recommendationsMessagesData: any;
  recommendations: Array<any> = [];
  messagesToSupplier: Array<any> = [];
  isClassVisible = false;
  rfName:string;



  constructor(
    private recommendationsMessagesService: RecommendationsMessagesService,
    private dataStorageService: DataStorageService
    ) {
    this.recommendationsMessagesData = {
      supplier: [
        'Benchmark indicates 6% cost savings for next 2 quarters',
        'Business is poised to grow by 12% over the next 4 quarters',
        'Price has not changed from last 3 Quarters'
      ],
      Avnet: ['Avnet should not pass through short term price volatility to the customer and provide a steady quarter over quarter average cost savings in exchange of the long term life cycle '],
      Future: ['cost saving opportunity if the supplier meets the benchmark target, which it should. LevaData strongly recommends to negotiate for these cost saving. Based on the guidance .'],
      Jifu: ['Compare with cost model and or explore the order of the impact. If this is a secondary impact specially if the supplier is not doing cost plus pricing, stay firm on savings demand. Point out to supplier that the cost increase due to raw material or labor cost should be a second order impact and should not impact the suppliers ability to give cost savings.'],
      Mibtech: ['Negotiate for 6 month award, to lock pricing and supply. Offer min 70% share if target savings of 2.65% is achieved']
    };
  }

  ngOnInit() {
    this.messagesToSupplier = []
    this.recommendations = [];
    this.rfName = this.dataStorageService.getSeletedRFQName();

    /* this.rfqpreviewService.getSupplierNameSubject().subscribe((supplierName) => {
			if (supplierName !== undefined && supplierName !== "All") {
				this.recommendations = this.getRFQRecommendationBySupplierName(supplierName);
				this.isClassVisible = true;
				this.messagesToSupplier =[];
				let negotiationLevers = this.negotiationLeversService.getNegotiationLeversByRFAndSupplier(this.rfName, supplierName).slice(0,2);
				for(let item of negotiationLevers){
					this.messagesToSupplier.push(item['leverDescription']);
				}
			}
			else{
				this.isClassVisible = false;
			}
		}); */




    this.dataStorageService.getNegLevDesSubject().subscribe((data) => {

      if (data !== undefined) {
        for (const str of data) {
          this.messagesToSupplier.push(str);
        }
        this.isClassVisible = true;
      }
      else {
        this.isClassVisible = false;
      }
    });
  }

  getRFQRecommendationBySupplierName(supplier: string) {

    if (this.recommendationsMessagesData[supplier] !== undefined) {
      return this.recommendationsMessagesData[supplier];
    } else {
      return [];
    }

  }

  ngOnChanges() {

  }


}


