import { PayLoad } from 'app/modules/common/models/payload.model';
import { FormParams } from 'app/modules/common/models/FormParams';

export class RequestData {
  propagate: boolean;
  targetedType: string;
  component: String;
  method: String;
  servicePath: String;
  clientName: String;
  placeHolder: any = 'Select';
  unmatchedSearch = false;
  searchInputLimit = 1;
  restrictedVals;
  type: String;
  displayName: string;
  defaultSelection: any;
  typeOfSelectSingle = false;
  showSelectedValue = false;
  onSelectAction = false;
  options: any[] = [];
  payload: any;
  formParams: FormParams;
  selectAll = false;
  defaultValue: any;
  title: any;
  target: any;
  id: any;
  selectAllText: any;
  isSubscribed: any = false;
  views?: any;
  hideSearch?: boolean;
  showTreeExpanded?: boolean;
  inputHeight?: any;
  field?: String;
  selectedValues?: any[] =[];
}
