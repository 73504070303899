import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

import { Srfq } from './srfq.model';
import { ToasterService, BodyOutputType, Toast } from 'angular2-toaster';

@Injectable()
export class ToastService {
  toasts = [];
  constructor(private toasterService: ToasterService) {}

  populateToaster(
    toastertype: string,
    toasterMessage: string,
    toastWithBodyFlag?: boolean
  ) {
    const toast: Toast = {
      type: toastertype
    };
    if (toastWithBodyFlag) {
      toast.body =
        '<div class="toast-msg ' +
        toastertype +
        '-msg">' +
        toasterMessage +
        '</div>';
      toast.bodyOutputType = BodyOutputType.TrustedHtml;
    } else {
      toast.title = toasterMessage;
    }
    return this.toasts[this.toasts.push(this.toasterService.pop(toast))];
  }

  clearLastToast() {
    if (this.toasts instanceof Array && this.toasts.length > 0) {
      const toast = this.toasts.pop();
      this.toasterService.clear();
    }
  }

  clearAllToasts() {
    if (this.toasts instanceof Array && this.toasts.length > 0) {
      this.toasts.forEach(this.clearLastToast.bind(this));
    }
  }
}
