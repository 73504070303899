import { Component, OnInit, Input } from '@angular/core';

import { Summary } from './shared/Summary.model';
import { SummaryService } from './shared/Summary.service';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';

@Component({
  selector: 'Summary',
  templateUrl: 'Summary.component.html',
  styleUrls: ['./Summary.component.sass'],
  providers: [SummaryService]
})

export class SummaryComponent implements OnInit {
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  Summary: Summary[] = [];
  @Input() target: string;
  SummaryList: any;
  constructor(private SummaryService: SummaryService) { }

  ngOnInit() {
    this.SummaryList = this.SummaryService.getSummaryList(this.target);
  }
}
