import { Component } from '@angular/core';

@Component({
    selector: 'app-child-cell',
    templateUrl: './view-part.component.html',
    styleUrls: ['./view-part.component.sass']
})
export class ViewPartComponent {
    public parentComp: any;
    public data: object;
    constructor() {}

    public agInit(params: any): void {
        this.parentComp = params.context ? params.context.componentParent : {};
        this.data = params.data;
    }
    public viewPart() {
        this.parentComp.openViewPart(this.data);
    }
}
