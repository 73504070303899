import { Component } from '@angular/core';
import { DataStorageService } from 'app/modules/common/data-storage.service';

@Component({
  selector: 'child-cell',
  template: `
    <div class="full-width full-height pull-left text-align-center">
      <button
        class="btn btn-primary btn-xs height-22 "
        id="{{ label }}"
        title="{{ label }}"
        (click)="help($event)"
      >
        {{ label }}
      </button>
    </div>
  `
})
export class GridbuttonComponent {
  constructor(private dataStorageService: DataStorageService) {}
  public params: any;
  public label = 'Graph';
  agInit(params: any): void {
    this.params = params;
    if (this.params.hasOwnProperty('drilldown')) {
      this.label = 'Drilldown';
    }
  }
  help(event) {
    this.dataStorageService.setrmGraphdata(this.params.data);
    this.dataStorageService.setRMPopUpData({
      data: this.params.data,
      label: this.label
    });
  }
}
