import { Component } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'cprt-date-render-cell',
  template: `
    <div class="center-cell" title="{{ dateValue }}">
      {{ dateValue }}
    </div>
  `,
  providers: [DatePipe]
})
export class DateRenderComponent implements ICellRendererAngularComp {
  public params: ICellRendererParams;

  dateValue: string;

  dateFormat: string;

  constructor(private datePipe: DatePipe) {}

  transformDate(date) {
    return this.datePipe.transform(date, this.dateFormat);
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;

    if (params['format'] && params['format'].dateFormat) {
      this.dateFormat = params['format'].dateFormat;
    } else {
      this.dateFormat = 'mediumDate';
    }

    this.dateValue = this.transformDate(params.value);
  }

  refresh(): boolean {
    return true;
  }
}
