import { CustomerConfigurationService } from './customerConfigurationService';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable()
export class SubNavBarService {
  public ProductSubChildList: any;
  public SubChildList: any;
  private productsubNavBarList: object[];
  private subNavBarList: object[];
  constructor(
    private customerConfigurationService: CustomerConfigurationService
  ) {
    if (this.customerConfigurationService.getConfData('Product')) {
      this.productsubNavBarList = this.customerConfigurationService.getConfData(
        'Product'
      ).subTabFeatures;
      this.SubChildList = this.customerConfigurationService.getConfData(
        'Product'
      ).subTabChildFeatures;
    }
    if (this.customerConfigurationService.getConfData('Commodity')) {
      this.SubChildList = this.customerConfigurationService.getConfData(
        'Commodity'
      ).subTabChildFeatures;
      this.subNavBarList = this.customerConfigurationService.getConfData(
        'Commodity'
      ).subTabFeatures;
    }
  }

  public getSubNavBarList(target) {
    if (
      target === 'Analysis' ||
      target === 'MasterData' ||
      target === 'Insights Configuration' ||
      target === 'cost'
    ) {
      this.subNavBarList = this.SubChildList;
    }
    let list;
    if (this.subNavBarList) {
      list = this.subNavBarList.filter((item) => item[target] instanceof Array);
    }
    // console.log(list[0][target]);
    let returnArray = [];
    if (list instanceof Array && list.length > 0) {
      returnArray = list[0][target].filter((item) => item.view !== 'null');
    }
    return returnArray;
  }
  public getProductSubNavBarList(target) {
    if (
      target === 'rmopportunities' ||
      target === 'Program' ||
      target === 'MasterData' ||
      target === 'cost'
    ) {
      this.productsubNavBarList = this.SubChildList;
    }
    let list;
    if (this.productsubNavBarList) {
      list = this.productsubNavBarList.filter(
        (item) => item[target] instanceof Array
      );
    }
    let returnArray = [];
    if (list instanceof Array && list.length > 0) {
      returnArray = list[0][target].filter((item) => item.view !== 'null');
    }
    return returnArray;
  }
}
