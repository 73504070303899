import { Injectable } from '@angular/core';
import { UnleashClient } from 'unleash-proxy-client';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ConfigLocator, FeatureFlagConfiguration } from './config-locator';

@Injectable()
export class FeatureFlagClientFactory {
  private SUCCESSFUL_PROXY_RESPONSE = 'ok';
  private APP_NAME = 'default';

  constructor(private http: HttpClient, private configLocator: ConfigLocator) {
  }

  public getClient(): Observable<UnleashClient> {
    return this.configLocator.getConfiguration().pipe(
      mergeMap(configuration =>
        this.getProxyHealth(configuration.featureFlags).pipe(
          map(response => {
            if (response === this.SUCCESSFUL_PROXY_RESPONSE) {
              return this.createClient(configuration.featureFlags);
            } else {
              throw new Error('Some configuration is wrong on the Unleash proxy');
            }
          })
        )
      )
    );
  }

  private createClient(featureFlagConfiguration: FeatureFlagConfiguration) {
    const unleashClient = new UnleashClient({
      url: featureFlagConfiguration.serverUrl,
      clientKey: featureFlagConfiguration.clientKey,
      appName: this.APP_NAME
    });
    return unleashClient;
  }

  private getProxyHealth(featureFlagConfiguration: FeatureFlagConfiguration): Observable<string> {
    return this.http.get(`${featureFlagConfiguration.serverUrl}/health`, { responseType: 'text' }).pipe(
      catchError(error => {
        throw new Error('The proxy server is down');
      })
    );
  }
}
