import { Injectable } from '@angular/core';
import { ApiServiceProvider } from 'app/api-service';
import {
  API_SERVICE_CONSTANTS,
  HTTP_REQUEST_TYPES,
  REST_END_POINT_URLS,
} from '../../../npiconstants';
import { NpiCommonApiService } from '../../../npi-common-api.service';

@Injectable()
export class NpiAdminRiskService {
  set errors(val) {
    this._errors = val;
  }
  get errors() {
    return this._errors;
  }
  set errorsSummary(val) {
    this._errorsSummary = val;
  }
  get errorsSummary() {
    return this._errorsSummary;
  }
  public keyInfo;
  public riskInfo: any;
  public headers: any;
  public programKeyInfo: any;
  public enableReset = false;
  public enableSave = false;
  public data: any;
  public saveObject = [];
  public bomRiskInfo: any;
  public bomRiskUpdated = false;
  public stageRiskInfoUpdated = false;
  public checkpintInfoUpdated = false;
  public stageRiskInfo = [];
  public checkpointRiskInfo = [];
  public maturityRiskInfo = [];
  public bomData: any;
  public showDataMessage = false;
  public _errors = {
    stage: [],
    maturity: [],
    componentLevelRisk: [],
    bomLevelRisk: [],
    eolRisk: [],
    supplierFinancialRisk: [],
    eolbomLevelRisk: [],
    supplierFinancialBomLevelRisk: [],
    psl: [],
    checkpoint: [],
  };
  public _errorsSummary = {
    timeline: null,
    bom: null,
  };
  public maturityRiskUpdated = false;

  public saveEnabler = false;
  constructor(
    private apiService: ApiServiceProvider,
    private npiCommonApiService: NpiCommonApiService
  ) {
    this.npiCommonApiService
      .loadWidgetHeaders(API_SERVICE_CONSTANTS.BOM_RISK)
      .subscribe((response) => {
        this.keyInfo = response;
      });
  }
  public retrieveRiskInfo(programKey) {
    const data = {
      targetedType: API_SERVICE_CONSTANTS.PROGRAM_MANAGEMENT_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.GET,
      servicePath: `/program/${programKey}/config`,
    };
    return this.apiService.post(data);
  }

  public retrieveBomRiskInfo(programId, bomRiskConfig?) {
    if (bomRiskConfig) {
      programId = -1;
    }
    const data = {
      targetedType: API_SERVICE_CONSTANTS.PROGRAM_MANAGEMENT_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.GET,
      formParams: {
        programId,
      },
      servicePath: REST_END_POINT_URLS.BOM_CONFIGURATION,
    };
    return this.apiService.post(data);
  }

  public saveStageRiskInfo(programStages) {
    const data = {
      targetedType: API_SERVICE_CONSTANTS.STAGE_GATE_MANAGEMENT_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.PUT,
      payload: programStages,
      servicePath: REST_END_POINT_URLS.UPDATE_STAGE_RISK,
    };
    return this.apiService.post(data);
  }

  public saveBomRiskInfo(programId, object, bomRiskConfig?) {
    const payload = {};
    payload['riskConfigMap'] = object.result['riskConfigMap'];
    if (bomRiskConfig) {
      programId = -1;
    }
    const data = {
      targetedType: API_SERVICE_CONSTANTS.PROGRAM_MANAGEMENT_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.PUT,
      payload,
      formParams: {
        programId,
      },
      servicePath: REST_END_POINT_URLS.BOM_CONFIGURATION,
    };
    return this.apiService.post(data);
  }
  public saveCheckpointRisk(programKey, programcheckpoints) {
    const data = {
      targetedType: API_SERVICE_CONSTANTS.STAGE_GATE_MANAGEMENT_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.POST,
      payload: programcheckpoints,
      servicePath: `${programKey}/${REST_END_POINT_URLS.CHECKPOINT_RISK}`,
    };
    return this.apiService.post(data);
  }

  public saveMaturityRisk(payload) {
    const data = {
      targetedType: API_SERVICE_CONSTANTS.PROGRAM_MANAGEMENT_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.PUT,
      payload,
      servicePath: REST_END_POINT_URLS.MATURITY_RISK,
    };
    return this.apiService.post(data);
  }
  public saveButtonStatus() {
    let count = 0;
    // let keys= Object.keys(this.npiAdminRiskService.errors);
    Object.keys(this.errors).forEach((element) => {
      if (this.errors[element].length > 0) {
        count++;
      }
    });
    if (
      this.bomRiskUpdated ||
      this.stageRiskInfoUpdated ||
      this.maturityRiskUpdated ||
      this.checkpintInfoUpdated
    ) {
      if (count > 0) {
        this.saveEnabler = false;
      } else {
        this.saveEnabler = true;
      }
    } else {
      this.saveEnabler = false;
    }
  }
}
