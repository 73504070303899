/*
Purpose : This is the application level loader which will help to block the user from doing any operation by blocking whole screen.
Usage : NpiLoadingService.showLoader(<true|false>);
*/

export class NpiLoadingService {
  public static loading = false;

  public static showLoader(bool = false) {
    this.loading = bool;
  }
}
