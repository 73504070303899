import { ICellEditorAngularComp } from '@ag-grid-community/angular'
import { AfterViewInit, Component, OnDestroy } from '@angular/core'
import { BaseService } from 'app/modules/common/base.service'
import { DataStorageService } from 'app/modules/common/data-storage.service'
import { DateUtils } from 'app/modules/common/utills/DateUtils.service'
import { DATE_PROPERTIES } from 'app/modules/npi/npiconstants'

@Component({
  selector: 'app-leva-comments-bom-render',
  templateUrl: './leva-comments-bom-render.component.html',
  styleUrls: ['./leva-comments-bom-render.component.sass'],
})
export class LevaCommentsBomRenderComponent
  implements ICellEditorAngularComp, AfterViewInit, OnDestroy {
  private cancelBeforeStart = false
  public params: any
  public userId
  public timeStamp
  public originalValue
  public comment = ''
  constructor(
    public dataStorageService: DataStorageService,
    public baseService: BaseService
  ) {}
  agInit(params: any): void {
    this.params = params
    if (this.params.value) {
      if (
        this.params &&
        this.params.context &&
        this.params.context.componentParent
      ) {
        if (this.params.value) {
          const val = this.params.value.split('@##@')
          this.originalValue = val[val.length - 1]
          this.comment = val[val.length - 1]
        } else {
          this.params.value = ''
        }
        this.userId = this.baseService.getUserInformation().email
        return
      }
      if (typeof this.params.value === 'string') {
        this.originalValue = JSON.parse(this.params.value).message
        this.comment = JSON.parse(this.params.value).message
      } else {
        this.originalValue = this.params.value.message
        this.comment = this.params.value.message
      }
    }
    this.userId = this.baseService.getUserInformation().email
  }

  commentChanged(val) {
    if (val) {
      if (
        this.params &&
        this.params.context &&
        this.params.context.componentParent
      ) {
        return
      }
      const emitData = {
        bomRepoAuditId:this.params.data.bomRepoAuditId,
        bomRepoId:this.params.data.bomRepoId,
        val
      }
      this.dataStorageService.gridCommentsRenderService.emit(
        emitData
      )
    }
  }

  public getValue(): any {
    if (
      this.params &&
      this.params.context &&
      this.params.context.componentParent
    ) {
      this.params.data[this.params.colDef.field] =
        this.userId +
        '@##@' +
        new Date().getTime() +
        '@##@' +
        this.originalValue
      if (this.comment) {
        this.comment = this.comment.replace(/\s\s+/g, ' ')
        return (
          this.userId + '@##@' + new Date().getTime() + '@##@' + this.comment
        )
      } else {
        return ''
      }
    }
    this.timeStamp = DateUtils.format(
      new Date().getTime(),
      DATE_PROPERTIES.FORMATWITHTIME
    )
    const jsonObj = {
      userName: this.userId,
      time: new Date().getTime(),
      message: this.comment,
    }
    // this.params.value =
    //   this.userId + '_' + this.timeStamp + ':' + this.comment;
    return jsonObj
  }

  public isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart
  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  public ngAfterViewInit() {}

  public isPopup(): boolean {
    return false
  }

  public ngOnDestroy() {
    this.timeStamp = DateUtils.format(
      new Date().getTime(),
      DATE_PROPERTIES.FORMATWITHTIME
    )
    const jsonObj = {
      userName: this.userId,
      time: new Date().getTime(),
      message: this.comment,
    }

    this.commentChanged(jsonObj)
  }
}
