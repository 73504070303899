/**
 * Template Home Page
 */
export const CHECKBOX_OBJ = {
  dbColumnName: 'lock-column',
  displayName: '',
  lockPosition: true,
  cellClass: 'locked-col',
  checkboxSelection: true
};
export const TEMPLATE_ID = 'templateId';
export const CONFIG_TEMPLATE_IMPORT = 'CONFIG_TEMPLATE';

export const TYPES = {
  TEXT: 'TEXT',
  NUMBER: 'NUMERIC',
  DROPDOWN: 'DROPDOWN',
  DATE: 'DATE',
  SINGLESELECT: 'SINGLESELECT',
  MULTISELECT: 'MULTISELECT',
  API: 'API'
};
export const HEADER_VISIBILITY = 'HEADER_VISIBILITY';
export const HEADER_DEFAULT_OPTIONS = 'HEADER_DEFAULT_OPTIONS';
export const RFQ_CUSTOMER_CONTEXTS = 'RFQ_CUSTOMER_CONTEXTS';
export const DROPDOWN_VALUES_FOR_ADD_NEW_FIELD_PAGE =
  'DECIMAL_PLACES, UNITS, HEADER_CAL_TYP, HEADER_DEV_TYP, HEADER_DEP_TYP, HEADER_SCOPE, HEADER_ADHOC_CELLTYPE';

export const METRIC_KEY_OBJECT: any = {
  METRIC_SCOPE: {
    key: 'METRIC_SCOPE',
    keyValue: 'metricType',
    actualValue: 'metricTypeName'
  },
  // 'SCREEN_LOCATION': {
  //     key: 'SCREEN_LOCATION',
  //     keyValue: 'screenLocation',
  //     actualValue: 'screenLocationName'
  // },
  METRIC_CATEGORY: {
    key: 'METRIC_CATEGORY',
    keyValue: 'metricCategory',
    actualValue: 'metricCategoryName'
  }
};
export const MODIFIED_METRIC_KEYS = ['metricType', 'metricCategory', 'formula'];
// create new template
export const CREATE_TEMPLATE_MESSAGES = {
  nameErrorMessage: 'Name cannot be empty',
  sameTemplateMessage: `Template name cannot be same as existing template's name`,
  typeError: 'Please select type',
  templateAddedSuccess: 'New Template created successfully',
  templateAddedError: 'Failed to create new template',
  fileUploadSuccess: 'File Successfully Uploaded',
  fileUploadError: 'Error While Uploading File'
};
export const TEMPLATE_SCOPE = 'TEMPLATE_SCOPE';
export const TEMPLATE_TYPE = 'TEMPLATE_TYPE';
export const TEMPLATE_RFX_ORIGIN = 'TEMPLATE_RFX_ORIGIN';
export const TOASTER_MSG_SAVEFIELD_SUCCESS = 'Field Saved Successfully';
export const TOASTER_MSG_SAVEFIELD_ERROR =
  'Error Occurred While Saving New Field';
export const TOASTER_MSG_TEMPLATE_FIELD_DELETE_SUCCESS =
  'Template Field Deleted Successfully.';
export const TOASTER_MSG_TEMPLATE_FIELD_DELETE_ERROR =
  'Error while deleting Template Field';
export const TOASTER_MSG_TEMPLATE_SAVE_SUCCESS = 'Template Saved Successfully.';
export const TOASTER_MSG_TEMPLATE_SAVE_ERROR = 'Error while saving Template';
export const TOASTER_MSG_TEMPLATE_DELETE_SUCCESS =
  'Template Deleted Successfully.';
export const TOASTER_MSG_TEMPLATE_DELETE_ERROR =
  'Error while deleting Template';
export const TOASTER_MSG_TEMPLATE_PUBLISH_SUCCESS =
  'Template Published Successfully.';
export const TOASTER_MSG_TEMPLATE_PUBLISH_ERROR =
  'Error while publishing Template';
export const TOASTER_MSG_TEMPLATE_ACTIVATE_SUCCESS =
  'Template Activated Successfully.';
export const TOASTER_MSG_TEMPLATE_ACTIVATE_ERROR =
  'Error while activating Template';
export const TOASTER_MSG_TEMPLATE_DEACTIVATE_SUCCESS =
  'Template Deactivated Successfully.';
export const TOASTER_MSG_TEMPLATE_DEACTIVATE_ERROR =
  'Error while deactivating Template';
export const TOASTER_MSG_TEMPLATE_FORM_INCOMPLETE =
  'Please fill in the missing information';

export const DEPENDENCY_CONDITIONS = [
  {
    refTermKey: '0',
    refTermDisplayValue: 'Equals',
    refTermActualValue: 'Equals'
  }
];
export const DATE_FORMAT = [
  {
    refTermKey: '0',
    refTermDisplayValue: '14/03/2018',
    refTermActualValue: '14/03/2018'
  },
  // {
  //   refTermKey: '1',
  //   refTermDisplayValue: '14th March 2018',
  //   refTermActualValue: '14th March 2018'
  // },
  // {
  //   refTermKey: '2',
  //   refTermDisplayValue: 'March 14th 2018',
  //   refTermActualValue: 'March 14th 2018'
  // },
  // {
  //   refTermKey: '3',
  //   refTermDisplayValue: 'Mar 14th 2018',
  //   refTermActualValue: 'Mar 14th 2018'
  // },
  // {
  //   refTermKey: '4',
  //   refTermDisplayValue: '03-14-2018',
  //   refTermActualValue: '03-14-2018'
  // },
  // {
  //   refTermKey: '5',
  //   refTermDisplayValue: '14-03-2018',
  //   refTermActualValue: '14-03-2018'
  // }
];

export const FORMAT_INPUT_TYPES = [
  {
    refTermKey: '0',
    refTermDisplayValue: 'Custom',
    refTermActualValue: 'Custom'
  },
  {
    refTermKey: '1',
    refTermDisplayValue: 'Field Input',
    refTermActualValue: 'Field Input'
  }
];

export const FORMAT_DEFAULT = [
  {
    refTermKey: '0',
    refTermDisplayValue: 'Blank',
    refTermActualValue: 'Blank'
  },
  {
    refTermKey: '1',
    refTermDisplayValue: 'Input values',
    refTermActualValue: 'Input values'
  }
];

export const FORMAT_USERS = [
  {
    refTermKey: '1',
    refTermDisplayValue: 'Supplier',
    refTermActualValue: 'supplier'
  },
  {
    refTermKey: '2',
    refTermDisplayValue: 'Customer',
    refTermActualValue: 'customer'
  }
];

export const FIELD_TYPES = [
  {
    refTermKey: '1',
    refTermDisplayValue: 'Text',
    refTermActualValue: 'TEXT'
  },
  {
    refTermKey: '2',
    refTermDisplayValue: 'Numeric',
    refTermActualValue: 'NUMERIC'
  },
  {
    refTermKey: '3',
    refTermDisplayValue: 'Date',
    refTermActualValue: 'DATE'
  },
  {
    refTermKey: '4',
    refTermDisplayValue: 'Dropdown',
    refTermActualValue: 'DROPDOWN'
  },
  {
    refTermKey: '5',
    refTermDisplayValue: 'Multiselect',
    refTermActualValue: 'MULTISELECT'
  },
  {
    refTermKey: '6',
    refTermDisplayValue: 'Api',
    refTermActualValue: 'API'
  },
  {
    refTermKey: '7',
    refTermDisplayValue: 'Singleselect',
    refTermActualValue: 'SINGLESELECT'
  }

];

export const FIELD_CATEGORY = [
  {
    refTermKey: '',
    refTermDisplayValue: 'Select Field type',
    refTermActualValue: ''
  },
  {
    refTermKey: '1',
    refTermDisplayValue: 'Input',
    refTermActualValue: 'input'
  },
  {
    refTermKey: '2',
    refTermDisplayValue: 'Read-only',
    refTermActualValue: 'read-only'
  }
];

export const FILE_TYPE_EXTENSIONS_ICONS = {
  JSON: {
    extension: '.json',
    hasIcon: false
  },
  EXCEL: {
    extension: '.xlsx',
    hasIcon: true,
    iconClass: 'ld-srfx-icon-upload-excel'
  },
  DEFAULT: {
    extension: '.xlsx',
    hasIcon: true,
    iconClass: 'ld-srfx-icon-upload-excel'
  }
};
export const TEMPLATE_STATUS = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  DEACTIVATED: 'DEACTIVATED',
  DELETED: 'DELETED'
};

export const TEMPLATE_SUPPLIER_CONTEXT = {
  refTermActualValue: 'SUPPLIER',
  refTermDisplayValue: 'Supplier',
  refTermKey: 'CONTEXT_SUPPLIER'
};
export const INCREASE_TYPE = [
  {
    refTermKey: '1',
    refTermDisplayValue: 'Good',
    refTermActualValue: 'GOOD'
  },
  { refTermKey: '2', refTermDisplayValue: 'Bad', refTermActualValue: 'BAD' },
  { refTermKey: '3', refTermDisplayValue: 'None', refTermActualValue: 'NONE' }
];

export const INIT_FORMAT_DATA = {
  DROPDOWN: {
    type: 'DROPDOWN',
    defaultValue: undefined,
    selectedValues: ['']
  },
  NUMERIC: {
    type: 'NUMERIC',
    decimalPlaces: undefined,
    units: undefined
  },
  TEXT: {
    type: 'TEXT',
    textMessage:
      'Text Format Fields are treated as text even when numbers are entered. Text will be displayed exactly as entered.'
  },
  DATE: {
    type: 'DATE',
    dateFormat: undefined
  },
  MULTISELECT: {
    type: 'MULTISELECT',
    defaultValue: 'Blank',
    selectedValues: ['']
  },
  SINGLESELECT: {
    type: 'SINGLESELECT',
    defaultValue: 'Blank',
    selectedValues: ['']
  },
  API: {
    type: 'API',
    defaultValue: undefined,
    selectedValues: ['']
  },
};
export function INITIAL_PERMISSION_OBJ() {
  return {
    customerPrivileges: {
      AWARD: 'VISIBLE',
      SCOPE: 'VISIBLE',
      DETAILS: 'VISIBLE',
      PREVIEW: 'VISIBLE',
      SIMULATION: 'VISIBLE',
      SIMCOMPARISON: 'NOTVISIBLE',
      ADHOC: 'VISIBLE'
    },
    supplierPrivileges: {
      SUPPLIER: 'VISIBLE'
    },
    defaults: {
      customer: {
        defaulValueOption: 'NULL',
        value: ''
      },
      supplier: {
        defaulValueOption: 'NULL',
        value: ''
      }
    },
    customerOptions: {
      SCOPE: ['VISIBLE', 'NOTVISIBLE', 'FORBACKEND', 'DEFAULTHIDDEN'],
      DETAILS: [
        'VISIBLE',
        'FORBACKEND',
        'NOTVISIBLE',
        'EDITABLE',
        'DEFAULTHIDDEN'
      ],
      SIMULATION: [
        'VISIBLE',
        'FORBACKEND',
        'NOTVISIBLE',
        'EDITABLE',
        'DEFAULTHIDDEN'
      ],
      PREVIEW: ['VISIBLE', 'FORBACKEND', 'NOTVISIBLE', 'EDITABLE', 'DEFAULTHIDDEN']
    },
    supplierOptions: {
      SUPPLIER: ['VISIBLE', 'FORBACKEND', 'NOTVISIBLE', 'EDITABLE', 'DEFAULTHIDDEN']
    }
  };
}
