import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { GridOptions } from '@ag-grid-community/core';
import { RfqConfigService } from '../shared/rfq-config.service';
import { GridConfiguration } from 'app/modules/core/nxt-grid/gridconfiguration';
import { DataStorageService } from '../../common/data-storage.service';
import { Router } from '@angular/router';
import {
  CHECKBOX_OBJ,
  TEMPLATE_ID,
  TEMPLATE_STATUS,
  TOASTER_MSG_TEMPLATE_DELETE_SUCCESS,
  TOASTER_MSG_TEMPLATE_DELETE_ERROR,
  TOASTER_MSG_TEMPLATE_PUBLISH_SUCCESS,
  TOASTER_MSG_TEMPLATE_PUBLISH_ERROR,
  TOASTER_MSG_TEMPLATE_ACTIVATE_SUCCESS,
  TOASTER_MSG_TEMPLATE_DEACTIVATE_SUCCESS,
  TOASTER_MSG_TEMPLATE_ACTIVATE_ERROR,
  TOASTER_MSG_TEMPLATE_DEACTIVATE_ERROR,
} from 'app/modules/rfq-configurations/shared/constants';
import { UploadDownloadService } from 'app/modules/srfq/shared/upload-download.service';
import * as _ from 'lodash';
import { ToastService } from 'app/modules/srfq/shared/toaster.service';
import { forkJoin } from 'rxjs';
import { CreateTemplateComponent } from '../create-template/create-template.component';
import { Subject } from 'rxjs';
import { BaseService } from '../../common/base.service';
import { FileDownloadService } from '../../core/file-download/shared/file-download.service';
import { DateUtils } from '../../common/utills/DateUtils.service';
import { ApiCommonService } from 'app/api-common-service';
import { takeUntil } from 'rxjs/operators';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';
@Component({
  selector: 'app-show-templates',
  templateUrl: './show-templates.component.html',
  styleUrls: ['./show-templates.component.sass'],
})
export class ShowTemplatesComponent implements OnInit, OnDestroy {
  private subject$: Subject<any> = new Subject();
  public createTemplate = false;
  public showGrid = false;
  public isRefreshTemplate = false;
  public gridOptions = {} as GridOptions;
  public rfqConfigSubscribe;
  public templates: any;
  public selectedRowSubscribe;
  public selectedRow;
  public OtherConfig: any = {
    noRowMessage: 'No templates available',
  };
  public gridHeaders;
  public copyTemplates;
  public downloadLink = '';
  public templateStatus = TEMPLATE_STATUS;
  public showAlertPopup = false;
  public alert: any;
  public cronTime: any;
  public showCronTime = false;
  public alertConfigObject: any;
  public popupConfiguration: any = {
    message: '',
    type: 'confirm',
  };
  public templateType = 'RFQ';
  public activeTab = 'Template';
  public cronTimeSaveDisable = false;
  public optionsArray = [
    {
      text: 'RFQ',
      view: 'RFQ',
      isdefault: true,
    },
    {
      text: 'RFI',
      view: 'RFI',
      isdefault: false,
    },
  ];
  public excelInfo = {
    method: 'POST',
    type: 'Template',
    title: 'Download',
    emit: true,
  };
  public pickedEndDate: any;
  public downloadPayload: any;
  public bool = new Subject<any>();
  @ViewChild('createTemplate', { static: false })
  public createTemplateComp: CreateTemplateComponent;
  public pickedDate: any;
  closeDays: any;
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  constructor(
    private apiCommonService: ApiCommonService,
    private rfqConfigService: RfqConfigService,
    public gridConfig: GridConfiguration,
    private toast: ToastService,
    private dataStorageService: DataStorageService,
    private router: Router,
    private uploadDownloadService: UploadDownloadService,
    public baseService: BaseService,
    private fileDownloadService: FileDownloadService
  ) {
    this.rfqConfigService.getRfxCronTime().subscribe((data) => {
      if (data instanceof Object) {
        this.cronTime = data.result;
      }
    });
    if (this.baseService.getUserInformation().tenantId === 323) {
      this.showCronTime = true;
    }
  }

  public ngOnInit() {
    this.alertConfigObject = {
      DEACTIVATE: {
        message: 'Do you want to deactivate template?',
        function: this.deactivateTemplate,
      },
      ACTIVATE: {
        message: 'Do you want to activate template?',
        function: this.activateTemplate,
      },
      DELETE: {
        message: 'Do you want to delete template?',
        function: this.deleteTemplate,
      },
      PUBLISH: {
        message:
          'This will publish the Template to Application. Would you like to continue?',
        function: this.publishTemplate,
      },
      CREATE: {
        message:
          'There are unsaved changes, Are you sure you want to navigate away from this page?',
        function: this.cancelPopupOkClick,
        discardFunction: this.cancelPopupCancelClick,
      },
    };
    forkJoin(
      this.rfqConfigService.getTemplateList(),
      this.rfqConfigService.getTemplateHeaders()
    ).subscribe(([rowData, headers]) => {
      if (rowData && !rowData.error) {
        this.templates = rowData;
        this.gridOptions.rowData = rowData;
        this.gridConfig.setRowData(this.gridOptions.rowData);
        this.OtherConfig.columnResize = false;
        this.configureRowNodeId();
      }
      if (headers && !headers.error) {
        this.gridHeaders = headers;
        this.gridHeaders.result.headers.unshift(CHECKBOX_OBJ);
        this.gridConfig.setHeaderObject(this.gridHeaders);
      }
      if (this.templates && this.gridHeaders) {
        this.configureGridOptions();
        this.showGrid = true;
      }
    });
    this.selectedRowSubscribe = this.dataStorageService
      .getSelectedGridData()
      .pipe(takeUntil(this.subject$))
      .subscribe(row => {
        if (row.length) {
          this.selectedRow = this.gridOptions.api.getRowNode(row[0]).data;
          this.createExportTemplateLink(this.selectedRow);
        } else {
          this.selectedRow = undefined;
        }
      });
  }
  public resetTemplates() {
    this.selectedRow = null;
    this.createTemplate = false;
    this.showGrid = false;
    this.rfqConfigService.getTemplateList().pipe(takeUntil(this.subject$)).subscribe(rowData => {
      this.templates = rowData;
      this.gridOptions.rowData = rowData;
      this.gridConfig.setRowData(this.gridOptions.rowData);
      this.OtherConfig.columnResize = false;
      this.configureRowNodeId();
      this.showGrid = true;
    });
  }

  public openCreateTemplate() {
    this.copyTemplates = this.templates.map((x) => {
      return {
        name: x.name,
        id: x.templateId,
      };
    });
    this.createTemplate = true;
  }
  public closeCreateTemplate() {
    this.createTemplate = false;
  }
  public configureGridOptions() {
    this.gridConfig.setPagination(false);
    this.gridConfig.setSuppressRowClickSelection(false);
    this.gridConfig.setRowSelection('single');
    this.gridConfig.setPaginationWithGotoPage(false);
    this.gridConfig.setPrimaryId(TEMPLATE_ID);
  }
  public ngOnDestroy() {
    this.showGrid = false;
    this.selectedRowSubscribe.unsubscribe();
    this.subject$.next();
    this.subject$.complete();
  }

  editTemplate() {
    if (this.selectedRow && this.selectedRow.templateId) {
      this.rfqConfigService.setTemplateId(this.selectedRow.templateId);
      this.rfqConfigService.setTemplate(this.selectedRow);
      this.router.navigate([`app/templates/edit-template/fields`]);
    }
  }

  public deleteTemplate() {
    this.showAlertPopup = false;
    if (this.selectedRow && this.selectedRow.templateId) {
      this.rfqConfigService
        .setTemplateStatus(
          this.selectedRow.templateId,
          this.templateStatus.DELETED
        )
        .pipe(takeUntil(this.subject$))
        .subscribe(res => {
          if (!res.error) {
            this.toast.populateToaster(
              'success',
              TOASTER_MSG_TEMPLATE_DELETE_SUCCESS
            );
            this.gridOptions.api.updateRowData({ remove: [this.selectedRow] });
            _.remove(this.templates, {
              templateId: this.selectedRow.templateId,
            });
            this.selectedRow = null;
          } else {
            this.toast.populateToaster(
              'error',
              TOASTER_MSG_TEMPLATE_DELETE_ERROR
            );
          }
        });
    }
  }

  showAlert(type) {
    if (type && this.alertConfigObject[type]) {
      this.alert = this.alertConfigObject[type];
      this.popupConfiguration.message = this.alert.message;
      this.showAlertPopup = true;
    }
  }

  public alertYesClick() {
    this.alert.function.call(this);
  }

  public alertNoClick() {
    this.showAlertPopup = false;
    if (this.alert.discardFunction) {
      this.alert.discardFunction.call(this);
    }
  }
  cancelPopupOkClick() {
    this.bool.next(true);
  }

  cancelPopupCancelClick() {
    this.bool.next(false);
  }
  deactivateTemplate() {
    this.showAlertPopup = false;
    this.toggleTemplateState(false);
  }
  public activateTemplate() {
    this.showAlertPopup = false;
    this.toggleTemplateState(true);
  }
  public publishTemplate() {
    this.showAlertPopup = false;
    if (this.selectedRow && this.selectedRow.templateId) {
      this.rfqConfigService
        .setTemplateStatus(
          this.selectedRow.templateId,
          this.templateStatus.PUBLISHED
        )
        .pipe(takeUntil(this.subject$))
        .subscribe(res => {
          if (!res.error) {
            this.toast.populateToaster(
              'success',
              TOASTER_MSG_TEMPLATE_PUBLISH_SUCCESS
            );
            this.resetTemplates();
          } else {
            this.toast.populateToaster(
              'error',
              TOASTER_MSG_TEMPLATE_PUBLISH_ERROR
            );
          }
        });
    }
  }
  toggleTemplateState(activate) {
    const status = activate
      ? this.templateStatus.PUBLISHED
      : this.templateStatus.DEACTIVATED;
    let toastMsg;
    if (this.selectedRow && this.selectedRow.templateId) {
      this.rfqConfigService
        .setTemplateStatus(this.selectedRow.templateId, status)
        .pipe(takeUntil(this.subject$))
        .subscribe(res => {
          if (!res.error) {
            toastMsg = activate
              ? TOASTER_MSG_TEMPLATE_ACTIVATE_SUCCESS
              : TOASTER_MSG_TEMPLATE_DEACTIVATE_SUCCESS;
            this.toast.populateToaster('success', toastMsg);
            this.gridOptions.api.updateRowData({ update: [res] });
            this.selectedRow = null;
            this.gridOptions.api.deselectAll();
          } else {
            toastMsg = activate
              ? TOASTER_MSG_TEMPLATE_ACTIVATE_ERROR
              : TOASTER_MSG_TEMPLATE_DEACTIVATE_ERROR;
            this.toast.populateToaster('error', toastMsg);
          }
        });
    }
  }

  addTemplate(newTemplate) {
    this.gridOptions.api.updateRowData({ add: [newTemplate], addIndex: 0 });
    this.templates.unshift(newTemplate);
    this.createTemplate = false;
  }

  public configureRowNodeId() {
    this.gridConfig.setRowNodeId((rowNodeData) => {
      if (rowNodeData instanceof Object) {
        return rowNodeData[TEMPLATE_ID] !== undefined
          ? rowNodeData[TEMPLATE_ID]
          : rowNodeData;
      }
    });
    this.gridConfig.setHideCheckBoxForRowSelection(() => true);
  }

  async createExportTemplateLink(selectedRow) {
    const servicePath = `/templates/${this.selectedRow.templateId}/download`;
    const data = {
      targetedType: 'RFQTemplateConfig',
      servicePath,
    };
    this.downloadLink = await this.fileDownloadService.formDownloadUrl(data);
  }

  public createDownloadLink() {
    const utcStartdate = new Date(DateUtils.getEpochTime(this.pickedDate));
    const startDate =
      DateUtils.getEpochTime(this.pickedDate) -
      utcStartdate.getTimezoneOffset() * 60 * 1000;
    const endOfDate = this.pickedEndDate.clone().endOf('day');
    const utcEndDate = new Date(DateUtils.getEpochTime(endOfDate));
    const endDate =
      DateUtils.getEpochTime(endOfDate) -
      utcEndDate.getTimezoneOffset() * 60 * 1000;
    const data = {
      targetedType: 'RFQ',
      formParams: {
        custId: this.baseService.getUserInformation().tenantId,
        startDate,
        endDate,
        templateType: this.templateType,
      },
      servicePath: '/downloadAll',
    };
    this.downloadPayload = data;
  }
  public saveTemplate() {
    this.showAlertPopup = false;
    this.createTemplateComp.saveTemplate();
  }
  public onDownloadClick() {
    this.selectedRow = null;
    this.gridOptions.api.deselectAll();
  }
  public canDeactivate() {
    if (
      this.createTemplateComp &&
      this.createTemplateComp.isNavigationAllowed()
    ) {
      this.showAlert('CREATE');
      this.baseService.setAppLoader(false);
    } else {
      return true;
    }
    return this.bool.asObservable();
  }

  public tabPanel(tab) {
    this.activeTab = tab;
    if (tab === 'RFQ Configuration') {
      this.configOperationPerformed('fetch')
    }
  }

  public optionChanged(e) {
    this.templateType = e;
  }
  public changeDate(event) {
    this.pickedDate = event;
  }
  public changeEndDate(event) {
    this.pickedEndDate = event;
  }
  public validateData(data, action) {
    if (this.apiCommonService.validateServiceCallData(data) !== 'error') {
      switch (action) {
        case 'delete':
          this.closeDays = '';
          this.toast.populateToaster('success', data.message);
          break;
        case 'update':
          this.toast.populateToaster('success', data.message);
          break;
        case 'fetch':
          this.closeDays = data.result.rfqCloseDays;
          break;
      }
    } else {
      switch (action) {
        case 'delete':
        case 'update':
          this.toast.populateToaster('warning', data.message);
          break;
      }
    }
  }
  public OnKeyUp(val) {
    this.closeDays = val;
  }
  public configOperationPerformed(action) {
    const userSessionData = this.baseService.getUserInformation().email;
    switch (action) {
      case 'delete':
        this.rfqConfigService
          .deleteRfqCloseDays(userSessionData)
          .pipe(takeUntil(this.subject$))
          .subscribe(value => {
            this.validateData(value, 'delete')
          })
        break;

      case 'save':
        this.rfqConfigService
          .saveRfqCloseDays(this.closeDays, userSessionData)
          .pipe(takeUntil(this.subject$))
          .subscribe(value => {
            this.validateData(value, 'update')
          })
        break;

      case 'fetch':
        this.rfqConfigService
          .getRfqCloseDays(userSessionData)
          .pipe(takeUntil(this.subject$))
          .subscribe(value => {
            this.validateData(value, 'fetch')
          })
        break;
    }
  }
  public onSaveCronTime() {
    this.rfqConfigService
      .updateRfxCronTime(encodeURIComponent(this.cronTime))
      .pipe(takeUntil(this.subject$))
      .subscribe(value => {
        if (value instanceof Object) {
          switch (value.responseStatus.code) {
            case 200:
              this.toast.populateToaster('success', value.message);
              break;
            case 500:
              this.toast.populateToaster('warning', value.message);
              break;
          }
        }
      });
  }

  public templateListUpdate(event) {
    if (event) {
      this.isRefreshTemplate = true;
      this.rfqConfigService.getTemplateList().pipe(takeUntil(this.subject$)).subscribe(rowData => {
        this.templates = rowData;
        this.gridOptions.rowData = rowData;
        this.gridConfig.setRowData(this.gridOptions.rowData);
        this.OtherConfig.columnResize = false;
        this.configureRowNodeId();
        this.showGrid = true;
        this.isRefreshTemplate = false;
        this.openCreateTemplate();
        this.copyTemplates = Object.create(this.copyTemplates);
      });
    }
  }

}
