import { Component, OnInit, Input, ChangeDetectionStrategy, TemplateRef } from '@angular/core';
import { LEVA_GLOBAL } from 'app/app.constants';
import { NgxLoadingConfig } from 'ngx-loading';

@Component({
  selector: 'cprt-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent implements OnInit {

  defaultConfig: NgxLoadingConfig = {
    backdropBackgroundColour: 'rgba(255,255,255,0.3)',
    fullScreenBackdrop: true,
    primaryColour: LEVA_GLOBAL.BRAND_COLOR,
    secondaryColour: LEVA_GLOBAL.BRAND_COLOR,
    tertiaryColour: LEVA_GLOBAL.BRAND_COLOR,
  };

  @Input() show: boolean;
  @Input() config: NgxLoadingConfig;
  @Input() template: TemplateRef<any>;

  constructor() {}

  ngOnInit() {

    this.config = {
      ...this.defaultConfig,
      ...this.config,
    };

  }

}
