import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LdGridComponent } from './components/ld-grid/ld-grid.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    LdGridComponent
  ],
  imports: [
    CommonModule,
    AgGridModule.withComponents([]),
    FormsModule
  ],
  exports: [
    LdGridComponent
  ]
})
export class LdGridModule { }
