import { IDatePickerConfig, ECalendarValue } from 'ng2-date-picker'

export const sourceIdConfiguration = {
    label: 'Source ID',
    customLabel: true,
    hideLabel: true,
    type: 'dynamic',
    val: 'tenantId',
    id: 'tenantFilterId',
    data: [],
}
export const mpnConfiguration = {
    label: 'MPN',
    type: 'newautosearch',
    filterConfig: {
        multiSelect: false,
        targetedType: 'riskManagementService',
        servicePath: 'risk/search/partsite/mpns',
    },
    hideLabel: true,
    val: 'mpn',
    data: [],
    id: `mpn-auto-select`,
    defaultParameter: null,
    isDisable: true
}
export const cmOdmConfiguration = {
    label: 'LD CM/ODM',
    type: 'newautosearch',
    filterConfig: {
        multiSelect: false,
        targetedType: 'riskManagementService',
        servicePath: 'risk/search/partsite/cmodms',
    },
    hideLabel: true,
    val: 'ldCmOdm',
    data: [],
    id: `ldCmOdm-auto-select`,
    defaultParameter: null,
    isDisable: true
}
export const supplierConfiguration = {
    label: 'LD Supplier',
    type: 'newautosearch',
    filterConfig: {
        multiSelect: false,
        targetedType: 'riskManagementService',
        servicePath: 'risk/search/partsite/suppliers',
    },
    hideLabel: true,
    val: 'ldSupplier',
    data: [],
    id: `ldSupplier-auto-select`,
    defaultParameter: null,
    isDisable: true
}
export const manufacturerConfiguration = {
    label: 'LD Manufacturer',
    type: 'newautosearch',
    filterConfig: {
        multiSelect: false,
        targetedType: 'riskManagementService',
        servicePath: 'risk/search/partsite/mfrs',
    },
    hideLabel: true,
    val: 'ldManufacturer',
    data: [],
    id: `ldManufacturer-auto-select`,
    defaultParameter: null,
    isDisable: true
}
export const sitesConfiguration = {
    label: 'Sites',
    type: 'newautosearch',
    filterConfig: {
        multiSelect: true,
        targetedType: 'riskManagementService',
        servicePath: 'risk/search/partsite/sites',
    },
    hideLabel: true,
    val: 'siteNames',
    data: [],
    id: `siteNames-auto-select`,
    defaultParameter: { partSite: true },
    isDisable: true
}
export const primaryFlagConfiguration = {
    label: 'Primary Flag',
    type: 'static',
    name: 'isPrimary',
    hideLabel: true,
    val: 'isPrimary',
    data: [
        {
            val: true,
            displayName: 'True',
        },
        {
            val: false,
            displayName: 'False',
        },
    ],
    id: 'primaryFlagId',
}

export const datePickerConfig: IDatePickerConfig = {
    format: 'DD-MMM-YYYY',
    disableKeypress: true,
    closeOnSelect: true,
    returnedValueType: ECalendarValue.String,
    drops: 'down',
    opens: 'right'
};

export const objectTypeDisableMap = {
    add: {
        enable: {
            sourceId: ['mpnObject', 'manufacturerObject'],
            mpn: ['cmOdmObject', 'supplierObject'],
            ldManufacturer: ['mpnObject', 'siteObject']
        },
        disable: {
            sourceId: ['cmOdmObject', 'supplierObject', 'siteObject'],
            mpn: ['siteObject']
        }
    },
    remove: {
        disable: {
            sourceId: ['mpnObject', 'cmOdmObject', 'supplierObject', 'manufacturerObject', 'siteObject'],
            mpn: ['cmOdmObject', 'supplierObject'],
            ldManufacturer: ['siteObject']
        }
    }
}
export const objectTypeResetMap = {
    sourceId: ['mpnObject', 'cmOdmObject', 'supplierObject', 'manufacturerObject', 'siteObject'],
    mpn: ['manufacturerObject', 'cmOdmObject', 'supplierObject'],
    ldManufacturer: ['mpnObject', 'siteObject']
}
export const defaultParameterMap = {
    sourceId: ['sourceId'],
    mpn: ['sourceId', 'mpn', 'ldManufacturer'],
    ldManufacturer: ['sourceId', 'ldManufacturer', 'mpn']
}
export const columnsMap = {
    sourceId: 'tenantId',
    ldManufacturer: 'mfr',
    ldSupplier: 'supplier',
    mpn: 'mpn',
    ldCmOdm: 'cmOdm',
    siteNames: 'siteNames'
}
