import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { RfqConfigService } from '../shared/rfq-config.service';
import { CREATE_TEMPLATE_MESSAGES, TEMPLATE_TYPE, TEMPLATE_SCOPE, TEMPLATE_STATUS, CONFIG_TEMPLATE_IMPORT, TEMPLATE_RFX_ORIGIN } from 'app/modules/rfq-configurations/shared/constants';
import { ToastService } from '../../srfq/shared/toaster.service';

@Component({
  selector: 'app-create-template',
  templateUrl: './create-template.component.html',
  styleUrls: ['./create-template.component.sass'],
  providers: [RfqConfigService]
})
export class CreateTemplateComponent implements OnInit {
  @Input() templates = [];
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() onTemplateAdd: EventEmitter<any> = new EventEmitter<any>();
  @Output() templateListUpdate: EventEmitter<any> = new EventEmitter<any>();
  public scopeTypes;
  public rfxOriginTypes;
  public templateTypes;
  public newTemplate;
  public nameError = '';
  public typeError = '';
  public FileData: any;
  constructor(private rfqConfigService: RfqConfigService, private toastService: ToastService) {
  }
  private actualForm: string;
  ngOnInit() {
    this.newTemplate = this.initNewTemplate();
    const keys = `${TEMPLATE_TYPE},${TEMPLATE_SCOPE},${TEMPLATE_RFX_ORIGIN}`
    this.rfqConfigService.getRefSetConstants(keys).subscribe(res => {
      this.templateTypes = res.find(x => x.refSetKey === TEMPLATE_TYPE).refSetTerms;
      this.scopeTypes = res.find(x => x.refSetKey === TEMPLATE_SCOPE).refSetTerms;
      this.rfxOriginTypes = res.find(x => x.refSetKey === TEMPLATE_RFX_ORIGIN).refSetTerms;
      this.newTemplate.scope = this.scopeTypes[0];
      this.newTemplate.type = this.templateTypes[0];
      this.actualForm = JSON.stringify(this.newTemplate);
    });

    this.FileData = {
      title: CONFIG_TEMPLATE_IMPORT,
      RFQ: true,
      servicePath: '/templates/import',
      formParams: {},
      fileType: 'JSON'
    }
  }
  initNewTemplate() {
    return {
      name: '',
      description: '',
      type: null,
      copy: 'Select Template',
      scope: null,
      rfxOrigin: 'Select Origin',
      templateId: null
    }
  }
  saveTemplate() {
    if (this.validateName() && this.validateType()) {
      const postObj: any = {
        name: this.newTemplate.name,
        description: this.newTemplate.description,
        rfxTypeId: parseInt(this.newTemplate.type.refTermActualValue),
        templateTypeId: parseInt(this.newTemplate.scope.refTermActualValue),
        rfxOrigin: this.newTemplate.rfxOrigin.refTermActualValue,
        status: TEMPLATE_STATUS.DRAFT,
        isOverrideParent: false,
        templateId: this.newTemplate.templateId
      }
      if (this.newTemplate.copy && this.newTemplate.copy.id) {
        postObj.parentTemplateId = this.newTemplate.copy.id;
      } else {
        delete postObj.parentTemplateId;
      }
      this.rfqConfigService.saveNewTemplate(postObj).subscribe(res => {
        if (res.error) {
          this.toastService.populateToaster('error', CREATE_TEMPLATE_MESSAGES.templateAddedError);
          return;
        } else {
          this.toastService.populateToaster('success', CREATE_TEMPLATE_MESSAGES.templateAddedSuccess);
          this.newTemplate = this.initNewTemplate();
          this.newTemplate.scope = this.scopeTypes[0];
          this.newTemplate.type = this.templateTypes[0];
          this.actualForm = JSON.stringify(this.newTemplate);
          this.onTemplateAdd.emit(res);
        }
      });
    }
    else {
    }
  }
  close() {
    this.onClose.emit();
  }
  /**
   * For validating the template name
   */
  validateName() {
    if (!this.newTemplate.name || this.newTemplate.name.trim().length === 0) {
      this.nameError = CREATE_TEMPLATE_MESSAGES.nameErrorMessage;
      return false;
    } else if (this.templates.find(temp => temp.name === this.newTemplate.name)) {
      this.nameError = CREATE_TEMPLATE_MESSAGES.sameTemplateMessage;
      return false;
    }
    this.nameError = '';
    return true;
  }
  validateType() {
    if (this.newTemplate.type === '') {
      this.typeError = CREATE_TEMPLATE_MESSAGES.typeError;
      return false;
    }
    this.typeError = '';
    return true;
  }
  typeChange(event) {
    this.newTemplate.type = event;
  }
  scopeChange(event) {
    this.newTemplate.scope = event;
  }

  rfxOriginChange(event) {
    this.newTemplate.rfxOrigin = event;
  }

  copyFrom(event) {
    this.newTemplate.copy = event;
  }

  onFileUpload(event) {
    if (event && event.responseStatus) {
      switch (event.responseStatus.code) {
        case 200:
          const response = event.result;
          this.toastService.populateToaster('success', CREATE_TEMPLATE_MESSAGES.fileUploadSuccess);
          this.newTemplate = this.initNewTemplate();
          this.newTemplate.name = response.name;
          this.newTemplate.description = response.description;
          this.newTemplate.type = this.getValueFromRefSets(this.templateTypes, response.rfxTypeId);
          this.newTemplate.scope = this.getValueFromRefSets(this.scopeTypes, response.templateTypeId);
          this.newTemplate.rfxOrigin = 'Select Origin'
          this.newTemplate.templateId = response.templateId;
          if (response.parentTemplateId) {
            this.newTemplate.copy = this.templates.find(x => x.id === response.parentTemplateId);
            if (!this.newTemplate.copy) {
              this.newTemplate.copy = 'Select Template';
            }
          } else {
            this.newTemplate.copy = 'Select Template';
          }
          this.templateListUpdate.emit(true);
          break;
        case 400:
          this.toastService.populateToaster('error', event.message);
          break;
        default:
          this.toastService.populateToaster('error', CREATE_TEMPLATE_MESSAGES.fileUploadError);
      }
      return;
    }
    this.toastService.populateToaster('error', CREATE_TEMPLATE_MESSAGES.fileUploadError);
  }

  getValueFromRefSets(refSetType, value) {
    return refSetType.find(type => Number(type.refTermActualValue) == value);
  }
  isNavigationAllowed() {
    return JSON.stringify(this.newTemplate) !== this.actualForm;
  }
}
