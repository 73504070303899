import { CustomerConfigurationService } from './customerConfigurationService';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {Subject} from 'rxjs';

@Injectable()
export class SubNavAdvBarService {

    constructor(private customerConfigurationService: CustomerConfigurationService) {
    }

    private subNavBarList: Array<object> = this.customerConfigurationService.getConfData('Commodity').subTabFeatures;
    private productsubNavBarList: Array<object> = this.customerConfigurationService.getConfData('Product').subTabFeatures;



    public getSubNavBarList(target) {
        let list;
        if (this.subNavBarList) {
            list = this.subNavBarList.filter(item => item[target] instanceof Array);
        }
        // console.log(list[0][target]);
        let returnArray = [];
        if (list instanceof Array && list.length > 0) {
            returnArray = list[0][target];
        };
        if (target === 'notifications') {
            if (returnArray instanceof Array && returnArray.length > 0) {
                returnArray.forEach(item => {
                    item.badgeEnabled = true;
                    // item.counter = 0;
                    item.bubbleColor = '#29ABE2'
                });
            }
        };
        return returnArray;
    }
    public getProductSubNavBarList(target) {
        let list;
        if (this.productsubNavBarList) {
            list = this.productsubNavBarList.filter(item => item[target] instanceof Array);
        }
        let returnArray = [];
        if (list instanceof Array && list.length > 0) {
            returnArray = list[0][target];
        }
        if (target === 'notifications') {
            if (returnArray instanceof Array && returnArray.length > 0) {
                returnArray.forEach(item => {
                    item.badgeEnabled = true;
                    // item.counter = 0;
                    item.bubbleColor = '#29ABE2'
                });
            }
        };
        return returnArray;

    }

}
