import { Injectable } from '@angular/core';
import { ApiServiceProvider } from 'app/api-service';

@Injectable({
  providedIn: 'root'
})
export class ContentLabService {
  constructor(private apiServiceProvider: ApiServiceProvider) {}

  // execute(payLoad: any) {
  //   const defaultPayLoadJSON = {
  //     targetedType: 'ContentManagementService',
  //     formParams: {},
  //     method: 'GET',
  //     pathParams: {},
  //     payload: {},
  //     servicePath: this.apiServicePath
  //   };
  //   const payLoadJSON = Object.assign(defaultPayLoadJSON, payLoad);
  //   return this.apiServiceProvider.post(payLoadJSON);
  // }

  getInsightDashboards() {
    const payload = {
      method: 'GET',
      servicePath: 'ContentManagementService/insight/dashboards',
      targetedType: 'ContentManagementService'
    };
    return this.apiServiceProvider.post(payload);
  }
  getInsightDashboardsBYTenant(requestParams) {
    const payload = {
      method: 'GET',
      servicePath: 'ContentManagementService/insight/dashboards/tenant',
      formParams: requestParams,
      targetedType: 'ContentManagementService'
    };
    return this.apiServiceProvider.post(payload);
  }
}
