import { Component } from '@angular/core';

@Component({
    selector: 'app-child-cell',
    templateUrl: './part-approve.component.html',
    styleUrls: ['./part-approve.component.sass']
})
export class PartApproveComponent {
    public approved = false;
    constructor() {}

    public agInit(params: any): void {
        this.approved = params && params.data && params.data.isApproved;
    }
    public approve() {}
}
