import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { TimeSelectionDataService } from 'app/modules/core/time-selection/shared/time-selectiondata.service';

@Component({
  selector: 'ld-metric-header',
  templateUrl: './metric-header.component.html',
  styleUrls: ['./metric-header.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class MetricHeaderComponent implements OnInit {

  @Input() configuration;
  next4Quarters='';

  constructor(
    private router: Router,
    private scopeDataService: ScopeDataService,
    private dataStorageService: DataStorageService,
    private timeSelectionDataService: TimeSelectionDataService
  ) { }

  ngOnInit() {
    setTimeout(()=>{this.getNext4Quarters()},2000)
  }
  getTitle(title){
    if(title === 'Cost Optimizer'){
      return this.next4Quarters;
    }else{
      return title;
    }
   }
   getNext4Quarters(){
     const next4QuartersArray =[];
     if(this.timeSelectionDataService.getFullTimePeriodObject()){
        const next4QuartersObj = this.timeSelectionDataService.getFullTimePeriodObject().next4Quarters;
        for(const year in next4QuartersObj) {
          if(year){
          next4QuartersArray.push(next4QuartersObj[year].map(i=>`${i}'${year.substring(2,4)}`));
          this.next4Quarters = next4QuartersArray.toString()
          }
        }
     }
 }

  navigateToPage(target) {
    if (target && target.url) {
      this.scopeDataService.setMetricHeader(true);
      if(target.url === 'review-opportunities'){
     this.dataStorageService.setRouteName('metriccard');
      }
      this.router.navigate([`app/${target.url.trim()}`]);
    }
  }
}
