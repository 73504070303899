import { Component } from '@angular/core';
import { InteractiveEditService } from 'app/modules/interactive-edit/shared/interactive-edit.service';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'app-numeric-cell-renderer',
  template: `{{params.value}}`
})
export class NumericCellRendererComponent implements ICellRendererAngularComp {

  constructor(private interactiveEditService: InteractiveEditService) { }

  public params: any;
  private value: string;

  agInit(params: any): void {
      this.params = params;
      this.setValue(params);
  }

  refresh(params: any): boolean {
      this.params = params;
      this.setValue(params);
      return true;
  }

  private setValue(params) {
      this.value = params.value;
  };

  public getValue() {
    return this.value;
  }

}
