import { Router } from '@angular/router';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { Component, Output, EventEmitter } from '@angular/core';
import { GridOptions } from '@ag-grid-community/core';
import { ApplicationURLS } from 'app/modules/common/applicationURL';
import { PartMatchingService } from 'app/modules/mi/partmatching/partmatching.service';
import { SubscriptionLike } from 'rxjs';


@Component({
    selector: 'cpn-link',
    templateUrl: './cpnlink.component.html',
    styleUrls: ['./cpnlink.component.sass']
})
export class CpnLinkComponent {
    public params: any;
    public gridOptions: GridOptions;
    link;
    cpn;
    loading= false;
    count;
    mpn;
    rowCount=0;
    rowInfo;
    lpn;
    pop;
    singlePartmatchSubscription: SubscriptionLike;

    show;
    result;
    @Output() public  hoveredCPN= new EventEmitter <{mpn: string}>();
    constructor(private router: Router,
          private partMatchingService: PartMatchingService,
           private applicationURLS: ApplicationURLS,
           private dataStorageService: DataStorageService) {}
agInit(params: any): void {

        this.params = params;
        this.count=this.params.data.count - 1;
        this.cpn=this.params.data.cpn;
        this.mpn=this.params.data.mpn;
        this.lpn= this.params.data.lpn;
        // this.rowInfo = this.dataStorageService.getRowInfo();
        // this.rowCount=this.rowInfo[this.lpn];
        if(this.params.data.count > 1){
            this.link = true;
        }else{
            this.link = false;
        }


    };
    showCPN_Tooltip(event){
            this.pop=true;
            const targetUrlObject = this.applicationURLS.getURLObject({
                    target: 'mi',
                    service: 'spm'
                });

                this.loading= true;
            this.singlePartmatchSubscription = this.partMatchingService.getInternalDetails(this.mpn, targetUrlObject.url).subscribe(
                (data)=>{
                    if(data){
                    this.result=data.result;
                    this.loading=false;
                    this.dataStorageService.setCpnPopUpData(this.result);
                    }
                });
            this.show=true;
    };

    hideCPN_Tooltip(event){
        // document.getElementById('cpntooltip'+this.mpn).classList.remove('show');
        // this.show=false;
    }

}
