import { Component, OnInit, ViewChild, OnDestroy, Input, ElementRef } from '@angular/core';
import { BaseService } from '../../common/base.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'app/modules/authentication/shared/authentication.service';
import { LoginService } from 'app/modules/authentication/login/login.component.service';
import { csAppHomePage } from 'app/modules/common/CSAppConfiguration.constants';
import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService';
import { ConfigService } from 'app/modules/common/configservice';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';
import { AUTHENTICATION_CONSTANT } from '../shared/authentication.constants';
import { ValidateLowerCase, ValidateNoSpaces, ValidateNumber, ValidateSpecialCharacter, ValidateUpperCase } from 'app/modules/core/generic-dynamic-form/common/validations/custom-validators';
import { takeWhile } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastService } from 'app/modules/srfq/shared/toaster.service';
import { SharedService } from 'app/modules/shared/services/shared.service';
import {CUSTOMER_CUSTOMER_DATA, CUSTOMER_LOGIN_RESPONSE, CUSTOMER_CS_APP, CUSTOMER_TOKEN_INFO} from '../../shared/services/shared-consts';
import {DataStorageService} from '../../common/data-storage.service';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.sass'],
  providers: [AuthenticationService]
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private baseService: BaseService,
    private loginService: LoginService,
    private customerConfigurationService: CustomerConfigurationService,
    private configService: ConfigService,
    private fb: FormBuilder,
    private toasterService: ToastService,
    private sharedService: SharedService,
    private dataStorageService: DataStorageService
  ) {}
  private _compActive = true;
  private userData: any;
  private token: any;
  private userEmail: string;
  public authConst = AUTHENTICATION_CONSTANT;
  public changePasswordForm: FormGroup;
  public returnUrl = this.authConst.urls.dashboard;
  public successMsg = '';
  public errorMsg = '';
  public changePasswordUserData: any;
  public isAlreadyActivated = false;
  public isLinkExpired = false;
  public showMessage = false;
  public type: string;
  public isResetPassword = false;
  @Input() isChangePassword = false;
  @Input() isDialog = false;
  @ViewChild('userValidation', { static: false }) userValidation: ElementRef;

  ngOnInit(): void {
    this.createForm();
    this.valueChangesCheck();
    this.getRouterData();
  }

  ngOnDestroy(): void {
    this._compActive = false;
  }

  private getRouterData(): void {
    this.route.data
    .pipe(takeWhile(() => this._compActive))
    .subscribe(data => {
      this.isResetPassword = data.key === this.authConst.resetPassword;
      if (this.isResetPassword) {
        this.route.queryParams
        .pipe(takeWhile(() => this._compActive))
        .subscribe(params => {
          this.token = params.token;
          this.getJwtToken();
        });
      } else {
        this.changePasswordForm.addControl('currentPassword', new FormControl('', Validators.required));
      }
    });
  }

  private getJwtToken(): void {
    const jwtHelper = new JwtHelperService();
    const decodedToken = jwtHelper.decodeToken(this.token);
    if (!decodedToken) { return; }
    this.userEmail = decodedToken.email;
    if (
      decodedToken.requestType &&
      decodedToken.requestType.toLowerCase().trim() === this.authConst.type.forgotPassword
    ) {
      this.showMessage = false;
      this.type = this.authConst.type.forgotPassword;
    } else {
      this.type = this.authConst.type.userActivation;
      this.checkUserAlreadyActivated(decodedToken.email);
    }
  }

  checkUserAlreadyActivated(email: string) {
    this.authenticationService
      .checkUserAlreadyActivated(email)
      .pipe(takeWhile(() => this._compActive))
      .subscribe(result => {
        if (result !== undefined && result instanceof Object
          && result.status && result.status === 200
          && result.result && result.result instanceof Object) {
          if (result.result.new) {
            this.isAlreadyActivated = false;
          } else {
            this.isAlreadyActivated = true;
            this.errorMsg = this.authConst.errors.activeUser;
          }
        } else {
          this.isAlreadyActivated = false;
        }
      });
  }

  private createForm(): void {
    this.changePasswordForm = this.fb.group({
      newPassword: ['', [
        Validators.required,
        Validators.minLength(8),
        ValidateNumber,
        ValidateUpperCase,
        ValidateLowerCase,
        ValidateSpecialCharacter,
        ValidateNoSpaces
      ]],
      confirmPassword: ['', [Validators.required]]
    })
  }

  private valueChangesCheck(): void {
    this.changePasswordForm.valueChanges
    .pipe(takeWhile(() => this._compActive))
    .subscribe(field => {
      if (this.errorMsg) {
        this.errorMsg = '';
      }
      if(field.currentPassword !== '' && field.newPassword !== '' && !this.isResetPassword) {
        const errors = this.changePasswordForm.controls.newPassword.errors;
        if (field.currentPassword === field.newPassword) {
          this.changePasswordForm.get('newPassword').setErrors({ ...errors, samePassword: true });
        } else {
          if (errors && errors['samePassword']) {
            delete errors['samePassword'];
            Object.keys(errors).length ? this.changePasswordForm.get('newPassword').setErrors({ ...errors })
            : this.changePasswordForm.get('newPassword').setErrors(null);
          }
        }
      }
      if(field.newPassword === '' || field.confirmPassword === '') { return; }
      if (field.newPassword !== field.confirmPassword) {
        this.changePasswordForm.get('confirmPassword').setErrors({ error: true });
      } else {
        this.changePasswordForm.get('confirmPassword').setErrors(null);
      }
    });
  }

  public submitChangePasswordForm(): void {
    if (!this.changePasswordForm.valid) {
      this.changePasswordForm.controls.currentPassword.markAsDirty();
      this.changePasswordForm.controls.newPassword.markAsDirty();
      this.changePasswordForm.controls.confirmPassword.markAsDirty();
      const errors = this.changePasswordForm.controls.newPassword.errors;
      if (errors && !errors['required'] && !errors['samePassword']) {
        this.changePasswordForm.controls.newPassword.setErrors({...errors, invalidPattern: true});
      }
      if (this.changePasswordForm.controls.confirmPassword.value !== this.changePasswordForm.controls.newPassword.value) {
        this.changePasswordForm.get('confirmPassword').setErrors({ mismatch: true });
      }
      return;
    }

    this.userData = this.baseService.getUserInformation();
    if (!this.userData) {
      this.errorMsg = this.authConst.errors.userConfig;
      return;
    }
    const obj: any = {
      targetedType: 'UserService',
      method: 'POST',
      servicePath: '/changeUserPassword',
      payload: {
        email: this.userData.email,
        newPassword: this.changePasswordForm.get('newPassword').value,
        password: this.changePasswordForm.get('currentPassword').value,
        userId: this.userData.userId
      }
    };
    this.authenticationService.changePassword(obj)
      .pipe(takeWhile(() => this._compActive))
      .subscribe(data => {
        if (data) {
          this.changePasswordUserData = data;
          this.validateData();
        } else {
          this.errorMsg = this.authConst.errors.somethingWrong;
        }
      });

  }

  public validateData() {
    const re = 'success';
    const str = this.changePasswordUserData.message;
    if (str.search(re) === -1) {
      this.errorMsg = this.changePasswordUserData.message;
      this.successMsg = '';
    } else {
      this.successMsg = this.changePasswordUserData.message;
      this.errorMsg = '';
      if (this.isDialog) {
        const ele = document.querySelectorAll('.ui-close')['0'] as HTMLElement;
        ele.click();
        this.toasterService.populateToaster('success', this.authConst.titles.passwordChangeSuccess);
      }
      this.getUserConfiguration();
    }
  }

  resetNewPassword(): void {
    if (!this.changePasswordForm.valid) {
      this.changePasswordForm.controls.newPassword.markAsDirty();
      this.changePasswordForm.controls.confirmPassword.markAsDirty();
      if (this.changePasswordForm.controls.confirmPassword.value !== this.changePasswordForm.controls.newPassword.value) {
        this.changePasswordForm.get('confirmPassword').setErrors({ mismatch: true });
      }
      return;
    }
    if (!this.userEmail) {
      this.errorMsg = this.authConst.errors.userConfig;
      return;
    }
    this.authenticationService
      .resetPassword(this.userEmail, this.changePasswordForm.get('newPassword').value, this.token)
      .pipe(takeWhile(() => this._compActive))
      .subscribe(result => {
        if (result !== undefined && result instanceof Object && result.responseStatus) {
          if (result.responseStatus.code === 200) {
            this.errorMsg = '';
            this.showMessage = true;
            this.isLinkExpired = false;
            if (this.showMessage && !this.isAlreadyActivated) {
              this.successMsg = this.authConst.titles.passwordUpdateSuccess;
            }
          } else if (result.responseStatus.code === 417) {
            this.errorMsg = '';
            this.showMessage = false;
            this.isLinkExpired = true;
            if (!this.showMessage && this.isLinkExpired && !this.isAlreadyActivated) {
              if (this.type === this.authConst.type.userActivation) {
                this.errorMsg = this.authConst.titles.linkExpired
              } else if(this.type === this.authConst.type.forgotPassword) {
                this.errorMsg = this.authConst.errors.linkExpired
              }
            }
          }
        } else {
          this.errorMsg = this.authConst.errors.errorToSetPassword;
        }
      });

  }

  getHomePage() {
    const homepage = this.loginService.setupHomePage();
    this.returnUrl = `/app/${homepage}`;
    this.router.navigate([this.returnUrl]);
  }

  getUserConfiguration() {
    const responseData = {};
    responseData['userInfo'] = this.userData;
    if (responseData['userInfo']['tenantId'] !== 0) {
      this.configService
        .getAuthenticationToken(
          'refresh_token',
          false,
          null,
          null,
          JSON.parse(localStorage.getItem(CUSTOMER_TOKEN_INFO)).refresh_token
        )
        .subscribe( newTokenInfo => {
          this.sharedService.setLocalStorageData(
            CUSTOMER_TOKEN_INFO,
            newTokenInfo
          );
          this.configService.caluculateTokenExpiryTime();
          this.dataStorageService.tokenSubject.next(newTokenInfo);
          this.configService
            .getUserConfiguration(
              responseData['userInfo']['tenantId'],
              responseData['userInfo']['userId']
            )
            .pipe(takeWhile(() => this._compActive))
            .subscribe(
              val => {
                if (val && val.responseStatus && val.responseStatus.code === 200) {
                  if (val.status === 500) {
                    this.errorMsg = this.authConst.errors.userConfig;
                    return;
                  }
                  const obj = this.sharedService.getLocalStorageData(CUSTOMER_LOGIN_RESPONSE);
                  obj['user'].new = false;
                  this.baseService.setAppLoader(false);
                  val = this.loginService.setupUserConfiguration(val, responseData);
                  this.customerConfigurationService.setLoginCustResponse(val.result);
                  this.sharedService.setLocalStorageData(CUSTOMER_CUSTOMER_DATA, val.result);
                  this.sharedService.setLocalStorageData(CUSTOMER_LOGIN_RESPONSE, obj);
                  this.getHomePage();
                } else {
                  this.errorMsg = this.authConst.errors.userConfig;
                }
              },
              error => {
                console.log(error);
              }
            );
        });
    } else {
      if (responseData['userInfo']['superAdmin']) {
        const obj = this.sharedService.getLocalStorageData(CUSTOMER_LOGIN_RESPONSE);
        obj['user'].new = false;
        this.baseService.setCsApp(true);
        this.sharedService.setLocalStorageData(CUSTOMER_CS_APP, true);
        this.sharedService.setLocalStorageData(CUSTOMER_LOGIN_RESPONSE, obj);
        if (this.baseService.getCsApp()) {
          this.router.navigate([`/app/${csAppHomePage}`]);
          this.baseService.setAppLoader(false);
          return;
        }
      }
    }
  }

}
