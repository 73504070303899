import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import 'rxjs/add/operator/map';

@Injectable()

export class BOTHelpService{
    selectedCategory = new Subject<any>();
    selectedCommand = new Subject<any>();

    updateHistoryValue = new BehaviorSubject<any>(undefined);
    constructor(){

    }

    setSelectedCategory(category){
        this.selectedCategory.next(category);
    }
    getSelectedCategory(){
        return this.selectedCategory.asObservable();
    }
    setSelectedCommand(command){
        this.selectedCommand.next(command);
    }
    getSelectedCommand(){
        return this.selectedCommand.asObservable();
    }

    setUpdateHistory(target){
        this.updateHistoryValue.next(target);
    }
    getUpdateHistory(){
        return this.updateHistoryValue.asObservable();
    }
}
