import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-risk-filters',
  templateUrl: './risk-filters.component.html',
  styleUrls: ['./risk-filters.component.sass'],
})
export class RiskFiltersComponent implements OnInit {
  @Input('defaultDimensions') defaultDimensions = [];
  @Input('filterType') filterType = null;
  @Output() onFilterChange = new EventEmitter<any>();

  selectedDimensions = [];
  filterDimensions = [];
  filterRows = [];
  constructor() {}

  ngOnInit() {
    this.filterDimensions = JSON.parse(JSON.stringify(this.defaultDimensions));
    const filterObject = {
      dimensions: this.filterDimensions,
      selectedDim: null,
      selectedDimType: null,
      dimensionValues: null,
      dimensionListConfiguration: null,
      includeForBase: false,
      includeForAlternate: false,
      resetDimensionValues: this.generateRandomKey(),
    };
    this.filterRows.push(JSON.parse(JSON.stringify(filterObject)));
  }

  generateRandomKey() {
    const crypto = window.crypto;
    const array = new Uint32Array(1);
    return crypto.getRandomValues(array)[0];
  }

  onFilterDimensionChange(rowIndex, currentFilterRow, selectedItem) {
    if (
      currentFilterRow.selectedDim !== null &&
      currentFilterRow.selectedDim.view === selectedItem.view
    ) {
      return;
    }
    currentFilterRow.selectedDim = selectedItem;
    currentFilterRow.selectedDimType = selectedItem.type;

    if (this.selectedDimensions.indexOf(selectedItem.view) === -1) {
      this.selectedDimensions = this.filterRows.map((filterRow) => {
        return filterRow.selectedDim.view;
      });
    }

    const totalDimensions = JSON.parse(JSON.stringify(this.defaultDimensions));
    this.filterDimensions = [];
    totalDimensions.forEach((dimension) => {
      if (this.selectedDimensions.indexOf(dimension.view) === -1) {
        this.filterDimensions.push(dimension);
      }
    });

    currentFilterRow.resetDimensionValues = this.generateRandomKey();
    let dimensionListConfiguration = null;
    switch (selectedItem.type) {
      case 'set':
        dimensionListConfiguration = {
          label: selectedItem.displayName,
          type: 'newautosearch',
          filterConfig: {
            multiSelect: true,
            targetedType: 'riskManagementService',
            servicePath: selectedItem.servicePath,
          },
          hideLabel: true,
          val: selectedItem.view,
          data: [],
          id: `${selectedItem.view}-filter-${this.filterType}`,
        };

        if (selectedItem.isDefaultSelection) {
          dimensionListConfiguration.filterConfig[
            'defaultItemSelection'
          ] = true;
        }
        break;
      case 'boolean':
        dimensionListConfiguration = {
          label: selectedItem.displayName,
          type: 'static',
          hideLabel: true,
          val: selectedItem.view,
          id: `${selectedItem.view}-filter-${this.filterType}`,
          data: [
            {
              val: 'true',
              displayName: 'True',
            },
            {
              val: 'false',
              displayName: 'False',
            },
          ],
        };
        break;
      default:
        break;
    }
    currentFilterRow.dimensionListConfiguration = dimensionListConfiguration;
    this.getFilterOutput();
  }

  onDimensionValueSelect(filterRow, item) {
    switch (filterRow.selectedDimType) {
      case 'set':
      case 'text':
        filterRow.dimensionValues = !item.selectedItems.length
          ? null
          : item.selectedItems;
        break;
      case 'boolean':
        filterRow.dimensionValues =
          item.actionType === 'add' ? JSON.parse(item.selectedItem.val) : null;
        break;
    }
    this.getFilterOutput();
  }

  onDimensionValueChange(filterRow) {
    this.getFilterOutput();
  }

  onIncludeBaseSelectionChange() {
    this.getFilterOutput();
  }
  onIncludeAlternateSelectionChange() {
    this.getFilterOutput();
  }

  onAddFilterRow(rowIndex, event) {
    event.stopPropagation();
    const filterObject = {
      dimensions: this.filterDimensions,
      selectedDim: null,
      selectedDimType: null,
      dimensionValues: null,
      dimensionListConfiguration: null,
      includeForBase: false,
      includeForAlternate: false,
      resetDimensionValues: this.generateRandomKey(),
    };
    this.filterRows.push(JSON.parse(JSON.stringify(filterObject)));
    this.getFilterOutput();
  }

  onDeleteFilterRow(rowIndex, selectedRow, event) {
    event.stopPropagation();
    const findIndex = this.selectedDimensions.indexOf(
      selectedRow.selectedDim.view
    );
    if (findIndex !== -1) {
      this.selectedDimensions.splice(findIndex, 1);
    }

    const totalDimensions = JSON.parse(JSON.stringify(this.defaultDimensions));
    this.filterDimensions = [];
    totalDimensions.forEach((dimension) => {
      if (this.selectedDimensions.indexOf(dimension.view) === -1) {
        this.filterDimensions.push(dimension);
      }
    });

    setTimeout(() => {
      this.filterRows.splice(rowIndex, 1)
      const lastFilterRow = this.filterRows[this.filterRows.length -1]
      lastFilterRow.dimensions = [lastFilterRow.selectedDim, ...this.filterDimensions]

      this.getFilterOutput()
    });
  }

  onClearAllFilters() {
    this.filterRows = [];
    this.selectedDimensions = [];
    this.filterDimensions = JSON.parse(JSON.stringify(this.defaultDimensions));
    const filterObject = {
      dimensions: this.filterDimensions,
      selectedDim: null,
      selectedDimType: null,
      dimensionValues: null,
      dimensionListConfiguration: null,
      includeForBase: false,
      includeForAlternate: false,
      resetDimensionValues: this.generateRandomKey(),
    };
    this.filterRows.push(JSON.parse(JSON.stringify(filterObject)));
    this.getFilterOutput();
  }

  getFilterOutput() {
    const addedFilters = {
      type: this.filterType,
      base: [],
      alternate: [],
    };
    this.filterRows.forEach((filterRow) => {
      switch (filterRow.selectedDimType) {
        case 'text':
          if (filterRow.dimensionValues && filterRow.dimensionValues.length) {
            if (filterRow.includeForBase) {
              addedFilters.base.push({
                label: filterRow.selectedDim.view,
                selectedItems: filterRow.dimensionValues,
              });
            }
            if (filterRow.includeForAlternate) {
              addedFilters.alternate.push({
                label: filterRow.selectedDim.view,
                selectedItems: filterRow.dimensionValues,
              });
            }
          }
          break;
        default:
          if (filterRow.dimensionValues !== null) {
            if (filterRow.includeForBase) {
              addedFilters.base.push({
                label: filterRow.selectedDim.view,
                selectedItems: filterRow.dimensionValues,
              });
            }
            if (filterRow.includeForAlternate) {
              addedFilters.alternate.push({
                label: filterRow.selectedDim.view,
                selectedItems: filterRow.dimensionValues,
              });
            }
          }
          break;
      }
    });
    // console.log(addedFilters);
    this.onFilterChange.emit(addedFilters);
  }
}
