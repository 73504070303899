import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiServiceProvider } from 'app/api-service';

@Injectable({
    providedIn: 'root'
})
export class PageLoaderService extends ApiServiceProvider {

    public isLoading = new BehaviorSubject(false);


    getApplicationStatus(tenantId){
        const object: any = {
            targetedType: 'CustomerConfigService',
            formParams:  {tenantId},
            method: 'GET',
            generic: true,
            custom: true,
            servicePath: `CustomerConfigurationService/tenant/banner`
          };
          return this.post(object);
    }
}
