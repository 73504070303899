import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  NgZone,
  HostListener,
} from '@angular/core';
import { BaseApp } from './shared/base-app.model';
import { BaseAppService } from './shared/base-app.service';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { DomSanitizer } from '@angular/platform-browser';
// import { Router, ActivatedRoute } from '@angular/router'
import { Router } from '@angular/router';
import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService';
import { ChatService } from 'app/modules/common/socket/chart.service';
import { SocketService } from 'app/modules/common/socket/scoket.service';
import { environment } from 'environments/environment';
import { Location } from '@angular/common';
import { BaseService } from 'app/modules/common/base.service';
import { AuthenticationService } from 'app/modules/authentication/shared/authentication.service';
import { AppHeaderService } from 'app/modules/base-app/app-header/shared/app-header.service';
import { UploadDownloadService } from '../srfq/shared/upload-download.service';
import { DynamicScriptLoaderService } from './shared/dynamic-script-loader.service';
import { FileDownloadService } from '../core/file-download/shared/file-download.service';
import { SCROLL_BAR_CONFIGURATION } from '../common/scroll-config.service';
import { DialogBoxService, DialogButtonName, DialogSize, DialogType, LdDialog } from '../leva-ui-library/components/dialog-box/dialog-box.service';
import { UtilService } from '../common/utills/util.service';
import {SharedService} from '../shared/services/shared.service';
import {CUSTOMER_LOGIN_RESPONSE, CUSTOMER_SESSION_EXPIRED} from '../shared/services/shared-consts';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export const COLLABORATION_TYPES = {
  DEFAULT: 'default',
  TYPE1: 'TYPE_1',
  TYPE2: 'TYPE_2',
  TYPE3: 'TYPE_3',
};
@Component({
  selector: 'base-app',

  templateUrl: 'base-app.component.html',
  styleUrls: ['base-app.component.sass'],
  providers: [
    BaseAppService,
    UploadDownloadService,
    Location,
    AuthenticationService,
    AppHeaderService,
    DynamicScriptLoaderService,
  ],
})
export class BaseAppComponent implements OnInit, OnDestroy {
  private subject$: Subject<any> = new Subject();
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  public COLLABORATION_TYPES = COLLABORATION_TYPES;
  public showAlertPopup = false;
  onClickPopup = false;
  public tenantId: any;
  public userSessionData: any;
  public url: any;
  public src: any;
  public csApp = false;
  public envDetails: any;
  public returnUrl = '/app/dashboard';
  public logo: any;
  public closePopup = false;
  public delayAlert = true;
  public customerConfiguration: any;
  public collapsableButton = 'expand';
  public logoWidth: string;
  public passwordExpiryPopup = false;
  @Output() public minimizeBot: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();
  // baseApp: BaseApp[] = [];

  private toggleBotAction: boolean;

  private custInfo: any;
  inactiveSessionPopup: LdDialog = {
    title: 'Inactive Session',
    id: 'inactive_session_popup',
    message: `Your session is about to end.You have been inactive for 30 minutes. For your security, we will automatically sign you out soon. Choose
    'Stay Signed In' to continue or "Sign out" if you're done.`,
    size: DialogSize.SMALL,
    type: DialogType.CONFIRMATION,
    buttons: [
      {
        title: 'Sign out',
        id: 'inactive_session_popup_sign_out',
      },
      {
        title: 'Stay Signed In',
        id: 'inactive_session_popup_stay_signed_in',
        primary: true,
      },
    ],
  };
  openPopup = false;
  passwordExpiryPopupConfig: LdDialog = {
    title: 'CONFIRMATION',
    id: 'password_expiry_popup',
    template: false,
    message: `Your password is expiring soon, please change the password`,
    size: DialogSize.SMALL,
    type: DialogType.CONFIRMATION,
    buttons: [
      {
        title: DialogButtonName.NO,
        id: 'password_expiry_popup' + DialogButtonName.NO
      },
      {
        title: 'Change Password',
        id: 'password_expiry_popup_change_password',
        primary: true,
      },
    ],
  };
  sessionExpiredPopup: LdDialog = {
    title: 'Session Expired',
    id: 'session_expired_popup',
    message: `Your session has expired, Please login again.`,
    size: DialogSize.SMALL,
    type: DialogType.INFORMATION,
    buttons: [
      {
        title: 'LOGIN',
        id: 'session_expired_popup LOGIN',
        primary: true,
      }
    ],
  };
  constructor(
    private authenticationService: AuthenticationService,
    private baseAppService: BaseAppService,
    private scopeDataService: ScopeDataService,
    private router: Router,
    public dataStorageService: DataStorageService,
    private sanitizer: DomSanitizer,
    private chat: ChatService,
    private socketService: SocketService,
    private zone: NgZone,
    private customerConfigurationService: CustomerConfigurationService,
    public location: Location,
    private uploadDownloadService: UploadDownloadService,
    private baseService: BaseService,
    private appHeaderService: AppHeaderService,
    private dialogBoxService: DialogBoxService,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private fileDownloadService: FileDownloadService,
    private utilService: UtilService,
    private sharedService: SharedService
  ) {
    // document.cookie = "username" + "=" + "eswar@levadata.com";
    if (document.readyState === 'complete') {
      this.setTabsInStorage();
    }
    window['currencySymbol'] = '$';
  }

  public ngOnInit() {
    this.custInfo = this.customerConfigurationService.getBaseCustomerInfo();
    this.envDetails = this.customerConfigurationService.getEnvironmentDetails();
    try {
      this.passwordExpiryPopup = false;
      const todayEpoch = new Date().getTime();
      if (!this.baseService.getUserInformation().isSSOUser) {
        const obj = JSON.parse(localStorage.getItem(CUSTOMER_LOGIN_RESPONSE));
        if (obj.userInfo.lastPasswordChangedDate !== null && obj.userInfo.lastPasswordChangedDate > 0) {
          if (obj.userInfo.passwordExpiryDays !== null && +obj.userInfo.passwordExpiryDays > 0) {
            const daysLeft = +obj.userInfo.passwordExpiryDays - obj.userInfo.notifyPasswordExpiryDays;
            let toasterDate: any;
            if (daysLeft > 0) {
              toasterDate = obj.userInfo.lastPasswordChangedDate + (daysLeft * 24 * 60 * 60 * 1000)
            } else {
              toasterDate = obj.userInfo.lastPasswordChangedDate;
            }
            if (new Date(obj.userInfo.lastPasswordChangedDate + (+obj.userInfo.passwordExpiryDays * 24 * 60 * 60 * 1000)) > new Date(todayEpoch)) {
              if (new Date(toasterDate) <= new Date(todayEpoch)) {
                if (!this.passwordExpiryPopup) {
                  setTimeout(() => { this.dialogBoxService.open(this.passwordExpiryPopupConfig.id) }, 120)
                  this.passwordExpiryPopup = true
                }
              }
            } else {
              console.log("monitoring the session expired pop up issue, 7")
              this.baseService.setUnAuthrizePopup(true);
            }
          }
        }
      }
    } catch (e) {
      this.passwordExpiryPopup = false
    }
    if (this.custInfo instanceof Object && this.custInfo.enableWalkme) {
      this.loadScripts();
    }
    console.log("monitoring the session expired pop up issue, 8")
    this.baseService.setUnAuthrizePopup(false);
    this.baseService.setAlertPopupAfterTimeout(false);
    this.csApp = this.baseService.getCsApp();
    this.baseService.getUnAuthrizePopup()
    .pipe(takeUntil(this.subject$))
    .subscribe((data) => {
      if (
        data ||
        JSON.parse(localStorage.getItem(CUSTOMER_SESSION_EXPIRED))
      ) {
        this.dataStorageService.collaborationInput = null;
        if (!this.closePopup) {
          this.authenticationService.clearCustomerLocalStorageItems();
          this.dialogBoxService.open(this.sessionExpiredPopup.id);
          this.closePopup = true;
        }
      }
    });
    this.baseService.getAlertPopupAfterTimeout()
    .pipe(takeUntil(this.subject$))
    .subscribe((data) => {
      if (
        data
      ) {
        this.showAlertPopup = true;
        this.dialogBoxService.open(this.inactiveSessionPopup.id);
        setTimeout(() => {
          if (!this.onClickPopup) {
            this.showAlertPopup = false;
            this.dialogBoxService.close(this.inactiveSessionPopup.id);
            this.authenticationService.clearCustomerLocalStorageItems();
            this.dialogBoxService.open(this.sessionExpiredPopup.id);
            this.closePopup = true;
            this.onClickPopup = true;

          }
        }, 120000)

      }
    });

    this.userSessionData = this.baseService.getUserInformation();
    if (this.userSessionData) {
      this.tenantId = this.userSessionData.tenantId;
    }
    this.getLogoURL();
    /*------------------------------ Web Socket Code Start --------------------------------*/
    if (environment && environment['webSocke']) {
      this.chat.messages.pipe(takeUntil(this.subject$)).subscribe((msg) => {
        if (msg !== undefined) {
          if (msg instanceof Object) {
            if (this.delayAlert) {
              setTimeout(() => {
                this.socketService.setChatBotNotificationAlert(msg);
                this.delayAlert = false;
              }, environment.scoket.timeDelay);
            } else {
              this.socketService.setChatBotNotificationAlert(msg);
            }
          }
        }
      });
    }

    /*------------------------------ Web Socket Code End --------------------------------*/

    // this.logo = this.custInfo.customerLogoPath ? this.custInfo.customerLogoPath : 'assets/images/logo.png';
    // this.logoWidth = this.custInfo.logoWidth ? this.custInfo.logoWidth : '60%';
  }
  closePane() {
    this.dataStorageService.taskActionInput = false;
  }
  public onCollapseView(event) {
    this.collapsableButton =
      this.collapsableButton === 'expand' ? 'collapse' : 'expand';
    // this.utilService.setNavBarStatus(this.collapsableButton);
    this.dataStorageService.setToggle(true);
this.onMenuToggle();
    this.baseService.setNavBarStatus(this.collapsableButton);
  }
  public onClickBot() {
    this.collapsableButton = 'expand';
  }
  public async getLogoURL() {
    if (this.baseService.getCsApp()) {
      this.url = 'assets/images/logo.png';
    } else {
      const data = {
        targetedType: 'CustomerConfigService',
        formParams: {
          tenantId: this.tenantId,
        },
        servicePath: '/downloadCustomerLogo',
      };
      this.url = await this.fileDownloadService.formDownloadUrl(data);
    }
  }
  public uuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
      const random = (Math.random() * 16) || 0; // Nachkommastellen abschneiden
      const value = char === 'x' ? random : (random % 4) + 8; // Bei x Random 0-15 (0-F), bei y Random 0-3 + 8 = 8-11 (8-b) gemÃ¤ss RFC 4122
      return value.toString(16); // Hexadezimales Zeichen zurÃ¼ckgeben
    });
  }
  public onBodyClick($event) {
    // this.scopeDataService.setScopeStatus(false);
  }
  public toggleAction(toggleBotAction) {
    this.toggleBotAction = toggleBotAction;
    if (!this.toggleBotAction) {
      document.getElementById('navigation').style.display = 'none';
      document.getElementById('bot').className = 'full-height';
      document.getElementById('bot').style.paddingTop = '30px';
      this.toggleBotAction = true;
      // document.getElementById("bot-toggle-action").innerHTML ='';
      const icon = document.getElementById('bot-toggle-action');
      document.getElementById('bot-toggle-action').className =
        'ld-icon-arrow-down ld-white';
    } else {
      document.getElementById('navigation').style.display = 'block';
      document.getElementById('bot-toggle-action').style.display = 'block';
      document.getElementById('bot').className = 'full-height-split-by-2';
      document.getElementById('bot').style.paddingTop = '';
      // document.getElementById("bot-toggle-action").innerHTML ='';
      document.getElementById('bot-toggle-action').className =
        'ld-icon-arrow-up ld-white';
    }
  }
  public onMenuToggle() {
    if (!this.toggleBotAction) {
      this.toggleBotAction = true;
      this.toggleAction(this.toggleBotAction);
      this.minimizeBot.emit(this.toggleBotAction);
    }
    else
    {
      this.toggleBotAction = false;
      this.minimizeBot.emit(this.toggleBotAction);
    }
  }
  
  public redirectToLogin() {
    this.authenticationService.logOutExecution();
  }

  public errorMethod() {
    this.url = 'assets/images/logo.png';
  }
  dialogBoxButtonClick(event) {
    if (event && event.button.id === 'inactive_session_popup_stay_signed_in') {
      this.onClickPopup = true;
      this.showAlertPopup = false;
    } else {
      this.sharedService.setLocalStorageData(
        CUSTOMER_SESSION_EXPIRED,
        true
      );
      this.dataStorageService.collaborationInput = null
      this.redirectToLogin();
    }
  }
  onLoginClick(event) {
    if (event) {
      this.redirectToLogin();
    }
  }
  ChangePasswordEvent(event) {
    if (event.button.id === 'password_expiry_popup_change_password') {
      this.onPasswordChange();
    } else {
      this.onCancelClick();
    }
  }
  public ngOnDestroy() {
    this.closePopup = false;
    this.showAlertPopup = false;
    console.log("monitoring the session expired pop up issue, 9")
    this.baseService.setUnAuthrizePopup(false);
    this.baseService.setAlertPopupAfterTimeout(false);
    this.subject$.next();
    this.subject$.complete();
  }

  @HostListener('window:beforeunload', ['$event'])
  public onBeforeUnload($event) {
    sessionStorage.setItem('customer_isPageRefreshed', JSON.stringify(true));
    if (
      Object.keys(localStorage).length > 0 &&
      JSON.parse(sessionStorage.getItem('customer_loaded'))
    ) {
      const item = Object.keys(localStorage).find((key) =>
        key.includes('customer_tab_id_')
      );
      if (item) {
        localStorage.removeItem(item);
      }
    }
  }
  @HostListener('window:load', ['$event'])
  public load($event) {
    this.setTabsInStorage();
  }
  private setTabsInStorage() {
    sessionStorage.removeItem('customer_isPageRefreshed');
    sessionStorage.setItem('customer_loaded', JSON.stringify(true));
    this.sharedService.setLocalStorageData(
      `customer_tab_id_${new Date().getTime().toString()}`,
      true
    );
  }
  private loadScripts() {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    let DYNAMIC_WALK_JS_URL = 'walkmejs_stage';

    switch (this.envDetails.envName.toLowerCase().trim()) {
      case 'qa':
      case 'dev':
      case 'demo':
        DYNAMIC_WALK_JS_URL = 'walkmejs_demo';
        break;
      case 'prod':
        DYNAMIC_WALK_JS_URL = 'walkmejs_prod';
        break;
      case 'stage':
        DYNAMIC_WALK_JS_URL = 'walkmejs_stage';
        break;
      case 'uat':
        DYNAMIC_WALK_JS_URL = 'walkmejs_uat';
        break;
    }

    this.dynamicScriptLoader
      .load(DYNAMIC_WALK_JS_URL)
      .then((data) => {
        // Script Loaded Successfully
      })
      .catch((error) => console.log(error));
  }
  onPasswordChange() {
    this.passwordExpiryPopup = false;
    this.openPopup = true;
    document.getElementById('popup').click();
  }
  onCancelClick() {
    this.dialogBoxService.close(this.passwordExpiryPopupConfig.id)
    this.passwordExpiryPopup = false;
  }
}
