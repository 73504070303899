// import { Router, ActivatedRoute,RouterState,ActivatedRouteSnapshot ,RouterStateSnapshot} from '@angular/router';
import { BaseService } from 'app/modules/common/base.service';
import { Injectable } from '@angular/core';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { CustomerConfigurationService } from './customerConfigurationService';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { NPI_ROUTING_PATHS } from '../npi/npiconstants';
import { CUSTOMER_CURRENT_USER } from '../shared/services/shared-consts';
@Injectable()
export class FeatureService {
  public subTabChildFeatures: any;
  public leftNavbarFeatures: any = [];
  public subNavBarList: any = [];
  public menuMap: any = [];
  public scope: any;
  public obj: any = '';
  public featuresarray: string[] = [];
  public features: any;
  public routes: string;
  public commonUrls = ['summary', 'opprisks', 'details'];
  public commonUrlsTabs = {
    competitiveness: [],
    np: [],
    curatorengine: [],
    rawmaterial: [],
    opprisks: [],
  };
  public reDirectionConstants = {
    app: 'opportunities',
    opprisks: `/app/curatorengine/opprisks`,
    basicalerts: `/app/curatorengine/alerts/basicalerts`,
    levers: `/app/curatorengine/negotiationlevers/levers`,
    edit: '/app/interactiveedit/edit',
    customreports: '/app/reports/customreports',
    spendintelligence: `/app/spendintelligence/product`,
    pendingapproval: '/app/interactiveedit/notifications/pendingapproval',
    benchmark: `/app/mi/benchmark`,
    supplierscorecard: `/app/mi/partnerscorecard/supplierscorecard`,
    ebenchmarktrends: `/app/mi/ebenchmark-mpn/trends`,
    insights: `/app/reviewopportunities/insights`,
    summary: `/app/mi/competitiveness/summary`,
    trends: `/app/mi/rawmaterial/trends`,
    driver: `app/opportunities/details/driver`,
    spend: `/app/spendanalytics/spend`,
    spendoverview: `/app/spendanalytics/spend/spendoverview`,
    mpncost: `/app/spendanalytics/cost/mpncost`,
    forecastlookup: `/app/spendanalytics/forecast/forecastlookup`,
    details: `/app/srfx/r/details`,
    customerconfiguration: `/app/administration/customerconfiguration`,
    users: `/app/administration/userconfiguration/users`,
    configure: `/app/administration/customerconfiguration\configure`,
    overview: `/app/npi/overview`,
    ppsummary: `/app/npi/ppsummary`,
    adminprogram: `/app/npi/npiadmin/adminprogram`,
    viewbom: `/app/npi/ppbommanagement/bomrevision/viewbom`
  };
  constructor(
    private baseService: BaseService,
    private scopeDataService: ScopeDataService,
    private customerConfigurationService: CustomerConfigurationService,
    private router: Router
  ) {
    this.scope = this.scopeDataService.getFocusValue();
    this.scopeDataService.getFocus().subscribe((val) => {
      this.scope = val;
      this.checkPathAvailability(val);
    });
  }

  public checkPathAvailability(val, urlval?) {
    if (this.router.url === '/login') {
      return;
    }
    const url = urlval ? urlval : this.router.url;
    const lastPath = url.split('?')[0].substring(url.lastIndexOf('/') + 1, url.length) || '';
    const features = this.getfeaturesArray();
    let navigationUrl;
    let leftNavscheckUrl;
    if (!features.includes(lastPath)) {
      navigationUrl = url.substring(0, url.lastIndexOf('/'));
      leftNavscheckUrl = url.substring(1, url.lastIndexOf('/'));
      const homepageCheck = url.split('/')[2];
      if (!this.featuresarray.includes(homepageCheck)) {
        let homepage = '';
        if (
          this.customerConfigurationService.getConfData(this.scope) &&
          this.customerConfigurationService.getConfData(this.scope).features
        ) {
          homepage =
            this.customerConfigurationService.getConfData(this.scope).features
              .homePage || '';
        }
        this.router.navigate([`/app/${homepage}`]);
      } else {
        if (navigationUrl) {
          const target = navigationUrl.split('/')[
            navigationUrl.split('/').length - 1
          ];
          if (this.reDirectionConstants.hasOwnProperty(lastPath)) {
            const redirectionUrl = this.reDirectionConstants[lastPath];
            if (
              this.featuresarray.includes(
                redirectionUrl.split('/')[redirectionUrl.split('/').length - 1]
              )
            ) {
              this.router.navigate([this.reDirectionConstants[lastPath]]);
            } else {
              this.checkPathAvailability(
                val,
                JSON.parse(JSON.stringify(navigationUrl))
              );
            }
          } else {
            this.checkPathAvailability(
              val,
              JSON.parse(JSON.stringify(navigationUrl))
            );
          }
        }
      }
    } else {
      if (urlval) {
        if (this.commonUrls.includes(lastPath)) {
          this.handlingNavigationforCommUrls(url);
        } else {
          if (this.reDirectionConstants.hasOwnProperty(lastPath)) {
            const redirectionUrl = this.reDirectionConstants[lastPath];
            if (
              this.featuresarray.includes(
                redirectionUrl.split('/')[redirectionUrl.split('/').length - 1]
              )
            ) {
              this.router.navigate([this.reDirectionConstants[lastPath]]);
            }
          } else {
            this.router.navigate([urlval]);
          }
        }
      } else {
        if (lastPath === 'benchmark') {
          this.router.navigate([this.reDirectionConstants.benchmark]);
        }
        if (this.commonUrls.includes(lastPath)) {
          this.handlingNavigationforCommUrls(url);
        }
      }
    }
  }
  public handlingNavigationforCommUrls(url) {
    let subUrl = url.substring(0, url.lastIndexOf('/'));
    let path = subUrl.substring(subUrl.lastIndexOf('/') + 1, subUrl.length);
    if (Object.getOwnPropertyNames(this.commonUrlsTabs).includes(path)) {
      if (this.commonUrlsTabs[path].length > 0) {
        this.router.navigate([subUrl + `/${this.commonUrlsTabs[path][0]}`]);
      } else {
        subUrl = subUrl.substring(0, subUrl.lastIndexOf('/'));
        path = subUrl.substring(subUrl.lastIndexOf('/') + 1, subUrl.length);
        if (Object.getOwnPropertyNames(this.commonUrlsTabs).includes(path)) {
          if (this.commonUrlsTabs[path].length > 0) {
            this.router.navigate([subUrl + `/${this.commonUrlsTabs[path][0]}`]);
          }
        }
      }
    }
  }
  public setfeaturesArray(): any {
    if (this.baseService.getCsApp()) {
      this.featuresarray = ['customerconfiguration', 'configure'];
      return this.featuresarray;
    }

    if (
      this.customerConfigurationService.getConfData(this.scope) &&
      this.customerConfigurationService.getConfData(this.scope).mainTabFeatures
    ) {
      this.menuMap = this.customerConfigurationService.getConfData(
        this.scope
      ).mainTabFeatures;
      this.leftNavbarFeatures = this.customerConfigurationService.getConfData(
        this.scope
      ).leftNavbarFeatures;
      // this.leftNavbarFeatures = menuMapConstant;
      this.subNavBarList = this.customerConfigurationService.getConfData(
        this.scope
      ).subTabFeatures;
      this.subTabChildFeatures = this.customerConfigurationService.getConfData(
        this.scope
      ).subTabChildFeatures;
      this.features = this.customerConfigurationService.getConfData(
        this.scope
      ).features;
    }
    this.featuresarray = [];
    this.obj = this.baseService.getUserInformation();
    if (this.obj === undefined) {
      this.obj = JSON.parse(localStorage.getItem(CUSTOMER_CURRENT_USER));
      this.baseService.setUserInformation(this.obj);
    }
    if (this.obj !== undefined) {
      if (!this.obj) {
        return;
      }
      let navListCheck = [];
      const letnavUrls = this.leftNavbarFeatures
        .filter((item) => item.view)
        .map((item) => item.view);
      this.featuresarray = this.featuresarray.concat(letnavUrls);
      navListCheck = this.leftNavbarFeatures.filter(
        (item) => item.view === 'np'
      );
      if (navListCheck.length > 0) {
        this.featuresarray = this.featuresarray.concat([navListCheck[0].view]);
        let np = this.menuMap.filter((item) => item.np instanceof Object);
        if (environment['subscriptionType'] === 'Internal') {
          np[0].np.filter((item) => {
            if (item.view === 'financialandcompetitivedetails') {
              item.view = 'competitivedetails';
              item.title = 'Competitive Details';
            }
          })
        }
        if (np.length > 0) {
          np = np[0].np.map((i) => i.view);
          this.commonUrlsTabs.np = np;
        }
        this.featuresarray = this.featuresarray.concat(np);
      }

      navListCheck = this.leftNavbarFeatures.filter(
        (item) => item.view === 'mi'
      );
      if (navListCheck.length > 0) {
        this.featuresarray = this.featuresarray.concat([navListCheck[0].view]);
        const mi = this.menuMap.filter((item) => item.mi instanceof Object);
        let misubfeature = [];
        if (mi.length > 0) {
          misubfeature = mi[0].mi.map((i) => i.view);
        }
        if (misubfeature.length > 0) {
          this.featuresarray = this.featuresarray.concat(misubfeature);
          if (misubfeature.length > 0) {
            for (const subfeature of misubfeature) {
              let list;
              if (this.subNavBarList) {
                for (const key of Object.keys(this.subNavBarList)) {
                  if (Object.keys(this.subNavBarList[key])[0] === 'Partner Score Card') {
                    this.subNavBarList[key]['partnerscorecard'] = this.subNavBarList[key]['Partner Score Card'];
                    delete this.subNavBarList[key]['Partner Score Card'];
                  }
                  if (Object.keys(this.subNavBarList[key])[0] === 'eBenchmark') {
                    this.subNavBarList[key]['ebenchmark-mpn'] = this.subNavBarList[key]['eBenchmark'];
                    delete this.subNavBarList[key]['eBenchmark'];
                  }
                }
                list = this.subNavBarList.filter(
                  (item) => item[subfeature] instanceof Array
                );
              }
              if (this.scope !== 'Product') {
                this.reDirectionConstants.benchmark = `/app/mi/${misubfeature[0]}`;
              }
              let returnArray = [];
              if (list instanceof Array && list.length > 0) {
                returnArray = list[0][subfeature].map((i) => i.view);
                switch (subfeature) {
                  case 'competitiveness':
                    this.commonUrlsTabs.competitiveness = returnArray;
                    this.reDirectionConstants.summary = `/app/mi/competitiveness/${returnArray[0]}`;
                    break;
                  case 'rawmaterial':
                    this.commonUrlsTabs.rawmaterial = returnArray;
                    this.reDirectionConstants.trends = `/app/mi/rawmaterial/${returnArray[0]}`;
                    break;
                  case 'partnerscorecard':
                    this.reDirectionConstants.supplierscorecard = `/app/mi/partnerscorecard/${returnArray[0]}`;
                    break;
                  case 'eBenchmark':
                    this.reDirectionConstants.ebenchmarktrends = `/app/mi/ebenchmar-mpn/${returnArray[0]}`;
                    break;
                  case 'benchmark':
                    if (this.scope === 'Product') {
                      this.reDirectionConstants.benchmark = `/app/mi/benchmark/${returnArray[0]}`;
                    } else {
                      this.reDirectionConstants.benchmark = `/app/mi/${misubfeature[0]}`;
                    }
                    break;
                }
              }
              if (returnArray.includes('opprisks')) {
                const opprisklist = this.subTabChildFeatures.filter(
                  (item) => item.Analysis instanceof Array
                );
                if (opprisklist.length > 0) {
                  this.commonUrlsTabs.opprisks = opprisklist[0].Analysis.map(
                    (i) => i.view
                  );
                  this.featuresarray = this.featuresarray.concat(
                    opprisklist[0].Analysis.map((i) => i.view)
                  );
                }
              } else {
                this.commonUrlsTabs.opprisks = [];
              }
              this.featuresarray = this.featuresarray.concat(returnArray);
            }
            if (!this.featuresarray.includes('benchmark')) {
              this.reDirectionConstants.benchmark = `/app/mi/${misubfeature[0]}`;
            }
          }
        }
      }
      navListCheck = this.leftNavbarFeatures.filter(
        (item) => item.view === 'reports'
      );
      if (navListCheck.length > 0) {
        this.featuresarray = this.featuresarray.concat([navListCheck[0].view]);
        const reportstabs = this.menuMap.filter(
          (item) => item.Reports instanceof Object
        );
        if (reportstabs instanceof Array && reportstabs.length > 0) {
          const reporturls = reportstabs[0].Reports.map((i) => i.view);
          this.featuresarray = this.featuresarray.concat(reporturls);
          this.reDirectionConstants.customreports = `/app/reports/${reporturls[0]}`;
        }
      }
      const navListCheck1 = this.leftNavbarFeatures.filter(
        (item) => item.view === 'interactiveedit'
      );
      if (navListCheck1.length > 0) {
        this.featuresarray = this.featuresarray.concat([navListCheck1[0].view]);
        const ietabs = this.menuMap.filter(
          (item) => item.ietabs instanceof Object
        );
        let ieurls;
        if (ietabs instanceof Array && ietabs.length) {
          ieurls = ietabs[0].ietabs.map((i) => i.view);
          this.featuresarray = this.featuresarray.concat(ieurls);
          this.reDirectionConstants.edit = `/app/interactiveedit/+${ieurls[0]}`;
        }
        if (this.featuresarray.includes('notifications')) {
          let list;
          if (this.subNavBarList) {
            list = this.subNavBarList.filter(
              (item) => item.notifications instanceof Array
            );
          }
          let returnArray = [];
          if (list instanceof Array && list.length > 0) {
            returnArray = list[0].notifications.map((i) => i.view);
          }
          if (returnArray.length > 0) {
            this.reDirectionConstants.pendingapproval = `/app/interactiveedit/notifications/${returnArray[0]}`;
            this.featuresarray = this.featuresarray.concat(returnArray);
          }
        }
      }
      const navListCheckro = this.leftNavbarFeatures.filter(
        (item) => item.view === 'review-opportunities'
      );
      if (navListCheckro.length > 0) {
        this.featuresarray = this.featuresarray.concat([
          navListCheckro[0].view,
        ]);
        const rotabs = this.menuMap.filter(
          (item) => item['Review Opportunities'] instanceof Object
        );
        let rourls;
        if (rotabs instanceof Array && rotabs.length) {
          rourls = rotabs[0]['Review Opportunities'].map((i) => i.view);
          this.featuresarray = this.featuresarray.concat(rourls);
          this.reDirectionConstants.insights = `/app/review-opportunities/+${rourls[0]}`;
        }
      }
      const navListChecksi = this.leftNavbarFeatures.filter(
        (item) => item.view === 'spendintelligence'
      )
      if (navListChecksi.length > 0) {
        this.featuresarray = this.featuresarray.concat([navListChecksi[0].view])
        const sitabs = this.menuMap.filter(
          (item) => item['Spend Intelligence'] instanceof Object
        )
        let siurls
        if (sitabs instanceof Array && sitabs.length) {
          siurls = sitabs[0]['Spend Intelligence'].map((i) => i.view)
          this.featuresarray = this.featuresarray.concat(siurls)
          this.reDirectionConstants.spendintelligence = `/app/spendintelligence/+${siurls[0]}`
        }
      }
      // }
      // if (this.features[i]['name'] === "internal") {
      const navListCheck2 = this.leftNavbarFeatures.filter(
        (item) => item.view === 'spendanalytics'
      );
      if (navListCheck2.length > 0) {
        this.featuresarray = this.featuresarray.concat([navListCheck2[0].view]);
        // let spendsubfeatures = this.features[i]["subfeatures"];
        const spendanalytics = this.menuMap.filter(
          (item) => item.spendanalytics instanceof Object
        );
        const features = [];
        let instance;
        if (spendanalytics.length > 0) {
          instance = spendanalytics[0].spendanalytics.map((i) => i.view);
        }
        if (instance instanceof Array && instance.length !== 0) {
          this.featuresarray = this.featuresarray.concat(instance);
          for (const subinstance of instance) {
            let list;
            if (this.subNavBarList) {
              list = this.subNavBarList.filter(
                (item) => item[subinstance] instanceof Array
              );
            }
            let returnArray = [];
            if (list instanceof Array && list.length > 0) {
              returnArray = list[0][subinstance].map((i) => i.view);
            }
            switch (subinstance) {
              case 'cost':
                this.reDirectionConstants.mpncost = `/app/spendanalytics/cost/${returnArray[0]}`;
                break;
              case 'spend':
                this.reDirectionConstants.spendoverview = `/app/spendanalytics/spend/${returnArray[0]}`;
                break;
              case 'forecast':
                this.reDirectionConstants.forecastlookup = `/app/spendanalytics/forecast/${returnArray[0]}`;
                break;
            }
            this.featuresarray = this.featuresarray.concat(returnArray);
          }
          if (!this.featuresarray.includes('spend')) {
            this.reDirectionConstants.spendoverview = `/app/spendanalytics/${instance[0]}`;
          }
        }
      }
      const navListCheckrfq = this.leftNavbarFeatures.filter(
        (item) => item.view === 'srfx'
      );
      if (navListCheckrfq.length > 0) {
        this.featuresarray = this.featuresarray.concat([
          navListCheckrfq[0].view,
        ]);
        const rfqtabs = this.menuMap.filter(
          (item) => item.rfq instanceof Object
        );
        let rfqUrls = [];
        if (rfqtabs instanceof Array && rfqtabs.length > 0) {
          rfqUrls = rfqtabs[0].rfq.map((i) => i.view);
        }
        this.reDirectionConstants.details = `/app/srfx/r/${rfqUrls[0]}`;
        this.featuresarray = this.featuresarray.concat(rfqUrls);
        this.featuresarray = this.featuresarray.concat([
          'details',
          'simulation',
          'award',
          'simulation',
          'schedule',
          'send',
          'suppliercollaboration',
          'managevendors',
          'manageusers',
          'r',
          'totalrfxsummary',
          'partquotecompletion',
          'supplierquotecompletion',
          'rfxdetaildashboardopportunities',
          'product',
          'create',
          'preview',
          'detail',
          'pr',
          'riskschedule',
        ]);
      }

      const navListCheckCLI = this.leftNavbarFeatures.filter(
        (item) => item.view === 'contentlabinsights'
      );
      if (navListCheckCLI.length > 0) {
        this.featuresarray = this.featuresarray.concat([
          navListCheckCLI[0].view,
        ]);
      }
      const navListSLD = this.leftNavbarFeatures.filter(
        (item) => item.view === 'loadingestiondata'
      );
      if (navListSLD.length > 0) {
        this.featuresarray = this.featuresarray.concat([navListSLD[0].view]);
      }
      const navListSWB = this.leftNavbarFeatures.filter(
        (item) => item.view === 'scenarioworkbench'
      );
      if (navListSWB.length > 0) {
        this.featuresarray = this.featuresarray.concat([navListSWB[0].view]);
      }
      const navListSR = this.leftNavbarFeatures.filter(
        (item) => item.view === 'supplyrisk'
      );
      if (navListSR.length > 0) {
        this.featuresarray = this.featuresarray.concat([navListSR[0].view]);
      }
      const navListSC = this.leftNavbarFeatures.filter(
        (item) => item.view === 'scenariocomparison'
      );
      if (navListSC.length > 0) {
        this.featuresarray = this.featuresarray.concat([navListSC[0].view]);
      }
      const navListAOPP = this.leftNavbarFeatures.filter(
        (item) => item.view === 'augmentedopportunities'
      );
      if (navListAOPP.length > 0) {
        this.featuresarray = this.featuresarray.concat([navListAOPP[0].view]);
      }
      // if (this.features[i]["name"] === "opportunities") {
      const navListCheckOpp = this.leftNavbarFeatures.filter(
        (item) => item.view === 'opportunities'
      );
      if (navListCheckOpp.length > 0) {
        this.featuresarray = this.featuresarray.concat([
          navListCheckOpp[0].view,
        ]);
        const tabs = this.menuMap.filter(
          (item) => item.Opportunities instanceof Object
        );
        let oppUrls = [];
        if (tabs instanceof Array && tabs.length > 0) {
          oppUrls = tabs[0].Opportunities.map((i) => i.view);
        }
        const oppRisksEnables = this.features.opportunitiesAlerts || false;
        if (oppRisksEnables) {
          this.reDirectionConstants.driver = `app/opportunities/details/${oppUrls[0]}`;
          this.featuresarray = this.featuresarray.concat(oppUrls);
          this.featuresarray = this.featuresarray.concat([
            'details',
            'driver',
            'risk',
          ]);
        }
        // }
      }
      // if (this.features[i]["name"] === "customreports" ||
      //     this.features[i]["name"] === "dashboard" || this.features[i]["name"] === "costlookup") {
      //     let navListCheck = this.leftNavbarFeatures.filter(item => item.view === this.features[i]["name"]);
      if (navListCheck.length > 0) {
        this.featuresarray = this.featuresarray.concat([navListCheck[0].view]);
      }
      // }
      if (this.obj && this.obj.superAdmin) {
        // if (!(this.featuresarray.includes("curatorengine"))) {
        const tabs = this.menuMap.filter(
          (item) => item.curatorengine instanceof Object
        );
        if (tabs instanceof Array && tabs.length > 0) {
          const curatorurls = tabs[0].curatorengine.map((i) => i.view);
          this.commonUrlsTabs.curatorengine = curatorurls;
          this.reDirectionConstants.opprisks = `/app/curatorengine/${curatorurls[0]}`;
          this.featuresarray = this.featuresarray.concat(curatorurls);
          const alertstabs = this.subNavBarList.filter(
            (item) => item.alerts instanceof Object
          );
          if (alertstabs.length > 0) {
            const alertsurls = alertstabs[0].alerts.map((i) => i.view);
            this.featuresarray = this.featuresarray.concat(alertsurls);
            this.reDirectionConstants.basicalerts = `/app/curatorengine/alerts/${alertsurls[0]}`;
          }
          let negotiationlevers = this.subNavBarList.filter(
            (item) => item.negotiationlevers instanceof Object
          );
          if (negotiationlevers.length > 0) {
            const levarsurls = negotiationlevers[0].negotiationlevers.map(
              (i) => i.view
            );
            this.featuresarray = this.featuresarray.concat(levarsurls);
            this.reDirectionConstants.levers = `/app/curatorengine/negotiationlevers/${levarsurls[0]}`;
          }
          negotiationlevers = '';
          this.featuresarray = this.featuresarray.concat(['curatorengine']);
        }

        // }
      }
      // }
    }

    if (
      this.obj &&
      (this.obj.tenantAdmin || this.obj.superAdmin)
    ) {
      // if (!(this.featuresarray.includes("administration"))) {
      if (this.obj.superAdmin) {
        this.featuresarray = this.featuresarray.concat(['configure']);
      }
      const tabs = this.menuMap.filter(
        (item) => item.administration instanceof Object
      );
      if (tabs instanceof Array && tabs.length > 0) {
        const administrationurls = tabs[0].administration.map((i) => i.view);
        this.reDirectionConstants.customerconfiguration = `/app/administration/${administrationurls[0]}`;
        this.featuresarray = this.featuresarray.concat(administrationurls);
        const userConfigurationTabs = this.subNavBarList.filter(
          (item) => item.userconfiguration instanceof Object
        );
        const customerconfigurationTabs = this.subNavBarList.filter(
          (item) => item.customerconfiguration instanceof Object
        );
        const sisenseconfigurationTabs = this.subNavBarList.find(
          (item) => item['PDL & BI Configuration'] instanceof Object
        );
        if (userConfigurationTabs.length > 0) {
          const userConfigurationTabsUrls = userConfigurationTabs[0].userconfiguration.map(
            (i) => i.view
          );
          this.featuresarray = this.featuresarray.concat(
            userConfigurationTabsUrls
          );
          this.reDirectionConstants.users = `/app/administration/userconfiguration/${userConfigurationTabsUrls[0]}`;
        }
        if (customerconfigurationTabs.length > 0) {
          const customerconfigurationTabsUrls = customerconfigurationTabs[0].customerconfiguration.map(
            (i) => i.view
          );
          this.featuresarray = this.featuresarray.concat(
            customerconfigurationTabsUrls
          );

          // this.reDirectionConstants.users = `/app/administration/customerconfiguration/${customerconfigurationTabsUrls[0]}`;
        }
        if (
          sisenseconfigurationTabs instanceof Object &&
          sisenseconfigurationTabs['PDL & BI Configuration'] instanceof Array &&
          sisenseconfigurationTabs['PDL & BI Configuration'].length > 0
        ) {
          const sisenseconfigurationTabsUrls = sisenseconfigurationTabs[
            'PDL & BI Configuration'
          ].map((i) => i.view);
          this.featuresarray = this.featuresarray.concat(
            sisenseconfigurationTabsUrls
          );

          this.reDirectionConstants[
            'buildcube'
          ] = `/app/administration/sisenseconfiguration/${sisenseconfigurationTabsUrls[0]}`;
        }
        this.featuresarray = this.featuresarray.concat(['administration']);
        if (!this.featuresarray.includes('configure')) {
          this.reDirectionConstants.configure = `/app/administration/${administrationurls[0]}`;
        }
      }

      //   }
    }

    const navListCheckNewsEvents = this.leftNavbarFeatures.filter(
      (item) => item.view === 'newsEvents'
    )
    if (navListCheckNewsEvents.length > 0) {
      const nsEsTabs = this.menuMap.filter(
        (item) => item['News and Events'] instanceof Object
      )

      let nsEsTabUrls: any[];

      if (nsEsTabUrls instanceof Array && nsEsTabUrls.length) {

        nsEsTabUrls = nsEsTabs[0]['News and Events'].map((i) => i.view)

        this.featuresarray = this.featuresarray.concat(nsEsTabUrls);
      }
    }

    const navListCheckSRA = this.leftNavbarFeatures.filter(
      (item) => item.view === 'supplyriskassessment'
    )

    if (navListCheckSRA.length > 0) {

      const sraTabs = this.menuMap.filter(
        (item) => item['Proactive Risk Assessment'] instanceof Object
      )

      let sraTabUrls: any[];

      if (sraTabs instanceof Array && sraTabs.length) {

        sraTabUrls = sraTabs[0]['Proactive Risk Assessment'].map((i) => i.view)

        this.featuresarray = this.featuresarray.concat(sraTabUrls);

        const marketTrendsSubTabs = this.subNavBarList.filter(
          (item) => item['Market Trends'] instanceof Object
        )
        if (marketTrendsSubTabs.length > 0) {
          const marketTrendsSubTabsUrls = marketTrendsSubTabs[0]['Market Trends'].map((i) => i.view)
          this.featuresarray = this.featuresarray.concat(marketTrendsSubTabsUrls)
        }

        const riskSummarySubTabs = this.subNavBarList.filter(
          (item) => item['Risk Summary'] instanceof Object
        )
        if (riskSummarySubTabs.length > 0) {
          const riskSummarySubTabsUrls = marketTrendsSubTabs[0]['Risk Summary'].map((i) => i.view)
          this.featuresarray = this.featuresarray.concat(riskSummarySubTabsUrls)
        }
      }

    }

    const navListCheckPdm = this.leftNavbarFeatures.filter(
      (item) => item.view === 'partsdatamanagement'
    )
    if (navListCheckPdm.length > 0) {
      this.featuresarray = this.featuresarray.concat([navListCheckPdm[0].view])
      const pdmTabs = this.menuMap.filter(
        (item) => item['Parts Data Management'] instanceof Object
      )
      let pdmUrls
      if (pdmTabs instanceof Array && pdmTabs.length) {
        pdmUrls = pdmTabs[0]['Parts Data Management'].map((i) => i.view)
        this.featuresarray = this.featuresarray.concat(pdmUrls)
      }
    }

    let npiObject;
    if (
      this.leftNavbarFeatures instanceof Array &&
      this.leftNavbarFeatures.length
    ) {
      npiObject = this.leftNavbarFeatures.find((item) => item.view === 'npi');
    }

    if (npiObject instanceof Object) {
      this.featuresarray = this.featuresarray.concat([npiObject.view]);
      if (
        this.menuMap instanceof Array &&
        this.menuMap.find(
          (item) => item.NPI instanceof Array && item.NPI.length
        )
      ) {
        const npiTabs = this.menuMap.find((item) => item.NPI).NPI;
        const npiTabsUrls = npiTabs.map((item) => item.view);
        this.featuresarray = this.featuresarray.concat(npiTabsUrls);
        if (npiTabsUrls.length) {
          this.reDirectionConstants.overview = `/app/npi/${npiTabsUrls[0]}`;
        }
        if (this.featuresarray.includes('npi')) {
          if (
            this.menuMap instanceof Array &&
            this.menuMap.find(
              (item) =>
                item['NPI Program Profile'] instanceof Array &&
                item['NPI Program Profile'].length
            )
          ) {
            const ppTabs = this.menuMap.find(
              (item) => item['NPI Program Profile'] instanceof Array
            );
            const ppTabsUrls = ppTabs['NPI Program Profile'].map(
              (item) => item.view
            );
            this.featuresarray = this.featuresarray.concat(ppTabsUrls);
            if (ppTabsUrls.length) {
              this.reDirectionConstants.ppsummary = `/app/npi/${ppTabsUrls[0]}`;
            }
          }
          if (
            this.subNavBarList instanceof Array &&
            this.subNavBarList.find(
              (item) =>
                item['BOM Management'] instanceof Array &&
                item['BOM Management'].length
            )
          ) {
            const bmTabs = this.subNavBarList.find(
              (item) => item['BOM Management']
            )['BOM Management'];
            const bmTabsUrls = bmTabs.map((item) => item.view);
            this.featuresarray = this.featuresarray.concat(bmTabsUrls);
            if (bmTabsUrls.length) {
              this.reDirectionConstants.viewbom = `/app/npi/${bmTabsUrls[0]}`;
            }
          }
        }
        if (this.featuresarray.includes('npi')) {
          if (
            this.menuMap instanceof Array &&
            this.menuMap.find(
              (item) =>
                item['NPI Create Program'] instanceof Array &&
                item['NPI Create Program'].length
            )
          ) {
            const cpTabs = this.menuMap.find(
              (item) => item['NPI Create Program']
            )['NPI Create Program'];
            const cpTabsUrls = cpTabs.map((item) => item.view);
            this.featuresarray = this.featuresarray.concat(cpTabsUrls);

            if (cpTabsUrls.includes('adminprogram')) {
              const programTabs = this.subNavBarList.find(
                (item) => item.Program instanceof Array
              );
              if (programTabs && programTabs.Program) {
                this.featuresarray = this.featuresarray.concat(
                  programTabs.Program.map((i) => i.view)
                );
              }
            }
          }
        }
        if (this.featuresarray.includes('npi')) {
          if (
            this.menuMap instanceof Array &&
            this.menuMap.find(
              (item) => item['NPI'] instanceof Array && item['NPI'].length
            )
          ) {
            const cpTabs = this.menuMap.find((item) => item['NPI'])['NPI'];
            const cpTabsUrls = cpTabs.map((item) => item.view);
            this.featuresarray = this.featuresarray.concat(cpTabsUrls);

            if (cpTabsUrls.includes('npipartsmaster')) {
              const programTabs = this.subNavBarList.find(
                (item) => item['NPI Parts Catalog'] instanceof Array
              );
              if (programTabs && programTabs['NPI Parts Catalog']) {
                this.featuresarray = this.featuresarray.concat(
                  programTabs['NPI Parts Catalog'].map((i) => i.view)
                );
              }
            }
          }
        }
      }

      this.featuresarray = this.featuresarray.concat([
        NPI_ROUTING_PATHS.OVERALL_STATUS_EXPANDVIEW,
        NPI_ROUTING_PATHS.NOTIFICATIONS_EXPAND_VIEW,
        NPI_ROUTING_PATHS.PROGRAM_COST_EXPAND_VIEW,
        NPI_ROUTING_PATHS.PROGRAM_RISK_EXPAND_VIEW,
        NPI_ROUTING_PATHS.PP_COST_EXPAND_VIEW,
        NPI_ROUTING_PATHS.BOM_RISK_EXPAND_VIEW,
        NPI_ROUTING_PATHS.PROGRAM_STATUS_EXPAND_VIEW,
        NPI_ROUTING_PATHS.CREATE_PROGRAM,
        NPI_ROUTING_PATHS.CREATE_BOM_REVISION,
        NPI_ROUTING_PATHS.PREVIEW_BOM_REVISION,
        NPI_ROUTING_PATHS.OVERVIEW,
        NPI_ROUTING_PATHS.BOM_REVISION,
      ]);
      // }
    }
    this.reDirectionConstants[
      'administration'
    ] = this.reDirectionConstants.customerconfiguration;
    this.reDirectionConstants['users'] = this.reDirectionConstants.users;
    this.reDirectionConstants[
      'curatorengine'
    ] = this.reDirectionConstants.opprisks;
    this.reDirectionConstants['alerts'] = this.reDirectionConstants.basicalerts;
    this.reDirectionConstants[
      'competitiveness'
    ] = this.reDirectionConstants.summary;
    this.reDirectionConstants['rawmaterial'] = this.reDirectionConstants.trends;
    this.reDirectionConstants[
      'partnerscorecard'
    ] = this.reDirectionConstants.supplierscorecard;
    this.reDirectionConstants[
      'eBenchmark'
    ] = this.reDirectionConstants.ebenchmarktrends;
    this.reDirectionConstants[
      'forecast'
    ] = this.reDirectionConstants.forecastlookup;
    this.reDirectionConstants['cost'] = this.reDirectionConstants.mpncost;
    this.reDirectionConstants[
      'notifications'
    ] = this.reDirectionConstants.pendingapproval;
    this.reDirectionConstants[
      'spend'
    ] = this.reDirectionConstants.spendoverview;
    this.reDirectionConstants[
      'forecast'
    ] = this.reDirectionConstants.forecastlookup;
    this.reDirectionConstants[
      'spendanalytics'
    ] = this.reDirectionConstants.spend;
    this.reDirectionConstants['npi'] = this.reDirectionConstants.overview;
    this.reDirectionConstants[
      'programprofile'
    ] = this.reDirectionConstants.ppsummary;
    this.reDirectionConstants[
      'npiadmin'
    ] = this.reDirectionConstants.adminprogram;
    this.reDirectionConstants['viewbom'] = this.reDirectionConstants.viewbom;
    if (!this.featuresarray.includes('cost')) {
      this.reDirectionConstants.mpncost = this.reDirectionConstants[
        'spendanalytics'
      ];
    }
    if (!this.featuresarray.includes('forecast')) {
      this.reDirectionConstants.forecastlookup = this.reDirectionConstants[
        'spendanalytics'
      ];
    }
    this.featuresarray = this.featuresarray.concat([
      'edit-template',
      'fields',
      'companyprofile',
      'metrics',
      'loginerror',
      'product-usage',
      'training',
      'levadashboard',
      'feed',
      'category',
      'ai-events',
      'partclassification',
      'vendorsites',
      'partsites',
      'locations',
      'training',
      'entityclassification',
      'commodities',
      'entities',
      'lmpns',
      'mpnclassification',
      'content-lab-config',
      'content-lab',
      'baseline-savings',
      'opportunity-attainability',
      'community-insights',
      'leva',
      'ebenchmark-mpn',
      'newscurator',
      'levatraining',
      'industrynews',
      'home',
      'events',
      'pc-update',
      'upload',
      'settings',
      'commoditymanager',
      'npipartsworkbench',
      'bomrepository',
      'mapping',
      'community-insights',
      'dashboards',
      'configuration',
      'bomanalyticssummary',
      'bomanalyticsdrivers',
      'bomsubsettagging',
      'new-landing-page-configuration',
      'ums-success',
      // 'ums-failed',
      // 'create-superadmin',
      'insight-to-action',
      'insight-scenario',
      'insight-action',
      'insight-details',
      'portfolio-summary',
      'alerts',
      'loadaidata',
      'overview',
      'details',
      'dashboard',
      'costexpand',
      'bomriskexpand',
      'defaultlandingpage',
      'my-vendor-sites',
      'community-vendor-sites',
      'vendor-destinations',
      'vendor-sites',
      'vendor-site-map',
      'transformationbom',
      'transformationbomanalyticsdrivers',
      'dashboard-metrics',
      'coverage-profile',
      'configure',
      'events-dashboard',
      'events-list',
      'event-detail',
      'manage-events',
      'save-event'
    ]);
    return this.featuresarray;
  }

  public getfeaturesArray(): any {
    this.featuresarray = this.setfeaturesArray();
    return this.featuresarray;
  }
}
