import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { SocketService } from 'app/modules/common/socket/scoket.service';
import { BaseService } from 'app/modules/common/base.service';

@Component({
  selector: 'app-bot-quesition',
  templateUrl: './app-bot-quesition.component.html',
  styleUrls: ['./app-bot-quesition.component.sass']
})

export class AppBotQuesitionComponent implements OnInit {

  @Input() parameters: any;
  question: string;
  options: Array<string> = [];
  showMessage = false;
  actionMessgae= '';

  optionMessage: Array<any> = ['You have viewed this alert', 'You have ignored it'];
  constructor(
    private router: Router,
    private baseService: BaseService,
    private scopeDataService: ScopeDataService,
    private socketService: SocketService
  ) { }

  ngOnInit() {
    if(this.parameters['groupMessage'].search('<<User Name>>') !== -1){
      this.parameters['groupMessage'] = String(this.parameters['groupMessage']).replace('<<User Name>>', this.baseService.getUserInformation()['fullName']);
      this.question = this.parameters['groupMessage'];
    }

  }

  onOptionClick(target) {

    switch (target.toLowerCase()) {
      case ('yes'):
        this.actionMessgae = this.optionMessage[0];
        if (this.parameters instanceof Object) {
          if (this.parameters['scope'] !== undefined) {
            if (this.parameters['scope'] !== this.scopeDataService.getFocusValue()) {
              this.scopeDataService.setFocus(this.parameters['scope']);
            }
          }
        }


        if (window.location.href.indexOf('opportunities') == -1) {
          this.socketService.setNormalAlertIdFromChatBot(this.parameters['alertsGroup'][0]);
          this.router.navigate(['/app/' + this.parameters.componentUrl]);
        } else {
          this.socketService.setAlertIdFromChatBot(this.parameters['alertsGroup'][0]);
        }
        break;
      case ('no'):
        this.actionMessgae = this.optionMessage[1];
        break;
    }

    this.showMessage = true;
  }
}
