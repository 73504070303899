import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreadCrumbService {
  private mainRoute: any;
  public breadCrumbValue= '';
  staticBreadCrumb = []
  newLabel;
  staticBreadcrumbSub = new Subject<any>();

  getMainRoute() {
    return this.mainRoute
  }

  setMainRoute(mainRoute) {
    this.mainRoute = mainRoute
  }

  getStaticBreadcrumbSub() {
    return this.staticBreadcrumbSub.asObservable();
  }

  onStaticBreadcrumbClick(value) {
    this.staticBreadCrumb.splice(this.staticBreadCrumb.findIndex((x) => x.label === value.label) + 1)
    this.staticBreadcrumbSub.next(value);
  }

  addStaticBreadcrumb(value) {
    this.staticBreadCrumb.push(value)
  }

  removeTopStaticBreadcrumb() {
    this.staticBreadCrumb.pop()
  }

  buildBreadCrumb(route: ActivatedRoute, url = '', breadcrumbs = []) {
    let newBreadcrumbs
    if (!route.firstChild) {
      this.breadCrumbValue = this.newLabel;
    }
    const label = route.routeConfig
      ? route.routeConfig.data
        ? route.routeConfig.data['title']
        : null
      : null
      if(label) {
        this.newLabel = label;
      }
    const path = route.routeConfig ? route.routeConfig.path : ''
    const nextUrl = `${url}${path}/`
    if (label) {
      const breadcrumb = {
        label,
        static: route.routeConfig.data && route.routeConfig.data['static'],
        url: nextUrl,
      }
      if (breadcrumbs.length && breadcrumbs[breadcrumbs.length - 1].url === `${url}${path}`) {
        newBreadcrumbs = [...breadcrumbs]
      } else {
        newBreadcrumbs = [...breadcrumbs, breadcrumb]
      }
      // breadcrumbs = []
    }

    if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs)
    }
    return newBreadcrumbs
  }
}
