import {
  Component,
  Input,
  Output,
  SimpleChanges,
  OnChanges,
  EventEmitter
} from '@angular/core';
import { INCREASE_TYPE } from '../../../shared/constants'

@Component({
  selector: 'deviation',
  templateUrl: './deviation.component.html',
  styleUrls: ['./deviation.component.sass'],
  providers: []
})

export class Deviation implements OnChanges {
  @Input() deviationTypes = [];
  @Input() deviation: any = { type: '', increase: '', formula: '', context: ''}
  @Output() deviationChanged : EventEmitter<Object> = new EventEmitter<Object>();

  defaultDeviationType = 'Select';
  defaultIncreaseType = 'Select';

  increaseType = INCREASE_TYPE;

  ngOnChanges(changes: SimpleChanges) {
    if(this.deviationTypes && this.deviationTypes.length) {
    this.defaultDeviationType = this.selectDefaultDropdownValue(this.deviationTypes, 'type');
    }

    if(this.increaseType && this.increaseType.length) {
    this.defaultIncreaseType = this.selectDefaultDropdownValue(this.increaseType, 'increase');
    }
  }

  deviationChange(key, value) {
    if(key==='type' && value ==='NOTREQUIRED') {
      this.deviation.increase = '';
      this.deviation.formula = '';
      this.defaultIncreaseType = 'Select';
    }
    this.deviation[key] = value;
    this.deviationChanged.emit(true);
  }

  selectDefaultDropdownValue(fromArray, value) {
    const selected = fromArray.find((item)=> {
      return item.refTermActualValue === this.deviation[value];
    });
    return selected || 'Select';
  }

  onBlur() {
    this.deviationChanged.emit(true);
  }
}
