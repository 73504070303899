export interface BlockRecords {
  selectedItems: any[]
  selectAll: boolean
  unSelectedItems?: any[]
}

export class EventsMitigationModel {
  events = {} as BlockRecords
  suppliers = {} as BlockRecords
  vendorSites = {} as BlockRecords
  partsAffected = {} as BlockRecords
  setEvents(events) {
    this.events = events
    this.resetVendors()
    this.resetParts()
  }
  getEvents() {
    return this.events
  }
  setSuppliers(suppliers) {
    this.suppliers = suppliers
    this.resetVendors()
    this.resetParts()
  }
  getSuppliers() {
    return this.suppliers
  }
  setVendors(vendors) {
    this.vendorSites = vendors
    this.resetParts()
  }
  getVendors() {
    return this.vendorSites
  }
  setParts(parts) {
    this.partsAffected = parts
  }
  getParts() {
    return this.partsAffected
  }
  resetAll() {
    this.resetEvents()
    this.resetSuppliers()
    this.resetVendors()
    this.resetParts()
  }
  resetEvents() {
    this.events.selectAll = false
    this.events.selectedItems = []
    this.events.unSelectedItems = []
  }
  resetSuppliers() {
    this.suppliers.selectAll = false
    this.suppliers.selectedItems = []
    this.suppliers.unSelectedItems = []
  }
  resetVendors() {
    this.vendorSites.selectAll = false
    this.vendorSites.selectedItems = []
    this.vendorSites.unSelectedItems = []
  }
  resetParts() {
    this.partsAffected.selectAll = false
    this.partsAffected.selectedItems = []
    this.partsAffected.unSelectedItems = []
  }
}
