import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { FlagEvent, flagEvents, Toggle } from './feature-flag.model';
import { FeatureFlagClientFactory } from './feature-flag-client-factory';


abstract class FeatureFlagService {
  abstract isEnabled(toggleName: string): Observable<boolean>;
}

@Injectable()
class UnleashFeatureFlagService implements FeatureFlagService {
  private unleashClient;
  private readonly togglesSubject = new ReplaySubject<Toggle[]>(1);
  private readonly eventSubject = new Subject<FlagEvent>();

  constructor(
    private readonly clientFactory: FeatureFlagClientFactory,
  ) {
    clientFactory.getClient().subscribe(client => {
        this.unleashClient = client;
        client.on('ready', () => {
          this.updateToggles();
          this.eventSubject.next(flagEvents.Ready);
        });

        client.on('update', () => {
          this.updateToggles();
          this.eventSubject.next(flagEvents.Update);
        });
        this.unleashClient.start();
      },
      error => {
        console.error(`Error building FF client: ${error.message}`);
      }
    );
  }

  isEnabled(toggleName: string): Observable<boolean> {
    return this.togglesSubject.asObservable().pipe(
      map(
        (toggles) => {
          const coincidence = toggles.find((toggle) => toggle.name === toggleName);
          if (coincidence) {
            return coincidence.enabled;
          }
          return false;
        }
      ),
      distinctUntilChanged()
    );
  }

  private updateToggles() {
    this.togglesSubject.next(this.unleashClient.toggles);
  }
}

export { FeatureFlagService, UnleashFeatureFlagService };
