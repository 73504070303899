import { DataStorageService } from 'app/modules/common/data-storage.service';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { HEADER_OBJECT } from 'app/modules/srfq/common/rfx-product-preview/product-rfx.details.constants';
import { STATUS_OBJECT } from 'app/modules/srfq/product/detail-product-rfx/detail-rfx-responses/shared/detail-rfx-response.constants';

@Component({
  selector: 'child-cell',
  template: `<div *ngIf="!disabledBOM" class="dropdown full-width">
      <select
        [disabled]="disableFlag"
        [(ngModel)]="selectedStatus"
        (change)="onChange($event)"
        class="form-control curator-select"
      >
        <option *ngFor="let status of statusDropDownArray[val]">
          {{ status }}
        </option>
      </select>
    </div>
    <div
      style="font-size: 14px;padding-left: 11px;padding-right: 11px"
      *ngIf="disabledBOM"
      class="dropdown full-width"
    >
      {{ statusDropDownArray[val][0] }}
    </div>`,
})
export class GridDropDownComponentGrid implements ICellRendererAngularComp {
  private params: ICellRendererParams | any;
  val: any;
  changedFlag = false;
  statusArray: any;
  statusModifierArray: any;
  selectedStatus: any;
  selectedStatusId: any;
  statusDropDownArray: any = {};
  statusCodeMap = {};
  disableFlag = false;
  uniqueIdentifier: string;
  disabledBOM = false;
  constructor(private gridDataStorageService: DataStorageService) {}

  checkDisabledBOM(): void {
    if (this.params.data.targetGrid === HEADER_OBJECT.BOM) {
      if (
        this.params.rfxOptions &&
        !this.params.rfxOptions.isBOMEditable &&
        this.params.rfxOptions.isBOMViewable
      ) {
        if (this.params.data.CM_ODM_CONTROL.trim().toLowerCase() === 'n') {
          this.disabledBOM = true;
        }
      } else {
        if (
          this.params.rfxOptions &&
          !this.params.rfxOptions.isBOMEditable &&
          !this.params.rfxOptions.isBOMViewable
        ) {
          this.disabledBOM = true;
        }
      }
    }
  }

  agInit(params: ICellRendererParams): void {
    this.val = params.value;
    this.params = params;
    this.uniqueIdentifier =
      this.params.data[HEADER_OBJECT.CMSPKID] + this.params.colDef.field;
    this.populateDropDown(this.params);
    this.checkDropDownDisability(this.params);
    this.checkDropDownModifications(this.params);
    this.checkDisabledBOM();
  }

  populateDropDown(params: ICellRendererParams): any {
    this.gridDataStorageService
      .getDropdownStatusModifier()
      .subscribe((statusIdModifierMap) => {
        if (statusIdModifierMap instanceof Object) {
          this.statusModifierArray =
            statusIdModifierMap[params.colDef.field + params.data.targetGrid];
          if (this.statusModifierArray instanceof Object) {
            const codeValueObject = {};
            for (const statusKey of Object.keys(this.statusModifierArray)) {
              codeValueObject[this.statusModifierArray[statusKey].id] =
                this.statusModifierArray[statusKey].value;
              this.statusDropDownArray[this.statusModifierArray[statusKey].id] =
                this.getDropDownValues(
                  this.statusModifierArray[statusKey].statusModifierArray
                );
            }
            this.statusCodeMap[params.colDef.field] = codeValueObject;
          }
        }
      });
  }

  refresh(): boolean {
    return true;
  }

  onChange(event: Event | any): any {
    const statusValue = event.currentTarget.value;
    if (this.statusCodeMap[this.params.colDef.field] instanceof Object) {
      for (const key of Object.keys(
        this.statusCodeMap[this.params.colDef.field]
      )) {
        // tslint:disable-next-line: radix
        const value: any = parseInt(key);
        if (
          this.statusCodeMap[this.params.colDef.field][value] === statusValue
        ) {
          this.params.data[this.params.colDef.field] = value;
          this.gridDataStorageService.setModifiedNode(this.params);
          const changedDropdownObject: any = {};
          changedDropdownObject.selectedStatusId = this.selectedStatus;
          changedDropdownObject.statusIdValue = this.val;
          this.gridDataStorageService.setDropDownModifierMap(
            this.params.data.targetGrid,
            this.uniqueIdentifier,
            changedDropdownObject
          );
        }
      }
    }
  }

  getDropDownValues(dropDownObject): any {
    const dropDownArray = [];
    if (dropDownObject instanceof Object) {
      for (const i of Object.keys(dropDownObject)) {
        dropDownArray.push(dropDownObject[i].value);
      }
    }
    return dropDownArray;
  }

  getSelectedStatusKey(status): any {
    let statusKey: any = 0;
    for (const k of Object.keys(this.statusCodeMap[this.params.colDef.field])) {
      if (this.statusCodeMap[this.params.colDef.field][k] === status) {
        statusKey = k;
      }
    }
    return statusKey;
  }

  checkDropDownDisability(params: ICellRendererParams): any {
    this.disableFlag = true;
    if (params.data instanceof Object) {
      switch (Number(params.data[HEADER_OBJECT.STATUS])) {
        case STATUS_OBJECT.ACCEPTED:
        case STATUS_OBJECT.REVOKED:
        case STATUS_OBJECT.AWARDED:
          this.disableFlag = true;
          break;
      }
    }
  }

  checkDropDownModifications(params: ICellRendererParams): any {
    const modifiedDropDownObject =
      this.gridDataStorageService.getDropDownModifierMap(
        params.data.targetGrid,
        this.uniqueIdentifier
      );
    if (modifiedDropDownObject !== undefined) {
      this.val = modifiedDropDownObject.statusIdValue;
      this.selectedStatusId = modifiedDropDownObject.selectedStatusId;
      this.selectedStatus = this.selectedStatusId;
      // tslint:disable-next-line: radix
      params.data[params.colDef.field] = parseInt(
        this.getSelectedStatusKey(this.selectedStatus)
      );
    } else {
      this.val = params.value;
      this.selectedStatusId = this.val;
      this.selectedStatus = ((this.statusDropDownArray || [])[this.val] ||
        [])[0];
    }
  }
}
