import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'app-ld-link-cell-renderer',
  templateUrl: './ld-link-cell-renderer.component.html',
  styleUrls: ['./ld-link-cell-renderer.component.sass'],
})
export class LdLinkCellRendererComponent implements ICellRendererAngularComp {
  params: any;
  disableLink = false;
  agInit(params: any): void {
    this.params = params;
    this.disableLink = params.hasOwnProperty('disableLink')
      ? params.disableLink
      : false;
  }

  refresh(): boolean {
    return false;
  }

  onCellClick() {
    if (
      this.params.data &&
      this.params.data[this.params.colDef.field] &&
      !this.disableLink
    ) {
      this.params.context.componentParent.invokeParentMethod(
        this.params.colDef.field,
        this.params.data
      );
    }
  }
}
