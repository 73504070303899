import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

import { InteractiveEditService } from 'app/modules/interactive-edit/shared/interactive-edit.service';
import {
  ToasterService,
  ToasterConfig,
  Toast,
  BodyOutputType
} from 'angular2-toaster';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
// import { environment } from '../../../../environments/environment';

@Component({
  selector: 'hybrid-cell',
  template: `
    <input
      #input
      (keydown)="onKeyDown($event)"
      [(ngModel)]="value"
      style="width: 100%"
    />
  `
})
export class HybridEditorComponent
  implements ICellEditorAngularComp, AfterViewInit {

  constructor(
    private interactiveEditService: InteractiveEditService,
    toasterService: ToasterService
  ) {
    this.toasterService = toasterService;
  }
  private params: any;
  public value: any;
  public oldValue: any;
  public headerName: any;
  private cancelBeforeStart = false;
  toasterService: ToasterService;
  reschedulableToast: Toast = {
    type: 'error',
    body:
      'Please Enter Either <span><em> Non-Reschedulable</em> </span> or Integer > 0',
    bodyOutputType: BodyOutputType.TrustedHtml
  };
  cancellableToast: Toast = {
    type: 'error',
    body:
      'Please Enter Either <span><em> Non-Cancellable</em> </span> or Integer > 0',
    bodyOutputType: BodyOutputType.TrustedHtml
  };
  blankToast: Toast = {
    type: 'error',
    body: 'Blank values not allowed.',
    bodyOutputType: BodyOutputType.TrustedHtml
  };

  lengthToast: Toast = {
    type: 'error',
    body: 'Maximum allowed is 255 Characters.',
    bodyOutputType: BodyOutputType.TrustedHtml
  };

  @ViewChild('input', { read: ViewContainerRef, static: false }) public input;

  public config1: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-full-width',
    timeout: 2000
  });

  agInit(params: any): void {
    this.params = params;
    this.headerName = params.column.colDef.headerName;
    this.oldValue = this.params.value;
    this.value = this.params.value;
    // only start edit if key pressed is a number, not a letter
    this.cancelBeforeStart =
      params.charPress && '1234567890'.indexOf(params.charPress) < 0;
  }

  getValue(): any {
    this.toasterService.clear();
    if (this.headerName === 'Comments') {
      if (this.value === undefined) {
        this.toasterService.pop(this.blankToast);
      } else if (this.value.length > 255) {
        this.toasterService.pop(this.lengthToast);
      } else {
        return this.value;
      }
    } else if (
      (this.headerName === 'Reschedule Terms(Days)' &&
        this.value === 'Non-Reschedulable') ||
      (this.headerName === 'Cancellation Terms(Days)' &&
        this.value === 'Non-Cancellable') ||
      (this.value > 0 && this.value.indexOf('.') < 0 && this.value !== '')
    ) {
      return this.value;
    } else {
      if (this.headerName === 'Reschedule Terms(Days)') {
        this.toasterService.pop(this.reschedulableToast);
      } else {
        this.toasterService.pop(this.cancellableToast);
      }
      return this.oldValue;
    }
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  // will reject the number if it greater than 1,000,000
  // not very practical, but demonstrates the method.
  isCancelAfterEnd(): boolean {
    return this.value < 0;
  }

  onKeyDown(event): void {
    // this.toasterService.clear();
    // let key = event.which || event.keyCode;
    // if (key === 37 || key === 39) {
    //     event.stopPropagation();
    // } else {
    //     if (!this.isKeyPressedNumeric(event)) {
    //         //this.loadEntitiesService.setNaiFound(true);
    //         this.toasterService.pop(this.toast);
    //         if (event.preventDefault) event.preventDefault();
    //     } else {
    //         //this.loadEntitiesService.setNaiFound(false);
    //     }
    // }
  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
    setTimeout(() => {
      this.input.element.nativeElement.focus();
    });
  }

  private getCharCodeFromEvent(event): any {
    event = event || window.event;
    return typeof event.which === 'undefined' ? event.keyCode : event.which;
  }

  private isCharNumeric(charStr): boolean {
    return !!/\d/.test(charStr);
  }

  private isKeyPressedNumeric(event): boolean {
    const charCode = this.getCharCodeFromEvent(event);
    const charStr = event.key ? event.key : String.fromCharCode(charCode);
    if (charStr === 'Backspace') {
      return this.isCharNumeric(8);
    } else if (charStr === 'Delete') {
      return this.isCharNumeric(46);
    } else if (charStr === 'Enter') {
      return this.isCharNumeric(13);
    } else {
      return this.isCharNumeric(charStr);
    }
  }
}
