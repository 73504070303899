import { Injectable } from '@angular/core';
import { ApiServiceProvider } from 'app/api-service';

@Injectable()
export class SearchListService extends ApiServiceProvider {
  getListItems(requestParams) {
    const payload = {
      targetedType: requestParams.targetedType,
      method: requestParams.method,
      payload: requestParams.payload || undefined,
      servicePath: requestParams.servicePath
    }
    return this.post(payload)
  }
}
