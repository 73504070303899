import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges
} from '@angular/core';

@Component({
  selector: 'field-actions',
  templateUrl: './field-actions.component.html',
  styleUrls: ['./field-actions.component.sass'],
  providers: []
})

export class FieldActions {
  @Output() actionType: EventEmitter<Object> = new EventEmitter<Object>();
  parentComp;
  params;
  isDeletable = false;
  agInit(params: any): void {
    this.params = params
    this.parentComp = params.context.componentParent;
    this.isDeletable = params && params.data && params.data.headerType !=='REQUIRED' || false;
    }
  action(type) {
    this.parentComp.action(type, this.params.node.data.custTmplHeaderId);
  }

}
