import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Router } from '@angular/router';
import { DataStorageService } from 'app/modules/common/data-storage.service';

@Component({
    selector: 'child-cell',
    template: `<span *ngIf="val !== '-'">{{val}}</span><span *ngIf="val === '-'" class="text-align-center" style="display:block">-</span>`,
})
export class PlaceHolderComponent {
    public params: any;
    val: any;
    constructor(private router: Router, private dataStorageService: DataStorageService) {
    }
    agInit(params: any): void {
        if (params.value === null) {
            this.val = '-'
        }
        else {
            this.val = params.value
        }
    }

}
