import { SubscriptionService } from './../../common/subscription.service';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  AfterViewChecked,
  OnChanges,
  OnDestroy
} from '@angular/core';

import { SubscriptionLike } from 'rxjs';
import { Location } from '@angular/common';
@Component({
  selector: 'app-icons',
  templateUrl: 'app-icons.component.html',
  providers: []
})
export class AppIconsComponent implements OnInit, AfterViewInit, OnDestroy {
  public appIcons: any;
  @Input() public TitleforGraph= '';
  @Input() public TitleforGrid= '';
  @Input() public graphView= '';
  @Input() public tableView= '';
  @Input() public id;
  @Input() public detailedTabledView= '';
  @Output() public changeView = new EventEmitter<{ id: string; viewName: string }>();
  @Output() public onIconSelect: EventEmitter<any> = new EventEmitter<any>();
  public _activeView;
  @Input() set activeView(val) {
    this._activeView = val;
    this.addActive(
      'btn-' + this._activeView.view + this._activeView.id,
      this._activeView.id + 'accordion'
    );
    // , 'btn' + this._activeView.id
  }

  get activeView() {
    return this._activeView;
  }

  public subscription: SubscriptionLike;
  public showGraphView = false;
  public showTableView = false;
  public showDetailedTabledView = false;
  public selectedValue: any;
  public divId: any;
  constructor(
    private subscriptionService: SubscriptionService,
    private location: Location
  ) {}
  public ngAfterViewInit() {
    if (this.selectedValue !== null || this.selectedValue !== undefined) {
      if(this.id){
        this.addActive('btn-' + this.selectedValue, this.id + 'accordion');
      }else{
        this.addActive('btn-' + this.selectedValue);
      }
    } else {
      document
        .querySelectorAll('.header-right-icons button:first-child')[0]
        .classList.add('active');
    }
  }
  // ngAfterViewChecked(){
  // 	console.log('view checked');
  // }
  public ngOnInit() {
    if (this.graphView === 'graph') {
      this.showGraphView = true;
    }
    if (this.tableView === 'table') {
      this.showTableView = true;
    }
    if (this.detailedTabledView === 'detailTable') {
      this.showDetailedTabledView = true;
    }

    this.subscription = this.subscriptionService
      .getViewNameSubject()
      .subscribe(data => {
        // console.log("partmatching received data" + data);
        if (data !== undefined) {
          if (this.id === undefined) {
            this.addActive('btn-' + data);
            this.selectedValue = data;
          } else {
            this.addActive(
              'btn-' + this._activeView.view + this._activeView.id,
              this._activeView.id + 'accordion'
            );
            this.selectedValue = this._activeView.view + this._activeView.id;
          }
          // if(document.querySelectorAll('.header-right-icons button:first-child')[0].classList !== undefined || document.querySelectorAll('.header-right-icons button:first-child')[0].classList !== null){
          // 	alert('not null');
          // }else{
          // 	alert('null');
          // }
          // let item:any = document.querySelector('.app-icon-items');
          // if(item.innerContent===""){
          // 	console.log('yes');
          // 	this.addActive('btn-'+data);
          // }else{
          // 	console.log('no');
          // }
        }
      });
  }
  // ngOnChanges(){
  // 	console.log('on changes');
  // }

  public viewDetailsFor(viewName: string, id?: string) {
    if (this.graphView === viewName && id) {
      this.graphView = viewName;

      this.changeView.emit({ id, viewName });
      if (this.id === id) {
        this.addActive('btn-' + viewName + id, id + 'accordion');
      }
    } else if (this.graphView === viewName) {
      this.graphView = viewName;

      this.subscriptionService.setViewNameSubject(viewName);
      this.addActive('btn-' + viewName);
    }
    if (this.tableView === viewName && id) {
      this.tableView = viewName;

      this.changeView.emit({ id, viewName });
      if (this.id === id) {
        this.addActive('btn-' + viewName + id, id + 'accordion');
      }
    } else if (this.tableView === viewName) {
      this.tableView = viewName;

      this.subscriptionService.setViewNameSubject(viewName);
      this.addActive('btn-' + viewName);
    }
    if (this.detailedTabledView === viewName && id) {
      this.detailedTabledView = viewName;

      this.changeView.emit({ id, viewName });
      if (this.id === id) {
        this.addActive('btn-' + viewName + id, id + 'accordion');
      }
    } else if (this.detailedTabledView === viewName) {
      this.detailedTabledView = viewName;

      this.subscriptionService.setViewNameSubject(viewName);
      this.addActive('btn-' + viewName);
    }
    this.onIconSelect.emit(viewName);
  }

  public addActive(item, id?) {
    try {
      if (document.getElementById(item) !== undefined) {
        if (id) {
          const elements: NodeListOf<Element> = document
            .getElementById(id)
            .querySelectorAll('button');
          this.removeClass(elements,item)

          document.getElementById(item).classList.add('active');
        } else {
          const elements: NodeListOf<Element> = document.querySelectorAll(
            '.header-right-icons button'
          );
          this.removeClass(elements,item)
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  removeClass(elements,item){
    elements.forEach((element) =>{
      const classes: DOMTokenList = element.classList;
      classes.remove('active');
    });
    if(document.getElementById(item)){ document.getElementById(item).classList.add('active');}if(document.getElementById(item)){ document.getElementById(item).classList.add('active');}
  }
  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
