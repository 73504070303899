import { Component } from '@angular/core';
import { DataStorageService } from 'app/modules/common/data-storage.service';

@Component({
    selector: 'child-cell',
    template: `<a *ngIf="!isDisable" (click) ="onClick('view')">View </a>
    <a *ngIf="isDisable" (click)="OnDisableClick($event)" style="cursor:not-allowed;color: gray;text-decoration: underline;">View</a>
     /<a *ngIf="!isDisable" (click) = "onClick('edit')"> Edit </a>
     <a *ngIf="isDisable" (click)="OnDisableClick($event)" style="cursor:not-allowed;color: gray;text-decoration: underline;">Edit</a>`,
})
export class ViewUserGroupFeatures  {
    public params: any;
    val: any;
    isDisable= false;

constructor(private dataStorageService: DataStorageService){

}
    agInit(params: any): void {
        this.val = params.value;
        this.params = params;
        if (this.params['data']['status'] !== undefined) {
            if (this.params['data']['status'] === 'INACTIVE') {
                this.isDisable = true;
            }else if(this.params['data']['status'] === 'ACTIVE'){
                this.isDisable = false;
            }
        }
    }

    onClick(target: string){
        this.dataStorageService.setViewUserGroupFeatures({data:this.params.data,view:target});
    }
    OnDisableClick(event) {
        event.preventDefault();
    }
}
