import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { LdCustomTag, LdCustomTagTypes } from './custom-tag.service';

@Component({
  selector: 'ld-custom-tag',
  templateUrl: './custom-tag.component.html',
  styleUrls: ['./custom-tag.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class CustomTagComponent implements OnInit {

  @Input() configuration: LdCustomTag[] ;
  @Output() onTagClick: EventEmitter<LdCustomTag> = new EventEmitter<LdCustomTag>();

  userIconType = LdCustomTagTypes.TAG_CONTEXT_USER;

  constructor() { }

  ngOnInit() {
  }


  tagClick(target){
    if(target){
      this.onTagClick.emit(target);
    }
  }

}
