import { Component, OnInit } from '@angular/core';
import { NpiAdminRiskService } from '../../npi-admin/npi-admin-risk/shared/npi-admin-risk.service';
import { SUPPLIER_FINANCIAL_RISK } from '../../npi-admin/npi-admin-risk/constants';
import { DATA_TYPES } from '../../npiconstants';

@Component({
  selector: 'app-supplier-financial-risk',
  templateUrl: './supplier-financial-risk.component.html',
  styleUrls: ['./supplier-financial-risk.component.sass']
})
export class SupplierFinancialRiskComponent implements OnInit {
  public sortedInfoForBomLevel: any = [];
  public sortedInfo: any = [];
  public showData = false;
  public operatorInfo: any = [];
  public displayMessage = 'No Program Configured Yet ';
  public showNotConfiguredMessage = false;
  public minValueErrorPL = false;
  public minValueErrorBL = false;
  constructor(public npiAdminRiskService: NpiAdminRiskService) {}
  public ngOnInit(){
    if (
      this.npiAdminRiskService.keyInfo !== undefined &&
      this.npiAdminRiskService.programKeyInfo !== undefined
    ) {
      if (
        Object.keys(
          this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.SUPPLIER_FINANCIAL_RISK
        ).length === 0
      ) {
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.SUPPLIER_FINANCIAL_RISK = SUPPLIER_FINANCIAL_RISK;
      } else {
        if (
          !this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.hasOwnProperty(
            'SUPPLIER_FINANCIAL_RISK'
          )
        ) {
          this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap[
            'SUPPLIER_FINANCIAL_RISK'
          ] = SUPPLIER_FINANCIAL_RISK;
        }
      }
      this.npiAdminRiskService.keyInfo.result.CONFIGURE_SUPPLIER_FINANCIAL_RISK.widgetHeaders.forEach(
        (element) => {
          if (element.headerInfo.displayOrder !== 4) {
            if (element.headerInfo.field.includes('low')) {
              element.headerInfo.map = 'low';
            } else if (element.headerInfo.field.includes('medium')) {
              element.headerInfo.map = 'medium';
            } else if (element.headerInfo.field.includes('high')) {
              element.headerInfo.map = 'high';
            }
            if (!element.headerInfo.hasOwnProperty('sectionGroup')) {
              this.sortedInfo.push(element.headerInfo);
            } else {
              this.sortedInfoForBomLevel.push(element.headerInfo);
            }
          } else {
            this.operatorInfo.push(element.headerInfo);
          }
        }
      );
      this.sortedInfo.sort(function (a, b) {
        return a.displayOrder - b.displayOrder;
      });
      this.sortedInfoForBomLevel.sort(function (a, b) {
        return a.displayOrder - b.displayOrder;
      });
      // let info = [];
      // info = JSON.parse(JSON.stringify(this.sortedInfo))
      // this.sortedInfoForBomLevel = JSON.parse(JSON.stringify(info.reverse()))
      this.showData = true;
    } else {
      this.showNotConfiguredMessage = true;
    }
  }

  public componentLevelRiskUpdated(event, j, label) {
    this.sortedInfoForBomLevel[j]['minValueError'] = false;
    this.npiAdminRiskService.enableReset = this.npiAdminRiskService.bomRiskUpdated = true;
    this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.SUPPLIER_FINANCIAL_RISK.componentLevelRisk[
      label
    ].min = +event.target.value;
    if (
      this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.SUPPLIER_FINANCIAL_RISK
        .componentLevelRisk[label].max <= event.target.value
    ) {
      this.sortedInfoForBomLevel[j].componentLevelRiskShowError = true;

      if (!this.npiAdminRiskService.errors['supplierFinancialRisk'].includes(j)) {
        this.npiAdminRiskService.errors['supplierFinancialRisk'].push(j);
      }
    } else if (event.target.value <= 0) {
      this.sortedInfoForBomLevel[j]['minValueError'] = true;
      if (!this.npiAdminRiskService.errors['supplierFinancialRisk'].includes(j)) {
        this.npiAdminRiskService.errors['supplierFinancialRisk'].push(j);
      }
    } else {
      this.sortedInfoForBomLevel[j]['minValueError'] = false;
      this.sortedInfoForBomLevel[j].componentLevelRiskShowError = false;
      if (this.npiAdminRiskService.errors['supplierFinancialRisk'].includes(j)) {
        const presenIndex = this.npiAdminRiskService.errors[
          'supplierFinancialRisk'
        ].findIndex((item) => item === j);
        this.npiAdminRiskService.errors['supplierFinancialRisk'].splice(presenIndex, 1);
      }
    }
    this.npiAdminRiskService.saveButtonStatus();
  }

  public componentLevelRiskToUpdated(event, j, label) {
    let nextUpdatedIndex;
    this.npiAdminRiskService.enableReset = this.npiAdminRiskService.bomRiskUpdated = true;
    if (
      j !==
      Object.keys(
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.SUPPLIER_FINANCIAL_RISK
          .componentLevelRisk
      ).length -
        1
    ) {
      nextUpdatedIndex = this.sortedInfoForBomLevel[j + 1].map;

      if (
        typeof nextUpdatedIndex === DATA_TYPES.STRING &&
        nextUpdatedIndex.length > 0
      ) {
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.SUPPLIER_FINANCIAL_RISK.componentLevelRisk[
          nextUpdatedIndex
        ].min = +event.target.value;
      }
    }
    this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.SUPPLIER_FINANCIAL_RISK.componentLevelRisk[
      label
    ].max = +event.target.value;
    if (
      this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.SUPPLIER_FINANCIAL_RISK
        .componentLevelRisk[label].min >= +event.target.value
    ) {
      this.sortedInfoForBomLevel[j].componentLevelRiskShowError = true;
      if (!this.npiAdminRiskService.errors['supplierFinancialRisk'].includes(j)) {
        this.npiAdminRiskService.errors['supplierFinancialRisk'].push(j);
      }
    } else {
      this.sortedInfoForBomLevel[j].componentLevelRiskShowError = false;
      if (this.npiAdminRiskService.errors['supplierFinancialRisk'].includes(j)) {
        const presenIndex = this.npiAdminRiskService.errors[
          'supplierFinancialRisk'
        ].findIndex((item) => item === j);
        this.npiAdminRiskService.errors['supplierFinancialRisk'].splice(presenIndex, 1);
      }
    }

    if (
      typeof nextUpdatedIndex === DATA_TYPES.STRING &&
      nextUpdatedIndex.length > 0
    ) {
      if (
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.SUPPLIER_FINANCIAL_RISK
          .componentLevelRisk[nextUpdatedIndex].min >=
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.SUPPLIER_FINANCIAL_RISK
          .componentLevelRisk[nextUpdatedIndex].max
      ) {
        this.sortedInfoForBomLevel[j + 1].componentLevelRiskShowError = true;
        if (!this.npiAdminRiskService.errors['supplierFinancialRisk'].includes(j + 1)) {
          this.npiAdminRiskService.errors['supplierFinancialRisk'].push(j + 1);
        }
      } else {
        this.sortedInfoForBomLevel[j + 1].componentLevelRiskShowError = false;
        if (this.npiAdminRiskService.errors['supplierFinancialRisk'].includes(j + 1)) {
          const nextIndex = this.npiAdminRiskService.errors[
            'supplierFinancialRisk'
          ].findIndex((item) => item === j + 1);
          if (nextIndex > -1) {
            this.npiAdminRiskService.errors['supplierFinancialRisk'].splice(nextIndex, 1);
          }
        }
      }
    }
    this.npiAdminRiskService.saveButtonStatus();
  }

  public bomLevelRiskUpdated(event, label, j) {
    this.sortedInfo[j]['minValueError'] = false;
    this.npiAdminRiskService.enableReset = this.npiAdminRiskService.bomRiskUpdated = true;
    this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.SUPPLIER_FINANCIAL_RISK.bomLevelRisk[
      label
    ].min = +event.target.value;
    if (
      this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.SUPPLIER_FINANCIAL_RISK
        .bomLevelRisk[label].max <= event.target.value
    ) {
      this.sortedInfo[j].showError = true;
      if (!this.npiAdminRiskService.errors['supplierFinancialBomLevelRisk'].includes(j)) {
        this.npiAdminRiskService.errors['supplierFinancialBomLevelRisk'].push(j);
      }
    } else if (event.target.value <= 0) {
      this.sortedInfo[j]['minValueError'] = true;

      if (!this.npiAdminRiskService.errors['supplierFinancialBomLevelRisk'].includes(j)) {
        this.npiAdminRiskService.errors['supplierFinancialBomLevelRisk'].push(j);
      }
    } else {
      this.sortedInfo[j]['minValueError'] = false;
      this.sortedInfo[j].showError = false;
      if (this.npiAdminRiskService.errors['supplierFinancialBomLevelRisk'].includes(j)) {
        const presenIndex = this.npiAdminRiskService.errors[
          'supplierFinancialBomLevelRisk'
        ].findIndex((item) => item === j);
        this.npiAdminRiskService.errors['supplierFinancialBomLevelRisk'].splice(
          presenIndex,
          1
        );
      }
    }
    this.npiAdminRiskService.saveButtonStatus();
  }

  public bomLevelRiskToUpdated(event, label, j) {
    let nextUpdatedIndex;
    this.npiAdminRiskService.enableReset = this.npiAdminRiskService.bomRiskUpdated = true;
    if (
      j !=
      Object.keys(
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.SUPPLIER_FINANCIAL_RISK
          .bomLevelRisk
      ).length -
        1
    ) {
      nextUpdatedIndex = this.sortedInfo[j + 1].map;
      if (
        typeof nextUpdatedIndex === DATA_TYPES.STRING &&
        nextUpdatedIndex.length > 0
      ) {
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.SUPPLIER_FINANCIAL_RISK.bomLevelRisk[
          nextUpdatedIndex
        ].min = +event.target.value + 1;
      }
    }
    this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.SUPPLIER_FINANCIAL_RISK.bomLevelRisk[
      label
    ].max = +event.target.value;
    if (
      this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.SUPPLIER_FINANCIAL_RISK
        .bomLevelRisk[label].min >= +event.target.value
    ) {
      this.sortedInfo[j].showError = true;
      if (!this.npiAdminRiskService.errors['supplierFinancialBomLevelRisk'].includes(j)) {
        this.npiAdminRiskService.errors['supplierFinancialBomLevelRisk'].push(j);
      }
    } else {
      this.sortedInfo[j].showError = false;
      if (this.npiAdminRiskService.errors['supplierFinancialBomLevelRisk'].includes(j)) {
        const presenIndex = this.npiAdminRiskService.errors[
          'supplierFinancialBomLevelRisk'
        ].findIndex((item) => item === j);
        this.npiAdminRiskService.errors['supplierFinancialBomLevelRisk'].splice(
          presenIndex,
          1
        );
      }
    }

    if (
      typeof nextUpdatedIndex === DATA_TYPES.STRING &&
      nextUpdatedIndex.length > 0
    ) {
      if (
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.SUPPLIER_FINANCIAL_RISK
          .bomLevelRisk[nextUpdatedIndex].min >=
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.SUPPLIER_FINANCIAL_RISK
          .bomLevelRisk[nextUpdatedIndex].max
      ) {
        this.sortedInfo[j + 1].showError = true;
        if (
          !this.npiAdminRiskService.errors['supplierFinancialBomLevelRisk'].includes(j + 1)
        ) {
          this.npiAdminRiskService.errors['supplierFinancialBomLevelRisk'].push(j + 1);
        }
      } else {
        this.sortedInfo[j + 1].showError = false;
        if (
          this.npiAdminRiskService.errors['supplierFinancialBomLevelRisk'].includes(j + 1)
        ) {
          const nextIndex = this.npiAdminRiskService.errors[
            'supplierFinancialBomLevelRisk'
          ].findIndex((item) => item === j + 1);
          if (nextIndex > -1) {
            this.npiAdminRiskService.errors['supplierFinancialBomLevelRisk'].splice(
              nextIndex,
              1
            );
          }
        }
      }
    }

    this.npiAdminRiskService.saveButtonStatus();
  }
  /**
   *
   * @param event
   * Only allow whole nunmber and less than 1000
   */
  public allowWholeNumber(event, index, maxValue?) {
    if (
      (event.charCode >= 48 && event.charCode <= 57) ||
      event.charCode === 46
    ) {
      const val = event.target.value.toString();
      const length = val.slice(val.indexOf('.')).length;
      if (index === 0) {
        return (
          +(event.target.value + event.key) < (maxValue ? 998 : 997) &&
          length < 3
        );
      }
      if (index === 1) {
        return (
          +(event.target.value + event.key) < (maxValue ? 999 : 998) &&
          length < 3
        );
      }
      if (index === 2) {
        return (
          +(event.target.value + event.key) < (maxValue ? 1000 : 999) &&
          length < 3
        );
      }
    }
    return (
      event.charCode >= 48 &&
      event.charCode <= 57 &&
      event.target.value.length < 3
    );
  }
}
