import { Router } from '@angular/router';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { Component, OnInit } from '@angular/core';
import { RequestData } from 'app/modules/common/models/request.model';
import { PayLoad } from 'app/modules/common/models/payload.model';
import { SubscriptionLike } from 'rxjs';
import { SubscriptionService } from 'app/modules/common/subscription.service';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { CuratorOpprisksService } from 'app/modules/curator/curatorOpprisks/shared/curatorOpprisks.service';
import { AutoSearchService } from 'app/modules/core/autosearch/shared/autosearch.service';
import { Subject } from 'rxjs';
import { FormParams } from 'app/modules/common/models/FormParams';
import { CustomerConfigurationService } from '../../../common/customerConfigurationService';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';

@Component({
  selector: 'child-cell',
  templateUrl: 'checkbox.component.html',
  styleUrls: ['./checkbox.component.sass']
})
export class CheckBoxComponent {
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  scope: string;
  autoSearchSelectedValue: any = '';
  autoSearchSelectedValue1: any = '';
  public params: any;
  showDiv: any = 'hide';
  showDiv1: any = 'hide';
  searchTerm$ = new Subject<string>();
  searchTerm$1 = new Subject<string>();
  customDimensionData: any[] = new Array();
  autoSearchCrossIcon: any = 'hide';
  autoSearchSearchIcon: any = 'show';
  customDimensionData1: any[] = new Array();
  autoSearchCrossIcon1: any = 'hide';
  autoSearchSearchIcon1: any = 'show';
  opportunityalerttext: string;
  rm = false;
  isopportunityAlertvalue = false;
  selectcheckbox = false;
  opportunityalertvalue = false;
  isalertval = false;
  alertfor = false;
  alertvalue: string;
  isdriverval = false;
  driver = false;
  drivervalue: string;
  istimeval = false;
  isdriverdetailval = false;
  timeselection = false;
  driverdetails = false;
  timevalue: string;
  Scope: string;
  driverdetailvalue: string;

  targetedId = 10;
  isSelected = false;
  private autoSearchSubscription: SubscriptionLike;
  private scopeSubscription: SubscriptionLike;
  private timeSubscription: SubscriptionLike;
  private driverSubscription: SubscriptionLike;
  request: RequestData;
  request1: RequestData;
  idsArry = [];
  text = 'next4q';

  roytimePeriod: Array<any>;
  timeperiodjson: Array<any>;
  timePeriod;
  commodityAlerts: Array<any>;
  alertArray;
  Commoditydrivers: Array<any>;
  ProductDrivers: Array<any>;
  uploadInputMap: any;
  titleArray;
  constructor(
    private customerConfigurationService: CustomerConfigurationService,
    private searchService: AutoSearchService,
    private scopeDataService: ScopeDataService,
    private dataStorageService: DataStorageService,
    private router: Router,
    private subscriptionService: SubscriptionService
  ) {}
  ngOnInit(params) {
    const payload = new PayLoad();
    payload.dimension = ['RAW_MATERIAL'];
    const payloadRM = new PayLoad();
    const formParams = new FormParams();
    this.request1 = new RequestData();
    this.request1.targetedType = 'MIService';
    this.request1.method = 'POST';
    this.request1.servicePath = '/rm/getRMList';
    this.request1.placeHolder = 'Enter value';
    this.request1.type = 'autodimension';
    this.request1.title = 'RM';
    this.request1.searchInputLimit = 10;
    this.request1.formParams = formParams;
    this.request1.payload = payloadRM;
  }
  agInit(params: any): void {
    this.scope = this.scopeDataService.getFocusValue();
    if (this.customerConfigurationService.getConfData(this.scope)) {
      let curatorViews;
      if (
        this.customerConfigurationService.getConfData(this.scope).viewByData
      ) {
        curatorViews = this.customerConfigurationService
          .getConfData(this.scope)
          .viewByData.filter(item => item.curator instanceof Object);
      }
      if (curatorViews instanceof Array && curatorViews.length > 0) {
        this.roytimePeriod = JSON.parse(
          JSON.stringify(curatorViews[0]['curator']['roytimeperiod'])
        );
        this.timeperiodjson = JSON.parse(
          JSON.stringify(curatorViews[0]['curator']['fourquartertimeperiod'])
        );
        this.timePeriod = this.roytimePeriod;
        this.commodityAlerts = JSON.parse(
          JSON.stringify(curatorViews[0]['curator']['oppRiskscommodityalerts'])
        );
        this.alertArray = this.commodityAlerts;
        this.Commoditydrivers = JSON.parse(
          JSON.stringify(curatorViews[0]['curator']['commoditydrivers'])
        );
        this.ProductDrivers = JSON.parse(
          JSON.stringify(curatorViews[0]['curator']['productdrivers'])
        );
        this.titleArray = this.Commoditydrivers;
      }
    }
    this.subscriptionService.getrmautosearch().subscribe(val => {
      if (val !== undefined) {
        if (params.data.alertAndAlertType === val.id) { this.rm = val.val; }
        if (
          params.data.alertId <= 0 &&
          val.val &&
          params.data.alertAndAlertType === val.id
        ) {
          this.rm = true;
          this.isdriverdetailval = true;
          this.driverdetailvalue = params.data.driverDetails;
        }
      } else {
        this.rm = false;
      }
    });
    this.subscriptionService.getClearText().subscribe(val => {
      if (val !== undefined) {
        if (params.data.alertAndAlertType === val.title) {
          if (val.val === 'Enter Value') {
            this.autoSearchSelectedValue = '';
            // this.request.placeHolder="Enter Value";
          } else {
            this.autoSearchSelectedValue = val.val;
          }
        }
      } else {
        this.autoSearchSelectedValue = '';
        // this.request.placeHolder="Enter Value";
      }
    });
    if (params.data.alertId <= 0 && params.colDef.headerName === 'Value') {
      let dimValue = '';
      for (let i = 0; i < this.commodityAlerts.length; i++) {
        if (
          params.data.alertFor.toLowerCase() ===
          this.commodityAlerts[i].text.toLowerCase()
        ) {
          dimValue = this.commodityAlerts[i].dbKey;
        }
      }
      // console.log("dim value:"+dimValue)
      this.autosearchReaquest(dimValue, params.data.alertAndAlertType);
    }
    if (params.colDef.headerName === 'Value') {
      if (params.data.isnew) {
        this.autosearchReaquest(
          'SUB_COMMODITY_NAME',
          params.data.alertAndAlertType
        );
        // this.autosearchReaquest1('RAW_MATERIAL', params.data.alertAndAlertType);
      }
    }
    this.subscriptionService.getPMRequestData().subscribe(value => {
      if (value !== '') {
        if (params.data.alertAndAlertType === value.title) {
          this.request = value.val;
        }
      }
    });
    this.timeSubscription = this.subscriptionService
      .gettimetext()
      .subscribe(val => {
        if (val !== undefined) {
          if (val.id === params.data.alertAndAlertType) {
            if (val.text === 'roy') {
              this.timePeriod = this.roytimePeriod;
            } else {
              this.timePeriod = this.timeperiodjson;
            }
          }
        }
      });
    this.driverSubscription = this.subscriptionService
      .getDriverText()
      .subscribe(val => {
        if (val !== undefined) {
          if (val.id === params.data.alertAndAlertType) {
            if (val.val === 'product') {
              this.titleArray = this.ProductDrivers;
              let attributes = [];
              if (this.subscriptionService.saveArray.length > 0) {
                attributes = Object.keys(this.subscriptionService.saveArray[0]);
              }
              let keys = [];
              if (this.subscriptionService.saveArray[0] !== undefined) {
                if (
                  this.subscriptionService.saveArray[0][
                    params.data.alertAndAlertType
                  ] !== undefined
                ) {
                  keys = Object.keys(
                    this.subscriptionService.saveArray[0][
                      params.data.alertAndAlertType
                    ]
                  );
                }
              }
              if (attributes instanceof Object) {
                if (attributes.includes(params.data.alertAndAlertType)) {
                  if (keys.includes('driver')) {
                    const driver = this.subscriptionService.saveArray[0][
                      params.data.alertAndAlertType
                    ]['driver'];
                    this.setDriver(driver);
                  }
                }
              }
            } else {
              this.titleArray = this.Commoditydrivers;
              let attributes = [];
              if (this.subscriptionService.saveArray.length > 0) {
                attributes = Object.keys(this.subscriptionService.saveArray[0]);
              }
              let keys = [];
              if (this.subscriptionService.saveArray[0] !== undefined) {
                if (
                  this.subscriptionService.saveArray[0][
                    params.data.alertAndAlertType
                  ] !== undefined
                ) {
                  keys = Object.keys(
                    this.subscriptionService.saveArray[0][
                      params.data.alertAndAlertType
                    ]
                  );
                }
              }
              if (attributes instanceof Object) {
                if (attributes.includes(params.data.alertAndAlertType)) {
                  if (keys.includes('driver')) {
                    const driver = this.subscriptionService.saveArray[0][
                      params.data.alertAndAlertType
                    ]['driver'];
                    this.setDriver(driver);
                  }
                }
              }
            }
          }
        }
      });

    let x = [];
    if (this.subscriptionService.saveArray.length > 0) {
      x = Object.keys(this.subscriptionService.saveArray[0]);
    }

    // console.log(Object.keys(this.subscriptionService.saveArray[0][params.data.alertAndAlertType]))
    if (x.includes(params.data.alertAndAlertType)) {
      const keys = Object.keys(
        this.subscriptionService.saveArray[0][params.data.alertAndAlertType]
      );

      if (keys.includes('alertforr')) {
        const alertforr = this.subscriptionService.saveArray[0][
          params.data.alertAndAlertType
        ]['alertforr'];
        this.setAlertfor(alertforr, params.data.alertAndAlertType);
      }
      if (keys.includes('driver')) {
        const driver = this.subscriptionService.saveArray[0][
          params.data.alertAndAlertType
        ]['driver'];
        this.setDriver(driver);
      }
      if (keys.includes('timePeriod')) {
        const Time = this.subscriptionService.saveArray[0][
          params.data.alertAndAlertType
        ]['timePeriod'];
        this.setTime(Time);
      }
      if (keys.includes('value')) {
        const val = this.subscriptionService.saveArray[0][
          params.data.alertAndAlertType
        ]['value'];
        this.subscriptionService.setclearText({
          val,
          title: params.data.alertAndAlertType
        });
      }
      if (keys.includes('driverDetails')) {
        const val = this.subscriptionService.saveArray[0][
          params.data.alertAndAlertType
        ]['driverDetails'];
        this.autoSearchSelectedValue1 = val;
        // this.subscriptionService.setclearText({ "val": val, "title": params.data.alertAndAlertType })
      }
    }
    this.params = params;
    if (this.params.data.alertId <= 0) {
      this.autoSearchSelectedValue = this.params.data.value;
      if (this.params.data.driverDetails !== 'NA') {
        this.autoSearchSelectedValue1 = this.params.data.driverDetails;
      }
    }
    this.isSelected = this.params.data.checkbox;
    this.opportunityalerttext = this.params.data.value;
    this.alertvalue = this.params.data.alertFor;
    this.drivervalue = this.params.data.driver;
    this.alertfor = this.params.data.alertFor;
    this.timevalue = this.params.data.timePeriod;
    this.driverdetailvalue = this.params.data.driverDetails;
    if (params.colDef.headerName === '') {
      this.selectcheckbox = true;
      this.opportunityalertvalue = false;
      this.alertfor = false;
      this.driver = false;
      this.timeselection = false;
    }

    if (params.colDef.headerName === 'Value') {
      this.selectcheckbox = false;
      this.opportunityalertvalue = true;
      this.alertfor = false;
      this.driver = false;
      this.timeselection = false;
      if (this.opportunityalerttext !== '') {
        // this.isopportunityAlertvalue=false;
        if (this.params.data.valueInvalid === true) {
          this.isopportunityAlertvalue = true;
        } else {
          this.isopportunityAlertvalue = false;
        }
      } else {
        this.isopportunityAlertvalue = true;
      }
    }
    if (params.colDef.headerName === 'Alert For') {
      this.selectcheckbox = false;
      this.opportunityalertvalue = false;
      this.alertfor = true;
      this.driver = false;
      this.timeselection = false;
      if (this.alertvalue !== '') {
        // this.isalertval = false;
        if (this.params.data.alerForInvalid === true) {
          this.isalertval = true;
          let identifyFlag = false;
          for (let i = 0; i < this.alertArray.length; i++) {
            if (
              this.alertArray[i]['text'].toLowerCase() ===
              this.params.data.alertFor.toLowerCase()
            ) {
              this.alertArray[i]['isdefault'] = true;
              identifyFlag = true;
              break;
            }
          }

          if (!identifyFlag) {
            // console.log(this.alertArray)
            this.alertArray.push({
              text: this.params.data.alertFor,
              value: this.params.data.alertFor,
              isdefault: ''
            });
            this.alertArray[this.alertArray.length - 1]['isdefault'] = true;
            this.alertArray[this.alertArray.length - 1]['disabled'] = true;
          }
        } else {
          this.isalertval = false;
        }
      } else {
        this.isalertval = true;
      }
    }
    if (params.colDef.headerName === 'Driver') {
      this.alertfor = false;
      this.selectcheckbox = false;
      this.opportunityalertvalue = false;
      this.driver = true;
      this.timeselection = false;
      if (this.drivervalue !== '') {
        // this.isdriverval = false;
        if (this.params.data.driverInvalid === true) {
          this.isdriverval = true;
          let identifyFlag = false;
          for (let i = 0; i < this.titleArray.length; i++) {
            if (
              this.titleArray[i]['view'].toLowerCase() ===
              this.params.data.driver.toLowerCase()
            ) {
              this.titleArray[i]['selected'] = true;
              identifyFlag = true;
              break;
            }
          }
          if (!identifyFlag) {
            // console.log(this.titleArray)
            this.titleArray.push({
              text: this.params.data.driver,
              view: this.params.data.driver,
              selected: ''
            });
            this.titleArray[this.titleArray.length - 1]['selected'] = true;
            this.titleArray[this.titleArray.length - 1]['disabled'] = true;
          }
        } else {
          this.isdriverval = false;
        }
      } else {
        this.isdriverval = true;
      }
    }
    if (params.colDef.headerName === 'Time Period') {
      this.alertfor = false;
      this.selectcheckbox = false;
      this.opportunityalertvalue = false;
      this.driver = false;
      this.timeselection = true;
      if (this.timevalue !== '') {
        // this.istimeval = false;
        if (this.params.data.timePeriodInvalid === true) {
          this.istimeval = true;
          let identifyFlag = false;
          // console.log(this.timePeriod)
          // console.log(this.params.data.timePeriod)
          for (let i = 0; i < this.timePeriod.length; i++) {
            if (
              this.timePeriod[i]['view'].toLowerCase() ===
              this.params.data.timePeriod.toLowerCase()
            ) {
              this.timePeriod[i]['selected'] = true;
              identifyFlag = true;
              break;
            }
          }
          if (!identifyFlag) {
            // console.log(this.timePeriod)
            this.timePeriod.push({
              text: this.params.data.timePeriod,
              view: this.params.data.timePeriod,
              selected: ''
            });
            this.timePeriod[this.timePeriod.length - 1]['selected'] = true;
            this.timePeriod[this.timePeriod.length - 1]['disabled'] = true;
          }
        } else {
          this.istimeval = false;
        }
      } else {
        this.istimeval = true;
      }
    }
    if (params.colDef.headerName === 'Driver Details') {
      this.alertfor = false;
      this.selectcheckbox = false;
      this.opportunityalertvalue = false;
      this.driver = false;
      this.driverdetails = true;
      this.timeselection = false;
      if (this.driverdetailvalue !== '') {
        this.isdriverdetailval = false;
        if (this.params.data.driverDetailsInvalid === true) {
          this.isdriverdetailval = true;
        } else {
          this.isdriverdetailval = false;
        }
      } else {
        this.isdriverdetailval = true;
      }
    }
  }

  selectedcheckbox(eve) {
    this.isSelected = !this.isSelected;
    if (this.isSelected) {
      this.subscriptionService.deleteArray.push(
        this.params.data.alertAndAlertType
      );
      this.subscriptionService.pushtochatbotArray.push(
        this.params.data.alertAndAlertType
      );
    } else {
      if (
        this.subscriptionService.deleteArray.includes(
          this.params.data.alertAndAlertType
        )
      ) {
        this.subscriptionService.deleteArray.splice(
          this.subscriptionService.deleteArray.indexOf(
            this.params.data.alertAndAlertType
          ),
          1
        );
      }
      if (
        this.subscriptionService.pushtochatbotArray.includes(
          this.params.data.alertAndAlertType
        )
      ) {
        this.subscriptionService.pushtochatbotArray.splice(
          this.subscriptionService.pushtochatbotArray.indexOf(
            this.params.data.alertAndAlertType
          ),
          1
        );
      }
    }

    if (this.subscriptionService.deleteArray.length > 0) {
      this.subscriptionService.setdisableDelete(true);
    } else {
      this.subscriptionService.setdisableDelete(false);
      this.subscriptionService.deleteArray = [];
    }
    if (this.subscriptionService.pushtochatbotArray.length > 0) {
      const val = this.checkpublishenabling();
      this.subscriptionService.setenablePublish(val);
    } else {
      this.subscriptionService.setenablePublish(true);
    }
  }
  ondriverChange(val) {
    // console.log(val)
    const errArr = this.dataStorageService.getErrorRecords();
    if (
      errArr !== undefined &&
      this.params.data.alertId !== undefined &&
      Object.keys(errArr).length > 0
    ) {
      errArr[this.params.data.alertId]['driver'] = val;
      this.dataStorageService.setErrorRecords(errArr);
    }
    if (val === 'Forecast Change' || val === 'Raw Material') {
      this.subscriptionService.settimetext({
        text: 'next4q',
        id: this.params.data.alertAndAlertType
      });
    } else {
      this.subscriptionService.settimetext({
        text: 'roy',
        id: this.params.data.alertAndAlertType
      });
    }
    if (val === 'Raw Material') {
      this.subscriptionService.setrmautosearch({
        val: true,
        id: this.params.data.alertAndAlertType
      });
    } else {
      this.subscriptionService.setrmautosearch({
        val: false,
        id: this.params.data.alertAndAlertType
      });
    }
    let x = [];
    const y = this.subscriptionService.newRowsArray;
    for (const i of this.subscriptionService.saveArray) {
      x = x.concat(Object.keys(i));
    }
    if (y.includes(this.params.data.alertAndAlertType)) {
      if (!x.includes(this.params.data.alertAndAlertType)) {
        this.subscriptionService.saveobj[this.params.data.alertAndAlertType] = {
          alertId: null,
          driver: val
        };
        if (this.subscriptionService.saveArray.length === 0) {
          this.subscriptionService.saveArray.push(
            this.subscriptionService.saveobj
          );
        } else {
          Object.assign(
            this.subscriptionService.saveArray[0],
            this.subscriptionService.saveobj
          );
        }
      } else {
        this.subscriptionService.saveArray[0][
          this.params.data.alertAndAlertType
        ]['driver'] = val;
      }
    }
  }
  ontimeChange(val) {
    let x = [];
    const errArr = this.dataStorageService.getErrorRecords();
    // console.log(errArr)
    // console.log(this.params.data.alertId)
    if (
      errArr !== undefined &&
      this.params.data.alertId !== undefined &&
      Object.keys(errArr).length > 0
    ) {
      errArr[this.params.data.alertId]['timePeriod'] = val;
      // console.log(errArr)
      this.dataStorageService.setErrorRecords(errArr);
    }

    if (this.subscriptionService.saveArray.length > 0) {
      x = Object.keys(this.subscriptionService.saveArray[0]);
    }
    const y = this.subscriptionService.newRowsArray;
    if (y.includes(this.params.data.alertAndAlertType)) {
      if (!x.includes(this.params.data.alertAndAlertType)) {
        this.subscriptionService.saveobj[this.params.data.alertAndAlertType] = {
          alertId: null,
          timePeriod: val
        };
        if (this.subscriptionService.saveArray.length === 0) {
          this.subscriptionService.saveArray.push(
            this.subscriptionService.saveobj
          );
        } else {
          Object.assign(
            this.subscriptionService.saveArray[0],
            this.subscriptionService.saveobj
          );
        }
      } else {
        this.subscriptionService.saveArray[0][
          this.params.data.alertAndAlertType
        ]['timePeriod'] = val;
      }
    }
  }
  onChange(val) {
    // console.log(val)
    let dimValue = '';
    for (let i = 0; i < this.commodityAlerts.length; i++) {
      if (val.toLowerCase() === this.commodityAlerts[i].dbKey.toLowerCase()) {
        dimValue = this.commodityAlerts[i].dbKey;
      }
    }
    const errArr = this.dataStorageService.getErrorRecords();

    // console.log(errArr)
    if (
      errArr !== undefined &&
      this.params.data.alertId !== undefined &&
      Object.keys(errArr).length > 0
    ) {
      errArr[this.params.data.alertId]['alertFor'] = dimValue;
      this.dataStorageService.setErrorRecords(errArr);
    }
    this.autosearchReaquest(val, this.params.data.alertAndAlertType);

    this.subscriptionService.setclearText({
      val: 'Enter Value',
      title: this.params.data.alertAndAlertType
    });

    if (
      val === 'PRODUCT_NAME' ||
      val === 'PRODUCT_FAMILY_NAME' ||
      val === 'CM_ODM_NAME'
    ) {
      this.subscriptionService.setDriverText({
        val: 'product',
        id: this.params.data.alertAndAlertType
      });
    } else {
      this.subscriptionService.setDriverText({
        val: 'commodity',
        id: this.params.data.alertAndAlertType
      });
    }
    let x = [];
    if (this.subscriptionService.saveArray.length > 0) {
      x = Object.keys(this.subscriptionService.saveArray[0]);
    }
    const y = this.subscriptionService.newRowsArray;
    if (y.includes(this.params.data.alertAndAlertType)) {
      this.subscriptionService.setenablesave(false);
      if (!x.includes(this.params.data.alertAndAlertType)) {
        this.subscriptionService.saveobj[this.params.data.alertAndAlertType] = {
          alertId: null,
          alertforr: val
        };
        if (this.subscriptionService.saveArray.length === 0) {
          this.subscriptionService.saveArray.push(
            this.subscriptionService.saveobj
          );
        } else {
          Object.assign(
            this.subscriptionService.saveArray[0],
            this.subscriptionService.saveobj
          );
        }
      } else {
        this.subscriptionService.saveArray[0][
          this.params.data.alertAndAlertType
        ]['alertforr'] = val;
      }
      // console.log(val)
      this.autosearchReaquest(val, this.params.data.alertAndAlertType);
      // console.log(  this.subscriptionService.saveArray);
    }
  }
  autosearchReaquest(name, title) {
    const payload = new PayLoad();
    payload.dimension = [name];
    payload.isWildCardSearch = true;
    this.request = new RequestData();
    this.request.targetedType = 'ELS';
    this.request.method = 'POST';
    this.request.title = 'curator';
    this.request.servicePath = 'MultiValueSearch';
    this.request.placeHolder = 'Enter Value';
    this.request.type = 'autodimension';
    this.request.typeOfSelectSingle = true;
    this.request.title = title;
    this.request.unmatchedSearch = true;
    this.request.payload = payload;
    this.subscriptionService.setPMRequestData({
      val: this.request,
      title
    });
  }

  setDriver(val) {
    for (const i of this.titleArray) {
      if (i['view'] === val) {
        i['isdefault'] = true;
        if (val === 'Forecast Change' || val === 'Raw Material') {
          this.timePeriod = this.timeperiodjson;
        }
        if (val === 'Raw Material') {
          this.rm = true;
        }
      }
    }
  }
  setTime(val) {
    for (const i of this.timePeriod) {
      if (i['view'] === val) {
        i['isdefault'] = true;
      }
    }
  }
  setAlertfor(val, title) {
    for (const i of this.alertArray) {
      if (i['dbKey'] === val) {
        i['isdefault'] = true;
        this.autosearchReaquest(val, title);
        if (
          val === 'PRODUCT_NAME' ||
          val === 'PRODUCT_FAMILY_NAME' ||
          val === 'CM_ODM_NAME'
        ) {
          this.titleArray = this.ProductDrivers;
        } else {
          this.titleArray = this.Commoditydrivers;
        }
      }
    }
  }
  onAutoSearchKey(event: any) {
    this.autoSearchSelectedValue = event.target.value;
    if (this.autoSearchSelectedValue === '') {
      this.showDiv = 'hide';
      this.autoSearchCrossIcon = 'hide';
      this.autoSearchSearchIcon = 'show';
      this.customDimensionData.length = 0;
      return;
    } else {
      this.showDiv = 'show';
      this.autoSearchSearchIcon = 'hide';
      this.autoSearchCrossIcon = 'show';
    }
    this.searchTerm$.next(event.target.value);
    this.searchService
      .searchMultiELS(this.searchTerm$, this.request)
      .subscribe(results => {
        if (!results['result']) {
          return false;
        }
        if (this.request.targetedType === 'ELS') {
          this.customDimensionData = this.searchService.onConvertArrayObjectKeysWithDimension(
            results['result'][0].hits,
            this.request.payload.dimension
          );
        } else if (this.request.targetedType === 'MANUAL') {
        } else {
          this.customDimensionData.length = 0;
        }
      });
  }
  clearAutoSearchText(event: any) {
    this.subscriptionService.setenablesave(false);
    this.autoSearchSelectedValue = '';
    this.showDiv = 'hide';
    this.autoSearchCrossIcon = 'hide';
    this.autoSearchSearchIcon = 'show';
  }
  clearAutoSearchText1(event: any) {
    this.autoSearchSelectedValue1 = '';
    this.showDiv1 = 'hide';
    this.autoSearchCrossIcon1 = 'hide';
    this.autoSearchSearchIcon1 = 'show';
  }
  onAutoDimensionSelect(key: any, keyValue: any) {
    this.autoSearchSelectedValue = keyValue;
    const errArr = this.dataStorageService.getErrorRecords();
    // console.log(this.params.data.alertId)
    if (this.params.data.alertId !== undefined) {
      errArr[this.params.data.alertId]['value'] = this.autoSearchSelectedValue;
      // for(let j=0;j<errArr.length;j++){
      //     if(errArr[j][])
      // }
      this.dataStorageService.setErrorRecords(errArr);
      // console.log(errArr)
    }
    this.showDiv = 'hide';
    const val = {
      dimension: '',
      value: ''
    };
    val.dimension = key;
    val.value = keyValue;
    let x = [];
    const y = this.subscriptionService.newRowsArray;
    if (this.subscriptionService.saveArray.length > 0) {
      x = Object.keys(this.subscriptionService.saveArray[0]);
    }
    if (y.includes(this.params.data.alertAndAlertType)) {
      if (!x.includes(this.params.data.alertAndAlertType)) {
        this.subscriptionService.saveobj[this.params.data.alertAndAlertType] = {
          alertId: null,
          alertFor: val.dimension,
          value: val.value,
          driver: 'Part match',
          timePeriod: 'Next Quarter'
        };
        if (this.subscriptionService.saveArray.length === 0) {
          this.subscriptionService.saveArray.push(
            this.subscriptionService.saveobj
          );
        } else {
          Object.assign(
            this.subscriptionService.saveArray[0],
            this.subscriptionService.saveobj
          );
        }
      } else {
        this.subscriptionService.saveArray[0][
          this.params.data.alertAndAlertType
        ]['alertFor'] = val.dimension;
        this.subscriptionService.saveArray[0][
          this.params.data.alertAndAlertType
        ]['value'] = val.value;
        const x = Object.keys(
          this.subscriptionService.saveArray[0][
            this.params.data.alertAndAlertType
          ]
        );
        if (!x.includes('driver')) {
          this.subscriptionService.saveArray[0][
            this.params.data.alertAndAlertType
          ]['driver'] = 'Part Match';
        }
        if (!x.includes('timePeriod')) {
          this.subscriptionService.saveArray[0][
            this.params.data.alertAndAlertType
          ]['timePeriod'] = 'Next Quarter';
        }
      }
    }
    const saveval = this.checkSaveEnabling();
    this.subscriptionService.setenablesave(saveval);
  }
  onAutoSearchKeyDimension(event: any) {
    this.autoSearchSelectedValue = event.target.value;
    // console.log(this.autoSearchSelectedValue);
    if (this.autoSearchSelectedValue === '') {
      this.showDiv = 'hide';
      this.autoSearchCrossIcon = 'hide';
      this.autoSearchSearchIcon = 'show';
      this.customDimensionData.length = 0;
      return;
    } else {
      this.showDiv = 'show';
      this.autoSearchSearchIcon = 'hide';
      this.autoSearchCrossIcon = 'show';
    }
    this.searchTerm$.next(event.target.value);

    this.searchService
      .searchMultiELS(this.searchTerm$, this.request)
      .subscribe(results => {
        if (this.request.targetedType === 'ELS') {
          if (results['result'] !== undefined) {
            this.customDimensionData = this.searchService.onConvertArrayForAutoDimnsionSelect(
              results['result'][0].hits,
              this.request.payload.dimension
            );
          }
        }
        const check = this.isEmptyObject(this.customDimensionData);
        if (check) {
          let newObject: any;
          this.customDimensionData['No data found'] = {};
        }
      });
  }
  onAutoSearchKeyDimension1(event: any) {
    this.autoSearchSelectedValue1 = event.target.value;
    // console.log(this.autoSearchSelectedValue1);
    if (this.autoSearchSelectedValue1 === '') {
      this.showDiv1 = 'hide';
      this.autoSearchCrossIcon1 = 'hide';
      this.autoSearchSearchIcon1 = 'show';
      this.customDimensionData1.length = 0;
      return;
    } else {
      this.showDiv1 = 'show';
      this.autoSearchSearchIcon1 = 'hide';
      this.autoSearchCrossIcon1 = 'show';
    }
    this.searchTerm$1.next(event.target.value);
    this.searchService
      .searchCustomEntries('0', this.request1)
      .subscribe(results => {
        if (results['result'].rmSet !== undefined) {
          this.customDimensionData1 = results['result'].rmSet;
          // let obj = {"Raw Material":x}
          // this.customDimensionData1.push(obj)
          const check = this.isEmptyObject(this.customDimensionData1);
          if (check) {
            let newObject: any;
            this.customDimensionData1['No data found'] = {};
          }
        }
      });
  }
  onAutoDimensionSelect1(keyValue: any) {
    this.autoSearchSelectedValue1 = keyValue;
    const errArr = this.dataStorageService.getErrorRecords();
    // console.log(this.params.data.alertId)
    if (this.params.data.alertId !== undefined) {
      errArr[this.params.data.alertId][
        'driverDetails'
      ] = this.autoSearchSelectedValue1;
      this.dataStorageService.setErrorRecords(errArr);
    }

    this.showDiv1 = 'hide';
    let x = [];
    const y = this.subscriptionService.newRowsArray;
    if (this.subscriptionService.saveArray.length > 0) {
      x = Object.keys(this.subscriptionService.saveArray[0]);
    }
    if (y.includes(this.params.data.alertAndAlertType)) {
      if (!x.includes(this.params.data.alertAndAlertType)) {
        this.subscriptionService.saveobj[this.params.data.alertAndAlertType] = {
          driverDetails: keyValue
        };
        if (this.subscriptionService.saveArray.length === 0) {
          this.subscriptionService.saveArray.push(
            this.subscriptionService.saveobj
          );
        } else {
          Object.assign(
            this.subscriptionService.saveArray[0],
            this.subscriptionService.saveobj
          );
        }
      } else {
        this.subscriptionService.saveArray[0][
          this.params.data.alertAndAlertType
        ]['driverDetails'] = keyValue;
      }
    }
  }
  isEmptyObject(obj) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return true;
  }
  checkSaveEnabling() {
    let count = 0;
    const x = this.subscriptionService.newRowsArray;
    // Object.keys(this.subscriptionService.saveArray[0]);
    for (const i of x) {
      if (this.subscriptionService.saveArray.length > 0) {
        if (this.subscriptionService.saveArray[0][i] !== undefined) {
          if (
            Object.keys(this.subscriptionService.saveArray[0][i]).includes(
              'value'
            )
          ) {
            count++;
          } else {
            break;
          }
        }
      }
    }
    if (count === x.length) {
      return true;
    } else {
      return false;
    }
  }
  checkpublishenabling() {
    const count = 0;
    const x = this.subscriptionService.newRowsArray;
    const y = this.subscriptionService.pushtochatbotArray;
    let b = false;
    for (const i of y) {
      if (x.includes(i)) {
        b = true;
      }
    }
    return b;
  }

  onClickOutside(event: Object) {
    this.showDiv = 'hide';
  }
  onClickOutside1(event: Object) {
    this.showDiv1 = 'hide';
  }

  ngOnDestroy() {
    if (this.timeSubscription) { this.timeSubscription.unsubscribe(); }
    if (this.scopeSubscription) { this.scopeSubscription.unsubscribe; }
    this.subscriptionService.settimetext(undefined);
  }
}
