import { DateUtils } from 'app/modules/common/utills/DateUtils.service';
import { CustomFilterComponent } from 'app/modules/core/custom-filter/custom-filter.component';
import { DateRenderComponent } from 'app/modules/core/grid/date.render.component';
import { LdLinkCellRendererComponent } from 'app/modules/load-data/shared/ld-link-cell-renderer/ld-link-cell-renderer.component';

export const defaultNoRowsText = `No Upload information to show`;
export const defaultSearchNoRowsText = `No Upload information to show for the typed search criteria`;
export const monthMap = {
  jan: '01',
  feb: '02',
  mar: '03',
  apr: '04',
  may: '05',
  jun: '06',
  jul: '07',
  aug: '08',
  sep: '09',
  oct: '10',
  nov: '11',
  dec: '12',
};
export const patternArray = ['pattern1', 'pattern2', 'pattern3', 'pattern4'];
export const patternKeyArray = [
  { name: 'pattern1', key: /^[A-Za-z]{3}\s\d{1,2},\s\d{4}$/ }, // Jul 29, 2020
  { name: 'pattern2', key: /^[A-Za-z]{3}\s\d{1,2}$/ }, // Jul 29
  { name: 'pattern3', key: /^[A-Za-z]{3}\s\d{4}$/ }, // Jul 2020
  { name: 'pattern4', key: /^\d{1,2},\s\d{4}$/ }, // 29, 2020
  { name: 'pattern5', key: /^[A-Za-z]{3}$/ }, // Jul
  { name: 'pattern6', key: /^[A-Za-z]{3}\s\d{1,2},$/ }, // Jul 29,
];

export const ldUploadHistoryGridData = {
  message: 'Retrive Job History',
  responseStatus: {
    code: 200,
    reasonPhrase: 'OK',
  },
  result: [
    {
      id: 'training_data#e04cbcc5-8efe-4f25-b017-3555a8289f4c',
      filename: 'TrainingData_2020-10-13 07-45-017.xlsx',
      type: 'training_data',
      rejectedRecords: 2,
      acceptedRecords: 5,
      totalRecords: 7,
      uploadedBy: 'bosesa1@mailinator.com',
      uploadedDate: '2020-10-13 07:46:47.523000',
      isErrors: true,
    },
    {
      id: 'training_data#0732fc24-7525-4d23-88a9-34b482406131',
      filename: 'TrainingData_2020-10-08 17-55-057.xlsx',
      type: 'training_data',
      rejectedRecords: 8,
      acceptedRecords: 226,
      totalRecords: 234,
      uploadedBy: 'arubatester1@mailinator.com',
      uploadedDate: '2020-10-11 01:57:58.903000',
      isErrors: true,
    },
    {
      id: null,
      filename: 'TrainingData_2020-10-08 16-59-022.xlsx',
      type: 'training_data',
      rejectedRecords: 0,
      acceptedRecords: 2,
      totalRecords: 2,
      uploadedBy: 'bosesa1@mailinator.com',
      uploadedDate: '2020-10-08 17:59:26.701000',
      isErrors: false,
    },
    {
      id: null,
      filename: 'TrainingData_2020-10-08 17-55-057.xlsx',
      type: 'training_data',
      rejectedRecords: 8,
      acceptedRecords: 226,
      totalRecords: 234,
      uploadedBy: 'bosesa1@mailinator.com',
      uploadedDate: '2020-10-08 17:58:03.026000',
      isErrors: true,
    },
    {
      id: null,
      filename: 'TrainingData_2020-09-30 14-39-000.xlsx',
      type: 'training_data',
      rejectedRecords: 6,
      acceptedRecords: 30,
      totalRecords: 36,
      uploadedBy: 'bosesa1@mailinator.com',
      uploadedDate: '2020-10-01 02:50:55.488000',
      isErrors: true,
    },
    {
      id: null,
      filename: 'TrainingData_2020-09-30 14-39-000.xlsx',
      type: 'training_data',
      rejectedRecords: 6,
      acceptedRecords: 30,
      totalRecords: 36,
      uploadedBy: 'bosesa1@mailinator.com',
      uploadedDate: '2020-10-01 00:38:07.240000',
      isErrors: true,
    },
  ],
  pagingConfiguration: null,
  status: 200,
  responseCode: 'OK',
};

export const ldUploadHistoryGridColumnDefs = [
  {
    headerName: 'User',
    field: 'uploadedBy',
    filterFramework: CustomFilterComponent,
    tooltipField: 'uploadedBy',
    filterParams: {
      applyButton: true,
      stringModifier: false,
    },
    width: 198,
  },
  {
    headerName: 'Uploaded File',
    field: 'filename',
    tooltipField: 'filename',
    filterFramework: CustomFilterComponent,
    filterParams: {
      applyButton: true,
      stringModifier: false,
    },
    width: 270,
  },
  {
    headerName: 'Date',
    field: 'uploadedDate',
    menuTabs: ['filterMenuTab'],
    width: 170,
    cellRendererFramework: DateRenderComponent,
    cellRendererParams: {
      format: {
        dateFormat: 'MMM d, y, h:mm a',
      },
    },
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = DateUtils.getDatewithDefaultTimeZone(
          cellValue
        ).toDate();

        if (dateAsString === null) {
          return 0;
        }

        const day = dateAsString.getDate();
        const month = dateAsString.getMonth();
        const year = dateAsString.getFullYear();
        const cellDate = new Date(year, month, day);

        // Now that both parameters are Date objects, we can compare
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        } else {
          return 0;
        }
      },
      browserDatePicker: true,
      suppressAndOrCondition: true,
      clearButton: true,
    },
  },
  {
    headerName: 'Upload Status',
    field: 'status',
    tooltipField: 'status',
    filterFramework: CustomFilterComponent,
    filterParams: {
      applyButton: true,
      stringModifier: false,
    },
    width: 180,
  },
  {
    headerName: 'Data Diagnostics - Records',
    headerClass: 'my-header-class',
    children: [
      {
        headerName: 'Total',
        field: 'totalRecords',
        tooltipField: 'totalRecords',
        width: 106,
        cellRenderer: (params) => {
          return params.data.totalRecords ? params.data.totalRecords : '-';
        },
      },
      {
        headerName: 'Accepted',
        field: 'acceptedRecords',
        tooltipField: 'acceptedRecords',
        width: 131,
        cellClass: (params) => {
          return params.data.acceptedRecords ? 'accepted-records-class' : '';
        },
        cellRendererFramework: LdLinkCellRendererComponent,
        cellRendererParams: {
          disableLink: true,
        },
      },
      {
        headerName: 'Rejected',
        field: 'rejectedRecords',
        tooltipField: 'rejectedRecords',
        cellClass: (params) => {
          return params.data.rejectedRecords ? 'rejected-records-class' : '';
        },
        width: 125,
        cellRendererFramework: LdLinkCellRendererComponent,
      },
    ],
  },
];
