import { Pipe } from '@angular/core';
import { ObjectUtils } from 'app/modules/common/utills/ObjectUtils.service';

@Pipe({ name: 'deepFind' })
export class DeepFindPipe {
  public transform(obj, readKey: string) {
    if (obj instanceof Object && (readKey || '').toString().trim().length > 0) {
      return ObjectUtils.deepFind(obj, readKey);
    }
    return null;
  }
}
