import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

import { Sorting } from './sorting.model';

@Injectable()
export class SortingService {

  constructor(private http: HttpClient) { }


}
