import { ColDef } from '@ag-grid-community/all-modules';

export const mainGridHeaderMap = {
  eventId: {
    headerName: 'Event Id',
    field: 'eventId',
  },
  title: {
    headerName: 'Title',
    field: 'title',
  },
  description: {
    headerName: 'Description',
    field: 'description',
  },
  type: {
    headerName: 'Type',
    field: 'type',
  },
  status: {
    headerName: 'Status',
    field: 'status',
  },
  activeState: {
    headerName: 'Status',
    field: 'activeState',
  },
  severity: {
    headerName: 'Severity',
    field: 'severity',
  },
  vendorImpacted: {
    headerName: 'Suppliers Impacted',
    field: 'vendorImpacted',
  },
  tenantId: {
    headerName: 'Tenant ID',
    field: 'tenantId',
  },
  createdDate: {
    headerName: 'Created Date',
    field: 'createdDate',
  },
  updatedDate: {
    headerName: 'Last Modified Date',
    field: 'updatedDate',
  },
  createdBy: {
    headerName: 'Created By',
    field: 'createdBy',
  },
  updatedBy: {
    headerName: 'Modified By',
    field: 'updatedBy',
  },
  curatedFlag:{
    headerName: 'Curated Flag',
    field: 'curatedFlag',
  },
  source:{
    headerName: 'Source',
    field: 'source',
  }
};

export const locationGridHeaderMap = {
  country: {
    headerName: 'Country',
    field: 'country',
  },
  state: {
    headerName: 'State',
    field: 'state',
  },
  city: {
    headerName: 'City',
    field: 'city',
  },
  siteImpacted: {
    headerName: 'Site Impacted',
    field: 'siteImpacted',
  },
  region: {
    headerName: 'Region',
    field: 'region',
    pinned: 'right',
    width: 150,
  },
  latitude: {
    headerName: 'Latitude',
    field: 'latitude',
    width: 150,
  },
  longitude: {
    headerName: 'Longitude',
    field: 'longitude',
    width: 150,
  },
};

export const locationHeaders = [
  {
    headerName: 'Country',
    field: 'country',
  },
  {
    headerName: 'State',
    field: 'state',
  },
  {
    headerName: 'City',
    field: 'city',
  },
  {
    headerName: 'Region',
    field: 'region',
  },
  {
    headerName: 'Latitude',
    field: 'latitude',
  },
  {
    headerName: 'Longitude',
    field: 'longitude',
  },
  {
    headerName: 'Site Impacted',
    field: 'site',
  },
];
