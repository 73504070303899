export class ToUserModel {
    firstName: string;
    fullName: string;
    groupIds: any[];
    lastName: string;
    tenantId: any;
    umsId: any;
    umsUserType: string;
    userEmail: string;
    userName: string;

    public setFirstName(firstName: string) {
        this.firstName = firstName;
    }

    public getFirstName() {
        return this.firstName;
    }

    public setLastName(lastName: string) {
        this.lastName = lastName;
    }

    public getLastName() {
        return this.lastName;
    }

    public setGroupIds(groupIds: any[]) {
        this.groupIds = groupIds;
    }

    public getGroupIds() {
        return this.groupIds;
    }

    public setFullName(fullName: string) {
        this.fullName = fullName;
    }

    public getFullName() {
        return this.fullName;
    }

    public setTenantId(tenantId: any) {
        this.tenantId = tenantId;
    }

    public getTenantId() {
        return this.tenantId;
    }

    public setUmsId(umsId: any) {
        this.umsId = umsId;
    }

    public getUmsId() {
        return this.umsId;
    }

    public setUmsUserType(umsUserType: any) {
        this.umsUserType = umsUserType;
    }

    public getUmsUserType() {
        return this.umsUserType;
    }

    public setUserEmail(userEmail: any) {
        this.userEmail = userEmail;
    }

    public getUserEmail() {
        return this.userEmail;
    }

    public setUserName(userName: any) {
        this.userName = userName;
    }

    public getUserName() {
        return this.userName;
    }
}
