import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiServiceProvider } from 'app/api-service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ApplicationURLS } from '../common/applicationURL';
import { BaseService } from '../common/base.service';
import { CustomerConfigurationService } from '../common/customerConfigurationService';
import { ScopeDataService } from '../common/scope.data.service';
import { DateUtils } from '../common/utills/DateUtils.service';
import { ObjectUtils } from '../common/utills/ObjectUtils.service';
import * as _ from 'lodash';
import {
  API_SERVICE_CONSTANTS,
  DATE_PROPERTIES,
  HTTP_REQUEST_TYPES,
  NPI_ROUTING_PATHS,
  REST_END_POINT_URLS
} from './npiconstants';
import {SharedService} from '../shared/services/shared.service';
import {CUSTOMER_REVISION_SELECTED, CUSTOMER_PROGRAM_SELECTED} from '../shared/services/shared-consts';

export interface IHeaderInfo {
  displayName: string;
  headerName?: string;
  displayOrder: string;
  field?: string;
  gridheader?: boolean;
  for?: string[];
  specifictoonegrid?: string;
}

export interface IHeader {
  headerName: string;
  field: string;
  headerInfo: IHeaderInfo;
}

export interface IResponseStatus {
  code: number;
  reasonPhrase: string;
}

export interface IWidgetInfo {
  displayName: string;
  displayOrder?: string;
  size?: string;
  type: string;
}

export interface IWidget {
  colabConfig?: any;
  widgetName: string;
  widgetInfo: IWidgetInfo;
  key: string;
}

export interface IWidgetResult {
  groupName: string;
  widgets: IWidget[];
}

export interface IServerResponse<T> {
  responseStatus: IResponseStatus;
  result: T;
  pagingConfiguration?: any;
  status: number;
  responseCode?: any;
  message: string;
}

@Injectable()
export class NpiCommonApiService {
  set programs(val) {
    this._programs = val;
  }
  get programs() {
    return this._programs;
  }
  constructor(
    private apiService: ApiServiceProvider,
    private baseService: BaseService,
    private router: Router,
    private applicationURLS: ApplicationURLS,
    private customerConfigurationService: CustomerConfigurationService,
    private scopeService: ScopeDataService,
    private sharedService: SharedService
  ) {
    this.checkProgramCreationAccess();
  }
  public programList=[];
  public programType='ACTIVE';
  public filterType='Active';
  public overallStatusData;
  public colData=[];
  public showLoader = true;
  public data: object = {};
  public noData = false;
  public costprofileMin=true;
  public expandOption=false;
  public costinfo = new BehaviorSubject<boolean>(undefined);
  public riskInfo = new BehaviorSubject<boolean>(undefined);
  public overviewInfo = new BehaviorSubject<boolean>(undefined);
  public programFilterBy = new BehaviorSubject<string>(undefined);
  public expandViewArchiveButtonClick = new BehaviorSubject<boolean>(undefined);

  private analyticsGraphInfo = new BehaviorSubject<any>(undefined);
  private programChangeInCostProfile = new BehaviorSubject<any>(undefined);
  public enableProgram = false;
  public selectedRevision: any;
  public widget: IWidget;
  public widgetKey: string;
  public _noDataMessage: boolean;
  private selectedProgram: string;
  private selectedDropdownObject;
  private editProgram = false;
  private configureEditProgram = false;
  private latestVersionId;
  private _programs;
  private allPrograms;
  public selectedBOM;
  public riskWidgetKey: string;
  public selectedUsers = '';
  public isPdmEnabled = false;
  public programConfig
  public static API_RESPONSE_VALIDATOR(res: IServerResponse<IWidgetResult>) {
    if (
      !(
        res instanceof Object &&
        res.responseStatus instanceof Object &&
        typeof res.responseStatus.code === 'number'
      )
    ) {
      return false;
    }
    switch (res.responseStatus.code) {
      case 200:
        return true;
      default:
        return false;
    }
  }
  public getOverallData(){
     if(this.filterType.toUpperCase() === 'ALL')
     {
         this.overallStatusData=this.colData
     }
 else{
      this.overallStatusData = this.colData.filter(i => this.filterType.toUpperCase().includes(i.programStatus));
     }
 }
  public setAllPrograms(val) {
    this.allPrograms = val;
  }
  public getAllPrograms() {
    return this.allPrograms;
  }
  public setnoDataMessage(val) {
    this._noDataMessage = val;
  }
  public onFilterSelect($event){
    if(!this.expandOption)
    {
      this.filterType=$event.selectedItem.val;
    }
    this.expandOption=false;
    this.filterType=$event.selectedItem.val;
    this.getRowData();
    this.setCostInfo(true);
    this.setRiskInfo(true);
    this.setOverviewInfo(true);
    // this.npiCommonApiService.costprofileMin=false;
   }
  public overallStatusMinViewData(payload) {

    const data = {
      targetedType: API_SERVICE_CONSTANTS.PROGRAM_MANAGEMENT_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.GET,
      servicePath: REST_END_POINT_URLS.OVERALL_STATUS_MIN_VIEW,
      formParams: {
        programStatus :payload
      }
    };
    return this.apiService.post(data);
  }
  public getRowData() {
    this.noData = false;
    this.overallStatusData = [];
    this.overallStatusMinViewData(this.filterType.toUpperCase())
      .subscribe((response) => {
        if (
          this.validateServiceCallData(response) !== 'error'
        ) {
          if (
            response.result &&
            response.result instanceof Array &&
            response.result.length > 0
          ) {
            this.showLoader = false;
            response.result = _.uniqBy(response.result, 'programKey');
            this.overallStatusData = response.result;
            this.programs=this.overallStatusData;
            this.overallStatusData.forEach(element => {
              element.checked=false;
            });
          } else {
            this.noData = true;
          }
        }
      });

  }
  public getnoDataMessage() {
    return this._noDataMessage;
  }
  public loadHeaders(widgetTabKey: string, expanded = false) {
    const payload = {
      targetedType: API_SERVICE_CONSTANTS.CUSTOMER_CONFIG_TARGETED_TYPE,
      formParams: {
        section: expanded
          ? API_SERVICE_CONSTANTS.WIDGET_SECTION.EXPANDED
          : API_SERVICE_CONSTANTS.WIDGET_SECTION.SUMMARY,
        widgetTabKey,
        tenantId: this.baseService.getUserInformation().tenantId
      },
      method: HTTP_REQUEST_TYPES.POST,
      generic: true,
      servicePath:
        API_SERVICE_CONSTANTS.PATH_SEPARATOR +
        API_SERVICE_CONSTANTS.WIDGET_MANAGEMENT_API_NAME +
        API_SERVICE_CONSTANTS.PATH_SEPARATOR +
        REST_END_POINT_URLS.GET_HEADERS
    };
    return this.apiService
      .post(payload)
      .map((res: IServerResponse<IHeader[]>) => {
        return res;
      });
  }

  public loadWidgetHeaders(widgetTabKey: string, expanded = false) {
    const payload = {
      targetedType: API_SERVICE_CONSTANTS.CUSTOMER_CONFIG_TARGETED_TYPE,
      formParams: {
        view: expanded
          ? API_SERVICE_CONSTANTS.WIDGET_SECTION.EXPANDED
          : API_SERVICE_CONSTANTS.WIDGET_SECTION.SUMMARY,
        widgetTabKey,
        tenantId: this.baseService.getUserInformation().tenantId
      },
      method: HTTP_REQUEST_TYPES.POST,
      generic: true,
      servicePath:
        API_SERVICE_CONSTANTS.PATH_SEPARATOR +
        API_SERVICE_CONSTANTS.WIDGET_MANAGEMENT_API_NAME +
        API_SERVICE_CONSTANTS.PATH_SEPARATOR +
        REST_END_POINT_URLS.GET_WIDGET_HEADERS
    };
    return this.apiService.post(payload);
  }

  public getBomTransformationDriversTable(input)
  {
    const data = {
      formParams: {
        programKey:  input['programKey'],
        bomId:input['bomId'],
        revisionId:input['revisionId']
      },
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.GET,
      servicePath: `bom/transcost`,
    };
     return this.apiService.post(data);
  }
  public getBomTransformationDriversGraph(sourceId)
  {
    const data = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.GET,
      formParams: {
        tenantId: sourceId,
      },
      servicePath: `status`,
    };
     return this.apiService.post(data);
  }
  public getUsersList() {
    const data = {
      targetedType: 'UserService',
      servicePath: '/getUsers',
      method: 'GET',
      formParams: {
        tenantId: this.baseService.getUserInformation().tenantId
      }
    };
    return this.apiService.post(data);
  }
  public addInternalUsersToProgram(payload) {
    const data = {
      targetedType: 'ProgramManagementService',
      servicePath: '/program/adduserstoprogram',
      method: 'POST',
      payload
    };
    return this.apiService.post(data);
  }

  public updateProgramStatus(payload,formParams){
    const data = {
      targetedType: 'ProgramManagementService',
      servicePath: 'program/updateProgramStatus',
      method: 'POST',
      payload,
      formParams
    };
    return this.apiService.post(data);
  }
  public loadWidgets(pageName: string) {
    const payload = {
      targetedType: API_SERVICE_CONSTANTS.CUSTOMER_CONFIG_TARGETED_TYPE,
      formParams: {
        tenantId: this.baseService.getUserInformation().tenantId,
        pageName
      },
      method: HTTP_REQUEST_TYPES.POST,
      generic: true,
      servicePath:
        API_SERVICE_CONSTANTS.PATH_SEPARATOR +
        API_SERVICE_CONSTANTS.WIDGET_MANAGEMENT_API_NAME +
        API_SERVICE_CONSTANTS.PATH_SEPARATOR +
        REST_END_POINT_URLS.GET_ALL_WIDGETS
    };
    return this.apiService
      .post(payload)
      .map((res: IServerResponse<IWidgetResult>) => {
        if (NpiCommonApiService.API_RESPONSE_VALIDATOR(res)) {
          return res.result;
        } else {
          return null;
        }
      });
  }
  public getProgramsList() {
    const data = {
      targetedType: API_SERVICE_CONSTANTS.PROGRAM_MANAGEMENT_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.GET,
      servicePath: REST_END_POINT_URLS.PROGRAMS_LIST
    };
    return this.apiService.post(data);
  }
  public retrieveStageInfo(programKey) {
    const data = {
      targetedType: API_SERVICE_CONSTANTS.STAGE_GATE_MANAGEMENT_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.GET,
      formParams: {
        programKey
      },
      servicePath: REST_END_POINT_URLS.PROGRAM_STATUS_STAGE_INFO
    };
    return this.apiService.post(data);
  }

  public getPartMatchDataForMPN(mpns) {
    const targetUrlObject = this.applicationURLS.getURLObject({
      target: 'mi',
      service: 'spm'
    });
    const mpnArray = mpns.split(',')
    const payload: any = {
      targetedType: 'MIService',
      method: targetUrlObject.type,
      servicePath: '/partmatching/partmatchingrows',
      payload: {
        mpnList: mpnArray,
        dimensionName: 'mpn'
      }
    };
    return this.apiService.post(payload);
  }

  public setWidgetData(widget: IWidget) {
    this.widget = widget;
  }
  public getWidgetData() {
    return this.widget;
  }
  public setLatestVersionId(versionId) {
    this.latestVersionId = versionId;
  }
  public getLatestVersionId() {
    return this.latestVersionId;
  }
  public getSortedData(
    data: object[],
    keyForSorting = 'headerInfo.displayOrder'
  ) {
    data.sort((obj1, obj2) => {
      return (
        +ObjectUtils.deepFind(obj1, keyForSorting) -
        +ObjectUtils.deepFind(obj2, keyForSorting)
      );
    });

    return data;
  }
  public onProgramSelection(program) {
    this.programList=[];
    this.programType=program.programStatus;
    this.setSelectedDropdownObject(program);
    this.programFilterBy.next('');
    this.router.navigate([NPI_ROUTING_PATHS.SUMMARY_PATH]);
  }

  public checkProgramCreationAccess() {
    const scope = this.scopeService.getFocusValue();
    const confData = this.customerConfigurationService.getConfData(scope);
    if (
      confData instanceof Object &&
      confData.leftNavbarFeatures instanceof Array
    ) {
      const npiCreate = confData.leftNavbarFeatures.find(
        (feature) =>
          feature instanceof Object && feature.title === 'NPI Create Program'
      );
      if (npiCreate instanceof Object) {
        this.enableProgram = true;
        return;
      }
    }
    this.enableProgram = false;
  }

  public setProgramSelected(programName) {
    this.selectedProgram = programName;
  }
  public setWidgetKey(widgetKey) {
    this.widgetKey = widgetKey;
  }

  public getWidgetKey() {
    return this.widgetKey;
  }

  public setRiskWidgetKey(widgetKey) {
    this.riskWidgetKey = widgetKey;
  }

  public getRiskWidgetKey() {
    return this.riskWidgetKey;
  }
  public getProgramSelected() {
    return this.selectedProgram;
  }

  public setSelectedBOM(data) {
    this.selectedBOM = data;
  }

  public getSelectedBOM() {
    return this.selectedBOM;
  }
  public serRevisionSelected(data) {
    this.sharedService.setLocalStorageData(CUSTOMER_REVISION_SELECTED, data);
    this.selectedRevision = data;
  }
  public gerRevisionSelected() {
    if (
      !this.selectedRevision &&
      localStorage.getItem(CUSTOMER_REVISION_SELECTED) !== 'undefined'
    ) {
      this.selectedRevision = JSON.parse(
        localStorage.getItem(CUSTOMER_REVISION_SELECTED)
      );
    }
    return this.selectedRevision;
  }
  public getProgramKeyfromProgram(programList, programName) {
    return programList.find((item) => item.programName === programName)
      .programKey;
  }
  public setSelectedDropdownObject(program) {
    this.sharedService.setLocalStorageData(CUSTOMER_PROGRAM_SELECTED, program);
    this.selectedDropdownObject = program;
  }
  public getSelectedDropdownObject() {
    if (
      !this.selectedDropdownObject &&
      localStorage.getItem(CUSTOMER_PROGRAM_SELECTED) !== 'undefined'
    ) {
      this.selectedDropdownObject = JSON.parse(
        localStorage.getItem(CUSTOMER_PROGRAM_SELECTED)
      );
    }
    return this.selectedDropdownObject;
  }
  public setEditProgram(val) {
    this.editProgram = val;
  }
  public getEditProgram() {
    return this.editProgram;
  }
  public setConfigureEditProgram(val) {
    this.configureEditProgram = val;
  }
  public getConfigureEditProgram() {
    return this.configureEditProgram;
  }
  /**
   *
   * @param programs Array of programs for which opportunities to be fetched Example:  ["LOUD_SPEAKER", "WIFISYSTEM"]
   * @param revision revisionId of bom, revisionId should be sent only incase of Older revisions.
   *  Incase of latest revisions data please DONOT send revisionId , Example: 2
   */
  public getOpportunities(programs?, revision?) {
    const data: any = {
      targetedType: API_SERVICE_CONSTANTS.PROGRAM_MANAGEMENT_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.GET,
      servicePath: 'program/opportunities',
      formParams: {
        programStatus :this.filterType.toUpperCase()
      }
    };
    if (programs) {
      data.formParams = {
        programKeys: programs
      };
      if (revision) {
        data.formParams.revisionId = revision;
      }
    }
    return this.apiService.post(data);
  }
  /**
   *
   * @param programs Array of programs for which risks have to be found
   * Example: ["LOUD_SPEAKER", "WIFISYSTEM"]
   */
  public getRisks(programs?) {
    const data: any = {
      targetedType: API_SERVICE_CONSTANTS.PROGRAM_MANAGEMENT_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.GET,
      servicePath: 'risks/parts'
    };
    if (programs) {
      data.formParams = {
        key: programs
      };
    }
    return this.apiService.post(data);
  }
  public saveColumnConfiguration(postData) {
    const payload = {
      method: 'POST',
      payload: postData,
      servicePath: 'savecolumnconfiguration',
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE
    };
    return this.apiService.post(payload);
  }
  public columnConfigurationSave(postData,view?) {
    const payload = {
      method: 'POST',
      payload: postData,
      servicePath: `/columnconfiguration/save?context=${view}`,
      targetedType: 'PartWorkbenchService'
    };
    return this.apiService.post(payload);
  }
  public validateServiceCallData(data) {
    const newRegEx = /^2\d\d/;
    // will add response status check once we get it from backend
    // we should not put condition on result, as it may be null in some positive responses
    // but that doesn't mean service failed, Rather the condition should be on matching with
    // 2** sttuses.
    if (
      data &&
      data instanceof Object &&
      data.responseStatus.code.toString().match(newRegEx)
    ) {
      return data;
    } else {
      return 'error';
    }
  }
  public getColumnDefObject(headers, key) {
    return headers.find((i) => i.field === key);
  }

  public addAttribute(formParams) {
    const data = {
      targetedType: 'partsMasterService',
      method: 'POST',
      servicePath: `/attribute/value/add`,
      formParams
    };
    return this.apiService.post(data);
  }

  public setCostInfo(val) {
    this.costinfo.next(val)
  }
  public getcostInfo(){
    return this.costinfo.asObservable();
  }
  public setRiskInfo(val) {
    this.riskInfo.next(val)
  }
  public getRiskInfo(){
    return this.riskInfo.asObservable();
  }
  public setOverviewInfo(val){
    this.overviewInfo.next(val);
  }
  public getOverviewInfo(){
    return this.overviewInfo.asObservable();
  }
  public setAnalyticsDriverGraphClick(val) {
    this.analyticsGraphInfo.next(val);
  }

  public getAnalyticsDriverGraphClick() {
    return this.analyticsGraphInfo.asObservable();
  }

  public setProgramChangeInCostProfile(val) {
    this.programChangeInCostProfile.next(val);
  }

  public getProgramChangeInCostProfile() {
    return this.programChangeInCostProfile.asObservable();
  }
  addCommentsRederer(header) {
    header['cellRenderer'] = 'commentsTooltipComponent';
    header.filter = 'set';
    header.tooltipValueGetter = (params) => {
      if (params.value) {
        const val = params.value.split('@##@');
        if (val[2]) {
          return (
            val[0] +
            ' ' +
            DateUtils.format(parseInt(val[1],10), DATE_PROPERTIES.FORMATWITHTIME) +
            ' ' +
            val[2]
          );
        } else {
          return '';
        }
      } else {
        return '';
      }
    };
    header.filterParams = {
      cellRenderer: (params) => {
        if (params && params.value) {
          let comment = params.value;
          if (typeof params.value === 'string') {
            comment = params.value.split('@##@');
          }
          const userName = comment[0] || '';
          const timeStamp = DateUtils.format(
            parseInt(comment[1],10) || 0,
            DATE_PROPERTIES.FORMATWITHTIME
          );
          comment = comment[2] || '';
          if (comment) {
            return `<span title='${comment}' >${comment}</span>`;
          } else {
            return '';
          }
        } else {
          return '(Blanks)';
        }
        // this.formatComments(params);
      },
      cellHeight: 20
    };
  }
  public checkIsPdmEnabled()
  {
    const scope = this.scopeService.getFocusValue()
    const leftNavFeatures = this.customerConfigurationService.getConfData(
      scope
    ).leftNavbarFeatures
    this.isPdmEnabled = leftNavFeatures.filter(nav => nav.view ==='partsdatamanagement').length > 0
  }
}
