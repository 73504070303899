import { Injectable } from '@angular/core';
import { ApiServiceProvider } from 'app/api-service';

@Injectable({
  providedIn: 'root'
})
export class NewsArticlesService {

  constructor(private apiServiceProvider: ApiServiceProvider) { }

  getNewsArticles(params: any) {
    const payLoad = {
      targetedType: 'riskManagementService',
      method: 'GET',
      servicePath: `events/news`,
      formParams: params ? { ...params } : {},
    };
    return this.apiServiceProvider.post(payLoad);
  }

}
