import { ITooltipAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';
import { DateUtils } from 'app/modules/common/utills/DateUtils.service';
import { DATE_PROPERTIES } from 'app/modules/npi/npiconstants';

@Component({
  selector: 'tooltip-component',
  template: `
    <div style="text-overflow: ellipsis">
      <div style="text-overflow: ellipsis;overflow: hidden;">
        {{ comment }}
      </div>
    </div>
  `,
  styleUrls: ['../npi-bom-repository.component.sass']
})
export class CommentsTooltipComponent implements ITooltipAngularComp {
  public tooltip = false;
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  params;
  id;
  public display;
  public toolTip;
  public comment;

  agInit(params): void {
    if (params) {
      if (params && params.data && params.data.comments) {
        this.id = params.data.bomRepoId;
        if (
          params &&
          params.context &&
          params.context.componentParent &&
          (params.context.componentParent.LOCALCONFIG ===
            'NPI_CHECKEDOUT_PARTS' ||
            params.context.componentParent.LOCALCONFIG === 'NPI_VIEW_PARTS' ||
            params.context.componentParent.LOCALCONFIG === 'NPI_PARTS_PREVIEW' ||
            params.context.componentParent.LOCALCONFIG === 'NPI_VIEW_BOM' ||
            params.context.componentParent.LOCALCONFIG === 'NPI_COST_EXPANDED' ||
            params.context.componentParent.LOCALCONFIG === 'NPI_BOM_PREVIEW' ||
            params.context.componentParent.LOCALCONFIG === 'NPI_BOM_COMPARE')
        ) {
          this.id = params.data.nodePath.join(',') + 'comment';
          const val = params.data.comments.split('@##@');

          this.display =
            val[0] +
            '_' +
            DateUtils.format(parseInt(val[1]), DATE_PROPERTIES.FORMATWITHTIME) +
            ':' +
            val[2];
          this.toolTip =
            val[0] +
            ' ' +
            DateUtils.format(parseInt(val[1]), DATE_PROPERTIES.FORMATWITHTIME) +
            '\n' +
            val[2];
          this.comment = val[2];
          return;
        }
        let comment = params.data.comments;
        if (typeof params.data.comments === 'string') {
          comment = JSON.parse(params.data.comments);
        }
        const userName = comment.userName;
        const timeStamp = DateUtils.format(
          comment.time,
          DATE_PROPERTIES.FORMATWITHTIME
        );
        comment = comment.message;
        this.display = userName + '_' + timeStamp + ':' + comment;
        this.toolTip = userName + ' ' + timeStamp + '\n' + comment;
        this.comment = comment;
      }
    }
  }

  showTooltip(event, id) {
    if (document.getElementById(id)) {
      document.getElementById(id).classList.add('show');
    }
  }
  hideTooltip(event, id) {
    if (document.getElementById(id)) {
      document.getElementById(this.id).classList.remove('show');
    }
  }
}
