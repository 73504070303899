import { GridOptions } from '@ag-grid-enterprise/all-modules';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ObjectUtils } from 'app/modules/common/utills/ObjectUtils.service';
import { DialogBoxService, DialogButtonName, DialogType, LdDialog } from 'app/modules/leva-ui-library/components/dialog-box/dialog-box.service';
import { NpiCommonApiService } from 'app/modules/npi/npi-common-api.service';
import { NpiToasterService } from 'app/modules/npi/npi-toaster.service';
import { TOASTER_MESSAGE_TYPES } from 'app/modules/npi/npiconstants';
// import { EventEmitter } from 'events';
import { NpiForwardSync } from './shared/npi-forward-sync.model';
import { NpiForwardSyncService } from './shared/npi-forward-sync.service';
declare let $;
@Component({
  selector: 'npi-forward-sync',
  templateUrl: 'npi-forward-sync.component.html',
  styleUrls: ['npi-forward-sync.component.sass'],
  providers: [NpiForwardSyncService],
})
export class NpiForwardSyncComponent implements OnInit {
  npiForwardSync: NpiForwardSync[] = [];
  @Output() public onCloseEvent: EventEmitter<any> = new EventEmitter();
  public redrawEvent: EventEmitter<any> = new EventEmitter();
  gridOptionsFirst: GridOptions;
  gripOptionsSecond: GridOptions;

  comparisonTableData = [];
  selectedNodes = {};
  selected = false;
  data = {
    additionalDataPart: { count: 0, cpns: [], selected: false },
    addedSource: { count: 0, cpns: [], mpn: [], selected: false },
    additionalDataSource: { count: 0, cpns: [], mpn: [], selected: false },
    differentDatainParts: { count: 0, cpns: [], selected: false },
    differentDatainSource: { count: 0, cpns: [], mpn: [], selected: false },
    noDataInParts: { count: 0, cpns: [], selected: false },
    noDataInSource: { count: 0, cpns: [], mpn: [], selected: false },
  };
  nodeExpanded = {};
  public preload = true;
  public disableConfirm = true;
  public loading = false;
  public response;
  public customOptions = {
    domLayout: '',
    pagination: false,
  };
  public width = '0px';
  scrollTop = 0;
  scrollLeft = 0;
  discardButtonClicked = false;
  maxWidth = 400;

  @Input() public referenceCostQuarter = '';
  @Input() public comparisonPage = '';
  @Input() public payload;
  shownochangesInAutoFill: LdDialog = {
    title: 'Autofill Information',
    type :  DialogType.INFORMATION,
    id: 'nochangesInAutoFill',
    message: 'No new source information loaded.'
  }
  compareElementHeight = 400;
  constructor(
    private npiForwardSyncService: NpiForwardSyncService,
    public npiCommonApiService: NpiCommonApiService,
    private dialogBoxService: DialogBoxService
  ) {}

  ngOnInit() {
    /*const synchronizeScrolls = ($event, id1, id2) => {
      $event.preventDefault();
      const scrollT = $event.target['scrollTop'];
      const scrollL = $event.target['scrollLeft'];
      if (this.scrollLeft !== scrollL) {
        if (scrollL !== 0) {
          $(id1).scrollLeft($(id2).scrollLeft());
        } else {
          $(id1).scrollLeft(this.scrollLeft);
        }
        this.scrollLeft = scrollL;
      }
      if (this.scrollTop !== scrollT) {
        $(id1).scrollTop($(id2).scrollTop());
        this.scrollTop = scrollT;
      }
    };
    document
      .getElementById('firstGrid')
      .addEventListener('scroll', ($event) => {
        synchronizeScrolls($event, '#secondGrid', '#firstGrid');
      });
    document
      .getElementById('secondGrid')
      .addEventListener('scroll', ($event) => {
        synchronizeScrolls($event, '#firstGrid', '#secondGrid');
      });*/
    this.redrawEvent.subscribe((val) => {
      const values = Array.from(Object.values(this.selectedNodes));
      // if (typeof val === 'object') {
      //   if (
      //     Object.getOwnPropertyNames(this.selectedNodes).length ===
      //     this.gridOptionsFirst.rowData.length
      //   ) {
      if (
        this.gridOptionsFirst.rowData.every(
          (ele) => this.selectedNodes[ele.cpn]
        )
      ) {
        this.selected = true;
      }
      if (
        this.gridOptionsFirst.rowData.some(
          (ele) => !this.selectedNodes[ele.cpn]
        )
      ) {
        this.selected = false;
      }
      if (values.some((ele) => ele)) {
        this.disableConfirm = false;
      } else {
        this.disableConfirm = true;
      }
      // } else {
      //   if (values.some(ele => ele)) {
      //     this.disableConfirm = false;
      //   } else {
      //     this.disableConfirm = true;
      //   }
      // }
      // }
    });
    this.gripOptionsSecond = {
      context: {
        component: this,
        gridType: 'PostSync',
      },
    };
    this.gridOptionsFirst = {
      context: {
        component: this,
        gridType: 'Current',
      },
    };
    const selectedRevision = this.npiCommonApiService.gerRevisionSelected();
    let programKey;
    if (this.npiCommonApiService.getSelectedDropdownObject()) {
      programKey = this.npiCommonApiService.getSelectedDropdownObject().programKey;
    }
    if(this.comparisonPage === 'PRODUCTION_PARTS_AUTOFILL'){
      this.npiForwardSyncService.getBomAutofillData(this.payload).subscribe((data)=>{
        this.retrieveCompareData(data);
      })
    } else if(this.comparisonPage === 'NPI_PARTS_AUTOFILL') {
      this.npiForwardSyncService.getPartsAutofillData(this.payload).subscribe((data)=>{
        this.retrieveCompareData(data);
      })
    } else if(this.comparisonPage === 'BOM_PRODUCTION_PARTS_AUTOFILL') {
      this.npiForwardSyncService.getBomProductionPartsAutofillCompareData(selectedRevision, programKey).subscribe((data)=>{
        this.retrieveCompareData(data);
      })
    } else {
      this.npiForwardSyncService
      .getCompareData(selectedRevision)
      .subscribe((data) => {
        this.retrieveCompareData(data);
      });
    }

  }
  retrieveCompareData(data) {
    if (
      data instanceof Object &&
      data.result instanceof Object &&
      data.result.current.length > 0 &&
      data.result.postSync.length > 0 &&
      data.responseStatus.code === 200
    ) {
      this.response = data.result;

      /* this.response.current = this.response.current.splice(0,10);
      this.response.postSync = this.response.postSync.splice(0,10); */

      (this.response.current || []).forEach((element,index) => {
        if(element){
          const node ={
            current: element,
            postSync: this.response.postSync[index]
          };
          if(element.partInfo){
              this.maxWidth = Object.keys(element.partInfo).length * 100;
          }else
          if(element.partInfo && element.sourceInfo[0]) {
          const sourceWidth =  Object.keys(element.sourceInfo[0]).length * 100 ;
          this.maxWidth =  this.maxWidth < sourceWidth ? sourceWidth : this.maxWidth;
          }
        this.comparisonTableData.push(node);

        }
      });

      this.maxWidth = this.maxWidth + 30;



      this.gripOptionsSecond.rowData = data.result.postSync;
      this.gridOptionsFirst.rowData = data.result.current;

      this.preload = false;
      this.getWidth();
      this.formSummaryInfo();
    } else if (data.responseStatus.code === 200) {
      this.shownochangesInAutoFill.message = 'No new source information loaded.'
      this.shownochangesInAutoFill.buttons = [{
        title: DialogButtonName.OK,
        id: 'shownochangesInAutoFillOkPopup',
        primary: true
      }]
      this.openDialog(this.shownochangesInAutoFill.id)
    } else if (data.responseStatus.code === 500) {
      NpiToasterService.populateToaster(
        TOASTER_MESSAGE_TYPES.ERROR,
        'Error in fetching data'
      );
      this.onCloseEvent.emit();
    }
  }
  formSummaryInfo() {
    this.data.addedSource.count = this.response.current.reduce((acc, ele) => {
      const len =
        (ele.sourceInfo || []).filter((item) =>
          Object.getOwnPropertyNames(item).every((key) => !item[key])
        ).length || 0;
      if (len > 0) {
        this.data.addedSource.cpns.push(ele.cpn);
      }
      return acc + len || 0;
    }, 0);
    const cpnCurrent = this.response.current.reduce((acc, ele) => {
      acc[ele.cpn] = ele;
      return acc;
    }, {});
    const cpnPostSync = this.response.postSync.reduce((acc, ele) => {
      acc[ele.cpn] = ele;
      return acc;
    }, {});
    // checking if atleast one adddtional part attribute is pulled which is not present in current bom
    const addinalDatainPartsCatalog = (input, comparingObj) =>
      Object.getOwnPropertyNames(input).some(
        (ele) => input[ele] && !comparingObj[ele]
      );
    // different data in parts catalog desc:'acb' and desc:'cde'
    const differentDatainPartsCatalog = (input, comparingObj) => {
      return Object.getOwnPropertyNames(input).some((ele) => {
        if (input[ele] && comparingObj[ele]) {
          return input[ele].trim() !== comparingObj[ele].trim();
        }
      });
    };
    // no data in parts catalog
    const noDataInPartsCatalog = (input, comparingObj) =>
      Object.getOwnPropertyNames(input).some(
        (ele) => !input[ele] && comparingObj[ele]
      );
    Object.getOwnPropertyNames(cpnCurrent).forEach((cpn) => {
      const partObjCurrent = cpnCurrent[cpn].partInfo;
      const partObjPostSync = cpnPostSync[cpn].partInfo;
      const sourceObjCurrent = cpnCurrent[cpn].sourceInfo;
      const sourceObjPostSync = cpnPostSync[cpn].sourceInfo;

      if (addinalDatainPartsCatalog(partObjPostSync, partObjCurrent)) {
        this.data.additionalDataPart.count++;
        this.data.additionalDataPart.cpns.push(cpn);
      }
      if (differentDatainPartsCatalog(partObjPostSync, partObjCurrent)) {
        this.data.differentDatainParts.count++;
        this.data.differentDatainParts.cpns.push(cpn);
      }
      if (noDataInPartsCatalog(partObjPostSync, partObjCurrent)) {
        this.data.noDataInParts.count++;
        this.data.noDataInParts.cpns.push(cpn);
      }
      // checking new additional data and which is not part of addded sources
      sourceObjPostSync.forEach((source, index) => {
        if (
          addinalDatainPartsCatalog(source, sourceObjCurrent[index]) &&
          Object.getOwnPropertyNames(sourceObjCurrent[index]).some(
            (key) => sourceObjCurrent[index][key]
          )
        ) {
          this.data.additionalDataSource.count++;
          this.data.additionalDataSource.cpns.push(cpn);
        }
        if (differentDatainPartsCatalog(source, sourceObjCurrent[index])) {
          this.data.differentDatainSource.count++;
          this.data.differentDatainSource.cpns.push(cpn);
        }
        if (noDataInPartsCatalog(source, sourceObjCurrent[index])) {
          this.data.noDataInSource.count++;
          this.data.noDataInSource.cpns.push(cpn);
        }
      });
    });
    console.log(this.data);
  }
  onClickCpnCount(data) {
    data.selected = !data.selected;
    let cpns = [];
    Object.getOwnPropertyNames(this.data).forEach((ele) => {
      if (this.data[ele]['selected']) {
        cpns = cpns.concat(this.data[ele]['cpns']);
      }
    });
    this.gridOptionsFirst.rowData = this.response.current.filter((ele) =>
      cpns.includes(ele.cpn)
    );
    this.gripOptionsSecond.rowData = this.response.postSync.filter((ele) =>
      cpns.includes(ele.cpn)
    );
    if (
      Object.getOwnPropertyNames(this.data).every(
        (ele) => !this.data[ele].selected
      )
    ) {
      this.gridOptionsFirst.rowData = ObjectUtils.copy(this.response.current);
      this.gripOptionsSecond.rowData = ObjectUtils.copy(this.response.postSync);
    }
    this.redrawEvent.emit(data);
  }
  onCloseevent(val) {
    if (val === 'confirm') {
      const selectedRevision = this.npiCommonApiService.gerRevisionSelected();
      const data = { current: [], postSync: [] };
      data['current'] = this.response['current'].filter(
        (ele) => this.selectedNodes[ele['cpn']]
      );
      data['postSync'] = this.response['postSync'].filter(
        (ele) => this.selectedNodes[ele['cpn']]
      );
      if(this.comparisonPage === 'PRODUCTION_PARTS_AUTOFILL' || this.comparisonPage === 'NPI_PARTS_AUTOFILL'){
        this.npiForwardSyncService
        .saveBomRepoAutofillData(this.payload, data)
        .subscribe((result) => {
          if (result) {
            this.onCloseEvent.emit();
          }
        });
      } else {
        this.npiForwardSyncService
        .savePartMasterAutofill(selectedRevision, data, this.comparisonPage)
        .subscribe((result) => {
          if (result) {
            this.onCloseEvent.emit();
          }
        });
      }
    } else {
      this.discardButtonClicked = true;
      this.shownochangesInAutoFill.message = 'Are you sure you want to discard the autofilled source information?'
      this.shownochangesInAutoFill.buttons = [
        {
          title: DialogButtonName.NO,
          id: 'shownochangesInAutoFillNoPopup'
        },
        {
        title: DialogButtonName.YES,
        id: 'shownochangesInAutoFillYesPopup',
        primary: true
      }]
      this.openDialog(this.shownochangesInAutoFill.id)
    }
  }
  noDataOK() {
    if (this.discardButtonClicked && (this.comparisonPage === 'PRODUCTION_PARTS_AUTOFILL' || this.comparisonPage === 'NPI_PARTS_AUTOFILL')) {
      this.npiForwardSyncService.discardCmodmFiscalQuarterChanges(this.payload.bomRepoId, this.payload.bomRepoAuditId).subscribe(data =>{
        if(data && data.result){
        }
      })
    }
    this.onCloseEvent.emit();
  }
  public getData(key, data) {
    if (key === 1) {
      return { ...this.gridOptionsFirst, data };
    } else {
      return { ...this.gripOptionsSecond, data };
    }
  }
  public onCheck(value) {
    const setVal = (val) =>
      this.gridOptionsFirst.rowData.forEach((ele) => {
        this.selectedNodes[ele.cpn] = val;
      });
    if (value) {
      setVal(true);
    } else {
      setVal(false);
    }
    if (this.gridOptionsFirst.rowData.length > 0) {
      this.redrawEvent.emit(this.gridOptionsFirst.rowData[0]);
    }
  }
  public getWidth() {
    let sourceKeysLength = [];
    this.gridOptionsFirst.rowData
      .map((ele) =>
        ele.sourceInfo.map((data) => Object.getOwnPropertyNames(data).length)
      )
      .forEach((e) => (sourceKeysLength = sourceKeysLength.concat(e)));
    const partKeysLength = this.gridOptionsFirst.rowData.map(
      (data) => Object.getOwnPropertyNames(data.partInfo).length
    );
    const sorteddData = sourceKeysLength
      .concat(partKeysLength)
      .sort((e1, e2) => +e2 - +e1);
    if (sorteddData.length > 0) {
      this.width = `${sorteddData[0] * 105}px`;
    }
  }

  trackById(index) {
    return index;
  }
  public confirmationButtonClick($event)
  {
    if($event && $event.button && ($event.button.title === 'OK' || $event.button.title === 'Yes'))
    {
      this.noDataOK()
    }
  }
  private openDialog(id: string): void {
    this.dialogBoxService.open(id)
  }
}
