import { AfterContentInit, Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'full-width-cell-renderer',
  templateUrl: './full-width-cell-renderer.component.html',
  styleUrls: ['./full-width-cell-renderer.component.sass']
})
export class FullWidthCellRendererComponent implements OnInit {
  data;


  @Input() expanded = true;
  @Input() selected = false;
  @Input() cellParams;


  constructor() {}

  ngOnInit() {
    this.data = this.cellParams.data;
    this.selected = this.cellParams.context['component']['selectedNodes'][
      this.data['cpn']
    ];
    const exp = this.cellParams.context['component']['nodeExpanded'][
      this.data['cpn']
    ];

    this.expanded = typeof exp === 'boolean' ? exp : true;
  }

  public onCheck(value) {
    this.cellParams.context['component']['selectedNodes'][
      this.data['cpn']
    ] = value;

    this.cellParams.context.component.redrawEvent.emit(this.cellParams);
  }
  onExpand() {
    this.cellParams.context['component']['nodeExpanded'][
      this.data['cpn']
    ] = this.expanded;
  }
}
