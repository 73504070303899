import { DateUtils } from 'app/modules/common/utills/DateUtils.service';

export class ValidationService {
  private numberValidationKeys = {
    MIN: 'MIN',
    MAX: 'MAX',
    RANGE: 'RANGE',
    DECIMAL: 'DECIMAL',
    POSITIVE: 'POSITIVE',
    NEGATIVE: 'NEGATIVE',
    INTEGERONLY: 'INTEGERONLY',
    ALLOWALPHA: 'ALLOWALPHA',
    NONZERO: 'NONZERO',
    NONBLANK: 'NONBLANK'
  };
  private textValidationKeys = {
    NULL: 'NULL',
    NOT_NULL: 'NOT_NULL'
  };
  private dateValidationKeys = {
    FORMAT: 'FORMAT',
    DATELESS: 'DATELESS',
    DATEGREATER: 'DATEGREATER',
    DATERANGE: 'DATERANGE',
    VALIDDATE: 'VALIDDATE'
  };
  constructor() {}

  public validateAllValidations(validations, value) {
    const errorArray = [];
    if (validations instanceof Array && validations.length > 0) {
      for (let i = 0; i < validations.length; i++) {
        this.validateEachItem(validations[i], errorArray, value);
      }
    }
    return errorArray;
  }
  public validateTextItem(validation, errorArray, value) {
    let valid = true;
    const type = validation.type;
    switch (type) {
      case this.textValidationKeys.NULL:
        valid = true;
        break;
      case this.textValidationKeys.NOT_NULL:
        valid = value && value.length !== 0;
        break;
    }
    this.formValidationMessages(validation, errorArray, valid);
    return valid;
  }

  private validateEachItem(validation, errorArray, value) {
    if (Object.keys(this.numberValidationKeys).includes(validation.type)) {
      this.validateNumberItem(validation, errorArray, value);
    } else if (Object.keys(this.textValidationKeys).includes(validation.type)) {
      this.validateTextItem(validation, errorArray, value);
    } else if (Object.keys(this.dateValidationKeys).includes(validation.type)) {
      this.validateDateItem(validation, errorArray, value);
    } else {
      return [];
    }
  }

  private validateNumberItem(validation, errorArray, value) {
    let valid = true;
    const type = validation.type;
    const validationInfo = validation.info;
    switch (type) {
      case this.numberValidationKeys.MIN:
        valid = value >= validationInfo.value;
        break;
      case this.numberValidationKeys.MAX:
        valid = value <= validationInfo.value;
        break;
      case this.numberValidationKeys.RANGE:
        valid = value >= validationInfo.min && value <= validationInfo.max;
        break;
      case this.numberValidationKeys.DECIMAL:
        // let arr = value.toString().split('.')
        // if (arr.length > 2) {
        //     valid = false;
        //     break;
        // }
        // valid = arr[1].length <= validationInfo.value;

        valid = true;
        if (value === null) { break; }
        const arr = value.toString().split('.');
        if (arr.length > 2) {
          valid = false;
          break;
        }
        if (arr.length > 1) {
          valid = arr[1].length <= validationInfo.value;
        }
        break;
      case this.numberValidationKeys.POSITIVE:
        valid = value > 0;
        break;
      case this.numberValidationKeys.NEGATIVE:
        valid = value < 0;
        break;
      case this.numberValidationKeys.INTEGERONLY:
        valid = Number.isInteger(Number(value));
        break;
      case this.numberValidationKeys.ALLOWALPHA:
        valid = !isNaN(value) || value === validationInfo.value;
        break;
      case this.numberValidationKeys.NONZERO:
        valid = Number(value) === NaN ? true : !Number(value) ? false : true;
        break;
      case this.numberValidationKeys.NONBLANK:
        valid = value.trim().length;
        break;
      default:
        valid = true;

    }
    this.formValidationMessages(validation, errorArray, valid);
    return valid;
  }
  private validateDateItem(validation, errorArray, value) {
    let valid = true;
    const info = validation.info;
    const type = validation.type;
    switch (type) {
      case this.dateValidationKeys.VALIDDATE:
        valid = this.isValidDate(info.value);
        break;
      case this.dateValidationKeys.DATELESS:
        valid = info.value > value;
        break;
      case this.dateValidationKeys.DATEGREATER:
        valid = value > info.value;
        break;
      case this.dateValidationKeys.DATERANGE:
        valid = value > info.min && value < info.max;
        break;
      case this.dateValidationKeys.FORMAT:
        valid = true;
        break;
      default:
        valid = true;
    }
    this.formValidationMessages(validation, errorArray, valid);
    return valid;
  }
  private isValidDate(myDate) {
    let date;
    if (typeof myDate === 'number') {
      date = DateUtils.getDate(+myDate);
    } else if (typeof myDate === 'string') {
      if (
        parseInt(myDate) > 0 &&
        !isNaN(parseInt(myDate)) &&
        parseInt(myDate).toString().length === myDate.length
      ) {
        date = DateUtils.getDate(+myDate);
      }
    }
    myDate =
      date instanceof Object &&
      date.isValid instanceof Function &&
      date.isValid()
        ? date.format('MM/DD/YYYY')
        : null;
    const filter = /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/;
    return filter.test(myDate);
  }
  private formValidationMessages(validation, errorArray, valid) {
    if (valid) {
      return;
    }
    // errorArray.push(validation.errorMessage ? validation.errorMessage : '');
    errorArray.push(validation);
  }
}
