import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import { Fileupload } from './shared/fileupload.model';
import { FileuploadService } from './shared/fileupload.service';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import {
  ToasterService,
  ToasterConfig,
  Toast,
  BodyOutputType
} from 'angular2-toaster';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { ApiServiceProvider } from '../../../api-service';
import {
  CONFIG_TEMPLATE_IMPORT,
  FILE_TYPE_EXTENSIONS_ICONS
} from 'app/modules/rfq-configurations/shared/constants';

@Component({
  selector: 'fileupload',
  templateUrl: 'fileupload.component.html',
  styleUrls: ['./fileupload.component.sass'],
  providers: [FileuploadService]
})
export class FileuploadComponent implements OnInit, OnDestroy {
  @Input()
  set fileDataObject(val) {
    this._fileDataObject = val;
    if (val && val.fileType && FILE_TYPE_EXTENSIONS_ICONS[val.fileType]) {
      this.fileUploadDetails = FILE_TYPE_EXTENSIONS_ICONS[val.fileType];
    } else {
      this.fileUploadDetails = FILE_TYPE_EXTENSIONS_ICONS.DEFAULT;
    }
    this.showButton = !(
      this.fileUploadDetails.hasIcon && this.fileUploadDetails.iconClass
    );
    this.title = this.showButton
      ? 'click to select and upload Json file'
      : 'click to select and upload Excel file';
    this.fileType = this.fileUploadDetails.extension
      ? this.fileUploadDetails.extension
      : '';
  }
  get fileDataObject() {
    return this._fileDataObject;
  }

  constructor(
    private fileuploadService: FileuploadService,
    private apiService: ApiServiceProvider,
    private scopeDataService: ScopeDataService,
    private elem: ElementRef,
    private dataStorageService: DataStorageService,
    toasterService: ToasterService
  ) {
    this.toasterService = toasterService;
    this.scopeDataService.getFocus().subscribe((val) => {
      this.focus = val;
    });
    this.focus = this.scopeDataService.getFocusValue();
  }
  title: string;
  cmsFileResponseData: any;
  @ViewChild('myInput', { static: false })
  myInputVariable: any;
  @ViewChild('myInputrfq', { static: false })
  myInputrfqVariable: any;
  focus: string;
  fileupload: Fileupload[] = [];
  selection: any;
  response: any;
  uploadData: any;
  message: string;
  filename: string;
  testFileName: string;
  successMsg = '';
  errorMsg = '';
  successMsgShow = false;
  errorMsgShow = false;
  fileSize = 'S';
  file = true;
  private toasterService: ToasterService;
  public fileType = '';
  public showButton = false;
  private _fileDataObject: any;
  public fileUploadDetails: any;
  @Output() onFileUpload = new EventEmitter<any>();
  @Output() dataObject = new EventEmitter<any>();
  @Output() onFileUploadPreloader = new EventEmitter<any>();
  @ViewChild('fileInput', { static: true }) fileInput;
  @ViewChild('inputText', { static: false }) inputText;
  @Input() partMasterSearch: string;
  subscriptions: any = {};
  showLoading = false;
  types = [
    {
      extensions:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
      isSingle: false
    },
    { extensions: '.csv, text/csv', isSingle: true },
    { extensions: 'image/*', isSingle: false },
    { extensions: 'audio/*', isSingle: false },
    { extensions: 'video/*', isSingle: false }
  ];

  /* fileType = [
    {
      fType:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
      isType: false
    },
    { fType: '.csv, text/csv', isType: true },
    { fType: '.pdf', isType: false },
    { fType: 'image/*', isType: false },
    {
      fType:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv, text/csv, .pdf',
      isType: false
    }
  ]; */

  public config1: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-right',
    timeout: 20000,
    limit: 1
  });

  ngOnInit() {
    this.subscriptions.getAutoSearchHit = this.dataStorageService
      .getautoSearchHit()
      .subscribe((value) => {
        if (value) {
          this.testFileName = 'Please Select File';
          if (this.inputText instanceof Object) {
            if (this.inputText.nativeElement instanceof HTMLElement) {
              this.inputText.nativeElement.innerHTML = this.testFileName;
            }
          }
        }
      });
  }

  clearSubscriptions(key?: string) {
    if (typeof key === 'string') {
      this.subscriptions[key].unsubscribe();
      return;
    }
    for (const eachKey in this.subscriptions) {
      if (this.subscriptions.hasOwnProperty(eachKey)) {
        this.subscriptions[eachKey].unsubscribe();
      }
    }
  }

  popToastS(data) {
    const toast: Toast = {
      type: 'success',
      showCloseButton: true,
      body: '<div class="toast-msg success-msg">' + data + '</div>',
      bodyOutputType: BodyOutputType.TrustedHtml
    };
    if (
      this.cmsFileResponseData &&
      this.cmsFileResponseData.status &&
      this.cmsFileResponseData.status === 401
    ) {
      this.cmsFileResponseData = undefined;
    } else {
      this.toasterService.pop(toast);
    }
  }
  popToastW(data) {
    const toast: Toast = {
      type: 'warning',
      showCloseButton: true,
      // tslint:disable-next-line: max-line-length
      body: '<div class="toast-msg warning-msg">' + data + '</div>',
      bodyOutputType: BodyOutputType.TrustedHtml
    };
    this.toasterService.pop(toast);
  }

  popToastI(data) {
    const toast: Toast = {
      type: 'info',
      showCloseButton: true,
      body: '<div class="toast-msg info-msg">' + data + '</div>',
      bodyOutputType: BodyOutputType.TrustedHtml
    };
    this.toasterService.pop(toast);
  }

  popToastF(data) {
    const toast: Toast = {
      type: 'error',
      showCloseButton: true,
      body: '<div class="toast-msg error-msg">' + data + '</div>',
      bodyOutputType: BodyOutputType.TrustedHtml
    };
    if (
      this.cmsFileResponseData &&
      this.cmsFileResponseData.status &&
      this.cmsFileResponseData.status === 401
    ) {
      this.cmsFileResponseData = undefined;
    } else {
      this.toasterService.pop(toast);
    }
  }

  popToastWC(data) {
    const toast: Toast = {
      type: 'warning',
      showCloseButton: true,
      // tslint:disable-next-line: max-line-length
      body:
        '<div class="toast-msg warning-msg"><a href="assets/templates/Commodity_Cost.xlsx">Please upload file as per the template with valid data. <p class="toast-link"> Download Template</p></a></div>',
      bodyOutputType: BodyOutputType.TrustedHtml
    };
    this.toasterService.pop(toast);
  }
  popToastWP(data) {
    const toast: Toast = {
      type: 'warning',
      showCloseButton: true,
      // tslint:disable-next-line: max-line-length
      body:
        '<div class="toast-msg warning-msg"><a href="assets/templates/Product_Cost.xlsx">Please upload file as per the template with valid data. <p class="toast-link"> Download Template</p></a></div>',
      bodyOutputType: BodyOutputType.TrustedHtml
    };
    this.toasterService.pop(toast);
  }

  popToastBenchMark(data) {
    const toast: Toast = {
      type: 'warning',
      showCloseButton: true,
      // tslint:disable-next-line: max-line-length
      body:
        '<div class="toast-msg warning-msg"><a href="assets/templates/Benchmarking.xlsx">Please upload file as per the template with valid data. <p class="toast-link"> Download Template</p></a></div>',
      bodyOutputType: BodyOutputType.TrustedHtml
    };
    this.toasterService.pop(toast);
  }
  popToastBenchmarkPercentileReport(data) {
    const toast: Toast = {
      type: 'warning',
      showCloseButton: true,
      // tslint:disable-next-line: max-line-length
      body:
        '<div class="toast-msg warning-msg"><a href="assets/templates/Benchmark_Percentile_Report.xlsx">Please upload file as per the template with valid data. <p class="toast-link"> Download Template</p></a></div>',
      bodyOutputType: BodyOutputType.TrustedHtml
    };
    this.toasterService.pop(toast);
  }

  popToastPartMatch(data) {
    const toast: Toast = {
      type: 'warning',
      showCloseButton: true,
      // tslint:disable-next-line: max-line-length
      body:
        '<div class="toast-msg warning-msg"><a href="assets/templates/Part_Matching.xlsx">Please upload file as per the template with valid data. <p class="toast-link"> Download Template</p></a></div>',
      bodyOutputType: BodyOutputType.TrustedHtml
    };
    this.toasterService.pop(toast);
  }

  popToastTCW(data) {
    const toast: Toast = {
      type: 'warning',
      showCloseButton: true,
      // tslint:disable-next-line: max-line-length
      body:
        '<div class="toast-msg warning-msg"><a href="assets/templates/MVABenchmarking.xlsx" download="" id="Transformation_Excel_Link_Src">Please upload file as per the template with valid data.<p class="toast-link"> Download Template</p></a></div>',
      bodyOutputType: BodyOutputType.TrustedHtml
    };
    this.toasterService.pop(toast);
  }

  popToastTCS(data) {
    const toast: Toast = {
      type: 'success',
      showCloseButton: true,
      body: '<div class="toast-msg success-msg">' + data + '</div>',
      bodyOutputType: BodyOutputType.TrustedHtml
    };
    if (
      this.cmsFileResponseData &&
      this.cmsFileResponseData.status &&
      this.cmsFileResponseData.status !== 401
    ) {
      this.toasterService.pop(toast);
      this.cmsFileResponseData = undefined;
    } else {
      this.toasterService.pop(toast);
    }
  }

  public uploadFile() {
    this.file = false;
    if (this.inputText instanceof Object) {
      if (this.inputText.nativeElement instanceof HTMLElement) {
        this.inputText.nativeElement.innerHTML = 'Please select file';
      }
    }
    try {
      if (this.myInputVariable.nativeElement.files) {
        const files = this.myInputVariable.nativeElement.files;
        const formData = new FormData();
        if (files.length === 0) {
          const alertMsg = 'Please select File';
          this.popToastI(alertMsg);
          return;
        }
        this.filename = files[0].name;
        if (this.fileDataObject.title === 'SUPPLIER_RFX_DETAILS' ||
          this.fileDataObject.title === 'bomtransformationsfileupdate' ||
          this.fileDataObject.title === 'VENDOR_RFX_DETAILS') {
          formData.append('filename', files[0]);
        }
        else {
          formData.append('attachments', files[0]);
        }
        this.myInputVariable.nativeElement.value = '';
        this.dataStorageService.setTransformationsBomuploadres(undefined);
        this.fileuploadService
          .getUploadfile(formData, this.fileDataObject, files[0])
          .subscribe(
            (data) => {
              this.cmsFileResponseData = JSON.parse(JSON.stringify(data));
              if (this.fileDataObject.title === 'bomtransformationsfileupdate') {
                this.dataStorageService.setTransformationsBomuploadres(data);
              }
              if (this.fileDataObject.title === 'costlookup') {
                if (data.status === 0) {
                  this.dataStorageService.setfileuploadres(true);
                  this.dataStorageService.setCostLookupFileupload(data);
                  this.dataStorageService.setUploadResponse(data);
                }
                else {
                  this.popToastF(data.message);
                }
              }
              else if (this.fileDataObject.title === 'SUPPLIER_RFX_DETAILS' ||
                this.fileDataObject.title === 'VENDOR_RFX_DETAILS') {
                if (data.responseStatus.code === 200) {
                  this.popToastS(data.message);
                  this.dataStorageService.setCostLookupFileupload(data);
                  this.dataStorageService.setUploadResponse(data);
                  this.onFileUpload.emit(data);
                } else {
                  this.popToastF(data.message);
                }
              }
              else if (this.fileDataObject.title === 'NPI') {
                if (data.responseStatus.code === 200) {
                  this.popToastS(data.message);
                  this.dataStorageService.setCostLookupFileupload(data);
                } else {
                  this.popToastF(data.message);
                }
              } else if (this.fileDataObject.title === 'partSearch') {
                if (data.responseStatus.code === 200) {
                  this.popToastS(data.message);
                  this.dataStorageService.setPartSearchID(data.result);
                } else {
                  this.popToastF(data.message);
                }
              } else if (this.fileDataObject.title === 'partmatch') {
                if (data.responseStatus.code === 200) {
                  this.popToastS(data.message);
                  this.dataStorageService.setCostLookupFileupload(data);
                } else {
                  this.popToastF(data.message);
                }
              } else if (this.fileDataObject.title === 'commodityManager') {
                if (data.responseStatus.code === 200) {
                  if (typeof data.result == 'string') {
                    this.dataStorageService.setCommoditySyncID(data.result);
                  } else {
                    this.popToastS(data.message);
                    this.dataStorageService.setCommoditySyncID(true);
                  }
                } else {
                  if (data.message.includes('<br/>')) {
                    this.dataStorageService.setCommoditySyncID(data.message);
                    this.popToastF('File Uploding failed');
                  } else {
                    this.popToastF(data.message);
                  }
                }
              } else if (
                this.fileDataObject.title === 'benchmark' ||
                this.fileDataObject.title === 'bm-percentile'
              ) {
                if (data.status === 0) {
                  this.popToastS(data.message);
                  this.dataStorageService.setCostLookupFileupload(data);
                } else {
                  this.popToastF(data.message);
                }
              } else if (this.fileDataObject.title === 'tc') {
                if (data.status === 0) {
                  this.popToastTCS(data.message);
                  this.dataStorageService.setTCLookupFileupload(data);
                } else {
                  this.popToastF(data.message);
                }
              } else if (this.fileDataObject.title === 'curator') {
                if (data.responseStatus.code === 417) {
                  this.popToastF('Invalid file Uploaded');
                } else if (!data.message.includes('Exception')) {
                  this.popToastTCS('Upload successful.');
                  this.dataStorageService.setUploadAlertsData(data);
                } else {
                  this.popToastF('Upload failed.');
                }
              } else if (this.fileDataObject.title === 'thresholdUpload') {
                if (data.message === 'success') {
                  this.popToastTCS('Upload successful.');
                } else if (data.responseStatus.code === 417) {
                  this.popToastF('Invalid file Uploaded');
                } else {
                  this.popToastF('Upload failed.');
                }
                this.dataStorageService.setUploadThresholdData(data);
              }
              this.dataObject.emit(data);
            },
            (error) => {
              console.log(' File Upload Error', error);
              this.popToastF(
                ' Please upload file as per the template with valid data. You may download template here.'
              );
            }
          );
      } else {
        const alertMsg = 'Please select File';
        this.popToastI(alertMsg);
      }
      this.onFileUpload.emit();
    } catch (Exception) {
      console.log('Exception in File Upload Method: ', Exception);
    }
  }

  public onChange(event) {
    if (event instanceof Object) {
      if (event.target instanceof HTMLElement) {
        if (event.target.files instanceof FileList) {
          if (event.target.files.length > 0) {
            const files = event.target.files;
            this.testFileName = files[0].name;
            this.dataStorageService.setfileName(this.testFileName);
            const ellipsis = '...';
            try {
              if (
                files[0].type ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              ) {
                if (this.inputText instanceof Object) {
                  if (this.inputText.nativeElement instanceof HTMLElement) {
                    this.inputText.nativeElement.innerHTML =
                      this.testFileName.substring(0, 13) + ellipsis;
                  }
                }
              } else {
                if (this.fileDataObject.title === 'benchmark') {
                  const alertMsg =
                    'Please upload file as per the template with valid data. You may download template';
                  this.myInputVariable.nativeElement.value = '';
                  this.popToastBenchMark(alertMsg);
                } else if (this.fileDataObject.title === 'costlookup') {
                  const alertMsg =
                    'Please upload file as per the template with valid data. You may download template';

                  if (this.focus === 'Commodity') {
                    this.popToastWC(alertMsg);
                  } else {
                    this.popToastWP(alertMsg);
                  }
                } else if (this.fileDataObject.title === 'partmatch') {
                  const alertMsg =
                    'Please upload file as per the template with valid data. You may download template';
                  this.popToastPartMatch(alertMsg);
                } else if (this.fileDataObject.title === 'bm-percentile') {
                  const alertMsg =
                    'Please upload file as per the template with valid data. You may download template';
                  this.popToastBenchmarkPercentileReport(alertMsg);
                } else if (this.fileDataObject.title === 'tc') {
                  const alertMsg =
                    'Please upload file as per the template with valid data. You may download template';
                  this.popToastTCW(alertMsg);
                }
              }
            } catch (Exception) {
              console.log('Exception in File Onchange Method ', Exception);
            }
          } else {
            this.testFileName = 'Please Select File';
            this.dataStorageService.setfileName(this.testFileName);
            if (this.inputText instanceof Object) {
              if (this.inputText.nativeElement instanceof HTMLElement) {
                this.inputText.nativeElement.innerHTML = this.testFileName;
              }
            }
          }
        } else {
          this.testFileName = 'Please Select File';
          this.dataStorageService.setfileName(this.testFileName);
          if (this.inputText instanceof Object) {
            if (this.inputText.nativeElement instanceof HTMLElement) {
              this.inputText.nativeElement.innerHTML = this.testFileName;
            }
          }
        }
      } else {
        this.testFileName = 'Please Select File';
        this.dataStorageService.setfileName(this.testFileName);
        if (this.inputText instanceof Object) {
          if (this.inputText.nativeElement instanceof HTMLElement) {
            this.inputText.nativeElement.innerHTML = this.testFileName;
          }
        }
      }
    }
  }
  public onUploadRfqFile(event) {
    if (event instanceof Object) {
      if (event.target instanceof HTMLElement) {
        if (event.target.files instanceof FileList) {
          if (event.target.files.length > 0) {
            const files = event.target.files;
            switch (files[0].type) {
              case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
              case 'application/vnd.ms-excel':
              case 'application/json':
                this.callUploadService(files)
                break
              default:
                this.toasterService.pop('error', 'Invalid File uploaded, upload .xls, .xlsx files only')
                break
            }
          }
        }
      }
    }
  }

  callUploadService(files) {
    try {
      this.onFileUploadPreloader.emit(true);
      const formData = new FormData();
      if (
        this.fileDataObject.title === 'CONTENT' ||
        this.fileDataObject.title === 'Collaboration'
      ) {
        if (this.fileDataObject.title === 'Collaboration') {
          this.onFileUpload.emit(files[0]);
          if (
            this.myInputVariable &&
            this.myInputVariable.nativeElement
          ) {
            this.myInputVariable.nativeElement.value = '';
          }
          return;
        }
        formData.append('file', files[0]);
      } else if (this.fileDataObject.title === 'UPLOAD_FOREX_DATA') {
        formData.append('forexData', files[0]);
      } else {
        formData.append('filename', files[0]);
      }
      this.apiService
        .fileUpload(
          formData,
          this.fileDataObject.servicePath,
          '',
          this.fileDataObject.title,
          this.fileDataObject.formParams
        )
        .subscribe(
          (data) => {
            if (
              this.fileDataObject.title === 'customerConfiguration' ||
              this.fileDataObject.title === 'UPLOAD_FOREX_DATA'
            ) {
              this.onFileUpload.emit(data);
            }

            if (this.fileDataObject.title === CONFIG_TEMPLATE_IMPORT) {
              this.onFileUpload.emit(data);
            }
            this.cmsFileResponseData = JSON.parse(JSON.stringify(data));
            if (data instanceof Object) {
              if (data.responseStatus instanceof Object) {
                if (typeof data.responseStatus.code === 'number') {
                  if (
                    this.fileDataObject.title === 'RFQ' ||
                    this.fileDataObject.title === 'PRODUCT_RFQ'
                  ) {
                    if (data.responseStatus.code === 406) {
                      this.popToastF(data.message);
                    }
                    if (data.responseStatus.code === 200) {
                      this.onFileUpload.emit(data.result);
                      this.popToastS('File Uploaded Successfully');
                    }
                    if (
                      data.responseStatus.code === 1000 &&
                      this.fileDataObject.title === 'PRODUCT_RFQ'
                    ) {
                      this.popToastF(data.message);
                    }
                  }
                  if (this.fileDataObject.title === 'Collaboration') {
                    switch (data.responseStatus.code) {
                      case 200:
                      case 202:
                        this.onFileUpload.emit(data.result);
                        this.popToastS(data.result);
                        break;
                      case 500:
                      case 550:
                      default:
                        this.popToastF(data.message);
                        break;
                    }
                  }
                  if (
                    this.fileDataObject.title === 'SIMULATION' ||
                    this.fileDataObject.title === 'CONTENT'
                  ) {
                    switch (data.responseStatus.code) {
                      case 200:
                      case 202:
                        this.onFileUpload.emit(data.result);
                        this.popToastS(data.message);
                        break;
                      case 500:
                      case 550:
                      default:
                        this.popToastF(data.message);
                        break;
                    }
                  }
                  if (this.fileDataObject.title === 'DETAILS') {
                    switch (data.responseStatus.code) {
                      case 200:
                        this.onFileUpload.emit(data.result);
                        this.popToastS('File Uploaded Successfully');
                        break;
                      case 550:
                      default:
                        this.popToastF('Please Upload Valid File');
                        break;
                    }
                  }
                  if (this.fileDataObject.title === 'ADHOC') {
                    switch (data.responseStatus.code) {
                      case 200:
                        this.onFileUpload.emit(data.result);
                        this.popToastS('File Uploaded Successfully');
                        break;
                      default:
                        this.popToastF('Please Upload Valid File');
                        break;
                    }
                  } else if (
                    this.fileDataObject.title === 'PREVIEW' ||
                    this.fileDataObject.title === 'PRODUCT_DETAILS'
                  ) {
                    this.onFileUploadPreloader.emit(false);
                    switch (data.responseStatus.code) {
                      case 200:
                        this.onFileUpload.emit(data.result);
                        this.popToastS(data.message);
                        break;
                      case 500:
                      case 550:
                      default:
                        this.popToastF(data.message);
                        break;
                    }
                  }
                  if (this.fileDataObject.title === 'PRODUCT_PREVIEW') {
                    this.onFileUploadPreloader.emit(false);
                    switch (data.responseStatus.code) {
                      case 200:
                        this.onFileUpload.emit(data);
                        this.popToastS(data.message);
                        break;
                      case 400:
                        this.onFileUploadPreloader.emit('400');
                        this.popToastF(data.message);
                        break;
                      default:
                        this.popToastF(data.message);
                        break;
                    }
                  }
                }
              }
            }
            this.myInputrfqVariable.nativeElement.value = '';
          },
          (error) => {
            this.popToastF(error.message || '');
          }
        );
    } catch (e) {
      console.log('Exception in File Onchange Method ', e);
    }
  }

  ngOnDestroy() {
    this.clearSubscriptions();
    this.dataStorageService.setPartSearchID(undefined);
    this.dataStorageService.setCommoditySyncID(undefined);
  }
}
