import { DropDownClass } from './../../common/models/dropdown.model';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy
} from '@angular/core';
import { DropDownListService } from 'app/modules/common/dropdown-list.service';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { UtilService } from 'app/modules/common/utills/util.service';
import { SubscriptionService } from 'app/modules/common/subscription.service';
import { SubscriptionLike } from 'rxjs';

@Component({
  selector: 'custom-dropdown',
  templateUrl: 'dropdown.component.html',
  styleUrls: ['./dropdown.component.sass'],
  providers: []
})
export class DropdownComponent implements OnInit, OnChanges, OnDestroy {
  constructor(
    private router: Router,
    private location: Location,
    private dropDownListService: DropDownListService,
    private scopeDataService: ScopeDataService,
    private subscriptionService: SubscriptionService,
    private utilService: UtilService
  ) {}

  @Input() selectedItem: any;
  @Input() target: string;
  @Input() values: Array<object> = [];
  @Input() targerId = '';
  @Input() showSelect = false;
  drillDownDropDown: any = '';
  isPredictiveSubscription: SubscriptionLike;
  dropDownListServiceSubscription: SubscriptionLike;
  focusSubscription: SubscriptionLike;
  dropdownlistSubscription: SubscriptionLike;
  getOpportunityClickSubscription: SubscriptionLike;
  isDisabled = false;
  public selectedDropdownItem: any;
  public defaultValue: any;
  routePath: string;
  public items: any;
  route: string;
  focus: string;

  value: any;

  ngOnInit() {
    // this.dropdownlistSubscription = this.dropDownListService
    //   .getDrillDropDown()
    //   .subscribe(value => {
    //     if (value !== undefined) {
    //       if (value instanceof Object) {
    //         if (typeof value.text === 'string') {
    //           if (value.text.length > 0) {
    //             this.dropDownListService._currentValue.next(value);
    //             this.findDefaultvalue();
    //             const evt = new MouseEvent('click');
    //             document.body.dispatchEvent(evt);
    //           }
    //         }
    //       }
    //     }
    //   });
    this.focus = this.scopeDataService.getFocusValue();
    this.getOpportunityClickSubscription = this.subscriptionService
      .getOpportunityClick()
      .subscribe(val => {
        if (val !== 'benchmark') {
          //   this.changesBasedOnScope(this.focus);
        }
      });

    // let resetObject: any = undefined;
    // this.dropDownListService.setSelectedValue(resetObject);
    // this.dropDownListService.setSelectedDropDownValue(resetObject);
    // this.findDefaultvalue();

    /* if (this.dropDownListService.getSelectedValue() !== undefined) {
      let item = this.dropDownListService.getBotResponseviewtemp();
      //this.defaultValue = this.dropDownListService.getDropDownValue(item);
      this.dropDownListService.setSelectedDropDownValue(this.dropDownListService.getDropDownValue(item));
      this.selected(this.dropDownListService.getDropDownValue(item));
    }
    this.dropDownListService.getBotResponseView().subscribe(
      (item) => {
        this.dropDownListService.setSelectedDropDownValue(this.dropDownListService.getDropDownValue(item));
        this.selected(this.dropDownListService.getDropDownValue(item));
      }
    ); */

    this.focusSubscription = this.scopeDataService
      .getFocus()
      .subscribe(value => {
        this.items = [];
        this.dropDownListService._currentValue.next(undefined);
        // let resetObject: any = undefined;
        // this.dropDownListService.setSelectedValue(resetObject);
        this.findPath(this.target);
        //  this.findDefaultvalue();
      });

    this.dropDownListServiceSubscription = this.dropDownListService
      .getSelectedValue()
      .subscribe(item => {
        this.selected(item);
      });

    this.router.events.subscribe(val => {
      try {
        if (this.items !== undefined) {
          if (this.items.length !== 0) {
            if (val instanceof NavigationEnd) {
              this.findPath(this.target);
              this.routePath = val['url'];
            }
          }
        }
      } catch (e) {}
    });

    this.findPath(this.target);
  }
  changesBasedOnScope(value) {
    this.items = [];
    // let resetObject: any = undefined;
    // this.dropDownListService.setSelectedValue(resetObject);

    this.findPath(this.target);
    // this.findDefaultvalue();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.showSelect = false;
    this.focusSubscription = this.scopeDataService
      .getFocus()
      .subscribe(value => {
        this.items = [];
        const resetObject = undefined;
        this.dropDownListService._currentValue.next(undefined);
        this.dropDownListService.setSelectedValue(resetObject);
        this.findPath(this.target);
        //  this.findDefaultvalue();
      });
    try {
      for (const propName in changes) {
        const change = changes[propName];

        const curVal = JSON.stringify(change.currentValue);
        const prevVal = JSON.stringify(change.previousValue);
        const changeLog = `${propName}: currentValue = ${curVal}, previousValue = ${prevVal}`;
        if (curVal !== prevVal) {
          if (changes.values.currentValue.dropdown) {
            this.values = changes.values.currentValue.dropdown;
          } else {
            this.values = change.currentValue;
          }
          this.items = [];
          if (this.values !== undefined && this.values.length > 0) {
            let dropdownclass: DropDownClass;
            let id: any = null;
            let text: any = null;
            let dim: any = null;
            let hirearchies: any = null;
            let dbcolumnname: any = null;
            let spendDim: any = null;
            let savingsDim: any = null;
            let KeyDim: any = null;
            let view: any = null;
            let opportunitydimension: any = null;
            let flagcolumn: any = null;
            let isdefault: any = null;
            let flagdimension: any = null;
            let spend_dim: any = null;
            let opportunitycolumn: any = null;
            let mpndimension: any = null;
            let title: any = null;
            for (let i = 0; i < this.values.length; i++) {
              let data: any;
              data = this.values[i];
              id = data.dropdownId;
              text = data.text;
              dim = data.dim;
              hirearchies = data.hirearchies;
              dbcolumnname = data.dbcolumnname;
              spendDim = data.spendDim;
              savingsDim = data.savingsDim;
              KeyDim = data.KeyDim;
              view = data.view;
              opportunitydimension = data.opportunitydimension;
              flagcolumn = data.flagcolumn;
              isdefault = data.isdefault;
              flagdimension = data.flagdimension;
              spend_dim = data.spend_dim;
              opportunitycolumn = data.opportunitycolumn;
              mpndimension = data.mpndimension;
              title = data.title;
              if (!text) {
                text = data.text;
              }
              dropdownclass = new DropDownClass(
                id,
                text,
                dim,
                hirearchies,
                dbcolumnname,
                spendDim,
                savingsDim,
                KeyDim,
                view,
                opportunitydimension,
                isdefault,
                flagdimension,
                flagcolumn,
                spend_dim,
                opportunitycolumn,
                mpndimension,
                title
              );
              if (text !== null) { this.items.push(dropdownclass); }
            }
            if (this.items !== undefined) {
              this.findDefaultvalue();
            }
            // this.items = this.values;
          }
        }
      }
    } catch (Error) {
      console.error(Error);
    }
  }

  findDefaultvalue() {
    if (this.utilService.getpath('') !== 'opportunities') {
      this.isDisabled = false;
    }
    if (
      this.dropDownListService._currentValue.getValue() === undefined ||
      this.dropDownListService.getSelectedValue() === undefined
    ) {
      try {
        if (this.items !== undefined) {
          for (const entry of this.items) {
            if (entry['isdefault']) {
              const defaultObject: Array<object> = [];
              defaultObject.push(entry);
              defaultObject[0]['id'] = 1;
              this.defaultValue = defaultObject[0];
              break;
            } else if (this.values !== undefined && this.values.length > 0) {
              this.defaultValue = this.items[0];
            }
          }
        }
        this.selected(this.defaultValue);
      } catch (e) {
        console.log(e);
      }
    } else {
      if (this.values !== undefined && this.values.length > 0) {
        const curObj = this.dropDownListService._currentValue.getValue();
        if (curObj !== undefined && this.utilService.getpath('') !== 'risk') {
          if (this.containsObject(curObj, this.values)) {
            for (let i = 0; i < this.values.length; i++) {
              if (this.values[i]['view'] === curObj.view) {
                this.defaultValue = curObj;

                break;
              }
            }
            // this.selected(this.items[itemIndex]);
          } else {
            this.defaultValue = this.items[0];
          }
        } else {
          this.defaultValue = this.items[0];
        }
        // this.dropDownListService.setSelectedValue( this.defaultValue);
        // this.subscriptionService.getOpportunityClick().subscribe((val)=>{if(val!=benchmark)})
        this.onSelected(this.defaultValue);
      } else {
        const curObj = this.dropDownListService._currentValue.getValue();
        if (
          this.containsObject(curObj, this.items) &&
          this.utilService.getpath('') !== 'forecastvsactual'
        ) {
          //  let itemIndex=this.items.indexOf(curObj);
          //  console.log(itemIndex);
          // //  let index = this.items.findIndex(curObj => curObj.views==="Q1'18");
          // //  console.log(index);
          let index;
          for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].text.toLowerCase() === curObj.text.toLowerCase()) {
              index = i;
              break;
            }
          }
          // this.selected(this.items[itemIndex]);
          for (const item of this.items) {
            try {
              if (item.text.toLowerCase() === curObj.text.toLowerCase()) {
                this.selected(this.items[index]);
              }
            } catch (e) {
              console.log(e);
            }
          }
        } else {
          try {
            if (this.utilService.getpath('') === 'forecastvsactual') {
              let defaultObject: Array<object> = [];
              for (const entry of this.items) {
                if (entry['isdefault']) {
                  defaultObject = entry;
                  break;
                }
              }
              this.selected(defaultObject);
            } else {
              if (this.items) {
                this.selected(this.items[0]);
              }
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    }
    this.subscriptionService.setfcDefaultdropdownValue(this.defaultValue);
  }

  findPath(loadTarget) {
    if (this.utilService.getpath('') !== 'opportunities') {
      this.isDisabled = false;
    }
    this.items = [];
    if (this.values !== undefined && this.values.length > 0) {
      let dropdownclass: DropDownClass;
      let id: any = null;
      let text: any = null;
      let dim: any = null;
      let hirearchies: any = null;
      let dbcolumnname: any = null;
      let spendDim: any = null;
      let savingsDim: any = null;
      let KeyDim: any = null;
      let view: any = null;
      let opportunitydimension = null;
      let flagcolumn: any = null;
      let isdefault: any = null;
      let flagdimension: any = null;
      let spend_dim: any = null;
      let opportunitycolumn: any = null;
      let mpndimension: any = null;
      let title: any = null;
      for (let i = 0; i < this.values.length; i++) {
        let data: any;
        data = this.values[i];

        id = data.dropdownId;
        text = data.text;
        dim = data.dim;
        hirearchies = data.hirearchies;
        dbcolumnname = data.dbcolumnname;
        spendDim = data.spendDim;
        savingsDim = data.savingsDim;
        KeyDim = data.KeyDim;

        opportunitydimension = data.opportunitydimension;
        flagcolumn = data.flagcolumn;
        isdefault = data.isdefault;
        flagdimension = data.flagdimension;
        spend_dim = data.spend_dim;
        opportunitycolumn = data.opportunitycolumn;
        mpndimension = data.mpndimension;
        title = data.title;
        view = data.view;
        if (!text) {
          text = data.text;
        }
        dropdownclass = new DropDownClass(
          id,
          text,
          dim,
          hirearchies,
          dbcolumnname,
          spendDim,
          savingsDim,
          KeyDim,
          view,
          opportunitydimension,
          isdefault,
          flagdimension,
          flagcolumn,
          spend_dim,
          opportunitycolumn,
          mpndimension,
          title
        );
        if (text !== null) {
          this.items.push(dropdownclass);
        }
      }
      if (this.items !== undefined) {
        this.getOpportunityClickSubscription = this.subscriptionService
          .getOpportunityClick()
          .subscribe(val => {
            if (val !== 'benchmark') {
              this.findDefaultvalue();
            }
          });
      }
      // this.items = this.values;
    } else {
      if (this.location.path() !== '') {
        const path = this.utilService.getpath(this.target);

        if (path !== undefined && loadTarget === undefined) {
          if (
            this.scopeDataService.getFocusValue().toLowerCase() === 'commodity'
          ) {
            this.items = this.dropDownListService.getDropDownOption(path);
          } else {
            this.items = this.dropDownListService.getProductDropDownOption(
              path
            );
          }

          if (this.items !== undefined) {
            this.findDefaultvalue();
          }
        } else {
          this.items = this.dropDownListService.getDropDownOption(loadTarget);
          if (this.items !== undefined) {
            this.findDefaultvalue();
          }
        }
      }
    }
  }

  onSelected(obj: any) {
    this.showSelect = false;
    // onSelected() {
    //  this.dropDownListService.setSelectedValue(this.selectedDropdownItem, false);
    // this.subscriptionService.getOpportunityClick().subscribe((val)=>{if(val===benchmark)})
    this.dropDownListService.setSelectedValue(obj, false);
  }
  public selected(value: any): void {
    this.defaultValue = value;
    this.dropDownListService.setSelectedDropDownValue(value);
    // this.dropDownListService.setSelectedValue(value);
  }

  containsObject(obj, list) {
    let i;
    try {
      for (i = 0; i < list.length; i++) {
        if (list[i].view.toLowerCase() === obj.view.toLowerCase()) {
          return true;
        }
      }
      return false;
    } catch (e) {}
  }

  ngOnDestroy() {
    if (this.dropdownlistSubscription) {
      this.dropdownlistSubscription.unsubscribe();
    }
    if (this.isPredictiveSubscription) {
      this.isPredictiveSubscription.unsubscribe();
    }
    if (this.focusSubscription) {
      this.focusSubscription.unsubscribe();
    }
    if (this.dropDownListServiceSubscription) {
      this.dropDownListServiceSubscription.unsubscribe();
    }
    if (this.getOpportunityClickSubscription) {
      this.getOpportunityClickSubscription.unsubscribe();
    }
    this.isDisabled = false;
    this.values = [];
    this.target = '';
  }
}
