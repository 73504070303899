import { FormControl } from '@angular/forms'
import { cloneDeep } from 'lodash'
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  ElementRef,
  AfterViewInit,
  HostListener,
} from '@angular/core'
import { LevaQuickFilterService } from './leva-quick-filter.service'
import { BodyOutputType, Toast, ToasterService } from 'angular2-toaster'
import { DialogBoxService, DialogButtonName, LdDialog, DialogType, DialogSize } from 'app/modules/leva-ui-library/components/dialog-box/dialog-box.service'
import { DataStorageService } from 'app/modules/common/data-storage.service'
import { AddFilterComponent } from './add-filter/add-filter.component';
@Component({
  selector: 'leva-quick-filter',
  templateUrl: './leva-quick-filter.component.html',
  styleUrls: ['./leva-quick-filter.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class LevaQuickFilterComponent implements OnInit, AfterViewInit {

  @Input('quickFilterConfig') quickFilterConfig
  @Input('moduleConfig')
  set moduleConfig(config) {
    this.levaQuickFilterService.setModule(config)
  }
  @Output() quickFilterSelect = new EventEmitter()
  @Output() quickFilterClearSelection = new EventEmitter()
  @Output() onMoreFilterSelect = new EventEmitter()
  @ViewChild('customTooltip', { static: false })
  customTooltip: ElementRef
  @ViewChild(AddFilterComponent, { static: false })
  addFilterComponent: AddFilterComponent
  options = []
  filterCount = 0
  tilesSelected = 0
  dislaynameInput: FormControl = new FormControl()
  valueInput: FormControl = new FormControl()
  popoverTitle = 'Delete'
  popoverMessage = 'Are you sure, Do you want to delete the filter?'
  confirmClicked = false
  cancelClicked = false
  quickFilterOptions = []
  quickFilterLabel = null
  quickFilterType = null
  noDataMessage = 'No Filters are available'
  widthForFilters: number
  numberOfTiles: number
  tilesList = []
  dropDownList = []
  searchValue
  filters = {
    label: 'More',
    type: 'multiselect',
    val: 'more-filters',
    id: 'morefilters',
    hideTooltip: true,
    hideClearItem: true,
    data: [],
    show: false,
    isSelectAll: false,
    parentComponent: 'leva-quick-filter'
  }
  tooltipInformation
  tooltipInformationOptimizeKeys = []
  addFilterDialogConfig: LdDialog = {
    id: 'addFilterDialog',
    title: 'Add filter',
    template: true,
    customSize: {
      width: 1200,
      height: 470
    },
    beforeClose: (event) => {
      if (event && event.primary) {
        this.addFilterComponent.checkValidation()
        if (this.addFilterComponent.displayNameInput && (this.addFilterComponent.displayNameInput.value &&
          (this.levaQuickFilterService.enableSave))) {
          this.levaQuickFilterService.showAddPopup = false
          return true
        } else {
          return false
        }
      }
      this.levaQuickFilterService.showAddPopup = false
      return false
    },
    buttons: [
      {
        title: DialogButtonName.CANCEL,
        id: DialogButtonName.CANCEL,
      },
      {
        title: DialogButtonName.SAVE,
        id: DialogButtonName.SAVE,
        primary: true,
        callback: (): boolean => {
          if (this.levaQuickFilterService.isEdit) {
            this.addFilterComponent.onUpdate()
          } else {
            this.addFilterComponent.onSave()
          }
          return true
        }
      }
    ],
    type: DialogType.INFORMATION,
  }
  availableFilterDialogConfig: LdDialog = {
    id: 'availableFilterDialog',
    title: 'Available filters',
    template: true,
    customSize: {
      width: 400,
      height: 400
    },
    buttons: [
      {
        title: DialogButtonName.CANCEL,
        id: DialogButtonName.CANCEL,
      }
    ],
    type: DialogType.INFORMATION,
  }
  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.updateFilterWidth()
    this.assignTilesandDropDown()
  }

  constructor(
    public levaQuickFilterService: LevaQuickFilterService,
    private toasterService: ToasterService,
    private dialogBoxService: DialogBoxService,
    public dataStorageService: DataStorageService,
  ) { }
  ngOnInit() {
    if (this.quickFilterConfig) {
      this.quickFilterLabel = this.quickFilterConfig.label
    }
    this.dataStorageService.quickFilterCount.subscribe((count) => {
      if (count === 0) {
        this.filterCount = this.filterCount + 1
        this.tilesSelected = this.tilesSelected + 1
      }
      else if (count === 1 && this.filterCount > 0) {
        this.filterCount = this.filterCount - 1
        this.tilesSelected = this.tilesSelected - 1
      }
    })
  }
  ngAfterViewInit() {
    this.levaQuickFilterService.updateKeys.subscribe((data) => {
      if (data) {
        this.updateFilterWidth()

        if (this.quickFilterConfig) {
          this.options = this.quickFilterConfig.options
          this.quickFilterOptions = this.options.length
            ? JSON.parse(JSON.stringify(this.options))
            : []
          this.quickFilterType = this.quickFilterConfig.type
        }
        this.assignTilesandDropDown()
      }
    })
  }

  updateFilterWidth() {
    const moduleConfig: any = this.levaQuickFilterService.moduleConfig
    if (document.getElementById(moduleConfig.id)) {
      this.widthForFilters =
        document.getElementById(moduleConfig.id).getBoundingClientRect().width -
        document.querySelector(`[id='${moduleConfig.id}'] [id='label_div']`).getBoundingClientRect().width -
        document.querySelector(`[id='${moduleConfig.id}'] [id='addClear-div']`).getBoundingClientRect().width
      this.numberOfTiles = Math.floor(this.widthForFilters / 110) - 1
    }
  }

  assignTilesandDropDown() {
    this.filterCount = this.quickFilterOptions.filter(
      (item) => item.selected === true
    ).length
    if (this.quickFilterOptions.length > this.numberOfTiles) {
      this.dropDownList = this.quickFilterOptions.slice(
        this.numberOfTiles,
        this.quickFilterOptions.length
      )
      this.tilesList = this.quickFilterOptions.slice(0, this.numberOfTiles)
      this.tilesSelected = this.tilesList.filter(
        (item) => item.selected === true
      ).length
      this.filters.data = []
      this.dropDownList.forEach((element) => {
        element['displayName'] = element.label
        this.filters.data.push(element)
      })
    } else {
      this.tilesList = this.quickFilterOptions
      this.dropDownList = []
    }
  }
  onDelete(options) {
    // backend call to delete
    // this.quickFilterOptions.splice(this.quickFilterOptions.indexOf(options), 1)
    this.filters.data = []
    // this.assignTilesandDropDown()
    //  options.tenantFilterId
    this.levaQuickFilterService
      .deleteFilter(options.tenantFilterId)
      .subscribe((data) => {
        if (data && data.responseStatus.code === 200) {
          const toast: Toast = {
            type: 'success',
            showCloseButton: true,
            body: `<div class="toast-msg info-msg"> Deleted Successfully.</div>`,
            bodyOutputType: BodyOutputType.TrustedHtml,
          }
          this.toasterService.pop(toast)
          if (options.selected) {
            this.levaQuickFilterService.onDelete = true
            this.levaQuickFilterService.setNewFilter(options.label)
          }
          this.levaQuickFilterService.setMainKeyInfo(true)
        } else {
          const toast: Toast = {
            type: 'error',
            showCloseButton: true,
            body: `<div class="toast-msg info-msg">Something went wrong.</div>`,
            bodyOutputType: BodyOutputType.TrustedHtml,
          }
          this.toasterService.pop(toast)
        }
      })
  }
  onQuickFilterSelect(quickFilter) {
    if (quickFilter.selected === false) {
      this.filterCount = this.filterCount + 1
      this.tilesSelected = this.tilesSelected + 1
      quickFilter.selected = true
    } else {
      quickFilter.selected = false
      this.filterCount = this.filterCount - 1
      this.tilesSelected = this.tilesSelected - 1
    }
    this.quickFilterSelect.emit(quickFilter)
  }
  enablePopup() {
    this.levaQuickFilterService.showAddPopup = true
    this.addFilterDialogConfig.buttons[1].title = this.levaQuickFilterService.isEdit ? DialogButtonName.UPDATE : DialogButtonName.SAVE
    if (this.levaQuickFilterService.moduleConfig.showCondition) {
      this.addFilterDialogConfig.customSize.width = 1000
    } else {
      this.addFilterDialogConfig.customSize.width = 1200
    }
    this.dialogBoxService.open(this.addFilterDialogConfig.id)
  }
  editOption(options) {
    this.levaQuickFilterService.filtersList.include = cloneDeep(options.include)
    this.levaQuickFilterService.filtersList.include.forEach(element => {
      if (element.metaInfo.defaultParameter) {
        element.metaInfo.defaultParameter = JSON.parse(element.metaInfo.defaultParameter)
      }
    });
    this.levaQuickFilterService.filtersList.exclude = cloneDeep(options.exclude)
    this.levaQuickFilterService.filtersList.filterName = options.label
    this.levaQuickFilterService.filtersList.filterType = options.filterType
    this.levaQuickFilterService.filtersList.filterModuleId =
      options.filterModuleId
    this.levaQuickFilterService.filtersList.tenantFilterId =
      options.tenantFilterId
    this.levaQuickFilterService.filtersList.include.forEach((ele) => {
      ele.selectedArea = 'inc'
      ele.index =
        ele.selectedArea + '-' + this.levaQuickFilterService.incIndex.toString()
      this.levaQuickFilterService.incIndex =
        this.levaQuickFilterService.incIndex + 1
      this.levaQuickFilterService.lastIncKey = ele.index
    })
    this.levaQuickFilterService.filtersList.exclude.forEach((ele) => {
      ele.selectedArea = 'exc'
      ele.index =
        ele.selectedArea + '-' + this.levaQuickFilterService.excIndex.toString()
      this.levaQuickFilterService.excIndex =
        this.levaQuickFilterService.excIndex + 1
      this.levaQuickFilterService.lastExcKey = ele.index
    })

    this.levaQuickFilterService.incCount = this.levaQuickFilterService.filtersList.include.length
    this.levaQuickFilterService.excCount = this.levaQuickFilterService.filtersList.exclude.length
    this.levaQuickFilterService.showAddPopup = true
    this.levaQuickFilterService.isEdit = true
    this.enablePopup()
  }
  onQuickFilterClearSelection() {
    this.filterCount = 0
    this.tilesSelected = 0
    this.tilesList.forEach((ele) => {
      ele.selected = false
    })
    this.filters.data.forEach((ele) => {
      ele.selected = false
    })
    this.filters.isSelectAll = false
    this.quickFilterOptions = this.options.length
      ? JSON.parse(JSON.stringify(this.options))
      : []
    this.quickFilterClearSelection.emit({
      options: this.quickFilterOptions,
      type: this.quickFilterType,
    })
  }
  onClickTimeRange($event) {
    const event = cloneDeep($event)
    event['popup'] = true
    event.selected = false
    this.quickFilterSelect.emit(event)
  }
  onFilterSelect($event) {
    if ($event.selectedItems.length !== 0) {
      this.filterCount = this.tilesSelected + $event.selectedItems.length
    } else {
      this.filterCount = this.tilesSelected
      $event.configuration.data.forEach((element) => {
        if (element.selected) {
          this.filterCount = this.filterCount + 1
        }
      })
    }

    this.onMoreFilterSelect.emit({
      selectAll: $event.configuration.isSelectAll,
      options: $event.configuration.data,
      selectedItems: $event.selectedItems,
      type: 'more',
    })
  }
  addButton(options) { }
  onAdd() {
    // backend call to add filters
    this.quickFilterOptions.push({
      label: this.dislaynameInput.value,
      value: this.valueInput.value,
      selected: false,
    })
    this.dislaynameInput.reset()
    this.valueInput.reset()
    this.filters.data = []
    this.assignTilesandDropDown()
  }
  onQuickFilterSearch($event) {
    this.searchValue = $event.target.value
  }

  onOptionHover($event) {
    this.onHoverListItem($event.event, $event.item);
  }

  onHoverListItem($event, item) {
    if (true && item && !item.hasOwnProperty('enableEditFilter')) {
      this.tooltipInformation = item
      const el = this.customTooltip.nativeElement
      el.setAttribute(
        'style',
        `left:${$event.pageX + 20}px; top: ${$event.pageY - 20
        }px; display: block;`
      )
    }
  }

  onOutListItem($event) {
    const el = this.customTooltip.nativeElement
    el.style.display = 'none'
  }

  openDialog(id) {
    this.searchValue = ''
    this.dialogBoxService.open(id)
  }
}
