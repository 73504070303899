import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  Output,
  EventEmitter
} from '@angular/core';

import { Collaboration } from './shared/collaboration.model';
import { CollaborationService } from './shared/collaboration.service';

@Component({
  moduleId: module.id,
  selector: 'collaboration',
  templateUrl: 'collaboration.component.html',
  styleUrls: ['./collaboration.component.sass'],
  providers: [CollaborationService]
})
export class CollaborationComponent implements OnInit {
  @Input() type;
  @Input() data;
  @Input() newCollaborationExists;
  @Input() tenantUsers;

  @Output() onCollabPost = new EventEmitter<any>();
  @Output() cancelEmitter = new EventEmitter<any>();
  @Output() changeDetectedEmitter = new EventEmitter<any>();
  @Output() onExpandOrCollapse = new EventEmitter<any>();
  @Output() onDelCollab = new EventEmitter<any>();

  _input;
  editedContent: string;

  @Input() set input(val) {
    this._input = val;
  }

  get input() {
    return this._input;
  }

  constructor(private collaborationService: CollaborationService) {}

  ngOnInit() {}

  onCollaborationPost(input: any) {
    this.onCollabPost.emit(input);
  }

  onChangeDetected(input: any) {
    this.changeDetectedEmitter.emit(input);
  }

  onCancelEmitter(cancelCollboration: any) {
    this.cancelEmitter.emit(cancelCollboration);
  }
  onHistoryExpandOrCollapse(input: any) {
    this.onExpandOrCollapse.emit(input);
  }
  onDelCollabPost(input: any) {
    this.onDelCollab.emit(input);
  }
  onContentEdited(input: any) {
    this.editedContent = input;
  }
}
