export class GenerateGraphObject {

    private GRAPH_OBJECT = {
        title: '',
        legend: true,
        xAxis: null,
        yAxis: null,
        category: null,
        pane: null,
    }

    public setGraphType(type) {
        this.GRAPH_OBJECT = {
            ...this.GRAPH_OBJECT, ...{
                chart: {
                    type,
                    plotBorderWidth: 0,
                    zoomType: 'none'

                }
            }
        };

    }

    public setGraphEvents(events) {
        this.GRAPH_OBJECT['chart']['events'] = { ...this.GRAPH_OBJECT['chart']['events'], ...events };
    }

    public setXAxis(value) {
        this.GRAPH_OBJECT.xAxis = value || null;
    }

    public setLegend(value) {
        this.GRAPH_OBJECT.legend = value || null;
    }

    public setYAxis(value) {
        this.GRAPH_OBJECT.yAxis = value || null;
    }

    public setSeriesData(seriesData) {
        this.GRAPH_OBJECT = {
            ...this.GRAPH_OBJECT, ...{
                series: seriesData || []
            }
        };
    }

    public addOtherInformation(value) {
        this.GRAPH_OBJECT = { ...this.GRAPH_OBJECT, ...value };
    }

    public getGraphData() {
        // console.log(this.GRAPH_OBJECT)
        return this.GRAPH_OBJECT;
    }
}
