import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

@Component({
  selector: 'ld-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(
        () => InputComponent
      ),
      multi: true
    }
  ]
})
export class InputComponent implements OnChanges, ControlValueAccessor {

  @Input() public parentForm: FormGroup;

  @Input() public fieldName: string;

  @Input() public icon: string;

  @Input() public placeholder: string;

  @Input() public title: string;

  @Input() public maxLength: number;

  @Input() type: 'text' | 'email' | 'password' = 'text';

  @Input() disabled = false;

  @Input() label: string;

  @Input() autoFocus = false;

  public value = '';

  public showPasswordEye = false;
  public showPassword = false;

  public changed: (value: string) => void;

  public onTouched: () => void;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.type && changes.type.currentValue) {
      this.showPasswordEye = changes.type.currentValue === 'password';
    }
  }

  get formField(): FormControl {
    return this.parentForm.get(this.fieldName) as FormControl;
  }

  public onChange(value: any): void {
    this.changed(value);
    this.writeValue(value);
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.changed = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public togglePasswordVisible(): void {
    if (this.type === 'text') {
      this.type = 'password';
      this.showPassword = false;
    } else {
      this.type = 'text';
      this.showPassword = true;
    }
  }


}
