export const CsAppFeatures = [{
  id: '1',
  order: '1',
  title: 'Create Tenant',
  view: 'administration'
}];
export const csAppTabFeatures = [{
  administration: [{
    id: '26',
    order: '1',
    view: 'customerconfiguration',
    title: 'Customer Configuration'
  }]
}];
export const csAppHomePage = 'administration';
