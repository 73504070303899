import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, OnInit } from '@angular/core';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import * as _ from 'lodash';


@Component({
  selector: 'child-cell',
  template: `
    <div #container tabindex="0">
        <select
          (change)="onSelect($event)"
          class="form-control full-height full-width font-size-12"
        >
          <option value={{optionStatus}} [selected]="optionStatus == params.value"
          title={{optionStatus}}
            *ngFor="let optionStatus of dropDownValues"
          >
            {{ optionStatus }}
          </option>
        </select>
      </div>
  `,
})
export class SingleSelectComponent implements ICellRendererAngularComp {
  public dropDownValues = [];
  public params;
  public showChanges = false;
  public uncheckedWhole = false;
  public storedRowData: any;

  constructor(
    private gridDataStorageService: DataStorageService
  ) { }

  refresh(): boolean {
    return true;
  }


  public agInit(params: any): void {
    this.params = params;
    this.dropDownValues = params.dataByUser;
  }
  public ngOnInit(): any { }
  onUnCheckWhole(e): any {
    this.uncheckedWhole = true;
  }

  public onSelect(event): any {
    let values = event.target.value;
    this.params.data.showChanges = true;

    this.params.value = this.params.data[this.params.colDef.field] = values;
    const param1 = this.params;
    param1.value = values;
    this.gridDataStorageService.setChangedStatusRow(this.params);

  }
}
