import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { BehaviorSubject } from 'rxjs';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { RmOpprisks } from './rmOpprisks.model';
import { ApiServiceProvider } from 'app/api-service';
import { BaseService } from 'app/modules/common/base.service';
import { insertValue } from 'angular2-mentions/mention';
import { DecimalPipe } from '@angular/common';
import { LinkComponent } from 'app/modules/core/nxt-grid/link.component';
import { GridbuttonComponent } from 'app/modules/core/grid/gridbutton/gridbutton.component';
@Injectable()
export class RmOpprisksService {
  excelPayload;
  viewByLink;
  selectedView = 'summary';
  detailViewData: any;
  selectedTab = 'spendimpact';
  defaultValue: any = '';
  detailActive: any = '';
  userSessionData: any;
  payloadData: any;
  disableHistory = false;
  disableFuture = false;
  defaultHeader = 'OPPORTUNITY_RISK_DETAILS_HISTORY';
  constructor(
    private baseService: BaseService,
    private apiServiceProvider: ApiServiceProvider,
    private dataStorage: DataStorageService
  ) {
    this.userSessionData = this.baseService.getUserInformation();
  }
  getRmOpprisksDimensitionsValueData(rmdata, selectedDimType) {
    const object: any = {
      targetedType: 'MIService',
      method: 'POST',
      servicePath: '/rm/getDimensionData',
      payload: {
        rmList: rmdata,
        dimType: selectedDimType
      }
    };
    return this.apiServiceProvider.post(object);
  }

  getRmOpprisksHeaders(key) {
    const object: any = {
      targetedType: 'MIService',
      method: 'GET',
      servicePath: '/rm/templateheaders',
      formParams: {
        templateKey: key
      }
    };

    return this.apiServiceProvider.post(object);
  }

  getRMSpendImpactSummaryData(payload) {
    const object: any = {
      targetedType: 'MIService',
      method: 'POST',
      servicePath: '/rm/spendimpactsummarydata',
      payload
    };
    this.dataStorage.setRmOppsExcelPayload(object['payload']);
    return this.apiServiceProvider.post(object);
  }

  getRMSpendImpactDetailData(payload) {
    // SERVICE HIT SIMILAR TO SUMMARY TABLE

    const object: any = {
      targetedType: 'MIService',
      method: 'POST',
      servicePath: '/rm/spendimpactdetaildata',
      payload
    };
    this.dataStorage.setRmOppsExcelPayload(object['payload']);
    return this.apiServiceProvider.post(object);
  }

  getRmOpprisksDetailsMPN(
    dinView,
    dim,
    rmdata,
    selectedDropDown,
    selectedYear,
    includefuture
  ) {
    const object: any = {
      targetedType: 'MIService',
      method: 'POST',
      servicePath: '/rm/getMPNDetails',
      payload: {
        viewBy: dinView,
        dim,
        rmList: rmdata,
        dimType: selectedDropDown,
        timePeriod: selectedYear,
        futures: includefuture,
        status: true,
        userEmail: this.userSessionData.email,
        userId: this.userSessionData.userId
      }
    };

    return this.apiServiceProvider.post(object);
  }

  getRMList() {
    const payload = {
      dimension: [],
      isWildCardSearch: false,
      rmList: [],
      dimList: []
    };
    const object: any = {
      targetedType: 'MIService',
      method: 'POST',
      servicePath: '/rm/getRMList',
      payload
    };
    return this.apiServiceProvider.post(object);
  }
  getRmOpprisksDetailsSpendImpact(
    dinView,
    dimensitionValue,
    rmdata,
    selectedDropDown,
    selectedYear,
    includefuture
  ) {
    const object: any = {
      targetedType: 'MIService',
      method: 'POST',
      servicePath: '/rm/getRMDetailTableData',
      payload: {
        viewBy: dinView,
        dim: dimensitionValue,
        rmList: rmdata,
        dimType: selectedDropDown,
        timePeriod: selectedYear,
        futures: includefuture,
        status: true,
        userEmail: this.userSessionData.email,
        userId: this.userSessionData.userId
      }
    };
    return this.apiServiceProvider.post(object);
  }

  getDimensionData(payload) {
    const object: any = {
      targetedType: 'MIService',
      method: 'POST',
      servicePath: '/rm/getDimensionData',
      payload
    };
    return this.apiServiceProvider.post(object);
  }
  getRmOpprisksRawMaterialOpps(
    dinView,
    dimensitionValue,
    rmdata,
    selectedDropDown,
    selectedYear,
    includefuture
  ) {
    const object: any = {
      targetedType: 'MIService',
      method: 'POST',
      servicePath: '/rm/getRawMaterialsOpportunitiesData',
      payload: {
        viewBy: dinView,
        dim: dimensitionValue,
        rmList: rmdata,
        dimType: selectedDropDown,
        timePeriod: selectedYear,
        futures: includefuture,
        status: true,
        userEmail: this.userSessionData.email,
        userId: this.userSessionData.userId
      }
    };
    this.dataStorage.setRmOppsExcelPayload(object['payload']);
    return this.apiServiceProvider.post(object);
  }

  refineTableHeaders(headers) {
    if (headers instanceof Array && headers.length > 0) {
      headers.forEach(i => {
        if (i['field'] === null) {
          delete i['field'];
        } else {
          delete i['children'];
        }

        if (i['link']) {
          i.cellRendererFramework = LinkComponent;
        } else if (i['graph']) {
          i.cellRendererFramework = GridbuttonComponent;
        }

        if (i['children']) {
          if (i['children'].length > 0) {
            for (const child of i['children']) {
              child.cellRenderer = params => {
                const x = params.colDef['field'];
                let formattedData = params.data[x];
                if (typeof params.data[x] === 'number') {
                  if (typeof params.data[x] === 'number') {
                    formattedData = Math.abs(params.data[x]);
                  }
                  if (params.colDef['displayDecimals'] && params.colDef['roundDecimals']) {
                    formattedData = formattedData.toFixed(
                      params.colDef['displayDecimals']
                    );
                  } else {
                    formattedData = Math.round(formattedData);
                  }
                  if (formattedData !== 0) {
                    const num = new DecimalPipe('en-US');
                    if (params.colDef['displayDecimals']  && params.colDef['roundDecimals']) {
                      formattedData = num.transform(
                        formattedData,
                        `1.0-${params.colDef['displayDecimals']}`
                      );
                    } else {
                      formattedData = num.transform(formattedData);
                    }
                  }
                  if (params.colDef['displayFormat']) {
                    if (params.colDef['displayFormat'] === '$') {
                      formattedData = params.colDef['displayFormat'].concat(
                        ' ' + formattedData
                      );
                    } else if (params.colDef['displayFormat'] === '%') {
                      formattedData =
                        formattedData + ' ' + params.colDef['displayFormat'];
                    }
                  }

                  if (params.colDef['applyColorCode']) {
                    if (Number(params.value) < 0) {
                      return `<em class='fa fa-long-arrow-down text-success' style='cursor: auto;' aria-hidden='true'></em>  <span class='text-success'> ${formattedData}</span>`;
                    } else if (Number(params.value) > 0) {
                      return `<em class='fa fa-long-arrow-up text-danger' style='cursor: auto;' aria-hidden='true'></em>  <span class='text-danger'> ${formattedData} </span>`;
                    } else {
                      return formattedData;
                    }
                  } else {
                    return formattedData;
                  }
                } else {
                  return formattedData;
                }
              };
            }
          }
        } else {
          if (!i['link'] && !i['graph'] && !i['drilldown']) {
            i.cellRenderer = params => {
              const x = params.colDef['field'];
              let formattedData = params.data[x];
              if (typeof params.data[x] === 'number') {
                formattedData = Math.abs(formattedData);
                if (params.colDef['displayDecimals'] && params.colDef['roundDecimals']) {
                  formattedData = formattedData.toFixed(
                    params.colDef['displayDecimals']
                  );
                } else {
                  formattedData = Math.round(formattedData);
                }
                if (formattedData !== 0) {
                  const num = new DecimalPipe('en-US');
                  if (params.colDef['displayDecimals'] && params.colDef['roundDecimals']) {
                    formattedData = num.transform(
                      formattedData,
                      `1.0-${params.colDef['displayDecimals']}`
                    );
                  } else {
                    formattedData = num.transform(formattedData);
                  }
                }
                if (params.colDef['displayFormat']) {
                  if (params.colDef['displayFormat'] === '$') {
                    formattedData = params.colDef['displayFormat'].concat(
                      ' ' + formattedData
                    );
                  } else if (params.colDef['displayFormat'] === '%') {
                    formattedData =
                      formattedData + ' ' + params.colDef['displayFormat'];
                  }
                }

                if (params.colDef['applyColorCode']) {
                  if (Number(params.value) < 0) {
                    return `<em class='fa fa-long-arrow-down text-success' style='cursor: auto;' aria-hidden='true'></em>  <span class='text-success'> ${formattedData}</span>`;
                  } else if (Number(params.value) > 0) {
                    return `<em class='fa fa-long-arrow-up text-danger' style='cursor: auto;' aria-hidden='true'></em>  <span class='text-danger'> ${formattedData} </span>`;
                  } else {
                    return formattedData;
                  }
                }
              } else {
                return formattedData;
              }
              return formattedData;
            };
          }
        }
      });
    }
    return headers;
  }
}
