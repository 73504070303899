import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { Bot } from './bot.model';
import { ApiServiceProvider } from 'app/api-service';

@Injectable()
export class BotService{
  constructor(private http: HttpClient, private apiServiceProvider: ApiServiceProvider) { }

  public getHistory(historyPalyload) {
    const object: any = {
      targetedType: 'ChatbotServices',
      method: 'POST',
      payload:historyPalyload.payload,
      servicePath: '/chatBotHistory'
    };
    return this.apiServiceProvider.post(object)
  }



  public getGenericService(url, payload) {
    // return this.post({ "url": url }, payload);
  }
  public getRandomGreeting() {
    return this.apiServiceProvider.get({ url: 'greeting.html', source: 'local' });
  }

  public send(sendPayload) {
    const object: any = {
      targetedType: 'ChatbotServices',
      method: 'POST',
      payload:sendPayload.payload,
      servicePath: '/chatBot'
    };
    return this.apiServiceProvider.post(object);
  }

  getBotAutoPopulatedData(val, list) {

    const payload = {
      targetedType: 'ELS',
      method: 'POST',
      servicePath: '/MultiValueSearch',
      payload: {
        inputs: [val],
        dimension: list,
        isWildCardSearch: true
      }
    }
    return this.apiServiceProvider.post(payload)
  }


}
