import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

import { Widget } from './widget.model';

@Injectable()
export class WidgetService {

  constructor(private http: HttpClient) { }


}
