import { cloneDeep, isEmpty, orderBy } from 'lodash'
import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core'
import { PIE_CHART, BAR_CHART, MAP_CHART } from './configuration'
import { GraphItemService } from './graph-item.service'
import { ColorUtils } from '../../common/utills/ColorUtils.service'
import { SraEmitterService } from 'app/modules/supply-risk-assessment/sra-emitter.service'
import { ShortAnumber } from '../../common/Pipe/ShortANumber'
import { GenericChartComponent } from 'app/modules/high-charts/generic-chart/generic-chart.component'

@Component({
  selector: 'cprt-graph-item',
  templateUrl: './graph-item.component.html',
  styleUrls: ['./graph-item.component.sass'],
  providers: [GraphItemService],
})
export class GraphItemComponent implements OnInit {
  legendClicked: string;
  constructor(
    public graphItemService: GraphItemService,
    private sraEmitterService: SraEmitterService
  ) { }
  @Input() showLegendVal = false
  @Input() OPTIONS
  @Input() hideCustom
  @Input() set filter(filterValue) {
    this.onOptionChange({ val: filterValue })
  }
  @Output() onPointClick = new EventEmitter()
  @ViewChild(GenericChartComponent, { static: false })
  genericChartComponent: GenericChartComponent
  selectedChartView: any = {}
  nodata = true
  noGraph = false
  graphData
  codes = []
  chartType = 'chart'
  additionalParam = null

  ngOnInit() {
    this.legendClicked = ''
    this.nodata = true
    this.plotPieGraph()
    if (this.OPTIONS.metaData.filterOptions) {
      return
    }
    if (this.OPTIONS.filter) {
      this.onOptionChange({ val: this.OPTIONS.filter })
      return
    }
    this.graphItemService.getGraphValues(this.OPTIONS).subscribe(
      (data) => {
        this.resolveGraph(data)
      },
      () => {
        this.nodata = true
      }
    )
  }

  onLegendClick(event) {
    const series = this.genericChartComponent.chart.ref.series;
    for (let i = 1; i < series.length; i++) {
      if (series[i].name !== event.name) {
        series[i].visible ?
          series[i].setVisible(false, false) :
          series[i].setVisible(true, false);
      }
    }
    this.genericChartComponent.chart.ref.redraw()
    if (this.hideCustom) {
      return
    }
    if (this.legendClicked !== event.name) {
      this.legendClicked = event.name
      const obj = {}
      obj[this.additionalParam] = event.name
      this.sraEmitterService.emitSelectedPointParams({
        options: obj,
        metadata: this.additionalParam,
      })
    } else {
      this.legendClicked = ''
      this.sraEmitterService.emitSelectedPointParams({
        metadata: this.additionalParam,
        reset: true
      })
    }
  }

  plotPieGraph() {
    const pieChart = cloneDeep(PIE_CHART)
    pieChart.series[0].data = this.OPTIONS.items
    pieChart.series[0].name = this.OPTIONS.header.toString()
    const showLegendVal = this.showLegendVal
    /*   PIE_CHART.title.text = ele.header.toString();
          PIE_CHART.title['align'] =  'center'; */
    const label = this.OPTIONS.metaData.viewBy ? '' : this.OPTIONS.header.toString()
    const filterLabel = this.OPTIONS.metaData.drillDown ? '(Click to Filter)' : ''
    pieChart['legend'] = {
      title: {
        text: ` <span style="font-size: 12px; color: #333; font-weight: bold">${label}</span> <br/> <span style="font-size: 8px; color: #666; font-style: italic font-weight: normal">${filterLabel}</span>`,
        style: {},
      },
      labelFormatter() {
        if (showLegendVal) {
          return `${this.name} -  ${this.y}`
        }
        return `${this.name}`
      },
      align: 'right',
      verticalAlign: 'top',
      layout: 'vertical',
      itemDistance: 50,
      x: 0,
      y: 20,
    }
    if (this.OPTIONS.metaData.drillDown) {
      pieChart.plotOptions.pie.point.events = {
        legendItemClick: (params) => {
          this.sraEmitterService.emitSelectedPointParams({
            options: { status: params.target.key },
            metadata: 'status',
          })
          return false
        },
        click() {
          return false
        }
      }
    } else {
      pieChart['legend'].itemStyle = {
        cursor: 'default',
      }
      pieChart.plotOptions.cursor = 'default'
    }
    pieChart.tooltip = {
      headerFormat: '',
      pointFormat: '<span style="color:{series.name}">{point.key}</span>: <b>{point.y}</b><br />'
    }
    pieChart.chart.plotBorderWidth = 0
    //  this.graphList.push(_.cloneDeep(PIE_CHART));
    this.graphData = pieChart
  }

  plotBarGraph() {

    this.chartType = 'chart'
    const barChart = cloneDeep(BAR_CHART)
    const subtype = this.OPTIONS.metaData && this.OPTIONS.metaData.subtype
    const measuredType = this.OPTIONS.metaData && this.OPTIONS.metaData.currency || null
    const differentType = this.OPTIONS.metaData && this.OPTIONS.metaData.direction || null
    if (differentType) {
      const colors = [
        '#B71B17',
        '#E56717',
        '#E5AC3A',
        '#5CA904',
        '#009336'
      ]
      const disableOrderBy = this.OPTIONS.metaData && this.OPTIONS.metaData.disableOrderBy
      if (!disableOrderBy) {
        this.OPTIONS.items = orderBy(this.OPTIONS.items, ['value'], ['desc'])
      }
      const colorMap = this.OPTIONS.metaInfo ? this.OPTIONS.metaInfo.colorMap : null
      const categories = this.OPTIONS.items.map(x => x.key)
      barChart.xAxis.categories = categories
      barChart.legend = {
        enabled: false
      }
      barChart.tooltip = {
        enabled: false
      }
      barChart.yAxis = {
        min: 0,
        title: {
          text: ''
        },
        labels: {
          enabled: false
        },
        gridLineWidth: 0
      }
      barChart.plotOptions = {
        series: {
          pointWidth: 25,
          pointPadding: 0,
          groupPadding: 0.1,
          dataLabels: {
            enabled: true,
            inside: false
          }
        },
        bar: {
          // cursor: 'pointer',
          events: {},
          dataLabels: {
            enabled: true,
            formatter() {
              return measuredType ? new ShortAnumber().transform(this.y) : Number(this.y).toLocaleString()
            }
          }
        }
      }

      if (this.OPTIONS.metaData.drillDown) {
        barChart.plotOptions.bar.events = {
          click: (params) => {
            this.sraEmitterService.emitSelectedPointParams({
              options: { status: params.point.category },
              metadata: 'status',
            })
            return false
          }
        }
      }
      const seriesData = []
      let index = 0
      this.OPTIONS.items.forEach(item => {
        seriesData.push({ y: item.y, color: colorMap ? colorMap[categories[index]] : colors[index] })
        index++
      })
      barChart.series = [{ data: seriesData }]
    } else {
      switch (subtype) {
        case 'grouped':
          const format = this.OPTIONS.metaData.format
          barChart.xAxis.categories = this.OPTIONS.items.map(x => x.name)
          barChart.tooltip = {
            pointFormatter() {
              let value = new ShortAnumber().transform(this.y)
              if (format === 'days') {
                value = this.y + ' days'
              }
              return '<span style="color:' + this.series.name + '">' + this.series.name + '</span>: <b>' + value + '</b><br />'
            }
          }
          barChart.plotOptions = {
            series: { events: {} },
            bar: {
              dataLabels: {
                enabled: true,
                formatter() {
                  if (format === 'days') {
                    return this.y + ' days'
                  }
                  return new ShortAnumber().transform(this.y)
                }
              }
            }
          }
          const groupedData = {}
          this.OPTIONS.items.forEach(item => {
            item.values.forEach((x) => {
              if (x.name) {
                if (groupedData[x.name]) {
                  groupedData[x.name].push(x.value)
                } else {
                  groupedData[x.name] = [x.value]
                }
              }
            })
          })
          barChart.series = Object.keys(groupedData).map(x => {
            return {
              name: x,
              data: groupedData[x]
            }
          }) as any
          break
        default:
          barChart.series = this.OPTIONS.items.map((x) => {
            return {
              name: x.name || x.key,
              data: [x.y],
              color: x.color
            }
          })
      }
    }
    if (this.OPTIONS.metaData.invertLegendSelection) {
      barChart.plotOptions.series.events = {
        legendItemClick() {
          if (!this.visible) {
            return false;
          }
          this.chart.series.forEach(series => {
            if (series.index !== this.index) {
              series.visible ?
                series.hide() :
                series.show();
            }
          })
          return false;
        }
      }
    }
    this.graphData = barChart
  }

  plotMapGraph(response) {
    const mapChart = cloneDeep(MAP_CHART)
    this.chartType = 'mapchart'
    this.noGraph = false
    if (isEmpty(response)) {
      this.noGraph = true
      this.nodata = false
      return
    }
    if (this.OPTIONS.metaData.tooltip) {
      mapChart.tooltip = this.OPTIONS.metaData.tooltip
    }
    mapChart.series = [
      mapChart.series[0],
      ...this.generateMapPoints(response),
    ]
    if (this.OPTIONS.metaData.drillDown && !this.hideCustom) {
      mapChart.plotOptions.series.point.events = {
        click: (params) => {
          this.sraEmitterService.emitSelectedPointParams({
            options: params.point.options,
            metadata: this.additionalParam,
          })
        },
      }
    }
    mapChart.legend.enabled = this.hideCustom
    this.graphData = mapChart
  }

  onOptionChange(event) {
    if (!event.val) {
      return
    }
    this.selectedChartView = event
    this.nodata = true
    this.additionalParam = event.val
    this.graphItemService.getGraphValues(this.OPTIONS, event.val).subscribe(
      (data) => {
        this.resolveGraph(data)
      },
      () => {
        this.nodata = true
      }
    )
  }

  generateMapPoints(pointObj) {
    const mapPoints = []
    this.codes = []
    ColorUtils.resetColorCounter()
    const colorMap = this.OPTIONS.metaInfo ? this.OPTIONS.metaInfo.colorMap : null
    if (Array.isArray(pointObj)) {
      const mapPoint = {
        // Specify points using lat/lon
        type: 'mappoint',
        cursor: 'pointer',
        name: '',
        marker: {
          radius: 5,
          symbol: 'circle',
          fillColor: ColorUtils.getRandomColor(),
        },
        allAreas: false,
        data: pointObj.splice(0, 1000).map(x => {
          x.lat = x.latitude
          x.lon = x.longitude
          x.markerName = x.name
          delete x.name
          return x
        }),
      }
      mapPoints.push(mapPoint)
    } else {
      const showInLegend = this.hideCustom
      Object.keys(pointObj).forEach((key) => {
        const colorCode = {
          name: key,
          visible: true,
          colorCode: colorMap ? colorMap[key] : ColorUtils.getRandomColor(),
        }
        this.codes.push(colorCode)
        const mapPoint = {
          // Specify points using lat/lon
          type: 'mappoint',
          cursor: 'pointer',
          showInLegend,
          name: key,
          marker: {
            radius: 5,
            symbol: 'circle',
            fillColor: colorCode.colorCode,
          },
          allAreas: false,
          data: !this.OPTIONS.metaData.convertMapMarker ? pointObj[key].splice(0, 1000) : pointObj[key].splice(0, 1000).map(x => {
            x.lat = x.latitude
            x.lon = x.longitude
            x.markerName = x.name
            delete x.name
            return x
          }),
          // [
          //   {
          //       name: "Australia",
          //       lat: -33.8688,
          //       lon: 151.2093
          //   }
          // ]
        }
        mapPoints.push(mapPoint)
        return mapPoint
      })
    }
    if (this.OPTIONS.metaData.hideLegend) {
      this.codes = []
    }
    return mapPoints
  }

  resolveGraph(data: any) {
    if (data && data.result && data.result.dataItems) {
      if (
        isEmpty(this.OPTIONS.items) &&
        this.OPTIONS.metaData.chartType === 'Bar'
      ) {
        data.result.dataItems.forEach((element) => {
          element.y = element.value
          if (element.value === null || element.values === null) {
            this.noGraph = true
          }
        })
        this.OPTIONS.items = data.result.dataItems
      } else {
        if (this.OPTIONS.items && this.OPTIONS.items.length) {
          this.OPTIONS.items.forEach((ele) => {
            data.result.dataItems.forEach((element) => {
              if (ele.key === element.key) {
                ele.y = element.value
              }
              if (element.value === null) {
                this.noGraph = true
              }
            })
          })
        } else {
          this.OPTIONS.items = data.result.dataItems
          this.OPTIONS.items.forEach(item => {
            item.name = item.key
            item.y = item.value
          });
        }
      }
      const type = this.OPTIONS.metaData && this.OPTIONS.metaData.chartType
      switch (type) {
        case 'Bar':
          this.plotBarGraph()
          break
        case 'map':
          this.plotMapGraph(data.result.sites)
          break
        default:
          this.plotPieGraph()
      }
      this.nodata = false
    } else if (data && data.result && data.result && data.result.sites) {
      this.plotMapGraph(data.result.sites)
      this.nodata = false
    }
    if (data.result === null) {
      this.noGraph = true
      this.nodata = false
    }
  }
}
