import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { ApiServiceProvider } from '../../../../../api-service';
import { BaseService } from '../../../../common/base.service';
import { API_SERVICE_CONSTANTS, HTTP_REQUEST_TYPES, REST_END_POINT_URLS } from '../../../../npi/npiconstants';

export const WIDGET_RESPONSE_KEYS = {
    RESULT: 'result',
    NO_SECTION: 'NO_SECTION',
    WIDGET_HEADERS: 'widgetHeaders'
};
@Injectable()
export class ManifacturerClassificationService {
    public uniqueKey = 'ldNormalizedName';
    public isNewEntities = 'false';
    private environment = environment;
    constructor(private apiService: ApiServiceProvider, private baseService: BaseService) {}
    public getHeaders(headerKey?): Observable<any> {
        const payload = {
            targetedType: API_SERVICE_CONSTANTS.CUSTOMER_CONFIG_TARGETED_TYPE,
            formParams: {
                widgetTabKey: headerKey || 'ENTITY_CLASSIFICATION',
                tenantId: this.baseService.getUserInformation().tenantId,
                view: API_SERVICE_CONSTANTS.WIDGET_SECTION.SUMMARY
            },
            method: HTTP_REQUEST_TYPES.POST,
            generic: true,
            servicePath:
                API_SERVICE_CONSTANTS.PATH_SEPARATOR +
                API_SERVICE_CONSTANTS.WIDGET_MANAGEMENT_API_NAME +
                API_SERVICE_CONSTANTS.PATH_SEPARATOR +
                REST_END_POINT_URLS.GET_WIDGET_HEADERS
        };
        if (headerKey === 'ENTITY_CLASSIFICATION_DETAILS') {
            payload.formParams.view = API_SERVICE_CONSTANTS.WIDGET_SECTION.DETAILS;
        }
        return this.apiService.post(payload);
    }
    public getPartDetails(vendorName: string) {
        vendorName = encodeURIComponent(vendorName);
        const payload = {
            targetedType: `${this.environment.content.contentManagementService}`,
            method: HTTP_REQUEST_TYPES.GET,
            servicePath: `${this.environment.content.contentManagementService}/entity/details`,
            formParams: {
                vendorName
            }
        };
        return this.apiService.post(payload);
    }
    public getRows(paginationObject, filterObj?: object): Observable<any> {
        const payload = {
            targetedType: 'ContentManagementService',
            method: HTTP_REQUEST_TYPES.POST,
            servicePath: `${this.environment.content.contentManagementService}/entity/classification`,
            formParams: {
                isNew: this.isNewEntities,
                filter: ''
            },
            payload: paginationObject
        };
        if (typeof filterObj !== 'undefined') {
            payload.formParams.filter = encodeURIComponent(JSON.stringify(filterObj));
        }
        return this.apiService.post(payload);
    }
    public deleteRows(rows): Observable<any> {
        const payload = {
            targetedType: 'ContentManagementService',
            method: HTTP_REQUEST_TYPES.DELETE,
            servicePath: `${this.environment.content.contentManagementService}/entity/classification`,
            payload: rows
        };
        return this.apiService.post(payload);
    }

    public saveChanges(data): Observable<any> {
        const payload = {
            targetedType: 'ContentManagementService',
            method: HTTP_REQUEST_TYPES.POST,
            servicePath: `${this.environment.content.contentManagementService}/entity/classifications`,
            payload: data
        };
        return this.apiService.post(payload);
    }
    public getChangeHistory(trackId: string) {
        trackId = encodeURIComponent(trackId);
        const payload = {
            targetedType: `${this.environment.content.contentManagementService}`,
            method: HTTP_REQUEST_TYPES.GET,
            servicePath: `${this.environment.content.contentManagementService}/entity/audit`,
            formParams: {
                trackId
            }
        };
        return this.apiService.post(payload);
    }
    public bulkUpdateByFilter(data: object, filter: object): Observable<any> {
        const payload = {
            targetedType: `${this.environment.content.contentManagementService}`,
            method: HTTP_REQUEST_TYPES.POST,
            servicePath: `${this.environment.content.contentManagementService}/entity/bulk/filter`,
            payload: {
                data,
                filter
            }
        };
        return this.apiService.post(payload);
    }
    public bulkUpdateByIds(data: object, entityIds: string[]): Observable<any> {
        const payload = {
            targetedType: `${this.environment.content.contentManagementService}`,
            method: HTTP_REQUEST_TYPES.POST,
            servicePath: `${this.environment.content.contentManagementService}/entity/bulk/ids`,
            payload: {
                data,
                ids: entityIds
            }
        };
        return this.apiService.post(payload);
    }
}
