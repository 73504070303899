import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RfqConfigService } from '../shared/rfq-config.service';
import { CREATE_TEMPLATE_MESSAGES } from '../shared/constants';
import { Router } from '@angular/router';
@Component({
  selector: './edit-template',
  templateUrl: './edit-template.component.html',
  styleUrls: ['./edit-template.component.sass'],
  providers: []
})

export class EditTemplateComponent {
  ajaxRequests: object ;
  isEditingTemplateName = false;
  template: any = { name: ''};
  nameError: string;
  templates: any;

  constructor(
    private rfqConfigService: RfqConfigService,
    private router: Router
  ){}

  ngOnInit(){
    this.rfqConfigService.getTemplate().subscribe((template)=>{
      if(!template) {
        this.router.navigate([`app/templates`]);
        return;
      }
      this.template = template;
    })
    this.rfqConfigService.getTemplateList().subscribe(rowData => {
      this.templates = rowData;
    })
  }
  setTemplateName() {
    this.rfqConfigService.setTemplate(this.template);
    if(this.template.name.trim().length === 0){
      this.nameError = CREATE_TEMPLATE_MESSAGES.nameErrorMessage;
      this.rfqConfigService.setTemplateNameError(true);
          return false;
    } else if (this.templates.find(temp => temp.name === this.template.name
      && temp.templateId !== this.template.parentTemplateId && temp.templateId !== this.template.templateId)) {
      this.nameError = CREATE_TEMPLATE_MESSAGES.sameTemplateMessage;
      this.rfqConfigService.setTemplateNameError(true);
      return false;
    }
    this.nameError = '';
    this.rfqConfigService.setTemplateNameError(false);
    this.isEditingTemplateName=false;
  }

  editing() {
    this.isEditingTemplateName = true;
  }
}
