import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { LeftNav } from './left-nav.model';
import { ApiServiceProvider } from 'app/api-service';

@Injectable()
export class LeftNavService extends ApiServiceProvider {

}
