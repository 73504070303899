import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewEncapsulation,
} from '@angular/core'
import * as _ from 'lodash'
import { UtilService } from 'app/modules/common/utills/util.service'
@Component({
  selector: 'app-browser-support',
  templateUrl: './browser-support.component.html',
  styleUrls: ['./browser-support.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class BrowserSupportComponent implements OnInit {
  @Input() config: any = 'notInApp'
  @Output() dismissEvent = new EventEmitter<any>()
  showOtherInformation = true
  supportedBrowserArray = []
  supportedEnvironments: any

  constructor(private utilService: UtilService) {
    this.supportedEnvironments = this.utilService.supportedBrowser()
  }

  ngOnInit() {
    switch (this.config.trim()) {
      case 'inApp':
        this.showOtherInformation = false
        break
      case 'notInApp':
        this.showOtherInformation = true
        break
    }
    // tslint:disable-next-line: no-shadowed-variable
    this.supportedEnvironments['headers'].forEach((browser) => {
      this.supportedBrowserArray.push(browser.imageName)
    })
  }

  private newMethod() {
    return 'inApp'
  }

  getImage(environment, browser) {
    if (environment[browser].status) {
      return '<em class="ld-icon-check ld-green font-size-18 font-weight-800"></em>'
    } else {
      return ' <em class="ld-icon-dismiss font-size-14">'
    }
  }
  getVersion(environment, browser) {
    return `<span> ${environment[browser].version}</span>`
  }

  dismiss($event) {
    this.dismissEvent.emit($event)
  }
}
