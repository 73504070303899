import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { GetTitlePipe } from './scope-filters/scope-filters.component';
import { WidgetComponent } from './widget/widget.component';
import { NgxLoadingModule } from 'ngx-loading';
import {
  NgModule,
  Pipe,
  PipeTransform,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderBarComponent } from 'app/modules/core/header-bar/header-bar.component';
import { AutopopulateComponent } from 'app/modules/core/autopopulate/autopopulate.component';
import { NavTabBarComponent } from 'app/modules/core/nav-tab-bar/nav-tab-bar.component';
import { NavAdvTabBarComponent } from 'app/modules/core/nav-adv-tab-bar/nav-adv-tab-bar.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { Ng2CompleterModule } from 'ng2-completer';
import { TreeviewModule } from 'ngx-treeview';
import { GridComponent } from 'app/modules/core/grid/grid.component';
import { GridPaginationComponent } from 'app/modules/core/grid/pagination/grid-pagination.component'
import { OpportunitiesListComponent } from 'app/modules/core/opportunities-list/opportunities-list.component';
import { NewsListComponent } from './news-list/news-list.component';
import { RisksComponent } from 'app/modules/core/risks/risks.component';
import { InsightsComponent } from 'app/modules/core/insights/insights.component';
import { NotesComponent } from 'app/modules/core/notes/notes.component';
import { ScopeComponent } from 'app/modules/core/scope/scope.component';
import { SubNavTabBarComponent } from 'app/modules/core/sub-nav-tab-bar/sub-nav-tab-bar.component';
import { SubNavAdvTabBarComponent } from 'app/modules/core/sub-nav-adv-tab-bar/sub-nav-adv-tab-bar.component';
import { PreloadTableComponent } from 'app/modules/core/preloaders/table/table.component';
import { PreloadNewsComponent } from 'app/modules/core/preloaders/news/news.component';
import { PreloadListComponent } from 'app/modules/core/preloaders/list/list.component';
import { PreloadBarGraphComponent } from 'app/modules/core/preloaders/bar-graph/bar-graph.component';
import { DropdownComponent } from 'app/modules/core/dropdown/dropdown.component';
import { RecommendationsComponent } from 'app/modules/core/recommendations/recommendations.component';
import { MessageToSupplierComponent } from 'app/modules/core/message-to-supplier/message-to-supplier.component';
import { ListComponent } from 'app/modules/core/list/list.component';
import { SearchBarComponent } from 'app/modules/core/search-bar/search-bar.component';
import { KeynoteCommentsComponent } from 'app/modules/core/keynote-comments/keynote-comments.component';
import { InsightsNotesComponent } from 'app/modules/core/insights-notes/insights-notes.component';
import { IndustryNewsContentComponent } from 'app/modules/mi/IndustryNewsContent/IndustryNewsContent.component';
import { HightChartModule } from 'app/modules/high-charts/high-charts.module';
import { ObjectToArray } from 'app/modules/common/Pipe/ObjectToArray';
import { FiltersComponent } from 'app/modules/core/filters/filters.component';
import { SummaryComponent } from './Summary/Summary.component';
import { RecommendationsMessagesComponent } from 'app/modules/core/recommendations-messages/recommendations-messages.component';
import { NoDataComponent } from 'app/modules/core/no-data/no-data.component';
import { AlertsComponent } from 'app/modules/core/alerts/alerts.component';
import { AppFilterComponent } from 'app/modules/core/app-filter/app-filter.component';
import { ScopeFiltersComponent } from 'app/modules/core/scope-filters/scope-filters.component';
import { MomentModule } from 'angular2-moment';
import { RelatedNewsComponent } from 'app/modules/core/related-news/related-news.component';
import { AutoSearchComponent } from 'app/modules/core/autosearch/autosearch.component';
import { AutoSearchDimensionComponent } from 'app/modules/core/autosearch-dimension/autosearch-dimension.component';
import { AutoSearchPipe } from 'app/modules/core/autosearch/autosearch.pipe';
import { AutoSearchPipes } from 'app/modules/core/autosearch-dimension/autosearch-dimension.pipe';
import { FileuploadComponent } from 'app/modules/core/fileupload/fileupload.component';
import { TimeSelectionComponent } from 'app/modules/core/time-selection/time-selection.component';
import { TimeSelectionCalendarComponent } from 'app/modules/core/time-selection-calendar/time-selection-calendar.component';
import { MultiplePartMatchingViewService } from 'app/modules/mi/partmatching/multiple-part-matching-view/shared/multiple-part-matching-view.service';
import { PartMatchingService } from 'app/modules/mi/partmatching/partmatching.service';
import { ToasterModule } from 'angular2-toaster';
import { ClickOutsideModule } from 'ng-click-outside';
import { CostLookupComponent } from 'app/modules/core/grid/RMpopup/rmpopup.component';
import { TooltipModule } from 'ngx-tooltip';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';
import { Routes, RouterModule } from '@angular/router';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { RmAutosearchComponent } from 'app/modules/core/rm-autosearch/rm-autosearch.component';
import { RMAutoSearchPipe } from 'app/modules/core/rm-autosearch/rm-autosearch.pipe';
import { AppIconsComponent } from 'app/modules/core/app-icons/app-icons.component';
import { NewsListService } from 'app/modules/core/news-list/news-list.service';
import { SubscriptionService } from 'app/modules/common/subscription.service';
import { IndustryNewsContentService } from 'app/modules/mi/IndustryNewsContent/shared/IndustryNewsContent.service';
import { FileuploadService } from 'app/modules/core/fileupload/shared/fileupload.service';
import { NxtGridComponent } from 'app/modules/core/nxt-grid/nxt-grid.component';
import { SupplierStatusComponent } from 'app/modules/core/nxt-grid/supplier.status.component';
import { RfxAlertsComponent } from 'app/modules/core/nxt-grid/rfxAlerts.component';
import { GridConfiguration } from 'app/modules/core/nxt-grid/gridconfiguration';
import { CheckBoxComponent } from 'app/modules/core/grid/checkbox/checkbox.component';
import { AdvCheckboxComponent } from 'app/modules/core/grid/advcheckbox/advcheckbox.component';
import { ExcelExportLoaderComponent } from './preloaders/excel-export-loader/excel-export-loader.component';
import { NegLeversComponent } from 'app/modules/core/grid/negLevers/neglevers.component';
import { FontComponent } from 'app/modules/core/nxt-grid/font.text.component';
import { LinkComponent } from 'app/modules/core/nxt-grid/link.component';
import { CurrencyFormatterComponent } from 'app/modules/core/nxt-grid/currency.formatter';
import { GridToolTipComponent } from 'app/modules/core/nxt-grid/grid.tooltip';
import { PlaceHolderComponent } from 'app/modules/core/nxt-grid/placeholder.component';
import { NumericEditorComponent } from 'app/modules/core/nxt-grid/numeric-editor.component';
import { TextEditorComponent } from 'app/modules/core/nxt-grid/text-editor.component';
import { SortingComponent } from 'app/modules/core/sorting/sorting.component';
import { PaginationComponent } from 'app/modules/core/pagination/pagination.component';
import { CreateDropdownComponent } from 'app/modules/core/create-dropdown/create-dropdown.component';
import { CheckboxCellComponent } from 'app/modules/core/nxt-grid/checkbox.renderer.component';
import { MultiSelectComponent } from 'app/modules/core/multi-select/multi-select.component';
import { ShortAnumber } from 'app/modules/common/Pipe/ShortANumber';
import { EnumStatusRendererComponent } from 'app/modules/core/nxt-grid/enum.status.renderer.component';
import { ToFixed } from 'app/modules/common/Pipe/toFixed';
import { CustomReportsComponent } from 'app/modules/core/grid/custom-reports/custom-reports.component';
import { NegotiationLeversComponent } from 'app/modules/srfq/common/negotiation-levers/negotiation-levers.component';
import {
  AmTimeAgoByTimeZonePipe,
  AmDateFormatByTimeZonePipe
} from '../common/utills/DateUtils.service';

import { ConverToEncode } from '../common/Pipe/converToEncode';
import { NumericRendererComponent } from 'app/modules/core/nxt-grid/numeric.renderer.component';
import { CustReportsGridComponent } from './custReports-grid/custReports-grid.component';
import { CustReportsGridService } from './custReports-grid/shared/custReports-grid.service';
import { CheckboxTreeviewComponent } from 'app/modules/core/checkbox-treeview/checkbox-treeview.component';
import { CustomPopupComponent } from './custom-popup/custom-popup.component';
import { GroupsMultiSelectRendererComponent } from './grid/userGroupFeatures/groupsmultiselect.component';
import { UserApproverEmailRendererComponent } from 'app/modules/core/grid/userGroupFeatures/userApproverEmailValidator';
import { ValidationService } from '../common/utills/validation.service';
import { ModalDialogComponent } from 'app/modules/core/modal/modal-dialog.component';
import { ActionCellRendererComponent } from './grid/action.cell.renderer';
import { BomTreeGridComponent } from './bom-tree-grid';
import { DatePickerComponent } from './bom-tree-grid/utils/date-picker-editor';
import { DpDatePickerModule } from 'ng2-date-picker';
import { CollaborationsComponent } from './collaborations/collaborations.component';
import { CollaborationComponent } from './collaboration/collaboration.component';
import { HistoryComponent } from './history/history.component';
import { ComposeComponent } from './compose/compose.component';
import { CollaborationIconComponent } from './collaborationIcon/collaborationIcon.component';
import { JSONFilterPipe } from './json-filter.pipe';
import { CollabFileuploadComponent } from 'app/modules/core/compose/fileupload/collabfileupload.component';
import { MentionModule } from 'angular2-mentions/mention';
import { UploadDownloadService } from './upload-download.services';
import { PartialMatchFilterComponent } from './partial-match-filter/partial-match-filter.component';
import { CustomFilterComponent } from './custom-filter/custom-filter.component';
import { BomTreeFilter } from './bom-tree-grid/utils/bom-tree-filters/bom-tree-filters.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from '../common/interceptors/tokeninterceptor.service';
import { ContentCheckboxComponent } from './grid/content-components/content-checkbox/content-checkbox.component';
import { FileDownloadComponent } from './file-download/file-download.component';
import { FileDownloadService } from './file-download/shared/file-download.service';
import { ContentAutoSearchComponent } from './grid/content-components/content-auto-search/content-auto-search.component';
import { ContentSingleSelectComponent } from './grid/content-components/content-single-select/content-single-select.component';
import { GridDropdownComponent } from './grid/grid-dropdown/grid-dropdown.component';
import { SelectDropdownComponent } from './select-dropdown/select-dropdown.component';
import { GenerateAutomationId } from '../common/Pipe/generateAutomationId';
import { MultiselectGridDropdownComponent } from './grid/multiselect-grid-dropdown/multiselect-grid-dropdown.component';
import { DateRenderComponent } from './grid/date.render.component';
import { GridDateEditorComponent } from './grid/grid-date.editor.component'
import { BooleanRendererComponent } from '../curator/industry-news/industry-news-category/shared/boolean.renderer.component';
import { CpnLinkComponent } from './grid/cpnLink/cpnlink.component';
import { UserAutoSearchRendererComponent } from './grid/userGroupFeatures/userAutosearchRenderer';
import { MultiSelectRendererComponent } from './nxt-grid/multi-select-renderer.component';
import { CheckboxTooltipComponent } from './bom-tree-grid/utils/checkbox-tooltip.component';
import { RatingComponent } from './rating/rating.component';
import { SpendImpactDetailLink } from './grid/spendImpactDetailLink/spendImpactDetailLink.component';
import { DatePickerRendererComponent } from './nxt-grid/date-picker-renderer';
import { AgGridModule } from '@ag-grid-community/angular';
import { ActionCLRendererComponent } from '../curator/content-lab-configuration/shared/action.renderer.component';
import { DataVisualizationComponent } from '../curator/content/data-visualization/data-visualization.component';
import { ContentLabComponent } from '../curator/content-lab/content-lab.component';
import { BrowserSupportComponent } from './browser-support/browser-support.component';
import { FullWidthCellRendererComponent } from './grid/full-width-cell-renderer/full-width-cell-renderer.component';
import { ShowMyAlertsComponent } from './show-my-alerts/show-my-alerts.component';
import { ApplicationMaintenanceBannerComponent } from './application-maintenance-banner/application-maintenance-banner.component';
import { WhereUsedComponent } from './bom-tree-grid/utils/where-used.component';
import { DeepFindPipe } from './deepfind.pipe';
import { ExpandCollapseAllComponent } from './bom-tree-grid/expand-collapse-all-filter.component';
import {
  FilterElementComponent,
  MyFilterPipe,
  AutoSearchFocusDirective
} from '../scenario-workbench/filter-element/filter-element.component';
import { LdActionCellRendererComponent } from '../load-data/shared/ld-action-cell-renderer/ld-action-cell-renderer.component';
import { LdLinkCellRendererComponent } from '../load-data/shared/ld-link-cell-renderer/ld-link-cell-renderer.component';
import { NumericFilterComponent } from './numeric-filter/numeric-filter.component';
import { QuickSightDashboardComponent } from './quicksight-dashboard/quicksight-dashboard.component';
import { MultiSelectTenantComponent } from './grid/insights-configuration/showoredittenantsbutton.component';
import { RouterLinkRendererComponent } from './grid/router-link-render';
import { DateFilterComponent } from './date-filter/date-filter.component';
import { GenericAccordion } from './generic-accordion/generic-accordion.component';
import { EolRiskComponent } from '../npi/npi-risk-configure/eol-risk/eol-risk.component';
import { NpiAdminRiskComponent } from '../npi/npi-admin/npi-admin-risk/npi-admin-risk.component';
import { MultisourceRiskComponent } from '../npi/npi-risk-configure/multisource-risk/multisource-risk.component';
import { PslRiskComponent } from '../npi/npi-risk-configure/psl-risk/psl-risk.component';
import { PslRiskSearchComponent } from '../npi/npi-risk-configure/psl-risk-search/psl-risk-search.component';
import { AiUploadHistoryComponent } from '../core/ai-upload-history/ai-upload-history.component';
import { NpiTitleDropdownComponent } from '../npi/shared/npi-title-dropdown/npi-title-dropdown.component';
import {
  deleteMatchedPartMatch,
  filterData,
  fixDeciamalDigits,
  OrderbyPipe,
} from '../npi/read-nested-property.pipe';
import { CheckpointRiskComponent } from '../npi/npi-risk-configure/checkpoint-risk/checkpoint-risk.component';
import { StageRiskComponent } from '../npi/npi-risk-configure/stage-risk/stage-risk.component';
import { MaturityRiskComponent } from '../npi/npi-risk-configure/maturity-risk/maturity-risk.component';
import { MarketingPagePopupComponent } from '../mi/partner-score-card/marketing-page-popup/marketing-page-popup.component';
import { SupplierFinancialRiskComponent } from '../npi/npi-risk-configure/supplier-financial-risk/supplier-financial-risk.component';
import { GridStaticListComponent } from './grid/grid-static-list/grid-static-list.component';
import { NpiForwardSyncComponent } from './npi-forward-sync/npi-forward-sync.component';
import { RiskFiltersComponent } from '../risk-rfi/components/risk-filters/risk-filters.component';
import { DetailLocationCellRendererComponent } from '../curator/industry-news/industry-news-events/components/detail-location-cell-renderer/detail-location-cell-renderer.component';
import { EventFiltersComponent } from '../curator/industry-news/industry-news-events/components/event-filters/event-filters.component';
import { CreateEventComponent } from '../curator/industry-news/industry-news-events/components/create-event/create-event.component';
import { ReplaceLineBreaks } from '../common/utills/util.service';
import { LoaderComponent } from './loader/loader.component';
import { LevaSummaryComponent } from './leva-summary/leva-summary.component';
import { LevaQuickFilterComponent } from './leva-quick-filter/leva-quick-filter.component';
import { InsightSummaryBannerComponent } from '../insight-to-action/components/insight-summary-banner/insight-summary-banner.component';
import { LevaQuickFilterService } from './leva-quick-filter/leva-quick-filter.service';
import { SummaryExpandViewComponent } from './summary-expand-view/summary-expand-view.component';
import { CustomLegendComponent } from './custom-legend/custom-legend.component';
import { GraphWrapperComponent } from './graph-wrapper/graph-wrapper.component';
import { EventDetailsComponent } from './event-details/event-detail.component';
import { AddFilterComponent } from './leva-quick-filter/add-filter/add-filter.component';
import { EachFilterComponent } from './leva-quick-filter/add-filter/each-filter/each-filter.component';
import { GraphItemComponent } from './graph-item/graph-item.component';
import { GraphFilterByPipe } from '../common/Pipe/GraphFilterBy';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { Ng5SliderModule } from 'ng5-slider';
import { LevaColumnLevelFilterComponent } from './leva-column-level-filter/leva-column-level-filter.component';
import { EventManagementComponent } from '../curator/industry-news/industry-news-events/components/event-management/event-management.component'
import { AddPartSiteComponent } from '../curator/content/part-sites/components/add-part-site/add-part-site.component';
import { LevaUiLibraryModule } from '../leva-ui-library/leva-ui-library.module';
import { CustomActionCellComponent } from './grid/custom-action-cell/custom-action-cell.component';
import { NewsArticlesComponent } from './news-articles/news-articles.component';
import { SiteLocationsComponent } from './site-locations/site-locations.component';
import { LevaAutosearchBomRenderComponent } from './leva-autosearch-bom-render/leva-autosearch-bom-render.component';
import { LevaCommentsBomRenderComponent } from './leva-comments-bom-render/leva-comments-bom-render.component';
import { CommodityGridDropDownComponent } from './nxt-grid/commodity.grid.dropdown.component';
import { GridDropDownComponentGrid } from './nxt-grid/grid-product.dropdown.component';
import { SraAltSuppliersComponent } from './sra-alt-suppliers/sra-alt-suppliers.component'
import { EventsMitigationModel } from './supply-risk-assessment.model';
import { TaskElementComponent } from './task-element/task-element.component'
import { TaskManagementComponent } from './task-management/task-management.component';
import { TaskFilterByPipe } from './task-management/shared/TaskFilterBy';
import { CreateNewsComponent } from '../curator/industry-news/industry-news-events/components/create-news/create-news.component';
import { AutoActionTooltip } from '../insight-to-action/components/auto-action/auto-action-tooltip';
import { SaveEventComponent } from './save-event/save-event.component';
import { ImpactedSuppliersComponent } from './save-event/impacted-suppliers/impacted-suppliers.component';
import { AffectedSitesComponent } from './save-event/affected-sites/affected-sites.component';
import { AssociatedNewsComponent } from './save-event/associated-news/associated-news.component';
import { ImpactedSuppliersService } from './save-event/impacted-suppliers/impacted-suppliers.service';
import { AssociatedNewsService } from './save-event/associated-news/associated-news.service';
import { SaveEventService } from './save-event/save-event.service';
import { FileLinkComponent } from './grid/downloadFromGridComponent/report-filedwld';
import { SortByOrderPipe } from './rm-autosearch/sort-by-order.pipe';
import { SearchListComponent } from './save-event/impacted-suppliers/search-list/search-list.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  useBothWheelAxes: true,
};

@NgModule({
  imports: [
    // GenericDynamicFormModule,
    HightChartModule,
    NgxLoadingModule.forRoot({}),
    CommonModule,
    // HttpModule,
    LevaUiLibraryModule,
    RouterModule,
    Ng5SliderModule,
    Ng2CompleterModule,
    ReactiveFormsModule,
    TreeviewModule.forRoot(),
    DpDatePickerModule,

    AgGridModule.withComponents([
      MultiSelectTenantComponent,
      WhereUsedComponent,
      CheckboxTooltipComponent,
      DatePickerComponent,
      UserApproverEmailRendererComponent,
      GroupsMultiSelectRendererComponent,
      CheckBoxComponent,
      AdvCheckboxComponent,
      DateRenderComponent,
      GridDateEditorComponent,
      NegLeversComponent,
      CostLookupComponent,
      CpnLinkComponent,
      SpendImpactDetailLink,
      SupplierStatusComponent,
      MultiSelectRendererComponent,
      RfxAlertsComponent,
      NumericEditorComponent,
      GridDropdownComponent,
      CheckboxCellComponent,
      ActionCellRendererComponent,
      RouterLinkRendererComponent,
      EnumStatusRendererComponent,
      NumericRendererComponent,
      TextEditorComponent,
      LinkComponent,
      FileLinkComponent,
      SupplierStatusComponent,
      FontComponent,
      RfxAlertsComponent,
      CurrencyFormatterComponent,
      GridToolTipComponent,
      AutoActionTooltip,
      PlaceHolderComponent,
      CustomReportsComponent,
      ContentCheckboxComponent,
      ContentAutoSearchComponent,
      ContentSingleSelectComponent,
      MultiselectGridDropdownComponent,
      BooleanRendererComponent,
      UserAutoSearchRendererComponent,
      DatePickerRendererComponent,
      ActionCLRendererComponent,
      LdActionCellRendererComponent,
      LdLinkCellRendererComponent,
      GridStaticListComponent,
      DetailLocationCellRendererComponent,
      CustomActionCellComponent
      // UserAutoSearchRendererComponent
    ]),
    FormsModule,
    MomentModule,
    ToasterModule,
    ClickOutsideModule,
    TooltipModule,
    VirtualScrollerModule,
    MentionModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger', // set defaults here
      confirmText: 'Yes',
      cancelText: 'No',
    }),
    PerfectScrollbarModule,
    SlickCarouselModule,
  ],
  declarations: [
    GenericAccordion,
    QuickSightDashboardComponent,
    FullWidthCellRendererComponent,
    BomTreeFilter,
    CpnLinkComponent,
    CommodityGridDropDownComponent,
    GridDropDownComponentGrid,
    BrowserSupportComponent,
    CheckboxTooltipComponent,
    SpendImpactDetailLink,
    UserApproverEmailRendererComponent,
    DatePickerComponent,
    GroupsMultiSelectRendererComponent,
    MultiselectGridDropdownComponent,
    TaskFilterByPipe,
    PaginationComponent,
    DateRenderComponent,
    GridDateEditorComponent,
    BooleanRendererComponent,
    FontComponent,
    LinkComponent,
    FileLinkComponent,
    CurrencyFormatterComponent,
    GridToolTipComponent,
    AutoActionTooltip,
    PlaceHolderComponent,
    NumericEditorComponent,
    GridDropdownComponent,
    CheckboxCellComponent,
    ActionCellRendererComponent,
    RouterLinkRendererComponent,
    EnumStatusRendererComponent,
    NumericRendererComponent,
    TextEditorComponent,
    SortingComponent,
    GetTitlePipe,
    ToFixed,
    CheckBoxComponent,
    AdvCheckboxComponent,
    AutoSearchPipes,
    CostLookupComponent,
    MultiSelectRendererComponent,
    ShortAnumber,
    ObjectToArray,
    GenerateAutomationId,
    HeaderBarComponent,
    AutopopulateComponent,
    DropdownComponent,
    GridComponent,
    GridPaginationComponent,
    HeaderBarComponent,
    InsightsComponent,
    ListComponent,
    MessageToSupplierComponent,
    NavTabBarComponent,
    NegotiationLeversComponent,
    NavAdvTabBarComponent,
    NewsListComponent,
    CustomReportsComponent,
    NotesComponent,
    NotificationsComponent,
    OpportunitiesListComponent,
    PreloadTableComponent,
    PreloadNewsComponent,
    PreloadListComponent,
    ExcelExportLoaderComponent,
    PartialMatchFilterComponent,
    NumericFilterComponent,
    DateFilterComponent,
    CustomFilterComponent,
    PreloadBarGraphComponent,
    RecommendationsComponent,
    RisksComponent,
    ScopeComponent,
    SubNavTabBarComponent,
    SubNavAdvTabBarComponent,
    AppIconsComponent,
    SearchBarComponent,
    KeynoteCommentsComponent,
    InsightsNotesComponent,
    NegLeversComponent,
    WidgetComponent,
    IndustryNewsContentComponent,
    FiltersComponent,
    SummaryComponent,
    RecommendationsMessagesComponent,
    NoDataComponent,
    AlertsComponent,
    AppFilterComponent,
    NewsListComponent,
    ScopeFiltersComponent,
    RelatedNewsComponent,
    AutoSearchComponent,
    AutoSearchDimensionComponent,
    FileuploadComponent,
    AutoSearchPipe,
    TimeSelectionComponent,
    RmAutosearchComponent,
    RMAutoSearchPipe,
    TimeSelectionCalendarComponent,
    NxtGridComponent,
    SupplierStatusComponent,
    RfxAlertsComponent,
    CreateDropdownComponent,
    SelectDropdownComponent,
    MultiSelectComponent,
    AmTimeAgoByTimeZonePipe,
    AmDateFormatByTimeZonePipe,
    ReplaceLineBreaks,
    ConverToEncode,
    CustReportsGridComponent,
    CheckboxTreeviewComponent,
    CustomPopupComponent,
    ModalDialogComponent,
    BomTreeGridComponent,
    ModalDialogComponent,
    CollaborationsComponent,
    CollaborationComponent,
    HistoryComponent,
    ComposeComponent,
    CollaborationIconComponent,
    JSONFilterPipe,
    CollabFileuploadComponent,
    ContentCheckboxComponent,
    FileDownloadComponent,
    ContentAutoSearchComponent,
    ContentSingleSelectComponent,
    RatingComponent,
    UserAutoSearchRendererComponent,
    DatePickerRendererComponent,
    ActionCLRendererComponent,
    LdActionCellRendererComponent,
    LdLinkCellRendererComponent,
    DataVisualizationComponent,
    DetailLocationCellRendererComponent,
    ContentLabComponent,
    ShowMyAlertsComponent,
    BrowserSupportComponent,
    ApplicationMaintenanceBannerComponent,
    WhereUsedComponent,
    MultiSelectTenantComponent,
    DeepFindPipe,
    ExpandCollapseAllComponent,
    FilterElementComponent,
    MyFilterPipe,
    AutoSearchFocusDirective,
    deleteMatchedPartMatch,
    filterData,
    fixDeciamalDigits,
    EolRiskComponent,
    NpiAdminRiskComponent,
    MultisourceRiskComponent,
    PslRiskComponent,
    PslRiskSearchComponent,
    NpiTitleDropdownComponent,
    CheckpointRiskComponent,
    StageRiskComponent,
    MaturityRiskComponent,
    // UserAutoSearchRendererComponent
    MarketingPagePopupComponent,
    SupplierFinancialRiskComponent,
    GridStaticListComponent,
    NpiForwardSyncComponent,
    RiskFiltersComponent,
    LevaSummaryComponent,
    LevaQuickFilterComponent,
    AddFilterComponent,
    EachFilterComponent,
    EventFiltersComponent,
    CreateEventComponent,
    CreateNewsComponent,
    AddPartSiteComponent,
    LoaderComponent,
    InsightSummaryBannerComponent,
    SummaryExpandViewComponent,
    GraphItemComponent,
    CustomLegendComponent,
    GraphWrapperComponent,
    EventDetailsComponent,
    OrderbyPipe,
    GraphFilterByPipe,
    LevaColumnLevelFilterComponent,
    AiUploadHistoryComponent,
    EventManagementComponent,
    CustomActionCellComponent,
    SiteLocationsComponent,
    NewsArticlesComponent,
    LevaAutosearchBomRenderComponent,
    LevaCommentsBomRenderComponent,
    SraAltSuppliersComponent,
    TaskElementComponent,
    TaskManagementComponent,
    SaveEventComponent,
    ImpactedSuppliersComponent,
    AffectedSitesComponent,
    AssociatedNewsComponent,
    SortByOrderPipe,
    SearchListComponent
  ],
  entryComponents: [
    WidgetComponent,
    PartialMatchFilterComponent,
    NumericFilterComponent,
    DateFilterComponent,
    CustomFilterComponent,
    CommodityGridDropDownComponent,
    GridDropDownComponentGrid,
    ExpandCollapseAllComponent,
    SummaryExpandViewComponent,
    LevaColumnLevelFilterComponent,
    LevaAutosearchBomRenderComponent,
    LevaCommentsBomRenderComponent
  ],
  exports: [
    PerfectScrollbarModule,
    DpDatePickerModule,
    GenericAccordion,
    QuickSightDashboardComponent,
    CommodityGridDropDownComponent,
    GridDropDownComponentGrid,
    FullWidthCellRendererComponent,
    FilterElementComponent,
    MyFilterPipe,
    AutoSearchFocusDirective,
    ReactiveFormsModule,
    BomTreeFilter,
    BrowserSupportComponent,
    PaginationComponent,
    FontComponent,
    FileLinkComponent,
    LinkComponent,
    CurrencyFormatterComponent,
    GridToolTipComponent,
    AutoActionTooltip,
    CustomFilterComponent,
    PlaceHolderComponent,
    NumericEditorComponent,
    GridDropdownComponent,
    CheckboxCellComponent,
    ActionCellRendererComponent,
    EnumStatusRendererComponent,
    NumericRendererComponent,
    TextEditorComponent,
    TaskElementComponent,
    TaskManagementComponent,
    SortingComponent,
    ShortAnumber,
    ToFixed,
    ObjectToArray,
    GenerateAutomationId,
    RmAutosearchComponent,
    RMAutoSearchPipe,
    AutoSearchPipes,
    AutoSearchComponent,
    AutopopulateComponent,
    DropdownComponent,
    GridComponent,
    GridPaginationComponent,
    HeaderBarComponent,
    InsightsComponent,
    ListComponent,
    MessageToSupplierComponent,
    NavTabBarComponent,
    NegotiationLeversComponent,
    NavAdvTabBarComponent,
    NewsListComponent,
    NotesComponent,
    NotificationsComponent,
    OpportunitiesListComponent,
    PreloadTableComponent,
    PreloadNewsComponent,
    PreloadListComponent,
    PreloadBarGraphComponent,
    ExcelExportLoaderComponent,
    RecommendationsComponent,
    RisksComponent,
    ScopeComponent,
    SubNavTabBarComponent,
    SubNavAdvTabBarComponent,
    AppIconsComponent,
    SearchBarComponent,
    KeynoteCommentsComponent,
    InsightsNotesComponent,
    WidgetComponent,
    IndustryNewsContentComponent,
    FiltersComponent,
    SummaryComponent,
    RecommendationsMessagesComponent,
    NoDataComponent,
    AlertsComponent,
    AppFilterComponent,
    ScopeFiltersComponent,
    RelatedNewsComponent,
    // AutoSearchComponent,
    AutoSearchDimensionComponent,
    FileuploadComponent,
    AutoSearchPipe,
    TimeSelectionComponent,
    TimeSelectionCalendarComponent,
    NxtGridComponent,
    SupplierStatusComponent,
    RfxAlertsComponent,
    CreateDropdownComponent,
    SelectDropdownComponent,
    MultiSelectComponent,
    PartialMatchFilterComponent,
    NumericFilterComponent,
    DateFilterComponent,
    CustomFilterComponent,
    AmTimeAgoByTimeZonePipe,
    AmDateFormatByTimeZonePipe,
    ReplaceLineBreaks,
    ConverToEncode,
    CustReportsGridComponent,
    CheckboxTreeviewComponent,
    CustomPopupComponent,
    ModalDialogComponent,
    BomTreeGridComponent,
    CollaborationsComponent,
    CollaborationComponent,
    HistoryComponent,
    ComposeComponent,
    CollaborationIconComponent,
    JSONFilterPipe,
    FileDownloadComponent,
    CpnLinkComponent,
    RatingComponent,
    UserAutoSearchRendererComponent,
    DatePickerRendererComponent,
    ActionCLRendererComponent,
    LdActionCellRendererComponent,
    LdLinkCellRendererComponent,
    DetailLocationCellRendererComponent,
    DataVisualizationComponent,
    ContentLabComponent,
    ShowMyAlertsComponent,
    BrowserSupportComponent,
    ApplicationMaintenanceBannerComponent,
    DeepFindPipe,
    ExpandCollapseAllComponent,
    deleteMatchedPartMatch,
    filterData,
    fixDeciamalDigits,
    EolRiskComponent,
    NpiAdminRiskComponent,
    MultisourceRiskComponent,
    PslRiskComponent,
    PslRiskSearchComponent,
    NpiTitleDropdownComponent,
    CheckpointRiskComponent,
    StageRiskComponent,
    MaturityRiskComponent,
    MarketingPagePopupComponent,
    SupplierFinancialRiskComponent,
    GridStaticListComponent,
    NpiForwardSyncComponent,
    RiskFiltersComponent,
    EventFiltersComponent,
    CreateEventComponent,
    CreateNewsComponent,
    AddPartSiteComponent,
    LoaderComponent,
    OrderbyPipe,
    GraphFilterByPipe,
    LevaSummaryComponent,
    LevaQuickFilterComponent,
    SummaryExpandViewComponent,
    GraphItemComponent,
    CustomLegendComponent,
    GraphWrapperComponent,
    EventDetailsComponent,
    InsightSummaryBannerComponent,
    LevaColumnLevelFilterComponent,
    AiUploadHistoryComponent,
    EventManagementComponent,
    SraAltSuppliersComponent,
    SaveEventComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    LevaQuickFilterService,
    CustReportsGridService,
    PartMatchingService,
    AppIconsComponent,
    NewsListService,
    IndustryNewsContentService,
    MultiplePartMatchingViewService,
    FileuploadService,
    GridConfiguration,
    ValidationService,
    UploadDownloadService,
    LevaAutosearchBomRenderComponent,
    LevaCommentsBomRenderComponent,
    FileDownloadService,
    ImpactedSuppliersService,
    AssociatedNewsService,
    SaveEventService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    EventsMitigationModel
  ],
})
export class CoreModule { }
