import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { ApiServiceProvider } from 'app/api-service';
import { Collaboration } from 'app/modules/core/collaboration/shared/collaboration.model';
import { CollaborationRequestModel } from 'app/modules/core/collaborations/shared/collaboration-request-model';
import { CollaborationSummaryModel } from 'app/modules/core/collaborations/shared/collaborationsummary-model';
import { ConversationModel } from 'app/modules/core/collaborations/shared/conversation-model';
import { FileAttachModel } from 'app/modules/core/collaborations/shared/fileattach-model';
import { FromUserModel } from 'app/modules/core/collaborations/shared/fromuser-model';
import { MentionedUsersModel } from 'app/modules/core/collaborations/shared/mentionedusers-model';
import { TaskAttachModel } from 'app/modules/core/collaborations/shared/taskattach-model';
import { ToUserModel } from 'app/modules/core/collaborations/shared/touser-model';

@Injectable()
export class ComposeService extends ApiServiceProvider {
  private collabReqModel: CollaborationRequestModel;
  private collabReqSummery: CollaborationSummaryModel;
  private collabReqConversation: ConversationModel;
  private fileAttaches: FileAttachModel[];
  private fromUser: FromUserModel;
  private mentionedUsers: MentionedUsersModel[];
  private groupdIds: any[];
  private taskAttach: TaskAttachModel;
  private toUser: ToUserModel;
  private isTask: boolean;
  private hasWaitingTask = false;

  postCollaborationOrTask(
    commentText: string,
    collabType: any,
    inputObj: any,
    displayTopic: any,
    data: any,
    userInformation: any,
    servicePath: string,
    epochTime: any
  ): Observable<Collaboration[]> {
    if (servicePath === 'task') {
      this.isTask = true;
    } else {
      this.isTask = false;
    }
    this.prepareCreateCollaborationRequest(
      data,
      collabType,
      inputObj,
      displayTopic,
      commentText,
      userInformation,
      epochTime
    );
    const object: any = {
      targetedType: 'Collaboration',
      formParams: {},
      payload: this.collabReqModel,
      method: 'POST',
      servicePath
    };
    // console.log("payload::", JSON.stringify(object.payload));
    return this.post(object);
  }

  public prepareCreateCollaborationRequest(
    data: any,
    collabType: any,
    inputObj: any,
    displayTopic: any,
    commentText: string,
    userInformation: any,
    epochTime: any
  ) {
    this.collabReqModel = new CollaborationRequestModel();
    this.collabReqSummery = new CollaborationSummaryModel();
    this.populateCollabReqSummery(
      data,
      collabType,
      inputObj,
      displayTopic,
      epochTime
    );
    this.collabReqConversation = new ConversationModel();
    this.populateCollabReqConversation(
      commentText,
      userInformation,
      inputObj,
      epochTime
    );
    this.collabReqModel.setCollaborationSummery(this.collabReqSummery);
    this.collabReqModel.setConversation(this.collabReqConversation);
  }

  public populateCollabReqSummery(
    data: any,
    collabType: any,
    inputObj: any,
    displayTopic: any,
    epochTime: any
  ) {
    this.hasWaitingTask =
      data === undefined ? false : data.collaborationSummary.hasWaitingTask;
    this.collabReqSummery.setArchived(
      data === undefined ? false : data.collaborationSummary.archived
    );
    this.collabReqSummery.setCategory(
      data === undefined
        ? typeof inputObj.context === 'string'
          ? inputObj.context
          : ''
        : data.collaborationSummary.category
    );
    this.collabReqSummery.setCollaborationId(
      data === undefined ? null : data.collaborationSummary.collaborationId
    );
    this.collabReqSummery.setCollaborationType(
      data === undefined
        ? collabType
        : data.collaborationSummary.collaborationType
    );
    this.collabReqSummery.setDisplayTopic(displayTopic);
    this.collabReqSummery.setFrontEndId(inputObj.FE_ID);
    this.collabReqSummery.setFrontEndTracker(
      data === undefined ? null : data.collaborationSummary.frontEndTracker
    );
    this.collabReqSummery.setHasWaitingTask(
      data === undefined ? false : data.collaborationSummary.hasWaitingTask
    );
    this.collabReqSummery.setSubCategory(
      data === undefined
        ? typeof inputObj.subContext === 'string'
          ? inputObj.subContext
          : ''
        : data.collaborationSummary.subCategory
    );
    this.collabReqSummery.setTaskDueDate(
      data === undefined ? null : data.collaborationSummary.taskDueDate
    );
    this.collabReqSummery.setTopic(inputObj.topic);
  }

  public populateCollabReqConversation(
    commentText: string,
    userInformation: any,
    inputObj: any,
    epochTime: any
  ) {
    this.collabReqConversation.setFileAttaches(inputObj['fileAttaches']);
    this.fromUser = new FromUserModel();
    this.populateFromUser(userInformation);
    this.collabReqConversation.setFromUser(this.fromUser);
    this.collabReqConversation.setHasFiles(
      inputObj['fileAttaches'].length > 0 ? true : false
    );
    this.populateMentionedUsers(inputObj);
    this.collabReqConversation.setMentionedUsers(this.mentionedUsers);
    this.collabReqConversation.setTask(this.isTask);
    this.taskAttach = new TaskAttachModel();
    this.populateTaskAttach(userInformation, epochTime);
    this.collabReqConversation.setTaskAttach(this.taskAttach);
    this.collabReqConversation.setText(commentText);
    this.collabReqConversation.setTimeStamp(7979);
    if (this.isTask) {
      this.toUser = new ToUserModel();
      if (inputObj.toUserObject !== undefined) {
        this.populateToUser(inputObj);
      }
    }
    this.collabReqConversation.setToUser(this.toUser);
  }

  public populateFromUser(userInformation: any) {
    this.groupdIds = userInformation['groups'];
    this.fromUser.setFirstName(userInformation['firstName']);
    this.fromUser.setLastName(userInformation['lastName']);
    this.fromUser.setGroupIds(this.groupdIds);
    this.fromUser.setFullName(userInformation['fullName']);
    this.fromUser.setTenantId(userInformation['tenantId']);
    this.fromUser.setUmsId(userInformation['userId']);
    this.fromUser.setUserEmail(userInformation['email']);
    this.fromUser.setUserName(userInformation['firstName']);
  }

  public populateMentionedUsers(inputObj: any) {
    this.mentionedUsers = inputObj['mentionedUsers'];
  }

  public populateTaskAttach(userInformation: any, epochTime: any) {
    if (this.hasWaitingTask && this.isTask) {
      this.taskAttach.setAction('Reassigned');
    } else {
      this.taskAttach.setAction('Assigned');
    }
    this.taskAttach.setActionBy(userInformation['email']);
    this.taskAttach.setActionResponseUrl('');
    this.taskAttach.setActionTime(231221320);
    this.taskAttach.setTaskDueDate(epochTime);
    this.taskAttach.setTaskFooter('');
    this.taskAttach.setTaskHeader('');
    this.taskAttach.setTaskPrefix('Urgent');
  }

  public populateToUser(inputObj: any) {
    this.toUser.setFirstName(inputObj['toUserObject']['firstName']);
    this.toUser.setLastName(inputObj['toUserObject']['lastName']);
    this.toUser.setGroupIds(inputObj['toUserObject']['groupIds']);
    this.toUser.setFullName(inputObj['toUserObject']['fullName']);
    this.toUser.setTenantId(inputObj['toUserObject']['tenantId']);
    this.toUser.setUmsId(inputObj['toUserObject']['umsId']);
    this.toUser.setUserEmail(inputObj['toUserObject']['userEmail']);
    this.toUser.setUserName(inputObj['toUserObject']['userName']);
  }

  fetchToUserDetails(input): Observable<Collaboration[]> {
    const object: any = {
      targetedType: 'Collaboration',
      formParams: {
        personaType: 'user'
      },
      method: 'GET',
      servicePath: 'userorgroup'
    };
    return this.post(object);
    // return null;
  }

  uploadFile(formData: any): Observable<Collaboration[]> {
    const object: any = {
      targetedType: 'Collaboration',
      formParams: {},
      payload: {},
      method: 'POST',
      servicePath: 'upload'
    };
    return this.directPostforFileUpload(object, formData);
  }
}
