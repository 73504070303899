import { Component } from '@angular/core';
import { DataStorageService } from 'app/modules/common/data-storage.service';

@Component({
    selector: 'child-cell',
    template: `<div class="full-width full-height ag-grid-deviation-down" *ngIf="!isEmailListNull">
   <select (change)="onChange($event.target.value)" class="form-control full-height full-width font-size-12">
   <option value={{list.email}}  title={{list.email}}
   [selected]= "list.selected === true"
   [disabled]= "list.disabled === true"
   *ngFor="let list of approverEmailsList">
   {{list.email}}
</option>
   </select>
    </div>
    <div *ngIf="isEmailListNull">
    No Approver Email is Present</div>`
})
export class UserApproverEmailRendererComponent {
    private resultObj: any;
    private tempresultObj: any;
    approverEmailsList: Array<any>;
    private params;
    isEmailListNull = false;
    private selctedObj: any = [];
    constructor(private dataStorageService: DataStorageService) {
        this.resultObj = this.dataStorageService.getApproverEmails();
        this.tempresultObj = this.resultObj.filter(function (f) { return f; })
    }
    prepareApproverEmailsList(params, resultObj?) {
        const approverEmailsList = [];
        approverEmailsList.push({ email: 'Please Select Approver Email', selected: false, disbaled: true });
        if (params.data.approver === 'Yes') {
            this.isEmailListNull = false;
            const eachEmailObj = {
                email: params.data.email,
                selected: true
            }
            approverEmailsList.push(eachEmailObj);
        } else {
            if (resultObj instanceof Array) {
                if (resultObj.length > 0) {
                    this.isEmailListNull = false;
                    resultObj.forEach(element => {
                        const eachEmailObj = {
                            email: element,
                            selected: element === params.data.approverEmail ? true : false
                        }
                        approverEmailsList.push(eachEmailObj);
                    });
                } else {
                    this.isEmailListNull = true;
                }
            } else {
                this.isEmailListNull = true;
            }
        }
        const selectedLength = approverEmailsList.filter(i => i.selected === true)
        if (selectedLength.length === 0) {
            approverEmailsList[0].selected = true;
            approverEmailsList[0].disabled = true;
        }
        return approverEmailsList;
    }
    agInit(params: any): void {
        this.params = params;
        if (!this.dataStorageService.getIsUsersGridChangeSaved()) {
            if (params.data.approverEmail) {
                this.dataStorageService.setSavedApproverEmails({ [params.data.userId]: params.data.approverEmail });
            }
            this.approverEmailsList = []
            this.approverEmailsList = this.prepareApproverEmailsList(params, this.resultObj);
        }
    }
    private setValueofGrid(params) {
        if (this.params !== undefined) {
            if (this.dataStorageService.getIsUsersGridChangeSaved()) {
                if (this.params.data.email === params.data.email) {
                    if (params.data.approver === 'Yes') {
                        this.params.data.approverEmail = params.data.email;
                        this.dataStorageService.setSavedApproverEmails({ [params.data.userId]: params.data.email });
                    } else {
                        this.params.data.approverEmail = '';
                        this.dataStorageService.setSavedApproverEmails({ [params.data.userId]: '' });
                    }
                }
                const index = this.tempresultObj.indexOf(params.data.email);
                if (params.data.approver === 'Yes') {
                    if (index < 0) {
                        this.tempresultObj.push(params.data.email)
                    }
                } else {
                    if (index >= 0) {
                        this.tempresultObj.splice(index, 1);
                    }
                }
                this.approverEmailsList = [];
                this.approverEmailsList = this.prepareApproverEmailsList(this.params, this.tempresultObj);
            }
        }
    }
    ngOnInit() {
        this.dataStorageService.getIsApprover().subscribe(result => {
            if (result !== undefined) {
                this.setValueofGrid(result);
            }
        })
    }
    onChange(event) {
        this.params.data.approverEmail = event;
        if (event) {
            this.dataStorageService.setSavedApproverEmails({ [this.params.data.userId]: event });
        }
        this.dataStorageService.setSelectedApproverEmails({ params: [this.params], iserror: false });
    }
}
