import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Router } from '@angular/router';
import { DataStorageService } from 'app/modules/common/data-storage.service';

@Component({
  selector: 'child-cell',
  template: `
    <a *ngIf="!smLinkFlag" (click)="OnClick()" [title]="desc" class="{{ viewLinkClass }}" >
  <div *ngIf="showIcon && statusIcon=='Completed'"><u>{{ val }}</u>&nbsp;&nbsp;<span   style="color:green" class="glyphicon glyphicon-ok"></span></div>
  <div *ngIf="showIcon &&  statusIcon=='In Progress' "><u>{{ val }}</u>&nbsp;&nbsp;<span style="color:orange" class="glyphicon glyphicon-transfer"></span></div>
  <div *ngIf="showIcon &&  statusIcon=='Failed'"><u>{{ val }}</u>&nbsp;&nbsp;&nbsp;<span style="color:red" class="glyphicon glyphicon-remove"></span></div>
 <div *ngIf="showIcon &&  !statusIcon"><u>{{ val }}</u></div>
 <div *ngIf="!showIcon"><u>{{ val }}</u></div>
 </a>
    <a *ngIf="smLinkFlag  && !manageVendorLinkFlag" (click)="OnClick()" title=""
      ><em
        class="ld-icon-user font-size-20"
        title="Manage Users"
        id="Supplier-management-manage-user-icon"
      ></em></a>
    <div *ngIf="smLinkFlag && manageVendorLinkFlag">
    <a
    (click)="OnClick()"
    title="Click to manage Users"
    class="btn btn-link"
    ><div [innerHTML]="firstNode">
    </div>
     </a>
    <span style="font-size: 13px; color: #1C4E84; font-weight: bold; " >{{ secondNode }}</span>
    </div>
  `
})
export class LinkComponent {
  public params: any;
  public val: any;
  public desc = '';
  firstNode: any;
  showIcon: boolean;
  statusIcon: string;
  secondNode: any
  public smLinkFlag = false;
  public disableLink = false;
  public viewLinkClass = '';
  public manageVendorLinkFlag = false;
  constructor(
    private router: Router,
    private dataStorageService: DataStorageService
  ) {
    dataStorageService.getLinkFlagValue().subscribe(value => {
      if (value) {
        this.smLinkFlag = true;
      } else {
        this.smLinkFlag = false;
      }
    });
    dataStorageService.getLinkFlagValue().subscribe(value => {
      this.manageVendorLinkFlag = value;
    })
  }
  public agInit(params: any): void {
    this.val = params.value;
    let parser = new DOMParser();
    let doc = parser.parseFromString(this.val, 'text/html');
    if (doc.getElementsByTagName('i')[0]) {
      this.firstNode = doc.getElementsByTagName('i')[0].outerHTML;
    }
    if (doc.getElementsByTagName('span')[0]) {
      this.secondNode = doc.getElementsByTagName('span')[0].innerText;
    }
    if (
      params &&
      params.data instanceof Object &&
      params.colDef === 'revisionName' &&
      params.data['status'] === 'DISCARDED'
    ) {
      this.disableLink = true;
    } else {
      this.disableLink = false;
    }
    if (params.data.rfqCustDesc) {
      this.desc = params.data.rfqCustDesc;
    }
    this.params = params;
    if (params.colDef['viewLink']) {
      this.viewLinkClass = 'btn btn-link';
    }
    if (params.colDef.field === 'ruleName') {
      this.showIcon = true;
      this.statusIcon = params.data.status;
    }

  }

  public OnClick() {
    if (this.params.data instanceof Object) {
      if (
        this.params.data['status'] === 'INGESTION_IN_PROGRESS' &&
        this.params.colDef === 'revisionName'
      ) {
        event.preventDefault();
      }
      else {
        this.dataStorageService.setLinkValue(this.params);
      }
    } else {
      this.dataStorageService.setLinkValue(this.params);
    }
  }
}
