import { Component, ViewChild } from '@angular/core';

import { IDatePickerConfig } from 'ng2-date-picker';
import { ECalendarValue } from 'ng2-date-picker';
import * as moment from 'moment-timezone-all';
import { ICellRendererParams } from '@ag-grid-community/core';
import { DateUtils } from 'app/modules/common/utills/DateUtils.service';
import { HEADER_OBJECT } from 'app/modules/srfq/create/adhoc-rfx/shared/adhoc-rfx.constants';
import { STATUS_OBJECT } from 'app/modules/srfq/detail/detail-view/shared/detail.constants';
import { DataStorageService } from 'app/modules/common/data-storage.service';

@Component({
  selector: 'date-picker',
  template: `
  <div *ngIf="!disabled" [ngClass]="[error ? 'isError' : '']">
  <dp-date-picker
    #datePicker
    theme="dp-material grid-date"
    mode="day"
    [config]="datePickerConfig"
    [(ngModel)]="dateValue"
    (ngModelChange)="onValueChanged()"
    (onSelect)="onDateSelect()"
  ></dp-date-picker>
</div>
<div *ngIf="disabled">{{dateValue}}</div>
  `
})
export class DatePickerRendererComponent {

  datePickerConfig: IDatePickerConfig = {
    format: 'MM/DD/YYYY',
    disableKeypress: false,
    closeOnSelect: true,
    returnedValueType: ECalendarValue.String,
    drops: 'down',
    opens: 'right'
  };

  @ViewChild('datePicker', { static: false }) datePicker;

  params: ICellRendererParams;
  error = false;
  dateValue: string;
  disabled = false;
  constructor(
    private gridDataStorageService: DataStorageService
  ) { }

  agInit(params: any): void {
    this.params = params;
    this.disabled = this.checkDatePickerDisabilty(this.params);
    this.setDateFormat(this.params, this.datePickerConfig);
    this.assignCurrentDateForAdhocItem(this.params);
    this.dateValue = DateUtils.formatWithDefaultTimeZone(+this.params.value, this.datePickerConfig.format);
  }

  private assignCurrentDateForAdhocItem(params: ICellRendererParams): void {
    if (params.value === null && typeof params.data.IS_ADHOC_ITEM === 'number') {
      if (params.data.IS_ADHOC_ITEM === 1) {
        params.value = Date.now();
        params.data[params.colDef.field] = Date.now();
      }
    }
  }

  private checkDatePickerDisabilty(params: ICellRendererParams): boolean {
    switch (Number(params.data[HEADER_OBJECT.STATUS])) {
      case STATUS_OBJECT.ACCEPTED:
      case STATUS_OBJECT.REVOKED:
      case STATUS_OBJECT.AWARDED:
        return true;
      default:
        return false;
    }
  }

  private setDateFormat(datePickerParams: ICellRendererParams, datePickerConfig: IDatePickerConfig) {
    if (datePickerParams.colDef['displayModifier'] instanceof Object
      && datePickerParams.colDef['displayModifier'].hasOwnProperty('dateModifier')) {
      datePickerConfig.format = datePickerParams.colDef['displayModifier']['dateModifier'];
    }
  }

  private isValid(): boolean {
    return moment(this.dateValue, this.datePickerConfig.format, true).isValid();
  }

  onDateSelect() {
    if (this.dateValue !== undefined) {
      this.error = false;
      this.params.data[this.params.colDef.field] = moment(this.dateValue, this.datePickerConfig.format, true).unix() * 1000;
      this.params['valid'] = true;
      this.gridDataStorageService.setModifiedDatePickerNode(this.params);
    } else {
      this.error = true;
    }
  }

  onValueChanged() {
    if (this.isValid() && this.dateValue !== undefined) {
      this.error = false;
    }
    else {
      this.error = true;
      this.params['valid'] = false;
      this.gridDataStorageService.setModifiedDatePickerNode(this.params);
    }
  }

}
