import { Router } from '@angular/router';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { DetailViewService } from 'app/modules/srfq/detail/detail-view/shared/detail-view.service';
import { AlertsService } from 'app/modules/core/alerts/shared/alerts.service';

@Component({
  selector: 'child-cell',
  template: '<span class="cursor-pointer" (click)="acknowledge($event)"><em class="ld-icon-check font-size-20" title="Acknowledge this alert"></em> </span>&nbsp;&nbsp;&nbsp;<span class="cursor-pointer" (click)="clear($event)"><em class="ld-icon-dismiss font-size-20" title="Clear this alert"></em> </span>'

})
export class RfxAlertsComponent {
  public params: any;
  rfqId: number;
  public responseObject: any;
  public customPayLoadObject: any;
  public alertIdArray = [];
  public alertResponseObject: any;
  public clearAlertResponseObject: any;

  constructor(private dataStorageService: DataStorageService, private router: Router,
    private detailViewService: DetailViewService,
    private alertsService: AlertsService) {

  }
  agInit(params: any): void {
    this.params = params;
    this.rfqId = this.dataStorageService.getSelectedRFQId();

  }
  acknowledge(event) {
    const alertId = this.params.data.alertId;
    this.replyAlert(alertId);

  }
  clear(event) {
    const alertId = this.params.data.alertId;
    this.clearAlert(alertId);

  }
  public replyAlert(alertId) {
    this.alertIdArray.push(alertId);
    this.alertsService.replyAlert(this.alertIdArray).subscribe(
      (data) => this.alertResponseObject = data,
      (error) => console.log('Unable to reply for alert id in expand view:' + alertId),
      () => this.checkAlertStatus()
    );
  }
  public checkAlertStatus() {
    if (this.alertResponseObject['responseStatus'].code === 200) {
    }
  }

  public clearAlert(alertId) {
    this.alertIdArray.push(alertId);
    this.alertsService.ClearAlert(this.alertIdArray).subscribe(
      (data) => this.clearAlertResponseObject = data,
      (error) => console.log('Unable to reply for alert id in expand view:' + alertId),
      () => this.checkClearAlertStatus()
    );
  }

  public checkClearAlertStatus() {
    if (this.clearAlertResponseObject['responseStatus'].code === 200) {
    }
  }


}
