import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { BaseService } from '../base.service';
import { ConfigService } from '../configservice';
import { catchError, filter, take, switchMap, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DataStorageService } from '../data-storage.service';
import { ApiServicePathsService } from '../apiservicepaths.service';
import { PageLoaderService } from '../utills/page-loader.service';
import {SharedService} from '../../shared/services/shared.service';
import {CUSTOMER_SESSION_EXPIRED, CUSTOMER_TOKEN_INFO} from '../../shared/services/shared-consts';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(
    private baseService: BaseService,
    private configService: ConfigService,
    private router: Router,
    private dataStorageService: DataStorageService,
    private apiServicePathsService: ApiServicePathsService,
    private pageLoaderService: PageLoaderService,
    private sharedService: SharedService // public ngProgress: NgProgress
  ) {}

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.pageLoaderService.isLoading.next(this.requests.length > 0);
  }

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.requests.push(request);
    this.baseService.currentRequestsCount++;
    /*  if (!this.ngProgress.isStarted()) {
      this.ngProgress.start();
    } */
    this.pageLoaderService.isLoading.next(true);
    return next
      .handle(this.addToken(request))
      .finally(() => {
        this.removeRequest(request);
        this.baseService.currentRequestsCount--;
        if (this.baseService.currentRequestsCount === 0) {
          // this.ngProgress.done();
        }
      })
      .pipe(
        catchError(error => {
          if (error instanceof HttpErrorResponse) {
            // tslint:disable-next-line:no-angle-bracket-type-assertion
            this.removeRequest(request);
            switch ((error as HttpErrorResponse).status) {
              case 401:
                if (
                  !(
                    JSON.parse(
                      localStorage.getItem(CUSTOMER_TOKEN_INFO)
                    ) instanceof Object
                  ) ||
                  (this.getServicePath(request.url) === 'token' &&
                    (this.baseService.isRefreshingToken ||
                      this.getServicePath(this.router.url) === 'login'))
                ) {
                  console.log("monitoring the session expired pop up issue, 11")
                  this.baseService.setUnAuthrizePopup(true);
                  this.sharedService.setLocalStorageData(
                    CUSTOMER_SESSION_EXPIRED,
                    true
                  );
                  return Observable.throw(error.error);
                } else {
                  return this.handle401Error(request, next);
                }
              default:
                if (
                  this.getServicePath(request.url) === 'token' &&
                  this.baseService.isRefreshingToken
                ) {
                  this.baseService.isRefreshingToken = false;
                }
                return Observable.throw(error.error);
            }
          } else {
            this.removeRequest(request);
            return Observable.throw(error.error);
          }
        })
      );
  }
  private addToken(request) {
    let request1 = request;
    if (request.headers.get('Authorization')) {
      return request;
    }
    if (JSON.parse(localStorage.getItem(CUSTOMER_TOKEN_INFO))) {
      request1 = request.clone({
        setHeaders: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem(CUSTOMER_TOKEN_INFO)).access_token
          }`
        }
      });
    }
    return request1;
  }
  private getServicePath(url) {
    const urlsArray = url.split('/');
    return urlsArray[urlsArray.length - 1];
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.baseService.isRefreshingToken) {
      this.baseService.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.dataStorageService.tokenSubject.next(null);

      return this.configService
        .getAuthenticationToken(
          'refresh_token',
          false,
          null,
          null,
          JSON.parse(localStorage.getItem(CUSTOMER_TOKEN_INFO)).refresh_token
        )
        .pipe(
          switchMap(newTokenInfo => {
            if (
              newTokenInfo instanceof Object &&
              newTokenInfo.hasOwnProperty('access_token')
            ) {
              this.sharedService.setLocalStorageData(
                CUSTOMER_TOKEN_INFO,
                newTokenInfo
              );
              this.configService.caluculateTokenExpiryTime();
              this.dataStorageService.tokenSubject.next(newTokenInfo);
              return next.handle(this.addToken(req));
            }
          }),
          // catchError(error => {
          //   // If there is an exception calling 'refreshToken', bad news so logout.
          //   Observable.throw(error);
          //   return this.showLogoutPopup();
          // }),
          finalize(() => {
            this.baseService.isRefreshingToken = false;
          })
        );
    } else {
      return this.dataStorageService.tokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(token => {
          return next.handle(this.addToken(req));
        })
      );
    }
  }
}
