import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { ApiServiceProvider } from 'app/api-service';
import { List } from './list.model';

@Injectable()
export class ListService extends ApiServiceProvider {


    payLoadJSON: object = {
        targetedType: 'ELS',
        payload: {
            dimensionName: undefined,
        },
        method: 'POST',
        servicePath: 'getDimensionData'
    };

    getDimensionData(target) {
        if (target !== undefined) {
            this.payLoadJSON['payload']['dimensionName'] = target.trim();
            return this.post(this.payLoadJSON);
        } else {
        }
    }

}
