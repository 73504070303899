import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { RelatedNews } from './shared/related-news.model';
import { RelatedNewsService } from './shared/related-news.service';
import { OpportunitiesListService } from 'app/modules/core/opportunities-list/shared/opportunities-list.service';
import { SubscriptionLike } from 'rxjs';

@Component({
  selector: 'related-news',
  templateUrl: 'related-news.component.html',
  styleUrls: ['./related-news.component.sass'],
  providers: [RelatedNewsService]
})
export class RelatedNewsComponent implements OnInit, OnDestroy {
  relatedNews: RelatedNews[] = [];
  @Input() target: string;
  keyinsights: any;
  npKeyInsights: any;
  scrollBarConfiguration;
  private subscription: SubscriptionLike;
  constructor(
    private relatedNewsService: RelatedNewsService,
    private opportunitiesListService: OpportunitiesListService
  ) {
    this.npKeyInsights = {
      ics: [
        'Specific component savings opportunities',
        'Alternative parts to consider (if are lower priced)'
      ],
      mechanical: ['No Related News Found.'],
      capacitor: [
        'Natural Disaster in Japan may delay Lead Times : Supply Chain Experts (Source: EBN)',
        'Impact on manufacturing industry to be substantial due to recent natural disaster: Trade Union, Japan (Source:EE Times)',
        'Delay and hassles in Japanese trade raise cost of trade (Source: Distributor reports)'
      ],
      copper: [
        'LME Copper rises above $7,000/Ton, Nickel rallies higher [Source: LME]',
        'Copper prices climb above $7,000 on Chinese data (source: Investingnews)'
      ],
      comments: []
    };
  }

  ngOnInit() {
    try {
      this.subscription = this.opportunitiesListService
        .getCurrentOpportunityId()
        .subscribe(data => {
          if (data !== undefined) {
            if (data.show !== 'none') {
              this.keyinsights = this.npKeyInsights[data.show];
            }
          }
        });
    } catch (Error) {
      console.log('error in related news  ' + Error);
    }
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
