import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

import { Rawmaterialnew } from './rawmaterialnew.model';
import { ApiServiceProvider } from 'app/api-service';
import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService';

@Injectable()
export class RawmaterialnewService {
  envDetails: any;
  cmsService: any;
  constructor(private http: HttpClient,private apiServiceProvider:ApiServiceProvider,private customerConfigurationService: CustomerConfigurationService) {
    this.envDetails = this.customerConfigurationService.getEnvironmentDetails();
    this.cmsService = this.envDetails.cmsService;
  }

  public getRawmaterialList(url: string, type: string){
    const object: any = {
    targetedType:'CMS',
    clientName:this.cmsService,
    method: type,

    // headerParams:{
    // 	"userEmail":"ramachinta@gmail.com"
    // },
    servicePath:url,

  }
  return this.apiServiceProvider.post(object)
}
public getTrendsTableData(url: string, type: string,inputload:any){
  const data={
    targetedType:'MIService',
    method:type,
    payload:inputload,
    servicePath:url,
  }
  return this.apiServiceProvider.post(data)
}
public getTrendsGraphData(url: string, type: string,inputload:any){
  const object: any = {
  targetedType:'MIService',
  method: type,
  payload:inputload,
  servicePath:url,
}
return this.apiServiceProvider.post(object)
}
public getpopUpData(url: string, type: string,inputload:any){
  const data={
    targetedType:'MIService',
    method:type,
    payload:inputload,
    servicePath:url,

  }
  return this.apiServiceProvider.post(data)
}
}
