import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';
@Component({
  selector: 'child-insight',
  templateUrl: './qualitative.insights.html',
  styleUrls: ['./qualitative.insights.sass']
})
export class InsightComponent {
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  val: any = '';
  parentComp;
  formObj: any = {};
  display = false;
  insights = null;
  headerTitle = '';
  show = '';
  loading: boolean;
  constructor() { }
  agInit(params: any): void {
    this.val = params.value;
    this.formObj.cpnCmId = params.data.CPN_CM_ID;
    this.formObj.groupBy = params.colDef.field;
    this.headerTitle = params.colDef.headerName;
    this.parentComp = params.context ? params.context.componentParent : {};
  }

  displayDetail() {
    if (!this.parentComp.showInsights) {
      return;
    }
    this.display = true;
    if (!this.insights) {
      if (this.parentComp.showInsights) {
        this.loading = true;
        this.parentComp.showInsights(this.formObj).subscribe(res => {
          this.insights = res;
          this.loading = false;
        });
      }
    }
  }
  hideDetail() {
    this.show = '';
    this.display = false;
  }
}
