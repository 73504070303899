export const SHARED_CONSTANT = {
    errors: {
        cacheFull: 'Your browser cache is full. Please clear your cache and try again.',
        enableLocalStorage: 'Local storage must be enabled to run this LevaData application.'
    }
}

export const OLD_URL_MAP = {
  '/app/supplyriskassessment/mitigate-events/events-dashboard': '/app/newsEvents/mitigate-events',
  '/app/supplyriskassessment/mitigate-events': '/app/newsEvents/mitigate-events/events-list',
  '/app/supplyriskassessment/mitigate-events/events-list': '/app/newsEvents/mitigate-events/events-list'
}
