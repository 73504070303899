import { Component, OnInit } from '@angular/core';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { SubscriptionService } from '../../../common/subscription.service';

@Component({
  selector: 'child-cell',
  template: `
    <div tabindex="0">
      <multi-select-dropdown
        [items]="dropDownValues"
        readKey="cmName"
        [showCustomisedText]="showCustomMsg"
        [defaultSelected]="defaultSelectedValues"
        [selectAll]="selectAllFlag"
        (onSelectionChange)="onSelect($event)"
        [dropdownTitle]="dropDownTitle"
      ></multi-select-dropdown>
    </div>
  `
})
export class UserAutoSearchRendererComponent implements OnInit {
  public dropDownValues = [];
  public defaultSelectedValues: object[] = [];
  public request: any;
  public cmList: any;
  public autosearchShow = false;
  public dropDownTitle;
  public showCustomMsg = true;
  public selectedCommodityMangersList = {};
  public params;
  public selectAllFlag;
  constructor(
    public subscriptionService: SubscriptionService,
    private dataStorageService: DataStorageService
  ) {}

  public agInit(params: any): void {
    this.showCustomMsg = true;
    this.selectAllFlag = false;
    this.params = params;
    this.dropDownValues = params.dataByUser;
    this.defaultSelectedValues = [];
    let arrayContainsAll = false;
    let arrayContainsDefault = false;
    params.data.commodityManagersList.forEach(i => {
      if (i.cmName === 'All') {
        arrayContainsAll = true;
      } else if (i.cmName === 'Default') {
        arrayContainsDefault = true;
      }
    });
    if (arrayContainsAll) {
      this.dropDownTitle = 'Selected All';
      this.selectAllFlag = true;
      params.data.commodityManagersList = [{ cmName: 'All', checked: true }];
    } else if (
      params.data.commodityManagersList.length === 0 ||
      arrayContainsDefault
    ) {
      this.dropDownTitle = 'All';
      params.data.commodityManagersList = [
        { cmName: 'Default', checked: true }
      ];
    } else {
      this.showCustomMsg = false;
      this.defaultSelectedValues = params.data.commodityManagersList;
    }
    this.dataStorageService.setDefaultSelectedCmList({
      [params.data.userId]: params.data.commodityManagersList
    });
  }
  public ngOnInit() {}

  public onSelect(selectedArray: object[]) {
    if (selectedArray) {
      this.showCustomMsg = true;
      let finalCmList;
      if (
        selectedArray &&
        selectedArray.length === this.dropDownValues.length
      ) {
        finalCmList = [{ cmName: 'All', checked: true }];
        this.dropDownTitle = 'Selected All';
      } else if (selectedArray && selectedArray.length === 0) {
        finalCmList = [{ cmName: 'Default', checked: true }];
        this.dropDownTitle = 'All';
      } else {
        finalCmList = selectedArray;
        this.showCustomMsg = false;
      }
      this.params.data.commodityManagersList = finalCmList;
      const param1 = this.params;
      param1.value = finalCmList;
      this.selectedCommodityMangersList[this.params.data.userId] = param1;
      this.dataStorageService.setCommodityMangersList(
        this.selectedCommodityMangersList
      );
    }
  }
}
