import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

import {Subject} from 'rxjs';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { ApiServiceProvider } from 'app/api-service';
import { SubscriptionService } from 'app/modules/common/subscription.service';

@Injectable()
export class NegotiationLeversService implements OnInit {
  constructor(
    private dataStorageService: DataStorageService,
    private http: HttpClient,
    private apiService: ApiServiceProvider,
    private subscriptionService: SubscriptionService
  ) {

  }

  ngOnInit() {
  }

  getLeversList(payload) {
    return this.apiService.post(payload);
  }
  /* getLeversList(path, type) {
    let payload = {
      'url': path,
      'source': type
    }
    return this.apiService.get(payload);
  } */

  addLeverToSupplier(payload){
    return this.apiService.post(payload);
  }
}
