import { cloneDeep } from 'lodash'
import { FormControl, Validators } from '@angular/forms'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { LevaQuickFilterService } from '../leva-quick-filter.service'
import { EACH_ITEM } from './configuration'
import { ToasterService, BodyOutputType, Toast } from 'angular2-toaster'
@Component({
  selector: 'app-add-filter',
  templateUrl: './add-filter.component.html',
  styleUrls: ['./add-filter.component.sass'],
  providers: [],
})
export class AddFilterComponent implements OnInit, OnDestroy{
  displayNameInput: FormControl = new FormControl(null, Validators.required)
  validated = false
  constructor(
    public levaQuickFilterService: LevaQuickFilterService,
    private toasterService: ToasterService
  ) {}

  views1 = [
    {
      text: 'PUBLIC',
    },
    {
      text: 'PRIVATE',
    },
  ]
  defaultVal = 'Select'
  ngOnInit() {
    if(this.levaQuickFilterService.filtersList.filterName !== ''){
      this.displayNameInput.setValue(this.levaQuickFilterService.filtersList.filterName)
    }
    if(this.levaQuickFilterService.filtersList.filterType !== ''){
      this.defaultVal= this.levaQuickFilterService.filtersList.filterType
    }else{
      this.defaultVal = this.views1[0].text
    }
    // get this list from backend
    this.levaQuickFilterService.getQuickFilterKeys().subscribe((data) => {
      if (data && data.result) {
        this.levaQuickFilterService.filtersList.filterModuleId = data.result.moduleId
        this.levaQuickFilterService.filtersList.filterScopeId = data.result.scopeId
        data.result.include.forEach((element) => {
          this.levaQuickFilterService.includeKeyslist.push({
            filterGroupId: element.filterGroupId,
            filterHeaderId: element.filterHeaderId,
            headerName: element.metaInfo.displayName,
            selected: false,
            metaInfo: {
              displayName: element.metaInfo.displayName,
              fieldName: element.metaInfo.fieldName,
              targetType: element.metaInfo.targetType,
              servicePath: element.metaInfo.servicePath,
              defaultParameter: element.metaInfo.defaultParameter ? JSON.parse(element.metaInfo.defaultParameter) : null,
              multiSelect: element.metaInfo.multiSelect,
              specialVersion: element.metaInfo.specialVersion,
              type: element.metaInfo.type,
              values: element.metaInfo.values
            },
          })
        })

        this.levaQuickFilterService.filtersList.filterScopeId=data.result.scopeId;
        this.levaQuickFilterService.filtersList.filterModuleId=data.result.moduleId;
        data.result.exclude.forEach((element) => {
          this.levaQuickFilterService.excludeKeyslist.push({
            filterGroupId: element.filterGroupId,
            filterHeaderId: element.filterHeaderId,
            headerName: element.metaInfo.displayName,
            selected: false,
            metaInfo: {
              displayName: element.metaInfo.displayName,
              fieldName: element.metaInfo.fieldName,
              servicePath: element.metaInfo.servicePath,
              targetType: element.metaInfo.targetType,
              type: element.metaInfo.type,
              values: element.metaInfo.values
            },
          })
        })
        this.levaQuickFilterService.filtersList.include.forEach(element => {
          this.levaQuickFilterService.includeKeyslist.forEach(ele => {
            if(ele.metaInfo.displayName === element.metaInfo.displayName)
            {
              ele.selected=true
            }
          });
        });
        this.levaQuickFilterService.filtersList.exclude.forEach(ele =>{
          this.levaQuickFilterService.excludeKeyslist.forEach(element=>{
            if(element.metaInfo.displayName === ele.metaInfo.displayName){
              element.selected= true;
            }
          })
        })
        if (this.levaQuickFilterService.moduleConfig.showCondition && !this.levaQuickFilterService.filtersList.include.length) {
          this.onAddIncClick()
        }
      }
    })
  }
  onValueSelection(value) {
    this.defaultVal = value.text
  }
  onAddIncClick() {
    EACH_ITEM.selectedArea = 'inc'
    this.levaQuickFilterService.incCount =
      this.levaQuickFilterService.incCount + 1
    EACH_ITEM.index = 'inc-' + this.levaQuickFilterService.incIndex.toString()
    this.levaQuickFilterService.incIndex =
      this.levaQuickFilterService.incIndex + 1
    this.levaQuickFilterService.filtersList.include.push(cloneDeep(EACH_ITEM))
    this.levaQuickFilterService.lastIncKey = EACH_ITEM.index
  }
  onAddExcClick() {
    EACH_ITEM.selectedArea = 'exc'
    this.levaQuickFilterService.excCount =
      this.levaQuickFilterService.excCount + 1
    EACH_ITEM.index = 'exc-' + this.levaQuickFilterService.excIndex.toString()
    this.levaQuickFilterService.excIndex =
      this.levaQuickFilterService.excIndex + 1
    this.levaQuickFilterService.filtersList.exclude.push(cloneDeep(EACH_ITEM))
    this.levaQuickFilterService.lastExcKey = EACH_ITEM.index
  }
  checkValidation() {
    this.validated = true
    this.levaQuickFilterService.checkSaveValidation()
  }
  onClearAll() {
    this.onClearIncAll()
    this.onAddIncClick()
  }
  onClearIncAll() {
    this.levaQuickFilterService.incIndex = 1
    this.levaQuickFilterService.incCount = 0
    this.levaQuickFilterService.filtersList.include = []

    this.levaQuickFilterService.includeKeyslist.forEach((ele) => {
      ele.selected = false
    })
    if(
      this.levaQuickFilterService.filtersList.exclude.length === 0 &&
      this.levaQuickFilterService.filtersList.include.length === 0
      )
      {
        this.levaQuickFilterService.enableSave=false
      }
  }
  onClearExcAll() {
    this.levaQuickFilterService.excIndex = 1
    this.levaQuickFilterService.excCount = 0
    this.levaQuickFilterService.filtersList.exclude = []
    this.levaQuickFilterService.excludeKeyslist.forEach((ele) => {
      ele.selected = false
    })
    if(
      this.levaQuickFilterService.filtersList.exclude.length === 0 &&
      this.levaQuickFilterService.filtersList.include.length === 0
      )
      {
        this.levaQuickFilterService.enableSave=false
      }
  }
  onUpdate(){
    this.levaQuickFilterService.showAddPopup = false
    this.levaQuickFilterService.filtersList.filterName = this.displayNameInput.value
    this.levaQuickFilterService.filtersList.filterType = this.defaultVal
    const newFilterValue = this.displayNameInput.value
    this.levaQuickFilterService
      .updateFilter(this.levaQuickFilterService.filtersList)
      .subscribe((data) => {
        if (data && data.responseStatus.code === 200) {
          const toast: Toast = {
            type: 'success',
            showCloseButton: true,
            body: `<div class="toast-msg info-msg"> Saved Successfully.</div>`,
            bodyOutputType: BodyOutputType.TrustedHtml,
          }
          this.toasterService.pop(toast)
          this.levaQuickFilterService.onUpdate=true
          this.levaQuickFilterService.setNewFilter(newFilterValue)
          this.levaQuickFilterService.setMainKeyInfo(true)
        } else {
          const toast: Toast = {
            type: 'error',
            showCloseButton: true,
            body: `<div class="toast-msg info-msg">Something went wrong.</div>`,
            bodyOutputType: BodyOutputType.TrustedHtml,
          }
          this.toasterService.pop(toast)
        }
      })

  }
  onSave() {
    this.levaQuickFilterService.showAddPopup = false
    this.levaQuickFilterService.filtersList.filterName = this.displayNameInput.value
    this.levaQuickFilterService.filtersList.filterType = this.defaultVal
    const newFilterValue = this.displayNameInput.value
    this.levaQuickFilterService
      .saveFilters(this.levaQuickFilterService.filtersList)
      .subscribe((data) => {
        if (data && data.responseStatus.code === 200) {
          const toast: Toast = {
            type: 'success',
            showCloseButton: true,
            body: `<div class="toast-msg info-msg"> Saved Successfully.</div>`,
            bodyOutputType: BodyOutputType.TrustedHtml,
          }
          this.toasterService.pop(toast)
          this.levaQuickFilterService.setNewFilter(newFilterValue)
          this.levaQuickFilterService.setMainKeyInfo(true)
        } else {
          const toast: Toast = {
            type: 'error',
            showCloseButton: true,
            body: `<div class="toast-msg info-msg">Something went wrong.</div>`,
            bodyOutputType: BodyOutputType.TrustedHtml,
          }
          this.toasterService.pop(toast)
        }
      })
  }

  ngOnDestroy(): void {
    this.levaQuickFilterService.excludeKeyslist =[];
    this.levaQuickFilterService.includeKeyslist= [];
    this.levaQuickFilterService.filtersList.include =[];
    this.levaQuickFilterService.filtersList.exclude=[];
    this.levaQuickFilterService.incIndex= 1;
    this.levaQuickFilterService.excIndex =1;
    this.levaQuickFilterService.excCount=0;
    this.levaQuickFilterService.incCount=0;
    this.levaQuickFilterService.lastIncKey='';
    this.levaQuickFilterService.lastExcKey='';
    this.levaQuickFilterService.isEdit= false;
    this.displayNameInput.setValue(null)
    this.levaQuickFilterService.filtersList.filterName='';
    this.levaQuickFilterService.filtersList.tenantFilterId=0
    this.levaQuickFilterService.filtersList.filterModuleId=0
    this.levaQuickFilterService.filtersList.filterScopeId=0
    this.levaQuickFilterService.filtersList.filterType='';
  }
}
