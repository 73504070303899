import { onTreeCellValidation } from './tree-validation';
import { updateUserAction } from './tree-track-action';

export const checkInfo = (params, targetColumn, htmlElement) => {
  const rowInfo = params.data.info;
  if (rowInfo) {
    if (!rowInfo[targetColumn]) {
      rowInfo[targetColumn] = {
        oldValue: params.data[targetColumn],
        newValue: undefined,
        updatedBy: undefined,
        validations: new Map()
      };
    }

    if (rowInfo && rowInfo[targetColumn]) {
      if (rowInfo[targetColumn].validations.size > 0) {
        if (htmlElement) {
          htmlElement.classList.add('isError');
        }
      } else {
        if (
          rowInfo[targetColumn].source !== undefined &&
          (rowInfo[targetColumn].source.toLowerCase() === 'manual' ||
            rowInfo[targetColumn].source.toLowerCase() === 'system')
        ) {
          if (htmlElement) {
            htmlElement.classList.add('isEdited');
          }
        }
      }
    }
  }
};

export const addDropDown = (params, targetColumn, htmlElement) => {
  const rowElement = params.data;
  if (rowElement) {
    const eSelectDiv = document.createElement('div');
    eSelectDiv.setAttribute('class', 'full-width height-28');
    const select = document.createElement('select');
    select.setAttribute('class', 'custom-dropdown classic');

    const componentType = params.data.componentType || '';
    const headerType =
      params.colDef.visibility[params.colDef.type][componentType];

    switch (headerType.toLowerCase()) {
      case 'readonly':
        select.setAttribute('disabled', 'true');
        eSelectDiv.classList.add('opacity');
        break;
      default:
        break;
    }
    select.addEventListener('change', function($event) {
      rowElement[targetColumn] = $event.target['value'];
    });

    (params.colDef.formatting.info.possibleValues || []).forEach(element => {
      const option = document.createElement('option');
      if (rowElement[targetColumn] === element.key) {
        option.setAttribute('selected', 'selected');
      }
      option.setAttribute('value', element.key);
      option.innerHTML = element.value;
      select.appendChild(option);
    });

    eSelectDiv.appendChild(select);
    htmlElement.appendChild(eSelectDiv);
  }
};

export const renderNumber = (params, targetColumn, htmlElement) => {
  const element = params.data;
  if (element) {
    const eInput = document.createElement('input');
    eInput.setAttribute('type', 'string');
    const componentType = params.data.componentType || '';
    const headerType =
      params.colDef.visibility[params.colDef.type][componentType];

    switch (headerType.toLowerCase()) {
      case 'readonly':
        eInput.setAttribute('readonly', 'true');
        eInput.classList.add('cursor_default');
        break;
      default:
        break;
    }
    eInput.setAttribute('value', element[targetColumn] || '');
    eInput.addEventListener('change', function() {
      params.data.info[targetColumn].newValue = this.value;
      params.data.info[targetColumn].updatedBy = 'manual';
      const validationObject = {
        value: this.value,
        validationOptions: params.colDef.validations
      };
      const targetElement = document.getElementById(
        `${params.data.id}_${targetColumn}`
      );

      targetElement.classList.remove('isEdited');
      targetElement.classList.remove('isError');
      if (onTreeCellValidation(validationObject, null) instanceof Object) {
        targetElement.classList.add('isError');
        params.data.info[targetColumn].validations.set(
          onTreeCellValidation(validationObject, null)
        );
      } else {
        targetElement.classList.add('isEdited');
        params.data.info[targetColumn].validations.clear();
      }
      element[targetColumn] = this.value;
      updateUserAction({ type: 'update', data: params.data });
    });

    htmlElement.appendChild(eInput);
  }
};

// function to act as a class
export const MyCustomCellRenderer = targetColumn => {
  // gets called once before the renderer is use
  function MyCellRenderer(targetColumn) {}
  MyCellRenderer.prototype.init = function(params) {
    // create the cell
    this.eDiv = document.createElement('div');
    this.eDiv.setAttribute('id', `${params.data.id}_${targetColumn}`);
    this.eDiv.classList.add('class', 'input_render');
    checkInfo(params, targetColumn, this.eDiv);

    switch (params.colDef.formatting.type.toLowerCase()) {
      case 'text':
      case 'numeric':
      case 'integer':
      case 'date':
      case 'textarea':
      case 'datepicker':
        renderNumber(params, targetColumn, this.eDiv);
        break;
      case 'dropdown':
        addDropDown(params, targetColumn, this.eDiv);
        break;
    }
  };

  // gets called once when grid ready to insert the element
  MyCellRenderer.prototype.getGui = function() {
    return this.eDiv;
  };

  return MyCellRenderer;
};
