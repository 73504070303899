export interface BomTreeGridConfig {
  setExpandedRow: number;
  showPaginationPanel: boolean,
  paginationPageSize: number,
  toolPanel: boolean,
  floatingFilter: boolean,
  rowHeight: number,
  groupHeaderHeight: number,
  headerHeight: number,
  suppressColumnVirtualisation: boolean,
  rowSelection: string,
  rowModelType: string,
  suppressRowClickSelection: boolean,
  singleClickEdit: boolean,
  stopEditingWhenGridLosesFocus: boolean,
  enableRangeSelection: boolean,
  toolPanelSuppressPivotMode: boolean,
  toolPanelSuppressRowGroups: boolean,
  toolPanelSuppressValues: boolean
}

export const GRID_READY = 'gridReady';
export const ROW_DATA_CHANGED = 'rowDataChanged';
export const CELL_VALUE_CHANGED = 'cellValueChanged';
export const SELECTION_CHANGED = 'selectionChanged';
export const MODEL_UPDATED = 'modelUpdated';


export const GRID_CONSTANTS = {
  [GRID_READY]: 'onGridReady',
  [ROW_DATA_CHANGED]: 'onRowDataChanged',
  [CELL_VALUE_CHANGED]: 'onCellValueChanged',
  [SELECTION_CHANGED]: 'onSelectionChanged',
  [MODEL_UPDATED]: 'onModelUpdated'
};
