import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy
} from '@angular/core';
import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService';
import { CuratornegotiationleversService } from 'app/modules/curator/curatornegotiationlevers/shared/curatornegotiationlevers.service';
import { Subject } from 'rxjs';
import { AutoSearchService } from 'app/modules/core/autosearch/shared/autosearch.service';
import { RequestData } from 'app/modules/common/models/request.model';
import { PayLoad } from 'app/modules/common/models/payload.model';
import { SubscriptionService } from 'app/modules/common/subscription.service';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { ObjectToArray } from '../../../common/Pipe/ObjectToArray';
import { FormParams } from 'app/modules/common/models/FormParams';
import { SubscriptionLike } from 'rxjs';
import { ScopeDataService } from '../../../common/scope.data.service';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';

@Component({
  selector: 'child-cell',
  templateUrl: 'neglevers.component.html',
  styleUrls: ['./neglevers.component.sass']
})
export class NegLeversComponent implements OnInit, OnDestroy {
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  scope: any;
  clearSubscription: SubscriptionLike;
  getLeversSubscription: SubscriptionLike;
  showDiv: any = 'hide';
  autoSearchSelectedValue: any = '';
  autoSearchCrossIcon: any = 'hide';
  customDimensionData: any[] = new Array();
  autoSearchSearchIcon: any = 'show';
  istype = false;
  isLeverName = false;
  isLeverCategory = false;
  isLeverDesc = false;
  levType = false;
  levCat = false;
  levName = false;
  levDesc = false;
  dropDownList: any;
  type: string;
  leverName: string;
  defaultValue: 'Supplier';
  leverDesc: string;
  category: string;
  name: string;
  desc: any;
  params: any;
  leverCategory: string;
  request: RequestData;
  request1: RequestData;
  searchTerm$ = new Subject<string>();

  views: Array<object>;
  //  [
  //     { view: "Supplier", text: "Supplier", isdefault: false },
  //     { view: "Manufacturer", text: "Manufacturer", isdefault: false },
  //     { view: "CM/ODM", text: "CM/ODM", isdefault: false },
  // ]
  constructor(
    private subscriptionService: SubscriptionService,
    private dataStorageService: DataStorageService,
    private searchService: AutoSearchService,
    private customerConfigurationService: CustomerConfigurationService,
    private curatornegotiationleversService: CuratornegotiationleversService,
    private scopeDataService: ScopeDataService
  ) {}
  ngOnInit() {
    const payload = new PayLoad();
    payload.dimension = ['LEVER CATEGORIES'];
    const payloadRM = new PayLoad();
    payloadRM.isWildCardSearch = true;
    const formParams = new FormParams();
    this.request1 = new RequestData();
    this.request1.targetedType = 'NegotiationService';
    this.request1.method = 'GET';
    this.request1.servicePath = '/getLeverCategories';
    this.request1.placeHolder = 'Enter value';
    this.request1.type = 'single';
    this.request1.title = 'RM';
    this.request1.searchInputLimit = 10;
    this.request1.formParams = formParams;
    this.request1.payload = payloadRM;
  }

  agInit(params: any): void {
    this.scope = this.scopeDataService.getFocusValue();
    if (this.customerConfigurationService.getConfData(this.scope)) {
      let curatorViews;
      if (
        this.customerConfigurationService.getConfData(this.scope).viewByData
      ) {
        curatorViews = this.customerConfigurationService
          .getConfData(this.scope)
          .viewByData.filter(item => item.curator instanceof Object);
      }
      if (curatorViews instanceof Array && curatorViews.length > 0) {
        this.views = JSON.parse(
          JSON.stringify(curatorViews[0]['curator']['negotiationlevers'])
        );
      }
    }
    this.params = params;
    let x = [];
    if (this.curatornegotiationleversService.negArray.length > 0) {
      x = Object.keys(this.curatornegotiationleversService.negArray[0]);
    }
    if (x.includes(params.data.alertAndAlertType)) {
      const keys = Object.keys(
        this.curatornegotiationleversService.negArray[0][
          params.data.alertAndAlertType
        ]
      );

      if (keys.includes('dimension')) {
        const type = this.curatornegotiationleversService.negArray[0][
          params.data.alertAndAlertType
        ]['dimension'];
        this.setType(type);
      }
      if (keys.includes('leverCategory')) {
        const leverCat = this.curatornegotiationleversService.negArray[0][
          params.data.alertAndAlertType
        ]['leverCategory'];

        this.subscriptionService.setClearLeversText({
          val: leverCat,
          title: params.data.alertAndAlertType
        });
      }
      if (keys.includes('leverName')) {
        const levaName = this.curatornegotiationleversService.negArray[0][
          params.data.alertAndAlertType
        ]['leverName'];

        this.setName(levaName);
      }
      if (keys.includes('leverDesc')) {
        const levaDesc = this.curatornegotiationleversService.negArray[0][
          params.data.alertAndAlertType
        ]['leverDesc'];

        this.setDesc(levaDesc);
      }
    }
    this.clearSubscription = this.subscriptionService
      .getClearLeversText()
      .subscribe(val => {
        if (val !== undefined) {
          if (params.data.alertAndAlertType === val.title) {
            if (val.val === 'Enter Value') {
              this.autoSearchSelectedValue = '';
              // this.request.placeHolder="Enter Value";
            } else {
              this.autoSearchSelectedValue = val.val;
            }
          }
        } else {
          this.autoSearchSelectedValue = '';
          // this.request.placeHolder="Enter Value";
        }
      });
    this.type = params.data.dimension;
    this.leverName = params.data.leverName;
    this.leverDesc = params.data.leverDesc;
    this.leverCategory = params.data.leverCategory;
    if (params.colDef.headerName === 'Type') {
      this.levType = true;
      this.istype = false;
      this.isLeverCategory = true;
      this.isLeverDesc = true;
      this.isLeverName = true;
      if (params.data.dimension === '') {
        this.istype = true;
      } else {
        this.istype = false;
      }
    } else if (params.colDef.headerName === 'Lever Category') {
      this.levCat = true;
      this.istype = true;
      this.isLeverCategory = false;
      this.isLeverDesc = true;
      this.isLeverName = true;
      if (params.data.leverCategory === '') {
        this.isLeverCategory = true;
      } else {
        this.isLeverCategory = false;
      }
    } else if (params.colDef.headerName === 'Negotiation Lever (UI)') {
      this.levName = true;
      this.istype = true;
      this.isLeverCategory = true;
      this.isLeverDesc = true;
      this.isLeverName = false;
      if (params.data.leverName === '') {
        this.isLeverName = true;
      } else {
        this.isLeverName = false;
      }
    } else {
      this.levDesc = true;
      this.istype = true;
      this.isLeverCategory = true;
      this.isLeverDesc = false;
      this.isLeverName = true;
      if (params.data.leverDesc === '') {
        this.isLeverDesc = true;
      } else {
        this.isLeverDesc = false;
      }
    }
  }
  setType(val) {
    for (const i of this.views) {
      if (i['view'].charAt(0) === val) {
        i['isdefault'] = true;
      }
    }
  }
  setName(val) {
    this.name = val;
  }
  setDesc(val) {
    this.desc = val;
  }
  onClickDropdown(val) {
    const dim = val.charAt(0);
    let x = [];
    if (this.curatornegotiationleversService.negArray.length > 0) {
      x = Object.keys(this.curatornegotiationleversService.negArray[0]);
    }
    if (!x.includes(this.params.data.alertAndAlertType)) {
      this.curatornegotiationleversService.negObject[
        this.params.data.alertAndAlertType
      ] = {
        dimension: dim
      };
      if (this.curatornegotiationleversService.negArray.length === 0) {
        this.curatornegotiationleversService.negArray.push(
          this.curatornegotiationleversService.negObject
        );
      } else {
        Object.assign(
          this.curatornegotiationleversService.negArray[0],
          this.curatornegotiationleversService.negObject
        );
      }
    } else {
      this.curatornegotiationleversService.negArray[0][
        this.params.data.alertAndAlertType
      ]['dimension'] = dim;
    }
    this.curatornegotiationleversService.setEmptyValue(
      this.curatornegotiationleversService.negArray
    );

    // this.curatornegotiationleversService.setEmptyValue(true);
  }
  onAutoDimensionSelect(keyValue: any) {
    this.showDiv = 'hide';

    this.autoSearchSelectedValue = keyValue;
    let x = [];
    for (const i of this.curatornegotiationleversService.negArray) {
      x = x.concat(Object.keys(i));
    }
    if (!x.includes(this.params.data.alertAndAlertType)) {
      this.curatornegotiationleversService.negObject[
        this.params.data.alertAndAlertType
      ] = {
        leverCategory: this.autoSearchSelectedValue
      };
      if (this.curatornegotiationleversService.negArray.length === 0) {
        this.curatornegotiationleversService.negArray.push(
          this.curatornegotiationleversService.negObject
        );
      } else {
        Object.assign(
          this.curatornegotiationleversService.negArray[0],
          this.curatornegotiationleversService.negObject
        );
      }
    } else {
      this.curatornegotiationleversService.negArray[0][
        this.params.data.alertAndAlertType
      ]['leverCategory'] = this.autoSearchSelectedValue;
    }
    this.curatornegotiationleversService.setEmptyValue(
      this.curatornegotiationleversService.negArray
    );
  }
  onClickName(val) {
    let x = [];
    for (const i of this.curatornegotiationleversService.negArray) {
      x = x.concat(Object.keys(i));
    }
    if (!x.includes(this.params.data.alertAndAlertType)) {
      this.curatornegotiationleversService.negObject[
        this.params.data.alertAndAlertType
      ] = {
        leverName: val
      };
      if (this.curatornegotiationleversService.negArray.length === 0) {
        this.curatornegotiationleversService.negArray.push(
          this.curatornegotiationleversService.negObject
        );
      } else {
        Object.assign(
          this.curatornegotiationleversService.negArray[0],
          this.curatornegotiationleversService.negObject
        );
      }
    } else {
      this.curatornegotiationleversService.negArray[0][
        this.params.data.alertAndAlertType
      ]['leverName'] = val;
    }
    this.curatornegotiationleversService.setEmptyValue(
      this.curatornegotiationleversService.negArray
    );

    // this.curatornegotiationleversService.setEmptyValue(true);
  }
  onClickDesc(val) {
    let x = [];
    for (const i of this.curatornegotiationleversService.negArray) {
      x = x.concat(Object.keys(i));
    }
    if (!x.includes(this.params.data.alertAndAlertType)) {
      this.curatornegotiationleversService.negObject[
        this.params.data.alertAndAlertType
      ] = {
        leverDesc: val
      };
      if (this.curatornegotiationleversService.negArray.length === 0) {
        this.curatornegotiationleversService.negArray.push(
          this.curatornegotiationleversService.negObject
        );
      } else {
        Object.assign(
          this.curatornegotiationleversService.negArray[0],
          this.curatornegotiationleversService.negObject
        );
      }
    } else {
      this.curatornegotiationleversService.negArray[0][
        this.params.data.alertAndAlertType
      ]['leverDesc'] = val;
    }
    this.curatornegotiationleversService.setEmptyValue(
      this.curatornegotiationleversService.negArray
    );
  }

  onAutoSearchKeyDimension(event: any) {
    this.autoSearchSelectedValue = event.target.value;
    if (this.autoSearchSelectedValue === '') {
      this.showDiv = 'hide';
      this.autoSearchCrossIcon = 'hide';
      this.autoSearchSearchIcon = 'show';
      this.customDimensionData.length = 0;
      return;
    } else {
      this.showDiv = 'show';
      this.autoSearchSearchIcon = 'hide';
      this.autoSearchCrossIcon = 'show';
    }
    this.searchTerm$.next(event.target.value);
    this.getLeversSubscription = this.searchService
      .searchCustomLeverEntries(event.target.value, this.request1)
      .subscribe(data => {
        if (data.responseStatus.code === 200) {
          if (data instanceof Object) {
            if (data.result instanceof Array) {
              let inputNotThere = true;
              // if (data.result.length > 0) {
              this.customDimensionData = data.result.map(i => {
                if (
                  i.leverCatName.toLowerCase() ===
                  this.autoSearchSelectedValue.toLowerCase()
                ) {
                  inputNotThere = false;
                }
                return i.leverCatName || '';
              });
              if (inputNotThere) {
                this.customDimensionData = [
                  this.autoSearchSelectedValue
                ].concat(this.customDimensionData);
              }
              // }
            }
          }
        }
      });
  }

  isEmptyObject(obj) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return true;
  }
  onClickOutside(event: Object) {
    this.showDiv = 'hide';
  }
  clearAutoSearchText(event: any) {
    this.subscriptionService.setenablesave(false);
    this.autoSearchSelectedValue = '';
    this.showDiv = 'hide';
    this.autoSearchCrossIcon = 'hide';
    this.autoSearchSearchIcon = 'show';
  }
  ngOnDestroy() {
    if (this.clearSubscription) {
      this.clearSubscription.unsubscribe();
    }
    if (this.getLeversSubscription) {
      this.getLeversSubscription.unsubscribe();
    }
  }
}
