import { Component } from '@angular/core';
@Component({
    selector: 'autoaction-tooltip',
    template: `
    <style>
        .tooltip-information{
            padding-left: 10px !important;
            text-align: left !important;
        }
        .tooltip-information > li{
            list-style-type: none;
        }
        .key-name,
        .key-value{
            width: 50%;
        }
    </style>
    <div class="position-relative status-tooltip-value">
    <span (mouseover)="showTooltip=true;" (mouseout)="showTooltip=false;"  [ngStyle]="{'padding-right':enableReloadButton == false ? '14px' : '0px'}">{{valName}}</span>
    <div class="custom-tooltip" *ngIf="showTooltip" style="display: block; right: calc(100% + 10px); top: 0px;" >
    <span  class="key-name">Created By:</span> <span class="key-value">{{valName}}&nbsp;<{{valUserName}}></span><br>
    <span  class="key-name">Created On:</span> <span class="key-value">{{valDate}}</span>   
    </div>
</div>
      `
})
export class AutoActionTooltip {
    constructor() {

    }
    valName: string;
    valUserName: string;
    valDate: string;
    showTooltip = false;
    enableReloadButton = true;
    public agInit(params: any): void {        
        this.valName = params.data.createdByUserName;
        this.valUserName = params.data.createdBy;
        this.valDate = this.convertDate(params.data.lastUpdatedDate);
    }
    convertDate(str) {
        const date = new Date(str),
        mnth=date.toLocaleString('default', { month: 'short' }),
          day = ('0' + date.getDate()).slice(-2);
        return [day, mnth, date.getFullYear()].join('-');
      } 
}
