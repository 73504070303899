import { ApiServiceProvider } from 'app/api-service';
import { BaseService } from './../../common/base.service';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService';
import { API_SERVICE_CONSTANTS } from '../../npi/npiconstants';
import { HttpClient } from '@angular/common/http';
import {CUSTOMER_TOKEN_INFO} from '../../shared/services/shared-consts';

@Injectable()
export class UploadDownloadService {
  public envDetails: any;
  constructor(
    private http: HttpClient,
    private baseService: BaseService,
    private ApiServiceProvider: ApiServiceProvider,
    private customerConfigurationService: CustomerConfigurationService
  ) {
    this.baseService = baseService;
    this.envDetails = this.customerConfigurationService.getEnvironmentDetails();
  }
  public downloadToExcel(servicePath, inputData, requestType?, targetedType?) {
    const finalLink = '';
    const downloadData = {
      clientName: this.envDetails.RFQ,
      isFileDownload: true,
      formParams: inputData,
      headerParams: {
        // "UUID": encodeURIComponent(this.baseService.getUserInformation().auth_token),
        userEmail: this.baseService.getUserInformation().email,
        Accept: '*/*'
      },
      method: 'GET',
      pathParams: {},
      payload: {},
      servicePath: this.envDetails.crmSrvcPath + servicePath
    };
    if (requestType === 'npi') {
      let path: any;
      if (targetedType === 'programManagement') {
        path = API_SERVICE_CONSTANTS.PROGRAM_MANAGEMENT_TARGETED_TYPE;
      }
      if (targetedType === 'bomManagement') {
        path = API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE;
      }
      downloadData.servicePath = `/${path}${servicePath}`;
    }
    if (requestType === 'product') {
      downloadData.servicePath = `${
        this.envDetails['productRFQConfig']['service']
      }${servicePath}`;
    }
    if (servicePath === 'downloadSimulation') {
      downloadData.method = 'POST';
    } else if (requestType === 'TemplateDownload') {
      downloadData.clientName = '';
      downloadData.pathParams['userEmail'] =
        downloadData.headerParams.userEmail;
      downloadData.servicePath =
        this.envDetails.templateConfig.service + servicePath;
    }
    if (requestType === 'customerConfig') {
      downloadData.servicePath = servicePath;
    }
    if (requestType === 'customerConfigLogo') {
      downloadData.servicePath = servicePath;
    }
    if (requestType === 'bomDownload') {
      downloadData.servicePath = servicePath;
    }
    if (requestType === 'chatBotDownload') {
      downloadData.servicePath = `/${
        this.envDetails['chatbotService']
      }/${servicePath}`;
    }
    if (!downloadData['formParams']['Authorization']) {
      downloadData['formParams']['Authorization'] = `Bearer ${
        JSON.parse(localStorage.getItem(CUSTOMER_TOKEN_INFO)).access_token
      }`;
    }
    if (!downloadData['formParams']['userEmail']) {
      downloadData['formParams'][
        'userEmail'
      ] = this.baseService.getUserInformation().email;
    }

    return this.getUrl(downloadData);
  }

  public getUrl(payLoadJSON) {
    const params = payLoadJSON.formParams;
    const headersData = payLoadJSON.headerParams;
    let url = '';
    if (payLoadJSON.servicePath.search('\\?') > -1) {
      url = this.envDetails.zuulApiEndPointPath + payLoadJSON.servicePath + '&';
    } else {
      url = this.envDetails.zuulApiEndPointPath + payLoadJSON.servicePath + '?';
    }

    for (const key in params) {
      if (params[key] === null || params[key] === undefined) {
        params[key] = '';
      }
      url = url.concat(key + '=' + params[key] + '&');
    }
    return url;
  }
}
