import { DetailHelpRendererComponent } from 'app/modules/srfq/detail/detail-view/detail-helper.renderer.component';

export const QUALITATIVE_INSIGHTS_OBJECT = {
  headerName: 'Insights',
  field: 'TOOLTIP',
  menuTabs: ['generalMenuTab', 'columnsMenuTab'],
  sortable: true,
  cellRendererFramework: DetailHelpRendererComponent,
  tooltipField: 'Insights',
  headerTooltip: 'Insights',
  lockPosition: true,
  lockVisible: true,
  pinned: 'left',
  lockPinned: true
};

export const RESPONSE_NAME_OBJECT = {
  headerName: 'Response Name',
  field: 'RFQ_SUP_NAME',
  headerTooltip: 'Response Name',
  tooltipField: 'RFQ_SUP_NAME'
};

export const STATUS_OBJECT = {
  DRAFT: 0,
  SENT: 1,
  QUOTED: 3,
  NO_BID: 4,
  ACCEPTED: 5,
  REJECTED: 6,
  REVOKED: 7,
  AWARDED: 9,
  REQUOTED: 10
};

export const SRFX_TAB_VIEWS = {
  DETAIL: 'details',
  SIMULATION: 'simulation',
  AWARD: 'award',
  COLLABORATION: 'suppliercollaboration'
};
