import { NgModule } from '@angular/core';
import { HelpCenterComponent } from 'app/modules/help-center/help-center.component';
import { CommonModule } from '@angular/common';
import { AccordionModule } from 'ngx-accordion';
import { SharedModule } from '../shared/shared.module';
@NgModule({
  imports: [CommonModule, AccordionModule,SharedModule],
  declarations: [HelpCenterComponent],
  exports: [HelpCenterComponent]
})
export class HelpCenterModule {}
