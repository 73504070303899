import { CellEditingStoppedEvent, CellValueChangedEvent, ColDef, ColumnApi, GridApi, GridOptions, Module, PaginationChangedEvent } from '@ag-grid-community/all-modules';
import { AgGridAngular } from '@ag-grid-community/angular';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { GRID_CONSTANT } from '../../constants/ld-grid.constant';
import { CustomGridOption } from '../../interfaces/ld-grid.interface';

@Component({
  selector: 'ld-grid',
  templateUrl: './ld-grid.component.html',
  styleUrls: ['./ld-grid.component.sass']
})
export class LdGridComponent implements OnInit {
  // Default grid options
  private options: GridOptions | CustomGridOption = {
    headerHeight: GRID_CONSTANT.HEADER_HEIGHT.DEFAULT,
    rowData: null,
    // pagination: true,
    // paginationPageSize: GRID_CONSTANT.PAGINATION.MID,
    rowHeight: 30,
    columnDefs: null,
    sideBar: GRID_CONSTANT.SIDEBAR_DEFAULT_CONFIG,
    serverSidePagination: false
  }

  // Server side pagination default config
  private SSPaginationConfig = {
    pagination: true,
    rowModelType: GRID_CONSTANT.ROW_MODEL_TYPE.SERVER_SIDE,
    cacheBlockSize: 50,
    paginationPageSize: 50
  }

  private InfinitePaginationConfig = {
    rowModelType: GRID_CONSTANT.ROW_MODEL_TYPE.INFINITE,
    cacheBlockSize: 10,
    cacheOverflowSize: 2,
    infiniteInitialRowCount: 100,
    maxBlocksInCache: 10
  }

  public selectedPageSize: number = 50;
  public defaultColDef = GRID_CONSTANT.COLUMN_DEF_DEFAULT_OPTIONS;
  public ldGridOptions: GridOptions;
  public gridApi: GridApi;
  public columnApi: ColumnApi;
  @ViewChild('agGrid', null) agGrid: AgGridAngular;
  // grid options from parent component
  @Input() customGridOptions: CustomGridOption;
  @Input() modules: Module[];
  @Output() public getSelectedData: EventEmitter<any> = new EventEmitter<any>();
  @Output() onGridReady = new EventEmitter<GridApi>();
  @Output() pageSizeChange = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
    this.ldGridOptions = {
      ...this.options,
      ...this.customGridOptions,
      ...(this.customGridOptions.serverSidePagination ? this.SSPaginationConfig : this.InfinitePaginationConfig)
    };

    this.defaultColDef = {
      ...this.defaultColDef,
      ...this.customGridOptions.defaultColDef
    }
  }

  ngOnChanges(changes: SimpleChanges) {
  }


  callOnGridReady(params): void {
    this.onGridReady.emit(params);
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }

  public onBtExport(fileType: string): void {
    if (fileType === 'csv') {
      this.gridApi.exportDataAsCsv();
    } else if (fileType === 'excel') {
      this.gridApi.exportDataAsExcel();
    }
  }

  public getSelectedNodes(): void {
    this.getSelectedData.emit(this.gridApi.getSelectedRows());
  }

  public onPaginationChanged(event: PaginationChangedEvent): void {
    if (!this.gridApi) { return; }
    if (event.newPage) {
      this.pageSizeChange.emit(this.gridApi.paginationGetPageSize());
    }
  }

  public cellEditingStopped(event: CellEditingStoppedEvent): void {
  }

  public cellValueChanged(event: CellValueChangedEvent): void {
    if (event.oldValue !== event.newValue) {
      console.log(event)
    }
  }


}
