import { Injectable } from '@angular/core';
import { ApiServiceProvider } from '../../../../api-service';
@Injectable()
export class HistoryService {
  constructor(
    private apiService: ApiServiceProvider
  ) {}

  deleteConversation(collaboration, collaborations) {
    const payload = {
      targetedType: 'Collaboration',
      formParams: {
        collaborationId: collaborations.collaborationSummary.collaborationId,
        conversationId: collaboration.timeStamp
      },
      method: 'PUT',
      servicePath: 'dconversation'
    };

    return this.apiService.post(payload);
  }

  editCollab(updatedText, input, collaboration, collaborations) {
    const payload = {
      targetedType: 'Collaboration',
      formParams: {
        collaborationId: collaborations.collaborationSummary.collaborationId,
        conversationId: collaboration.timeStamp,
        text: updatedText
      },
      method: 'PUT',
      servicePath: 'uconversation'
    };

    return this.apiService.post(payload);
  }
}
