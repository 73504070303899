import { ColDef } from '@ag-grid-community/all-modules';
import { Injectable } from '@angular/core';
import { ApiServiceProvider } from 'app/api-service';
import { BaseService } from 'app/modules/common/base.service';
import {
  API_SERVICE_CONSTANTS,
  HTTP_REQUEST_TYPES,
  REST_END_POINT_URLS,
} from 'app/modules/npi/npiconstants';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { FILTER } from '../interfaces/FILTER';
import { DateRenderComponent } from 'app/modules/core/grid/date.render.component';
import { GridDateEditorComponent } from 'app/modules/core/grid/grid-date.editor.component';
import { DateUtils } from 'app/modules/common/utills/DateUtils.service';

const INITIAL_VALUE = 'initialValue';
const LATEST_VALUE = 'latestValue';
@Injectable()
export class PartSitesService {
  uniqueKey = 'mrdId';
  env = environment;
  countryRegionMap = null;
  mappedRegion = '';
  mappedLatLongs = null;
  latLongMap = null;
  changedValMap = {};
  editedRows = [];
  errorList = [];
  headers = [];
  partSiteMetaInfo: any
  loaderInfo = new BehaviorSubject<any>(undefined);

  constructor(
    private apiService: ApiServiceProvider,
    private baseService: BaseService
  ) { }

  setLoaderInfo(val: any) {
    this.loaderInfo.next(val);
  }
  getLoaderInfo() {
    return this.loaderInfo.asObservable();
  }

  setMetaInfo(val) {
    this.partSiteMetaInfo = val
  }

  getMetaInfo() {
    return this.partSiteMetaInfo
  }

  addPartToSite(payload) {
    const requestPayload = {
      targetedType: 'riskManagementService',
      method: 'POST',
      servicePath: `partsite/add`,
      payload
    };
    return this.apiService.post(requestPayload);
  }


  getHeaders(tabKey = 'part-sites'): Observable<any> {
    let widgetTabKey: string, view: string;
    switch (tabKey) {
      case 'part-sites':
        widgetTabKey = 'PART_SITES';
        view = API_SERVICE_CONSTANTS.WIDGET_SECTION.SUMMARY;
        break;
      case 'change-history':
        widgetTabKey = 'CHANGE_HISTORY';
        view = API_SERVICE_CONSTANTS.WIDGET_SECTION.SUMMARY;
        break;
    }
    const payload = {
      targetedType: API_SERVICE_CONSTANTS.CUSTOMER_CONFIG_TARGETED_TYPE,
      formParams: {
        widgetTabKey,
        tenantId: this.baseService.getUserInformation().tenantId,
        view,
      },
      method: HTTP_REQUEST_TYPES.POST,
      generic: true,
      servicePath:
        API_SERVICE_CONSTANTS.PATH_SEPARATOR +
        API_SERVICE_CONSTANTS.WIDGET_MANAGEMENT_API_NAME +
        API_SERVICE_CONSTANTS.PATH_SEPARATOR +
        REST_END_POINT_URLS.GET_WIDGET_HEADERS,
    };
    return this.apiService.post(payload);
  }

  getRows(
    category = 'part-sites',
    paginatorObject?,
    filterObj?
  ): Observable<any> {
    let payload;
    switch (category) {
      case 'change-history':
        break;
      case 'part-sites':
        payload = {
          targetedType: `${this.env.risk}`,
          method: HTTP_REQUEST_TYPES.POST,
          servicePath: `/partsite/list`,
          payload: paginatorObject,
        };
        if (typeof filterObj !== 'undefined') {
          payload.formParams = {
            filter: encodeURIComponent(JSON.stringify(filterObj)),
          };
        }
        break;
    }
    return this.apiService.post(payload);
  }

  parseFilters(data) {
    const columnFilters = [];
    // dataTypes: varchar, set, date, boolean, ''
    data.forEach((item) => {
      if (item.headerMetaInfo.dataType !== '' && !item.headerInfo.hidden) {
        const filter: FILTER = {
          name: item.headerInfo.headerName,
          columnDataType: item.headerMetaInfo.dataType,
          dbName: item.headerInfo.field,
        };
        if (item.headerMetaInfo.dataType === 'set') {
          switch (item.headerMetaInfo.dbColumn) {
            case 'country':
            case 'state':
            case 'city':
            case 'siteType':
            case 'region':
              item.headerMetaInfo.dataType = 'varchar';
              filter.columnDataType = item.headerMetaInfo.dataType;
              columnFilters.push(filter);
              break;
          }
        } else if (item.headerMetaInfo.dataType === 'decimal') {
          item.headerMetaInfo.dataType = 'varchar';
          filter.columnDataType = item.headerMetaInfo.dataType;
          columnFilters.push(filter);
        } else {
          columnFilters.push(filter);
        }
      }
    });
    return columnFilters;
  }

  getCellClass(params) {
    let cellClass = '';
    const errorFlag = `${params.colDef.field}_valid`;
    const modifiedFlag = `${params.colDef.field}_modified`;
    if (params.colDef.editable !== undefined && params.colDef.editable) {
      if (
        params.data[errorFlag] !== undefined &&
        params.data[errorFlag] !== null
      ) {
        cellClass = 'grid-cell-value-error';
      } else {
        if (
          params.data[modifiedFlag] !== undefined &&
          params.data[modifiedFlag]
        ) {
          cellClass = 'grid-cell-value-changed';
        } else {
          cellClass = 'grid-cell-editable';
        }
      }
    }
    return cellClass;
  }

  parseHeaders(data) {
    const agGridHeaders = [];
    (data || []).forEach((header) => {
      const headerObj = {
        field: header.headerInfo.field,
        headerName: header.headerInfo.displayName,
        tooltipField: header.headerInfo.field,
        editable: header.headerInfo.editable || false,
        menuTabs: ['generalMenuTab', 'columnsMenuTab'],
        headerComponentParams: header,
        cellClass: this.getCellClass.bind(this),
      } as ColDef;
      switch (headerObj.field) {
        case 'siteDescription':
          headerObj.width = 300;
          break;
        case 'createdDate':
          headerObj.width = 146;
          headerObj.cellRendererFramework = DateRenderComponent,
            headerObj.cellRendererParams = {
              format: {
                dateFormat: 'mediumDate',
              },
            };
          break;
        case 'updatedDate':
          headerObj.width = 160;
          headerObj.cellRendererFramework = DateRenderComponent,
            headerObj.cellRendererParams = {
              format: {
                dateFormat: 'mediumDate',
              },
            };
          break;
        case 'validityDate':
          headerObj.width = 160;
          headerObj.cellRendererFramework = DateRenderComponent,
            headerObj.cellRendererParams = {
              format: {
                dateFormat: 'mediumDate',
              },
            };
          headerObj.cellEditorFramework = GridDateEditorComponent;
          headerObj.filter = 'agDateColumnFilter',
            headerObj.filterParams = {
              comparator: (filterLocalDateAtMidnight, cellValue) => {
                const cellDate = DateUtils.getDatewithDefaultTimeZone(
                  cellValue
                ).toDate();
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0;
                }

                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                }

                if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
              },
              browserDatePicker: true,
              suppressAndOrCondition: true,
              clearButton: true,
            };
          break;
        case 'country':
        case 'state':
        case 'city':
        case 'region':
          break;
        case 'isPrimary':
          headerObj.filterParams = {
            applyButton: true,
            stringModifier: false,
          };
          headerObj.cellEditor = 'agRichSelectCellEditor';
          headerObj.cellEditorParams = {
            values: [true, false],
          };
          headerObj.cellRenderer = (params) => {
            return `<div class="full-width-10 text-style pull-left">${params.value
              }</div>
              <div class="pull-right">
                  <i class="ld-icon-arrow-down ld-blue font-size-10" id="dropdownArrow"></i>
              </div>`;
          };

          headerObj.width = 105;
          break;
      }

      if (header.headerInfo.hasOwnProperty('hidden')) {
        headerObj['hide'] = header.headerInfo.hidden;
      }

      if (headerObj.editable) {
        if (!headerObj.hasOwnProperty('cellEditorParams')) {
          headerObj.cellEditorParams = {};
        }

        if (header.headerInfo.validations) {
          headerObj.cellEditorParams['isMandatory'] =
            header.headerInfo.validations.required;
        }
      }
      agGridHeaders.push(headerObj);
    });
    const checkBoxHeader = {
      headerName: '',
      lockPosition: true,
      cellClass: 'locked-col',
      checkboxSelection: true,
      field: '',
      width: 40,
      lockVisible: true,
      pinned: 'left',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      menuTabs: [],
    };
    this.headers = [...agGridHeaders];
    return [checkBoxHeader].concat(agGridHeaders);
  }

  getChangeHistory(trackId: string) {
    const payload = {
      targetedType: `${this.env.risk}`,
      method: HTTP_REQUEST_TYPES.GET,
      servicePath: '/partsite/history/audit',
      formParams: {
        partsiteId: trackId
      },
    };
    return this.apiService.post(payload);
  }

  deleteRows(part = 'part-sites', partIds: string[]): Observable<any> {
    const payload = {
      targetedType: `${this.env.risk}`,
      method: HTTP_REQUEST_TYPES.DELETE,
      servicePath: `/partsite/list`,
      payload: partIds,
    };
    return this.apiService.post(payload);
  }

  saveChanges(cat: string, data: object) {
    const payload = {
      targetedType: `${this.env.risk}`,
      method: HTTP_REQUEST_TYPES.PUT,
      servicePath: `/partsite/list`,
      payload: data,
    };
    return this.apiService.post(payload);
  }

  calculateErrors(params, keyVariable) {
    const editedColumn = params.colDef.field;
    const errorFlag = editedColumn + '_valid';

    const errObj: object = {};
    errObj[keyVariable] = params.data[keyVariable];
    errObj[editedColumn] = params.newValue;
    errObj[errorFlag] = params.data[errorFlag];

    if (this.errorList.length) {
      const matchedErrors = this.errorList.filter(
        (error) =>
          error[keyVariable] === errObj[keyVariable] &&
          error.hasOwnProperty(errorFlag)
      );

      if (!matchedErrors.length) {
        this.errorList.push(errObj);
      }
    } else {
      this.errorList.push(errObj);
    }
  }

  removeErrorsFromList(params, keyVariable) {
    const errorFlag = params.colDef.field;
    const keyColumn = params.data[keyVariable];
    const keyColumnErrors: any[] = this.errorList.filter(
      (error) =>
        error[keyVariable] === keyColumn && error.hasOwnProperty(errorFlag)
    );
    keyColumnErrors.filter((keyColumnError) => {
      const errorIndex = this.errorList.findIndex((element, index, array) => {
        if (
          element[keyVariable] === keyColumnError[keyVariable] &&
          element.hasOwnProperty(errorFlag)
        ) {
          return true;
        }
      });
      if (errorIndex > -1) {
        this.errorList.splice(errorIndex, 1);
      }
    });
  }

  mandatoryValidation(params, keyVariable) {
    const modifiedColumnDefObj = this.headers.filter(
      (header) => header.field === params.colDef.field
    )[0];
    const errorFlag = `${params.colDef.field}_valid`;
    const isMandatory = modifiedColumnDefObj.cellEditorParams.hasOwnProperty(
      'isMandatory'
    )
      ? modifiedColumnDefObj.cellEditorParams.isMandatory
      : false;
    if (isMandatory) {
      const mandatoryError = {
        type: 'NOTEMPTY',
        info: null,
        errorMessage: `${modifiedColumnDefObj.headerName} should not be empty.`,
      };
      if (params.newValue !== null && params.newValue !== '') {
        params.data[errorFlag] = null;
        this.removeErrorsFromList(params, keyVariable);
      } else {
        params.data[errorFlag] = mandatoryError;
        this.calculateErrors(params, keyVariable);
      }
    }
  }

  prepareEditedRows(editedRow, keyVariable) {
    const editedRowId = editedRow[keyVariable];

    if (!this.editedRows.length) {
      this.editedRows.push(editedRow);
    } else {
      const filteredEditedRow = this.editedRows.filter(
        (match) => match[keyVariable] === editedRowId
      );
      if (filteredEditedRow.length) {
        filteredEditedRow[0] = editedRow;
      } else {
        this.editedRows.push(editedRow);
      }
    }
  }

  prepareChangedValueMap(params) {
    if (params.newValue === params.oldValue) {
      return;
    }
    const columnName = params.field;
    const rowPrimaryKeyValue = params.keyId;
    const currChangedRow = this.changedValMap[rowPrimaryKeyValue];

    if (!!currChangedRow) {
      const currColumn = currChangedRow[columnName];
      if (!!currColumn) {
        currColumn[LATEST_VALUE] =
          params.newValue !== null ? params.newValue : '';
      } else {
        currChangedRow[columnName] = {};
        currChangedRow[columnName][INITIAL_VALUE] =
          params.oldValue !== null ? params.oldValue : '';
        currChangedRow[columnName][LATEST_VALUE] =
          params.newValue !== null ? params.newValue : '';
      }
    } else {
      const currEditedObj = {};
      currEditedObj[rowPrimaryKeyValue] = {};
      currEditedObj[rowPrimaryKeyValue][columnName] = {};
      currEditedObj[rowPrimaryKeyValue][columnName][INITIAL_VALUE] =
        params.oldValue !== null ? params.oldValue : '';
      currEditedObj[rowPrimaryKeyValue][columnName][LATEST_VALUE] =
        params.newValue !== null ? params.newValue : '';
      this.changedValMap = Object.assign({}, this.changedValMap, currEditedObj);
    }
  }

  updateModifiedRows(params, keyVariable) {
    const editRow = params.data;
    if (Object.keys(this.changedValMap).length > 0) {
      for (const rowKeyValue in this.changedValMap) {
        if (this.changedValMap[rowKeyValue] !== undefined) {
          const currRowMapValues = this.changedValMap[rowKeyValue];
          if (!!currRowMapValues && Object.keys(currRowMapValues).length > 0) {
            let isAnyActualChangeExistInEntireRow = false;
            for (const colKeyValue in currRowMapValues) {
              if (currRowMapValues[colKeyValue] !== undefined) {
                const modifiedFlag = colKeyValue + '_modified';
                if (
                  currRowMapValues[colKeyValue][LATEST_VALUE] !==
                  currRowMapValues[colKeyValue][INITIAL_VALUE]
                ) {
                  isAnyActualChangeExistInEntireRow = true;
                  editRow[modifiedFlag] = true;
                  break;
                } else {
                  editRow[modifiedFlag] = false;
                }
              }
            }
            if (!isAnyActualChangeExistInEntireRow) {
              const filteredEditedRows = this.editedRows.filter(
                (editedRow) => editedRow[keyVariable] !== rowKeyValue
              );
              this.editedRows = filteredEditedRows;
            }
            for (const colKeyValue in currRowMapValues) {
              if (currRowMapValues[colKeyValue] !== undefined) {
                const modifiedFlag = colKeyValue + '_modified';
                if (
                  currRowMapValues[colKeyValue][LATEST_VALUE] !==
                  currRowMapValues[colKeyValue][INITIAL_VALUE]
                ) {
                  editRow[modifiedFlag] = true;
                } else {
                  editRow[modifiedFlag] = false;
                }
              }
            }
          }
        }
      }
    }
  }
}
