import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { QuickSightDashboard } from './quicksight-dashboard.model';
import { environment } from 'environments/environment';
import { ApiServiceProvider } from 'app/api-service';

@Injectable()
export class QuickSightDashboardService {
    public env = environment;
    parameters ={
        CM_ODM_NAME: 'CMODM',
        COMMODITY_MANAGER_NAME:'CommodityManager',
        COMMODITY_NAME:'Commodity',
        CPN:'CPN',
        MANUFACTURER_NAME:'Manufacturer',
        MPN:'MPN',
        SUB_COMMODITY_NAME:'SubCommodity',
        SUPPLIER_NAME:'Supplier',
        CPN_DESC: 'CPNDescription',
        BUSINESS_UNIT_NAME: 'businessunit',
        PRODUCT_FAMILY_NAME: 'ProductFamily',
        PRODUCT_NAME: 'Product',
        CPN_STRATEGY: 'Source',
        CONTROL: 'Control',
        CM_ODM_REGION: 'cmodmregion',
        CM_ODM_LOCATION: 'cmodmlocation',
        division: 'division'

    }
    constructor(private apiService: ApiServiceProvider) { }
    public getDashboardUrl(dashboardId?: string,disableReset?:boolean): Observable<any> {
        const payload = {
            targetedType: `${this.env.content.contentManagementService}`,
            method: 'GET',
            servicePath: `${this.env.content.dashboardService}/dashboards/${dashboardId}/${disableReset}`
        };
        return this.apiService.post(payload);
    }

    retriveQuickSightParameterNames(dimension){
       return this.parameters[dimension];
    }
}
