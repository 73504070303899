export const RESPONSE = {
  responseStatus: {
    code: 200,
    reasonPhrase: 'OK'
  },
  result: {
    headerGroups: null,
    headers: [
      {
        headerMetaId: null,
        scope: 'COMMODITY',
        widget: 'TEMPLATE_LIST',
        dbColumnName: 'name',
        entryType: 'COLUMN',
        custHeaderId: 1017,
        custId: 380,
        columnName: 'Name',
        displayModifier: null,
        userId: 0,
        linkEnabled: false,
        colorTextEnabled: false,
        rowGroupEnabled: false,
        tooltipEnabled: false,
        ifNullHyphen: true,
        editable: false,
        displayName: 'Name',
        pinned: false
      },
      {
        headerMetaId: null,
        scope: 'COMMODITY',
        widget: 'TEMPLATE_LIST',
        dbColumnName: 'description',
        entryType: 'COLUMN',
        custHeaderId: 1018,
        custId: 380,
        columnName: 'Description',
        displayModifier: null,
        userId: 0,
        linkEnabled: false,
        colorTextEnabled: false,
        rowGroupEnabled: false,
        tooltipEnabled: false,
        ifNullHyphen: true,
        editable: false,
        displayName: 'Description',
        pinned: false
      },
      {
        headerMetaId: null,
        scope: 'COMMODITY',
        widget: 'TEMPLATE_LIST',
        dbColumnName: 'templateTypeId',
        entryType: 'COLUMN',
        custHeaderId: 1019,
        custId: 380,
        columnName: 'Type',
        displayModifier: null,
        userId: 0,
        linkEnabled: false,
        colorTextEnabled: false,
        rowGroupEnabled: false,
        tooltipEnabled: false,
        ifNullHyphen: true,
        editable: false,
        displayName: 'Type',
        pinned: false
      },
      {
        headerMetaId: null,
        scope: 'COMMODITY',
        widget: 'TEMPLATE_LIST',
        dbColumnName: 'createdOn',
        entryType: 'COLUMN',
        custHeaderId: 1020,
        custId: 380,
        columnName: 'Created On',
        displayModifier: {
          initialValue: '##SYSTEM##',
          dateModifier: 'MM/DD/YYYY',
          stringModifier: null,
          checkBoxEnabled: false,
          colorIndicator: null,
          rfxMetricData: null,
          linkEnabled: null,
          colorTextEnabled: null,
          tooltipEnabled: null,
          ifNullHyphen: null,
          cmForeCastInsights: false,
          isUnitCostCol: false,
          isSplitCol: false,
          dataType: null,
          currencyFormat: null,
          decimalPrecision: 0,
          executeImpact: false,
          toggleEditableSource: null,
          toggleEditable: false,
          unitCostCol: false,
          splitCol: false
        },
        userId: 0,
        linkEnabled: false,
        colorTextEnabled: false,
        rowGroupEnabled: false,
        tooltipEnabled: false,
        ifNullHyphen: true,
        editable: false,
        displayName: 'Created On',
        pinned: false
      },
      {
        headerMetaId: null,
        scope: 'COMMODITY',
        widget: 'TEMPLATE_LIST',
        dbColumnName: 'publishedOn',
        entryType: 'COLUMN',
        custHeaderId: 1021,
        custId: 380,
        columnName: 'Published On',
        displayModifier: {
          initialValue: '##SYSTEM##',
          dateModifier: 'MM/DD/YYYY',
          stringModifier: null,
          checkBoxEnabled: false,
          colorIndicator: null,
          rfxMetricData: null,
          linkEnabled: null,
          colorTextEnabled: null,
          tooltipEnabled: null,
          ifNullHyphen: null,
          cmForeCastInsights: false,
          isUnitCostCol: false,
          isSplitCol: false,
          dataType: null,
          currencyFormat: null,
          decimalPrecision: 0,
          executeImpact: false,
          toggleEditableSource: null,
          toggleEditable: false,
          unitCostCol: false,
          splitCol: false
        },
        userId: 0,
        linkEnabled: false,
        colorTextEnabled: false,
        rowGroupEnabled: false,
        tooltipEnabled: false,
        ifNullHyphen: true,
        editable: false,
        displayName: 'Published On',
        pinned: false
      },
      {
        headerMetaId: null,
        scope: 'COMMODITY',
        widget: 'TEMPLATE_LIST',
        dbColumnName: 'updatedOn',
        entryType: 'COLUMN',
        custHeaderId: 1022,
        custId: 380,
        columnName: 'Last Updated On',
        displayModifier: {
          initialValue: '##SYSTEM##',
          dateModifier: 'MM/DD/YYYY',
          stringModifier: null,
          checkBoxEnabled: false,
          colorIndicator: null,
          rfxMetricData: null,
          linkEnabled: null,
          colorTextEnabled: null,
          tooltipEnabled: null,
          ifNullHyphen: null,
          cmForeCastInsights: false,
          isUnitCostCol: false,
          isSplitCol: false,
          dataType: null,
          currencyFormat: null,
          decimalPrecision: 0,
          executeImpact: false,
          toggleEditableSource: null,
          toggleEditable: false,
          unitCostCol: false,
          splitCol: false
        },
        userId: 0,
        linkEnabled: false,
        colorTextEnabled: false,
        rowGroupEnabled: false,
        tooltipEnabled: false,
        ifNullHyphen: true,
        editable: false,
        displayName: 'Last Updated On',
        pinned: false
      },
      {
        headerMetaId: null,
        scope: 'COMMODITY',
        widget: 'TEMPLATE_LIST',
        dbColumnName: 'createdBy',
        entryType: 'COLUMN',
        custHeaderId: 1023,
        custId: 380,
        columnName: 'Created By',
        displayModifier: null,
        userId: 0,
        linkEnabled: false,
        colorTextEnabled: false,
        rowGroupEnabled: false,
        tooltipEnabled: false,
        ifNullHyphen: true,
        editable: false,
        displayName: 'Created By',
        pinned: false
      },
      {
        headerMetaId: null,
        scope: 'COMMODITY',
        widget: 'TEMPLATE_LIST',
        dbColumnName: 'publishedBy',
        entryType: 'COLUMN',
        custHeaderId: 1024,
        custId: 380,
        columnName: 'Published By',
        displayModifier: null,
        userId: 0,
        linkEnabled: false,
        colorTextEnabled: false,
        rowGroupEnabled: false,
        tooltipEnabled: false,
        ifNullHyphen: true,
        editable: false,
        displayName: 'Published By',
        pinned: false
      },
      {
        headerMetaId: null,
        scope: 'COMMODITY',
        widget: 'TEMPLATE_LIST',
        dbColumnName: 'activeStatus',
        entryType: 'COLUMN',
        custHeaderId: 1025,
        custId: 380,
        columnName: 'Status',
        displayModifier: null,
        userId: 0,
        linkEnabled: false,
        colorTextEnabled: false,
        rowGroupEnabled: false,
        tooltipEnabled: false,
        ifNullHyphen: true,
        editable: false,
        displayName: 'Status',
        pinned: false
      }
    ]
  },
  invalidResult: null,
  pagingConfiguration: null,
  message: null
};
export const templateHeaders = [
  { headerName: '', field: '', width: 100, checkboxSelection: true },
  { headerName: 'Template Name', field: 'name', width: 200 },
  { headerName: 'Description', field: 'description', width: 250 },
  { headerName: 'Type', field: 'templateTypeId', width: 100 },
  /* {
    headerName: 'Created On',
    field: 'createdOn',
    width: 150,
    valueFormatter: this.formatDate
  },
  {
    headerName: 'Published On',
    field: 'publishedOn',
    width: 150,
    valueFormatter: this.formatDate
  },
  {
    headerName: 'Last Updated On',
    field: 'updatedOn',
    width: 150,
    valueFormatter: this.formatDate
  }, */
  { headerName: 'Published By', field: 'publishedBy', width: 150 },
  { headerName: 'Created By', field: 'createdBy', width: 150 },
  { headerName: 'Status', field: 'activeStatus', width: 100 }
];

export function getMockTemplateFields() {
  const dummyFields = [];
  for (let i = 0; i < 10; i++) {
    dummyFields.push({
      metaInfo: {
        datatype: `datatype-${i + 1}`,
        custDisplayName: `custDisplayName-${i + 1}`,
        headerOrder: i + 1
      },
      headerName: `headerName-`,
      Permission: { privileges: { supplier: i % 2 === 0 ? 'Yes' : 'No' } }
    });
  }
  return dummyFields;
}
export const MOCK_PERMISSIONS = {
  customerPrivileges: {
    AWARD: 'NOTVISIBLE',
    SCOPE: 'EDITABLE',
    DETAILS: 'EDITABLE',
    PREVIEW: 'FORBACKEND',
    SIMULATION: 'VISIBLE',
    SIMCOMPARSION: 'VISIBLE'
  },
  supplierPrivileges: {
    SUPPLIER: 'VISIBLE'
  },
  defaults: {
    customer: {
      defaulValueOption: 'SYSTEM',
      value: null
    },
    supplier: {
      defaulValueOption: 'INPUT',
      value: 'Test'
    }
  }
};
export const mockMetricData = {
  responseStatus: {
    code: 200,
    reasonPhrase: 'OK'
  },
  result: [
    {
      metricId: 1,
      templateId: 1,
      metricType: '2',
      metricCategory: '2',
      screenLocation: '2',
      dataType: 'Decimal',
      formula: '123 - 456'
    },
    {
      metricId: 2,
      templateId: 1,
      metricType: '1',
      metricCategory: '1',
      screenLocation: '1',
      dataType: 'Decimal',
      formula: '123 - 456'
    }
  ]
};

export const ImportTemplateConfigData = {
  templateId: 1,
  customerId: 380,
  name: 'name',
  description: 'description',
  templateTypeId: 1,
  parentTemplateId: 41,
  scopeId: 1,
  status: 'DRAFT',
  createdOn: 1531477482000,
  createdBy: 'Naveen A',
  updatedOn: 1532189223000,
  updatedBy: 'Naveen A',
  publishedOn: 0,
  publishedBy: 'Naveen A',
  additionalInfo: null,
  templateHeaderList: null,
  overrideParent: false
};
