import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter
} from '@angular/core';

import { TimeSelectionCalendar } from './shared/time-selection-calendar.model';
import { TimeSelectionCalendarService } from './shared/time-selection-calendar.service';
import { TimeSelectionDataService } from 'app/modules/core/time-selection/shared/time-selectiondata.service';
import { BaseService } from 'app/modules/common/base.service';
import { Router, NavigationEnd } from '@angular/router';
import { SubscriptionLike } from 'rxjs';
import { ScopeDataService } from 'app/modules/common/scope.data.service';

@Component({
  selector: 'time-selection-calendar',
  templateUrl: 'time-selection-calendar.component.html',
  styleUrls: ['./time-selection-calendar.component.sass'],
  providers: [TimeSelectionCalendarService]
})
export class TimeSelectionCalendarComponent
  implements OnInit, OnChanges, OnDestroy {
  public timeSelectionCalendar: TimeSelectionCalendar[] = [];
  @Input('timePeriodData') public timePeriodData: any;
  @Input('viewType') public viewType: string;
  public quartersArray: any = [];
  public quarterObject: any;
  public monthsObject: any;
  public monthsArray = [];
  public currentYear: any;
  public showMonths = false;
  public showQuarters = true;
  public subscription: SubscriptionLike;
  public clearSubscription: SubscriptionLike;
  public optionSubscription: SubscriptionLike;
  public viewSubscription: SubscriptionLike;
  public urlsubscription: SubscriptionLike;
  @Output() public quarterChanged: EventEmitter<boolean> = new EventEmitter<
  boolean
>();
  public monthObjectTemp: any = [];
  constructor(
    private timeSelectionCalendarService: TimeSelectionCalendarService,
    private timeSelectionDataService: TimeSelectionDataService,
    private baseService: BaseService,
    private scopeDataService: ScopeDataService,
    private router: Router
  ) {
    if (this.urlsubscription !== undefined) {
      this.urlsubscription.unsubscribe();
    }
    this.optionSubscription = this.timeSelectionDataService
      .getOptionSelected()
      .subscribe(val => {
        if (val !== undefined) {
          this.clearAll();
          this.setSelection(val);
        }
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    // *this.timeSelectionDataService.updateTitle();
    // this.changeView(this.viewType);
    if(this.timePeriodData && this.timePeriodData.yearObject){
      this.currentYear = this.timePeriodData.yearObject.year;
      this.quarterObject = this.timePeriodData.yearObject.quarter;
      this.monthsObject = this.timePeriodData.yearObject.month;
      console.log(this.monthsObject);
      // this.monthsObject = Object.keys(this.getMonthObject());
      this.quartersArray = Object.keys(this.quarterObject);
      this.monthsArray = Object.keys(this.monthsObject);
      this.changeView(this.viewType);
      // console.log("this.monthsObject");
      //
      // console.log(this.getMonthObject());
      }
  }

  public ngOnInit() {
   
  }

  public getMonthObject() {
    try {
      const timeperiodObject = this.timeSelectionDataService.getFullTimePeriodObject()
        .timePeriod[this.timePeriodData.yearObject.year.year][0];
      for (const qtrInfo of Object.keys(timeperiodObject)) {
        if (
          this.timeSelectionDataService.getFullTimePeriodObject().timePeriod[
            this.timePeriodData.yearObject.year.year
          ][0][qtrInfo] !== null &&
          this.timeSelectionDataService.getFullTimePeriodObject().timePeriod[
            this.timePeriodData.yearObject.year.year
          ][0][qtrInfo]['months'] !== null
        ) {
          const mon = this.timeSelectionDataService.getFullTimePeriodObject()
            .timePeriod[this.timePeriodData.yearObject.year.year][0][qtrInfo][
            'months'
          ][0]['title'];

          const tempVar =
            this.timeSelectionDataService.getFullTimePeriodObject().timePeriod[
              this.timePeriodData.yearObject.year.year
            ][0][qtrInfo]['months'][0]['title'] +
            this.timeSelectionDataService
              .getFullTimePeriodObject()
              .timePeriod[this.timePeriodData.yearObject.year.year][0][qtrInfo][
                'months'
              ][0]['date'].split('-')[0]
              .slice(-2);

          this.monthObjectTemp.push(tempVar);
        }
      }
    } catch (error) {
      console.error('Error in getMonthObject ' + error);
    }
    return this.monthObjectTemp;
  }
  public changeView(val) {
    switch (val) {
      case 'Quarters':
        this.showMonths = false;
        this.showQuarters = true;
        break;
      case 'Months':
        this.showMonths = true;
        this.showQuarters = false;
        break;
      case 'all':
        this.showMonths = true;
        this.showQuarters = true;
        break;
    }
  }
  public setSelection(option) {
    switch (option) {
      case 'currentYear': // console.log(option)
        break;
      case 'currentMonth': // console.log(option)
        break;
    }
  }
  public onYearSelect() {
    if (this.currentYear.isSelect === false) {
      this.currentYear.isSelect = true;
    } else {
      this.currentYear.isSelect = false;
    }
    this.timePeriodData.yearObject.year = this.currentYear;
    for (const i of this.quartersArray) {
      if (
        (this.quarterObject[i].isSelect =
          this.quarterObject[i].isSelect === true)
      ) {
        this.quarterObject[i].isSelect = this.quarterObject[i].isSelect = false;
      }
    }
    for (const i of this.monthsArray) {
      if (this.monthsObject[i].isSelect === true) {
        this.monthsObject[i].isSelect = false;
      }
    }

    if (this.viewType === 'Months') {
      this.timeSelectionDataService.updateTitle('month');
    } else {
      this.timeSelectionDataService.updateTitle();
    }
    this.onUpdateTimeSelection();
  }

  public onQuarterSelect(quarter) {
    this.quarterChanged.emit(true)
    this.quarterObject[quarter].isSelect =
      this.quarterObject[quarter].isSelect === false ? true : false;
    this.timePeriodData.yearObject.quarter[quarter] = this.quarterObject[
      quarter
    ];
    if (this.checkIfQuarterIsSelected()) {
      this.onSelectYear();
    }
    // *this.timeSelectionDataService.updateTitle();
    this.onUpdateTimeSelection();
  }
  public onMonthSelect(month) {
    if (this.monthsObject[month].isSelect === true) {
      this.monthsObject[month].isSelect = false;
    } else {
      this.monthsObject[month].isSelect = true;
    }
    this.timePeriodData.yearObject.month[month] = this.monthsObject[month];
    this.onSelectYear();
    this.timeSelectionDataService.updateTitle('month');
    this.onUpdateTimeSelection();
  }
  public onSelectYear() {
    this.currentYear.isSelect = true;
  }

  public checkIfQuarterIsSelected() {
    let check = false;
    for (const quarter of Object.keys(this.quarterObject)) {
      if (!check) {
        if (this.quarterObject[quarter].isSelect === true) {
          check = true;
        } else {
          check = false;
        }
      }
    }
    return check;
  }
  public clearAll() {
    for (const i of this.quartersArray) {
      if (
        (this.quarterObject[i].isSelect =
          this.quarterObject[i].isSelect === true)
      ) {
        this.quarterObject[i].isSelect = this.quarterObject[i].isSelect = false;
      }
    }
    if (this.currentYear !== undefined) {
      this.currentYear.isSelect = false;
      this.timePeriodData.yearObject.year = this.currentYear;
      this.onUpdateTimeSelection();
    }
  }

  public onUpdateTimeSelection() {
    this.scopeDataService.updateYearSelection(
      this.timePeriodData,
      this.viewType
    );
  }
  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.viewSubscription) {
      this.timeSelectionDataService.setView(undefined);
    }
  }
}
