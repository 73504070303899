import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-derive-values',
  templateUrl: './derive-values.component.html',
  styleUrls: ['./derive-values.component.sass']
})
export class DeriveValuesComponent {
  @Input() fieldInfo;
  @Output() onFieldInfoChange: EventEmitter<Object> = new EventEmitter<Object>();

  constructor() { }

  fieldInfoChange(key, value) {
    if (this.fieldInfo[key] !== value) {
      this.fieldInfo[key] = value;
      this.onFieldInfoChange.emit(true);
    }
  }

}
