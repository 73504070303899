import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import * as _ from 'lodash';
import {
  IAfterGuiAttachedParams,
  IDoesFilterPassParams,
  IFilterParams,
  RowNode,
  IRowModel,
} from '@ag-grid-community/core';
import { SrfqService } from 'app/modules/srfq/shared/srfq.service';
import { ObjectUtils } from 'app/modules/common/utills/ObjectUtils.service';
import { ObjectType } from 'app/modules/common/interface/objectype';
import { IFilterAngularComp } from '@ag-grid-community/angular';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { keyframes } from '@angular/animations';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'filter-cell',
  templateUrl: './custom-filter.component.html',
  styleUrls: ['./custom-filter.component.sass'],
  providers: [SrfqService],
})
export class CustomFilterComponent implements IFilterAngularComp {
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  groupColumn = false;
  public text = '';
  public appendClicked = false;
  public selectedAll = true;
  public applyDisable = false;
  public searchedSuggestion = false;
  public searchedAndSelectedAll = false;
  public applyClicked = false;
  public appendFilterButton = false;
  public searchedKeyword: any;
  public appendedOptions: any;
  public highlightClearFilter = false;
  @ViewChild('input', { read: ViewContainerRef, static: true }) public input;
  public tempOptions = [];
  public clearApplied = false;
  public options = [];
  public rowData = [];
  public rowModel = [];
  public previousOptions = [];
  public showLoader = true;
  public previousTempOptions = [];

  public plotArray = [];

  private params: IFilterParams | any;
  private valueGetter: (rowNode: RowNode) => any;
  private test: Function;
  constructor(
    private dataStorageService: DataStorageService,
    private srfqService: SrfqService
  ) {}
  public agInit(params: IFilterParams) {
    this.params = params;
    // this.formOptions(this.params);
    // this.assignOptions();
  }

  public formOptions(params: any) {
    this.showLoader = true;
    this.options = [];
    this.params = params;
    this.valueGetter = params.valueGetter;
    this.groupColumn = params.groupColumn || false;
    this.rowData = this.params.rowModel.gridApi.rowModel.gridOptionsWrapper.gridOptions.rowData;
    if (this.params.stringModifier) {
      // Getting options for columns which have status modifier like PREFERNCES in Simulation Page
      const obj: ObjectType = {};
      const k = [];
      for (let i = 0, len = this.params.data.length; i < len; i++) {
        obj[this.params.data[i].id] = this.params.data[i].value;
        k.push(this.params.data[i].id);
      }
      // this.params['data'].forEach(element => {
      //   obj[element['id']] = element.value;
      // });
      if (this.params.hasOwnProperty('isCreateGrid')) {
        this.options = [];
        k.forEach((val) => {
          this.options.push({
            name: obj[val],
            selected: true,
            id: val,
          });
        });
      } else {
        let keyColumn = this.params.colDef.field;
        if (this.groupColumn) {
          keyColumn = this.params.keyColumn || this.params.colDef.field;
        }

        this.rowData.forEach((element) => {
          if (obj[element[keyColumn]] !== undefined) {
            this.options.push({
              name: obj[element[keyColumn]],
              selected: true,
              id: element[keyColumn],
            });
          }
        });
      }
    } else {
      // If column doesnt have String modifer
      this.rowData.forEach((element) => {
        if(this.params.colDef.field === 'rfqProxyGcmUser') {
          const key = ObjectUtils.deepFind(element, 'proxyGcmUserList');
          if(key.length) {
            if(element['proxyGcmUserList'] instanceof Array && element['proxyGcmUserList'].length) {
              element['proxyGcmUserList'].forEach(data => {
                this.options.push({
                  name: data,
                  selected: true,
                });    
              });
            } 
            
          }
        }
        else if (this.params.colDef.field.includes('.')) {
          // If column field has obj path like info.name etc
          const key = ObjectUtils.deepFind(element, this.params.colDef.field);
          if (key.length) {
            this.options.push({
              name: key,
              selected: true,
            });
          }
        } else {
          if (
            element[this.params.colDef.field] !== null &&
            element[this.params.colDef.field].length
          ) {
            this.options.push({
              name: element[this.params.colDef.field],
              selected: true,
            });
          }
        }
      });
    }
    this.options = this.getUniqueOptions(this.options);
  }

  public assignOptions() {
    this.options = this.getUniqueOptions(this.options);
    this.tempOptions = [...this.options];
    this.tempOptions = this.tempOptions.sort(this.compare);
    this.showLoader = false;
  }

  public isFilterActive(): boolean {
    return this.tempOptions.length !== this.options.length;
  }
  public doesFilterPass(params: IDoesFilterPassParams): any {
    // To pass the filters applied from our end to the grid. This method passes a boolean

    if (this.params.stringModifier) {
      return (
        this.plotArray.find((filterWord) => {
          return this.valueGetter(params.node) === filterWord.id;
        }) instanceof Object
      );
    } else {
      if(this.params.colDef.field === 'rfqProxyGcmUser') {
        return (
          this.plotArray.find((filterWord) => {
            return this.valueGetter(params.node) === filterWord.name;
          }) instanceof Object
        );
      }
      return (
        this.plotArray.find((filterWord) => {
          return this.valueGetter(params.node).toString() === filterWord.name;
        }) instanceof Object
      );
    }
  }

  public getModel(): any {
    if(this.params.colDef.field === 'rfqProxyGcmUser') {
      if(this.selectedAll) {
        if(this.previousTempOptions.length >0 && this.tempOptions.length !== this.previousTempOptions.length)
        return {value: this.tempOptions}
        else 
        return {selectAll:true}
      }
      else {
        return {value: this.tempOptions}
      }
    }
    return { value: this.text };
  }

  public setModel(model: any): void {
    this.text = model ? model.value : '';
  }
  public onChange(newValue): void {
    // called on key word search
    this.searchedKeyword = newValue;
    if (newValue.length) {
      if (this.text !== newValue) {
        this.selectedAll = true;
        this.text = newValue;
        this.searchedSuggestion = true;
        this.tempOptions = [];
        this.options = this.getUniqueOptions(this.options);
        this.options.forEach((element) => {
          if (element.name.toLowerCase().includes(newValue.toLowerCase())) {
            this.tempOptions.push(element);
          }
        });
        this.tempOptions.forEach((value) => {
          value.selected = true;
        });
        if (!this.tempOptions.length) {
          this.applyDisable = true;
        } else {
          this.applyDisable = false;
        }
      }
    } else {
      this.text = '';
      this.tempOptions = this.formRowOptions(this.tempOptions);
      const checkedArray = _.differenceWith(
        JSON.parse(JSON.stringify(this.options)),
        this.tempOptions,
        _.isEqual
      );
      checkedArray.forEach((obj) => {
        obj.selected = false;
      });
      this.tempOptions = this.tempOptions.concat(checkedArray);
      this.searchedSuggestion = false;
      this.checkSelectedStatusAfterApply();
    }
    this.tempOptions = this.tempOptions.sort(this.compare);
  }

  public onCheckBoxClick(suggest) {
    // If checkbox is checked pushing it into plotting grid array , else splicing it from that array
    if (suggest.selected) {
      this.checkSelectedStatus();
    } else {
      this.checkSelectedStatusAfterApply();
      if (!!this.tempOptions) {
        this.tempOptions.forEach((suggestion) => {
          if (suggestion.name === suggest.name) {
            this.tempOptions.splice(suggestion, -1);
          }
        });
      }
    }
    this.getButtonStatus();
  }

  public clickApplyFunction() {
    this.applyClicked = true;

    if (this.searchedSuggestion) {
      /* If any keyword is searched , and clicked on apply ,
       to plot grid with those matching options only */
      if (this.appendClicked) {
        const referredOptions = JSON.parse(JSON.stringify(this.tempOptions));
        const previousplottedArray = this.formRowOptions(this.tempOptions);
        this.tempOptions = referredOptions.concat(previousplottedArray);
        this.appendClicked = false;
      }
      this.tempOptions = this.getUniqueOptions(this.tempOptions);
      this.tempOptions = this.tempOptions.sort(this.compare);
      const checkedArray = _.differenceWith(
        JSON.parse(JSON.stringify(this.options)),
        this.tempOptions,
        _.isEqual
      );
      checkedArray.forEach((obj) => {
        obj.selected = false;
      });
      this.previousTempOptions = JSON.parse(JSON.stringify(this.tempOptions));
      this.previousTempOptions = this.previousTempOptions.concat(checkedArray);
      this.dataStorageService.proxyOptions = this.previousTempOptions;
    } else {
      this.dataStorageService.proxyOptions = this.tempOptions;
      this.tempOptions = this.tempOptions.filter((i) => i.selected === true);
      for (let i = 0; i < this.tempOptions.length; i++) {
        if (this.tempOptions[i].selected === false) {
          this.selectedAll = false;
        }
      }
    }
    this.plotArray = this.tempOptions.filter((i) => i.selected === true);
    // this.options = [];
    if (this.params.hasOwnProperty('isCreateGrid')) {
      if (this.selectedAll && !this.searchedSuggestion) {
        if (this.params.hasOwnProperty('isCreateGrid')) {
          delete this.srfqService.partialMatchFilterColumnData[
            this.params.colDef.headerName
          ];
          this.dataStorageService.setPartialMatchFilter([
            {
              headerName: this.params.colDef.headerName,
              columnDataType: 'STRING',
              columnName: this.params.colDef.headerName,
              filters: 'No Filter',
            },
          ]);
        }
      } else {
        const k = [];
        this.plotArray.forEach((val) => {
          k.push(val.id);
        });
        const obj = {
          columnDataType: 'STRING',
          dbName: this.params.columnHeader.dbColumnName,
          endVal: null,
          startVal: k,
          type: 'GridFil',
        };
        this.srfqService.partialMatchFilterColumnData[
          this.params.colDef.headerName
        ] = obj;
        this.dataStorageService.setPartialMatchFilter([obj]);
      }
    } else {
      this.params.filterChangedCallback();
      this.test();
    }

    // this.tempOptions = this.options;
    this.text = '';
    this.searchedSuggestion = false;
    this.checkSelectedStatusAfterApply();
  }

  public selectAll() {
    // To change the default options selection
    this.options.forEach((option) => {
      option.selected = this.selectedAll;
    });
    // To change the searched options selection
    this.tempOptions.forEach((option) => {
      option.selected = this.selectedAll;
    });
    // To plot grid and get apply button status
    if (this.selectedAll) {
      this.applyDisable = false;
      if (!this.searchedSuggestion) {
        this.tempOptions = JSON.parse(JSON.stringify(this.options));
      } else {
        this.searchedAndSelectedAll = true;
        this.tempOptions = this.tempOptions;
      }
      this.tempOptions = this.tempOptions.sort(this.compare);
    } else {
      this.applyDisable = true;
    }
    // this.getButtonStatus();
  }

  public getUniqueArray(array1, array2) {
    return _.differenceWith(
      JSON.parse(JSON.stringify(array1)),
      array2,
      _.isEqual
    );
  }
  public afterGuiAttached(params) {
    //  To hide toggle of filter
    this.test = params.hidePopup;
    this.showLoader = true;
    if(this.params.colDef.field === 'rfqProxyGcmUser' && this.dataStorageService.proxyOptions 
      && this.dataStorageService.proxyOptions.length>0) {
      this.options = this.dataStorageService.proxyOptions;
    }
    if (this.options.length === 0) {
      this.formOptions(this.params);
      this.assignOptions();
    }
    this.previousOptions = this.options;
    if (this.previousTempOptions.length) {
      this.tempOptions = this.options = JSON.parse(
        JSON.stringify(this.previousTempOptions)
      );
      this.previousTempOptions = [];
    } else {
      if (this.params.hasOwnProperty('isCreateGrid')) {
        const obj: ObjectType = {};
        const k = [];
        for (let i = 0, len = this.params.data.length; i < len; i++) {
          obj[this.params.data[i].id] = this.params.data[i].value;
          k.push(this.params.data[i].id);
        }

        this.options = [];
        if (
          this.srfqService.partialMatchFilterColumnData[
            this.params.colDef.headerName
          ] instanceof Object
        ) {
          k.forEach((val) => {
            if (
              this.srfqService.partialMatchFilterColumnData[
                this.params.colDef.headerName
              ].startVal.indexOf(val) > -1
            ) {
              this.options.push({
                name: obj[val],
                selected: true,
                id: val,
              });
            } else {
              this.options.push({
                name: obj[val],
                selected: false,
                id: val,
              });
            }
          });
        } else {
          k.forEach((val) => {
            this.options.push({
              name: obj[val],
              selected: true,
              id: val,
            });
          });
        }
      } else {
        if (
          this.rowData.length > this.params.rowModel.rowsToDisplay.length ||
          this.rowData.length === this.params.rowModel.rowsToDisplay.length
        ) {
          this.options = this.formRowOptions(this.options);
        }
      }
    }
    this.assignOptions();
    if (!this.selectedAll) {
      this.previousOptions = this.getUniqueOptions(this.previousOptions);
      this.tempOptions = this.options = this.previousOptions;
      this.tempOptions = this.tempOptions.sort(this.compare);
    }
    this.checkSelectedStatus();
    this.highlightClearFilter = this.getClearFilterStatus();
    this.showLoader = false;
  }

  public formRowOptions(array) {
    array = [];
    if (this.params.stringModifier) {
      // Getting options for columns which have status modifier like PREFERNCES in Simulation Page
      const obj: ObjectType = {};
      this.params.data.forEach((element) => {
        obj[element.id] = element.value;
      });

      let keyColumn = this.params.colDef.field;
      if (this.groupColumn) {
        keyColumn = this.params.keyColumn || this.params.colDef.field;
      }

      this.params.rowModel.rowsToDisplay.forEach((element) => {
        if (element.data !== undefined) {
          array.push({
            name: obj[element.data[keyColumn]],
            selected: true,
            id: element.data[keyColumn],
          });
        }
      });
    } else {
      this.params.rowModel.rowsToDisplay.forEach((element) => {
        if(this.params.colDef.field === 'rfqProxyGcmUser') {
          const key = ObjectUtils.deepFind(element, 'proxyGcmUserList');
          if(key && key.length) {
            if(element['proxyGcmUserList'] instanceof Array && element['proxyGcmUserList'].length) {
              element['proxyGcmUserList'].forEach(data => {
                this.options.push({
                  name: data,
                  selected: true,
                });    
              });
            } 
            
          }
        }
        else if (this.params.colDef.field.includes('.')) {
          // If column field has obj path like info.name etc
          const key = ObjectUtils.deepFind(element, this.params.colDef.field);
          if (key.length) {
            array.push({
              name: key,
              selected: true,
            });
          }
        } else {
          if (
            element.data[this.params.colDef.field] !== null &&
            element.data[this.params.colDef.field].length
          ) {
            array.push({
              name: element.data[this.params.colDef.field],
              selected: true,
            });
          }
        }
      });
      if(this.params.colDef.field === 'rfqProxyGcmUser')
      {
        array= this.options;
      }
    }
    array = this.getUniqueOptions(array);
    return array;
  }
  public ngAfterViewInit(params: IAfterGuiAttachedParams): void {
    setTimeout(() => {
      this.input.element.nativeElement.focus();
    });
  }

  public getButtonStatus() {
    if (this.tempOptions.length === 0) {
      this.applyDisable = true;
    } else {
      this.applyDisable = false;
    }
  }

  public clearFilters() {
    this.clearApplied = true;
    if (this.params.hasOwnProperty('isCreateGrid')) {
      delete this.srfqService.partialMatchFilterColumnData[
        this.params.colDef.headerName
      ];
      this.dataStorageService.setPartialMatchFilter([
        {
          headerName: this.params.colDef.headerName,
          columnDataType: 'STRING',
          columnName: this.params.colDef.headerName,
          filters: 'No Filter',
        },
      ]);
    } else {
      this.options.forEach((element) => {
        element.selected = true;
      });
      this.assignOptions();
      this.params.filterChangedCallback();
    }

    this.test();
    this.text = '';
    this.searchedSuggestion = false;
    this.selectedAll = true;
  }
  public appendFilter(event) {
    if (event.target.checked) {
      this.appendClicked = true;
    } else {
      this.appendClicked = false;
    }
  }

  public cancelPopup() {
    this.text = '';
    this.test();
  }

  public getUniqueOptions(optionArray) {
    const obj: ObjectType = {};
    optionArray.forEach((i) => {
      if (obj[i.name] === undefined) {
        obj[i.name] = i;
      }
    });
    return Object.values(obj);
    /* optionArray = optionArray.filter((thing, index) => {
      return (
        index ===
        optionArray.findIndex(obj => {
          return JSON.stringify(obj) === JSON.stringify(thing);
        })
      );
    });
    return optionArray; */
  }

  public checkSelectedStatus() {
    this.selectedAll = this.tempOptions.every(function (item: any) {
      return item.selected === true;
    });
  }

  public checkSelectedStatusAfterApply() {
    for (let i = 0; i < this.tempOptions.length; i++) {
      if (this.tempOptions[i].selected === false) {
        this.selectedAll = false;
      }
    }
  }

  public getClearFilterStatus(): boolean {
    if (this.params.hasOwnProperty('isCreateGrid')) {
      if (
        this.srfqService.partialMatchFilterColumnData instanceof Object &&
        Object.keys(this.srfqService.partialMatchFilterColumnData).length
      ) {
        if (
          Object.keys(this.srfqService.partialMatchFilterColumnData).includes(
            this.params.colDef.headerName
          )
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return this.tempOptions instanceof Array &&
        this.tempOptions.every((i) => i.selected === true)
        ? false
        : true;
    }
  }
  compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  }
}
