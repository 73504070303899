export const EACH_ITEM = {
  filterGroupId: '',
  filterHeaderId: '',
  filterValues: [],
  filterType: null,
  headerName: '',
  metaInfo: {},
  index: '',
  selectedArea: '',
  isSelected: false
}
