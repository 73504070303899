import { FeatureService } from './../../common/feature.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BodyOutputType, Toast, ToasterService } from 'angular2-toaster';
import { BaseService } from 'app/modules/common/base.service';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { RequestData } from 'app/modules/common/models/request.model';
import { DateUtils } from 'app/modules/common/utills/DateUtils.service';
import {
  LdCustomTagTypes,
  TaskPrefix,
} from 'app/modules/leva-ui-library/components/custom-tag/custom-tag.service';
import {
  DialogBoxService,
  DialogButtonName,
  DialogSize,
  DialogType,
  LdDialog,
} from 'app/modules/leva-ui-library/components/dialog-box/dialog-box.service';
import {
  LDTaskContentConfiguration,
  TaskContentType,
} from 'app/modules/leva-ui-library/components/task-location-context/task-location-context.service';
import { ToastService } from 'app/modules/srfq/shared/toaster.service';
import moment from 'moment';
import * as uuid from 'uuid';
import { IDatePickerConfig } from 'ng2-date-picker';
import { element } from 'protractor';
import { first, single } from 'rxjs/operators';
import { BreadCrumbService } from '../bread-crumb.service';
import { TaskManagementService } from './shared/task-management.service';
import {
  ItemList,
  LdCustomDrowpdown,
} from 'app/modules/leva-ui-library/components/custom-dropdown/custom-dropdown.service';
import { OLD_URL_MAP } from 'app/modules/shared/constants/shared.constants';

@Component({
  selector: 'app-task-management',
  templateUrl: './task-management.component.html',
  styleUrls: ['./task-management.component.sass'],
  encapsulation: ViewEncapsulation.None,
  providers: [TaskManagementService],
})
export class TaskManagementComponent implements OnInit, AfterViewInit {
  @ViewChild('search', { read: ViewContainerRef, static: true }) searchElement;
  @ViewChild('topicFieldName', { read: ViewContainerRef, static: false })
  topicFocus;
  tasksList = [];
  public requestData: RequestData;
  taskStatusList = [];
  filterList = [];
  nodata = false;
  showBox = false;
  editPopupOpen = false;
  selectedStatus = 1;
  searchText;
  viewType = 'DUE_DATE_VIEW';
  showLoader = false;
  editedTaskPayload;
  contextList = [];
  selectedDueDate = null;
  addPopupOpen = false;
  public invalidDropdown = false;
  public invalidTopic = false;
  currentuser = false;
  selectedUserFullname;
  taskIdPrefix;
  selectedUserUmsId;
  selectedUserEmail;
  fromUsername;
  showLocation = true;
  locationIconType = LdCustomTagTypes.LOCATION_DEFAULT;
  locationDisplayName;
  locationUrl;
  updateTaskId;
  fromuserUms;
  fromUserEmail;
  usersList = [];
  minDate = new Date();
  form = new FormGroup({
    topicName: new FormControl('', Validators.maxLength(25)),
    topicDescription: new FormControl(),
    selectedDate: new FormControl(),
  });
  taskLocationConfiguration: LDTaskContentConfiguration = {
    type: TaskContentType.LOCATION,
    tags: [
      {
        type: LdCustomTagTypes.LOCATION_DEFAULT,
        displayName: '',
        otherInformation: [
          {
            url: '',
          },
        ],
      },
    ],
  };
  dropdownConfig: LdCustomDrowpdown = {
    iconName: 'calendar_today',
    defaultTooltip: 'Due Date View',
    menuItems: [],
  };
  taskContextConfiguration: LDTaskContentConfiguration = {
    type: TaskContentType.CONTEXT,
    tags: [
      {
        type: LdCustomTagTypes.LOCATION_DEFAULT,
        displayName: '',
        otherInformation: [
          {
            url: '',
          },
        ],
      },
    ],
  };
  statusDropdown = {
    label: 'Status',
    hideLabel: true,
    type: 'static',
    val: 'programType',
    id: 'programTypeId',
    noToggle: true,
    className: 'task-management-height',
    isDisable: false,
    filterConfig: {
      multiSelect: false,
      defaultItemSelection: true,
    },
    selectedItemVal: null,
    hideTooltip: true,
    hideClearSelection: true,
    defaultSelectedView: {},
    // isSelectAll: true,
    data: [],
  };
  filterDropdown = {
    label: 'Status',
    hideLabel: true,
    type: 'static',
    val: 'programType',
    id: 'programTypeId',
    noToggle: true,
    className: 'task-management-height',
    isDisable: false,
    filterConfig: {
      multiSelect: false,
      defaultItemSelection: true,
    },
    selectedItemVal: null,
    hideTooltip: true,
    hideClearSelection: true,
    defaultSelectedView: {
      displayName: 'Assigned to me',
      val: 3,
      selected: true,
    },
    // isSelectAll: true,
    data: [],
  };
  dateConfig: IDatePickerConfig = {
    format: 'YYYY-MMM-DD hh:mm:ss A',
  };
  showtaskTypeFilter = false;
  showViewFilter: boolean = false;
  resetDropdown = 1;
  userDropdown = {
    label: 'User',
    hideLabel: true,
    type: 'multiselectvirtualscroll',
    val: 'user',
    id: 'user',
    noToggle: true,
    className: 'task-management-height',
    enableCustomFilter: true,
    enableIcons: {
      type: 'user',
    },
    showAll: false,
    isSelectAll: false,
    isDisable: false,
    filterConfig: {
      multiSelect: true,
      defaultItemSelection: true,
    },
    selectedItemVal: null,
    hideTooltip: true,
    hideClearSelection: true,
    hideEmptyValue: true,
    defaultSelectedView: {
      displayName: 'Select',
      val: 'select',
    },
    // isSelectAll: true,
    data: [],
  };
  selectedUsers: any = [];
  targetPage: string;
  @HostListener('document:keydown', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (event.code === 'Escape' && !this.editPopupOpen && !this.addPopupOpen) {
      this.dataStorageService.taskActionInput = null;
      this.dataStorageService.setAddTask(false);
    }
  }
  constructor(
    public dataStorageService: DataStorageService,
    private dialogBoxService: DialogBoxService,
    private taskManagementService: TaskManagementService,
    public baseService: BaseService,
    private router: Router,
    private _toasterService: ToasterService,
    private breadCrumbService: BreadCrumbService,
    private activatedRoute: ActivatedRoute,
    private featureService: FeatureService
  ) {}
  addNewTaskPopup: LdDialog = {
    title: 'Add Task',
    id: 'add_task_popup',
    template: true,
    size: DialogSize.MEDIUM,
    type: DialogType.CONFIRMATION,
    beforeClose: (target): boolean => {
      if (target && target.primary && target.id === 'save') {
        if (
          this.form.value['topicName'] === null ||
          String(this.form.value['topicName']).trim() === '' ||
          !this.selectedUserEmail ||
          !this.selectedUserUmsId ||
          !this.selectedUserFullname ||
          !this.selectedStatus ||
          this.selectedDueDate === null ||
          (this.userDropdown.type === 'multiselectvirtualscroll' &&
            !this.userDropdown.isSelectAll &&
            this.selectedUsers.length < 1)
        ) {
          if (
            this.form.value['topicName'] === null ||
            String(this.form.value['topicName']).trim() === ''
          ) {
            this.invalidTopic = true;
          }
          if (
            this.addPopupOpen &&
            this.userDropdown.type === 'multiselectvirtualscroll' &&
            !this.userDropdown.isSelectAll &&
            this.selectedUsers.length < 1
          ) {
            this.invalidDropdown = true;
          }
          return false;
        }
        return true;
      }
      if (
        (this.dialogBoxService.selectedButton &&
          this.dialogBoxService.selectedButton.id === 'Close') ||
        this.dialogBoxService.selectedButton.id === 'Cancel'
      ) {
        return false;
      }
      return true;
    },
    discardConfiguration: {
      title: 'Delete',
      size: DialogSize.SMALL,
      draggable: true,
      modal: true,
      id: 'discardConf',
      message: 'Do you want to delete the task?',
    },
    buttons: [
      {
        title: DialogButtonName.DELETE,
        id: 'delete',
      },
      {
        title: DialogButtonName.CANCEL,
        id: 'cancel',
        callback: (): boolean => {
          if (!this.dataStorageService.showTaskPane) {
            this.showBox = false;
            this.dataStorageService.setAddTask(false);
          }
          this.dialogBoxService.close(this.addNewTaskPopup.id);
          return true;
        },
      },
      {
        title: DialogButtonName.POST,
        id: 'save',
        primary: true,
        callback: (): boolean => {
          return true;
        },
      },
    ],
  };
  ngOnInit() {
    this.showtaskTypeFilter = false;
    this.showViewFilter = false;
    this.getfilters();
    this.getUserList();
    this.getStatusList();
    this.getViewTypes();
    this.dataStorageService.getRefreshTask().subscribe((data) => {
      if (data) {
        this.getfilters();
        this.getUserList();
        this.getStatusList();
        this.getViewTypes();
      }
    });
    this.dataStorageService
      .getAddTask()
      .pipe(first())
      .subscribe((data) => {
        if (data) {
          this.targetPage = (data && data.location && data.location.url) || '';
          setTimeout(() => {
            this.dataStorageService.showTaskLoader = false;
            this.openAddNewTaskPopup();
          }, 2000);
        }
      });
    this.dataStorageService
      .getEditTask()
      .pipe(first())
      .subscribe((data) => {
        if (data) {
          if (this.dataStorageService.openEdittaskPayload) {
            setTimeout(() => {
              this.dataStorageService.showTaskPane = false;
              this.dataStorageService.taskActionInput = true;
              this.onItemClick(this.dataStorageService.openEdittaskPayload);
              this.dataStorageService.openEdittaskPayload = null;
              this.dataStorageService.setEditTask(false);
            }, 1000);
          }
        }
      });
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.searchElement) this.searchElement.element.nativeElement.focus();
    }, 10);
  }
  onKeydownMain($event) {
    this.invalidTopic = false;
  }
  onDropdownClicked(event) {
    if (event) {
      this.dataStorageService.filterPayload[1].filterName = event.displayName;
      this.dataStorageService.filterPayload[1].filterValue = event.viewValue;
      this.getTaskList(this.dataStorageService.filterPayload);
    }
  }
  getViewTypes() {
    this.dropdownConfig.menuItems = [];
    this.taskManagementService.getViewTypes().subscribe((data) => {
      if (data && data.result) {
        data.result.forEach((element) => {
          let menu: ItemList = {
            displayName: element.viewName,
            id: element.viewTypeId,
            tooltipValue: element.viewDesc,
            viewIcon: element.viewIcon || 'web_asset',
            defaultView: element.defaultView,
            viewValue: element.viewValue,
          };
          this.dropdownConfig.menuItems.push(menu);
        });
        if (
          this.dataStorageService.filterPayload &&
          this.dataStorageService.filterPayload.length === 2
        ) {
          const viewFilter = this.dropdownConfig.menuItems.filter(
            (data) =>
              data.viewValue ===
              this.dataStorageService.filterPayload[1].filterValue
          );
          this.dropdownConfig.iconName = viewFilter[0].viewIcon;
          this.dropdownConfig.defaultTooltip = viewFilter[0].displayName;
        }
      }
      this.showViewFilter = true;
    });
  }
  getfilters() {
    this.filterDropdown.data = [];
    this.filterList = [];
    this.taskManagementService.getFilters().subscribe((data) => {
      if (data && data.result) {
        data.result.forEach((element) => {
          this.filterList = data.result;
          this.filterDropdown.data.push({
            displayName: element.filterName,
            val: element.filterId,
          });
        });
        if (
          this.dataStorageService.filterPayload &&
          this.dataStorageService.filterPayload.length == 2
        ) {
          const defaultFilter = this.filterList.filter(
            (data) =>
              data.filterValue ===
              this.dataStorageService.filterPayload[0].filterValue
          );
          this.filterDropdown.defaultSelectedView = {
            displayName: defaultFilter[0].filterName,
            val: defaultFilter[0].filterId,
            selected: true,
          };
        } else {
          (this.filterDropdown.defaultSelectedView = {
            displayName: 'Assigned to me',
            val: 3,
            selected: true,
          }),
            (this.dataStorageService.filterPayload = [
              {
                filterType: 'FILTER',
                filterName: data.result[2].filterName,
                filterValue: data.result[2].filterValue,
              },
              {
                filterType: 'VIEW',
                filterName: 'Due Date View',
                filterValue: 'DUE_DATE_VIEW',
              },
            ]);
        }
        this.showtaskTypeFilter = true;
        this.getTaskList(this.dataStorageService.filterPayload);
      }
      this.showtaskTypeFilter = true;
    });
  }
  getTaskList(input) {
    this.tasksList = [];
    this.nodata = false;
    if (input) {
      if (input[1].filterValue === 'DUE_DATE_VIEW') {
        this.viewType = 'DUE_DATE_VIEW';
      }
      if (input[1].filterValue === 'STATUS_VIEW') {
        this.viewType = 'STATUS_VIEW';
      }
      if (input[1].filterValue === 'ASSIGNEE_VIEW') {
        this.viewType = 'ASSIGNEE_VIEW';
      }
    }
    this.taskManagementService.getTaskList(input).subscribe((data) => {
      if (data && data.result) {
        this.tasksList = [];
        for (const key in data.result) {
          data.result[key].forEach((element) => {
            if (
              element.taskIdPrefix &&
              String(element.taskIdPrefix).trim() !== ''
            ) {
              element.topicPrefix =
                element.taskIdPrefix +
                '-' +
                ('0000' + element.taskId).slice(-5);
            } else {
              element.topicPrefix =
                TaskPrefix.DEFAULT + '-' + ('0000' + element.taskId).slice(-5);
            }
          });
          this.tasksList.push({
            key: key,
            value: data.result[key],
          });
        }
        if (this.tasksList.length === 0) {
          this.nodata = true;
        }
      }
    });
  }
  openAddNewTaskPopup() {
    this.invalidTopic = false;
    this.editPopupOpen = false;
    this.addPopupOpen = true;
    this.editedTaskPayload = null;
    this.contextList = [];
    this.showLocation = true;
    this.selectedDueDate = null;
    this.taskIdPrefix = TaskPrefix.DEFAULT;
    this.form.reset();
    let tomorrow = moment().add(1, 'days');
    this.form.get('selectedDate').patchValue(tomorrow);
    this.locationIconType = LdCustomTagTypes.LOCATION_DEFAULT;
    this.locationUrl = this.router.url;
    if (this.breadCrumbService.buildBreadCrumb(this.activatedRoute.root)) {
      const taskData = this.breadCrumbService.buildBreadCrumb(
        this.activatedRoute.root
      );
      this.locationDisplayName = taskData[taskData.length - 1].label;
    } else {
      this.locationDisplayName = this.breadCrumbService.breadCrumbValue;
    }
    if (!this.dataStorageService.showTaskPane) {
      if (
        this.dataStorageService.addTaskConfig.topic &&
        String(this.dataStorageService.addTaskConfig.topic).trim() !== ''
      ) {
        this.form
          .get('topicName')
          .patchValue(this.dataStorageService.addTaskConfig.topic);
      }
      if (
        this.dataStorageService.addTaskConfig.description &&
        String(this.dataStorageService.addTaskConfig.description).trim() !== ''
      ) {
        this.form
          .get('topicDescription')
          .patchValue(this.dataStorageService.addTaskConfig.description);
      }
      if (
        this.dataStorageService.addTaskConfig.topicPrefix &&
        String(this.dataStorageService.addTaskConfig.topicPrefix).trim() !== ''
      ) {
        this.taskIdPrefix = this.dataStorageService.addTaskConfig.topicPrefix;
      }
      this.contextList = this.dataStorageService.addTaskConfig.contextDetails();
      let contextTag = [];
      this.contextList.forEach((ele) => {
        ele.id = uuid.v4();
        contextTag.push({
          type: ele.iconType,
          displayName: ele.displayName,
          otherInformation: [
            {
              url: ele.url,
              value: ele.value,
              options: ele.options || {},
            },
          ],
        });
      });
      this.taskContextConfiguration.tags = contextTag;
      if (
        this.dataStorageService.addTaskConfig.location &&
        String(this.dataStorageService.addTaskConfig.location.url).trim() !==
          '' &&
        String(
          this.dataStorageService.addTaskConfig.location.displayName
        ).trim() !== ''
      ) {
        this.locationUrl = this.dataStorageService.addTaskConfig.location.url;
        this.locationDisplayName =
          this.dataStorageService.addTaskConfig.location.displayName;
      }
      if (
        this.dataStorageService.addTaskConfig.location &&
        String(
          this.dataStorageService.addTaskConfig.location.iconType
        ).trim() !== ''
      ) {
        this.locationIconType =
          this.dataStorageService.addTaskConfig.location.icon;
      }
    }
    let tags = [
      {
        type: this.locationIconType,
        displayName: this.locationDisplayName,
        otherInformation: [
          {
            url: this.locationUrl,
          },
        ],
      },
    ];
    this.taskLocationConfiguration.tags = tags;
    const data = this.baseService.getUserInformation();
    this.selectedUsers.forEach((x) => (x.selected = false));
    const selectedUsers = this.userDropdown.data.filter(
      (e) => e.val === data.userId
    );
    this.resetDropdown = Math.random();
    if (selectedUsers.length > 0) {
      selectedUsers[0].selected = true;
      this.selectedUsers = [selectedUsers[0]];
      if (String(data.fullName).trim() == '') {
        this.userDropdown.defaultSelectedView = {
          displayName: data.email,
          val: data.userId,
        };
      } else {
        this.userDropdown.defaultSelectedView = {
          displayName: data.fullName,
          val: data.userId,
        };
      }
      this.selectedUserEmail = data.email;
      this.selectedUserFullname = data.fullName;
      this.selectedUserUmsId = data.userId;
    } else {
      this.userDropdown.defaultSelectedView = {
        displayName: 'Select',
        val: 'select',
      };
      this.selectedUserEmail = null;
      this.selectedUserFullname = null;
      this.selectedUserUmsId = null;
    }
    this.statusDropdown.defaultSelectedView = {
      displayName: 'Open',
      val: 1,
      selected: true,
    };
    this.selectedStatus = 1;
    this.addNewTaskPopup.title = 'Add Task';
    this.userDropdown.type = 'multiselectvirtualscroll';
    this.userDropdown.isSelectAll = false;
    (this.addNewTaskPopup.buttons = [
      {
        title: DialogButtonName.CANCEL,
        id: 'cancel',
        callback: (): boolean => {
          if (!this.dataStorageService.showTaskPane) {
            this.showBox = false;
            this.dataStorageService.setAddTask(false);
          }
          this.dialogBoxService.close(this.addNewTaskPopup.id);
          return true;
        },
      },
      {
        title: DialogButtonName.POST,
        id: 'save',
        primary: true,
        callback: (): boolean => {
          return true;
        },
      },
    ]),
      (this.showBox = true);
    this.ngAfterViewInit();

    this.openDialog(this.addNewTaskPopup.id);
    setTimeout(() => {
      this.openDialog(this.addNewTaskPopup.id);
    }, 10);
  }
  onClickedTag(event): void {
    if (
      this.editPopupOpen &&
      event.otherInformation &&
      event.otherInformation[0].url &&
      String(event.otherInformation[0].url).trim().length > 0
    ) {
      this.dataStorageService.taskActionInput = null;
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
      this.router.onSameUrlNavigation = 'reload';
      const url =
        OLD_URL_MAP[event.otherInformation[0].url.split('?')[0]] ||
        event.otherInformation[0].url.split('?')[0];
      this.router
        .navigate([url], {
          queryParams: event.otherInformation[0].url.split('?')[1]
            ? this.queryStringToJSON(
                event.otherInformation[0].url.split('?')[1]
              )
            : null,
          state: { clickedItem: event, data: this.editedTaskPayload },
        })
        .then(() => {
          this.router.onSameUrlNavigation = 'ignore';
        });
    }
  }
  queryStringToJSON(queryString) {
    if (queryString.indexOf('?') > -1) {
      queryString = queryString.split('?')[1];
    }
    const pairs = queryString.split('&');
    const result = {};
    pairs.forEach((pair) => {
      pair = pair.split('=');
      result[pair[0]] = decodeURIComponent(pair[1] || '');
    });
    return result;
  }
  onContextClicked(event): void {
    if (
      this.editPopupOpen &&
      event.otherInformation &&
      event.otherInformation[0].url &&
      String(event.otherInformation[0].url).trim().length > 0
    ) {
      this.dataStorageService.taskActionInput = null;
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
      this.router.onSameUrlNavigation = 'reload';
      const url =
        OLD_URL_MAP[event.otherInformation[0].url.split('?')[0]] ||
        event.otherInformation[0].url.split('?')[0];

      this.router
        .navigate([url], {
          queryParams: event.otherInformation[0].url.split('?')[1]
            ? this.queryStringToJSON(
                event.otherInformation[0].url.split('?')[1]
              )
            : null,
          state: { clickedItem: event, data: this.editedTaskPayload },
        })
        .then(() => {
          this.router.onSameUrlNavigation = 'ignore';
        });
    }
  }
  onItemClick(item) {
    this.editedTaskPayload = item;
    this.invalidTopic = false;
    this.editPopupOpen = true;
    this.addPopupOpen = false;
    this.currentuser = false;
    this.showLocation = true;
    this.locationDisplayName = null;
    this.locationUrl = null;
    this.locationIconType = LdCustomTagTypes.LOCATION_DEFAULT;
    if (item.taskIdPrefix && String(item.taskIdPrefix).trim() !== '') {
      this.taskIdPrefix = item.taskIdPrefix;
    } else {
      this.taskIdPrefix = TaskPrefix.DEFAULT;
    }
    const date = this.getdate(item);
    this.contextList = [];
    this.selectedDueDate = item.dueDate;
    this.form.setValue({
      topicName: item.topic,
      topicDescription: item.description,
      selectedDate: date,
    });
    if (item.hasOwnProperty('contextDetails')) {
      this.contextList = item.contextDetails;
      let contextTag = [];
      this.contextList.forEach((ele) => {
        contextTag.push({
          type: ele.iconType,
          displayName: ele.displayName,
          otherInformation: [
            {
              url: ele.url,
              value: ele.value,
              options: ele.options || {},
            },
          ],
        });
      });
      this.taskContextConfiguration.tags = contextTag;
    }
    if (
      item.hasOwnProperty('locationDetails') &&
      item.locationDetails &&
      item.locationDetails.displayName
    ) {
      this.locationIconType = item.locationDetails.iconType;
      this.locationDisplayName = item.locationDetails.displayName;
      this.locationUrl = item.locationDetails.url;
    } else {
      this.showLocation = false;
    }
    let tags = [
      {
        type: this.locationIconType,
        displayName: this.locationDisplayName,
        otherInformation: [
          {
            url: this.locationUrl,
          },
        ],
      },
    ];
    this.taskLocationConfiguration.tags = tags;
    if (String(item.toUser.fullName).trim() == '') {
      this.userDropdown.defaultSelectedView = {
        displayName: item.toUser.userEmail,
        val: item.toUser.userId,
      };
    } else {
      this.userDropdown.defaultSelectedView = {
        displayName: item.toUser.fullName,
        val: item.toUser.userId,
      };
    }
    this.fromUserEmail = item.fromUser.userEmail;
    this.fromUsername = item.fromUser.fullName;
    this.fromuserUms = item.fromUser.userId;
    this.updateTaskId = item.taskId;
    this.selectedUserEmail = item.toUser.userEmail;
    this.selectedUserFullname = item.toUser.fullName;
    this.selectedUserUmsId = item.toUser.userId;
    // this.form.value['selectedDate'] = item.dueDate;
    const data = this.taskStatusList.filter(
      (e) => e.statusId === item.statusId
    );
    if (data && data.length > 0) {
      this.statusDropdown.defaultSelectedView = {
        displayName: data[0].statusName,
        val: data[0].statusId,
        selected: true,
      };
      this.selectedStatus = item.statusId;
    }
    if (item.topicPrefix == null || item.topicPrefix == undefined) {
      if (item.taskIdPrefix && String(item.taskIdPrefix).trim() !== '') {
        item.topicPrefix =
          item.taskIdPrefix + '-' + ('0000' + item.taskId).slice(-5);
      }
    }
    this.userDropdown.type = 'static';
    this.addNewTaskPopup.title = 'Edit Task: ' + item.topicPrefix;
    const userInfo = this.baseService.getUserInformation();
    if (item.fromUser.userId === userInfo.userId) {
      this.currentuser = true;
      this.addNewTaskPopup.buttons = [
        {
          title: DialogButtonName.DELETE,
          id: 'delete',
        },
        {
          title: DialogButtonName.CANCEL,
          id: 'cancel',
          callback: (): boolean => {
            this.dialogBoxService.close(this.addNewTaskPopup.id);
            return true;
          },
        },
        {
          title: DialogButtonName.UPDATE,
          id: 'save',
          primary: true,
          callback: (): boolean => {
            return true;
          },
        },
      ];
    } else {
      this.addNewTaskPopup.buttons = [
        {
          title: DialogButtonName.CANCEL,
          id: 'cancel',
          callback: (): boolean => {
            this.dialogBoxService.close(this.addNewTaskPopup.id);
            return true;
          },
        },
        {
          title: DialogButtonName.UPDATE,
          id: 'save',
          primary: true,
          callback: (): boolean => {
            return true;
          },
        },
      ];
    }

    this.showBox = true;
    this.openDialog(this.addNewTaskPopup.id);
  }
  getdate(item) {
    let date = String(item.dueDate).split(' ')[0];
    let time = String(item.dueDate).split(' ')[1];
    const year = String(date).split('-')[0];
    const month = String(date).split('-')[1];
    const day = String(date).split('-')[2];
    const hour = String(time).split(':')[0];
    const mins = String(time).split(':')[1];
    const seconds = String(time).split(':')[2];
    let diffDate = new Date(
      Number(year),
      Number(month) - 1,
      Number(day),
      Number(hour),
      Number(mins),
      Number(seconds)
    );
    return moment(diffDate.getTime());
  }
  getUserList() {
    this.userDropdown.data = [];
    this.usersList = [];
    this.taskManagementService.getUsersList().subscribe((data) => {
      if (data && data.result) {
        this.usersList = data.result;
        data.result.forEach((element) => {
          let item = element.fullName;
          if (String(element.fullName).trim() == '') {
            item = element.userEmail;
          }
          this.userDropdown.data.push({
            displayName: item,
            val: element.userId,
            selected: false,
            data: {
              fullName: element.fullName,
              userId: element.userId,
              userEmail: element.userEmail,
            },
          });
        });
      }
    });
  }
  getStatusList() {
    this.statusDropdown.data = [];
    this.taskStatusList = [];
    this.taskManagementService.getStatusList().subscribe((data) => {
      if (data && data.result) {
        this.taskStatusList = data.result;
        data.result.forEach((element) => {
          this.statusDropdown.data.push({
            displayName: element.statusName,
            val: element.statusId,
          });
        });
      }
    });
  }
  onSearch() {}
  onClearSearch() {
    this.dataStorageService.searchText = '';
  }
  OnFilterSelect(event) {
    if (event.selectedItem.val !== 'select') {
      this.selectedStatus = event.selectedItem.val;
    }
  }
  OndropdownSelect(event) {
    if (event) {
      const data = this.filterList.filter(
        (e) => e.filterId === event.selectedItem.val
      );
      if (
        this.dataStorageService.filterPayload &&
        this.dataStorageService.filterPayload.length === 2
      ) {
        this.dataStorageService.filterPayload[0].filterName =
          data[0].filterName;
        this.dataStorageService.filterPayload[0].filterValue =
          data[0].filterValue;
      } else {
        this.dataStorageService.filterPayload = data[0];
      }
      this.getTaskList(this.dataStorageService.filterPayload);
    }
  }
  onUserDropdownClick(event) {
    this.invalidDropdown = false;
    if (event.selectedItem && event.selectedItem.val !== 'select') {
      let data = this.usersList.filter(
        (e) => e.userId === event.selectedItem.val
      );
      if (data && data.length > 0) {
        this.selectedUserEmail = data[0].userEmail;
        this.selectedUserFullname = data[0].fullName;
        this.selectedUserUmsId = data[0].userId;
      }
    }
    if (event.selectedItems) {
      this.selectedUsers = event.selectedItems;
    }
  }
  openDialog(id: string): void {
    this.dialogBoxService.open(id);
  }
  closeTaskManagement() {
    this.dataStorageService.taskActionInput = null;
  }
  updateTask() {
    const payload = {
      contextDetails: this.contextList,
      deleted: false,
      description: this.form.value['topicDescription'],
      dueDate: this.selectedDueDate,
      fromUser: {
        fullName: this.fromUsername,
        userId: this.fromuserUms,
        userEmail: this.fromUserEmail,
      },
      locationDetails: {
        iconType: this.locationIconType,
        displayName: this.locationDisplayName,
        url: this.locationUrl,
      },
      statusId: this.selectedStatus,
      taskId: this.updateTaskId,
      pdmEnabled:
        this.featureService
          .getfeaturesArray()
          .indexOf('partsdatamanagement') !== -1
          ? true
          : false,
      toUser: {
        lastName: this.baseService.getUserInformation().lastName,
        fullName: this.selectedUserFullname,
        userId: this.selectedUserUmsId,
        userEmail: this.selectedUserEmail,
      },
      taskIdPrefix: this.taskIdPrefix,
      topic: this.form.value['topicName'],
      updatedUser: {
        fullName: this.baseService.getUserInformation().fullName,
        userId: this.baseService.getUserInformation().userId,
        userEmail: this.baseService.getUserInformation().email,
      },
    };
    this.taskManagementService.updateTask(payload).subscribe((data) => {
      if (data && data.result && data.responseStatus.code === 200) {
        this.popToastS('Task Updated Successfully');
        this.getTaskList(this.dataStorageService.filterPayload);
        this.sendEmailNotification(payload, false);
      } else {
        this.popToastF('Something went wrong');
      }
      this.form.reset();
      this.selectedDueDate = null;
    });
  }
  saveTask() {
    let userList = [];
    if (this.addPopupOpen) {
      if (this.userDropdown.isSelectAll) {
        userList = this.userDropdown.data.map((item) => item.data);
      } else {
        userList = this.selectedUsers.map((item) => item.data);
      }
    }
    const payload: any = {
      contextDetails: this.contextList,
      deleted: false,
      description: this.form.value['topicDescription'],
      dueDate: this.selectedDueDate,
      fromUser: {
        fullName: this.baseService.getUserInformation().fullName,
        userId: this.baseService.getUserInformation().userId,
        userEmail: this.baseService.getUserInformation().email,
      },
      locationDetails: {
        iconType: this.locationIconType,
        displayName: this.locationDisplayName,
        url: this.locationUrl,
      },
      statusId: this.selectedStatus,
      taskId: 0,
      toUsers: userList,
      taskIdPrefix: this.taskIdPrefix,
      topic: this.form.value['topicName'],
      pdmEnabled:
        this.featureService
          .getfeaturesArray()
          .indexOf('partsdatamanagement') !== -1
          ? true
          : false,
      updatedUser: {
        fullName: this.baseService.getUserInformation().fullName,
        userId: this.baseService.getUserInformation().userId,
        userEmail: this.baseService.getUserInformation().email,
      },
    };
    this.taskManagementService.saveTask(payload).subscribe((data) => {
      if (data && data.result && data.responseStatus.code === 200) {
        this.popToastS('Task Created Successfully');
        payload['toUser'] = {
          userId: this.baseService.getUserInformation().userId,
          userEmail: this.baseService.getUserInformation().email,
          lastName: this.baseService.getUserInformation().lastName,
          fullName: this.baseService.getUserInformation().fullName,
        };
        let selectedRows = [];
        if (this.targetPage == '/app/npi/ppbommanagement/bomrevision/viewbom') {
          (this.dataStorageService.getSelectedRows() || []).forEach((row) => {
            let partDetails = {
              cpn: row.partNumber || '',
              cpnDescrption: row.description || '',
              mpn: row.cMpn || '',
              quantity: row.quantity || '',
              manufacture: row.cManufacturer || '',
              cmcost: row.cmCost || '',
              customerUnitPerCost: row.customerUnitPerCost || '',
              actionItems: row.actionItems || '',
              taskId: Object.values(data.result)[0] || '',
            };
            selectedRows.push(partDetails);
          });
        }
        payload['taskId'] = Object.values(data.result)[0] || '';
        payload['bomDetails'] = selectedRows;
        payload.userToTask = data.result;
        this.getTaskList(this.dataStorageService.filterPayload);
        this.sendEmailNotification(payload, true);
      } else {
        this.popToastF('Something went wrong');
      }
      this.form.reset();
      this.selectedDueDate = null;
    });
  }
  dateChange(event) {
    if (event) {
      // this.selectedDueDate = event._a[0] +'-'+ (event._a[1]+1) +'-' + event._a[2] + ' ' + event._a[3]+':'+ event._a[4] +':'+event._a[5]
      this.selectedDueDate =
        event.format('YYYY') +
        '-' +
        event.format('MM') +
        '-' +
        event.format('DD') +
        ' ' +
        event.format('HH') +
        ':' +
        event.format('mm') +
        ':' +
        event.format('ss');
    }
  }
  sendEmailNotification(payload, created) {
    this.taskManagementService
      .sendEmailNotification(payload, created)
      .subscribe((data) => {
        this.targetPage = '';
        console.log('Email sent');
      });
  }
  deleteTask() {
    const deletePayload = {
      contextDetails: this.contextList,
      deleted: true,
      description: this.form.value['topicDescription'],
      dueDate: this.selectedDueDate,
      fromUser: {
        fullName: this.fromUsername,
        userId: this.fromuserUms,
        userEmail: this.fromUserEmail,
      },
      locationDetails: {
        iconType: this.locationIconType,
        displayName: this.locationDisplayName,
        url: this.locationUrl,
      },
      statusId: this.selectedStatus,
      taskId: this.updateTaskId,
      pdmEnabled:
        this.featureService
          .getfeaturesArray()
          .indexOf('partsdatamanagement') !== -1
          ? true
          : false,
      toUser: {
        fullName: this.selectedUserFullname,
        userId: this.selectedUserUmsId,
        userEmail: this.selectedUserEmail,
        lastName: this.baseService.getUserInformation().lastName,
      },
      taskIdPrefix: this.taskIdPrefix,
      topic: this.form.value['topicName'],
      updatedUser: {
        fullName: this.baseService.getUserInformation().fullName,
        userId: this.baseService.getUserInformation().userId,
        userEmail: this.baseService.getUserInformation().email,
      },
    };
    const payload = {
      taskId: this.updateTaskId,
    };
    this.taskManagementService.deleteTask(payload).subscribe((data) => {
      if (data && data.result && data.responseStatus.code === 200) {
        this.popToastS('Task deleted Successfully');
        this.sendEmailNotification(deletePayload, false);
        this.getTaskList(this.dataStorageService.filterPayload);
      } else {
        this.popToastF('Something went wrong');
      }
      this.form.reset();
      this.selectedDueDate = null;
    });
  }
  popToastS(data) {
    const toast: Toast = {
      type: 'success',
      showCloseButton: true,
      body: '<div class="toast-msg success-msg">' + data + '</div>',
      bodyOutputType: BodyOutputType.TrustedHtml,
    };
    this._toasterService.pop(toast);
  }

  popToastW(message) {
    const toast: Toast = {
      type: 'warning',
      showCloseButton: true,
      body: `<div class="toast-msg warning-msg">` + message + `</div>`,
      bodyOutputType: BodyOutputType.TrustedHtml,
    };
    this._toasterService.pop(toast);
  }

  popToastF(message) {
    const toast: Toast = {
      type: 'error',
      showCloseButton: true,
      body:
        '<div vertical-align=middle class="toast-msg error-msg">' +
        message +
        '</div>',
      bodyOutputType: BodyOutputType.TrustedHtml,
    };
    this._toasterService.pop(toast);
  }
  onBtnClick(target): void {
    this.showBox = false;
    this.dataStorageService.setAddTask(false);
    if (this.editPopupOpen) {
      if (this.currentuser) {
        if (
          target.button &&
          target.button.id === this.addNewTaskPopup.buttons[2].id
        ) {
          this.updateTask();
        }
      } else {
        if (
          target.button &&
          target.button.id === this.addNewTaskPopup.buttons[1].id
        ) {
          this.updateTask();
        }
      }
      if (this.dialogBoxService.selectedButton.id === 'yes') {
        this.deleteTask();
      }
    } else {
      if (
        target.button &&
        target.button.id === this.addNewTaskPopup.buttons[1].id
      ) {
        this.saveTask();
      }
    }
  }
}
