import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { ApiServiceProvider } from 'app/api-service';


@Injectable()
export class RisksService extends ApiServiceProvider {
rfqRisks: any;
rfqRisksData=[
    {
        'Mechanical RFQ':
        [
            {
                riskMargin: 'External Market conditions',
                riskDescription: 'The industry is going through capacity constraints, due to significant increase in demand and resulting in 10-15% price  premium.'
            },
            {
                riskMargin: 'Savings performance',
                riskDescription: 'In last cost savings negotiation cycle, significant cost savings have been offered via very aggressive forward pricing.'
            },
            {
                riskMargin: 'New Business',
                riskDescription: 'No new products awarded in last year'
            }
        ]
    },
    {
        'Transducer RFQ Q1 2018':
        [
           {
                riskMargin: 'Savings performance',
                riskDescription: 'The overall profit margin in doing business with our company is declining and hence the savings target cannot be met'
            },
            {
                riskMargin: 'External Market conditions',
                riskDescription: 'The raw material and labor cost has increased significantly in the Manufacturer location. This is forcing the supplier to pass through the excess cost and consequently there is no room for cost-reduction but actually cost increase. The supplier is absorbing the cost increase already and therefore the savings target is unachievable'
            }

        ]
    },
    {
        'Capacitors RFQ':
        [
           {
                riskMargin: 'Savings performance',
                riskDescription: 'The overall profit margin in doing business with our company is declining and hence the savings target cannot be met'
            },
            {
                riskMargin: 'External Market conditions',
                riskDescription: 'The raw material and labor cost has increased significantly in the Manufacturer location. This is forcing the supplier to pass through the excess cost and consequently there is no room for cost-reduction but actually cost increase. The supplier is absorbing the cost increase already and therefore the savings target is unachievable'
            }

        ]
    }

];
public getRiskData(rfName: string){

    for(let i=0; i<  this.rfqRisksData.length; i++){
        if(this.rfqRisksData[i][rfName] !== undefined){

             this.rfqRisks = this.rfqRisksData[i][rfName];
             continue;
        }

     }

    return this.rfqRisks;
}

}
