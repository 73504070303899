export class DataFilter {
  templateId: number;
  startQuoteIndex: number;
  rfqId: number;
  for: string;
  timeGranularity: string;
  rollingPeriod: string;
  startPeriod: number;
  endPeriod: number;
  tmplQuotePeriod: number;
  refreshCache: boolean;
  templateType: string;
  randomNumber: number;
}
export interface ExistingFilter {
  filterId: number;
  filterName: string;
  genericFilter: number;
  quickFilter: number;
}

export interface FilterType {
  headerGrpId: number;
  filterMapId: number;
  filterId: number;
  name: string;
  dbName: string;
  startVal: any;
  endVal: any;
  sequence: any;
  operator: any;
  condition: any;
  columnDataType: string;
  filter: any;
  expression: any;
  globalFilterId: number;
  value: any;
  isDelete: boolean;
}
