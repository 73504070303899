import {Subject} from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { ApiServiceProvider } from 'app/api-service';

@Injectable()
export class IndustryNewsContentService  extends ApiServiceProvider {
}
