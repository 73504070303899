import { Component, OnInit, Input } from '@angular/core';

import { CollaborationIcon } from './shared/collaborationIcon.model';
import { CollaborationIconService } from './shared/collaborationIcon.service';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { COLLABORATION_CONSTS } from './collaboration.constants';

@Component({
  moduleId: module.id,
  selector: 'collaborationIcon',
  templateUrl: 'collaborationIcon.component.html',
  styleUrls: ['./collaborationIcon.component.sass'],
  providers: [CollaborationIconService]
})

export class CollaborationIconComponent implements OnInit {
  collaborationIcon: CollaborationIcon[] = [];
  _input;

  @Input() set input(input) {
    this._input = input;
  }
  get input() {
    return this._input;
  }

  constructor(private collaborationIconService: CollaborationIconService,
    private datastorageService: DataStorageService) { }

  ngOnInit() {

  }
  testClick() {
    this._input[COLLABORATION_CONSTS.FE_ID] = this.generateFeId(this._input);
    this.datastorageService.collaborationInput = this._input
  }
  generateFeId(input) {
    return (input[COLLABORATION_CONSTS.FE_ID].length > 0) ? input[COLLABORATION_CONSTS.FE_ID] : (input[COLLABORATION_CONSTS.context] + '_' + input[COLLABORATION_CONSTS.topic]);
  }
}
