import {
  Component,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnInit
} from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import {
  ICellRendererParams,
  IAfterGuiAttachedParams
} from '@ag-grid-community/core';

import { DataStorageService } from 'app/modules/common/data-storage.service';
import { PostRfxCreationService } from 'app/modules/srfq/detail/detail-view/shared/post.rfx.creation.service';
import { HEADER_OBJECT } from 'app/modules/srfq/create/adhoc-rfx/shared/adhoc-rfx.constants';
import { STATUS_OBJECT } from 'app/modules/srfq/detail/detail-view/shared/detail.constants';

@Component({
  selector: 'child-cell',
  template: `
    <input
      *ngIf="showCheckbox"
      type="checkbox"
      data-md-icheck
      class="form-control border-checkbox"
      [checked]="isChecked"
      (change)="onChange($event)"
    />
    <span *ngIf="!showCheckbox"></span>
  `
})
export class CheckboxCellComponent
  implements OnInit, AfterViewInit, ICellRendererAngularComp {

  constructor(private dataStorageService: DataStorageService,private postRfxCreationService:PostRfxCreationService) {}
  @ViewChild('.checkbox', { static: false }) checkbox: ElementRef;

  isChecked = false;

  public params: ICellRendererParams;

  showCheckbox = true;
  refresh(params: any): boolean {
    this.params = params;
    // this.isChecked = Boolean(this.params.value);
    return;
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    throw new Error('Method not implemented.');
  }

  agInit(params: ICellRendererParams): void {
    this.showCheckbox = !this.checkComponentDisability(params);
    this.params = params;
    this.isChecked = Boolean(this.params.value);
  }

  ngOnInit() {}

  public onChange(event) {
    /* let obj ;
        obj['field'] = this.params.colDef.field;
        obj['oldValue'] = this.params.data[this.params.colDef.field];
        this.params.data[this.params.colDef.field] = Number(event.currentTarget.checked);

        obj['modifiedRow'] = this.params.data;
        this.dataStorageService.setEditedFieldsByRow(obj);

        if (!event.currentTarget.checked) {
            this.dataStorageService.setDeselectedIds(obj);
        } */

    const obj = {};
    obj['field'] = this.params.colDef.field;
    obj['oldValue'] = this.params.data[this.params.colDef.field];
    obj['newValue'] = Number(event.currentTarget.checked);

    this.params.data[obj['field']] = Number(event.currentTarget.checked);
    obj['modifiedRow'] = this.params.data;

    console.log(obj);
    this.dataStorageService.setEditedFieldsByRow(obj);

    if (!event.currentTarget.checked) {
      this.dataStorageService.setDeselectedIds(obj);
    }
  }

  ngAfterViewInit() {}

  /**
   * Post RFX Creation Changes
   * Start
   */

   checkComponentDisability(params:ICellRendererParams){
     if(this.postRfxCreationService.isPostRFXCreation() && params.data instanceof Object && typeof params.data['STATUS_ID'] === 'number'){
       if(params.data.STATUS_ID !== STATUS_OBJECT.DRAFT){
         return true;
       }
     }
     return false;
   }

  /**
   * Post RFX Creation Changes
   * End
   */

}
