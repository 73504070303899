import { Injectable } from '@angular/core'
import { ApiServiceProvider } from 'app/api-service'
import { Observable } from 'rxjs'
import * as _ from 'lodash'
import { GraphData } from '../leva-summary/leva-summary.interface'

@Injectable()
export class SummaryExpandViewService extends ApiServiceProvider {
  graphData: GraphData[] = []
  module: string
  currentPage = null
  slideConfig: any
  summary: any = []
  setGraphData(graphData: GraphData[]) {
    this.graphData = graphData
  }
  getBannerMetaData(moduleName) {
    const requestPayload: any = {
      targetedType: 'CustomerConfigService',
      method: 'POST',
      generic: false,
      formParams: {
        moduleName,
      },
      servicePath: `/scoreboard/metainfo`,
    }
    return this.post(requestPayload)
  }

  getBanner() {
    const payload: any = {
      targetedType: 'platform',
      method: 'POST',
      servicePath: `insightsSummary`,
      payload: 'Payload',
    }
    return this.post(payload)
  }

  getBannerValue(data) {
    const requestPayload: any = {
      targetedType: this.getTargetedType(),
      method: 'GET',
      generic: false,
      formParams: {
        metricKey: data.metricKey,
        formula: data.eachItemConfiguration.formula,
      },
      servicePath: data['eachItemConfiguration']['endPoint'],
    }
    return this.post(requestPayload)
  }

  getBannerData(moduleName) {
    this.graphData = []
    this.summary = []
    return new Observable((observer) => {
      this.getBannerMetaData(moduleName).subscribe((data) => {
        if (data && data.result) {
          data.result.metrics.forEach((element) => {
            this.summary.push({
              displayName: element.displayName,
              value: null,
              metricKey: element.metricKey,
              eachItemConfiguration: {
                // currencyFormat: JSON.parse(element.metaData).format,
                formula: element.formula,
                endPoint: element.endPoint,
                order: element.order + '',
                showType: JSON.parse(element.metaData).format,
              },
            })
            this.summary.sort((a, b) =>
              a.eachItemConfiguration.order < b.eachItemConfiguration.order
                ? -1
                : a.eachItemConfiguration.order > b.eachItemConfiguration.order
                  ? 1
                  : 0
            )
          })
          this.summary.forEach((element) => {
            this.getBannerValue(element).subscribe((bannerData) => {
              if (bannerData && bannerData.result) {
                element.value = bannerData.result.metricValue
              }
            })
          })

          const itemsArray = []
          const itemKeys = []
          const colorMapObj = {
            colorMap: {
              'NEW': '#B71B17',
              'IN REVIEW': '#CD6428',
              'IN MITIGATION': '#E5AC3A',
              'MONITOR': '#B0A538',
              'RESOLVED': '#009336',
              'DISMISSED': '#BFBFBF'
            }
          }
          data.result.visualCards.forEach((element) => {
            Object.keys(element.dataItems).forEach((ele) => {
              itemKeys.push(ele)
              itemsArray.push({
                name: element.dataItems[ele].toString(),
                y: 0,
                key: ele.toString(),
              })
            })
            const metaData = JSON.parse(element.metaData)
            this.graphData.push({
              targetedType: this.getTargetedType(),
              header: element.displayName,
              endPoint: element.endPoint,
              order: element.order + '',
              metaData,
              visualCardId: element.visualCardId,
              visualCardKey: element.visualCardKey,
              itemKeys,
              items: itemsArray,
              metaInfo: this.currentPage === 'EVENTS' ? colorMapObj : null
            })
            this.graphData.sort((a, b) =>
              a.order < b.order ? -1 : a.order > b.order ? 1 : 0
            )
          })
        }
        observer.next(data)
        observer.complete()
      })
    })
  }

  getTargetedType() {
    return this.module === 'SRN'
      ? 'riskManagementService'
      : 'PartWorkbenchService'
  }

  reset() {
    this.module = ''
    this.graphData = []
    this.summary = []
  }
}
