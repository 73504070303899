import { Component, OnInit } from '@angular/core';
import { NpiAdminRiskService } from '../../npi-admin/npi-admin-risk/shared/npi-admin-risk.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { riskConfigMap } from '../../npi-admin/npi-admin-risk/constants';
import { DATA_TYPES } from '../../npiconstants';
import * as _ from 'lodash';

@Component({
  selector: 'app-multisource-risk',
  templateUrl: './multisource-risk.component.html',
  styleUrls: ['./multisource-risk.component.sass'],
})
export class MultisourceRiskComponent implements OnInit {
  public addUserForm: FormGroup;
  public sortedInfo: any = [];
  public sortedInfoBomLevel: any = [];
  public showData = false;
  public operatorInfo: any = [];
  public displayMessage = 'No Program Configured Yet ';
  public showNotConfiguredMessage = false;
  public Operators = [
    { title: 'Between', value: 'Between', display: 'Between', selected: true },
    { title: 'Equals', value: 'Equal', display: 'Equals', selected: false },
  ];
  public dropdownClick = false;
  public valueFieldValidations = {
    fromValue: new FormControl('', Validators.required),
    toValue: new FormControl('', Validators.required),
  };
  public keysArray: any = [];
  public showInfo = false;
  constructor(public npiAdminRiskService: NpiAdminRiskService) {}
  public ngOnInit() {
    this.showInfo = false;
    if (
      this.npiAdminRiskService.keyInfo &&
      this.npiAdminRiskService.programKeyInfo
    ) {
      if (
        Object.keys(
          this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
            .MULTI_SOURCE_RISK
        ).length === 0
      ) {
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.MULTI_SOURCE_RISK =
          riskConfigMap.MULTI_SOURCE_RISK;
      } else {
        if (
          !this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.hasOwnProperty(
            'MULTI_SOURCE_RISK'
          )
        ) {
          this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap[
            'MULTI_SOURCE_RISK'
          ] = riskConfigMap.MULTI_SOURCE_RISK;
        }
      }
      this.keysArray = Object.keys(
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
          .MULTI_SOURCE_RISK
      );

      const headers = this.npiAdminRiskService.keyInfo.result
        .CONFIGURE_MULTISOURCE_RISK.widgetHeaders;
      const groupedHeaders = _.groupBy(headers, 'headerInfo.sectionGroup');
      groupedHeaders['undefined'].forEach((element) => {
        if (element.headerInfo.displayOrder !== 4) {
          if (element.headerInfo.field.includes('low')) {
            element.headerInfo.map = 'low';
            const lowOperators = JSON.parse(JSON.stringify(this.Operators));
            // lowOperators[1].display = '>=';
            element.headerInfo.operators = lowOperators;
            // element.headerInfo['values'] = ['Between', 'Equal'];
          } else if (element.headerInfo.field.includes('medium')) {
            element.headerInfo.map = 'medium';
            element.headerInfo.operators = JSON.parse(
              JSON.stringify(this.Operators)
            );
            // element.headerInfo['values'] = ['Between', 'Equal'];
          } else if (element.headerInfo.field.includes('high')) {
            element.headerInfo.map = 'high';
            element.headerInfo.operators = JSON.parse(
              JSON.stringify(this.Operators)
            );
            // element.headerInfo['values'] = ['Equal', 'Between'];
          }
          element.headerInfo.showError = false;
          this.sortedInfo.push(element.headerInfo);
        } else {
          this.operatorInfo.push(element.headerInfo);
        }
      });
      groupedHeaders['BOM LEVEL'].forEach((element) => {
        if (element.headerInfo.displayOrder !== 4) {
          if (element.headerInfo.field.includes('low')) {
            element.headerInfo.map = 'low';
          } else if (element.headerInfo.field.includes('medium')) {
            element.headerInfo.map = 'medium';
          } else if (element.headerInfo.field.includes('high')) {
            element.headerInfo.map = 'high';
          }
          element.headerInfo.showError = false;
          this.sortedInfoBomLevel.push(element.headerInfo);
        }
      });
      this.sortedInfo.sort(function (a, b) {
        return a.displayOrder - b.displayOrder;
      });
      this.sortedInfoBomLevel.sort(function (a, b) {
        return a.displayOrder - b.displayOrder;
      });
      this.showData = true;
    } else {
      this.showNotConfiguredMessage = true;
    }
    if (
      this.npiAdminRiskService.bomRiskInfo &&
      this.npiAdminRiskService.bomRiskInfo.result &&
      this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap &&
      this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
        .MULTI_SOURCE_RISK &&
      this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
        .MULTI_SOURCE_RISK.componentLevelRisk
    ) {
      Object.getOwnPropertyNames(
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
          .MULTI_SOURCE_RISK.componentLevelRisk
      ).forEach((element) => {
        if (
          this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
            .MULTI_SOURCE_RISK.componentLevelRisk[element].min ===
          this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
            .MULTI_SOURCE_RISK.componentLevelRisk[element].max
        ) {
          const a = this.sortedInfo.findIndex((i) => i.map === element);
          if (!this.sortedInfo[a].componentLevelRiskShowError) {
            this.sortedInfo[a].operator = 'Equal';
            this.sortedInfo[a].operators[1].selected = true;
            this.sortedInfo[a]['defaultSelected'] = this.sortedInfo[
              a
            ].operators[1];
            this.sortedInfo[a].operators[0].selected = false;
          }
        } else {
          const a = this.sortedInfo.findIndex((i) => i.map === element);
          this.sortedInfo[a].operator = 'Between';
          this.sortedInfo[a].operators[0].selected = true;
          this.sortedInfo[a]['defaultSelected'] = this.sortedInfo[
            a
          ].operators[0];
          this.sortedInfo[a].operators[1].selected = false;
        }
      });
    }
    this.showInfo = true;
  }

  public componentLevelRiskUpdated(event, j, label) {
    this.sortedInfo[j]['minValueError'] = false;
    this.npiAdminRiskService.enableReset = this.npiAdminRiskService.bomRiskUpdated = true;
    this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.MULTI_SOURCE_RISK.componentLevelRisk[
      label
    ].min = +event.target.value;
    if (
      this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
        .MULTI_SOURCE_RISK.componentLevelRisk[label].max <= event.target.value
    ) {
      this.sortedInfo[j].componentLevelRiskShowError = true;
      if (!this.npiAdminRiskService.errors['componentLevelRisk'].includes(j)) {
        this.npiAdminRiskService.errors['componentLevelRisk'].push(j);
      }
    } else if (event.target.value < 1) {
      this.sortedInfo[j]['minValueError'] = true;
      if (!this.npiAdminRiskService.errors['componentLevelRisk'].includes(j)) {
        this.npiAdminRiskService.errors['componentLevelRisk'].push(j);
      }
    } else {
      this.sortedInfo[j]['minValueError'] = false;
      this.sortedInfo[j].componentLevelRiskShowError = false;
      if (this.npiAdminRiskService.errors['componentLevelRisk'].includes(j)) {
        const presenIndex = this.npiAdminRiskService.errors[
          'componentLevelRisk'
        ].findIndex((item) => item === j);
        this.npiAdminRiskService.errors['componentLevelRisk'].splice(
          presenIndex,
          1
        );
      }
    }
    this.npiAdminRiskService.saveButtonStatus();
  }

  public componentLevelRiskToUpdated(event, j, label, operator) {
    if (j === 0) {
      this.sortedInfo[j]['minValueError'] = false;
    }
    this.npiAdminRiskService.enableReset = this.npiAdminRiskService.bomRiskUpdated = true;
    let nextUpdatedIndex;
    if (operator === 'Equal') {
      this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.MULTI_SOURCE_RISK.componentLevelRisk[
        label
      ].min = +event.target.value;
      this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.MULTI_SOURCE_RISK.componentLevelRisk[
        label
      ].max = +event.target.value;
    }
    if (
      j !==
      Object.keys(
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
          .MULTI_SOURCE_RISK.componentLevelRisk
      ).length -
        1
    ) {
      nextUpdatedIndex = this.sortedInfo[j + 1].map;
      if (
        typeof nextUpdatedIndex === DATA_TYPES.STRING &&
        nextUpdatedIndex.length > 0
      ) {
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.MULTI_SOURCE_RISK.componentLevelRisk[
          nextUpdatedIndex
        ].min = +event.target.value + 1;
        this.validateDataForEqual(j, 'componentLevelRisk');
        if (
          this.sortedInfo[j + 1].operator === 'Equal' &&
          !this.sortedInfo[j].componentLevelRiskShowError
        ) {
          this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.MULTI_SOURCE_RISK.bomLevelRisk[
            nextUpdatedIndex
          ].max = +event.target.value + 1;
          this.componentLevelRiskToUpdated(
            { target: { value: +event.target.value + 1 } },
            j + 1,
            this.sortedInfo[j + 1].map,
            this.sortedInfo[j + 1].operator
          );
        }
      }
    }
    this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.MULTI_SOURCE_RISK.componentLevelRisk[
      label
    ].max = +event.target.value;
    if (operator !== 'Equal') {
      if (
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
          .MULTI_SOURCE_RISK.componentLevelRisk[label].min >=
        +event.target.value
      ) {
        this.sortedInfo[j].componentLevelRiskShowError = true;
        if (
          !this.npiAdminRiskService.errors['componentLevelRisk'].includes(j)
        ) {
          this.npiAdminRiskService.errors['componentLevelRisk'].push(j);
        }
      } else {
        this.sortedInfo[j].componentLevelRiskShowError = false;
        if (this.npiAdminRiskService.errors['componentLevelRisk'].includes(j)) {
          const presenIndex = this.npiAdminRiskService.errors[
            'componentLevelRisk'
          ].findIndex((item) => item === j);
          this.npiAdminRiskService.errors['componentLevelRisk'].splice(
            presenIndex,
            1
          );
        }
      }
    }
    if (
      typeof nextUpdatedIndex === DATA_TYPES.STRING &&
      nextUpdatedIndex.length > 0
    ) {
      if (this.sortedInfo[j + 1].operator !== 'Equal') {
        if (
          this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
            .MULTI_SOURCE_RISK.componentLevelRisk[nextUpdatedIndex].min >=
          this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
            .MULTI_SOURCE_RISK.componentLevelRisk[nextUpdatedIndex].max
        ) {
          this.sortedInfo[j + 1].componentLevelRiskShowError = true;
          if (
            !this.npiAdminRiskService.errors['componentLevelRisk'].includes(
              j + 1
            )
          ) {
            this.npiAdminRiskService.errors['componentLevelRisk'].push(j + 1);
          }
        } else {
          this.sortedInfo[j + 1].componentLevelRiskShowError = false;
          if (
            this.npiAdminRiskService.errors['componentLevelRisk'].includes(
              j + 1
            )
          ) {
            const nextIndex = this.npiAdminRiskService.errors[
              'componentLevelRisk'
            ].findIndex((item) => item === j + 1);
            if (nextIndex > -1) {
              this.npiAdminRiskService.errors['componentLevelRisk'].splice(
                nextIndex,
                1
              );
            }
          }
        }
      }
    }

    this.npiAdminRiskService.saveButtonStatus();
  }

  public bomLevelRiskUpdated(event, label, j) {
    this.sortedInfoBomLevel[j]['minValueError'] = false;
    this.npiAdminRiskService.enableReset = this.npiAdminRiskService.bomRiskUpdated = true;
    this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.MULTI_SOURCE_RISK.bomLevelRisk[
      label
    ].min = +event.target.value;
    if (
      this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
        .MULTI_SOURCE_RISK.bomLevelRisk[label].max <= event.target.value
    ) {
      this.sortedInfoBomLevel[j].showError = true;
      if (!this.npiAdminRiskService.errors['bomLevelRisk'].includes(j)) {
        this.npiAdminRiskService.errors['bomLevelRisk'].push(j);
      }
    } else if (event.target.value < 1) {
      this.sortedInfoBomLevel[j]['minValueError'] = true;
      if (!this.npiAdminRiskService.errors['bomLevelRisk'].includes(j)) {
        this.npiAdminRiskService.errors['bomLevelRisk'].push(j);
      }
    } else {
      this.sortedInfoBomLevel[j]['minValueError'] = false;
      this.sortedInfoBomLevel[j].showError = false;
      if (this.npiAdminRiskService.errors['bomLevelRisk'].includes(j)) {
        const presenIndex = this.npiAdminRiskService.errors[
          'bomLevelRisk'
        ].findIndex((item) => item === j);
        this.npiAdminRiskService.errors['bomLevelRisk'].splice(presenIndex, 1);
      }
    }
    this.npiAdminRiskService.saveButtonStatus();
  }
  public dropdownClicked() {
    this.dropdownClick = true;
  }
  public operatorChange($event, label, j, risk) {
    if (this.npiAdminRiskService.bomRiskUpdated || this.dropdownClick) {
      label['operator'] = $event.value;
      if (label['operator'] === 'Between') {
        if (j !== 0) {
          this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.MULTI_SOURCE_RISK.componentLevelRisk[
            label.map
          ].min =
            +this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
              .MULTI_SOURCE_RISK.componentLevelRisk[this.sortedInfo[j - 1].map]
              .max + 1;
          if (
            this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
              .MULTI_SOURCE_RISK.componentLevelRisk[label.map].min >=
            this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
              .MULTI_SOURCE_RISK.componentLevelRisk[label.map].max
          ) {
            if (risk === 'componentLevelRisk') {
              this.sortedInfo[j].componentLevelRiskShowError = true;
            }
            if (!this.npiAdminRiskService.errors[risk].includes(j)) {
              this.npiAdminRiskService.errors[risk].push(j);
            }
          } else {
            this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.MULTI_SOURCE_RISK.componentLevelRisk[
              label.map
            ].min =
              this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
                .MULTI_SOURCE_RISK.componentLevelRisk[label.map].max - 1;
          }
        } else {
          this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.MULTI_SOURCE_RISK.componentLevelRisk[
            label.map
          ].min = +this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
            .MULTI_SOURCE_RISK.componentLevelRisk[label.map].max;
          this.validateDataForEqual(j, risk);
        }
      }
    }
  }

  public validateDataForEqual(j, risk) {
    if (j !== 0) {
      if (
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
          .MULTI_SOURCE_RISK.componentLevelRisk[this.sortedInfo[j].map].max <=
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
          .MULTI_SOURCE_RISK.componentLevelRisk[this.sortedInfo[j - 1].map].max
      ) {
        if (risk === 'componentLevelRisk') {
          this.sortedInfo[j].componentLevelRiskShowError = true;
        } else {
          this.sortedInfo[j].showError = true;
        }
        if (!this.npiAdminRiskService.errors[risk].includes(j)) {
          this.npiAdminRiskService.errors[risk].push(j);
        }
      } else {
        if (risk === 'componentLevelRisk') {
          this.sortedInfo[j].componentLevelRiskShowError = false;
        } else {
          this.sortedInfo[j].showError = false;
        }
        if (this.npiAdminRiskService.errors[risk].includes(j)) {
          const presenIndex = this.npiAdminRiskService.errors[risk].findIndex(
            (item) => item === j
          );
          this.npiAdminRiskService.errors[risk].splice(presenIndex, 1);
        }
      }
    } else {
      if (this.sortedInfo[j].operator === 'Equal') {
        if (
          this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
            .MULTI_SOURCE_RISK.componentLevelRisk[this.sortedInfo[j].map].max <
          1
        ) {
          if (risk === 'componentLevelRisk') {
            this.sortedInfo[j]['minValueError'] = true;
            if (!this.npiAdminRiskService.errors[risk].includes(j)) {
              this.npiAdminRiskService.errors[risk].push(j);
            }
          }
        } else {
          if (risk === 'componentLevelRisk') {
            this.sortedInfo[j]['minValueError'] = false;
          }
          if (this.npiAdminRiskService.errors[risk].includes(j)) {
            const presenIndex = this.npiAdminRiskService.errors[risk].findIndex(
              (item) => item === j
            );
            this.npiAdminRiskService.errors[risk].splice(presenIndex, 1);
          }
        }
      }
    }
  }

  public bomLevelRiskToUpdated(event, label, j) {
    let nextUpdatedIndex;
    this.npiAdminRiskService.enableReset = this.npiAdminRiskService.bomRiskUpdated = true;
    if (
      j !==
      Object.keys(
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
          .MULTI_SOURCE_RISK.bomLevelRisk
      ).length -
        1
    ) {
      nextUpdatedIndex = this.sortedInfoBomLevel[j + 1].map;
      if (
        typeof nextUpdatedIndex === DATA_TYPES.STRING &&
        nextUpdatedIndex.length > 0
      ) {
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.MULTI_SOURCE_RISK.bomLevelRisk[
          nextUpdatedIndex
        ].min = +event.target.value + 1;
      }
    }
    this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.MULTI_SOURCE_RISK.bomLevelRisk[
      label
    ].max = +event.target.value;
    if (
      this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
        .MULTI_SOURCE_RISK.bomLevelRisk[label].min >= +event.target.value
    ) {
      this.sortedInfoBomLevel[j].showError = true;
      if (!this.npiAdminRiskService.errors['bomLevelRisk'].includes(j)) {
        this.npiAdminRiskService.errors['bomLevelRisk'].push(j);
      }
    } else {
      this.sortedInfoBomLevel[j].showError = false;
      if (this.npiAdminRiskService.errors['bomLevelRisk'].includes(j)) {
        const presenIndex = this.npiAdminRiskService.errors[
          'bomLevelRisk'
        ].findIndex((item) => item === j);
        this.npiAdminRiskService.errors['bomLevelRisk'].splice(presenIndex, 1);
      }
    }
    if (
      typeof nextUpdatedIndex === DATA_TYPES.STRING &&
      nextUpdatedIndex.length > 0
    ) {
      if (
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
          .MULTI_SOURCE_RISK.bomLevelRisk[nextUpdatedIndex].min >=
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
          .MULTI_SOURCE_RISK.bomLevelRisk[nextUpdatedIndex].max
      ) {
        this.sortedInfoBomLevel[j + 1].showError = true;
        if (!this.npiAdminRiskService.errors['bomLevelRisk'].includes(j + 1)) {
          this.npiAdminRiskService.errors['bomLevelRisk'].push(j + 1);
        }
      } else {
        this.sortedInfoBomLevel[j + 1].showError = false;
        if (this.npiAdminRiskService.errors['bomLevelRisk'].includes(j + 1)) {
          const nextIndex = this.npiAdminRiskService.errors[
            'bomLevelRisk'
          ].findIndex((item) => item === j + 1);
          if (nextIndex > -1) {
            this.npiAdminRiskService.errors['bomLevelRisk'].splice(
              nextIndex,
              1
            );
          }
        }
      }
    }
    this.npiAdminRiskService.saveButtonStatus();
  }
  /**
   *
   * @param event
   * Only allow whole nunmber and less than 1000
   */
  public allowWholeNumber(event, index, maxValue) {
    if (event.charCode >= 48 && event.charCode <= 57) {
      if (index === 0) {
        return +(event.target.value + event.key) < (maxValue ? 996 : 995);
      }
      if (index === 1) {
        return +(event.target.value + event.key) < (maxValue ? 998 : 997);
      }
      if (index === 2) {
        return +(event.target.value + event.key) < (maxValue ? 1000 : 999);
      }
    }
    return (
      event.charCode >= 48 &&
      event.charCode <= 57 &&
      event.target.value.length < 3
    );
  }
}
