import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core'
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service'
import { LevaSummary } from './leva-summary.interface'
import { LevaSummaryService } from './leva-summary.service'
import { BaseService } from 'app/modules/common/base.service'
import { LdDialog, DialogType, DialogButtonName, DialogBoxService, DialogSize } from 'app/modules/leva-ui-library/components/dialog-box/dialog-box.service'

@Component({
  selector: 'app-leva-summary',
  templateUrl: './leva-summary.component.html',
  styleUrls: ['./leva-summary.component.sass'],
  encapsulation: ViewEncapsulation.None,
  providers: [LevaSummaryService],
})
export class LevaSummaryComponent implements OnInit {
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION

  @Input() configuration: LevaSummary
  @Input() showClearAll
  @Output() clearAll = new EventEmitter()
  @Output() onExpandToggle = new EventEmitter()
  @Input() isDirty
  @ViewChild('dynamicComponentView', { static: false }) dynamicComponentView
  @Input() dynamicComponent
  public noData
  filterViewContainer = {
    isExpand: false,
    show: false,
    close() {
      this.isExpand = false
    },
  }
  reload=false
  showConfigurePopup: LdDialog = {
    title: '',
    type :  DialogType.CONFIRMATION,
    id: 'showConfigurePopup',
    message: '',
    template: true,
    customSize: {
      height:490,
      width: 1100
    },buttons: [
      {
        title: DialogButtonName.CANCEL,
        id: 'showConfigurePopupCancel'
      },
      {
        title: DialogButtonName.SAVE,
        id: 'showConfigurePopupSave',
        primary: true
      }
    ],
    beforeClose:()=> this.isDirty,
    discardConfiguration: {
      title: 'Changes',
      size: DialogSize.SMALL,
      draggable: true,
      modal: true,
      id: '123',
      message:
        'You have unsaved changes. Would you like to discard the changes?',
    }
  }
  currencyCode
  constructor(private levaSummaryService: LevaSummaryService, private baseService: BaseService,private dialogBoxService: DialogBoxService) {}
  ngOnInit() {
    this.filterViewContainer.isExpand = this.configuration.isExpand || false;
    if(!this.configuration.bannerSummary.data.length)
    {
      this.noData='No Metrics are available'
    }
    this.currencyCode = this.baseService.getCurrencyObject().currencyCode || 'USD'
  }
  onExpand(event) {
    event.stopPropagation()
    this.filterViewContainer.isExpand = !this.filterViewContainer.isExpand
    this.onExpandToggle.emit(this.filterViewContainer.isExpand)
  }
  onClickclearAll()
  {
    this.clearAll.emit();
  }
  onSaveConfigureScorecard() {}

  public openDialog(id: string): void {
    this.showConfigurePopup.title = this.configuration.title
    this.reload = true
    this.dialogBoxService.open(id)
  }
  public confirmationButtonClick($event)
  {
    if($event && $event.id && $event.button && $event.button.id === 'showConfigurePopupSave'){
      this.configuration.saveCallBack()
    }
    else
    {
      this.reload = false
    }
  }
}
