import { Component, OnInit, Input } from '@angular/core';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';



@Component({
  selector: 'recommendations',
  templateUrl: 'recommendations.component.html',
  styleUrls: ['./recommendations.component.sass'],
  providers: []
})

export class RecommendationsComponent implements OnInit {
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;


  @Input() messages: Array<any> = [];
  constructor() {
  }
  ngOnInit() {
  }
  ngOnChanges() {
  }
}
