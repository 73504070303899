import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-generic-dashboard',
  templateUrl: './generic-dashboard.component.html',
  styleUrls: ['./generic-dashboard.component.sass']
})
export class GenericDashboardComponent implements OnInit {
  @Input() dashboardConfiguration: any;
  @Output() filterSelection: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  filterSelected(event) {
    this.filterSelection.emit(event);
  }

}
