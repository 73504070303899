import { EventEmitter, Injectable, Output } from '@angular/core'
import { ApiServiceProvider } from 'app/api-service'
import { BaseService } from 'app/modules/common/base.service'
import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService'
import { FormParams } from 'app/modules/common/models/FormParams'
import { getUserActions } from 'app/modules/core/bom-tree-grid/utils/tree-track-action'
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class CheckoutService {
  @Output() public statusUpdate: EventEmitter<any> = new EventEmitter<any>()
  @Output() public buttonsEvent: EventEmitter<any> = new EventEmitter<any>()
  public disableButtonsInfo = {
    save: true,
    reset: true,
    checkin: true,
    undoCheckout: true,
  }
  public gridAutoSearchPayload = {
    targetedType: 'PartWorkbenchService',
    method: 'GET',
    servicePath: '/part/attributes',
    type: 'autodimension',
    title: 'PDM'
  }
  checkoutTab = 'partsWorkBenchCheckOutTab'
  public columnLevelFilter = {
    method: 'POST',
    servicePath: '/attribute/column/search',
    targetedType: 'PartWorkbenchService',
    payload: {
      pagingConfiguration: {
        pageNumber: 1,
        pageSize: 100,
        pullRecordCount: true,
        totalRecords: 0,
      },
      sustenaceParts: true,
      visualGrid: 'DEFAULT',
      visualGridElement: '',
      visualGridLevel: '',
      columnFilterDimension: '',
      columnFilterInput: '',
      cpnSearchInput: '',
      filter: []
    }
  }
  public defaultPagination = {
    pageNumber: 1,
    pageSize: 100,
    pullRecordCount: true,
    totalRecords: 0,
  }
  public payload = {
    filter: [],
    pagingConfiguration: {
      pageNumber: 1,
      pageSize: 100,
      pullRecordCount: true,
      totalRecords: 0,
    },
  }
  private headersCustomInfo = {}
  public cellLevelInfo = {}
  public rejectedArray = []
  public finalizedSaveArray = []
  public selectedRowsMap = new Map<string, object>()
  public excludePartIds = []
  constructor(private apiService: ApiServiceProvider,
    private ccService: CustomerConfigurationService, private baseService: BaseService) { }

  public formSavePayload() {
    const editedRows = getUserActions()
    let primaryKeyMissing
    this.finalizedSaveArray = []
    this.rejectedArray = []
    Object.keys(editedRows).forEach((item) => {
      editedRows[item].forEach((i) => {
        i.action = item.toLowerCase()

        primaryKeyMissing = this.validateChanges(i)
        this.finalizedSaveArray.push(i)
        if (primaryKeyMissing) {
          this.rejectedArray.push(i)
        }
      })
    })
    return this.finalizedSaveArray
  }
  public validateChanges(rowData) {
    const primaryKeys = this.getHeadersCustomInfo()
    let missing = true
    if (rowData['nodeType'] === 'PART') {
      missing = !primaryKeys['partKey'].some((key) =>
        this.partNumberValidation(rowData[key])
      )
    } else if (rowData['nodeType'] === 'SOURCE_GROUP') {
      missing = !primaryKeys['sourceGroupKey'].some((key) => rowData[key])
    } else if (rowData['nodeType'] === 'SOURCE') {
      missing = !primaryKeys['sourceKey'].some((key) => rowData[key])
    }
    return missing
  }

  partNumberValidation(data) {
    if (typeof data === 'string') {
      return data.trim()
    } else {
      return data
    }
  }
  getRFXData(rfqName){
    const payload = {
      method: 'GET',
      servicePath: '/rfq',
      targetedType: 'RFQ',
      formParams: {
        rfqName
      }
    };
    return this.apiService.post(payload);
  }
  public undoCheckout(partCheckoutIds) {
    const data = {
      targetedType: 'PartWorkbenchService',
      method: 'POST',
      servicePath: `/undo/checkout`,
      payload: partCheckoutIds,
    }
    return this.apiService.post(data)
  }

  public undoCheckoutAll(partCheckoutIds) {
    const data = {
      targetedType: 'PartWorkbenchService',
      method: 'POST',
      servicePath: `/undo/checkout`,
      payload: partCheckoutIds,
    }
    delete data.payload.pagingConfiguration
    data.payload['excludePartIds'] = this.excludePartIds
    return this.apiService.post(data)
  }
  public onSave() {
    const data = {
      targetedType: 'PartWorkbenchService',
      method: 'POST',
      servicePath: `/save/parts`,
      payload: { components: this.formSavePayload() },
    }
    return this.apiService.post(data)
  }
  public onCheckIn(payload) {
    const data = {
      targetedType: 'PartWorkbenchService',
      method: 'POST',
      servicePath: `/checkin/parts`,
      payload,
    }
    return this.apiService.post(data)
  }
  public onCheckInAll(payload) {
    const payloadCopy = payload
    const data = {
      targetedType: 'PartWorkbenchService',
      method: 'POST',
      servicePath: `/checkin/parts/all`,
      payload,
    }
    delete data.payload.pagingConfiguration
    data.payload['excludePartIds'] = this.excludePartIds
    return this.apiService.post(data)
  }
  public getCheckedoutParts() {
    if (
      this.payload instanceof Object &&
      this.payload['columnLevelFilter'] &&
      !Object.keys(this.payload['columnLevelFilter']).length
    ) {
      delete this.payload['columnLevelFilter']
    }
    const data = {
      targetedType: 'PartWorkbenchService',
      method: 'POST',
      servicePath: `/checkedoutparts`,
      payload: this.payload,
    }
    return this.apiService.post(data)
  }

  public getCheckInStatus() {
    const data = {
      targetedType: 'PartWorkbenchService',
      method: 'GET',
      servicePath: `/job/status`,
    }
    return this.apiService.post(data)
  }
  public updateCheckInStatus(jobId, status) {
    const data = {
      targetedType: 'PartWorkbenchService',
      method: 'GET',
      servicePath: `/job/updatestatus`,
      formParams: {
        jobId,
        status,
      },
    }
    return this.apiService.post(data)
  }
  public setHeadersCustomInfo(val) {
    this.headersCustomInfo = val
  }
  public getHeadersCustomInfo() {
    return this.headersCustomInfo
  }
  getTemplateHeaders() {
    const payload = {
      targetedType: 'TemplateManagementService',
      method: 'GET',
      servicePath: '/pwb/customertemplate',
    }
    return this.apiService.post(payload)
  }
  uploadParts(formdata) {
    const envDetails = this.ccService.getEnvironmentDetails();
    return this.apiService.fileUpload(
      formdata,
      envDetails.PartWorkbenchService + '/upload',
      null,
      null
    );
  }
  public uploadStatus() {
    const payload = {
      targetedType: 'PartWorkbenchService',
      method: 'GET',
      servicePath: '/upload/status'
    };
    return this.apiService.post(payload);
  }

  public savePartCheckoutIds(parts){
    const payload = {
      method: 'POST',
      servicePath: 'PartWorkbenchService/pwb/rfx/current/selected/scope/INSERT',
      payload : parts,
      formParams : {
        userEmail : this.baseService.getUserInformation().email,
      }
    }
    return this.apiService.post(payload);
  }
  public fetchRFXStatus(actionTypeVal,responseStatusVal,email){
    const payload = {
      method: 'GET',
      servicePath: 'PartWorkbenchService/pwb/rfxresponse/update/pending',
      formParams: {
        actionType: actionTypeVal,
        responseStatus: responseStatusVal,
        userEmail: email
      },
    }
    return this.apiService.post(payload);
  }

  public fetchRfqList(){
    const payload = {
      method: 'GET',
      servicePath: 'PartWorkbenchService/pwb/rfx/getRfqDetails'
    }
    return this.apiService.post(payload);
  }

  public bulkUpdateParts(input){
    const payloadJson = {
      method: 'POST',
      servicePath: 'PartWorkbenchService/update/pwb/rfx',
      payload : input
    }
    return this.apiService.post(payloadJson);
  }
}
