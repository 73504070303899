import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

import { MultiplePartMatchingView } from './multiple-part-matching-view.model';
import { ApiServiceProvider } from 'app/api-service';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class MultiplePartMatchingViewService{
  constructor(private apiServiceProvider: ApiServiceProvider) { }
public	whereUsedData =new BehaviorSubject<any>(undefined);

  public getMultiplePartMatchingResult(name: string, url: string, type: string) {
    const object: any = {
      targetedType: 'MIService',
      method: type,
      servicePath: url,
      payload: {
        input: name
      }
    };
    return this.apiServiceProvider.post(object);
  }
  // 	public getWhereUsedResult(mpnname: string,cpnname: string,url:string, type:string){
  // 		var object: any = {
  // 			"targetedType":"CMS",

  // 			"method":type,
  //             "servicePath": url,
  //             "payload": {

  // 				"productInputs":{"mpn":mpnname,"cpn":cpnname}}
  // 		}
  // 		return this.apiServiceProvider.post(object);
  // }
  setwhereUsedData(value: any) {

    this.whereUsedData.next(value)

  }
  getwhereUsedData() {

    return this.whereUsedData.asObservable();
  }


}
