import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bot-link',
  templateUrl: './app-bot-link.component.html',
  styleUrls: ['./app-bot-link.component.css']
})

export class AppBotLinkComponent implements OnInit {

  constructor() { }

  ngOnInit() { }
}
