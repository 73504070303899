export const vendorListConfiguration = {
    label: 'Suppliers Impacted',
    type: 'newautosearch',
    filterConfig: {
        multiSelect: true,
        targetedType: 'riskManagementService',
        servicePath: 'risk/search/ldSupplier',
        defaultItemSelection: false,
        defaultSelectedItem: null,
    },
    hideLabel: true,
    val: 'vendorImpacted',
    data: [],
    showCustomToolTip: true,
    id: `vendor-auto-select`,
    defaultParameter: null,
    resetValues: generateRandomKey()
}
export const typeListConfiguration = {
    label: 'Event Type',
    type: 'static',
    name: 'type',
    hideLabel: true,
    data: [],
    id: 'eventTypeListId',
    resetValues: generateRandomKey(),
    defaultItemSelection: null
}
export const statusListConfiguration = {
    label: 'Event Status',
    type: 'static',
    hideLabel: true,
    name: 'activeState',
    data: [],
    id: 'eventStatusListId',
    resetValues: generateRandomKey(),
    defaultItemSelection: null
}

function generateRandomKey() {
    const crypto = window.crypto
    const array = new Uint32Array(1)
    return crypto.getRandomValues(array)[0]
}
