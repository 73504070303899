import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

import { MsgList } from './msg-list.model';
import { ApiServiceProvider } from '../../../../../api-service';

@Injectable()
export class MsgListService {

  constructor(private http: HttpClient, private apiServiceProvider: ApiServiceProvider) { }

  getSingleRfqData(rfqid, scope) {
    const value = {
      filter: [
        {
          columnDataType: 'NUMBER',
          dbName: 'r.RFQ_CUST_ID',
          value: rfqid
        }
      ]
    }
    const object: any = {
      targetedType: 'RFQ',
      formParams: {
        templateType: scope
      },
      payload: value,
      method: 'POST',
      encryptionType: 'payload',
      servicePath: 'rfqCardsList',

    };

    return this.apiServiceProvider.post(object);

  }

  getSingleRFXData(rfxDetails) {
    const object: any = {
      targetedType: 'RFQ'
    };

    switch (rfxDetails.THREAD_TYPE) {
      case 'E':
      case 'R':
        object['method'] = 'GET';
        object['formParams'] = {
          rfqId: rfxDetails.RFQ_CUST_ID
        }
        object['servicePath'] = 'common/getRFQNameByRfqId';
        break;
      default:
        object['method'] = 'GET';
        object['servicePath'] = 'rfx/' + rfxDetails.RFQ_CUST_ID;
        break;
    }
    return this.apiServiceProvider.post(object);
  }

  getRfqBasicInfo(rfqId) {
    const payload = {
      servicePath: `rfx/productrfq/${rfqId}/controls`,
      method: 'GET',
      targetedType: 'productRFQ'
    };
    return this.apiServiceProvider.post(payload);
  }

  public validateServiceData(data) {
    if (data instanceof Object) {
      if (data['responseStatus'] instanceof Object) {
        switch (data['responseStatus']['code']) {
          case 200:
            return data;
          default:
            return 'error';
        }
      }
    }
  }
}
