import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RfqConfigService } from 'app/modules/rfq-configurations/shared/rfq-config.service';
import { GridOptions } from '@ag-grid-community/core';
import { GridConfiguration } from 'app/modules/core/nxt-grid/gridconfiguration';
import { RFQ_CUSTOMER_CONTEXTS, TEMPLATE_SUPPLIER_CONTEXT } from 'app/modules/rfq-configurations/shared/constants';

@Component({
  selector: 'template-preview',
  templateUrl: './template-preview.component.html',
  styleUrls: ['./template-preview.component.sass']
})

export class TemplatePreviewComponent implements OnInit {

  public loader = false;
  public previewItems;
  public selectedView: any;
  public gridOptions = {} as GridOptions;
  public gridHeaders;
  private rfqConfigSubscribe;
  private gridData;
  @Input() templateId: number;

  @Output() popupClosed: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private rfqConfigService: RfqConfigService,
    public gridConfig: GridConfiguration
  ) {
  }

  ngOnInit() {
    this.rfqConfigService.getRefSetConstants(RFQ_CUSTOMER_CONTEXTS).subscribe((res) => {
      if(!res.error) {
        let refSetTerms = res[0].refSetTerms;
        refSetTerms = refSetTerms.filter((term) => term.refTermActualValue !== 'ADHOC')
        refSetTerms.unshift(TEMPLATE_SUPPLIER_CONTEXT);
        this.previewItems = refSetTerms;
        this.selectedView = this.previewItems[0];
        this.setupGrid();
        this.showPreview();
      }
    })
  }

  setupGrid() {
    this.rfqConfigService.getPreviewHeaderInfo().subscribe(res => {
      if (res && res.result) {
        this.gridHeaders = res;
        console.log('recieved headers', this.gridHeaders);
        this.gridConfig.setHeaderObject(this.gridHeaders);
      }
    });
    this.gridConfig.setPagination(false);
    this.gridConfig.setSuppressRowClickSelection(false);
    this.gridConfig.setRowSelection('single');
    this.gridConfig.setPaginationWithGotoPage(false);
  }

  closePreviewPopUp() {
    this.popupClosed.emit(true);
  }

  onViewValueChange(event) {
    this.selectedView = event;
    this.showPreview();
  }

  showPreview() {
    this.loader = false;
    this.rfqConfigService.getPreviewGridData(this.templateId, this.selectedView.refTermActualValue).subscribe(
      res => {
        let data = null;
        if (!res.error) {
          data = res;
          this.gridData = data;
          this.gridOptions.rowData = data;
          this.gridConfig.setRowData(this.gridOptions.rowData);
          this.loader = true;
        }
      },
      err => {
        console.log(err);
      }
    );
  }
}
