import { Component, OnInit, Input } from '@angular/core';

import { MsgList } from './shared/msg-list.model';
import { MsgListService } from './shared/msg-list.service';
import { MessageToSupplier } from '../../../core/message-to-supplier/shared/message-to-supplier.model';
import { SubscriptionService } from '../../../common/subscription.service';
import { Router } from '@angular/router';
import { DataStorageService } from '../../../common/data-storage.service';
import { ScopeDataService } from '../../../common/scope.data.service';
import { AppHeaderService } from '../shared/app-header.service';
import { SrfxDataProvider } from 'app/modules/srfq/srfx.data.provider';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';

@Component({
  selector: 'msg-list',
  templateUrl: 'msg-list.component.html',
  styleUrls: ['msg-list.component.sass'],
  providers: [MsgListService, SrfxDataProvider]
})

export class MsgListComponent implements OnInit {
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  focus: string;
  unReadSubjects: any[];
  data = false;
  constructor(

    private msgListService: MsgListService,
    private subscriptionService: SubscriptionService,
    private router: Router,
    private dataStorageService: DataStorageService,
    private scopeDataService: ScopeDataService,
    private appHeaderService: AppHeaderService,
    private srfxDataProvider: SrfxDataProvider
  ) { }
  @Input() set Messages(val) {
    this.unReadSubjects = val;
    this.data = true;
  }
  ngOnInit() {
    this.focus = this.scopeDataService.getFocusValue();
    this.scopeDataService.getFocus().subscribe((val) => {
      this.focus = val;
    })

  }
  onclickUrSubject(index) {
    this.appHeaderService.setMsgPanel(false);
    this.subscriptionService.setUnreadSubject(this.unReadSubjects[index]);
    const payload = {
      rfxId: this.unReadSubjects[index].rfq_cust_id,
      type: this.unReadSubjects[index].thread_type
    }
    this.srfxDataProvider.getDetailedRFXInfo(payload).subscribe(
      (info) => {
        if (this.srfxDataProvider.validateServiceData(info) !== 'error') {

          let resultObj: any;
          let routeString = '';

          switch (this.unReadSubjects[index].thread_type) {
            case 'E':
              resultObj = info.result.rfqCustomer;
              resultObj['rfqTypeName'] = 'IE';
              routeString = 'informationexchange';
              resultObj['creatorEmail'] = info.result.creatorEmail;
              break;
            case 'R':
              resultObj = info.result.rfqCustomer;
              resultObj['rfqTypeName'] = 'RFQ';
              routeString = 'r';
              resultObj['creatorEmail'] = info.result.creatorEmail;
              break;
            case 'RFX':
              resultObj = info.result;
              resultObj['rfqTypeName'] = 'RFX';
              routeString = 'product/pr';
              resultObj['creatorEmail'] = info.result.creatorEmail;
              break;
            default:
              resultObj = info.result;
              resultObj['rfqCustName'] = info.result.name;
              resultObj['rfqCustId'] = info.result.rfxId;
              resultObj['rfqTypeName'] = info.result.type;
              resultObj['creatorEmail'] = info.result.creatorEmail;
              routeString = 'informationsharing';
              break;
          }

          this.dataStorageService.setClikedRFQforDetails(resultObj);
          this.dataStorageService.setClickedSubjectRfqValues(resultObj);
          this.router.navigate(['/app/srfx/' + routeString + '/suppliercollaboration']);

          this.unReadSubjects.splice(index, 1)
        }
      }
    )

  }
}
