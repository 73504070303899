import {
  Component,
  ViewChild,
  ViewContainerRef,
  AfterViewInit
} from '@angular/core';
import { DataStorageService } from '../../common/data-storage.service';

@Component({
  selector: 'child-cell',
  template: `
    <div tabindex="0">
      <multi-select-dropdown
        [items]="dropDownValues"
        readKey="userGroup"
        [defaultSelected]="defaultSelectedValues"
        [dropdownTitle]="'Please Select a User Permission'"
        (onSelectionChange)="onViewSelectionChange($event)"
        (onUnCheckAll)="multiselectCheckandUncheck()"
      ></multi-select-dropdown>
    </div>
  `
})
export class MultiSelectRendererComponent {
  public dropDownValues: any;
  public defaultSelectedValues: Object[] = [];
  public request: any;
  public cmList: any;
  public params: any;
  public autosearchShow = false;
  private selectedGroups = {};
  constructor(private dataStorageService: DataStorageService) {}

  public agInit(params: any): void {
    this.params = params;
    this.dropDownValues = [];
    this.params.dataByUser.forEach(value => {
      let obj = {};
      obj = {
        userGroup: value,
        checked: false
      };
      this.dropDownValues.push(obj);
    });
    this.defaultSelectedValues = [];
    this.params.value.forEach(element => {
      let obj = {};
      obj = {
        userGroup: element,
        checked: true
      };
      this.defaultSelectedValues.push(obj);
    });
  }
  public onViewSelectionChange(event) {
    const newparam = this.params;
    if (event instanceof Array) {
      newparam.value = [];
      if (event.length > 0) {
        event.forEach(value => {
          newparam.value.push(value.userGroup);
        });
      }
      this.params.data.userGroups = newparam.value;
      this.selectedGroups[this.params.data.reportId] = newparam;
      this.dataStorageService.setCheckedUserGroupsInGrid(this.selectedGroups);
    }
  }
  public multiselectCheckandUncheck() {}
  public ngOnInit() {}
}
