import { ECalendarValue, IDatePickerConfig } from 'ng2-date-picker';

export const primaryCategoryConfiguration = {
    label: 'Primary Category',
    id: 'primary-category',
    val: 'primaryCategory',
    type: 'multiselectvirtualscroll',
    showAll: false,
    hideLabel: true,
    hideEmptyValue: true,
    hideClearItem: true,
    noTooltip: true,
    data: [],
    resetValues: generateRandomKey()
}
export const secondaryCategoryConfiguration = {
    label: 'Secondary Category',
    id: 'secondary-category',
    val: 'secondaryCategory',
    type: 'autosearch',
    filterConfig: {
        specialVersion: true,
        servicePath: 'IndustryNewsPlatformService/news/search/secondaryCategory',
        targetedType: 'IndustryNewsPlatformService',
        multiSelect: true,
    },
    showAll: false,
    hideLabel: true,
    hideEmptyValue: true,
    hideClearItem: true,
    noTooltip: true,
    data: [],
    resetValues: generateRandomKey()
}
export const severityConfiguration = {
    label: 'Severity',
    type: 'static',
    hideLabel: true,
    name: 'severity',
    data: [
        {
            val: 'Low',
            displayName: 'Low'
        },
        {
            val: 'Medium',
            displayName: 'Medium'
        },
        {
            val: 'High',
            displayName: 'High'
        },
        {
            val: 'Moderate',
            displayName: 'Moderate'
        },
        {
            val: 'Severe',
            displayName: 'Severe'
        }
    ],
    id: 'severity',
    resetValues: generateRandomKey(),
    defaultItemSelection: null
}
export const sourceConfiguration = {
    label: 'Source',
    type: 'static',
    hideLabel: true,
    name: 'source',
    data: [],
    id: 'source',
    resetValues: generateRandomKey(),
    defaultItemSelection: null
}
// export const datePickerObject: Moment

function generateRandomKey() {
    const crypto = window.crypto
    const array = new Uint32Array(1)
    return crypto.getRandomValues(array)[0]
}
