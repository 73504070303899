import { Component, Input } from '@angular/core'
import { GridOptions } from '@ag-grid-community/core'
import { GridEmitterService } from 'app/modules/common/grid.emitter.service'
@Component({
  selector: 'cprt-grid-pagination',
  styleUrls: ['./grid-pagination.component.sass'],
  templateUrl: './grid-pagination.component.html',
})
export class GridPaginationComponent {
  @Input() public customGridOptions: any
  public gridCustomOptions = {} as GridOptions
  @Input() public pageConfig = {
    pageNumber: 1,
    pageCount: 0,
    pageSize: 50,
    totalRecords: 0,
  }
  @Input() additionalGridConfig: any = {}

  constructor(
    private gridEmitterService: GridEmitterService
  ) {
  }

  customPaginationMethod() {
    this.customGridOptions.paginationPageSize = this.pageConfig.pageSize
    this.gridCustomOptions.paginationPageSize = this.pageConfig.pageSize
    this.setPageConfig()
  }

  setPageConfig() {
    switch (this.additionalGridConfig.enableServerSidePagination) {
      case true:
        // console.log(this.pageConfig)
        break
      case false:
        this.customGridOptions.onPaginationChanged = this.onPaginationChanged.bind(
          this
        )
        break
    }
  }

  first() {
    this.customGridOptions.api.paginationGoToFirstPage()
  }
  previousPage() {
    this.customGridOptions.api.paginationGoToPreviousPage()
  }
  nextPage() {
    this.customGridOptions.api.paginationGoToNextPage()
  }
  last() {
    this.customGridOptions.api.paginationGoToLastPage()
  }
  itemsPerPage() {
    this.customGridOptions.api.paginationSetPageSize(
      Number(this.pageConfig.pageSize)
    )
  }
  goToPage(pageNumber) {
    this.customGridOptions.api.paginationGoToPage(pageNumber - 1)
  }
  onPaginationChanged() {
    this.pageConfig.totalRecords = this.customGridOptions.api.paginationGetRowCount()
    this.pageConfig.pageCount = this.customGridOptions.api.paginationGetTotalPages()
    this.pageConfig.pageSize = this.customGridOptions.api.paginationGetPageSize()
    this.pageConfig.pageNumber =
      this.customGridOptions.api.paginationGetCurrentPage() + 1
  }
  onPaginationPanelButtonClick(buttonType) {
    if (this.additionalGridConfig.enableServerSidePagination) {
      this.gridEmitterService.emitPaginationResult({
        buttonType,
        additionalGridConfig: this.additionalGridConfig,
      })
    } else {
      switch (buttonType) {
        case 'first':
          this.customGridOptions.api.paginationGoToFirstPage()
          break
        case 'last':
          this.customGridOptions.api.paginationGoToLastPage()
          break
        case 'next':
          this.customGridOptions.api.paginationGoToNextPage()
          break
        case 'previous':
          this.customGridOptions.api.paginationGoToPreviousPage()
          break
        case 'pagesizechange':
          this.customGridOptions.api.paginationSetPageSize(
            Number(this.pageConfig.pageSize)
          )
          break
        case 'goto':
          const pageNumber = this.pageConfig.pageNumber
          this.customGridOptions.api.paginationGoToPage(pageNumber - 1)
          break
      }
    }
  }
}
