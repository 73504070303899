import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import {
  TreeviewItem,
  TreeviewConfig,
  DownlineTreeviewItem,
  TreeviewEventParser,
  OrderDownlineTreeviewEventParser
} from 'ngx-treeview';
import * as _ from 'lodash';

@Component({
  selector: 'checkbox-treeview',
  templateUrl: './checkbox-treeview.component.html',
  styleUrls: ['./checkbox-treeview.component.sass'],
  providers: [
    { provide: TreeviewEventParser, useClass: OrderDownlineTreeviewEventParser }
  ]
})
export class CheckboxTreeviewComponent implements OnInit {
  previousItems = [];
  @Input()
  target: any;
  @Input()
  treeviewConfig: TreeviewConfig;
  @Input()
  identifier: any = {};
  @Input()
  treeviewItems: TreeviewItem[];
  @Input()
  itemTemplate;
  @Output()
  itemselected: EventEmitter<any> = new EventEmitter<any>();
  allSelectedItems = [];
  constructor() {}

  ngOnInit() {
    this.previousItems = this.treeviewItems.map(i => i.text);
    if(this.target === 'usergroups' && this.treeviewItems.filter(i =>i.text === 'Spend Intelligence') && this.treeviewItems.filter(i =>i.text === 'Spend Intelligence')[0].hasOwnProperty('internalChildren')){
       if(this.treeviewItems.filter(i =>i.text === 'Spend Intelligence')[0]['internalChildren'].filter(i=>i.checked === true).map(i=>i.text).length === 2){
        this.treeviewItems.filter(i =>i.text === 'Spend Intelligence')[0]['internalChildren'][1].checked = false;
       }
    }
  }
  ngOnChanges() {}

  onFilterChange(value: string) {
    // console.log("filter:", value);
  }
  onSelectedChange(downlineItems: DownlineTreeviewItem[]) {
    if(this.target === 'usergroups'){
    const currentItems =downlineItems.map(i => i.item.text);
    const checkedItem =  _.difference(currentItems,this.previousItems);
    if(this.previousItems.includes('Spend Intelligence with Transformation') && checkedItem[0] === 'Spend Intelligence without Transformation'){
      const index =downlineItems.findIndex(i => i.item.text === 'Spend Intelligence with Transformation');
      downlineItems[index].item.checked =  false;
    }else if(downlineItems.map(i => i.item.text).includes('Spend Intelligence without Transformation') && checkedItem[0] === 'Spend Intelligence with Transformation'){
      const index =downlineItems.findIndex(i => i.item.text === 'Spend Intelligence without Transformation');
      downlineItems[index].item.checked =  false;
    }
    this.previousItems=[];
    this.previousItems = [...downlineItems.filter(i => i.item.checked).map(j=>j.item.text)];
  }
    const id = this.identifier.recordId;
    const name = this.identifier.feature;
    const section = this.identifier.section;
    const checked = this.identifier.checked;
    const scopeId = this.identifier.scopeId;
    const error = this.identifier.error;
    this.allSelectedItems = [];
    // downlineItems.forEach(downlineItem => {
    //   const item = downlineItem.item;
    //   let currentItem = { text: item.text, value: item.value, checked: true };
    //   const parentItems = [];
    //   let parent = downlineItem.parent;
    //   while (!isNil(parent)) {
    //     parentItems.push({ text: parent.item.text, value: parent.item.value });
    //     parent = parent.parent;
    //   }
    //   currentItem["parent"] = parentItems;
    //   this.allSelectedItems.push(currentItem);
    // });
    this.itemselected.emit({
      recordId: id,
      feature: name,
      section,
      checked,
      scopeId,
      error,
      items: this.treeviewItems
    });
  }
}
