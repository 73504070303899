import { Injectable } from '@angular/core';
import { ApiServiceProvider } from 'app/api-service';

@Injectable({ providedIn: 'root' })
export class DashboardMetricTrendsService extends ApiServiceProvider{
    getDashboardCardDetails(requestPayload){
      const payload: any = {
        targetedType: requestPayload.targetedType,
        method: requestPayload.method,
        generic: false,
      };
      payload.servicePath = requestPayload.servicePath || '' ;
      return this.post(payload);
    }
}
