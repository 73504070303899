import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiServiceProvider } from 'app/api-service';
import { BaseService } from '../common/base.service';

@Injectable({
    providedIn: 'root'
})

export class ContextLandingPageService {
    constructor(
        private apiService: ApiServiceProvider,
        private baseService: BaseService
      ) { }
    getTaskList(input,userId) {
        let payload;
        payload = {
          targetedType: 'Collaboration',
          method: 'GET',
          formParams: {
            userEmail: userId,
            taskId: input
          },
          servicePath: '/task/getTask'
        };
        return this.apiService.post(payload);
      }
  getMailAuth(input, userId) {
    let payload;
    payload = {
      targetedType: 'Collaboration',
      method: 'GET',
      formParams: {
        encryptedData: input
      },
      servicePath: '/task/getTaskMailAuth'
    };
    return this.apiService.post(payload);
  }
  getContextDetail(input, userId) {
    let payload;
    payload = {
      targetedType: 'Collaboration',
      method: 'GET',
      formParams: {
        encryptedData: input
      },
      servicePath: '/task/contextdetails'
    };
    return this.apiService.post(payload);
  }

}
