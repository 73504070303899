import { Component } from '@angular/core';

import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { DataStorageService } from 'app/modules/common/data-storage.service';

const ROW_UNIQUE_ID = 'cmspkId';

@Component({
    selector: 'child-cell',
    template: `
    <div class="dropdown full-width">
        <select (change)="onChange($event)" class="form-control full-height full-width font-size-12">
            <option value={{optionStatus.id}}
                title={{optionStatus.value}}
                *ngFor="let optionStatus of options">
                {{optionStatus.value}}
            </option>
        </select>
    </div>`
})
export class ProductDropdownStatusRenderer implements ICellRendererAngularComp {
    public params: any;
    public totalOptions: Array<any> = [];
    public options: Array<any> = [];
    public status: number;
    public selectedOption: any;

    constructor(private dataStorageService: DataStorageService) { }

    agInit(params: any): void {

        this.params = params;
        this.totalOptions = params.values;

        const findOption: Array<any> = this.totalOptions.filter(
            (match) => match.id === Number(params.value)
        );

        this.selectedOption = findOption.length ? findOption[0] : this.totalOptions[0];
        this.options = this.selectedOption.statusModifierArray;
    }

    refresh(): boolean {
        return true;
    }

    setStatus(status: number): void {
        this.status = status;
    }

    onChange(event: any) {

        let changedOption: object;
        changedOption = this.totalOptions.find(
            (match) => match.id === Number(event.target.value)
        );
        if (changedOption) {
            this.setStatus(changedOption['id']);
        }

        let selectedStatusObj: any;
        selectedStatusObj = {
            oldValue: Number(this.params.value),
            newValue: this.status,
            currentColumn: this.params.colDef.field,
            currentRow: this.params.data
        };
        this.dataStorageService.setSelectedRowStatus(selectedStatusObj);
    }

}
