import {
  Component,
  ViewChild,
  ViewContainerRef,
  EventEmitter,
  Output
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  IAfterGuiAttachedParams,
  IDoesFilterPassParams,
  IFilterParams,
  RowNode
} from '@ag-grid-community/core';
import { IFilterAngularComp } from '@ag-grid-community/angular';
import { RfqListSearchService } from 'app/modules/srfq/common/rfq-list-search/shared/rfq-list-search.service';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { SubscriptionLike } from 'rxjs';

import { SrfqService } from 'app/modules/srfq/shared/srfq.service';
import { CreateScheduleService } from 'app/modules/srfq/create/create-schedule/shared/create-schedule.service';
import { Create } from 'app/modules/srfq/create/shared/create.model';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';
import { DataFilter } from 'app/modules/srfq/common/data-filter/shared/data-filter.model';

@Component({
  moduleId: module.id,
  selector: 'partial-match-filter',
  templateUrl: './partial-match-filter.component.html',
  styleUrls: ['./partial-match-filter.component.sass']
})
export class PartialMatchFilterComponent implements IFilterAngularComp {
  public headerName: string;
  public filterApplied = false;
  public appplyDisable = false;
  public columnDataType: any;
  public appliedColumnFilters: any;
  public columnFilters: {};
  public checkedArray: any = [];
  public createGrid: string;
  public arr: any[];
  public selectedAll = true;
  public suggestion: any;
  public fieldName: string;
  public temp: any = [];
  public text = '';
  public getFiltersWhenApplySubcription: SubscriptionLike;
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  public tabNameSubcription: SubscriptionLike;
  @Output() public searchTermChanged: EventEmitter<any> = new EventEmitter<
    any
  >();
  @ViewChild('input', { read: ViewContainerRef, static: true }) public input;
  public suggestions = [];
  public filteredSuggestions = [];
  public searchedSuggestion = false;
  public appendFilterDisable = true;
  public appendSelected = false;
  public checkboxSuggestionSelected = false;
  public previousAppendedArray = [];
  public previousTempArray = [];
  private tempHasUncheckedOption = false;
  private test: Function;
  private params: IFilterParams;
  private valueGetter: (rowNode: RowNode) => any;
  constructor(
    private createScheduleService: CreateScheduleService,
    private create: Create,
    private srfqService: SrfqService,
    private rfqListSearchService: RfqListSearchService,
    private dataStorageService: DataStorageService,
    public DataFilterModel: DataFilter
  ) {
    this.createGrid = this.dataStorageService.getcreateELS();
  }

  public agInit(params: IFilterParams): void {
    if (
      this.srfqService.partialMatchFilterColumnData instanceof Object &&
      Object.keys(this.srfqService.partialMatchFilterColumnData).length
    ) {
      if (
        Object.keys(this.srfqService.partialMatchFilterColumnData).includes(
          params.colDef.headerName
        )
      ) {
        this.filterApplied = true;
      }
    }
    this.params = params;
    this.valueGetter = params.valueGetter;
    this.fieldName = params.colDef.field;
    this.columnDataType = params['datatype'];
    this.headerName = params.colDef.headerName;
    this.getFiltersWhenApplySubcription = this.dataStorageService
      .getFiltersWhenApply()
      .subscribe(appliedValues => {
        if (appliedValues === 'Quick Filters') {
          const searchPayload = {
            query: {
              key: '',
              columns: [this.fieldName.toLowerCase()]
            },
            filters: [],
            groupBy: '',
            count: 500
          };
          this.rfqListSearchService
            .getSearchScopeResults(searchPayload, this.createGrid)
            .subscribe(result => {
              this.suggestions =
                (result instanceof Object &&
                  result['suggestions'] instanceof Array &&
                  result['suggestions'].length &&
                  result['suggestions'][0].suggestions instanceof Array &&
                  result['suggestions'][0].suggestions) ||
                [];
              this.arr = [];
              const arr2 = [];
              this.params.rowModel['rowsToDisplay'].forEach(rowNode => {
                arr2.push(rowNode['data'][this.params.colDef.field])
              })
              result.suggestions[0].suggestions.forEach(item => {
                if (arr2.includes(item)) {
                  this.arr.push({
                    name: item,
                    selected: true
                  });
                } else {
                  this.arr.push({
                    name: item,
                    selected: false
                  });
                }
                this.suggestion = this.arr;
                this.temp = this.arr;
                for (let i = 0; i < this.temp.length; i++) {
                  if (this.temp[i].selected === false) {
                    this.selectedAll = false;
                  }
                }
              });
            });
        }
        else if (appliedValues !== undefined) {
          this.appliedColumnFilters = {};
          for (let x = 0; x < appliedValues.length; x++) {
            if (appliedValues[x].columnDataType === 'STRING') {
              if (appliedValues[x].dbName) {
                this.appliedColumnFilters[
                  appliedValues[x].dbName.toLowerCase()
                ] = appliedValues[x].startVal;
              }
            }
            // if (appliedValues[x].columnDataType === 'DECIMAL') {
            //     this.appliedColumnFilters[appliedValues[x].dbName.toLowerCase()] = []
            //     this.appliedColumnFilters[appliedValues[x].dbName.toLowerCase()] = ["<btw>|" + appliedValues[x].startVal + "|" + appliedValues[x].endVal]
            //     //appliedValues[x].startVal;
            // }
          }
          const aplliedFilterNames =
            Object.keys(this.appliedColumnFilters) || [];
          let tempBackupOfCOlFil = [];
          if (aplliedFilterNames.includes(this.fieldName.toLowerCase())) {
            tempBackupOfCOlFil = JSON.parse(
              JSON.stringify(
                this.appliedColumnFilters[this.fieldName.toLowerCase()]
              )
            );
            delete this.appliedColumnFilters[this.fieldName.toLowerCase()];
          }
          if (appliedValues !== undefined) {
            const searchPayload = {
              query: {
                key: '',
                columns: [this.fieldName.toLowerCase()]
              },
              filters: [
                {
                  columnFilters: this.appliedColumnFilters
                }
              ],
              groupBy: '',
              count: 500
            };
            this.rfqListSearchService
              .getSearchScopeResults(searchPayload, this.createGrid)
              .subscribe(result => {
                this.suggestions =
                  (result instanceof Object &&
                    result['suggestions'] instanceof Array &&
                    result['suggestions'].length &&
                    result['suggestions'][0].suggestions instanceof Array &&
                    result['suggestions'][0].suggestions) ||
                  [];
                this.arr = [];
                if (
                  tempBackupOfCOlFil instanceof Array &&
                  tempBackupOfCOlFil.length
                ) {
                  tempBackupOfCOlFil.forEach(item => {
                    if (!result['suggestions'][0].suggestions.includes(item)) {
                      result['suggestions'][0].suggestions.push(item);
                    }
                  });
                }
                result.suggestions[0].suggestions.forEach(item => {
                  if (
                    tempBackupOfCOlFil.length !== 0 ||
                    aplliedFilterNames.includes(this.fieldName.toLowerCase())
                  ) {
                    if (tempBackupOfCOlFil.includes(item)) {
                      this.arr.push({
                        name: item,
                        selected: true
                      });
                    } else {
                      this.arr.push({
                        name: item,
                        selected: false
                      });
                    }
                  } else {
                    this.arr.push({
                      name: item,
                      selected: true
                    });
                  }
                  this.suggestion = this.arr;
                  this.temp = this.arr;
                  this.previousAppendedArray = [];
                });
                for (let i = 0; i < this.temp.length; i++) {
                  if (this.temp[i].selected === false) {
                    this.selectedAll = false;
                  }
                }
              });
          }
        } else {
          const searchPayload = {
            query: {
              key: '',
              columns: [this.fieldName.toLowerCase()]
            },
            filters: [],
            groupBy: '',
            count: 500
          };
          this.rfqListSearchService
            .getSearchScopeResults(searchPayload, this.createGrid)
            .subscribe(result => {
              this.suggestions =
                (result instanceof Object &&
                  result['suggestions'] instanceof Array &&
                  result['suggestions'].length &&
                  result['suggestions'][0].suggestions instanceof Array &&
                  result['suggestions'][0].suggestions) ||
                [];
              this.arr = [];
              result.suggestions[0].suggestions.forEach(item => {
                this.arr.push({
                  name: item,
                  selected: true
                });
                this.suggestion = this.arr;
                this.temp = this.arr;
              });
            });
        }
      });
  }

  public isFilterActive(): boolean {
    return this.text !== null && this.text !== undefined && this.text !== '';
  }

  public doesFilterPass(params: IDoesFilterPassParams): boolean {
    return this.text
      .toLowerCase()
      .split(' ')
      .every(filterWord => {
        return (
          this.valueGetter(params.node)
            .toString()
            .toLowerCase()
            .indexOf(filterWord) >= 0
        );
      });
  }

  public getModel(): any {
    return { value: this.text };
  }

  public setModel(model: any): void {
    this.text = model ? model.value : '';
  }

  public ngAfterViewInit(params: IAfterGuiAttachedParams): void {
    setTimeout(() => {
      this.input.element.nativeElement.focus();
    });
  }

  public onChange(newValue): void {
    this.text = newValue;
    if (newValue.length) {
      this.searchedSuggestion = true;
    } else {
      this.searchedSuggestion = false;
      this.temp = JSON.parse(JSON.stringify(this.previousTempArray));
    }
    this.selectedAll = true;
    this.getSuggestionList(newValue);
  }

  public getSuggestionList(searchTerm: string) {
    if (searchTerm.length && this.filterApplied) {
      this.appendFilterDisable = false;
      this.selectedAll = true;
    } else {
      this.appendFilterDisable = true;
    }
    const searchPayload = {
      query: {
        key: searchTerm,
        columns: [this.fieldName.toLowerCase()]
      },
      filters: [],
      groupBy: '',
      count: 500
    };
    const columnObj = {};
    if (
      this.appliedColumnFilters !== null &&
      this.appliedColumnFilters !== undefined
    ) {
      columnObj['columnFilters'] = this.appliedColumnFilters;
      searchPayload['filters'].push(columnObj);
    }
    this.rfqListSearchService
      .getSearchScopeResults(searchPayload,this.createGrid)
      .subscribe(result => {
        this.suggestions =
          (result instanceof Object &&
            result['suggestions'] instanceof Array &&
            result['suggestions'].length &&
            result['suggestions'][0].suggestions instanceof Array &&
            result['suggestions'][0].suggestions) ||
          [];
        this.temp = [];
        result.suggestions[0].suggestions.forEach(item => {
          // if item not exists in arr
          // prepare obj & place values in it
          // if it exists in arr - push existing obj to temp
          // it it not exists in arr - push obj to arr & temp
          const existsInArr = this.arr.filter(i => {
            return i.name === item;
          });
          if (existsInArr instanceof Array && existsInArr.length > 0) {
            this.temp.push(existsInArr[0]);
          } else {
            const obj = {
              name: item,
              selected: false
            };
            this.arr.push(obj);
            this.temp.push(obj);
          }
        });
        this.check();
        for (let i = 0; i < this.temp.length; i++) {
          if (this.temp[i].selected === false) {
            this.selectedAll = false;
          }
        }
      });
    this.checkedArray = [];
  }

  public selectAll() {
    const lengthofSuggestion = this.temp.length;
    for (let i = 0; i < lengthofSuggestion; i++) {
      this.temp[i].selected = this.selectedAll;
    }
    this.updateButtonDisability();
  }

  public onCheckBoxClick(suggest) {
    this.checkboxSuggestionSelected = true;
    if (this.searchedSuggestion && !suggest.selected) {
      this.tempHasUncheckedOption = suggest.selected;
    }
    if (!this.tempHasUncheckedOption && this.checkboxSuggestionSelected) {
      this.selectedAll = this.tempHasUncheckedOption;
    }
    this.check();
  }

  public check() {
    if (this.searchedSuggestion && this.selectedAll) {
      this.previousTempArray = JSON.parse(JSON.stringify(this.temp));
      if (this.selectedAll && !this.tempHasUncheckedOption) {
        this.temp.forEach(item => {
          item.selected = true;
        });
        this.selectedAll = this.temp.every(function(item: any) {
          return item.selected === true;
        });
      }
      if (!this.tempHasUncheckedOption && this.checkboxSuggestionSelected) {
        this.selectedAll = this.tempHasUncheckedOption;
      }
      //   this.searchedSuggestion = false;
    } else {
      this.selectedAll = this.temp.every(function(item: any) {
        return item.selected === true;
      });
    }
    this.updateButtonDisability();
  }

  public updateButtonDisability() {
    this.appplyDisable = true;
    if (this.arr instanceof Array && this.arr.length > 0) {
      for (let i = 0; i < this.arr.length; i++) {
        if (this.arr[i]['selected'] === true) {
          this.appplyDisable = false;
        }
      }
    }
    if (this.appplyDisable) {
      document.getElementById('demo').style.cursor = 'not-allowed';
      document.getElementById('demo').style.opacity = '0.65';
    } else {
      document.getElementById('demo').style.cursor = 'pointer';
      document.getElementById('demo').style.opacity = 'unset';
    }
  }

  public clickApplyFunction(isClearFilterApplied?: boolean) {
    if (this.searchedSuggestion) {
      if (this.appendSelected) {
        this.temp = this.temp.concat(this.previousAppendedArray);
        this.temp = this.temp.filter((data, index) => {
          return (
            index ===
            this.temp.findIndex(obj => {
              return JSON.stringify(obj) === JSON.stringify(data);
            })
          );
        });
        this.checkedArray = [];
        for (let i = 0; i < this.temp.length; i++) {
          if (this.temp[i]['selected'] === true) {
            this.checkedArray.push(this.temp[i]);
          }
        }
      } else {
        this.temp = this.temp.filter((data, index) => {
          return (
            index ===
            this.temp.findIndex(obj => {
              return JSON.stringify(obj) === JSON.stringify(data);
            })
          );
        });

        this.checkedArray = [];
        for (let i = 0; i < this.temp.length; i++) {
          if (this.temp[i]['selected'] === true) {
            this.checkedArray.push(this.temp[i]);
          }
        }
      }
    } else {
      if (!this.filterApplied) {
        this.checkedArray = [];
        for (let i = 0; i < this.arr.length; i++) {
          if (this.arr[i]['selected'] === true) {
            this.checkedArray.push(this.arr[i]);
          }
        }
      } else {
        this.arr = this.temp;
        this.checkedArray = [];
        for (let i = 0; i < this.arr.length; i++) {
          if (this.arr[i]['selected'] === true) {
            this.checkedArray.push(this.arr[i]);
          }
        }
      }
    }
    if (this.searchedSuggestion || this.checkboxSuggestionSelected) {
      const obj = {
        headerName: this.headerName,
        columnDataType: this.columnDataType,
        columnName: this.fieldName,
        filters: this.checkedArray
      };
      this.pushFilterColumn(obj);
    }
    this.tabNameSubcription = this.dataStorageService
      .getSelectedTabInCreateFlow()
      .subscribe(tabName => {
        if (tabName === 'PRODUCT') {
          if (
            this.params.colDef.field === 'PRODUCT' ||
            this.params.colDef.field === 'PRODUCT_DESCRIPTION' ||
            this.params.colDef.field === 'CM_ODM'
          ) {
            this.dataStorageService.setProductPartialMatchFilter(true);
          }
        }
      });
    if (
      !this.searchedSuggestion &&
      this.checkedArray instanceof Array &&
      this.suggestions instanceof Array &&
      this.checkedArray.length === this.suggestions.length
    ) {
      delete this.srfqService.partialMatchFilterColumnData[this.headerName];
      this.dataStorageService.setPartialMatchFilter([{
        headerName: this.headerName,
        columnDataType: this.columnDataType,
        columnName: this.fieldName,
        filters: 'No Filter'
      }]);
    } else {
      if(this.searchedSuggestion && isClearFilterApplied){
        delete this.srfqService.partialMatchFilterColumnData[this.headerName];
      this.dataStorageService.setPartialMatchFilter([{
        headerName: this.headerName,
        columnDataType: this.columnDataType,
        columnName: this.fieldName,
        filters: 'No Filter'
      }]);
      }else{
        this.dataStorageService.setPartialMatchFilter({
          headerName: this.headerName,
          columnDataType: this.columnDataType,
          columnName: this.fieldName,
          filters: this.checkedArray
        });
      }
    }
    this.params.filterChangedCallback();
  }

  public appendFilter() {
    this.appendSelected = true;
    this.previousAppendedArray = [];
    if (this.appendSelected) {
      this.dataStorageService.getPartialMatchFilter().subscribe(val => {
        if (val) {
          if (val.headerName === this.headerName) {
            this.previousAppendedArray = val.filters;
          }
        }
      });
    }
  }

  public pushFilterColumn(obj) {
    // To push Column names into array to check whether filter is applied on that column or not
    this.srfqService.partialMatchFilterColumnData[this.headerName] = obj;
  }

  public clearFilters() {
    // this.searchedSuggestion = false;
    // this.checkboxSuggestionSelected = false;
    for (let i = 0; i < this.arr.length; i++) {
      this.arr[i]['selected'] = true;
    }
    this.clickApplyFunction(true);
  }
  public afterGuiAttached(params) {
    //  To hide toggle of filter
    this.test = params.hidePopup;
  }
  public cancelPopup() {
    if(this.searchedSuggestion){
      this.text = '';
      this.onChange(this.text)
    }
    this.test();
  }

  public ngOnDestroy() {
    if (this.getFiltersWhenApplySubcription) {
      this.getFiltersWhenApplySubcription.unsubscribe();
    }
    if (this.tabNameSubcription) {
      this.tabNameSubcription.unsubscribe();
    }
  }
}
