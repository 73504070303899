export class ColorUtils {
  private static colorCounter = 0
  public static colors = [
    '#29ABE2',
    '#FFD066',
    '#50Ad64',
    '#E85885',
    '#2BCAD9',
    '#E64560',
    '#7373E6',
    '#FF8652',
    '#008dc9',
    '#e6b545',
    '#ff6929',
    '#07a2b0',
    '#18b6c4',
    '#2bcad9',
    '#db4877',
    '#3a994f',
    '#f2c355',
    '#119bd6',
  ];
  public static getRandomColor() {
    if (this.colorCounter < 19) {
      return this.colors[this.colorCounter++]
    }
    return this.hslToHex(Math.floor(Math.random() * 360), Math.floor(Math.random() * 100), Math.floor(50 + Math.random() * 20))
  }

  public static hslToHex(hue, saturation, lightness) {
    hue /= 360
    saturation /= 100
    lightness /= 100
    let r, g, b
    if (saturation === 0) {
      r = g = b = lightness
    } else {
      const q = lightness < 0.5 ? lightness * (1 + saturation) : lightness + saturation - lightness * saturation
      const p = 2 * lightness - q
      r = this.hue2rgb(p, q, hue + 1 / 3)
      g = this.hue2rgb(p, q, hue)
      b = this.hue2rgb(p, q, hue - 1 / 3)
    }
    return `#${this.toHex(r)}${this.toHex(g)}${this.toHex(b)}`
  }

  public static toHex(x) {
    const hex = Math.round(x * 255).toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }

  public static hue2rgb(p, q, t) {
    if (t < 0) {
      t += 1
    }
    if (t > 1) {
      t -= 1
    }
    if (t < 1 / 6) {
      return p + (q - p) * 6 * t
    }
    if (t < 1 / 2) {
      return q
    }
    if (t < 2 / 3) {
      return p + (q - p) * (2 / 3 - t) * 6
    }
    return p
  }

  public static resetColorCounter() {
    this.colorCounter = 0;
  }
}
