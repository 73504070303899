import { Injectable, Optional } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TimeSelectionDataService {
  constructor(private http?: HttpClient) {}

  clear = new BehaviorSubject<any>(undefined);
  option = new BehaviorSubject<any>(undefined);
  view = new BehaviorSubject<any>(undefined);
  timePeriodJSON: any = undefined;
  timePeriodJSONKeys: Array<any> = [];
  timePeriodKeys: Array<any> = [];
  update: any = new BehaviorSubject<any>(false);
  timeSelection = new BehaviorSubject<any>(undefined);
  months = [];

  // Time Change Function

  timePeriodYearsObject = new BehaviorSubject<any>(undefined);

  localTimePeriodYearsObject: object = {};
  checkIndexObject: object = {};
  selectedIndexArray: Array<any> = [];
  quarterCount = 0;
  monthCount = 0;

  title = new BehaviorSubject<any>(undefined);

  botYear: number;
  setFullTimePeriodObject(obj) {
    this.timePeriodJSON = obj;
    this.timePeriodJSONKeys = Object.keys(this.timePeriodJSON);
    this.timePeriodKeys = Object.keys(this.timePeriodJSON.timePeriod);
    this.createTimePeriodObject();
    this.update.next(true);
  }

  public getTimeSelectionData() {
  }

  getCalenderDifference()
  {
    if(this.timePeriodJSON)
    {
      return this.timePeriodJSON.calenderDiff;
    }
  }

  getFullTimePeriodObject() {
    return this.timePeriodJSON;
  }
  setView(val) {
    this.view.next(val);
  }
  getView() {
    return this.view.asObservable();
  }

  getDataCheck() {
    return this.update.asObservable();
  }
  setClearAll(val) {
    this.selectedIndexArray = [];
    this.clear.next(val);
  }
  getClearAll() {
    return this.clear.asObservable();
  }
  setOptionSelected(val) {
    this.option.next(val);
  }
  getOptionSelected() {
    return this.option.asObservable();
  }
  hideTimeSelection(status) {
    // true || false
    this.timeSelection.next(status);
  }
  getTimeSelectionState() {
    // Return true || false
    return this.timeSelection.asObservable();
  }
  getTimePeriod() {
    if (this.timePeriodJSON.timePeriod !== undefined) {
      return this.timePeriodJSON.timePeriod;
    } else {
      return undefined;
    }
  }
  getCurrentYear() {
    // Return Current Year 2018 || 2019 || etc
    if (this.timePeriodJSON !== undefined) {
      return this.timePeriodJSON.currentYear.year !== undefined
        ? this.timePeriodJSON.currentYear.year
        : undefined;
    }
  }
  public getCurrentQuarter() {
    if (this.timePeriodJSON !== undefined) {
      return this.timePeriodJSON.currentQuarter !== undefined
        ? this.timePeriodJSON.currentQuarter
        : undefined;
    }
  }

  getQuartosByYear(year: number) {
    // Return Quarto list based on year input
    if (year !== undefined) {
      return this.timePeriodJSON.timePeriod[year][0] !== undefined
        ? this.timePeriodJSON.timePeriod[year][0]
        : undefined;
    }
  }
  getmonthsbyquarter(year, quarter) {
    if (year !== undefined) {
      try {
        if (
          this.timePeriodJSON.timePeriod[year][0][quarter] !== undefined &&
          this.timePeriodJSON.timePeriod[year][0][quarter] !== null
        ) {
          return this.timePeriodJSON.timePeriod[year][0][quarter].months !==
            undefined
            ? this.timePeriodJSON.timePeriod[year][0][quarter]
            : undefined;
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
  getOptions() {
    if (this.timePeriodJSON !== undefined) {
      return this.timePeriodJSON.quickOptions;
    }
  }
  private formatQuartersByYear(year) {
    const quarterObject: object = {};
    for (const quarter of Object.keys(this.getQuartosByYear(year))) {
      if (
        this.getQuartosByYear(year)[quarter] !== undefined &&
        this.getQuartosByYear(year)[quarter] !== null
      ) {
        quarterObject[quarter] = {
          title: quarter,
          isSelect: false
        };
        this.checkIndexObject[year + quarter] = this.quarterCount;
        this.quarterCount++;
      } else {
        quarterObject[quarter] = {
          title: quarter,
          isDisable: true
        };
      }
    }
    return quarterObject;
  }
  private formatMonthsByQuarter(year) {
    const monthObject: object = {};
    for (const quarter of Object.keys(this.getQuartosByYear(year))) {
      if (this.getmonthsbyquarter(year, quarter) !== undefined) {
        for (const i of Object.keys(
          this.getmonthsbyquarter(year, quarter).months
        )) {
          const obj = this.getmonthsbyquarter(year, quarter).months[i];
          const key = obj.title;

          if (
            this.getmonthsbyquarter(year, quarter).months[i].title !== undefined
          ) {
            monthObject[key] = {
              title: key,
              quarter,
              isSelect: false,
              fiscalYear: obj.date.split('-')[0].slice(-2)
            };
          } else {
            monthObject[key] = {
              title: obj.title,
              quarter,
              isDisable: true,
              fiscalYear: obj.date.split('-')[0].slice(-2)
            };
          }
        }
      } else {
        const yr=+year + 1;
        if (
          Object.keys(this.timePeriodJSON.timePeriod).includes(yr.toString())
        ) {
          if (this.getmonthsbyquarter(+year + 1, quarter) !== undefined) {
            for (const i of Object.keys(
              this.getmonthsbyquarter(+year + 1, quarter).months
            )) {
              const obj = this.getmonthsbyquarter(+year + 1, quarter).months[i];
              const key = obj.title;
              this.months.push(key);
              monthObject[key] = {
                title: obj.title,
                quarter,
                isDisable: true,
                fiscalYear: obj.date.split('-')[0].slice(-2)
              };
            }
          }
        }
      }
    }
    return monthObject;
  }

  createTimePeriodObject() {
    if (this.timePeriodJSON !== undefined) {
      if (this.timePeriodJSON.timePeriod !== undefined) {
        for (const year of Object.keys(this.timePeriodJSON.timePeriod)) {
          if (year === this.getCurrentYear()) {
            this.localTimePeriodYearsObject[year] = {
              year: {
                year,
                isSelect: true
              },
              quarter: this.formatQuartersByYear(year),
              month: this.formatMonthsByQuarter(year)
            };
          } else {
            this.localTimePeriodYearsObject[year] = {
              year: {
                year,
                isSelect: false
              },
              quarter: this.formatQuartersByYear(year),
              month: this.formatMonthsByQuarter(year)
            };
          }
        }
        this.timePeriodYearsObject.next(this.localTimePeriodYearsObject);
      }
    }
  }

  getLocalTimePeriodYearObject() {
    return this.localTimePeriodYearsObject;
  }

  getTimePeriodObject() {
    return this.timePeriodYearsObject.asObservable();
  }

  getQuarterStartDate(filterInput) {
    /* {
			"year": year,
			"quarter": quarter
        }*/
    const timePeriod = this.getTimePeriodObject();
    const fyYear = filterInput.year;
    const quarter = filterInput.quarter;
    if (this.timePeriodJSON.timePeriod[fyYear] !== undefined) {
      return this.timePeriodJSON.timePeriod[fyYear][0][quarter] !== undefined
        ? this.timePeriodJSON.timePeriod[fyYear][0][quarter]
        : [];
    } else {
      return [];
    }
  }

  checkMonths(year) {
    let yearLevelTitle: string;
    const yearArray: Array<any> = Object.keys(this.localTimePeriodYearsObject);
    const yearTitle= year;
    const titleArray: Array<any> = [];
    const monthArray: Array<any> = Object.keys(
      this.localTimePeriodYearsObject[year].month
    );
    const monthObject: any = this.localTimePeriodYearsObject[year].month;
    const monthTitle: Array<any> = [];
    let monthCount = 0;

    for (const month of monthArray) {
      if (monthObject[month].isSelect !== undefined) {
        if (monthObject[month].isSelect === true) {
          this.selectedIndexArray.push(this.checkIndexObject[year + month]);
          monthCount++;
          //          monthTitle.push(monthObject[month].title);
          monthTitle.push(
            monthObject[month].title + monthObject[month].fiscalYear
          );
        }
      }
    }
    if (monthCount === 12) {
      const staticQuarterTitle = year;
      titleArray.push(staticQuarterTitle);
    } else {
      if (monthTitle.length > 0) {
        yearLevelTitle = yearTitle + '[' + monthTitle.join(',') + ']';
      } else {
        yearLevelTitle = yearTitle;
      }
      titleArray.push(yearLevelTitle);
    }

    return titleArray;
  }

  checkQuarters(year) {
    let yearLevelTitle: string;
    const yearArray: Array<any> = Object.keys(this.localTimePeriodYearsObject);
    const yearTitle= year;
    const titleArray: Array<any> = [];
    const quarterArray: Array<any> = Object.keys(
      this.localTimePeriodYearsObject[year].quarter
    );
    const quarterObject = this.localTimePeriodYearsObject[year].quarter;
    const quarterTitle: Array<any> = [];
    let quarterCount = 0;
    for (const quarter of quarterArray) {
      if (quarterObject[quarter].isSelect !== undefined) {
        if (quarterObject[quarter].isSelect === true) {
          this.selectedIndexArray.push(this.checkIndexObject[year + quarter]);
          quarterCount++;
          quarterTitle.push(quarterObject[quarter].title);
        }
      }
    }
    if (quarterCount === 4) {
      const staticQuarterTitle = year;
      titleArray.push(staticQuarterTitle);
    } else {
      // quarterTitle.push(yearTitle);
      if (quarterTitle.length > 0) {
        yearLevelTitle = yearTitle + '[' + quarterTitle.join(',') + ']';
      } else {
        yearLevelTitle = yearTitle;
      }

      titleArray.push(yearLevelTitle);
    }

    return titleArray;
  }

  checkRange() {
    let checkRange = false;
    let currentSelectionIndex=this.selectedIndexArray[0];
    const selectedIndexArrayLength = this.selectedIndexArray.length;
    let checkCount = 0;
    for (const index of this.selectedIndexArray) {
      if (index === currentSelectionIndex) {
        checkCount++;
        currentSelectionIndex++;
      }
    }

    if (checkCount === selectedIndexArrayLength) {
      checkRange = true;
    }
    return checkRange;
  }

  findKey(target: number) {
    if (target !== undefined) {
      return Object.keys(this.checkIndexObject)[target] !== undefined
        ? Object.keys(this.checkIndexObject)[target]
        : undefined;
    }
  }

  generateRangeTitle() {
    let startValue = '';
    let endValue = '';
    startValue = this.findKey(this.selectedIndexArray[0]);
    endValue = this.findKey(
      this.selectedIndexArray[this.selectedIndexArray.length - 1]
    );

    return startValue + ' to ' + endValue;
  }

  setDefaultTitle(title) {
    this.title.next(title);
  }

  updateTitle(target?: string) {
    this.selectedIndexArray = [];
    const titleArray: Array<any> = [];
    const yearArray: Array<any> =
      Object.keys(this.localTimePeriodYearsObject) !== undefined
        ? Object.keys(this.localTimePeriodYearsObject)
        : [];
    let currentYear: number;
    if (yearArray instanceof Array) {
      for (const year of yearArray) {
        if (this.localTimePeriodYearsObject[year].year.isSelect === true) {
          if (target === 'month') {
            titleArray.push(this.checkMonths(year));
          } else {
            titleArray.push(this.checkQuarters(year));
          }
        }
      }
      this.title.next(titleArray.join(','));
    }
  }

  getTitle() {
    return this.title.asObservable();
  }

  clearLocalTimePeriodYearsObject() {
    for (const year of Object.keys(this.localTimePeriodYearsObject)) {
      this.localTimePeriodYearsObject[year].year.isSelect = false;
      for (const index of Object.keys(
        this.localTimePeriodYearsObject[year].quarter
      )) {
        this.localTimePeriodYearsObject[year].quarter[index].isSelect = false;
      }
      for (const i of Object.keys(
        this.localTimePeriodYearsObject[year].month
      )) {
        this.localTimePeriodYearsObject[year].month[i].isSelect = false;
      }
    }
  }
  updateYearThroughChatBot(yearArray) {
    try{
        if (yearArray.length > 0) {
      this.clearLocalTimePeriodYearsObject();
      for (const year of yearArray) {
        this.botYear = year;
        if (this.localTimePeriodYearsObject[year.year] !== undefined) {
          this.localTimePeriodYearsObject[year.year].year.isSelect = true;
          if (year.quarter !== undefined) {
            if (
              this.localTimePeriodYearsObject[year.year].quarter instanceof Object && this.localTimePeriodYearsObject[year.year].quarter.hasOwnProperty([year.quarter])
            ) {
              this.localTimePeriodYearsObject[year.year].quarter[
                year.quarter
              ].isSelect = true;
            }
          }
        }
      }
      this.timePeriodYearsObject.next(this.localTimePeriodYearsObject);
    }
  }
  catch(e){
    console.log('error in updating year through chatbot',e);
  }
  }

  updateQuarterThroughChatBot(arr) {
    this.clearLocalTimePeriodYearsObject();
    for (const obj of arr) {
      const year = obj.year;
      const quarter = obj.quarter;
      if (this.localTimePeriodYearsObject[year] !== undefined) {
        this.localTimePeriodYearsObject[year].year.isSelect = true;
        this.localTimePeriodYearsObject[year].quarter[quarter].isSelect = true;
        this.timePeriodYearsObject.next(this.localTimePeriodYearsObject);
      }
    }
  }

  getBotUpdatedYear() {
    return this.botYear;
  }

  onCheckTimeSelectionDataKey(timePeriodKey) {
    if (timePeriodKey.length === 2) {
      timePeriodKey = '20' + timePeriodKey;
    }
    const key = `${timePeriodKey}`;
    if (this.timePeriodJSONKeys.indexOf(key) !== -1) {
      switch (key.toLocaleLowerCase()) {
        case 'restofyear':
          const restOfYearObject: any = this.timePeriodJSON[key];
          const restofYear: Array<any> = [];
          let year: number;
          let quarterArray: Array<any> = [];
          if (restOfYearObject !== undefined) {
            year = restOfYearObject.year || undefined;
            quarterArray = restOfYearObject.quarter[0].split(',') || undefined;
          }
          if (year !== undefined && quarterArray !== undefined) {
            for (const quarter of quarterArray) {
              restofYear.push({
                year,
                quarter
              });
            }
          }

          return restofYear;
        case 'next4quarters':
          const next4quartersArray: Array<any> = this.timePeriodJSON[key];
          const next4Quarter: Array<any> = [];
          for (const year of Object.keys(next4quartersArray)) {
            for (const quarter of next4quartersArray[year]) {
              next4Quarter.push({
                year: parseInt(year),
                quarter: 'Q' + quarter
              });
            }
          }
          return next4Quarter;
        default:
          return this.timePeriodJSON[key];
      }
    } else if (this.timePeriodKeys.indexOf(key) !== -1) {
      const returnObj = {
        year: key,
        title: key
      };
      return returnObj;
    } else {
      return undefined;
    }
  }
}
