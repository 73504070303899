export const HEADER_OBJECT = {
    ICON: 'icon',
    UID: 'UID',
    CPN: 'CPN',
    MPN: 'MPN',
    STATUS: 'STATUS_ID',
    CPN_CM_ID: 'CPN_CM_ID',
    SUP_VISIBLE: 'supVisible',
    SUP_EDIT: 'supEdit',
    RFQ_SUP_NAME: 'rfq_sup_name',
    STRING_MODIFIER: 'stringModifier',
    MANDATORY_IDENTIFIER: ' * ',
    MANUFACTURER: 'MANUFACTURER',
    CPN_DESC: 'CPN_DESC',
    GLOBAL_PRICE: 'GLOBAL_PRICE',
    CM_ODM: 'CM_ODM',
    COMPONENT_USAGE: 'COMPONENT_USAGE',
    SUPPLIER: 'SUPPLIER',
    ALL: 'ALL',
    FIELD: 'field',
    VALUE: 'value',
    IS_FIRST: 'isFirst',
    DISP_VALUE: 'dispValue',
    NO_VALUE: '',
    IS_ADHOC_ITEM: 'IS_ADHOC_ITEM',
    _ERROR: '_ERROR',
    _VALID: '_VALID',
    _MODIFIED: '_MODIFIED',
    DISPLAY_MODIFIER: 'displayModifier',
    ADHOC_VISIBLE: 'adhocVisible',
    ADHOC_CONFIG: 'adhocConfig',
    DISPLAY_NAME: 'displayName',
    DB_COLUMN_NAME: 'dbColumnName',
    IS_EDITABLE: 'isEditable',
    CELL_HTML_TYPE: 'cellHtmlType',
    CELL_RENDERER: 'cellRenderer',
    CELL_RENDERER_FRAMEWORK: 'cellRendererFramework',
    CELL_EDITOR_FRAMEWORK: 'cellEditorFramework',
    HEADER_EDITABLE: 'editable',
    CELL_STYLE: 'cellStyle',
    DATA_TYPE: 'datatype',
    SINGLESELECT: 'singleselect',
    MULTISELECT: 'multiselect',
    CMSPKID: 'cmspkId',
    ACCEPT: 'Accept',
    REJECT: 'Reject',
    TEMPLATE_HEADER_NAME: 'tmplHeaderName',
    TEMPLATE_DB_COLUMN: 'tmplHeaderDbCol',
    PRODUCT: 'product',
    BOM: 'bom',
    TRANSFORMATION: 'transformation',
    REQUEST_TYPE: 'productRFQ',
    UPPER_BOM: 'BOM',
    UPPER_PRODUCT: 'PRODUCT',
    UPPER_TRANSFORMATION: 'TRANSFORMATION',
    CONTEXT: 'PREVIEW',
    VALIDATIONS: 'validations',
    VENDOR_PREVIEW_CONTEXT: 'VENDOR_VIEW',
    CUST_VIEW: 'CM_ODM',
    CM_ODM_CONTROL_COLUMN: 'CM_ODM_CONTROL',
    PRODUCT_PREVIEW: 'PRODUCT_PREVIEW',
    PREVIEW_TABS: [
        {
            title: 'Options',
            value: 'Options'
        }, {
            title: 'Stats',
            value: 'Stats'
        }, {
            title: 'Preview',
            value: 'Preview'
        },
        {
            title: 'Message',
            value: 'Message'
        },
    ],
    TAB_NAMES: {
        options: 'Options',
        stats: 'Stats',
        preview: 'Preview',
        message: 'Message'
    }
};
export const STATUS_OBJECT = {
    NEW: 1,
    VIEWED: 2,
    QUOTED: 3,
    NO_BID: 4,
    ACCEPTED: 5,
    REJECTED: 6,
    REVOKED: 7,
    AWARDED: 9,
    REQUOTED: 10,
    PARTIALLY_QUOTED: 11,
    DELETED: 12
};
export const TEMPLATE_TYPES = ['PRODUCT', 'BOM', 'TRANSFORMATION'];
export const BACKGROUND_COLOR_OBJECT = {
    editableBackground: {
        'background-color': '#F2F3F4'
    },
    modifiedBackground: {
        'background-color': '#F2F3F4',
        border: '2px solid #64CCEF !important'
    },
    errorBackground: {
        border: '2px solid #E64560 !important'
    },
    errorWithEditable: {
        'background-color': '#F2F3F4',
        border: '2px solid #E64560 !important'
    }
}
