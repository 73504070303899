import { Injectable } from '@angular/core';

import { ApiServiceProvider } from 'app/api-service';
import { BaseService } from 'app/modules/common/base.service';
import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService';
import { environment } from '../../../environments/environment';
import { DataStorageService } from './data-storage.service';
import { DateUtils } from './utills/DateUtils.service';
import {SharedService} from '../shared/services/shared.service';
import {CUSTOMER_SESSION_EXPIRED, CUSTOMER_TOKEN_EXPIRY, CUSTOMER_TOKEN_INFO} from '../shared/services/shared-consts';
@Injectable()
export class ConfigService {
  public envDetails: any;
  public targetType: any;

  constructor(
    private apiServiceProvider: ApiServiceProvider,
    private baseService: BaseService,
    private customerConfigurationService: CustomerConfigurationService,
    private dataStorageService: DataStorageService,
    private sharedService: SharedService
  ) {}

  public getdetails() {
    this.envDetails = this.customerConfigurationService.getEnvironmentDetails();
    const object: any = {
      targetedType: 'CustomerConfigService',
      formParams: {
        tenantId: this.envDetails.tenantId
      },
      method: 'GET',
      servicePath: '/getEnvironmentConfiguration'
    };
    return this.apiServiceProvider.post(object);
  }
  public getUserConfiguration(tenantId, userId) {
    const object: any = {
      targetedType: 'UserService',
      formParams: {
        tenantId,
        userId
      },
      method: 'GET',
      servicePath: '/getUserConfiguration'
    };
    return this.apiServiceProvider.post(object);
  }
  public validate2FaCode(email,id,code){
    const data: any = {
      headerParams: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      formParams:  {
        user_email: email,
        user_id: id,
        code
      }
    };
    return this.apiServiceProvider.directPost(
      environment.authenticationServiceApiPath + '/auth/v2/verify/otp',
      null,
      data.formParams,
      data.headerParams
    );
  }
  public getAuthenticationToken(
    grantType,
    isSupplier,
    userEmail,
    password,
    refreshtoken,
    code?,
    sessionState?,
    state?
  ) {
    const data = {
      headerParams: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      formParams: {
        grant_type: grantType,
        is_supplier: isSupplier,
        username: userEmail,
        password: encodeURIComponent(password),
        refresh_token: refreshtoken,
        code,
        session_state: sessionState,
        state
      }
    };
    return this.apiServiceProvider.directPost(
      environment.authenticationServiceApiPath + '/auth/v2/token',
      null,
      data.formParams,
      data.headerParams
    );
  }
  public caluculateTokenExpiryTime() {
    const tokenRefreshedTime = DateUtils.getEpochTime(
      DateUtils.getDate(null, true)
    );
    const tokenExpiresIn = JSON.parse(
      localStorage.getItem(CUSTOMER_TOKEN_INFO)
    ).expires_in;
    const tokenLength = tokenExpiresIn.toString().length;
    const d = tokenExpiresIn - Math.pow(10, tokenLength - 2);
    const tokenExpiryTime = tokenRefreshedTime + d;
    this.sharedService.setLocalStorageData(
      CUSTOMER_TOKEN_EXPIRY,
      tokenExpiryTime
    );
  }
  public checkRefreshToken() {
    let result = false;
    if (
      JSON.parse(localStorage.getItem(CUSTOMER_TOKEN_INFO)) &&
      JSON.parse(localStorage.getItem(CUSTOMER_TOKEN_EXPIRY)) &&
      DateUtils.getEpochTime(DateUtils.getDate(null, true)) >
        JSON.parse(localStorage.getItem(CUSTOMER_TOKEN_EXPIRY))
    ) {
      result = true;
    }
    return result;
  }
  public async updateAccessTokenInfo() {
    if (this.checkRefreshToken()) {
      this.baseService.isRefreshingToken = true;
      this.dataStorageService.tokenSubject.next(null);
      const response = await this.getAuthenticationToken(
        'refresh_token',
        false,
        null,
        null,
        JSON.parse(localStorage.getItem(CUSTOMER_TOKEN_INFO)).refresh_token
      ).toPromise();
      this.caluculateTokenExpiryTime();
      this.sharedService.setLocalStorageData(CUSTOMER_TOKEN_INFO, response);
      this.baseService.isRefreshingToken = false;
      this.dataStorageService.tokenSubject.next(response);
    } else {
      if (
        !(
          JSON.parse(localStorage.getItem(CUSTOMER_TOKEN_INFO)) instanceof
          Object
        )
      ) {
        console.log("monitoring the session expired pop up issue, 10")
        this.baseService.setUnAuthrizePopup(true);
        this.sharedService.setLocalStorageData(CUSTOMER_SESSION_EXPIRED, true);
      }
      await Promise.resolve(10);
    }
  }
  public getUserInfo() {
    const data = {
      targetedType: 'TokenService',
      headerParams: {
        'Content-Type': 'application/json'
      },
      formParams: {
        is_supplier: false
      },
      method: 'GET',
      servicePath: '/auth/v2/userinfo'
    };
    return this.apiServiceProvider.post(data);
  }
}
