export const countryListConfiguration = {
    label: 'Country',
    type: 'newautosearch',
    filterConfig: {
        multiSelect: false,
        targetedType: 'riskManagementService',
        servicePath: 'risk/search/country',
        defaultItemSelection: false,
        defaultSelectedItem: null,
    },
    showFixedDropdown: true,
    hideLabel: true,
    val: 'country',
    data: [],
    id: `country-auto-select`,
    defaultParameter: null,
    isDisable: false
}
export const stateListConfiguration = {
    label: 'State',
    type: 'newautosearch',
    filterConfig: {
        multiSelect: false,
        targetedType: 'riskManagementService',
        servicePath: 'risk/search/state',
        defaultItemSelection: false,
        defaultSelectedItem: null,
    },
    showFixedDropdown: true,
    hideLabel: true,
    val: 'state',
    data: [],
    id: `state-auto-select`,
    defaultParameter: null,
    isDisable: true
}
export const cityListConfiguration = {
    label: 'City',
    type: 'newautosearch',
    filterConfig: {
        multiSelect: false,
        targetedType: 'riskManagementService',
        servicePath: 'risk/search/city',
        defaultItemSelection: false,
        defaultSelectedItem: null,
    },
    showFixedDropdown: true,
    hideLabel: true,
    val: 'city',
    selectAllDefault: false,
    data: [],
    id: `city-auto-select`,
    defaultParameter: null,
    isDisable: true
}
export const siteListConfiguration = {
    label: 'Sites Impacted',
    type: 'newautosearch',
    filterConfig: {
        multiSelect: true,
        targetedType: 'riskManagementService',
        servicePath: 'risk/search/sites',
        defaultItemSelection: false,
        defaultSelectedItem: null,
    },
    showFixedDropdown: true,
    hideLabel: true,
    val: 'siteImpacted',
    showCustomToolTip: true,
    showSelectAll: true,
    selectAllDefault: false,
    data: [],
    id: `site-auto-select`,
    defaultParameter: null,
    isDisable: true
}

export const typeListConfiguration = {
    label: 'Event Type',
    type: 'static',
    name: 'type',
    hideLabel: true,
    data: [],
    id: 'eventTypeListId',
    resetValues: generateRandomKey(),
    defaultItemSelection: null
}
export const statusListConfiguration = {
    label: 'Event Status',
    type: 'static',
    hideLabel: true,
    name: 'activeState',
    data: [],
    id: 'eventStatusListId',
    resetValues: generateRandomKey(),
    defaultItemSelection: null
}
export const severityListConfiguration = {
    label: 'Severity',
    type: 'static',
    hideLabel: true,
    name: 'seveirty',
    data: [...Array(11)].map((_, i) => {
        return { val: i + '', displayName: i + '' };
      }),
    id: 'severityListId',
    resetValues: generateRandomKey(),
    defaultItemSelection: true
}

function generateRandomKey() {
    const crypto = window.crypto
    const array = new Uint32Array(1)
    return crypto.getRandomValues(array)[0]
}

