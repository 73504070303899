import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';

@Component({
  moduleId: module.id,
  selector: 'child-cell',
  templateUrl: './grid-static-list.component.html',
  styleUrls: ['./grid-static-list.component.sass'],
})
export class GridStaticListComponent
  implements ICellEditorAngularComp, AfterViewInit {
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  params: any;
  value = null;
  selectedItem = null;
  displayValue = '';
  valueMap = null;
  dropdownItems = [];
  cancelBeforeStart = false;
  @ViewChild('container', { read: ViewContainerRef, static: true })
  private container;
  agInit(params: any): void {
    this.params = params;
    this.value = params.value || null;
    if (params.hasOwnProperty('customCondition')) {
      const customConditionColumn = params.customCondition.column;
      const customConditionColumnValue = params.customCondition.columnValue;
      if (params.data[customConditionColumn] === customConditionColumnValue) {
        this.dropdownItems = params.customCondition.list || [];
      } else {
        this.dropdownItems = params.values || [];
      }
    } else {
      this.dropdownItems = params.values || [];
    }
    this.valueMap = params.valueMap || null;

    this.displayValue = this.valueMap
      ? this.valueMap[params.value] || null
      : this.value;
  }

  getValue(): any {
    return this.value !== undefined && this.value !== null
      ? this.value
      : this.params.value;
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  // don't use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
    this.container.element.nativeElement.focus();
  }

  isPopup(): boolean {
    return true;
  }

  setValue(value: any): void {
    this.value = value;
  }

  public onSelectItem(selectedItem) {
    this.selectedItem = selectedItem;
    this.setValue(selectedItem.value);
    this.displayValue = selectedItem.displayValue;
    this.params.api.stopEditing();
  }
}
