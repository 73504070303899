import { Injectable } from '@angular/core';

export interface NewsMetaInfo {
  title: string
  brief?: string
  body: string
  severity: string
  primaryCategory: any[]
  secondaryCategory: any[]
  provider: string
  newsUrl: string
  source: string
  tag: string
  published: string
  relevance: boolean
  resourceId?: string
}

@Injectable({
  providedIn: 'root'
})
export class NewsMetaInfoService {
  private newsMetaInfo = {
    title: null,
    body: null,
    brief: null,
    severity: null,
    primaryCategory: [],
    secondaryCategory: [],
    newsUrl: null,
    provider: null,
    source: null,
    tag: null,
    published: null,
    relevance: false
  } as NewsMetaInfo
  private newsDetailsInfo = {} as NewsMetaInfo

  constructor() { }

  setTitle(value: any) {
    this.newsMetaInfo.title = value
  }
  setSentiment(value: any) {
    this.newsMetaInfo.severity = value
  }
  setPrimaryCategory(value: any[]) {
    this.newsMetaInfo.primaryCategory = value
  }
  setSecondaryCategory(value: any[]) {
    this.newsMetaInfo.secondaryCategory = value
  }
  setProvider(value: any) {
    this.newsMetaInfo.provider = value
  }
  setSource(value: any) {
    this.newsMetaInfo.source = value
  }
  setTag(value: any) {
    this.newsMetaInfo.tag = value
  }
  setPublished(value: any) {
    this.newsMetaInfo.published = value
  }
  setRelevance(value: any) {
    this.newsMetaInfo.relevance = value
  }
  setNewDetails(value: NewsMetaInfo) {
    this.newsDetailsInfo = { ...value }
  }

  getTitle() {
    return this.newsMetaInfo.title
  }
  getSentiment() {
    return this.newsMetaInfo.severity
  }
  getPrimaryCategory() {
    return this.newsMetaInfo.primaryCategory
  }
  getSecondaryCategory() {
    return this.newsMetaInfo.secondaryCategory
  }
  getProvider() {
    return this.newsMetaInfo.provider
  }
  getSource() {
    return this.newsMetaInfo.source
  }
  getTag() {
    return this.newsMetaInfo.tag
  }
  getPublished() {
    return this.newsMetaInfo.published
  }
  getRelevance() {
    return this.newsMetaInfo.relevance
  }
  getNewsMetaInfo() {
    return this.newsMetaInfo
  }
  getNewsDetailsInfo() {
    return this.newsDetailsInfo
  }
}
