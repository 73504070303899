import { EventEmitter, Injectable } from '@angular/core'
@Injectable()
export class GridEmitterService {
  paginationResult: EventEmitter<any> = new EventEmitter<any>()
  constructor() {}

  emitPaginationResult(value) {
    this.paginationResult.emit(value)
  }
  getPaginationResultEmitter() {
    return this.paginationResult
  }
}
