import { Component, OnDestroy, Input } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { MultiplePartMatchingViewService } from 'app/modules/mi/partmatching/multiple-part-matching-view/shared/multiple-part-matching-view.service';
import { ApplicationURLS } from 'app/modules/common/applicationURL';
import { SubscriptionService } from 'app/modules/common/subscription.service';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { PartMatchingService } from 'app/modules/mi/partmatching/partmatching.service';
import { SubscriptionLike } from 'rxjs';

@Component({
    selector: 'ds-link',
    template: `<button class="btn btn-primary btn-xs dataSheetView" [disabled]="hide || showhide" id="dataSheet" title="Data Sheet" (click)="helpWindow($event)" type="submit">DS</button>
    <button *ngIf="hide" class="btn btn-primary btn-xs dataSheetView" [disabled]="view" id="dataSheet" title="Where Used" (click)="onClick($event)"s  type="submit">U</button>  `,

})
export class DsComponent implements OnDestroy {
    // url:any;
    getPartMatchSubscription: SubscriptionLike;
    getWhereUsedSubscription: SubscriptionLike;
    index: any;
    hide = false;
    view = false;
    showhide = false;
    responseObject: any;
    data: Array<any> = [];
    url: Array<any> = [];
    tableData: Array<object>;
    columnDefs: any;
    mpnname: any;
    cpnname: any;
    public WhereUsedList: Array<object> = [];
    public params: any;
    constructor(
        private subscriptionService: SubscriptionService,
        private multiplePartMatchingViewService: MultiplePartMatchingViewService,
        private partMatchingService: PartMatchingService,
        private applicationURLS: ApplicationURLS,
        private dataStorageService: DataStorageService) { }
    ngOnInit() {

        this.getPartMatchSubscription = this.partMatchingService.getsinglepartMatchData().subscribe((val) => {
            if (val.lpn === this.params.data.lpn) {
                if (val.mpnCost === 0) {
                    this.view = true;
                }
                else if (val.mpnCost === 200) {
                    this.view = true;
                }
            }
        })
    }
    agInit(params: any): void {
        this.params = params;
        if (!(this.params['data']['dsUrl'].includes('https'))) {
            this.showhide = true;
        }
        if (this.params['data']['source'] === 'Customer Part') {
            if (this.params['data']['dsUrl'] === 'NA') {
                this.hide = true;
            }
            if (this.params['data']['cpn'] === 'NA' || this.params['data']['cpn'] === '-') {
                this.view = true;
            }

        }
    }
    onClick(event) {
        this.mpnname = this.params['data']['mpn']
        this.cpnname = this.params['data']['cpn']

        const targetUrlObject = this.applicationURLS.getURLObject({
            target: 'mi',
            service: 'mpmwu',
        });

        this.getWhereUsedSubscription = this.partMatchingService.getWhereUsedResult(this.mpnname, this.cpnname, targetUrlObject.url, targetUrlObject.type)
            .subscribe(
                (data) => { this.responseObject = data },

                (error) => console.log('Unable to get the Multiple PartMatch Result'),
                () => this.validateData()
            )
    }
    validateData() {

        if (this.responseObject['responseStatus']['code'] === 200) {

            this.tableData = this.responseObject['result'];
            this.columnDefs = this.WhereUsedList;
            this.partMatchingService.setwhereUsedData({ tableData: this.tableData, lpn: this.params.data.lpn });
        }
    }
    helpWindow(event) {
        const url = this.params['data']['dsUrl']
        window.open(url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    }
    ngOnDestroy() {
        if (this.getPartMatchSubscription) { this.getPartMatchSubscription.unsubscribe() }
        if (this.getWhereUsedSubscription) { this.getWhereUsedSubscription.unsubscribe() }
    }
}
