import { ITooltipAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';

@Component({
  selector: 'tooltip-component',
  template: `
    <div
      (mouseenter)="showTooltip($event, id)"
      (mouseleave)="hideTooltip($event, id)"
      *ngIf="part"
    >
      <em
        *ngIf="pageName == 'partsCatalog'"
        class="ld-icon-information leva-small-icon-styles"
      ></em>
      <em
        *ngIf="
          pageName == 'pwb' && data;
          else unlock
        "
        class="ld-icon-lock"
      ></em>
      <ng-template #unlock>
        <em *ngIf="pageName == 'pwb'" class="ld-icon-unlocked"></em>
      </ng-template>
      <div class="custom-tooltip right" id="{{ id }}">
        <ul class="tip-content" [perfectScrollbar]="scrollBarConfiguration">
          <li class="supplier-details">
            <ng-container *ngIf="pageName != 'partsCatalog'">
              <ng-container
                *ngIf="pageName == 'pwb' && data; else notCheckedout"
              >
                <p style="font-size:12px">
                  <span>Status : {{ data?.status }}</span>
                </p>
                <p style="font-size:12px">
                  <span>By : {{ data?.statusBy }}</span>
                </p>
              </ng-container>
              <ng-template #notCheckedout>
                <p style="font-size:12px">
                  <span> Available for Checkout </span>
                </p>
                <p style="font-size:12px"></p>
              </ng-template>
            </ng-container>
            <ng-container *ngIf="pageName == 'partsCatalog'">
              <p style="font-size:12px">
                <span
                  >Part Checked Out By :
                  {{ data?.partCheckedOutBy || 'None' }}</span
                >
              </p>
              <p style="font-size:12px">
                <span
                  >Source Checked Out By :
                  {{ data?.sourceCheckedOutBy || 'None' }}</span
                >
              </p>
            </ng-container>
          </li>
        </ul>
      </div>
    </div>
  `,
  styleUrls: ['./checkboxgrid.tooltip.sass'],
})
export class CheckboxTooltipComponent implements ITooltipAngularComp {
  public data: any;
  public tooltip = false;
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  params;
  id;
  public part: boolean;
  pageName = 'partsCatalog';
  currentUser;

  agInit(params): void {
    if (params) {
      this.params = params;
      this.id = params.data.nodePath;
      let row;
      if (params.api.getDisplayedRowAtIndex(params.rowIndex)) {
        row = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
      }
      if ((params.data.nodeType || '').toLowerCase() === 'part') {
        this.part = true;
      } else {
        this.part = false;
      }
      if (params.context && params.context.componentParent) {
        this.pageName = params.context.componentParent.pageName
          ? params.context.componentParent.pageName
          : 'partsCatalog';
        this.currentUser = params.context.componentParent.email;
        if (params.context.componentParent.npiPartsWorbenchService) {
          if (
            row &&
            params.context.componentParent.npiPartsWorbenchService
              .checkoutDetails instanceof Object &&
            params.context.componentParent.npiPartsWorbenchService
              .checkoutDetails[row.partId] instanceof Object
          ) {
            this.data =
              params.context.componentParent.npiPartsWorbenchService.checkoutDetails[
              row.partId
              ];
          }
        } else if (
          row &&
          params.context.componentParent.checkoutDetails instanceof Object &&
          params.context.componentParent.checkoutDetails[row.partId] instanceof
          Object
        ) {
          this.data =
            params.context.componentParent.checkoutDetails[row.partId];
        }
      }
    }
  }

  showTooltip(event, id) {
    document.getElementById(id).classList.add('show');
  }
  hideTooltip(event, id) {
    document.getElementById(this.id).classList.remove('show');
  }
}
