import { Injectable, Output } from '@angular/core';
import {
  API_SERVICE_CONSTANTS,
  HTTP_REQUEST_TYPES,
  TOASTER_MESSAGE_TYPES,
} from 'app/modules/npi/npiconstants';
import { ApiServiceProvider } from 'app/api-service';
import { REST_END_POINT_URLS } from '../../../npiconstants';
import { EventEmitter } from 'events';
import { Subject, BehaviorSubject } from 'rxjs';
import { NpiToasterService } from 'app/modules/npi/npi-toaster.service';
import { NpiCommonApiService } from 'app/modules/npi/npi-common-api.service';
import { ObjectType } from 'app/modules/common/interface/objectype';

@Injectable()
export class NpiBomManagementService {
  public editConfirmation: Subject<any> = new Subject();
  public bomHeaders;
  public revisionsList: any;
  public buttonActions: Subject<any> = new Subject();
  public fileData: Subject<any> = new Subject();
  public save = false;
  public disableSave = true;
  public discard = false;
  public autoFill = false;
  public autoFillSDB = false;
  public disableAutoFill = true;
  public disableAutoFillSDB = false;
  public checkIn = false;
  public disableCheckIn = true;
  public disableCheckOut = true;
  public disableReset = true;
  public reset = true;
  public fileUpload = false;
  public disableFileUpload = true;
  public editedCells: any = [];
  public checkout = false;
  public disableDiscard = true;
  public hasEditPermissions = false;
  public showSendReminderButton = false;
  public showCheckoutOverrideButton = false;
  private sdbFatalColumns = [];
  private checkoutDetails = {};
  public forwardSyncPreview = false;
  public showAutofillSDB;
  public forwardSyncTitle = '';
  public bomgridtype = 'VIEW_BOM';
  public customOverlay: Subject<any> = new BehaviorSubject(
    '<span style="padding: 10px; border: 2px solid #444; background: white;">No records to show</span>'
  );
  private selectedDate = new Subject<any>();
  private timeInterval = 10000;
  public disableWorkFlowInitiation = false;
  public workFlowInitiationName = '';
  public showWorkFlow = false;
  public costqtr;
  public costQtrDisplayName;
  constructor(
    private apiService?: ApiServiceProvider,
    private npiCommonApiService?: NpiCommonApiService
  ) {}
  public setSelectedDate(val) {
    this.selectedDate.next(val);
  }
  public getSelectedDate() {
    return this.selectedDate.asObservable();
  }

  public setCustomOverlay(val) {
    this.customOverlay.next(val);
  }

  public getCustomOverlay() {
    return this.customOverlay.asObservable();
  }

  public saveEditedBom(rev, programKey, payload) {
    const request = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: 'PUT',
      servicePath: `${rev.bomId}`,
      formParams: {
        bomId: rev.bomId,
        revisionId: rev.revisionId,
        versionId: rev.versionId,
        isPreview: false,
        autoFill: false,
        programKey,
      },
      payload,
    };
    return this.apiService.post(request);
  }

  public saveFlattenedRevision(rev, programKey, payload) {
    const request = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_FLATTENED_TARGETED_TYPE,
      method: 'PUT',
      servicePath: `${rev.bomId}`,
      formParams: {
        bomId: rev.bomId,
        revisionId: rev.revisionId,
        versionId: rev.versionId,
        isPreview: false,
        autoFill: false,
        programKey,
      },
      payload,
    };
    return this.apiService.post(request);
  }

  public bomAutoFill(rev) {
    const request = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: 'GET',
      servicePath: `autofill/partdtls`,
      formParams: {
        bomId: rev.bomId,
        revId: rev.revisionId,
        verId: rev.versionId,
      },
    };
    return this.apiService.post(request);
  }
  public getTransformationDetailsForBOM(input) {
    const data = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.GET,
      formParams: {
        bomId: input['bomId'],
        revisionId: input['revisionId'],
      },
      servicePath: `transcost`,
    };
    return this.apiService.post(data);
  }
  public getBOMRevisions(programKey) {
    const data = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.GET,
      formParams: {
        programKey,
      },
      servicePath: 'bomrevisions',
    };
    return this.apiService.post(data);
  }
  public checkOutRevision(bomId, revisionId) {
    const data = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.GET,
      formParams: {
        bomId,
        revisionId,
      },
      servicePath: REST_END_POINT_URLS.BOM_CHECKOUT,
    };
    return this.apiService.post(data);
  }
  public checkInRevision(bomId, revisionId, versionId) {
    const data = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.GET,
      formParams: {
        bomId,
        revisionId,
        versionId,
      },
      servicePath: REST_END_POINT_URLS.BOM_CHECKIN,
    };
    return this.apiService.post(data);
  }
  public getStatus(rev) {
    const data = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.GET,
      formParams: {
        bomId: rev.bomId,
        revisionId: rev.revisionId,
        versionId: rev.versionId,
      },
      servicePath: `${rev.bomId}/status`,
    };
    return this.apiService.post(data);
  }
  public getBomTransformationStatus(rev) {
    const data = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.GET,
      formParams: {
        bomId: rev.bomId,
        revisionId: rev.revisionId,
      },
      servicePath: `transcost/status`,
    };
    return this.apiService.post(data);
  }
  public editBomAccess(programKey) {
    const data = {
      targetedType: API_SERVICE_CONSTANTS.PROGRAM_MANAGEMENT_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.GET,
      servicePath: `program/${programKey}/access/editbom`,
    };
    return this.apiService.post(data);
  }
  public getHeaders(revision, type, programKey) {
    const payload = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: 'GET',
      servicePath: `templateheaders`,

      formParams: {
        pageView: type,
        programKey,
        revision,
      },
    };
    return this.apiService.post(payload);
  }
  public checkoutOverride(revisionInfo, programInfo) {
    let email = '';
    if (programInfo.programManager) {
      email = programInfo.programManager;
    } else {
      email = programInfo.programManagerName;
    }
    const data = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.GET,
      formParams: {
        programName: programInfo.programName,
        revisionName: revisionInfo.revisionName,
        bomId: revisionInfo.bomId,
        revisionId: revisionInfo.revisionId,
        programManagerEmail: email,
      },
      servicePath: `undobomcheckout`,
    };
    return this.apiService.post(data);
  }
  public sendReminder(revisionInfo, programInfo) {
    let email = '';
    if (programInfo.programManager) {
      email = programInfo.programManager;
    } else {
      email = programInfo.programManagerName;
    }
    const data = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.GET,
      formParams: {
        programName: programInfo.programName,
        revisionName: revisionInfo.revisionName,
        bomId: revisionInfo.bomId,
        revisionId: revisionInfo.revisionId,
        programManagerEmail: email,
      },
      servicePath: `bomcheckinreminder`,
    };
    return this.apiService.post(data);
  }
  public setChekoutDetails(val) {
    this.checkoutDetails = val;
  }
  public getChekoutDetails() {
    return this.checkoutDetails;
  }
  public setSDBTemplateFatalColumns(val) {
    this.sdbFatalColumns = val;
  }
  public getSDBTemplateFatalColumns() {
    return this.sdbFatalColumns;
  }

  public getFullTemplate(rev, programKey) {
    const payload = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: 'GET',
      servicePath: `fulltemplate`,
      formParams: {
        programKey,
        revision: rev.revId,
      },
    };
    return this.apiService.post(payload);
  }

  public getNPIUniquePartsCount(rev) {
    const payload = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: 'GET',
      servicePath: `bompartsync/partcount`,
      formParams: {
        bomId: rev.bomId,
        revisionId: rev.revisionId,
        versionId: rev.versionId,
      },
    };

    return this.apiService.post(payload);
  }

  public uploadNPIUniquePartsToCatalog(rev) {
    const payload = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: 'GET',
      servicePath: `bompartsync/uploadnewparts`,
      formParams: {
        bomId: rev.bomId,
        revisionId: rev.revisionId,
        versionId: rev.versionId,
      },
    };
    return this.apiService.post(payload);
  }

  public checkBomPermissions(programKey) {
    const data = {
      targetedType: API_SERVICE_CONSTANTS.PROGRAM_MANAGEMENT_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.GET,
      servicePath: `program/${programKey}/bom/permissions`,
    };
    return this.apiService.post(data);
  }
  getRFXData(rfqName) {
    const payload = {
      method: 'GET',
      servicePath: '/rfq',
      targetedType: 'RFQ',
      formParams: {
        rfqName,
      },
    };
    return this.apiService.post(payload);
  }
  public getProductRFXData(name) {
    const payload = {
      method: 'GET',
      servicePath: '/rfx/productrfq',
      targetedType: 'productRFQ',
      formParams: {
        name,
      },
    };
    return this.apiService.post(payload);
  }
  public bomSendRfx(
    bomId,
    revisionId,
    versionId,
    productRfx,
    commodityRfiName,
    productRfiName,
    bomComponentList
  ) {
    const data = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.POST,
      servicePath: `bom/rfx/sendbomrfx`,
      payload: {
        bomId,
        revisionId,
        versionId,
        productRfx,
        commodityRfiName,
        productRfiName,
        bomComponentList,
      },
    };
    return this.apiService.post(data);
  }

  public updateCheckedoutBom(programId) {
    const data = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.POST,
      servicePath: `bom/rfx/rfxresponse/update/checkedoutbom`,
      formParams: {
        programId,
      },
    };
    return this.apiService.post(data);
  }

  public checkPartsUpdatePending(programId, bomRepoId, bomRepo) {
    const data = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.GET,
      servicePath: `bom/rfx/rfxresponse/update/pending`,
      formParams: {
        programId,
        bomRepoId,
        bomRepo,
      },
    };
    return this.apiService.post(data);
  }
  public sendWorkFlowInitiation(
    bomId,
    revisionId,
    versionId,
    programId,
    workflowName
  ) {
    const data = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.POST,
      servicePath: `initiate/workflow`,
      formParams: {
        bomId,
        revisionId,
        versionId,
        programId,
        workflowName,
      },
    };
    return this.apiService.post(data);
  }

  public checkWorkFlowInitiationStatus(bomId, revisionId, versionId) {
    const data = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.GET,
      servicePath: `workflow/initiation/status`,
      formParams: {
        bomId,
        revisionId,
        versionId,
      },
    };
    return this.apiService.post(data);
  }

  public checkWorkFlowInitiation(enableToaster?) {
    this.disableWorkFlowInitiation = false;
    const revisionDetails = this.npiCommonApiService.gerRevisionSelected();
    if (revisionDetails instanceof Object) {
      this.checkWorkFlowInitiationStatus(
        revisionDetails.bomId,
        revisionDetails.revisionId,
        revisionDetails.versionId
      ).subscribe((data) => {
        if (data instanceof Object && data['result'] instanceof Object) {
          if (data.result.status === 'SUBMITTED') {
            setTimeout(() => {
              this.checkWorkFlowInitiation(enableToaster);
            }, this.timeInterval);
          } else if (data.result.status === 'COMPLETED') {
            if (enableToaster) {
              NpiToasterService.populateToaster(
                TOASTER_MESSAGE_TYPES.SUCCESS,
                data.result.statusMessage
              );
            }
            this.disableWorkFlowInitiation = false;
          } else if (enableToaster) {
            NpiToasterService.populateToaster(
              TOASTER_MESSAGE_TYPES.ERROR,
              'Work Flow initiation failed'
            );
            this.disableWorkFlowInitiation = false;
          }
        }
      });
    }
  }

  public onSave(payload, programKey) {
    const data: ObjectType = {
      targetedType: API_SERVICE_CONSTANTS.PROGRAM_MANAGEMENT_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.POST,
      payload,
    };
    data.servicePath = '/program/' + programKey + '/config';
    return this.apiService.post(data);
  }

  getRAndOHeaders() {
    const data: ObjectType = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.GET,
      servicePath: 'rno/headers',
    };
    return this.apiService.post(data);
  }

  getRAndORowData(payload: any) {
    const data = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.GET,
      servicePath: `rno?bomId=${payload.bomId}&revisionId=${payload.revisionId}&versionId=${payload.versionId}&`,
    };
    return this.apiService.post(data);
  }
}
