import { Component} from '@angular/core';
import { ScopeDataService } from 'app/modules/common/scope.data.service';

@Component({
  selector: 'template-navbar',
  templateUrl: './template-navbar.component.html',
  styleUrls: ['./template-navbar.component.sass'],
  providers: [ScopeDataService]
})

export class TemplateNavbarComponent {

  tabsList: any;
  constructor(
    private scopeDataService: ScopeDataService
  ) { }

  ngOnInit() {
    this.tabsList = [{
      name : 'fields',
      title : 'Template Fields'
    }];
    // {
    //   "name" : "metrics",
    //   "title" : "Metrics"
    // }
  }

  onTabClick() {
    this.scopeDataService.settype('manual');
  }
}
