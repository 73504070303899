import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { SubNavAdvBarService } from 'app/modules/common/sub-nav-adv-bar.service';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { Router } from '@angular/router';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { SubscriptionLike } from 'rxjs';
 import { OnDestroy } from '@angular/core';
import { BaseService } from 'app/modules/common/base.service';
import { InteractiveEditService } from 'app/modules/interactive-edit/shared/interactive-edit.service';
@Component({
  selector: 'sub-nav-adv-tab-bar',
  templateUrl: 'sub-nav-adv-tab-bar.component.html',
  styleUrls: ['./sub-nav-adv-tab-bar.component.sass'],
  providers: [SubNavAdvBarService]
})

export class SubNavAdvTabBarComponent implements OnInit, OnDestroy, OnChanges {
  subNavTabBar: any;
  scopeSubscription: SubscriptionLike;
  datastoragesub: SubscriptionLike;
  focus: string;
  @Input() target: string;
  routerarray: Array<string> = [];
  constructor(
    private subNavBarService: SubNavAdvBarService,
    private scopeDataService: ScopeDataService,
    private router: Router,
    private dataStorageService: DataStorageService,
    private baseService: BaseService,
    private interactiveEditService: InteractiveEditService
  ) {

    this.scopeSubscription = this.dataStorageService.getSelectedFocus().subscribe(
      value => {
        if (value !== undefined) {
          this.updateTabs(value);
        }

      }

    );

  }
  ngOnInit() {
    this.routerarray = this.router.url.split('/');
    this.focus = this.scopeDataService.getFocusValue();
    if (this.focus !== undefined) {
      this.dataStorageService.setSelectedFocus(this.focus);
    }

    this.scopeDataService.getFocus().subscribe(
      (value) => {
        this.dataStorageService.setSelectedFocus(value);
      });
  }

  updateTabs(value) {
    if (value === 'Commodity') {
      this.subNavTabBar = this.subNavBarService.getSubNavBarList(this.target);
    }
    else if (value === 'Product') {
      this.subNavTabBar = this.subNavBarService.getProductSubNavBarList(this.target);
    }
  }


  ngOnChanges() {
    this.routerarray = this.router.url.split('/');
    this.datastoragesub = this.scopeDataService.getFocus().subscribe(
      (value) => {
        this.dataStorageService.setSelectedFocus(value);
      });
    this.updateTabs(this.focus);
    this.interactiveEditService.getLoadApprovalMap().subscribe(
      (res) => {
        if (res[0] !== undefined) {
          this.subNavBarService.getSubNavBarList('notifications')[0]['counter'] = res[0].length;
        } else {
          this.subNavBarService.getSubNavBarList('notifications')[0]['counter'] = 0;
        }
        if (res[1] !== undefined) {
          this.subNavBarService.getSubNavBarList('notifications')[1]['counter'] = res[1].length;
        } else {
          this.subNavBarService.getSubNavBarList('notifications')[1]['counter'] = 0;
        }
        if (res[-1] !== undefined) {
          this.subNavBarService.getSubNavBarList('notifications')[2]['counter'] = res[-1].length;
        } else {
          this.subNavBarService.getSubNavBarList('notifications')[2]['counter'] = 0;
        }
        this.subNavTabBar = this.subNavBarService.getSubNavBarList(this.target);
      }
    )
  }

  ngOnDestroy() {
    if (this.scopeSubscription) {
      this.scopeSubscription.unsubscribe();
    }
    if (this.datastoragesub) {
      this.datastoragesub.unsubscribe();
    }
  }

  onTabClick() {
    this.scopeDataService.settype('manual');
  }


}
