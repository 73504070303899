import { Component, OnInit, Input } from '@angular/core';

import { HelpMessage } from './shared/help-message.model';
import { HelpMessageService } from './shared/help-message.service';
import { BOTHelpService } from 'app/modules/base-app/bot/help/help.service';

@Component({
  selector: 'help-message',
  templateUrl: 'help-message.component.html',
  styleUrls : ['./help-message.component.sass'],
  providers: [HelpMessageService]
})

export class HelpMessageComponent implements OnInit {
  helpMessage: HelpMessage[] = [];

  @Input('commands') commands: any;
  message ='';
  constructor(
    private helpMessageService: HelpMessageService,
    private botHelpService: BOTHelpService) { }

  ngOnInit() {

    /* for(let str of this.commands){
			this.message +=" "+str['text'];
		}*/
  }

  onCommandClick(command){
    this.botHelpService.setSelectedCommand(command);
  }
}
