import { Component, OnInit, ɵConsole } from '@angular/core';
import { NpiAdminRiskService } from '../../npi-admin/npi-admin-risk/shared/npi-admin-risk.service';
import { PayLoad } from '../../../common/models/payload.model';
import { RequestData } from '../../../common/models/request.model';
import { SubscriptionService } from '../../../common/subscription.service';
import { DateUtils } from '../../../common/utills/DateUtils.service';
import * as moment from 'moment-timezone-all';
import { DATE_PROPERTIES, API_SERVICE_CONSTANTS } from '../../npiconstants';
import { ECalendarValue, IDatePickerConfig } from 'ng2-date-picker';
import { ObjectUtils } from '../../../common/utills/ObjectUtils.service';
import { UploadDownloadService } from '../../../srfq/shared/upload-download.service';
import { ApiServiceProvider } from '../../../../api-service';
import { IfObservable } from 'rxjs/observable/IfObservable';
import * as _ from 'lodash';

@Component({
  selector: 'app-psl-risk',
  templateUrl: './psl-risk.component.html',
  styleUrls: ['./psl-risk.component.sass'],
})
export class PslRiskComponent implements OnInit {
  public sortedInfo: any = [];
  public showData = false;
  public request: RequestData;
  public requestPayload: any;
  public showAddNewRow = false;
  public minValuesArray: any;
  public info = [];
  public showNotConfiguredMessage = false;
  public indexForSupplier: any;
  public FileData: any;
  public configsArray = [];
  public psls = [
    {
      name: null,
      approvedUntilDate: null,
      delete: false,
    },
  ];

  public config = {};
  public downloadPayload;
  public excelInfo = {
    method: 'GET',
    type: 'NPI',
  };
  constructor(
    public npiAdminRiskService: NpiAdminRiskService,
    private subscriptionService: SubscriptionService,
    private apiServiceProvider: ApiServiceProvider,
    private uploadDownloadService: UploadDownloadService
  ) {
    moment.tz.setDefault(DateUtils.getUserTimeZone());
  }

  public ngOnInit() {
    this.config = {
      format: DATE_PROPERTIES.FORMAT,
      returnedValueType: ECalendarValue.Moment,
      disableKeypress: true,
      unSelectOnClick: false,
      min: DateUtils.getNextHour().startOf('day'),
      drops: 'down',
    };
    this.FileData = {
      title: 'NPI',
      RFQ: false,
      servicePath: `/ProgramManagementService/api/programservice/risks/uploadpsl`,
    };

    if (
      this.npiAdminRiskService.keyInfo &&
      this.npiAdminRiskService.programKeyInfo
    ) {
      this.formExcelPayload();
      if (
        !this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.hasOwnProperty(
          'NON_PREFERRED_SUPPLIER_RISK'
        )
      ) {
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap[
          'NON_PREFERRED_SUPPLIER_RISK'
        ] = {
          psls: this.psls,
        };
      } else if (
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
          .NON_PREFERRED_SUPPLIER_RISK === null
      ) {
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap[
          'NON_PREFERRED_SUPPLIER_RISK'
        ] = {
          psls: this.psls,
        };
      }
      if (
        !this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
          .NON_PREFERRED_SUPPLIER_RISK.psls.length
      ) {
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls = this.psls;
      }
      if (this.npiAdminRiskService.keyInfo !== undefined) {
        this.npiAdminRiskService.keyInfo.result.CONFIGURE_NON_PSL_RISK.widgetHeaders.forEach(
          (element) => {
            switch (element.headerInfo.headerName) {
              case 'Supplier':
                element.headerInfo.key = 'autoSearch';
                break;
              case 'Approved Until':
                element.headerInfo.key = 'date';
                break;
              case 'Comments':
                element.headerInfo.key = 'input';
                break;
              default:
                element.headerInfo.key = '';
                break;
            }
            this.sortedInfo.push(element.headerInfo);
          }
        );
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls.forEach(
          (element) => {
            if (element.approvedUntilDate && element.approvedUntilDate !== 0) {
              element.isDefault = true;
              if (!moment.isMoment(element['approvedUntilDate'])) {
                element.approvedUntilDate = DateUtils.getMomentFromEpoch(
                  element['approvedUntilDate']
                );
              }
            }
            element['delete'] = false;
            element['isAdded'] = false;
          }
        );
        this.sortedInfo.sort(function (a, b) {
          return a.displayOrder - b.displayOrder;
        });

        this.sortedInfo.sort(function (a, b) {
          return a.displayOrder - b.displayOrder;
        });

        // this.updateMinMaxValues()
      }
    } else {
      this.showNotConfiguredMessage = true;
    }
  }

  public addNewRow(item) {
    const newItem = JSON.parse(JSON.stringify(item));
    for (const key in newItem) {
      newItem[key] = null;
      if (key === 'isDefault') {
        newItem[key] = true;
      }
    }
    newItem['delete'] = false;
    newItem['isAdded'] = true;
    this.npiAdminRiskService.enableReset = true;
    this.npiAdminRiskService.saveButtonStatus();
    this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls.push(
      newItem
    );
  }

  public updateDate(event, i) {
    if (moment.isMoment(event)) {
      if (
        !this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
          .NON_PREFERRED_SUPPLIER_RISK.psls[i]['isDefault']
      ) {
        /* this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls[
          i

				].approvedUntilDate = event.clone().endOf('day'); */
        this.npiAdminRiskService.bomRiskUpdated = true;
        this.npiAdminRiskService.enableReset = true;
        // this.updateMinMaxValues();

        this.npiAdminRiskService.saveButtonStatus();
      } else {
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls[
          i
        ]['isDefault'] = false;
      }
    }
  }

  public deleteRow(i) {
    this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls[
      i
    ]['delete'] = true;
    // this.configsArray.splice(i, 1);
    if (
      !this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
        .NON_PREFERRED_SUPPLIER_RISK.psls[i]['isAdded']
    ) {
      this.npiAdminRiskService.bomRiskUpdated = true;
    }
    this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls.splice(
      i,
      1
    );
    if (
      this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
        .NON_PREFERRED_SUPPLIER_RISK.psls.length === 0
    ) {
      this.subscriptionService.setPMRequestData('');
      this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls = [
        {
          name: null,
          approvedUntilDate: null,
          delete: false,
        },
      ];
    }
    this.npiAdminRiskService.errors['psl'] = [];
    this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls.forEach(
      (element) => {
        element.error = false;
      }
    );
    this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls.forEach(
      (element) => {
        if (element.name) {
          const commonSupp = this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls.filter(
            (obj) => obj.name.toUpperCase() === element.name.toUpperCase()
          );
          if (commonSupp && commonSupp.length > 1) {
            element.error = true;
            this.npiAdminRiskService.errors['psl'].push('Error');
          }
        }
      }
    );
    this.npiAdminRiskService.enableReset = true;
    this.npiAdminRiskService.saveButtonStatus();
    const tempArr = _.cloneDeep(
      this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
        .NON_PREFERRED_SUPPLIER_RISK.psls
    );
    this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls = [];
    this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls = tempArr;
  }
  public commentsChanged(i, event) {
    this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls[
      i
    ]['comments'] = event.target.value;
    this.npiAdminRiskService.saveButtonStatus();
    this.npiAdminRiskService.enableReset = true;
  }

  public onFileUpload(event) {
    if (
      event.result &&
      event.result.riskConfigMap &&
      event.result.riskConfigMap.hasOwnProperty(
        'NON_PREFERRED_SUPPLIER_RISK'
      ) &&
      event.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls
    ) {
      if (
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
          .NON_PREFERRED_SUPPLIER_RISK.psls.length
      ) {
        event.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls.forEach(
          (element) => {
            if (!element.hasOwnProperty('approvedUntilDate')) {
              element['approvedUntilDate'] = '';
            } else {
              if (element['approvedUntilDate'] !== 0) {
                element['approvedUntilDate'] = DateUtils.getMomentFromEpoch(
                  element['approvedUntilDate']
                );
              } else {
                element['approvedUntilDate'] = '';
              }
            }
            if (!element.hasOwnProperty('comments')) {
              element['comments'] = '';
            }
          }
        );
        const psl = this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
          .NON_PREFERRED_SUPPLIER_RISK.psls;
        if (psl.length === 1 && !psl[0].name) {
          this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls = [];
        }
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls = this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls.concat(
          event.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls
        );
        const output = Object.values(
          this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls.reduce(
            (r, o) => {
              r[o.name] = Object.assign({}, o);
              return r;
            },
            {}
          )
        );
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls = output;
      } else {
        this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls =
          event.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls;
      }
      this.npiAdminRiskService.bomRiskUpdated = true;
      this.npiAdminRiskService.enableReset = true;
      this.npiAdminRiskService.saveButtonStatus();
    }
  }

  // public downloadExcelFile() {
  //   const formParams = {};
  //   formParams['programId'] = this.npiAdminRiskService.programKeyInfo.programId;
  //   return this.uploadDownloadService.downloadToExcel(
  //     '/api/programservice/risks/downloadpsl',
  //     formParams,
  //     'npi',
  //     'programManagement'
  //   );
  // }
  public formExcelPayload() {
    this.downloadPayload = {
      targetedType: API_SERVICE_CONSTANTS.PROGRAM_MANAGEMENT_TARGETED_TYPE,
      formParams: {
        programId: this.npiAdminRiskService.programKeyInfo.programId,
      },
      servicePath: '/risks/downloadpsl',
    };
  }
  public trackByFn(index: any) {
    return index;
  }
  public ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    if (this.npiAdminRiskService.bomRiskInfo instanceof Object) {
      this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls.forEach(
        (element) => {
          element['isDefault'] = true;
        }
      );
    }
  }
}
