import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AUTHENTICATION_CONSTANT } from 'app/modules/authentication/shared/authentication.constants';

@Component({
  selector: 'app-field-errors',
  templateUrl: './field-errors.component.html',
  styleUrls: ['./field-errors.component.sass']
})
export class FieldErrorsComponent implements OnInit {
  public authConst = AUTHENTICATION_CONSTANT;
  @Input() public formField: FormControl;
  @Input() public fieldName: string;

  constructor() { }

  ngOnInit() {
  }

}
