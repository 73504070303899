import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

import { AppFilter } from './app-filter.model';

@Injectable()
export class AppFilterService {
  constructor(private http: HttpClient) {}
}
