import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { EventEmitter, Output } from '@angular/core';
// export const changesInGrid = new Subject<any>();
export const changesInGrid: EventEmitter<any> = new EventEmitter<any>();
let fatalErrors = {};
let userActions = {
  ADD: [],
  UPDATE: [],
  DELETE: []
};

export const updateUserAction = obj => {
  switch (obj.type.toLowerCase().trim()) {
    case 'add':
      if (obj.data) {
        userActions.ADD.push(obj.data);
      }
      break;
    case 'delete':
      if (obj.data) {
        if (obj.data.userAdded) {
          _.remove(userActions.ADD, (n) => {
            return n.id === obj.data.id;
          });
        } else {
          _.remove(userActions.UPDATE, (n) => {
            return n.id === obj.data.id;
          });
          userActions.DELETE.push(obj.data);
        }
      }
      break;
    case 'update':
      if (obj.data) {
        if (!obj.data.userAdded) {
          const findIndex = _.findIndex(userActions.UPDATE, (o) => {
            return o.id === obj.data.id;
          });
          if (findIndex === -1) {
            userActions.UPDATE.push(obj.data);
          } else {
            userActions.UPDATE[findIndex] = obj.data;
          }
        }
      }
      break;
  }
  changesInGrid.emit(userActions);
};
export const setUserActions = data => {
  userActions = data;
};
export const getUserActions = () => {
  return userActions;
};

export const restUserAction = () => {
  userActions = {
    ADD: [],
    UPDATE: [],
    DELETE: []
  };
};

export const updateFatalErrors = val => {
  fatalErrors[val] = true;
};
export const deleteFatalError = val => {
  if (val instanceof Array) {
    val.forEach(element => {
      if (element.id) {
        Object.getOwnPropertyNames(fatalErrors).forEach(error => {
          const a = error.split('**');
          if (a[a.length - 1] === element.id) {
            if (fatalErrors[error]) {
              delete fatalErrors[error];
            }
          }
        });
      }
    });
  }
  if (fatalErrors[val]) {
    return delete fatalErrors[val];
  }
};
export const getFatalErrors = () => {
  return fatalErrors;
};
export const resetFatalErrors = () => {
  fatalErrors = {};
};
