import { Router } from '@angular/router';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { SubscriptionService } from 'app/modules/common/subscription.service';

@Component({
  selector: 'child-cell',
  template: `
    <div class="pull-left full-width full-height">
      <input
        class="form-control margin-top-0"
        type="checkbox"
        [(ngModel)]="params.value"
        (change)="onChange($event)"
      />
    </div>
  `
})
export class AdvCheckboxComponent {
  public params: any = {};
  public currentRowData: any = {};
  public currentColumn = '';

  constructor(private subService: SubscriptionService) {}

  agInit(params: any): void {
    this.params = params;
    this.currentRowData = params.data;
    this.currentColumn = params.colDef.field;
  }

  public onChange($event) {
    this.currentRowData[this.currentColumn] = this.params.value;
    this.subService.checkboxSetRenderder(this.currentRowData);
  }
}
