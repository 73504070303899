import { Component, OnInit, ViewEncapsulation, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { InsightSummaryBannerService } from './insight-summary-banner.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-insight-summary-banner',
  templateUrl: './insight-summary-banner.component.html',
  styleUrls: ['./insight-summary-banner.component.sass'],
  providers:[InsightSummaryBannerService],
  encapsulation: ViewEncapsulation.None
})
export class InsightSummaryBannerComponent implements OnInit, OnDestroy {
  insightSummary =[
    // {
    //   title: 'Target',
    //   value: 'target'
    // },
    {
      title: 'Total Offered',
      value: 'totalOffered'
    },
    {
      title: 'Accepted',
      value: 'accepted'
    },
    {
      title: 'On Hold',
      value: 'inReview'
    },
    {
      title: 'Rejected',
      value: 'rejected'
    },
    {
      title: 'Not Reviewed',
      value: 'notReviewed'
    }
  ]

  summary$: Subscription
  @Input() summaryData;
  constructor(
    private insightSummaryBannerService: InsightSummaryBannerService
  ) { }

  ngOnInit() {}
  onExpand(){
    this.summaryData.isExpand  = !this.summaryData.isExpand;
  }
  ngOnDestroy(){}
}
