import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { BodyOutputType, Toast, ToasterService } from 'angular2-toaster';
import { EventMetaInfoService } from 'app/modules/curator/industry-news/industry-news-events/components/event-management/event-meta-info.service';
import { DialogBoxService, DialogSize, DialogType, LdDialog } from 'app/modules/leva-ui-library/components/dialog-box/dialog-box.service';
import { statusListConfiguration, typeListConfiguration, severityListConfiguration } from './save-event.constants';
import { SaveEventService } from './save-event.service';

interface EventDetails {
  eventId?: string
  title?: string
  description?: string
  type?: string
  vendorImpacted?: string[]
  activeState?: string
  severity?: number
  rawCompanies?: string
  locations?: any[]
  articles?: any[]
  customerCreatedEvent? : boolean
}

export interface EventPayload {
  eventId?: string
  articleIds: string[]
  title: string
  description: string
  severity: string
  type: string
  activeState: string
  vendorImpacted: string[]
  locations: []
  customerCreatedEvent?: boolean
  additionalInfo?: any
}

export function rangeValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (control.value < 0 || control.value > 10) {
    return { rangeError: true }
  }
  return null
}

export function locationMandatoryCheckValidator(firstControl, secondControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const vendorImpacted = control.get(firstControl).value;
    const locations = control.get(secondControl).value;
    if (!vendorImpacted.length && !locations.length) { return { locationMandatoryCheck: true } }
    return null
  }
}

@Component({
  selector: 'cprt-save-event',
  templateUrl: './save-event.component.html',
  styleUrls: ['./save-event.component.sass']
})
export class SaveEventComponent implements OnInit {

  panelBodyHeight = 0
  formBContainerHeight = 0
  newEventForm: FormGroup
  eventProperties = {} as EventDetails
  eventCurrentValues = {} as EventDetails
  defaultEventProperties = {} as EventDetails
  @Input('showCustomerEventOnly') showCustomerEventOnly = false
  @Output() onCloseClick = new EventEmitter<any>()
  metaInfoForFilter: any = {}
  selectAllCountForColumns = {}
  selectedItems = {}
  locationMandatoryFlag = false
  metaInfo = null
  refreshFormFlag = true
  eventId = null
  pageTitle = 'Event'
  buttonTitle = 'Create'
  eventListConfiguration = {
    label: 'Select Global Event to clone',
    type: 'newautosearch',
    filterConfig: {
      multiSelect: false,
      targetedType: 'riskManagementService',
      servicePath: 'events/global',
      defaultItemSelection: false,
      defaultSelectedItem: null,
      paramsKey: 'titleLike',
      idKey: 'eventId',
      valueKey: 'title'
    },
    specialVersion: true,
    val: 'events',
    data: [],
    id: `global-event-select`,
    defaultParameter: null,
    resetValues: null,
    name: 'events'
  }
  selectedGlobalEvent: any

  loaderConfig = {
    backdropBackgroundColour: 'rgba(255,255,255,0.3)',
    fullScreenBackdrop: 'true',
    primaryColour: '#1C4E84',
    secondaryColour: '#1C4E84',
    tertiaryColour: '#1C4E84'
  }
  showClonePreloader = false
  showCreatePreloader = false
  showPreloader = false

  defaultFormValues = null
  modifiedFormValues = null
  formValuesChanged = false

  statusListConfiguration = JSON.parse(JSON.stringify(statusListConfiguration))
  typeListConfiguration = JSON.parse(JSON.stringify(typeListConfiguration))
  severityListConfiguration = JSON.parse(JSON.stringify(severityListConfiguration))
  discardChangesPopup: LdDialog = {
    title: 'CONFIRMATION',
    id: 'add_edit_event_id',
    template: false,
    message: 'There are unsaved changes, would you like to continue?',
    size: DialogSize.SMALL,
    type: DialogType['CONFIRMATION'],
    buttons: [
      {
        title: 'Discard and Continue',
        id: 'add_edit_event_id' + 'Discard and Continue',
      },
      {
        title: 'Cancel',
        id: 'add_edit_event_id' + 'Back to Page',
        primary: true,
      },
    ],
  };
  enableReset = false
  slideOptions = {
    floor: 0,
    ceil: 10
  }
  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.panelBodyHeight = window.innerHeight - 177
    this.formBContainerHeight = this.panelBodyHeight - 131
  }

  constructor(
    private eventMetaInfoService: EventMetaInfoService,
    private eventService: SaveEventService,
    private toasterService: ToasterService,
    private dialogBoxService: DialogBoxService
  ) {
    this.metaInfo = this.eventMetaInfoService.getEventMetaInfo()
    this.setStaticDropdownData()
  }

  ngOnInit() {
    // this.onResize(window)
    this.initModuleDetails(this.eventProperties)
    this.initModuleDetails(this.eventCurrentValues)

    if (this.metaInfo.eventData) {
      this.setEventDataFromNews()
    }
    if (this.metaInfo.isClone) {
      this.buttonTitle = 'Clone'
      this.pageTitle = 'Clone Event'
    }
    if (this.metaInfo.eventId) {
      this.buttonTitle = this.metaInfo.isClone ? 'Clone' : 'Update'
      this.pageTitle = this.metaInfo.isClone ? 'Clone Event' : 'Edit Event'
      this.getEventData()
    } else {
      this.configureForm()
      this.initializeForm()
      this.onValueChanges()
      setTimeout(() => {
        this.showPreloader = false
        this.showClonePreloader = false
      }, 100)
    }
  }

  setEventDataFromNews() {
    this.showPreloader = true
    this.eventProperties.title = this.metaInfo.eventData.title
    this.eventProperties.description = this.metaInfo.eventData.body
    this.eventProperties.severity = null
    this.metaInfo.eventData.vendorImpacted = this.metaInfo.eventData.vendorImpacted ? this.metaInfo.eventData.vendorImpacted : []
    this.eventProperties.locations = this.eventProperties.locations ? this.eventProperties.locations : []
    this.eventProperties.vendorImpacted = this.metaInfo.eventData.vendorImpacted.map(vendor => vendor.toUpperCase())
    this.eventProperties.locations = this.metaInfo.eventData.locations.map(location => {
      location.siteImpacted = location.siteImpacted || ['ALL']
      location.city = location.city && location.city.toUpperCase()
      location.state = location.state && location.state.toUpperCase()
      location.country = location.country && location.country.toUpperCase()
      return location
    })
    this.eventProperties.type = this.metaInfo.eventData.primaryCategory[0].toLowerCase()
    this.eventProperties.articles = [{
      articleId: this.metaInfo.eventData.documentId || this.metaInfo.eventData.resourceId,
      articleTitle: this.metaInfo.eventData.title,
      articleSentiment: this.metaInfo.eventData.sentiment,
      url: this.metaInfo.eventData.newsUrl,
      provider: this.metaInfo.eventData.provider,
      publishedDate: this.metaInfo.eventData.published,
      validData: true
    }]
    this.eventProperties.activeState = 'Active'
    this.setSelectedValue(this.statusListConfiguration, this.eventProperties.activeState)
    this.setSelectedValue(this.typeListConfiguration, this.eventProperties.type.toLowerCase())
    this.setSelectedValue(this.severityListConfiguration, this.eventProperties.severity)
    this.eventCurrentValues = JSON.parse(JSON.stringify(this.eventProperties))
  }

  onEventClonePropSelect(params) {
    if (params.selectedItem) {
      this.showClonePreloader = true
      this.selectedGlobalEvent = params.selectedItem
      this.eventMetaInfoService.setEventId(this.selectedGlobalEvent.val)
      this.ngOnInit()
    } else {
      this.selectedGlobalEvent = null
      this.eventMetaInfoService.setEventId(null)
    }
  }

  getEventData() {
    const payload = {
      eventIds: [this.metaInfo.eventId],
      filter: []
    }
    this.showCreatePreloader = true
    this.showPreloader = true
    this.eventService.getEventDetails(payload).subscribe(
      (eventDetailsResponse) => {
        if (eventDetailsResponse && eventDetailsResponse.result) {
          const detailsResult
            = eventDetailsResponse.result.length > 0
              ? (eventDetailsResponse.result[0] || null)
              : null
          if (detailsResult) {
            this.eventProperties.title = detailsResult.title
            if (this.metaInfo.isClone) {
              this.eventProperties.title = 'Clone: ' + this.eventProperties.title
            }
            if (detailsResult.companies && detailsResult.companies.length) {
              this.eventProperties.rawCompanies = `'` + detailsResult.companies.join(`', '`) + `'`
            }
            this.eventProperties.description = detailsResult.description
            this.eventProperties.customerCreatedEvent = detailsResult.customerCreatedEvent
            this.eventProperties.type = detailsResult.type
            this.eventProperties.activeState = detailsResult.activeState
            this.eventProperties.severity = detailsResult.severity
            this.eventProperties.vendorImpacted = detailsResult.vendorImpacted
            this.eventProperties.locations = detailsResult.locations
            this.eventProperties.articles = detailsResult.eventDetails.articles.sort((article1: any, article2: any) => {
              return new Date(article2.publishedDate).valueOf() - new Date(article1.publishedDate).valueOf();
            });
            this.setSelectedValue(this.statusListConfiguration, detailsResult.activeState)
            this.setSelectedValue(this.typeListConfiguration, detailsResult.type.toLowerCase())
            this.setSelectedValue(this.severityListConfiguration, detailsResult.severity + '')
            this.eventCurrentValues = JSON.parse(JSON.stringify(this.eventProperties))
            this.configureForm()
            this.initializeForm()
            this.onValueChanges()
            setTimeout(() => {
              this.showPreloader = false
              this.showClonePreloader = false
            }, 100)

          }
        }
        setTimeout(() => { this.showCreatePreloader = false }, 10)
      }
    )
  }

  setSelectedValue(filterConfig, value) {
    filterConfig.defaultItemSelection = true
    filterConfig.defaultSelectedItem = value
  }

  setStaticDropdownData() {
    this.statusListConfiguration.data = Object.assign([], this.metaInfo.statusList)
    this.typeListConfiguration.data = Object.assign([], this.metaInfo.typesList)
  }

  onEventPropSelect(params) {
  }

  initModuleDetails(eventObj) {
    eventObj.title = null
    eventObj.description = null
    eventObj.severity = this.showCustomerEventOnly ? null : 0
    eventObj.activeState = null
    eventObj.type = null
    eventObj.rawCompanies = ''
    eventObj.vendorImpacted = []
    eventObj.locations = []
    eventObj.articles = []
    eventObj.customerCreatedEvent = false
  }

  configureForm() {
    this.newEventForm = new FormGroup({
      userData: new FormGroup({
        title: new FormControl(null, [Validators.required]),
        description: new FormControl(null, [Validators.required]),
        rawCompanies: new FormControl('', []),
        severity: this.showCustomerEventOnly ? new FormControl(null) : new FormControl(0, [Validators.required, rangeValidator]),
        activeState: this.showCustomerEventOnly ? new FormControl(null) : new FormControl(null, [Validators.required]),
        type: new FormControl(null, [Validators.required]),
        vendorImpacted: new FormControl([]),
        locations: new FormControl([]),
        articles: new FormControl([]),
        customerCreatedEvent: new FormControl(false)
      }),
    })
  }

  initializeForm() {
    this.newEventForm.setValue({
      userData: JSON.parse(JSON.stringify(this.eventProperties)),
    })
    if (this.metaInfo.isClone || this.metaInfo.eventData) {
      this.defaultFormValues = {} as EventDetails
      this.initModuleDetails(this.defaultFormValues)
    } else {
      this.defaultFormValues = Object.assign({}, this.newEventForm.value.userData)
    }
    this.modifiedFormValues = Object.assign({}, this.newEventForm.value.userData)
  }

  onValueChanges() {
    this.newEventForm.valueChanges.subscribe((val) => {
      this.modifiedFormValues = Object.assign({}, val.userData);
      this.eventCurrentValues = JSON.parse(JSON.stringify(this.modifiedFormValues))
    });
  }

  setEventProperties(formData) {
    this.eventCurrentValues.title = formData.title
    this.eventCurrentValues.description = formData.description
    this.eventCurrentValues.severity = formData.severity
    this.eventCurrentValues.activeState = formData.activeState
    this.eventCurrentValues.type = formData.type
    this.eventCurrentValues.vendorImpacted = formData.vendorImpacted
    this.eventCurrentValues.locations = formData.locations
    this.eventCurrentValues.customerCreatedEvent = formData.customerCreatedEvent
    this.eventCurrentValues.articles = formData.articles
  }

  getFilterMetaInfo(params, column) {
    this.metaInfoForFilter[column] = params.payload
    this.selectAllCountForColumns[column] = params.count
  }

  resetFormB() {
    this.metaInfoForFilter = {}
    this.selectAllCountForColumns = {}
    this.selectedItems = {}
  }

  getSelectedItemsCount(column) {
    let count = 0
    switch (column) {
      case 'vendorImpacted':
        this.selectedItems[column] = this.newEventForm.get(`userData.${column}`).value
        count = this.selectedItems[column].length + (this.selectAllCountForColumns[column] || 0)
        break
    }
    return count
  }

  onClickSubmit() {
    if (this.isValidEventForm()) {
      if (JSON.stringify(this.defaultFormValues) === JSON.stringify(this.modifiedFormValues)) {
        this.message('info', 'No changes are there to update')
      } else {
        // alert('Form is changed')
        const payload = {} as EventPayload
        this.preparePayload(payload, this.modifiedFormValues)
        this.createEvent(payload)
      }
    }
    this.newEventForm.markAllAsTouched()
  }

  getCompanies(rawCompanies) {
    return rawCompanies.split(',').map(text => {
      return text.trim().replace(/^'|'$/g, '')
    });
  }

  preparePayload(eventPayload, formData) {
    eventPayload.title = formData.title
    eventPayload.description = formData.description
    eventPayload.severity = formData.severity
    eventPayload.companies = formData.rawCompanies ? this.getCompanies(formData.rawCompanies) : []
    eventPayload.type = formData.type
    eventPayload.customerCreatedEvent = formData.customerCreatedEvent
    eventPayload.activeState = formData.activeState
    eventPayload.vendorImpacted = formData.vendorImpacted
    eventPayload.locations = formData.locations
    eventPayload.articleIds = formData.articles.map(article => article.articleId)
    if (this.metaInfo.isClone) {
      eventPayload.additionalInfo = {
        parentId: this.metaInfo.eventId
      }
    } else {
      eventPayload.eventId = this.metaInfo.eventId
    }
  }

  isValidEventForm() {
    const modifiedDetails = JSON.parse(JSON.stringify(this.newEventForm.value.userData))
    this.locationMandatoryFlag = !modifiedDetails.vendorImpacted.length && !modifiedDetails.locations.length ? true : false
    return this.newEventForm.valid && !this.locationMandatoryFlag
  }

  resetForm() {
    if (JSON.stringify(this.defaultFormValues) === JSON.stringify(this.modifiedFormValues)) {
      this.message('info', 'No changes are there to refresh')
    } else {
      this.showPreloader = true
      this.setDefaultEventProperties()
      this.initializeForm()
      setTimeout(() => {
        this.showPreloader = false
      }, 10)
    }
  }

  setDefaultEventProperties() {
    this.eventProperties = JSON.parse(JSON.stringify(this.defaultFormValues))
    this.eventCurrentValues = JSON.parse(JSON.stringify(this.defaultFormValues))
  }

  createEvent(eventPayload) {

    this.showCreatePreloader = true
    if (!!eventPayload.eventId) {
      this.eventService
        .updateEvent([eventPayload])
        .subscribe((updateEventResponse) => {
          if (updateEventResponse && updateEventResponse.result) {
            if (updateEventResponse.responseStatus.code === 200) {
              this.message('success', 'Event details have been updated successfully')
              this.onCloseClick.emit(true)
            } else {
              this.message('warning', 'Event details updating is not successful')
            }
          }
          if (updateEventResponse && updateEventResponse.responseStatus.code === 200 && !updateEventResponse.result) {
            this.message('warning', updateEventResponse.message || 'Event Modification Failed')
          }
          this.showCreatePreloader = false
        })
    } else {
      // in-case of customer created events set default status as active
      if (this.showCustomerEventOnly) {
        eventPayload.customerCreatedEvent = true;
        eventPayload.activeState = 'Active';
      }
      this.eventService
        .createNewEvent(eventPayload)
        .subscribe((newEventResponse) => {
          if (newEventResponse && newEventResponse.result) {
            if (newEventResponse.responseStatus.code === 200) {
              const logMessage
                = this.metaInfo.isClone
                  ? 'Event is cloned successfully'
                  : 'Event is created successfully'
              this.message('success', logMessage)
              this.onCloseClick.emit(true)
            } else {
              const logMessage
                = this.metaInfo.isClone
                  ? 'Event cloning is not successful'
                  : 'Event is not created'
              this.message('warning', logMessage)
            }
          }
          if (newEventResponse && newEventResponse.responseStatus.code === 200 && !newEventResponse.result) {
            const logMessage
              = this.metaInfo.isClone
                ? 'Event Cloning Failed'
                : 'Event Creation Failed'
            this.message('warning', newEventResponse.message || logMessage)
          }
          this.showCreatePreloader = false
        })
    }
  }

  onCloseCreateEventScreen() {
    if (JSON.stringify(this.defaultFormValues) === JSON.stringify(this.modifiedFormValues)) {
      this.onCloseClick.emit(false)
    } else {
      this.dialogBoxService.open(this.discardChangesPopup.id)
    }
  }

  dialogBoxButtonClick(event) {
    if (event && event.button.title === 'Discard and Continue') {
      this.onCloseClick.emit(false)
    } else {
      this.dialogBoxService.close(this.discardChangesPopup.id)
    }
  }

  message(type: string, message: string) {
    const toast: Toast = {
      type,
      body: '<div vertical-align=middle>' + message + '</div>',
      bodyOutputType: BodyOutputType.TrustedHtml,
    }
    this.toasterService.pop(toast)
  }
}
