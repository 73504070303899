import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-conflict',
    templateUrl: './conflict.component.html',
    styleUrls: ['./conflict.component.sass']
})
export class ConflictComponent implements OnInit {
    public conflict = false;
    constructor() {}
    public agInit(params: any): void {
        this.conflict = params && params.data && params.data.conflict;
    }

    public ngOnInit() {}
}
