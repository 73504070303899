import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { ApiServiceProvider } from 'app/api-service';

@Injectable()
export class AutopopulateService extends ApiServiceProvider  {

    public onSearch(){
        // return this.get({"url":"costlookupsearch.json"});
       // var object = {"clientName":"Levadata26","formParams":{"UUID":"WfovekUuD4XlfPUOHiKNJwb90POBugZ5spdcdBLr","username":"eswar@levadata.com","userInput":"079"},"headerParams":{"userEmail":"eswar@levadata.com","Content-Type":"application/json"},"method":"GET","servicePath":"/Levadata26/REST/services/SearchBasedCommodityCostLookup/autopopulate"};
    // return this.post({"url": "cmsapigateway/generic"},object);
    }

}

