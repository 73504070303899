import {
  Component,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnInit
} from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { DataStorageService } from 'app/modules/common/data-storage.service';

@Component({
  selector: 'child-cell',
  template: `
    <div
      class="full-width full-height ag-grid-deviation-down"
      title="{{ status.title }}"
    >
      <span class="full-width">{{ status.value }}</span>
      <span
        class="text-right pull-right deviation_down_div"
        *ngIf="showDropdownIcon"
      >
        <em
          aria-hidden="true"
          class="fa fa-long-arrow-down ag-grid-deviation-down font-size-8"
          style="cursor: auto;"
        ></em>
      </span>
    </div>
  `
})
export class EnumStatusRendererComponent
  implements OnInit, AfterViewInit, ICellRendererAngularComp {
  public params: ICellRendererParams;
  public status: any = {};
  public validationMap: Array<any> = [];
  public showDropdownIcon = false;

  constructor(private dataStorageService: DataStorageService) {
    this.validationMap = this.dataStorageService.getValidationMessages();
  }

  agInit(params: ICellRendererParams): void {
    const currentColumn = params.colDef.field;
    if (params.colDef.cellEditorFramework !== undefined) {
      this.showDropdownIcon = true;
    } else {
      this.showDropdownIcon = false;
    }
    // console.log(this.showDropdownIcon);

    const currentRowData: object = params.data;
    const errorFlag = currentColumn + '_valid';

    if (
      currentRowData[errorFlag] !== undefined &&
      currentRowData[errorFlag] !== null
    ) {
      this.status['title'] = currentRowData[errorFlag].errorMessage;
      this.status['value'] = params.value;
    } else {
      this.getCellValue(params);
      this.status['title'] = params.value;
    }
  }

  getCellError(validFlag) {
    let matchedErrors: Array<any> = [];
    matchedErrors = this.validationMap.filter(
      matchedError => matchedError.VALIDATION_ID === validFlag
    );
    if (matchedErrors.length) {
      this.status['title'] = matchedErrors[0]['VALIDATION_MSG'];
    }
  }

  getCellValue(params) {
    this.params = params;
    const statusParams = params['values'].filter(
      status => status.id === params.value || status.value === params.value
    );
    if (statusParams.length) {
      this.status = statusParams[0];
    } else {
      this.status['value'] = params.value;
    }
  }

  refresh(): boolean {
    return false;
  }

  ngOnInit() { }

  ngAfterViewInit() { }
}
