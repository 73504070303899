import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Compose } from './shared/compose.model';
import { ComposeService } from './shared/compose.service';
import { COLLABORATION_TYPES } from 'app/modules/base-app/base-app.component';
import { BaseService } from 'app/modules/common/base.service';
import { ECalendarValue } from 'ng2-date-picker';
import { DateUtils } from 'app/modules/common/utills/DateUtils.service';
import { PayLoad } from 'app/modules/common/models/payload.model';
import { RequestData } from 'app/modules/common/models/request.model';
import { SubscriptionService } from 'app/modules/common/subscription.service';
import { SubscriptionLike } from 'rxjs';
import * as _ from 'lodash';
import { UploadDownloadService } from '../upload-download.services';
import { FileDownloadService } from '../file-download/shared/file-download.service';

@Component({
  moduleId: module.id,
  selector: 'compose',
  templateUrl: 'compose.component.html',
  styleUrls: ['./compose.component.sass'],
  providers: [ComposeService]
})
export class ComposeComponent implements OnInit {
  @Input() set tenantUsers(input) {
    if (input instanceof Array) {
      this._tenantUsers = input;
      const allUserFullNames = this._tenantUsers.map(
        tenantUser => tenantUser.fullName
      );
      this.allUserFullNamesOfTenant = allUserFullNames;
      this.createRequestPayLoad(this.allUserFullNamesOfTenant);
    }
  }
  get tenantUsers() {
    return this._tenantUsers;
  }
  public COLLABORATION_TYPES = COLLABORATION_TYPES;
  public _tenantUsers;
  @Input() public type;
  @Input() public data;
  @Input() public input;
  @Input() public newCollaborationExists;

  @Output() public onCollabPost = new EventEmitter<any>();
  @Output() public cancelEmitter = new EventEmitter<any>();
  @Output() public changeDetectedEmitter = new EventEmitter<any>();
  @Input() editedContent;

  public compose: Compose[] = [];
  public postText: any;
  public userInformation: any;
  public displayTopic: any;
  public isTask = false;
  public cancelOrClear = false;

  public toUser: any;
  public toUserObj: any;
  public pickedDate: any;
  public defaultDate: any;
  public selectedDateTimeInEpoch: any;
  public fileAttaches: any[] = [];
  public showUsers = false;
  public disableCompose = true;
  public disableClose = true;
  public allUserFullNamesOfTenant: string[] = [];
  public subscription: SubscriptionLike;
  public selectedMentions = [];
  public resetDrpdwn: any;
  public mentionConfig = {
    triggerChar: '@',
    labelKey: 'fullName',
    mentionSelect: this.formatter.bind(this)
  };
  public titleChanged = false;
  public messasgeChanged = false;
  public dateChanged = false;
  public toUserChanged = false;
  public filesUploaded = false;

  public dateCalendarConfig = {
    disableKeypress: true,
    showNearMonthDays: false,
    showTwentyFourHours: true,
    returnedValueType: ECalendarValue.Moment,
    drops: 'down',
    displayFormat: 'MM-DD-YYYY',
    closeOnSelect: true,
    min: DateUtils.getNextDayNextHour(),
    minTime: DateUtils.getNextDayNextHour()
  };

  public requestData: RequestData;
  public userName= '';

  constructor(
    private composeService: ComposeService,
    private baseService: BaseService,
    private subscriptionService: SubscriptionService,
    private uploadDownloadService: UploadDownloadService,
    private fileDownloadService: FileDownloadService
  ) {
    this.userInformation = this.baseService.getUserInformation();
    this.subscription = this.subscriptionService
      .getAutoSearchText()
      .subscribe(autoSearchText => {
        if (autoSearchText instanceof Array) {
          if (autoSearchText.length > 0) {
            this.toUserChanged = true;
            this.userName = autoSearchText[0].value;
            this.disableButtons();
            this.setEmitter();
            for (let i = 0; i < this._tenantUsers.length; i++) {
              if (this.userName === this._tenantUsers[i].fullName) {
                this.toUserObj = this._tenantUsers[i];
              }
            }
          }
        }
      });
  }
  public changeEmitter(value: boolean) {
    this.changeDetectedEmitter.emit(value);
  }

  public ngOnInit() {
    this.displayTopic = this.input.topic;
    this.pickedDate = DateUtils.getNextHour();
    this.defaultDate = DateUtils.getNextHour();
    this.selectedDateTimeInEpoch = '';
    if (
      this.newCollaborationExists !== null &&
      this.newCollaborationExists !== undefined &&
      this.newCollaborationExists === 'NC'
    ) {
      this.cancelOrClear = true;
      this.disableClose = false;
    }
  }
  public setEmitter() {
    if (
      this.isTask ||
      this.titleChanged ||
      this.messasgeChanged ||
      this.toUserChanged ||
      this.dateChanged ||
      this.filesUploaded
    ) {
      this.changeEmitter(true);
    } else {
      this.changeEmitter(false);
    }
  }
  public changeText(event) {
    this.postText = event;
    if (this.postText.length > 0) {
      this.messasgeChanged = true;
    } else {
      this.messasgeChanged = false;
    }
    this.setEmitter();
    this.disableButtons();
  }
  public changeTitle(event) {
    this.displayTopic = event;
    this.titleChanged = true;
    this.setEmitter();
    if (
      this.messasgeChanged ||
      this.toUserChanged ||
      this.dateChanged ||
      this.filesUploaded
    ) {
      this.disableButtons();
    }
  }
  public hasTask() {
    this.isTask = !this.isTask;
    this.setEmitter();
    if (!this.isTask) {
      // this.pickedDate = DateUtils.getNextHour();
      this.resetDrpdwn = Math.random();
      this.toUserChanged = false;
    }
    this.disableButtons();
  }
  public disableButtons() {
    this.setEmitter();
    if (
      this.messasgeChanged ||
      this.toUserChanged ||
      this.dateChanged ||
      this.filesUploaded
    ) {
      if (this.isTask && this.userName.length === 0) {
        this.disableCompose = true;
      } else {
        this.disableCompose = false;
      }
      this.disableClose = false;
    } else {
      this.disableCompose = true;
      if (this.newCollaborationExists === 'NC') {
        this.disableClose = false;
      } else {
        this.disableClose = true;
      }
    }
  }

  public changeDate(event) {
    this.selectedDateTimeInEpoch = DateUtils.getEpochTime(this.defaultDate);
    const selEpochDate = DateUtils.getEpochTime(event);
    if (
      DateUtils.getEpochTime(event) !== undefined &&
      this.selectedDateTimeInEpoch !== selEpochDate
    ) {
      this.dateChanged = true;
      if (this.isTask && this.userName.length === 0) {
        // dont enable the post button
      } else {
        this.disableButtons();
        this.selectedDateTimeInEpoch = selEpochDate;
      }
    } else {
      this.selectedDateTimeInEpoch = '';
    }
    this.setEmitter();
  }

  public postCollab() {
    this.disableCompose = true;
    const filteredMentions = [];
    const customString = this.postText;

    for (let i = 0; i < this.selectedMentions.length; i++) {
      if (customString.search(this.selectedMentions[i].fullName) !== -1) {
        filteredMentions.push(this.selectedMentions[i]);
      }
    }

    const finalMentions = _.uniqBy(filteredMentions, function(e) {
      return e.umsId;
    });
    if (this.isTask) {
      this.input['toUserObject'] = this.toUserObj;
      this.input['fileAttaches'] = this.fileAttaches;
      this.input['mentionedUsers'] = finalMentions;
      this.composeService
        .postCollaborationOrTask(
          this.postText,
          this.type,
          this.input,
          this.displayTopic,
          this.data.collaborationSummary === undefined ? undefined : this.data,
          this.userInformation,
          'task',
          this.selectedDateTimeInEpoch
        )
        .subscribe(
          data => {
            setTimeout(() => {
              this.onCollabPost.emit(data);
              this.changeEmitter(false);
            }, 500);
          },
          error => {
            console.error(
              'exception occurred while post collaboration with Task..'
            );
          },
          () => {}
        );
    } else {
      this.input['fileAttaches'] = this.fileAttaches;
      this.input['mentionedUsers'] = finalMentions;
      this.composeService
        .postCollaborationOrTask(
          this.postText,
          this.type,
          this.input,
          this.displayTopic,
          this.data.collaborationSummary === undefined ? undefined : this.data,
          this.userInformation,
          'collaboration',
          this.selectedDateTimeInEpoch
        )
        .subscribe(
          data => {
            setTimeout(() => {
              this.onCollabPost.emit(data);
              this.changeEmitter(false);
            }, 500);
          },
          error => {
            console.error('exception occurred while post collaboration..');
          },
          () => {}
        );
    }
  }

  public collabTextChange(ev) {
    try {
      this.postText = ev.target.value;
      this.changeEmitter(true);
    } catch (e) {
      console.info('could not set commentText');
    }
  }
  public cancelCollaboration() {
    if (
      this.newCollaborationExists !== null &&
      this.newCollaborationExists !== undefined &&
      this.newCollaborationExists === 'NC'
    ) {
      this.cancelOrClear = true;
      this.fileAttaches = [];
      this.postText = '';
      this.pickedDate = DateUtils.getNextHour();
      this.resetDrpdwn = Math.random();
      this.resetDisableFlags();
      this.disableButtons();
      this.cancelEmitter.emit(true);
    } else {
      this.fileAttaches = [];
      this.postText = '';
      this.pickedDate = DateUtils.getNextHour();
      this.resetDrpdwn = Math.random();
      if (this.isTask) {
        this.isTask = false;
      }
      this.resetDisableFlags();
      this.disableButtons();
      this.disableClose = true;
      this.disableCompose = true;
    }
  }
  public resetDisableFlags() {
    this.titleChanged = false;
    this.messasgeChanged = false;
    this.toUserChanged = false;
    this.dateChanged = false;
    this.filesUploaded = false;
  }

  public async onFileUpload(result: any) {
    await this.parseDownloadUrl(result);
    this.fileAttaches = this.fileAttaches.concat(result);
    if (this.fileAttaches.length > 0) {
      this.filesUploaded = true;
      this.disableButtons();
    }
  }

  public async parseDownloadUrl(input) {
    const data = {
      targetedType: 'Collaboration',
      formParams: {
        downloadUrl: input.downloadUrl,
        fileName: input.name
      },
      servicePath: '/downloadFile'
    };
    const url = await this.fileDownloadService.formDownloadUrl(data);
    input['finalDownloadUrl'] = url;
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public formatter($event) {
    this.selectedMentions.push($event);
    return `@${$event.fullName}` || '';
  }

  public removeAttachment(input: any) {
    this.fileAttaches.splice(this.fileAttaches.indexOf(input), 1);
    if (this.fileAttaches.length === 0) {
      this.filesUploaded = false;
    } else {
      this.filesUploaded = true;
    }
    this.disableButtons();
  }

  private createRequestPayLoad(options: string[]) {
    const payLoad = new PayLoad();
    payLoad.isWildCardSearch = true;
    this.requestData = new RequestData();
    this.requestData.placeHolder = 'Enter Value';
    this.requestData.type = 'multi';
    this.requestData.options = options;
    this.requestData.id = 'usernames';
    this.requestData.typeOfSelectSingle = true;
    this.requestData.payload = payLoad;
  }
}
