import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.sass']
})
export class ModalDialogComponent {

  @Input()
  componentId= '';

  isVisible = false;

  @Output()
  visibleChange = new EventEmitter<boolean>();

  @Input()
  get visible() {
    return this.isVisible
  }
  set visible(val) {
    this.isVisible = val;
    this.visibleChange.emit(this.isVisible);
  }

  @Input()
  title= '';

  @Input()
  data: any = { data: {} };


  constructor() {

  }

  ngOnInit() {
  }

  close() {
    this.visible = false;
  }
}
