import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PostRfxCreationService {

    private postRFXCreationDetails: any;

    public setPostRFXCreationDetails(postRFXCreationDetails: any) {
        this.postRFXCreationDetails = postRFXCreationDetails;
    }

    public retrievePostRFXCreationDetails(): any {
        return this.postRFXCreationDetails;
    }

    public isPostRFXCreation(): boolean {
        return this.postRFXCreationDetails instanceof Object;
    }

}
