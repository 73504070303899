import { Component, OnInit, Input } from '@angular/core';

import { KeynoteComments } from './shared/keynote-comments.model';
import { KeynoteCommentsService } from './shared/keynote-comments.service';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';

@Component({
  selector: 'keynote-comments',
  templateUrl: 'keynote-comments.component.html',
  styleUrls: ['./keynote-comments.component.sass'],
  providers: [KeynoteCommentsService]
})
export class KeynoteCommentsComponent implements OnInit {
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  keynoteComments: KeynoteComments[] = [];
  isClassVisible = true;
  @Input() keyinsights: any;
  @Input() comments: any;

  constructor(private keynoteCommentsService: KeynoteCommentsService) {}

  ngOnInit() {}
  ngOnchanges() {
    this.isClassVisible = false;
  }
  ngOnDestroy() {
    this.isClassVisible = false;
  }
}
