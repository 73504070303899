

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { ApiServiceProvider } from 'app/api-service';

@Injectable()
export class GenericChartService extends ApiServiceProvider {



}
