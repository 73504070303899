import { Injectable } from '@angular/core';
import { ApiServiceProvider } from 'app/api-service';
import { BaseService } from 'app/modules/common/base.service';
import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService';
import { environment } from 'environments/environment';

@Injectable()
export class PartnerScoreCardService {

    constructor(private apiService: ApiServiceProvider,
        private baseService: BaseService,
        private customerConfigurationService: CustomerConfigurationService) {
    }
    private env = environment;
    retrieveSupplierFinancials(suppliers: string[],dimension, view) {
        const payLoadObject: any = {
            targetedType: this.env.content.contentManagementService,
            method: 'POST',
            payload: suppliers,
            formParams: {
                dimension,
                view
            },
            servicePath: `${this.env.content.contentManagementService}/supplier/cards/byAlias`,
        }
        return this.apiService.post(payLoadObject);
    }

    retrieveSuppliers() {
        const tenantId = this.customerConfigurationService.getEnvironmentDetails().tenantId;
        const payLoadObject: any = {
            targetedType: 'CommonService',
            formParams: {
                tenantId
            },
            method: 'GET',
            servicePath: `/suppliers`,
        }
        return this.apiService.post(payLoadObject);
    }

    retrieveManufacturers() {
        const tenantId = this.customerConfigurationService.getEnvironmentDetails().tenantId;
        const payLoadObject: any = {
            targetedType: 'CommonService',
            formParams: {
                tenantId
            },
            method: 'GET',
            servicePath: `/mfrs`,
        }
        return this.apiService.post(payLoadObject);
    }

    retrieveSupplierUserRatings(rateeId: any, rateeSource: string, fiscalQuarter: string) {
        const userEmail = this.baseService.getUserInformation().email;
        const tenantId = this.customerConfigurationService.getEnvironmentDetails().tenantId;
        const payLoadObject: any = {
            targetedType: 'CommonService',
            method: 'GET',
            formParams: {
                rateeId: encodeURIComponent(rateeId),
                rateeSource,
                fiscalQuarter,
                tenantId,
                userEmail: encodeURIComponent(userEmail)
            },
            servicePath: `/userratings`,
        }
        return this.apiService.post(payLoadObject);
    }
    retrieveManufacturerUserRatings(rateeId: any, rateeSource: string, fiscalQuarter: string) {
        const userEmail = this.baseService.getUserInformation().email;
        const tenantId = this.customerConfigurationService.getEnvironmentDetails().tenantId;
        const payLoadObject: any = {
            targetedType: 'CommonService',
            method: 'GET',
            formParams: {
                rateeId:encodeURIComponent(rateeId),
                rateeSource,
                fiscalQuarter,
                tenantId,
                userEmail: encodeURIComponent(userEmail)
            },
            servicePath: `/mfr-ratings`,
        }
        return this.apiService.post(payLoadObject);
    }

    createSupplierUserRating(
        rateeId: any,
        rateeSource: string,
        fiscalQuarter: string,
        ratingObject: any
      ) {
        const createUserRatingPayLoad = {
          userEmail: this.baseService.getUserInformation().email,
          tenantId: this.customerConfigurationService.getEnvironmentDetails()
            .tenantId,
          rateeId:encodeURIComponent(rateeId),
          rateeSource,
          fiscalQuarter,
          metaInfoList: ratingObject,
        };
        const payLoadObject: any = {
          targetedType: 'CommonService',
          method: 'POST',
          payload: createUserRatingPayLoad,
          servicePath: `/insertuserrating`,
        };
        return this.apiService.post(payLoadObject);
      }
    createManufacturerUserRating(rateeId: any, rateeSource: string, fiscalQuarter: string, ratingMetaInfoId: number, value: number) {
        const createUserRatingPayLoad = {
            userEmail: this.baseService.getUserInformation().email,
            tenantId: this.customerConfigurationService.getEnvironmentDetails().tenantId,
            rateeId:encodeURIComponent(rateeId),
            rateeSource,
            fiscalQuarter,
            ratingMetaInfoId,
            value
        };
        const payLoadObject: any = {
            targetedType: 'CommonService',
            method: 'POST',
            payload: createUserRatingPayLoad,
            servicePath: `/mfr-rating`
        }
        return this.apiService.post(payLoadObject);
    }

    retrieveSupplierDetails(supplierName: string) {
        const tenantId = this.customerConfigurationService.getEnvironmentDetails().tenantId;
        const payLoadObject: any = {
            targetedType: 'CommonService',
            formParams: {
                supplierName: encodeURIComponent(supplierName),
                tenantId
            },
            method: 'GET',
            servicePath: `/supplierid`,
        }
        return this.apiService.post(payLoadObject);
    }
    retrieveManufacturerDetails(mfrName: string) {
        const tenantId = this.customerConfigurationService.getEnvironmentDetails().tenantId;
        const payLoadObject: any = {
            targetedType: 'CommonService',
            formParams: {
                mfrName: encodeURIComponent(mfrName),
                tenantId
            },
            method: 'GET',
            servicePath: `/mfrid`,
        }
        return this.apiService.post(payLoadObject);
    }

    retrieveScoreCard(supplier,dimension) {
        const payLoadObject: any = {
            targetedType: this.env.content.contentManagementService,
            method: 'POST',
            payload: [supplier],
            formParams: {
                dimension,
                view : 'Detail'
            },
            servicePath: `${this.env.content.contentManagementService}/supplier/cards/byAlias`,
        }
        return this.apiService.post(payLoadObject);
    }

    retrieveSupplierSpendSavingsInformation(supplierName: string) {
        const tenantId = this.customerConfigurationService.getEnvironmentDetails().tenantId;
        const payLoadObject: any = {
            targetedType: 'CommonService',
            formParams: {
                supplierName: encodeURIComponent(supplierName),
                tenantId
            },
            method: 'GET',
            servicePath: `/supplierfinancialdetails`,
        }
        return this.apiService.post(payLoadObject);
    }
    retrieveManufacturerSpendSavingsInformation(manufacturerName: string) {
        const tenantId = this.customerConfigurationService.getEnvironmentDetails().tenantId;
        const payLoadObject: any = {
            targetedType: 'CommonService',
            formParams: {
                mfrName: encodeURIComponent(manufacturerName),
                tenantId
            },
            method: 'GET',
            servicePath: `/mfrfinancial-details`,
        }
        return this.apiService.post(payLoadObject);
    }

    getSuggestions(filter, indexedKey) {
        if (filter instanceof Object) {
            filter.customerId = this.customerConfigurationService.getEnvironmentDetails().tenantId;
            filter.dataType = indexedKey;
        }
        const payload = {
            clientName: 'ElasticSearch',
            fileDownload: false,
            fileUpload: false,
            headerParams: {
                'content-type': 'application/json'
            },
            payload: filter,
            method: 'POST',
            servicePath: 'search/',
            targetedType: 'ELS'
        };
        return this.apiService.post(payload);
    }
      public sendMailForRR(selectedValue: string) {
        const payLoadObject: any = {
            targetedType: 'ContentManagementService',
            formParams: {
                fullName: this.baseService.getUserInformation().fullName || '',
                vendorName : encodeURIComponent(selectedValue)
            },
            method: 'GET',
            servicePath: 'ContentManagementService/marketing/mail',
        }
        return this.apiService.post(payLoadObject);
    }
}
