import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';

@Component({
    selector: 'child-cell',
    template: `<div>
    <div class="" (mouseenter)="showTooltip($event)" (mouseleave)="hideTooltip($event)">
        <div class="position-relative status-tooltip-value">
         <p class="block-title full-width pull-left margin-bottom-0">
        <a class="text-decoration-none">
            <b class="question-mark">?</b>
        </a>
    </p>
    </div>
    </div>
    <div *ngIf="qualitativeInsightsArray.length >0" class="custom-tooltip right" style="position: fixed;" id='helpToolTip{{val}}'>
     <div class="tip-content" [perfectScrollbar]="scrollBarConfiguration">
        <span *ngFor="let qData of qualitativeInsightsArray" class="">{{qData.qualitativeInsights}}</span>
        </div>
    </div>
    <div *ngIf ="qualitativeInsightsArray.length ===0" class="custom-tooltip right" style="position: fixed;" id='helpToolTip{{val}}'>
     <div class="tip-content" [perfectScrollbar]="scrollBarConfiguration">
        <span>No Insights</span>
     </div>
    </div>
    </div>
`,
})
export class DetailHelpRendererComponent {
    scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
    public params: any;
    val: any;
    qualitativeInsightsArray = []
    constructor() {


    }
    agInit(params: any): void {
        this.val = params.data.cmspkId;
        this.qualitativeInsightsArray = !!params.data.TOOLTIP ? params.data.TOOLTIP : []
    }

    showTooltip(event) {
        event.currentTarget.nextElementSibling.classList.add('show');
    }
    hideTooltip(event) {
        event.currentTarget.nextElementSibling.classList.remove('show');
    }
}
