import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { NgForm } from '@angular/forms';
import { ObjectType } from 'app/modules/common/interface/objectype';

@Component({
  selector: 'editor-cell',
  template: `
    <div #container tabindex="0">
      <select
        (change)="onChange($event)"
        class="form-control full-height full-width font-size-12"
      >
        <option
          value="{{ optionStatus.id }}"
          title="{{ optionStatus.value }}"
          [selected]="optionStatus.id === selectedOption?.id"
          [disabled]="optionStatus.id === -1"
          *ngFor="let optionStatus of options"
        >
          {{ optionStatus.value }}
        </option>
      </select>
    </div>
  `
})
export class DropdownStatusComponent
  implements ICellEditorAngularComp, AfterViewInit {
  private params: any;
  public options: Array<any> = [];
  public status: number;
  public selectedOption: ObjectType = {};

  @ViewChild('container', { read: ViewContainerRef, static: true })
  public container;

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
    setTimeout(() => {
      this.container.element.nativeElement.focus();
    });
  }

  agInit(params: any): void {
    this.params = params;
    this.options = params.values;

    if (params.customValues !== undefined) {
      this.options = params.customValues;
    } else {
      this.options = params.values;
    }

    const findOption: Array<any> = this.options.filter(
      match => match.id === Number(params.value)
    );
    if (findOption.length) {
      this.selectedOption = findOption[0];
    } else {
      this.selectedOption = this.options[0];
    }
  }

  setStatus(status: number): void {
    this.status = status;
  }

  getValue(): any {
    return this.status ? this.status : this.params.value;
  }

  onChange(event) {
    let changedOption: object;
    changedOption = this.options.find(
      match => match.id === Number(event.currentTarget.value)
    );
    if (changedOption !== undefined) {
      this.setStatus(changedOption['id']);
    }
    this.params.api.stopEditing();
  }
}
