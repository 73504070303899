import {
  AllModules,
  GridOptions,
  Module,
} from '@ag-grid-enterprise/all-modules';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilService } from 'app/modules/common/utills/util.service';
import { FileDownloadService } from 'app/modules/core/file-download/shared/file-download.service';
import {
  defaultNoRowsText,
  defaultSearchNoRowsText,
  ldUploadHistoryGridColumnDefs,
  ldUploadHistoryGridData,
  monthMap,
  patternKeyArray,
} from './ai-upload-history.constants';
import { AiUploadHistoryService } from './ai-upload-history.service';

@Component({
  selector: 'app-ai-upload-history',
  templateUrl: './ai-upload-history.component.html',
  styleUrls: ['./ai-upload-history.component.sass'],
  providers: [AiUploadHistoryService],
})
export class AiUploadHistoryComponent implements OnInit {
  @Output() closeHistoryWindow: EventEmitter<any> = new EventEmitter<any>();
  @Input('uploadType') uploadType = null;
  @Input() isDialogue = false;
  searchText = '';
  filterPattern = null;
  timePeriods = [
    {
      view: 0,
      dropdownId: 'currentQuarter',
      text: 'Current Quarter',
    },
    {
      view: -1,
      dropdownId: 'previousQuarter',
      text: 'Previous Quarter',
    },
    {
      view: -2,
      dropdownId: 'previous2Quarter',
      text: 'Previous Two Quarters',
    },
    {
      view: -3,
      dropdownId: 'previous3Quarter',
      text: 'Previous Three Quarters',
    },
  ];
  defaultTimePeriod = 'Current Quarter';
  selectedTimePeriod = this.timePeriods[0];
  ldUploadHistoryGrid: GridOptions;
  ldUploadHistoryGridData = [];
  modules: Module[] = AllModules;
  showGrid = false;
  constructor(
    private utilService: UtilService,
    private aiUploadHistoryService: AiUploadHistoryService,
    private fileDownloadService: FileDownloadService
  ) {}

  ngOnInit() {
    this.initGridOptions();
    this.loadHistory();
  }

  initGridOptions() {
    const gridOptions: GridOptions = {
      context: {
        componentParent: this,
      },
      rowData: JSON.parse(JSON.stringify(this.ldUploadHistoryGridData)),
      columnDefs: [...ldUploadHistoryGridColumnDefs],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      suppressMenuHide: true,
      pagination: true,
      // paginationPageSize: this.pageConfig.pageSize,
      overlayNoRowsTemplate: defaultNoRowsText,
      isExternalFilterPresent: this.isExternalFilterPresent.bind(this),
      doesExternalFilterPass: this.doesExternalFilterPass.bind(this),
      onGridReady: this.onGridReady.bind(this),
      // onSelectionChanged: this.onSelectionChanged.bind(this),
      // onPaginationChanged: this.onPaginationChanged.bind(this),
    };
    this.ldUploadHistoryGrid = { ...gridOptions };
  }

  loadHistory() {
    const requestPayload = {
      quarters: this.selectedTimePeriod.view,
      type: this.uploadType,
    };
    this.aiUploadHistoryService
      .getUploadHistory(requestPayload)
      .subscribe((loadDataHistoryResult) => {
        if (
          loadDataHistoryResult &&
          loadDataHistoryResult.responseStatus.code === 200
        ) {
          this.ldUploadHistoryGridData =
            loadDataHistoryResult.result instanceof Array
              ? loadDataHistoryResult.result
              : [];
          if (this.ldUploadHistoryGridData.length > 0) {
            this.ldUploadHistoryGridData = this.utilService.getsortedDscArray(
              loadDataHistoryResult.result,
              'uploadedDate'
            );
          }
        } else {
          this.ldUploadHistoryGridData = [];
        }
        this.loadGridData();
      });
  }

  loadGridData() {
    this.ldUploadHistoryGrid.rowData = this.ldUploadHistoryGridData;
    setTimeout(() => {
      this.showGrid = true;
    }, 10);
  }

  invokeParentMethod(type, params) {
    switch (type) {
      default:
        let servicePath = '';
        switch (type) {
          case 'rejectedRecords':
            servicePath = '/external/errorFile/download';
            break;
        }
        const runId = encodeURIComponent(params.id);
        this.downloadExcel({
          targetedType: 'ContentManagementService',
          formParams: {
            runId,
          },
          servicePath,
        });
        break;
    }
  }

  private async downloadExcel(payload) {
    console.log(payload);
    const dataObject = {
      targetedType: payload.targetedType,
      servicePath: payload.servicePath,
      formParams: payload.formParams,
    };
    const url = await this.fileDownloadService.formDownloadUrl(dataObject);
    const aTag = document.createElement('a');
    aTag.setAttribute('href', url);
    aTag.click();
    this.fileDownloadService.getDownloadClicked = false;
    return 'SUCCESS';
  }

  onGridReady(params) {
    // params.api.sizeColumnsToFit();
  }

  isExternalFilterPresent() {
    return true;
  }

  doesExternalFilterPass(node) {
    if (this.searchText) {
      switch (this.filterPattern) {
        case 'pattern1': {
          const dateStrArray = this.searchText.trim().toLowerCase().split(' ');
          const dateSubStr =
            Number(dateStrArray[1].split(',')[0]) < 10
              ? '0' + dateStrArray[1].split(',')[0]
              : dateStrArray[1].split(',')[0];
          const searchStr = `${dateStrArray[2]}-${
            monthMap[dateStrArray[0]]
          }-${dateSubStr}`;
          return node.data.uploadedDate.includes(searchStr);
        }
        case 'pattern2':
        case 'pattern6': {
          const dateStrArray = this.searchText.trim().toLowerCase().split(' ');
          const dateSubStr =
            Number(dateStrArray[1].split(',')[0]) < 10
              ? '0' + dateStrArray[1].split(',')[0]
              : dateStrArray[1].split(',')[0];
          const searchStr = `-${monthMap[dateStrArray[0]]}-${dateSubStr}`;
          return node.data.uploadedDate.includes(searchStr);
        }
        case 'pattern3': {
          const dateStrArray = this.searchText.trim().toLowerCase().split(' ');
          const searchStr = `${dateStrArray[2]}-${monthMap[dateStrArray[0]]}`;
          return node.data.uploadedDate.includes(searchStr);
        }
        case 'pattern4': {
          const dateStrArray = this.searchText.trim().toLowerCase().split(' ');
          const dateSubStr =
            Number(dateStrArray[0].split(',')[0]) < 10
              ? '0' + dateStrArray[0].split(',')[0]
              : dateStrArray[0].split(',')[0];
          const searchStr = `${dateStrArray[1]}-\\d{2}-${dateSubStr}`;
          const pattern = new RegExp(searchStr);
          return pattern.test(node.data.uploadedDate);
        }
        case 'pattern5': {
          const dateStrArray = this.searchText.trim().toLowerCase().split(' ');
          const searchStr = `-${monthMap[dateStrArray[0]]}-`;
          return (
            node.data.uploadedDate.includes(searchStr) ||
            Object.values(node.data).join('').includes(this.searchText)
          );
        }
        default:
          return Object.values(node.data).join('').includes(this.searchText);
      }
    }
    return true;
  }

  onSearch() {
    this.filterPattern = null;
    patternKeyArray.forEach((pattern) => {
      if (pattern.key.test(this.searchText.trim())) {
        this.filterPattern = pattern.name;
      }
    });
    // console.log(this.filterPattern);
    this.ldUploadHistoryGrid.api.onFilterChanged();
    if (!this.ldUploadHistoryGrid.api.getDisplayedRowCount()) {
      this.ldUploadHistoryGrid.overlayNoRowsTemplate =
        this.searchText.length > 0
          ? defaultSearchNoRowsText
          : defaultNoRowsText;
      this.ldUploadHistoryGrid.api.showNoRowsOverlay();
    } else {
      this.ldUploadHistoryGrid.api.hideOverlay();
      this.ldUploadHistoryGrid.overlayNoRowsTemplate = defaultNoRowsText;
    }
  }

  onClickClose() {
    this.closeHistoryWindow.emit(true);
  }

  onViewSelection(selectedTimePeriod) {
    if (this.selectedTimePeriod === selectedTimePeriod) {
      return;
    }
    this.showGrid = false;
    this.selectedTimePeriod = selectedTimePeriod;
    this.defaultTimePeriod = selectedTimePeriod.text;
    this.loadHistory();
  }
}
