import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { ApiServiceProvider } from 'app/api-service';
import { BehaviorSubject } from 'rxjs';
import { BaseService } from 'app/modules/common/base.service';
import { CustomerConfigurationService } from '../../../../common/customerConfigurationService';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { DataStorageService } from 'app/modules/common/data-storage.service';

@Injectable()
export class RfqListSearchService {
  envDetails: any;
  userEmail: any;
  focus: any;
  filterTypes = new BehaviorSubject<any>([]);
  scopeIds: any = [];
  constructor(
    private dataStorageService: DataStorageService,
    private apiService: ApiServiceProvider,
    private baseService: BaseService,
    private customerConfigurationService: CustomerConfigurationService,
    private scopeDataService: ScopeDataService
  ) {
    this.envDetails = this.customerConfigurationService.getEnvironmentDetails();
    this.focus = this.scopeDataService.getFocusValue();
    this.scopeDataService.getFocus().subscribe(val => {
      this.focus = val;
    })
  }

  getColumnList() {
    return ['cpn', 'mpn', 'sub_commodity', 'commodity', 'supplier', 'manufacturer', 'rfq_cust_name', 'rfq_status_id'];
  }

  displayToColumnMapping() {
    return {
      CPN: 'cpn',
      MPN: 'mpn',
      SUB_COMMODITY: 'sub_commodity',
      COMMODITY: 'commodity',
      SUPPLIER: 'supplier',
      MANUFACTURER: 'manufacturer',
      RFQ_CUST_DESC: 'rfq_cust_desc',
      RFQ_CUST_NAME: 'rfq_cust_name',
      RFQ_STATUS_ID: 'rfq_status_id'
    };
  }

  columnToDisplayMapping() {
    return {
      cpn: 'CPN',
      mpn: 'MPN',
      sub_commodity: 'SUB_COMMODITY',
      commodity: 'COMMODITY',
      supplier: 'SUPPLIER',
      manufacturer: 'MANUFACTURER',
      rfq_cust_desc: 'RFQ_CUST_DESC',
      rfq_cust_name: 'RFQ_CUST_NAME',
      rfq_status_id: 'RFQ_STATUS_ID'
    };
  }

  columnToPayloadKeyMapping() {
    return {
      CPN: 'cpn',
      MPN: 'mpn',
      SUB_COMMODITY: 'subCommodity',
      COMMODITY: 'commodity',
      SUPPLIER: 'supplier',
      MANUFACTURER: 'manufacturer',
      RFQ_CUST_DESC: 'description',
      RFQ_CUST_NAME: 'name',
      RFQ_STATUS_ID: 'status'
    };
  }

  getColumnQueryObject() {
    return {
      CPN: [],
      MPN: [],
      SUB_COMMODITY: [],
      COMMODITY: [],
      SUPPLIER: [],
      MANUFACTURER: [],
      RFQ_CUST_DESC: [],
      RFQ_CUST_NAME: [],
      RFQ_STATUS_ID: []
    };
  }

  getSearchResults(filter, dataType = '') {
    const scope = this.focus.toUpperCase();
    let payload: any;
    switch (scope) {
      case 'PRODUCT':
        let rfx_list: any = []
        this.dataStorageService.getIdsList().subscribe(value => {
          if (value !== undefined) {
            rfx_list = value
          }
        })
        if (filter instanceof Object) {
          filter['dataType'] = 'product_rfx_data';
          filter.query['columns'] = ['value'];
          filter['customerId'] = this.envDetails.customerId;
          filter['groupBy'] = 'attr_name';
          filter['toBeAggregated'] = true
          filter.filters = [{ columnFilters: { attr_name: ['cpn', 'mpn', 'manufacturer', 'supplier', 'cm_odm', 'product', 'rfx_name', 'rfx_status'], rfx_id: rfx_list } }]
          filter.filters[0]['userEmail'] = [this.baseService.getUserInformation().email]
        }
        break;
      case 'COMMODITY':
        if (filter instanceof Object) {
          filter['customerId'] = this.envDetails.customerId;
          filter['dataType'] = dataType ? dataType : 'rfq_data';
        }
        break;
    }
    payload = {
      clientName: 'ElasticSearch',
      fileDownload: false,
      fileUpload: false,
      headerParams: {
        'content-type': 'application/json'
      },
      payload: filter,
      method: 'POST',
      servicePath: 'search/',
      targetedType: 'ELS'
    }
    return this.apiService.post(payload);
  }

  getSearchScopeResults(filter, dataType = '') {
    this.userEmail = this.baseService.getUserInformation().email;
    const scope = this.focus.toUpperCase();
    let dataTypeString: any;
    this.dataStorageService.getSelectedScopeIds().subscribe(scopeIds => {
      if (scopeIds !== undefined) {
        this.scopeIds = scopeIds;
      }
    })
    this.dataStorageService.getSelectedTabInCreateFlow().subscribe(tabName => {
      switch (tabName) {
        case 'PRODUCT':
          dataTypeString = 'cms_product_data';
          break;
        case 'BOM':
          dataTypeString = 'cms_bom_data';

          break;
        case 'TRANSFORMATION':
          dataTypeString = 'cms_transformation_data';
          break;
      }
    })

    switch (scope) {
      case 'COMMODITY':
        if (filter instanceof Object) {
          filter['customerId'] = this.envDetails.customerId;
          filter['dataType'] = dataType ? dataType : 'rfq_data';
        }
        break;
      case 'PRODUCT':
        if (filter instanceof Object) {
          filter['customerId'] = this.envDetails.customerId;
          filter['dataType'] = dataTypeString;
        }
        if (this.scopeIds.length && (dataTypeString === 'cms_bom_data' || dataTypeString === 'cms_transformation_data')) {
          const columnFilters: any = { columnFilters: { product_group_id: this.scopeIds } }
          filter.filters.push(columnFilters)
        }
        break;
    }

    const payload = {
      clientName: 'ElasticSearch',
      fileDownload: false,
      fileUpload: false,
      headerParams: {
        'content-type': 'application/json',
        userEmail: this.userEmail
      },
      payload: filter,
      method: 'POST',
      servicePath: 'common/templates/cmsData/search',
      targetedType: 'RFQ'
    }
    return this.apiService.post(payload);
  }

  getFilterTypes() {
    const columns = this.getColumnList();
    const dbname = this.displayToColumnMapping();
    const types = [];
    for (const key in dbname) {
      const filterItem = {
        name: key,
        dbname: dbname[key],
        columnDataType: 'STRING',
        headerGrpId: null,
        filterMapId: null,
        filterId: null,
        startVal: null,
        endVal: null,
        sequence: null,
        operator: null,
        condition: null,
        filter: null,
        expression: null,
        globalFilterId: null,
        value: null,
        isDelete: null,
        filters: null
      }
      types.push(filterItem);
    }
    // this.filterTypes.next(types);
    // return this.filterTypes;
    return types;
  }
}
