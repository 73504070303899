export const riskTypes = [
  {
    label: 'Timeline Risks',
    id: 'timeline',
  },
  {
    label: 'BOM Risks',
    id: 'bom',
  },
  // {
  //   label: 'Custom Risks',
  //   id: 'custom'
  // }
];
export const riskTypesIds = {
  bom: 'bom',
  timeline: 'timeline',
  custom: 'custom',
};

export const timelineRisks = [
  {
    label: 'Checkpoint',
    id: 'checkpoint',
  },
  {
    label: 'Stages',
    id: 'stages',
  },
  {
    label: 'Maturity',
    id: 'maturity',
  },
];

export const bomRiskTypes = [
  {
    label: 'BOM Risks',
    id: 'bom',
  },
];

export const bomRepoRisks = [
  {
    label: 'Multisource',
    id: 'multisource',
  },

  {
    label: 'EOL',
    id: 'eol',
  },
  {
    label: 'Supplier Financial Risk',
    id: 'supplier_financial_risk',
  },
];

export const bomRisks = [
  // {
  //   label: 'BOM Health',
  //   id: 'health'
  // },
  // {
  //   label: 'EOL',
  //   id: 'eol'
  // },
  {
    label: 'Multisource',
    id: 'multisource',
  },

  {
    label: 'EOL',
    id: 'eol',
  },
  {
    label: 'Supplier Financial Risk',
    id: 'supplier_financial_risk',
  },
  {
    label: 'PSL/AVL',
    id: 'psl/avl',
  },
  // },
  // {
  //   label: 'Market',
  //   id: 'market'
  // }
];
export const customRisks = [];
export const subRiskTypes = {
  checkpoint: 'checkpoint',
  stages: 'stages',
  maturity: 'maturity',
  health: 'health',
  eol: 'eol',
  multisource: 'multisource',
  psl: 'psl/avl',
  market: 'market',
  supplier_financial_risk: 'supplier_financial_risk',
};

export const SUPPLIER_FINANCIAL_RISK = {
  riskByPart: {
    low: {
      min: '0',
      max: '0',
      color: 'yellow',
    },
    high: {
      min: '0',
      max: '0',
      color: 'red',
    },
    medium: {
      min: '0',
      max: '0',
      color: 'orange',
    },
  },
};

export const EOL_RISK = {
  category: {
    low: {
      min: '0',
      max: '0',
      color: 'yellow',
    },
    high: {
      min: '0',
      max: '0',
      color: 'red',
    },
    medium: {
      min: '0',
      max: '0',
      color: 'orange',
    },
  },
  riskByPart: {
    low: {
      min: '0',
      max: '0',
      color: 'yellow',
    },
    high: {
      min: '0',
      max: '0',
      color: 'red',
    },
    medium: {
      min: '0',
      max: '0',
      color: 'orange',
    },
  },
};
export const riskConfigMap = {
  SUPPLIER_FINANCIAL_RISK: {
    riskByPart: {
      low: {
        min: '0',
        max: '0',
        color: 'yellow',
      },
      high: {
        min: '0',
        max: '0',
        color: 'red',
      },
      medium: {
        min: '0',
        max: '0',
        color: 'orange',
      },
    },
  },
  EOL_RISK: {
    category: {
      low: {
        min: '0',
        max: '0',
        color: 'yellow',
      },
      high: {
        min: '0',
        max: '0',
        color: 'red',
      },
      medium: {
        min: '0',
        max: '0',
        color: 'orange',
      },
    },
    riskByPart: {
      low: {
        min: '0',
        max: '0',
        color: 'yellow',
      },
      high: {
        min: '0',
        max: '0',
        color: 'red',
      },
      medium: {
        min: '0',
        max: '0',
        color: 'orange',
      },
    },
  },
  NON_PREFERRED_SUPPLIER_RISK: {
    psls: [],
  },
  MULTI_SOURCE_RISK: {
    category: {
      low: {
        min: '0',
        max: '0',
        color: 'yellow',
      },
      high: {
        min: '0',
        max: '0',
        color: 'red',
      },
      medium: {
        min: '0',
        max: '0',
        color: 'orange',
      },
    },
    riskByPart: {
      low: {
        min: '0',
        max: '0',
        color: 'yellow',
      },
      high: {
        min: '0',
        max: '0',
        color: 'red',
      },
      medium: {
        min: '0',
        max: '0',
        color: 'orange',
      },
    },
  },
};
