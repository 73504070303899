import { Pipe } from '@angular/core';
import { ObjectUtils } from '../common/utills/ObjectUtils.service';

@Pipe({ name: 'deepFind' })
export class ReadNestedPropertyPipe {
  public transform(obj, readKey: string) {
    if (obj instanceof Object && (readKey || '').toString().trim().length > 0) {
      return ObjectUtils.deepFind(obj, readKey);
    }
    return null;
  }
}

// tslint:disable-next-line:max-classes-per-file
@Pipe({ name: 'removespace' })
export class RemoveSpacePipe {
  public transform(key: string) {
    if (key) {
      return key.replace(/\s/g, '');
    }
  }
}

// tslint:disable-next-line:max-classes-per-file
@Pipe({ name: 'orderBy' })
export class OrderbyPipe {
  public transform(obj, key: string) {
    if (key) {
      return obj.sort((obj1, obj2) => {
        return (
          +ObjectUtils.deepFind(obj1, key) - +ObjectUtils.deepFind(obj2, key)
        );
      });
    }
    return obj;
  }
}

// tslint:disable-next-line:max-classes-per-file
@Pipe({ name: 'fixDeciamalDigits' })
export class fixDeciamalDigits {
  public transform(num, digits?) {
    let formattedNumber = num;
    if (typeof num === 'number' && digits) {
      formattedNumber = num.toFixed(digits);
      return '$' + formattedNumber;
    } else {
      return formattedNumber;
    }
  }
}
// tslint:disable-next-line:max-classes-per-file
@Pipe({ name: 'deleteMatchedPartMatch' })
export class deleteMatchedPartMatch {
  public transform(response, partMatchDetails) {
    if (response && response.length > 0) {
      const index = response.findIndex(
        i =>
          i &&
          i.mfr &&
          partMatchDetails &&
          partMatchDetails.manufacturer &&
          i.mfr.toUpperCase() === partMatchDetails.manufacturer.toUpperCase() &&
          i.mpn === partMatchDetails.mpn
      );
      if (index >= 0) {
        response.splice(index, 1);
      }
    }
    return response;
  }
}
// tslint:disable-next-line:max-classes-per-file
@Pipe({ name: 'filterData' })
export class filterData {
  public transform(response, searchText) {
    if (searchText && searchText.length > 0) {
      return response.filter(eachAlternatePart =>
        Object.keys(eachAlternatePart).some(key => {
          if (typeof eachAlternatePart.mpnCost === 'number') {
            if (eachAlternatePart.mpnCost === 0) {
              eachAlternatePart.mpnCost = 'Cost Not Available';
            } else {
              eachAlternatePart.mpnCost =
                '$' + eachAlternatePart.mpnCost.toFixed(6);
            }
          }
          switch (key) {
            case 'mpn':
            case 'mfr':
            case 'mpnCost':
              return eachAlternatePart[key]
                .toUpperCase()
                .includes(searchText.toUpperCase());
          }
        })
      );
    }
    return response;
  }
}
// tslint:disable-next-line:max-classes-per-file
@Pipe({ name: 'previousStageLevel' })
export class PreviousStageLevelPipe {
  public transform(stagesList, index) {
    if (stagesList && stagesList[index - 1]) {
      stagesList[index]['stageLevel'] = stagesList[index - 1]['stageLevel'] + 1;
    }
    return stagesList[index]['stageLevel'];
  }
}
