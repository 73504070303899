import { Router } from '@angular/router';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { Component, Output, EventEmitter } from '@angular/core';
import { GridOptions } from '@ag-grid-community/core';
import { ApplicationURLS } from 'app/modules/common/applicationURL';
import { PartMatchingService } from 'app/modules/mi/partmatching/partmatching.service';
import { SubscriptionLike } from 'rxjs';
import { RmOpprisksService } from 'app/modules/mi/rawmaterialnew/rmOpprisks/shared/rmOpprisks.service';

@Component({
  selector: 'spendImpact-detail-link',
  template: `
    <button type="button" class="btn btn-link" (click)="getData(this.cellData)">
      {{ this.cellData }}
    </button>
  `
})
export class SpendImpactDetailLink {
  public params: any;
  public gridOptions: GridOptions;
  public viewBy;
  public cellData;
  constructor(
    private dataStorageService: DataStorageService,
    private rmOppriskService: RmOpprisksService
  ) {}
  agInit(params: any): void {

  }

  getData(data) {
  }
}
