import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-training',
    templateUrl: './training.component.html',
    styleUrls: ['./training.component.sass']
})
export class IsTrainingComponent implements OnInit {
    public training = false;
    constructor() {}
    public agInit(params: any): void {
        this.training = params && params.data && params.data.training;
    }

    public ngOnInit() {}
}
