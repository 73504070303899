import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { NgForm } from '@angular/forms';
import { ObjectType } from 'app/modules/common/interface/objectype';

@Component({
  selector: 'editor-cell',
  template: `
    <div #container tabindex="0">
      <select
        (change)="onChange($event)"
        class="form-control full-height full-width font-size-12"
      >
        <option value={{optionStatus}} [selected]="optionStatus == params.value"
        title={{optionStatus}}
          *ngFor="let optionStatus of options"
        >
          {{ optionStatus }}
        </option>
      </select>
    </div>
  `
})
export class SingleSelectSimComponent
  implements ICellEditorAngularComp, AfterViewInit {
  public params: any;
  public options: Array<any> = [];
  public status: number;
  public selectedOption: ObjectType = {};

  @ViewChild('container', { read: ViewContainerRef, static: true })
  public container;

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
    setTimeout(() => {
      this.container.element.nativeElement.focus();
    });
  }

  agInit(params: any): void {
    this.params = params;
    this.options = this.params.colDef.cellEditorParams.values;
  }

  setStatus(status: number): void {
    this.status = status;
  }

  getValue(): any {
    return this.status ? this.status : this.params.value;
  }

  onChange(event) {
    let selectedStatusObj: any;
    selectedStatusObj = {
      oldValue: this.params.value,
      newValue: event.target.value,
      currentColumn: this.params.colDef.field,
      currentRow: this.params.data
    };
    if (selectedStatusObj !== undefined) {
      this.setStatus(event.target.value);
    }
    this.params.api.stopEditing();
  }
}
