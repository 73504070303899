export const FILTERCONDITIONS = {
  date: [
    {
      displayName: 'Equal',
      val: 'Equal'
    },
    {
      displayName: 'Not Equal',
      val: 'Not Equal'
    },
    {
      displayName: 'Less than',
      val: 'Less than'
    },
    {
      displayName: 'Greater than',
      val: 'Greater than'
    },
    {
      displayName: 'In Range',
      val: 'In Range'
    }
  ],
  varchar: [
    {
      displayName: 'Equal',
      val: 'Equal'
    },
    {
      displayName: 'Not Equal',
      val: 'Not Equal'
    },
    {
      displayName: 'Contains',
      val: 'Contains'
    },
    {
      displayName: 'Not Contains',
      val: 'Not Contains'
    },
    {
      displayName: 'Starts with',
      val: 'Starts with'
    },
    {
      displayName: 'Ends with',
      val: 'Ends with'
    }
  ],
  text: [
    {
      displayName: 'Equal',
      val: 'Equal'
    },
    {
      displayName: 'Not Equal',
      val: 'Not Equal'
    },
    {
      displayName: 'Contains',
      val: 'Contains'
    },
    {
      displayName: 'Not Contains',
      val: 'Not Contains'
    },
    {
      displayName: 'Starts with',
      val: 'Starts with'
    },
    {
      displayName: 'Ends with',
      val: 'Ends with'
    }
  ],
  textmatch: [
    {
      displayName: 'Contains',
      val: 'Contains'
    },
    {
      displayName: 'Not Contains',
      val: 'Not Contains'
    }
  ],
  set: [
    {
      displayName: 'Equal',
      val: 'Equal'
    },
    {
      displayName: 'Not Equal',
      val: 'Not Equal'
    }
  ],
  multiselect: [
    {
      displayName: 'Equal',
      val: 'Equal'
    },
    {
      displayName: 'Not Equal',
      val: 'Not Equal'
    }
  ],
  static: [
    {
      displayName: 'Equal',
      val: 'Equal'
    },
    {
      displayName: 'Not Equal',
      val: 'Not Equal'
    }
  ],
  newautosearch: [
    {
      displayName: 'Equal',
      val: 'Equal'
    },
    {
      displayName: 'Not Equal',
      val: 'Not Equal'
    }
  ],
  singleselect: [
    {
      displayName: 'Equal',
      val: 'Equal'
    },
    {
      displayName: 'Not Equal',
      val: 'Not Equal'
    }
  ],
  boolean: [
    {
      displayName: 'true',
      val: true
    },
    {
      displayName: 'false',
      val: false
    }
  ],
  number: [
    {
      displayName: 'Equal',
      val: 'Equal'
    },
    {
      displayName: 'Not Equal',
      val: 'Not Equal'
    },
    {
      displayName: 'Less than',
      val: 'Less than'
    },
    {
      displayName: 'Less than or Equal',
      val: 'Less than or Equal'
    },
    {
      displayName: 'Greater than',
      val: 'Greater than'
    },
    {
      displayName: 'Greater than or Equal',
      val: 'Greater than or Equal'
    },
    {
      displayName: 'In Range',
      val: 'In Range'
    }
  ]
};
