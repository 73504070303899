import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

export interface LdDialogButton {
  title: string
  id: string
  primary?: boolean
  callback?: () => {}
  // icon?: string
  // isIcon?: boolean
  // className?: string
}

interface CustomSize {
  width: number
  height?: number
}

export interface LdDialog {
  title?: string
  logo?: string
  id: string
  size?: string
  customSize?: CustomSize
  template?: boolean
  type?: string
  message?: string
  buttons?: Array<LdDialogButton>
  copyright?: boolean
  modal?: boolean
  parentId?: string
  draggable?: boolean
  discardConfiguration?: LdDialog
  beforeClose?: (string?) => {}
  left?: string
  top?: string
  position?: string
  maxHeight?: string
  padding?: string
  boxShadow?: string
  overflow?: string
  justifyContent?: string
  // resizable?: boolean
}

export interface DialogCurrentState {
  id: string
  status: string
}

export enum DialogInfo {
  MODAL = 'modal',
  NONMODAL = 'non-modal',
}

export enum DialogType {
  INFORMATION = 'info',
  WARNING = 'error',
  ERROR = 'cancel',
  SUCCESS = 'check_circle',
  CONFIRMATION = 'help',
  BRAND = 'brand'
}

export enum DialogButtonName {
  SUCCESS = 'Success',
  YES = 'Yes',
  NO = 'No',
  SAVE = 'Save',
  CANCEL = 'Cancel',
  OK = 'OK',
  CLOSE = 'Close',
  SUBMIT = 'Submit',
  DELETE = 'Delete',
  UPDATE = 'Update',
  RESET = 'Reset',
  AWARD = 'Award',
  DISCARD = 'Discard',
  DISCARD_SAVE = 'Discard & Save',
  DISCARDAPPLY = 'Discard & Apply',
  SENDANYWAY = 'Send Anyway',
  GOTOVENDORS = 'Go to Manage Vendors',
  UPLOAD = 'Upload',
  SELECT = 'Select',
  SEND = 'Send',
  SAVESUP = 'Save Supplier',
  SAVE_VENDOR = 'Save Vendor',
  SAVEUSR = 'Save User',
  SHARE = 'Share',
  CREATE = 'Create',
  POST = 'Post',
  PROCEED = 'Proceed',
  CONTINUE = 'Continue',
  RUN = 'Run Now',
  GENERATE_PREVIEW = 'Generate Preview',
  INVITE = 'Invite',
  AUTOFILL = 'Autofill'
}

export enum DialogSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'xlarge',
}

export enum DialogStatus {
  OPEN = 'open',
  CLOSE = 'close',
  BEFOREOPEN = 'before-open',
  BEFORECLOSE = 'before-close',
}

@Injectable({ providedIn: 'root' })
export class DialogBoxService {
  private dialogs: any[] = []

  openDialogById = new Subject()
  selectedButton;
  zIndex = 2100

  discardDialogOpenStatus = false

  setZIndex(value: number): void {
    this.zIndex = value
  }

  getZIndex(): number {
    return this.zIndex
  }

  add(dialog: any) {
    // add dialog to array of active dialogs
    this.dialogs.push(dialog)
  }

  remove(id: string) {
    // remove dialog from array of active dialogs
    this.dialogs = this.dialogs.filter((x) => x.id !== id)
  }

  open(id: string) {
    // open dialog specified by id
    if (id && this.dialogs.length) {
      const dialog = this.dialogs.find((x) => x.id === id)
      if (dialog) {
        this.openDialogById.next({ id, status: DialogStatus.OPEN })
        dialog.open()
      }
    }
  }

  close(id: string) {
    // close dialog specified by id
    const dialog = this.dialogs.find((x) => x.id === id)
    if (dialog) {
      dialog.close()
    }
  }

  getIconName(type: string): string {
    if (type) {
      return DialogType[type]
    }
  }
}
