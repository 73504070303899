import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders, Route } from '@angular/compiler/src/core';
import { AuthGuard } from '../common/auth.guard'
import { RfqConfigComponent } from './rfq-config.component';
import { ShowTemplatesComponent } from './show-templates/show-templates.component';
import { CreateTemplateComponent } from './create-template/create-template.component';
import { EditTemplateComponent } from './edit-template/edit-template.component';
import { TemplateFeildsComponent } from './edit-template/template-feilds/template-feilds.component';
import { MetricsComponent } from './edit-template/metrics/metrics.component';
import { CanNavigateAway } from 'app/modules/srfq/create/shared/can-navigate-guard.service';
const rfqConfigRoutes: Routes = [{
    path: '', component: RfqConfigComponent,
    children: [
        { path: '', component: ShowTemplatesComponent,  canActivate: [AuthGuard], canDeactivate: [CanNavigateAway]  },
        {
            path: 'edit-template',
            component: EditTemplateComponent,

            children: [
                { path: 'fields', component: TemplateFeildsComponent, canActivate: [AuthGuard], canDeactivate: [CanNavigateAway]  },
                { path: 'metrics', component: MetricsComponent },
            ]
        }
    ]
}];

export const AppRoutingModule: ModuleWithProviders = RouterModule.forChild(rfqConfigRoutes);
