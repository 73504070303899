import { Injectable } from '@angular/core';
import { ApiServiceProvider } from '../../../../api-service';
import { CustomerConfigurationService } from '../../../common/customerConfigurationService';
import { Subject } from 'rxjs';

@Injectable()
export class ConfigureTenantService {
  constructor(private apiServiceProvider: ApiServiceProvider,private customerConfigurationService:CustomerConfigurationService) {
    // this.tenantId= this.customerConfigurationService.getEnvironmentDetails().tenantId ;
  }
  logoDownloadObj= new Subject<any>();
  // tenantId: any;
  logoData = new Subject<any>();

  customerConfig ={
    tenantId: null,
    advancedInformation: { commonInformation: [{
        id: 0,
        recordId: '7cdb4ebc7a544d17aa75f3b2a16abe2b',
        section: 'Customer Information',
        dependentOn: null,
        header: 'null',
        disabled: false,
        preLabel: 'Customer Name',
        postLabel: null,
        name: 'CustomerNameCustomerInformation',
        options: null,
        placeHolder: 'Please provide Customer Name',
        type: 'input',
        validation: [
          {
            required: true,
            message: 'Customer Name is required'
          }
        ],
        value: null
      },
      {
        id: 0,
        recordId: 'f37790fdef324baa885461becf4e3a65',
        section: 'Customer Information',
        dependentOn: null,
        header: 'Scope',
        disabled: false,
        preLabel: null,
        postLabel: 'Commodity',
        name: 'CommodityCustomerInformation',
        options: null,
        placeHolder: 'null',
        type: 'checkbox',
        validation: null,
        value: true
      },
      {
        id: 0,
        recordId: 'f58304ae70a242aea9a94644baa1fa04',
        section: 'Customer Information',
        dependentOn: null,
        header: 'Scope',
        disabled: false,
        preLabel: null,
        postLabel: 'Product',
        name: 'ProductCustomerInformation',
        options: null,
        placeHolder: 'null',
        type: 'checkbox',
        validation: null,
        value: false
      },
      {
        id: 0,
        recordId: '73fe1611e70846aa95301c4164fa130a',
        section: 'Customer Information',
        dependentOn: null,
        header: 'Scope',
        disabled: false,
        preLabel: 'Is default',
        postLabel: null,
        name: 'IsdefaultCustomerInformation',
        options: [
          {
            displayValue: 'Commodity',
            value: 'f37790fdef324baa885461becf4e3a65',
            id: 1
          },
          {
            displayValue: 'Product',
            value: 'f58304ae70a242aea9a94644baa1fa04',
            id: 2
          }
        ],
        placeHolder: 'Please select',
        type: 'select',
        validation: [
          {
            required: true,
            message: 'Is default is required'
          }
        ],
        value: null
      }
    ]},
    customerInformation: {
      commonInformation: [
        {
          id: 0,
          recordId: '7cdb4ebc7a544d17aa75f3b2a16abe2b',
          section: 'Customer Information',
          dependentOn: null,
          header: 'null',
          disabled: false,
          preLabel: 'Customer Name',
          postLabel: null,
          name: 'CustomerNameCustomerInformation',
          options: null,
          placeHolder: 'Please provide Customer Name',
          type: 'input',
          validation: [
            {
              required: true,
              message: 'Customer Name is required'
            }
          ],
          value: null
        },
        {
          id: 0,
          recordId: 'f37790fdef324baa885461becf4e3a65',
          section: 'Customer Information',
          dependentOn: null,
          header: 'Scope',
          disabled: false,
          preLabel: null,
          postLabel: 'Commodity',
          name: 'CommodityCustomerInformation',
          options: null,
          placeHolder: 'null',
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 0,
          recordId: 'f58304ae70a242aea9a94644baa1fa04',
          section: 'Customer Information',
          dependentOn: null,
          header: 'Scope',
          disabled: false,
          preLabel: null,
          postLabel: 'Product',
          name: 'ProductCustomerInformation',
          options: null,
          placeHolder: 'null',
          type: 'checkbox',
          validation: null,
          value: false
        },
        {
          id: 0,
          recordId: '73fe1611e70846aa95301c4164fa130a',
          section: 'Customer Information',
          dependentOn: null,
          header: 'Scope',
          disabled: false,
          preLabel: 'Is default',
          postLabel: null,
          name: 'IsdefaultCustomerInformation',
          options: [
            {
              displayValue: 'Commodity',
              value: 'f37790fdef324baa885461becf4e3a65',
              id: 1
            },
            {
              displayValue: 'Product',
              value: 'f58304ae70a242aea9a94644baa1fa04',
              id: 2
            }
          ],
          placeHolder: 'Please select',
          type: 'select',
          validation: [
            {
              required: true,
              message: 'Is default is required'
            }
          ],
          value: null
        }
      ],
      generalInformation: [
        {
          id: 0,
          recordId: '55d8c17ffbd04b538e6af028d83e77e3',
          section: 'General Information',
          dependentOn: null,
          header: 'null',
          disabled: false,
          preLabel: 'Industry Vertical',
          postLabel: null,
          name: 'IndustryVerticalGeneralInformation',
          options: [
            {
              displayValue: 'High Tech',
              value: 'a11bf0eba1064e5eae3db20e2fca5c08',
              id: 1
            },
            {
              displayValue: 'Consumer Electronics',
              value: '8f81453da527423290483adf295d0c10',
              id: 2
            },
            {
              displayValue: 'Industrial',
              value: '1e9d2c51703b49eaa6a23a51bc9ba766',
              id: 3
            },
            {
              displayValue: 'CPG',
              value: '25dda848bbb244f38756e5bd251f67e4',
              id: 4
            }
          ],
          placeHolder: 'Please select',
          type: 'select',
          validation: [
            {
              required: true,
              message: 'Industry Vertical is required'
            }
          ],
          value: null
        },
        {
          id: 0,
          recordId: '9986cd0a5ea94f60b998b1532a052938',
          section: 'General Information',
          dependentOn: null,
          header: 'null',
          disabled: false,
          preLabel: 'Spend Level',
          postLabel: null,
          name: 'SpendLevelGeneralInformation',
          options: [
            {
              displayValue: '0-250M',
              value: 'bed7af0a09c44e59aa0b9f6926e372a0',
              id: 1
            },
            {
              displayValue: '250M-1000M',
              value: '3fa8d555efef46a19638b8adac946bbf',
              id: 2
            },
            {
              displayValue: '1000M+',
              value: 'd7d07aa1c995457ba0985b56920c8d48',
              id: 3
            }
          ],
          placeHolder: 'Please select',
          type: 'select',
          validation: [
            {
              required: true,
              message: 'Spend Level is required'
            }
          ],
          value: null
        },
        {
          id: 0,
          recordId: '9bad0c5119f74b1b8bdfbdb05ca84749',
          section: 'General Information',
          dependentOn: null,
          header: 'null',
          disabled: false,
          preLabel: 'Currency',
          postLabel: null,
          name: 'CurrencyGeneralInformation',
          options: [
            {
              displayValue: 'USD',
              value: '4f76e49b199d4a6081fb0276e8b85ca0',
              id: 1
            }
          ],
          placeHolder: 'Please select',
          type: 'select',
          validation: [
            {
              required: true,
              message: 'Currency is required'
            }
          ],
          value: null
        },
        {
          id: 0,
          recordId: '0e77d47958584d9fa2680fae75d6732d',
          section: 'General Information',
          dependentOn: null,
          header: 'null',
          disabled: false,
          preLabel: 'Time Zone',
          postLabel: null,
          name: 'TimeZoneGeneralInformation',
          options: [
            {
              displayValue: 'PST',
              value: 'c6a56d2c1a884af0a7219c3cfa9a8442',
              id: 1
            }
          ],
          placeHolder: 'Please select',
          type: 'select',
          validation: [
            {
              required: true,
              message: 'Time Zone is required'
            }
          ],
          value: null
        },
        {
          id: 0,
          recordId: '433baa8223c041e68bec399d6412cb4d',
          section: 'General Information',
          dependentOn: null,
          header: 'null',
          disabled: false,
          preLabel: 'Base Country',
          postLabel: null,
          name: 'BaseCountryGeneralInformation',
          options: [
            {
              displayValue: 'United States',
              value: 'b761a8d5f00b4f0aa29fb8895328bc44',
              id: 1
            }
          ],
          placeHolder: 'Please select',
          type: 'select',
          validation: [
            {
              required: true,
              message: 'Base Country is required'
            }
          ],
          value: null
        },
        {
          id: 0,
          recordId: 'efb6929d03e64bba93f33e52276a8eaa',
          section: 'General Information',
          dependentOn: null,
          header: 'null',
          disabled: false,
          preLabel: 'Baseline',
          postLabel: null,
          name: 'BaselineGeneralInformation',
          options: [
            {
              displayValue: 'Previous Quarter',
              value: '7f6026aa928e4761a6b0bf8e702de613',
              id: 1
            },
            {
              displayValue: 'Previous Year last quarter',
              value: '4defe5161dfa481aa896e06ef61935b9',
              id: 2
            }
          ],
          placeHolder: 'Please select',
          type: 'select',
          validation: [
            {
              required: true,
              message: 'Baseline is required'
            }
          ],
          value: null
        },
        {
          id: 0,
          recordId: 'ab20663e77df4387bba45ba0e09a1c7f',
          section: 'General Information',
          dependentOn: null,
          header: 'null',
          disabled: false,
          preLabel: 'Opportunity Cap',
          postLabel: null,
          name: 'OpportunityCapGeneralInformation',
          options: null,
          placeHolder: 'Please provide Opportunity cap value',
          type: 'input',
          validation: [
            {
              required: true,
              message: 'Opportunity Cap is required'
            }
          ],
          value: null
        },
        {
          id: 0,
          recordId: '4ce86a4bc1414246afd5a2d7148d0a04',
          section: 'General Information',
          dependentOn: null,
          header: 'null',
          disabled: false,
          preLabel: 'UOM',
          postLabel: null,
          name: 'UOMGeneralInformation',
          options: [
            {
              displayValue: 'uom',
              value: '8751c9b589454da98929f16d01e499b9',
              id: 1
            }
          ],
          placeHolder: 'Please select',
          type: 'select',
          validation: [
            {
              required: true,
              message: 'UOM is required'
            }
          ],
          value: null
        },
        {
          id: 0,
          recordId: '3d6f066b1fe945a7ae7456bab40b2a37',
          section: 'General Information',
          dependentOn: null,
          header: 'null',
          disabled: false,
          preLabel: 'Competitiveness Level',
          postLabel: null,
          name: 'CompetitivenessLevelGeneralInformation',
          options: [
            {
              displayValue: 'ALL',
              value: '9a3541c2e9914fc5b680a0a5ba7eb56a',
              id: 1
            },
            {
              displayValue: 'SPEND',
              value: 'c3461f79da4a4babafe2197dbdb66eb6',
              id: 2
            },
            {
              displayValue: 'INDUSTRY',
              value: 'ad0a8d5d14db45e2be92b8ed68889d6c',
              id: 3
            }
          ],
          placeHolder: 'Please select',
          type: 'select',
          validation: [
            {
              required: true,
              message: 'Competitiveness Level is required'
            }
          ],
          value: null
        },
        {
          id: 0,
          recordId: '59374777239e4ee68c7c9fab707610e6',
          section: 'General Information',
          dependentOn: null,
          header: 'null',
          disabled: false,
          preLabel: 'Competitiveness Percentile',
          postLabel: null,
          name: 'CompetitivenessPercentileGeneralInformation',
          options: [
            {
              displayValue: '90',
              value: '010ae63a1f45441c97aaf18746e09031',
              id: 1
            },
            {
              displayValue: '70',
              value: '49f78eb69b7c4cf0ba97986f48b211a5',
              id: 2
            }
          ],
          placeHolder: 'Please select',
          type: 'select',
          validation: [
            {
              required: true,
              message: 'Competitiveness Percentile is required'
            }
          ],
          value: null
        },
        {
          id: 0,
          recordId: '94ab98bfd4af48cf81b813ee06236561',
          section: 'General Information',
          dependentOn: null,
          header: 'null',
          disabled: false,
          preLabel: 'Benchmark Savings Type',
          postLabel: null,
          name: 'BenchmarkSavingsTypeGeneralInformation',
          options: [
            {
              displayValue: 'Benchmark Cost Algorithm',
              value: '6d32ce4d8f3e4a54a7f5697ca20b8c27',
              id: 1
            },
            {
              displayValue: 'Lowest Benchmark Cost',
              value: '45f34155d72d47c5bdaf32526c733744',
              id: 2
            }
          ],
          placeHolder: 'Please select',
          type: 'select',
          validation: [
            {
              required: true,
              message: 'Benchmark Savings Type is required'
            }
          ],
          value: null
        },
        {
          id: 0,
          recordId: 'dd9d7cb534c842f69bedf7ec37275fff',
          section: 'General Information',
          dependentOn: null,
          header: 'null',
          disabled: false,
          preLabel: 'Negative Savings',
          postLabel: null,
          name: 'NegativeSavingsGeneralInformation',
          options: [
            {
              displayValue: true,
              value: 'd7b916617855445d8e1ecf8885526baa',
              id: true
            },
            {
              displayValue: false,
              value: '87538d9936514336b0c4f341b0c7b721',
              id: false
            }
          ],
          placeHolder: 'Please select',
          type: 'select',
          validation: [
            {
              required: true,
              message: 'Negative Savings is required'
            }
          ],
          value: null
        },
        {
          id: 0,
          recordId: 'bfa66003c7554d1bb4d107587e624811',
          section: 'General Information',
          dependentOn: null,
          header: 'null',
          disabled: false,
          preLabel: 'Fiscal Calender',
          postLabel: null,
          name: 'FiscalCalenderGeneralInformation',
          options: [
            {
              displayValue: 'January',
              value: '8da787a8bc534b9a93a12eb196d5a431',
              id: 0
            },
            {
              displayValue: 'February',
              value: '2e1ad19a8dab4424bf5a910cb6a73262',
              id: 11
            },
            {
              displayValue: 'March',
              value: '4cf2bcf2af08482680388e36eb234ada',
              id: 10
            },
            {
              displayValue: 'April',
              value: '7da541f9d223459b8f8806eaa44c6a29',
              id: 9
            },
            {
              displayValue: 'May',
              value: '4b84c03bd0a34a349e37a0def60d58e3',
              id: 8
            },
            {
              displayValue: 'June',
              value: '38af16c036614c5aa5802a86657455c6',
              id: 7
            },
            {
              displayValue: 'July',
              value: 'c52be6e22af24a2091dceacf4b0dd90a',
              id: 6
            },
            {
              displayValue: 'August',
              value: '1400f0de426d4b5e9f65241322ba54cf',
              id: 5
            },
            {
              displayValue: 'September',
              value: '2af54b8c6700493bb4d745a4f3ef7d57',
              id: 4
            },
            {
              displayValue: 'October',
              value: '04465df36c69420ea7870fe853a71f97',
              id: 3
            },
            {
              displayValue: 'November',
              value: '29852d1cd9ce469dbbdc702e65c37a50',
              id: 2
            },
            {
              displayValue: 'December',
              value: 'fc77403dc673457c9dcbd9dcaf895812',
              id: 1
            }
          ],
          placeHolder: 'Please select',
          type: 'select',
          validation: [
            {
              required: true,
              message: 'Fiscal Calender is required'
            }
          ],
          value: null
        },
        {
          id: 0,
          recordId: 'e35d508e7d594581818df094a0b2c338',
          section: 'General Information',
          dependentOn: null,
          header: 'null',
          disabled: false,
          preLabel: 'Cycle',
          postLabel: null,
          name: 'CycleGeneralInformation',
          options: [
            {
              displayValue: 'quarterly',
              value: '5c4e09eed0bc4245998ca5d309556ee1',
              id: 1
            }
          ],
          placeHolder: 'Please select',
          type: 'select',
          validation: [
            {
              required: true,
              message: 'Cycle is required'
            }
          ],
          value: null
        }
      ],
      dimensionsInformation: [
        {
          id: 1,
          recordId: 'ff36453da9dd4b52a7011375b0d870eb',
          section: 'Dimensions',
          dependentOn: [
            'f37790fdef324baa885461becf4e3a65'
          ],
          header: 'Commodity Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'CPN',
          name: 'CPNCommodityScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 2,
          recordId: 'efb7003f369f42cb8a56f2a220426fff',
          section: 'Dimensions',
          dependentOn: [
            'f37790fdef324baa885461becf4e3a65'
          ],
          header: 'Commodity Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'MPN',
          name: 'MPNCommodityScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 3,
          recordId: 'ffdf40226b7847e8812ebef4fb8eeff2',
          section: 'Dimensions',
          dependentOn: [
            'f37790fdef324baa885461becf4e3a65'
          ],
          header: 'Commodity Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'CM/ODM',
          name: 'CM/ODMCommodityScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 4,
          recordId: 'dbb16b76a09145e7a78ca8331611853e',
          section: 'Dimensions',
          dependentOn: [
            'f37790fdef324baa885461becf4e3a65'
          ],
          header: 'Commodity Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'Supplier',
          name: 'SupplierCommodityScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 5,
          recordId: '2be329a0bfd94f0085615d5c7110835e',
          section: 'Dimensions',
          dependentOn: [
            'f37790fdef324baa885461becf4e3a65'
          ],
          header: 'Commodity Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'Manufacturer',
          name: 'ManufacturerCommodityScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 6,
          recordId: '776e69ea27254e7ba7a807d53aa516ed',
          section: 'Dimensions',
          dependentOn: [
            'f37790fdef324baa885461becf4e3a65'
          ],
          header: 'Commodity Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'Product',
          name: 'ProductCommodityScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 7,
          recordId: '79a17a3cef1b4908b3e4f95070a1b17f',
          section: 'Dimensions',
          dependentOn: [
            'f37790fdef324baa885461becf4e3a65'
          ],
          header: 'Commodity Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'Sub Commodity',
          name: 'SubCommodityCommodityScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 8,
          recordId: '10dba15a749041d39f51e4bf7fdefabc',
          section: 'Dimensions',
          dependentOn: [
            'f37790fdef324baa885461becf4e3a65'
          ],
          header: 'Commodity Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'Commodity',
          name: 'CommodityCommodityScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 9,
          recordId: 'db64faa66cd84e4fab715e2423cc7184',
          section: 'Dimensions',
          dependentOn: [
            'f37790fdef324baa885461becf4e3a65'
          ],
          header: 'Commodity Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'Product Family',
          name: 'ProductFamilyCommodityScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 10,
          recordId: '0c3bf95eedef45f78a0d3485f5b64856',
          section: 'Dimensions',
          dependentOn: [
            'f37790fdef324baa885461becf4e3a65'
          ],
          header: 'Commodity Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'Component Control',
          name: 'ComponentControlCommodityScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 11,
          recordId: '36c50a828d67442c96f3bbf8e30c9e80',
          section: 'Dimensions',
          dependentOn: [
            'f37790fdef324baa885461becf4e3a65'
          ],
          header: 'Commodity Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'Commodity Manager',
          name: 'CommodityManagerCommodityScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 12,
          recordId: 'f00d05687a14496f8cfc5c678cbaaad6',
          section: 'Dimensions',
          dependentOn: [
            'f37790fdef324baa885461becf4e3a65'
          ],
          header: 'Commodity Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'Source',
          name: 'SourceCommodityScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 13,
          recordId: '6b934bd9587946f1a2a8d3ceb74df3d5',
          section: 'Dimensions',
          dependentOn: [
            'f37790fdef324baa885461becf4e3a65'
          ],
          header: 'Commodity Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'Real CPN',
          name: 'RealCPNCommodityScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 16,
          recordId: '110b2c4e5f27468fbc0cc6271d80f206',
          section: 'Dimensions',
          dependentOn: [
            'f37790fdef324baa885461becf4e3a65'
          ],
          header: 'Commodity Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'Classification',
          name: 'ClassificationCommodityScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 17,
          recordId: '4860bc4dc98e4094830e823a01206b2f',
          section: 'Dimensions',
          dependentOn: [
            'f37790fdef324baa885461becf4e3a65'
          ],
          header: 'Commodity Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'CM/ODM Region',
          name: 'CM/ODMRegionCommodityScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 18,
          recordId: '26ab13c7b0e147a4b942c207a2d7de31',
          section: 'Dimensions',
          dependentOn: [
            'f37790fdef324baa885461becf4e3a65'
          ],
          header: 'Commodity Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'CM/ODM Location',
          name: 'CM/ODMLocationCommodityScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 19,
          recordId: '7f7b99527aed45f082d0d47a659ac4da',
          section: 'Dimensions',
          dependentOn: [
            'f37790fdef324baa885461becf4e3a65'
          ],
          header: 'Commodity Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'CPN Description',
          name: 'CPNDescriptionCommodityScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 20,
          recordId: '9e55f54855d048d29db7d00f47629c47',
          section: 'Dimensions',
          dependentOn: [
            'f37790fdef324baa885461becf4e3a65'
          ],
          header: 'Commodity Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'Product Description',
          name: 'ProductDescriptionCommodityScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 1,
          recordId: '0f3472d2e5014334ad3f18e841932207',
          section: 'Dimensions',
          dependentOn: [
            'f58304ae70a242aea9a94644baa1fa04'
          ],
          header: 'Product Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'CPN',
          name: 'CPNProductScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 2,
          recordId: 'ab09965bfcec47218998f1335d4cd2a8',
          section: 'Dimensions',
          dependentOn: [
            'f58304ae70a242aea9a94644baa1fa04'
          ],
          header: 'Product Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'MPN',
          name: 'MPNProductScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 3,
          recordId: '4154d77e877e454cbf51d885560db80c',
          section: 'Dimensions',
          dependentOn: [
            'f58304ae70a242aea9a94644baa1fa04'
          ],
          header: 'Product Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'CM/ODM',
          name: 'CM/ODMProductScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 4,
          recordId: 'e629d63b42a9414fae6f9ce356d008e6',
          section: 'Dimensions',
          dependentOn: [
            'f58304ae70a242aea9a94644baa1fa04'
          ],
          header: 'Product Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'Supplier',
          name: 'SupplierProductScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 5,
          recordId: '6e5b057b9c9b41aabd5e48cd14b9527f',
          section: 'Dimensions',
          dependentOn: [
            'f58304ae70a242aea9a94644baa1fa04'
          ],
          header: 'Product Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'Manufacturer',
          name: 'ManufacturerProductScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 6,
          recordId: 'ca8b12e009cb4284b9502747599d66be',
          section: 'Dimensions',
          dependentOn: [
            'f58304ae70a242aea9a94644baa1fa04'
          ],
          header: 'Product Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'Product',
          name: 'ProductProductScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 7,
          recordId: '4a060caa0e174268b57d05372089aed8',
          section: 'Dimensions',
          dependentOn: [
            'f58304ae70a242aea9a94644baa1fa04'
          ],
          header: 'Product Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'Sub Commodity',
          name: 'SubCommodityProductScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 8,
          recordId: 'efe472b68daa4dd4ad9bad7b02c36da4',
          section: 'Dimensions',
          dependentOn: [
            'f58304ae70a242aea9a94644baa1fa04'
          ],
          header: 'Product Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'Commodity',
          name: 'CommodityProductScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 9,
          recordId: '9e7f597cde26412bb19b48cffdf99e80',
          section: 'Dimensions',
          dependentOn: [
            'f58304ae70a242aea9a94644baa1fa04'
          ],
          header: 'Product Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'Product Family',
          name: 'ProductFamilyProductScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 10,
          recordId: 'd3e6bc909b374e45a06b0aefe0f9dc03',
          section: 'Dimensions',
          dependentOn: [
            'f58304ae70a242aea9a94644baa1fa04'
          ],
          header: 'Product Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'Component Control',
          name: 'ComponentControlProductScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 11,
          recordId: '3db7fe3bab064336b42ec2e5b666901d',
          section: 'Dimensions',
          dependentOn: [
            'f58304ae70a242aea9a94644baa1fa04'
          ],
          header: 'Product Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'Commodity Manager',
          name: 'CommodityManagerProductScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 12,
          recordId: '37078e9f9fe648e88be4669b894ef24a',
          section: 'Dimensions',
          dependentOn: [
            'f58304ae70a242aea9a94644baa1fa04'
          ],
          header: 'Product Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'Source',
          name: 'SourceProductScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 13,
          recordId: 'e0ed460c9251413ca5d87efe6c14c94c',
          section: 'Dimensions',
          dependentOn: [
            'f58304ae70a242aea9a94644baa1fa04'
          ],
          header: 'Product Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'Real CPN',
          name: 'RealCPNProductScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 14,
          recordId: '4555ac45c76047d494da390795077f40',
          section: 'Dimensions',
          dependentOn: [
            'f58304ae70a242aea9a94644baa1fa04'
          ],
          header: 'Product Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'Transformation',
          name: 'TransformationProductScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 15,
          recordId: 'ad4131af90e344efa8c1058030c160e2',
          section: 'Dimensions',
          dependentOn: [
            'f58304ae70a242aea9a94644baa1fa04'
          ],
          header: 'Product Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'Cost Adders',
          name: 'CostAddersProductScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 16,
          recordId: '5a21061191d6400793829fe14f25409c',
          section: 'Dimensions',
          dependentOn: [
            'f58304ae70a242aea9a94644baa1fa04'
          ],
          header: 'Product Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'Classification',
          name: 'ClassificationProductScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 17,
          recordId: '99be2398e88042c59f47609b3faa2ed6',
          section: 'Dimensions',
          dependentOn: [
            'f58304ae70a242aea9a94644baa1fa04'
          ],
          header: 'Product Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'CM/ODM Region',
          name: 'CM/ODMRegionProductScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 18,
          recordId: 'b23936c0029b417c981672fd7dea5183',
          section: 'Dimensions',
          dependentOn: [
            'f58304ae70a242aea9a94644baa1fa04'
          ],
          header: 'Product Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'CM/ODM Location',
          name: 'CM/ODMLocationProductScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 19,
          recordId: 'a1c0a91ffcb84f60868ec066272f4836',
          section: 'Dimensions',
          dependentOn: [
            'f58304ae70a242aea9a94644baa1fa04'
          ],
          header: 'Product Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'CPN Description',
          name: 'CPNDescriptionProductScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 20,
          recordId: '64abe22511554be7948d2026a8134a23',
          section: 'Dimensions',
          dependentOn: [
            'f58304ae70a242aea9a94644baa1fa04'
          ],
          header: 'Product Scope Dimensions',
          disabled: false,
          preLabel: null,
          postLabel: 'Product Description',
          name: 'ProductDescriptionProductScopeDimensions',
          options: null,
          placeHolder: null,
          type: 'checkbox',
          validation: null,
          value: true
        },
        {
          id: 0,
          recordId: '7b368110f98b495898b5ece57218d6ec',
          section: 'Dimensions',
          dependentOn: null,
          header: 'Label Information',
          disabled: false,
          preLabel: 'CPN',
          postLabel: null,
          name: 'CPNLabelInformation',
          options: null,
          placeHolder: null,
          type: 'input',
          validation: null,
          value: 'CPN'
        },
        {
          id: 0,
          recordId: '6910b83e0e0a42778e472bb28154b503',
          section: 'Dimensions',
          dependentOn: null,
          header: 'Label Information',
          disabled: false,
          preLabel: 'MPN',
          postLabel: null,
          name: 'MPNLabelInformation',
          options: null,
          placeHolder: null,
          type: 'input',
          validation: null,
          value: 'MPN'
        },
        {
          id: 0,
          recordId: 'cb961818e9074834821a35df90e2d286',
          section: 'Dimensions',
          dependentOn: null,
          header: 'Label Information',
          disabled: false,
          preLabel: 'CM/ODM',
          postLabel: null,
          name: 'CM/ODMLabelInformation',
          options: null,
          placeHolder: null,
          type: 'input',
          validation: null,
          value: 'CM/ODM'
        },
        {
          id: 0,
          recordId: '94947a7426494f3fa693f2434327257b',
          section: 'Dimensions',
          dependentOn: null,
          header: 'Label Information',
          disabled: false,
          preLabel: 'Supplier',
          postLabel: null,
          name: 'SupplierLabelInformation',
          options: null,
          placeHolder: null,
          type: 'input',
          validation: null,
          value: 'Supplier'
        },
        {
          id: 0,
          recordId: 'e8ddaf92486840adb74ac1915a634beb',
          section: 'Dimensions',
          dependentOn: null,
          header: 'Label Information',
          disabled: false,
          preLabel: 'Manufacturer',
          postLabel: null,
          name: 'ManufacturerLabelInformation',
          options: null,
          placeHolder: null,
          type: 'input',
          validation: null,
          value: 'Manufacturer'
        },
        {
          id: 0,
          recordId: '851d5b85177c42e29f3bb75cff1aa6ad',
          section: 'Dimensions',
          dependentOn: null,
          header: 'Label Information',
          disabled: false,
          preLabel: 'Product',
          postLabel: null,
          name: 'ProductLabelInformation',
          options: null,
          placeHolder: null,
          type: 'input',
          validation: null,
          value: 'Product'
        },
        {
          id: 0,
          recordId: '5a9034f0a86640cb8cceb3c5ef14998f',
          section: 'Dimensions',
          dependentOn: null,
          header: 'Label Information',
          disabled: false,
          preLabel: 'Sub Commodity',
          postLabel: null,
          name: 'SubCommodityLabelInformation',
          options: null,
          placeHolder: null,
          type: 'input',
          validation: null,
          value: 'Sub Commodity'
        },
        {
          id: 0,
          recordId: '57df08f75c264c2585f06279170937ef',
          section: 'Dimensions',
          dependentOn: null,
          header: 'Label Information',
          disabled: false,
          preLabel: 'Commodity',
          postLabel: null,
          name: 'CommodityLabelInformation',
          options: null,
          placeHolder: null,
          type: 'input',
          validation: null,
          value: 'Commodity'
        },
        {
          id: 0,
          recordId: '7606fc1879af4cbe8eeba10be01e55ba',
          section: 'Dimensions',
          dependentOn: null,
          header: 'Label Information',
          disabled: false,
          preLabel: 'Product Family',
          postLabel: null,
          name: 'ProductFamilyLabelInformation',
          options: null,
          placeHolder: null,
          type: 'input',
          validation: null,
          value: 'Product Family'
        },
        {
          id: 0,
          recordId: 'a584e96ea9754bba9f314ba1b4f3e733',
          section: 'Dimensions',
          dependentOn: null,
          header: 'Label Information',
          disabled: false,
          preLabel: 'Component Control',
          postLabel: null,
          name: 'ComponentControlLabelInformation',
          options: null,
          placeHolder: null,
          type: 'input',
          validation: null,
          value: 'Component Control'
        },
        {
          id: 0,
          recordId: '68ef6a7d92bc49c2a468efc8da37d6da',
          section: 'Dimensions',
          dependentOn: null,
          header: 'Label Information',
          disabled: false,
          preLabel: 'Commodity Manager',
          postLabel: null,
          name: 'CommodityManagerLabelInformation',
          options: null,
          placeHolder: null,
          type: 'input',
          validation: null,
          value: 'Commodity Manager'
        },
        {
          id: 0,
          recordId: 'de04bb5bd058465fada31458225d7482',
          section: 'Dimensions',
          dependentOn: null,
          header: 'Label Information',
          disabled: false,
          preLabel: 'Source',
          postLabel: null,
          name: 'SourceLabelInformation',
          options: null,
          placeHolder: null,
          type: 'input',
          validation: null,
          value: 'Source'
        },
        {
          id: 0,
          recordId: '111b25936b6349239ca00b29198e60e4',
          section: 'Dimensions',
          dependentOn: null,
          header: 'Label Information',
          disabled: false,
          preLabel: 'Real CPN',
          postLabel: null,
          name: 'RealCPNLabelInformation',
          options: null,
          placeHolder: null,
          type: 'input',
          validation: null,
          value: 'Real CPN'
        },
        {
          id: 0,
          recordId: '1eade30d4e234c45bca200ac37e14737',
          section: 'Dimensions',
          dependentOn: null,
          header: 'Label Information',
          disabled: false,
          preLabel: 'Transformation',
          postLabel: null,
          name: 'TransformationLabelInformation',
          options: null,
          placeHolder: null,
          type: 'input',
          validation: null,
          value: 'Transformation'
        },
        {
          id: 0,
          recordId: '0c8da4be4e75482981a68a6776323ef6',
          section: 'Dimensions',
          dependentOn: null,
          header: 'Label Information',
          disabled: false,
          preLabel: 'Cost Adders',
          postLabel: null,
          name: 'CostAddersLabelInformation',
          options: null,
          placeHolder: null,
          type: 'input',
          validation: null,
          value: 'Cost Adders'
        },
        {
          id: 0,
          recordId: '66273f21c6dc48ee845fe545b815131a',
          section: 'Dimensions',
          dependentOn: null,
          header: 'Label Information',
          disabled: false,
          preLabel: 'Classification',
          postLabel: null,
          name: 'ClassificationLabelInformation',
          options: null,
          placeHolder: null,
          type: 'input',
          validation: null,
          value: 'Classification'
        },
        {
          id: 0,
          recordId: '8f59fa9b90204ef28a3ab7ad2f65d7ab',
          section: 'Dimensions',
          dependentOn: null,
          header: 'Label Information',
          disabled: false,
          preLabel: 'CM/ODM Region',
          postLabel: null,
          name: 'CM/ODMRegionLabelInformation',
          options: null,
          placeHolder: null,
          type: 'input',
          validation: null,
          value: 'CM/ODM Region'
        },
        {
          id: 0,
          recordId: 'c79015ed28d14bf589fb9bfaa4e2c516',
          section: 'Dimensions',
          dependentOn: null,
          header: 'Label Information',
          disabled: false,
          preLabel: 'CM/ODM Location',
          postLabel: null,
          name: 'CM/ODMLocationLabelInformation',
          options: null,
          placeHolder: null,
          type: 'input',
          validation: null,
          value: 'CM/ODM Location'
        },
        {
          id: 0,
          recordId: '0dbea239f5fa4a0ea132c2a52c1791b2',
          section: 'Dimensions',
          dependentOn: null,
          header: 'Label Information',
          disabled: false,
          preLabel: 'CPN Description',
          postLabel: null,
          name: 'CPNDescriptionLabelInformation',
          options: null,
          placeHolder: null,
          type: 'input',
          validation: null,
          value: 'CPN Description'
        },
        {
          id: 0,
          recordId: 'a546c45f936b4350baa4d230e8a46966',
          section: 'Dimensions',
          dependentOn: null,
          header: 'Label Information',
          disabled: false,
          preLabel: 'Product Description',
          postLabel: null,
          name: 'ProductDescriptionLabelInformation',
          options: null,
          placeHolder: null,
          type: 'input',
          validation: null,
          value: 'Product Description'
        }
      ]
    },
    features: [
      {
        section: 'Commodity Features',
        feature: 'Commodity',
        checked: true,
        recordId: 'f37790fdef324baa885461becf4e3a65',
        items: [
          {
            tabId: 1,
            text: 'Dashboard',
            value: 'cdc4ae7238ca4a8c9f89a99a1522ee92',
            checked: true,
            children: [
              {
                tabId: 85,
                text: 'Spend and Savings',
                value: '0ec2c7c2417f4e5584b5bb52109140a9',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 86,
                text: 'Supplier and Manufacturer',
                value: 'c9e36d9b317340e5b24f8a66abf983d9',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 87,
                text: 'Total Opportunities',
                value: '3e1f6139dd0247e1a86ef5b7fff3c180',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 88,
                text: 'Benchmark Opportunity',
                value: '95557e2d78274ec7912ad23fbff74076',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 89,
                text: 'Spend Pareto',
                value: 'd0335cac4149416a95a3029b3f777de9',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 90,
                text: 'Multi-Source Metrics',
                value: '6bcbebd2a03440d1892f384bda34de0a',
                checked: true,
                children: [

                ],
                selected: true
              }
            ],
            selected: true
          },
          {
            tabId: 2,
            text: 'Interactive Edit',
            value: 'c1f25ebed00b4fa5a2b07ad7fe5a412d',
            checked: true,
            children: [
              {
                tabId: 83,
                text: 'Edit',
                value: '790c64c401024b39b31672b508cb1259',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 84,
                text: 'Notifications',
                value: '4aac2f24cbb94ba59263ed16fb58128d',
                checked: true,
                children: [
                  {
                    tabId: 174,
                    text: 'Pending Approval',
                    value: 'a45f14f78d354a95ab780c789619d5a8',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 175,
                    text: 'Approved',
                    value: '292e26a298a14eeab603c46677ee795c',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 176,
                    text: 'Rejected',
                    value: '220d3579206d4cc490a37fb295097967',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 177,
                    text: 'System Approved',
                    value: '220d3579206d4cc490a37fb295097967',
                    checked: true,
                    children: null,
                    selected: true
                  }
                ],
                selected: true
              }
            ],
            selected: true
          },
          {
            tabId: 3,
            text: 'Spend Analytics',
            value: '10d6bdd0cbf0400ca3f158ac1f43b8a7',
            checked: true,
            children: [
              {
                tabId: 59,
                text: 'Spend',
                value: '987bc4de2fd14606ac6a0e12073b2f35',
                checked: true,
                children: [
                  {
                    tabId: 146,
                    text: 'Spend Overview',
                    value: '5e88f6c447d547ab80d59419bba66e1d',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 147,
                    text: 'Spend Drivers',
                    value: '5cf39c9d3bfd448a94877cbd00e04e8b',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 148,
                    text: 'Savings Drivers',
                    value: '140ebd8bf4724750a7ba5210ce78a931',
                    checked: true,
                    children: null,
                    selected: true
                  }
                ],
                selected: true
              },
              {
                tabId: 60,
                text: 'Cost',
                value: 'b01e57ac518840ce8ab74d3726aebb48',
                checked: true,
                children: [
                  {
                    tabId: 150,
                    text: 'MPN Cost',
                    value: '998c5696469f43079e367d6eede2f5bd',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 151,
                    text: 'CPN Cost',
                    value: 'd18257f261df40b19d59ed28094fc680',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 152,
                    text: 'Product Allocations',
                    value: '2d0956fafc014a0dba3d9d0caeb26f8a',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 153,
                    text: 'Master Cost Table',
                    value: '6da493737d41423fb8573e922695bc82',
                    checked: true,
                    children: null,
                    selected: true
                  }
                ],
                selected: true
              },
              {
                tabId: 61,
                text: 'Forecast',
                value: '9f4e41503c6f4f9199bb80f089495770',
                checked: true,
                children: [
                  {
                    tabId: 158,
                    text: 'Forecast Lookup',
                    value: '1239c38ffcae4f83ad28961e60404dd8',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 159,
                    text: 'Forecast Drivers',
                    value: '47d9ea54f3364064a2f0e06cc069a237',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 160,
                    text: 'Forecast Change',
                    value: '293a63311019484c947fa5181816d023',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 161,
                    text: 'Forecast Vs.Actual',
                    value: '3b376b61144847348bb94984311692ac',
                    checked: true,
                    children: null,
                    selected: true
                  }
                ],
                selected: true
              }
            ],
            selected: true
          },
          {
            tabId: 4,
            text: 'Market Intelligence',
            value: '7431a97a135a49b389219e3d70e7112c',
            checked: true,
            children: [
              {
                tabId: 62,
                text: 'Benchmarking',
                value: 'a9e2ee0892ba45dba1f8b525b26a3e22',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 63,
                text: 'Competitiveness',
                value: '30cba9e8c92d4a40b70184a273cac9cb',
                checked: true,
                children: [
                  {
                    tabId: 162,
                    text: 'Summary',
                    value: '96017537ba434af3b7ac9f5465620fc5',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 163,
                    text: 'Overall Performance',
                    value: 'ffb75fb7b3f74ae881710fcbc1096edc',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 164,
                    text: 'Sub Commodity Performance',
                    value: '09f685ce4ab3482398b9024bec0c67d9',
                    checked: true,
                    children: null,
                    selected: true
                  }
                ],
                selected: true
              },
              {
                tabId: 64,
                text: 'Part Matching',
                value: '23ffe15dd03c47139b40dd6abac8a7bf',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 65,
                text: 'Raw Materials',
                value: '891fd07f7f7244ef81026dcde8f137f8',
                checked: true,
                children: [
                  {
                    tabId: 165,
                    text: 'Trends',
                    value: 'f53a868a503e4ef4a586ffc0c4241244',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 166,
                    text: 'Opportunities/Risks',
                    value: 'fafe50b4ec464b6fa7393ebe742da56b',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 167,
                    text: 'Mapping',
                    value: '91fa58d3c3f6450a83f5c9b4c64792b7',
                    checked: true,
                    children: null,
                    selected: true
                  }
                ],
                selected: true
              }
            ],
            selected: true
          },
          {
            tabId: 5,
            text: 'Opportunities',
            value: 'ee02c0e65d764c208409918f9c9835bb',
            checked: true,
            children: [
              {
                tabId: 77,
                text: 'Driver',
                value: 'b86578916f294c1a879bcc4ce6fd5f48',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 78,
                text: 'Opportunities/Risks',
                value: 'df7377b6b36a4aba8a5f3d43b44e8e05',
                checked: true,
                children: [

                ],
                selected: true
              }
            ],
            selected: true
          },
          {
            tabId: 6,
            text: 'Negotiation Playbook',
            value: '0c659e59b6204e4f886e8f323f75e040',
            checked: true,
            children: [
              {
                tabId: 71,
                text: 'Summary',
                value: 'e4c9723d5e0d45f0b1d0218be48911e2',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 72,
                text: 'Details',
                value: 'bb962965a83f4e359a6d7736f4b206b0',
                checked: true,
                children: [

                ],
                selected: true
              }
            ],
            selected: true
          },
          {
            tabId: 7,
            text: 'Lookup',
            value: 'b55aaaecaf3b47d58a78a961c17564f8',
            checked: true,
            children: [

            ],
            selected: true
          },
          {
            tabId: 8,
            text: 'Smart RFX',
            value: '46568ce33a92492cad478093fbcd27c4',
            checked: true,
            children: [
              {
                tabId: 73,
                text: 'Details',
                value: '9e2bdb27977848c984932388433de9e1',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 74,
                text: 'Simulation',
                value: 'cd588f0ab1ab4aa4b25a1afd782cc16d',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 75,
                text: 'Award',
                value: 'f063f976424b4c4fab235db01a0e9c30',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 76,
                text: 'Supplier Messaging',
                value: '4a572dc13c444a5ebe9787f375eeba3f',
                checked: true,
                children: [

                ],
                selected: true
              }
            ],
            selected: true
          },
          {
            tabId: 9,
            text: 'Reports',
            value: '1077f95949c4452b981566e6e14574ec',
            checked: true,
            children: [
              {
                tabId: 103,
                text: 'Baseline CPN/Predecessor Part',
                value: 'f3bbe68e93ca4693bee3fdaa59a5b97e',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 104,
                text: 'CM Tier 2 Cost',
                value: 'fa84eca50ad442e49b9ad700ee75b9b9',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 105,
                text: 'BOM Compare',
                value: '17638901edfb42b4a0a956ae52108863',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 106,
                text: 'CPN Forecast Report',
                value: '5e6a36bce8a446be9c292518fcb6ee7a',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 107,
                text: 'PPV Report - All Changes',
                value: 'e88c3edb7adb4b1380c53d2e13fd3bbe',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 108,
                text: 'PPV For Approval',
                value: '305428d676ff43b5a91f6774de24dee5',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 109,
                text: 'PPV Compare Report',
                value: '19be1bb09e214bbf8fe3b2df4273ca9f',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 110,
                text: 'Master Cost Changes',
                value: '5c11b73a6e6a40edb8c8e955bf9d1919',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 111,
                text: 'GCM Template',
                value: '4b7ee432e6904681b243e91bd1491e1f',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 112,
                text: 'Where Used Report',
                value: '7419e949439e471692c0002cfdaecf2e',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 113,
                text: 'Distributor Benchmark',
                value: '1bc184cdca4a4c67be7b962d3856e701',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 114,
                text: 'Forecast and Cost Report',
                value: '67f282c34b0e4ab3aa71975fbc1a0364',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 102,
                text: 'Commodity Lead Report',
                value: '178b15d59db8463caa0a25d96b15b67a',
                checked: true,
                children: [

                ],
                selected: true
              }
            ],
            selected: true
          },
          {
            tabId: 10,
            text: 'Augmented Intelligence',
            value: '4c6142084f9a45118f43c07eca27c1ce',
            checked: true,
            children: [
              {
                tabId: 80,
                text: 'Opportunities/Risks',
                value: 'd84da2874904489da3115f6a3d37fae1',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 81,
                text: 'Alerts Threshold',
                value: '63d64f26c40b494d83af1621db2be311',
                checked: true,
                children: [
                  {
                    tabId: 170,
                    text: 'Basic',
                    value: '88ca5d479c404216a31a3f74d0f06691',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 171,
                    text: 'Advanced',
                    value: '2bcd5612900040d0af3265f47b2ee206',
                    checked: true,
                    children: null,
                    selected: true
                  }
                ],
                selected: true
              },
              {
                tabId: 82,
                text: 'Negotiation Levers',
                value: '17b924867e8f4966910c24d293360b40',
                checked: true,
                children: [
                  {
                    tabId: 172,
                    text: 'Levers',
                    value: '548b0f8e9521409aa6f37a5cf9888842',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 173,
                    text: 'Meta Data',
                    value: '507cd06a69894e3e86be81b99279a952',
                    checked: true,
                    children: null,
                    selected: true
                  }
                ],
                selected: true
              }
            ],
            selected: true
          },
          {
            tabId: 11,
            text: 'RFQ Congiguration',
            value: '4a608d17dfe64d2c888b2db6b2acfb70',
            checked: true,
            children: [
              {
                tabId: 66,
                text: 'Template Feilds',
                value: '88599fd290a74f17a19e7e4c9b82b8ac',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 67,
                text: 'Metrics',
                value: 'c37ca66911e0424aa15b71de007ebad2',
                checked: true,
                children: [

                ],
                selected: true
              }
            ],
            selected: true
          },
          {
            tabId: 12,
            text: 'Global Preferences',
            value: '4db6b2aa27d842298be0582934e4128d',
            checked: true,
            children: [
              {
                tabId: 68,
                text: 'Opportunity Alerts',
                value: '0c076f2964e7468a8b63de9b397bc21c',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 69,
                text: 'Access Control',
                value: 'c97585986f9e470bad548ea07ccd3d7e',
                checked: true,
                children: [

                ],
                selected: true
              }
            ],
            selected: true
          },
          {
            tabId: 13,
            text: 'Close RFQ',
            value: 'ee0bcc9719f04ad0859a3b0bc4c452ba',
            checked: true,
            children: [
              {
                tabId: 70,
                text: 'Details',
                value: 'f86a4073a9ec466c939d3e197c6245da',
                checked: true,
                children: [

                ],
                selected: true
              }
            ],
            selected: true
          },
          {
            tabId: 14,
            text: 'OpportunitiesICS',
            value: 'a7df26ff9b5c4c60bb5feb10057bf039',
            checked: true,
            children: [
              {
                tabId: 79,
                text: 'Opportunities/Risk',
                value: '9e0b182ac389464389132a4a870d01e2',
                checked: true,
                children: [

                ],
                selected: true
              }
            ],
            selected: true
          },
          {
            tabId: 15,
            text: 'Opportunities Widgets',
            value: 'e4a35773c662436f8a984d1d3b6a44af',
            checked: true,
            children: [
              {
                tabId: 91,
                text: 'Opportunity by Drivers',
                value: 'd85794c5026044baa46c6381a7c2e042',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 95,
                text: 'MPN',
                value: 'd1e99ffc8cab47b9b3c64c290c8e277e',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 97,
                text: 'Manufacturer',
                value: 'bec7071d207c49ca98bffed5814e0533',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 98,
                text: 'Supplier',
                value: 'd10e14b5091b4372b47f397b1c88927e',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 99,
                text: 'Sub Commodity',
                value: '37c2f746a7d34146a73c8f654edd73a2',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 100,
                text: 'CPN',
                value: 'c63a3cdcaf6a4f2f94c4d8087e03f26b',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 101,
                text: 'Commodity Manager',
                value: 'e3948f3212a24773b049e35fd82ee9ab',
                checked: true,
                children: [

                ],
                selected: true
              }
            ],
            selected: true
          }
        ]
      },
      {
        section: 'Product Features',
        feature: 'Product',
        checked: false,
        recordId: 'f58304ae70a242aea9a94644baa1fa04',
        items: [
          {
            tabId: 1,
            text: 'Dashboard',
            value: 'd469e5e3e1de4597822ebd37dbb58496',
            checked: true,
            children: [
              {
                tabId: 85,
                text: 'Spend and Savings',
                value: '3c03afe7294a4e66bd7ded36abce02c9',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 86,
                text: 'Supplier and Manufacturer',
                value: '21fe2329a7aa41a7bbaa2ad3ac0f214e',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 87,
                text: 'Total Opportunities',
                value: '66b3d0a3cf3e4235b1cf224a5f860518',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 88,
                text: 'Benchmark Opportunity',
                value: '8454093b0f4b467791b1ce6a2868d7ed',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 89,
                text: 'Spend Pareto',
                value: '2b7ebf2d45794b0cbe2bdf251913006b',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 90,
                text: 'Multi-Source Metrics',
                value: 'e46241d9be9142d9b0458a190f50f3f4',
                checked: true,
                children: [

                ],
                selected: true
              }
            ],
            selected: true
          },
          {
            tabId: 2,
            text: 'Interactive Edit',
            value: '541ed7fe12e6498d87105633f22f6105',
            checked: true,
            children: [
              {
                tabId: 83,
                text: 'Edit',
                value: '9df74267117f460e8982e631fb6e1d0a',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 84,
                text: 'Notifications',
                value: '4b1dfc372ad448968feb0627d42b0a8d',
                checked: true,
                children: [
                  {
                    tabId: 174,
                    text: 'Pending Approval',
                    value: '9dca39cf655c410c9652ab87c12410ac',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 175,
                    text: 'Approved',
                    value: 'f7d2d893ddae4c62a00ba2af7cff2bd2',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 176,
                    text: 'Rejected',
                    value: '9d8fb4cd4e8a4a5ba679ee81b51130e1',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 177,
                    text: 'System Approved',
                    value: '220d3579206d4cc490a37fb295097967',
                    checked: true,
                    children: null,
                    selected: true
                  }
                ],
                selected: true
              }
            ],
            selected: true
          },
          {
            tabId: 3,
            text: 'Spend Analytics',
            value: '53777b67232d49c485f827135d6d3f16',
            checked: true,
            children: [
              {
                tabId: 59,
                text: 'Spend',
                value: '7bf822e021164269a27cb24fe19497f5',
                checked: true,
                children: [
                  {
                    tabId: 146,
                    text: 'Spend Overview',
                    value: 'f3ab8dc8423343f596ce29902a4298ba',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 147,
                    text: 'Spend Drivers',
                    value: '7709a8e5d013495988ef9f919271c9b5',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 148,
                    text: 'Savings Drivers',
                    value: 'fe50cec3acc14918b1abfbf9f3b91da5',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 149,
                    text: 'Spend Elements',
                    value: '9aa3417e176e470d922b17e76566608b',
                    checked: true,
                    children: null,
                    selected: true
                  }
                ],
                selected: true
              },
              {
                tabId: 60,
                text: 'Cost',
                value: '6a26c5c9d7724fe1bf8a1486bdbffe00',
                checked: true,
                children: [
                  {
                    tabId: 154,
                    text: 'Product Cost',
                    value: '8b88398ad31a4527aabfb7ce0059f68a',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 155,
                    text: 'Costed BOM',
                    value: '735f81c43bf342d7b7f764f0032b7891',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 156,
                    text: 'Transformation Cost',
                    value: '208a20f2d2d14f0d82a51949725e98eb',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 157,
                    text: 'Cost Adders',
                    value: '5fed704c3ccb44e3b928863075dc657b',
                    checked: true,
                    children: null,
                    selected: true
                  }
                ],
                selected: true
              },
              {
                tabId: 61,
                text: 'Forecast',
                value: '144288ec672a42bdb8e71b6bae2a12e8',
                checked: true,
                children: [
                  {
                    tabId: 158,
                    text: 'Forecast Lookup',
                    value: '7daefa6f6cd341a1bc543d7ad774a093',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 159,
                    text: 'Forecast Drivers',
                    value: 'a3259af38ad94539b62533fa0f529fd8',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 160,
                    text: 'Forecast Change',
                    value: '7407dd71eb47474dbb61526d13f6bf68',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 161,
                    text: 'Forecast Vs.Actual',
                    value: '338291a3560d4aba83d24df3d6797353',
                    checked: true,
                    children: null,
                    selected: true
                  }
                ],
                selected: true
              }
            ],
            selected: true
          },
          {
            tabId: 4,
            text: 'Market Intelligence',
            value: '96dc645f3eac482cb33732c50a0e4fba',
            checked: true,
            children: [
              {
                tabId: 62,
                text: 'Benchmarking',
                value: '38ec4072a2d5438c869582fabcb6dccc',
                checked: true,
                children: [
                  {
                    tabId: 168,
                    text: 'Component Cost',
                    value: '47844cf4b2e34e249d86c9374046ceae',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 169,
                    text: 'Transformation Cost',
                    value: 'a4798977efe04346a67c097dee307965',
                    checked: true,
                    children: null,
                    selected: true
                  }
                ],
                selected: true
              },
              {
                tabId: 63,
                text: 'Competitiveness',
                value: 'a91f70434a584dc294c1cf5439043b12',
                checked: true,
                children: [
                  {
                    tabId: 162,
                    text: 'Summary',
                    value: '27dcdaf4d910459385f7f328dde3494a',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 163,
                    text: 'Overall Performance',
                    value: '9f4b406c6df54181bc63efadd5cef45e',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 164,
                    text: 'Sub Commodity Performance',
                    value: '35e2debdd4cc4e0b8d6561047b598017',
                    checked: true,
                    children: null,
                    selected: true
                  }
                ],
                selected: true
              },
              {
                tabId: 64,
                text: 'Part Matching',
                value: 'e3dfcd9416b347bb9e45e493bbdf76e8',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 65,
                text: 'Raw Materials',
                value: 'bf80fe862d1e42b89ef4fae0bbee217e',
                checked: true,
                children: [
                  {
                    tabId: 165,
                    text: 'Trends',
                    value: 'cfebe4f3c8ea4d71822c9ac512b9e986',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 166,
                    text: 'Opportunities/Risks',
                    value: '9600d5f38e4744d3be9d42b0bbe8efe8',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 167,
                    text: 'Mapping',
                    value: 'fbde4530347145f78c29598b44337512',
                    checked: true,
                    children: null,
                    selected: true
                  }
                ],
                selected: true
              }
            ],
            selected: true
          },
          {
            tabId: 5,
            text: 'Opportunities',
            value: '6bac6c7cda994c09958a2fb1d7a64ebb',
            checked: true,
            children: [
              {
                tabId: 77,
                text: 'Driver',
                value: '1ad9d24f4e804978b677ec1e9d87c8da',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 78,
                text: 'Opportunities/Risks',
                value: 'ff10710c9fae46e8b167f23f8b0987ea',
                checked: true,
                children: [

                ],
                selected: true
              }
            ],
            selected: true
          },
          {
            tabId: 6,
            text: 'Negotiation Playbook',
            value: '8e09a0352ad94c39b70bd4a082663ceb',
            checked: true,
            children: [
              {
                tabId: 71,
                text: 'Summary',
                value: 'b059018f14e245308745daba5c173add',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 72,
                text: 'Details',
                value: 'adc41af074e94e5185d51474d737d6e7',
                checked: true,
                children: [

                ],
                selected: true
              }
            ],
            selected: true
          },
          {
            tabId: 7,
            text: 'Lookup',
            value: 'd6c2fba6c1024b9aa7f8697e3ed5e90f',
            checked: true,
            children: [

            ],
            selected: true
          },
          {
            tabId: 8,
            text: 'Smart RFX',
            value: 'ab0a4ae19e344bffb49c5b25172799e6',
            checked: true,
            children: [
              {
                tabId: 73,
                text: 'Details',
                value: 'af2860825a2f427c9dd67776305a4d78',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 74,
                text: 'Simulation',
                value: '824fd10c43a343e9853cd662318c1fc2',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 75,
                text: 'Award',
                value: '9d302a85e2dd461d938d5666c78f8e8e',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 76,
                text: 'Supplier Messaging',
                value: 'ff0b540239214d41b559ef5510977b88',
                checked: true,
                children: [

                ],
                selected: true
              }
            ],
            selected: true
          },
          {
            tabId: 9,
            text: 'Reports',
            value: '64a1d969fb7b43a7b0f5769a402db8ae',
            checked: true,
            children: [
              {
                tabId: 102,
                text: 'Commodity Lead Report',
                value: 'c0c1334770724755b8cf4e524aa57182',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 103,
                text: 'Baseline CPN/Predecessor Part',
                value: '03a36309011b4e4eb8e2396d69740c97',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 104,
                text: 'CM Tier 2 Cost',
                value: '272edb09cfb04909ab90aea23c13c8da',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 105,
                text: 'BOM Compare',
                value: '869acfc4b11c4917b5786ff0e759b50c',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 106,
                text: 'CPN Forecast Report',
                value: '5dcf8876696b4568933357adedebe93f',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 107,
                text: 'PPV Report - All Changes',
                value: '5840e005e3b84141abeedc28007507b1',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 108,
                text: 'PPV For Approval',
                value: '776404ed674540558bb0cdbd2bfb5517',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 109,
                text: 'PPV Compare Report',
                value: 'b471681385d545c68280c8be14002f0c',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 110,
                text: 'Master Cost Changes',
                value: 'e199a0eca5e847d690649cfbca55c31f',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 111,
                text: 'GCM Template',
                value: '13ffe6f320684559a248ed2dcc377c2a',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 112,
                text: 'Where Used Report',
                value: '536d40238edb4185af4673aa3ed046ff',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 113,
                text: 'Distributor Benchmark',
                value: '38f60c43761343648b941672a8e3d5c2',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 114,
                text: 'Forecast and Cost Report',
                value: '04a16c487db546dcabea566ad8b16695',
                checked: true,
                children: [

                ],
                selected: true
              }
            ],
            selected: true
          },
          {
            tabId: 10,
            text: 'Augmented Intelligence',
            value: '6acd91296df14e81a5ae9bbbdf844ada',
            checked: true,
            children: [
              {
                tabId: 80,
                text: 'Opportunities/Risks',
                value: '428838e8e0f04f52b5d430c9266c0ce8',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 81,
                text: 'Alerts Threshold',
                value: 'b5cec8de9ed7473283f0dc3b7fc4e036',
                checked: true,
                children: [
                  {
                    tabId: 170,
                    text: 'Basic',
                    value: '9830c33bb6e241fbba4a94ef4165e6da',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 171,
                    text: 'Advanced',
                    value: 'ba241488873a43c79bf3885347671ece',
                    checked: true,
                    children: null,
                    selected: true
                  }
                ],
                selected: true
              },
              {
                tabId: 82,
                text: 'Negotiation Levers',
                value: 'bc940beedb074488ace674b0a27b4c0c',
                checked: true,
                children: [
                  {
                    tabId: 172,
                    text: 'Levers',
                    value: '5b537a665e3d411b904777642c9bc538',
                    checked: true,
                    children: null,
                    selected: true
                  },
                  {
                    tabId: 173,
                    text: 'Meta Data',
                    value: '3a08ede2435c45528b1a06dc12895877',
                    checked: true,
                    children: null,
                    selected: true
                  }
                ],
                selected: true
              }
            ],
            selected: true
          },
          {
            tabId: 11,
            text: 'RFQ Congiguration',
            value: '3a359bad484348ca81819189dfb7a795',
            checked: true,
            children: [
              {
                tabId: 66,
                text: 'Template Feilds',
                value: '11af5f6f43de44a98e48a88af7187b69',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 67,
                text: 'Metrics',
                value: 'b90a1045558141cb932497ef14a55d6f',
                checked: true,
                children: [

                ],
                selected: true
              }
            ],
            selected: true
          },
          {
            tabId: 12,
            text: 'Global Preferences',
            value: '94db0c94ccd24d8f82e0980511ea7748',
            checked: true,
            children: [
              {
                tabId: 68,
                text: 'Opportunity Alerts',
                value: '3e8fa302a13f49baa7ef6c8325a120ce',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 69,
                text: 'Access Control',
                value: 'd13f694689b641449cfe597686511670',
                checked: true,
                children: [

                ],
                selected: true
              }
            ],
            selected: true
          },
          {
            tabId: 13,
            text: 'Close RFQ',
            value: '93a6153a21bd43de88e2eecdec7c2ff0',
            checked: true,
            children: [
              {
                tabId: 70,
                text: 'Details',
                value: 'f5be65d7c0074aa092e13282f66f5a20',
                checked: true,
                children: [

                ],
                selected: true
              }
            ],
            selected: true
          },
          {
            tabId: 14,
            text: 'OpportunitiesICS',
            value: 'a2bc252e23d84517b0559ff75e3cb4c1',
            checked: true,
            children: [
              {
                tabId: 79,
                text: 'Opportunities/Risk',
                value: '5a5b6ff85dc7468b9fbf7c9112bac9b8',
                checked: true,
                children: [

                ],
                selected: true
              }
            ],
            selected: true
          },
          {
            tabId: 15,
            text: 'Opportunities Widgets',
            value: '65fb5b5c403b445dac13faf19e03e7f8',
            checked: true,
            children: [
              {
                tabId: 91,
                text: 'Opportunity by Drivers',
                value: 'fd8dfce970684f2e9912fa17f80e0f9b',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 92,
                text: 'Product Family',
                value: '09b4d7fd73f54c988742dd496ec806d4',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 93,
                text: 'Product',
                value: '78dd2d4e43254d808393bb7f0e91954d',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 94,
                text: 'CM/ODM',
                value: 'e7a907d8f71d429cabc9787be248cf38',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 95,
                text: 'MPN',
                value: '583c822053364dfa857fc1827d43c943',
                checked: true,
                children: [

                ],
                selected: true
              },
              {
                tabId: 96,
                text: 'Transformation',
                value: '3b420ba7ad4e47e88d3f27588683d899',
                checked: true,
                children: [

                ],
                selected: true
              }
            ],
            selected: true
          }
        ]
      }
    ]
  }
  setUploadedLogo(data){
    this.logoData.next(data);
  }
  getUploadedLogo(){
    return this.logoData.asObservable()
  }
  getCustomerConfigurationFieldsExistingCustomerData() {
    const tenantId = this.customerConfigurationService.getEnvironmentDetails().tenantId; 
   if(tenantId && tenantId !== 0 ){
    const object: any = {
      targetedType: 'CustomerConfigService',
      formParams:  {tenantId},
      method: 'GET',
      servicePath: '/getExistingCustomerConfiguration'
    };
    return this.apiServiceProvider.post(object);
  }else{
    const object: any = {
      targetedType: 'CustomerConfigService',
      method: 'GET',
      servicePath: '/getCustomerConfigurationMetaData'
    };
    return this.apiServiceProvider.post(object);
  }
  }
  getCustomerConfigurationFieldsData() {
    // return this.customerConfig;

    const object: any = {
      targetedType: 'CustomerConfigService',
      method: 'GET',
      servicePath: '/getCustomerConfigurationMetaData'
    };
    return this.apiServiceProvider.post(object);
  }

  postCustomerConfigurationFieldsCreateData(data) {
    const payloadObject: any = {
      targetedType: 'CustomerConfigService',
      payload: data ,
      method: 'POST',
      servicePath: '/createCustomerConfiguration'
    };
    return this.apiServiceProvider.post(payloadObject);
  }
  postCustomerConfigurationFieldsEditData(data) {
    const payloadObject: any = {
      targetedType: 'CustomerConfigService',
      payload:data ,
      method: 'POST',
      servicePath: '/editCustomerConfiguration'
    };
    return this.apiServiceProvider.post(payloadObject);
  }
    getRedirectionURL(customerName,providerName) {
    const payloadObject: any = {
      targetedType: 'CustomerConfigService',
      method: 'GET',
      servicePath: `/realms/${customerName}/identity-provider/providers/${providerName}/redirect-uri`
    };
    return this.apiServiceProvider.post(payloadObject);
  }
}
