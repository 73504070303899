import {
  Component,
  OnInit,
  Input,
  AfterViewChecked,
  ElementRef,
  ViewChild,
  EventEmitter,
  Output,
  HostListener,
  ViewChildren,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { BotService } from './shared/bot.service';
import { Router, NavigationEnd } from '@angular/router';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { DropDownListService } from 'app/modules/common/dropdown-list.service';
import { ApplicationURLS } from 'app/modules/common/applicationURL';
import { BOTHelpService } from 'app/modules/base-app/bot/help/help.service';
import { BaseService } from 'app/modules/common/base.service';
import { ISingeItem } from 'app/modules/common/models/scope/singleItem';
import { TimeSelectionDataService } from 'app/modules/core/time-selection/shared/time-selectiondata.service';
import { SocketService } from 'app/modules/common/socket/scoket.service';
import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService';
import { AutoSearchService } from 'app/modules/core/autosearch/shared/autosearch.service';
import { ngxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';

import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/observable/fromEvent';
import { FeatureService } from '../../common/feature.service';
import { environment } from 'environments/environment';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';
import { DialogBoxService, DialogButtonName, DialogType, LdDialog } from 'app/modules/leva-ui-library/components/dialog-box/dialog-box.service';
import { ReverseArray } from 'app/modules/common/Pipe/ReverseArray';

interface filterItem<TValue> {
  [id: string]: TValue;
}

interface quarterObject {
  startQuarter: number;
  endQuarter: number;
  year: number;
}

declare let annyang;
declare let window;
@Component({
  selector: 'bot',
  templateUrl: 'bot.component.html',
  styleUrls: ['./bot.component.sass'],
  providers: [BotService, ReverseArray],
})
export class BotComponent implements OnInit, OnDestroy, AfterViewChecked {
  chatDialog: LdDialog = {
    title: 'Ask Leva',
    id: 'Ask Leva',
    template: true,
    justifyContent: 'right',
    top: '0%',
    position: 'fixed',
    type: DialogType.BRAND,
    copyright: false,
    maxHeight: '550px',
    padding: '2px 15px 15px 2px',
    boxShadow: '1px 1px 6px 2px rgb(28, 78, 132 , .25)',
  }

  showDialog = 'hide';
  @ViewChild('input', { static: true }) inputBox: ElementRef;
  @ViewChild('mike', { static: false }) mike: ElementRef;

  constructor(
    private searchService: AutoSearchService,
    public dataStorageService: DataStorageService,
    private router: Router,
    private socketService: SocketService,
    private baseService: BaseService,
    private botService: BotService,
    private customerConfigurationService: CustomerConfigurationService,
    private scopeDataService: ScopeDataService,
    private dropDownListService: DropDownListService,
    private applicationURl: ApplicationURLS,
    private botHelpService: BOTHelpService,
    private timeSelectionDataService: TimeSelectionDataService,
    private ngxZendeskWebwidgetService: ngxZendeskWebwidgetService,
    private featureService: FeatureService,

    public dialogBoxService: DialogBoxService,

    private renderer: Renderer2
  ) {
    this.scopeList = this.customerConfigurationService.getScopeList();
    this.notApplicableRoutes = this.featureService.getfeaturesArray();
    this.dataStorageService.setChatBotEnable(false);
    this.botHelpService.getUpdateHistory().subscribe((message) => {
      if (message != undefined) {
        this.updateHistory(message);
      }
    });
    this.baseService.getUnAuthrizePopup().subscribe((val) => {
      this.unAuthorized = val;
    });

    /*------------------------------ Web Socket Code Start --------------------------------*/

    // if (environment && environment['webSocke']) {
    // 	this.socketService.getChatBotNotificationAlert().subscribe(message => {
    // 		if (message !== undefined) {
    // 			if (message instanceof Object) {
    // 				if (this.checkLoadHistory == true) {
    // 					this.addNotificationIntoChatbot(message);
    // 				} else {
    // 					this.tempNotificatonArray.push(message);
    // 				}
    // 			}
    // 		}
    // 	});
    // } else {
    // 	setInterval(() => {
    // 		if (!this.unAuthorized) {
    // 			this.getAlertsPeriodically();
    // 		}
    // 	}, environment['webSocketInterval']);
    // }

    /*------------------------------ Web Socket Code End --------------------------------*/

    if (
      this.customerConfigurationService.getBaseCustomerInfo() instanceof Object
    ) {
      this.enableSpeechRecognition = this.customerConfigurationService.getBaseCustomerInfo().enableBotSpeechRecognition;
    }
  }
  public selectedDialogId: string;
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  speechListening = false;
  enableSpeechRecognition = false;
  isListening = false;
  messages: any[];
  dimensionsList: Array<string> = [
    'COMMODITY_NAME',
    'SUB_COMMODITY_NAME',
    'PRODUCT_NAME',
    'PRODUCT_FAMILY_NAME',
    'SUPPLIER_NAME',
    'MANUFACTURER_NAME',
    'CPN',
    'MPN_DESC',
    'CPN_DESC',
    'PRODUCT_DESCRIPTION',
    'MPN',
    'COMMODITY_MANAGER_NAME',
    'CPN_STRATEGY',
    'CM_ODM_NAME',
    'CONTROL',
  ];
  customDimensionData: any;
  botHistory: any = {};
  randomGreeting: Array<number>;
  greeting: any;
  ite: any;
  sorryMsg: any;
  msg1: any;
  msg2: any;
  onSend = false;
  showPanel = 'hide';
  // userInputkeys = new Subject<string>();
  customerMessageObject: any;
  userInput: string;
  sendReceivedObject; // Get generic object data
  focus: string;
  helpString = 'help with';
  scrollBottom = true;
  checkLoadHistory = false;
  scopeList: any;
  tempNotificatonArray: Array<{}> = [];
  @Input() toggleBotAction = true;
  @ViewChild('scrollMe', { static: false })
  private myScrollContainer: ElementRef;
  @ViewChildren('input') vc;
  alertsMap: Array<string> = [];
  unAuthorized = false;
  dateArray: Array<any> = [];
  notApplicableRoutes: Array<any> = [];

  customQuestion = [
    {
      question:
        'We have identified an Opportunity of $500K with Murata. Do you want to view it?',
      options: [
        { displayName: 'Yes', target: 'opportunities' },
        { displayName: 'No', target: undefined },
      ],
    },
    {
      question:
        'An opportunity of $200K is present for AVX. Do you want to create RFQ for it?',
      options: [
        { displayName: 'Yes', target: 'srfx/create/schedule' },
        { displayName: 'No', target: undefined },
      ],
    },
    {
      question:
        '{{user}}, There are some opportunities and risks that need your attention, would you like to see them?',
      options: [
        { displayName: 'Yes', target: 'opportunities' },
        { displayName: 'No', target: undefined },
      ],
    },
    {
      question:
        'Sure! Would you like me to create an autonomous or an assisted RFQ?',
      options: [
        { displayName: 'Assisted', target: 'srfx/create/schedule' },
        { displayName: 'Autonomous', target: undefined },
      ],
    },
  ];

  botUserName = '';

  @Output() toggleBotActionEvent: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();
  finalChatBotObject: any = {};

  setDefaultYear: Array<any> = [
    'spendanalytics/spend',
    'spendanalytics/cost',
    'spendanalytics/forecast',
  ];

  observableList: Array<any> = [];
  @HostListener('mousemove', ['$event'])
  onMousemove(event: MouseEvent) {
    this.scrollBottom = false;
  }


  addNotificationIntoChatbot(message) {
    if (message !== undefined) {

      const sampleObject: {} = message;

      if (sampleObject instanceof Object) {
        if (sampleObject['notificationType'] !== undefined) {
          let notificationMessage: {};
          switch (sampleObject['notificationType'].toLowerCase()) {
            case 'opportunity_group':
            case 'raw material_group':
              notificationMessage = {
                CHAT_CONVERSATION_ID: '1a5918bd-b334-4747-8d71-627e1dfaeb79',
                CHAT_FROM: 'B',
                CREATED_DATE: new Date().getTime(),
                response: {
                  component: 'bot-quesition',
                  parameters: sampleObject,
                },
              };

              break;
            case 'raw material':
            case 'opportunity':
              notificationMessage = {
                CHAT_CONVERSATION_ID: '1a5918bd-b334-4747-8d71-627e1dfaeb79',
                CHAT_FROM: 'B',
                CREATED_DATE: new Date().getTime(),
                response: {
                  component: 'bot-viewdetails',
                  parameters: sampleObject,
                },
              };
              break;
          }

          this.updateHistory(notificationMessage);
        }
      }
    }
  }
  ngAfterViewChecked() {
    this.scrollToBottom();
  }
  overlay(event) {
    const element = document.getElementById('bot-request');
    element.classList.add('bot-overlay');
  }

  keyDownFunction(event) {
    if (event.keyCode == 13) {
      this.send();
      this.onSend = true;
      this.showPanel = 'hide';
    }
  }

  public botToggleButtonClick(event) {
    this.toggleBotAction = !this.toggleBotAction ? true : false;
    this.toggleBotActionEvent.emit(this.toggleBotAction);
  }

  public scrollToBottom(): void {
    if (this.scrollBottom && this.myScrollContainer) {
      try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      } catch (err) { }
    }
  }

  ngOnInit() {
    if (this.enableSpeechRecognition) {
      this.registerSpeechRecognition();
    }
    // hiding help Icon with route change
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.ngxZendeskWebwidgetService.hide();
      }
    });
    this.botUserName = this.baseService.getUserInformation().fullName.charAt(0);
    if (this.botUserName.trim() == '')
      this.botUserName = this.baseService.getUserInformation().email.charAt(0);

    this.botHelpService.getSelectedCategory().subscribe((category) => {
      this.getCategoryMessage(category);
    });

    this.botHelpService.getSelectedCommand().subscribe((command) => {
      this.getCommandOptions(command);
    });

    this.scopeDataService.getFocus().subscribe((focus) => {
      this.focus = focus;
    });
    ``;

    // this.timeSelectionDataService
    //   .getTimeSelectionData()
    //   .subscribe((timePeriodData) => {
    //     if (timePeriodData != undefined) {
    //       if (
    //         timePeriodData.toString().includes('GMT') ||
    //         timePeriodData.toLowerCase().includes('t00')
    //       ) {
    //         if (this.timeSelectionDataService.title.getValue() != undefined) {
    //           if (this.timeSelectionDataService.title.getValue().length != 0) {
    //             this.scopeDataService.onUpdateChatBotApplication(
    //               this.finalChatBotObject
    //             );
    //           } else {
    //             this.finalChatBotObject['time'] = [{}];
    //             this.scopeDataService.onUpdateChatBotApplication(
    //               this.finalChatBotObject
    //             );
    //           }
    //         }
    //         if (
    //           this.scopeDataService.getSelectedDimensionsDataByFocus(
    //             this.scopeDataService.currentFocus
    //           ) !== undefined
    //         ) {
    //           const selectedDimesionData = this.scopeDataService.getSelectedDimensionsDataByFocus(
    //             this.scopeDataService.currentFocus
    //           )['dimensions'];
    //           if (selectedDimesionData !== undefined) {
    //             this.finalChatBotObject['filter'] = selectedDimesionData;
    //             this.scopeDataService.onUpdateBotDimensions(
    //               this.finalChatBotObject['filter']
    //             );
    //           }
    //         }
    //       } else if (timePeriodData.toLowerCase().includes('q')) {
    //         let currentYear = '2018';
    //         this.timeSelectionDataService
    //           .getTitle()
    //           .subscribe((currentYearTitle) => {
    //             if (currentYearTitle !== undefined) {
    //               if (currentYearTitle.toLowerCase().includes('q')) {
    //                 currentYear = currentYearTitle.split('[')[0];
    //               } else {
    //                 currentYear = currentYearTitle;
    //               }
    //             }
    //           });
    //         this.onGenerateDateFilter({
    //           others: {
    //             timePeriod: [{ year: currentYear, quarter: timePeriodData }],
    //           },
    //         });
    //         if (
    //           this.scopeDataService.getSelectedDimensionsDataByFocus(
    //             this.scopeDataService.currentFocus
    //           ) !== undefined
    //         ) {
    //           const selectedDimesionData = this.scopeDataService.getSelectedDimensionsDataByFocus(
    //             this.scopeDataService.currentFocus
    //           )['dimensions'];
    //           if (selectedDimesionData !== undefined) {
    //             this.finalChatBotObject['filter'] = selectedDimesionData;
    //             this.scopeDataService.onUpdateBotDimensions(
    //               this.finalChatBotObject['filter']
    //             );
    //           }
    //         }
    //         this.scopeDataService.onUpdateChatBotApplication(
    //           this.finalChatBotObject
    //         );
    //         this.timeSelectionDataService.updateYearThroughChatBot([
    //           {
    //             year: currentYear,
    //             title: currentYear,
    //             quarter: timePeriodData,
    //           },
    //         ]);
    //         this.timeSelectionDataService.title.next(
    //           currentYear + '[' + timePeriodData + ']'
    //         );
    //       } else if (timePeriodData.toLowerCase().includes('20')) {
    //         this.onGenerateDateFilter({
    //           others: {
    //             timePeriod: [timePeriodData],
    //           },
    //         });
    //         if (
    //           this.scopeDataService.getSelectedDimensionsDataByFocus(
    //             this.scopeDataService.currentFocus
    //           ) !== undefined
    //         ) {
    //           const selectedDimesionData = this.scopeDataService.getSelectedDimensionsDataByFocus(
    //             this.scopeDataService.currentFocus
    //           )['dimensions'];
    //           if (selectedDimesionData !== undefined) {
    //             this.finalChatBotObject['filter'] = selectedDimesionData;
    //             this.scopeDataService.onUpdateBotDimensions(
    //               this.finalChatBotObject['filter']
    //             );
    //           }
    //         }
    //         this.scopeDataService.onUpdateChatBotApplication(
    //           this.finalChatBotObject
    //         );
    //         this.timeSelectionDataService.updateYearThroughChatBot([
    //           { year: timePeriodData, title: timePeriodData },
    //         ]);
    //         this.timeSelectionDataService.title.next(timePeriodData);
    //       }
    //     }
    //   });
    this.botService.getRandomGreeting().subscribe(
      (data) => (this.randomGreeting = data),
      (error) => console.log('Bot Greeting Message Error'),
      () => this.getMessage()
    );
    const targetURLObject = this.applicationURl.getURLObject({
      target: 'bot',
      service: 'history',
    });

    const userSessionData = this.baseService.getUserInformation();
    const historyPayload: {} = {
      payload: {
        oneDayHistory: true,
        scope: this.baseService.getCurrentApplicationScope(),
        userId: this.baseService.getUserInformation().userId,
        tenantId: this.customerConfigurationService.getEnvironmentDetails()
          .tenantId,
      },
    };
    this.botService.getHistory(historyPayload).subscribe(
      (data) => {
        if (data instanceof Object) {
          if (data['responseStatus'] instanceof Object) {
            switch (data['responseStatus']['code']) {
              case 200:
                if (data['history'] != undefined || data['history'] == null) {
                  Object.assign(this.botHistory, data.history);
                  this.dateArray = Object.keys(this.botHistory);
                  this.checkLoadHistory = true;
                }
                break;
            }
          }
        }
      },
      (error) => console.log('History Error'),
      () => this.setGreeting()
    );
  }

  public onInputFocus() {

  }

  public onInputBlur() {

  }

  getCategoryMessage(category) {
    this.send(this.helpString + ' ' + category);
  }

  getCommandOptions(command) {
    this.send(this.helpString + ' ' + command);
  }

  public getMessage() {
    const message = this.randomGreeting[
      Math.floor(Math.random() * this.randomGreeting.length)
    ];
    try {
      this.greeting = {
        CHAT_CONVERSATION_ID: '1a5918bd-b334-4747-8d71-627e1dfaeb79',
        CHAT_FROM: 'B',
        CREATED_DATE: new Date().getTime(),
        MESSAGE:
          message['MESSAGE'] +
          ' ' +
          this.baseService.getUserInformation()['fullName'],
        response: '',
      };
    } catch (e) {
      console.log(e);
    }
  }

  public setGreeting() {
    if (window.localStorage.getItem('customer_userLogin') == null) {
      this.updateHistory(this.greeting);
      this.howCanIHelp();
      window.localStorage.setItem('customer_userLogin', 'true');
    }
    this.loadTempNotification();
  }

  generateTodayEpochNumber() {
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    const date = new Date().getDate();
    return new Date(year, month, date).getTime() != undefined
      ? new Date(year, month, date).getTime()
      : undefined;
  }

  updateHistory(message) {
    if (message instanceof Object) {
      if (Object.keys(this.botHistory).length == 0) {
        const date = this.generateTodayEpochNumber();
        const obj: ISingeItem<any> = {};
        obj[date] = [];
        Object.assign(this.botHistory, obj);
        this.dateArray = Object.keys(this.botHistory);
      }
      this.scrollBottom = true;
      if (message['MESSAGE'] != undefined) {
        const msg = message['MESSAGE'].replace(/\s?/g, '');
        if (msg.length != 0) {
          this.botHistory[this.dateArray[this.dateArray.length - 1]].push(
            message
          );
        }
      } else {
        this.botHistory[this.dateArray[this.dateArray.length - 1]].push(
          message
        );
      }
    }
  }

  loadTempNotification() {
    if (this.tempNotificatonArray.length > 0) {
      for (let index = 0; index <= this.tempNotificatonArray.length; index++) {
        this.addNotificationIntoChatbot(this.tempNotificatonArray[index]);
      }
    }
  }

  howCanIHelp() {
    const askHelp = {
      CHAT_CONVERSATION_ID: '1a5918bd-b334-4747-8d71-627e1dfaeb79',
      CHAT_FROM: 'B',
      CREATED_DATE: new Date().getTime(),
      MESSAGE: 'How can I help ?',
      response: '',
    };
    this.updateHistory(askHelp);
  }
  public onNavigateFormChatBot(sendReceivedObject) {
    setTimeout(() => { }, 2500);
    if (sendReceivedObject instanceof Object) {
      let targetComponent =
        sendReceivedObject.component != undefined
          ? sendReceivedObject.component
          : undefined;
      const scope =
        sendReceivedObject.scope != undefined
          ? sendReceivedObject.scope
          : undefined;

      /* ------------------------- Scope Switch base on chat bot response----------------------- */
      if (scope != undefined) {
        const currentScope = this.baseService.getCurrentApplicationScope();
        if (currentScope.toLowerCase() != scope) {
          if (this.scopeList.length != 1) {
            this.scopeDataService.setFocus(scope);
          }
        }
      }

      /* --------------------------- Spend Element Checking -------------------------------------- */
      if (targetComponent != undefined) {
        switch (targetComponent.toLowerCase()) {
          case 'spendanalytics/spend/spendelements':
            targetComponent = 'spendanalytics/spend';
            this.dataStorageService.setSpendElementCheck(true);
            break;
          case 'mi/benchmark/transformationcost':
            this.dataStorageService.setBenchmarkTransformationCost(true);
            break;
        }
      }

      /* ------------------------- Apply Filter base on chat bot response----------------------- */

      if (Object.keys(sendReceivedObject.parameters).length >= 0) {
        const path = sendReceivedObject.component.split('/');
        if (path[path.length - 1] != 'summary') {
          if (sendReceivedObject.parameters.dateFilter != undefined) {
            this.timeSelectionDataService.clearLocalTimePeriodYearsObject();
            // this.onGenerateDateFilter(sendReceivedObject);

            switch (targetComponent.toLowerCase()) {
              case 'opportunities':
                if (
                  sendReceivedObject.parameters.dateFilter.others != undefined
                ) {
                  const chatBotTime =
                    sendReceivedObject.parameters.dateFilter.others
                      .timePeriod || undefined;
                  if (chatBotTime != undefined) {
                    this.scopeDataService.setOpportunitiesTimeFromChatBot(
                      chatBotTime[0]
                    );
                  }
                }
                break;
              case 'spendanalytics/forecast/forecastchange':
                let chatBotTime;
                if (
                  sendReceivedObject.parameters.dateFilter.range != undefined
                ) {
                  chatBotTime =
                    sendReceivedObject.parameters.dateFilter.range.timePeriod ||
                    undefined;
                } else if (
                  sendReceivedObject.parameters.dateFilter.others != undefined
                ) {
                  chatBotTime =
                    sendReceivedObject.parameters.dateFilter.others
                      .timePeriod || undefined;
                }
                if (chatBotTime != undefined) {
                  this.scopeDataService.setForecastChangeTimeFromChatBot(
                    chatBotTime
                  );
                }
                break;
              case 'spendanalytics/forecast/forecastvsactual':
                break;
              default:
                this.onGenerateDateFilter(
                  sendReceivedObject.parameters.dateFilter
                );
                break;
            }
          } else {
            /* ----------------------- Check and add time base on component ------------------------- */

            const urlArray: Array<any> = sendReceivedObject.component
              .split('/', 2)
              .join('/');

            if (this.setDefaultYear.indexOf(urlArray) > -1) {
              this.timeSelectionDataService.clearLocalTimePeriodYearsObject();
              const dateFilter: {} = {
                others: {
                  timePeriod: [
                    this.timeSelectionDataService.getCurrentYear() != undefined
                      ? this.timeSelectionDataService.getCurrentYear()
                      : 2018,
                  ],
                },
              };

              this.onGenerateDateFilter(dateFilter);
            }
          }

          const botScopeItems: any = {};
          if (sendReceivedObject.parameters.filter !== undefined) {
            for (const dimension of Object.keys(
              sendReceivedObject.parameters.filter
            )) {
              for (const item of sendReceivedObject.parameters.filter[
                dimension
              ]) {
                const singleItem: filterItem<any> = {};
                singleItem[item] = { isSelected: true };
                if (botScopeItems[dimension] == undefined) {
                  botScopeItems[dimension] = {};
                }
                Object.assign(botScopeItems[dimension], singleItem);
              }
            }
            this.scopeDataService.setFilter(true);
            this.finalChatBotObject['filter'] = botScopeItems;
          } else {
            this.scopeDataService.setFilter(false);
          }
          if (sendReceivedObject.parameters.viewBy !== undefined) {
            const url = sendReceivedObject.component.split('/');
            const target = url[url.length - 1];
            this.dropDownListService.setSelectedValue(
              {
                target,
                selectedList: sendReceivedObject.parameters.viewBy[0],
              },
              true
            );
          }
          if (this.finalChatBotObject['time'] != undefined) {
            this.timeSelectionDataService.updateYearThroughChatBot(
              this.finalChatBotObject['time']
            );
          }
        }
      }

      /* --------------------- Checking Predictive cost ------------------- */
      if (sendReceivedObject.enablePredictiveCost != undefined) {
        const enablePredictiveCost = sendReceivedObject.enablePredictiveCost;
        if (enablePredictiveCost == true) {
          this.finalChatBotObject['isPredictive'] = true;
        }
      }
      const path = sendReceivedObject.component.split('/');
      this.scopeDataService.onUpdateChatBotApplication(this.finalChatBotObject);
      this.finalChatBotObject = {};

      this.scopeDataService.settype('chatbot');

      if (targetComponent != 'help') {
        if (!this.notApplicableRoutes.includes(path[path.length - 1])) {
          if (path.length >= 2) {
            if (!this.notApplicableRoutes.includes(path[path.length - 2])) {
              this.scopeDataService.setBotMethod(true);
            } else {
              this.scopeDataService.setBotMethod(false);
              this.router.navigate(['/app/' + targetComponent]);
            }
          } else {
            this.scopeDataService.setBotMethod(true);
          }
        } else {
          this.scopeDataService.setBotMethod(false);
          this.router.navigate(['/app/' + targetComponent]);
        }
        if (path[path.length - 1] == 'predictivecostopportunities') {
          this.router.navigate(['/app/opportunities']);
        }
      }
    }
  }

  private onGenerateQuarters(obj: quarterObject) {
    const quartersObject: Array<object> = [];
    for (let index = obj.startQuarter; index <= obj.endQuarter; index++) {
      const quarter = {
        year: obj.year,
        quarter: 'Q' + index,
      };
      quartersObject.push(quarter);
    }
    return quartersObject;
  }

  private onGenerateDateFilter(dateFilter) {
    if (dateFilter != undefined) {
      const targetSelection = Object.keys(dateFilter)[0];
      if (targetSelection != undefined) {
        let targetTimeObject: any;
        this.scopeDataService.resetTimeDimensionDataObject();
        this.scopeDataService.clearSelectedQuarterObject();
        let timeObject: Array<object> = [];
        switch (targetSelection) {
          case 'others':
            targetTimeObject = dateFilter.others.timePeriod;
            for (const year of targetTimeObject) {
              if (year != undefined) {
                let yearObject: any = {};
                switch (typeof year) {
                  case 'object':
                    yearObject = this.timeSelectionDataService.onCheckTimeSelectionDataKey(
                      year.year
                    );
                    if (yearObject) {
                      yearObject.quarter = year.quarter;
                    }
                    break;
                  case 'string':
                  case 'number':
                    yearObject = this.timeSelectionDataService.onCheckTimeSelectionDataKey(
                      year
                    );
                    break;
                }
                if (yearObject != undefined) {
                  if (yearObject instanceof Array) {
                    timeObject = timeObject.concat(yearObject);
                  } else {
                    if (yearObject.quarter != undefined) {
                      timeObject.push(Object.assign({}, yearObject));
                    } else {
                      timeObject.push(yearObject);
                    }
                  }
                }
              }
            }
            // finalChatBotObject['time'] = timeObject;
            break;
          case 'range':
            targetTimeObject = dateFilter.range.timePeriod;
            let startVal: any;
            let endVal: any;
            const yearCount = 0;
            const yearsLength = targetTimeObject.length;
            let findDiff: number;
            startVal = targetTimeObject[0];
            endVal = targetTimeObject[yearsLength - 1];
            if (typeof startVal == 'string' && typeof endVal == 'string') {
              findDiff = Number(endVal) - Number(startVal);
              for (let i = 0; i < findDiff + 1; i++) {
                // year
                const yearObject: object = {};
                yearObject['year'] = Number(startVal) + i;
                timeObject.push(yearObject);
              }
            } else if (
              typeof startVal == 'object' &&
              typeof endVal == 'object'
            ) {
              findDiff = Number(endVal.year) - Number(startVal.year);
              if (findDiff == 0) {
                // Same Year
                const startQuarter: any =
                  String(targetTimeObject[0].quarter) != undefined
                    ? String(targetTimeObject[0].quarter)
                    : undefined;
                const endQuarter: any =
                  String(
                    targetTimeObject[targetTimeObject.length - 1].quarter
                  ) != undefined
                    ? String(
                      targetTimeObject[targetTimeObject.length - 1].quarter
                    )
                    : undefined;
                const findDiffQuarter: any =
                  endQuarter.charAt(1) - startQuarter.charAt(1);
                if (findDiffQuarter == 0) {
                  const startQuarterObj: object = {
                    year: Number(startVal.year),
                    quarter: startQuarter,
                  };
                  timeObject.push(startQuarterObj);
                } else {
                  const quaterArray: Array<object> = this.onGenerateQuarters({
                    startQuarter: startQuarter.charAt(1),
                    endQuarter: Number(endQuarter.charAt(1)),
                    year: Number(startVal.year),
                  });
                  timeObject = timeObject.concat(
                    quaterArray != undefined ? quaterArray : []
                  );
                }
              } else {
                for (let i = 0; i < findDiff + 1; i++) {
                  const yearObject: object = {};
                  yearObject['year'] = Number(startVal.year) + i;
                  if (Number(startVal.year) + i == startVal.year) {
                    if (startVal.quarter != undefined) {
                      if (startVal.quarter.charAt(1) != undefined) {
                        const quaterArray: Array<Object> = this.onGenerateQuarters(
                          {
                            startQuarter: startVal.quarter.charAt(1),
                            endQuarter: 4,
                            year: Number(startVal.year) + i,
                          }
                        );
                        timeObject = timeObject.concat(
                          quaterArray != undefined ? quaterArray : []
                        );
                      }
                    }
                  } else if (Number(startVal.year) + i == endVal.year) {
                    if (endVal.quarter != undefined) {
                      if (endVal.quarter.charAt(1) != undefined) {
                        const quaterArray: Array<Object> = this.onGenerateQuarters(
                          {
                            startQuarter: 1,
                            endQuarter: Number(endVal.quarter.charAt(1)),
                            year: Number(startVal.year) + i,
                          }
                        );
                        timeObject = timeObject.concat(
                          quaterArray != undefined ? quaterArray : []
                        );
                      }
                    }
                  } else {
                    const quaterArray: Array<object> = this.onGenerateQuarters({
                      startQuarter: 1,
                      endQuarter: 4,
                      year: Number(startVal.year) + i,
                    });
                    timeObject = timeObject.concat(
                      quaterArray != undefined ? quaterArray : []
                    );
                  }
                }
              }
            } else if (
              typeof startVal == 'object' &&
              typeof endVal == 'string'
            ) {
              findDiff = Number(endVal) - Number(startVal.year);
              if (findDiff == 0) {
                // Same Year
                const startQuarter: any =
                  String(startVal.quarter) != undefined
                    ? String(startVal.quarter)
                    : undefined;
                const quaterArray: Array<object> = this.onGenerateQuarters({
                  startQuarter: Number(startQuarter.charAt(1)),
                  endQuarter: 4,
                  year: Number(startVal.year),
                });
                timeObject = timeObject.concat(
                  quaterArray != undefined ? quaterArray : []
                );
              } else {
                for (let i = 0; i < findDiff + 1; i++) {
                  const yearObject: object = {};
                  yearObject['year'] = Number(startVal.year) + i;
                  if (Number(startVal.year) + i == startVal.year) {
                    if (startVal.quarter != undefined) {
                      if (startVal.quarter.charAt(1) != undefined) {
                        const quaterArray: Array<Object> = this.onGenerateQuarters(
                          {
                            startQuarter: Number(startVal.quarter.charAt(1)),
                            endQuarter: 4,
                            year: Number(startVal.year) + i,
                          }
                        );
                        timeObject = timeObject.concat(
                          quaterArray != undefined ? quaterArray : []
                        );
                      }
                    }
                  } else if (Number(startVal.year) + i == Number(endVal)) {
                    const quaterArray: Array<object> = this.onGenerateQuarters({
                      startQuarter: 1,
                      endQuarter: 4,
                      year: Number(startVal.year) + i,
                    });
                    timeObject = timeObject.concat(
                      quaterArray != undefined ? quaterArray : []
                    );
                  } else {
                    if (startVal.year != undefined) {
                      const quaterArray: Array<object> = this.onGenerateQuarters(
                        {
                          startQuarter: 1,
                          endQuarter: 4,
                          year: Number(startVal.year) + i,
                        }
                      );
                      timeObject = timeObject.concat(
                        quaterArray != undefined ? quaterArray : []
                      );
                    }
                  }
                }
              }
            } else if (
              typeof startVal == 'string' &&
              typeof endVal == 'object'
            ) {
              findDiff = Number(endVal.year) - Number(startVal);
              if (findDiff == 0) {
                // Same Year
                const quaterArray: Array<object> = this.onGenerateQuarters({
                  startQuarter: 1,
                  endQuarter: Number(endVal.quarter.charAt(1)),
                  year: Number(startVal),
                });
                timeObject = timeObject.concat(
                  quaterArray != undefined ? quaterArray : []
                );
              } else {
                for (let i = 0; i < findDiff + 1; i++) {
                  const yearObject: object = {};
                  yearObject['year'] = Number(startVal) + i;
                  if (Number(startVal) + i == Number(startVal)) {
                    const quaterArray: Array<object> = this.onGenerateQuarters({
                      startQuarter: 1,
                      endQuarter: 4,
                      year: Number(startVal) + i,
                    });
                    timeObject = timeObject.concat(
                      quaterArray != undefined ? quaterArray : []
                    );
                  } else if (Number(startVal) + i == Number(endVal.year)) {
                    const quaterArray: Array<object> = this.onGenerateQuarters({
                      startQuarter: 1,
                      endQuarter: Number(endVal.quarter.charAt(1)),
                      year: Number(startVal) + i,
                    });
                    timeObject = timeObject.concat(
                      quaterArray != undefined ? quaterArray : []
                    );
                  } else {
                    if (startVal != undefined) {
                      const quaterArray: Array<object> = this.onGenerateQuarters(
                        {
                          startQuarter: 1,
                          endQuarter: 4,
                          year: Number(startVal) + i,
                        }
                      );
                      timeObject = timeObject.concat(
                        quaterArray != undefined ? quaterArray : []
                      );
                    }
                  }
                }
              }
            }
            // this.finalChatBotObject['time'] = timeObject;
            break;
        }
        this.scopeDataService.setTime(true);
        this.finalChatBotObject['time'] = timeObject;
      }
    }
  }

  private checkView(sendReceivedObject) {
    const userName = this.baseService.getUserInformation().fullName;
    if (sendReceivedObject.component != null) {
      const path = sendReceivedObject.component.split('/');
      if (
        !(
          this.notApplicableRoutes.includes(path[path.length - 1]) &&
          this.notApplicableRoutes.includes(path[path.length - 2])
        )
      ) {
        if (path.length >= 2) {
          if (!this.notApplicableRoutes.includes(path[path.length - 2])) {
            sendReceivedObject['message'] =
              'No Data Found to Display Selected Feature. Please contact LevaData Customer Success Team to map data';
          }
        }
      }
    }

    if (sendReceivedObject['message'].includes('<user name>')) {
      sendReceivedObject['message'] = sendReceivedObject['message'].replace(
        '<user name>',
        userName
      );
    }
    if (sendReceivedObject['action'] == 'help.action') {
      window.open('https://levadatasupport.zendesk.com/hc/en-us', '_blank');
    }
    if (sendReceivedObject['action'] == 'undefined.action') {
      this.sorryMsg = sendReceivedObject['message'];
      const completeMsg = this.sorryMsg.split('##');
      this.messages = [];
      for (let i = 2; i < completeMsg.length; i++) {
        this.messages.push(completeMsg[i]);
      }
      this.msg1 = completeMsg[0];
      this.msg2 = completeMsg[1];
    }
    if (sendReceivedObject instanceof Object) {
      const dynamicUserObject = {
        CHAT_CONVERSATION_ID: '1a5918bd-b334-4747-8d71-627e1dfaeb79',
        CHAT_FROM: 'B',
        CREATED_DATE: new Date().getTime(),
        MESSAGE:
          sendReceivedObject['message'] !== undefined
            ? sendReceivedObject['message']
            : '',
        response: sendReceivedObject,
      };
      this.updateHistory(dynamicUserObject);

      if (sendReceivedObject.inlineView == false) {
        this.scopeDataService.clearPredictiveCostFlag();
        this.scopeDataService.clearDefaultData();
        if (sendReceivedObject.component != undefined) {
          this.onNavigateFormChatBot(sendReceivedObject);
        }
      }
      // removing dependency with inline view flag
      if (
        sendReceivedObject &&
        sendReceivedObject.component != undefined &&
        sendReceivedObject.component === 'help'
      ) {
        // window.open(window.location.origin + window.location.pathname + "/#/help");
        this.ngxZendeskWebwidgetService.setHelpCenterSuggestions({
          search: sendReceivedObject.payload.query,
        });
        this.ngxZendeskWebwidgetService.activate({ hideOnClose: true });
        this.ngxZendeskWebwidgetService.hide();
        this.ngxZendeskWebwidgetService.show();
      } else {
        // hiding zendesk with any other action in chatbot
        this.ngxZendeskWebwidgetService.hide();
      }
    }
  }

  private botSend(obj) {
    if (obj instanceof Object) {
      // Get Chat bot URL
      const targetURLObject = this.applicationURl.getURLObject({
        target: 'bot',
        service: 'send',
      });
      if (targetURLObject instanceof Object) {
        if (targetURLObject.url != undefined) {
          this.userInput = null;
          this.updateHistory(obj);

          const payload = {
            availableScopes: this.availableScopes(),
            scope: this.baseService.getCurrentApplicationScope(),
            userId: this.baseService.getUserInformation().userId,
            tenantId: this.customerConfigurationService.getEnvironmentDetails()
              .tenantId,
            query: obj.MESSAGE,
            chatId: obj.chatId !== undefined ? obj.chatId : '',
          };

          const sendPayload = {
            payload,
          };
          this.botService.send(sendPayload).subscribe(
            (data) => {
              if (data instanceof Object) {
                if (data.responseStatus instanceof Object) {
                  switch (data.responseStatus.code) {
                    case 200:
                    case 500:
                      this.onSend = false;
                      this.sendReceivedObject = Object.assign({}, data, {
                        payload,
                      });
                      this.dataStorageService.setBotServiceName(
                        this.sendReceivedObject
                      );
                      this.checkView(this.sendReceivedObject);
                      break;
                    default:
                      break;
                  }
                }
              }
            },
            (error) => {
              console.log('Send Error');
            },
            () => {
              this.userInput = null;
            }
          );
          this.scrollBottom = true;
        }
      }
    }
  }

  private send(value?: any) {
    let message: any;
    if (value !== undefined) {
      message = value;
    } else {
      message = this.userInput;
    }

    const customerMessageObject = {
      CHAT_CONVERSATION_ID: '1a5918bd-b334-4747-8d71-627e1dfaeb79',
      CHAT_FROM: 'U',
      CREATED_DATE: 1505733828604,
      MESSAGE: message,
      response: '',
    };
    this.botSend(customerMessageObject);
  }
  onClickOutside(val) {
    this.showPanel = 'hide';
  }
  onClickOutsideChat(event) {
    if (event.target == this.inputBox.nativeElement ||
      (this.mike != undefined && event.target == this.mike.nativeElement) ||
      this.showPanel == 'show' ||
      event.target.className.includes('ui-dialog-title') ||
      event.target.className.includes('ui-dialog-header') ||
      event.target.className.includes('dialog-body') ||
      event.target.className.includes('ui-dialog-footer')) {
      return;
    }
    this.showDialog = 'hide';
    this.dataStorageService.setChatBotEnable(false);
  }

  onAutoDimensionSelect(key, value) {
    // his.userInput = this.userInput+""+value;

    const customInput = this.userInput;
    const wordsArray: Array<any> = customInput.split(' ');
    const findWord = wordsArray[wordsArray.length - 1];
    const removedArray: Array<any> = [];
    if (wordsArray.length > 0) {
      const replaceWord = value;
      if (replaceWord != undefined) {
        this.userInput = this.userInput.replace(
          findWord,
          key + ' ' + replaceWord
        );
        this.vc.first.nativeElement.focus();
      }
    }
  }
  availableScopes() {
    const listArray: Array<string> = [];
    for (const i of this.scopeList) {
      listArray.push(i.view);
    }
    return listArray;
  }
  setAutoSuggestion(findWord) {
    const obs = this.botService
      .getBotAutoPopulatedData(findWord, this.dimensionsList)
      .debounceTime(500)
      .subscribe(
        (val) => {
          if (val['result'] != undefined) {
            if (val['result'] instanceof Array) {
              if (val['result'].length > 0) {
                if (val['result'][0].hits != undefined) {
                  if (val['result'][0].hits.length > 0) {
                    this.customDimensionData = this.searchService.onConvertArrayForAutoDimnsionSelect(
                      val['result'][0].hits,
                      this.dimensionsList
                    );
                    if (!this.onSend) {
                      this.showPanel = 'show';
                    }
                  } else {
                    this.showPanel = 'hide';
                  }
                }
              }
            }
          }
        },
        (error) => {
        },
        () => { }
      );
    this.observableList.push(obs);
  }

  increaseWidth() {
    document.getElementById('form-width').style.width = '300px';
  }
  onClickOutsideInput(val: any) {
    if ((document.getElementById('bot-request') as HTMLInputElement).value == '') {
      document.getElementById('form-width').style.width = '200px';
    }
  }
  autosuggestionPanel(val) {
    if (val !== undefined) {
      document.getElementById('form-width').style.width = '350px';
      const wordsArray: Array<any> = val.split(' ');
      if (wordsArray.length > 0) {
        const findWord = wordsArray[wordsArray.length - 1];
        for (const index of this.observableList) {
          if (this.observableList[index]) {
            this.observableList[index].unsubscribe();
          }
        }
        if (findWord !== '' && findWord.length > 2) {
          this.customDimensionData = {};

          this.setAutoSuggestion(findWord);

          /* if(curretService !== undefined){
            curretService.unsubscribe();
          } */
        } else {
          this.showPanel = 'hide';
        }
      }
    }
  }

  ngOnDestroy() {
    this.dropDownListService.setSelectedValue(undefined);
  }

  registerSpeechRecognition() {
    try {
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognition = new SpeechRecognition();
      if (annyang) {
        const commands = {
          '*any': this.sendSpeechToChatBot.bind(this),
        };
        // Add our commands to annyang
        annyang.addCommands(commands);
        annyang.addCallback('start', this.onSpeakStart.bind(this));
        annyang.addCallback('end', this.onSpeakEnd.bind(this));
        annyang.addCallback('error', this.onSpeakError.bind(this));
        annyang.addCallback('errorNetwork', this.onSpeakError.bind(this));
        annyang.addCallback(
          'errorPermissionBlocked',
          this.onSpeakError.bind(this)
        );
        annyang.addCallback(
          'errorPermissionDenied',
          this.onSpeakError.bind(this)
        );
      }
    } catch (e) {
      this.enableSpeechRecognition = false;
    }
  }

  onSpeakError() {
    this.speechListening = this.isListening = false;
  }
  @HostListener('document:keydown', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (this.showDialog == 'show' && event.code === 'Escape') {
      this.dataStorageService.setChatBotEnable(false);
      this.showDialog = 'hide';
      this.selectedDialogId = '';
    }
  }
  startOrStopSpeechListening(event: any) {
    if (event.pointerType == "mouse") {
      this.openDialogMic(this.chatDialog.id);
      this.speechListening = !this.speechListening;
      if (!annyang.isListening()) {
        return annyang.start({
          autoRestart: false,
          continuous: false,
          paused: true,
        });
      }
      if (this.speechListening) {
        annyang.resume();
      } else {
        annyang.pause();
      }
    }
  }

  sendSpeechToChatBot(spokenText) {
    annyang.pause();
    // this.userInput = spokenText;
    this.send(spokenText);
    this.speechListening = this.isListening = false;
  }

  onSpeakStart() {
    this.isListening = true;
  }
  onSpeakEnd() {
    if (this.isListening) {
      this.isListening = false;
    }
  }
  openDialog(id: string, event): void {
    if (event.keyCode == 13 && this.showDialog == 'hide') {
      this.showDialog = 'show';
      this.selectedDialogId = id;
      this.dataStorageService.setChatBotEnable(true);
      this.dialogBoxService.open(id)
    }
  }
  openDialogMic(id: string): void {
    if (this.showDialog == 'hide') {
      this.showDialog = 'show';
      this.selectedDialogId = id;
      this.dataStorageService.setChatBotEnable(true);
      this.dialogBoxService.open(id)
    }

  }
  buttonClick(target): void {
    // To destroy component when we close dialog
    if (target.button.id === DialogButtonName.CLOSE) {
      this.dataStorageService.setChatBotEnable(false);
      this.showDialog = 'hide';
      this.selectedDialogId = '';
    }
  }


}
