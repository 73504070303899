import { LdCustomDrowpdown, ItemList } from './custom-dropdown.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ld-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.sass'],
})
export class CustomDropdownComponent implements OnInit {
  @Input() configuration: LdCustomDrowpdown;
  @Output() onDropdownClick: EventEmitter<ItemList> =
    new EventEmitter<ItemList>();
  constructor() {}
  tooltip = '';
  @Input() isDisable = false;
  ngOnInit() {
    if (this.configuration.defaultTooltip) {
      this.tooltip = this.configuration.defaultTooltip;
    }
  }
  selectedItem(item) {
    this.tooltip = item.displayName;
    if (!this.configuration.denyIconSwitch) {
      this.configuration.iconName = item.viewIcon;
    }
    this.onDropdownClick.emit(item);
  }
}
