import { Component, OnInit } from '@angular/core';

import { AdhocCheckbox } from './shared/adhoc-checkbox.model';
import { AdhocCheckboxService } from './shared/adhoc-checkbox.service';
import { AdhocSubscriptionService } from '../../../../common/adhoc.subscription.service';

@Component({
  selector: 'cprt-adhoc-checkbox',
  templateUrl: 'adhoc-checkbox.component.html',
  // styleUrls :["adhoc-checkbox.component.sass"],
  providers: [AdhocCheckboxService]
})

export class AdhocCheckboxComponent {
  params: any;
  rowIndex: any;

  constructor(private adhocCheckboxService: AdhocCheckboxService, private adhocSubscriptionService: AdhocSubscriptionService) { }
  agInit(params: any): void {
    this.params = params
  }

  deleteRow() {
    this.adhocSubscriptionService.setRowIndex(this.params['data']['UID']);
  }
}
