import { PipeTransform, Pipe } from '@angular/core';
@Pipe({ name: 'generateAutomationId', pure: false })
export class GenerateAutomationId implements PipeTransform {
  public transform(input= ''): any {
      let result='';
    if (typeof input !== undefined) {
        result = String(input).replace(/ /g,'_').replace(/-/g, '_').replace(/\//g,'_').toLowerCase()
    }
    return result;
  }
}
