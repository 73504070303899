import { Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core'
import { CoverageDashboardService } from 'app/modules/coverage-dashboard/coverage-dashboard.service'
import Highcharts from 'highcharts'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { GenerateGraphObject } from '../graph-container/GenerateGraphObject'
import { DashboardPieChartService } from './dashboard-pie-chart.service'

@Component({
  selector: 'app-dashboard-pie-chart',
  templateUrl: './dashboard-pie-chart.component.html',
  styleUrls: ['./dashboard-pie-chart.component.sass'],
  providers: [GenerateGraphObject, DashboardPieChartService],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardPieChartComponent implements OnInit, OnDestroy {
  private subject$: Subject<any> = new Subject();
  graphData: any
  graphRenderObject: any = {}
  _configuration: any
  get configuration() {
    return this._configuration
  }
  @Input() set configuration(value) {
    this._configuration = value || {}
  }

  scopeId: string

  private colorsList = {
    info: '#0597C3',
    success: '#009336',
    error: '#B71B17',
    conformation: '#006196',
    default: '#F2F3F4',
    warning: '#E5AC3A',
    gray: '#CCCCCC',
  }

  private pieChartConfig = {
    title: {
      text: '',
      useHTML: true,
      verticalAlign: 'bottom',
      margin: 0,
      y: 60,
      style: {
        fontSize: '12px',
        fontFamily: 'Roboto !important',
        color: this.colorsList.conformation,
      },
    },
    plotOptions: {
      pie: {
        innerSize: '70%',
        depth: 100,
        size: '100%',
        center: ['50%', '50%'],
        borderColor: '#FFFFFF',
        borderWidth: 2,
        dataLabels: {
          enabled: false
        },
      },
    },
  }

  seriesData = []
  dataFindInterval;
  legendPosition = 'bottom';
  pieLabelFont = '14px';

  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    this.seriesData = [];
    this.graphData = null;
    if (this.configuration && this.configuration.data && this.configuration.data.data.length
      && this.configuration.data.data[0].value
      && Object.keys(this.configuration.data.data[0].value).length) {
      if (window.innerWidth < 1200 && window.innerWidth > 800) {
        this.pieLabelFont = '12px';
        this.pieChartConfig = {
          title: {
            ...this.pieChartConfig.title,
            style: {
              ...this.pieChartConfig.title.style,
              fontSize: '12px'
            }
          },
          plotOptions: {
            pie: {
              ...this.pieChartConfig.plotOptions.pie,
              innerSize: '80%',
            }
          }
        }
      } else if (window.innerWidth < 800 && window.innerWidth > 600) {
        this.pieLabelFont = '8px';
        this.pieChartConfig = {
          title: {
            ...this.pieChartConfig.title,
            style: {
              ...this.pieChartConfig.title.style,
              fontSize: '8px'
            }
          },
          plotOptions: {
            pie: {
              ...this.pieChartConfig.plotOptions.pie,
              innerSize: '80%',
              size: '100%',
              borderWidth: 1
            }
          }
        }
      } else {
        this.pieLabelFont = '14px';
        this.pieChartConfig = {
          title: {
            ...this.pieChartConfig.title,
            style: {
              ...this.pieChartConfig.title.style,
              fontSize: '14px'
            }
          },
          plotOptions: {
            pie: {
              ...this.pieChartConfig.plotOptions.pie,
              innerSize: '70%',
            }
          }
        }
      }
      this.load();
    }
  }

  constructor(
    private generateGraphObject: GenerateGraphObject,
    private coverageGridService: CoverageDashboardService,
  ) { }


  findTheData(path) {
    // this.dataFindInterval = setInterval(() => {
    if (
      path &&
      path.data &&
      path.data.data &&
      path.data.data.length > 0 &&
      Object.keys(path.data.data[0]).length > 0 &&
      Object.keys(path.data.data[0].value).length > 0
    ) {
      this.load()
      // this.clearDataInterval()
      this.scopeId = this.configuration.data.id || ''
      window[this.scopeId] = this
    }
    // }, 10)
  }

  clearDataInterval() {
    clearInterval(this.dataFindInterval)
  }

  ngOnInit() {
    this.subscribeToPieEvents();
  }

  private subscribeToPieEvents(): void {
    this.coverageGridService.getDashboardDataChangeObservable()
      .pipe(takeUntil(this.subject$))
      .subscribe((response: any) => {
        // Check to see if the response.data is needed
        if (response.type === 'PIE_UPDATE') {
          this.reloadChartAfterUpdating(response.isData);
        }
      })

  }

  private reloadChartAfterUpdating(hasData: boolean): void {
    this.seriesData = [];
    this.graphData = null;
    if (hasData) {
      this.findTheData(this.configuration);
    } else {
      this.generateGraphObject.setSeriesData(this.seriesData);
      this.generateGraphObject.addOtherInformation(undefined);
      this.graphRenderObject = null;
    }
  }

  showDate(configuration) {
    if (configuration && configuration.data && configuration.data.data && configuration.data.data[0] && configuration.data.data[0].value && configuration.data.data[0].value.data
      && configuration.data.data[0].value.data[0] && configuration.data.data[0].value.data[0].date) {
      return true;
    } else {
      return false;
    }
  }

  private load() {
    Highcharts.setOptions({

    });
    var fontSizeInside = '16px';
    if (window.innerWidth < 1200 && window.innerWidth > 800) {
      fontSizeInside = '12px';
    } else if (window.innerWidth < 800 && window.innerWidth > 600) {
      fontSizeInside = '8px';
    } else {
      fontSizeInside = '16px';
    }

    this.legendPosition = this.configuration.data.data[0].customLegend.position;
    this.graphData = this.configuration.data.data[0].value;
    this.seriesData.push({
      name: this.configuration.data.data[0].customLegend.title || '',
      data: [],
    })
    let dataCheckCount = 0;
    (this.graphData.data || []).forEach((data) => {
      if (typeof data.value == 'object' && data.value[0] == 'NaN') {
        data.value = 0;
        dataCheckCount++;
      }
    });
    if (this.graphData.data.length == dataCheckCount) {
      this.seriesData = [];
      this.configuration.data.status = 'done';
      dataCheckCount = 0;
      return;
    }
    let date = '';
    (this.graphData.data || []).forEach((seriesValue) => {
      date = seriesValue.date || ''
      this.seriesData[0].data.push({
        name: seriesValue.key,
        x: this.configuration.data.data[0].value.metaData.key,
        y: Number(Number(seriesValue.value).toFixed(2))
      })
    })

    const networkProductTooltip = {
      tooltip: {
        useHTML: true,
        borderWidth: 0,
        borderRadius: 5,
        outside: true,
        formatter: function () {
          const scope = window['that']
          const methodPayload = {
            title: this.series.name,
            targetName: this.point.options.name,
            indicatorColor: this.color,
            data: [
              {
                title: this.series.data[0].name,
                value: `${((this.series.data[0].y * 100).toFixed(0))}%`
              },
              {
                title: this.series.data[1].name,
                value: `${((this.series.data[1].y * 100).toFixed(0))}%`
              },
            ],
          }
          scope.graphUtilService.setTooltip(methodPayload)
          return scope.graphUtilService.getTooltip()
        },
      },
    }
    this.generateGraphObject.addOtherInformation(networkProductTooltip)

    this.generateGraphObject.addOtherInformation({
      colors: this.configuration.data.data[0].colorList,
      title: this.pieChartConfig.title,
      plotOptions: this.pieChartConfig.plotOptions
    })
    this.generateGraphObject.setGraphType('pie')
    this.generateGraphObject.setGraphEvents({
      load: function () {
        const chart = this,
          offsetLeft = -20,
          offsetTop = 7,
          y = chart.plotTop + chart.plotHeight / 2 + offsetTop
        let value: number
        let color: string
        chart.series[0].data.forEach((point) => {
          if (point.name === chart.series[0].name) {
            value = Number(point.y * 100)
            color = point.color
          }
        });
        let x = chart.plotLeft + chart.plotWidth / 2 + offsetLeft;

        if (!Number.isNaN(value) && value != undefined) {
          if (value.toString().length == 1) {
            x = x + 8;

          }
          if (value.toString().length == 2) {
            x = x + 5;

          }

          if (value.toString().length == 3) {
            x = x;

          }

          chart.renderer
            .text(`<p style='color: ${color}'>${value.toFixed(0)}%</p>`, x, y)
            .add()
            .css({
              fontSize: fontSizeInside,
              fontWeight: 'bold',
            })
            .toFront()
        }
      }
    });
    this.generateGraphObject.setSeriesData(this.seriesData);
    this.graphRenderObject = this.generateGraphObject.getGraphData();
    this.configuration.data.status = 'done';
  }

  ngOnDestroy(): void {
    this.subject$.next();
    this.subject$.complete();
  }

}
