import { Injectable } from '@angular/core';
import { LdCustomTag } from '../custom-tag/custom-tag.service';

export enum TaskContentType {
  LOCATION =  'location',
  CONTEXT = 'context'
}
export interface LDTaskContentConfiguration {
  type: string,
  tags: LdCustomTag[],
  [property: string]: string | any
}
export enum TaskContentIcons {
  location = 'location_on',
  context = 'local_offer'
}

@Injectable({
  providedIn: 'root'
})
export class TaskLocationContextService {

constructor() { }

}
