import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import * as fromComponents from './components';
import * as fromPopups from './popups';
import * as fromServices from './services';
import { AutofocusDirective } from './components/form-elements/autofocus.directive';
import { LevaUiLibraryModule } from '../leva-ui-library/leva-ui-library.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CoreModule,
    LevaUiLibraryModule
  ],
  declarations: [
    ...fromComponents.components,
    ...fromPopups.popups,
    AutofocusDirective
  ],
  providers: [
    ...fromServices.services,
  ],
  exports: [
    ...fromComponents.components,
    ...fromPopups.popups,
  ],
})
export class SharedModule { }
