import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NewsMetaInfo, NewsMetaInfoService } from '../../news-meta-info.service';
import { primaryCategoryConfiguration, secondaryCategoryConfiguration, severityConfiguration, sourceConfiguration } from './create-news.constants';
import _ from 'lodash'
import { DateUtils } from 'app/modules/common/utills/DateUtils.service';
import { ECalendarValue, IDatePickerConfig } from 'ng2-date-picker';
import { Moment } from 'moment/moment';
import * as moment from 'moment-timezone-all'
@Component({
  selector: 'cprt-create-news',
  templateUrl: './create-news.component.html',
  styleUrls: ['./create-news.component.sass']
})
export class CreateNewsComponent implements OnInit {

  loaderConfig = {
    backdropBackgroundColour: 'rgba(255,255,255,0.3)',
    fullScreenBackdrop: 'true',
    primaryColour: '#1C4E84',
    secondaryColour: '#1C4E84',
    tertiaryColour: '#1C4E84'
  }
  showPreloader = true

  newsProperties = {
    title: null,
    body: null,
    brief: null,
    severity: null,
    primaryCategory: [],
    secondaryCategory: [],
    newsUrl: null,
    provider: null,
    source: null,
    tag: null,
    published: null,
    relevance: false
  } as NewsMetaInfo

  @Input('isCreate') isCreate = false
  @Input('isCustomerNews') isCustomerNews = false
  @Output() onCloseDialog: EventEmitter<any> = new EventEmitter<any>()
  @Output() onSubmitNewsForm: EventEmitter<any> = new EventEmitter<any>()

  @ViewChild('newsForm', { static: true })
  newsForm: FormGroup
  metaInfo = null
  severityConfiguration = _.cloneDeep(severityConfiguration)
  sourceConfiguration = _.cloneDeep(sourceConfiguration)
  primaryCategoryConfiguration = _.cloneDeep(primaryCategoryConfiguration)
  secondaryCategoryConfiguration = _.cloneDeep(secondaryCategoryConfiguration)
  publishedDateConfig: IDatePickerConfig = {
    returnedValueType: ECalendarValue.String,
    drops: 'up',
    format: 'MM-DD-YYYY',
    max: DateUtils.getNextHour().startOf('day')
  };
  publishedDate: Moment = DateUtils.getCurrentDateAsMoment()
  publishedConfiguration = {
    name: 'published',
    value: this.publishedDate
  }

  constructor(
    private newsMetaInfoService: NewsMetaInfoService
  ) {
    moment.tz.setDefault(DateUtils.getUserTimeZone())
  }

  ngOnInit() {
    this.setDropdownValues()
    if (this.isCreate) {
      this.sourceConfiguration.isDisable = true
      this.setSelectedValue(this.sourceConfiguration, 'manual')
      this.newsProperties.source = 'manual'
    } else {
      const details = this.newsMetaInfoService.getNewsDetailsInfo()
      if (Object.keys(details).length) {
        details.published = this.publishedDate = DateUtils.getMomentFromEpoch(details.published).format('MM-DD-YYYY')
        this.initModuleDetails(details)
        this.setSelectedValue(this.severityConfiguration, details.severity)
        this.setSelectedValue(this.sourceConfiguration, details.source)
        this.setSelectedValue(this.secondaryCategoryConfiguration.filterConfig,details.secondaryCategory)

        if (details.source === 'manual') {
          this.sourceConfiguration.isDisable = true
        }
        details.primaryCategory.forEach(category => {
          const selectedPrimaryCategory = this.primaryCategoryConfiguration.data.find(item => category === item.displayName)
            selectedPrimaryCategory.selected = true
        })
        this.primaryCategoryConfiguration.resetValues = this.generateRandomKey()
        this.secondaryCategoryConfiguration.defaultParameter = {
          primaryCategory: details.primaryCategory
        }
      }
    }

    this.configureForm()
    setTimeout(() => {
      this.showPreloader = false
    }, 100)
  }

  generateRandomKey() {
    const crypto = window.crypto
    const array = new Uint32Array(1)
    return crypto.getRandomValues(array)[0]
  }

  setSelectedValue(filterConfig, value) {
    filterConfig.defaultItemSelection = true
    filterConfig.defaultSelectedItem = value
  }

  setDropdownValues() {
    this.primaryCategoryConfiguration.data = _.cloneDeep(this.newsMetaInfoService.getPrimaryCategory())
    this.sourceConfiguration.data = _.cloneDeep(this.newsMetaInfoService.getSource())
  }

  configureForm() {
    this.newsForm = new FormGroup({
      userData: new FormGroup({
        title: new FormControl(null, [Validators.required]),
        body: new FormControl(null, [Validators.required]),
        brief: new FormControl(null),
        severity: new FormControl(null, [Validators.required]),
        primaryCategory: new FormControl([], [Validators.required]),
        secondaryCategory: new FormControl([]),
        newsUrl: new FormControl(null, [Validators.required, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .\\-\?=&%]*/?')]),
        provider: new FormControl(null, [Validators.required]),
        source: new FormControl(null, [Validators.required]),
        tag: new FormControl(null),
        published: new FormControl(null, [Validators.required]),
        relevance: new FormControl(false)
      })
    })
    this.newsProperties.relevance = this.newsProperties.relevance || this.isCustomerNews
    this.newsForm.setValue({
      userData: JSON.parse(JSON.stringify(this.newsProperties))
    })
  }

  get f() {
    return this.newsForm.controls
  }

  initModuleDetails(details) {
    const { resourceId, ...rest } = details
    this.newsProperties = _.cloneDeep(rest)
  }

  isValidEventForm() {
    return this.newsForm.dirty && this.newsForm.valid
  }

  resetForm() {
    this.newsForm.reset()
    this.initModuleDetails({
      title: null,
      body: null,
      brief: null,
      severity: null,
      primaryCategory: [],
      secondaryCategory: [],
      newsUrl: null,
      provider: null,
      source: null,
      tag: null,
      published: null,
      relevance: false
    })
    this.configureForm()
  }

  updateSecondary(e) {
    this.secondaryCategoryConfiguration.defaultParameter = {
      primaryCategory: e.selectedItems.map(x => x.displayName)
    }
    this.secondaryCategoryConfiguration.filterConfig.defaultItemSelection= true
    this.secondaryCategoryConfiguration.filterConfig.defaultSelectedItem = []
    this.secondaryCategoryConfiguration.resetValues = this.generateRandomKey()
  }
}
