import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  EventEmitter,
  Output,
  ElementRef,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ObjectUtils } from '../../common/utills/ObjectUtils.service';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';

export class StringUtils {
  public static getRandom() {
    return (
      this.chr4() +
      this.chr4() +
      '-' +
      this.chr4() +
      '-' +
      this.chr4() +
      '-' +
      this.chr4() +
      '-' +
      this.chr4() +
      this.chr4() +
      this.chr4()
    );
  }
  public static getRandomString(m?: number) {
    const len = m || 9;
    let s = '';
    const r = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < len; i++) {
      s += r.charAt(Math.floor(Math.random() * r.length));
    }
    return s;
  }
  public static quote(regex) {
    return regex.replace(/([()[{*+.$^\\|?])/g, '\\$1');
  }
  private static chr4() {
    return Math.random().toString(16).slice(-4);
  }
}

const dropdownHeight = 214;

@Component({
  selector: 'multi-select-dropdown',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectComponent),
      multi: true,
    },
  ],
})
/* OnChanges */
export class MultiSelectComponent
  implements OnInit, OnDestroy, ControlValueAccessor {
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  @Input()
  set items(value) {
    // console.log('values changed', value);
    if (!(value instanceof Object)) {
      return;
    }
    this._items = ObjectUtils.copy(value);
    if (value instanceof Array) {
      if (value.length > 0) {
        if ((this.getText(this.items[0]) || '').length > 0) {
          this._items.forEach((element, index) => {
            if (this.getText(element) === this.allText) {
              this._items.splice(index, 1);
              return false;
            }
          });
          this.data = [];
          this._resetToDefault = false;
          if (this._selectAll) {
            this._selectedDropdownItem = null;
          }
          // console.log('items', this._items);
          this._items.forEach((element, index) => {
            const el = ObjectUtils.copy(element);
            // el.checked = false;
            this.data.push(el);
          });
          // console.log('created data', this.data);
          this.filteredData = this.data;
          this.empty = false;
          this.disabled = false;
          if (this.selectAll) {
            if (!this.dynamicSearch) {
              if (this.search.length > 0) {
                this.clearSearchText({});
              }
            }
            this.checkAll();
          } else {
            if (!this.dynamicSearch) {
              this.selectedDropdownItem = [];
            }
            this.setDefaultValue();
          }
        } else {
          this.empty = true;
          this.selectedDropdownItem = [];
        }
      } else {
        if (this.search.length > 0) {
          this.filteredData = [];
          this.selectedDropdownItem = [];
        } else {
          this.empty = true;
          this.selectedDropdownItem = [];
        }
      }
    } else {
      this.empty = true;
      this.selectedDropdownItem = [];
    }
    // this.setDefaultValue();
    // console.log('final filtered data', this.filteredData)
  }

  @Input()
  set disableOnEmpty(value) {
    this._disableOnEmpty = value;
    if (this.data instanceof Array) {
      if (this.data.length === 0) {
        this.empty = true;
      }
    }
  }

  @Input()
  set disabledText(value) {
    this._disabledText = value;
  }

  @Input()
  set emptyText(value) {
    this._emptyText = value;
  }

  @Input()
  set dropdownTitle(value) {
    this._dropdownTitle = value;
  }

  set empty(value) {
    this._empty = value;
    if (!value) {
      if (this.disableOnEmpty) {
        this.disabled = true;
      }
    }
  }

  set disabled(value) {
    this._disabled = value;
  }

  @Input()
  set defaultItemKey(value) {
    this._defaultItemKey = value;
    this.setDefaultValue();
  }

  @Input()
  set selectAll(bool) {
    this._selectAll = bool;
    if (bool) {
      if (this.search.length > 0) {
        this.clearSearchText({});
      }
      this.checkAll();
    } else {
      if (this.search.length > 0) {
        this.clearSearchText({});
      }
      this.unCheckAll();
    }
  }

  @Input()
  set resetToDefault(bool) {
    this._resetToDefault = bool;
    if (bool) {
      this.setDefaultValue();
    }
  }

  @Input()
  set defaultSelected(value) {
    this.selectedDropdownItem = null;
    this._defaultSelect = value;
    this.setDefaultValue();
  }

  @Input()
  set disableSelection(val) {
    if(val !== undefined){
    this._disableSelection = val;
    this.disabled = val;
    this.setDefaultValue();
    }
  }

  // getters

  get items() {
    return this._items;
  }

  get disabledText() {
    return this._disabledText;
  }

  get emptyText() {
    return this._emptyText;
  }

  get dropdownTitle() {
    return this._dropdownTitle;
  }

  get disabled() {
    return this._disabled;
  }

  get empty() {
    return this._empty;
  }

  get defaultSelected() {
    return this._defaultSelect;
  }

  get defaultItemKey() {
    return this._defaultItemKey;
  }

  get resetToDefault() {
    return this._resetToDefault;
  }

  get selectAll() {
    return this._selectAll;
  }

  get disableSelection() {
    return this._disableSelection;
  }

  public get selectedDropdownItem() {
    return this._selectedDropdownItem;
  }

  public set selectedDropdownItem(item: any) {
    if (this.data instanceof Array) {
      if (this.data.length > 0) {
        if (!(this._selectedDropdownItem instanceof Array)) {
          this._selectedDropdownItem = [];
        }
        if (!(item instanceof Array) && item !== null && item !== undefined) {
          item = [item];
        }
        this._selectedDropdownItem = item;
        /* this.propagateChange(this._selectedDropdownItem);
                this.itemCheck.emit(item); */
      }
    }
    if (this.selectedDropdownItem === null) {
      // this.selectedDropdownItem = null;
    }
  }
  @Input()
  set readKey(val) {
    this._readKey = val;
    this.items = this._items;
    this.setDefaultValue();
    this.disabled = this.disabled;
    this.disableSelection = this.disableSelection;
  }
  get readKey() {
    return this._readKey;
  }
  public _selectedDropdownItem: any = null;
  public filteredData = [];
  public search = '';
  public data = [];
  public virtualScroll = true;
  @Input() public styleForGrid = false;
  @Input() public disabledKey = 'disabled';
  @Input() public dropdownId = StringUtils.getRandomString();
  @Input() public dynamicSearch = false;
  @Input() public dynamicResultsLoading = false;
  @Input() public selection = 'multi';
  @Input() public adhocEnable = false;
  @Input() public showCustomisedText = false;

  // output variables

  // @Output() change = new EventEmitter<any>();
  @Output() public onCheckAll = new EventEmitter<any>();
  @Output() public onUnCheckAll = new EventEmitter<any>();
  @Output() public onUnCheckWhole = new EventEmitter<any>();
  @Output() public itemCheck = new EventEmitter<any>();
  @Output() public onSelectionChange = new EventEmitter<any>();
  @Output() public onDynamicSearchInputChange = new EventEmitter<any>();
  @Output() public onElementClicked = new EventEmitter<any>();
  @Output() public clickedOutside = new EventEmitter<any>();

  // input variables
  private _readKey;
  // local variables
  private _dropdownTitle = 'Please select any of the available value';
  private dropdownVisible = false;
  private defaultReadKey = 'title';
  private _items: any = [];
  private _disabledText =
    'You are not able to select any value in this dropdown';
  _emptyText = 'Dropdown is having empty list';
  _filteredEmptyText = 'No Items matching your search';
  private defaultButtonText = 'Please select any of the available value';
  private _disableOnEmpty = false;
  private changes;
  private _empty = true;
  private _disabled = true;
  private _defaultSelect: any = null;
  private _defaultItemKey = 'defaultVal';
  private _resetToDefault = false;
  private allText = 'All';
  private _selectAll = false;
  private setCheckAll = false;
  private showDiv = false;
  public showDropUp = false;
  private _disableSelection: boolean;

  constructor(private elRef: ElementRef) {}

  public getSuggestions(event) {
    this.search = '';
    if (!this.dynamicSearch) {
      return;
    }
    this.showDiv = true;
    this.onElementClicked.emit(event);
  }

  public onClickDropdown(event) {
    if (window.innerHeight - event.clientY > dropdownHeight) {
      this.showDropUp = false;
    } else {
      this.showDropUp = true;
    }
  }

  public onClickOutside($event) {
    this.search = '';
    this.showDiv = false;
    this.clickedOutside.emit(true);
    this.dropdownVisible = false;
  }

  public ngOnInit() {
    // console.log('initializeing again');
    if (this.selectAll) {
      if (this.search.length > 0) {
        this.clearSearchText({});
      }
      this.checkAll();
    } else {
      this.setDefaultValue();
    }
  }

  /* ngOnChanges(changes: SimpleChanges) {
		for (let prop in changes) {
			let change = changes[prop];

			let currentValue = change.currentValue;
			let previosValue = change.previousValue;

			console.log(currentValue, " ,  ", previosValue);
		}

	} */

  public setDefaultValue() {
    if (this.selectAll) {
      if (!this.dynamicSearch) {
        if (this.search.length > 0) {
          this.clearSearchText({});
        }
      }
      return this.checkAll();
    }
    if (this.defaultSelected === null) {
      let readKey = this._defaultItemKey;
      if (typeof this.readKey === 'string') {
        readKey = this.readKey;
      }
      if (typeof readKey === 'string') {
        if (readKey.length > 0) {
          if (this.data instanceof Array) {
            const defaultItem = this.data.filter((i) => {
              return i[readKey] === true;
            });
            if (defaultItem instanceof Array && defaultItem.length > 0) {
              this._defaultSelect = defaultItem;
            }
          }
        }
      }
      if (this.defaultSelected === null) {
        // this.selectedDropdownItem = [];
      } else {
        this.setDefaultValue();
      }
    } else {
      if (
        JSON.stringify(this.selectedDropdownItem) !=
        JSON.stringify(this.defaultSelected)
      ) {
        if (
          this.defaultSelected instanceof Object &&
          !(this.defaultSelected instanceof Array)
        ) {
          if (
            this.defaultSelected !== null &&
            this.defaultSelected !== undefined
          ) {
            this._defaultSelect = [this._defaultSelect];
          }
        }
        if (this.defaultSelected instanceof Array) {
          if (this.defaultSelected.length > 0) {
            if (!(this.selectedDropdownItem instanceof Array)) {
              this.selectedDropdownItem = [];
            }
            const selected = [];
            this.defaultSelected.forEach((i) => {
              let dataObj = null;
              this.data.forEach((obj) => {
                if (this.getText(i) === this.getText(obj)) {
                  dataObj = obj;
                  return false;
                }
              });
              if (dataObj instanceof Object) {
                if (!this.adhocEnable) {
                  dataObj.checked = true;
                }
                // dataObj.checked = true;
                if (dataObj.checked) {
                  if (this.selectedDropdownItem.indexOf(dataObj) === -1) {
                    this.selectedDropdownItem.push(dataObj);
                  }
                } else {
                  if (this.selectedDropdownItem.indexOf(dataObj) > -1) {
                    this.selectedDropdownItem.splice(
                      this.selectedDropdownItem.indexOf(dataObj),
                      1
                    );
                  }
                }
                selected.push(dataObj);
              }
              // this.onCheckBoxSelect(null, i);
            });
            if (selected.length > 0) {
              this.data.forEach((i) => {
                selected.indexOf(i) === -1 ? (i.checked = false) : '';
              });
              this.itemCheck.emit(selected);
              this.onSelectionChange.emit(this.selectedDropdownItem);
              this.propagateChange(this.selectedDropdownItem);
            } else {
              if (this.data.length > 0) {
                this.unCheckAll();
              }
              this.itemCheck.emit(this.selectedDropdownItem);
              this.onSelectionChange.emit(this.selectedDropdownItem);
              this.propagateChange(this.selectedDropdownItem);
            }
          } else {
            if (this.data.length > 0) {
              this.unCheckAll();
            }
            this.itemCheck.emit(this.selectedDropdownItem);
            this.onSelectionChange.emit(this.selectedDropdownItem);
            this.propagateChange(this.selectedDropdownItem);
          }
        }
      }
      if (this.adhocEnable) {
        if (this.defaultSelected && this.defaultSelected[0].dispValue) {
          this._dropdownTitle =
            this.defaultSelected[0].dispValue !== undefined
              ? this.defaultSelected[0].dispValue
              : this._dropdownTitle;
        }
      }
    }
    this.dynamicResultsLoading = false;
    if (this.showDiv) {
      this.dropdownVisible = true;
    }
  }

  public getDisplay() {
    if (this.dropdownVisible) {
      return 'block';
    } else {
      ('none');
    }
  }

  public toggleDropdownVisibility() {}

  public getText(item: any) {
    let text: any = '';
    try {
      let readKey = this.defaultReadKey;
      if (typeof this.readKey === 'string') {
        readKey = this.readKey;
      }
      if (item instanceof Array) {
        text = [];
        item.forEach((i) => {
          let readText: any = ObjectUtils.deepFind(i, readKey);
          if (typeof readText === 'string') {
          } else if (readText instanceof Array) {
            readText = readText.toString();
          } else {
            readText = '';
          }
          readText = readText as string;
          if ((readText || '').length > 0) {
            text.push(readText);
          }
        });
        if (text.length > 0) {
          if (text.join('').length === 0) {
            text = '';
          }
        }
        text = text.join(', ');
      } else if (item instanceof Object) {
        text = ObjectUtils.deepFind(item, readKey);
        /* let selectedText;
                selectedText = ObjectUtils.deepFind(this.selectedDropdownItem, readKey);
                if (selectedText === this.allText) {
                    item.checked = true;
                } else {
                    if (text === selectedText) {
                        item.checked = true;
                    } else {
                        item.checked = false;
                    }
                } */
      } else if (typeof item === 'string' || typeof item === 'number') {
        text = item;
      } else {
        // item.checked = false;
        text = '';
      }
      return text;
    } catch (e) {
      console.log('get text catch block : ', item, e);
      return text;
    }
  }

  public isItemDisabled(item: any) {
    if (item instanceof Object) {
      if (typeof item[this.disabledKey] === 'boolean') {
        return item[this.disabledKey];
      }
    }
    return false;
  }

  public setSelectedItem(item: any) {
    this.selectedDropdownItem = item;
  }

  /*  ControlValueAccessorFunctionImplementation */
  public writeValue(value: any) {
    if (this.data instanceof Array) {
      if (this.data.length > 0) {
        this.data.forEach((item) => {
          if (JSON.stringify(item) === JSON.stringify(value)) {
            return this.setSelectedItem(value);
          }
        });
      }
    }
    // this.setSelectedItem(value);
  }

  public registerOnChange(fn) {
    this.propagateChange = fn;
  }

  public registerOnTouched() {}

  public ngOnDestroy() {}

  public checkAll() {
    if (this.data.length > 0) {
      if (this.filteredData.length === this.data.length) {
        // if (this.setCheckAll === true) {
        //   return;
        // }
        if (
          this.selectedDropdownItem instanceof Array &&
          this.selectedDropdownItem.length === this.items.length
        ) {
          return;
        }
        this.setCheckAll = true;
      } else {
        this.setCheckAll = false;
      }
      if (!(this.selectedDropdownItem instanceof Array)) {
        this.selectedDropdownItem = [];
      }
      const selectedItem = this.selectedDropdownItem;
      this.filteredData.forEach((e) => {
        e.checked = true;
        if (selectedItem.indexOf(e) === -1) {
          selectedItem.push(e);
        }
      });
      this.selectedDropdownItem = selectedItem;
      this.onCheckAll.emit(this.selectedDropdownItem);
      this.onSelectionChange.emit(this.selectedDropdownItem);
      this.propagateChange(this.selectedDropdownItem);
    }
  }

  public unCheckWhole(e) {
    this.onUnCheckWhole.emit(true);
    this.unCheckAll(e);
  }

  public unCheckAll(e?: any) {
    if (this.data.length > 0) {
      if (!(this.selectedDropdownItem instanceof Array)) {
        this.selectedDropdownItem = [];
      }
      this.setCheckAll = false;
      const selectedItem = [...this.selectedDropdownItem];
      this.selectedDropdownItem.forEach((e) => {
        e.checked = false;
        if (selectedItem.indexOf(e) > -1) {
          selectedItem.splice(selectedItem.indexOf(e), 1);
        }
      });
      this.selectedDropdownItem = selectedItem;
      this.onUnCheckAll.emit(this.selectedDropdownItem);
      this.onSelectionChange.emit(this.selectedDropdownItem);
      this.propagateChange(this.selectedDropdownItem);
      try {
        if (e) {
          this.clearSearchText(e);
        }
      } catch (exception) {
        console.log(exception);
      }
    }
  }

  public onCheckBoxSelect(e, obj) {
    /* e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation(); */
    if (!(this.selectedDropdownItem instanceof Array)) {
      this.selectedDropdownItem = [];
    }
    if (this.data instanceof Array) {
      if (this.data.length > 0) {
        if (this.data.indexOf(obj) === -1) {
          this.data.forEach((i) => {
            if (this.getText(i) === this.getText(obj)) {
              const checked = obj.checked;
              obj = i;
              i.checked = checked;
              return false;
            }
          });
        }
      }
    }
    if (this.selection === 'multi') {
      if (obj.checked) {
        if (this.selectedDropdownItem.indexOf(obj) === -1) {
          this.selectedDropdownItem.push(obj);
        }
      } else {
        if (this.selectedDropdownItem.indexOf(obj) > -1) {
          this.selectedDropdownItem.splice(
            this.selectedDropdownItem.indexOf(obj),
            1
          );
        }
      }
    } else {
      if (this.selectedDropdownItem.indexOf(obj) === -1) {
        this.selectedDropdownItem = [obj];
      }
      this.onClickOutside(e);
    }
    this.itemCheck.emit(obj);
    this.onSelectionChange.emit(this.selectedDropdownItem);
    this.propagateChange(this.selectedDropdownItem);
  }

  public onMultiSelectKey(e) {
    if (!this.dynamicSearch) {
      if (this.search.length > 0) {
        this.filteredData = [];
        this.data.forEach((element, index) => {
          const text = StringUtils.quote(this.search.trim());
          const re = new RegExp(text, 'i');
          if ((this.getText(element) || '').search(re) > -1) {
            this.filteredData.push(element);
          }
        });
      } else {
        this.filteredData = this.data;
      }
    } else {
      this.onDynamicSearchInputChange.emit(e);
    }
    // console.log('filtered data', this.filteredData);
  }
  public clearSearchText(e) {
    this.search = '';
    this.onMultiSelectKey(e);
  }

  public getSelectedArray() {
    /* let selected = [];
        if (this.data instanceof Array) {
            if (this.data.length > 0) {
                selected = this.data.filter((i) => {
                    return i.checked === true;
                });
            }
        }
        return selected; */
    return this.selectedDropdownItem || [];
  }
  public onDropdownMenuClick(e) {
    e.stopImmediatePropagation();
  }
  private propagateChange = (_: any) => {};
}
