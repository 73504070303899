import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { ICellRendererParams } from '@ag-grid-community/core';
import { NumericUtils } from 'app/modules/common/utills/NumericUtils.service';
import { ObjectType } from 'app/modules/common/interface/objectype';

@Component({
  selector: 'child-cell',
  template: `
    <div
      class="full-width full-height"
      [ngClass]="[
        deviationIndicator === 0
          ? ''
          : deviationIndicator > 0
          ? 'ag-grid-deviation-up'
          : 'ag-grid-deviation-down'
      ]"
      title="{{ renderCell?.title }}"
    >
      <span>{{ renderCell?.value }}</span>
      <span
        class="text-right pull-right text-right deviation_down_div"
        *ngIf="showDeviation"
      >
        <em
          class="fa"
          [ngClass]="[
            deviationIndicator > 0
              ? isSuccess
                ? 'fa-long-arrow-up text-success ag-grid-deviation-up'
                : 'fa-long-arrow-up text-danger ag-grid-deviation-up'
              : isSuccess
              ? 'fa-long-arrow-down text-success ag-grid-deviation-down'
              : 'fa-long-arrow-down text-danger ag-grid-deviation-down'
          ]"
          style="cursor: auto;"
          aria-hidden="true"
        ></em> 
        <span
          class="deviation_down_div_span"
          [ngClass]="[isSuccess ? 'text-success' : 'text-danger']"
          ><span>{{ absValue }}</span
          >&nbsp;<span *ngIf="showPercent">%</span></span
        >
      </span>
    </div>
  `
})
export class ShowDeviationsComponent implements ICellRendererAngularComp {
  public deviationMapObj: object;
  public params: ICellRendererParams;
  public showDeviation = false;
  public isSuccess = false;
  public showPercent = true;
  public deviationIndicator = 0;
  public absValue = 0;
  public renderCell: ObjectType = {};
  public validationMap: Array<any> = [];

  constructor(private dataStorageService: DataStorageService) {
    this.deviationMapObj = this.dataStorageService.getDeviationMap();
    this.validationMap = this.dataStorageService.getValidationMessages();
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    const currentColumn = params.colDef.field;
    const errorFlag = currentColumn + '_valid';
    const currentRow: object = params.data;

    if (params.value === null) {
      this.renderCell['value'] = null;
    } else {
      this.renderCell['value'] = Number.isNaN(Number(params.value))
        ? params.value
        : NumericUtils.format(params.value, params['decimalPrecision']);
    }
    this.renderCell['title'] = params.value;
    if (currentRow[errorFlag] !== undefined && currentRow[errorFlag] !== null) {
      this.renderCell['title'] = currentRow[errorFlag].errorMessage;
    }
    this.getCellValue(params);
  }

  getCellValue(params) {
    let deviationIndicatorColumn = '';
    const currentColumn = params.colDef.field;

    if (params['deviationValueColumn'] === undefined) {
      deviationIndicatorColumn = this.deviationMapObj[currentColumn][
        'deviationValueColumn'
      ];
    } else {
      deviationIndicatorColumn = params['deviationValueColumn'];
    }

    if (deviationIndicatorColumn.indexOf('.') !== -1) {
      this.deviationIndicator = Math.round(
        params.data[deviationIndicatorColumn.split('.')[0]][
          deviationIndicatorColumn.split('.')[1]
        ]
      );
    } else {
      if (!!params.data[deviationIndicatorColumn]) {
        this.deviationIndicator = Math.round(
          params.data[deviationIndicatorColumn]
        );
      }
    }

    if (!this.deviationIndicator) {
      this.showDeviation = false;
    } else {
      this.showDeviation = true;
      this.absValue =
        this.deviationIndicator > 0
          ? this.deviationIndicator
          : -this.deviationIndicator;

      if (this.deviationIndicator > 0) {
        this.isSuccess = params.splitCol ? true : false;
      } else {
        this.isSuccess = params.splitCol ? false : true;
      }

      this.showPercent = params.unitCostCol || params.splitCol ? true : false;
    }
  }

  refresh(): boolean {
    return false;
  }
}
