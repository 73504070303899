import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'rfq-calculations',
  templateUrl: './rfq-calculations.component.html',
  styleUrls: ['./rfq-calculations.component.sass'],
  providers: []
})
export class RfqCalculationsComponent {
  private _formula: any;
  @Output() formulaChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  set formula(val) {
    if (this._formula === val) {
      return;
    }
    this._formula = val;
    this.formulaChanged.emit(this._formula);
  }
  get formula() {
    return this._formula;
  }
}
