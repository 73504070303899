import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Alerts } from './shared/alerts.model';
import { AlertsService } from './shared/alerts.service';
import { DataStorageService } from 'app/modules/common/data-storage.service';

@Component({
  selector: 'alerts',
  templateUrl: 'alerts.component.html',
  styleUrls: ['./alerts.component.sass'],
  providers: [AlertsService]
})
export class AlertsComponent implements OnInit {
  alerts: Alerts[] = [];
  @Input() rfqId;
  public customPayLoadObject: any;
  public alertIdArray = [];
  isClassVisible = false;
  public responseObject: any;
  public alertResponseObject: any;
  public clearAlertResponseObject: any;
  noData = false;

  constructor(
    private alertsService: AlertsService,
    private router: Router,
    private dataStorageService: DataStorageService
  ) {}

  ngOnInit() {
    this.getAlertsInformation(this.rfqId);
  }

  getAlertsInformation(rfqId) {
    this.customPayLoadObject = {
      rfqId: this.rfqId
    };
    this.alertsService
      .getAlertsInfo(this.customPayLoadObject)
      .subscribe(
        data => (this.responseObject = data),
        error => console.log('Unable to get alerts data for rfq id:' + rfqId),
        () => this.fetchAlertsObject()
      );
  }

  public fetchAlertsObject() {
    if (this.responseObject.responseStatus.code === 200) {
      this.alerts = this.responseObject.result;
      this.noData = this.alerts.length > 0 ? false : true;
    }
  }
  public replyAlert(alertId) {
    this.alertIdArray.push(alertId);
    this.alertsService
      .replyAlert(this.alertIdArray)
      .subscribe(
        data => (this.alertResponseObject = data),
        error => console.log('Unable to reply for alert id:' + alertId),
        () => this.checkAlertStatus()
      );
  }
  public checkAlertStatus() {
    if (this.alertResponseObject.responseStatus.code === 200) {
    }
  }

  public clearAlert(alertId) {
    this.alertIdArray.push(alertId);
    this.alertsService
      .ClearAlert(this.alertIdArray)
      .subscribe(
        data => (this.clearAlertResponseObject = data),
        error => console.log('Unable to reply for alert id:' + alertId),
        () => this.checkClearAlertStatus()
      );
  }

  public checkClearAlertStatus() {
    if (this.clearAlertResponseObject.responseStatus.code === 200) {
    }
  }
}
