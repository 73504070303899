import { Component, Input, Output, EventEmitter } from '@angular/core'
import { SraEmitterService } from 'app/modules/supply-risk-assessment/sra-emitter.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'cprt-custom-legend',
  templateUrl: './custom-legend.component.html',
  styleUrls: ['./custom-legend.component.sass'],
})
export class CustomLegendComponent {
  @Input()
  legends: any
  @Output()
  onClick: EventEmitter<any> = new EventEmitter<any>();
  selectedLegend

  constructor(private sraEmitterService: SraEmitterService,) {
    this.sraEmitterService
      .getResetMapEmitter()
      .subscribe((pointResult) => {
        if (this.selectedLegend && pointResult) {
          this.legends.forEach(legend => legend.visible = true)

          this.onClick.emit(this.selectedLegend)
          this.selectedLegend = null
        }
      })
  }
  onLegendClick(event) {
    this.selectedLegend = event
    this.legends.forEach(legend => legend.visible = !legend.visible)
    event.visible = true
    this.onClick.emit(event)
  }
}
