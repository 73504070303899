import { CustomerConfigurationService } from './../../common/customerConfigurationService';
import { Component, OnInit,Input } from '@angular/core';
import { IndustryNewsContentService } from './shared/IndustryNewsContent.service';
import { NewsListService } from 'app/modules/core/news-list/news-list.service';
import * as Highcharts from 'highcharts';
import { ObjectType } from 'app/modules/common/interface/objectype';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';
@Component({
  selector: 'IndustryNewsContent',
  templateUrl: 'IndustryNewsContent.component.html',
  styleUrls: ['./IndustryNewsContent.component.sass'],
  providers: []
})

export class IndustryNewsContentComponent implements OnInit {
  // IndustryNewsContent: IndustryNewsContent[] = [];
  selectedNewsItem : any;
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  constructor(private IndustryNewsContentService: IndustryNewsContentService,
        private newsListService:NewsListService	,private customerConfigurationService: CustomerConfigurationService
  ) { }
  IndustryNews: any;
  newscontent:any={};
  inewssubcommodity:any={};
  @Input() target : ObjectType;
  IndustryNewsContent:ObjectType = {};
  custConfname: any;
  subcommName: string;

  ngOnInit() {

this.custConfname= this.customerConfigurationService.getCommodityConfData();
this.subcommName = this.custConfname.dimensionData.SubCommodity;

    this.inewssubcommodity = {
      chart: {
        type: 'column',
        style: {
          fontFamily: 'Roboto',
          'font-size':'14px'
        }
      },
      title: {
        text: ''
      },

      xAxis: {
        categories: ['Connectors', 'Inductors', 'Magnets', 'PCB’s','Batteries'],
        title: {
          text: this.subcommName
        },
        labels:{
          style:{
            fontSize:'14px'
          }
        }
      },
      yAxis: {
        gridLineWidth: 1,
        lineWidth: 1,
        minorGridLineWidth: 1,
        title: {
          text: '$millions',
          align: 'high'
        },
        labels: {
          overflow: 'justify'
        }
      },
      tooltip: {
        valueSuffix: ' millions'
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,

          }
        }
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
      },

      series: [{
        name: 'Saving',
        color:'#41B4D8',
        data: [1000, 500, 600, 500, 500]
      }, {
        name: 'Spend',
        color:'#FFD066',
        data: [600, 400, 700, 400, 400]
      }
      ]
    }
    this.newscontent = {
      chart: {
        type: 'line',

      },
      title: {
        text:'Copper in USD/tonnes'
      },
      xAxis: {
        title: {
          text: '',
          align: 'top'
        },
        categories: ['10/24/2016', '10/31/2016', '11/07/2016', '11/14/2016',  '11/21/2016', '11/28/2016', '12/05/2016', '12/12/2016', '12/19/2016','12/26/2016', '01/03/2017',  '01/10/2017', '01/17/2017', '01/24/2017', '02/06/2017', '02/13/2017', '02/20/2017', '02/27/2017', '03/06/2017', '03/13/2017', '03/20/2017','03/27/2017','04/05/2017','04/12/2017','04/19/2017','04/26/2017','05/02/2017','05/09/2017','05/16/2017','05/23/2017','05/30/2017', '06/05/2017', '06/11/2017', '06/18/2017', '06/25/2017','07/03/2017','07/10/2017','07/17/2017','07/24/2017','07/31/2017', '08/21/2017', '08/28/2017','09/04/2017','09/11/2017']
      },
      yAxis: {
        gridLineWidth: 1,
        lineWidth: 1,
        minorGridLineWidth: 1,
        title: {
          text: '',
          align: 'high'
        },
        labels: {
          enabled: true,
          overflow: 'justify',
          formatter () {
                return  Highcharts.numberFormat(this.value , 2) ;
              }
        }
      },
      tooltip: {
        pointFormat: ' <b>{point.y}</b>',
        crosshairs: true,
        shared: true
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: false,
          }
        },
        series: {
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 7,
            states: {
              hover: {
                enabled: true
              },
            },
            width: 16,
            height: 16
          },
        }
      },
      series: [{
        name: 'data1',
        data:  [4667.7,4740.3,4925.2, 5503.6, 5470.6, 5754.6, 5775, 5835.6, 5678.4, 5472.75, 5475.4, 5529.6, 5593.16, 5759.5,5865.48, 5901.6, 6022.5, 5933.5, 5949.4, 5749.9, 5857, 5749.5, 5782.91, 5759.8, 5621.62, 5715.04,5534, 5556.1, 5579.75, 5644.05, 5593.91, 5650.55, 5664.2, 5720.25, 5874.3,5815.95,5880.1,5965.05,5984,6267.6, 6414,6622.6,6823.5,6824.6],
      },
      ]
    }
  }
  ngOnChanges(){
    this.IndustryNewsContent = this.target;
  }
  readImpact(event){
    const clickedItem:any = document.getElementById('readImpactbtn');
    if (clickedItem.value==='View Impact'){
      clickedItem.value = 'Read Less';
      event.currentTarget.innerText = 'Read Less';
      document.getElementById('readImpactBlock').style.display = 'block';
    }else{
      clickedItem.value = 'View Impact';
      document.getElementById('readImpactBlock').style.display = 'none';
      event.currentTarget.innerText = 'View Impact';
    }
  }

}
