import { CheckoutService } from './../../npi-parts-workbench/checkout/checkout.service';
import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { PayLoad } from 'app/modules/common/models/payload.model';
import { RequestData } from 'app/modules/common/models/request.model';
import { SubscriptionService } from 'app/modules/common/subscription.service';
import { NpiCommonApiService } from 'app/modules/npi/npi-common-api.service';
import { SubscriptionLike } from 'rxjs';
import { updateUserAction } from '../bom-tree-grid/utils/tree-track-action';

@Component({
  selector: 'app-leva-autosearch-bom-render',
  templateUrl: './leva-autosearch-bom-render.component.html',
  styleUrls: ['./leva-autosearch-bom-render.component.sass'],
})
export class LevaAutosearchBomRenderComponent implements OnDestroy {
  public params: any;
  public request;
  public displayAS = false;
  public dimension;
  public dbCol;
  public multiSelect = false;
  public autoSearchSubscription: SubscriptionLike;
  public maturityValues = [
    { title: '1', value: 1, display: '1' },
    { title: '2', value: 2, display: '2' },
    { title: '3', value: 3, display: '3' },
    { title: '4', value: 4, display: '4' },
    { title: '5', value: 5, display: '5' },
  ];
  public curVal;
  public dropDownClicked = false;
  public border;
  public error = false;
  public edited = false;
  public levaAutoSearchRequestPayload =
    this.dataStorageService.getLevaAutoSearchPayload();
  @Output() public hoveredCPN = new EventEmitter<{ mpn: string }>();
  constructor(
    private subscriptionService: SubscriptionService,
    private npiCommonApiService: NpiCommonApiService,
    private dataStorageService: DataStorageService,
    private checkOutService: CheckoutService
  ) {}
  agInit(params: any): void {
    this.params = params;
    if (
      this.params.colDef.field === 'maturityLevel' &&
      (this.params.data.componentType === 'PART' ||
        this.params.data.componentType === 'CPN' ||
        this.params.data.componentType === 'SUB_ASSEMBLY')
    ) {
      this.curVal = parseInt(this.params.data['maturityLevel'] || null, 10) - 1;

      this.multiSelect = true;
    }
    if (this.isComponentEditable(params)) {
      this.params.disabledSearchVal =
        this.params.data[this.params.colDef.field];
    } else {
      delete params['disabledSearchVal'];
    }
    this.dimension = [];
    if (this.params.context && this.params.context.componentParent.cellStyle) {
      const style = this.params.context.componentParent.cellStyle(params);
      if (style instanceof Object && style.border) {
        if (style.border.indexOf('#29ABE2') !== -1) {
          this.edited = true;
          this.border = style.border;
        } else if (style.border.indexOf('red') !== -1) {
          // this.error = true;
          this.border = style.border;
        } else {
          this.border = '';
        }
      }
    }
    this.autoSearchRequest();
    this.autoSearchSubscription = this.subscriptionService
      .getAutoSearchText()
      .subscribe((val) => {
        if (
          val instanceof Object &&
          val.id ===
            this.params.data.nodePath.join('.') + this.params.colDef.field &&
          this.request.searchCustomization instanceof Object
        ) {
          val.value = val[this.request.searchCustomization['displayKey']];
        }
        if (
          val instanceof Object &&
          typeof val.value === 'string' &&
          val.id ===
            this.params.data.nodePath.join('.') + this.params.colDef.field &&
          (this.params.data[this.params.colDef.field] !== val.value ||
            !this.edited)
        ) {
          this.params['oldValue'] = this.params.data[this.params.colDef.field];
          this.params.data[this.params.colDef.field] = val.value;
          this.params['newValue'] = val.value;
          this.params['newlyAdded'] = val.newlyAdded;
          if (this.params.colDef.field === 'tagName') {
            params.data.tagId = val.tagId;
          }
          if (
            params.data.validations &&
            params.data.validations[this.params.colDef.field]
          ) {
            delete params.data.validations[this.params.colDef.field];
          }
          this.params.context.componentParent.onCellValueChanged(params);
          if (
            this.params.context &&
            this.params.context.componentParent.cellStyle
          ) {
            const style = this.params.context.componentParent.cellStyle(params);
            if (style instanceof Object && style.border) {
              if (style.border.indexOf('#29ABE2') !== -1) {
                this.edited = true;
                this.border = style.border;
              } else if (
                style.border.indexOf('red') !== -1 &&
                !val.newlyAdded
              ) {
                this.error = true;
                this.border = style.border;
              } else {
                this.border = '';
              }
            }
          }
          if (val.newlyAdded) {
            this.npiCommonApiService
              .addAttribute({
                name: this.dimension,
                value:
                  typeof val.value === 'string'
                    ? encodeURIComponent(
                        val.value.trim().replace(/\s\s+/g, ' ')
                      )
                    : encodeURIComponent(val.value),
              })
              .subscribe((value) => {
                this.params.context.componentParent.gridApi.forEachNode(
                  (eachNode) => {
                    if (
                      eachNode.data.cmOdm === val.value ||
                      eachNode.data.manufacturer === val.value ||
                      eachNode.data.supplier === val.value
                    ) {
                      eachNode.data.validations = null;
                    }
                  }
                );
                this.params.context.componentParent.gridApi.refreshCells({
                  force: true,
                });
              });
          }
        }
      });
  }
  public dropdownClicked() {
    this.dropDownClicked = !this.dropDownClicked;
  }
  public mlValueChanged($event) {
    if (
      $event &&
      $event.value &&
      parseInt(this.params.data['maturityLevel'], 10) !== $event.value
    ) {
      this.checkOutService.disableButtonsInfo.save = false;
      if (
        this.params.data['maturityLevel'] &&
        !isNaN(this.params.data['maturityLevel']) &&
        this.params.data['maturityLevel'].split('.').length === 2 &&
        this.params.data['maturityLevel'].split('.')[1] === '0'
      ) {
        this.params['oldValue'] =
          this.params.data['maturityLevel'].split('.')[0];
      } else {
        this.params['oldValue'] = this.params.data['maturityLevel'];
      }
      this.params.data['maturity'] = this.params['oldValue'];
      this.params.data['maturityLevel'] = $event.display;
      updateUserAction({ type: 'update', data: this.params.data });
      this.params['newValue'] = $event.display;
      this.params.value = $event.display;
      if (
        this.params.data.validations &&
        this.params.data.validations[this.params.colDef.field]
      ) {
        delete this.params.data.validations[this.params.colDef.field];
      }
      //this.params.context.componentParent.onCellValueChanged(this.params);
      const style = this.params.context.componentParent.cellStyle(this.params);
      if (style instanceof Object && style.border) {
        if (style.border.indexOf('#29ABE2') !== -1) {
          this.edited = true;
          this.border = style.border;
        } else if (style.border.indexOf('red') !== -1) {
          this.error = true;
          this.border = style.border;
        } else {
          this.border = '';
        }
      }
    }
  }

  public autoSearchRequest() {
    const payload = new PayLoad();
    payload.isWildCardSearch = true;
    this.request = new RequestData();
    this.request.searchCustomization = null;
    this.request.targetedType = this.levaAutoSearchRequestPayload.targetedType;
    this.request.defaultSelection = {};
    this.request.method = this.levaAutoSearchRequestPayload.method;
    this.request.inputHeight = '24px';
    this.request.typeOfSelectSingle = true;
    this.request.servicePath = this.levaAutoSearchRequestPayload.servicePath;
    this.request.placeHolder = 'Select ' + this.params.colDef.headerName;
    this.request.type = this.levaAutoSearchRequestPayload.type;
    this.request.title = this.levaAutoSearchRequestPayload.title;
    this.request.payload = payload;
    this.request.unmatchedSearch = true;
    this.request.restrictedVals = ['_NA'];
    if (this.params.colDef.cellRendererParams) {
      this.dimension = this.params.colDef.cellRendererParams.dbCol;
    }

    this.request.id =
      this.params.data.nodePath.join('.') + this.params.colDef.field;
    this.request.formParams = {
      dimensions: [this.dimension],
      type: 'DATA',
    };
    this.request.payload = {
      dimension: [this.dimension],
      type: 'DATA',
    };
    if (this.params.data[this.params.colDef.field]) {
      this.request.defaultSelection =
        this.params.data[this.params.colDef.field];
    } else {
      this.request.placeHolder = 'Select ' + this.params.colDef.headerName;
      this.request.defaultSelection = undefined;
    }

    if (
      typeof this.params.context.componentParent.editable === 'function' &&
      this.params.colDef.field !== 'tagName'
    ) {
      this.displayAS = this.params.context.componentParent.editable(
        this.params
      );
    } else if (this.params.colDef.field === 'tagName') {
      if (this.params.context.componentParent.LOCALCONFIG === 'NPI_VIEW_BOM') {
        this.displayAS = false;
        if (
          this.params.data.componentType === 'CPN' ||
          this.params.data.componentType === 'SUB_ASSEMBLY'
        ) {
          this.displayAS = true;
        }
      } else {
        if (
          !Object.values(this.params.node.childrenMapped).some((ele) => ele)
        ) {
          this.displayAS = true;
        } else {
          this.params.data['tagName'] = '';
          this.displayAS = false;
        }
      }
    }
  }

  public isComponentEditable(params) {
    if (
      (params &&
        params.node.data &&
        params.node.data.componentType !== 'CPN' &&
        ((typeof params.node.data.enableRfx === 'boolean' &&
          params.node.data.enableRfx === false) ||
          (params.node.data.hasOwnProperty('enableRfx') &&
            params.node.data.enableRfx === undefined))) ||
      (params.node.data.componentType === 'CPN' &&
        ((!Object.values(params.node.childrenMapped).every(
          (i) => typeof i['data']['enableRfx'] === 'boolean'
        ) &&
          typeof params.node.data.enableRfx === 'boolean' &&
          params.node.data.enableRfx === false) ||
          (!Object.values(params.node.childrenMapped).every(
            (i) => typeof i['data']['enableRfx'] === undefined
          ) &&
            Object.values(params.node.childrenMapped).every(
              (i) => typeof i['data']['enableRfx'] === 'boolean'
            ) &&
            !Object.values(params.node.childrenMapped).every(
              (i) => i['data']['enableRfx']
            )))) ||
      this.isParentIncludedInRfi(params)
    ) {
      return true;
    }
    return false;
  }

  public isParentIncludedInRfi(param) {
    if (
      (param.node &&
        param.node.parent &&
        param.node.parent.data &&
        param.node.parent.data.componentType !== 'CPN' &&
        typeof param.node.parent.data.enableRfx === 'boolean' &&
        !param.node.parent.data.enableRfx) ||
      (param.parent &&
        param.parent.data &&
        typeof param.parent.data.enableRfx === 'boolean' &&
        !param.parent.data.enableRfx)
    ) {
      return true;
    } else if (param.node && param.node.parent && param.node.parent.parent) {
      return this.isParentIncludedInRfi(param.node.parent);
    } else if (param.parent && param.parent.parent) {
      return this.isParentIncludedInRfi(param.parent);
    } else {
      return false;
    }
  }

  ngOnDestroy() {
    if (this.autoSearchSubscription) {
      this.autoSearchSubscription.unsubscribe();
    }
  }
}
