import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

import { Collaboration } from './collaboration.model';

@Injectable()
export class CollaborationService {
  constructor(private http: HttpClient) {}
}
