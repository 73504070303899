export const LEVA_GLOBAL = {
  BRAND_COLOR: '#1C4E84',
};

export const RFQ_STATUS = [ 'Draft',
'Sent',
'Negotiation In-Progress',
'Award In-Progress',
'Awarded',
'Revoked',
'Closed']
