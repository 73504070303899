import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

import { AboutLevadata } from './about-levadata.model';
import { ApiServiceProvider } from 'app/api-service';
import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService';

@Injectable()
export class AboutLevadataService {

  constructor(private http: HttpClient, private apiServiceProvider: ApiServiceProvider, private customerConfigurationService: CustomerConfigurationService) { }

  public getAboutScreenDetails() {
    const envDetails = this.customerConfigurationService.getEnvironmentDetails();
    const tenantId = envDetails.tenantId;
    const object: any = {
      targetedType: 'CustomReports',
      method: 'GET',
      formParams: {
        tenantId
      },
      servicePath: '/versionDetails'
    };
    return this.apiServiceProvider.post(object);
  }
}
