import { CustomerConfigurationService } from './../../common/customerConfigurationService'
import { Component, OnInit, OnDestroy, Renderer2, ViewChild, ElementRef, NgZone } from '@angular/core'
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router'
import { AppHeader } from './shared/app-header.model'
import { AppHeaderService } from './shared/app-header.service'
import { BaseService } from 'app/modules/common/base.service'
import { ScopeDataService } from 'app/modules/common/scope.data.service'
import { AuthenticationService } from 'app/modules/authentication/shared/authentication.service'
import { TimeSelectionDataService } from 'app/modules/core/time-selection/shared/time-selectiondata.service'
import { SubscriptionService } from 'app/modules/common/subscription.service'
import { SubscriptionLike, Subscription } from 'rxjs'
import { DataStorageService } from '../../common/data-storage.service'
import { supplier } from 'app/modules/srfq/common/constants'
import { DateUtils } from 'app/modules/common/utills/DateUtils.service'
import * as _ from 'lodash'
import { WindowService } from 'app/modules/common/window.service'
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service'
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe'
import * as jwt from 'jwt-simple'

import {
  DialogType,
  DialogButtonName,
  DialogBoxService,
  LdDialog,
  DialogSize,
} from 'app/modules/leva-ui-library/components/dialog-box/dialog-box.service'
import { title } from 'process';
import * as moment from 'moment-timezone-all';
import { CUSTOMER_CURRENT_USER, CUSTOMER_CUSTOMER_DATA, CUSTOMER_FOCUS } from '../../shared/services/shared-consts';

const disabledScopeDropdownRoutes = ['portfolio-risk']
@AutoUnsubscribe()
@Component({
  selector: 'app-header',
  moduleId: module.id,
  templateUrl: 'app-header.component.html',
  styleUrls: ['app-header.component.sass'],
  providers: [AppHeaderService, AuthenticationService],
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  alertsCount$: Subscription;
  alertsCount = 0

  @ViewChild('toggleButton', { static: true }) toggleButton: ElementRef;
  @ViewChild('menu', { static: false }) menu: ElementRef;
  @ViewChild('moreOptionButton', { static: true }) moreOptionButton: ElementRef;
  @ViewChild('moreOptionMenu', { static: false }) moreOptionMenu: ElementRef;
  @ViewChild('taskManagement', { static: false }) taskManagement: ElementRef;


  timezoneAbbr: string;
  notificationCount$: Subscription

  aboutLevadata: LdDialog = {
    title: 'About LevaData',
    id: 'about_cognitive_platform',
    template: true,
    size: 'large',
    type: DialogType.INFORMATION,
    copyright: true,
    buttons: [
      {
        title: DialogButtonName.OK,
        id: 'ok',
        primary: true,
      },
    ],
  }

  public changePasswordDialogData: LdDialog = {
    // title: 'About LevaData',
    id: 'change_password',
    logo: 'assets/images/logo-new-8.jpg',
    template: true,
    size: 'small',
    message: 'enim aperiam reprehenderit animi au',
    type: DialogType.ERROR,
    customSize: {
      width: 550
    }
  }

  dataLoadPopUpConfig: LdDialog = {
    title: '',
    id: 'data_load_popup',
    template: true,
    size: DialogSize.SMALL,
    type: DialogType.INFORMATION,
    buttons: [
      {
        title: DialogButtonName.OK,
        id: 'ok',
        primary: true,
      },
    ],
  }

  userInital: any;
  fullName: any;
  displayBlue: boolean
  mouseOnDropDown: boolean
  moreOptionShow: boolean

  constructor(
    private appHeaderService: AppHeaderService,
    private authenticationService: AuthenticationService,
    private scopeDataService: ScopeDataService,
    private route: ActivatedRoute,
    private router: Router,
    private baseService: BaseService,
    private timeSelectionDataService: TimeSelectionDataService,
    private subscriptionService: SubscriptionService,
    private zone: NgZone,
    public dataStorageService: DataStorageService,
    private customerConfigurationService: CustomerConfigurationService,
    private windowService: WindowService,
    private dialogBoxService: DialogBoxService,
    private renderer: Renderer2
  ) {
    this.initialHeaderConfiguration();
    this.displayBlue = false;
    this.mouseOnDropDown = false;
    this.eventListnerHover();
  }

  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  leftNavbarFeatures: any;
  viewDetails = false;
  showscope = false;
  menuMap: any;
  customerData: any;
  toggleMsgPanel = false;
  unReadSubjects: Array<any>;
  year = 'Next Quarter';
  yearList: any[];
  public rfxManageSupplier: boolean;
  appHeader: AppHeader[] = [];
  userSessionData: any;
  toggleScopePanel = false;
  csApp = false;
  title = 'Select Time Period';
  showTimePeriod = false;
  showOpportunityTime: boolean;
  scope = 'Commodity';
  selectedTime: any = 'Rest Of Year';
  userEmail = '';
  sisenseSrc = '';
  diffhrs: any;
  scopeList: any[];
  timePeriod: any[];
  custConfname: any;
  custLogoInfo: any;
  custLogo: any;
  scopedropdown = true;
  custMessage: boolean;
  custNotifications: boolean;
  showMessage = false;
  showNotifications = false;
  supName: string;
  featuresArray: Array<string> = [];
  rfxOptions: boolean;
  isSuperAdmin = false;
  tenantAdmin = false;
  dataLoadProgressIcon = false;
  dataLoadCompletedIcon = false;
  dataLoadFailedicon = false;
  isList = true;
  text: string;
  envDetails: any;
  hide = false;
  npiApp = true
  tooltiptext = '';
  unreadCount = 0;
  titleObj: any;
  botSubscription;
  customerConfiguration: any;
  unAuthorized = false;
  scopeDisableSubscription: SubscriptionLike;
  getManualSelectionSubscription: SubscriptionLike;
  dataLoadSubscription: SubscriptionLike;
  unAuthorizedSubscription: SubscriptionLike;
  public supplierConst = supplier;
  private getTimeStampSelectionSubscription: SubscriptionLike;
  configuration: any;

  collapse: boolean;
  animation: boolean;
  diffmins: any;
  inputObj = {
    context: 'test context',
    topic: 'Collaboration',
    userEmail: 'fitbit.user2@fbit.com',
    needCalendar: true,
    needTitle: true,
    defaultType: 'TYPE_1',
    FE_ID: 'NONE',
    subContext: '',
  };
  strtTime: any;
  endTime: any;
  openPopup = false;
  isSSOUser: boolean;
  // refresh(): void {
  //   window.location.reload();
  // }
  showTime = false;
  onShowChatBotDashboard = false;
  enableChatBotDashboard = false;
  public selectedDialogId: string;
  zenDeskURL;

  eventListnerHover() {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.mouseOnDropDown && e.target !== this.toggleButton.nativeElement && e.target !== this.menu.nativeElement) {
        this.mouseOnDropDown = false

      }
      if (this.moreOptionShow && e.target !== this.moreOptionButton.nativeElement && e.target !== this.moreOptionMenu.nativeElement) {
        this.moreOptionShow = false
      }
    })
  }

  initialHeaderConfiguration() {
    this.notificationCount$ = this.scopeDataService
      .getNotificationAlertClick()
      .subscribe((data) => {
        if (data) {
          this.getNotificationCount();
        }
      });
    this.scopeDataService.setFilterOnlyAfterLogin(true);
    this.unAuthorizedSubscription = this.baseService
      .getUnAuthrizePopup()
      .subscribe((val) => {
        this.unAuthorized = val;
      });
    this.onloadDataLloadStatus();
    setInterval(() => {
      if (!this.unAuthorized) {
        this.onloadDataLloadStatus();
      }
    }, 120000);
    this.customerConfiguration = this.customerConfigurationService.getBaseCustomerInfo()
    this.botSubscription = this.dataStorageService
      .getBotServiceName()
      .subscribe((data) => {
        if (data) {
          // const clonedData = _.clone(data);
          if (data.action.trim() === 'scope.change.action') {
            this.onSelected(data.scope || this.scope);
          }
        }
      });
    this.csApp = this.baseService.getCsApp();
    this.titleObj = this.appHeaderService.getRFQTitleObject();
    this.getUnreadSubjects();

    this.timeSelectionDataService.getTitle().subscribe((titleData) => {
      if (titleData !== undefined && titleData !== '') {
        this.title = titleData;
      }
    });
    this.timeSelectionDataService.getOptionSelected().subscribe((option) => {
      if (option !== undefined) {
        this.title = '';
      }
    });
    this.appHeaderService.getMsgPanel().subscribe((val) => {
      if (val || !val) {
        this.toggleMsgPanel = false;
      }
      if (this.unreadCount > 0) {
        --this.unreadCount;
        // this.unreadCount = this.unreadCount -1;
      }
    });
    this.scopeDataService
      .getOpportunitiesTimeFromChatBot()
      .subscribe((time) => {
        if (time !== undefined) {
          let requiredTimeObject: {};
          switch (time.toLowerCase()) {
            case 'next4quarter':
              requiredTimeObject = {
                view: 'Next 4 Quarters',
                title: 'Next 4 Quarters',
                isdefault: false,
              };
              break;
            case 'nextquarter':
              requiredTimeObject = {
                default: false,
                title: 'Next Quarter',
                view: 'Next Quarter',
              };
              break;
            case 'restofyear':
            default:
              requiredTimeObject = {
                default: true,
                title: 'Rest Of Year',
                view: 'Rest Of Year',
              };
              break;
          }
          this.onTimeStampSelection(requiredTimeObject);
        }
      });

    this.scopeDataService.getFocus().subscribe((value) => {
      this.getFeatures();
      this.getUnreadSubjects();

      this.customerData = this.customerConfigurationService.getConfData(
        this.scope
      )
      this.scope = value;
    });

    this.router.events.subscribe((val) => {
      try {
        this.timeManagementFlags(val)
      } catch (e) { }
    });

    this.subscriptionService.getfcPath().subscribe((value) => {
      if (value !== undefined) {
        if (value === 'forecastchange' || value === 'forecastvsactual') {
          this.showTimePeriod = false;
        }
      }
    });

    this.timeSelectionDataService.getClearAll().subscribe((val: string) => {
      if (val === 'clear') {
        this.title = 'Select Time Period';
      }
    });
    this.scopeDataService.getApplicationScope().subscribe((val) => {
      if (val !== undefined) {
        let x: Array<any>;
        let y: Array<any>;
        if (val.selectedScopeData !== undefined) {
          if (val.selectedScopeData.Commodity !== undefined) {
            if (val.selectedScopeData.Commodity['timeDuration']) {
              x = val.selectedScopeData.Commodity['timeDuration'];
            }
          }
          if (val.selectedScopeData.Product !== undefined) {
            if (val.selectedScopeData.Product['timeDuration'] !== undefined) {
              y = val.selectedScopeData.Product['timeDuration'];
            }
          }
          if (x !== undefined && y !== undefined) {
            if (x.length === 0 && y.length === 0) {
              this.title = 'Select Time Period';
            }
          }
        }
      }
    });

    this.scopeAvailability()
    this.scopeDisableSubscription = this.dataStorageService
      .getStatusOfQSDashboard()
      .subscribe((val) => {
        if (val === true) {
          this.showscope = false
        } else if (val === false) {
          this.showscope = true
        } else {
          this.showscope = false
        }
      })
  }

  timeManagementFlags(val: any) {

    if (val instanceof NavigationEnd) {
      if (val['urlAfterRedirects'].search('/spendintelligence') !== -1 ||
        window.location.href.search('/review-opportunities') !== -1 || val['urlAfterRedirects'].search('/opportunities') !== -1) {
        this.showTimePeriod = true;
        this.showOpportunityTime = false;
      } else if (
        window.location.href.search('/savingsopportunities') !== -1 && !window.location.href.includes('np')
      ) {
        this.showTimePeriod = false;
        this.showOpportunityTime = true;
        this.yearList = this.customerConfigurationService.getyearList();
      } else {
        this.showTimePeriod = false;
        this.showOpportunityTime = false;
      }

      if (val['urlAfterRedirects'] === '/app/srfx') {
        this.getUnreadSubjects()
      }
    }
  }

  getUnreadSubjects() {
    this.appHeaderService
      .getUnreadSubjects()
      .subscribe((headerResponse) => {
        // console.log(val);
        if (headerResponse && headerResponse.responseStatus !== undefined) {
          if (headerResponse.responseStatus.code === 200) {
            this.unreadCount = headerResponse.result.length
            this.unReadSubjects = headerResponse.result
          } else {
            this.unReadSubjects = []
          }
        } else {
          this.unReadSubjects = []
          this.unreadCount = 0
        }
      })
  }

  scopeAvailability() {
    this.baseService.getScopeAvailability().subscribe((val) => {
      if (val === 'notavailable') {
        this.hide = true;
        this.npiApp = true;
        switch (this.baseService.gettooltip()) {
          case 'dashboard':
            this.tooltiptext = 'Dashboard';
            this.scopedropdown = false;
            break;
          case 'templates':
            this.tooltiptext = 'Templates';
            this.scopedropdown = true;
            break;
          case 'risk-rfi':
            this.tooltiptext = 'Risk RFI';
            this.scopedropdown = true;
            break;
          case 'np':
          case 'spendandsavings':
            this.tooltiptext = 'Negotiation Playbook';
            this.scopedropdown = true;
            break;
          case 'mi':
            this.tooltiptext = 'Market Intelligence';
            this.scopedropdown = false;
            break;
          case 'competitiveness':
            this.tooltiptext = 'Competitiveness';
            this.scopedropdown = true;
            break;
          case 'reports':
            this.tooltiptext = 'Reports';
            this.scopedropdown = true;
            break;
          case 'benchmark':
          case 'componentcost':
          case 'transformationcost':
            this.tooltiptext = 'Benchmark';
            this.scopedropdown = true;
            break;
          case 'partmatching':
            this.tooltiptext = 'Part Matching';
            this.scopedropdown = true;
            break;
          case 'review-opportunities':
            this.tooltiptext = 'Review Opportunities'
            this.scopedropdown = true
            break
          case 'rawmaterial':
            this.tooltiptext = 'Raw Material';
            this.scopedropdown = true;
            break;
          case 'industrynews':
          case 'events':
          case 'home':
            this.tooltiptext = 'Industry News';
            this.scopedropdown = false;
            break;
          case 'supplier-discovery':
            this.tooltiptext = 'Supplier Network';
            this.scopedropdown = false;
            break;
          case 'pc-update':
            this.tooltiptext = 'Predictive Cost Trend';
            this.scopedropdown = true;
            break;
          case 'partnerscorecard':
            this.tooltiptext = 'Partner Score Card';
            this.scopedropdown = true;
            break;
          case 'ebenchmark-mpn':
            this.tooltiptext = 'eBenchmark';
            this.scopedropdown = true;
            break;
          case 'custompartintelligence':
            this.tooltiptext = 'Custom Part Intelligence';
            this.scopedropdown = true;
            break;
          case 'costlookup':
            this.tooltiptext = 'Cost Lookup';
            this.scopedropdown = true;
            break;
          case 'risk':
            this.tooltiptext = 'Opportunities & Risks';
            this.scopedropdown = true;
            break;
          case 'driver':
            this.tooltiptext = 'Opportunities & Risks';
            this.scopedropdown = true;
            break;
          case 'interactiveedit':
            this.tooltiptext = 'Interactive Edit';
            this.scopedropdown = true;
            break;

          case 'basicalerts':
          case 'advancedalerts':
          case 'curatorengine':
          case 'ceopprisks':
          case 'negotiationlevers':
          case 'levers':
          case 'opprisks':
          case 'loadaidata':
          case 'settings':
          case 'metadata':
          case 'dataanalysis':
          case 'newscurator':
          case 'category':
          case 'ai-events':
          case 'feed':
          case 'leva':
          case 'vendorsites':
          case 'training':
          case 'levadashboard':
          case 'product-usage':
          case 'content':
          case 'partsites':
          case 'partclassification':
          case 'entityclassification':
          case 'mpnclassification':
          case 'masterdata':
          case 'companyprofile':
          case 'entities':
          case 'lmpns':
          case 'commodities':
          case 'upload':
          case 'community-insights':
          case 'dashboards':
          case 'configuration':
          case 'content-lab-config':
          case 'content-lab':
          case 'opportunity-attainability':
          case 'baseline-savings':
            this.tooltiptext = 'Curator'
            this.scopedropdown = false
            break
          case 'referencemap':
            this.tooltiptext = 'Reference Map'
            this.scopedropdown = false
            break
          case 'insights':
            this.tooltiptext = 'Review Opportunities'
            this.scopedropdown = false
            break
          case 'partneroverview':
          case 'summary':
            this.tooltiptext = 'Negotiation Playbook'
            this.scopedropdown = true
            break
          case 'srfx':
            this.tooltiptext = 'SRFX';
            this.scopedropdown = true;
            break;
          case 'administration':
          case 'customerconfiguration':
          case 'userconfiguration':
          case 'sisenseconfiguration':
          case 'postdatarefresh':
          case 'buildcube':
          case 'sisensetenantconfiguration':
            this.tooltiptext = 'Administration';
            this.scopedropdown = false;
            break;
          case 'npi':
          case 'npipartsmaster':
          case 'npidashboard':
          case 'programprofile':
          case 'ppsummary':
          case 'ppbommanagement':
          case 'ppprogramtrends':
          case 'ppbomcompare':
          case 'ppbomanalytics':
          case 'npiadmin':
          case 'createprogram':
          case 'overallstatusexpand':
          case 'notificationsexpand':
          case 'programcostexpand':
          case 'programriskexpand':
          case 'programstatusexpand':
          case 'costexpand':
          case 'bomriskexpand':
          case 'adminprogram':
          case 'adminrisk':
          case 'adminrfq':
          case 'adminopportunities':
          case 'createbomrevision':
          case 'transformationbom':
          case 'previewbomrevision':
          case 'bomrevision':
          case 'viewbom':
          case 'viewhistory':
          case 'datahealth':
          case 'programgeneral':
          case 'programcheckpoints':
          case 'programstage':
          case 'programtemplate':
          case 'programusermanagement':
          case 'expand':
            this.tooltiptext = 'New Product Introduction';
            this.scopedropdown = false;
            this.npiApp = false
            break;
          case 'contentlabinsights':
            this.tooltiptext = 'Insights';
            this.scopedropdown = false;
            break;
          case 'loadingestiondata':
            this.tooltiptext = 'Self Data Load';
            this.scopedropdown = false;
            break;
          case 'scenarioworkbench':
            this.tooltiptext = 'Scenario Workbench';
            this.scopedropdown = true;
            break;
          case 'supplyrisk':
            this.tooltiptext = 'Legacy Supply Risk';
            this.scopedropdown = true;
            break;
          case 'mitigate-events':
            this.tooltiptext = 'Events';
            this.scopedropdown = false;
            break;
          case 'market-trends':
            this.tooltiptext = 'Market Trends';
            this.scopedropdown = false;
            break;
          case 'data-health':
            this.tooltiptext = 'Date Health';
            this.scopedropdown = false;
            break;
          case 'risk-summary':
            this.tooltiptext = 'Risk Summary'
            this.scopedropdown = true
            break
          case 'scenariocomparison':
            this.tooltiptext = 'Scenario Comparison';
            this.scopedropdown = true;
            break;
          case 'augmentedopportunities':
            this.tooltiptext = 'Savings Opportunities';
            this.scopedropdown = false;
            break;
        }
        if (this.toggleScopePanel) {
          this.toggleScopePanel = false;
        }
      } else {
        if (disabledScopeDropdownRoutes.indexOf(this.baseService.gettooltip()) !== -1) {
          this.scopedropdown = false
        } else {
          this.scopedropdown = true
        }
        this.hide = false;
      }
    });
  }

  openDialog(id: string): void {
    this.displayBlue = false;
    this.selectedDialogId = id;
    this.dialogBoxService.open(id)
  }

  buttonClick(target): void {
    // To destroy component when we close dialog
    if (target.button.id === DialogButtonName.CLOSE) {
      this.selectedDialogId = '';
    }
  }

  ngOnInit() {
    this.getBaseConfiguration();
    this.getTimePeriodSelection();
    this.checkTimePeriod();
    this.getScopeList();
    this.getFeatures();
    this.getNotificationCount();

    if (this.userSessionData !== undefined) {
      const localStorageCurrencyObject = JSON.parse(
        localStorage.getItem(CUSTOMER_CUSTOMER_DATA)
      );
      // Tenant ID
      if (localStorageCurrencyObject &&
        localStorageCurrencyObject.hasOwnProperty('configurationData')) {
        this.configuration = JSON.parse(localStorage.getItem(CUSTOMER_CUSTOMER_DATA)).configurationData;
      }
      if (
        localStorageCurrencyObject &&
        localStorageCurrencyObject.hasOwnProperty('baseCusomerInfo')
      ) {
        if (localStorageCurrencyObject.baseCusomerInfo.isLandingEnabled) {
          if (!sessionStorage.getItem('ENLP')) {
            this.onShowChatBotDashboard = true;
          }
          this.enableChatBotDashboard = true;
        }
      }
      if (JSON.parse(localStorage.getItem(CUSTOMER_CURRENT_USER)) !== null) {
        this.fullName = JSON.parse(localStorage.getItem(CUSTOMER_CURRENT_USER)).fullName;
      }
      if (this.fullName.trim() === '') {
        this.fullName = this.userSessionData.email;
      }
      this.userInital = this.fullName.charAt(0);
      this.userEmail = this.userSessionData.email;
      this.inputObj.userEmail = this.userEmail;

      this.isSuperAdmin = this.userSessionData.superAdmin;
      this.isSSOUser = this.userSessionData.ssoUser;
      // TODO : Need to uncomment it to set it dynamically
      this.tenantAdmin = this.userSessionData.tenantAdmin;
    } else {
    }

    this.getTimeStampSelectionSubscription = this.scopeDataService
      .getTimeStampSelection()
      .subscribe((data) => {
        this.selectedTime = data;
      });
  }

  generateZendeskSSOKey() {
    const sharedKey = 'nMVeVBb22lPcx7H9k9HHBUxeqkZVq58kEJ5hqnqFJCQysdT8';
    const payload = {
      iat: (new Date().getTime() / 1000),
      jti: Math.floor(100000 + Math.random() * 900000),
      name: this.userSessionData.name || '',
      email: this.userSessionData.email || ''
    };
    // encode
    const token = jwt.encode(payload, sharedKey);
    // this.zenDeskURL = 'https://levadatasupport.zendesk.com/access/jwt?jwt=' + token
    this.zenDeskURL = 'https://levadatasupport.zendesk.com/hc/en-us';
    window.open(this.zenDeskURL);
  }

  taskBtnClick() {
    let chatEnable;
    this.dataStorageService.getChatBotEnable().subscribe(message => (chatEnable = message));
    if (!chatEnable) {
      this.dataStorageService.showTaskPane = true;
      this.dataStorageService.taskActionInput = !this.dataStorageService.taskActionInput;
      this.mouseOnDropDown = false;
      this.moreOptionShow = false;
    }
  }
  onClickedOutsideTask(e: Event) {
    if (e.target !== this.taskManagement.nativeElement) {
      this.dataStorageService.showTaskPane = false;
      this.dataStorageService.taskActionInput = false;
    }
    else {
      this.taskBtnClick();
    }

  }

  getNotificationCount() {
    this.alertsCount$ = this.appHeaderService
      .getAlertsCount(this.scope)
      .subscribe((data) => {
        if (data && data.result) {
          this.alertsCount = data.result || 0;
        } else {
          this.alertsCount = 0;
        }
      });
  }

  ngOnDestroy() {
    if (this.getTimeStampSelectionSubscription) {
      this.getTimeStampSelectionSubscription.unsubscribe();
    }
    if (this.scopeDisableSubscription) {
      this.scopeDisableSubscription.unsubscribe();
    }

    if (this.getManualSelectionSubscription) {
      this.getManualSelectionSubscription.unsubscribe();
    }
    if (this.unAuthorizedSubscription) {
      this.unAuthorizedSubscription.unsubscribe();
    }
    if (this.dataLoadSubscription) {
      this.dataLoadSubscription.unsubscribe();
    }
    this.botSubscription.unsubscribe();
    this.dialogBoxService.remove(this.aboutLevadata.id)
  }

  checkTimePeriod() {
    if (
      window.location.href.search('/spendintelligence') !== -1 ||
      window.location.href.search('/opportunities') !== -1 ||
      window.location.href.search('/review-opportunities') !== -1
    ) {
      this.showTimePeriod = true;
    } else if (
      window.location.href.search('/savingsopportunities') !== -1 &&
      !window.location.href.includes('np')
    ) {
      this.showTimePeriod = false;
      this.showOpportunityTime = true;
      this.yearList = this.customerConfigurationService.getyearList();
    }
  }
  onClose() {
    this.viewDetails = false;
  }
  getFeatures() {
    if (
      this.customerConfigurationService.getConfData(this.scope) &&
      this.customerConfigurationService.getConfData(this.scope).mainTabFeatures
    ) {
      this.menuMap = this.customerConfigurationService.getConfData(
        this.scope
      ).mainTabFeatures;
      this.leftNavbarFeatures = this.customerConfigurationService.getConfData(
        this.scope
      ).leftNavbarFeatures;
      this.featuresArray = this.leftNavbarFeatures.map((i) => i.view);
    }
    let rfxFeatures: any;
    if (this.menuMap instanceof Array) {
      rfxFeatures = this.menuMap.find((item) => item['rfq'] instanceof Object);
      if (rfxFeatures instanceof Object && rfxFeatures.hasOwnProperty('rfq')) {
        rfxFeatures = rfxFeatures['rfq'];
      }
    }
    if (rfxFeatures instanceof Array && rfxFeatures.length > 0) {
      rfxFeatures = rfxFeatures.map((item) => item.title);
      if (rfxFeatures.includes('Manage Vendors')) {
        this.rfxManageSupplier = true;
      } else {
        this.rfxManageSupplier = false;
      }
    }
    if (
      this.userSessionData.tenantAdmin ||
      (this.userSessionData.superAdmin && this.featuresArray.includes('srfx'))
    ) {
      this.rfxOptions = true;
      this.text = 'border-left-2';
    } else {
      this.rfxOptions = false;
      this.text = '';
    }
  }

  getBaseConfiguration() {
    this.envDetails = this.customerConfigurationService.getEnvironmentDetails();
    this.userSessionData = this.baseService.getUserInformation();
    this.customerData = this.customerConfigurationService.getConfData(
      this.scope
    );

    this.custLogoInfo = this.customerConfigurationService.getBaseCustomerInfo();
    if (this.custLogoInfo) {
      this.custLogo = this.custLogoInfo.customerLogoPath;
      this.custMessage = this.custLogoInfo.isMessagesEnabled;
      this.custNotifications = this.custLogoInfo.isNotificationsEnables;
    }

    this.showOpportunityTime = false;
    if (this.custMessage) {
      this.showMessage = true;
    }

    if (this.custNotifications) {
      this.showNotifications = true;
    }
  }

  getScopeList() {
    this.scopeList = this.customerConfigurationService.getScopeList();
    if (this.scopeList instanceof Array) {
      if (this.scopeList.length === 1) {
        this.isList = false;
      } else {
        this.isList = true;
      }
    }
    if (this.scopeList instanceof Array && this.scopeList.length > 0) {
      const defaultScope = this.scopeList.filter(
        (item) => item.defaultValue === true
      );
      if (JSON.parse(localStorage.getItem(CUSTOMER_FOCUS)) !== null) {
        this.scope = JSON.parse(localStorage.getItem(CUSTOMER_FOCUS));
        this.scopeDataService.setFocus(this.scope);
      } else {
        this.onSelected(defaultScope[0].title);
      }
    }
  }
  onTimeStampSelection(timestamp: any) {
    this.selectedTime = timestamp.title;
    this.scopeDataService.setTsSelection(timestamp['view']);
    this.scopeDataService.setTimeStampSelection(timestamp['view']);
  }

  onFocusChange(focus: string) {
    this.scope = focus;
    this.scopeDataService.setFocus(this.scope);
    this.scopeDataService.settype('manual');
  }
  mouseEnter($event) {
    $event.stopPropagation();

    // this.HideTimeFrame();
    this.toggleScopePanel = true;
    // this.toggleScopePanel === false ? true : false;
    try {
      document.querySelector('.scopehover').classList.add('active');
      document
        .getElementById('app-scope-change-dropdown')
        .classList.remove('open');
      if (document.getElementById('rest-of-year-change-dropdown') !== null) {
        document
          .getElementById('rest-of-year-change-dropdown')
          .classList.remove('open');
        document
          .getElementById('rest-of-year')
          .setAttribute('aria-expanded', 'false');
      }

      document.getElementById('scope').setAttribute('aria-expanded', 'false');
    } catch (e) {
      console.log(e);
    }
  }
  onCurator() {
    this.router.navigate(['/app/curatorengine/loadaidata']);
  }
  onClosePopup() {
    this.openPopup = false;
    this.collapse = true;
    this.animation = true;
    setTimeout(() => {
      this.animation = false;
    }, 300);
  }
  onloadDataLloadStatus() {
    this.collapse = false;
    this.animation = false;
    this.dataLoadSubscription = this.appHeaderService
      .getDataLoadStatus()
      .subscribe((val) => {
        if (val && val.responseStatus && val.responseStatus.code === 200) {
          if (val.result instanceof Object) {
            if (
              val.result.status === 'IN VALIDATION' ||
              val.result.status === 'INGESTION_FAILED' ||
              val.result.status === 'INGESTION-TRIGGERED'
            ) {
              this.endTime = val.result.endTime
              if (!this.dataLoadProgressIcon) {
                if (!this.openPopup) {
                  this.dataLoadPopUpConfig.title = 'Dataload in Progress'
                  this.dialogBoxService.open(this.dataLoadPopUpConfig.id);
                  this.openPopup = true;
                }
                this.dataLoadProgressIcon = true;
                this.dataLoadCompletedIcon = false;
                this.endTime = this.getUserSpecificTimeZoneTime(val.result.endTime);
                this.countdownTimer();
              }
            } else if (val.result.status === 'INGESTION_COMPLETED') {
              if (!this.dataLoadCompletedIcon) {
                this.endTime = this.getUserSpecificTimeZoneTime(val.result.endTime)
                this.dataLoadPopUpConfig.title = 'Dataload is successful'
                this.dataLoadCompletedIcon = true;
                this.dataLoadProgressIcon = false;
              }
            }
          }
        } else {
          this.dataLoadProgressIcon = false;
          this.dataLoadCompletedIcon = false;
        }
      });
  }
  getUserSpecificTimeZoneTime(epochTime) {
    const userTimeZone = moment.tz.guess();
    const timeZoneOffset = new Date(epochTime).getTimezoneOffset()
    this.timezoneAbbr = moment.tz.zone(userTimeZone).abbr(timeZoneOffset);
    return moment(epochTime).tz(userTimeZone).format('MMM DD, YYYY HH:mm:ss')
  }

  checkDataLaodStatus() {
    this.collapse = false;
    this.animation = false;
    this.openPopup = false;
    this.dataLoadSubscription = this.appHeaderService
      .getDataLoadStatus()
      .subscribe((val) => {
        if (val && val.responseStatus && val.responseStatus.code === 200) {
          if (val.result instanceof Object) {
            if (val.result.status === 'IN VALIDATION' || val.result.status === 'INGESTION_FAILED') {
              this.endTime = val.result.endTime;
              this.countdownTimer();
              this.dataLoadProgressIcon = true;
              this.dataLoadCompletedIcon = false;
              this.dataLoadPopUpConfig.title = 'Dataload in Progress'
            } else if (val.result.status === 'INGESTION_COMPLETED') {
              this.endTime = this.getUserSpecificTimeZoneTime(val.result.endTime)
              this.dataLoadPopUpConfig.title = 'Dataload is successful'
              this.dataLoadCompletedIcon = true;
              this.dataLoadProgressIcon = false;
            }
            if (!this.openPopup) {
              this.dialogBoxService.open(this.dataLoadPopUpConfig.id);
              this.openPopup = true;
            }
          }
        } else {
          this.dataLoadProgressIcon = false;
          this.dataLoadCompletedIcon = false;
        }
      })
  }
  countdownTimer() {
    const formatedTime = DateUtils.format(this.endTime, undefined, true);
    const newEndTime = new Date(formatedTime);
    const locTime = DateUtils.format(
      DateUtils.getDate(null, true),
      undefined,
      true
    );
    const localtime = new Date(locTime);
    this.diffhrs = Math.abs(newEndTime.getHours() - localtime.getHours());
    this.diffmins = newEndTime.getMinutes() - localtime.getMinutes();
    if (Math.sign(this.diffmins) === -1) {
      this.diffmins = Math.abs(60 + this.diffmins);
      this.diffhrs = Math.abs(this.diffhrs - 1);
    }

    setInterval(
      function () {
        if (this.diffmins === 1) {
          this.diffmins = 0;
        } else if (this.diffmins === 0) {
          this.diffmins = 59;
          this.diffhrs = Math.abs(this.diffhrs - 1);
        } else {
          this.diffmins = Math.abs(this.diffmins - 1);
        }
        if (Math.sign(this.diffmins) === -1) {
          this.diffmins = Math.abs(60 + this.diffmins);
        }
      }.bind(this),
      60000
    );
  }
  navigateToAdministration() {
    let nav = '';
    if (this.configuration && this.configuration.commodityConfigurationData
      && this.configuration.commodityConfigurationData.mainTabFeatures) {
      this.configuration.commodityConfigurationData.mainTabFeatures.forEach((key) => {
        Object.keys(key).forEach((val) => {
          if (val && val === 'administration') {
            nav = key[val][0].view;
            return false;
          }
        });

      }
      );
      this.router.navigate(['/app/administration/' + nav]);
    }

  }
  // onCustomerConfig() {
  //   this.router.navigate(["/app/customerconfiguration/configure"]);
  // }
  mouseLeave($event) {
    $event.stopPropagation();
    this.toggleScopePanel = false;
    this.timeSelectionDataService.hideTimeSelection(true);
    document.querySelector('.scopehover').classList.remove('active');
  }

  // onIBMCognos() {
  //   const ibmCognosUrl = 'https://cognosbp.bi.ibmcloud.com/bi'
  //   this.windowService.nativeWindow.open(ibmCognosUrl, '_blank')
  // }

  toggleScopeButton($event) {
    if (!this.showscope) {
      $event.stopPropagation();
      // console.log(this.toggleScopePanel);
      this.showTime = false;
      if (document.getElementById('time-selection-header') !== null) {
        document
          .getElementById('time-selection-header')
          .classList.remove('active');
      }
      this.toggleScopePanel = !this.toggleScopePanel ? true : false;
      this.mouseEnter($event);
      // this.scopeDataService.setScopeStatus(this.toggleScopePanel);
    }
  }

  onHelpCenter() {
    // this.router.navigate(['/help'])
    window.open('#/help');
  }

  onScopeChanage() {
    // this.HideTimeFrame();
    this.toggleScopePanel = false;
    this.showTime = false;
    if (document.getElementById('time-selection-header') !== null) {
      document
        .getElementById('time-selection-header')
        .classList.remove('active');
    }
  }

  getTimePeriodSelection() {
    try {
      let localTimeObject: any;
      this.appHeaderService.getTimePeriodData().subscribe(
        (timeObject) => {
          if (timeObject instanceof Object) {
            if (timeObject.responseStatus instanceof Object) {
              if (
                timeObject.responseStatus.code &&
                timeObject.responseStatus.code === 200
              ) {
                if (timeObject.result instanceof Array) {
                  if (timeObject.result.length > 0) {
                    localTimeObject = timeObject.result[0];
                    this.timePeriod = localTimeObject;
                    this.timeSelectionDataService.setFullTimePeriodObject(
                      this.timePeriod
                    );
                    this.dataStorageService.timePeriodService.emit(true);
                  } else {
                    this.dataStorageService.setFailedTsDataStatus(true);
                  }
                } else {
                  this.dataStorageService.setFailedTsDataStatus(true);
                }
              } else {
                this.dataStorageService.setFailedTsDataStatus(true);
              }
            } else {
              this.dataStorageService.setFailedTsDataStatus(true);
            }
          } else {
            this.dataStorageService.setFailedTsDataStatus(true);
          }
        },
        (error) => {
          this.dataStorageService.setFailedTsDataStatus(true)
        },
        () => {
          // For Forecast
          if (localTimeObject instanceof Object) {
            this.subscriptionService.setCurrentQuarter(
              localTimeObject['currentQuarter']
            );
            this.subscriptionService.setQuarters({
              previousquarter: localTimeObject['lastQuarter'],
              currentquarter: localTimeObject['currentQuarter'],
              nextquarter: localTimeObject['nextQuarter'],
            });
          } else {
            console.log('Time is not loaded....');
          }
        }
      );
    } catch (e) {
      console.log('Exception in getTimePeriodData subscribe', e);
    }
  }

  onSelected(val) {
    this.scope = val;
    this.baseService.setCurrentApplicationScope(val);
    this.scopeDataService.setFocus(val);
    this.getNotificationCount();
  }
  navigateToGlobal() {
    this.router.navigate(['app/srfx/global']);
  }
  navigateToSupplierManagment() {
    this.router.navigate(['app/srfx/managevendors']);
  }

  manageusers() {
    this.router.navigate(['app/srfx/manageusers']);
  }

  // sisenseLogout() {
  //   this.instance.setElasticCubeSource(undefined);
  //   this.apiInteractor.getFromSisenseApi(this.sisenseSrc + "/api/v1/authentication/logout").then((res1) => {
  //     //this.authenticationService.logout();
  //     // this.baseService.setUserInformation('');
  //     // this.router.navigate(['/login']);
  //   });
  // }

  onLogout() {
    sessionStorage.removeItem('ENLP');
    const logOutList = this.dataStorageService.canLogOutList;
    if (logOutList instanceof Array && logOutList.length > 0) {
      const bool = logOutList[0]();
      if (bool) {
        logOutList[0](true).subscribe((val) => {
          if (val) {
            this.logOutExcution();
          }
        });
      } else {
        this.logOutExcution();
      }
    } else {
      this.logOutExcution();
    }
  }

  logOutExcution() {
    this.authenticationService.logOutExecution();
    // localStorage.clear();
    // // localStorage.removeItem('columnConfigurations');
    // // window.localStorage.removeItem("userLogin")
    // this.sisenseLogout();
    // this.scopeDataService.setDefaultDimensionDataObject();
    // this.scopeDataService.clearDefaultData();
    // //this.authenticationService.logout();

    // this.baseService.setUserInformation('');
    // this.baseService.setLogout(false);
    // this.router.navigate(['/login']);
    // this.refresh();
  }
  loadTimeFrame($event) {
    if (!this.showscope) {
      $event.stopPropagation();
      // console.log(this.showTime)
      this.showTime = true;
      document.getElementById('time-selection-header').classList.add('active');
      try {
        this.toggleScopePanel = false;
        document.querySelector('.scopehover').classList.remove('active');
        document
          .getElementById('app-scope-change-dropdown')
          .classList.remove('open');
        document.getElementById('scope').setAttribute('aria-expanded', 'false');
      } catch (e) {
        console.log(e);
      }
    }
  }
  HideTimeFrame($event) {
    $event.stopPropagation();
    // console.log(this.showTime)
    this.showTime = false;
    if (document.getElementById('time-selection-header') !== null) {
      document
        .getElementById('time-selection-header')
        .classList.remove('active');
    }
  }

  onClickedOutside(event) {
    this.toggleScopePanel = false;
    this.timeSelectionDataService.hideTimeSelection(true);;
    if (document.querySelector('.scopehover')) {
      document.querySelector('.scopehover').classList.remove('active');
    }
  }

  showTemplates() {
    this.router.navigate(['app/templates']);
  }
  ShowMessagePanel() {
    if (this.unreadCount > 0) {
      this.toggleMsgPanel = this.toggleMsgPanel ? false : true;
    }
  }
  onClickedOutsidemsg(eve) {
    if (this.toggleMsgPanel) {
      this.toggleMsgPanel = false;
    }
  }

  navigateToRfxOptions() {
    this.router.navigate(['app/srfx/product/rfxOptions']);
  }

  showChatBotDashboard() {
    this.onShowChatBotDashboard = true;
    this.baseService.setCurrentLandingPageURL(this.router.url);
    this.router.navigate(['defaultlandingpage']);
  }
  onFavoriteClick($event) {
    this.onShowChatBotDashboard = false;
  }
  toggleIcon() {
    this.displayBlue = true;
  }
  toggleIconOut() {
    this.displayBlue = false;
  }

  dropdownHover() {
    let chatEnable;
    this.dataStorageService.getChatBotEnable().subscribe(message => (chatEnable = message));
    if (!chatEnable && !this.dataStorageService.taskActionInput) {
      this.moreOptionShow = false;
      this.mouseOnDropDown = true;
    }

  }
  dropdownHoverLeave() {
    this.mouseOnDropDown = false;
  }
  dropdownToggle() {
    this.moreOptionShow = false;
    this.dataStorageService.showTaskPane = false;
    this.dataStorageService.taskActionInput = false;
    this.mouseOnDropDown = !this.mouseOnDropDown;
  }
  moreOptionShowHover() {
    let chatEnable;
    this.dataStorageService.getChatBotEnable().subscribe(message => (chatEnable = message));
    if (!chatEnable && !this.dataStorageService.taskActionInput) {
      this.mouseOnDropDown = false;
      this.moreOptionShow = true;
    }

  }
  moreOptionShowHoverLeave() {
    this.moreOptionShow = false;
  }

  moreOptiontoggle() {
    this.mouseOnDropDown = false;
    this.dataStorageService.showTaskPane = false;
    this.dataStorageService.taskActionInput = false;
    this.moreOptionShow = !this.moreOptionShow;
  }


}
