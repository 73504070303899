import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  EventEmitter,
  Output
} from '@angular/core';
import { MomentModule } from 'angular2-moment';
import { Router } from '@angular/router';
import { NotificationService } from './shared/notifications.service';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { ApiServiceProvider } from 'app/api-service';
import { DateUtils } from 'app/modules/common/utills/DateUtils.service';
import { ElementRef, ViewChild } from '@angular/core';
import { ApiCommonService } from 'app/api-common-service';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.sass'],
  providers: [NotificationService, ApiCommonService]
})
export class NotificationsComponent implements OnInit, OnChanges {
  @Output() notificationCount = new EventEmitter<any>();
  notificationsFilteredList: any = [];
  notificationsOriginalList: any = [];
  notificationsUnreadList: any = [];
  notificationsShowAllList: any = [];
  notificationSubscr: any;
  appScope: string;
  scopeSubscription: any;
  userFilter: any = { message: '' };
  loading = true;

  showUpdateForecastPopup = false;
  clickedAlert: any;
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;

  viewPortItems;
  @ViewChild('notificationDiv', { static: false }) notificationDiv: ElementRef;

  private _notificationType: string;
  @Input()
  set notificationType(notificationType: string) {
    this._notificationType = notificationType;
  }

  get notificationType() {
    return this._notificationType;
  }

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private dataStorageService: DataStorageService,
    private scopeDataService: ScopeDataService,
    private apiService: ApiServiceProvider,
    private apiCommonService: ApiCommonService
  ) {}

  ngOnInit() {
    this.scopeSubscription = this.scopeDataService.getFocus().subscribe(
      value => {
        this.appScope = value;
        this.subscribe();
      },
      error => {
        console.log('error while getting focus', error);
      }
    );
    this.appScope = this.scopeDataService.getFocusValue();
    this.subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    const type: SimpleChange = changes.notificationType;
    this.userFilter.message = '';
    this.notificationType = type.currentValue;
    this.notificationsFilteredList = this.getFilteredList();
    this.processMessageInNotifications();
  }

  getFilteredList() {
    let list;
    if (this.notificationType === 'SHOW UNREAD') {
      list = this.notificationsShowAllList;
    } else {
      list = this.notificationsUnreadList;
    }
    return list;
  }

  subscribe() {
    this.notificationSubscr = this.notificationService
      .getAlertsListData(this.appScope)
      .subscribe(
        data => {
          this.notificationsOriginalList = [];
          if (
            data &&
            data instanceof Object &&
            data['responseStatus'] instanceof Object &&
            data['responseStatus']['code'] !== undefined &&
            data['responseStatus']['code'] === 200
          ) {
            // data.result=[];
            if (data.result instanceof Array && data.result.length > 0) {
              data.result.forEach(item => {
                if (item !== undefined && !item.cleaned) {
                  this.notificationsOriginalList.push(item);
                }
                if (item !== undefined && !item.cleaned && !item.acknowledged) {
                  this.notificationsUnreadList.push(item);
                }
              });
              this.notificationsFilteredList = this.notificationsOriginalList;
              this.notificationsShowAllList = this.notificationsOriginalList;
              this.processMessageInNotifications();
              this.loading = false;
            } else {
              this.notificationsOriginalList = [];
              this.loading = false;
            }

            this.notificationCount.emit(this.notificationsOriginalList);
          } else {
            this.notificationsOriginalList = [];
            this.loading = false;
          }
        },
        error => {
          this.notificationsOriginalList = [];
          this.loading = false;
          console.log('Error while getting notification alerts.');
        }
      );
  }

  processMessageInNotifications() {
    if (
      this.notificationsFilteredList instanceof Array &&
      this.notificationsFilteredList.length > 0
    ) {
      this.notificationsFilteredList.forEach(i => {
        const onIndex = i.message
          .toString()
          .toLowerCase()
          .lastIndexOf(' on ');
        if (onIndex > -1) {
          const date = i.message.toString().substr(onIndex + 3, 13);
          if (!date.includes('/')) {
            const formattedDate = DateUtils.format(
              DateUtils.getDate(+date),
              true
            );
            i.message = i.message.toString().replace(date, formattedDate);
          }
        }
      });
    }
  }

  onAlertsClick(event, item) {
    event.stopPropagation();
    if (item && item.alertId) {
      this.notificationService.viewNotification(item.alertId).subscribe(res => {
        this.subscribe();
        this.scopeDataService.onNotificationAlertClick(true);
        this.navigateToRfq(item);
      });
    }
  }

  cleanAlert(event, item) {
    event.stopPropagation();
    if (item && item.alertId) {
      this.notificationService.clearNotification(item.alertId).subscribe(res => {
        this.subscribe();
        this.scopeDataService.onNotificationAlertClick(true);
      });
    }
  }

  updateAlert(event, item) {
    event.stopPropagation();
    console.log(item);
    this.showUpdateForecastPopup = true;
    this.clickedAlert = item;
  }

  onClickUpdateOk() {
    this.showUpdateForecastPopup = false;
    this.loading = true;
    this.notificationsOriginalList = [];

    this.notificationService
      .callUpdateAlert(this.clickedAlert)
      .subscribe(updateResult => {
        if (
          this.notificationService.validateServiceData(updateResult) !== 'error'
        ) {
          this.subscribe();
        } else {
          console.log(updateResult['message']);
        }
      });
  }

  onClickUpdateCancel() {
    this.showUpdateForecastPopup = false;
  }

  filterNotifications() {
    let filterAlerts = [];
    const list = this.getFilteredList();
    if (list === undefined || list.length === 0) { return; }
    if (this.userFilter.message === '') {
      filterAlerts = list;
    } else {
      list.forEach(item => {
        if (
          this.compareStrings(item.targetName) ||
          this.compareStrings(item.message)
        ) {
          filterAlerts.push(item);
        }
      });
    }
    this.notificationsFilteredList = filterAlerts;
  }

  compareStrings(string) {
    return (
      string.toLowerCase().indexOf(this.userFilter.message.toLowerCase()) > -1
    );
  }

  getNotificationType(item) {
    if (item.subTypeName.toUpperCase() === 'RESPONDED') { return 'sent'; }
    else if (item.subTypeName.toUpperCase() === 'CREATED') { return 'draft'; }
    else if (item.subTypeName.toUpperCase() === 'AWARDED') { return 'awarded'; }
    else if (item.subTypeName.toUpperCase() === 'NO BID') { return 'nobid'; }
    else if (item.subTypeName.toUpperCase() === 'CLOSED') { return 'closed'; }
    else if (item.subTypeName.toUpperCase() === 'REVOKED') { return 'revoked'; }
    else if (item.subTypeName.toUpperCase() === 'DELETE_RFQ') { return 'closed'; }
    else { return 'nobid'; }
  }

  navigateToRfq(item) {
    if (this.scopeDataService.currentFocus.toLocaleLowerCase() === 'commodity') {
      const payload = {
        method: 'GET',
        servicePath: 'rfq/' + item.targetId + '/basicData',
        targetedType: 'RFQ'
      };
      this.apiService.post(payload).subscribe(res => {
        if (this.apiCommonService.validateServiceCallData(res) !== 'error') {
          this.applyRouting(item, res.result);
        }
      });
    } else {
      this.applyProductRouting(item);
    }
  }

  applyRouting(item, result) {
    switch (item.typeName) {
      case 'RFQ':
        this.dataStorageService.setLinkValue(result.rfqCustName);
        if (result.hasOwnProperty('statusName')) {
          if (result['statusName'].toLowerCase().includes('progress')) {
            if (result['statusName'].toLowerCase().includes('negotiation')) {
              result['rfqStage'] = 'Negotiation In-Progress'
            }
            if (result['statusName'].toLowerCase().includes('award')) {
              result['rfqStage'] = 'Award In-Progress'
         }
          } else {
            result['statusName'] = result['statusName'].toLowerCase()
         result['rfqStage'] = result['statusName'].charAt(0).toUpperCase() + result['statusName'].slice(1);
       }
        }
        this.dataStorageService.setClikedRFQforDetails(result);
        this.dataStorageService.setSelectedRFQId(item.targetId);
        this.dataStorageService.setSeletedRFQName(item.targetName);
        switch (item.subTypeName) {
          case 'DELETE_RFQ':
            break;
          case 'SUBMISSIONDATE':
          case 'CREATED':
          case 'DUEDATE':
            this.router.navigate(['/app/srfx/create/']);
            break;
          case 'RESPONDED':
          case 'AWARDED':
          case 'RECEIVED':
          default:
            this.router.navigate(['/app/srfx/r/details']);
            break;
        }
        break;
      case 'Message':
        break;
      default:
        break;
    }
  }

  getAlertsHeight() {
    let height = '270px';
    if (
      this.notificationDiv &&
      this.notificationDiv.nativeElement &&
      this.notificationDiv.nativeElement.offsetHeight !== null
    ) {
      height = `${this.notificationDiv.nativeElement.offsetHeight}px`;
    }
    if (this.notificationsFilteredList.length === 0) { height = '0px'; }
    return height;
  }
  routetoDetails(item) {
    const payload = {
      method: 'GET',
      servicePath: 'rfx/productrfq/' + item.targetId,
      targetedType: 'productRFQ'
    };
    this.apiService.post(payload).subscribe(res => {
      if (this.apiCommonService.validateServiceCallData(res) !== 'error') {
        if (res.result.info === undefined) {
          res.result['info'] = {
            id: res.result.id,
            templateId: res.result.templateId
          };
        }
        this.dataStorageService.setClikedRFQforDetails(res.result);
        this.router.navigate(['/app/srfx/product/pr/details']);
      }
    });
  }

  routetoCreate(item) {
    const payload = {
      method: 'GET',
      servicePath: 'rfx/productrfq/' + item.targetId + '/complete',
      targetedType: 'productRFQ'
    };
    this.apiService.post(payload).subscribe(res => {
      if (this.apiCommonService.validateServiceCallData(res) !== 'error') {
        this.dataStorageService.setProductEditRfqObject(res.result);
        this.router.navigate(['app/srfx/product/create']);
      }
    });
  }
  applyProductRouting(item) {
    switch (item.typeName) {
      case 'RFQ':
        switch (item.subTypeName) {
          case 'DELETE_RFQ':
            break;
          case 'SUBMISSIONDATE':
          case 'CREATED':
          case 'DUEDATE':
            this.routetoCreate(item);
            break;
          case 'RESPONDED':
          case 'AWARDED':
          case 'RECEIVED':
          case 'REVOKED':
          default:
            this.routetoDetails(item);
            break;
        }
        break;
      case 'Message':
        break;
      default:
        break;
    }
  }
}
