import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

import { Curatornegotiationlevers } from './curatornegotiationlevers.model';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CuratornegotiationleversService {
  constructor(private http: HttpClient) {}
  negArray = [];
  negObject: any = {};
  public emptyValue = new BehaviorSubject<any>('');
  setEmptyValue(val) {
    this.emptyValue.next(val);
  }
  getEmptyValue() {
    return this.emptyValue.asObservable();
  }
}
