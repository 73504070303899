export class JobModel {

        public tenantId:string;
        public jobType:string;
        public userPk:string;
        public info:string;
        public startDt:any;

        constructor(tenantId:string, jobType:string, userPk:string, info:string,
          startDt:any) {
          this.tenantId = tenantId;
          this.jobType = jobType;
          this.userPk = userPk;
          this.info = info;
          this.startDt = startDt;
        }



    }
