import {
  Component,
  OnInit,
  ViewChild,
  Input,
  EventEmitter,
  Output,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { GridOptions, GridApi } from '@ag-grid-community/core';
import { CustomerConfigurationService } from '../../../../common/customerConfigurationService';

@Component({
  selector: 'bom-tree-filter',
  templateUrl: 'bom-tree-filters.component.html',
  styleUrls: ['./bom-tree-filters.component.sass'],
  providers: [],
})
export class BomTreeFilter implements OnInit {
  public selectedFilters: any = [];
  public filters: any[];
  filterConfiguration = {};
  public gridApi: GridApi;
  public addSelectClass = false;
  getFilterList: any[];
  secondCpnFilter: any[];
  contextFilterList: any[];
  cpnFilter: any[];
  @Input() set filtersInput(val) {
    if (val instanceof Object) {
      this.filterConfiguration = val;
      this.filters = val.filters;
    }
  }
  @Input() set filterFromTask(val) {
    if (val) {
      this.getFilterList = val;
    }
  }
  @Output() totalFilter = new EventEmitter<any>();
  @Output() filtersSelected = new EventEmitter<any>();
  @Input() public gridOptions: GridOptions;
  @Input() public set clearFilters(val) {
    if (
      this.filterConfiguration instanceof Object &&
      Object.getOwnPropertyNames(this.filterConfiguration).length
    ) {
      if (this.filterConfiguration['emit']) {
        if (val) {
          this.filters.forEach((i) => {
            if (i.selected) {
              i.selected = false;
              this.addActiveClass(i);
            }
          });
          this.filtersSelected.emit([]);
        }
      }
    } else {
      if (this.filters instanceof Array) {
        this.filters.forEach((i) => {
          if (i.selected) {
            i.selected = false;
            this.addActiveClass(i);
          }
        });
        this.gridApi.setFilterModel(null);
      }
    }
  }
  public cpnprefix: any;
  public mpnprefix: any;
  public componentTypeFilter = {
    cpn: false,
    mpn: false,
  };
  public cpnMpnFilter: any = [];
  public lowerCpnHigherCpnCostFilter: any = [];
  public costRollUpFlagFilter: any = [];
  public mpnconf: any;
  public cpnconf: any;
  public totalPaddingRequired = 0;
  // container childrens
  @Input() set api(val) {
    this.gridApi = val;
  }
  public gridRows;
  @ViewChild('staticParents', { read: ElementRef, static: true })
  public staticParent: any;
  public filterData = [];

  constructor(
    private customerConfigurationService: CustomerConfigurationService
  ) {}
  public ngOnInit() {
    if (
      !(
        this.filterConfiguration instanceof Object &&
        Object.getOwnPropertyNames(this.filterConfiguration).length
      )
    ) {
      this.cpnprefix =
        this.customerConfigurationService.getBaseCustomerInfo().npipartprefix ||
        'LEVA-PART';
      this.mpnprefix =
        this.customerConfigurationService.getBaseCustomerInfo().npimpnprefix ||
        'LEVA-MPN';
      if (this.customerConfigurationService.getConfData('Commodity')) {
        this.mpnconf =
          this.customerConfigurationService.getConfData(
            'Commodity'
          ).dimensionData['MPN'];
        this.cpnconf =
          this.customerConfigurationService.getConfData(
            'Commodity'
          ).dimensionData['CPN'];
      } else {
        this.mpnconf =
          this.customerConfigurationService.getConfData(
            'Product'
          ).dimensionData['MPN'];
        this.cpnconf =
          this.customerConfigurationService.getConfData(
            'Commodity'
          ).dimensionData['CPN'];
      }
      this.contextFilterList = [
        {
          key: 'lowerCpnCost',
          displayName: 'CM WAC > CPN Cost',
          displayOrder: 1,
          field: 'levadataCost',
          filterValue: [],
          addClass: 'normal',
          selected: false,
          componentType: 'ALL',
        },
        {
          key: 'higherCpnCost',
          displayName: 'CM WAC < CPN Cost',
          displayOrder: 2,
          field: 'levadataCost',
          filterValue: [],
          addClass: 'normal',
          selected: false,
          componentType: 'ALL',
        },
        {
          key: 'missingLdCpnCost',
          displayName: 'Missing CPN Cost',
          displayOrder: 3,
          field: 'levadataCost',
          filterValue: [],
          addClass: 'normal',
          selected: false,
          componentType: 'ALL',
        },

        {
          key: 'cpnsWithoutCost',
          displayName: 'Missing BOM CPN Cost',
          displayOrder: 4,
          field: 'cost',
          filterValue: [],
          addClass: 'normal',
          selected: false,
          componentType: 'ALL',
        },

        {
          key: 'mpnsWithoutCost',
          displayName: 'Missing MPN Cost',
          displayOrder: 5,
          field: 'cost',
          filterValue: [],
          addClass: 'normal',
          selected: false,
          componentType: 'ALL',
        },

        {
          key: 'mpnsWithoutPartNo',
          displayName: `Components Without ${this.mpnconf}`,
          displayOrder: 6,
          field: 'mpn',
          filterValue: [],
          addClass: 'normal',
          selected: false,
          componentType: 'ALL',
        },

        {
          key: 'cpnsWithoutPartNo',
          displayName: `Components Without ${this.cpnconf}`,
          displayOrder: 7,
          field: 'partNumber',
          filterValue: [],
          addClass: 'normal',
          selected: false,
          componentType: 'ALL',
        },

        {
          key: 'partsWithCrfN',
          displayName: 'Parts With Cost Rollup Flag N',
          displayOrder: 8,
          field: 'costRollupFlag',
          filterValue: [],
          addClass: 'normal',
          selected: false,
          componentType: 'ALL',
        },

        {
          key: 'partsWithNoMakeOrBuy',
          displayName: 'Parts Without Make or Buy',
          displayOrder: 9,
          field: 'makeOrBuy',
          filterValue: null,
          selected: false,
          addClass: 'normal',
          componentType: 'ALL',
        },

        {
          key: 'partsWithMake',
          displayName: 'Parts With Make',
          displayOrder: 10,
          field: 'makeOrBuy',
          filterValue: [],
          selected: false,
          addClass: 'normal',
          componentType: 'ALL',
        },
        {
          key: 'partsWithBuy',
          displayName: 'Parts With Buy',
          displayOrder: 11,
          field: 'makeOrBuy',
          filterValue: [],
          addClass: 'normal',
          selected: false,
          componentType: 'ALL',
        },
        {
          key: 'partsWithCrfY',
          displayName: 'Parts With Cost Rollup Flag Y',
          displayOrder: 12,
          field: 'costRollupFlag',
          filterValue: [],
          selected: false,
          addClass: 'normal',
          componentType: 'ALL',
        },
      ];
      this.contextFilterList.map((i) => (i.addClass = ''));
      this.totalFilter.emit(this.contextFilterList);
      this.contextFilterList.sort((a, b) => {
        return a.displayOrder - b.displayOrder;
      });
      this.cpnFilter = this.contextFilterList.filter((i) =>
        this.gridOptions.columnDefs.map((col) => col['field']).includes(i.field)
      );
      if (this.getFilterList && this.getFilterList.length > 0) {
        const contextFilter = [];
        this.getFilterList.forEach((taskFilter) => {
          contextFilter.push(
            this.cpnFilter.filter(
              (filter) => filter.displayName === taskFilter.displayName
            )[0]
          );
        });
        contextFilter.map((i) => {
          i.selected = true;
          i.addClass = 'normal';
        });
      }
      this.gridRows = this.gridOptions.rowData;
    }
    setTimeout(() => {
      this.setScrollableDivWidth();
    });
  }
  public getSelctedFilterRows(selectedCpns, filter) {
    let result;
    this.filterData = [];
    if (selectedCpns.length > 0) {
      if (filter && filter.key === 'mpnsWithoutPartNo') {
        selectedCpns = this.cpnsWhichHaveMpns(selectedCpns);
        if (selectedCpns && selectedCpns === undefined) {
          selectedCpns = [];
        }
      }
      selectedCpns.forEach((element) => {
        this.parentNode(element.nodePath);
      });
      result = this.gridOptions.rowData.filter((row) => {
        if (this.filterData.indexOf(row.nodePath.toString()) !== -1) {
          return row;
        } else if (
          row.componentType === 'MPN' &&
          this.filterData.indexOf(
            row.nodePath.slice(0, row.nodePath.length - 1).toString()
          ) !== -1 &&
          filter &&
          filter.key !== 'mpnsWithoutCost'
        ) {
          return row;
        }
      });
    } else {
      result = [];
    }
    this.gridApi.setRowData(result);
    this.gridApi.redrawRows();
  }

  public parentNode(rowNode) {
    rowNode.forEach((element, index) => {
      const row = rowNode.slice(0, index + 1);
      if (this.filterData.indexOf(row.toString()) === -1) {
        this.filterData.push(row.toString());
      }
    });
  }

  public cpnsWhichHaveMpns(selectedMpns) {
    selectedMpns = selectedMpns.map((row) => row.nodePath.toString());
    const mpns = this.gridOptions.rowData.filter((row) => {
      if (
        row.componentType === 'MPN' &&
        selectedMpns.indexOf(row.nodePath.toString()) === -1
      ) {
        return row;
      }
    });
    const cpnsWhichHaveMpns = mpns.map((row) =>
      row.nodePath.slice(0, row.nodePath.length - 1).toString()
    );
    const cpnsWithoutMpns = this.gridOptions.rowData.filter((row) => {
      if (
        row.componentType === 'CPN' &&
        cpnsWhichHaveMpns.indexOf(row.nodePath.toString()) === -1
      ) {
        return row;
      }
    });
    return cpnsWithoutMpns;
  }
  public onClickFilter(i, preventAddActiveCall?: boolean) {
    if (!preventAddActiveCall) {
      this.addActiveClass(i);
    }
    if (
      !(
        this.filterConfiguration instanceof Object &&
        Object.getOwnPropertyNames(this.filterConfiguration).length
      )
    ) {
      const isExists = this.selectedFilters.find((obj) => obj.key === i.key);
      const isExistsIndex = this.selectedFilters.findIndex(
        (obj) => obj.key === i.key
      );
      if (i.selected && !isExists) {
        this.selectedFilters.push(i);
      }
      if (!i.selected && isExistsIndex !== -1) {
        this.selectedFilters.splice(isExistsIndex, 1);
      }
      const isCpnExists = this.selectedFilters.find(
        (obj) =>
          obj.key === 'cpnsWithoutCost' ||
          obj.key === 'cpnsWithoutPartNo' ||
          obj.field === 'makeOrBuy' ||
          obj.field === 'costRollupFlag'
      );
      const isMpnExists = this.selectedFilters.find(
        (obj) =>
          obj.key === 'mpnsWithoutCost' || obj.key === 'mpnsWithoutPartNo'
      );
      const isCostRollUpFlag = this.selectedFilters.find((obj) => {
        if (i.key === 'partsWithCrfN' || i.key === 'partsWithCrfY') {
          return obj.key === i.key;
        }
      });
      if (isCostRollUpFlag && isCostRollUpFlag.key === i.key) {
        if (!this.costRollUpFlagFilter.includes(i.key)) {
          this.costRollUpFlagFilter.push(i.key);
        }
      } else {
        if (this.costRollUpFlagFilter.indexOf(i.key) !== -1) {
          this.costRollUpFlagFilter.splice(
            this.costRollUpFlagFilter.indexOf(i.key),
            1
          );
        }
      }
      const isLDCostFilter = this.selectedFilters.find((obj) => {
        if (
          i.key === 'lowerCpnCost' ||
          i.key === 'higherCpnCost' ||
          i.key === 'missingLdCpnCost'
        ) {
          return obj.key === i.key;
        }
      });
      if (isLDCostFilter && isLDCostFilter.key === i.key) {
        if (!this.lowerCpnHigherCpnCostFilter.includes(i.key)) {
          this.lowerCpnHigherCpnCostFilter.push(i.key);
        }
      } else {
        if (this.lowerCpnHigherCpnCostFilter.indexOf(i.key) !== -1) {
          this.lowerCpnHigherCpnCostFilter.splice(
            this.lowerCpnHigherCpnCostFilter.indexOf(i.key),
            1
          );
        }
      }
      if (
        this.lowerCpnHigherCpnCostFilter.length <= 1 &&
        this.costRollUpFlagFilter.length <= 1
      ) {
        let model = this.gridOptions.rowData;
        this.selectedFilters.forEach((filters) => {
          switch (filters.field) {
            case 'cost':
              if (filters.key === 'cpnsWithoutCost') {
                model = this.getCpnsMpnsValuesWithoutCost(
                  model,
                  filters,
                  'CPN'
                );
              } else {
                model = this.getCpnsMpnsValuesWithoutCost(
                  model,
                  filters,
                  'MPN'
                );
              }
              break;
            case 'partNumber':
              model = this.getMissingCpnComponent(model, filters);
              break;
            case 'mpn':
              model = this.getMissingMpnComponent(model, filters);
              break;
            case 'makeOrBuy':
              model = this.getMakeOrBuyValues(model, filters);
              break;
            case 'costRollupFlag':
              model = this.costRollUpFlagCustomFilter(model, filters);
              break;
            case 'levadataCost':
              if (filters.key === 'lowerCpnCost') {
                model = this.getlowerValuesOfLdCpnCost(model);
              } else if (filters.key === 'higherCpnCost') {
                model = this.getHigherValuesOfLdCpnCost(model);
              } else {
                model = this.getNullsValuesOfLdCpnCost(model);
              }
              break;
          }
        });
        if (this.selectedFilters.length > 0) {
          this.getSelctedFilterRows(model, this.selectedFilters[0]);
        } else {
          this.getSelctedFilterRows(this.gridOptions.rowData, i);
        }
      } else {
        this.getSelctedFilterRows([], i);
      }
    } else {
      this.filtersSelected.emit(
        this.filters.filter((filter) => filter.selected)
      );
    }
    this.filtersSelected.emit(this.selectedFilters);
  }
  public move(moveRight) {
    if (this.staticParent && this.staticParent.nativeElement) {
      const staticParentWidth = this.staticParent.nativeElement.clientWidth;
      const scrollableChild =
        this.staticParent.nativeElement.querySelector('.scrollable-child');
      let left = parseInt(scrollableChild.style.left, 10) || 0;
      if (moveRight) {
        if (
          -1 * left < scrollableChild.clientWidth &&
          scrollableChild.clientWidth > staticParentWidth
        ) {
          // left = (left - staticParentWidth);
          const hiddenPortion =
            scrollableChild.clientWidth - -1 * left - staticParentWidth;
          left =
            hiddenPortion < staticParentWidth
              ? left - hiddenPortion
              : left - staticParentWidth;
        }
      } else {
        if (left < 0) {
          left = left * -1 < staticParentWidth ? 0 : left + staticParentWidth;
        }
      }
      scrollableChild.style.left = left + 'px';
    }
  }
  public setScrollableDivWidth() {
    if (this.staticParent && this.staticParent.nativeElement) {
      let width = 0;
      const scrollableDiv =
        this.staticParent.nativeElement.querySelector('.scrollable-child');
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < scrollableDiv.children.length; i++) {
        width += scrollableDiv.children[i].offsetWidth;
      }
      // scrollableDiv.children[0].clientWidth +
      //   scrollableDiv.style.width = width + 'px';
      scrollableDiv.style.width = width + 120 + 'px';
    }
  }
  public moveToLastBadge() {
    const staticParentWidth = this.staticParent.nativeElement.clientWidth;
    const scrollableChild =
      this.staticParent.nativeElement.querySelector('.scrollable-child');
    const scrollableChildWidth = parseInt(scrollableChild.style.width, 10);
    if (staticParentWidth < scrollableChildWidth) {
      scrollableChild.style.left =
        (scrollableChildWidth - staticParentWidth) * -1 + 'px';
    }
  }
  public applyRemainingFilters() {
    this.selectedFilters.forEach((j) => {
      this.onClickFilter(j, true);
    });
  }
  public addActiveClass(filterObj) {
    const id = filterObj.displayName;
    if (
      filterObj.selected &&
      filterObj.addClass !== 'normal' &&
      id &&
      document.getElementById(id) &&
      document.getElementById(id).classList &&
      !document.getElementById(id).classList.contains('selected')
    ) {
      filterObj.addClass = 'normal';
    } else if (filterObj.selected && filterObj.addClass === 'normal') {
      filterObj.addClass = 'deselect';
    } else {
      filterObj.addClass = '';
    }
  }
  public getCpnsMpnsValuesWithoutCost(filterObj, currentfilter, filterType) {
    const filteredRows = filterObj.filter(
      (row) =>
        row.componentType === filterType &&
        ((row.info[currentfilter.field] &&
          row.info[currentfilter.field].validations instanceof Array &&
          row.info[currentfilter.field].validations.length) ||
          !row.cost ||
          (typeof row.cost === 'string' && !row.cost.length))
    );
    return filteredRows;
  }
  public costRollUpFlagCustomFilter(filterConfig, currentFilter) {
    let model;
    if (currentFilter.key === 'partsWithNoCrf') {
      model = filterConfig.filter(
        (row) => row.costRollupFlag !== 'Y' && row.costRollupFlag !== 'N'
      );
    } else if (currentFilter.key === 'partsWithCrfY') {
      model = filterConfig.filter(
        (row) => row.componentType === 'CPN' && row.costRollupFlag === 'Y'
      );
    } else {
      model = filterConfig.filter(
        (row) => row.componentType === 'CPN' && row.costRollupFlag === 'N'
      );
    }
    return model;
  }
  public getlowerValuesOfLdCpnCost(filterConfig) {
    const filteredRows = filterConfig.filter((row) => {
      if (row.componentType === 'CPN' && row.costRollupFlag === 'Y') {
        if (
          typeof row.levadataCost === 'string' &&
          row.levadataCost.length &&
          row.levadataCost !== '' &&
          Number(row.levadataCost) !== 0 &&
          typeof row.cmCost === 'string' &&
          row.cmCost.length
        ) {
          return Number(row.cmCost) > Number(row.levadataCost);
        } else if (
          !row.levadataCost ||
          (typeof row.levadataCost === 'string' && !row.levadataCost.length)
        ) {
          row.levadataCost = '';
        }
      }
    });
    return filteredRows;
  }
  public getHigherValuesOfLdCpnCost(filterConfig) {
    const filteredRows = filterConfig.filter((row) => {
      if (row.componentType === 'CPN' && row.costRollupFlag === 'Y') {
        if (
          typeof row.levadataCost === 'string' &&
          row.levadataCost.length &&
          row.levadataCost !== '' &&
          typeof row.cmCost === 'string' &&
          row.cmCost.length
        ) {
          return Number(row.cmCost) < Number(row.levadataCost);
        } else if (
          !row.levadataCost ||
          (typeof row.levadataCost === 'string' && !row.levadataCost.length)
        ) {
          row.levadataCost = '';
        }
      }
    });
    return filteredRows;
  }
  public getNullsValuesOfLdCpnCost(filterConfig) {
    const filteredRows = filterConfig.filter((row) => {
      if (row.componentType === 'CPN' && row.costRollupFlag === 'Y') {
        if (
          !row.levadataCost ||
          (typeof row.levadataCost === 'string' && !row.levadataCost.length)
        ) {
          return row;
        } else if (
          typeof row.levadataCost === 'string' &&
          row.levadataCost === ''
        ) {
          return row;
        }
      }
    });
    return filteredRows;
  }
  public getMakeOrBuyValues(filterConfig, currentFilter) {
    let model;
    if (currentFilter.key === 'partsWithNoMakeOrBuy') {
      model = filterConfig.filter(
        (row) => row.makeOrBuy !== 'Make' && row.makeOrBuy !== 'Buy'
      );
    } else if (currentFilter.key === 'partsWithMake') {
      model = filterConfig.filter(
        (row) => row.makeOrBuy === 'Make' || row.makeOrBuy === 'make'
      );
    } else {
      model = filterConfig.filter(
        (row) => row.makeOrBuy === 'Buy' || row.makeOrBuy === 'buy'
      );
    }
    return model;
  }
  public getMissingCpnComponent(filterConfig, currentFilter) {
    const filteredRows = filterConfig.filter(
      (row) =>
        row.componentType === 'CPN' &&
        ((row.info[currentFilter.field] &&
          row.info[currentFilter.field].validations instanceof Array &&
          row.info[currentFilter.field].validations.length) ||
          !row.partNumber ||
          (typeof row.partNumber === 'string' &&
            (!row.partNumber.length ||
              row.partNumber.startsWith(this.cpnprefix))))
    );
    return filteredRows;
  }

  public getMissingMpnComponent(filterConfig, currentFilter) {
    const filteredRows = filterConfig.filter(
      (row) =>
        row.componentType === 'MPN' &&
        ((row.info[currentFilter.field] &&
          row.info[currentFilter.field].validations instanceof Array &&
          row.info[currentFilter.field].validations.length) ||
          !row.mpn ||
          (typeof row.mpn === 'string' &&
            (!row.mpn.length || row.mpn.startsWith(this.mpnprefix))))
    );
    return filteredRows;
  }
}
