
import { PipeTransform, Pipe} from '@angular/core';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash/fp';
export interface Item {
  key: string;
  value: Array<object>;
}
@Pipe({ name: 'TaskFilterBy' })

export class TaskFilterByPipe implements PipeTransform {
  public transform(items,searchTerm) {
    if(searchTerm){
      const newSearchTerm=!isNaN(searchTerm)? searchTerm.toString(): searchTerm.toString().toUpperCase();
      let finalItems =[]
      items.forEach(ele => {
        let temp:Item={
          key: ele.key,
          value: []
        };
        ele.value.forEach(element => {
          if(this.search(element,JSON.parse(JSON.stringify(element)),newSearchTerm)) {
            temp.value.push(element);
          }
        });
        if(temp.value.length > 0) {
         finalItems.push(temp);
        }
      });
      return finalItems;
      }
      else{return items;}
      }
      search(item,origItem,newSearchTerm){
        const filteredList=[];
        let prop= '';
        origItem = this.flattenObject(origItem)
        for(const koy in origItem){
        prop=isNaN(origItem[koy]) ? String(origItem[koy]).toString().toUpperCase() : String(origItem[koy]).toString();
        if(prop.indexOf(newSearchTerm) > -1){
        filteredList.push(item);
        return filteredList;
        }}
    }
  
    flattenObject = (obj:any, prefix = '') => {
      const flattened:any = {}
     // const flatData = []
     flattened['description'] = obj['description'];
     flattened['topic'] = obj['topic'];
     flattened['dueDate'] = obj['dueDate'];
     flattened['topicPrefix'] = obj['topicPrefix'];
      Object.keys(obj).forEach((key) => {
        if(key === 'toUser') {
          flattened['tofullName'] = obj['toUser']['fullName']
          flattened['touserEmail'] = obj['toUser']['userEmail']
        }
      })
      return flattened;
  }
  }
