import { SubscriptionService } from 'app/modules/common/subscription.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
// import { Component, OnInit, Input } from '@angular/core';

import { SearchBar } from './shared/search-bar.model';
import { SearchBarService } from './shared/search-bar.service';

@Component({
  selector: 'search-bar',
  templateUrl: 'search-bar.component.html',
  styleUrls: ['./search-bar.component.sass'],
  providers: [SearchBarService]
})

export class SearchBarComponent implements OnInit {
  searchBar: SearchBar[] = [];
  finalOptions: any;
  searchText= '';

  @Input() isRfq = false;
  @Input('customOptions') customOptions: any;
  @Input() filtersCleared = false;
  @Output() searchTextValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() clearText: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  set defaultText(value: string) {
    this.searchText = value;
  }

  get defaultText() {
    return this.searchText;
  }

  constructor(private searchBarService: SearchBarService, private subscriptionService:SubscriptionService) { }

  ngOnInit() {

    this.finalOptions = {
      placeholder: 'Search',
      width: 100,
      height: 30
    }
  }

  onSearch() {
    this.subscriptionService.setSearchText(this.searchText);
  }

  clearSearchText(e){
    this.searchText= '';
    this.clearText.emit(e)
    }
  emitInput() {
    if(this.customOptions.emitter) {
      this.searchTextValue.emit(this.searchText);
    }
  }
}
