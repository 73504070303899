import { Injectable } from '@angular/core';
import { ApiServiceProvider } from 'app/api-service';

@Injectable()
export class AddPartSiteService {
    constructor(private apiServiceProvider: ApiServiceProvider) { }
    getTenantInfo() {
        const data = {
            targetedType: 'UserService',
            servicePath: '/tenants',
            method: 'GET',
        };
        return this.apiServiceProvider.post(data);
    }

    addPartToSite(payload) {
        const requestPayload = {
            targetedType: 'riskManagementService',
            method: 'POST',
            servicePath: `partsite/add`,
            payload
        };
        return this.apiServiceProvider.post(requestPayload);
    }
}
