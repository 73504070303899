import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class GenericDynamicFormService {
  constructor() {}

  private formEventData = new BehaviorSubject<any>(undefined);
  // private selectEventData = new BehaviorSubject<any>(undefined);

  private formControlValue = new BehaviorSubject<any>(undefined);

  setFormEventData(obj) {
    this.formEventData.next(obj);
  }

  getFormEventData() {
    return this.formEventData.asObservable();
  }

  setFormControlValue(obj) {
    this.formControlValue.next(obj);
  }

  getFormControlValue() {
    return this.formControlValue.asObservable();
  }
}
