import { Component, OnInit, Input} from '@angular/core';

import { Insights } from './shared/insights.model';
import { InsightsService } from './shared/insights.service';

@Component({
  selector: 'insights',
  templateUrl: 'insights.component.html',
  styleUrls : ['./insights.component.sass'],
  providers: [InsightsService]
})

export class InsightsComponent implements OnInit {
  @Input() insights: any[];
  @Input() insightsId:string;

  constructor(private insightsService: InsightsService) { }

  ngOnInit() {


  }
}
