import worldMap from '@highcharts/map-collection/custom/world.geo.json'
import proj4 from 'proj4'

export const PIE_CHART = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: 1,
    plotShadow: false,
    type: 'pie',
  },
  title: {
    text: '',
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    headerFormat: '',
  },
  accessibility: {
    point: {
      valueSuffix: '%',
    },
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: false,
        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
      },
      point: {
        events: {
          legendItemClick() {
            this.translate(0, 0)
            this.slice(null)
            return false
          },
          click() {
            return false
          }
        },
      },
      showInLegend: true,
    },
  },
  series: [
    {
      name: 'Opportunity',
      colorByPoint: true,
      point: {
        events: {},
      },
      data: [
        /* {
        name: 'Chrome',
        y: 61.41,
        sliced: true,
        selected: true
      } */
      ],
    },
  ],
}
export const BAR_CHART = {
  chart: {
    type: 'bar',
  },
  title: {
    text: '',
  },
  xAxis: {
    categories: [''],
  },
  yAxis: {
    min: 0,
    title: {
      text: '',
    },
  },
  legend: {
    reversed: true,
  },
  plotOptions: {
    series: {
      stacking: 'normal',
      events: {}
    },
    bar: {
      cursor: 'pointer',
      dataLabels: {}
    },
  },
  series: [
    {
      name: 'Opportunity',
      colorByPoint: true,
      point: {
        events: {},
      },
      data: [
        /* {
        name: 'Chrome',
        y: 61.41,
        sliced: true,
        selected: true
      } */
      ],
    },
  ],
}

export const MAP_CHART = {
  chart: {
    map: worldMap,
    proj4,
  },
  title: {
    text: '',
  },
  mapNavigation: {
    enabled: true,
    buttonOptions: {
      alignTo: 'spacingBox',
    },
  },
  legend: {
    enabled: false,
    align: 'right',
    layout: 'vertical',
    verticalAlign: 'bottom',
    floating: false,
    maxHeight: 400
  },
  colorAxis: {
    showInLegend: false,
    min: 0,
  },
  tooltip: {
    headerFormat: '',
    pointFormat:
    '<b>{series.name}</b><br>Supplier: {point.supplier}<br>Event Type: {point.type}<br>Event Lifecycle: {point.options.status}<br>Site type: {point.options.siteType}',
  },
  plotOptions: {
    series: {
      point: {
        events: {
          // click() {},
        },
      },
    },
  },
  series: [
    {
      type: 'map',
      name: 'Countries',
      color: '#E0E0E0',
      showInLegend: false,
      enableMouseTracking: false,
    },
  ],
}

export const SRFX_PIE_CHART= {
  chart: {
    type: 'pie',
    style: {
      fontFamily: 'Roboto',
      fontSize: '14px'
    }
  },
  title: {
    text: ''
  },
  plotOptions: {
    pie: {
      shadow: false,
      center: ['50%', '50%'],
      dataLabels: {
        enabled: false
      },
      showInLegend: false
    }
  },
  tooltip: {
    useHTML: true,
    formatter() {
      const number = Math.round(this.y)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return `${this.key}: <b>$${number}</b> (${this.percentage.toFixed(
        2
      )}%)`;
    }
  },
  series: [
    {
      name: 'Percentage',
      data: [],
      size: '60%',
      dataLabels: {
        formatter() {
          return this.y > 5 ? this.point.name : null;
        },
        color: '#ffffff',
        distance: -30
      }
    },
    {
      name: 'Percentage',
      data: [],
      size: '80%',
      innerSize: '60%',
      dataLabels: {
        formatter() {
          // display only if larger than 1
          return this.y > 1
            ? '<b>' + this.point.name + ':</b> ' + this.y + '%'
            : null;
        }
      },
      id: 'versions'
    }
  ],
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 400
        },
        chartOptions: {
          series: [
            {
              id: 'versions',
              dataLabels: {
                enabled: false
              }
            }
          ]
        }
      }
    ]
  }
};
