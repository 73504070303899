import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { FeatureService } from 'app/modules/common/feature.service';
import { Location } from '@angular/common';
import { BaseService } from 'app/modules/common/base.service';
import { DataStorageService } from './data-storage.service';
import {CUSTOMER_CURRENT_USER, CUSTOMER_SESSION_EXPIRED} from '../shared/services/shared-consts';
@Injectable()
export class AuthGuard implements CanActivate {
  public returnUrl = '/login';
  public obj: any = '';
  public featuresarray: string[] = [];
  public features: object[] = [];
  public routes: string;

  constructor(
    private baseService: BaseService,
    private dataStorageService: DataStorageService,
    private router: Router,
    private featureService: FeatureService
  ) { }
  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const routeURLArray: string[] = state.url.split('?')[0].split('/');
    const routeURLArrayLength = routeURLArray.length;
    const target = routeURLArray[routeURLArrayLength - 1];
    this.obj = this.baseService.getUserInformation();
    this.featuresarray = this.featureService.getfeaturesArray();
    if (this.obj === undefined) {
      this.obj = JSON.parse(localStorage.getItem(CUSTOMER_CURRENT_USER));
      this.baseService.setUserInformation(this.obj);
      this.featuresarray = this.featureService.getfeaturesArray();
    }
    if (
      this.obj && localStorage.getItem(CUSTOMER_SESSION_EXPIRED) &&
      !JSON.parse(localStorage.getItem(CUSTOMER_SESSION_EXPIRED))
    ) {
      if (this.featuresarray.includes(target)) {
        this.baseService.setAppLoader(false);
        if (target === 'summary' || target === 'opprisks' || target === 'trends'|| target ==='insights') {
          return this.checkCommonUrl(
            state.url.substring(0, state.url.lastIndexOf('/')),
            target
          );
        } else {
          if (target === 'benchmark') {
            if (this.featureService.scope === 'Product') {
              this.router.navigate([
                this.featureService.reDirectionConstants.benchmark
              ]);
            }
          }
          return true;
        }
      } else {
        this.baseService.setAppLoader(false);
        const redirectionUrl =
          this.featureService.reDirectionConstants[target] || '';
        if (target === 'summary' || target === 'opprisks' || target === 'trends'|| target ==='insights') {
          return this.checkCommonUrl(
            state.url.substring(0, state.url.lastIndexOf('/')),
            target
          );
        } else {
          if (
            this.featuresarray.includes(
              redirectionUrl.split('/')[redirectionUrl.split('/').length - 1]
            ) ||
            ''
          ) {
            if (
              this.featureService.reDirectionConstants.hasOwnProperty(target)
            ) {
              const url = state.url.split('/')[2] || '';
              const comparewithUrl = redirectionUrl.split('/')[2] || '';
              if (url === comparewithUrl) {
                this.router.navigate([redirectionUrl]);
              } else {
                return false;
              }
            } else {
              return false;
            }
          } else {
            if (target.length === 0) {
              this.router.navigate([this.returnUrl]);
            }
            return false;
          }
        }
      }
    } else {
      this.baseService.setAppLoader(false);
      this.router.navigate([this.returnUrl]);
      return false;
    }
  }

  public checkCommonUrl(url, target) {
    const path = url.substring(url.lastIndexOf('/') + 1, url.length) || '';
    if (
      Object.getOwnPropertyNames(
        this.featureService.commonUrlsTabs || {}
      ).includes(path)
    ) {
      if (!this.featureService.commonUrlsTabs[path].includes(target)) {
        this.router.navigate([
          url + `/${this.featureService.commonUrlsTabs[path][0]}`
        ]);
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
}
