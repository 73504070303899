import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-is-new',
    templateUrl: './is-new.component.html',
    styleUrls: ['./is-new.component.sass']
})
export class IsNewComponent implements OnInit {
    public new = false;
    constructor() {}
    public agInit(params: any): void {
        this.new = params && params.data && params.data.new;
    }

    public ngOnInit() {}
}
