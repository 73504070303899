import { Injectable } from '@angular/core';
import { ApiServiceProvider } from 'app/api-service';
import { API_SERVICE_CONSTANTS, HTTP_REQUEST_TYPES, REST_END_POINT_URLS } from '../../../npiconstants';

@Injectable()
export class StageRiskService {

    constructor(private apiService: ApiServiceProvider) { }


}
