
export class DownloadExcelService {

    serviceObject = {
        clientName: '',
        formParams: {
            userEmail: '',
            result: undefined
        },
        headerParams: {
            UUID: '',
            userEmail: '',
            Accept: '*/*'
        },
        payload: {},
        method: '',
        servicePath: ''
    };


    setClientName(name) {
        this.serviceObject.clientName = name;
    }

    setMethodName(type) {
        this.serviceObject.method = type;
    }
    setFormParams(username) {
        // if (params.UUID) {
        //     params.UUID = encodeURIComponent(params.UUID);
        //     this.serviceObject.formParams.UUID = params.UUID;
        // }
        this.serviceObject.formParams=username;
        // if (params) {
        //         params = encodeURIComponent(params);
        //          this.serviceObject.formParams.UUID = params;
        //      }
        // this.serviceObject.formParams.userEmail = username;
    }
    setFormHeaders(params) {
        if (params.UUID) {
            params.UUID = encodeURIComponent(params.UUID);
        }
        this.serviceObject.formParams = params;
    }
    setHeaderParams(userName) {
        this.serviceObject.headerParams = userName;


    }
    setPayload(payload){
        this.serviceObject.payload = payload
    }
    setServicePath(url) {

        this.serviceObject.servicePath = url;
    }

    // setPayload(payload) {
    //     this.serviceObject.payload = payload;
    // }
    getServiceTemplate() {
        return this.serviceObject;
    }

}

