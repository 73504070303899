import { Router } from '@angular/router';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import {SharedService} from '../../../shared/services/shared.service';
import {CUSTOMER_USER_GROUP} from '../../../shared/services/shared-consts';

@Component({
  selector: 'child-cell',
  template: '<span class="cursor-pointer" (click)="openUsers($event)"><em class="ld-icon-user font-size-20"></em> </span>'

})
export class UserGroupComponent {
  public params: any;
  constructor(private dataStorageService: DataStorageService, private router: Router,
              private sharedService: SharedService) {

  }


  agInit(params: any): void {
    this.params = params;
  }

  openUsers(event) {
    this.sharedService.setLocalStorageData(CUSTOMER_USER_GROUP, this.params.data);
    this.dataStorageService.setSelectedUserGroup(this.params.data)
    this.router.navigate(['app/srfx/manageusers']);
  }


}
