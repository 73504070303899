import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { PayLoad } from '../../../common/models/payload.model';
import { RequestData } from '../../../common/models/request.model';
import { SubscriptionService } from '../../../common/subscription.service';
import { SubscriptionLike } from 'rxjs';
import { NpiCommonApiService } from '../../npi-common-api.service';
import { SharedService } from 'app/modules/shared/services/shared.service';
import {PROGRAM_CONFIG} from '../../../shared/services/shared-consts';

@Component({
  moduleId: module.id,
  selector: 'npi-title-dropdown',
  templateUrl: './npi-title-dropdown.component.html',
  styleUrls: ['./npi-title-dropdown.component.sass'],
})
export class NpiTitleDropdownComponent implements OnInit, OnChanges, OnDestroy {
  @Input('pageName') public pageName;
  @Input('selectedDropDownValue') public selectedDropDownValue;
  @Input('options') public options;
  @Input('readKey') public readKey;
  @Output() public autoSearchEvent = new EventEmitter<any>();
  @Input('enableToolTip') public enableToolTip = false;
  public searchCustomization;
  public showAutoSearch = false;
  public requestData: RequestData;
  private autoSearchSubscription: SubscriptionLike;
  private programs;
  public showProgramToolTip = false;
  public toolTipData = {
    businessUnit: '',
    productFamily: '',
    expectedRollOutDate :  '',
    referenceCostQuarter: '',
    productTargetCost: '',
    productForecast: '',
    currentStage:''
  }
  constructor(
    private subscriptionService: SubscriptionService,
    private npiCommonApiService: NpiCommonApiService,
    public sharedService: SharedService
  ) {}
  public ngOnChanges() {
    if (this.readKey) {
      this.searchCustomization = {
        displayKey: this.readKey,
        searchKeys: [this.readKey],
      };
    }
    if (this.options && this.readKey) {
      if (this.readKey === 'bomName') {
        this.setAutosearchRequest(
          this.options,
          this.readKey,
          this.npiCommonApiService.getSelectedBOM()
        );
      } else {
        this.npiCommonApiService.getProgramsList().subscribe((value) => {
          if (this.readKey === 'programName') {
            if (
              this.npiCommonApiService.validateServiceCallData(value) !==
              'error'
            ) {
              if (value.result instanceof Array && value.result.length > 0) {
                this.programs = value.result.sort(
                  (a, b) => a.programId - b.programId
                );
                this.npiCommonApiService.setAllPrograms(this.programs);
                this.setAutosearchRequest(
                  this.options,
                  this.readKey,
                  this.selectedDropDownValue
                );
              }
            }
          } else if (this.readKey === 'revisionName') {
            this.setAutosearchRequest(
              this.options,
              this.readKey,
              this.selectedDropDownValue
            );
          }
        });
      }
    }
  }
  public ngOnInit() {
    this.autoSearchSubscription = this.subscriptionService
      .getAutoSearchText()
      .subscribe((value) => {
        if (value) {
          if (
            value &&
            value instanceof Object &&
            value.id &&
            value.id === 'titleDropDownId'
          ) {
            if (
              value.val &&
              value.val instanceof Array &&
              value.val.length > 0
            ) {
              this.selectedDropDownValue = value.val[0][this.readKey];

              if (this.readKey === 'programName') {
                this.npiCommonApiService.setProgramSelected(
                  this.selectedDropDownValue
                );
                this.npiCommonApiService.setSelectedDropdownObject(
                  value.val[0]
                );
              } else if (this.readKey === 'revisionName') {
                this.npiCommonApiService.serRevisionSelected(value.val[0]);
              } else if (
                this.readKey === 'bomName' &&
                !this.npiCommonApiService.getSelectedBOM()
              ) {
                this.npiCommonApiService.setSelectedBOM(value.val[0]);
              }
              this.autoSearchEvent.emit(value.val[0]);
            }
          }
        }
      });
  }
  public setAutosearchRequest(list, readKey, selectedValue) {
    const selectedProgram = this.npiCommonApiService.getSelectedDropdownObject();
    const selectedRevision = this.npiCommonApiService.gerRevisionSelected();
    const selectedBOM = this.npiCommonApiService.getSelectedBOM();
    let selectedProgramDefValue;
    if (
      selectedProgram &&
      selectedProgram[readKey] &&
      readKey === 'programName'
    ) {
      selectedProgramDefValue = list.find(
        (item) => item.programKey === selectedProgram['programKey']
      );
    } else if (selectedRevision && selectedRevision[this.readKey]) {
      selectedProgramDefValue = selectedRevision;
    } else if (selectedBOM && selectedBOM[this.readKey]) {
      selectedProgramDefValue = selectedBOM;
    } else {
      selectedProgramDefValue = list[0];
    }
    const payLoad = new PayLoad();
    payLoad.isWildCardSearch = true;
    this.requestData = new RequestData();
    this.requestData.placeHolder = 'Enter Value';
    this.requestData.type = 'multi';
    this.requestData.id = 'titleDropDownId';
    this.requestData.options = list.slice();
    this.requestData.typeOfSelectSingle = true;
    this.requestData.payload = payLoad;

    this.subscriptionService.setAutoSearchText({
      val: [selectedProgramDefValue],
      id: 'titleDropDownId',
    });
    // this.subscriptionService.setPMRequestData(this.requestData);
    this.showAutoSearch = true;
  }
  public ngOnDestroy() {
    if (this.autoSearchSubscription) {
      this.autoSearchSubscription.unsubscribe();
    }
  }

  public showToolTipData(show)
  {
    if(this.enableToolTip)
    {
    this.showProgramToolTip = show
    if(!this.npiCommonApiService.programConfig)
    {
      this.npiCommonApiService.programConfig=this.sharedService.getLocalStorageData(PROGRAM_CONFIG)
    }
    const config = this.npiCommonApiService.programConfig.generalInfo
    const year = 'FY' + config.referenceQuarter.slice(2, config.referenceQuarter.lastIndexOf('#'));
    const quarter = 'Q' + config.referenceQuarter.slice(config.referenceQuarter.lastIndexOf('#') + 1);
    const costQuarter = year + quarter;
    const currentStage = config.stageWiseForecast.filter(stage=>stage.currentStage)
    const stageWiseForecastEnable = config.stageWiseForecastEnable?currentStage.length>0?currentStage[0].stageName:'':'Overall'
    this.toolTipData = {
      businessUnit: config.businessUnit.hasOwnProperty('selected')?config.businessUnit.selected:'',
      productFamily: config.productFamilyName.hasOwnProperty('selected')?config.productFamilyName.selected:'',
      expectedRollOutDate : config.expectedRollOutDate,
      referenceCostQuarter: costQuarter,
      productTargetCost: window['currencySymbol']+config.productTargetCost,
      productForecast: config.productForecast +' ('+stageWiseForecastEnable+')',
      currentStage:currentStage.length>0?currentStage[0].stageName:''
    }
    }
  }
}
