export const RESPONSE_NAME_OBJECT = {
    dbColumnName: 'rfxRespName',
    datatype: 'STRING',
    mandatory: 0,
    custEdit: 0,
    dtlEdit: 0,
    supEdit: 0,
    custVisible: 1,
    dtlVisible: 1,
    supVisible: 1,
    tmplHeaderOrder: 1,
    headerGroupId: null,
    applyFilter: 1,
    simulationVisible: 0,
    simulationEdit: 0,
    responseComparable: 0,
    awardVisible: 1,
    awardEdit: 0,
    displayName: 'Response Name',
    validations: null
};

export const CONTEXT= 'DETAILS';
export const CONTEXT_UPLOAD= 'PRODUCT_DETAILS';
export const INITIAL_VALUE = 'initialValue';
export const LATEST_VALUE = 'latestValue';
export const ROW_UNIQUE_ID = 'cmspkId';
export const SHOW_EDITABLES_FLAG = 'dtlEdit';

export const STATUS_OBJECT = {
    SENT: 1,
    QUOTED: 3,
    NO_BID: 4,
    ACCEPTED: 5,
    REJECTED: 6,
    REVOKED: 7,
    AWARDED: 9,
    REQUOTED: 10
};
