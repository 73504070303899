import { CustomerConfigurationService } from './../../common/customerConfigurationService';
import { Router, NavigationEnd } from '@angular/router';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ScopeService } from './shared/scope.service';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { ListService } from 'app/modules/core/list/shared/list.service';
import { BaseService } from 'app/modules/common/base.service';
import { IDimensionList } from 'app/modules/common/models/scope/dimensionList';
import { UtilService } from 'app/modules/common/utills/util.service';
import { Location } from '@angular/common';
import { SubscriptionLike } from 'rxjs';
import { TimeSelectionDataService } from 'app/modules/core/time-selection/shared/time-selectiondata.service';
import { PayLoad } from 'app/modules/common/models/payload.model';
import { RequestData } from 'app/modules/common/models/request.model';
import { SubscriptionService } from 'app/modules/common/subscription.service';
import { ISingeItem } from 'app/modules/common/models/scope/singleItem';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { HEADER_OBJECT } from '../../srfq/common/rfx-product-preview/product-rfx.details.constants';
import { ObjectType } from 'app/modules/common/interface/objectype';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';



@Component({
  selector: 'scope',
  templateUrl: 'scope.component.html',
  styleUrls: ['./scope.component.sass'],
  providers: [ScopeService, ListService]
})

export class ScopeComponent implements OnInit, OnDestroy {

  constructor(
    private baseService: BaseService,
    private scopeDataService: ScopeDataService,
    private scopeService: ScopeService,
    private utilService: UtilService,
    private location: Location,
    private router: Router,
    private listService: ListService,
    private timeSelectionDataService: TimeSelectionDataService,
    private customerConfigurationService: CustomerConfigurationService,
    private subscriptionService: SubscriptionService,
    private dataStorageService: DataStorageService
  ) {
    this.botSubscription = this.dataStorageService.getBotServiceName().subscribe(
      (data) => {
        if (data) {
          if (data.action.trim() === 'clear.filter.action') {
            this.onClearApplicationScope();
          }
        }
      }
    );
    this.scopeList = this.customerConfigurationService.getScopeList();
    this.appCurrentFocus = this.scopeDataService.getFocus().subscribe(
      (focus) => {
        this.currentFocus = focus;
        this.data = undefined;
        this.dimensionList = this.scopeDataService.getFilterListByScope(this.currentFocus);
        this.scopeDataService.clearApplicationScope();
        // this.scopeService.setclearall("clear");
        this.scopeDataService.clearDefaultDimensionData();
        this.autoSearchRequest();
        this.selectedScopeFilter = {};
        this.selectedFilterName = HEADER_OBJECT.NO_VALUE;
        this.getUserScopeFilters(this.currentFocus, this.filterType);
      }
    );

    this.scopeDataService.getFilter().subscribe(
      (type) => {
        // console.log(type);
        if (type !== undefined) {
          if (type) {
            this.checkBoxValue = true;
          } else {
            this.checkBoxValue = false;
          }
          setTimeout(
            () => {
              this.onViewOnlySelected();
            }, 100
          );

        }
      }
    );

    this.autoSearchSubscription = this.subscriptionService.getAutoSearchDimensionText().subscribe((data) => {
      if (data !== '' && data !== undefined) {
        // 	this.scopeService.setautosearchdata(data);
        this.data = data;
      }

    });



    this.scopeDataService.boCheckDimension.asObservable().subscribe(
      (dimensionObject) => {
        if (dimensionObject !== undefined) {
          const length = dimensionObject.length;
          let count: any = 0;
          for (const dimension of dimensionObject) {
            if (this.scopeDataService.getDefaultDimensionDataByDimension(dimension) === undefined) {
              this.listService.getDimensionData(dimension).subscribe(
                (data) => {
                  count++;
                  const listData: object = {};
                  for (const item of data['dimensionResult']) {
                    const singleItem: ISingeItem<any> = {}
                    singleItem[item] = {};
                    if (listData !== undefined) {
                      Object.assign(listData, singleItem);
                    }
                  }
                  this.scopeDataService.setDefaultScopeByDimensions({
                    dimensionName: data['dimensionName'],
                    data: listData
                  });

                  if (length === count) {
                    this.scopeDataService.updateChatBotDimension();
                  }
                }

              );
            }
          }
        }
      }
    );
    this.scopeService.getClickOnList().subscribe(
      (val) => {
        if (val !== undefined) {
          this.insidePanel();
        }
      }
    );

    /* this.timeSelectionDataService.getTimePeriodObject().subscribe(
			(data) => {
				if (data !== undefined) {
					this.formatTimePeriodObjectToJQL(data);
					this.getTimePeriod();
				}
			}
    ); */


    this.scopeDataService.getApplicationScope().subscribe(
      selectedScopeData => {
        if (selectedScopeData instanceof Object) {
          this.selectedScopeData = selectedScopeData;
        }
      }
    );
  }
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  data: any = undefined;
  request: RequestData;
  currentFocus: string;
  dimensionList: IDimensionList[] = [];
  toggle_time: boolean;
  customDimensionData: any = {};
  checkBoxValue = false;
  noItemSelected = false;
  customSearch = false;
  customSearchInput: any;
  globalSearch: any;
  hide = false;
  outsidetimeframe: boolean;
  outsideframe: boolean;
  title = '';

  @Input() target: string;

  private widgetTypeSubscription: SubscriptionLike;
  private subscription: SubscriptionLike;
  private autoSearchSubscription: SubscriptionLike;
  private drilldownSubscription: SubscriptionLike;


  hideTime: any;
  appCurrentFocus: any;
  scopeList: any[];
  botSubscription;

  /* get Dimension Data By User input */
  time = false;
  count = 0;
  timePosition: any = {
    x: undefined,
    y: undefined
  };
  divFrame: boolean;
  sereisframe: boolean;

  /**
   * Manage Scope Filter
   * Start
   */

  showCreateFilter = false;
  showManageFilter = false;
  alterFilter = false;
  editFilter = false;
  filterType = 'SCOPE';
  editFilterName = HEADER_OBJECT.NO_VALUE;
  createFilterName = HEADER_OBJECT.NO_VALUE;
  userScopeFilters: any[] = [];
  selectedScopeFilter: ObjectType = {};
  selectedScopeData = {};
  selectedFilterName = HEADER_OBJECT.NO_VALUE;
  popupConfiguration = {
    message: `This action will delete the filter. Do you want to continue?`
  }
  showFiltersTab = false;
  deleteShowPopup = false;
  autoSearchRequest() {

    const payload = new PayLoad();
    // 'CPN_DESC','MPN','CPN','SUPPLIER_NAME','MANUFACTURER_NAME'
    payload.dimension = [];
    if (this.dimensionList !== undefined) {
      for (const i of this.dimensionList) {
        payload.dimension.push(i.dimensionName);
      }
    }

    payload.isWildCardSearch = true;
    this.request = new RequestData();
    this.request.targetedType = 'ELS';
    this.request.method = 'POST';
    this.request.servicePath = 'MultiValueSearch';
    this.request.placeHolder = 'Enter value';
    this.request.type = 'autodimension';
    this.request.typeOfSelectSingle = true;
    this.request.payload = payload;

    this.subscriptionService.setscopeRequestData(this.request);
  }

  ngOnInit() {
    this.currentFocus = this.baseService.getCurrentApplicationScope();
    this.currentFocus = this.scopeDataService.getFocusValue();
    this.dimensionList = this.scopeDataService.getFilterListByScope(this.currentFocus);

    this.autoSearchRequest();



    this.findPath();
    this.subscription = this.router.events.subscribe((val) => {
      try {
        if (val instanceof NavigationEnd) {
          this.findPath();
        }
      } catch (e) { }
    });
    this.getUserScopeFilters(this.currentFocus, this.filterType);
  }

  ngOnDestroy() {
    this.botSubscription.unsubscribe();
    this.scopeDataService.setUserScopeFilter(undefined);
  }

  getTimePeriod() {
    this.scopeService.getTimePeriod();
    // this.timeSelectionDataService.createTimePeriodObject();
    // *this.timeSelectionDataService.updateTitle();
  }
  onTimeStampSelection(timestamp: string) {
    this.scopeDataService.setTimeStampSelection(timestamp);
  }

  /* Event Handlers */
  onFocusChange(focus: string) {
    this.data = undefined;
    this.currentFocus = focus;
    this.scopeDataService.setFocus(this.currentFocus);
    this.dimensionList = this.scopeDataService.getFilterListByScope(this.currentFocus);
    this.autoSearchRequest();
  }

  showTime(event) {

    this.timePosition.x = '250px'; // event.pageX+'px';
    this.timePosition.y = (event.pageY - event.offsetY) + 'px';
    this.count++;
    this.sereisframe = false;
    this.time = !this.time;

  }

  formatTimePeriodObjectToJQL(timePeriod) {
    const localTimePeriodObject: Array<any> = [];
    const localAllTimePeriodObject: Array<any> = [];
    let quarterCount = 0;
    for (const year of Object.keys(timePeriod)) {
      if (timePeriod[year].year.isSelect === true) {
        const quarterObject = timePeriod[year].quarter;
        for (const quarter of Object.keys(quarterObject)) {
          if (quarterObject[quarter].isSelect === true) {
            quarterCount++;
            const reqQuarter = this.timeSelectionDataService.getQuarterStartDate({
              year,
              quarter
            });
            const newObject: object = {
              from_time_period: reqQuarter['QSDate'],
              type: 'quarter'
            };
            localTimePeriodObject.push(newObject);
          } else {
            const reqQuarter = this.timeSelectionDataService.getQuarterStartDate({
              year,
              quarter
            });
            const newObject: object = {
              from_time_period: reqQuarter['QSDate'],
              type: 'quarter'
            };
            localAllTimePeriodObject.push(newObject);
          }
        }
      }
    }

    let returnValue: any;
    if (quarterCount === 0 || quarterCount === 4) {
      returnValue = localAllTimePeriodObject;
    } else {
      returnValue = localTimePeriodObject;
    }
    // this.scopeDataService.setTimePeriod(returnValue);
  }

  onScopeSearch() {
    if (this.globalSearch.length > 0) {
      if (!this.checkBoxValue) {
        this.customSearchInput = this.globalSearch;
        this.scopeService.getGlobalSearchData(this.globalSearch).subscribe(
          (data) => {
            if (data['status'] === 200) {
              this.customDimensionData = this.utilService.onConvertArrayObjectKeys(data['result'][0].hits);
            }
          },
          (error) => { },
          () => {

            this.customSearch = true;
            this.updateDimensionData(this.customDimensionData);
          }
        );
      } else {
      }
    } else {
    }
  }

  onCheckDimensionData() {
    return this.scopeDataService.getSelectedDimensionsDataByFocus(this.currentFocus)['dimensions'];
  }

  onCloseListBox() {
    const elements: NodeListOf<Element> = document.querySelectorAll('.targeted-collapse');
    for (let i = 0; i < elements.length; i++) {
      const classes: DOMTokenList = elements[i].classList;
      if (this.onCheckDimensionData() === undefined) {
        const classes: DOMTokenList = elements[i].classList;
        classes.remove('in');
      } else {
        let values = [];
        if (this.onCheckDimensionData()) {
          values = Object.keys(this.onCheckDimensionData());
        }
        if (!values.includes(elements[i].id)) {
          classes.remove('in');
        }
      }
    }
  }

  /* Check Box Update */
  onViewOnlySelected() {
    this.checkBoxValue !== this.checkBoxValue;
    if (this.checkBoxValue) {
      if (this.onCheckDimensionData() === undefined) {
        this.noItemSelected = true;
        this.onCloseListBox();
      } else {
        this.onCloseListBox();
      }
    } else {
      this.noItemSelected = false;
    }
    if (this.globalSearch !== undefined) {
      if (this.globalSearch.length > 0) {
        this.customSearch = true;
      }
    }
    if (this.customSearch) {
      this.onScopeSearch();
    }
    this.updateViewType();
  }


  updateDimensionData(obj) {
    this.scopeDataService.setCustomDimensionData(obj);
    this.updateViewType();
  }

  updateViewType() {
    if (this.checkBoxValue && this.customSearch) {
      if (!this.noItemSelected) {
        this.scopeDataService.setCustomeInput(this.customSearchInput);
        this.scopeDataService.updateDisplayType('custom+selected');
      }
    } else if (this.checkBoxValue && !this.customSearch) {
      this.scopeDataService.updateDisplayType('only_selected');

    } else if (!this.checkBoxValue && this.customSearch) {
      this.scopeDataService.updateDisplayType('custom');
    } else if (!this.checkBoxValue && !this.customSearch) {
      this.scopeDataService.updateDisplayType('default');
    }
  }

  onClearApplicationScope() {
    this.selectedScopeFilter = {};
    this.selectedFilterName = HEADER_OBJECT.NO_VALUE;
    this.subscriptionService.setClearScope(true);
    this.scopeService.setclearall('clear');
    this.scopeDataService.clearApplicationScope();
    this.scopeDataService.updateApplicationScope();
    // 	this.timeSelectionDataService.clearLocalTimePeriodYearsObject();
    this.checkBoxValue = false;
    this.customSearch = false;

  }
  // Clear Custom Input

  loadDefaultDimensionData($event) {
    $event.stopPropagation();
    this.customSearch = false;
    this.updateViewType();
  }
  findPath() {
    if (this.location.path() !== '') {
      const path = this.utilService.getpath(this.target);
      if (path === 'opportunities') {
        this.toggle_time = false;
      }
      else {
        this.toggle_time = true;
      }
    }
  }

  OnDestroy() {
    this.hideTime.unsubscribe();
    this.appCurrentFocus.unsubscribe();
  }
  ClickedOutside() {

    this.divFrame = true;

  }
  onClickedOutside() {
    this.sereisframe = true;
  }
  insidePanel() {

    if (this.sereisframe && (this.divFrame || this.divFrame === undefined)) {
      if (this.time) {
        this.time = false
      }
    }
  }

  clickedSeriesFrame() {
    this.divFrame = false;
  }
  mousescroll(ev) {
    if (this.time) {
      this.time = false;
    }
  }
  ngOndestroy() {
    if (this.widgetTypeSubscription) { this.widgetTypeSubscription.unsubscribe(); }
    if (this.autoSearchSubscription) { this.autoSearchSubscription.unsubscribe(); }
    if (this.drilldownSubscription) { this.drilldownSubscription.unsubscribe(); }

  }

  private getUserScopeFilters(scope: string, filterType: string) {
    this.showFiltersTab = false;
    this.scopeService.getUserScopeFilters(scope, filterType).subscribe(
      userScopeFiltersResponse => {
        if (userScopeFiltersResponse instanceof Object) {
          if (userScopeFiltersResponse['responseStatus'] && userScopeFiltersResponse['responseStatus']['code'] && userScopeFiltersResponse['responseStatus']['code'] === 200) {
            if (userScopeFiltersResponse['result'] instanceof Array) {
              this.userScopeFilters = this.formatUserScopeFilters(userScopeFiltersResponse['result']);
              this.showFiltersTab = true;
            } else {
              console.log('Failed to retreive User Scope Filters');
            }
          } else {
            console.log('Failed to retreive User Scope Filters');
          }
        } else {
          console.log('Failed to retreive User Scope Filters')
        }
      }
    );
  }

  selectionChanged(event: Event, selectedObject: ObjectType) {
    if (event.target['checked']) {
      this.onClearApplicationScope();
      this.alterFilter = false;
      this.editFilter = false;
      this.selectedScopeFilter = selectedObject;
      this.editFilterName = this.selectedScopeFilter['scopeFilterName'];
      this.selectedFilterName = this.selectedScopeFilter['scopeFilterName'];
      this.scopeDataService.setUserScopeFilter(selectedObject['scopeFilterObject']);
    }
  }

  private formatUserScopeFilters(userScopeFilters: any[]) {
    userScopeFilters.forEach(userScopeFilter => {
      userScopeFilter.scopeFilterObject = JSON.parse(userScopeFilter.scopeFilterObject)
    });
    return userScopeFilters;
  }

  deleteUserScopeFilter() {
    this.scopeService.deleteUserScopeFilter(this.selectedScopeFilter['scopeFilterId']).subscribe(
      deleteUserScopeFilterResponse => {
        if (deleteUserScopeFilterResponse instanceof Object) {
          if (deleteUserScopeFilterResponse['responseStatus']['code'] === 200) {
            this.selectedScopeFilter = {};
            this.alterFilter = false;
            this.editFilter = false;
            this.onClearApplicationScope();
            this.getUserScopeFilters(this.currentFocus, this.filterType);
          } else {
            console.log('Failed to delete User Scope filter');
          }
        } else {
          console.log('Failed to delete User Scope filter');
        }
      }
    );
  }

  editFilterSave() {
    this.updateUserScopeFilter(this.selectedScopeFilter['scopeFilterId'], this.editFilterName, this.selectedScopeData['selectedScopeData'][this.currentFocus]);
  }

  createFilterSave() {
    this.createUserScopeFilter(this.currentFocus, this.filterType, this.createFilterName, this.selectedScopeData['selectedScopeData'][this.currentFocus]);
  }

  private updateUserScopeFilter(scopeFilterId: number, scopeFilterName: string, scopeFilterObject: Object) {
    this.scopeService.updateUserScopeFilter(scopeFilterId, scopeFilterName, scopeFilterObject).subscribe(
      updateUserScopeFilterResponse => {
        if (updateUserScopeFilterResponse instanceof Object) {
          if (updateUserScopeFilterResponse['responseStatus']['code'] === 200) {
            this.editFilterName = scopeFilterName;
            this.showFiltersTab = false;
            this.scopeService.getUserScopeFilters(this.currentFocus, this.filterType).subscribe(
              userScopeFiltersResponse => {
                this.editFilter = false;
                this.alterFilter = false;
                this.userScopeFilters = this.formatUserScopeFilters(userScopeFiltersResponse['result']);
                this.selectedScopeFilter = this.userScopeFilters.find(x => x.scopeFilterId === scopeFilterId);
                this.selectedFilterName = this.selectedScopeFilter['scopeFilterName'];
                this.showFiltersTab = true;
              }
            );
          } else {
            console.log('Failed to update User Scope filter');
          }
        } else {
          console.log('Failed to update User Scope filter');
        }
      }
    );
  }

  checkScopeDimensionData(): boolean {
    if (this.selectedScopeData instanceof Object &&
      this.selectedScopeData.hasOwnProperty('selectedScopeData') && typeof this.currentFocus === 'string') {
      if (this.selectedScopeData['selectedScopeData'][this.currentFocus] instanceof Object &&
        this.selectedScopeData['selectedScopeData'][this.currentFocus].hasOwnProperty('dimensions')) {
        if (this.selectedScopeData['selectedScopeData'][this.currentFocus]['dimensions'] instanceof Object) {
          return Object.entries(this.selectedScopeData['selectedScopeData'][this.currentFocus]['dimensions']).length > 0
        }
      }
    }
  }

  checkEditFilterName(): boolean {
    if (this.selectedScopeFilter instanceof Object) {
      return this.userScopeFilters.filter(userScopeFilter =>
        userScopeFilter.scopeFilterId !== this.selectedScopeFilter['scopeFilterId'])
        .map(scopeFilter => scopeFilter.scopeFilterName)
        .some((validEditFilterName: string) => validEditFilterName.trim().toLowerCase()
          === this.editFilterName.trim().toLowerCase());
    }
  }

  checkCreateFilterName(): boolean {
    return this.userScopeFilters.map(scopeFilter => scopeFilter.scopeFilterName)
      .some(validCreateFilterName => validCreateFilterName.trim().toLowerCase()
        === this.createFilterName.trim().toLowerCase());
  }

  private createUserScopeFilter(scope: string, filterType: string, scopeFilterName: string, scopeFilterObject: Object) {
    this.scopeService.createUserScopeFilter(scope, filterType, scopeFilterName, scopeFilterObject).subscribe(
      createUserScopeFilterResponse => {
        if (createUserScopeFilterResponse instanceof Object) {
          if (createUserScopeFilterResponse['responseStatus']['code'] === 200) {
            this.createFilterName = HEADER_OBJECT.NO_VALUE;
            this.showCreateFilter = false;
            this.showManageFilter = true;
            this.showFiltersTab = false;
            this.scopeService.getUserScopeFilters(this.currentFocus, this.filterType).subscribe(
              userScopeFiltersResponse => {
                this.editFilter = false;
                this.alterFilter = false;
                this.editFilterName = scopeFilterName;
                this.userScopeFilters = this.formatUserScopeFilters(userScopeFiltersResponse['result']);
                this.selectedScopeFilter = this.userScopeFilters.find(x => x.scopeFilterName === scopeFilterName);
                this.selectedFilterName = this.selectedScopeFilter['scopeFilterName'];
                this.showFiltersTab = true;
              }
            );
          } else {
            console.log('Failed to create User Scope filter');
          }
        } else {
          console.log('Failed to create User Scope filter');
        }
      }
    );
  }

  /**
   * Manage Scope Filter
   * End
   */

}
