import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { environment } from '../../../../../environments/environment';
import { CustomerConfigurationService } from '../../../common/customerConfigurationService';
import { DemoReportsData } from '../../../reports/shared/reports-demo-constants';
import { ScopeDataService } from '../../../common/scope.data.service';
import { FileDownloadService } from '../../file-download/shared/file-download.service';

@Component({
  selector: 'child-cell',
  template: `
    <div>
      <a (click)="onClick(this.val)">{{ this.val }} </a>
    </div>
  `
})
export class CustomReportsComponent {
  public params: any;
  public val: any;
  public showDemoReports: Boolean = false;
  public baseCustomerInfo: any;
  public reportsInfo: any;
  public custName: any;
  public scope: string;

  constructor(
    private dataStorageService: DataStorageService,
    private customerConfigurationService: CustomerConfigurationService,
    private scopeDataService: ScopeDataService,
    private fileDownloadService: FileDownloadService
  ) {
    this.baseCustomerInfo = this.customerConfigurationService.getBaseCustomerInfo();
    if (this.baseCustomerInfo instanceof Object) {
      if (
        typeof this.baseCustomerInfo.customerName === 'string' &&
        this.baseCustomerInfo.customerName.length > 0
      ) {
        this.custName = this.baseCustomerInfo.actualCustomerName;
      }
    }
    this.scope = this.scopeDataService.getFocusValue();
    this.scopeDataService.getFocus().subscribe(val => {
      this.scope = val;
    });
  }
  public agInit(params: any): void {
    this.val = params.value;
    this.params = params;
  }
  public async onClick(reportName) {
    // let demoReportsArray = [];
    // if (DemoReportsData) {
    //   demoReportsArray = Object.keys(DemoReportsData);
    // }
    let downLoadUrlPath: any;
    const reportsData = this.customerConfigurationService.getConfData(
      this.scope
    ).reportsData;
    if (
      reportsData.customReportExpandView &&
      reportsData.customReportExpandView.Reports
    ) {
      const reportObject =
        reportsData.customReportExpandView.Reports[reportName];
      if (reportObject && reportObject.directDownload) {
        const payloadJson = {
          targetedType: 'CustomReports',
          servicePath: reportObject.servicePath
        };
        downLoadUrlPath = await this.fileDownloadService.formDownloadUrl(
          payloadJson
        );
        const aTag = document.createElement('a');
        aTag.setAttribute('href', downLoadUrlPath);
        aTag.click();
      } else {
        this.dataStorageService.setReportName(this.val);
        if (this.params.data.jsonConfiguration) {
          this.dataStorageService.setReportData(this.params.data);
        } else {
          this.dataStorageService.setReportData(undefined);
        }
      }
    } else {
      this.dataStorageService.setReportName(this.val);
      if (this.params.data.jsonConfiguration) {
        this.dataStorageService.setReportData(this.params.data);
      } else {
        this.dataStorageService.setReportData(undefined);
      }
    }
  }
}
