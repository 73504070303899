import { Component, OnInit, Input } from '@angular/core';
import { HelpCategory } from './shared/help-category.model';
import { HelpCategoryService } from './shared/help-category.service';
import { BOTHelpService } from 'app/modules/base-app/bot/help/help.service';

@Component({
  selector: 'help-category',
  templateUrl: 'help-category.component.html',
  styleUrls :['./help-category.component.sass' ],
  providers: [HelpCategoryService]
})

export class HelpCategoryComponent implements OnInit {

  @Input('categories') categories : object;
  constructor(
    private helpCategoryService: HelpCategoryService,
    private botHelpService: BOTHelpService
  ) { }

  ngOnInit() {

  }

  onCategoryClick(cat){
    this.botHelpService.setSelectedCategory(cat);
  }
}
