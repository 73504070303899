import { Injectable } from '@angular/core'
import { ApiServiceProvider } from 'app/api-service'
import 'rxjs/add/operator/map'
import { ColDef } from '@ag-grid-community/all-modules'
import { RouterLinkRendererComponent } from '../grid/router-link-render'
import { CustomFilterComponent } from 'app/modules/core/custom-filter/custom-filter.component';
import { DateUtils } from '../../common/utills/DateUtils.service'
import { DateRenderComponent } from 'app/modules/core/grid/date.render.component'

@Injectable()
export class SraAltSuppliersService extends ApiServiceProvider {
  getBaseData(requestPayload, servicePath) {
    const payload = {
      targetedType: 'riskManagementService',
      method: 'POST',
      payload: requestPayload,
      servicePath
    }
    return this.post(payload)
  }
  getRFIBaseData(payload, tenantId) {
    const requestPayload = {
      targetedType: 'riskManagementService',
      method: 'POST',
      payload,
      servicePath: `scenarios/rfi/new/base?tenantId=${tenantId}`,
    };
    return this.post(requestPayload);
  }
  getAlternateData(requestPayload, servicePath) {
    const payload = {
      targetedType: 'riskManagementService',
      method: 'POST',
      payload: requestPayload,
      servicePath
    }
    return this.post(payload)
  }
  getRFIAlternateData(requestPayload) {
    const payload = {
      targetedType: 'riskManagementService',
      method: 'POST',
      payload: requestPayload,
      servicePath: `/scenarios/rfi/new/alternate`,
    }
    return this.post(payload)
  }
  download(requestParams) {
    const payload = {
      method: 'POST',
      targetedType: 'riskManagementService',
      payload: requestParams.payload,
      servicePath: requestParams.servicePath
    };
    return this.downloadExcelUsingPost(payload);
  }

  getWidgetHeaders(queryParams) {
    const payload = {
      targetedType: 'CustomerConfigService',
      method: 'POST',
      generic: true,
      formParams: queryParams,
      servicePath: `/widgetManagement/getWidgetTabHeaders`,
    }
    return this.post(payload)
  }
  getItems(itemsRequest) {
    const payload = {
      targetedType: 'riskManagementService',
      method: 'POST',
      payload: itemsRequest.payload,
      servicePath: itemsRequest.servicePath,
    }
    if (payload.servicePath.length > 0) {
      return this.post(payload)
    } else {
      return null
    }
  }
  createRFI(payload, tenantId, type) {
    const requestPayload = {
      targetedType: 'RFQ',
      method: 'POST',
      payload,
      servicePath: `content/createRfi?alternateParts=${type}&tenantId=${tenantId}`,
    }
    return this.post(requestPayload)
  }

  createRFIFromAlternate(payload, tenantId, selectAll) {
    const requestPayload = {
      targetedType: 'riskManagementService',
      method: 'POST',
      payload,
      servicePath: `insight/events/createRfi?alternateParts=true&tenantId=${tenantId}&selectAll=${selectAll}`,
    }
    return this.post(requestPayload)
  }

  parseHeaders(data, showMenu = true) {
    let agGridHeaders = []
      ; (data || []).forEach((header) => {
        const headerObj: ColDef = {
          field: header.headerInfo.field,
          headerName: header.headerInfo.displayName,
          tooltipField: header.headerInfo.field,
          editable: header.headerInfo.editable || false,
          headerComponentParams: header,
          cellStyle: (params) => {
            if (params.data.hasOwnProperty('altFlag') && params.data.altFlag) {
              return { 'background-color': '#ccd2f5' }
            } else if (params.data.customerCreatedEvent) {
              return { 'background-color': '#E3FDED' }
            }
            if (params.data.hasOwnProperty('altCount') && params.data.altCount > 0) {
              return { 'background-color': '#ccd2f5' }
            }
          },
        }
        if (!showMenu) {
          headerObj.menuTabs = []
        }

        if (header.headerInfo.hasOwnProperty('linkEnabled')) {
          headerObj['cellRendererFramework'] = RouterLinkRendererComponent
          headerObj['cellRendererParams'] = {
            disableParams: {
              key: 'eventDetails',
              value: null,
            },
          }
        }

        switch (headerObj.field) {
          case 'updatedBy':
          case 'city':
          case 'state':
          case 'country':
            headerObj.filterFramework = CustomFilterComponent;
            break
          case 'title':
            headerObj.filter = 'agTextColumnFilter';
            headerObj.filterParams = {
              suppressAndOrCondition: true,
              clearButton: true,
            };
            break
          case 'description':
            headerObj.width = 300
            headerObj.filter = 'agTextColumnFilter';
            headerObj.filterParams = {
              suppressAndOrCondition: true,
              clearButton: true,
            };
            break
          case 'type':
            headerObj.width = 145
            headerObj.filterFramework = CustomFilterComponent;
            break
          case 'status':
            headerObj.width = 127
            headerObj.filterFramework = CustomFilterComponent;
            break
          case 'severity':
            headerObj.width = 114
            break
          case 'changedState':
            headerObj.width = 153
            break
          case 'siteImpacted':
            headerObj.width = 300
            headerObj.filterFramework = CustomFilterComponent;
            break
          case 'vendorImpacted':
            headerObj.width = 300
            break
          case 'latitude':
            headerObj.width = 131
            headerObj['filter'] = 'agNumberColumnFilter'
            headerObj['filterParams'] = {
              filterOptions: ['equals', 'lessThan', 'greaterThan'],
              suppressAndOrCondition: true,
              clearButton: true,
            }
            break
          case 'longitude':
            headerObj.width = 141
            headerObj['filter'] = 'agNumberColumnFilter'
            headerObj['filterParams'] = {
              filterOptions: ['equals', 'lessThan', 'greaterThan'],
              suppressAndOrCondition: true,
              clearButton: true,
            }
            break
          case 'createdDate':
            break
          case 'mpnCost':
          case 'mpnSplit':
          case 'leadTime':
          case 'demand':
          case 'riskIndex':
            headerObj['filter'] = 'agNumberColumnFilter'
            headerObj['filterParams'] = {
              filterOptions: ['equals', 'lessThan', 'greaterThan'],
              suppressAndOrCondition: true,
              clearButton: true,
            }
            break
          // case 'ldManufacturer':
          // case 'ldSupplier':
          //   if (this.isScoreCardEnabled) {
          //     headerObj['cellRendererFramework'] = RouterLinkRendererComponent
          //   }
          //   break
        }
        if (header.headerMetaInfo) {
          switch (header.headerMetaInfo.dataType) {
            case 'date':
              headerObj.tooltipField = undefined
              headerObj.cellRendererFramework = DateRenderComponent
              headerObj.cellRendererParams = {
                format: {
                  dateFormat: 'MMM d, y, h:mm a',
                },
              }
              headerObj.filter = 'agDateColumnFilter'
              headerObj.filterParams = {
                comparator: (filterLocalDateAtMidnight, cellValue) => {
                  if (cellValue) {
                    const dateAsString = DateUtils.getDatewithDefaultTimeZone(
                      cellValue
                    ).toDate()

                    if (dateAsString === null) {
                      return 0
                    }

                    const day = dateAsString.getDate()
                    const month = dateAsString.getMonth()
                    const year = dateAsString.getFullYear()
                    const cellDate = new Date(year, month, day)

                    // Now that both parameters are Date objects, we can compare
                    if (cellDate < filterLocalDateAtMidnight) {
                      return -1
                    } else if (cellDate > filterLocalDateAtMidnight) {
                      return 1
                    } else {
                      return 0
                    }
                  }
                },
                browserDatePicker: true,
                suppressAndOrCondition: true,
                clearButton: true,
              }
              break
          }
        }
        if (header.headerInfo.hasOwnProperty('hidden')) {
          headerObj['hide'] = header.headerInfo.hidden
        }
        agGridHeaders.push(headerObj)
      })
    if (agGridHeaders.length > 0) {
      const checkBoxHeader = {
        headerName: 'Select',
        checkboxSelection: true,
        width: 65,
        pinned: 'left',
        menuTabs: [],
        sortable: false,
        lockVisible: true,
        lockPosition: true,
      }
      agGridHeaders = [checkBoxHeader].concat(agGridHeaders)
    }
    return agGridHeaders
  }

  getQuickFilterData() {
    const requestPayload: any = {
      targetedType: 'CustomerConfigService',
      method: 'GET',
      generic: false,
      formParams: {
        moduleName: 'SRN',
        moduleType: 'FILTER',
        scope: 'COMMODITY',
      },
      servicePath: `/filters`,
    }
    return this.post(requestPayload)
  }

  getScenarios(tenantId) {
    const payload = {
      targetedType: 'riskManagementService',
      method: 'GET',
      servicePath: `scenarios/?tenantId=${tenantId}&`,
    }
    return this.post(payload)
  }

  removeScenario(id, tenantId) {
    const removePayload = {
      targetedType: 'riskManagementService',
      method: 'DELETE',
      payload: {},
      formParams: {
        tenantId,
      },
      servicePath: `scenarios/${id}`,
    };
    return this.post(removePayload);
  }

  getDefaultTemplates() {
    const payload = {
      targetedType: 'riskManagementService',
      method: 'GET',
      servicePath: `insight/rfq/templates/`,
      formParams: {
        templatetype: 'COMMODITY',
      },
    }
    return this.post(payload)
  }
}
