import { CreateScheduleComponent } from '../create-schedule/create-schedule.component';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

export interface CanComponentDeactivate {
  canDeactivate: (
    component?: CanComponentDeactivate,
    currentRoute?: ActivatedRouteSnapshot,
    currentState?: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ) => Observable<boolean> | Promise<boolean> | boolean;
}
@Injectable()
export class CanNavigateAway implements CanDeactivate<CanComponentDeactivate> {
  /* canDeactivate(component: CreateScheduleComponent): Observable<boolean> | Promise<boolean> | boolean {
        debugger;
        let bool = true;
        component.validateAllFormFields(component.createRFQForm);
        if (component.formChanges instanceof Array) {
            if (component.formChanges.length > 0) {
                bool = false;
            }
        }
        return bool;
    } */
  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return component.canDeactivate(
      component,
      currentRoute,
      currentState,
      nextState
    );
  }
}
