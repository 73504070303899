import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericChartComponent } from 'app/modules/high-charts/generic-chart/generic-chart.component';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as highstock from 'highcharts/modules/stock.src';
import * as highmaps from 'highcharts/modules/map.src';
// import * as highboostcanvas from 'highcharts/modules/boost-canvas';
// import * as highboost from 'highcharts/modules/boost.src';

@NgModule({
  imports: [CommonModule, ChartModule],
  declarations: [GenericChartComponent],
  exports: [GenericChartComponent],
  providers: [
    {
      provide: HIGHCHARTS_MODULES,
      useFactory: () => [
        more,
        exporting,
        highstock,
        highmaps,
        // highboostcanvas,
        // highboost,
      ],
    },
  ],
})
export class HightChartModule {}
