import { Injectable } from '@angular/core';
import { ApiServiceProvider } from 'app/api-service';
import 'rxjs';

@Injectable()
export class AiUploadHistoryService {
  constructor(private apiServiceProvider: ApiServiceProvider) {}
  getUploadHistory(requestPayload) {
    const payload = {
      method: 'GET',
      servicePath: `ContentManagementService/external/job/history`,
      targetedType: 'ContentManagementService',
      formParams: requestPayload,
    };

    if (requestPayload.type === 'part_classification') {
        payload.servicePath = 'ContentManagementService/parts/job/history'
    }
    return this.apiServiceProvider.post(payload);
  }
}
