import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Toast, BodyOutputType, ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-ld-action-cell-renderer',
  templateUrl: './ld-action-cell-renderer.component.html',
  styleUrls: ['./ld-action-cell-renderer.component.sass'],
})
export class LdActionCellRendererComponent implements ICellRendererAngularComp {
  params: any;
  uploadFileData = null;
  currentTemplate = null;
  disableStartIngestion = true;
  fileUpload = false;
  constructor(private toasterService: ToasterService) {}
  agInit(params: any): void {
    this.params = params;
    const fileObject = JSON.parse(JSON.stringify(this.params.fileObject));
    const filteredTemplates = params.templateOptions.filter(
      (option) =>
        option.templateDisplayName === this.params.data.template ||
        option.templateName === this.params.data.template
    );
    if (filteredTemplates.length) {
      const templateString = JSON.stringify({
        templateName: filteredTemplates[0].templateName,
        templateDisplayName: filteredTemplates[0].templateDisplayName,
      });
      const paramsObj = {
        sessionId: this.params.data.runId,
        template: encodeURIComponent(templateString),
      };
      fileObject['collaboration'] = true;
      fileObject.formParams = paramsObj;
      fileObject.servicePath =
        filteredTemplates[0].templateName === 'npa_structured_bom'
          ? 'data/multi/upload'
          : 'data/upload';
      this.uploadFileData = fileObject;
      if (
        params.data.rejectedRecords ||
        (filteredTemplates[0].templateName === 'npa_structured_bom' &&
          this.params.data.ingestionStatus === 'INGESTION_COMPLETED')
      ) {
        this.fileUpload = true;
      }
    }
    if (this.params.data) {
      this.disableStartIngestion = !this.params.data.isCriteriaMatched
        ? true
        : this.params.data.ingestionStatus === 'READY_FOR_INGESTION' ||
          this.params.data.ingestionStatus === 'INGESTION_FAILED' ||
          this.params.data.ingestionStatus ===
            'INGESTION FAILED DUE TO SERVER ISSUES'
        ? false
        : true;
    }
  }

  refresh(): boolean {
    return false;
  }
  onActionClick(type) {
    this.params.context.componentParent.invokeParentMethod(
      type,
      this.params.data
    );
  }

  onFileUpload(uploadResponse) {
    if (uploadResponse instanceof File) {
      this.params.context.componentParent.onReUpload(
        uploadResponse,
        this.params.data,
        this.uploadFileData
      );
    } else {
      this.params.context.componentParent.invokeParentMethod(
        'upload',
        this.params.data
      );
    }
  }

  message(type: string, message: string) {
    const toast: Toast = {
      type,
      body: '<div vertical-align=middle>' + message + '</div>',
      bodyOutputType: BodyOutputType.TrustedHtml,
    };
    this.toasterService.pop(toast);
  }
}
