export class PayLoad {
  dimension: String[] = [];
  resultLimit: number;
  isWildCardSearch = false;
  inputs: String[];
  viewBy: String;
  rmList: any = [];
  dimType: String;
  timePeriod: string;
  futures: string;
  status: boolean;
  dimList: any = [];
  tenantId?: number;
  reportName?: string;
  reportId?: string;
  indexName?: string;
}


