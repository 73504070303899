import {Component} from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'child-cell',
    template: `\${{ this.val }} M`,
})
export class RfxListFieldComponent  {
    public params: any;
    val: any;

constructor(private router: Router){

}
    agInit(params: any): void {
        this.val = params.value;
    }
}
