import { Injectable } from '@angular/core';

@Injectable()
export class ApplicationURLS {

    applicationURL: object ;

    constructor() {
        this.applicationURL = {
            login: {
                userlogin:
                {
                    // AuthService/login
                    url: 'AuthService/userLoginn',
                    type: 'post'
                },
                changepassword: {
                    url: 'AuthService/userChangePassword',
                    type: 'POST'
                },
                forgotpassword: {
                    url: 'AuthService/forgotpassword',
                    type: 'POST'
                }
            },
            bot: {
                history:
                {
                    url: 'chatBotHistory',
                    type: 'get'
                },
                send:
                {
                    url: 'chatBot',
                    type: 'get'
                }
            },
            mi:{
                dsbm:
                {
                    url:'/BM/singleBmLookup/singleBMLookup/new',
                    type:'POST'
                },
                sbm:
                {
                    url:'/BM/singleBmLookup/singleBMLookup',
                    type:'POST'
                },
                mpm:
                {
                    url:'/partmatching/pmresult',
                    type:'POST'
                },
           mpmwu:
                {
                    url:'/partmatching/productinfo',
                    type:'POST'
                },
                spm:
                {

                    url:'/partmatching/singlepartmatching',
                    type:'POST'
                },
                rm:
                {
                    url:'/rm/getRMList',
                    type:'POST'
                },
                rmTable:{
                url:'/rm/getTrendsTableData',
                 type:'POST'

            },
            rmGraph:{
                url:'/rm/getThumbnailsData',
                 type:'POST'

            },
            rmpopupGraph:{
                url:'/rm/getMpnTrendData',
                 type:'POST'

            }

            },
            basicalerts:{
                bm:{
                url:'/getAlertsThreshold',
                 type:'GET'}
            }
        };
    }
    getURLObject(obj: object) {
        if (obj === undefined) {
            return ' Pleae check the request Object.'
        }
        return this.applicationURL[obj['target']][obj['service']];
    }









}
