import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

import { NoData } from './no-data.model';

@Injectable()
export class NoDataService {

  constructor(private http: HttpClient) { }


}
