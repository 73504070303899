import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  NgForm,
} from '@angular/forms';
import { AuthenticationService } from 'app/modules/authentication/shared/authentication.service';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';
import { takeWhile } from 'rxjs/operators'
import { AUTHENTICATION_CONSTANT } from '../shared/authentication.constants';

@Component({
  selector: 'cprt-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.sass'],
  providers: [AuthenticationService]
})
export class ForgotComponent implements OnInit, OnDestroy{
  private _compActive = true;
  public authConst = AUTHENTICATION_CONSTANT;
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  forgotForm: FormGroup;
  @ViewChild('forgotForm', { static: false }) currentForm: NgForm;
  successMsg = '';
  errorMsg = '';
  successMsgShow = false;
  errorMsgShow = false;

  constructor(
    private authenticationService: AuthenticationService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.createForgotPasswordForm();
  }

  ngOnDestroy(): void {
    this._compActive = false;
  }

  createForgotPasswordForm(): void {
    this.forgotForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    })
  }

  onForgotPasswordChange() {
    if (!this.forgotForm.valid) {
      this.forgotForm.controls.email.markAsDirty();
      const errors = this.forgotForm.controls.email.errors;
      if (errors && !errors['required']) {
        this.forgotForm.controls.email.setErrors({invalidEmail: true});
      }
      return;
    }
    const contextRoot = window.location.pathname.replace(/\//g, '');
    const obj: any = {
      targetedType: 'UserService',
      method: 'POST',
      servicePath: this.authConst.urls.forgotPassword,
      payload: { email: this.forgotForm.controls.email.value },
      formParams: {
        contextRoot
      }
    };
    if (this.forgotForm.controls.email.value !== null && this.forgotForm.controls.email.value !== '') {
      this.authenticationService.forgotPassword(obj)
      .pipe(takeWhile(() => this._compActive))
      .subscribe(
        data => {
          if (data.responseStatus) {
            if (data.responseStatus.code === 302) {
              this.successMsgShow = true;
              this.errorMsgShow = false;
              this.errorMsg = '';
              this.successMsg = this.authConst.titles.forgotEmailSent;
            } else {
              this.successMsgShow = false;
              this.errorMsgShow = true;
              this.errorMsg = data.message;
              this.successMsg = '';
            }
            // this.router.navigate(['/login']);
          } else {
            this.successMsgShow = false;
            this.errorMsgShow = true;
            this.errorMsg = data.message;
            this.successMsg = '';
          }
        },
        error => {
          this.successMsgShow = false;
          this.successMsg = '';
          this.errorMsgShow = true;
          this.errorMsg = error.message;
          console.log(
            'error in the authentication service response ::' + error
          );
        }
      );
    } else {
      this.errorMsgShow = true;
      this.errorMsg = this.authConst.errors.invalidEmailAddress;
    }
  }
}
