import { CustomerConfigurationService } from './customerConfigurationService';
import { BaseService } from 'app/modules/common/base.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import 'rxjs/add/operator/map';
import { ISingeItem } from 'app/modules/common/models/scope/singleItem';
import { TimeSelectionDataService } from '../core/time-selection/shared/time-selectiondata.service';
import {SharedService} from '../shared/services/shared.service';
import {CUSTOMER_FOCUS} from '../shared/services/shared-consts';

@Injectable()
export class ScopeDataService {
  constructor(
    private baseService: BaseService,
    private timeSelectionDataService: TimeSelectionDataService,
    private customerConfigurationService: CustomerConfigurationService,
    private sharedService: SharedService
  ) {

    this.setDefaultDimensionDataObject();
    this.currentSelection =
      this.selectDimensionData[this.currentFocus] !== undefined
        ? this.selectDimensionData[this.currentFocus]
        : {};
    if (
      typeof localStorage.getItem(CUSTOMER_FOCUS) === 'string' &&
      JSON.parse(localStorage.getItem(CUSTOMER_FOCUS))
        .toString()
        .trim().length > 0
    ) {
      this.setFocus(JSON.parse(localStorage.getItem(CUSTOMER_FOCUS)));
    }
    /*  this.currentFocus = JSON.parse(sessionStorage.getItem('focus'));
         this.focus.next(JSON.parse(sessionStorage.getItem('focus'))); */
    // this.setFocus(JSON.parse(sessionStorage.getItem('focus')));
    // this.focus.next(JSON.parse(sessionStorage.getItem('focus')));
  }
  enableOnlyAfterLogin = false;
  viewtype: string;
  sourcetype: any;
  focus = new Subject<any>();
  defaultDimensionData: object = {};
  selectDimensionData: object = {};
  monthList: Array<any> = [];
  customDimensionData: object = {};
  allObj = {};
  displayDimensionData: object = {};
  timeStampSelection = new BehaviorSubject<any>('Rest Of Year');
  tsSelection = new Subject<any>();
  customInput: any;
  currentFocus = this.baseService.getCurrentApplicationScope();
  // applicationScope = new Subject<any>();
  applicationScope = new BehaviorSubject<any>(undefined);
  displayType = new BehaviorSubject<any>(undefined);
  botMethodFlag = false;
  applicationScopeSubject = new Subject<any>();
  currentSelection: any;
  public reportsFiltersData = new BehaviorSubject<any>(undefined);
  applicationClearNotification = new BehaviorSubject<any>(undefined);
 selectedNavFeature= new Subject<any>();
  isManualSelection = new Subject<boolean>();

  /* Part matching */
  pmValue = new BehaviorSubject<any>(undefined);

  /* RM matching */
  rmValue = new BehaviorSubject<any>(undefined);

  calendarValue = new Subject<any>();

  typeOfWidget = new Subject<any>();
  selectedQuarterObject: object = {};
  selectedMonthObject: object = {};
  quarterList: Array<any> = [];
  chatBotYearCount = 0;
  userSessionData: any;
  boCheckDimension = new BehaviorSubject<any>(undefined);
  dimensionsObject: any;

  botTimeArrayLength = 0;
  type: string;

  timeSelectionStatus = false;

  setChatFilter = new BehaviorSubject<any>(undefined);

  opportunitesTimeFromChatBot = new BehaviorSubject<any>(undefined);

  forecastChngeTimeFormChatBot = new BehaviorSubject<any>(undefined);

  userScopeFilter = new BehaviorSubject<any>(undefined);

  // for opportunities report
  opportunitiesTimePeriod: any;

  onAlertClick = new BehaviorSubject<boolean>(false);

  // for getting loading of data

  // for checkingif its manual or bot component selection

  public oppTrendFinalFilters = new BehaviorSubject<any>(undefined);
  

  onNotificationAlertClick(val: boolean) {
    this.onAlertClick.next(val);
  }

  getNotificationAlertClick() {
    return this.onAlertClick.asObservable();
  }

  getOpportunitiesTimePeriod() {
    return this.opportunitiesTimePeriod;
  }
  setOpportunitiesTimePeriod(value) {
    this.opportunitiesTimePeriod = value;
  }

  public setFinalFilters(value) {
    this.oppTrendFinalFilters.next(value);
  }
  public getFinalFilters() {
    return this.oppTrendFinalFilters.asObservable();
  }

  setManualSelection(value: boolean) {
    this.isManualSelection.next(value);
  }
  getManualSelection() {
    return this.isManualSelection.asObservable();
  }

  /*opportunities*/
  setTsSelection(value) {
    this.tsSelection.next(value);
  }

  getTsSelection() {
    return this.tsSelection.asObservable();
  }
  
  setSelectedNavFeature(value) {
    this.selectedNavFeature.next(value);
  }

  getSelectedNavFeature() {
    return this.selectedNavFeature.asObservable();
  }

  setTimeStampSelection(value) {
    this.timeStampSelection.next(value);
  }

  getTimeStampSelection() {
    return this.timeStampSelection.asObservable();
  }

  setPMValue(value) {
    this.pmValue.next(value);
  }

  resetDefaultDimensionDataObject() {
    this.selectDimensionData['Commodity']['dimensions'] = undefined;
    this.selectDimensionData['Product']['dimensions'] = undefined;
  }

  resetTimeDimensionDataObject() {
    this.viewtype = '';
    this.selectDimensionData['Commodity']['timeDuration'] = undefined;
    this.selectDimensionData['Product']['timeDuration'] = undefined;
  }
  setDefaultDimensionDataObject() {
    this.selectDimensionData = {
      Commodity: {
        timeDuration: undefined,
        dimensions: undefined
      },
      Product: {
        timeDuration: undefined,
        dimensions: undefined
      }
    };
  }

  getPMValue() {
    return this.pmValue.asObservable();
  }

  setRMValue(value) {
    this.rmValue.next(value);
  }

  getRMValue() {
    return this.rmValue.asObservable();
  }

  setCalendarValue(value) {
    this.calendarValue.next(value);
  }

  getCalendarValue() {
    return this.calendarValue.asObservable();
  }

  setTypeOfWidget(value) {
    this.typeOfWidget.next(value);
  }

  getTypeOfWidget() {
    return this.typeOfWidget.asObservable();
  }
  // GET application listbox list by scope(type:Commodity/Product)
  getFilterListByScope(type: string) {
    let selectedFilters: any;
    if (type !== undefined) {
      if (
        this.customerConfigurationService.getConfData(type) &&
        this.customerConfigurationService.getConfData(type).scopeDimensionData
      ) {
        selectedFilters = this.customerConfigurationService.getConfData(type)
          .scopeDimensionData;
      }
    }
    return selectedFilters;
  }

  // Commodity / Product
  setFocus(field: any) {
    if (field === 'commodity') {
      this.currentFocus = 'Commodity';
    } else {
      this.currentFocus = field;
    }

    // if (field !== undefined) {
    this.sharedService.setLocalStorageData(CUSTOMER_FOCUS, this.currentFocus);
    this.focus.next(this.currentFocus);
    //     console.log(focus);
    // }
  }

  getFocus() {
    return this.focus.asObservable();
  }
  getFocusValue() {
    return this.currentFocus;
  }

  getApplicationScopeByFocus(target) {
    if (target !== undefined) {
      if (this.applicationScope !== undefined) {
        return this.applicationScope[target] !== undefined
          ? this.applicationScope[target]
          : undefined;
      }
    }
  }

  getApplicationScope() {
    return this.applicationScope.asObservable();
  }

  getApplicationScopeSubject() {
    return this.applicationScopeSubject.asObservable();
  }
  // New Development

  clearPredictiveCostFlag() {
    if (this.selectDimensionData !== undefined) {
      if (this.selectDimensionData['isPreditive'] !== undefined) {
        delete this.selectDimensionData['isPreditive'];
      }
    }
  }

  setFilterOnlyAfterLogin(value) {
    this.enableOnlyAfterLogin = value;
  }

  getFilterOnlyAfterLogin() {
    return this.enableOnlyAfterLogin;
  }

  clearDefaultData() {
    try {
      if (this.currentSelection['dimensions'] !== undefined) {
        for (const dimension of Object.keys(
          this.currentSelection['dimensions']
        )) {
          if (dimension !== undefined) {
            if (this.currentSelection['dimensions'][dimension] !== undefined) {
              for (const item of Object.keys(
                this.currentSelection['dimensions'][dimension]
              )) {
                if (item !== undefined) {
                  if (
                    this.defaultDimensionData[this.currentFocus] !== undefined
                  ) {
                    if (
                      this.defaultDimensionData[this.currentFocus][dimension] !==
                      undefined
                    ) {
                      if (
                        this.defaultDimensionData[this.currentFocus][dimension][
                        item
                        ] !== undefined
                      ) {
                        this.defaultDimensionData[this.currentFocus][dimension][
                          item
                        ]['isSelected'] = false;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
    const filterName = this.checkAndGetDefaultFiltersInfo();
    if (this.enableOnlyAfterLogin && typeof filterName === 'string') {
      this.selectDimensionData['Commodity']['dimensions'] = {};
      this.selectDimensionData['Commodity']['dimensions']['COMMODITY_MANAGER_NAME'] = {
        [filterName]: { isSelected: true }
      };
      this.applicationScope.next({
        selectedScopeData: this.selectDimensionData
      });
      this.enableOnlyAfterLogin = false;
    }
    else {
      this.selectDimensionData[this.currentFocus]['dimensions'] = undefined;
    }
    delete this.selectDimensionData[this.currentFocus]['filters'];
    this.updateDisplayType(undefined);
    this.currentSelection = this.selectDimensionData[this.currentFocus];
  }

  checkAndGetDefaultFiltersInfo() {
    let enableDefaultFilter = false
    if (
      this.customerConfigurationService.getConfData('Commodity') &&
      this.customerConfigurationService.getConfData('Commodity').leftNavbarFeatures
    ) {

      this.customerConfigurationService.getConfData('Commodity').leftNavbarFeatures.forEach((element) => {
        if (element.title === 'Autoselect Commodity Manager') {
          enableDefaultFilter = true;
        }
      })
    }
    this.userSessionData = this.baseService.getUserInformation();
    if (this.userSessionData.hasOwnProperty('fullName') && this.userSessionData.hasOwnProperty('commodityMgr')) {
      if (this.userSessionData.commodityMgr && enableDefaultFilter) {
        return this.userSessionData.fullName.trim();
      }
    }
  }

  /* Clear Application */
  clearApplicationScope() {
    this.clearDefaultData();
  }

  setCustomeInput(value: any) {
    this.customInput = value;
  }

  setCustomDimensionData(obj) {
    this.customDimensionData = obj;
  }

  updateDisplayType(type: string) {
    this.displayType.next(type);
  }
  getDimensionDataByDimension(obj) {
    let listData: any;
    switch (obj['view']) {
      case 'custom':
        if (this.customDimensionData[obj['dimensionName']] !== undefined) {
          listData = this.customDimensionData[obj['dimensionName']];
        }
        break;
      case 'only_selected':
        if (this.currentSelection['dimensions'] === undefined) {
          listData = undefined;
        } else {
          if (
            this.currentSelection['dimensions'][obj['dimensionName']] !==
            undefined
          ) {
            listData = this.currentSelection['dimensions'][
              obj['dimensionName']
            ];
          }
        }
        break;
      case 'custom+selected':
        // console.log(this.customInput);
        break;
      default:
        if (this.defaultDimensionData[this.currentFocus] === undefined) {
          listData = undefined;
        } else {
          if (
            this.defaultDimensionData[this.currentFocus][
            obj['dimensionName']
            ] !== undefined
          ) {
            listData = this.defaultDimensionData[this.currentFocus][
              obj['dimensionName']
            ];
          }
        }
        break;
    }

    return listData;
  }

  getSelectedDimensionsDataByFocus(focus) {
    return this.selectDimensionData[focus];
  }

  clearDefaultDimensionData() {
    this.defaultDimensionData = {};
  }

  setDefaultScopeByDimensions(target) {
    if (this.defaultDimensionData[this.currentFocus] === undefined) {
      this.defaultDimensionData[this.currentFocus] = {};
    }
    if (
      this.defaultDimensionData[this.currentFocus][target['dimensionName']] ===
      undefined
    ) {
      this.defaultDimensionData[this.currentFocus][
        target['dimensionName']
      ] = {};
    }
    this.defaultDimensionData[this.currentFocus][target['dimensionName']] =
      target['data'];
  }

  getDefaultDimensionDataByDimension(dimensionName: string) {
    if (this.defaultDimensionData[this.currentFocus] === undefined) {
      return undefined;
    } else {
      if (
        this.defaultDimensionData[this.currentFocus][dimensionName] === undefined
      ) {
        return undefined;
      } else {
        return this.defaultDimensionData[this.currentFocus][dimensionName];
      }
    }
  }

  setSelectedItemsByDimension(obj: any, defaultFilter?: boolean) {
    if (this.currentSelection !== undefined) {
      if (this.currentSelection['dimensions'] === undefined) {
        this.currentSelection['dimensions'] = {};
      }
      if (
        this.currentSelection['dimensions'][obj['dimensionName']] === undefined
      ) {
        this.currentSelection['dimensions'][obj['dimensionName']] = {};
      }
      if (this.displayType.value === 'only_selected') {
        if (this.defaultDimensionData[this.currentFocus] !== undefined) {
          if (
            this.defaultDimensionData[this.currentFocus][
            obj['dimensionName']
            ] !== undefined
          ) {
            if (
              Object.keys(
                this.defaultDimensionData[this.currentFocus][
                obj['dimensionName']
                ]
              ).indexOf(obj['value']) !== undefined
            ) {
              if (
                Object.keys(
                  this.defaultDimensionData[this.currentFocus][
                  obj['dimensionName']
                  ]
                ).indexOf(obj['value']) !== -1
              ) {
                this.defaultDimensionData[this.currentFocus][
                  obj['dimensionName']
                ][obj['value']]['isSelected'] = false;
              }
            }
          }
        }
      }
      if (
        Object.keys(
          this.currentSelection['dimensions'][obj['dimensionName']]
        ).indexOf(obj['value']) === -1
      ) {
        const singleItem: ISingeItem<any> = {};
        singleItem[obj['value']] = { isSelected: true };
        if (
          this.currentSelection['dimensions'][obj['dimensionName']] !== undefined
        ) {
          Object.assign(
            this.currentSelection['dimensions'][obj['dimensionName']],
            singleItem
          );
        }
      } else {
        if (!defaultFilter) {
          delete this.currentSelection['dimensions'][obj['dimensionName']][
            obj['value']
          ];
        }
      }
      this.selectDimensionData[this.currentFocus] = this.currentSelection;
      this.updateApplicationScope();
    }
  }
  setBotMethod(obj) {
    this.botMethodFlag = obj;
  }
  getBotMethod() {
    return this.botMethodFlag;
  }

  updateApplicationScope() {
    // console.log(this.selectDimensionData);
    if (this.viewtype === 'Months') {
      this.timeSelectionDataService.updateTitle('month');
    } else {
      this.timeSelectionDataService.updateTitle();
    }
    setTimeout(() => {
      const updateScope = this.getBotMethod();
      if (!updateScope && (!this.enableOnlyAfterLogin || typeof this.checkAndGetDefaultFiltersInfo() !== 'string')) {
        this.applicationScope.next({
          selectedScopeData: this.selectDimensionData
        });
        this.applicationScopeSubject.next({
          selectedScopeData: this.selectDimensionData
        });
      }
    }, 500);
  }
  updateYearSelection(timePeriodData, view) {
    try {
      this.viewtype = view;
      let updatedYear: string;
      let yearObject: any;
      let quarterObject: any;
      let customQuarterObject: any;
      let monthObject: any;
      if (timePeriodData !== undefined) {
        if (timePeriodData.yearObject !== undefined) {
          // Get Current Updated Year
          yearObject = timePeriodData.yearObject.year;
          quarterObject = timePeriodData.yearObject.quarter;
          customQuarterObject = timePeriodData.timePeriod;
          monthObject = timePeriodData.yearObject.month;
          if (yearObject !== undefined) {
            updatedYear = yearObject.year;
            if (yearObject.isSelect === false) {
              delete this.selectedQuarterObject[updatedYear];
              delete this.selectedMonthObject[updatedYear];
              this.getSelectedQuarter();
              if (this.monthList.length > 0) {
                if (view === 'Months') {
                  this.getSelectedMonth();
                }
              }
            }
          }
          if (yearObject.isSelect) {
            this.selectedQuarterObject[updatedYear] = [];
            this.selectedMonthObject[updatedYear] = [];
            if (view === 'Quarters') {
              this.checkQuarterSelection(
                updatedYear,
                quarterObject,
                customQuarterObject
              );
            }
            if (view === 'Months') {
              this.checkMonthSelection(
                updatedYear,
                monthObject,
                customQuarterObject,
                quarterObject
              );
            }
          }
        }
      }
    } catch (error) {
      console.log('error in  updateYearSelection ' + error);
    }
  }

  clearSelectedQuarterObject() {
    this.selectedQuarterObject = {};
    this.selectedMonthObject = {};
  }
  clearSelectedMonthObject() {
    this.monthList = [];
    this.allObj = {};
  }
  onUpdateQuarterSelection(obj) {
    const updatedYear = obj.year;
    const quarter = obj.quarter;
    const quarterObject: any = {};
    const customQuarterObject: any = [];
    const qsDateQuarterObject: object = {};
    const reqQuarter = this.timeSelectionDataService.getQuarterStartDate({
      year: updatedYear,
      quarter
    });
    quarterObject[quarter] = { title: quarter, isSelect: true };
    qsDateQuarterObject[quarter] = reqQuarter;
    customQuarterObject.push(qsDateQuarterObject);
    this.checkQuarterSelection(updatedYear, quarterObject, customQuarterObject);
  }

  onUpdateYearSelection(year) {
    console.log(year);
    this.resetTimeDimensionDataObject();
    let updatedYear: string;
    let yearObject: any;
    let quarterObject: any;
    const customQuarterObject: any = [];
    if (year !== undefined) {
      // Get Current Updated Year
      yearObject = year;
      quarterObject = this.timeSelectionDataService.getQuartosByYear(
        yearObject
      );
      const qsDateQuarterObject: object = {};
      for (const quarter of Object.keys(quarterObject)) {
        const reqQuarter = this.timeSelectionDataService.getQuarterStartDate({
          year,
          quarter
        });
        qsDateQuarterObject[quarter] = reqQuarter;
      }
      customQuarterObject.push(qsDateQuarterObject);
      if (yearObject !== undefined) {
        updatedYear = yearObject;
        if (yearObject.isSelect === false) {
          delete this.selectedQuarterObject[updatedYear];
        }
      }

      // Get Quarter based on Updated Year
      // from_time_period: "01-01-2017", type: "quarter"
      this.checkQuarterSelection(
        updatedYear,
        quarterObject,
        customQuarterObject
      );
    }
  }

  checkQuarterSelection(updatedYear, quarterObject, customQuarterObject) {
    let checkQuarterCount = 0;
    let timeSelectionArray: Array<any> = [];
    let allTimeSelectionArray: Array<any> = [];
    if (quarterObject !== undefined) {
      timeSelectionArray = [];
      allTimeSelectionArray = [];
      for (const quarter of Object.keys(quarterObject)) {
        if (quarterObject[quarter] === null) {
          quarterObject[quarter] = [];
        }
        if (quarterObject[quarter].isSelect) {
          const time =
            customQuarterObject[0][quarter].qsdate !== undefined
              ? customQuarterObject[0][quarter].qsdate
              : undefined;
          if (time !== undefined) {
            const timeObject = {
              from_time_period: time,
              type: 'quarter'
            };
            timeSelectionArray.push(timeObject);
          }
        } else {
          checkQuarterCount++;
          if (customQuarterObject[0][quarter] !== null) {
            const time =
              customQuarterObject[0][quarter].qsdate !== undefined
                ? customQuarterObject[0][quarter].qsdate
                : undefined;
            if (time !== undefined) {
              const timeObject = {
                from_time_period: time,
                type: 'quarter'
              };
              allTimeSelectionArray.push(timeObject);
            }
          }
        }
      }
      this.updateSelectedQuarterObjectByYear(
        updatedYear,
        checkQuarterCount,
        allTimeSelectionArray,
        timeSelectionArray
      );
    }
  }
  updateSelectedQuarterObjectByYear(
    updatedYear,
    checkQuarterCount,
    allTimeSelectionArray,
    timeSelectionArray
  ) {
    if (updatedYear !== undefined) {
     // this.selectedQuarterObject =JSON.parse(JSON.stringify({}));
      if (this.selectedQuarterObject[updatedYear] === undefined) {
        this.selectedQuarterObject[updatedYear] = [];
      }
      if (checkQuarterCount === 4) {
        this.selectedQuarterObject[updatedYear] = this.selectedQuarterObject[
          updatedYear
        ].concat(allTimeSelectionArray);
      } else {
        this.selectedQuarterObject[updatedYear] = this.selectedQuarterObject[
          updatedYear
        ].concat(timeSelectionArray);
      }
    } else {
      delete this.selectedQuarterObject[updatedYear];
    }
    this.getSelectedQuarter();
  }
  updateSelectedMonthObjectByYear(updatedYear, timeSelectionArray) {
    if (updatedYear !== undefined) {
      // if (this.selectedMonthObject[updatedYear] === undefined) {
      // this.selectedMonthObject[updatedYear] = [];
      this.selectedMonthObject[updatedYear] = [];

      this.selectedMonthObject[updatedYear] = this.selectedMonthObject[
        updatedYear
      ].concat(timeSelectionArray);
    }

    // }
    else {
      delete this.selectedMonthObject[updatedYear];
    }

    this.getSelectedMonth();
  }
  checkMonthSelection(updatedyear, monthobject, cusomobject, quarterObject) {
    try {
      // console.log(updatedyear,monthobject,cusomobject)
      const checkQuarterCount = 0;

      const timeSelectionsArray: Array<any> = [];
      const allTimeSelectionArray: Array<any> = [];
      let count = 0;
      if (monthobject !== undefined) {
        for (const month of Object.keys(monthobject)) {
          if (monthobject[month].isSelect) {
            for (const i of cusomobject[0][monthobject[month].quarter][
              'months'
            ]) {
              if (monthobject[month].title === i.title) {
                const time = i.date !== undefined ? i.date : undefined;
                const timeObject = {
                  from_time_period: time,
                  type: 'month'
                };
                timeSelectionsArray.push(timeObject);
              }
            }
          } else {
            count++;
            if (
              cusomobject[0][monthobject[month].quarter] !== null &&
              cusomobject[0][monthobject[month].quarter]['months'] !== undefined
            ) {
              for (const i of cusomobject[0][monthobject[month].quarter][
                'months'
              ]) {
                if (monthobject[month].title === i.title) {
                  const time = i.date !== undefined ? i.date : undefined;
                  const timeObject = {
                    from_time_period: time,
                    type: 'month'
                  };
                  allTimeSelectionArray.push(timeObject);
                }
              }
            }
          }
        }
        if (timeSelectionsArray.length > 0) {
          this.updateSelectedMonthObjectByYear(
            updatedyear,
            timeSelectionsArray
          );
        }

        if (count === 12) {
          this.updateSelectedMonthObjectByYear(
            updatedyear,
            allTimeSelectionArray
          );
        }
        if (count === 12) {
          if (this.allObj === undefined) {
            this.allObj[updatedyear] = allTimeSelectionArray;
          }
        }
        if (Object.keys(this.allObj).length > 0) {
          if (
            count === 12 &&
            !Object.keys(this.allObj).includes(updatedyear)
          ) {
            this.allObj[updatedyear] = allTimeSelectionArray;
          }
        }

        // this.updateSelectedQuarterObjectByYear(updatedYear, checkQuarterCount, allTimeSelectionArray, timeSelectionArray);
      }
    } catch (error) {
      console.log('error in  checkMonthSelection ' + error);
    }
  }
  getSelectedQuarter() {
    this.quarterList = [];
    for (const year of Object.keys(this.selectedQuarterObject)) {
      if (year !== undefined) {
        for (const quarterData of this.selectedQuarterObject[year]) {
          this.quarterList.push(quarterData);
        }
      }
    }
    if (this.type === 'chatBot') {
      this.chatBotYearCount++;
      if (this.chatBotYearCount === this.botTimeArrayLength) {
        this.type = undefined;
        this.chatBotYearCount = 0;
        // this.onUpdateTimeDuration(this.quarterList);
        this.selectDimensionData['Commodity'].timeDuration = this.quarterList;
        this.selectDimensionData['Product'].timeDuration = this.quarterList;
      }
    } else {
      this.onUpdateTimeDuration(this.quarterList);
    }
  }

  onUpdateTimeDuration(quarterList) {
    this.selectDimensionData['Commodity'].timeDuration = quarterList;
    this.selectDimensionData['Product'].timeDuration = quarterList;
    this.updateApplicationScope();
  }

  getSelectedMonth() {
    this.monthList = [];
    for (const year of Object.keys(this.selectedMonthObject)) {
      if (year !== undefined) {
        for (const monthData of this.selectedMonthObject[year]) {
          this.monthList.push(monthData);
        }
      }
      if (this.selectedMonthObject[year] instanceof Array) {
        if (this.selectedMonthObject[year].length === 0) {
          if (this.allObj[year] !== undefined) {
            if (this.allObj[year] instanceof Array) {
              for (const monthData of this.allObj[year]) {
                this.monthList.push(monthData);
              }
            }
          }
        }
      }
    }
    // this.selectDimensionData[this.currentFocus].timeDuration = this.quarterList;
    this.selectDimensionData['Commodity'].timeDuration = this.monthList;
    this.selectDimensionData['Product'].timeDuration = this.monthList;

    this.updateApplicationScope();
  }

  onMonthBulkUpdate(monthList) {
    this.selectDimensionData['Commodity'].timeDuration = monthList;
    this.selectDimensionData['Product'].timeDuration = monthList;
    this.updateApplicationScope();
  }
  clearTimeSelectionData() {
    this.selectDimensionData['Commodity']['timeDuration'] = undefined;
    this.selectDimensionData['Product']['timeDuration'] = undefined;
    this.clearSelectedQuarterObject();
    this.clearSelectedMonthObject();
    this.updateApplicationScope();
  }

  // Time Period Methods

  setTimePeriod(obj) {
    const selectedTimePeriod: object = {};
    /* if (selectedTimePeriod[this.currentFocus] === undefined) {
            selectedTimePeriod[this.currentFocus] = {
                "timeDuration": null
            };
        } */

    if (selectedTimePeriod['Commodity'] === undefined) {
      selectedTimePeriod['Commodity'] = {
        timeDuration: undefined
      };
    }

    if (selectedTimePeriod['Product'] === undefined) {
      selectedTimePeriod['Product'] = {
        timeDuration: undefined
      };
    }
    if (this.currentSelection['timeDuration'] === undefined) {
      this.currentSelection['timeDuration'] = {};
    }
    this.currentSelection['timeDuration'] = obj;
    this.updateApplicationScope();
  }

  updateChatBotDimension() {
    for (const dimension of Object.keys(this.dimensionsObject)) {
      if (dimension !== undefined) {
        for (const metric of Object.keys(this.dimensionsObject[dimension])) {
          if (
            Object.keys(
              this.defaultDimensionData[this.currentFocus][dimension]
            ).indexOf(metric) !== -1
          ) {
            this.defaultDimensionData[this.currentFocus][dimension][metric] = {
              isSelected: true
            };
          }
        }
      }
    }
  }
  onUpdateBotDimensions(dimensionsObject) {
    if (dimensionsObject !== undefined) {
      this.dimensionsObject = dimensionsObject;
      const length = Object.keys(dimensionsObject).length;
      const dimensionArray: Array<any> = [];
      for (const dimension of Object.keys(dimensionsObject)) {
        if (Object.keys(this.defaultDimensionData).indexOf(dimension) === -1) {
          dimensionArray.push(dimension);
        }
      }
      this.boCheckDimension.next(dimensionArray);
    }
    this.selectDimensionData[this.currentFocus][
      'dimensions'
    ] = dimensionsObject;
    setTimeout(() => { }, 2000);
    this.updateApplicationScope();
  }
  onUpdateChatBotApplication(finalChatBotObject) {
    this.settype('chatbot');
    if (finalChatBotObject['time'] !== undefined) {
      const targetedArray = finalChatBotObject['time'];
      this.botTimeArrayLength = targetedArray.length;
      this.type = 'chatBot';
      for (const year of targetedArray) {
        if (year !== undefined) {
          if (year.quarter !== undefined) {
            this.onUpdateQuarterSelection(year);
          } else {
            this.onUpdateYearSelection(year.year);
          }
        }
      }
    }
    if (finalChatBotObject['isPredictive'] !== undefined) {
      this.selectDimensionData['isPreditive'] =
        finalChatBotObject['isPredictive'];
    }
    setTimeout(() => {
      if (finalChatBotObject['filter'] !== undefined) {
        this.onUpdateBotDimensions(finalChatBotObject['filter']);
      } else {
        this.onUpdateBotDimensions(undefined);
      }
    }, 800)
  }
  settype(val) {
    this.sourcetype = val;
  }
  gettype() {
    return this.sourcetype;
  }
  setTime(states: boolean) {
    if (states !== undefined) {
      this.timeSelectionStatus = states;
    }
  }
  getTime() {
    return this.timeSelectionStatus;
  }

  setFilter(setFilter: boolean) {
    this.setChatFilter.next(setFilter);
  }

  getFilter() {
    return this.setChatFilter.asObservable();
  }

  public setReportsFiltersData(val) {
    this.reportsFiltersData.next(val);
  }
  public getReportsFiltersData() {
    return this.reportsFiltersData.asObservable();
  }

  setOpportunitiesTimeFromChatBot(timeObject) {
    /*
     * timeObject = "restOfYear" || next
     */
    this.opportunitesTimeFromChatBot.next(timeObject);
  }
  getOpportunitiesTimeFromChatBot() {
    return this.opportunitesTimeFromChatBot.asObservable();
  }
  setForecastChangeTimeFromChatBot(timeArray) {
    this.forecastChngeTimeFormChatBot.next(timeArray);
  }
  getForecastChangeTimeFromChatBot() {
    return this.forecastChngeTimeFormChatBot.asObservable();
  }

  setUserScopeFilter(scopeFilter: object) {
    this.userScopeFilter.next(scopeFilter);
  }

  getUserScopeFilter() {
    return this.userScopeFilter.asObservable();
  }

  onUpdateManageFilter(dimensionData: object, dimension: string) {
    this.resetDefaultParameters();
    this.selectDimensionData[this.currentFocus]['dimensions'][dimension] =
      dimensionData['dimensions'][dimension];
    if (dimensionData.hasOwnProperty('filters')) {
      if (dimensionData['filters'].hasOwnProperty(dimension)) {
        this.selectDimensionData[this.currentFocus]['filters'][dimension] =
          dimensionData['filters'][dimension];
      }
    }
    this.updateApplicationScope();
  }

  onAdvancedFilterApplied(
    advancedFilterSelectionData: object,
    dimension: string,
    filterObject: object
  ) {
    this.resetDefaultParameters();
    if (
      this.selectDimensionData[this.currentFocus]['dimensions'][dimension] ===
      undefined
    ) {
      this.selectDimensionData[this.currentFocus]['dimensions'][dimension] = {};
    }
    Object.keys(advancedFilterSelectionData).forEach(dimensionValue => {
      this.selectDimensionData[this.currentFocus]['dimensions'][dimension][
        dimensionValue
      ] = advancedFilterSelectionData[dimensionValue];
    });
    this.selectDimensionData[this.currentFocus]['filters'][
      dimension
    ] = filterObject;
    this.updateApplicationScope();
  }

  resetDefaultParameters() {
    if (this.selectDimensionData[this.currentFocus] === undefined) {
      this.selectDimensionData[this.currentFocus] = {};
    }
    if (
      this.selectDimensionData[this.currentFocus]['dimensions'] === undefined
    ) {
      this.selectDimensionData[this.currentFocus]['dimensions'] = {};
    }
    if (this.selectDimensionData[this.currentFocus]['filters'] === undefined) {
      this.selectDimensionData[this.currentFocus]['filters'] = {};
    }
  }

  onAdvancedFilterCleared(dimension: string) {
    this.resetDefaultParameters();
    delete this.selectDimensionData[this.currentFocus]['dimensions'][dimension];
    delete this.selectDimensionData[this.currentFocus]['filters'][dimension];
    this.updateApplicationScope();
  }

  metricHeader = false;
  setMetricHeader(status) {
    this.metricHeader = status || false;
  }

  getMetricHeader() {
    return this.metricHeader;
  }
}
