import { DataStorageService } from 'app/modules/common/data-storage.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  GridOptions,
  GroupCellRenderer,
  ColDef,
  ICellRendererParams,
  GridApi
} from '@ag-grid-community/core';
import { ICustomGridOptions } from 'app/modules/common/models/customGrid';
import { GridConfiguration } from 'app/modules/core/nxt-grid/gridconfiguration';
import * as numeral from 'numeral';
import { NumericEditorComponent } from 'app/modules/core/nxt-grid/numeric-editor.component';
import { LinkComponent } from 'app/modules/core/nxt-grid/link.component';
import { SupplierStatusComponent } from 'app/modules/core/nxt-grid/supplier.status.component';
import { FontComponent } from 'app/modules/core/nxt-grid/font.text.component';
import { RfxAlertsComponent } from 'app/modules/core/nxt-grid/rfxAlerts.component';
import { CurrencyFormatterComponent } from 'app/modules/core/nxt-grid/currency.formatter';
import { GridToolTipComponent } from 'app/modules/core/nxt-grid/grid.tooltip';
import { PlaceHolderComponent } from 'app/modules/core/nxt-grid/placeholder.component';
import { ApiServiceProvider } from 'app/api-service';
import { SharedTargetCostComponent } from 'app/modules/core/nxt-grid/shared-target-checkbox';
import { CheckboxCellComponent } from 'app/modules/core/nxt-grid/checkbox.renderer.component';

import { NumericCellRendererComponent } from 'app/modules/interactive-edit/edit/ag-grid/numeric-cell-renderer.component';
import { IntegerEditorComponent } from 'app/modules/interactive-edit/edit/ag-grid/integer-editor.component';
import { HybridEditorComponent } from 'app/modules/interactive-edit/edit/ag-grid/hybrid-editor.component';
import { IENumericEditorComponent } from 'app/modules/interactive-edit/edit/ag-grid/numeric-editor.component';
import { StatusModifierComponent } from 'app/modules/srfq/detail/detail-view/status-modifier.component';
import { ShowDeviationsComponent } from 'app/modules/core/nxt-grid/show.deviations.component';
import { DropdownStatusComponent } from 'app/modules/core/nxt-grid/dropdown.status.component';
import { InsightComponent } from './qualitative.insights.component';
import { EnumStatusRendererComponent } from 'app/modules/core/nxt-grid/enum.status.renderer.component';
import { DateUtils } from 'app/modules/common/utills/DateUtils.service';
import { NumericUtils } from 'app/modules/common/utills/NumericUtils.service';
import { NumericRendererComponent } from 'app/modules/core/nxt-grid/numeric.renderer.component';
import {
  BACKGROUND_COLOR_OBJECT,
  HEADER_OBJECT
} from 'app/modules/srfq/create/adhoc-rfx/shared/adhoc-rfx.constants';
import { ProductDropdownStatusRenderer } from './product.dropdown.renderer.component';
import { PartialMatchFilterComponent } from '../partial-match-filter/partial-match-filter.component';
import { CustomFilterComponent } from '../custom-filter/custom-filter.component';
import { DatePickerRendererComponent } from './date-picker-renderer';
import { AllModules, Module } from '@ag-grid-enterprise/all-modules';
import { STATUS_OBJECT } from 'app/modules/srfq/detail/detail-view/shared/detail.constants';
import { PostRfxCreationService } from 'app/modules/srfq/detail/detail-view/shared/post.rfx.creation.service';
import { NumericFilterComponent } from '../numeric-filter/numeric-filter.component';
import { DateFilterComponent } from '../date-filter/date-filter.component';
import { GenericDetailDropdownComponent } from 'app/modules/srfq/detail/detail-view/generic-dropdown.component';
import { SharedService } from '../../shared/services/shared.service';
import { CUSTOMER_CELLS } from 'app/modules/shared/services/shared-consts';
import { SingleSelectComponent } from './single.select.component';
import { SingleSelectSimComponent } from './single.select.sim.component';
@Component({
  selector: 'nxt-grid',
  templateUrl: './nxt-grid.component.html',
  styleUrls: ['./nxt-grid.component.sass']
})
export class NxtGridComponent implements OnInit {
  constructor(
    private dataStorageService: DataStorageService,
    private apiService: ApiServiceProvider,
    private postRFXCreationService: PostRfxCreationService,
    private sharedService: SharedService
  ) {
    this.gridOptions = ({} as GridOptions);
    this.gridOptions.groupSelectsChildren = true;
    this.gridOptions.angularCompileHeaders = true;

    this.gridOptions.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true
    };
    this.gridOptions.groupSuppressAutoColumn = true;
    this.OtherConfig.gridHeight = '300';
    this.OtherConfig.columnResize = true;
    this.OtherConfig.gridHeightDefault = true;
  }
  public modules: Module[] = AllModules;
  createGrid: string;
  gridApi: GridApi;
  stringModifierMap = {}
  // private gridColumnApi;
  gridOptions: GridOptions;
  @Input() gridData: GridOptions;
  @Input() scopeSelected = false;
  @Input() customOptions: any;
  @Input() customGridOptions: object;
  @Input() CustomEvent: any;
  @Input() gridConfig: GridConfiguration;
  @Input() OtherConfig: any = {};
  @Input() noDataAvailableOverlay: any = null;
  @Output() cellEditStarted: EventEmitter<any> = new EventEmitter<any>();
  @Output() public gridFiltersChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() public gridSortedData = new EventEmitter<any>();
  @Input() newDropdown?: any;
  overlayNoRowsTemplate: any = `<span style="padding: 10px;background: white;">No results match your search criteria</span>`;
  idNameMap: any = {};
  parentIdMap: any = {};
  childIdMap: any = {};
  inputHeaders: any = [];
  inputArray: any = [];
  parentIdArray: any = [];
  changed = false;
  firstLevelParentIdArray: any = [];
  parentNameArray: any = [];
  headersMap: any = {};
  noData = true;
  gridCustomOptions = {} as GridOptions;
  cellEditedArray: any = [];
  input: string;
  components: any;
  editedRows: any = [];
  editedFieldsByRow: any = [];
  headerFitFlag = false;
  size = 2000;
  input1: number;
  paginationWithGotoPage = false;
  update = false;
  // Input message that needs to be shown when grid has no rows.
  @Input() overlayNoRowMessage: string;
  private frameworkComponents;
  // private getRowNodeId;
  private editableColumnFlag: string;

  // private isRowSelectable;
  public prevState;

  sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressSideButtons: true
          // suppressColumnExpandAll: true
        }
      }
    ]
    // defaultToolPanel: "filters"
  };

  @Output() columnChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() cellEdited: EventEmitter<any> = new EventEmitter<any>();

  public gridColumnsChange = false;

  updatedRowOrder = [];

  onGridReady(params) {
    if (this.customOptions && this.customOptions['isReorderingEnabled']) {
      this.gridApi = params.api;
      this.update = true;
    }
  }

  filterChanged = event => {
    const model = this.gridOptions.api.getFilterModel();
    this.gridFiltersChanged.emit(model);
    this.dataStorageService.setNumericalMatchFilter(event);
  };

  public submitOverrides() {
    this.dataStorageService.setEditedCellsData(
      this.dataStorageService.getEditedRows()
    );
  }

  public rowStyle1(params) {
    if (
      params['data'].restricted === 1 ||
      params['data'].AWARDED === 1 ||
      params['data'].RESTRICTED === 1 ||
      params['data'].LOCKED === 1
    ) {
      return {
        'background-color': '#EDF3F5'
      };
    } else if (typeof params['data']['IS_ADHOC_ITEM'] === 'number') {
      if (params['data']['IS_ADHOC_ITEM'] === 1) {
        return BACKGROUND_COLOR_OBJECT.duplicateBackground;
      }
    }
  }

  public onSelectionChanged(event) {
    if (
      this.gridOptions instanceof Object &&
      this.gridOptions.api instanceof Object &&
      this.gridOptions.api.getSelectedRows instanceof Function
    ) {
      const currentSelection = this.gridOptions.api
        .getSelectedRows()
        .map(i => i[this.gridConfig.getPrimaryId()]);
      this.dataStorageService.setSelectedGridData(currentSelection);
      this.dataStorageService.setSelectedRowsLength(currentSelection.length);
      return true;
    }
  }

  public onFilterTextBoxChanged(event) {
    this.gridOptions.api.setQuickFilter(this.input);
  }

  public rowStyle(params) {
    if (params['data'].restricted === 1) {
      return {
        'background-color': '#dddddd'
      };
    }
  }

  onRowSelected(params) {
    if (params && params.data && params.data.hasOwnProperty('id') && params.data.hasOwnProperty('referenceMpn')) {
      this.dataStorageService.areaOfSelection = 'explore'
    }
    else {
      this.dataStorageService.areaOfSelection = '';
    }
    this.dataStorageService.setCurrentSelectedRow(params);
  }
  gotoPage(input) {
    this.gridOptions.api.paginationGoToPage(input - 1);
  }

  onChange(size) {
    this.gridOptions.api.paginationSetPageSize(Number(size));
    this.gridOptions.cacheBlockSize = size;
  }

  entertext(text) {
    this.input1 = text;
  }

  toggleRowSelectable(node) {
    console.log(node);
    return true;
  }
  ngOnInit() {
    if (
      this.overlayNoRowMessage &&
      typeof this.overlayNoRowMessage === 'string' &&
      this.overlayNoRowMessage.trim().length
    ) {
      this.overlayNoRowsTemplate = `<span style="padding: 10px;background: white;">${this.overlayNoRowMessage}</span>`;
    }
    this.gridOptions = this.gridData;
    this.gridOptions.getRowStyle = this.rowStyle1.bind(this);
    this.gridOptions.pagination = this.gridConfig.isEnableServerSidePagination()
      ? false
      : true;
    this.gridOptions.paginationPageSize = this.gridConfig.getPageSize();
    this.gridOptions.suppressRowClickSelection = this.gridConfig.getSuppressRowClickSelection();
    this.gridOptions.rowSelection = this.gridConfig.getRowSelection();
    this.gridCustomOptions.paginationPageSize = this.gridConfig.getPageSize();
    this.paginationWithGotoPage = this.gridConfig.getPaginationWithGotoPage();
    this.editableColumnFlag = this.gridConfig.getEditableColumn();
    this.gridCustomOptions = {
      floatingFilter: this.gridConfig.getFloatingFilter(),
      defaultColDef: {
        filter: this.gridConfig.getEnableFilter(),
        resizable: true,
        sortable: true
      },
      pagination: this.gridConfig.getPagination(),
      rowHeight: 30,
      groupHeaderHeight: 30,
      headerHeight: 30,
      domLayout: 'autoHeight',
      // suppressColumnVirtualisation: true,
      // rowModelType: 'inMemory',
      groupSelectsChildren: true,
      deltaRowDataMode: false,
      animateRows: false,
      suppressMenuHide: this.gridConfig.getSuppressMenuhide()
    };
    this.createGrid = this.dataStorageService.getcreateELS();

    if (this.gridConfig.getCellMouseOut() instanceof Function) {
      this.gridOptions.onCellMouseOut = this.enableCellMouseOut.bind(this);
    }
    if (this.gridConfig.getCellMouseOver() instanceof Function) {
      this.gridOptions.onCellMouseOver = this.enableCellMouseOver.bind(this);
    }

    if (this.customOptions !== undefined) {
      for (const key of Object.keys(this.customOptions)) {
        switch (key.trim()) {
          case 'suppressMenuHide':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
          case 'floatingFilter':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
          case 'pagination':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
          case 'paginationPageSize':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
          case 'enableFilter':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
          case 'toolPanel':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
          case 'rowHeight':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
          case 'headerHeight':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
          case 'groupHeaderHeight':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
          case 'domLayout':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
          case 'rowSelection':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
          case 'suppressColumnVirtualisation':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
          case 'rowModelType':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
            // case 'defaultColDef':
            // this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
            break;
          case 'deltaRowDataMode':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
            break;
          case 'animateRows':
            this.gridCustomOptions[key.trim()] = this.customOptions[key.trim()];
            break;
          case 'context':
            this.gridOptions[key.trim()] = this.customOptions[key.trim()];
            break;
          case 'frameworkComponents':
            this.gridOptions[key.trim()] = this.customOptions[key.trim()];
            break;
          default:
            break;
        }
      }
    }


    setTimeout(() => {
      if (this.gridConfig.getHeaderObject().result !== undefined) {
        this.formatData();
      } else {
        this.featureSelection(this.gridConfig.getHeaderObject());
        // added to resize columns

        if (this.gridConfig.getSuppressAutoFit()) {
          if (!(this.gridOptions.onGridReady instanceof Function)) {
            this.gridOptions.onGridReady = () => {
              this.gridOptions.api.sizeColumnsToFit();
            };
          }
        } else {
          if (!(this.gridOptions.onGridReady instanceof Function)) {
            this.gridOptions.onGridReady = () => {
              try {
                this.gridAutoSize();
              } catch (e) {
                console.log(e);
              }
            };
          }
        }
        this.gridOptions.columnDefs = this.gridConfig.getHeaderObject();
        if (this.gridOptions.columnDefs[0]['rowDrag']) {
          this.gridOptions.pagination = false;
        }
        if (this.gridConfig.getClientSidePagination()) {
          if (
            this.gridConfig.getRowData().length <=
            this.gridConfig.getClientSideRecords()
          ) {
            this.gridOptions.rowData = this.gridConfig.getRowData();

            if (this.customOptions && this.customOptions['isReorderingEnabled']) {
              this.updatedRowOrder = this.gridOptions.rowData;
            }
          } else {
            // alert('Server Side Pagination')
          }
        } else {
          // alert('Server Side Pagination')
        }
        // for fitting columns as per grid
        // if (this.OtherConfig.columnResize) {
        //   this.gridOptions.onGridReady = () => {
        //     this.gridOptions.api.sizeColumnsToFit();
        //   };
        // }
        this.noData = false;
      }

      this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
      this.gridOptions.onRowSelected = this.onRowSelected.bind(this);
      this.gridOptions.onCellValueChanged = this.onCellValueChanged.bind(this);
    }, 0);

    // this.gridOptions.api.sizeColumnsToFit();
    // this.gridOptions.singleClickEdit = true;
    this.gridOptions.groupMultiAutoColumn = true;
  }

  public getHeight() {
    if (
      !this.OtherConfig ||
      (this.OtherConfig.gridHeightDefault && !this.OtherConfig.gridHeight)
    ) {
      // if (this.OtherConfig.gridHeightDefault && !this.OtherConfig.gridHeight)
      return '100%';
    }
    else { return this.OtherConfig.gridHeight; }
  }

  public formatData() {
    this.gridOptions.rowData = [];
    this.inputArray = this.gridConfig.getHeaderObject();
    this.parentIdArray = [];
    this.firstLevelParentIdArray = [];
    this.idNameMap = {};
    this.parentNameArray = [];
    // console.log(this.inputArray)
    for (const header of this.inputArray['result'].headers) {
      if (header.headerGroupId !== null) {
        this.headersMap[header.headerGroupId] = {
          columnName: header.columnName,
          dbColumnName: header.dbColumnName
        };
      }
    }
    if (
      this.gridConfig.getColumnGrouping() &&
      this.inputArray['result'].headerGroups !== undefined &&
      this.inputArray['result'].headerGroups.length > 0
    ) {
      for (const item of this.inputArray['result'].headerGroups) {
        this.idNameMap[item.headerGroupId] = item.headerGroupName;
        if (item.level === 0) {
          this.parentIdArray.push(item.headerGroupId);
          this.parentNameArray.push(item.headerGroupName);
          // console.log('parent id Array' + this.parentIdArray)
        } else if (item.level === 1) {
          this.firstLevelParentIdArray.push(item.headerGroupId);
          // console.log('first Level ParentId Array' + this.firstLevelParentIdArray)
        } else if (item.level === 2) {
          // console.log('Items in Group 2' + item.headerGroupId)
        }
      }
      // console.log(this.idNameMap)
      const leafMap = {};
      let k = 0;
      for (const i of this.parentIdArray) {
        const childArray = [];
        // alert(k);
        const sample = {
          headerName: this.parentNameArray[k],
          field: this.parentNameArray[k]
        };
        // console.log(sample)
        const childrenArray = [];
        for (const item of this.inputArray['result'].headerGroups) {
          if (item.level === 1 && item.parent === i) {
            // console.log('children for parent ' + i + ' are ' + item.headerGroupId + item.headerGroupName)
            // 	childArray.push(item.headerGroupId)
            let flag = false;
            let str;
            let str1;
            let sample2 = {};
            for (const child of this.inputArray['result'].headerGroups) {
              if (child.level === 2 && child.parent === item.headerGroupId) {
                flag = true;
              }
            }
            if (flag) {
              str = item.headerGroupName;
              if (item.pinned) {
                sample2 = {
                  headerName: item.headerGroupName,
                  field: item.headerGroupName
                };
              }
              else {
                sample2 = {
                  headerName: item.headerGroupName,
                  field: item.headerGroupName,
                  columnGroupShow: 'open'
                };
              }
            } else {
              str = this.headersMap[item.headerGroupId].dbColumnName;
              str1 = this.headersMap[item.headerGroupId].columnName;
              leafMap[item.headerGroupId] = this.headersMap[
                item.headerGroupId
              ].columnName;
              if (item.pinned) {
                sample2 = { headerName: str1, field: str };
              }
              else {
                sample2 = {
                  headerName: str1,
                  field: str,
                  columnGroupShow: 'open'
                };
              }
            }
            childrenArray.push(sample2);
            sample['children'] = childrenArray;
            // console.log(childrenArray)
            const children2Array = [];
            let sample3;
            for (const child of this.inputArray['result'].headerGroups) {
              if (child.level === 2 && child.parent === item.headerGroupId) {
                // console.log('33333333333')
                // console.log(child);
                leafMap[child.headerGroupId] = child.headerGroupName;
                if (child.pinned) {
                  sample3 = {
                    headerName: child.headerGroupName,
                    field: child.headerGroupName
                  };
                }
                else {
                  sample3 = {
                    headerName: child.headerGroupName,
                    field: child.headerGroupName,
                    columnGroupShow: 'open'
                  };
                }
                children2Array.push(sample3);
                sample2['children'] = children2Array;
              }
            }
          }
        }
        this.parentIdMap[i] = childArray;
        this.inputHeaders.push(sample);
        k++;
      }
      for (const i of this.firstLevelParentIdArray) {
        const firstlevelchildArray = [];
        for (const item of this.inputArray['result'].headerGroups) {
          if (item.level === 2 && item.parent === i) {
            firstlevelchildArray.push(item.headerGroupId);
          }
        }
        this.childIdMap[i] = firstlevelchildArray;
      }
      // console.log(leafMap);
      const modifierMap = {};
      for (const item of this.inputArray['result'].headers) {
        if (leafMap[item.headerGroupId]) {
        } else {
          const sample = { headerName: '', field: '', type: 'dimension' };
          if (item.datatype === 'STRING') {
            sample['filter'] = 'agTextColumnFilter';
          } else if (item.datatype === 'INT' || item.datatype === 'DECIMAL') {
            sample['filter'] = 'number';
          }
          if (item.aggr) {
            sample['aggFunc'] = 'sum';
          }
          if (this.gridConfig.getRowGrouping()) {
            // if (item['rowGroupEnabled'] === true) {
            //   sample['rowGroup'] = true;
            //   sample['cellRenderer'] = 'agGroupCellRenderer';
            //   sample['cellRendererParams'] = { checkbox: true };
            //   this.gridOptions.rowGroupPanelShow = 'always';
            //   this.gridOptions.columnTypes = {
            //     dimension: {
            //       enableRowGroup: true,
            //       enablePivot: true
            //     }
            //   };
            // }
            // if (
            //   item['enableRowGroup'] ||
            //   item['rowGroup'] ||
            //   !isNaN(item['rowGroupIndex'])
            // ) {
            //   sample['enableRowGroup'] = true;
            //   sample['rowGroup'] = item['rowGroup'];
            //   sample['rowGroupIndex'] = item['rowGroupIndex'];
            // }
          }
          if (item['linkEnabled']) {
            sample['cellRendererFramework'] = LinkComponent;
          }
          if (item['ifNullHyphen']) {
            sample['cellRendererFramework'] = PlaceHolderComponent;
          }
          if (item['pinned']) {
            sample['pinned'] = item['pinned'];
          }
          if (item['colorTextEnabled']) {
            sample['cellRendererFramework'] = FontComponent;
          }
          if (item['tooltipEnabled']) {
            if (item['toolTipWidgetId'] === 'RFX_SCOPE') {
              sample['cellRendererFramework'] = GridToolTipComponent;
              sample['cellClass'] = 'tooltipRequired';
            }
          }
          if (item['tooltipEnabled']) {
            sample['cellStyle'] = function (params) {
              return {
                overflow: 'visible'
              };
            };
          } else {
            sample['cellStyle'] = function (params) {
              return {};
            };
          }
          sample['hide'] = item.hide;

          if (item.lockVisible) {
            sample['lockVisible'] = true;
          }
          if (this.gridConfig.getEditable()) {
            if (item.editable) {
              sample['editable'] = true;
              // alert();
              if (item.datatype === 'INT' || item.datatype === 'DECIMAL') {
                sample['cellEditorFramework'] = NumericEditorComponent;
                if (item.displayModifier.splitCol !== undefined) {
                  sample['cellEditorParams'] = {
                    validationRequired: item.displayModifier.splitCol
                  };
                }
                sample['valueParser'] = 'NumberParser';
              }
              sample['cellStyle'] = function (params) {
                return {
                  'background-color': '#aaaaff'
                };
              };
            }
          }
          // console.log(this.gridConfig.getCheckBox());
          if (this.gridConfig.getCheckBox()) {
            if (
              item.displayName ===
              this.gridConfig.getHeaderObject()['result'].headers[0].displayName
            ) {
              if (!this.gridConfig.getRowGrouping()) {
                sample['checkboxSelection'] = true;
                sample['headerCheckboxSelection'] = true;
              }
            }
          } else {
            sample['checkboxSelection'] = false;
            sample['headerCheckboxSelection'] = false;
          }
          sample.headerName = item.displayName;
          sample.field = item.dbColumnName;
          if (item.useForScopeData === 0 || item.custVisible === 0) {
          } else {
            this.inputHeaders.push(sample);
          }

          if (item.columnName === 'Actions') {
            this.headerFitFlag = true;
          }
        }
      }
      const columnGroupheader = this.inputHeaders[0];
      this.inputHeaders.shift();
      this.inputHeaders.push(columnGroupheader);
    } else {
      this.inputHeaders = [];
      const modifierMap = {};
      for (const item of this.gridConfig.getHeaderObject()['result'].headers) {
        const sample = {
          headerName: '',
          field: '',
          tooltipField: '',
          headerTooltip: ''
        };
        sample.headerName = item.displayName;
        sample.field = item.dbColumnName;
        if (
          item['datatype'] === 'BIGINT' ||
          item['dbColumnName'] === 'STATUS_ID'
        ) {
        } else if ((item['displayModifier'] || {})['dataType'] === 'BIGINT') {
        } else {
          sample.tooltipField = item.dbColumnName;
        }
        // added for showing tooltips for grid headers
        sample.headerTooltip = item.displayName;
        if (item.hasOwnProperty('headerTooltip') && item.displayName === '') {
          sample.headerTooltip = item.headerTooltip;
        }
        if (item['approvalRequired'] !== null) {
          sample['approvalRequired'] = item['approvalRequired'];
        }
        if (item['featureId'] !== null) {
          sample['featureId'] = item['featureId'];
        }
        if (item['ieToolPanelClass'] !== null) {
          sample['toolPanelClass'] = item['ieToolPanelClass'];
        }
        if (item['lockPosition']) {
          sample['pinned'] = 'left';
          sample['menuTabs'] = [];
          sample['width'] = 45;
          sample['lockPosition'] = true;
          // sample['lock'] = true;
          sample['lockVisible'] = true;
          sample['sortable'] = false;
          sample['lockPinned'] = true;
          if (item['checkboxSelection']) {
            sample['checkboxSelection'] = true;
          }
        }
        if (item['filtermenuTabEnabled']) {
          sample['menuTabs'] = ['generalMenuTab'];
        } else {
          sample['menuTabs'] = ['filterMenuTab', 'generalMenuTab'];
        }


        if (this.createGrid) {
          if (item['applyFilter']) {
            if (!this.scopeSelected) {
              switch (item['datatype']) {
                case 'BIGINT':
                case 'DATE':
                  sample['filterFramework'] = DateFilterComponent;
                  break;
                case 'DECIMAL':
                  sample['filterFramework'] = NumericFilterComponent;
                  sample['filterParams'] = {
                    applyButton: true,
                  }
                  break;
                case 'SINGLESELECT':
                case 'TEXT':
                case 'STRING':
                  sample['filterFramework'] = PartialMatchFilterComponent;
                  sample['filterParams'] = { datatype: 'STRING' };
                  break;
                case 'INT':
                  if (
                    item['displayModifier'] instanceof Object &&
                    item['displayModifier'].hasOwnProperty('stringModifier') &&
                    item['displayModifier']['stringModifier'] instanceof Array
                  ) {
                    sample['filterFramework'] = CustomFilterComponent;
                    sample['filterParams'] = {
                      applyButton: true,
                      stringModifier: true,
                      isCreateGrid: true,
                      columnHeader: item,
                      data: item['displayModifier']['stringModifier']
                    };
                  }
                  break;
                default:
                case 'DECIMAL':
                  sample['filterFramework'] = NumericFilterComponent;
                  sample['filterParams'] = {
                    applyButton: true,
                  }
                  break;
              }
            } else {
              switch (item['datatype']) {
                case 'DECIMAL':
                  sample['filterFramework'] = NumericFilterComponent;
                  sample['filterParams'] = {
                    applyButton: true,
                    scopeSelected: true
                  }
                  break;
                case 'STRING':
                  sample['filterFramework'] = CustomFilterComponent;
                  sample['filterParams'] = {
                    applyButton: true,
                    stringModifier: false
                  };
                  break;

                case 'INT':
                  if (
                    item['displayModifier'] instanceof Object &&
                    item['displayModifier'].hasOwnProperty('stringModifier') &&
                    item['displayModifier']['stringModifier'] instanceof Array
                  ) {
                    sample['filterFramework'] = CustomFilterComponent;
                    sample['filterParams'] = {
                      applyButton: true,
                      stringModifier: true,
                      isCreateGrid: true,
                      columnHeader: item,
                      data: item['displayModifier']['stringModifier']
                    };
                  }
                  break;
                default:
                  sample['filter'] = false;
                  break;
              }
            }
          } else {
            sample['filter'] = false;
          }
        } else {
          switch (item['datatype']) {
            case 'DECIMAL':
            case 'NUMERIC':
            case 'INT':
              if (
                item['displayModifier'] instanceof Object &&
                item['displayModifier'].hasOwnProperty('stringModifier') &&
                item['displayModifier']['stringModifier'] instanceof Array
              ) {
                sample['filterFramework'] = CustomFilterComponent;
                sample['filterParams'] = {
                  applyButton: true,
                  stringModifier: true,
                  data: item['displayModifier']['stringModifier']
                };
              } else {
                sample['filter'] = 'agNumberColumnFilter';
                sample['filterParams'] = {
                  filterOptions: ['inRange'],
                  apply: true
                };
              }

              break;
            case 'SINGLESELECT':
            case 'STRING':
            case 'TEXT':
              sample['filterFramework'] = CustomFilterComponent;
              sample['filterParams'] = {
                applyButton: true,
                stringModifier: false
              };
              break;
            case 'DATE':
            case 'BIGINT':
            default:
              sample['filter'] = false;
              break;
          }
        }
        if (item['featureId'] && item['featureId'] === 'IE') {
          sample['filter'] = true;
        }
        if (item['ieCellEditor'] !== null) {
          if (item['ieCellEditor'] === 'IE_NUMERIC') {
            sample['cellEditorFramework'] = IENumericEditorComponent;
          }
          if (item['ieCellEditor'] === 'IE_INTEGER') {
            sample['cellEditorFramework'] = IntegerEditorComponent;
          }
          if (item['ieCellEditor'] === 'IE_HYBRID') {
            sample['cellEditorFramework'] = HybridEditorComponent;
          }
          if (item['ieCellEditor'] === 'IE_RICH_SELECT') {
            sample['cellEditor'] = 'agSelectCellEditor';
            sample['cellEditorParams'] = {
              values: item['ieCellValues']
            };
          }
        }
        if (item['ieCellRenderer'] !== null) {
          if (item['ieCellEditor'] === 'IE_CELL_RENDERER') {
            sample['cellRendererFramework'] = NumericCellRendererComponent;
          }
        }

        if (
          item['displayModifier'] &&
          item['displayModifier']['stringModifier'] &&
          item['displayModifier']['stringModifier'] != null &&
          item['displayModifier']['stringModifier'].length > 0
        ) {
          const eParamObj: object = {};
          eParamObj['values'] = item['displayModifier']['stringModifier'];

          if (!!item['customParams']) {
            eParamObj['customValues'] = item['customParams'];
          }
          if (!!item['editCPNLevel']) {
            eParamObj['editCPNLevel'] = item['editCPNLevel'];
          }

          if (!!item['dropdownRequired']) {
            sample['cellEditorParams'] = eParamObj;
            sample['cellEditorFramework'] = DropdownStatusComponent;
          }
        }


        if (item['linkEnabled']) {
          sample['cellRendererFramework'] = LinkComponent;
        }

        if (
          item['datatype'] === 'BIGINT' ||
          (item['displayModifier'] || {})['dataType'] === 'BIGINT'
        ) {
          delete sample['cellRendererFramework'];
          sample['cellRenderer'] = function (params) {
            return DateUtils.formatWithDefaultTimeZone(
              params.value,
              (item['displayModifier'] || {})['dateModifier']
            );
          };
        }

        if (item['validations'] !== null) {
          const eParamObj: object = {};
          eParamObj['validations'] = item.validations;

          if (
            item['displayModifier'] &&
            item['displayModifier'] !== undefined &&
            item['displayModifier'] !== null
          ) {
            if (item.displayModifier.decimalPrecision !== undefined) {
              eParamObj['decimalPrecision'] =
                item.displayModifier.decimalPrecision;
            }
            if (item.displayModifier.splitCol !== undefined) {
              eParamObj['splitCol'] = item.displayModifier.splitCol;
            }
            if (item.displayModifier.unitCostCol !== undefined) {
              eParamObj['unitCostCol'] = item.displayModifier.unitCostCol;
            }
            if (item.displayModifier.currencyFormat !== null) {
              eParamObj['currencyFormat'] = item.displayModifier.currencyFormat;
            }

            if (
              item.displayModifier.stringModifier !== null &&
              item.displayModifier.stringModifier.length > 0
            ) {
              eParamObj['values'] = item.displayModifier.stringModifier;
            }
          }

          if (Object.keys(eParamObj).length) {
            sample['cellRendererParams'] = eParamObj;
          }

          if (item['showDeviations'] !== undefined && item['showDeviations']) {
            sample['cellRendererFramework'] = ShowDeviationsComponent;
          } else if (
            item['showCostErrors'] !== undefined &&
            item['showCostErrors']
          ) {
            sample['cellRendererFramework'] = NumericRendererComponent;
          } else {
            if (eParamObj['values'] !== undefined) {
              modifierMap[item['dbColumnName']] =
                item.displayModifier.stringModifier;
              this.dataStorageService.setStatusModifier(modifierMap);
              sample['cellRendererFramework'] = EnumStatusRendererComponent;
            } else {
              if (item['ifNullHyphen']) {
                sample['cellRendererFramework'] = PlaceHolderComponent;
              }
            }

            if (eParamObj['currencyFormat'] !== undefined) {
              delete sample['cellRendererFramework'];
              delete sample['cellRenderer'];
              sample['cellRenderer'] = function (params) {
                return params.value === null
                  ? params.value
                  : NumericUtils.format(
                    params.value,
                    (item['displayModifier'] || {}).decimalPrecision
                  );
              };
            }
          }
        } else {
          const eParamObj: object = {};
          if (
            item['displayModifier'] &&
            item['displayModifier'] !== undefined &&
            item['displayModifier'] !== null
          ) {
            if (item.displayModifier.currencyFormat !== null) {
              eParamObj['currencyFormat'] = item.displayModifier.currencyFormat;
            }

            if (item['displayModifier'] && item['displayModifier'] !== null
              && item['displayModifier']['mutliSelectStringModifier'] instanceof Array
              && item[this.editableColumnFlag] === 1 && item['datatype'] === 'SINGLESELECT'
            ) {
              delete sample['cellRenderer'];
              delete sample['cellRendererFramework'];
              sample['tooltipField'] = HEADER_OBJECT.NO_VALUE;
              if (this.newDropdown) {
                sample['cellRendererFramework'] = SingleSelectComponent;
                sample['cellRendererParams'] = {
                  applyButton: true,
                  stringModifier: true,
                  dataByUser: item.displayModifier.mutliSelectStringModifier,
                };
              }
              else {
                sample['cellEditorFramework'] = SingleSelectSimComponent;
                const eParamObj: object = {};
                eParamObj['values'] = item.displayModifier.mutliSelectStringModifier;
                sample['cellEditorParams'] = eParamObj;
              }

            }

            else {
              if (
                item.displayModifier.stringModifier !== null &&
                item.displayModifier.stringModifier.length > 0
              ) {
                eParamObj['values'] = item.displayModifier.stringModifier;
              }
              if (Object.keys(eParamObj).length) {
                sample['cellRendererParams'] = eParamObj;
              }

              if (eParamObj['values'] !== undefined) {
                modifierMap[item['dbColumnName']] =
                  item.displayModifier.stringModifier;
                this.dataStorageService.setStatusModifier(modifierMap);

                if (item['prodDropdownRequired']) {
                  sample['cellRendererFramework'] = ProductDropdownStatusRenderer;
                } else {
                  if (item['isStatusModifier']) {
                    sample['cellRendererFramework'] = StatusModifierComponent;
                  } else {
                    sample['cellRendererFramework'] = EnumStatusRendererComponent;
                  }
                }
              } else {
                if (item['ifNullHyphen']) {
                  sample['cellRendererFramework'] = PlaceHolderComponent;
                }
              }
              if (
                item['showDeviations'] !== undefined &&
                item['showDeviations']
              ) {
                const paramObj: object = {};
                if (item.displayModifier.decimalPrecision !== undefined) {
                  paramObj['decimalPrecision'] =
                    item.displayModifier.decimalPrecision;
                }
                if (item.displayModifier.splitCol !== undefined) {
                  paramObj['splitCol'] = item.displayModifier.splitCol;
                }
                if (item.displayModifier.unitCostCol !== undefined) {
                  paramObj['unitCostCol'] = item.displayModifier.unitCostCol;
                }
                if (item.displayModifier.currencyFormat !== null) {
                  paramObj['currencyFormat'] =
                    item.displayModifier.currencyFormat;
                }
                if (Object.keys(paramObj).length) {
                  sample['cellRendererParams'] = paramObj;
                }
                sample['cellRendererFramework'] = ShowDeviationsComponent;
              } else {
                if (eParamObj['currencyFormat'] !== undefined) {
                  delete sample['cellRendererFramework'];
                  delete sample['cellRenderer'];
                  sample['cellRenderer'] = function (params) {
                    return NumericUtils.format(
                      params.value,
                      (item['displayModifier'] || {}).decimalPrecision
                    );
                  };
                }
              }
            }
          } else {
            if (item['ifNullHyphen']) {
              sample['cellRendererFramework'] = PlaceHolderComponent;
            }
          }
        }

        if (item['pinned']) {
          sample['pinned'] = item['pinned'];
        }
        if (item['sort']) {
          sample['sort'] = item['sort'];
        }
        if (item['colorTextEnabled']) {
          sample['cellRendererFramework'] = FontComponent;
        }
        if (item['tooltipEnabled']) {
          if (item['toolTipWidgetId'] === 'RFX_SCOPE') {
            sample['cellRendererFramework'] = GridToolTipComponent;
            sample['cellClass'] = 'tooltipRequired';
          }
        }

        if (item['tooltipEnabled']) {
          sample['cellStyle'] = function (params) {
            return {
              overflow: 'visible'
            };
          };
        } else {
          if (item['displayModifier'] && item['displayModifier'] !== null) {
            if (item['displayModifier']['formatters']) {
              if (item['displayModifier']['formatters'] instanceof Array) {
                item['displayModifier']['formatters'].forEach(formatter => {
                  sample['cellStyle'] = function (params) {
                    if (params.value === +formatter['cellValue']) {
                      return {
                        'background-color': formatter['cellColor'],
                        overflow: 'visible'
                      };
                    }
                  }.bind(this);
                });
              }
            }
          } else {
            sample['cellStyle'] = function (params) {
              return {};
            };
          }
        }
        if (this.gridConfig.getEditable()) {
          if (item[this.editableColumnFlag] === undefined) {
            sample['editable'] = item.editable;
            if (item.hasOwnProperty('cellEditorParams') && item.cellEditorParams.hasOwnProperty('interactiveEdit')) {
              sample['editable'] = (params) => {
                if (+params.data.LOCKED === 1 || +params.data.RESTRICTED === 1) {
                  return false
                } else {
                  return item['editable']
                }
              }
            };
            if (item.editable) {
              sample['cellStyle'] = function (params) {
                return {
                  'background-color': '#aaaaff'
                };
              };
            }
          } else {
            if (item[this.editableColumnFlag] === 1) {
              if (this.gridConfig.getEnableEditMode() instanceof Function) {
                sample['editable'] = this.enableEditMode.bind(this);
              } else {
                sample['editable'] = true;
              }
              if (item.datatype === 'INT' || item.datatype === 'DECIMAL') {
                if (item.displayModifier !== null) {
                  if (item.displayModifier.checkBoxEnabled) {
                    delete sample['cellRendererFramework'];
                    delete sample['cellRenderer'];

                    sample['editable'] = false;
                    sample['cellRendererFramework'] = CheckboxCellComponent;
                  }
                  if (item.displayModifier.stringModifier !== null) {
                  } else {
                    // sample['cellEditorFramework'] = NumericEditorComponent;
                    const eParamObj: object = {};
                    if (item.validations !== undefined) {
                      eParamObj['validations'] = item.validations;
                    }
                    if (item.displayModifier.splitCol !== undefined) {
                      eParamObj['splitCol'] = item.displayModifier.splitCol;
                      if (item.displayModifier.splitCol) {
                        sample['cellEditorFramework'] = NumericEditorComponent;
                      }
                    }
                    if (item.displayModifier.unitCostCol !== undefined) {
                      eParamObj['unitCostCol'] =
                        item.displayModifier.unitCostCol;
                      if (item.displayModifier.unitCostCol) {
                        sample['cellEditorFramework'] = NumericEditorComponent;
                      }
                    }
                    if (item.displayModifier.decimalPrecision !== undefined) {
                      eParamObj['decimalPrecision'] =
                        item.displayModifier.decimalPrecision;
                    }

                    if (Object.keys(eParamObj).length) {
                      sample['cellEditorParams'] = eParamObj;
                    }
                  }
                } else {
                  // sample['cellEditorFramework'] = NumericEditorComponent;
                }
              }

              if (!!item['prodDropdownRequired']) {
                sample['editable'] = false;
              }
              if (sample['cellRendererFramework'] === SingleSelectComponent) {
                sample['editable'] = false;
              }

              if (item.dataType === 'BIGINT') {
              }

              if (this.gridConfig instanceof Object) {
                if (this.gridConfig.getCellColorClass() instanceof Function) {
                  // sample['cellClass'] = this.cellColorClass.bind(this);
                } else {
                  sample['cellStyle'] = function (params) {
                    return {
                      'background-color': '#aaaaff'
                    };
                  };
                }
              }
            }
          }
        } else {
          if (item.editable) {
            sample['editable'] = false;
          }
        }
        if (
          item['displayModifier'] != null &&
          item['displayModifier']['cmForeCastInsights']
        ) {
          sample['cellRendererFramework'] = InsightComponent;
          sample['cellClass'] = 'tooltipRequired';
          sample['cellStyle'] = function (params) {
            return {
              overflow: 'visible'
            };
          };
        }
        if (
          item['displayModifier'] &&
          item['displayModifier'] !== null &&
          item['displayModifier']['dateModifier']
        ) {
          sample['valueFormatter'] = this.formatDate;
          delete sample['tooltipField'];
          delete sample['cellRendererFramework'];
        }
        // console.log(this.gridConfig.getCheckBox());
        if (this.gridConfig.getCheckBox()) {
          if (
            item.displayName ===
            this.gridConfig.getHeaderObject()['result'].headers[0].displayName
          ) {
            sample['checkboxSelection'] = true;
            sample['headerCheckboxSelection'] = true;
            sample['headerCheckboxSelectionFilteredOnly'] = true;
          }
        } else {
        }

        if (this.gridConfig.getFloatingFilter()) {
          sample['filter'] = item['filter'];
        }

        if (item['showSupModified'] !== undefined && item['showSupModified']) {
          if (sample['cellRendererParams'] === undefined) {
            sample['cellRendererParams'] = {};
          }
          sample['cellRendererParams']['showSupModified'] =
            item['showSupModified'];
        }

        if (item.hasOwnProperty('derivedCol')) {
          sample['derivedCol'] = item['derivedCol']
        }
        if (item[this.editableColumnFlag] === 1) {
          if (sample['cellRendererParams'] === undefined) {
            sample['cellRendererParams'] = {};
          }
          sample['cellRendererParams']['showNotificationMsg'] = true;
        }

        /**
         * Date Changes
         * Start
         */

        this.assignDatePicker(sample, item);
        this.assignDropdownInPreview(sample, item);
        /**
         * Date Changes
         * End
         */

        /**
         * Post RFX Creation Changes
         * Start
         */

        this.checkPreviewEditableFeature(sample, item);

        /**
         * Post RFX Creation Changes
         * End
         */

        this.inputHeaders.push(sample);

        if (item.columnName === 'Actions') {
          this.headerFitFlag = true;
          sample['cellRendererFramework'] = RfxAlertsComponent;
        }

        sample['hide'] = item.hide;
        if (item.lockVisible) {
          sample['lockVisible'] = true;
        }
        if (this.gridConfig instanceof Object) {
          if (this.gridConfig.getCellColorClass() instanceof Function) {
            sample['cellClass'] = this.cellColorClass.bind(this);
          }
        }
      }
    }
    // for auto sizing columns
    if (!(this.gridOptions.onGridReady instanceof Function)) {
      this.gridOptions.onGridReady = () => {
        try {
          this.gridAutoSize();
        } catch (e) {
          console.log(e);
        }
      };
    }

    this.gridOptions.columnDefs = this.inputHeaders;
    // console.log(this.gridOptions.columnDefs);
    if (this.gridConfig.getClientSidePagination()) {
      if (
        this.gridConfig.getRowData().length <=
        this.gridConfig.getClientSideRecords()
      ) {
        this.gridOptions.rowData = this.gridConfig.getRowData();
        this.gridConfig.rowDataChanged().subscribe(rowData => {
          if (this.update) {
            try {
              this.gridOptions.api.setRowData(rowData);
              this.gridApi.redrawRows();
            } catch (e) { }
          }
        });
      } else {
        // alert('Server Side Pagination')
      }
    } else {
      // alert('Server Side Pagination')
    }
    // console.log(this.gridOptions.rowData)
    this.noData = false;
  }

  // added for auto sizing columns
  gridAutoSize() {
    const allColumnIds = [];
    this.gridOptions.columnDefs.forEach(params => {
      if (!params['checkboxSelection']) {
        allColumnIds.push(params['field']);
      }
    });
    this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
  }

  featureSelection(parseHeaders) {
    // console.log(parseHeaders)
    for (const i of parseHeaders) {
      if (!this.gridConfig.getRowGrouping()) {
        if (i['rowGroupEnabled'] !== undefined) {
          i.rowGroup = false;
        }
      }
      if (!this.gridConfig.getEditable()) {
        if (i['editable'] !== undefined) {
          i.editable = false;
        }
      }
      if (this.gridConfig.getCheckBox()) {
        // alert("checkbox");
        i['checkboxSelection'] = true;
        i['headerCheckboxSelection'] = true;
      }
    }
    // if (this.gridConfig.getRowGrouping()) {
    //   this.gridOptions.rowGroupPanelShow = 'always';
    //   this.gridOptions.columnTypes = {
    //     dimension: {
    //       enableRowGroup: true,
    //       enablePivot: true
    //     }
    //   };
    // }
  }

  previousPage() {
    const payload = this.gridConfig.getPayload();
    payload['payload'].pagingConfiguration.pageNumber =
      payload['payload'].pagingConfiguration.pageNumber - 1;
    // alert(JSON.stringify(payload['payload'].pagingConfiguration.pageNumber));
    this.getData(payload);
  }

  nextPage() {
    const payload = this.gridConfig.getPayload();

    payload['payload'].pagingConfiguration.pageNumber =
      +payload['payload'].pagingConfiguration.pageNumber + 1;
    // alert(JSON.stringify(payload['payload'].pagingConfiguration.pageNumber));
    this.getData(payload);
  }

  first() {
    const payload = this.gridConfig.getPayload();
    payload['payload'].pagingConfiguration.pageNumber = 1;
    // alert(JSON.stringify(payload['payload'].pagingConfiguration.pageNumber));
    this.getData(payload);
  }

  last() {
    const payload = this.gridConfig.getPayload();
    payload[
      'payload'
    ].pagingConfiguration.pageNumber = this.gridConfig.getPageConfiguration().pageCount;
    // alert(JSON.stringify(payload['payload'].pagingConfiguration.pageNumber));
    this.getData(payload);
  }

  goToPage(maxPageNumber) {
    if (
      this.gridConfig.getPageConfiguration().pageNumber <= maxPageNumber &&
      this.gridConfig.getPageConfiguration().pageNumber > 0
    ) {
      const payload = this.gridConfig.getPayload();
      payload[
        'payload'
      ].pagingConfiguration.pageNumber = this.gridConfig.getPageConfiguration().pageNumber;
      // alert(JSON.stringify(payload['payload'].pagingConfiguration.pageNumber));
      this.getData(payload);
    } else {
      if (this.gridConfig.getPageConfiguration().pageNumber < 1) {
        const payload = this.gridConfig.getPayload();
        payload['payload'].pagingConfiguration.pageNumber = 1;
        // alert(JSON.stringify(payload['payload'].pagingConfiguration.pageNumber));
        this.getData(payload);
      } else {
        const payload = this.gridConfig.getPayload();
        payload['payload'].pagingConfiguration.pageNumber = maxPageNumber;
        // alert(JSON.stringify(payload['payload'].pagingConfiguration.pageNumber));
        this.getData(payload);
      }
    }
  }

  itemsPerPage() {
    const payload = this.gridConfig.getPayload();
    payload['payload'].pagingConfiguration.pageNumber = 1;
    payload[
      'payload'
    ].pagingConfiguration.pageSize = this.gridConfig.getPageConfiguration().pageSize;
    // payload['payload'].pagingConfiguration.dropdownPageSize = this.gridConfig.getPageConfiguration().dropdownPageSize;
    this.getData(payload);
  }

  public getData(payload: any) {
    if (payload['payload'] && payload['payload'].sortGroupCriteria) {
      payload['payload'].sortGroupCriteria.sort = payload['payload'].sortGroupCriteria.sortDirection
      payload['payload'].sortGroupCriteria.colId = (payload['payload'].sortGroupCriteria.sortList).toString()
      delete payload['payload'].sortGroupCriteria.sortDirection
      delete payload['payload'].sortGroupCriteria.sortList;
      let array = []
      array.push(payload['payload'].sortGroupCriteria)
      this.gridConfig.setColumnConfigurations(
        this.gridOptions.columnApi.getColumnState()
      );
      if (this.gridOptions['paginationChanged'] instanceof Function) {
        this.gridOptions['paginationChanged'](
          payload['payload'].pagingConfiguration.pageNumber,
          payload['payload'].pagingConfiguration.pageSize,
          array
        );
      }
    }
    else {
      this.gridConfig.setColumnConfigurations(
        this.gridOptions.columnApi.getColumnState()
      );
      if (this.gridOptions['paginationChanged'] instanceof Function) {
        this.gridOptions['paginationChanged'](
          payload['payload'].pagingConfiguration.pageNumber,
          payload['payload'].pagingConfiguration.pageSize);
      }
    }
    // alert(JSON.stringify(payload['payload']));
  }

  isRowSelectable(rowNode) {
    if (this.gridConfig instanceof Object) {
      if (this.gridConfig.hideCheckBoxForRowSelection instanceof Function) {
        try {
          if (rowNode instanceof Object) {
            const restrictedFlag = this.gridConfig.hideCheckBoxForRowSelection(
              rowNode
            );
            return restrictedFlag;
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  }

  getRowNodeId(data) {
    // return data.id;
    if (this.gridConfig instanceof Object) {
      if (this.gridConfig.getRowNodeId instanceof Function) {
        try {
          if (data instanceof Object) {
            const rowNodeId = this.gridConfig.rowNodeIdFn(data);
            // console.log(rowNodeId);
            return rowNodeId;
          }
        } catch (e) {
          // console.log(e);
        }
      }
    }
  }
  onColumnsChanged(event) {
    const currState = this.gridOptions.columnApi.getColumnState();
    currState.forEach(col => delete col.width);
    if (JSON.stringify(currState) === JSON.stringify(this.prevState)) {
      return;
    }
    if (this.gridColumnsChange && this.gridConfig.getColumnConfigurations()) {
      this.gridOptions.columnApi.setColumnState(
        this.gridConfig.getColumnConfigurations()
      );
      // clear the config
      this.gridColumnsChange = false;
      this.gridConfig.setColumnConfigurations(null);
    } else {
      this.columnChanged.emit(this.prevState);
    }
    this.prevState = JSON.parse(JSON.stringify(currState));
  }
  gridColumnsChanged(event) {
    this.gridColumnsChange = true;
  }

  cellColorClass(params) {
    try {
      if (params instanceof Object) {
        return this.gridConfig.cellColorClassFn(params);
      }
    } catch (e) {
      console.log(e);
    }
  }

  enableEditMode(params) {
    try {
      if (params instanceof Object) {
        return this.gridConfig.enableEditModeFn(params);
      }
    } catch (e) {
      console.log(e);
    }
  }
  enableCellMouseOver(params) {
    try {
      if (params instanceof Object) {
        return this.gridConfig.onCellMouseOver(params);
      }
    } catch (e) {
      console.log(e);
    }
  }
  enableCellMouseOut(params) {
    try {
      if (params instanceof Object) {
        return this.gridConfig.onCellMouseOut(params);
      }
    } catch (e) {
      console.log(e);
    }
  }

  lookupValue(mappings, key) {
    console.log(mappings, key);
    return key;
  }

  lookupKey(mappings, value) {
    return value;
  }
  formatDate(params) {
    return DateUtils.formatWithDefaultTimeZone(params.value) || '';
  }

  public onCellValueChanged(params) {
    if (this.gridConfig instanceof Object) {
      if (this.gridConfig.getCellEditValidator() instanceof Function) {
        try {
          if (params instanceof Object) {
            this.gridConfig.cellEditValidator(params);
          }
        } catch (e) {
          // console.log(e);
        }
      } else {
        if (params.oldValue !== params.newValue) {
          const keyVariable = this.gridConfig.getPrimaryId();
          // console.log(params);
          const obj = {};
          obj['field'] = params.colDef.field;
          obj['modifiedRow'] = params.data;
          this.dataStorageService.setEditedFieldsByRow(obj);
          this.dataStorageService.setEditedCells(
            'C' + params.colDef.field + ':' + params.data[keyVariable] + 'C'
          );
          this.sharedService.setLocalStorageData(
            CUSTOMER_CELLS,
            this.dataStorageService.getEditedCells()
          );
          params.colDef['cellStyle'] = function (params) {
            if (
              localStorage
                .getItem(CUSTOMER_CELLS)
                .indexOf(
                  'C' +
                  params.colDef.field +
                  ':' +
                  params.data[keyVariable] +
                  'C'
                ) > 0
            ) {
              return {
                'background-color': '#0000ff'
                // 'border': '2px solid #1c4e83'
              };
            } else {
              return {
                'background-color': '#aaaaff'
              };
            }
          };
          params.api.redrawRows();
        }
      }
    }
  }

  public onCellEditStart(params) {
    this.cellEditStarted.emit(params);
  }
  onRowDragMove(event) {
    if (this.customOptions && this.customOptions['isReorderingEnabled']) {
      const movingNode = event.node;
      const overNode = event.overNode;
      const rowNeedsToMove = movingNode !== overNode;
      if (rowNeedsToMove) {
        const movingData = movingNode.data;
        const overData = overNode.data;
        const fromIndex = this.updatedRowOrder.indexOf(movingData);
        const toIndex = this.updatedRowOrder.indexOf(overData);
        const newStore = this.updatedRowOrder.slice();
        this.moveInArray(newStore, fromIndex, toIndex);
        this.updatedRowOrder = newStore;
        this.gridApi.setRowData(newStore);
        this.gridApi.clearRangeSelection();
      }
    }
  }

  moveInArray(arr, fromIndex, toIndex) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  getUpdatedRowOrder() {
    return this.updatedRowOrder;
  }

  assignDatePicker(headerObject: ColDef, responsesServiceHeader: any) {
    if (
      responsesServiceHeader['context'] === 'PREVIEW' &&
      responsesServiceHeader.hasOwnProperty('displayModifier') &&
      responsesServiceHeader.displayModifier instanceof Object
    ) {
      if (
        responsesServiceHeader.displayModifier.hasOwnProperty('dateModifier') &&
        typeof responsesServiceHeader.supDispModifier.dateModifier === 'string'
      ) {
        headerObject.editable = false;
        delete headerObject.cellRenderer;
        delete headerObject.cellRendererFramework;
        delete headerObject.valueFormatter;
        headerObject.tooltipField = HEADER_OBJECT.NO_VALUE;
        if (responsesServiceHeader.custEdit === 1) {
          headerObject.cellRendererFramework = DatePickerRendererComponent;
          headerObject.cellStyle = { overflow: 'visible!important;' };
        } else {
          headerObject.cellRenderer = (params: ICellRendererParams) => {
            return DateUtils.formatWithDefaultTimeZone(
              params.value,
              responsesServiceHeader.displayModifier['dateModifier']
            );
          };
        }
      }
    }

    /**
     * Date Changes
     * End
     */
  }
  assignDropdownInPreview(headerObject: ColDef, responsesServiceHeader: any) {
    if (
      responsesServiceHeader['context'] === 'PREVIEW' &&
      responsesServiceHeader.hasOwnProperty('displayModifier') &&
      responsesServiceHeader.displayModifier instanceof Object
    )

      if (
        responsesServiceHeader['displayModifier'] !== null &&
        responsesServiceHeader['displayModifier'] instanceof Object &&
        responsesServiceHeader['datatype'] === 'INT' &&
        responsesServiceHeader.custEdit === 1
      ) {
        if (
          responsesServiceHeader['displayModifier'][
          'stringModifier'
          ] instanceof Array
        ) {
          headerObject.editable = false;
          headerObject.tooltipField = HEADER_OBJECT.NO_VALUE;
          this.stringModifierMap[responsesServiceHeader.dbColumnName] =
            responsesServiceHeader.supDispModifier.stringModifier;
          this.dataStorageService.setCommodityStringModifier(
            this.stringModifierMap
          );
          headerObject.cellRendererFramework = GenericDetailDropdownComponent;
        }
      }
    /**
     * Date Changes
     * End
     */
  }

  /**
   * Post RFX Creation Changes
   * Start
   */

  checkPreviewEditableFeature(
    headerObject: ColDef,
    responsesServiceHeader: any
  ) {
    if (
      responsesServiceHeader['context'] === 'PREVIEW' &&
      headerObject.hasOwnProperty('editable') &&
      headerObject.editable === true
    ) {
      headerObject.editable = (params: ICellRendererParams) => {
        if (typeof params.data.STATUS_ID === 'string') {
          params.data.STATUS_ID = + params.data.STATUS_ID;
        }
        if (params.data.STATUS_ID !== STATUS_OBJECT.DRAFT) {
          return false;
        } else {
          return true;
        }
      };
    }
  }

  /**
   * Post RFX Creation Changes
   * End
   */

  sort(event): void {
    const fieldName = event.api.getSortModel();
    this.gridSortedData.emit(fieldName)
  }
}
