import { Component, OnDestroy, OnInit } from '@angular/core';
import { ICellRendererParams, ColDef } from '@ag-grid-community/core';
import { SubscriptionLike } from 'rxjs';
import { PayLoad } from '../../../../common/models/payload.model';
import { RequestData } from '../../../../common/models/request.model';
import { SubscriptionService } from '../../../../common/subscription.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'child-cell',
  templateUrl: './content-single-select.component.html',
  styleUrls: ['./content-single-select.component.sass']
})
export class ContentSingleSelectComponent implements OnInit, OnDestroy {
  public params: ICellRendererParams;
  public requestData: RequestData;
  public resetDropdown = false;
  public customSearch = {
    displayKey: 'name',
    searchKeys: ['name']
  };
  private valueChanged$: SubscriptionLike;
  private uniqueId = Math.random();
  private colDef: ColDef;

  constructor(private subscriptionService: SubscriptionService) {}

  public agInit(params: any): void {
    this.params = params;
    this.colDef = this.params.column.getColDef();
    if (this.colDef.cellRendererParams.options$ instanceof BehaviorSubject) {
      this.colDef.cellRendererParams.options$.subscribe(res => {
        if (res instanceof Object && res.result instanceof Array) {
          this.loadOptions();
          if (
            this.colDef.cellRendererParams.options$ instanceof BehaviorSubject
          ) {
            this.colDef.cellRendererParams.options$.unsubscribe();
          }
        }
      });
    }
    this.loadOptions();
  }

  public stopEditing() {
    this.params.api.stopEditing(false);
  }

  public onValueChanged(e) {
    // this.params.api.updateRowData(this.params.data);
    this.params.node.setDataValue(this.params.colDef.field, this.params.value);
    this.stopEditing();
  }

  public ngOnInit() {
    this.valueChanged$ = this.subscriptionService
      .getAutoSearchText()
      .subscribe(i => {
        if (
          i instanceof Object &&
          i.id === this.uniqueId &&
          i.val instanceof Array &&
          i.val.length === 1
        ) {
          this.params.value = i.val[0].value.name;
          this.onValueChanged(i.val[0]);
        }
      });
  }

  public loadOptions() {
    const payLoad = new PayLoad();
    payLoad.isWildCardSearch = true;
    this.requestData = new RequestData();
    this.requestData.placeHolder = 'Enter Value';
    this.requestData.type = 'multi';
    this.requestData.options = this.colDef.cellRendererParams.options;
    this.requestData.typeOfSelectSingle = true;
    this.requestData.id = this.uniqueId;
    this.requestData.payload = payLoad;
    if (typeof this.params.value === 'string') {
      let valueExists = false;
      if (this.colDef.cellRendererParams.options instanceof Array) {
        valueExists = this.colDef.cellRendererParams.options.find(
          i => i.name === this.params.value
        );
        if (valueExists) {
          // delete this.params.node.data[this.colDef.field + 'Error'];
          // delete this.params.node.data.isError;
          this.requestData.defaultSelection = {
            value: this.params.value
          };
        } else {
          // this.params.node.data[this.colDef.field + 'Error'] = true;
          // this.params.node.data.isError = true;
        }
      }
    }
  }

  public ngOnDestroy() {
    if (
      this.valueChanged$ instanceof Object &&
      this.valueChanged$.unsubscribe instanceof Function
    ) {
      this.valueChanged$.unsubscribe();
    }
  }
}
