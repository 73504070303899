import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'app-responsive-container',
  templateUrl: './responsive-container.component.html',
  styleUrls: ['./responsive-container.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class ResponsiveContainerComponent implements OnInit {
  @Input() configuration;

  constructor() { }

  ngOnInit() {
  }



}
