import { AdhocCheckboxComponent } from 'app/modules/srfq/create/adhoc-rfx/adhoc-checkbox/adhoc-checkbox.component';

export const VALIDATIONS_OBJECT = {
  IS_RELATION_EXISTS: 'IS_RELATION_EXISTS',
  IS_VALID: 'IS_VALID',
  IS_DUPLICATE_ENTRY: 'IS_DUPLICATE_ENTRY'
};

export const ADHOC_RFX= 'ADHOC';

export const VALIDATION_MESSAGES = {
  resolveErrorMessage:
    'There are some errors highlighted below. Please resolve them and click on save.',
  combinationExistsMessages:
    'Same combination of data already exists. Please correct them and click on save.',
  unsavedChangesMessage:
    'You didn\'t save newly created item(s). Do you want to continue.?',
  adhocSuccessMessage: 'Adhoc Items Added Successfully',
  acceptAllMessage: 'Do you want to accept the selected items ?',
  rejectAllMessage: 'Do you want to reject the selected items ?',
  resetMessage: 'Do you want to discard the changes ?',
  refreshMessage: 'Do you want to refresh ?',
  splitErrorMessage: 'Sum of Splits must be 100',
  forecastErrorMessage: 'Same forecast is allowed at CPN Level',
  deleteIEMessage: 'Are you sure you want to delete this RFI?',
  deleteRFQMessage: 'Are you sure you want to delete the RFX?',
  discardAddedScope: 'Are you sure you want to discard the Added Scope?',
  deleteDraft: 'This event was created in a previous quarter and can no longer be used. Please delete and create a new event.',
  onNavigationMessage : 'There are unsaved changes in this RFQ. Are you sure you want to navigate away from this page?',
  discardMessage : 'There are unsaved changes, Are you sure you want to discard?',
  deletePWBRFQ: 'There is unsent RFX. This action will delete the RFX . Are you sure you want to perform this action? '
};

export const MESSAGES = {
  showForecastMessage: 'Please click on Forecast update',
  uploadFileMessage: 'You have uploaded file , please click on send',
  updateSplitMessage: 'Please click on update split to update it',
  forecastErrorMessage: 'Forecast Should not be Zero'
};
export const HEADER_ICON_OBJECT = {
  headerName: '',
  field: 'icon',
  cellRendererFramework: AdhocCheckboxComponent,
  width: 60
};

export const BACKGROUND_COLOR_OBJECT = {
  editableBackground: {
    'background-color': '#F2F3F4'
  },
  duplicateBackground: {
    'background-color': '#FFD177'
  },
  duplicateBackgroundwithError: {
    'background-color': '#FFD177',
    border: '2px solid #E64560 !important'
  },
  errorBackground: {
    border: '1px solid #E64560 !important'
  },
  modifiedBackground: {
    'background-color': '#F2F3F4',
    border: '2px solid #64CCEF !important'
  },
  errorWithEditable: {
    'background-color': '#F2F3F4',
    border: '1px solid #E64560 !important'
  },
  forecastChangedBackground: {
    'background-color': '#FFD066',
    border: '1px solid #FFD066 !important',
    color: '#fff'
  }
};

export const HEADER_OBJECT = {
  ICON: 'icon',
  UID: 'UID',
  CPN: 'CPN',
  MPN: 'MPN',
  STATUS: 'STATUS_ID',
  CPN_CM_ID: 'CPN_CM_ID',
  MANUFACTURER: 'MANUFACTURER',
  CPN_DESC: 'CPN_DESC',
  GLOBAL_PRICE: 'GLOBAL_PRICE',
  CM_ODM: 'CM_ODM',
  COMPONENT_USAGE: 'COMPONENT_USAGE',
  PRODUCT: 'PRODUCT',
  SUPPLIER: 'SUPPLIER',
  ALL: 'ALL',
  FIELD: 'field',
  VALUE: 'value',
  IS_FIRST: 'isFirst',
  DISP_VALUE: 'dispValue',
  NO_VALUE: '',
  IS_ADHOC_ITEM: 'IS_ADHOC_ITEM',
  _ERROR: '_ERROR',
  DISPLAY_MODIFIER: 'displayModifier',
  ADHOC_VISIBLE: 'adhocVisible',
  ADHOC_CONFIG: 'adhocConfig',
  DISPLAY_NAME: 'displayName',
  DB_COLUMN_NAME: 'dbColumnName',
  IS_EDITABLE: 'isEditable',
  CELL_HTML_TYPE: 'cellHtmlType',
  CELL_RENDERER: 'cellRenderer',
  CELL_RENDERER_FRAMEWORK: 'cellRendererFramework',
  CELL_EDITOR_FRAMEWORK: 'cellEditorFramework',
  HEADER_EDITABLE: 'editable',
  CELL_STYLE: 'cellStyle',
  DATA_TYPE: 'datatype',
  SINGLESELECT: 'singleselect',
  MULTISELECT: 'multiselect',
  CMSPKID: 'cmspkId',
  ACCEPT: 'Accept',
  REJECT: 'Reject',
  VALIDATIONS: 'validations',
  _VALID: '_VALID',
  _ADHOC_ERROR: '_ADHOC_ERROR',
  MANDATORY_IDENTIFIER: ' * ',
};
