import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { countryListConfiguration, stateListConfiguration, cityListConfiguration, siteListConfiguration, supplierListConfiguration } from './site-locations.constants';
import { cloneDeep } from 'lodash'

export interface LocationDetails {
  country: string
  state: string
  city: string
  siteImpacted: string
  vendorImpacted: string
  region: string
  continent: string
  latitude: string
  longitude: string
}

@Component({
  selector: 'cprt-site-locations',
  templateUrl: './site-locations.component.html',
  styleUrls: ['./site-locations.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SiteLocationsComponent),
      multi: true
    }
  ]
})
export class SiteLocationsComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input('locationList') locationList = []
  @Input('vendorImpacted') vendorImpacted = []
  @Input('isExpanded') isExpanded = false

  @Output() onConfirmLocationDetails: EventEmitter<any> = new EventEmitter<any>()
  @Output() onExpandToggle: EventEmitter<boolean> = new EventEmitter<boolean>()
  currentLocationList = []
  locations = []
  showLoader = true
  disableConfirm = true
  loaderConfig = {
    backdropBackgroundColour: 'rgba(255,255,255,0.3)',
    fullScreenBackdrop: 'true',
    primaryColour: '#1C4E84',
    secondaryColour: '#1C4E84',
    tertiaryColour: '#1C4E84'
  }

  propagateChange = (_: any) => { }
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['vendorImpacted'] && !changes['locationList']) {
      return
    }
    this.currentLocationList = JSON.parse(JSON.stringify(this.locationList))
    if (this.currentLocationList.length > 0) {
      this.locations = []
      this.showLoader = true
      this.getLocationDetails()
    } else {
      this.initializeLocations()
      setTimeout(() => {
        this.showLoader = false
      }, 100)
    }
  }

  toggleExpansion() {
    this.isExpanded = !this.isExpanded
    this.onExpandToggle.emit(this.isExpanded)
  }

  initializeLocations() {
    this.locations = []
    const location = JSON.parse(JSON.stringify(this.getLocationObject()))
    this.locations.push(location)
  }

  setSelectedValue(filterConfig, value) {
    filterConfig.defaultItemSelection = true
    filterConfig.defaultSelectedItem = value
  }

  setDefaultParameter(config, paramValue) {
    config.defaultParameter = paramValue
    config.isDisable = true
  }

  getLocationObject() {
    return {
      countryObject: {
        name: 'country',
        config: JSON.parse(JSON.stringify(countryListConfiguration)),
        resetValues: this.generateRandomKey()
      },
      stateObject: {
        name: 'state',
        config: JSON.parse(JSON.stringify(stateListConfiguration)),
        resetValues: this.generateRandomKey()
      },
      cityObject: {
        name: 'city',
        config: JSON.parse(JSON.stringify(cityListConfiguration)),
        resetValues: this.generateRandomKey()
      },
      siteObject: {
        name: 'siteImpacted',
        config: JSON.parse(JSON.stringify(siteListConfiguration)),
        resetValues: this.generateRandomKey()
      },
      supplierObject: {
        name: 'vendorImpacted',
        config: JSON.parse(JSON.stringify(supplierListConfiguration)),
        resetValues: this.generateRandomKey()
      },
      properties: this.initLocationDetails({} as LocationDetails),
      mandatoryFilledCheck: false
    }
  }

  getLocationDetails() {
    this.currentLocationList.forEach((locationData) => {
      const location = JSON.parse(JSON.stringify(this.getLocationObject()))
      location.properties = { ...locationData }
      location.mandatoryFilledCheck = true

      this.setSelectedValue(location.countryObject.config.filterConfig, locationData.country)
      this.setSelectedValue(location.stateObject.config.filterConfig, locationData.state)
      this.setSelectedValue(location.cityObject.config.filterConfig, locationData.city)
      this.setSelectedValue(location.siteObject.config.filterConfig, locationData.siteImpacted)
      locationData.vendorImpacted = locationData.vendorImpacted || []
      if (locationData.vendorImpacted.length) {
        this.setSelectedValue(location.supplierObject.config.filterConfig, locationData.vendorImpacted)
      }

      this.setDefaultParameter(location.stateObject.config, {
        country: locationData.country,
      })
      this.setDefaultParameter(location.cityObject.config, {
        country: locationData.state,
      })
      this.setDefaultParameter(location.siteObject.config, {
        country: locationData.country,
        state: locationData.state,
        city: locationData.city,
        mfr: this.vendorImpacted
      })
      this.setDefaultParameter(location.supplierObject.config, {
        country: locationData.country,
        state: locationData.state,
        city: locationData.city
      })
      this.locations.push(JSON.parse(JSON.stringify(location)))
    })
    setTimeout(() => {
      this.showLoader = false
    }, 100)
  }

  initLocationDetails(location) {
    location.country = null
    location.state = null
    location.city = null
    location.siteImpacted = null
    location.region = null
    location.continent = null
    location.latitude = null
    location.longitude = null
    location.vendorImpacted = null
    return location
  }

  onLocationPropSelect(params, locationRow?, locationIndex?) {
    if (locationRow && locationIndex !== undefined) {
      const selectedValues = params.selectedItem
        ? (params.selectedItem.val || null)
        : params.selectedItems
          ? params.selectedItems
          : null
      // if (locationRow.properties.hasOwnProperty(params.type)) {
        locationRow.properties[params.type] = selectedValues
      // }
      switch (params.type) {
        case 'country':
          locationRow.stateObject.config.defaultParameter = {
            country: selectedValues,
          }
          locationRow.supplierObject.config.defaultParameter = {
            country: selectedValues,
          }
          locationRow.stateObject.config.isDisable = false
          locationRow.stateObject.resetValues = this.generateRandomKey()
          locationRow.cityObject.resetValues = this.generateRandomKey()
          locationRow.siteObject.resetValues = this.generateRandomKey()
          locationRow.supplierObject.resetValues = this.generateRandomKey()
          break
        case 'state':
          locationRow.cityObject.config.defaultParameter = {
            state: selectedValues,
          }
          locationRow.supplierObject.config.defaultParameter = {
            country: locationRow.properties.country,
            state: selectedValues,
          }
          locationRow.cityObject.config.isDisable = false
          locationRow.cityObject.resetValues = this.generateRandomKey()
          locationRow.siteObject.resetValues = this.generateRandomKey()
          locationRow.supplierObject.resetValues = this.generateRandomKey()
          break
        case 'city':
          locationRow.siteObject.config.defaultParameter = {
            country: locationRow.properties.country,
            state: locationRow.properties.state,
            city: locationRow.properties.city,
            mfr: this.vendorImpacted
          }
          locationRow.supplierObject.config.defaultParameter = {
            country: locationRow.properties.country,
            state: locationRow.properties.state,
            city: locationRow.properties.city
          }
          locationRow.siteObject.config.isDisable = false
          locationRow.supplierObject.config.isDisable = false
          locationRow.siteObject.resetValues = this.generateRandomKey()
          locationRow.supplierObject.resetValues = this.generateRandomKey()
          break
      }
      this.locations[locationIndex].properties = JSON.parse(
        JSON.stringify(locationRow.properties)
      )
      this.locations[locationIndex].mandatoryFilledCheck
        = !!locationRow.properties.country && !!locationRow.properties.city && !!locationRow.properties.state && !!locationRow.properties.siteImpacted
          ? true
          : false
      this.mandatoryFilledCheck()
    }
  }

  mandatoryFilledCheck() {
    const validLocations = this.locations.filter(location => location.mandatoryFilledCheck).map(location => location.properties)
    const firstLocationRow = this.locations[0]
    const oldLocationExist = this.locationList.length && this.locations.length === 1 &&
      (!firstLocationRow.properties.country && !firstLocationRow.properties.city && !firstLocationRow.properties.state && !firstLocationRow.properties.siteImpacted)
    this.disableConfirm
      = (this.locations.length === validLocations.length)
        ? false
        : !oldLocationExist
  }

  generateRandomKey() {
    const crypto = window.crypto
    const array = new Uint32Array(1)
    return crypto.getRandomValues(array)[0]
  }

  onClearAllLocations() {
    this.locations = []
    this.initializeLocations()
    this.mandatoryFilledCheck()
  }

  addLocation(rowIndex, event) {
    event.stopPropagation()
    this.locations.push(JSON.parse(JSON.stringify(this.getLocationObject())))
    this.mandatoryFilledCheck()
  }

  deleteLocation(rowIndex, selectedLocation, event) {
    event.stopPropagation()
    this.locations.splice(rowIndex, 1)
    this.mandatoryFilledCheck()
  }

  onConfirmLocations() {
    const addedLocations = []
    this.locations.forEach(location => {
      if (location.mandatoryFilledCheck) {
        addedLocations.push(location.properties)
      }
    })
    this.onConfirmLocationDetails.emit(JSON.parse(JSON.stringify(addedLocations)))
    this.propagateChange(cloneDeep(addedLocations))
  }

  /*  ControlValueAccessorFunctionImplementation */
  writeValue(value: any) {
    return value
  }

  registerOnChange(fn) {
    this.propagateChange = fn
  }

  registerOnTouched() { }

}
