import * as _ from 'lodash';
import { ObjectUtils } from '../../../common/utills/ObjectUtils.service';
import { updateUserAction } from './tree-track-action';

export const onTreeCellValidation = (
  validationObject,
  colDef,
  validationObj?
) => {
  const NUMERIC_RANGE = 'NUMERIC_RANGE';
  const INTEGER_RANGE = 'INTEGER_RANGE';
  // validation type;
  const types = {
    ALLOW_BLANKS: 'allowblanks',
    MIN: 'min',
    MAX: 'max',
    ALLOW_ALPHA_NUMERIC: 'allowalphanumeric',
    ALLOW_SPECIAL_CHARACTERS: 'allowspecialcharacters',
    FORMAT: 'format',
    POSSIBLE_VALUES: 'possiblevalues',
    TOTAL: 'total',
    MIN_TOTAL: 'min_total'
  };

  let valid = true;
  let failedValidationType;
  let failedValidations = [];
  let validationsList = colDef.validations;

  if (validationObj instanceof Array && validationObj.length > 0) {
    validationsList = validationObj;

    Object.keys(validationObject.node.parent.childrenMapped).forEach((key) => {
      if (validationObject.node.parent.childrenMapped[key]) {
        validationObject.node.parent.childrenMapped[key].data.info = {};
        validationObject.node.parent.childrenMapped[key].data.info[
          validationObject.colDef.field
        ] = {};
        if (validationObject.node.parent.childrenMapped[key].data.validations) {
          if (
            validationObject.node.parent.childrenMapped[key].data.validations[
            validationObject.colDef.field
            ]
          ) {
            validationObject.node.parent.childrenMapped[key].data.info[
              validationObject.colDef.field
            ].validations =
              validationObject.node.parent.childrenMapped[key].data.validations[
              validationObject.colDef.field
              ];
          } else {
            validationObject.node.parent.childrenMapped[key].data.info[
              validationObject.colDef.field
            ].validations = [];
          }
        }
      }
    });
  }
  (validationsList || []).forEach((eachValidation) => {
    if (eachValidation) {
      try {
        failedValidationType = ObjectUtils.copy(eachValidation);
      } catch (e) {
        console.log(e);
      }

      if (eachValidation['info']) {
        const validationOptions = Object.keys(eachValidation['info']);
        const targetInfo = eachValidation['info'] || {};
        const targetType = eachValidation.type || ' ';
        (validationOptions || []).forEach((validationType) => {
          switch (validationType.toLowerCase()) {
            case types.ALLOW_BLANKS:
              if (!targetInfo[validationType]) {
                if (
                  typeof validationObject.value === 'number' ||
                  typeof validationObject.value === 'string'
                ) {
                  valid =
                    validationObject.value.toString().trim().length === 0
                      ? false
                      : true;
                }
              }
              break;
            case types.MIN:
              if (
                targetType === INTEGER_RANGE ||
                targetType === NUMERIC_RANGE
              ) {
                if (targetType === NUMERIC_RANGE) {
                  if (
                    failedValidationType.info &&
                    typeof failedValidationType.info.minIncluded === 'boolean' &&
                    failedValidationType.info.minIncluded
                  ) {
                    valid =
                      +validationObject.value >= +targetInfo[validationType];

                    if (!valid && validationObject.colDef.field === 'split') {
                      failedValidationType.errorMessage =
                        validationObject.colDef.field + `should be a number between ${targetInfo.min} and ${targetInfo.max}`;
                      break;
                    }
                  } else {
                    valid =
                      +validationObject.value > +targetInfo[validationType];
                  }
                } else {
                  valid =
                    +validationObject.value >= +targetInfo[validationType];
                }
                if (targetType === INTEGER_RANGE) {
                  if (
                    validationObject.value !== null &&
                    validationObject.value !== undefined &&
                    validationObject.value.indexOf('.') !== -1 &&
                    !failedValidations.length
                  ) {
                    const decimalVal = validationObject.value.split('.');
                    if (decimalVal instanceof Array && +decimalVal[1] === 0) {
                      break;
                    }
                    valid = false;
                    failedValidationType.errorMessage =
                      validationObject.colDef.field + ' should be an Integer';
                    break;
                  }
                }
              } else {
                if (
                  failedValidationType.info &&
                  typeof failedValidationType.info.minIncluded === 'boolean' &&
                  failedValidationType.info.minIncluded
                ) {
                  valid =
                    +validationObject.value >= +targetInfo[validationType];
                } else {
                  valid = +validationObject.value > +targetInfo[validationType];
                }
              }

              failedValidationType.errorMessage = failedValidationType.errorMessage.replace(
                '{' + types.MIN + '}',
                failedValidationType.info[types.MIN]
              );

              if (
                failedValidationType.info &&
                typeof failedValidationType.info.allowBlanks === 'boolean' &&
                failedValidationType.info.allowBlanks
              ) {
                if (!validationObject.value) {
                  valid = !validationObject.value;
                }
              }
              break;
            case types.MAX:
              if (targetInfo[validationType]) {
                if (
                  targetType === INTEGER_RANGE ||
                  targetType === NUMERIC_RANGE
                ) {
                  if (targetType === NUMERIC_RANGE) {
                    if (
                      failedValidationType.info &&
                      typeof failedValidationType.info.maxIncluded ===
                      'boolean' &&
                      failedValidationType.info.maxIncluded
                    ) {
                      valid =
                        +validationObject.value <= +targetInfo[validationType];
                      if (!valid && validationObject.colDef.field === 'split') {
                        failedValidationType.errorMessage =
                          validationObject.colDef.field + `should be a number between ${targetInfo.min} and ${targetInfo.max}`;
                        break;
                      }
                    } else {
                      valid =
                        +validationObject.value < +targetInfo[validationType];
                    }
                  } else {
                    valid =
                      +validationObject.value <= +targetInfo[validationType];
                  }
                  if (targetType === INTEGER_RANGE) {
                    if (
                      validationObject.value !== null &&
                      validationObject.value !== undefined &&
                      validationObject.value.indexOf('.') !== -1 &&
                      !failedValidations.length
                    ) {
                      const decimalVal = validationObject.value.split('.');
                      if (decimalVal instanceof Array && +decimalVal[1] === 0) {
                        break;
                      }
                      valid = false;
                      failedValidationType.errorMessage =
                        validationObject.colDef.field + ' should be an Integer';
                      break;
                    }
                  }
                } else {
                  if (
                    failedValidationType.info &&
                    typeof failedValidationType.info.maxIncluded === 'boolean' &&
                    failedValidationType.info.maxIncluded
                  ) {
                    if (
                      typeof validationObject.value === 'number' ||
                      typeof validationObject.value === 'string'
                    ) {
                      valid =
                        validationObject.value.toString().trim().length <=
                        targetInfo[validationType];
                    }
                  } else {
                    if (
                      typeof validationObject.value === 'number' ||
                      typeof validationObject.value === 'string'
                    ) {
                      valid =
                        validationObject.value.toString().trim().length <
                        targetInfo[validationType];
                    }
                  }
                }
              }
              failedValidationType.errorMessage = failedValidationType.errorMessage.replace(
                '{' + types.MAX + '}',
                failedValidationType.info[types.MAX]
              );
              if (
                failedValidationType.info &&
                typeof failedValidationType.info.allowBlanks === 'boolean'
              ) {
                if (!validationObject.value) {
                  valid = !validationObject.value;
                }
              }
              break;
            case types.ALLOW_ALPHA_NUMERIC:
              if (targetInfo[validationType] === false) {
                const format = /^[A-Za-z0-9]+$/i;
                valid = !format.test(validationObject.value);
              }
              break;
            case types.ALLOW_SPECIAL_CHARACTERS:
              if (targetInfo[validationType] === false) {
                const format = /[~`!@#$%^&*<>?/,.:;'"()\-+=|{}\[\]\\\_]/;
                valid = !format.test(validationObject.value);
              }
              break;
            case types.FORMAT: {
              if (targetType === 'DATE_FORMAT') {
                // regex to match MM/DD/YYYY format
                const dateRegex = /^((((0[13578])|([13578])|(1[02]))[\/](([1-9])|([0-2][0-9])|(3[01])))|(((0[469])|([469])|(11))[\/](([1-9])|([0-2][0-9])|(30)))|((2|02)[\/](([1-9])|([0-2][0-9]))))[\/]\d{4}$|^\d{4}$/;
                valid = dateRegex.test(validationObject.value);
              }
              break;
            }
            case types.POSSIBLE_VALUES: {
              if (targetInfo[validationType] instanceof Array) {
                valid = targetInfo[validationType].includes(
                  validationObject.value
                );
              }
              break;
            }
            case types.TOTAL:
            case types.MIN_TOTAL: {
              if (
                targetType === 'SPLIT' &&
                validationObject &&
                validationObject.node &&
                validationObject.node.parent.childrenMapped instanceof Object
              ) {
                let total = 0;
                const intIndexInFailedValidations = failedValidations.findIndex(
                  (i) => i.type === INTEGER_RANGE || i.type === NUMERIC_RANGE
                );
                let blankSpaceValidation = true;
                if (
                  validationObject.node.data &&
                  validationObject.node.data.componentType === 'SOURCE'
                ) {
                  blankSpaceValidation = Object.values(
                    validationObject.node.parent.childrenMapped
                  ).every(
                    (j) => {
                      const val = _.get(j['data'], validationObject.colDef.field)
                      return val && val.trim().length > 0
                    });
                }
                let splitSumZeroValidation = true
                if (
                  (intIndexInFailedValidations === -1 ||
                    validationObject.value.trim().length === 0) &&
                  blankSpaceValidation
                ) {
                  // tslint:disable-next-line:forin
                  for (const key in validationObject.node.parent
                    .childrenMapped) {
                    if (
                      validationObject.node.parent.childrenMapped.hasOwnProperty(
                        key
                      )
                    ) {
                      if (
                        validationObject.node.parent.childrenMapped[key].data
                          .info &&
                        validationObject.node.parent.childrenMapped[key].data
                          .info[validationObject.colDef.field]
                      ) {
                        validationObject.node.parent.childrenMapped[
                          key
                        ].data.info[
                          validationObject.colDef.field
                        ].validations = [];
                      }
                      if (
                        validationObject.node.parent.childrenMapped[key].data
                          .componentType === 'MPN' ||
                        validationObject.node.parent.childrenMapped[key].data
                          .componentType === 'SOURCE'
                      ) {
                        const val = isNaN(parseFloat(_.get(validationObject.node.parent.childrenMapped[key].data, validationObject.colDef.field)))
                          ? 0 : parseFloat(_.get(validationObject.node.parent.childrenMapped[key].data, validationObject.colDef.field));
                        total =
                          total + val;

                        total = isNaN(total) ? total : +total.toFixed(8);

                      }
                    }
                  }
                  if(total===0)
                  {
                    splitSumZeroValidation = false
                  }
                }
                if (total !== 100 && splitSumZeroValidation) {
                  valid = false;
                  // tslint:disable-next-line:forin
                  for (const key in validationObject.node.parent
                    .childrenMapped) {
                    if (
                      validationObject.node.parent.childrenMapped.hasOwnProperty(
                        key
                      )
                    ) {
                      if (
                        !validationObject.node.parent.childrenMapped[key].data
                          .info[validationObject.colDef.field]
                      ) {
                        validationObject.node.parent.childrenMapped[
                          key
                        ].data.info[validationObject.colDef.field] = {};
                      }

                      if (
                        validationObject.node.parent.childrenMapped[key].data
                          .info[validationObject.colDef.field]
                          .validations instanceof Array
                      ) {
                        const splitIndex = validationObject.node.parent.childrenMapped[
                          key
                        ].data.info[
                          validationObject.colDef.field
                        ].validations.findIndex((i) => i.type === 'SPLIT');
                        const intIndex = validationObject.node.parent.childrenMapped[
                          key
                        ].data.info[
                          validationObject.colDef.field
                        ].validations.findIndex(
                          (i) => i.type === 'INTEGER_RANGE' || i.type === NUMERIC_RANGE
                        );
                        failedValidationType.errorMessage = failedValidationType.errorMessage.replace(
                          '{' + types.TOTAL + '}',
                          failedValidationType.info[types.TOTAL]
                        );
                        if (intIndex !== -1) {
                          continue;
                        }

                        if (splitIndex !== -1) {
                          valid = true;
                          return;
                        }
                        if (
                          validationObject.node.parent.childrenMapped[key].data
                            .componentType === 'MPN' ||
                          validationObject.node.parent.childrenMapped[key].data
                            .componentType === 'SOURCE'
                        ) {
                          validationObject.node.parent.childrenMapped[
                            key
                          ].data.info[
                            validationObject.colDef.field
                          ].validations.push(failedValidationType);
                        }
                      } else {
                        validationObject.node.parent.childrenMapped[
                          key
                        ].data.info[
                          validationObject.colDef.field
                        ].validations = [];
                        failedValidationType.errorMessage = failedValidationType.errorMessage.replace(
                          '{' + types.TOTAL + '}',
                          failedValidationType.info[types.TOTAL]
                        );
                        if (
                          validationObject.node.parent.childrenMapped[key].data
                            .componentType === 'MPN' ||
                          validationObject.node.parent.childrenMapped[key].data
                            .componentType === 'SOURCE'
                        ) {
                          validationObject.node.parent.childrenMapped[
                            key
                          ].data.info[
                            validationObject.colDef.field
                          ].validations.push(failedValidationType);
                        }
                      }
                      if (validationObj) {
                        validationObject.node.parent.childrenMapped[
                          key
                        ].data = copyInfoValidationtoValidations(
                          validationObject.node.parent.childrenMapped[key].data,
                          validationObject.colDef.field
                        );
                      }
                      updateUserAction({
                        type: 'update',
                        data:
                          validationObject.node.parent.childrenMapped[key].data
                      });
                      if (
                        validationObject.node.parent.childrenMapped[key].data
                          .componentType === 'MPN' &&
                        validationObject.api
                      ) {
                        validationObject.api.redrawRows({
                          rowNodes: [
                            validationObject.api.getRowNode(
                              validationObject.node.parent.childrenMapped[key]
                                .data.id
                            )
                          ]
                        });
                      }
                    }
                    if (intIndexInFailedValidations === -1) {
                      valid = null;
                    } else {
                      valid = true;
                    }
                  }
                }
                if (total === 100 || !splitSumZeroValidation) {
                  // tslint:disable-next-line:forin
                  for (const key in validationObject.node.parent
                    .childrenMapped) {
                    if (
                      validationObject.node.parent.childrenMapped.hasOwnProperty(
                        key
                      )
                    ) {
                      if (
                        validationObject.node.parent.childrenMapped[key].data
                          .info[validationObject.colDef.field] &&
                        validationObject.node.parent.childrenMapped[key].data
                          .info[validationObject.colDef.field]
                          .validations instanceof Array &&
                        validationObject.node.parent.childrenMapped[key].data
                          .info[validationObject.colDef.field].validations
                          .length
                      ) {
                        validationObject.node.parent.childrenMapped[
                          key
                        ].data.info[
                          validationObject.colDef.field
                        ].validations.splice(
                          validationObject.node.parent.childrenMapped[
                            key
                          ].data.info[
                            validationObject.colDef.field
                          ].validations.findIndex((i) => i.type === 'SPLIT'),
                          1
                        );
                      }
                      if (validationObj) {
                        validationObject.node.parent.childrenMapped[
                          key
                        ].data = copyInfoValidationtoValidations(
                          validationObject.node.parent.childrenMapped[key].data,
                          validationObject.colDef.field
                        );
                      }
                      updateUserAction({
                        type: 'update',
                        data:
                          validationObject.node.parent.childrenMapped[key].data
                      });
                      if (
                        validationObject.node.parent.childrenMapped[key].data
                          .componentType === 'MPN' &&
                        validationObject.api
                      ) {
                        validationObject.api.redrawRows({
                          rowNodes: [
                            validationObject.api.getRowNode(
                              validationObject.node.parent.childrenMapped[key]
                                .data.id
                            )
                          ]
                        });
                      }
                    }

                    valid = null;
                  }
                }
              }
            }
          }
          if (valid === null) {
            return;
          }
          if (!valid) {
            failedValidations.push(failedValidationType);
          }
        });
      }
    }
  });
  if (valid === null) {
    return null;
  }
  if (failedValidations.length) {
    failedValidations = _.uniqBy(failedValidations, 'type');
    return failedValidations;
  } else {
    return true;
  }
};

export const copyInfoValidationtoValidations = (targetData, field) => {
  if (
    targetData.info &&
    targetData.info[field] &&
    targetData.info[field]['validations']
  ) {
    if (!(targetData.validations instanceof Object)) {
      targetData.validations = {};
    }
    targetData.validations[field] = targetData.info[field]['validations'];
  }
  return targetData;
};
