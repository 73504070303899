export const GRID_CONSTANT = {
    COLUMN_DEF_DEFAULT_OPTIONS: {
        flex: 1,
        minWidth: 100,
        sortable: true,
        resizable: true,
    },
    PAGE_SIZE: {
        DEFAULT: 2000,
        MID: 3000,
        MAX: 10000
    },
    PAGINATION: {
        DEFAULT: 100,
        MID: 500,
        MAX: 1000
    },
    ROW_SELECTION: {
        SINGLE: 'single',
        MULTIPLE: 'multiple'
    },
    HEADER_HEIGHT: {
        DEFAULT: 30
    },
    SIDEBAR_DEFAULT_CONFIG: {
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                    suppressRowGroups: true,
                    suppressValues: true,
                    suppressPivots: true,
                    suppressPivotMode: true,
                    suppressSideButtons: true,
                },
            },
        ],
    },
    ROW_MODEL_TYPE: {
        INFINITE: 'infinite',
        SERVER_SIDE: 'serverSide'
    }

}
