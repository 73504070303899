import { Moment} from 'moment/moment';
import { TimeAgoPipe } from 'angular2-moment/time-ago.pipe';
import {
  Injectable,
  Pipe,
  PipeTransform,
  ChangeDetectorRef,
  NgZone
} from '@angular/core';
import * as moment from 'moment-timezone-all';
import { DateFormatPipe } from 'angular2-moment/date-format.pipe';
import {CUSTOMER_CURRENT_USER} from '../../shared/services/shared-consts';
// import * as moment from 'moment-timezone';

export class Logger {
  constructor(private component: string, private isLoggingFunc: any) { }

  public log(func: string, message: string, ...args) {
    let loggingFunction = null;
    /*if isLoggingFunc is boolean && isLoggingFunc is true
     * if isLoggingFunc is Function && isLoggingFunc is returning a boolean we are console
     */
    if (
      (typeof this.isLoggingFunc === 'boolean' && this.isLoggingFunc) ||
      (this.isLoggingFunc instanceof Function &&
        typeof this.isLoggingFunc() === 'boolean' &&
        this.isLoggingFunc())
    ) {
      loggingFunction = console.debug;
    }
    /*
    if isLoggingFunc is a custom function which is overridable
    */
    if (
      this.isLoggingFunc instanceof Function &&
      !(typeof this.isLoggingFunc() === 'boolean')
    ) {
      loggingFunction = this.isLoggingFunc;
    }
    if (loggingFunction instanceof Function) {
      loggingFunction.call(
        this,
        `${this.component} - ${func} - ${message}`,
        args
      );
    }
  }
}

@Injectable()
export class DateUtils {
  public static formatString = 'MM/DD/YYYY';
  public static showUTCTimeZone = false;
  // private static formatString = 'DD-MM-YYYY';
  public static formatStringWithTime = 'MM/DD/YYYY HH:mm:ss';

  // private static defaultTimeZone = null;
  private static defaultTimeZone = 'America/Los_Angeles';
  private static userInfo: any;

  private static _debug = false;
  public static set debug(val) {
    this._debug = val;
  }

  public static get debug() {
    return this._debug;
  }

  private static logger = new Logger('DateUtils.service', DateUtils.debug);

  public static format(date: any, format?: any, withTime?: any): any {
    withTime = typeof format === 'boolean' ? format : withTime;
    const formatString =
      typeof format === 'string' && format.toString().trim().length > 0
        ? format
        : withTime
          ? this.formatStringWithTime
          : this.formatString;

    const momentDate: any =
      date instanceof moment && date.isValid() ? date : this.getDate(date);
    if (momentDate instanceof moment && momentDate.isValid() && date !== 0) {
      return momentDate.format(formatString);
    } else if (date === null || date === 0) {
      return '-';
    } else {
      return date;
    }
  }

  public static getDate(date, now?: any) {
    this.logger.log('getDate', `date: ${date}, now: ${now}`);
    if (typeof date === 'number') {
      date = moment(date);
    } else if (date instanceof Date) {
      date = moment(date);
    } else if (date instanceof moment) {
      date = date;
    } else if (typeof date === 'string' && date.toString().trim().length > 0) {
      date = moment(date).isValid() ? moment(date) : null;
      if (!(date instanceof moment)) {
        return false;
      }
    } else {
      if (now !== true) {
        return false;
      }
    }
    if (now ) {
      date = moment.tz(this.getUserTimeZone());
    }
    const browserValue = date.format(this.formatStringWithTime);
    const timezoneValue = date.tz(this.getUserTimeZone());
    this.logger.log('getDate', `browser Time zone value : ${browserValue}`);
    this.logger.log(
      'getDate',
      `default Time zone value : ${timezoneValue.format(
        this.formatStringWithTime
      )}`
    );
    return timezoneValue;
  }

  /**
   *  Price Effective Date Changes
   *  Start
   */

  public static formatWithDefaultTimeZone(
    date: any,
    format?: any,
    withTime?: any
  ): any {
    withTime = typeof format === 'boolean' ? format : withTime;
    const formatString =
      typeof format === 'string' && format.toString().trim().length > 0
        ? format
        : withTime
          ? this.formatStringWithTime
          : this.formatString;

    const momentDate: any =
      date instanceof moment && date.isValid()
        ? date
        : this.getDatewithDefaultTimeZone(date);
    if (momentDate instanceof moment && momentDate.isValid() && date !== 0) {
      return momentDate.format(formatString);
    } else if (date === null || date === 0) {
      return '-';
    } else {
      return date;
    }
  }

  public static getDatewithDefaultTimeZone(date, now?: any) {
    this.logger.log('getDate', `date: ${date}, now: ${now}`);
    if (typeof date === 'number') {
      date = moment(date);
    } else if (date instanceof Date) {
      date = moment(date);
    } else if (date instanceof moment) {
      date = date;
    } else if (typeof date === 'string' && date.toString().trim().length > 0) {
      date = moment(date).isValid() ? moment(date) : null;
      if (!(date instanceof moment)) {
        return false;
      }
    } else {
      if (!now) {
        return false;
      }
    }
    if (now) {
      date = moment.tz('Europe/London');
    }
    const browserValue = date.format(this.formatStringWithTime);
    const timezoneValue = date.tz('Europe/London');
    this.logger.log('getDate', `browser Time zone value : ${browserValue}`);
    this.logger.log(
      'getDate',
      `default Time zone value : ${timezoneValue.format(
        this.formatStringWithTime
      )}`
    );
    return timezoneValue;
  }

  /**
   *  Price Effective Date Changes
   *  End
   */

  public static getUserTimeZone() {
    const userInfo: any = this.userInfo || this.getUserInformation();
    this.logger.log('getUserTimeZone', 'userInfo : ', userInfo);
       let timeZone = this.defaultTimeZone;
    if (userInfo instanceof Object) {
      if (
        typeof userInfo.timeZone === 'string' &&
        userInfo.timeZone.length > 0
      ) {
        timeZone = userInfo.timeZone;
        this.logger.log(
          'getUserTimeZone',
          'timeZone from userInfo : ',
          timeZone
        );
      }
    }
    if (
      !(typeof timeZone === 'string' && timeZone.length > 0) &&
      !this.isValidTimeZone(timeZone)
    ) {
      timeZone = moment.tz.guess();
      this.logger.log(
        'getUserTimeZone',
        `guessing user zone value : ${timeZone}`
      );
    }
    this.logger.log(
      'getUserTimeZone',
      `returning timeZone value : ${timeZone}`
    );
    if(!this.showUTCTimeZone){
       return timeZone;
   }else{
     return timeZone = 'UTC (UTC)'
   }
  }

  public static getUserInformation() {
    let userInfo: any = localStorage.getItem(CUSTOMER_CURRENT_USER);
    userInfo =
      typeof userInfo === 'string' && userInfo.trim().length > 0
        ? JSON.parse(userInfo)
        : null;
    if (userInfo instanceof Object) {
      this.userInfo = userInfo;
    }
    return userInfo;
  }

  public static isValidTimeZone(tz: string) {
    if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
      this.logger.log(
        'isValidTimeZone',
        `Time zones are not available in this environment`
      );
      return false;
    }
    try {
      Intl.DateTimeFormat(undefined, { timeZone: tz });
      return true;
    } catch (ex) {
      return false;
    }
  }

  public static getMomentFromEpoch(epoch) {
    this.logger.log('getMomentFromEpoch', 'epcoh : ', epoch);
    if (typeof epoch === 'number') {
      const date = this.getDate(epoch);
      this.logger.log('getMomentFromEpoch', 'returning date : ', date);
      return date;
    }
    return false;
  }

  public static getNextDayNextHour(date?: any): Moment {
    this.logger.log('getNextDayNextHour', 'date parameter : ', date);
    date = this.getDate(date);
    this.logger.log(
      'getNextDayNextHour',
      'dateValue after parsing to date is : ',
      date
    );
    if (date instanceof moment && date.isValid()) {
      this.logger.log(
        'getNextDayNextHour',
        'moving given date to end of hour & adding 1 seconds & 1 day'
      );
      /* if (moment(date).isValid()) {
        return moment(date)
          .endOf('hour')
          .add(1, 'seconds')
          .add(1, 'days');
      } */
      return date
        .endOf('hour')
        .add(1, 'seconds')
        .add(1, 'days');
    }
    this.logger.log(
      'getNextDayNextHour',
      'now to end of hour & adding 1 seconds & 1 day'
    );
    return this.getDate(null, true)
      .endOf('hour')
      .add(1, 'seconds')
      .add(1, 'days');
  }
  public static getNextHour(date?: any): Moment {
    this.logger.log('getNextHour', 'date parameter is : ', date);
    date = this.getDate(date);
    this.logger.log(
      'getNextHour',
      'date value after parsing paramter is : ',
      date
    );
    if (date instanceof moment && date.isValid()) {
      this.logger.log(
        'getNextHour',
        'moving to end of hour & adding 1 seconds to the provided date'
      );
      /* if (moment(date).isValid()) {
        return moment(date)
          .endOf('hour')
          .add(1, 'seconds')
          .add(1, 'days');
      } */
      return date.endOf('hour').add(1, 'seconds');
    }
    this.logger.log(
      'getNextHour',
      'getting current date & time & moving to end of hour & adding 1 seconds'
    );
    return this.getDate(null, true)
      .endOf('hour')
      .add(1, 'seconds');
    /* if (date) {
      if (moment(date).isValid()) {
        return moment(date)
          .endOf('hour')
          .add(1, 'seconds');
      }
    }

    return moment()
      .endOf('hour')
      .add(1, 'seconds'); */
  }

  public static getProvidedTimeByBrowsersTimeZone(date, returnMoment) {
    return this.format(date, true);
  }
  public static getProvidedTimeByTimeZone(date, timezone, returnMoment) {
    // let dateEpoch = this.getEpochTime(date);
    if (typeof timezone === 'string' && timezone.toString().length > 0) {
    } else {
      timezone = this.defaultTimeZone;
    }
    if (returnMoment) {
      return moment.tz(date, timezone);
    }
    return this.format(moment.tz(date, timezone), true);
  }

  public static getTimeByTimeZone(timezone, returnMoment) {
    if (typeof timezone === 'string' && timezone.toString().length > 0) {
    } else {
      timezone = this.defaultTimeZone;
    }
    if (returnMoment ) {
      return moment().tz(timezone);
    }
    return this.format(moment().tz(timezone), true);
  }

  public static getEpochTime(date) {
    if (date instanceof moment) {
      return moment.utc(date).isValid()
        ? moment
          .utc(date)
          .toDate()
          .getTime()
        : null;
    } else if (typeof date === 'string') {
      return moment.utc(date).isValid()
        ? moment
          .utc(date)
          .toDate()
          .getTime()
        : null;
    }
  }

  public static getMoment(date) {
    if (date instanceof moment) {
      return this.format(date.isValid() ? date : null, true);
    } else if (typeof date === 'string') {
      return moment(date).isValid() ? this.format(moment(date), true) : null;
    }
  }

  public static getCurrentDateAsMoment() {
    return moment();
  }

  public static getUTCTime() {
    return moment
      .utc(moment())
      .toDate()
      .getTime();
  }

  public static getDateAndTimeFromUTCEpoch(epoch) {
    if (typeof epoch === 'number') {
      return this.format(moment.utc(epoch), true);
    } else if (
      typeof epoch === 'string' &&
      epoch.length > 0 &&
      !isNaN(parseInt(epoch)) &&
      parseInt(epoch).toString().length === epoch.length
    ) {
      return this.format(moment.utc(+epoch), true);
    }
    return null;
  }

  public static timeZoneCheck() {
    moment.tz.setDefault('America/New_York');
    this.logger.log(
      'timeZoneCheck',
      `after setting timezone manually current date & time values are : ${moment
        .tz()
        .toString()}`
    );
    this.logger.log(
      'timeZoneCheck',
      `default timezone is : ${
      this.defaultTimeZone
      } current date & time values are : ${moment
        .tz(this.defaultTimeZone)
        .toString()}`
    );
    const june = moment();
    this.logger.log(
      'timeZoneCheck',
      `America/Los_Angeles date & time now is : ${june
        .tz('America/Los_Angeles')
        .format(this.formatStringWithTime)}`
    );
    this.logger.log(
      'timeZoneCheck',
      `America/New_York date & time now is : ${june
        .tz('America/New_York')
        .format(this.formatStringWithTime)}`
    );
    this.logger.log(
      'timeZoneCheck',
      `Asia/Tokyo date & time now is : ${june
        .tz('Asia/Tokyo')
        .format(this.formatStringWithTime)}`
    );
    this.logger.log(
      'timeZoneCheck',
      `Australia/Sydney date & time now is : ${june
        .tz('Australia/Sydney')
        .format(this.formatStringWithTime)}`
    );
  }

  public static getUTCTimeByMoment(date) {
    if (date instanceof moment) {
      date = date.tz(this.getUserTimeZone());
      return date.isValid() ? date.toDate().getTime() : false;
    }
    return false;
  }

  public static getDateFromUTCEpoch(epoch: number) {
    const date = moment.utc(epoch);
  }
  public static setUserTimeZone(timeZone) {
    this.defaultTimeZone = timeZone;
  }

  public static getTimeZoneFromMoment(date) {
    if (date instanceof moment) {
      return date.isValid() ? date.tz() : false;
    }
    return false;
  }

  public static getMomentOfUserTimeZoneFromMomentObject(val) {
    if (val instanceof moment) {
      const a = this.getDate(null, true);
      a.date(val.date());
      console.log(a.toString());
      a.month(val.month());
      console.log(a.toString());
      a.year(val.year());
      console.log(a.toString());
      a.hour(val.hour());
      console.log(a.toString());
      a.minute(val.minute());
      console.log(a.toString());
      a.second(val.second());
      console.log(a.toString());
      return a;
    }
    return false;
  }
  public static getMomentTimeInCurrentTimeZone(date) {
    return moment(date).tz(moment.tz.guess());
  }
}

@Pipe({
  name: 'amTimeAgoByTimeZone'
})
export class AmTimeAgoByTimeZonePipe implements PipeTransform {
  cp;
  private logger = new Logger('AmTimeAgoByTimeZonePipe', DateUtils.debug);
  constructor(private cdr: ChangeDetectorRef, private ngZone: NgZone) { }
  transform(value: any) {
    this.logger.log('transform', 'value  : ', value);
    this.cp = new TimeAgoPipe(this.cdr, this.ngZone);
    const transformedValue = this.cp.transform(DateUtils.getDate(value));
    this.logger.log('transform', 'transformedValue', transformedValue);
    return transformedValue;
  }
}

@Pipe({
  name: 'amDateFormatByTimeZone'
})
export class AmDateFormatByTimeZonePipe implements PipeTransform {
  cp;
  private logger = new Logger('AmDateFormatByTimeZonePipe', DateUtils.debug);
  constructor(private cdr: ChangeDetectorRef, private ngZone: NgZone) { }
  transform(value: any, withTime?: boolean, ...args: any[]) {
    this.logger.log('transform', 'value  : ', value);
    const formatString =
      typeof withTime === 'boolean' && withTime
        ? DateUtils.formatStringWithTime
        : DateUtils.formatString;
    this.logger.log('transform', 'formatString  : ', formatString);
    this.cp = new DateFormatPipe();
    // console.log(value, withTime, DateUtils.format(DateUtils.getDate(value)));
    const transformedValue = this.cp.transform(
      DateUtils.getDate(value),
      formatString,
      args
    );
    this.logger.log('transform', 'transformedValue : ', transformedValue);
    return transformedValue;
  }
}

window['getMoment'] = function getMoment() {
  return moment;
};
