import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface FileData {
  data: File;
  name: string;
  type: string;
  inprogress: boolean;
  progress: number;
  downloadable: boolean;
  failed: boolean;
  run_id?: string;
  location?: string;
}

@Component({
  selector: 'cprt-file-upload-status',
  templateUrl: './file-upload-status.component.html',
  styleUrls: ['./file-upload-status.component.sass'],
})
export class FileUploadStatusComponent implements OnInit {
  @Input() file: FileData;

  @Output() downloadFile = new EventEmitter<FileData>();
  @Output() refreshProgress = new EventEmitter<FileData>();
  @Output() deleteFile = new EventEmitter<FileData>();

  constructor() {}

  ngOnInit() {}
}
