import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'header-bar',
  templateUrl: 'header-bar.component.html',
  styleUrls: ['./header-bar.component.sass']
})
export class HeaderBarComponent implements OnInit {
  @Input() title: string;
  @Input() headerColor: string;

  ngOnInit() {}
}
