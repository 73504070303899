import { Component, OnInit, Input } from '@angular/core';
import { NewsListService } from './news-list.service';
import { IndustryNewsContentService } from 'app/modules/mi/IndustryNewsContent/shared/IndustryNewsContent.service';
import { Router } from '@angular/router';
import { NewsCodes } from './newscodes';
import { MomentModule } from 'angular2-moment';
import { DataStorageService } from 'app/modules/common/data-storage.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.sass'],
  providers: []
})
export class NewsListComponent implements OnInit {
  constructor(private newsListService: NewsListService,
    private router: Router,
    private moment: MomentModule,
    private dataStorageService: DataStorageService,
    private IndustryNewsContentService:IndustryNewsContentService) {
     this.myDate = new Date().getTime();
      }
  news: any[];
  dashbObject: object;
  @Input() target : string;
  myDate: number;


  togglesBotAction = true;

  toggleBotAction = false;


  ngOnInit() {
     this.myDate = new Date().getTime();
    this.news = this.newsListService.getNewsList(this.target);

    if (this.target === 'industrynews' || this.target === 'supplierfinancials') {

                const newsBlk:any ='.news-list';
                const newsList:any = document.querySelector(newsBlk);
                newsList.classList.add('in-news');
                newsList.classList.add('ovewrflow-y-scroll');
                if (this.target === 'industrynews') {
                  this.dashbObject = this.dataStorageService.getSelectedNewsId();

                if(this.dashbObject !== undefined){
                   this.newsListService.setCurrentNewsObject(this.dashbObject);
                   this.dataStorageService.setSelectedNewsId(undefined);
                }else {
                  this.newsListService.setCurrentNewsObject(this.news[0]);
                }

              }
            }
  }
  togglesAction(news: object, target) {

  }
  toggleAction(news: object, target) {
  }

  onClick(news: object, event, target) {

  }

  changebutton(IndustryNewsContent : object,event,target){
    event.stopPropagation();
    const targetClassList:any = event.currentTarget.classList;
    switch(target){
      case ('bookmark'):
          targetClassList.toggle('selected');
          break;
      case ('readlater'):
          targetClassList.toggle('selected');
          break;
    }

  }
  onNewsClick(selectedNews : object,event){
    event.stopPropagation();
   this.newsListService.setCurrentNewsObject(selectedNews);
   this.dataStorageService.setSelectedNewsId(selectedNews);
   if(this.target === 'industrynews'){
    const selector:any = '.news-list .itemcontainer li';
     const liItems:any = document.querySelectorAll(selector);

     for (let i = 0; i < liItems.length; i++) {
      liItems[i].classList.remove('selected');
     }
      event.currentTarget.classList.add('selected');
   }
   this.router.navigate(['/app/mi/industrynews']);
  }



}
