import * as numeral from 'numeral';
export class NumericUtils {
  public static formatString= '0,0';
  public static formatStringWithDecimals= '0,0[.]00';
  public static format(cVal, decimalCount?: any) {
    let formatString = this.formatString;
    if (typeof +decimalCount === 'number' && +decimalCount > 0 && !isNaN(+decimalCount)) {
        formatString += '[.]' + this.getDecimalsByCount(+decimalCount);
    }
    const formattedValue = numeral(cVal).format(formatString);
    return formattedValue;
  }


  public static ifInputIsNumeric(value) {
    if(typeof value === 'number') {
      return true;
    } else if(typeof value === 'string') {
      return /^[0-9.$]*$/g.test(value);
    }
    return false;
  }

  public static getDecimalsByCount(count?: number) {
    count = +count || 0;
    if (typeof count === 'number' && count > 0) {
      const zeroArr = [];
      zeroArr.length = +count;
      zeroArr.fill('0');
      return zeroArr.join('');
    }
    return '';
  }
}
