import { Component, Output, EventEmitter } from '@angular/core';
import { DataStorageService } from '../../../common/data-storage.service';
import { NpService } from '../../../np/shared/np.service';

@Component({
    selector: 'app-red-component',

    template: `<div class="full-width pull-left social-icons">
    <button class="btn btn-link padding-0 height-24 vertical-align-top  {{likeHighLight}}" type="button" (click) = "liked()" title ="Relevant" >
             <em class=" ld-icon-like font-size-18 liked" id="like" ></em> 
       </button>
   <button class="btn btn-link padding-0
   height-24 vertical-align-top {{dislikeHighLight}}" type="button" (click) = "unlike()" title ="Not Relevant">
       <em class="ld-icon-dislike  font-size-18 unliked" id="dislike"></em> 
   </button>
   </div>`,
    styleUrls: ['./npuseful.component.sass']
})
export class npUsefullComponent {
    params: any;
    costnotAvailble = false;
    doller = false;
    percentage = false;
    value: any;
    member: any;
    dollerValue: any;
    percValue: any;
    val: any;
    classificationId: any;
    classificationItemId: any;
    likeHighLight: any = ''
    dislikeHighLight: any = ''
    @Output() clicked = new EventEmitter<any>();

    constructor(private dataStorageService: DataStorageService, private npService: NpService) {

    }
    agInit(params: any): void {
        this.params = params

        if (this.params.data['relevent']) {
            this.likeHighLight = 'highlight'
            this.dislikeHighLight = ''
        }
        else if (this.params.data['irelevent']) {
            this.likeHighLight = ''
            this.dislikeHighLight = 'highlight'
        }
        else {
            this.likeHighLight = ''
            this.dislikeHighLight = ''
        }
    }
    liked() {
        this.likeHighLight = 'highlight'
        this.dislikeHighLight = ''
        this.dataStorageService.setPertinentData(this.params)
        this.npService.updatePertience(1, this.params['data']['leverDataId'], 1).subscribe(
            value => {

                if (value['responseStatus']['code'] === 200) {
                    this.params['data']['relevent'] = true;
                    this.params['data']['irelevent'] = false;
                }

            }
        )

    }
    unlike() {
        this.likeHighLight = ''
        this.dislikeHighLight = 'highlight'
        this.dataStorageService.setPertinentData(this.params)
        this.npService.updatePertience(1, this.params['data']['leverDataId'], 0).subscribe(
            value => {
                if (value['responseStatus']['code'] === 200) {
                    this.params['data']['relevent'] = false;
                    this.params['data']['irelevent'] = true;
                }
            }
        )
    }
}
