import { Component, OnInit, Input, EventEmitter, Output, OnDestroy, OnChanges } from '@angular/core';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';
import { DialogBoxService, DialogButtonName, DialogSize, DialogType, LdDialog } from 'app/modules/leva-ui-library/components/dialog-box/dialog-box.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { PartnerScoreCardService } from '../partner-score-card.service';

@AutoUnsubscribe()
@Component({
  moduleId: module.id,
  selector: 'marketing-page-popup',
  templateUrl: './marketing-page-popup.component.html',
  styleUrls: ['./marketing-page-popup.component.sass'],
  providers: [PartnerScoreCardService],
})
export class MarketingPagePopupComponent implements OnInit,OnDestroy,OnChanges {
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  requestRatingSubscription$: Subscription;
  @Input() learnMoreClicked: any;
  @Input() selectedValue: any;
  @Input() sendMail: any;
  @Output() dismissModel: EventEmitter<any> = new EventEmitter<any>(undefined);
  marketingPagePopup: LdDialog = {
    title: 'Request Rating',
    id: 'marketing_page_popup',
    copyright: true,
    template: true,
    customSize: { width: 800, height: 400 },
    type: DialogType.INFORMATION,
    buttons: [
      {
        title: DialogButtonName.OK,
        id: 'ok',
        primary: true,
      },
    ]
  };
  link =
    'https://www.rapidratings.com/request-an-fhr-report/?utm_campaign=Partnershihps&utm_source=levadata&utm_medium=platform';
  constructor(private partnerScoreCardService: PartnerScoreCardService,
    private dataStorageService: DataStorageService,
    private dialogBoxService: DialogBoxService) {}
  ngOnDestroy(): void {
    // throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.requestRatingSubscription$ =  this.dataStorageService.getRequestRating().subscribe((val)=>{
      if(val){
        this.dialogBoxService.open(this.marketingPagePopup.id);
      }
    })
  }
  ngOnChanges(){
    if(this.learnMoreClicked){
      this.dialogBoxService.open(this.marketingPagePopup.id);
    }
  }

  onClickLink() {
    if (this.sendMail === true) {
      this.partnerScoreCardService
        .sendMailForRR(this.selectedValue)
        .subscribe((val) => {
          console.log(val);
        });
    }
  }
}
