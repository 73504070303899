import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'preload-bar-graph',
  templateUrl: './bar-graph.component.html',
  styleUrls: ['./bar-graph.component.sass']
})

export class PreloadBarGraphComponent implements OnInit {

  constructor() { }

  ngOnInit() { }
}
