import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiServiceProvider } from 'app/api-service';

@Injectable({
    providedIn: 'root'
})
export class ChatBotService extends ApiServiceProvider{

    chatBotActions = new BehaviorSubject<any>(null)

    setCustomChatBotActions(val){
        this.chatBotActions.next(val);

    }
    getCustomChatBotActions(){
        return this.chatBotActions.asObservable();
    }
    // HTTP Call Methods
    bookMarks(payload){
        const bookMark : any = {
            targetedType: 'ChatbotServices',
            method: 'post',
            payload,
            servicePath: '/bookMark'
          };
          return this.post(bookMark);
    }

    addNewFavorites(command){
        const payload ={
            command,
            enabled: true
        };
        const favourites : any = {
            targetedType: 'ChatbotServices',
            method: 'POST',
            payload,
            servicePath: '/favourites'
        };
        return this.post(favourites);
    }

    updateFavorites(command, favouriteId,enabled){
        const payload ={
            command,
            enabled
        }
        const favourites : any = {
            targetedType: 'ChatbotServices',
            method: 'PUT',
            payload,
            servicePath: `/favourites/${favouriteId}`
          };
          return this.post(favourites);
    }

    clearAllFavourites(favouriteIds){
        const payload ={
            favouriteIds
        };
        const clearFavourites: any = {
            targetedType: 'ChatbotServices',
            method: 'PUT',
            payload,
            servicePath: '/favourites'
        };
        return this.post(clearFavourites);
    }

    getLandingPageButtons(tenantId){
        const requestPayload: any = {
            targetedType: 'CustomerConfigService',
            method: 'POST',
            generic: false,
            servicePath: `/getSelectedLandingButton?tenantId=${tenantId}&`,
            payload: {}
        };
        return this.post(requestPayload);
    }

    public getAlertsCount(scope?: string) {
        let templateType = 'COMMODITY';
        if (scope === 'Commodity') {
            templateType = 'COMMODITY';
        } else if (scope === 'Product') {
            templateType = 'PRODUCT';
        }

        const object: any = {
          targetedType: 'AlertsService',
          formParams: {
            alertType: 'RFQ',
            templateType: templateType,
            tenantType: 'CUSTOMER',
            sort: '-DATE'
          },
          method: 'GET',
          servicePath: '/user/alertsCount'
        };
        return this.post(object);
      }
}
