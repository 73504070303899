import { GridOptions } from '@ag-grid-community/all-modules';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BodyOutputType, Toast, ToasterService } from 'angular2-toaster';
import { BaseService } from 'app/modules/common/base.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RfqConfigService } from '../shared/rfq-config.service';

@Component({
  selector: 'app-show-customer-data',
  templateUrl: './show-customer-data.component.html',
  styleUrls: ['./show-customer-data.component.sass']
})
export class ShowCustomerDataComponent implements OnInit, OnDestroy {
  private subject$: Subject<any> = new Subject();
  public gridOptions: GridOptions = {};
  public preload = true;
  public rowData: any[] = [];
  customOptions: any;
  private isAutoFilled = false;
  constructor(private rfqConfigService: RfqConfigService,
    public baseService: BaseService,
    private toasterService: ToasterService) { }

  ngOnInit() {
    this.customOptions = {
      floatingFilter: false,
      pagination: true,
      paginationPageSize: 20,
      enableFilter: true,
      toolPanel: false,
    };
    this.getReservedColumnsData();
  }

  private getReservedColumnsData() {
    this.preload = true;
    this.rfqConfigService.getReservedColumnsData()
      .pipe(takeUntil(this.subject$))
      .subscribe(rowData => {
        if (Object.keys(rowData).length) {
          this.gridOptions.columnDefs = [
            {
              headerName: 'Description',
              field: 'description',
              editable: false
            },
            {
              headerName: 'Column',
              field: 'column',
              editable: false
            }
          ];
          this.gridOptions.rowData = Object.entries(rowData).map(([key, value]) => ({ column: key, description: value }));
          this.preload = false;
          if (this.isAutoFilled) {
            const toast: Toast = {
              type: 'success',
              showCloseButton: true,
              body: `<div class="toast-msg success-msg">Completed Successfully.</div>`,
              bodyOutputType: BodyOutputType.TrustedHtml,
            };
            this.toasterService.pop(toast)
          }
        } else {
          this.preload = false;
          this.gridOptions.columnDefs = [];
          this.gridOptions.rowData = [];
        }
      });
  }

  public autofillData() {
    this.isAutoFilled = false;
    const userEmail = this.baseService.getUserInformation().email;
    this.rfqConfigService.getAutofillStatus(userEmail)
      .pipe(takeUntil(this.subject$))
      .subscribe(rowData => {
        if (Object.keys(rowData).length) {
          this.isAutoFilled = true;
          this.getReservedColumnsData();
        } else {
          const toast: Toast = {
            type: 'success',
            showCloseButton: true,
            body: `<div class="toast-msg success-msg">All fields are already up to date.</div>`,
            bodyOutputType: BodyOutputType.TrustedHtml,
          };
          this.toasterService.pop(toast)
        }
      });
  }

  ngOnDestroy(): void {
    this.subject$.next();
    this.subject$.complete();
  }

}
