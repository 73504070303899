import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { UtilService } from 'app/modules/common/utills/util.service';

@Component({
  selector: 'app-industry-alerts',
  templateUrl: './industry-alerts.component.html',
  styleUrls: ['./industry-alerts.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class IndustryAlertsComponent implements OnInit {

  @Input() configuration;
  constructor(
    public utilService: UtilService,
    private router: Router,
    private dataStorageService: DataStorageService
  ) { }

  ngOnInit() {
  }

  onClickLink() {
    const link = this.configuration.data[0].data[0].values[2] || null
    this.dataStorageService.setQueryParams(this.configuration.data[0].data[0].values[5] || {})
    if (link) {
      this.router.navigate([link])
    }
  }
}


