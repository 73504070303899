import { PipeTransform, Pipe } from '@angular/core';
@Pipe({ name: 'toFixed', pure: false })
export class ToFixed implements PipeTransform {
  /* transform(value: any, args: any[] = null): any {
        return Object.keys(value)//.map(key => value[key]);
    } */

  transform(input, args=null): any {
    if (typeof input === 'string') {
      input = parseFloat(input);
    } else if (
      input === null ||
      input === undefined ||
      typeof input === 'boolean' ||
      input instanceof Object
    ) {
      return input;
    }
    return input.toFixed(args);
  }
}
