import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';


@Injectable()
export class SrfqService {
  public showParentScroll = false;
  public partialMatchFilterColumnData: any = {};
  public filtersMap: any = {};
  constructor(private http: HttpClient) {}
}
