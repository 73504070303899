import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { ApiServiceProvider } from 'app/api-service';
import { BaseService } from 'app/modules/common/base.service';
import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService';
import { environment } from 'environments/environment';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { Router } from '@angular/router';
import {CUSTOMER_CUSTOMER_DATA} from '../../shared/services/shared-consts';
@Injectable()
export class AuthenticationService {
  constructor(
    private apiServiceProvider: ApiServiceProvider,
    private customerConfigurationService: CustomerConfigurationService,
    private baseService: BaseService,
    private scopeDataService: ScopeDataService,
    private router: Router
  ) { }

  public login(obj: object) {
    return this.apiServiceProvider.post(obj);
  }
  public newLogin(obj: object) {
    return this.apiServiceProvider.directPost(
      environment.authenticationService + '/login',
      obj,
      null,
      null
    );
  }

  public getUserInfo() {
    const data = {
      targetedType: 'TokenService',
      headerParams: {
        'Content-Type': 'application/json'
      },
      formParams: {
        is_supplier: false
      },
      method: 'GET',
      servicePath: '/auth/v2/userinfo'
    };
    return this.apiServiceProvider.post(data);
  }
  public checkSSOUserAuthentication(userEmail) {
    const obj = {
      servicePath: `${environment.authenticationServiceApiPath}/staticuserinfo`,
      method: 'POST',
      formParams: {
        userEmail: userEmail
      }
    };
    return this.apiServiceProvider.postCallThroughZuul(obj);
  }
  public logout() {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    this.baseService.setCookie('username', '', -1);
  }

  public changePassword(obj) {
    return this.apiServiceProvider.post(obj);
  }

  public forgotPassword(obj: object) {
    return this.apiServiceProvider.post(obj);
  }

  public clearCustomerLocalStorageItems() {
    if (Object.keys(localStorage).length > 0) {
      Object.keys(localStorage).forEach(key => {
        if (key.startsWith('customer_')) {
          delete localStorage[key];
        }
      });
    }
  }
  public scheduledMaintainanceCall() {
    const object: any = {
      targetedType: 'CustomReports',
      method: 'GET',
      servicePath: '/scheduledMaintenance'
    };
    return this.apiServiceProvider.post(object);
  }
  public logOutExecution() {

    const userInfo = this.baseService.getUserInformation();
    const envDetails = this.customerConfigurationService.getEnvironmentDetails();
    const customerData = window.localStorage.getItem(CUSTOMER_CUSTOMER_DATA);
    let sloURL;
    if (customerData) {
      sloURL = JSON.parse(JSON.stringify(JSON.parse(customerData))).ssoLogOutUrl;
    }
    this.logoutApplication().subscribe(data => {
      console.log('Logged out successfully');
      // this.router.navigate(['/login']);
    });
    setTimeout(() => {
      this.clearCustomerLocalStorageItems();
      this.scopeDataService.setDefaultDimensionDataObject();
      this.scopeDataService.clearDefaultData();
      this.logout();
      this.baseService.setUserInformation('');
      this.baseService.setLogout(true);
      if (userInfo.ssoUser) {
        window.location.href = sloURL || '/login';
      } else {
        this.router.navigate(['/login']);
      }
    }, 0)
  }
  public refresh(): void {
    window.location.reload();
  }
  public logoutApplication() {
    const data = {
      targetedType: 'TokenService',
      formParams: {
        is_supplier: false
      },
      method: 'POST',
      servicePath: `/auth/v2/logout`
    };
    return this.apiServiceProvider.post(data);
  }

  checkUserAlreadyActivated(userEmail: string) {
    const data = {
      targetedType: 'UserService',
      servicePath: '/checkUserActivated',
      method: 'GET',
      formParams: {
        email: userEmail
      }
    }
    return this.apiServiceProvider.post(data);
  }

  resetPassword(userEmail: string, password: string,token: string) {
    const data = {
      targetedType: 'UserService',
      servicePath: '/updateForgottenPassword',
      method: 'POST',
      formParams: {
        userEmail,
        password: encodeURIComponent(password),
        jwtToken: token
      }
    }
    return this.apiServiceProvider.post(data);
  }

}
