
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { CoverageDashboardService } from 'app/modules/coverage-dashboard/coverage-dashboard.service'
import Highcharts from 'highcharts'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

import { GenerateGraphObject } from '../graph-container/GenerateGraphObject'
@Component({
  selector: 'app-radial-chart',
  templateUrl: './radial-chart.component.html',
  styleUrls: ['./radial-chart.component.sass'],
  providers: [GenerateGraphObject],
})
export class RadialChartComponent implements OnInit, OnDestroy {
  _config: any
  datas: any = []
  data1
  category: any = []
  chartOptions: Highcharts.Options
  chart: Highcharts.Chart

  graphRenderObject: any = {}
  graphData: any
  xAxis: any = {}
  seriesData = []
  _configuration: any
  get configuration() {
    return this._configuration
  }
  @Input() set configuration(value) {
    this._configuration = value || {}
  }

  constructor(private generateGraphObject: GenerateGraphObject, private coverageRadial: CoverageDashboardService) { }

  ngOnInit(): void {
    this.subscribeToPieEvents();
  }
  private subject$: Subject<any> = new Subject();
  private subscribeToPieEvents(): void {
    this.coverageRadial.getDashboardDataChangeObservable()
      .pipe(takeUntil(this.subject$))
      .subscribe((response: any) => {
        if (response.type === 'RADIAL_UPDATE') {
          this.reloadChartAfterUpdating(response.isData);
        }
      })
  }

  private reloadChartAfterUpdating(hasData: boolean): void {
    this.seriesData = [];
    this.graphData = null;
    if (hasData) {
      this.findTheData(this.configuration);
    } else {
      this.generateGraphObject.setSeriesData(this.seriesData);
      this.generateGraphObject.addOtherInformation(undefined);
      this.graphRenderObject = null;
    }
  }

  findTheData(path) {
    if (
      path &&
      path.data &&
      path.data.data &&
      path.data.data.length > 0 &&
      Object.keys(path.data.data[0]).length > 0 &&
      Object.keys(path.data.data[0].value).length > 0
    ) {
      if (this.configuration && this.configuration.hasOwnProperty('data') && this.configuration.data.hasOwnProperty('data') && this.configuration.data.data[0].hasOwnProperty('value')) {
        this.datas = [];
        this.datas = this.configuration.data.data[0].value.map((i) => i.data[0].value)
        this.data1 = [];
        this.data1 = this.datas.map((i) => (Math.round(i * 100)));
        this.category = [];
        this.category = this.configuration.data.data[0].value.map((i) => i.data[0].key);
        this.generateBarChartObject();

      }
    }
  }
  generateBarChartObject() {
    if (this.configuration.data.type = "RADIAL_GRAPH_TILE") {
      this.generateGraphObject.addOtherInformation({
        chart: {
          type: 'column',
          inverted: true,
          polar: true,
        },
        colors: [
          '#002642',
          '#006196',
          '#0597c3',
          '#7f7f7f',
          '#bfbfbf',
          '#e6e6e6',
        ],
      })

      this.generateGraphObject.addOtherInformation({
        xAxis: {
          // visible: false,
          minorGridLineWidth: 0,
          gridLineWidth: 0,
          lineColor: 'transparent',
          tickInterval: 1,
          labels: {
            align: 'right',
            useHTML: true,
            allowOverlap: true,
            step: 1,
            y: 3,
            style: {
              color: '#333',
              fontSize: '10px',
              backgroundColor: 'rgb(255 255 255 / 50%)',
              padding: '0px 0px 0px 0px',
              marginTop: '2px',
              lineHeight: 1.2,
            }
          },
          lineWidth: 0,
          categories: this.category
        },
      })
      this.generateGraphObject.addOtherInformation({
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
          }]
        },
        tooltip: {
          useHTML: true,
          borderWidth: 0,
          borderRadius: 5,
          outside: true,
          hideDelay: 0,
          formatter: function () {
            const scope = window['that'];
            const methodPayload = {
              title: 'Coverage',
              targetName: this.key,
              indicatorColor: this.color,
              data: [
                { title: this.key, value: `${this.y}%` },
                { title: '', value: '' }
              ]
            }
            scope.graphUtilService.setTooltip(methodPayload);
            return scope.graphUtilService.getTooltip();
          }

        },
        pane: {
          size: '100%',
          innerSize: '20%',
          endAngle: 359,
        },
      })
      this.generateGraphObject.addOtherInformation({
        yAxis:
        {
          visible: false,
          min: 0,
          max: 100,
          crosshair: {
            color: '#333',
          },
          minorGridLineWidth: 0,
          gridLineWidth: 0,
          tickInterval: 0,
          lineWidth: 0,
          reversedStacks: false,
          endOnTick: true,
          showLastLabel: true,
        },
      })
      this.generateGraphObject.addOtherInformation({
        plotOptions: {
          column: {
            stacking: 'normal',
            borderWidth: 0,
            pointPadding: 0,
            groupPadding: 0.05,
            dataLabels: {
              // padding: 10,
              style: {
                fontSize: '10px',
                fontWeight: 'normal',
              },
              enabled: true,
              allowOverlap: true,
              textPath: {
                enabled: true,
                attributes: {
                  textAnchor: 'start',
                  startOffset: 5,
                  dy: 11.5,
                },
              },
              formatter: function () {
                return this.y + '%'
              },
            },
          },
        },
      });

      this.generateGraphObject.setSeriesData([
        {
          // type: 'column',
          colorByPoint: true,
          data: this.data1,
          name: 'Series Name',

        },
      ])

      this.generateGraphObject.addOtherInformation(this.xAxis);
      this.graphRenderObject = this.generateGraphObject.getGraphData();
    }
  }
  ngOnDestroy(): void {
    this.subject$.next();
    this.subject$.complete();
  }
}
