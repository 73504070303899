import { Component, OnInit } from '@angular/core';

import { Risks } from './shared/risks.model';
import { RisksService } from './shared/risks.service';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';

@Component({
  selector: 'risks',
  templateUrl: 'risks.component.html',
  styleUrls: ['risks.component.sass'],
  providers: [RisksService]
})

export class RisksComponent implements OnInit {
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  risks: any;
  rfName: string;

  constructor(private risksService: RisksService,
    private dataStorageService: DataStorageService) { }

  ngOnInit() {
    if (this.dataStorageService.getSeletedRFQName() !== undefined) {
      this.rfName = this.dataStorageService.getSeletedRFQName();
    }
    this.risks = this.risksService.getRiskData(this.rfName);
  }
}
