import { Component, OnInit } from '@angular/core';
import { ContentLabService } from './shared/content-lab.service';
import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService';
import { Router } from '@angular/router';
import { LdDialog, DialogSize, DialogType, DialogButtonName, DialogBoxService } from 'app/modules/leva-ui-library/components/dialog-box/dialog-box.service';

@Component({
  selector: 'app-content-lab',
  templateUrl: './content-lab.component.html',
  styleUrls: ['./content-lab.component.sass'],
  providers: [ContentLabService]
})
export class ContentLabComponent implements OnInit {
  labDashboards = [];
  loading = true;
  loaderConfig = {
    backdropBackgroundColour: 'rgba(255,255,255,0.3)',
    fullScreenBackdrop: 'true',
    primaryColour: '#1C4E84',
    secondaryColour: '#1C4E84',
    tertiaryColour: '#1C4E84'
  };
  selectedDashboard = null;
  envDetails = null;
  moduleConfigurationConfig: LdDialog = {
    title: 'Module Configuration',
    id: 'moduleConfigurationConfig',
    template: true,
    size: DialogSize.MEDIUM,
    type: DialogType['CONFIRMATION'],
    buttons: [
      {
        title: 'RESET',
        id: 'moduleConfigurationConfig_' + DialogButtonName.NO,
        primary: false,
      },
      {
        title: 'SAVE',
        id: 'moduleConfigurationConfig_' + DialogButtonName.YES,
        primary: true,
      },
    ],
  };
  constructor(
    private contentLabService: ContentLabService,  private dialogBoxService: DialogBoxService,
    private customerConfigurationService: CustomerConfigurationService,
    private router: Router
  ) {
    this.envDetails = this.customerConfigurationService.getEnvironmentDetails();
  }

  ngOnInit() {
    this.getDashboards();
  }
  getDashboards() {
    const requestParams = {
      tenantId: this.envDetails.tenantId
    };

    this.contentLabService
      .getInsightDashboardsBYTenant(requestParams)
      .subscribe(dashboardResult => {
        this.labDashboards = dashboardResult.result;
        setTimeout(() => {
          this.loading = false;
        }, 100);
      });
  }

  onDashboardSelect(selectedDashboard) {
    const dashboardObject = {
      dashboard_id: null,
      display_name: null,
      description: null,
      parameters: null
    };

    dashboardObject.display_name = selectedDashboard.displayName;
    dashboardObject.dashboard_id = selectedDashboard.dashboardId;
    dashboardObject.description = selectedDashboard.description;
    dashboardObject.parameters = selectedDashboard.parameters;

    if (!!selectedDashboard.parameters) {
      try {
        const parameters = JSON.parse(selectedDashboard.parameters.trim());
        parameters.forEach(param => {
          const paramObj = {};
          switch (param.value) {
            case 'TENANT_ID':
              paramObj[param.key] = this.envDetails.tenantId;
              break;
          }
          dashboardObject.parameters = { ...paramObj };
        });
      } catch (e) {
        dashboardObject.parameters = null;
      }
    }
    this.selectedDashboard = { ...dashboardObject };
  }
  public showTooltip(id) {
    id = id === undefined ? 'helpToolTip' : id;
    document.getElementById(id).style.display = 'block';
  }
  public hideTooltip(id) {
    id = id === undefined ? 'helpToolTip' : id;
    document.getElementById(id).style.display = 'none';
  }
}
