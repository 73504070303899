import { Component, OnInit, Input } from '@angular/core';
import { ListService } from './shared/list.service';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { ISingeItem } from 'app/modules/common/models/scope/singleItem';
import { ScopeService } from 'app/modules/core/scope/shared/scope.service';
import { SubscriptionLike } from 'rxjs';
import { HEADER_OBJECT } from '../../srfq/create/adhoc-rfx/shared/adhoc-rfx.constants';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';
@Component({
  selector: 'list',
  templateUrl: 'list.component.html',
  styleUrls: ['./list.component.sass'],
  providers: [ListService]
})


export class ListComponent implements OnInit {
  @Input('data') set data(data) {
    this.autodata = data;
    this.autosearchdatamethod(this.autodata);
  }
  constructor(
    private scopeDataService: ScopeDataService,
    private listService: ListService,
    private scopeService: ScopeService

  ) {
    this.scopeService.getclearall().subscribe((val) => {
      if (this.dimensionFiltersObjectData !== undefined) {
        let keys = [];
        if (this.dimensionFiltersObjectData !== undefined) {
          keys = Object.keys(this.dimensionFiltersObjectData);
        }
        if (keys instanceof Array) {
          for (const i of keys) {
            if (typeof i === 'string' && i !== '') {
              if (this.dimensionFiltersObjectData[i].isSelected) {
                this.dimensionFiltersObjectData[i].isSelected = false;
              }
            }
          }
        }
    }
    if(val === 'clear'){
      this.checkClearAll = true;
    }
    });
    // this.drilldownSubscription = instance.getDrillDownSelection().subscribe((data) => {
    // 	this.autosearch = false;
    // 	this.autosearchdata = {}
    // 	if (data !== undefined) {
    // 		if (this.dimension !== undefined) {

    // 			if (data.dimensionName === this.dimension.dimensionName) {
    // 				this.autosearch = true;
    // 				this.autosearchdata = data;
    // 				let getDimensionData = this.scopeDataService.getDefaultDimensionDataByDimension(this.dimension.dimensionName);
    // 				if (getDimensionData === undefined) {
    // 					this.getDimensionDataFromServer(this.dimension.dimensionName);
    // 				} else {
    // 					this.onSelectedItem(this.autosearchdata.value, undefined);
    // 				}
    // 				var evt = new MouseEvent("click");
    // 				document.body.dispatchEvent(evt);
    // 			}
    // 		}
    // 	}
    // })
    this.autosearchsubscription = this.scopeService.getautosearchdata().subscribe((data) => {
      this.autosearch = false;
      this.autosearchdata = {}
      if (data !== '') {
        if (this.dimension !== undefined) {
          if (data.dimension === this.dimension.displayName) {
            this.autosearch = true;
            this.autosearchdata = data;
            const getDimensionData = this.scopeDataService.getDefaultDimensionDataByDimension(this.dimension.dimensionName);
            if (getDimensionData instanceof Object) {
              this.onSelectedItem(this.autosearchdata.value, undefined);
            } else {
              this.getDimensionDataFromServer(this.dimension.dimensionName);
            }
          }
        }
      }
    });

    scopeDataService.getUserScopeFilter().subscribe(
      userScopeFilter => {
        if (userScopeFilter instanceof Object && userScopeFilter.hasOwnProperty('dimensions')) {
          if (userScopeFilter['dimensions'] instanceof Object && this.dimension instanceof Object) {
            if (userScopeFilter['dimensions'][this.dimension.dimensionName] instanceof Object) {
              const getDimensionData = this.scopeDataService.getDefaultDimensionDataByDimension(this.dimension.dimensionName);
              if (getDimensionData === undefined) {
                this.listService.getDimensionData(this.dimension.dimensionName).subscribe(
                  (data) => {
                    if (data['statusCode'] === 200) {
                      if (data['dimensionResult'] instanceof Array) {
                        // data['dimensionResult']  = data['dimensionResult'].splice(0,300);
                        const listData: object = {};
                        for (const item of data['dimensionResult']) {
                          const singleItem = {};
                          singleItem[item] = {};
                          Object.assign(listData, singleItem);
                        }
                        this.onManageFilterApplied(listData, userScopeFilter);
                      }
                    }
                  }
                );
              } else {
                this.onManageFilterApplied(getDimensionData, userScopeFilter);
              }
            }
          }
        }

      }
    );

    scopeDataService.getFocus().subscribe(
      (focus) => {
        this.currentFocus = focus;
        this.advanceFilterSelector = HEADER_OBJECT.NO_VALUE;
        this.advancedFilterName = HEADER_OBJECT.NO_VALUE;
      }
    );

    scopeDataService.getApplicationScope().subscribe(
      selectedScopeData => {
        if (selectedScopeData instanceof Object && this.dimension instanceof Object) {
          if (selectedScopeData['selectedScopeData'][this.currentFocus].hasOwnProperty('filters')) {
            if (selectedScopeData['selectedScopeData'][this.currentFocus]['filters'] instanceof Object) {
              const filtersObject: Object = selectedScopeData['selectedScopeData'][this.currentFocus]['filters'];
              if (filtersObject.hasOwnProperty(this.dimension.dimensionName)) {
                this.showAdvancedFilterApplied = true;
                this.advanceFilterSelector = filtersObject[this.dimension.dimensionName]['filterSelected'];
                this.advancedFilterName = filtersObject[this.dimension.dimensionName]['filterParameter'];
              } else {
                this.showAdvancedFilterApplied = false;
                this.advanceFilterSelector = HEADER_OBJECT.NO_VALUE;
                this.advancedFilterName = HEADER_OBJECT.NO_VALUE;
              }
            }
          } else {
            this.showAdvancedFilterApplied = false;
            this.advanceFilterSelector = HEADER_OBJECT.NO_VALUE;
            this.advancedFilterName = HEADER_OBJECT.NO_VALUE;
          }
        }
        if(selectedScopeData && selectedScopeData['selectedScopeData'] && selectedScopeData['selectedScopeData']['Commodity'] &&  selectedScopeData['selectedScopeData']['Commodity'].hasOwnProperty('dimensions')){
          if(selectedScopeData['selectedScopeData']['Commodity']['dimensions']){
            this.showDefaultFilter = true;
          }
          else{
            this.showDefaultFilter = false;
          }
        }
      }
    );
  }
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  autodata: any;
  @Input('dimension') dimension: any;
  showDefaultFilter = false;
  checkClearAll = false;
  dimensionFiltersObjectData: Object = {};
  loader = false;
  displayView: string;
  autosearch = false;
  autosearchsubscription: SubscriptionLike;
  // drilldownSubscription: SubscriptionLike;
  autosearchdata: any = {};
  dimensionFiltersObjectDataList: any[] = [];
  viewPortItems = [];
  virtualScroll = true;

    /**
	 * Manage Filters Changes
	 * End
	 */

  /**
	 * Advanced Filter Changes
	 * Start
	 */

  advanceFilterSelector: string = HEADER_OBJECT.NO_VALUE;
  advancedFilterName: string = HEADER_OBJECT.NO_VALUE;
  currentFocus = 'Commodity';
  showAdvancedFilter = false;
  showAdvancedFilterApplied = false;
  dimensionListExpanded = false;

  ngOnInit() {
    this.scopeDataService.displayType.subscribe(
      (view) => {
        if(view) {
          this.displayView = view;
          this.updateView(view);
        }
      }
    );

  }
  autosearchdatamethod(data) {
    this.autosearch = false;
    this.autosearchdata = {}
    if (data !== '' && data !== undefined) {
      if (this.dimension !== undefined) {
        if (data.dimension === this.dimension.displayName) {
          this.autosearch = true;
          this.autosearchdata = data;
          const getDimensionData = this.scopeDataService.getDefaultDimensionDataByDimension(this.dimension.dimensionName);
          if (getDimensionData === undefined) {
            this.getDimensionDataFromServer(this.dimension.dimensionName);
          } else {
            this.onSelectedItem(this.autosearchdata.value, undefined);
          }
        }
      }
    }
  }

  updateView(view) {
    if (view !== undefined) {
      this.dimensionFiltersObjectData = this.scopeDataService.getDimensionDataByDimension({
        dimensionName: this.dimension['dimensionName'],
        view
      });
      if (this.dimensionFiltersObjectData !== undefined) {
        this.dimensionFiltersObjectDataList = Object.keys(this.dimensionFiltersObjectData);
        const target = document.getElementById(this.dimension['dimensionName']);
        try {
          target.classList.add('in');
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          if (document.getElementById(this.dimension['dimensionName']) !== undefined) {
            if (document.getElementById(this.dimension['dimensionName']).classList.contains('in')) {

              this.getDimensionDataFromServer(this.dimension['dimensionName']);
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  }


  getDimensionDataFromServer(dimension: string) {
    if (dimension !== undefined) {
      this.loader = true;
      this.listService.getDimensionData(dimension).subscribe(
        (data) => {
          if (data['statusCode'] === 200) {
            if (data['dimensionResult'] !== undefined) {
              // data['dimensionResult']  = data['dimensionResult'].splice(0,300);
              const listData: object = {};
              for (const item of data['dimensionResult']) {
                const singleItem: ISingeItem<any> = {}
                const filterName = this.scopeDataService.checkAndGetDefaultFiltersInfo();
                if(item === filterName && data['dimensionName'] === 'COMMODITY_MANAGER_NAME' && this.showDefaultFilter){
                    singleItem[item] = {isSelected:true};
                    const obj = {
                   dimensionName: 'COMMODITY_MANAGER_NAME',
                    value: item
                 }
             this.scopeDataService.setSelectedItemsByDimension(obj,true);
                  }
          else{
                  singleItem[item] = {};
          }
                if (listData !== undefined) {
                  Object.assign(listData, singleItem);
                }
              }
              this.scopeDataService.setDefaultScopeByDimensions({
                dimensionName: data['dimensionName'],
                data: listData
              });
              this.dimensionFiltersObjectData = listData;
              this.dimensionFiltersObjectDataList = Object.keys(this.dimensionFiltersObjectData);
              if (this.autosearch) {
                this.onSelectedItem(this.autosearchdata.value, undefined)
              }

            }
          } else {
          }
        },
        () => { },
        () => { this.loader = false; }
      );
    } else {
    }

  }
  onSelectedItem(item: string, $event): void {

    if ($event !== undefined) {
      $event.stopPropagation();
    }
    this.updateStatus(item)
    this.scopeService.setClickOnList('true')
  }

  updateStatus(value) {

    const obj = {
      dimensionName: this.dimension['dimensionName'],
      value
    };
    if (this.dimensionFiltersObjectData && this.dimensionFiltersObjectData[value] !== undefined) {
      if (this.dimensionFiltersObjectData[value]['isSelected'] === undefined) {
        this.dimensionFiltersObjectData[value] = { isSelected: true };
      } else {
        this.dimensionFiltersObjectData[value]['isSelected'] = this.dimensionFiltersObjectData[value]['isSelected'] === true ? false : true;
      }
    }

    this.scopeDataService.setSelectedItemsByDimension(obj);
  }

  getFilterDimensionData(dimension: string) {
    // if (this.displayView !== "only_selected" ) {
    // console.log(document.getElementById(this.dimension['dimensionName']).classList);
    if (this.dimensionFiltersObjectData === undefined) {
      this.dimensionFiltersObjectDataList = [];
    }
    if (this.displayView === undefined || this.displayView === 'default') {
      if (!document.getElementById(this.dimension['dimensionName']).classList.contains('in')) {
        const getDimensionData = this.scopeDataService.getDefaultDimensionDataByDimension(dimension);
        if (getDimensionData === undefined) {
          this.getDimensionDataFromServer(dimension);
        } else {
          this.dimensionFiltersObjectData = getDimensionData;
          this.dimensionFiltersObjectDataList = Object.keys(this.dimensionFiltersObjectData);
        }
      }
    }
  }

  ngOndestroy() {
    if (this.autosearchsubscription) {
      this.autosearchsubscription.unsubscribe();
    }
    // if (this.drilldownSubscription) {
    // 	this.drilldownSubscription.unsubscribe();
    // }
  }

  /**
	   * Manage Filters Changes
	   * Start
	   */

  onManageFilterSelected(userScopeFilterDimensions: Object, dimesionFilterObject: Object) {
    Object.keys(dimesionFilterObject).forEach(
      dimesionFilter => {
        if (userScopeFilterDimensions[dimesionFilter] instanceof Object) {
          dimesionFilterObject[dimesionFilter] = { isSelected: true };
        }
      }
    );
  }

  onManageFilterApplied(dimensionData: Object, selectedScopeFilter: Object) {
    this.onManageFilterSelected(selectedScopeFilter['dimensions'][this.dimension.dimensionName], dimensionData);
    this.scopeDataService.setDefaultScopeByDimensions({
      dimensionName: this.dimension.dimensionName,
      data: dimensionData
    });
    this.scopeDataService.onUpdateManageFilter(selectedScopeFilter, this.dimension.dimensionName);
    this.dimensionFiltersObjectData = dimensionData;
    this.dimensionFiltersObjectDataList = Object.keys(this.dimensionFiltersObjectData);
  }

  onAdvancedFilterApplied() {
    this.showAdvancedFilter = false;
    const filterFunction: Function = this.retrieveFilterFunction(this.advanceFilterSelector).bind(this);
    const advancedFilterObject: Object = {
      displayName: this.dimension.displayName,
      filterSelected: this.advanceFilterSelector,
      filterParameter: this.advancedFilterName,
      advancedScopeFilterName: ` ${this.advanceFilterSelector} '${this.advancedFilterName}'`
    };
    this.applyAdvancedFilterParameters(this.retrieveLowerCase(this.filterWhiteSpaces(this.advancedFilterName.split(','))), filterFunction, advancedFilterObject);
  }

  private filterWhiteSpaces(searchParameters: string[]): string[] {
    return searchParameters.filter(searchParameter => !(searchParameter.replace(/\s/g, '').length === 0));
  }

  private retrieveFilterFunction(filterSelector: string): Function {
    switch (filterSelector) {
      case 'Equal to':
        return (searchTermArray: string[], dimensionValue: string) => searchTermArray.some(x => x === dimensionValue);
      case 'Does Not Equal to':
        return (searchTermArray: string[], dimensionValue: string) => searchTermArray.every(x => x !== dimensionValue);
      case 'Begins with':
        return (searchTermArray: string[], dimensionValue: string) => searchTermArray.some(x => dimensionValue.startsWith(x));
      case 'Does not begin with':
        return (searchTermArray: string[], dimensionValue: string) => searchTermArray.every(x => !(dimensionValue.startsWith(x)));
      case 'Ends with':
        return (searchTermArray: string[], dimensionValue: string) => searchTermArray.some(x => dimensionValue.endsWith(x));
      case 'Does not end with':
        return (searchTermArray: string[], dimensionValue: string) => searchTermArray.every(x => !(dimensionValue.endsWith(x)));
      case 'Contains':
        return (searchTermArray: string[], dimensionValue: string) => searchTermArray.some(x => dimensionValue.includes(x));
      case 'Does not contain':
        return (searchTermArray: string[], dimensionValue: string) => searchTermArray.every(x => !(dimensionValue.includes(x)));
    }
  }

  private retrieveLowerCase(wordArray: string[]): string[] {
    const searchTermArray = [];
    wordArray.forEach((x: string) => {
      searchTermArray.push(x.trim().toLowerCase())
    });
    return searchTermArray;
  }

  private applyAdvancedFilterParameters(searchParameters: string[], filterFunction: Function, advancedScopeFilter: Object) {
    const advancedFilterSelectionData: Object = {};
    Object.keys(this.dimensionFiltersObjectData)
      .filter(dimensionKey => filterFunction(searchParameters, dimensionKey.trim().toLowerCase()))
      .forEach(dimensionKey => {
        this.dimensionFiltersObjectData[dimensionKey] = { isSelected: true };
        advancedFilterSelectionData[dimensionKey] = { isSelected: true };
      });
    this.scopeDataService.onAdvancedFilterApplied(advancedFilterSelectionData, this.dimension.dimensionName, advancedScopeFilter);
  }

  onClearAppliedParameters() {
    this.advanceFilterSelector = HEADER_OBJECT.NO_VALUE;
    this.advancedFilterName = HEADER_OBJECT.NO_VALUE;
    Object.keys(this.dimensionFiltersObjectData).forEach(dimensionValue => {
      this.dimensionFiltersObjectData[dimensionValue] = {};
    });
    this.scopeDataService.onAdvancedFilterCleared(this.dimension.dimensionName);
  }

  /**
	 * Advanced Filter Changes
	 * End
	 */

}
