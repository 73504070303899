import { Injectable } from '@angular/core'
import { ApiServiceProvider } from 'app/api-service'
@Injectable()
export class GraphItemService extends ApiServiceProvider {
  getGraphValues(payload, filter?) {
    const requestPayload: any = {
      targetedType: payload.targetedType,
      method: 'POST',
      generic: false,
      formParams: {
        visualCardKey: payload.visualCardKey,
        ...payload.formParams
      },
      payload: payload.itemKeys,
      servicePath: payload.endPoint.trim(),
    }
    if (payload.metaData.viewBy) {
      requestPayload.formParams.viewBy = filter
    }
    return this.post(requestPayload)
  }
}
