import { Injectable } from '@angular/core';
import { ApiServiceProvider } from 'app/api-service';
import { HTTP_REQUEST_TYPES } from 'app/modules/npi/npiconstants';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class GridDropdownService {
  private env = environment;
  constructor(private apiService: ApiServiceProvider) { }
  public getListBySearchText(
    servicePath: string,
    key = '',
    count = 500,
    defaultParameter?,
    type?
  ): Observable<any> {
    let payload = null;
    if (type) {
      payload = {
        targetedType: `${this.env[type]}`,
        method: HTTP_REQUEST_TYPES.GET,
        servicePath,
        formParams: {
          count,
          key,
        },
      };
    } else {
      payload = {
        targetedType: `${this.env.content.contentManagementService}`,
        method: HTTP_REQUEST_TYPES.GET,
        servicePath: `${this.env.content.contentManagementService}${servicePath}`,
        formParams: {
          count,
          key,
        },
      };
    }
    if (defaultParameter) {
      payload.formParams = { ...payload.formParams, ...defaultParameter };
    }
    return this.apiService.post(payload);
  }
}
