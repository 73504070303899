import { Component, ViewChild, ViewContainerRef } from '@angular/core';

import {
  IAfterGuiAttachedParams,
  IDoesFilterPassParams,
  IFilterParams,
  RowNode,
} from '@ag-grid-community/all-modules';
import { IFilterAngularComp } from '@ag-grid-community/angular';
import { DataStorageService } from 'app/modules/common/data-storage.service';

@Component({
  selector: 'filter-cell',
  template: `
    <div class="ag-tab-body">
      <div class="ag-menu-list" *ngFor="let targetNode of nodes">
        <div class="ag-menu-option">
          <span class="ag-menu-option-icon"></span>
          <span class="ag-menu-option-text">{{ targetNode }}</span>
        </div>

        <div class="ag-menu-option">
          <span class="ag-menu-option-icon"></span>
          <button (click)="expandAll(targetNode)" class="btn btn-link">
            Expand All
          </button>

          <span class="ag-menu-option-shortcut"></span>
          <span class="ag-menu-option-popup-pointer"></span>
        </div>
        <div class="ag-menu-option">
          <span class="ag-menu-option-icon"></span>
          <button (click)="collapseAll(targetNode)" class="btn btn-link">
            Collapse All
          </button>

          <span class="ag-menu-option-shortcut"></span>
          <span class="ag-menu-option-popup-pointer"></span>
        </div>
        <div class="ag-menu-separator">
          <span class="ag-menu-separator-cell"></span>
          <span class="ag-menu-separator-cell"></span>
          <span class="ag-menu-separator-cell"></span>
          <span class="ag-menu-separator-cell"></span>
        </div>
      </div>
    </div>
  `,

  styles: ['src/assets/styles/ag-grid/ag-grid.sass'],
})
export class ExpandCollapseAllComponent implements IFilterAngularComp {
  @ViewChild('input', { read: ViewContainerRef, static: true }) public input;
  private params: IFilterParams;
  private valueGetter: (rowNode: RowNode) => any;
  public text = '';
  public expandedAtSALevel = false;
  public noSubAssemblies = false;
  public nodes = [];

  constructor(public dataStorageService: DataStorageService) {}
  agInit(params: IFilterParams): void {
    this.params = params;
    this.nodes = params['nodes'];
    this.valueGetter = params.valueGetter;
  }

  isFilterActive(): boolean {
    return this.text !== null && this.text !== undefined && this.text !== '';
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return this.text
      .toLowerCase()
      .split(' ')
      .every((filterWord) => {
        return (
          this.valueGetter(params.node)
            .toString()
            .toLowerCase()
            .indexOf(filterWord) >= 0
        );
      });
  }

  getModel(): any {
    return { value: this.text };
  }

  setModel(model: any): void {
    this.text = model ? model.value : '';
  }
  componentMethod(message: string): void {
    alert(`Alert from ExpandCollapseAllComponent ${message}`);
  }

  expandAll(targetNode) {
    if (
      this.params.colDef.headerName !== 'Level' &&
      !this.params.api.getColumnDef('levelName')
    ) {
      this.params.api.forEachNode((node) => {
        if (node.data.nodeType === targetNode) {
          node.expanded = true;
        }
      });
    } else {
      this.params.api.forEachNode((node) => {
        node.expanded = true;
      });
    }

    this.params.api.onGroupExpandedOrCollapsed();
  }
  collapseAll(targetNode) {
    if (
      this.params.colDef.headerName !== 'Level' &&
      !this.params.api.getColumnDef('levelName')
    ) {
      this.params.api.forEachNode((node) => {
        if (node.data.nodeType === targetNode) {
          node.expanded = false;
        }
      });
    } else {
      this.params.api.forEachNode((node) => {
        node.expanded = false;
      });
    }

    this.params.api.onGroupExpandedOrCollapsed();
  }
}
