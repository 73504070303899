import { Injectable } from '@angular/core'
import { ApiServiceProvider } from 'app/api-service'
import {
  HTTP_REQUEST_TYPES,
} from 'app/modules/npi/npiconstants'
import { environment } from 'environments/environment'
import { Observable, Subject } from 'rxjs'
import { tap } from 'rxjs/operators'

const INITIAL_VALUE = 'initialValue'
const LATEST_VALUE = 'latestValue'
@Injectable()
export class EventManagementService {
  uniqueKey = 'vendorSiteId'
  showCustomerEventOnly = false
  tenantId = null
  env = environment
  countryRegionMap = null
  mappedRegion = ''
  mappedLatLongs = null
  latLongMap = null
  changedValMap = {}
  editedRows = []
  errorList = []
  headers = []
  originalRow = []
  rowsUpdateSub = new Subject<boolean>()
  statusUpdateSub = new Subject<boolean>()
  constructor(
    private apiService: ApiServiceProvider
  ) {}

  getRows(
    paginatorObject?,
    filterObj?
  ): Observable<any> {
    const filterPayload: any = { ...filterObj }
    filterPayload.pagingConfiguration = paginatorObject
    if (this.showCustomerEventOnly) {
      filterPayload.source_id = {
        filter: this.tenantId,
        filterType: 'text',
        type: 'equals'
      }
      filterPayload.status = {
        filter: filterPayload.status || 'Active',
        filterType: 'text',
        type: 'equals'
      }
    }
    const payload = {
      targetedType: 'riskManagementService',
      method: HTTP_REQUEST_TYPES.POST,
      servicePath: `events/list`,
      payload: filterPayload,
    }

    return this.apiService.post(payload).pipe(tap(() => {
      this.rowsUpdateSub.next(true)
    }))
  }

  parseFilters(data) {
    const columnFilters = []
    // dataTypes: varchar, set, date, boolean, ''
    data.forEach((item) => {
      if (item.headerMetaInfo.dataType !== '' && !item.headerInfo.hidden) {
        const filter = {
          name: item.headerInfo.headerName,
          columnDataType: item.headerMetaInfo.dataType,
          type: item.headerMetaInfo.dataType === 'varchar' ? 'text' : item.headerMetaInfo.dataType,
          dbName: item.headerInfo.field,
          url: item.headerInfo.url,
          inputType: item.headerInfo.inputType
        }
        if (item.headerMetaInfo.dataType === 'set') {
          switch (item.headerMetaInfo.dbColumn.toLowerCase()) {
            case 'city':
            case 'country':
            case 'state':
              item.headerMetaInfo.dataType = 'varchar'
              filter.columnDataType = item.headerMetaInfo.dataType
              columnFilters.push(filter)
              break
            case 'sitetype':
              item.headerMetaInfo.dataType = 'varchar'
              filter.type = 'text'
              columnFilters.push(filter)

          }
        } else if (item.headerMetaInfo.dataType === 'decimal') {
          item.headerMetaInfo.dataType = 'varchar'
          filter.columnDataType = item.headerMetaInfo.dataType
          filter.type = 'number'
          columnFilters.push(filter)
        } else {
          columnFilters.push(filter)
        }
      }
    })
    return columnFilters
  }

  getCellClass(params) {
    let cellClass = ''
    const errorFlag = `${params.colDef.field}_valid`
    const modifiedFlag = `${params.colDef.field}_modified`
    if (params.colDef.editable !== undefined && params.colDef.editable) {
      if (
        params.data[errorFlag] !== undefined &&
        params.data[errorFlag] !== null
      ) {
        cellClass = 'grid-cell-value-error'
      } else {
        if (
          params.data[modifiedFlag] !== undefined &&
          params.data[modifiedFlag]
        ) {
          cellClass = 'grid-cell-value-changed'
        } else {
          cellClass = 'grid-cell-editable'
        }
      }
    }
    return cellClass
  }

  bulkUpdateByIds(data: any, eventIds: string[]): Observable<any> {
    if (data.is_approved) {
      data = {
        status: 'Active'
      }
    }
    else if(data.is_curation) {
      data = {
        curated_flag: true
      }
    }
    else {
        data = {
          status: 'Inactive'
        }
    }
    const payload = {
      targetedType: `${this.env.risk}`,
      method: HTTP_REQUEST_TYPES.PUT,
      servicePath: `events/bulk/update`,
      payload: {
        data,
        filter: {},
        ids: eventIds,
      },
    }
    const req = this.apiService.post(payload)
    if (this.showCustomerEventOnly) {
      return req.pipe(tap(() => {
        this.statusUpdateSub.next(true)
      }))
    }
    return req
  }

  prepareEditedRows(editedRow, keyVariable) {
    const editedRowId = editedRow[keyVariable]

    if (!this.editedRows.length) {
      this.editedRows.push(editedRow)
    } else {
      const filteredEditedRow = this.editedRows.filter(
        (match) => match[keyVariable] === editedRowId
      )
      if (filteredEditedRow.length) {
        filteredEditedRow[0] = editedRow
      } else {
        this.editedRows.push(editedRow)
      }
    }
  }

  prepareChangedValueMap(params) {
    if (params.newValue === params.oldValue) {
      return
    }
    const columnName = params.field
    const rowPrimaryKeyValue = params.keyId
    const currChangedRow = this.changedValMap[rowPrimaryKeyValue]

    if (!!currChangedRow) {
      const currColumn = currChangedRow[columnName]
      if (!!currColumn) {
        currColumn[LATEST_VALUE] =
          params.newValue !== null ? params.newValue : ''
      } else {
        currChangedRow[columnName] = {}
        currChangedRow[columnName][INITIAL_VALUE] =
          params.oldValue !== null ? params.oldValue : ''
        currChangedRow[columnName][LATEST_VALUE] =
          params.newValue !== null ? params.newValue : ''
      }
    } else {
      const currEditedObj = {}
      currEditedObj[rowPrimaryKeyValue] = {}
      currEditedObj[rowPrimaryKeyValue][columnName] = {}
      currEditedObj[rowPrimaryKeyValue][columnName][INITIAL_VALUE] =
        params.oldValue !== null ? params.oldValue : ''
      currEditedObj[rowPrimaryKeyValue][columnName][LATEST_VALUE] =
        params.newValue !== null ? params.newValue : ''
      this.changedValMap = Object.assign({}, this.changedValMap, currEditedObj)
    }
  }

  updateModifiedRows(params, keyVariable) {
    const editRow = params.data
    if (Object.keys(this.changedValMap).length > 0) {
      for (const rowKeyValue in this.changedValMap) {
        if (this.changedValMap[rowKeyValue] !== undefined) {
          const currRowMapValues = this.changedValMap[rowKeyValue]
          if (!!currRowMapValues && Object.keys(currRowMapValues).length > 0) {
            let isAnyActualChangeExistInEntireRow = false
            for (const colKeyValue in currRowMapValues) {
              if (currRowMapValues[colKeyValue] !== undefined) {
                const modifiedFlag = colKeyValue + '_modified'
                if (
                  currRowMapValues[colKeyValue][LATEST_VALUE] !==
                  currRowMapValues[colKeyValue][INITIAL_VALUE]
                ) {
                  isAnyActualChangeExistInEntireRow = true
                  editRow[modifiedFlag] = true
                  break
                } else {
                  editRow[modifiedFlag] = false
                }
              }
            }
            if (!isAnyActualChangeExistInEntireRow) {
              const filteredEditedRows = this.editedRows.filter(
                (editedRow) => editedRow[keyVariable] !== rowKeyValue
              )
              this.editedRows = filteredEditedRows
            }
            for (const colKeyValue in currRowMapValues) {
              if (currRowMapValues[colKeyValue] !== undefined) {
                const modifiedFlag = colKeyValue + '_modified'
                if (
                  currRowMapValues[colKeyValue][LATEST_VALUE] !==
                  currRowMapValues[colKeyValue][INITIAL_VALUE]
                ) {
                  editRow[modifiedFlag] = true
                } else {
                  editRow[modifiedFlag] = false
                }
              }
            }
          }
        }
      }
    }
  }

  triggerMail(formParams): Observable<any> {
    const payload = {
      targetedType: 'riskManagementService',
      method: HTTP_REQUEST_TYPES.POST,
      servicePath: `events/email/trigger`,
      formParams
    }

    return this.apiService.post(payload);
  }
}
