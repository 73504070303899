import { ColDef } from '@ag-grid-community/all-modules'
import { ICellRendererAngularComp } from '@ag-grid-community/angular'
import { Component } from '@angular/core'
export interface LocationDetails {
  country: string
  state: string
  city: string
  site: string
  longitude: number
  latitude: number
  region: string
  eventId: string
  locationId: string
  vendorImpacted?: any[]
}
@Component({
  selector: 'cprt-detail-location-cell-renderer',
  templateUrl: './detail-location-cell-renderer.component.html',
  styleUrls: ['./detail-location-cell-renderer.component.sass'],
})
export class DetailLocationCellRendererComponent
  implements ICellRendererAngularComp {
  params
  colDefs: ColDef[] = []
  masterGridApi
  rowId
  defaultColDef
  rowData = []
  defaultLocations = []
  showLoader = true
  newsEventService = null
  areLocationsInValid = false

  countryListConfiguration = {
    label: 'Country',
    type: 'newautosearch',
    filterConfig: {
      multiSelect: false,
      targetedType: 'riskManagementService',
      servicePath: 'risk/search/country',
      defaultItemSelection: true,
      defaultSelectedItem: null,
    },
    hideLabel: true,
    val: 'country',
    data: [],
    id: `country-auto-select`,
    defaultParameter: null,
    resetValues: this.generateRandomKey(),
  }
  stateListConfiguration = {
    label: 'State',
    type: 'newautosearch',
    filterConfig: {
      multiSelect: false,
      targetedType: 'riskManagementService',
      servicePath: 'risk/search/state',
      defaultItemSelection: true,
      defaultSelectedItem: null,
    },
    hideLabel: true,
    val: 'state',
    data: [],
    id: `state-auto-select`,
    defaultParameter: null,
    resetValues: this.generateRandomKey(),
  }
  cityListConfiguration = {
    label: 'City',
    type: 'newautosearch',
    filterConfig: {
      multiSelect: false,
      targetedType: 'riskManagementService',
      servicePath: 'risk/search/city',
      defaultItemSelection: true,
      defaultSelectedItem: null,
    },
    hideLabel: true,
    val: 'city',
    data: [],
    id: `city-auto-select`,
    defaultParameter: null,
    resetValues: this.generateRandomKey(),
  }
  siteListConfiguration = {
    label: 'Sites Impacted',
    type: 'newautosearch',
    filterConfig: {
      multiSelect: false,
      targetedType: 'riskManagementService',
      servicePath: 'risk/search/sites',
      defaultItemSelection: true,
      defaultSelectedItem: null,
    },
    hideLabel: true,
    val: 'siteImpacted',
    data: [],
    id: `site-auto-select`,
    defaultParameter: null,
    resetValues: this.generateRandomKey(),
  }
  loaderConfig = {
    backdropBackgroundColour: '#ffffff',
    fullScreenBackdrop: 'true',
    primaryColour: '#1C4E84',
    secondaryColour: '#1C4E84',
    tertiaryColour: '#1C4E84',
  }

  agInit(params: any): void {
    this.params = params

    this.masterGridApi = params.api
    this.rowId = params.node.id
    console.log(params)

    this.colDefs = params.columDefs
    this.newsEventService = params.context.componentParent.newsEventService
    this.getLocationDetails()
  }

  getLocationDetails() {
    this.params.data.locations.forEach((locationData) => {
      const location = {
        countryObject: {
          name: 'country',
          config: JSON.parse(JSON.stringify(this.countryListConfiguration)),
        },
        stateObject: {
          name: 'state',
          config: JSON.parse(JSON.stringify(this.stateListConfiguration)),
        },
        cityObject: {
          name: 'city',
          config: JSON.parse(JSON.stringify(this.cityListConfiguration)),
        },
        siteObject: {
          name: 'siteImpacted',
          config: JSON.parse(JSON.stringify(this.siteListConfiguration)),
        },
        properties: { ...locationData },
      }
      location.countryObject.config.filterConfig.defaultSelectedItem =
        locationData.country

      location.stateObject.config.filterConfig.defaultSelectedItem =
        locationData.state
      location.stateObject.config.defaultParameter = {
        country: locationData.country,
      }

      location.cityObject.config.filterConfig.defaultSelectedItem =
        locationData.city
      location.cityObject.config.defaultParameter = {
        state: locationData.state,
      }

      location.siteObject.config.filterConfig.defaultSelectedItem =
        locationData.siteImpacted
      location.siteObject.config.defaultParameter = {
        country: locationData.country,
        state: locationData.state,
        city: locationData.city,
      }
      this.rowData.push(location)
    })
    this.defaultLocations = JSON.parse(JSON.stringify(this.rowData))
    setTimeout(() => {
      this.showLoader = false
    }, 1000)
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    return false
  }

  generateRandomKey() {
    const crypto = window.crypto
    const array = new Uint32Array(1)
    return crypto.getRandomValues(array)[0]
  }

  initLocationDetails(location) {
    location.country = null
    location.state = null
    location.city = null
    location.siteImpacted = null
    location.region = null
    location.latitude = null
    location.longitude = null
    location.eventId = this.params.data.eventId
    location.locationId = null
    return location
  }

  onLocationPropSelect(event, locationRow?, locationIndex?) {
    if (locationRow && locationIndex !== undefined) {
      const propertyValue = event.selectedItem
        ? event.selectedItem.val || null
        : null
      if (locationRow.properties.hasOwnProperty(event.type)) {
        locationRow.properties[event.type] = propertyValue
      }
      switch (event.type) {
        case 'country':
          locationRow.stateObject.config.defaultParameter = {
            country: propertyValue,
          }
          locationRow.stateObject.config.resetValues = this.generateRandomKey()
          locationRow.cityObject.config.resetValues = this.generateRandomKey()
          locationRow.siteObject.config.resetValues = this.generateRandomKey()

          this.newsEventService.setRegion(propertyValue)
          locationRow.properties.region = this.newsEventService.getRegion()

          locationRow.properties.state = null
          locationRow.properties.city = null
          locationRow.properties.siteImpacted = null
          locationRow.properties.latitude = null
          locationRow.properties.longitude = null
          break
        case 'state':
          locationRow.cityObject.config.defaultParameter = {
            state: propertyValue,
          }

          locationRow.cityObject.config.resetValues = this.generateRandomKey()
          locationRow.siteObject.config.resetValues = this.generateRandomKey()
          locationRow.properties.city = null
          locationRow.properties.siteImpacted = null
          locationRow.properties.latitude = null
          locationRow.properties.longitude = null
          break
        case 'city':
          locationRow.siteObject.config.defaultParameter = {
            country: locationRow.properties.country,
            state: locationRow.properties.state,
            city: locationRow.properties.city,
          }
          locationRow.siteObject.config.resetValues = this.generateRandomKey()
          this.newsEventService.setLatLongs(propertyValue)
          locationRow.properties.siteImpacted = null
          locationRow.properties.latitude = null
          locationRow.properties.longitude = null

          const mappedLatLongs = this.newsEventService.getLatLongs()
          if (mappedLatLongs) {
            locationRow.properties.latitude = mappedLatLongs.latitude
            locationRow.properties.longitude = mappedLatLongs.longitude
          }
          break
      }
      this.rowData[locationIndex].properties = JSON.parse(
        JSON.stringify(locationRow.properties)
      )
      this.mandatoryFilledCheck()
    }
  }

  mandatoryFilledCheck() {
    let validLocationCount = 0
    let invalidLocation = false
    this.rowData.forEach((location) => {
      const props = location.properties
      if (props.country && props.state && props.city && props.siteImpacted) {
        validLocationCount++
      } else {
        invalidLocation = true
        return
      }
    })
    const locationsModifiedEvents = this.params.context.componentParent
      .locationsModifiedEvents
    const locationsErrorEvents = this.params.context.componentParent
      .locationsErrorEvents
    const eventId = this.params.data.eventId

    if (this.rowData.length === validLocationCount) {
      this.areLocationsInValid = false
      const defaultLocationProperties = this.defaultLocations.map(
        (location) => location.properties
      )
      const modifiedLocationProperties = this.rowData.map(
        (location) => location.properties
      )
      if (
        JSON.stringify(defaultLocationProperties) ===
        JSON.stringify(modifiedLocationProperties)
      ) {
        if (locationsModifiedEvents.includes(eventId)) {
          this.params.context.componentParent.locationsModifiedEvents = locationsModifiedEvents.filter(
            (event) => event !== eventId
          )
        }
        if (locationsErrorEvents.includes(eventId)) {
          this.params.context.componentParent.locationsErrorEvents = locationsErrorEvents.filter(
            (event) => event !== eventId
          )
        }
        this.params.context.componentParent.toggleActionButtons()
      } else {
        const editedRows = this.params.context.componentParent.editedRows
        const filteredRow = editedRows.filter(
          (editedRow) => (editedRow.eventId = this.params.data.eventId)
        )
        if (filteredRow.length) {
          filteredRow[0].locations = modifiedLocationProperties
        } else {
          const modifiedRow = JSON.parse(JSON.stringify(this.params.data))
          modifiedRow.locations = modifiedLocationProperties
          editedRows.push(modifiedRow)
        }

        if (!locationsModifiedEvents.includes(eventId)) {
          this.params.context.componentParent.locationsModifiedEvents.push(
            eventId
          )
        }
        if (locationsErrorEvents.includes(eventId)) {
          this.params.context.componentParent.locationsErrorEvents = locationsErrorEvents.filter(
            (event) => event !== eventId
          )
        }

        // this.areLocationsInValid = true;
        this.params.context.componentParent.toggleActionButtons()
      }
    } else {
      this.areLocationsInValid = true
      if (locationsModifiedEvents.includes(eventId)) {
        this.params.context.componentParent.locationsModifiedEvents = locationsModifiedEvents.filter(
          (event) => event !== eventId
        )
      }
      if (!locationsErrorEvents.includes(eventId)) {
        this.params.context.componentParent.locationsErrorEvents.push(eventId)
      }
      this.params.context.componentParent.toggleActionButtons()
    }
  }

  deleteLocation(rowIndex, selectedLocation, event) {
    event.stopPropagation()
    this.rowData.splice(rowIndex, 1)
    this.mandatoryFilledCheck()
  }

  onClearAllLocations() {
    this.rowData = []
    this.showLoader = true
    this.rowData = JSON.parse(JSON.stringify(this.defaultLocations))
    this.mandatoryFilledCheck()
    setTimeout(() => {
      this.showLoader = false
    }, 100)
  }

  addLocation(rowIndex, event) {
    event.stopPropagation()
    const location = {
      countryObject: {
        name: 'country',
        config: JSON.parse(JSON.stringify(this.countryListConfiguration)),
      },
      stateObject: {
        name: 'state',
        config: JSON.parse(JSON.stringify(this.stateListConfiguration)),
      },
      cityObject: {
        name: 'city',
        config: JSON.parse(JSON.stringify(this.cityListConfiguration)),
      },
      siteObject: {
        name: 'siteImpacted',
        config: JSON.parse(JSON.stringify(this.siteListConfiguration)),
      },
      properties: this.initLocationDetails({} as LocationDetails),
    }
    location.countryObject.config.filterConfig.defaultItemSelection = false
    location.stateObject.config.filterConfig.defaultItemSelection = false
    location.cityObject.config.filterConfig.defaultItemSelection = false
    location.siteObject.config.filterConfig.defaultItemSelection = false
    this.rowData.push(location)
    this.mandatoryFilledCheck()
  }
}
