import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Router } from '@angular/router';
import { DataStorageService } from 'app/modules/common/data-storage.service';

@Component({
    selector: 'child-cell',
    template: `
    <input *ngIf="databaseColumnName === 'SHARE_TRGT_MPN_COST' && !checkedSharedCost " type="checkbox" data-md-icheck  class="form-control border-checkbox" checked={{checkedString}} (click)="selectMpnTarget($event)"  >
    <input *ngIf="databaseColumnName === 'SHARE_TRGT_MPN_COST'  && checkedSharedCost" type="checkbox" data-md-icheck  class="form-control border-checkbox" (click)="selectMpnTarget($event)" >
    <input *ngIf="databaseColumnName === 'SHARE_MPN_LEVERS' && !checkedMPNLever " type="checkbox" data-md-icheck  class="form-control border-checkbox" checked={{checkedMPNString}} (click)="selectMpnLevers($event)">
    <input *ngIf="databaseColumnName === 'SHARE_MPN_LEVERS' && checkedMPNLever" type="checkbox" data-md-icheck  class="form-control border-checkbox" (click)="selectMpnLevers($event)" >`
    ,
})
export class SharedTargetCostComponent implements OnInit {
    public params: any;
    val: any;
    checkedString: any = ''
    checkedMPNString: any = ''
    checkedSharedCost = false;
    checked = false
    databaseColumnName= ''
    checkedMPNLever = false;

    constructor(private router: Router, private dataStorageService: DataStorageService) {

    }
    agInit(params: any): void {

        this.databaseColumnName = params['colDef']['field']
        this.val = params.value;
        if (this.val === 1) {
            if (this.databaseColumnName === 'SHARE_MPN_LEVERS') {
                this.checkedMPNLever = false;
            }
            if (this.databaseColumnName === 'SHARE_TRGT_MPN_COST') {
                this.checkedSharedCost = false;
            }
        }
        this.params = params;
    }
    ngOnInit() {
        this.dataStorageService.getSharedTargetChecked().subscribe(
            (sharedTarget) => {
                if (sharedTarget !== undefined) {
                    if (sharedTarget ) {
                        this.val = 1
                        this.checkedSharedCost = false
                        this.checkedString = true
                    }
                    else {
                        this.val = 0
                        this.checkedSharedCost = true
                        this.checkedString = false
                    }

                }
            })
        this.dataStorageService.getMpnSharedChecked().subscribe((mpnSharedCost) => {
            if (mpnSharedCost !== undefined) {
                if (mpnSharedCost) {
                    this.val = 1
                    this.checkedMPNLever = false
                    this.checkedMPNString = true
                }
                else {
                    this.val = 0
                    this.checkedMPNLever = true;
                    this.checkedMPNString = false
                }

            }
        })
    }

    selectMpnTarget(event) {
        console.log(event)
        if (this.val === 0) {
            this.val = 1
        }
        if (this.val === 1) {
            this.val = 0;
        }
    }
    selectMpnLevers(event){
        if (this.val === 0) {
            this.val = 1
        }
        if (this.val === 1) {
            this.val = 0;
        }
    }
}
