import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiServiceProvider } from 'app/api-service';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
import { RequestData } from 'app/modules/common/models/request.model';
import { ISingeItem } from 'app/modules/common/models/scope/singleItem';
import { PayLoad } from '../../../common/models/payload.model';

@Injectable()
export class AutoSearchService {
  constructor(private apiServiceProvider: ApiServiceProvider) { }

  searchMultiELS(terms: Observable<string>, requestData: RequestData) {
    let elsterm;
    if (
      requestData.payload &&
      requestData.payload.inputs &&
      requestData.payload.inputs.length > 0
    ) {
      elsterm = requestData.payload.inputs;
    }
    return (
      terms
        .debounceTime(400)
        .distinctUntilKeyChanged(elsterm)
        // .distinctUntilChanged()
        .switchMap((term) => this.searchMultiEntriesELS(term, requestData))
    );
  }

  searchMultiEntriesELS(term, requestData) {
    if (requestData.payload) {
      if (
        requestData.hasOwnProperty('component') &&
        term.length > 0 &&
        (requestData.component === 'np' ||
          requestData.component === 'reports' ||
          requestData.component === 'projectedreport'
        )
      ) {
        term = '"' + term + '"';
      }
      requestData.payload.inputs = [term];
    }
    return this.apiServiceProvider.post(requestData);
  }

  searchCustom(terms: Observable<string>, requestData: RequestData) {
    let custtrm;
    if (
      requestData.formParams &&
      requestData.formParams['userInput'].length > 0
    ) {
      custtrm = requestData.formParams['userInput'];
    }
    if (requestData.payload && requestData.payload['userInput'].length > 0) {
      custtrm = requestData.payload['userInput'];
    }
    return (
      terms
        .debounceTime(400)
        .distinctUntilKeyChanged(custtrm)
        // .distinctUntilChanged()
        .switchMap((term) => this.searchCustomEntries(term, requestData))
    );
  }

  searchCustomEntries(term, requestData) {
    if (term === '') {
      term = '55';
    }
    // requestData.payload.inputs = [term];
    if (requestData.formParams !== undefined) {
      if (
        requestData.formParams.hasOwnProperty('dimension') ||
        requestData.formParams.hasOwnProperty('dimensions') || requestData.component === 'partmatching'
      ) {
        if (term === '0' || term === '' || term === '55') {
          term = '';
        }
      }
      if (requestData.hasOwnProperty('component') && term.length > 0 && (requestData.component === 'partmatching')) {
        requestData.formParams['userInput'] = encodeURIComponent(term);
        requestData.formParams['inputs'] = [];
        requestData.formParams['inputs'].push(encodeURIComponent(term));
      }
      else {
        requestData.formParams['userInput'] = term;
        requestData.formParams['inputs'] = [];
        requestData.formParams['inputs'].push(term);
      }
    }
    if (requestData.payload !== undefined) {
      if (term === '0' || term === '' || term === '55') {
        term = '';
      }
      requestData.payload['userInput'] = term;
    }

    return this.apiServiceProvider.post(requestData);
  }
  searchCustomLeverEntries(term, requestData) {
    if (requestData.formParams !== undefined) {
      requestData.formParams['leverCat'] = term;
    }
    return this.apiServiceProvider.post(requestData);
  }

  onConvertArrayForAutoDimnsionSelect(source, dimension: Array<any>) {
    const newObject: any = {};
    if (dimension.length > 0) {
      for (const item of source) {
        for (const dimensionName of dimension) {
          if (item['internalName'] === dimensionName) {
            const listItems: any = {};
            for (const list of item['list']) {
              const singleItem: ISingeItem<any> = {};
              singleItem[list] = {};
              Object.assign(listItems, singleItem);
            }
            newObject[item['externalName']] = listItems;
          } else {
            // console.log("dimension name not matched")
          }
        }
      }
    }

    return newObject;
  }

  onConvertArrayObjectKeysWithDimension(source, dimension: Array<any>) {
    const valuArray: any[] = new Array();
    if (dimension.length > 0) {
      for (const item of source) {
        for (const dimensionName of dimension) {
          if (item['internalName'] === dimensionName) {
            for (const list of item['list']) {
              const sing = {
                value: '',
                check: false,
                dimension: dimensionName,
              };
              sing.value = list;
              valuArray.push(sing);
            }
          } else {
            // "dimension name not matched"
          }
        }
      }
    } else {
      return this.onConvertArrayObjectKeys(source);
    }
    return valuArray;
  }

  onConvertArrayObjectKeys(source) {
    const valuArray: any[] = new Array();
    for (const item of source) {
      for (const list of item['list']) {
        const sing = {
          value: '',
          check: false,
        };
        sing.value = list;
        valuArray.push(sing);
      }
    }
    return valuArray;
  }

  onConvertCustomMulti(source, dimension) {
    const valuArray: any[] = new Array();
    let i = 0;
    for (const item of source) {
      const obj = {
        value: '',
        check: false,
        dimension,
      };
      obj.value = item;
      valuArray.push(obj);
      i = i + 1;
      if (i === 100) {
        break;
      }
    }
    return valuArray;
  }

  onConvertTree(source) {
    const valuArray: any[] = new Array();
    let i = 0;
    for (const result of source) {
      const parent = {
        value: '',
        check: false,
        items: [],
        dimension: '',
      };
      parent.value = result.name;
      if (result.dimensionName !== undefined) {
        parent.dimension = result.dimensionName;
      }
      if (result.items instanceof Array) {
        for (const item of result.items) {
          const child = {
            value: '',
            check: false,
          };
          child.value = item;
          parent.items.push(child);
        }
      } else {
        const child = {
          value: '',
          check: false,
        };
        child.value = result.items;
        parent.items.push(child);
      }

      valuArray.push(parent);
      i = i + 1;
      if (i === 100) {
        break;
      }
    }

    return valuArray;
  }

  onConvertMultiTree(source) {
    const valuArray: any[] = new Array();
    let i = 0;
    for (const result of source) {
      const grandParent = {
        value: '',
        check: false,
        parent: [],
      };
      grandParent.value = result.name;
      if (result.items instanceof Array) {
        for (const cpn of result.items) {
          const parent = {
            value: '',
            check: false,
            items: [],
          };
          parent.value = cpn.name;
          if (cpn.items instanceof Array) {
            for (const item of cpn.items) {
              const child = {
                value: '',
                check: false,
              };
              child.value = item;
              parent.items.push(child);
            }
          } else {
            const child = {
              value: '',
              check: false,
            };
            child.value = result.items.items;
            parent.items.push(child);
          }
          grandParent.parent.push(parent);
        }
      } else if (result.items instanceof Object) {
        const parent = {
          value: '',
          check: false,
          items: [],
        };
        parent.value = result['items'].name;
        if (result['items'].items instanceof Array) {
          for (const item of result['items'].items) {
            const child = {
              value: '',
              check: false,
            };
            child.value = item;
            parent.items.push(child);
          }
        } else {
          const child = {
            value: '',
            check: false,
          };
          child.value = result.items.items;
          parent.items.push(child);
        }
        grandParent.parent.push(parent);
      }
      valuArray.push(grandParent);
      i = i + 1;
      if (i === 100) {
        break;
      }
    }

    return valuArray;
  }
}
