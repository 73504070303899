import { Router } from '@angular/router';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { Component } from '@angular/core';


@Component({
  selector: 'child-cell',
  template: '<span *ngIf="viewDisable" class="cursor-pointer" (click)="drilldown($event)"   data-toggle="modal" data-target="#costLookupPopup"><button class="btn btn-primary btn-xs height-22" id="viewDetails" title="View Details">View Details</button></span>'

})
export class CostLookupComponent {
  public params: any;
  viewDisable = true;

  constructor(private dataStorageService: DataStorageService, private router: Router
  ) {

  }
  agInit(params: any): void {
    this.params = params;
    if (params.data === undefined) {
      this.viewDisable = false;
    }
  }
  drilldown(event) {
    this.dataStorageService.setDetailViewClicked(this.params);
  }



}
