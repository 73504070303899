import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { Subject } from 'rxjs';
import { ApiServiceProvider } from 'app/api-service';
import { SubscriptionLike } from 'rxjs';

@Injectable()
export class PartMatchingService {
  public whereUsedData = new Subject<any>();
  public singlePartMatchdata = new Subject<any>();
  public multiplePartMatchdata = new Subject<any>();
  public dropDownListServiceSubscription: SubscriptionLike;
  public dimensionName;
  constructor(private apiServiceProvider: ApiServiceProvider) {}
  public getWhereUsedResult(
    mpnname: string,
    cpnname: string,
    url: string,
    type: string
  ) {
    const object: any = {
      targetedType: 'MIService',

      method: type,
      servicePath: url,
      payload: [
        {
          cpn: cpnname,
          mpn: mpnname
        }
      ]
    };
    return this.apiServiceProvider.post(object);
  }
  public getSinglePartMatchingResult(
    name,
    url: string,
    type: string,
    dimensionName: string,
    parentNode?
  ) {
    const object: any = {
      targetedType: 'MIService',
      method: type,
      servicePath: url,
      payload: {
        mpnList: name,
        dimensionName,
        parentNode
      }
    };
    if (name instanceof Array) {
      return this.apiServiceProvider.post(object);
    }
  }
  public getDefaultPartMatchingResult(
    input,
    url: string,
    type: string
  ) {
    const object: any = {
      targetedType: 'MIService',
      method: type,
      servicePath: url,
      payload: input
    };
    
      return this.apiServiceProvider.post(object);
    
  }
  public getInternalDetails(mpn, url: string) {
    const object: any = {
      targetedType: 'MIService',
      method: 'GET',
      servicePath: '/partmatching/internaldetailsformpn',
      formParams: {
        mpn
      }
    };

    return this.apiServiceProvider.post(object);
  }

  public setwhereUsedData(value: any) {
    this.whereUsedData.next(value);
  }
  public getwhereUsedData() {
    return this.whereUsedData.asObservable();
  }
  public setsinglepartMatchData(target: any) {
    this.singlePartMatchdata.next(target);
  }
  public getsinglepartMatchData() {
    return this.singlePartMatchdata.asObservable();
  }
  //   setmultiplepartMatchData(target:any){
  //       this.multiplePartMatchdata.next(target);
  //   }
  //   getmultiplepartMatchData(){

  //       return this.multiplePartMatchdata.asObservable();
  //   }
}
