import { Component, OnInit } from '@angular/core';

import { Pagination } from './shared/pagination.model';
import { PaginationService } from './shared/pagination.service';

@Component({
  selector: 'pagination',
  templateUrl: 'pagination.component.html',
  styleUrls: ['./pagination.component.sass'],
  providers: [PaginationService]
})

export class PaginationComponent implements OnInit {
  pagination: Pagination[] = [];

  constructor(private paginationService: PaginationService) { }

  ngOnInit() {
  }
}
