import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RatingService } from './shared/rating.service';
import { PartnerScoreCardService } from 'app/modules/mi/partner-score-card/partner-score-card.service';
import { DataStorageService } from 'app/modules/common/data-storage.service';

@Component({
  selector: 'rating',
  templateUrl: 'rating.component.html',
  styleUrls: ['rating.component.sass'],
  providers: [RatingService,PartnerScoreCardService]
})

export class RatingComponent implements OnInit {

  constructor(private ratingService: RatingService,
    private dataStorageService: DataStorageService,
    ) { }
  showrating= false;
  inputName: string;
  ratingItem: Object;
  showPopup = false
  noLink = false;
  suppRating = true;
  link = '';
  showRequestLink = false;
  ratingArray = [1,2,3,4,5];
  rating: number;

  @Input() set item(value: Object) {
    if (value instanceof Object) {
      this.ratingItem = value;
    }
  }

  @Input() set readProperty(value: string) {
    if (typeof value === 'string') {
      this.inputName = value;
    }
  }

  @Input() set readValue(value: number) {
    if (typeof value === 'number') {
      this.rating = value;
    }
  }

  @Output() ratingClick: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {

  }
  onClickRequestRating(){
    this.dataStorageService.setRequestRating(true);
  }
  agInit(params: any): void {
    if(params.value){
      this.showrating = true;
      this.suppRating = false;
      this.showRequestLink = false;
      this.rating =params.value;
    }else{
      this.suppRating = false;
        this.showrating = false;
        this.noLink = false;
        this.showRequestLink = true;
      // if(this.dataStorageService.getPrivateLink()){
      //   this.link=this.dataStorageService.getPrivateLink()
      //   this.showRequestLink = true;
      // }else{
      //   this.showRequestLink = false;
      //   this.noLink = true;
      // }
    }
  }

  onClick(rating: number): void {
    this.rating = rating;
    this.ratingItem['value'] = rating;
    this.ratingClick.emit(this.ratingItem);
  }

  retrieveRatingColor(rating) {
    if (rating > 0 && rating < 3) {
      return 'low-rating';
    }
    else if (rating === 3) {
      return 'medium-rating';
    }
    else if (rating > 3 && rating < 6) {
      return 'high-rating';
    }
  }
  retriveRatingStyle(rating,element){
    if(element - 0.5 <= rating){
      if (rating > 0 && rating < 3) {
        return 'low-rating';
      }
      else if (rating === 3) {
        return 'medium-rating';
      }
      else if (rating > 3 && rating < 6) {
        return 'high-rating';
      }
    }
    else{
      return 'empty-star';
    }

  }
  onClickRequest(){
     this.showPopup = true;
  }

  showHalf(rating, element) {
    return (element - 0.5 <= rating && rating < element)
  }

}
