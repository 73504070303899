import { Injectable } from '@angular/core';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { Router } from '@angular/router';
import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService';
import { ScopeDataService } from 'app/modules/common/scope.data.service';

@Injectable()
export class Detail {
  public rfqInfo: any;
  public titleObj: any;
  constructor(
    private dataStorageService: DataStorageService,
    private router: Router,
    private scopeDataService: ScopeDataService,
    private customerConfigurationService: CustomerConfigurationService
  ) {
    if (this.dataStorageService.getClikedRFQforDetails() === undefined) {
      this.router.navigate(['app/srfx']);
    }
    this.rfqInfo = this.dataStorageService.getClikedRFQforDetails();
    if (this.rfqInfo instanceof Object) {
      this.rfqInfo.rfqCustId = this.rfqInfo.rfqCustId
        ? this.rfqInfo.rfqCustId
        : this.rfqInfo.rfqId;
    }

    this.dataStorageService.getClickedSubjectRfqValues().subscribe(val => {
      if (val instanceof Object) {
        this.rfqInfo = val;
      }
    });
    this.titleObj = this.customerConfigurationService.getRFQCustomDimensions();
  }

  public setRFQInfo(val) {
    this.rfqInfo = val;
    if (this.rfqInfo instanceof Object) {
      this.rfqInfo.rfqCustId = this.rfqInfo.rfqCustId
        ? this.rfqInfo.rfqCustId
        : this.rfqInfo.rfqId;
    }
    if (this.rfqInfo instanceof Object) {
      this.rfqInfo.rfqTypeName = this.rfqInfo.rfqTypeName
        ? this.rfqInfo.rfqTypeName
        : this.rfqInfo.rfqType;
    }
    if (val && val['info'] && val['info']['createdBy']) {
      this.rfqInfo['creatorEmail'] = val['info']['createdBy'];
    }
  }

  public deleteRFQInfo() {
    this.rfqInfo = null;
  }
}
