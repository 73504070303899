import { IDoesFilterPassParams, IFilterParams, RowNode } from '@ag-grid-community/all-modules';
import { IFilterAngularComp } from '@ag-grid-community/angular';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { RequestData } from 'app/modules/common/models/request.model';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';
import { Subject, SubscriptionLike } from 'rxjs';
import { LevaColumnLevelFilterService } from './leva-column-level-filter.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-leva-column-level-filter',
  templateUrl: './leva-column-level-filter.component.html',
  styleUrls: ['./leva-column-level-filter.component.sass'],
  providers: [LevaColumnLevelFilterService]
})
export class LevaColumnLevelFilterComponent
implements IFilterAngularComp, OnChanges, OnDestroy {

  @ViewChild('input', { read: ViewContainerRef, static: true }) public input;
  private params: IFilterParams;
  private valueGetter: (rowNode: RowNode) => any;
  public text = '';
  public expandedAtSALevel = false;
  public filter = [];
  placeHolder;
  public request: any;
  public dimension;
  public inputs;
  public autoSearchSubscription: SubscriptionLike;
  public page;
  public dbCol;
  public valDBCol;
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;

  public subscriptions: object = {};
  public customSubscription: SubscriptionLike;
  public clearFlagSubscription: SubscriptionLike;
  public event: object = {};
  public reqReady = false;

  public showGo = false;
  public manualOptionsRequired = false;
  public requestData : RequestData;
  public monthlyView = false;
  set searchCustomization(val) {
    this._searchCustomization = val;
  }
  get searchCustomization() {
    return this._searchCustomization;
  }
  public _searchCustomization: any;
  @Input() public showOnlyArrow = false;
  public customDimensionData: any[] = new Array();
  @Input() public showDropdown = true;
  @Input() public inputBorder;
  @Input() public requestPayload;
  public selectedArray: any[] = new Array();
  public inputOptions: any[] = new Array();
  public type: any;
  public borderWidth = '2px 2px 5px 2px';
  public typeOfSelectSingle: boolean;
  public searchTerm$ = new Subject<string>();
  public search: any = '';
  public singleSearchSelectedValue: any = 'Select';
  public showDiv: any = 'hide';
  public count = 0;
  public mutliSelectCrossIcon: any = 'hide';
  public multiSelectSearchIcon: any = 'show';
  public autoSearchCrossIcon: any = 'hide';
  public autoSearchSearchIcon: any = 'show';
  public manualOptionsView: any = 'show';
  public searchResult: any[] = [];
  public searchInputLimit = 1;
  public showOrHideDropdown = 'show';
  public showSelectedValue = false;
  public searchedItem: any[] = [];
  public columnLevelPayload;
  @Output() inputChangeOptions = new EventEmitter<any>();
  private autosearchSubscription: SubscriptionLike;

  constructor(
    public dataStorageService: DataStorageService,
    private ref: ChangeDetectorRef,
    private columnLevelFilterService: LevaColumnLevelFilterService
  ) {}

  agInit(params: IFilterParams): void {
    this.dimension = [];
    this.inputs = [];
    const fieldName = this.getFieldName(params)
    this.dimension.push(fieldName)
    this.placeHolder = params.colDef.headerName
    this.params = params;
    this.columnLevelPayload = this.dataStorageService.getColumnLevelFilterPayload()
    this.autoSearchRequest();

    this.valueGetter = params.valueGetter;
    if (this.autoSearchSubscription) {
      this.autoSearchSubscription.unsubscribe();
    }

    this.showOrHideDropdown = 'show';

    this.onLoad();

    this.autoSearchSubscription = this.columnLevelFilterService
      .getAutoSearchText()
      .subscribe((val) => {
        if (val instanceof Object && val.val instanceof Array) {
          if (
            val.val.length > 0 && val.val[0]['dimension'] === fieldName) {
            this.filter = [];

            val.val.forEach((element) => {
              this.valDBCol = element['dimension'];
              if (this.valDBCol === 'comments') {
                this.filter.push(element.key);
              } else {
                this.filter.push(element.value);
              }
            });
            this.onFilterApplied();
          } else {
            this.onClearFilter();
          }
        }
      });
  }

  autoSearchRequest() {
    this.requestData = new RequestData();
    this.requestData.targetedType =   this.columnLevelPayload.targetedType
    this.requestData.method =   this.columnLevelPayload.method
    this.requestData.servicePath = this.columnLevelPayload.servicePath
    this.requestData.placeHolder = `Enter ${this.placeHolder}`;
    this.requestData.type = 'multi'
    this.requestData.defaultSelection = `Select ${this.placeHolder}`;
    let payload={
      columnFilterDimension: this.dimension[0],
      columnFilterInput:''
    }
    if(this.columnLevelPayload.payload){
      payload = Object.assign(this.columnLevelPayload.payload, payload)
    }
    this.requestData.payload = payload
  }

  onClearFilter() {
    if (this.valDBCol) {
      if (this.filter) {
            const fieldName = this.getFieldName(this.params)
            if (this.dataStorageService.gridHeaderPayload['columnLevelFilter']) {
              delete this.dataStorageService.gridHeaderPayload['columnLevelFilter'][
                fieldName
              ];
              if (
                this.dataStorageService.gridHeaderPayload['columnLevelFilter'] === {}
              ) {
                delete this.dataStorageService.gridHeaderPayload['columnLevelFilter'];
              }
            }
            this.dataStorageService.gridHeaderPayload['levaFilterTab'] = this.dataStorageService.getLevaFilterTab();
            this.dataStorageService.gridHeaderFilterService.emit(this.dataStorageService.gridHeaderPayload);
      }
      this.filter = [];
    }
  }
  onFilterApplied() {
    if (this.valDBCol) {
      if (this.filter.length > 0) {
            const fieldName = this.getFieldName(this.params)
            this.dataStorageService.setPwbColumnMappings(fieldName, this.params['colDef']['field'])
            if (this.dataStorageService.gridHeaderPayload['columnLevelFilter']) {
              this.dataStorageService.gridHeaderPayload['columnLevelFilter'][
                fieldName
              ] = this.filter;
            } else {
              this.dataStorageService.gridHeaderPayload['columnLevelFilter'] = {
                [fieldName]: this.filter
              };
            }
            this.dataStorageService.gridHeaderPayload['levaFilterTab'] = this.dataStorageService.getLevaFilterTab();
            this.dataStorageService.gridHeaderFilterService.emit(this.dataStorageService.gridHeaderPayload);
      }
    }
  }

  isFilterActive(): boolean {
    return this.text !== null && this.text !== undefined && this.text !== '';
  }

  public ngOnChanges() {
    if (this.requestData) {
      this.onLoad();
    }
    if (this.manualOptionsRequired) {
      this.onLoad();
    }
  }
  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return this.text
      .toLowerCase()
      .split(' ')
      .every((filterWord) => {
        return (
          this.valueGetter(params.node)
            .toString()
            .toLowerCase()
            .indexOf(filterWord) >= 0
        );
      });
  }

  getModel(): any {
    return { value: this.text };
  }

  setModel(model: any): void {
    this.text = model ? model.value : '';
  }

  componentMethod(message: string): void {
    alert(`Alert from ColumnLevelFilterPartCatalog ${message}`);
  }

  public afterGuiAttached(params) {
    console.log(params);
  }

  public clearSubscriptions(eachKey?: string) {
    if (typeof eachKey === 'string') {
      if (this.subscriptions[eachKey] instanceof Object) {
        if (this.subscriptions[eachKey].unsubscribe instanceof Function) {
          this.subscriptions[eachKey].unsubscribe();
        }
      }
      return;
    }
    // tslint:disable-next-line: no-shadowed-variable
    for (const eachKey in this.subscriptions) {
      if (this.subscriptions[eachKey] instanceof Object) {
        if (this.subscriptions[eachKey].unsubscribe instanceof Function) {
          this.subscriptions[eachKey].unsubscribe();
        }
      }
    }
  }

  public removeRestrictedVals(customDimensionData) {
    if (
      this.requestData instanceof Object &&
      this.requestData.restrictedVals instanceof Array
    ) {
      _.remove(customDimensionData, (v) => {
        if (this.requestData.restrictedVals.includes(v.value)) {
          return v;
        }
      });
    }
    return customDimensionData;
  }
  public onLoad() {
    try {
      if (this.customSubscription) {
        this.customSubscription.unsubscribe();
      }
      this.search = '';
      if (!this.monthlyView) {
        this.searchResult.length = 0;
        this.selectedArray.length = 0;
      }
      this.singleSearchSelectedValue = 'Select';
      this.customDimensionData = [];
      this.searchInputLimit = this.requestData.searchInputLimit;
      this.type = this.requestData.type;
      this.showSelectedValue = this.requestData.showSelectedValue;

      if (this.type === 'multi') {
        this.inputOptions = [];
        this.customDimensionData = [];
        this.columnLevelFilterService
          .searchCustomEntries('0', this.requestData)
          .subscribe((results) => {
            if (results.result) {
              this.customDimensionData = this.columnLevelFilterService.onConvertCustomMulti(
                results.result,
                this.requestData.payload.columnFilterDimension
              );
            }
            this.customDimensionData = this.removeRestrictedVals(
              this.customDimensionData
            );
            this.dataStorageService.getLevaColumnPDMIntegration().subscribe((value)=>{
              if(value && value['cpn'] && value['cpn'] instanceof Array && value['cpn'].length > 0)
              {
                this.valDBCol = this.requestData.payload.columnFilterDimension
                value['cpn'].forEach(data => {
                  const index = this.customDimensionData.findIndex(i=>i.value===data)
                  if(index !== -1)
                  {
                  const event = {
                    target :{
                      checked : true
                    }
                  }
                  this.onCheckBoxSelect(event,this.customDimensionData[index]);
                  }
                })
              }
            })
            this.count = this.customDimensionData.length;
          });
      }
    } catch (error) {
      console.log(error);
    }
  }

  public onMultiSelectKey(event: any, clearAll?: boolean) {
    this.search = event.target.value;
    this.searchTerm$.next(event.target.value);
    this.requestData.payload['columnFilterInput']=this.search
    this.onMultiCustom(clearAll);
  }

  public onMultiCustom(clearAll?: boolean) {
    this.customSubscription = this.columnLevelFilterService
      .searchCustom(this.searchTerm$, this.requestData)
      .subscribe((results) => {
        if (results.result === undefined) {
          this.customDimensionData.length = 0;
        } else {
          if (results.result) {
            this.customDimensionData = this.columnLevelFilterService.onConvertCustomMulti(
              results.result,
              this.requestData.payload.columnFilterDimension
            );
            this.customDimensionData = this.removeRestrictedVals(
              this.customDimensionData
            );
          }
        }
        if (this.search === '') {
          this.multiSelectSearchIcon = 'show';
          this.mutliSelectCrossIcon = 'hide';
          for (const selv of this.selectedArray) {
            for (const k of this.customDimensionData) {
              if (k.value === selv.value) {
                this.customDimensionData.splice(
                  this.customDimensionData.indexOf(k),
                  1
                );
              }
            }
            this.customDimensionData.unshift(selv);
          }
        } else {
          this.mutliSelectCrossIcon = 'show';
          this.multiSelectSearchIcon = 'hide';
          for (const selv of this.selectedArray) {
            for (const k of this.customDimensionData) {
              if (k.value === selv.value) {
                k.check = true;
              }
            }
          }
        }
        this.count = this.customDimensionData.length;
      });
  }
  public onCheckBoxSelect(event, valueObj) {
    if (event.target.checked) {
      if (this.searchedItem && this.searchedItem[0]) {
        if (this.searchedItem[0].includes('Select')) {
          this.searchedItem = [];
        }
      }
      this.searchedItem.push(valueObj.value);
      this.searchedItem = this.searchedItem.slice();
      if (this.searchedItem.length === 1) {
        this.searchResult = this.searchedItem;
      } else if (this.searchedItem.length === 0) {
        this.searchResult = ['Select'];
      } else {
        this.searchResult = [
          'selected ' + '(' + this.searchedItem.length + ')'
        ];
      }
      this.ref.markForCheck();
      valueObj.check = true;
      this.selectedArray.unshift(valueObj);
      this.inputOptions.push(valueObj);

      this.columnLevelFilterService.setAutoSearchSelected(this.inputOptions);
      this.inputChangeOptions.emit(this.inputOptions);
    } else {
      // this.columnLevelFilterService.setAutoSearchSelected(this.selectedArray);
      const tobeRemove = this.inputOptions.filter((item) => {
        if (item.value === valueObj.value) {
          return item;
        }
      });
      const inputOptions = this.inputOptions.map((val) => {
        return val.value;
      });
      if (inputOptions.indexOf(tobeRemove[0].value) > -1) {
        this.inputOptions.splice(inputOptions.indexOf(tobeRemove[0].value), 1);
        this.columnLevelFilterService.setAutoSearchSelected(this.inputOptions);
        this.inputChangeOptions.emit(this.inputOptions);
      }
      const selectedArray = this.selectedArray.map((val) => {
        return val.value;
      });
      if (selectedArray.indexOf(tobeRemove[0].value) > -1) {
        this.selectedArray.splice(
          selectedArray.indexOf(tobeRemove[0].value),
          1
        );
      }
      this.searchedItem.splice(this.searchedItem.indexOf(valueObj.value), 1);
      if (this.searchedItem.length === 1) {
        this.searchResult = this.searchedItem;
      } else if (this.searchedItem.length === 0) {
        this.searchResult = ['Select'];
      } else {
        this.searchResult = [
          'selected ' + '(' + this.searchedItem.length + ')'
        ];
      }
    }

  }

  public checkAll() {
    this.searchedItem.length = 0;
    this.selectedArray.length = 0;
    this.searchResult.length = 0;
    for (const data of this.customDimensionData) {
      data.check = true;
      this.selectedArray.unshift(data);
      this.inputOptions.push(data);
      this.searchedItem.push(data.value);
      this.searchResult = this.searchResult.slice();
      this.ref.markForCheck();
    }
    this.searchResult = [
      'Selected All ' + '(' + this.searchedItem.length + ')'
    ];
    this.columnLevelFilterService.setAutoSearchSelected(this.searchedItem);
    this.inputChangeOptions.emit(this.searchedItem);

    this.onMultiSelectSearch();
  }

  public unCheckAll(event?: any) {
    for (const data of this.customDimensionData) {
      data.check = false;
    }
    this.searchedItem.length = 0;
    this.selectedArray.length = 0;
    this.searchResult.length = 0;
    this.columnLevelFilterService.setAutoSearchSelected(this.selectedArray);
    this.inputChangeOptions.emit(this.selectedArray);

    this.onMultiSelectSearch();

    this.clearFlagSubscription = this.columnLevelFilterService
      .getClearflag()
      .subscribe((val) => {
        if (val) {
          // tslint:disable-next-line: deprecation
          (event.target['value'] = ''), this.clearSearchText(event);
          this.columnLevelFilterService.setClearflag(false);
        }
      });
    if (!!event) {
      this.clearSearchText(event, true);
    }
  }

  public clearSearchText(event: any, clearAll?: boolean) {
    this.search = '';
    event.target.value = '';
    this.onMultiSelectKey(event, clearAll);
  }

  public onMultiSelectSearch() {
    this.dataStorageService.setautoSearchHit(true);

    this.columnLevelFilterService.setAutoSearchText({
      val: this.selectedArray,
      id: this.requestData.id
    });

    if (this.showSelectedValue) {
      this.selectedArray.length = 0;
      this.searchResult.length = 0;
      this.searchedItem.length = 0;
      for (const data of this.customDimensionData) {
        data.check = false;
      }
    }
  }

  public ngOnDestroy() {
    if (this.clearFlagSubscription) {
      this.clearFlagSubscription.unsubscribe();
    }
    if (this.autosearchSubscription) {
      this.autosearchSubscription.unsubscribe();
    }
  }

  public getFieldName(params) {
    if (
      params['colDef']['field'] &&
      params['colDef']['field'].includes('timeSeriesData.')
    ) {
      const fieldMappings = params['colDef']['field'].split('.')
      return fieldMappings[fieldMappings.length - 1]
    }
    return params['colDef']['field'];
  }
}
