import { Component, OnInit, Input } from '@angular/core';

import { Search } from './shared/autosearch-dimension.model';
import { AutoSearchDimensionService } from './shared/autosearch-dimension.service';
import {Subject} from 'rxjs';
import { RequestData } from 'app/modules/common/models/request.model';
import { UtilService } from 'app/modules/common/utills/util.service';
import { SubscriptionService } from 'app/modules/common/subscription.service';
import { ChangeDetectorRef } from '@angular/core';
import { SubscriptionLike } from 'rxjs';
 import { OnDestroy } from '@angular/core';
import { DropDownListService } from 'app/modules/common/dropdown-list.service';
import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';


@Component({
  selector: 'auto-search-dimension',
  templateUrl: 'autosearch-dimension.component.html',
  styleUrls: ['./autosearch-dimension.component.sass'],
  providers: [AutoSearchDimensionService]
})

export class AutoSearchDimensionComponent implements OnInit, OnDestroy {
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;

  @Input() requestData: RequestData;
  private autosearchSubscription: SubscriptionLike;
  customDimensionData: any[] = new Array;

  selectedArray: any[] = new Array;
  inputOptions: any[] = new Array;
  multiType: boolean;
  type: any;
  typeOfSelectSingle: boolean;
  searchTerm$ = new Subject<string>();
  search: any = '';
  autoSearchSelectedValue: any = '';
  singleSearchSelectedValue: any = 'Select';
  showDiv: any = 'hide';
  count=0;
  mutliSelectCrossIcon: any = 'hide'
  multiSelectSearchIcon: any = 'show'
  autoSearchCrossIcon: any = 'hide'
  autoSearchSearchIcon: any = 'show'
  manualOptionsView: any = 'show'
  searchResult: any[] = [];
  searchInputLimit: Number = 1;
  showOrHideDropdown= 'hide';
  scope: string;

  constructor(private searchService: AutoSearchDimensionService,
    private utilService: UtilService,
    private subscriptionService: SubscriptionService,
    private ref: ChangeDetectorRef,
    private dropDownlistService: DropDownListService,
    private customerConfigurationService: CustomerConfigurationService,
    private scopeDataService: ScopeDataService) {
  }

  ngOnInit() {
    this.scope = this.scopeDataService.getFocusValue();
    this.scopeDataService.getFocus().subscribe((val) => {
      this.scope = val;
    })
    this.autosearchSubscription = this.subscriptionService.getscopeRequestData().subscribe((value) => {
      if (value === '') {
      } else {
        this.requestData = value;
      }
      this.onLoad();
    })
    this.subscriptionService.getClearScope().subscribe((val) => {
      if (val) {
        this.clearAutoSearchText(null);
      }
    })
    // this.onLoad();

  }

  onClickOutside(event: Object) {
    this.showOrHideDropdown = 'hide'
    this.showDiv = 'hide'
  }

  onDropDownClick() {
    if (this.showOrHideDropdown === 'hide') {
      this.showOrHideDropdown = 'show';
    } else if (this.showOrHideDropdown === 'show') {
      this.showOrHideDropdown = 'hide'
    }
  }

  onLoad() {
    this.searchResult.length = 0;
    this.selectedArray.length = 0;
    this.singleSearchSelectedValue = 'Select';
    this.customDimensionData = [];
    this.searchInputLimit = this.requestData.searchInputLimit;
    this.type = this.requestData.type;
    this.typeOfSelectSingle = this.requestData.typeOfSelectSingle;
    if (this.type === 'multi') {
      this.inputOptions = [];
      this.customDimensionData = [];
      if (this.requestData.options.length > 0) {
        // If options are provided manually .. this case will execute
        // this.manualOptionsView = 'hide'
        for (const option of this.requestData.options) {
          const optionsObject = {
            value: '',
            check: true
          }
          optionsObject.value = option;
          if (this.requestData.selectAll) {
            this.inputOptions.push(optionsObject);
            optionsObject.check = true;
            this.searchResult.push(optionsObject.value);
          }
          else {
            optionsObject.check = false;
          }
          optionsObject.value = option;
          this.customDimensionData.push(optionsObject);
        }
        if (this.requestData.defaultSelection !== undefined) {
          this.searchResult.push(this.requestData.defaultSelection.value);
        }

        this.count = this.customDimensionData.length;
      } else {
        // if data need to be fetched from elastic search or custom service below code will execute
        if (this.requestData.targetedType === 'ELS') {
          this.searchService.searchMultiEntriesELS('', this.requestData)
            .subscribe(results => {
              if (!results['result']) {
                return false;
              }
              this.customDimensionData = this.searchService.onConvertArrayObjectKeysWithDimension(results['result'][0].hits, this.requestData.payload.dimension);
              this.count = this.customDimensionData.length;
            });
        } else {
          this.searchService.searchCustomEntries('0', this.requestData)
            .subscribe(results => {
              if (results['result'].rmSet !== undefined) {
                this.customDimensionData = this.searchService.onConvertCustomMulti(results['result'].rmSet)
                if (this.requestData.selectAll) {
                  this.customDimensionData.forEach(item => {
                    item.check = true;
                  })

                }
                else {
                  this.customDimensionData.forEach(item => {
                    item.check = false;
                  })
                }
                this.inputOptions = this.customDimensionData;
              }
              else if (results['result'].list !== undefined) {
                this.customDimensionData = this.searchService.onConvertCustomMulti(results['result'].list)
                if (this.requestData.selectAll) {
                  this.customDimensionData.forEach(item => {
                    item.source = item.value;
                    item.check = true;
                    item.value = item.source.name;
                  })

                }
                else {
                  this.customDimensionData.forEach(item => {
                    item.source = item.value;
                    item.check = false;
                    item.value = item.source.name;
                  })
                }
                this.inputOptions = this.customDimensionData;
              }
              else {
                this.customDimensionData = this.searchService.onConvertCustomMulti(results['result'].items)
              }
              this.count = this.customDimensionData.length;
            });
        }
      }
    } else if (this.type === 'single') {
      // write logic
      if (this.requestData.options.length > 0) {
        // If options are provided manually .. this case will execute
        if (this.requestData.defaultValue !== undefined) {
          this.singleSearchSelectedValue = this.requestData.defaultValue;
        } else {
          const optionsObject = {
            value: '',
          }
          optionsObject.value = 'Select';
          this.singleSearchSelectedValue = optionsObject;
        }
        this.manualOptionsView = 'hide'
        for (const option of this.requestData.options) {

          // optionsObject.value =(option.title==='undefined')? option : option.title;
          this.customDimensionData.push(option);
        }
        this.count = this.customDimensionData.length;
      } else {
        // if data need to be fetched from elastic search or custom service below code will execute
        if (this.requestData.targetedType === 'ELS') {
          this.searchService.searchMultiEntriesELS('', this.requestData)
            .subscribe(results => {
              if (!results['result']) {
                return false;
              }
              this.customDimensionData = this.searchService.onConvertArrayObjectKeysWithDimension(results['result'][0].hits, this.requestData.payload.dimension);

              this.count = this.customDimensionData.length;
            });
        } else {
          this.searchService.searchCustomEntries('0', this.requestData)
            .subscribe(results => {
              if (results['result'].rmSet !== undefined) {
                this.inputOptions = this.customDimensionData = this.searchService.onConvertCustomMulti(results['result'].rmSet)
              }
              else {
                this.customDimensionData = this.searchService.onConvertCustomMulti(results['result'].items)
              }

              this.count = this.customDimensionData.length;
            });
        }
      }
    } else if (this.type === 'tree') {
      // write logic
      this.searchService.searchCustomEntries('55', this.requestData)
        .subscribe(results => {
          this.customDimensionData = this.searchService.onConvertTree(results['result'])
          this.count = this.customDimensionData.length;
        });
    } else if (this.type === 'multitree') {
      // write logic
      this.searchService.searchCustomEntries('55', this.requestData)
        .subscribe(results => {
          this.customDimensionData = this.searchService.onConvertMultiTree(results['result'])
          this.count = this.customDimensionData.length;
        });
    }


  }

  // Auto Search Related Code

  onAutoSearchKey(event: any) {
    this.autoSearchSelectedValue = event.target.value;
    if (this.autoSearchSelectedValue === '') {
      this.showDiv = 'hide'
      this.autoSearchCrossIcon = 'hide'
      this.autoSearchSearchIcon = 'show'
      this.customDimensionData.length = 0;
      return;
    } else {
      this.showDiv = 'show'
      this.autoSearchSearchIcon = 'hide'
      this.autoSearchCrossIcon = 'show'
    }
    this.searchTerm$.next(event.target.value)
    this.searchService.searchMultiELS(this.searchTerm$, this.requestData)
      .subscribe(results => {
        if (!results['result']) {
          return false;
        }
        if (this.requestData.targetedType === 'ELS') {
          this.customDimensionData = this.searchService.onConvertArrayObjectKeysWithDimension(results['result'][0].hits, this.requestData.payload.dimension);
        } else if (this.requestData.targetedType === 'MANUAL') {

        } else {
          this.customDimensionData.length = 0;
        }

      });
  }

  onAutoSearchKeyDimension(event: any) {
    this.autoSearchSelectedValue = event.target.value;
    if (this.autoSearchSelectedValue === '') {
      this.showDiv = 'hide'
      this.autoSearchCrossIcon = 'hide'
      this.autoSearchSearchIcon = 'show'
      this.customDimensionData.length = 0;
      return;
    } else {
      this.showDiv = 'show'
      this.autoSearchSearchIcon = 'hide'
      this.autoSearchCrossIcon = 'show'
    }
    this.searchTerm$.next(event.target.value)
    this.searchService.searchMultiELS(this.searchTerm$, this.requestData)
      .subscribe(results => {
        if (this.requestData.targetedType === 'ELS') {
          this.customDimensionData = this.searchService.onConvertArrayForAutoDimnsionSelect(results['result'][0].hits, this.requestData.payload.dimension);



        }
        const check = this.isEmptyObject(this.customDimensionData);

        if (check) {
          let newObject: any ;
          this.customDimensionData['No data found'] ;
        }
      });

  }
  getrenamedLabelOfKey(key) {
    const scopeData = this.customerConfigurationService.getConfData(this.scope);
    let dimensionData;
    if (scopeData) {
      dimensionData = scopeData.dimensionData;
    }
    if (dimensionData &&
      Object.getOwnPropertyNames(dimensionData).includes(key.replace(/ /g, ''))) {
      key = dimensionData[key.replace(/ /g, '')];
    }
    return key;

  }
  isEmptyObject(obj) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return true;
  }
  onSelectOfAutoSeachValue(val: String) {
    this.autoSearchSelectedValue = val;
    this.showDiv = 'hide'
  }

  onAutoDimensionSelect(key: any, keyValue: any) {
    // this.autoSearchSelectedValue =  keyValue;
    setTimeout(() => {
      this.autoSearchSelectedValue = '';
    }, 500);
    this.showDiv = 'hide';
    const val = {
      dimension: '',
      value: ''
    }
    val.dimension = key;
    val.value = keyValue;
    this.subscriptionService.setAutoSearchDimensionText(val);
  }

  clearAutoSearchText(event: any) {
    this.autoSearchSelectedValue = '';
    this.showDiv = 'hide';
    this.autoSearchCrossIcon = 'hide'
    this.autoSearchSearchIcon = 'show'
  }

  onAutoSearchClickOnSearchIcon() {
    this.subscriptionService.setAutoSearchText(this.autoSearchSelectedValue);
  }

  // autosearch related code end

  // MultiSelect code start

  onMultiSelectKey(event: any) {
    this.search = event.target.value;
    this.searchTerm$.next(event.target.value)
    if (this.requestData.targetedType === 'ELS') {
      this.onMultiELS();
    } else {
      this.onMultiCustom();
    }

  }

  onMultiELS() {
    this.searchService.searchMultiELS(this.searchTerm$, this.requestData)
      .subscribe(results => {
        if (!results['result']) {
          return false;
        }
        this.customDimensionData = this.searchService.onConvertArrayObjectKeysWithDimension(results['result'][0].hits, this.requestData.payload.dimension);
        if (this.search === '') {
          this.multiSelectSearchIcon = 'show'
          this.mutliSelectCrossIcon = 'hide'
          for (const selv of this.selectedArray) {
            for (const k of this.customDimensionData) {
              if (k.value === selv.value) {
                this.customDimensionData.splice(this.customDimensionData.indexOf(k), 1)
              }
            }
            this.customDimensionData.unshift(selv)
          }
        } else {
          this.mutliSelectCrossIcon = 'show'
          this.multiSelectSearchIcon = 'hide'
          for (const selv of this.selectedArray) {
            for (const k of this.customDimensionData) {
              if (k.value === selv.value) {
                k.check = true
              }
            }
          }
        }
        this.count = this.customDimensionData.length
        if (this.requestData.unmatchedSearch) {
          if (this.count === 0) {
            const unMatchedObject = {
              value: '',
              check: false
            }
            unMatchedObject.value = this.search;
            this.customDimensionData.push(unMatchedObject)
            this.count = 1;
          }
        }
      });
  }

  onMultiCustom() {
    if (this.requestData.options.length > 0) {
      this.customDimensionData.length = 0;
      if (this.search === '') {
        this.multiSelectSearchIcon = 'show'
        this.mutliSelectCrossIcon = 'hide'
      } else {
        this.mutliSelectCrossIcon = 'show'
        this.multiSelectSearchIcon = 'hide'
      }

      for (const data of this.requestData.options) {
        if (data.includes(this.search)) {
          const optionsObject = {
            value: '',
            check: true
          }
          optionsObject.value = data;
          if (this.requestData.selectAll) {
            this.inputOptions.push(optionsObject);
            optionsObject.check = true;
          }
          else {
            optionsObject.check = false;
          }
          optionsObject.value = data;
          this.customDimensionData.push(optionsObject);
        }
      }
      this.count = this.customDimensionData.length
    } else {
      this.searchService.searchCustom(this.searchTerm$, this.requestData)
        .subscribe(results => {
          if (results['result'] === undefined) {
            this.customDimensionData.length = 0
          } else {
            this.customDimensionData = this.searchService.onConvertCustomMulti(results['result'].items);
          }
          if (this.search === '') {
            this.multiSelectSearchIcon = 'show'
            this.mutliSelectCrossIcon = 'hide'
            for (const selv of this.selectedArray) {
              for (const k of this.customDimensionData) {
                if (k.value === selv.value) {
                  this.customDimensionData.splice(this.customDimensionData.indexOf(k), 1)
                }
              }
              this.customDimensionData.unshift(selv)
            }
          } else {
            this.mutliSelectCrossIcon = 'show'
            this.multiSelectSearchIcon = 'hide'
            for (const selv of this.selectedArray) {
              for (const k of this.customDimensionData) {
                if (k.value === selv.value) {
                  k.check = true
                }
              }
            }
          }
          this.count = this.customDimensionData.length
        });

    }

  }

  onCheckBoxSelect(event, valueObj) {
    if (event.target.checked) {
      this.searchResult.push(valueObj.value)
      this.searchResult = this.searchResult.slice();
      this.ref.markForCheck();

      valueObj.check = true;
      this.selectedArray.unshift(valueObj)
      this.inputOptions.push(valueObj)
      this.subscriptionService.setAutoSearchSelected(this.inputOptions);
    } else {
      // this.subscriptionService.setAutoSearchSelected(this.selectedArray);
      const tobeRemove = this.inputOptions.filter(item => {
        if (item.value === valueObj.value) {
          return item
        }
      }
      );
      if (this.inputOptions.indexOf(tobeRemove[0]) > 0) {
        this.inputOptions.splice(this.inputOptions.indexOf(tobeRemove[0]), 1)
        this.subscriptionService.setAutoSearchSelected(this.inputOptions);
      }
      this.selectedArray.splice(this.selectedArray.indexOf(valueObj), 1)
      this.searchResult.splice(this.searchResult.indexOf(valueObj.value), 1)
    }

  }

  checkAll() {
    this.selectedArray.length = 0
    this.searchResult.length = 0
    for (const data of this.customDimensionData) {
      data.check = true;
      this.selectedArray.unshift(data)

      this.searchResult.push(data.value)
      this.searchResult = this.searchResult.slice();
      this.ref.markForCheck();
    }
    this.subscriptionService.setAutoSearchSelected(this.searchResult);
  }

  unCheckAll() {
    for (const data of this.customDimensionData) {
      data.check = false;
    }
    this.selectedArray.length = 0
    this.searchResult.length = 0
    this.subscriptionService.setAutoSearchSelected(this.selectedArray);
  }

  clearSearchText(event: any) {
    this.search = '';
    event.target.value = ''
    this.onMultiSelectKey(event)
  }

  onSingleSelectMulti(childInput) {
    this.searchResult.length = 0;
    this.selectedArray.length = 0;
    this.searchResult.push(childInput.value)
    this.selectedArray.unshift(childInput)
    this.showOrHideDropdown = 'hide'
  }
  onMultiSelectSearch() {
    this.showOrHideDropdown = 'hide'
    this.subscriptionService.setAutoSearchText(this.selectedArray);
    this.selectedArray.length = 0
    this.searchResult.length = 0
    for (const data of this.customDimensionData) {
      data.check = false;
    }
  }

  // MultiSelect code end

  // Tree view code start
  onTreeCustomKey(event: any) {
    this.search = event.target.value;
    this.searchTerm$.next(event.target.value)
    this.searchService.searchCustom(this.searchTerm$, this.requestData)
      .subscribe(results => {
        if (results['result'] === undefined) {
          this.customDimensionData.length = 0
        } else {
          this.customDimensionData = this.searchService.onConvertTree(results['result'])
          this.count = this.customDimensionData.length;
        }
        if (this.search === '') {
          this.multiSelectSearchIcon = 'show'
          this.mutliSelectCrossIcon = 'hide'
          // Commenting for now .. Will check later and add the functionality
          // for(let selv of this.selectedArray){
          //   for(let parent of this.customDimensionData){
          //     for(let child of parent.items){
          //       if(child.value === selv.value){
          //         child.ckheck = true;
          //         this.customDimensionData.splice(this.customDimensionData.indexOf(parent),1)
          //       }
          //     }
          //     this.customDimensionData.unshift(parent)
          //   }
          // }
        } else {
          this.mutliSelectCrossIcon = 'show'
          this.multiSelectSearchIcon = 'hide'
        }
        for (const selv of this.selectedArray) {
          for (const parent of this.customDimensionData) {
            for (const child of parent.items) {
              if (child.value === selv.value) {
                child.check = true
              }
            }
            let parentInputCheck: boolean;
            for (const child of parent.items) {
              if (child.check) {
                parentInputCheck = true;
                parent.check = parentInputCheck
              } else {
                parentInputCheck = false;
                parent.check = parentInputCheck
                break;
              }
            }
          }
        }
        this.count = this.customDimensionData.length
      });
  }

  onCheckBoxCustomParentSelect(event, parentInput) {
    if (event.target.checked) {
      for (const child of parentInput.items) {
        let isExists = false;
        for (const selectedValue of this.selectedArray) {
          if (selectedValue.value === child.value) {
            isExists = true
          }
        }
        if (!isExists) {
          this.searchResult.push(child.value);
          this.selectedArray.unshift(child);
          child.check = true;
        }

      }
    } else {
      for (const child of parentInput.items) {
        child.check = false;
        this.selectedArray.splice(this.selectedArray.indexOf(child), 1)
        this.searchResult.splice(this.searchResult.indexOf(child.value), 1)
      }
    }
  }

  onSingleSelectTree(childInput) {
    this.searchResult.length = 0;
    this.selectedArray.length = 0;
    this.searchResult.push(childInput.value)
    this.selectedArray.unshift(childInput)
    this.showOrHideDropdown = 'hide'
  }

  onCheckBoxChildSelect(event, childInput, parentInput) {
    if (event.target.checked) {
      this.searchResult.push(childInput.value)
      this.selectedArray.unshift(childInput)
      let parentInputCheck: boolean;
      for (const child of parentInput.items) {
        if (child.check) {
          parentInputCheck = true;
          parentInput.check = parentInputCheck
        } else {
          parentInputCheck = false;
          parentInput.check = parentInputCheck
          break;
        }
      }
    } else {
      // Logic is bad... but facing issues. need to check how to resolve it
      // childInput.check = true;
      const newSelectedArray: any[] = new Array;
      for (const s of this.selectedArray) {
        if (s.value === childInput.value) {
          // do nothing
        } else {
          newSelectedArray.unshift(s)
        }
      }
      this.selectedArray.length = 0;
      this.selectedArray = newSelectedArray
      // this.selectedArray.splice(this.selectedArray.indexOf(childInput),1)
      this.searchResult.splice(this.searchResult.indexOf(childInput.value), 1)
      parentInput.check = false;
    }
  }

  checkAllTree() {
    this.selectedArray.length = 0
    this.searchResult.length = 0
    for (const parent of this.customDimensionData) {
      parent.check = true;
      for (const child of parent.items) {
        child.check = true;
        this.selectedArray.unshift(child)
        this.searchResult.push(child.value)
      }
    }
    this.subscriptionService.setAutoSearchSelected(this.selectedArray);
  }

  unCheckAllTree() {
    for (const parent of this.customDimensionData) {
      parent.check = false;
      for (const child of parent.items) {
        child.check = false;
      }
      this.selectedArray.length = 0
      this.searchResult.length = 0
    }
    this.subscriptionService.setAutoSearchSelected(this.selectedArray);
  }

  clearSearchTextTree(event: any) {
    this.search = '';
    event.target.value = '';
    this.onTreeCustomKey(event)
  }

  onTreeSelectSearch() {
    this.showOrHideDropdown = 'hide'
    this.subscriptionService.setAutoSearchText(this.selectedArray);
    this.selectedArray.length = 0
    this.searchResult.length = 0
    for (const parent of this.customDimensionData) {
      parent.check = false;
      for (const child of parent.items) {
        child.check = false;
      }
    }
  }

  // Tree view code end

  // multitree view code

  onMultiTreeCustomKey(event: any) {
    this.search = event.target.value;
    this.searchTerm$.next(event.target.value)
    this.searchService.searchCustom(this.searchTerm$, this.requestData)
      .subscribe(results => {
        if (results['result'] === undefined) {
          this.customDimensionData.length = 0
        } else {
          this.customDimensionData = this.searchService.onConvertMultiTree(results['result'])
          this.count = this.customDimensionData.length;
        }
        if (this.search === '') {
          this.multiSelectSearchIcon = 'show'
          this.mutliSelectCrossIcon = 'hide'
          // for(let selv of this.selectedArray){
          //   for(let k of this.customDimensionData){
          //     if(k.value === selv.value){
          //       this.customDimensionData.splice(this.customDimensionData.indexOf(k),1)
          //     }
          //   }
          //   this.customDimensionData.unshift(selv)
          // }
        } else {
          this.mutliSelectCrossIcon = 'show'
          this.multiSelectSearchIcon = 'hide'
        }
        for (const selv of this.selectedArray) {
          for (const gp of this.customDimensionData) {
            for (const p of gp.parent) {
              for (const c of p.items) {
                if (c.value === selv.value) {
                  c.check = true
                }
              }
              let parentInputCheck: boolean;
              for (const child of p.items) {
                if (child.check) {
                  parentInputCheck = true;
                  p.check = parentInputCheck
                } else {
                  parentInputCheck = false;
                  p.check = parentInputCheck
                  break;
                }
              }
            }
          }
        }
        this.count = this.customDimensionData.length
      });
  }

  checkAllMultiTree() {
    this.selectedArray.length = 0
    this.searchResult.length = 0
    for (const grandParent of this.customDimensionData) {
      for (const parent of grandParent.parent) {
        parent.check = true;
        for (const child of parent.items) {
          child.check = true;
          this.selectedArray.unshift(child)
          this.searchResult.push(child.value)
        }
      }
    }
  }

  onSingleSelectMultiTree(childInput) {
    this.searchResult.length = 0;
    this.selectedArray.length = 0;
    this.searchResult.push(childInput.value)
    this.selectedArray.unshift(childInput)
    this.showOrHideDropdown = 'hide'
  }

  unCheckAllMultiTree() {
    for (const grandParent of this.customDimensionData) {
      for (const parent of grandParent.parent) {
        parent.check = false;
        for (const child of parent.items) {
          child.check = false;
        }
      }
    }
    this.selectedArray.length = 0
    this.searchResult.length = 0
  }

  clearSearchTextMultiTree(event: any) {
    this.search = '';
    event.target.value = '';
    this.onMultiTreeCustomKey(event)
  }

  onMultiTreeSelectSearch() {
    this.showOrHideDropdown = 'hide'
    this.subscriptionService.setAutoSearchText(this.selectedArray);
    this.selectedArray.length = 0
    this.searchResult.length = 0
    for (const grandParent of this.customDimensionData) {
      for (const parent of grandParent.parent) {
        parent.check = false;
        for (const child of parent.items) {
          child.check = false;
        }
      }
    }
  }

  // multitree code end

  // Single select code start

  onSingleSelect(val: any) {
    this.singleSearchSelectedValue = val;
    this.subscriptionService.setSingleSearchText(val);
    // this.dropDownlistService.setSelectedDropDownValue(this.singleSearchSelectedValue);
  }

  onSingleSelectSearch() {
    this.showOrHideDropdown = 'hide'
    this.dropDownlistService.setSelectedDropDownValue(this.singleSearchSelectedValue);
  }

  ngOnDestroy() {
    this.subscriptionService.setPMRequestData('');
    if (this.autosearchSubscription) {
      this.autosearchSubscription.unsubscribe();
    }
  }
}
