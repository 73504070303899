import { ColDef, GridOptions } from '@ag-grid-community/all-modules'
import { Component, OnInit, Input, EventEmitter, Output, HostListener, OnDestroy, ViewChild } from '@angular/core'
import {
  Toast,
  BodyOutputType,
  ToasterService,
} from 'angular2-toaster'
import { BaseService } from 'app/modules/common/base.service'
import {
  mainGridHeaderMap
} from '../../industry-news-events.constants'
import { IndustryNewsEventsService } from '../../shared/industry-news-events.service'
import { CustomActionCellComponent } from 'app/modules/core/grid/custom-action-cell/custom-action-cell.component'
import { DialogBoxService, DialogSize, DialogType, LdDialog, DialogButtonName } from 'app/modules/leva-ui-library/components/dialog-box/dialog-box.service'
import { EventMetaInfoService } from './event-meta-info.service'
import { EventManagementService } from './event-management.service'
import {
  ContentGridUtils,
} from 'app/modules/curator/content/manufacturer-classification/shared/content-grid-utils.service'
import { cloneDeep } from 'lodash'
import { NpiToasterService } from 'app/modules/npi/npi-toaster.service'
import { Subscription, forkJoin } from 'rxjs';
import { CreateEventComponent } from '../create-event/create-event.component'
import { DateUtils } from 'app/modules/common/utills/DateUtils.service';
import { Router } from '@angular/router'

@Component({
  selector: 'cprt-event-management',
  templateUrl: './event-management.component.html',
  styleUrls: ['./event-management.component.sass'],
  providers: [IndustryNewsEventsService, ContentGridUtils, EventManagementService],
})
export class EventManagementComponent implements OnInit, OnDestroy {

  filterViewContainer = {
    isExpand: false,
    show: false,
    close() {
      this.isExpand = false
    },
  }
  disableClearFilter = true
  selectedFilters: any = {}
  filterDimensions = []
  defaultDimensions = []
  disableReset = true
  disableSave = true
  resetFilter = this.generateRandomKey()
  showNewEventPopup = false
  disableCreate = false
  currentCellNotifyMsg = null
  showInactive = false

  noData = true
  userSessionData = null
  gridHeaders = []
  eventGridData = []
  showGrid = false
  eventTypes = []
  statusList = [
    { val: 'Active', displayName: 'Active' },
    { val: 'Inactive', displayName: 'Inactive' },
  ]

  sendMailConfirmationDialogConfig: LdDialog = {
    title: 'Send Confirmation',
    id: 'send_mail_confirmation',
    message: 'This action will trigger mail sending, do you want to continue?',
    type: DialogType.CONFIRMATION,
    buttons: [
      {
        title: DialogButtonName.NO,
        id: 'no',
      },
      {
        title: DialogButtonName.YES,
        id: 'yes',
        primary: true,
      }
    ],
  }

  eventsCustomOptions: GridOptions
  countryRegionMap = null
  mappedRegion = ''
  mappedLatLongs = null
  latLongMap = null
  changedValMap = {}
  editedRows = []
  errorList = []
  headers = []
  locationsModifiedEvents = []
  locationsErrorEvents = []

  fileDataObject = {
    title: 'CONTENT',
    content: true,
    servicePath: 'RiskManagementService/events/upload',
    formParams: {
      type: 'event_ingestion'
    }
  }
  showUploadHistoryPopup = false
  uploadHistoryDialogConfig: LdDialog = {
    title: 'Update Status',
    id: 'upload_history_dialog',
    template: true,
    customSize: {
      width: 1000,
      height: 300
    },
    beforeClose: () => {
      this.showUploadHistoryPopup = false
      return false
    },
    type: DialogType.INFORMATION
  }
  excelInfo = {
    method: 'POST',
    type: 'NPI',
    emit: true,
  }
  payloadObject: {
    targetedType: string
    servicePath: string
    formParams: { filter: string }
  }
  downloadPayload = null

  @Input('showFilter') showFilter = false
  @Input('showCustomerEventOnly') showCustomerEventOnly = false

  @Output() onCreate = new EventEmitter<any>()
  @Output() onAddEventClick = new EventEmitter<any>()
  @Output() onCloneEventClick = new EventEmitter<any>()

  @ViewChild(CreateEventComponent, { static: false })
  createEventComponent: CreateEventComponent

  eventDialog: LdDialog = {
    title: 'Event',
    id: 'event-creation',
    template: true,
    customSize: {
      height: 0,
      width: 0
    },
    type: DialogType.CONFIRMATION,
    beforeClose: (params) => {
      if (params && params.primary) {
        if (this.createEventComponent.isValidEventForm()) {
          return true
        }
        this.createEventComponent.newEventForm.markAllAsTouched()
        return false
      }
      const sameData = JSON.stringify(this.createEventComponent.newEventForm.value.userData) === JSON.stringify(this.createEventComponent.eventProperties)
      const isClone = this.eventMetaInfoService.getEventMetaInfo().isClone
      if (!isClone) {
        if (sameData) {
          this.showEventDetails = false
        }
      }
      return !sameData || (isClone && sameData && this.createEventComponent.newEventForm.value.userData.title)
    },
    discardConfiguration: {
      title: 'Changes',
      size: DialogSize.MEDIUM,
      draggable: true,
      modal: true,
      id: '123',
      message:
        'You have unsaved changes. Would you like to discard the changes?',
    },
    buttons: [
      {
        title: DialogButtonName.CANCEL,
        id: 'cancel',
        primary: false,
      },
      {
        title: 'Create',
        id: 'create',
        primary: true,
        callback: () => {
          this.createEventComponent.onClickSubmit()
          return false
        }
      }
    ]
  }

  showEventDetails = false
  rowSubscription: Subscription
  lastEventUpdatedTime: any;
  selectedEvent: any;

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    const EVENT_POPUP_HEIGHT = window.innerHeight - 20 * (window.innerHeight / 100)
    const EVENT_POPUP_WIDTH = window.innerWidth - 20 * (window.innerWidth / 100)
    this.eventDialog.customSize.height = EVENT_POPUP_HEIGHT
    this.eventDialog.customSize.width = EVENT_POPUP_WIDTH
  }

  constructor(
    private newsEventService: IndustryNewsEventsService,
    private toasterService: ToasterService,
    private baseService: BaseService,
    public contentGridUtils: ContentGridUtils,
    private dialogBoxService: DialogBoxService,
    private eventMetaInfoService: EventMetaInfoService,
    public eventManagementService: EventManagementService,
    private router: Router
  ) {
    this.userSessionData = this.baseService.getUserInformation()
    if (this.userSessionData) {
      this.newsEventService.tenantId = this.userSessionData.tenantId
      this.eventManagementService.tenantId = this.userSessionData.tenantId
    }
    this.contentGridUtils.componentService = this.eventManagementService
    NpiToasterService.setToasterService(toasterService)
  }

  ngOnInit() {
    this.onResize(window)
    this.eventMetaInfoService.setStatusList(JSON.parse(JSON.stringify(this.statusList)))
    this.rowSubscription = this.eventManagementService.rowsUpdateSub.subscribe(() => {
      this.locationsModifiedEvents = []
      this.locationsErrorEvents = []
      this.editedRows = []
      this.errorList = []
      this.changedValMap = {}
    })
    this.eventManagementService.showCustomerEventOnly = this.showCustomerEventOnly
    if (this.showCustomerEventOnly) {
      this.selectedFilters.status = this.showInactive ? 'Inactive': 'Active'
    }
    this.getEventTypes()
    this.eventManagementService.statusUpdateSub.subscribe(() => {
      this.onCreate.emit(true)
    })
    this.newsEventService.getEventsJobStatus().subscribe((res) => {
      this.lastEventUpdatedTime = DateUtils.format(res.result['Last updated date'], 'LLLL')
    })
  }

  downloadExcel(downloadType = 'xls') {
    const filters = this.convertToJsonParam(this.selectedFilters)
    let filterOption = encodeURIComponent(
      JSON.stringify(filters)
    )

    if (this.showCustomerEventOnly) {
      const payload: any = { ...this.selectedFilters }
      payload.source_id = this.newsEventService.tenantId
      payload.status = 'Active'

      filterOption = encodeURIComponent(
        JSON.stringify(payload)
      )
    }

    const dataObject = {
      targetedType: 'riskManagementService',
      servicePath: '/events/download',
      formParams: {
        filter: filterOption,
      },
    }
    this.payloadObject = dataObject
  }
  getEventTypes() {
    const staticTypesSubs = [
      this.newsEventService.getEventTypeList(),
      this.newsEventService.getTenantIdList()
    ]

    forkJoin(staticTypesSubs).subscribe((responses) => {
      const eventTypeResponse = responses[0]
      const tenantsResponse = responses[1]
      this.newsEventService.tenants = tenantsResponse.result
      if (eventTypeResponse && eventTypeResponse.result) {
        const suggestionsResponse = eventTypeResponse.result
        this.newsEventService.eventTypes =
          suggestionsResponse.suggestions instanceof Array
            ? suggestionsResponse.suggestions
            : []
        this.eventTypes = this.newsEventService.eventTypes.map((eventType) => ({
          val: eventType,
          displayName: eventType,
        }))
        this.eventMetaInfoService.setTypesList(JSON.parse(JSON.stringify(this.eventTypes)))
        this.getFilterDimensions()
        this.getHeaders()
      } else {
        this.newsEventService.eventTypes = []
      }
    })
  }

  getHeaders() {
    const payload = {
      tenantId: this.newsEventService.tenantId,
      view: 'Summary',
      widgetTabKey: this.showCustomerEventOnly ? 'USER_EVENTS' : 'EVENTS',
    }
    this.newsEventService
      .getEventGridHeaders(payload)
      .subscribe((headerResponse) => {
        let columnDefs = []
        if (headerResponse && headerResponse.result) {
          const mainGridHeader = cloneDeep(mainGridHeaderMap)
          if (!this.showCustomerEventOnly) {
            mainGridHeader.sourceId = {
              headerName: 'Source Id',
              field: 'sourceId',
            }
          }
          columnDefs = this.newsEventService.parseHeaders(
            headerResponse.result.NO_SECTION.widgetHeaders || [],
            mainGridHeader
          )
          const actions: ColDef = {
            width: this.showCustomerEventOnly ? 70 : 90,
            headerName: 'Actions',
            pinned: 'right',
            cellRendererFramework: CustomActionCellComponent,
            cellRendererParams: {
              buttons: [{
                key: 'send',
                icon: 'send',
                tooltip: 'Send mail',
              }],
              editFieldTitle: 'Edit Event Details'
            },
            valueGetter: (params) => {
              return {
                send: params.data.activeState === 'Active' &&  !params.data.customerCreatedEvent
              }
            },
            lockPinned: true,
            lockVisible: true,
            lockPosition: true,
            sortable: false,
            menuTabs: []
          }
          const checkBoxHeader = {
            headerName: '',
            lockPosition: true,
            cellClass: 'locked-col',
            checkboxSelection: true,
            field: '',
            width: 40,
            lockVisible: true,
            pinned: 'left',
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            menuTabs: [],
          };
          columnDefs.unshift(checkBoxHeader)
          columnDefs.push(actions)
        } else {
          columnDefs = []
        }
        this.contentGridUtils.serviceHeaders = headerResponse.result.NO_SECTION.widgetHeaders
        this.contentGridUtils.gridOptions.context = {
          componentParent: this,
        }
        this.contentGridUtils.gridOptions.columnDefs = columnDefs
        this.contentGridUtils.loadRecords()
      })
  }

  onActionClick(type, paramsData) {
    if (type === 'send') {
      this.selectedEvent = paramsData.eventId
      this.openDialog(this.sendMailConfirmationDialogConfig.id)

    } else {
      this.showEventDetails = true
      this.eventMetaInfoService.setEventId(paramsData.eventId)
      this.eventMetaInfoService.setEventData(null)
      this.eventMetaInfoService.setCloneStatus(false)
      // this.eventDialog.buttons[1].title = 'Update'
      // this.dialogBoxService.open(this.eventDialog.id)
      // this.router.navigate(['app/supplyriskassessment/mitigate-events/save-event'], { state: { showCustomerEventOnly: this.showCustomerEventOnly } })
      this.onAddEventClick.emit(true)
    }
  }

  onCloneEvent() {
    this.eventMetaInfoService.setEventId(null)
    this.eventMetaInfoService.setCloneStatus(true)
    this.onCloneEventClick.emit(true)
  }

  onCloneClick(id: string) {
    this.showEventDetails = true
    this.eventMetaInfoService.setEventId(null)
    this.eventDialog.buttons[1].title = 'Clone'
    this.eventMetaInfoService.setCloneStatus(true)
    this.dialogBoxService.open(id)
  }

  openDialog(id: string) {
    if (id === this.uploadHistoryDialogConfig.id) {
      this.showUploadHistoryPopup = true
      this.dialogBoxService.open(this.uploadHistoryDialogConfig.id)
      return
    }
    this.showEventDetails = true
    this.eventMetaInfoService.setEventId(null)
    this.eventDialog.buttons[1].title = 'Create'
    this.eventMetaInfoService.setCloneStatus(false)
    this.dialogBoxService.open(id)
  }

  onDismissedFilterChanged() {
    this.selectedFilters.status = this.showInactive ? 'Inactive': 'Active'
    this.contentGridUtils.activeFilter = this.selectedFilters
    this.getEvents()
  }

  getEvents() {
    this.contentGridUtils.loadRecords('', null, { pageNumber: 1, pullRecordCount: true }, this.selectedFilters)
  }

  onExpand(event) {
    event.stopPropagation()
    this.filterViewContainer.isExpand = !this.filterViewContainer.isExpand
  }

  generateRandomKey() {
    const crypto = window.crypto
    const array = new Uint32Array(1)
    return crypto.getRandomValues(array)[0]
  }

  resetFilterSelection() {
    this.disableClearFilter = true
    this.filterDimensions = []
    this.selectedFilters = []
    this.resetFilter = this.generateRandomKey()
    this.searchEvents()
    setTimeout(() => {
      this.filterDimensions = JSON.parse(JSON.stringify(this.defaultDimensions))
    }, 10)
    this.filterViewContainer.close()
  }

  onFilterChange(e) {
    if (e.data.length) {
      this.disableClearFilter = !e.data.some((item) => item.selectedItems || item.dateFrom || item.dateTo)
    }
    this.selectedFilters = e.data
  }

  convertToJsonParam(data) {
    if (!data) {
      return
    }
    const jsonObj = {}
    data.forEach((obj) => {
      if (obj.type === 'set') {
        jsonObj[obj.key] = {
          values: obj.selectedItems,
          type: obj.condition,
          filterType: obj.type
        }
      } else if (obj.type === 'date') {
        jsonObj[obj.key] = {
          dateFrom: obj.dateFrom,
          dateTo: obj.dateTo,
          type: obj.condition,
          filterType: obj.type
        }
      } else if (obj.type === 'number') {
        jsonObj[obj.key] = {
          filter: obj.selectedItems,
          filterTo: obj.filterTo,
          dateTo: obj.dateTo,
          type: obj.condition,
          filterType: obj.type
        }
      } else {
        jsonObj[obj.key] = {
          filter: obj.selectedItems,
          type: obj.condition,
          filterType: obj.type
        }
      }
    })
    return jsonObj
  }

  searchEvents() {
    const filteredJson = this.convertToJsonParam(this.selectedFilters)
    this.contentGridUtils.activeFilter = filteredJson
    this.contentGridUtils.loadRecords('', null, { pageNumber: 1, pullRecordCount: true }, filteredJson)
  }

  getFilterDimensions() {
    this.newsEventService
      .getFilterDimensions()
      .subscribe((dimensionResponse) => {
        if (dimensionResponse && dimensionResponse.result) {
          if (dimensionResponse.responseStatus.code === 200) {
            this.defaultDimensions = this.newsEventService.parseFilterDimensions(
              JSON.parse(JSON.stringify(dimensionResponse.result))
            )
            this.filterDimensions = JSON.parse(
              JSON.stringify(this.defaultDimensions)
            )
          }
        }
      })
  }

  updateEventsData() {
    this.newsEventService
      .updateEventsData(this.editedRows)
      .subscribe((updateResponse) => {
        if (updateResponse && updateResponse.result) {
          if (updateResponse.responseStatus.code === 200) {
            this.message('success', updateResponse.message)
            this.showGrid = false
            this.editedRows = []
            this.changedValMap = {}
            this.locationsModifiedEvents = []
            this.locationsErrorEvents = []
            this.errorList = []
            // this.toggleActionButtons()
            this.getEvents()
          } else {
            this.message('warning', updateResponse.message)
          }
        }
      })
  }

  onCloseCreateEventWindow() {
    this.showNewEventPopup = false
  }

  onCreateEvent(params) {
    if (params) {
      // this.showNewEventPopup = false
      this.dialogBoxService.close(this.eventDialog.id)
      this.showEventDetails = false
      this.showGrid = false
      this.getEvents()
      // refresh I2A main events
      if (this.showCustomerEventOnly) {
        this.onCreate.emit(true)
      }
    }
  }

  removeNullValues(payload) {
    ; (Object.keys(payload) || []).forEach((keys) => {
      Object.keys(payload[keys]).forEach((key) => {
        if (payload[keys][key] === null) {
          delete payload[keys][key]
        }
      })
    })

    return payload
  }

  message(type: string, message: string) {
    const toast: Toast = {
      type,
      body: '<div vertical-align=middle>' + message + '</div>',
      bodyOutputType: BodyOutputType.TrustedHtml,
    }
    this.toasterService.pop(toast)
  }

  onResetChanges() {
    this.errorList = []
    this.editedRows = []
    this.locationsModifiedEvents = []
    this.locationsErrorEvents = []
    this.changedValMap = {}
    this.showGrid = false
    this.getEvents()
  }

  toggleMessageTypes() {
    this.currentCellNotifyMsg = this.errorList.length
      ? this.errorList.length === 1
        ? `There is 1 error, resolve to proceed`
        : `There are ${this.errorList.length} errors, resolve to proceed`
      : this.editedRows.length
        ? `Events information changes made, click on 'Save' to update the changes`
        : ``
  }

  confirmationButtonClick(event) {
    if (event.button.title === DialogButtonName.YES) {
      this.sendMail(this.selectedEvent)
    }
  }

  onCloseDialog() {
    this.showEventDetails = false
    this.dialogBoxService.close(this.eventDialog.id)
  }

  onDialogueClick() {
    this.showEventDetails = false
  }
  syncEvents() {
    this.newsEventService.syncEvents().subscribe(() => {
      this.message('success', 'Events sync has been initiated')
    })
  }

  onAddEvent() {
    this.eventMetaInfoService.setEventId(null)
    this.eventMetaInfoService.setEventData(null)
    this.eventMetaInfoService.setCloneStatus(false)
    this.onAddEventClick.emit(false)
  }

  onFileUpload(event) {
    this.getEvents()
  }

  sendMail(eventId) {
    this.eventManagementService.triggerMail({
      eventId
    }).subscribe((res) => {
      this.message('success', res.message)
    })
  }

  ngOnDestroy() {
    this.rowSubscription.unsubscribe()
  }
}
