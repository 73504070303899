import { DataStorageService } from 'app/modules/common/data-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { LdAddTask } from './add-task.service';
import { ToastService } from 'app/modules/srfq/shared/toaster.service';

@Component({
  selector: 'ld-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.sass'],
})
export class AddTaskComponent implements OnInit {
  @Input() configuration: LdAddTask;
  @Input() rowSelected: any;
  enableLoader;
  constructor(
    public dataStorageService: DataStorageService,
    private toast: ToastService
  ) {}
  ngOnInit() {
    console.log(this.configuration);
  }
  openAddTask() {
    this.enableLoader = true;
    setTimeout(() => (this.enableLoader = false), 5000);
    if (this.configuration && this.configuration.isDisabled) {
      this.toast.populateToaster(
        'warning',
        this.configuration.disabledMessage || 'Icon is not enabled'
      );
      return;
    }
    this.dataStorageService.addTaskConfig = this.configuration;
    this.dataStorageService.showTaskPane = false;
    this.dataStorageService.showTaskLoader = true;
    this.dataStorageService.taskActionInput = true;
    this.dataStorageService.setAddTask(this.configuration);
  }
}
