import { Component, OnInit, Input } from '@angular/core';

import { NoData } from './shared/no-data.model';
import { NoDataService } from './shared/no-data.service';

@Component({
  selector: 'no-data',
  templateUrl: 'no-data.component.html',
  styleUrls: ['./no-data.component.sass'],
  providers: [NoDataService]
})

export class NoDataComponent implements OnInit {
  @Input() message: any = 'No Data Found';
  noData: NoData[] = [];

  constructor(private noDataService: NoDataService) { }

  ngOnInit() {

  }
}
