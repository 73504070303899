/**
 * Validations constants
 */

export const numberDropdownConstants = [
  {label : '0', id: 0},{label : '1', id: 1},{label : '2', id: 2},{label : '3', id: 3},{label : '4', id: 4},
  {label : '5', id: 5},{label : '6', id: 6},{label : '7', id: 7},{label : '8', id: 8},{label : '9', id: 9},
]
export const VALIDATIONS = {
  dropdown: [{ label: 'Blanks are not allowed', id: 0, key:'NONBLANK'}],
  date: [
    { label: 'Less than', id: 0, key: 'DATELESS' },
    { label: 'Greater than', id: 1, key: 'DATEGREATER' },
    { label: 'Range', id: 2, key: 'DATERANGE' },
    { label: 'Allow Only valid date', id: 3, key:'VALIDDATE'}
  ],
  text: [
    { label: 'Blanks are not allowed', id: 0, key:'NONBLANK'},
    { label: 'Allow Alpha character or number', id: 1, key: 'ALLOWALPHA'}
  ],
  number: [
    { label: 'Min', id: 0, key: 'MIN' },
    { label: 'Max', id: 1, key: 'MAX' },
    { label: 'Range', id: 2, key: 'RANGE' },
    { label: 'Decimal Precision', id: 3, key: 'DECIMAL' },
    { label: 'Allow Only Positive', id: 4, key: 'POSITIVE' },
    { label: 'Allow Only Negative', id: 5, key: 'NEGATIVE' },
    { label : 'Allow Only Integer', id:6 , key:'INTEGERONLY'},
    { label: 'Allow non zero number only', id: 7 , key:'NONZERO'},
    { label: 'Don\'t Allow blank', id: 8, key:'NONBLANK'}
  ]
};

export const dateKeys = {lessThan : 'DATELESS', greaterThan : 'DATEGREATER', range: 'DATERANGE'};
export const numberKeys = {MIN: 'MIN', MAX: 'MAX', RANGE: 'RANGE', ALLOWALPHA:'ALLOWALPHA'};

