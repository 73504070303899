import { ChatBotModule } from './modules/chatbot/chatbot.module';
import { CustomerConfigurationService } from './modules/common/customerConfigurationService';
import { GridvalueComponent } from './modules/core/grid/gridvalueFormatter/gridvalueFormatter.component';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { ApiServiceProvider } from './api-service';
import { BaseAppModule } from './modules/base-app/base.app.module';
import { ApplicationURLS } from 'app/modules/common/applicationURL';
import { DismissComponent } from 'app/modules/core/grid/dismiss/dismiss.component';
import { LoginAuthentication } from 'app/modules/authentication/login_auth.module';
import { BaseService } from 'app/modules/common/base.service';
import { FeatureService } from 'app/modules/common/feature.service';
import { UtilService } from 'app/modules/common/utills/util.service';
import { AgGridModule } from '@ag-grid-community/angular';
import { OpportunitiesListService } from 'app/modules/core/opportunities-list/shared/opportunities-list.service';
import { UserGroupComponent } from 'app/modules/core/grid/userGroup/userGroup.component';
import { CostLookupComponent } from 'app/modules/core/grid/costlookup/costlookup.component';
import { DownloadExcelService } from 'app/modules/common/utills/download-excel.service';
import { TimeSelectionDataService } from 'app/modules/core/time-selection/shared/time-selectiondata.service';
import { DataExporterService } from './modules/common/utills/data-exporter.service';
import { ConfigService } from 'app/modules/common/configservice';
import { HelpCenterModule } from 'app/modules/help-center/help-center.module';
import { GridbuttonComponent } from 'app/modules/core/grid/gridbutton/gridbutton.component';
import { DsComponent } from 'app/modules/core/grid/ds/ds.component';
import { AuthGuard } from 'app/modules/common/auth.guard';
import { AppRoutingModule } from 'app/app-routing.module';

import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { PartMatchingService } from 'app/modules/mi/partmatching/partmatching.service';
import { AppIconsComponent } from 'app/modules/core/app-icons/app-icons.component';
import { NewsListService } from 'app/modules/core/news-list/news-list.service';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { SubscriptionService } from 'app/modules/common/subscription.service';
import { IndustryNewsContentService } from 'app/modules/mi/IndustryNewsContent/shared/IndustryNewsContent.service';
import { FileuploadService } from 'app/modules/core/fileupload/shared/fileupload.service';
import { MultiplePartMatchingViewService } from 'app/modules/mi/partmatching/multiple-part-matching-view/shared/multiple-part-matching-view.service';
import { BOTHelpService } from 'app/modules/base-app/bot/help/help.service';
import { DropDownListService } from 'app/modules/common/dropdown-list.service';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { npUsefullComponent } from 'app/modules/core/grid/npusefull/npUsefull.component';
import { GridnumericEditorComponent } from 'app/modules/core/grid/gridNumericEditor/gridNumericeditor.component';
import { ExportLoaderService } from 'app/modules/common/excel-preloader.service';
import { AutoSearchService } from 'app/modules/core/autosearch/shared/autosearch.service';
import { ChatService } from 'app/modules/common/socket/chart.service';
import { WebsocketService } from 'app/modules/common/socket/webscoket.service';
import { SocketService } from 'app/modules/common/socket/scoket.service';
import { NgxLoadingModule } from 'ngx-loading';
import { CanDeactivateGuard } from 'app/modules/common/candeactivate.guard';
import { GridFormatterService } from './grid.formatter.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Angulartics2Module } from 'angulartics2';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { SharedTargetCostComponent } from 'app/modules/core/nxt-grid/shared-target-checkbox';
import { HelpRendererComponent } from 'app/modules/core/nxt-grid/help-renderer.component';
import { DetailHelpRendererComponent } from 'app/modules/srfq/detail/detail-view/detail-helper.renderer.component';
import { StatusModifierComponent } from 'app/modules/srfq/detail/detail-view/status-modifier.component';
import { ResponsesStatusComponent } from 'app/modules/srfq/detail/detail-view/responses.status.component';
import { ShowDeviationsComponent } from 'app/modules/core/nxt-grid/show.deviations.component';
import { DropdownStatusComponent } from 'app/modules/core/nxt-grid/dropdown.status.component';
import { InsightComponent } from './modules/core/nxt-grid/qualitative.insights.component';
import { NegotiationLeversService } from 'app/modules/srfq/common/srfx-negotiation-levers/shared/negotiation-levers.service';
import {
  ngxZendeskWebwidgetModule,
  ngxZendeskWebwidgetConfig,
} from 'ngx-zendesk-webwidget';
import { RfxListFieldComponent } from './modules/core/grid/rfxfield/rfxListField';
import { RfxListComponent } from './modules/core/grid/rfxList/rfxlist.component';
import { RfxAlertsComponent } from './modules/core/grid/rfxAlerts/rfxAlerts.component';
import { VirtualRepeatDirective } from './modules/srfq/detail/detail-view/virtual-scroll-horizontal-details/VirtualRepeatDirective';
import { AdhocSubscriptionService } from 'app/modules/common/adhoc.subscription.service';
import { UserIdleModule } from 'angular-user-idle';
import { environment } from 'environments/environment';
import { GenericDynamicFormService } from './modules/common/generic-dynamic-form.service';
import { ViewUserGroupFeatures } from 'app/modules/core/grid/userGroupFeatures/viewUserGroups.component';
import { ToastService } from './modules/srfq/shared/toaster.service';
import { WindowService } from './modules/common/window.service';
import { ClientTokenService } from './modules/common/client-token.service';
import { ProductDropdownStatusRenderer } from './modules/core/nxt-grid/product.dropdown.renderer.component';
import { Detail } from './modules/srfq/detail/shared/detail.model';
import { ApiCommonService } from './api-common-service';
import { ToasterModule, ToasterService } from 'angular2-toaster';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './modules/common/interceptors/tokeninterceptor.service';
import { ApiServicePathsService } from './modules/common/apiservicepaths.service';
import { RouterModule } from '@angular/router';
import { RfqConfigModule } from './modules/rfq-configurations/rfq-config.module';
import { PageLoaderService } from './modules/common/utills/page-loader.service';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { RowFormatter } from './modules/core/nxt-grid/rowFormatter.component';
import { SharedModule } from './modules/shared/shared.module';
import { CoreModule } from './modules/core/core.module';
import { ChatBotService } from './modules/chatbot/chatbot.service';
import { BotService } from './modules/base-app/bot/shared/bot.service';
import { SraEmitterService } from './modules/supply-risk-assessment/sra-emitter.service'
import { GridEmitterService } from './modules/common/grid.emitter.service';
import { ConfigLocator } from './modules/core/feature-flags/config-locator';

export class ZendeskConfig extends ngxZendeskWebwidgetConfig {
  public accountUrl = 'levadatasupport.zendesk.com';
  public beforePageLoad(zE) {
    zE.setLocale('en');
    zE.hide();
  }
}

const userIdleTimeConfig = {
  idle: environment.sessionTimeoutProperties.idle,
  ping: environment.sessionTimeoutProperties.ping,
  timeout: environment.sessionTimeoutProperties.timeUp,
};
import { RatingComponent } from './modules/core/rating/rating.component';
import { WorkbenchService } from './modules/scenario-workbench/workbench/workbench.service';
import { DistributerLinkComponent } from './modules/core/grid/distributerlink/distributer-link.component';
import { RiskConfigurationEmitterService } from './modules/administration/risk-configuration/risk-configuration.emitter.service';
import { GenericDetailDropdownComponent } from './modules/srfq/detail/detail-view/generic-dropdown.component';
import { ContextLandingPageModule } from './modules/context-landing-page/context-landing-page.module';
import { ChildMessageRendererComponent } from './modules/curator/industry-news/industry-news-feed/shared/child-message-renderer';
import { FeatureFlagService, UnleashFeatureFlagService } from './modules/core/feature-flags/feature-flag.service';
import { SingleSelectRenderer } from './modules/srfq/detail/detail-view/singleselect.renderer.component ';
import { SingleSelectComponent } from './modules/core/nxt-grid/single.select.component';
import { FeatureFlagClientFactory } from './modules/core/feature-flags/feature-flag-client-factory';
import { SingleSelectSimComponent } from './modules/core/nxt-grid/single.select.sim.component';
@NgModule({
  declarations: [
    AppComponent,
    HelpRendererComponent,
    RowFormatter,
    DismissComponent,
    DistributerLinkComponent,
    DsComponent,
    UserGroupComponent,
    CostLookupComponent,
    GridvalueComponent,
    npUsefullComponent,
    GridbuttonComponent,
    GridnumericEditorComponent,
    SharedTargetCostComponent,
    DetailHelpRendererComponent,
    StatusModifierComponent,
    ShowDeviationsComponent,
    ResponsesStatusComponent,
    GenericDetailDropdownComponent,
    DropdownStatusComponent,
    SingleSelectComponent,
    SingleSelectSimComponent,
    ProductDropdownStatusRenderer,
    SingleSelectRenderer,
    InsightComponent,
    RfxListFieldComponent,
    RfxListComponent,
    RfxAlertsComponent,
    VirtualRepeatDirective,
    ViewUserGroupFeatures,
    ChildMessageRendererComponent

    // MultiSelectCellRenderer
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    CoreModule,
    UserIdleModule.forRoot(userIdleTimeConfig),
    Angulartics2Module.forRoot(),
    CommonModule,
    BaseAppModule,
    ChatBotModule,
    HelpCenterModule,
    LoginAuthentication,
    AppRoutingModule,
    RouterModule,
    ToasterModule,
    AgGridModule.withComponents([
      HelpRendererComponent,
      RatingComponent,
      RowFormatter,
      DismissComponent,
      npUsefullComponent,
      DistributerLinkComponent,
      DsComponent,
      ContextLandingPageModule,
      GridbuttonComponent,
      UserGroupComponent,
      CostLookupComponent,
      SharedTargetCostComponent,
      GridvalueComponent,
      GridnumericEditorComponent,
      DetailHelpRendererComponent,
      StatusModifierComponent,
      ShowDeviationsComponent,
      ResponsesStatusComponent,
      GenericDetailDropdownComponent,
      DropdownStatusComponent,
      SingleSelectComponent,
      SingleSelectSimComponent,
      ProductDropdownStatusRenderer,
      SingleSelectRenderer,
      InsightComponent,
      ViewUserGroupFeatures,
      ChildMessageRendererComponent
    ]),
    SharedModule,
    RfqConfigModule,
    // NgProgressModule,
    NgxLoadingModule.forRoot({}),
    VirtualScrollerModule,
    ngxZendeskWebwidgetModule.forRoot(ZendeskConfig),

    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule,
  ],
  providers: [
    WorkbenchService,
    ToasterService,
    Detail,
    Title,
    ChatService,
    WebsocketService,
    SocketService,
    ChatBotService,
    DataStorageService,
    ExportLoaderService,
    DropDownListService,
    BOTHelpService,
    AuthGuard,
    ConfigService,
    NegotiationLeversService,
    ApiServiceProvider,
    ScopeDataService,
    PartMatchingService,
    AppIconsComponent,
    NewsListService,
    FilterPipeModule,
    SubscriptionService,
    AdhocSubscriptionService,
    IndustryNewsContentService,
    MultiplePartMatchingViewService,
    FileuploadService,
    AutoSearchService,
    CanDeactivateGuard,
    TimeSelectionDataService,
    BotService,
    DataExporterService,
    CustomerConfigurationService,
    BaseService,
    FeatureService,
    ApplicationURLS,
    UtilService,
    PageLoaderService,
    OpportunitiesListService,
    ApiCommonService,
    DownloadExcelService,
    GridFormatterService,
    GenericDynamicFormService,
    WindowService,
    ClientTokenService,
    ToastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    ApiServicePathsService,
    SraEmitterService,
    GridEmitterService,
    RiskConfigurationEmitterService,
    {
      provide: FeatureFlagService, useClass: UnleashFeatureFlagService
    },
    ConfigLocator,
    FeatureFlagClientFactory,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  exports: [RouterModule],
})
export class AppModule { }
