
import { PipeTransform, Pipe } from '@angular/core'
import { filter } from 'rxjs/operators'
import * as _ from 'lodash/fp'
@Pipe({ name: 'GraphFilterBy' })
export class GraphFilterByPipe implements PipeTransform {
  public transform(items, searchTerm) {
    if (searchTerm) {
      const newSearchTerm = !isNaN(searchTerm)
        ? searchTerm.toString()
        : searchTerm.toString().toUpperCase()
      return items.filter((item) => {
        return this.search(
          item,
          JSON.parse(JSON.stringify(item)),
          newSearchTerm
        )
      })
    } else {
      return items
    }
  }
  search(item, origItem, newSearchTerm) {
    const filteredList = []
    let prop = ''
    for (const koy in origItem) {
      prop = isNaN(origItem[koy])
        ? String(origItem[koy]).toString().toUpperCase()
        : String(origItem[koy]).toString()
      if (prop.indexOf(newSearchTerm) > -1) {
        filteredList.push(item)
        return filteredList
      }
    }
  }
}
