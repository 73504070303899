import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import {
  IFilterParams,
  IAfterGuiAttachedParams,
  IDoesFilterPassParams,
  RowNode,
} from '@ag-grid-community/all-modules';
import { IFilterAngularComp } from '@ag-grid-community/angular';
import { DropDownListService } from 'app/modules/common/dropdown-list.service';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { SrfqService } from 'app/modules/srfq/shared/srfq.service';

@Component({
  moduleId: module.id,
  selector: 'numeric-filter',
  templateUrl: './numeric-filter.component.html',
  styleUrls: ['./numeric-filter.component.sass'],
})
export class NumericFilterComponent implements IFilterAngularComp {
  dropdownValues: any = [
    {
      view: 'equalsTo',
      text: 'Equals To',
      isdefault: 'true',

    },
    {
      view: 'inRange',
      text: 'In Range',
      isdefault: 'false',
    },
  ];
  public text = '';
  public fromValue = '';
  public toValue = '';
  public params: any = {};
  public dropdownValue = '';
  public applyDisable = true;
  plotArray = [];
  rowData = [];
  originalListArray = [];
  private test: Function;
  public enableClearFilter = false;
  public createGrid : string;
  private valueGetter: (rowNode: RowNode) => any;
  @ViewChild('input', { read: ViewContainerRef, static: true }) public input;
  constructor(private dropDownListService: DropDownListService, private dataStorageService: DataStorageService, private srfqService: SrfqService) {
    this.createGrid = this.dataStorageService.getcreateELS();
   }

  public agInit(params: IFilterParams) {
    if(params.hasOwnProperty('scopeSelected')){
      this.createGrid = undefined;
    }else{
      this.createGrid = this.dataStorageService.getcreateELS();
    }
    if (this.createGrid) {
      if (
        this.srfqService.partialMatchFilterColumnData instanceof Object &&
        Object.keys(this.srfqService.partialMatchFilterColumnData).length
      ) {
        if (
          Object.keys(this.srfqService.partialMatchFilterColumnData).includes(
            params.colDef.headerName
          )
        ) {
          this.enableClearFilter = true;
          this.applyDisable = false;
        }
      }
    }
    this.params = params;
    this.valueGetter = params.valueGetter;
    this.rowData = this.params.rowModel.gridApi.rowModel.gridOptionsWrapper.gridOptions.rowData;

    this.dropDownListService.getSelectedDropDownValue().subscribe((item) => {
      if (item) {
        this.dropdownValue = item.view.toLowerCase();
        if (this.dropdownValue === 'inrange') {
          if (this.toValue.length === 0 && this.fromValue.length ===0) {
            this.applyDisable = true;
          } else {
            this.applyDisable = false;
          }
        }
      }
    });
  }

  public pushFilterColumn(obj, headerName) {
    // To push Column names into array to check whether filter is applied on that column or not
    this.srfqService.partialMatchFilterColumnData[headerName] = obj;
  }
  public cancelPopup() {
    this.text = '';
    this.test();
  }

  public getModel(): any {
    return { value: this.text };
  }

  public setModel(model: any): void {
    this.text = model ? model.value : '';
  }

  public afterGuiAttached(params) {
    /* To hide toggle of filter*/
    if(this.params.hasOwnProperty('scopeSelected')){
      this.createGrid = undefined;
    }else{
      this.createGrid = this.dataStorageService.getcreateELS();
    }
    if (this.createGrid) {
      if (
        this.srfqService.partialMatchFilterColumnData instanceof Object &&
        Object.keys(this.srfqService.partialMatchFilterColumnData).length
      ) {
        if (
          Object.keys(this.srfqService.partialMatchFilterColumnData).includes(
            this.params.colDef.headerName
          )
        ) {
          this.plotFilteredValues(this.srfqService.partialMatchFilterColumnData)
          this.fromValue = this.srfqService.partialMatchFilterColumnData[this.params.colDef.headerName]['fromValue'];
          this.enableClearFilter = true;
          this.applyDisable = false;
        }
      }
    } else {
    if(this.srfqService.filtersMap[this.getRoutePath()] instanceof Object){
     if( this.srfqService.filtersMap[this.getRoutePath()].hasOwnProperty(this.params.colDef.headerName)){
      this.plotFilteredValues(this.srfqService.filtersMap[this.getRoutePath()])
      this.fromValue = this.srfqService.filtersMap[this.getRoutePath()][this.params.colDef.headerName][0]['fromValue'];
      this.enableClearFilter = true;
      this.applyDisable = false;
     }else{
       this.enableClearFilter = false;
     }
     }
    }
    this.test = params.hidePopup;
  }

  plotFilteredValues(input){
    if (input[this.params.colDef.headerName].hasOwnProperty('toValue')) {
      this.dropDownListService.setSelectedValue(this.dropdownValues[1]);
      this.toValue = input[this.params.colDef.headerName]['toValue'];
    } else {
      this.dropDownListService.setSelectedValue(this.dropdownValues[0]);
    }
  }



  public doesFilterPass(params: IDoesFilterPassParams): any {
    let isRangeFilter = '';
    if (this.plotArray instanceof Array && this.plotArray.length > 0) {
        if(this.plotArray[0].hasOwnProperty('name')){
          isRangeFilter = 'nofilter';
        }else{
          if (this.plotArray[0].hasOwnProperty('toValue')) {
            isRangeFilter = 'inrange';
          } else {
            isRangeFilter = 'equalsto';
          }
        }
       switch(isRangeFilter){
         case 'inrange':
          return (
            this.plotArray.find(filterWord => {
              return this.valueGetter(params.node) >= +filterWord.fromValue && this.valueGetter(params.node) <= +filterWord.toValue
            }) instanceof Object
          );
          break;
        case 'equalsto':
          return (
            this.plotArray.find(filterWord => {
              return this.valueGetter(params.node).toString() === filterWord.fromValue.toString();
            }) instanceof Object
          );
          break;
        case 'nofilter':
          return (
            this.plotArray.find(filterWord => {
              return this.valueGetter(params.node).toString() === filterWord.name.toString();
            }) instanceof Object
          );
          break;
        default:
          this.plotArray.find(filterWord => {
            return this.valueGetter(params.node)
          }) instanceof Object
       }
      }
  }

  public ngAfterViewInit(params: IAfterGuiAttachedParams): void {
    setTimeout(() => {
      if(this.input){
        this.input.element.nativeElement.focus();
      }
    });
  }

  public onKeyup(type, event) {
    if (event.target.value.length > 0) {
      this.applyDisable = false;
    } else {
      this.applyDisable = true;
    }
    switch (type) {
      case 'from':
        this.fromValue = event.target.value;
        break;
      case 'to':
        this.toValue = event.target.value;
        break;
      default:
        this.fromValue = '';
        this.toValue = '';
        break;
    }
  }

  public isFilterActive(): boolean {
     if(this.srfqService.filtersMap[this.getRoutePath()] instanceof Object){
     if( this.srfqService.filtersMap[this.getRoutePath()].hasOwnProperty(this.params.colDef.headerName)){
      return true;
     }else{
      return false;
     }
    }else{
      return false;
    }
  }

  public clearFilters() {
    this.plotArray = [];
    if (this.createGrid) {
      delete this.srfqService.partialMatchFilterColumnData[this.params.colDef.headerName];
      const obj = {
        selected: false,
        clearedFilter: true,
        colDef: this.params.colDef,
      }
      this.plotArray.push(obj)
      this.dataStorageService.setCustomNumericalMatchFilter(this.plotArray);
    } else {
      this.rowData.forEach((value) => {
        const obj = {
          name: value[this.params.colDef.field],
          selected: true
        }
        this.plotArray.push(obj)
      })

      delete this.srfqService.filtersMap[this.getRoutePath()][this.params.colDef.headerName]
      this.params.filterChangedCallback();
    }
    this.test();
    this.fromValue = '';
    this.toValue = '';
  }

  private getRoutePath(){
    const currentURL = window.location.href;
    const routeURLArray: Array<string> = currentURL.split('/');
    const routeURLArrayLength = routeURLArray.length;
    const path = routeURLArray[routeURLArrayLength - 1];
    return path;
  }

  public clickApplyFunction() {
    this.plotArray = [];
    const obj = {
      colDef: this.params.colDef,
      fromValue: this.fromValue,
      selected: true,
    }
    if (this.dropdownValue === 'inrange') {
     obj['toValue']= this.toValue,
      this.plotArray.push(obj);
    } else {
      this.plotArray.push(obj);
    }
    if (this.createGrid) {
      this.pushFilterColumn(obj, this.params.colDef.headerName)
      this.dataStorageService.setCustomNumericalMatchFilter(this.plotArray);
    } else {

      if(!(this.srfqService.filtersMap[this.getRoutePath()] instanceof Object)){
        this.srfqService.filtersMap[this.getRoutePath()] = {}
      }
      this.srfqService.filtersMap[this.getRoutePath()][this.params.colDef.headerName] = this.plotArray;
      this.params.filterChangedCallback();
    }
    this.test();
  }
}


