import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'field-dependency',
  templateUrl: './field-dependency.component.html',
  styleUrls: ['./field-dependency.component.sass'],
  providers: []
})

export class fieldDependency implements OnChanges {
  @Output() fieldDependencyChanged: EventEmitter<Object> = new EventEmitter<Object>();
  @Input() disabled;
  @Input() dependency: any = {
    type: '',
    field: '',
    condition: '',
    value: '',
    context: ''
  };
  @Input() dependencyTypes: Array<object> = [];
  @Input() fields: Array<object> = [];
  @Input() conditions: Array<object> = [];

  defaultType = 'Select';
  defaultField = 'Select';
  defaultCondition = 'Select';

  ngOnChanges(changes: SimpleChanges) {
    if(this.fields && this.fields.length) {
    this.defaultField = this.selectDefaultDropdownValue(this.fields, 'field');
    }

    if(this.dependencyTypes && this.dependencyTypes.length) {
    this.defaultType = this.selectDefaultDropdownValue(this.dependencyTypes, 'type');
    }

    if(this.conditions && this.conditions.length) {
    this.defaultCondition = this.selectDefaultDropdownValue(this.conditions, 'condition');
    }

  }

  selectDefaultDropdownValue(fromArray, value) {
    const selected = fromArray.find((item)=> {
      return item.refTermActualValue === this.dependency[value];
    });
    return selected || 'Select';
  }

  dependencyChange(key, value) {
    this.dependency[key] = value;
    if(key==='type' && value ==='BLANK') {
      this.dependency.field = '';
      this.dependency.condition = '';
      this.dependency.value = '';
      this.defaultField = 'Select';
      this.defaultCondition = 'Select';
    }
    this.fieldDependencyChanged.emit(true);
  }

  onBlur() {
    this.fieldDependencyChanged.emit(true);
  }
}
