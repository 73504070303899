import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

import { CustReportsGrid } from './custReports-grid.model';

@Injectable()
export class CustReportsGridService {

  constructor(private http: HttpClient) { }

  private payload: any;
  public pageConfiguration: any;
  localJson = {
    enableServerSidePagination: false,
    dropdownPageSize: 100,

    };
  public getDropDownPageSize() {
    return this.localJson.dropdownPageSize;
    }

    public setDropDownPageSize(dropdownPageSize) {
    this.localJson.dropdownPageSize = dropdownPageSize;
    }


  public setPayload(payload: any) {
    this.payload = payload;
  }
  public getPayload() {
    return this.payload;
  }
  public setPageConfiguration(pageConfiguration: any) {
    this.pageConfiguration = pageConfiguration;
  }

  public getPageConfiguration() {
    return this.pageConfiguration;
  }
  public isEnableServerSidePagination() {
    return this.localJson.enableServerSidePagination;
  }

  public setEnableServerSidePagination(serverFlag: boolean) {
    this.localJson.enableServerSidePagination = serverFlag;
  }
}
