import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Subject, Observable } from 'rxjs';
import { API_SERVICE_CONSTANTS, HTTP_REQUEST_TYPES } from 'app/modules/npi/npiconstants';
import { ApiServiceProvider } from 'app/api-service';

@Injectable()
export class NpiForwardSyncService {
  public rowData;
  constructor(private apiService: ApiServiceProvider) { }

  savePartMasterAutofill(rev, data, page) {
    const payload = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: 'POST',
      servicePath: 'autofill/savepartmasterautofill',
      formParams: {
        bomId: rev.bomId,
        revId: rev.revisionId,
        verId: rev.versionId,
        source: page === 'BOM_PRODUCTION_PARTS_AUTOFILL' ? 'CMS' : 'PART_MASTER'
      },
      payload: data
    };
    return this.apiService.post(payload);
  }
  getCompareData(rev) {
    const payload = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: 'GET',
      servicePath: `autofill/partmasterautofill`,
      formParams: {
        bomId: rev.bomId,
        revId: rev.revisionId,
        verId: rev.versionId
      }
    };

    return this.apiService.post(payload);
  }

  public getBomAutofillData(selectedBom) {
    const data = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.POST,

      servicePath: `bomrepository/autofill/data`,
      payload: {
        bomRepoId: selectedBom.bomRepoId,
        auditId: selectedBom.bomRepoAuditId,
        autoFillSource: 'CMS',
        cmOdmName: selectedBom.cmOdmName,
        fiscalQuarter: parseInt(
          selectedBom.costQuarter.slice(selectedBom.costQuarter.lastIndexOf('#') + 1)
        ),
        fiscalYear: parseInt(
          selectedBom.costQuarter.slice(0, selectedBom.costQuarter.lastIndexOf('#'))
        ),
      }
    };
    return this.apiService.post(data);

  }

  public getPartsAutofillData(selectedBom) {
    const data = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.POST,

      servicePath: `bomrepository/partsautofill/data`,
      payload: {
        bomRepoId: selectedBom.bomRepoId,
        auditId: selectedBom.bomRepoAuditId,
        cmOdmName: selectedBom.cmOdmName,
        fiscalQuarter: parseInt(
          selectedBom.costQuarter.slice(selectedBom.costQuarter.lastIndexOf('#') + 1)
        ),
        fiscalYear: parseInt(
          selectedBom.costQuarter.slice(0, selectedBom.costQuarter.lastIndexOf('#'))
        ),
      }
    };
     return this.apiService.post(data);


  }

  public saveBomRepoAutofillData(selectedBom, data) {
    const payload = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: 'POST',
      servicePath: 'bomrepository/savepartmasterautofill',
      formParams: {
        bomRepoId: selectedBom.bomRepoId,
        bomRepoAuditId: selectedBom.bomRepoAuditId,
      },
      payload: data
    };
    return this.apiService.post(payload);
  }

  getBomProductionPartsAutofillCompareData(rev, programKey) {
    const payload = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: 'POST',
      servicePath: `autofill/comparedata/${rev.bomId}/${rev.revisionId}/${rev.versionId}`,
      formParams: {
        programKey
      }
    };

    return this.apiService.post(payload);
  }
  discardCmodmFiscalQuarterChanges(bomRepoId, bomRepoAuditId) {
    const data = {
      targetedType: API_SERVICE_CONSTANTS.BOM_ANALYTICS_TARGETED_TYPE,
      method: HTTP_REQUEST_TYPES.GET,
      servicePath: `bomrepository/${bomRepoId}/${bomRepoAuditId}/autofill/discard`,
    };
    return this.apiService.post(data);
  }
}
