import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';
import { NpiToasterService } from 'app/modules/npi/npi-toaster.service';
import { TOASTER_MESSAGE_TYPES } from 'app/modules/npi/npiconstants';
import * as _ from 'lodash';
import { Subject, SubscriptionLike } from 'rxjs';
import { DataStorageService } from '../../common/data-storage.service';
import { DropDownListService } from '../../common/dropdown-list.service';
import { ObjectType } from '../../common/interface/objectype';
import { RequestData } from '../../common/models/request.model';
import { SubscriptionService } from '../../common/subscription.service';
import { ObjectUtils } from '../../common/utills/ObjectUtils.service';
import { UtilService } from '../../common/utills/util.service';
import { AutoSearchService } from './shared/autosearch.service';

const dropdownHeight = 180;
const rowHeight = 28;
@Component({
  selector: 'auto-search',
  templateUrl: 'autosearch.component.html',
  styleUrls: ['./autosearch.component.sass'],
  providers: [
    AutoSearchService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutoSearchComponent),
      multi: true,
    },
  ],
})
export class AutoSearchComponent
  implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  @Input() autoSearchWidth:string = "100%"
  @Input() set searchCustomization(val) {
    this._searchCustomization = val;
  }
  get searchCustomization() {
    return this._searchCustomization;
  }
  public _defaultSelect: any;
  @Input() set defaultSelected(val) {
    this._defaultSelect = val;
  }
  get defaultSelected() {
    return this._defaultSelect;
  }
  @Input() set reset(val) {
    this._reset = Math.random();
    const event = {
      target:{
        value: ''
      }
    };
    this.clearSearchText(event)
    this.unCheckAll();
  }
  get reset() {
    return this._reset;
  }
  public subscriptions: object = {};
  public searchedKey = false;
  public elsSubscription: SubscriptionLike;
  public customSubscription: SubscriptionLike;
  public clearFlagSubscription: SubscriptionLike;
  public event: object = {};
  public _searchCustomization: any;
  @Input() public removeOrShowItem: any;
  @Input() public showGo = true;
  @Input() public manualOptionsRequired = false;
  @Input() public requestData: RequestData;
  @Input() public monthlyView = false;
  public _reset: any;
  @Input() public showOnlyArrow = false;
  public customDimensionData: any[] = new Array();
  @Input() public showDropdown;
  @Input() public inputBorder;
  public selectedArray: any[] = new Array();
  public inputOptions: any[] = new Array();
  public childrensList: any[] = [];
  public allDimensions: any[];
  public childClick = true;
  public multiType: boolean;
  public type: any;
  public borderWidth = '2px 2px 2px 2px';
  public typeOfSelectSingle: boolean;
  public searchTerm$ = new Subject<string>();
  public search: any = '';
  public autoSearchSelectedValue: any = '';
  public singleSearchSelectedValue: any = 'Select';
  public showDiv: any = 'hide';
  public count = 0;
  public mutliSelectCrossIcon: any = 'hide';
  public multiSelectSearchIcon: any = 'show';
  public autoSearchCrossIcon: any = 'hide';
  public autoSearchSearchIcon: any = 'show';
  public manualOptionsView: any = 'show';
  public searchResult: any[] = [];
  public searchInputLimit = 1;
  public showOrHideDropdown = 'hide';
  public showSelectedValue = false;
  public searchedItem: any[] = [];
  public selectedFormDrpDwn = false;
  public showDropUp = false;
  @Input() id;

  @Output() inputChangeOptions = new EventEmitter<any>();
  private autosearchSubscription: SubscriptionLike;
  constructor(
    private searchService: AutoSearchService,
    private utilService: UtilService,
    private subscriptionService: SubscriptionService,
    private dataStorageService: DataStorageService,
    private ref: ChangeDetectorRef,
    private dropDownlistService: DropDownListService,
    private toasterService: ToasterService
  ) { }

  public ngOnInit() {
    NpiToasterService.setToasterService(this.toasterService)
    if (this.showDropdown) {
      this.showOrHideDropdown = 'show';
    }
    this.autosearchSubscription = this.subscriptionService
      .getPMRequestData()
      .subscribe((value) => {
        if (value === '') {
        } else {
          if (value instanceof Object && value.id) {
            if (value.id === this.id) {
              this.requestData = value.value;
            }
          } else {
            this.requestData = value;
          }
        }
        this.onLoad();
      });
  }
  public ngOnChanges() {
    if (this.requestData && this.searchCustomization) {
      // if (this.requestData) {
      this.onLoad();
    }
    if (this.manualOptionsRequired) {
      this.onLoad();
    }
    if (this.removeOrShowItem && this.customDimensionData.length > 0) {
      if (
        this.removeOrShowItem.dimensionName ===
        this.customDimensionData[0].dimension
      ) {
        const optionsObject = {
          value: this.removeOrShowItem.displayName,
          check: this.removeOrShowItem.selected,
          dimension: this.removeOrShowItem.dimensionName,
        };
        if (
          this.customDimensionData.filter(
            (i) => i.value === optionsObject.value
          ).length === 0
        ) {
          this.customDimensionData.unshift({
            value: this.removeOrShowItem.displayName,
            check: this.removeOrShowItem.selected,
            dimension: this.removeOrShowItem.dimensionName,
          });
        }
        const event = { target: { checked: this.removeOrShowItem.selected } };
        if (!optionsObject.check) {
          this.onCheckBoxSelect(event, optionsObject);
          const index = this.customDimensionData.findIndex(
            (row) => row.value === optionsObject.value
          );
          if (index < 0) {
            this.customDimensionData.push(optionsObject);
          } else {
            this.customDimensionData[index].check = false;
          }
        } else {
          this.searchedItem.length = 0;
          this.selectedArray.length = 0;
          this.searchResult.length = 0;
          this.onCheckBoxSelect(event, optionsObject);
          this.customDimensionData.forEach((element) => {
            if (element.value === this.removeOrShowItem.displayName) {
              element.check = true;
            } else {
              element.check = false;
            }
          });
        }
      }
    }
  }
  /*  ControlValueAccessorFunctionImplementation */
  writeValue(value: any) { }
  registerOnChange(fn) { }

  registerOnTouched() { }
  public clearSubscriptions(eachKey?: string) {
    if (typeof eachKey === 'string') {
      if (this.subscriptions[eachKey] instanceof Object) {
        if (this.subscriptions[eachKey].unsubscribe instanceof Function) {
          this.subscriptions[eachKey].unsubscribe();
        }
      }
      return;
    }
    // tslint:disable-next-line: no-shadowed-variable
    for (const eachKey in this.subscriptions) {
      if (this.subscriptions[eachKey] instanceof Object) {
        if (this.subscriptions[eachKey].unsubscribe instanceof Function) {
          this.subscriptions[eachKey].unsubscribe();
        }
      }
    }
  }

  public onClickOutside(event: object) {
    if (!this.showDropdown) {
      this.showOrHideDropdown = 'hide';
      this.showDiv = 'hide';
      if (this.requestData.type === 'autodimension') {
        if (!this.selectedFormDrpDwn) {
          if (
            this.autoSearchSelectedValue === this.requestData.defaultSelection
          ) {
            this.autoSearchCrossIcon = 'hide';
            this.autoSearchSearchIcon = 'true';
          }
        }
      }
    }
  }

  public onDropDownClick() {
    if (this.showOrHideDropdown === 'hide') {
      this.showOrHideDropdown = 'show';
    } else if (this.showOrHideDropdown === 'show') {
      this.showOrHideDropdown = 'hide';
    }
  }

  public removeRestrictedVals(customDimensionData) {
    if (
      this.requestData instanceof Object &&
      this.requestData.restrictedVals instanceof Array
    ) {
      _.remove(customDimensionData, (v) => {
        if (this.requestData.restrictedVals.includes(v.value)) {
          return v;
        }
      });
    }
    return customDimensionData;
  }
  public onLoad() {
    try {
      if (this.customSubscription) {
        this.customSubscription.unsubscribe();
      }
      if (this.elsSubscription) {
        this.elsSubscription.unsubscribe();
      }
      this.search = '';
      if (!this.monthlyView) {
        this.searchResult.length = 0;
        this.selectedArray.length = 0;
      }
      this.singleSearchSelectedValue = 'Select';
      this.customDimensionData = [];
      this.searchInputLimit = this.requestData.searchInputLimit;
      this.type = this.requestData.type;
      this.typeOfSelectSingle = this.requestData.typeOfSelectSingle;
      this.showSelectedValue = this.requestData.showSelectedValue;

      if (this.type === 'multi') {
        this.inputOptions = [];
        this.customDimensionData = [];
        if (this.requestData.views) {
          this.customDimensionData = this.requestData.views;
          this.customDimensionData = this.removeRestrictedVals(
            this.customDimensionData
          );
        } else {
          if (
            this.requestData.options.length === 0 &&
            this.searchCustomization
          ) {
            this.count = this.requestData.options.length;
            if (this.requestData.defaultSelection !== undefined) {
              this.searchResult.push(this.requestData.defaultSelection.value);
            }
          }
          if (this.requestData.options.length > 0) {
            if (!this.searchCustomization) {
              for (const option of this.requestData.options) {
                const optionsObject = {
                  value: '',
                  check: true,
                  dimension: this.requestData.target,
                  // dimension: this.requestData.payload.dimension
                };
                optionsObject.value = option;
                if (this.requestData.selectAll) {
                  this.inputOptions.push(optionsObject);
                  optionsObject.check = true;
                  this.searchResult.push(optionsObject.value);
                } else {
                  optionsObject.check = false;
                  if (this._defaultSelect) {
                    if (this._defaultSelect.includes(option)) {
                      optionsObject.check = true;
                      this.searchResult.push(optionsObject.value);
                    }
                  }
                }
                optionsObject.value = option;
                this.customDimensionData.push(optionsObject);
              }
            } else {
              this.customDimensionData = _.cloneDeep(this.requestData.options);
            }
            if (this.requestData.defaultSelection !== undefined) {
              if (this._defaultSelect instanceof Array) {
                if (this._defaultSelect.length === 0) {
                  this.searchResult.push(
                    this.requestData.defaultSelection.value
                  );
                }
              } else {
                this.searchResult.push(this.requestData.defaultSelection.value);
              }
            }
            this.dataStorageService.setrmDimensiondata(
              this.customDimensionData
            );

            this.count = this.customDimensionData.length;
          } else {
            if (this.searchCustomization) {
              return;
            }
            // if data need to be fetched from elastic search or custom service below code will execute
            if (this.requestData.targetedType === 'ELS') {
              this.clearSubscriptions('searchMultiEntriesELS');
              this.subscriptions['searchMultiEntriesELS'] = this.searchService
                .searchMultiEntriesELS('', this.requestData)
                .subscribe((results) => {
                  if (!results.result) {
                    return false;
                  }
                  this.customDimensionData =
                    this.searchService.onConvertArrayObjectKeysWithDimension(
                      results.result[0].hits,
                      this.requestData.payload.dimension
                    );
                  this.customDimensionData = this.removeRestrictedVals(
                    this.customDimensionData
                  );
                  this.count = this.customDimensionData.length;
                  if (this.requestData.defaultSelection !== undefined) {
                    this.searchResult.push(this.requestData.defaultSelection);
                  }
                  if (
                    this.removeOrShowItem && this.removeOrShowItem.dimensionName ===
                    this.customDimensionData[0].dimension
                  ) {
                    const optionsObject = {
                      value: this.removeOrShowItem.displayName,
                      check: this.removeOrShowItem.selected,
                      dimension: this.removeOrShowItem.dimensionName,
                    };
                    if (
                      this.customDimensionData.filter(
                        (i) => i.value === optionsObject.value
                      ).length === 0
                    ) {
                      this.customDimensionData.unshift({
                        value: this.removeOrShowItem.displayName,
                        check: this.removeOrShowItem.selected,
                        dimension: this.removeOrShowItem.dimensionName,
                      });
                    }
                    const event = {
                      target: { checked: this.removeOrShowItem.selected },
                    };
                    this.searchedItem.length = 0;
                    this.selectedArray.length = 0;
                    this.searchResult.length = 0;
                    this.onCheckBoxSelect(event, optionsObject);
                    this.customDimensionData.forEach((element) => {
                      if (element.value === this.removeOrShowItem.displayName) {
                        element.check = true;
                      } else {
                        element.check = false;
                      }
                    });
                  }
                });
            } else {
              this.searchService
                .searchCustomEntries('0', this.requestData)
                .subscribe((results) => {
                  if (results.result) {
                    const dimName: any = Object.keys(results.result);
                    if (results.result[dimName] !== undefined) {
                      this.customDimensionData =
                        this.searchService.onConvertCustomMulti(
                          results.result[dimName],
                          this.requestData.payload.dimension
                        );
                      this.customDimensionData = this.removeRestrictedVals(
                        this.customDimensionData
                      );
                      if (this.requestData.selectAll) {
                        this.customDimensionData.forEach((item) => {
                          item.check = true;
                        });
                      } else {
                        this.customDimensionData.forEach((item) => {
                          item.check = false;
                        });
                      }
                      //      this.inputOptions = this.customDimensionData;
                    } else if (results.result.list !== undefined) {
                      this.customDimensionData =
                        this.searchService.onConvertCustomMulti(
                          results.result.list,
                          this.requestData.payload.dimension
                        );
                      this.customDimensionData = this.removeRestrictedVals(
                        this.customDimensionData
                      );
                      if (this.requestData.selectAll) {
                        this.customDimensionData.forEach((item) => {
                          item.source = item.value;
                          item.check = true;
                          item.value = item.source.name;
                        });
                      } else {
                        this.customDimensionData.forEach((item) => {
                          item.source = item.value;
                          item.check = false;
                          item.value = item.source.name;
                        });
                      }
                      //  this.inputOptions = this.customDimensionData;
                    } else {
                      if (
                        this.requestData.targetedType ===
                        'partsMasterService' ||
                        this.requestData.targetedType ===
                        'PartWorkbenchService' ||
                        this.requestData.targetedType ===
                        'riskManagementService' ||
                        this.requestData.targetedType ===
                        'ContentManagementService'
                      ) {
                        if (this.requestData.defaultSelection !== undefined) {
                          this.searchResult.push(
                            this.requestData.defaultSelection
                          );
                        }
                        results.results = results.result;
                        this.customDimensionData =
                          this.searchService.onConvertCustomMulti(
                            results.result,
                            this.requestData.formParams['dimensions']
                          );
                        if (
                          (this.requestData.targetedType ===
                            'riskManagementService' || this.requestData.targetedType ===
                            'PartWorkbenchService' || this.requestData.targetedType === 'ContentManagementService') &&
                          this.defaultSelected.length > 0
                        ) {
                          for (const option of this.defaultSelected) {
                            const optionsObject = {
                              value: '',
                              check: true,
                              dimension: this.requestData.target,
                            };
                            optionsObject.value = option;
                            const event = { target: { checked: true } };
                            this.onCheckBoxSelect(event, optionsObject);
                            const index = this.customDimensionData.findIndex(
                              (row) => row.value === optionsObject.value
                            );
                            if (index < 0) {
                              this.customDimensionData.push(optionsObject);
                            } else {
                              this.customDimensionData[index].check = true;
                            }
                          }
                        }
                      } else {
                        this.customDimensionData =
                          this.searchService.onConvertCustomMulti(
                            results.result.items,
                            this.requestData.payload.dimension
                          );
                      }

                      this.customDimensionData = this.removeRestrictedVals(
                        this.customDimensionData
                      );
                    }
                    this.count = this.customDimensionData.length;
                  }
                });
            }
          }
        }
      } else if (this.type === 'single') {
        // write logic
        if (this.requestData.options.length > 0) {
          // If options are provided manually .. this case will execute
          if (this.requestData.defaultValue !== undefined) {
            this.singleSearchSelectedValue = this.requestData.defaultValue;
          } else {
            const optionsObject = {
              value: '',
            };
            optionsObject.value = 'Select';
            this.singleSearchSelectedValue = optionsObject;
          }
          this.manualOptionsView = 'hide';
          for (const option of this.requestData.options) {
            // optionsObject.value =(option.title==='undefined')? option : option.title;

            this.customDimensionData.push(option);
          }

          this.count = this.customDimensionData.length;
        } else {
          // if data need to be fetched from elastic search or custom service below code will execute
          if (this.requestData.targetedType === 'ELS') {
            this.searchService
              .searchMultiEntriesELS('', this.requestData)
              .subscribe((results) => {
                if (!results.result) {
                  return false;
                }
                this.customDimensionData =
                  this.searchService.onConvertArrayObjectKeysWithDimension(
                    results.result[0].hits,
                    this.requestData.payload.dimension
                  );
                this.customDimensionData = this.removeRestrictedVals(
                  this.customDimensionData
                );
                this.count = this.customDimensionData.length;
              });
          } else {
            this.searchService
              .searchCustomEntries('0', this.requestData)
              .subscribe((results) => {
                if (results.result.rmSet !== undefined) {
                  this.inputOptions = this.customDimensionData =
                    this.searchService.onConvertCustomMulti(
                      results.result.rmSet,
                      this.requestData.payload.dimension
                    );
                  this.customDimensionData = this.removeRestrictedVals(
                    this.customDimensionData
                  );
                } else {
                  this.customDimensionData =
                    this.searchService.onConvertCustomMulti(
                      results.result.items,
                      this.requestData.payload.dimension
                    );
                  this.customDimensionData = this.removeRestrictedVals(
                    this.customDimensionData
                  );
                }

                this.count = this.customDimensionData.length;
              });
          }
        }
      } else if (this.type === 'tree') {
        // write logic
        if (this.requestData.targetedType === 'ELS') {
          this.allDimensions = this.requestData.payload.dimension;
          const result: any[] = [];
          const disArray: any = this.requestData.displayName;
          for (const i in disArray) {
            if (i !== 'insert') {
              result.push({
                items: [],
                name: this.requestData.displayName[i],
                dimensionName: this.requestData.payload.dimension[i],
              });
            }
          }
          this.customDimensionData = this.searchService.onConvertTree(result);
          this.customDimensionData = this.removeRestrictedVals(
            this.customDimensionData
          );
          this.count = this.customDimensionData.length;
          if (this.requestData.defaultSelection !== undefined && this.showGo) {
            this.searchResult.push(this.requestData.defaultSelection);
          }
        } else if (
          this.requestData.options instanceof Array &&
          this.requestData.options.length > 0
        ) {
          this.searchedKey = this.requestData.showTreeExpanded;
          this.customDimensionData = this.requestData.options;
          this.count = this.customDimensionData.length;
          if (this.requestData.defaultSelection !== undefined) {
            this.searchResult.push(this.requestData.defaultSelection.value);
          }
        } else {
          this.searchService
            .searchCustomEntries('55', this.requestData)
            .subscribe((results) => {
              this.customDimensionData = this.searchService.onConvertTree(
                results.result
              );
              this.customDimensionData = this.removeRestrictedVals(
                this.customDimensionData
              );
              this.count = this.customDimensionData.length;
              if (
                this.requestData.defaultSelection !== undefined &&
                this.showGo
              ) {
                this.searchResult.push(this.requestData.defaultSelection);
              }
            });
        }
      } else if (this.type === 'multitree') {
        // write logic
        this.searchedKey = false;
        this.searchService
          .searchCustomEntries('55', this.requestData)
          .subscribe((results) => {
            this.customDimensionData = this.searchService.onConvertMultiTree(
              results.result
            );
            this.customDimensionData = this.removeRestrictedVals(
              this.customDimensionData
            );
            this.count = this.customDimensionData.length;
            if (
              this.requestData.defaultSelection !== undefined &&
              this.showGo
            ) {
              this.searchResult.push(this.requestData.defaultSelection);
            }
          });
      } else if (this.type === 'autodimension') {
        if (this.requestData.defaultSelection) {
          this.autoSearchSelectedValue = this.requestData.defaultSelection;
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Auto Search Related Code
  public parentClick(dimension) {
    if (!this.showGo) {
      const array = this.customDimensionData.filter((i) => {
        return i.dimension === dimension;
      });
      if (array[0].items.length === 0) {
        this.childrensList = [];
        this.requestData.payload.dimension = [dimension];
        this.searchService
          .searchMultiEntriesELS('', this.requestData)
          .subscribe((results) => {
            if (results.result !== undefined) {
              const hits = results.result[0].hits.filter((i) => {
                return i.internalName === dimension;
              });
              this.childrensList = hits[0].list;
              const tempArray = [];
              for (const i of this.childrensList) {
                tempArray.push({
                  value: i,
                  check: false,
                  dimension,
                });
              }
              for (const i of this.customDimensionData) {
                if (i.dimension === dimension) {
                  i.items = tempArray;
                }
              }
              this.childClick = false;
            }
          });
      }
    } else {
      this.childClick = false;
    }
  }
  public onAutoSearchKey(event: any) {
    this.autoSearchSelectedValue = event.target.value;
    if (this.autoSearchSelectedValue === '') {
      this.showDiv = 'hide';
      this.autoSearchCrossIcon = 'hide';
      this.autoSearchSearchIcon = 'show';
      this.customDimensionData.length = 0;
      return;
    } else {
      this.showDiv = 'show';
      this.autoSearchSearchIcon = 'hide';
      this.autoSearchCrossIcon = 'show';
    }
    this.searchTerm$.next(event.target.value);
    this.elsSubscription = this.searchService
      .searchMultiELS(this.searchTerm$, this.requestData)
      .subscribe((results) => {
        if (!results.result) {
          return false;
        }
        if (this.requestData.targetedType === 'ELS') {
          this.customDimensionData =
            this.searchService.onConvertArrayObjectKeysWithDimension(
              results.result[0].hits,
              this.requestData.payload.dimension
            );
          this.customDimensionData = this.removeRestrictedVals(
            this.customDimensionData
          );
        } else if (this.requestData.targetedType === 'MANUAL') {
        } else {
          this.customDimensionData.length = 0;
        }
      });
  }

  public onAutoSearchKeyDimension(event: any) {
    this.autoSearchSelectedValue = event.target.value;
    if (this.requestData.options.length > 0) {
      const custData = [...this.customDimensionData];
      this.customDimensionData.length = 0;
      if (this.autoSearchSelectedValue === '') {
        this.showDiv = 'show';
        this.autoSearchSearchIcon = 'hide';
        this.autoSearchCrossIcon = 'show';
        this.customDimensionData.length = 0;
        // return;
      } else {
        this.showDiv = 'show';
        this.autoSearchSearchIcon = 'hide';
        this.autoSearchCrossIcon = 'show';
      }
      if (!this.searchCustomization) {
        for (const data of this.requestData.options) {
          if (
            data !== undefined &&
            data !== null &&
            data
              .toString()
              .toUpperCase()
              .includes(this.autoSearchSelectedValue.toUpperCase())
          ) {
            const optionsObject = {
              value: '',
              check: true,
              dimension: [],
            };
            if (
              this.requestData.payload instanceof Object &&
              this.requestData.payload.dimension
            ) {
              optionsObject['dimension'] = this.requestData.payload.dimension;
            }
            optionsObject.value = data;
            if (this.requestData.selectAll) {
              this.inputOptions.push(optionsObject);
              optionsObject.check = true;
            } else {
              // if (clearAll) {
              //   optionsObject.check = false;
              // } else {
              //   custData.forEach((valueData) => {
              //     if (valueData.value === optionsObject.value) {
              //       optionsObject.check = valueData.check;
              //     }
              //   });
              // }
            }
            optionsObject.value = data;
            this.customDimensionData.push(optionsObject);
          }
        }
      } else {
        if (this.autoSearchSelectedValue === '') {
          this.customDimensionData = ObjectUtils.copy(this.requestData.options);
          this.customDimensionData = this.removeRestrictedVals(
            this.customDimensionData
          );
        } else {
          const data = JSON.parse(JSON.stringify(this.requestData.options));
          const key0 = JSON.parse(
            JSON.stringify(this.searchCustomization.searchKeys[0])
          );
          let key1;
          if (this.searchCustomization.searchKeys[1]) {
            key1 = JSON.parse(
              JSON.stringify(this.searchCustomization.searchKeys[1])
            );
          }
          this.customDimensionData = data.filter((item) => {
            switch (this.searchCustomization.searchKeys.length) {
              case 1:
                if (
                  item[key0]
                    .toLowerCase()
                    .includes(this.autoSearchSelectedValue.toLowerCase())
                ) {
                  return item;
                }
                break;
              case 2:
                if (
                  (item[key1] &&
                    item[key0]
                      .toLowerCase()
                      .includes(this.autoSearchSelectedValue.toLowerCase())) ||
                  item[key1]
                    .toLowerCase()
                    .includes(this.autoSearchSelectedValue.toLowerCase())
                ) {
                  return item;
                }
                break;
            }
          });
          this.customDimensionData = this.removeRestrictedVals(
            this.customDimensionData
          );
        }
      }
      this.count = this.customDimensionData.length;
      if (this.count === 0) {
        this.customDimensionData = ['No data found'];
      }
    } else {
      if (
        typeof this.autoSearchSelectedValue === 'string' &&
        this.autoSearchSelectedValue.trim().length === 0
      ) {
        this.selectedFormDrpDwn = false;
        this.showDiv = 'show';
        this.autoSearchSearchIcon = 'hide';
        this.autoSearchCrossIcon = 'show';
        this.customDimensionData.length = 0;
        this.requestData.formParams['inputs'] = [];
        // return;
      } else {
        this.selectedFormDrpDwn = false;
        this.showDiv = 'show';
        this.autoSearchSearchIcon = 'hide';
        this.autoSearchCrossIcon = 'show';
        this.requestData.formParams['inputs'] = [
          event.target.value.trim().replace(/\s\s+/g, ' '),
        ];
      }

      this.searchTerm$.next(event.target.value);
      this.elsSubscription = this.searchService
        .searchMultiELS(this.searchTerm$, this.requestData)
        .subscribe((results) => {
          if (this.requestData.targetedType === 'ELS') {
            this.customDimensionData =
              this.searchService.onConvertArrayForAutoDimnsionSelect(
                results.result[0].hits,
                this.requestData.payload.dimension
              );
            this.customDimensionData = this.removeRestrictedVals(
              this.customDimensionData
            );
          } else if (this.requestData.targetedType === 'MANUAL') {
          } else if (
            (this.requestData.targetedType === 'partsMasterService' ||
              this.requestData.targetedType === 'PartWorkbenchService') &&
            results &&
            results.result instanceof Array
          ) {
            if (results.result.length > 0) {
              results.result['item'] = results.result;

              const item: any = Object.keys(results.result);
              this.customDimensionData =
                this.searchService.onConvertCustomMulti(
                  results.result['item'],
                  this.requestData.formParams['dimensions']
                );
              this.customDimensionData = this.removeRestrictedVals(
                this.customDimensionData
              );
              const exists =
                event.target.value &&
                results.result.find(
                  (ele) =>
                    ele.toLowerCase().replace(/ /g, '') ===
                    (event.target.value || '').toLowerCase().replace(/ /g, '')
                );
              if (
                !exists &&
                event.target.value &&
                event.target.value.replace(/ /g, '')
              ) {
                const unMatchedObject = {
                  value: '',
                  check: false,
                  dimension: this.requestData.payload.dimension[0],
                  newlyAdded: true,
                };
                unMatchedObject.value = event.target.value;
                this.customDimensionData.unshift(unMatchedObject);
              }
            } else {
              this.customDimensionData = [];
              const count = this.customDimensionData.length;
              if (this.requestData.unmatchedSearch) {
                const unMatchedObject = {
                  value: '',
                  check: false,
                  dimension: this.requestData.payload.dimension[0],
                  newlyAdded: true,
                };
                unMatchedObject.value = event.target.value;
                if (count === 0) {
                  this.customDimensionData.push(unMatchedObject);
                }
              }
            }
          } else {
            this.customDimensionData.length = 0;
          }
          let check;
          if (this.requestData.targetedType === 'partsMasterService' || this.requestData.targetedType === 'PartWorkbenchService') {
            check = this.customDimensionData.length === 0;
          } else {
            check = this.isEmptyObject(this.customDimensionData);
          }

          if (check) {
            const newObject: ObjectType = {};

            if (this.requestData.targetedType === 'partsMasterService' || this.requestData.targetedType === 'PartWorkbenchService') {
              this.customDimensionData = ['No data found'];
            } else {
              this.customDimensionData['No data found'] = {};
            }
          }
        });
    }
  }
  public isEmptyObject(obj) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return true;
  }

  public onSelectOfAutoSeachValue(val) {
    this.selectedFormDrpDwn = true;
    this.autoSearchSelectedValue = val;
    if (val instanceof Object) {
      val['id'] = this.requestData.id;
      this.autoSearchSelectedValue = this.searchCustomization
        ? val[this.searchCustomization.displayKey]
        : val.value.trim().replace(/\s\s+/g, ' ');
    }

    this.showDiv = 'hide';
    this.autoSearchCrossIcon = 'hide';
    this.autoSearchSearchIcon = 'show';
    this.selectedArray = val;
    this.subscriptionService.setAutoSearchText(val);
  }

  public onAutoDimensionSelect(key: any, keyValue: any) {
    this.autoSearchSelectedValue = keyValue;
    // setTimeout(() => {
    //   this.autoSearchSelectedValue="";
    // },500);
    this.showDiv = 'hide';
    const val = {
      dimension: '',
      value: '',
    };
    val.dimension = key;
    val.value = keyValue;
    this.subscriptionService.setAutoSearchText(val);
  }

  public clearAutoSearchText(event) {
    if (this.requestData.title === 'curator') {
      this.subscriptionService.setenablesave(false);
    }
    if(this.requestData && (this.requestData.title === 'NPI' || this.requestData.title === 'PDM') && this.requestData.defaultSelection && this.requestData.defaultSelection.length>0)
    {
      if(this.requestData.payload.dimension[0] === 'manufacturer_name'  || this.requestData.payload.dimension[0] === 'supplier_name')
      {
        return NpiToasterService.populateToaster(
          TOASTER_MESSAGE_TYPES.ERROR,
          'Required field. Please select a value.'
        )
      }
      else
      {
        const data = {
          check: false,
          dimension: this.requestData.payload.dimension,
          value: ''
        }
        this.onSelectOfAutoSeachValue(data)
      }
    }
    this.autoSearchSelectedValue = '';
    this.showDiv = 'hide';
    this.autoSearchCrossIcon = 'hide';
    this.autoSearchSearchIcon = 'show';
  }

  public onAutoSearchClickOnSearchIcon() {
    this.subscriptionService.setAutoSearchText(this.autoSearchSelectedValue);
  }

  public onAutoSearch(event) {
    const clientPos =
      event.clientY -
      (event.offsetY - 4) +
      event.currentTarget.clientHeight +
      rowHeight;
    if (window.innerHeight - clientPos > dropdownHeight) {
      this.showDropUp = false;
    } else {
      this.showDropUp = true;
    }
  }

  // autosearch related code end

  // MultiSelect code start

  public onMultiSelectKey(event: any, clearAll?: boolean) {
    this.search = event.target.value;
    this.searchTerm$.next(event.target.value);
    if (this.requestData.targetedType === 'ELS') {
      if (this.requestData.type !== 'tree') {
        this.onMultiELS();
      }
    } else {
      this.onMultiCustom(clearAll);
    }
  }

  public onMultiELS() {
    this.elsSubscription = this.searchService
      .searchMultiELS(this.searchTerm$, this.requestData)
      .subscribe((results) => {
        if (!results.result) {
          return false;
        }
        this.customDimensionData =
          this.searchService.onConvertArrayObjectKeysWithDimension(
            results['result'][0].hits,
            this.requestData.payload.dimension
          );
        this.customDimensionData = this.removeRestrictedVals(
          this.customDimensionData
        );
        if (this.search === '') {
          this.multiSelectSearchIcon = 'show';
          this.mutliSelectCrossIcon = 'hide';
          for (const selv of this.selectedArray) {
            for (const k of this.customDimensionData) {
              if (k.value === selv.value) {
                this.customDimensionData.splice(
                  this.customDimensionData.indexOf(k),
                  1
                );
              }
            }
            this.customDimensionData.unshift(selv);
          }
        } else {
          this.mutliSelectCrossIcon = 'show';
          this.multiSelectSearchIcon = 'hide';
          for (const selv of this.selectedArray) {
            for (const k of this.customDimensionData) {
              if (k.value === selv.value) {
                k.check = true;
              }
            }
          }
        }
        this.count = this.customDimensionData.length;
        if (this.requestData.unmatchedSearch) {
          const unMatchedObject = {
            value: '',
            check: false,
          };
          if (
            this.requestData instanceof Object &&
            this.requestData.payload instanceof Object &&
            this.requestData.payload.dimension instanceof Array &&
            this.requestData.payload.dimension.length > 0
          ) {
            unMatchedObject['dimension'] =
              this.requestData.payload['dimension'][0];
          }
          unMatchedObject.value = this.search;
          if (this.count === 0) {
            this.customDimensionData.push(unMatchedObject);
            this.count = 1;
          } else {
            let valueExists = false;
            for (const res of this.customDimensionData) {
              if (this.search.toUpperCase() === res.value.toUpperCase()) {
                valueExists = true;
                break;
              }
            }
            if (!valueExists) {
              if (unMatchedObject.value !== '') {
                this.customDimensionData.unshift(unMatchedObject);
                this.count = this.count + 1;
              }
            }
          }
        }
      });
  }

  public onMultiCustom(clearAll?: boolean) {
    if (this.requestData.options.length > 0) {
      const custData = [...this.customDimensionData];
      this.customDimensionData.length = 0;
      if (this.search === '') {
        this.multiSelectSearchIcon = 'show';
        this.mutliSelectCrossIcon = 'hide';
      } else {
        this.mutliSelectCrossIcon = 'show';
        this.multiSelectSearchIcon = 'hide';
      }
      if (!this.searchCustomization) {
        for (const data of this.requestData.options) {
          if (
            data !== undefined &&
            data !== null &&
            data.toString().toUpperCase().includes(this.search.toUpperCase())
          ) {
            const optionsObject = {
              value: '',
              check: true,
              dimension: [],
            };
            if (this.requestData.target && this.requestData.target.length > 0) {
              optionsObject['dimension'] = this.requestData.target;
            }
            if (this.id) {
              optionsObject.check = false;
            }
            if (
              this.requestData.payload instanceof Object &&
              this.requestData.payload.dimension
            ) {
              optionsObject['dimension'] = this.requestData.payload.dimension;
            }
            if (this.requestData.target && this.requestData.target.length > 0) {
              optionsObject['dimension'] = this.requestData.target;
            }
            optionsObject.value = data;
            if (this.requestData.selectAll) {
              this.inputOptions.push(optionsObject);
              optionsObject.check = true;
            } else {
              if (clearAll) {
                optionsObject.check = false;
                if (this._defaultSelect) {
                  this.inputOptions = [];
                  this._defaultSelect = [];
                  this.selectedArray = [];
                }
              } else {
                if (this._defaultSelect) {
                  for (const selv of this.selectedArray) {
                    if (data === selv.value) {
                      optionsObject.check = true;
                    }
                  }
                } else {
                  custData.forEach((valueData) => {
                    if (valueData.value === optionsObject.value) {
                      optionsObject.check = valueData.check;
                    }
                  });
                }
              }
            }
            optionsObject.value = data;
            this.customDimensionData.push(optionsObject);
          }
        }
      } else {
        if (this.search === '') {
          this.customDimensionData = ObjectUtils.copy(this.requestData.options);
          this.customDimensionData = this.removeRestrictedVals(
            this.customDimensionData
          );
        } else {
          const data = JSON.parse(JSON.stringify(this.requestData.options));
          const key0 = JSON.parse(
            JSON.stringify(this.searchCustomization.searchKeys[0])
          );
          let key1;
          if (this.searchCustomization.searchKeys[1]) {
            key1 = JSON.parse(
              JSON.stringify(this.searchCustomization.searchKeys[1])
            );
          }
          this.customDimensionData = data.filter((item) => {
            switch (this.searchCustomization.searchKeys.length) {
              case 1:
                if (
                  item[key0].toLowerCase().includes(this.search.toLowerCase())
                ) {
                  return item;
                }
                break;
              case 2:
                if (
                  (item[key1] &&
                    item[key0]
                      .toLowerCase()
                      .includes(this.search.toLowerCase())) ||
                  item[key1].toLowerCase().includes(this.search.toLowerCase())
                ) {
                  return item;
                }
                break;
            }
          });
          this.customDimensionData = this.removeRestrictedVals(
            this.customDimensionData
          );
        }
      }
      this.count = this.customDimensionData.length;
    } else {
      this.customSubscription = this.searchService
        .searchCustom(this.searchTerm$, this.requestData)
        .subscribe((results) => {
          if (results.result === undefined) {
            this.customDimensionData.length = 0;
          } else {
            if (
              this.requestData.targetedType === 'partsMasterService' ||
              this.requestData.targetedType === 'PartWorkbenchService' ||
              this.requestData.targetedType === 'riskManagementService' ||
              this.requestData.targetedType === 'ContentManagementService'
            ) {
              results.result['item'] = results.result;
              const item: any = Object.keys(results.result);
              this.customDimensionData =
                this.searchService.onConvertCustomMulti(
                  results.result['item'],
                  this.requestData.formParams['dimensions']
                );
              this.customDimensionData = this.removeRestrictedVals(
                this.customDimensionData
              );
            } else {
              const item: any = Object.keys(results.result);
              this.customDimensionData =
                this.searchService.onConvertCustomMulti(
                  results.result[item],
                  this.requestData.payload.dimension
                );
              this.customDimensionData = this.removeRestrictedVals(
                this.customDimensionData
              );
            }
          }
          if (this.search === '') {
            this.multiSelectSearchIcon = 'show';
            this.mutliSelectCrossIcon = 'hide';
            for (const selv of this.selectedArray) {
              for (const k of this.customDimensionData) {
                if (k.value === selv.value) {
                  this.customDimensionData.splice(
                    this.customDimensionData.indexOf(k),
                    1
                  );
                }
              }
              this.customDimensionData.unshift(selv);
            }
          } else {
            this.mutliSelectCrossIcon = 'show';
            this.multiSelectSearchIcon = 'hide';
            for (const selv of this.selectedArray) {
              for (const k of this.customDimensionData) {
                if (k.value === selv.value) {
                  k.check = true;
                }
              }
            }
          }
          this.count = this.customDimensionData.length;
          if (this.requestData.unmatchedSearch &&
            this.requestData.targetedType !== 'ContentManagementService' &&
            this.requestData.targetedType !== 'PartWorkbenchService'
          ) {
            const unMatchedObject = {
              value: '',
              check: false,
            };
            unMatchedObject.value = this.search;

            if (this.count === 0) {
              this.customDimensionData.push(unMatchedObject);
              this.count = 1;
            } else {
              let valueExists = false;
              for (const res of this.customDimensionData) {
                if (this.search.toUpperCase() === res.value.toUpperCase()) {
                  valueExists = true;
                  break;
                }
              }
              if (!valueExists) {
                if (unMatchedObject.value !== '') {
                  this.customDimensionData.unshift(unMatchedObject);
                  this.count = this.count + 1;
                }
              }
            }
          }
        });
    }
  }
  public onCheckBoxSelect(event, valueObj) {
    if (event.target.checked) {
      if (this.searchedItem && this.searchedItem[0]) {
        if (this.searchedItem[0].includes('Select')) {
          this.searchedItem = [];
        }
      }
      const index = this.searchedItem.findIndex(
        (row) => row === valueObj.value
      );
      if (index < 0) {
        this.searchedItem.push(valueObj.value);
      }
      this.searchedItem = this.searchedItem.slice();
      if (this.searchedItem.length === 1) {
        this.searchResult = this.searchedItem;
      } else if (this.searchedItem.length === 0) {
        this.searchResult = ['Select'];
      } else {
        this.searchResult = [
          'selected ' + '(' + this.searchedItem.length + ')',
        ];
      }
      this.ref.markForCheck();
      valueObj.check = true;
      this.selectedArray.unshift(valueObj);
      this.inputOptions.push(valueObj);

      this.subscriptionService.setAutoSearchSelected(this.inputOptions);
      this.inputChangeOptions.emit(this.inputOptions);
    } else {
      // this.subscriptionService.setAutoSearchSelected(this.selectedArray);
      const tobeRemove = this.inputOptions.filter((item) => {
        if (item.value === valueObj.value) {
          return item;
        }
      });
      const inputOptions = this.inputOptions.map((val) => {
        return val.value;
      });
      if (tobeRemove.length > 0 && inputOptions.indexOf(tobeRemove[0].value) > -1) {
        this.inputOptions.splice(inputOptions.indexOf(tobeRemove[0].value), 1);
        this.subscriptionService.setAutoSearchSelected(this.inputOptions);
        this.inputChangeOptions.emit(this.inputOptions);
      }
      // if (this.inputOptions.indexOf(tobeRemove[0]) > 0) {
      //   this.inputOptions.splice(this.inputOptions.indexOf(tobeRemove[0]), 1);
      //   this.subscriptionService.setAutoSearchSelected(this.inputOptions);
      //   this.inputChangeOptions.emit(this.inputOptions);
      // }
      const selectedArray = this.selectedArray.map((val) => {
        return val.value;
      });
      if (tobeRemove.length > 0 && selectedArray.indexOf(tobeRemove[0].value) > -1) {
        this.selectedArray.splice(
          selectedArray.indexOf(tobeRemove[0].value),
          1
        );
      }
      this.searchedItem.splice(this.searchedItem.indexOf(valueObj.value), 1);
      if (this.searchedItem.length === 1) {
        this.searchResult = this.searchedItem;
      } else if (this.searchedItem.length === 0) {
        this.searchResult = ['Select'];
      } else {
        this.searchResult = [
          'selected ' + '(' + this.searchedItem.length + ')',
        ];
      }
    }
    if (!this.showGo) {
      this.onMultiSelectSearch();
    }
  }

  public checkAll() {
    this.searchedItem.length = 0;
    this.selectedArray.length = 0;
    this.searchResult.length = 0;
    for (const data of this.customDimensionData) {
      data.check = true;
      this.selectedArray.unshift(data);
      this.inputOptions.push(data);
      if (this._defaultSelect && this._defaultSelect instanceof Array) {
        this._defaultSelect.push(data);
      }
      this.searchedItem.push(data.value);
      this.searchResult = this.searchResult.slice();
      this.ref.markForCheck();
    }
    this.searchResult = [
      'Selected All ' + '(' + this.searchedItem.length + ')',
    ];
    this.subscriptionService.setAutoSearchSelected(this.searchedItem);
    this.inputChangeOptions.emit(this.searchedItem);

    if (!this.showGo) {
      this.onMultiSelectSearch();
    }
  }

  public unCheckAll(event?: any) {
    for (const data of this.customDimensionData) {
      data.check = false;
    }
    this.searchedItem.length = 0;
    this.selectedArray.length = 0;
    this.searchResult.length = 0;
    this.inputOptions = [];
    this.subscriptionService.setAutoSearchSelected(this.selectedArray);
    this.inputChangeOptions.emit(this.selectedArray);
    if (!this.showGo) {
      this.onMultiSelectSearch();
    }
    this.clearFlagSubscription = this.subscriptionService
      .getClearflag()
      .subscribe((val) => {
        if (val) {
          // tslint:disable-next-line: deprecation
          (event.target['value'] = ''), this.clearSearchText(event);
          this.subscriptionService.setClearflag(false);
        }
      });
    if (!!event) {
      this.clearSearchText(event, true);
    }
  }

  public clearSearchText(event: any, clearAll?: boolean) {
    this.search = '';
    event.target.value = '';
    if(this.requestData){
    this.onMultiSelectKey(event, clearAll);
    }
  }

  public onSingleSelectMulti(childInput) {
    this.searchResult.length = 0;
    this.selectedArray.length = 0;
    if (!this.searchCustomization) {
      this.searchResult.push(childInput.value);
    } else {
      this.searchResult.push(childInput[this.searchCustomization.displayKey]);
    }
    this.selectedArray.unshift(childInput);
    this.showOrHideDropdown = 'hide';
    this.inputChangeOptions.emit([childInput]);
    if (!this.showGo) {
      this.onMultiSelectSearch();
    }
  }
  public onMultiSelectSearch() {
    this.dataStorageService.setautoSearchHit(true);
    if (this.showGo) {
      this.showOrHideDropdown = 'hide';
    }
    if (!this.searchCustomization) {
      this.subscriptionService.setAutoSearchText(this.selectedArray);
    } else {
      this.subscriptionService.setAutoSearchText({
        val: this.selectedArray,
        id: this.requestData.id,
      });
    }
    if (this.showSelectedValue) {
      this.selectedArray.length = 0;
      this.searchResult.length = 0;
      this.searchedItem.length = 0;
      for (const data of this.customDimensionData) {
        data.check = false;
      }
    }
  }

  // MultiSelect code end

  // Tree view code start
  public onTreeCustomKey(event: any) {
    this.search = event.target.value;
    this.searchTerm$.next(event.target.value);
    if (!this.showGo) {
      this.searchedKey = true;
      this.requestData.payload.dimension = this.allDimensions;
    }
    this.customSubscription = this.searchService
      .searchCustom(this.searchTerm$, this.requestData)
      .subscribe((results) => {
        if (results.result === undefined) {
          this.customDimensionData.length = 0;
        } else {
          if (!this.showGo) {
            if (this.search.length === 0) {
              this.searchedKey = false;
              const result: any[] = [];
              const disArray: any = this.requestData.displayName;
              for (const i in disArray) {
                if (i !== 'insert') {
                  result.push({
                    items: [],
                    name: this.requestData.displayName[i],
                    dimensionName: this.requestData.payload.dimension[i],
                  });
                }
              }
              this.customDimensionData =
                this.searchService.onConvertTree(result);
              this.customDimensionData = this.removeRestrictedVals(
                this.customDimensionData
              );
              this.count = this.customDimensionData.length;
              if (
                this.requestData.defaultSelection !== undefined &&
                this.showGo
              ) {
                this.searchResult.push(this.requestData.defaultSelection);
              }
              this.childClick = false;
            } else {
              const result: any[] = [];
              const resultArray = results.result[0].hits;
              for (const i in resultArray) {
                if (i !== 'insert') {
                  result.push({
                    items: results.result[0].hits[i].list,
                    name: results.result[0].hits[i].externalName,
                    dimensionName: results.result[0].hits[i].internalName,
                  });
                }
              }
              this.customDimensionData =
                this.searchService.onConvertTree(result);
              this.customDimensionData = this.removeRestrictedVals(
                this.customDimensionData
              );
              this.count = this.customDimensionData.length;
              if (
                this.requestData.defaultSelection !== undefined &&
                this.showGo
              ) {
                this.searchResult.push(this.requestData.defaultSelection);
              }
              this.childClick = false;
            }
          } else {
            let resultsArr: any[] = new Array();
            if (!(results.result instanceof Array)) {
              resultsArr.push(results.result);
            } else {
              resultsArr = results.result;
            }
            this.customDimensionData =
              this.searchService.onConvertTree(resultsArr);
            this.customDimensionData = this.removeRestrictedVals(
              this.customDimensionData
            );
            this.count = this.customDimensionData.length;
          }
        }
        if (this.search === '') {
          this.multiSelectSearchIcon = 'show';
          this.mutliSelectCrossIcon = 'hide';
          // Commenting for now .. Will check later and add the functionality
          // for(let selv of this.selectedArray){
          //   for(let parent of this.customDimensionData){
          //     for(let child of parent.items){
          //       if(child.value === selv.value){
          //         child.ckheck = true;
          //         this.customDimensionData.splice(this.customDimensionData.indexOf(parent),1)
          //       }
          //     }
          //     this.customDimensionData.unshift(parent)
          //   }
          // }
        } else {
          this.mutliSelectCrossIcon = 'show';
          this.multiSelectSearchIcon = 'hide';
        }
        for (const selv of this.selectedArray) {
          for (const parent of this.customDimensionData) {
            for (const child of parent.items) {
              if (child.value === selv.value) {
                child.check = true;
              }
            }
            let parentInputCheck: boolean;
            for (const child of parent.items) {
              if (child.check) {
                parentInputCheck = true;
                parent.check = parentInputCheck;
              } else {
                parentInputCheck = false;
                parent.check = parentInputCheck;
                break;
              }
            }
          }
        }
        this.count = this.customDimensionData.length;
      });
  }

  public onCheckBoxCustomParentSelect(event, parentInput) {
    if (event.target.checked) {
      for (const child of parentInput.items) {
        let isExists = false;
        for (const selectedValue of this.selectedArray) {
          if (selectedValue.value === child.value) {
            isExists = true;
          }
        }
        if (!isExists) {
          this.searchResult.push(child.value);
          this.selectedArray.unshift(child);
          child.check = true;
        }
      }
    } else {
      for (const child of parentInput.items) {
        child.check = false;
        this.selectedArray.splice(this.selectedArray.indexOf(child), 1);
        this.searchResult.splice(this.searchResult.indexOf(child.value), 1);
      }
    }
  }

  public onSingleSelectTree(childInput, parent) {
    this.searchResult.length = 0;
    this.selectedArray.length = 0;
    this.searchResult.push(childInput.value);
    childInput.parent = parent;
    this.selectedArray.unshift(childInput);
    this.showOrHideDropdown = 'hide';
    if (!this.showGo) {
      this.subscriptionService.setAutoSearchText(this.selectedArray);
    }
  }

  public onCheckBoxChildSelect(event, childInput, parentInput) {
    if (event.target.checked) {
      this.searchedItem.push(childInput.value);
      //  this.searchedItem.push(valueObj.value)
      // this.searchedItem = this.searchedItem.slice();
      if (this.searchedItem.length === 1) {
        this.searchResult = this.searchedItem;
      } else if (this.searchedItem.length === 0) {
        this.searchResult = ['Select'];
      } else {
        this.searchResult = [
          'selected ' + '(' + this.searchedItem.length + ')',
        ];
      }
      this.selectedArray.unshift(childInput);
      let parentInputCheck: boolean;
      for (const child of parentInput.items) {
        if (child.check) {
          parentInputCheck = true;
          parentInput.check = parentInputCheck;
        } else {
          parentInputCheck = false;
          parentInput.check = parentInputCheck;
          break;
        }
      }
    } else {
      // Logic is bad... but facing issues. need to check how to resolve it
      // childInput.check = true;
      const newSelectedArray: any[] = new Array();
      for (const s of this.selectedArray) {
        if (s.value === childInput.value) {
          // do nothing
        } else {
          newSelectedArray.unshift(s);
        }
      }
      this.selectedArray.length = 0;
      this.selectedArray = newSelectedArray;
      // this.selectedArray.splice(this.selectedArray.indexOf(childInput),1)
      // this.searchResult.splice(this.searchResult.indexOf(childInput.value), 1)
      this.searchedItem.splice(this.searchedItem.indexOf(childInput.value), 1);
      if (this.searchedItem.length === 1) {
        this.searchResult = this.searchedItem;
      } else if (this.searchedItem.length === 0) {
        this.searchResult = ['Select'];
      } else {
        this.searchResult = [
          'selected ' + '(' + this.searchedItem.length + ')',
        ];
      }
    }
    parentInput.check = false;

    if (!this.showGo) {
      this.onTreeSelectSearch();
    }
  }

  public checkAllTree() {
    this.selectedArray.length = 0;
    this.searchResult.length = 0;
    this.searchedItem.length = 0;
    for (const parent of this.customDimensionData) {
      parent.check = true;
      for (const child of parent.items) {
        child.check = true;
        this.selectedArray.unshift(child);
        this.searchResult.push(child.value);
      }
    }
    this.searchResult = [
      'Selected All ' + '(' + this.searchedItem.length + ')',
    ];
    this.subscriptionService.setAutoSearchSelected(this.selectedArray);
    this.inputChangeOptions.emit(this.selectedArray);
    if (!this.showGo) {
      this.onTreeSelectSearch();
    }
  }

  public unCheckAllTree(event?: any) {
    for (const parent of this.customDimensionData) {
      parent.check = false;
      for (const child of parent.items) {
        child.check = false;
      }
      this.searchedItem.length = 0;
      this.selectedArray.length = 0;
      this.searchResult.length = 0;
    }
    this.subscriptionService.setAutoSearchSelected(this.selectedArray);
    this.inputChangeOptions.emit(this.selectedArray);

    if (!this.showGo) {
      this.onTreeSelectSearch();
    }
    try {
      if (event) {
        this.clearSearchTextTree(event);
      }
    } catch (e) {
      console.log(e);
    }
  }

  public clearSearchTextTree(event: any) {
    this.search = '';
    event.target.value = '';

    this.onTreeCustomKey(event);
  }

  public onTreeSelectSearch() {
    if (this.showGo) {
      this.showOrHideDropdown = 'hide';
    }
    this.subscriptionService.setAutoSearchText(this.selectedArray);

    if (this.showSelectedValue) {
      this.selectedArray.length = 0;
      this.searchResult.length = 0;
      for (const parent of this.customDimensionData) {
        parent.check = false;
        for (const child of parent.items) {
          child.check = false;
        }
      }
    }
  }

  // Tree view code end

  // multitree view code

  public onMultiTreeCustomKey(event: any) {
    this.search = event.target.value;
    this.searchTerm$.next(event.target.value);
    this.customSubscription = this.searchService
      .searchCustom(this.searchTerm$, this.requestData)
      .subscribe((results) => {
        if (results.result === undefined) {
          this.customDimensionData.length = 0;
        } else {
          let resultsArr: any[] = new Array();
          if (!(results.result instanceof Array)) {
            resultsArr.push(results.result);
          } else {
            resultsArr = results.result;
          }
          this.customDimensionData =
            this.searchService.onConvertMultiTree(resultsArr);
          this.customDimensionData = this.removeRestrictedVals(
            this.customDimensionData
          );
          this.count = this.customDimensionData.length;
        }
        if (this.search === '') {
          this.multiSelectSearchIcon = 'show';
          this.mutliSelectCrossIcon = 'hide';
          // for(let selv of this.selectedArray){
          //   for(let k of this.customDimensionData){
          //     if(k.value === selv.value){
          //       this.customDimensionData.splice(this.customDimensionData.indexOf(k),1)
          //     }
          //   }
          //   this.customDimensionData.unshift(selv)
          // }
        } else {
          this.mutliSelectCrossIcon = 'show';
          this.multiSelectSearchIcon = 'hide';
        }
        for (const selv of this.selectedArray) {
          for (const gp of this.customDimensionData) {
            for (const p of gp.parent) {
              for (const c of p.items) {
                if (c.value === selv.value) {
                  c.check = true;
                }
              }
              let parentInputCheck: boolean;
              for (const child of p.items) {
                if (child.check) {
                  parentInputCheck = true;
                  p.check = parentInputCheck;
                } else {
                  parentInputCheck = false;
                  p.check = parentInputCheck;
                  break;
                }
              }
            }
          }
        }
        this.count = this.customDimensionData.length;
      });
  }

  public checkAllMultiTree() {
    this.selectedArray.length = 0;
    this.searchResult.length = 0;
    for (const grandParent of this.customDimensionData) {
      for (const parent of grandParent.parent) {
        parent.check = true;
        for (const child of parent.items) {
          child.check = true;
          this.selectedArray.unshift(child);
          this.searchResult.push(child.value);
        }
      }
    }
  }

  public onSingleSelectMultiTree(childInput, parent) {
    this.searchResult.length = 0;
    this.selectedArray.length = 0;
    this.searchResult.push(childInput.value);
    childInput.parent = parent;
    this.selectedArray.unshift(childInput);
    this.showOrHideDropdown = 'hide';
  }

  public unCheckAllMultiTree(event?: any) {
    for (const grandParent of this.customDimensionData) {
      for (const parent of grandParent.parent) {
        parent.check = false;
        for (const child of parent.items) {
          child.check = false;
        }
      }
    }
    this.selectedArray.length = 0;
    this.searchResult.length = 0;
    try {
      if (event) {
        this.clearSearchTextMultiTree(event);
      }
    } catch (exception) {
      console.log(exception);
    }
  }

  public clearSearchTextMultiTree(event: any) {
    this.search = '';
    event.target.value = '';
    this.onMultiTreeCustomKey(event);
  }

  public onMultiTreeSelectSearch() {
    this.showOrHideDropdown = 'hide';
    this.subscriptionService.setAutoSearchText(this.selectedArray);

    if (this.showSelectedValue) {
      this.selectedArray.length = 0;
      this.searchResult.length = 0;
      for (const grandParent of this.customDimensionData) {
        for (const parent of grandParent.parent) {
          parent.check = false;
          for (const child of parent.items) {
            child.check = false;
          }
        }
      }
    }
  }

  // multitree code end

  // Single select code start

  public onSingleSelect(val: any) {
    this.singleSearchSelectedValue = val;
    this.subscriptionService.setSingleSearchText(val);
    this.dropDownlistService.setSelectedDropDownValue(
      this.singleSearchSelectedValue
    );
  }

  onFocusAutodimensionSelect($event) {
    this.onAutoSearchKeyDimension({
      target: { value: this.autoSearchSelectedValue },
    });
    setTimeout(() => {
      this.onAutoSearchKeyDimension({
        target: { value: this.autoSearchSelectedValue },
      });
    }, 0);

    this.showDiv = 'show';
  }
  public onSingleSelectSearch() {
    this.showOrHideDropdown = 'hide';
    this.dropDownlistService.setSelectedDropDownValue(
      this.singleSearchSelectedValue
    );
  }
  onBlurAutodimensionSelect($event) {
    this.autoSearchCrossIcon = 'hide';
    this.autoSearchSearchIcon = 'show';
  }

  public ngOnDestroy() {
    this.showGo = true;
    if (this.clearFlagSubscription) {
      this.clearFlagSubscription.unsubscribe();
    }
    this.subscriptionService.setPMRequestData('');
    if (this.autosearchSubscription) {
      this.autosearchSubscription.unsubscribe();
    }
  }
}
