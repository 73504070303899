import { Injectable } from '@angular/core';
import { ApiServiceProvider } from 'app/api-service';

@Injectable()
export class ImpactedSuppliersService {

  constructor(private http: ApiServiceProvider) { }

  getSupplierList(requestPayload){
    const payload = {
      targetedType: 'riskManagementService',
      method: 'POST',
      payload: requestPayload || undefined,
      servicePath: 'events/suppliers',
    }
    return this.http.post(payload)
  }
}
