import { HttpErrorResponse, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BodyOutputType, Toast, ToasterService } from 'angular2-toaster';
import { BaseService } from 'app/modules/common/base.service';
import { DateUtils } from 'app/modules/common/utills/DateUtils.service';
import { FileDownloadService } from 'app/modules/core/file-download/shared/file-download.service';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FileData } from '../../components';
import { UploadFileDataService } from '../../services/upload-file-data/upload-file-data.service';
import { SharedService } from '../../services/shared.service';
import { CUSTOMER_SESSION_EXPIRED } from '../../services/shared-consts';
import { DialogBoxService, DialogButtonName, DialogSize, DialogType, LdDialog } from 'app/modules/leva-ui-library/components/dialog-box/dialog-box.service';
import { DataStorageService } from 'app/modules/common/data-storage.service';
@Component({
  selector: 'cprt-upload-file-data',
  templateUrl: './upload-file-data.popup.html',
  styleUrls: ['./upload-file-data.popup.sass'],
})
export class UploadFileDataComponent implements OnInit {
  deleteConfirmationPopup = false;
  @Input() loadDataFor: 'customer' | 'self';
  fileInfo: FileData;
  @Output() loadJob = new EventEmitter<void>();
  @Output() closePopup = new EventEmitter<void>();

  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;

  resetFilter = this.generateRandomKey();
  showWarningText: boolean = false;
  tenantList = [];
  tenantFilterObj = {
    label: 'Customer',
    customLabel: true,
    type: 'dynamic',
    val: 'tenant',
    id: 'tenantFilterId',
    data: [],
  };
  selectedTenant = null;
  fileLocation = null;

  selectedDeletionDate = null;
  isForceDeleteApplied = false;
  isNpa = false;
  deletionTypes = [
    {
      text: 'FORECAST HORIZON',
      view: 'FORECAST_HORIZON',
    },
    {
      text: 'HISTORY HORIZON',
      view: 'HISTORY_HORIZON',
    },
    {
      text: 'TOTAL HORIZON',
      view: 'TOTAL_HORIZON',
    }
  ];
  selectedDeletionType = this.deletionTypes[0];
  helpText: string = 'Do you want to proceed with data load?';
  disableLoad = true;

  files: FileData[] = [];

  showUploadContainerLoader = false;
  showLoadOverlay = false;
  showDiscardOverlay = false;
  addNewTaskPopup: LdDialog = {
    title: 'Add Task',
    id: 'add_task_popup',
    template: true,
    size: DialogSize.MEDIUM,
    type: DialogType.CONFIRMATION,
    beforeClose: (target): boolean => {
      if (target && target.primary && target.id === 'save') {
        return true;
      }
      if (this.dialogBoxService.selectedButton && this.dialogBoxService.selectedButton.id === 'Close' || this.dialogBoxService.selectedButton.id === 'Cancel') {
        return false;
      }
      return true
    }

  }
  constructor(
    public dataStorageService: DataStorageService,
    private dialogBoxService: DialogBoxService,
    private toasterService: ToasterService,
    private uploadFileDataService: UploadFileDataService,
    private baseService: BaseService,
    private fileDownloadService: FileDownloadService,
    private sharedService: SharedService

  ) { }

  ngOnInit() {

    if (this.loadDataFor === 'customer') {
      this.getTenants();
    }

  }

  generateRandomKey() {
    const crypto = window.crypto;
    const array = new Uint32Array(1);
    return crypto.getRandomValues(array)[0];
  }

  getTenants() {
    this.uploadFileDataService.getTenantInfo().subscribe((tenantResponse) => {
      if (
        tenantResponse &&
        tenantResponse.result instanceof Array &&
        tenantResponse.result.length > 0
      ) {
        this.tenantList = tenantResponse.result;
        this.tenantFilterObj.data =
          tenantResponse.result instanceof Array
            ? this.convertToLocalFormat(tenantResponse.result)
            : [];
        this.resetFilter = this.generateRandomKey();
      }
    });
  }

  convertToLocalFormat(data) {
    const localArray = [];
    data.forEach((val) => {
      localArray.push({
        val,
        displayName: val['tenantName'],
        customLabel: val['tenantId'],
      });
    });

    return localArray;
  }

  message(type: string, message: string) {
    const toast: Toast = {
      type,
      body: '<div vertical-align=middle>' + message + '</div>',
      bodyOutputType: BodyOutputType.TrustedHtml,
    };
    this.toasterService.pop(toast);
  }

  onFilterSelect(item) {
    if (item && item.type) {
      switch (item.actionType.toLowerCase().trim()) {
        case 'add':
          this.selectedTenant = JSON.parse(
            JSON.stringify(item.selectedItem.val)
          );
          this.getDraftedFilesOfTenant();
          break;
        case 'remove':
          this.selectedTenant = null;
          this.files = [];
          this.fileLocation = null;
          break;
      }
    }
  }

  getDraftedFilesOfTenant() {
    this.showUploadContainerLoader = true;
    this.files = [];
    this.fileLocation = null;
    let reqPayload;
    if (this.loadDataFor === 'customer') {
      reqPayload = {
        tenantId: this.selectedTenant.tenantId,
      };
    }
    this.uploadFileDataService
      .getLoadedFiles(reqPayload, this.loadDataFor === 'customer')
      .subscribe((filesResponse) => {
        if (filesResponse && filesResponse.result instanceof Object) {
          this.files = JSON.parse(
            JSON.stringify(filesResponse.result.fileList)
          );
          this.files.forEach((file) => {
            file['progress'] = 0;
            file['downloadable'] = file.inprogress ? false : true;
            file['failed'] = false;
          });
          this.fileLocation = filesResponse.result.fileLocation;
        } else {
        }
        setTimeout(() => {
          this.showUploadContainerLoader = false;
          this.toggleLoadButton();
        }, 1000);
      });
  }

  onClickFileUpload() {
    const fileUpload = this.fileUpload.nativeElement;
    const duplicateFiles = [];

    fileUpload.onchange = () => {
      const currentUploadFileNames = [];
      for (const file of fileUpload.files) {
        currentUploadFileNames.push(file.name);
      }
      const existingUploadedFileNames = this.files.map(
        (existingFile) => existingFile.name
      );

      currentUploadFileNames.forEach((fileName) => {
        if (existingUploadedFileNames.indexOf(fileName) !== -1) {
          duplicateFiles.push(fileName);
        }
      });

      if (!duplicateFiles.length) {
        for (const file of fileUpload.files) {
          this.files.push({
            data: file,
            inprogress: false,
            progress: 0,
            name: file.name,
            downloadable: false,
            failed: false,
            type: file.type,
          });
        }
        this.uploadFiles();
      } else {
        const errorDescString = `Upload Unsuccessful<br>File(s) are uploaded already with same name<br>`;
        let liString = ``;
        duplicateFiles.forEach((dFile) => {
          liString = liString + `<li>${dFile}</li>`;
        });
        const errorString = `${errorDescString}<ul>${liString}</ul>`;
        this.message('warning', errorString);
      }
    };
    fileUpload.click();
  }

  private uploadFiles() {
    this.fileUpload.nativeElement.value = '';
    this.files
      .filter((file) => !file.hasOwnProperty('run_id'))
      .forEach((file) => {
        if (this.loadDataFor === 'customer') {
          this.insertFile(file);
        } else {
          this.uploadFile(file);
        }
      });
  }

  insertFile(file) {
    const requestPayload = {
      tenantId: this.selectedTenant.tenantId,
      location: this.selectedTenant.tenantId,
      fileName: file.name,
    };
    this.uploadFileDataService
      .insertFileToS3(requestPayload)
      .subscribe((insertFileResponse) => {
        if (insertFileResponse && insertFileResponse.responseStatus) {
          if (insertFileResponse.responseStatus.code === 200) {
            file.run_id = insertFileResponse.result;
            this.uploadFile(file);
          }
        } else {
        }
      });
  }

  uploadFile(file) {
    const formData = new FormData();

    if (this.loadDataFor === 'customer') {
      formData.append('tenantId', this.selectedTenant.tenantId);
      formData.append('location', this.fileLocation);
      formData.append('runId', file.run_id);
    }

    formData.append('file', file.data);

    file.inprogress = true;

    this.uploadFileDataService
      .uploadFileToS3(formData, this.loadDataFor === 'customer')
      .pipe(
        map((event: any) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        map((res: any) => {
          try {
            const body = res.json();
            if (
              (body.responseStatus !== undefined &&
                body.responseStatus.code === 401) ||
              (body.status !== undefined && body.status === 401)
            ) {
              this.sharedService.setLocalStorageData(
                CUSTOMER_SESSION_EXPIRED,
                true
              );
              console.log("monitoring the session expired pop up issue, 12")
              this.baseService.setUnAuthrizePopup(true);
            }

            return body || {};
          } catch (e) {
            return res;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          file.inprogress = false;
          return of(error);
        })
      )
      .subscribe((event: any) => {
        if (typeof event === 'object') {
          if (event instanceof HttpResponse) {
            switch (event.body.status) {
              case 404:
              case 400:
              case 500:
                file.inprogress = false;
                file['failed'] = true;
                file.progress = 0;
                break;
              case 200:
                if (this.loadDataFor === 'customer') {
                  file['run_id'] = event.body.result;
                } else {
                  file['run_id'] = event.body.result.runId;
                  this.fileLocation = event.body.result.fileLocation;
                }
                file.inprogress = false;
                file['downloadable'] =
                  file.type === 'application/zip' ? false : true;
                file['failed'] = false;
                break;
            }
          } else {
            file.inprogress = false;
            file['failed'] = true;
            file.progress = 0;
          }
        }
        this.toggleLoadButton();
      });
  }

  onDeleteUploadedFile(file: FileData) {
    this.deleteConfirmationPopup = true;
    this.fileInfo = file;
  }

  deleteUploadedFile() {
    const requestPayload = {};
    if (this.fileInfo.hasOwnProperty('run_id')) {
      requestPayload['runId'] = this.fileInfo.run_id;
    } else {
      requestPayload['name'] = this.fileInfo.name;
      if (this.loadDataFor === 'customer') {
        requestPayload['tenantId'] = this.selectedTenant.tenantId;
      }
    }
    this.deleteConfirmationPopup = false;
    this.uploadFileDataService
      .deleteUploadedFile(requestPayload, this.loadDataFor === 'customer')
      .subscribe((deleteResponse) => {
        if (deleteResponse && deleteResponse.result) {
          // this.getDraftedFilesOfTenant();
          this.files = this.files.filter(
            (uploadedFile) => uploadedFile.run_id !== this.fileInfo.run_id
          );
          this.toggleLoadButton();
        }
      });

  }

  onDownloadClick(file: FileData) {
    if (file.hasOwnProperty('run_id')) {
      this.downloadExcel({
        targetedType: 'Collaboration',
        formParams: {
          runId: file.run_id,
        },
        servicePath: `/customerFile/download`,
      });
    }
  }

  private async downloadExcel(payload) {
    const dataObject = {
      targetedType: payload.targetedType,
      servicePath: payload.servicePath,
      formParams: payload.formParams,
    };
    const url = await this.fileDownloadService.formDownloadUrl(dataObject);
    const aTag = document.createElement('a');
    aTag.setAttribute('href', url);
    aTag.click();
    this.fileDownloadService.getDownloadClicked = false;
    return 'SUCCESS';
  }

  onRefreshProgress() {
    this.getDraftedFilesOfTenant();
  }

  toggleLoadButton() {
    this.disableLoad = this.files.filter(
      (loadedFile) => loadedFile.inprogress || loadedFile.failed
    ).length
      ? true
      : !this.files.length
        ? true
        : false;
  }

  onClickDiscardOk() {
    this.onDiscardUploads(true);
  }

  onDiscardUploads(closePopup?) {
    let requestPayload;
    if (this.loadDataFor === 'customer') {
      requestPayload = {
        tenantId: this.selectedTenant.tenantId,
      };
    }
    this.uploadFileDataService
      .discardUploadedFiles(requestPayload, this.loadDataFor === 'customer')
      .subscribe((discardResponse) => {
        if (discardResponse && discardResponse.responseStatus) {
          if (discardResponse.responseStatus.code === 200) {
            this.files = [];
            if (closePopup) {
              this.closePopup.emit();
            }
          }
        }
        this.toggleLoadButton();
      });
  }

  onLoadJob() {
   
    if (this.isForceDeleteApplied === false ||  this.selectedDeletionType.view === 'FORECAST_HORIZON') {
      this.helpText = 'Do you want to proceed with data load?';
      this.showLoadOverlay = false;
      this.executeDataloadAction();
    }
    else
    {
      this.showLoadOverlay = true;
    }
  }

  executeDataloadAction() {
    try {
      this.showLoadOverlay = false;
      const requestPayload = {
        location: this.fileLocation,
      };
      if (this.loadDataFor === 'customer') {
        requestPayload['tenantId'] = this.selectedTenant.tenantId;
      } else {
        requestPayload['isNpa'] = this.isNpa
      }
      if (this.isForceDeleteApplied) {
        requestPayload['deletionType'] = this.selectedDeletionType.text;
        requestPayload['date'] = DateUtils.format(
          this.selectedDeletionDate,
          'YYYY-MM-DD'
        );
      }
      this.showUploadContainerLoader = true;
      this.uploadFileDataService
        .triggerPreprocessing(requestPayload, this.loadDataFor === 'customer')
        .subscribe((triggerJobResult) => {
          if (triggerJobResult && triggerJobResult.responseStatus.code === 200) {
            this.loadJob.emit();
          }
        });
    }
    catch (e) {

    }
  }
  onDeleteTypeSelection(deletionType) {
    if (deletionType.view === 'FORECAST_HORIZON') {
      this.showWarningText = false;
      this.helpText = 'Do you want to proceed with data load?';
    }
    else {
      this.showWarningText = true;
      this.helpText = 'WARNING - ' + deletionType.text + ' will delete data on platform. Do you want to proceed with data load?';
    }
    this.selectedDeletionType = deletionType;
  }

  onCloseUploadWindow() {
    if (!this.files.length) {
      return this.closePopup.emit();
    }
    this.showDiscardOverlay = true;
  }

}
