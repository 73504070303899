import { SelectDropdown } from './shared/select-dropdown.model';
import { SelectDropdownService } from './shared/select-dropdown.service';
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  EventEmitter,
  Output,
  ElementRef,
  forwardRef,
  ChangeDetectionStrategy,
  /*OnChanges, SimpleChanges*/
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ObjectUtils } from 'app/modules/common/utills/ObjectUtils.service';
import * as _ from 'lodash';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';

export class StringUtils {
  private static chr4() {
    return Math.random()
      .toString(16)
      .slice(-4);
  }
  public static getRandom() {
    return (
      this.chr4() +
      this.chr4() +
      '-' +
      this.chr4() +
      '-' +
      this.chr4() +
      '-' +
      this.chr4() +
      '-' +
      this.chr4() +
      this.chr4() +
      this.chr4()
    );
  }
  public static getRandomString(m?: number) {
    const len = m || 9;
    let s = '';
    const r = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < len; i++) {
      s += r.charAt(Math.floor(Math.random() * r.length));
    }
    return s;
  }
  public static quote(regex) {
    return regex.replace(/([()[{*+.$^\\|?])/g, '\\$1');
  }
}
@Component({
  moduleId: module.id,
  selector: 'select-dropdown',
  templateUrl: 'select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectDropdownComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SelectDropdownComponent implements OnInit, OnDestroy, ControlValueAccessor {
  // setters

  @Input()
  set defaultSelected(value) {
    this._defaultSelect = value;
    this.setDefaultValue();
  }
  @Input()
  set items(value) {
    this._items = value;
    // console.log('recieved value', value);
    if (value instanceof Array) {
      if (value.length > 0) {
        value = _.differenceWith(value, this.filterItems, _.isEqual);
        if ((this.getText(this.items[0]) || '').length > 0) {
          this.empty = false;
          if (this.disableSelection === undefined) {
            this.disabled = false;
          }
          /* if (this.fromWriteValue) {
					  this.setDefaultValue();
					  this.fromWriteValue = false;
					  this._defaultSelect = null;
					} else {
					  this.setDefaultValue();
					} */

          this.data = [];
          this._resetToDefault = false;
          this._items.forEach((element, index) => {
            const el = ObjectUtils.copy(element);
            this.data.push(el);
          });
          this.filteredData = this.data;

          this.setDefaultValue();
        } else {
          if (this.searchItemText.length > 0) {
            this.filteredData = [];
          } else {
            this.empty = true;
          }
        }
      } else {
        if (this.searchItemText.length > 0) {
          this.filteredData = [];
        } else {
          this.empty = true;
        }
      }
    } else {
      if (this.searchItemText.length > 0) {
        this.filteredData = [];
      } else {
        this.empty = true;
      }
    }

  }

  @Input()
  set disableOnEmpty(value) {
    this._disableOnEmpty = value;
    if (this.empty) {
    }
  }

  @Input()
  set disabledText(value) {
    this._disabledText = value;
  }

  @Input()
  set emptyText(value) {
    this._emptyText = value;
  }

  @Input()
  set dropdownTitle(value) {
    this._dropdownTitle = value;
  }

  set empty(value) {
    this._empty = value;
    if (!value) {
      if (this.disableOnEmpty) {
        this.disabled = true;
      }
    }
  }

  set disabled(value) {
    this._disabled = value;
  }

  @Input()
  set disableSelection(val) {
    this._disableSelection = val;
    this.disabled = val;
    this.setDefaultValue();
  }


  @Input()
  set defaultItemKey(value) {
    this._defaultItemKey = value;
    this.setDefaultValue();
  }

  @Input()
  set resetToDefault(bool) {
    this._resetToDefault = bool;
    if (bool) {
      this.setDefaultValue();
    }
  }

  // getters

  get items() {
    return this._items;
  }

  get disabledText() {
    return this._disabledText;
  }

  get emptyText() {
    return this._emptyText;
  }

  get dropdownTitle() {
    return this._dropdownTitle;
  }

  get disabled() {
    return this._disabled;
  }

  get empty() {
    return this._empty;
  }

  get defaultSelected() {
    return this._defaultSelect;
  }

  get defaultItemKey() {
    return this._defaultItemKey;
  }

  get resetToDefault() {
    return this._resetToDefault;
  }

  get disableSelection() {
    return this._disableSelection;
  }

  constructor(private elRef: ElementRef) { }

  private get selectedDropdownItem() {
    return this._selectedDropdownItem;
  }

  private set selectedDropdownItem(item: any) {
    if (this.items instanceof Array) {
      if (this.items.length > 0) {
        this._selectedDropdownItem = item;
        this.propagateChange(this._selectedDropdownItem);
        this.change.emit(item);
      }
    }
    if (this.selectedDropdownItem === null) {
      // this.selectedDropdownItem = null;
    }
  }
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  private _dropdownTitle = '';
  public dropdownVisible = false;
  private defaultReadKey = 'title';
  private _items: any = [];
  private _selectedDropdownItem: any = null;
  private _disabledText = 'You are not able to select any value in this dropdown';
  private _emptyText = 'No results match your search criteria';
  private _disableOnEmpty = true;
  private changes;
  private _empty = true;
  private _disabled = true;
  private _defaultSelect: any = null;
  private _defaultItemKey = 'defaultVal';
  private _resetToDefault = false;
  private _disableSelection: boolean;
  private data: any[] = [];
  public filteredData: any[] = [];
  public virtualScroll = true;

  @Input() public dynamicResultsLoading = false;

  public searchItemText = '';
  @Output() public clickedOutside = new EventEmitter<any>();

  // private fromWriteValue = false;

  // input variables

  @Input() readKey = null;
  @Input() upDirection = false;
  @Input() disabledKey = 'disabled';
  @Input() dropdownId = StringUtils.getRandomString();

  @Input() filterDisabled = false;
  @Input() filterItems = [];

  // output variables

  @Output() change = new EventEmitter<any>();

  @Output() dropdownClicked = new EventEmitter<any>();
  private propagateChange = (_: any) => { };

  ngOnInit() {
    this.setDefaultValue();
    if (this.filterDisabled) {
      this.items = this.items.filter((item) => item.disabledSelection === false);
    }
  }

  /* ngOnChanges(changes: SimpleChanges) {
		  for (let prop in changes) {
			  let change = changes[prop];

			  let currentValue = change.currentValue;
			  let previosValue = change.previousValue;

			  console.log(currentValue, ", ", previosValue);
		  }
	  } */

  setDefaultValue() {
    if (this.defaultSelected === null) {
      if (typeof this.defaultItemKey === 'string') {
        if (this.defaultItemKey.length > 0) {
          if (this.items instanceof Array) {
            const defaultItem = this.items.filter(i => {
              return i[this.defaultItemKey] === true;
            });
            if (defaultItem instanceof Array && defaultItem.length > 0) {
              this._defaultSelect = defaultItem[0];
            }
          }
        }
      }
      if (this.defaultSelected === null) {
        if (this.items instanceof Array) {
          if (this.items.length > 0) {
            if (
              JSON.stringify(this.selectedDropdownItem) !=
              JSON.stringify(this.items[0])
            ) {
              this.setSelectedItem(this.items[0]);
            }
          }
        }
      } else {
        if (
          JSON.stringify(this.selectedDropdownItem) !=
          JSON.stringify(this.defaultSelected)
        ) {
          this.setSelectedItem(this.defaultSelected);
        }
      }
    } else {
      if (
        JSON.stringify(this.selectedDropdownItem) !=
        JSON.stringify(this.defaultSelected)
      ) {
        this.setSelectedItem(this.defaultSelected);
      }
    }
  }

  toggleDropdownVisibility(event) {
    this.dropdownClicked.emit(event)
    // this.dropdownClick(event)
  }

  getText(item: any) {
    if (item instanceof Object) {
      let readKey = this.defaultReadKey;
      if (typeof this.readKey === 'string') {
        readKey = this.readKey;
      }
      const text = ObjectUtils.deepFind(item, readKey);
      return text;
    } else {
      return item;
    }
  }

  isItemDisabled(item: any) {
    if (item instanceof Object) {
      if (typeof item[this.disabledKey] === 'boolean') {
        return item[this.disabledKey];
      }
    }
    return false;
  }

  dropDownClick(event) {
    this.dropdownClicked.emit(event)
  }

  itemClicked(event, item) {
    // event.stopImmediatePropagation();
    this.dropdownVisible = false;
    this.setSelectedItem(item);
  }

  setSelectedItem(item: any) {
    this.selectedDropdownItem = item;
  }

  getDropdownText() {
    let text = '';
    if (this.disableSelection) {
      if (this.selectedDropdownItem instanceof Object) {
        if (this.getText(this.selectedDropdownItem).length > 0) {
          text = this.getText(this.selectedDropdownItem);
        } else {
          // text = 'Selected Dropdown Item is having empty text';
          text = this.disabledText;
        }
      } else {
        text = this.disabledText;
      }
    } else {
      if (this.disabled) {
        text = this.disabledText;
      } else {
        if (this.selectedDropdownItem instanceof Object) {
          if (this.getText(this.selectedDropdownItem).length > 0) {
            text = this.getText(this.selectedDropdownItem);
          } else {
            // text = 'Selected Dropdown Item is having empty text';
            text = this.disabledText;
          }
        } else {
          if (this._defaultSelect === null) {
            text = this.emptyText;
          } else {
            text = this._defaultSelect;
          }
        }
      }
    }
    return text;
  }

  getDropdownTitle() {
    let text = '';
    if (this.disableSelection) {
      if (this.selectedDropdownItem instanceof Object) {
        if (this.getText(this.selectedDropdownItem).length > 0) {
          text = this.getText(this.selectedDropdownItem);
        } else {
          // text = 'Selected Dropdown Item is having empty text';
          text = this.disabledText;
        }
      } else {
        text = this.disabledText;
      }
    } else {
      if (this.disabled) {
        text = this.disabledText;
      } else {
        if (this.selectedDropdownItem instanceof Object) {
          if (this.getText(this.selectedDropdownItem).length > 0) {
            text = this.getText(this.selectedDropdownItem);
          } else {
            // text = 'Selected Dropdown Item is having empty text';
            text = this.disabledText;
          }
        } else {
          if (this._defaultSelect === null) {
            text = this.emptyText;
          } else {
            text = this._defaultSelect;
          }
        }
      }
    }
    return text;
  }

  /*  ControlValueAccessorFunctionImplementation */
  writeValue(value: any) {
    if (this.items instanceof Array) {
      if (this.items.length > 0) {
        this.items.forEach(item => {
          if (JSON.stringify(item) === JSON.stringify(value)) {
            return this.setSelectedItem(value);
          }
        });
      }
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  onSearchList() {
    if (this.searchItemText.length > 0) {
      this.filteredData = [];
      this.data.forEach((element, index) => {
        const text = StringUtils.quote(this.searchItemText.trim());
        const re = new RegExp(text, 'i');
        if ((this.getText(element) || '').search(re) > -1) {
          this.filteredData.push(element);
        }
      });
    } else {
      this.filteredData = this.data;
    }
  }

  clearSearchText(ev: any) {
    this.searchItemText = '';
    this.onSearchList();
  }

  registerOnTouched() { }

  ngOnDestroy() { }

  public onClickOutside($event) {
    this.searchItemText = '';
    this.clickedOutside.emit(true);
    this.dropdownVisible = false;
  }

  public getDisplay() {
    if (this.dropdownVisible) {
      return 'block';
    } else {
      'none';
    }
  }

  public onDropdownMenuClick(e) {
    e.stopImmediatePropagation();
  }
}
