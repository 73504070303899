import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DATE_PROPERTIES, API_SERVICE_CONSTANTS } from '../../npiconstants';
import { ECalendarValue, IDatePickerConfig } from 'ng2-date-picker';
import { DateUtils } from '../../../common/utills/DateUtils.service';
import { StageRiskService } from './shared/stage-risk.service';
import { NpiCommonApiService } from '../../npi-common-api.service';
import { NpiAdminRiskService } from '../../npi-admin/npi-admin-risk/shared/npi-admin-risk.service';
import * as moment from 'moment-timezone-all';
import { findIndex } from 'rxjs/operator/findIndex';

@Component({
  selector: 'app-stage-risk',
  templateUrl: './stage-risk.component.html',
  styleUrls: ['./stage-risk.component.sass'],
  providers: [StageRiskService]
})
export class StageRiskComponent implements OnInit, OnDestroy {
  data: any;
  showNotConfiguredMessage = false;
  showHeaders = false;
  configsArray = [];
  plotArray = [];
  plotData = false;
  headers: any;
  displayMessage= 'No Program Configured Yet '
  programKeyInfo: any;
  widgetInfo: any;
  minValuesArray = {};
  constructor(private npiCommonApiService: NpiCommonApiService,
    public npiAdminRiskService: NpiAdminRiskService) {
    this.programKeyInfo = this.npiCommonApiService.getSelectedDropdownObject();
    moment.tz.setDefault(DateUtils.getUserTimeZone());
  }

  ngOnInit() {
    if (!this.programKeyInfo && this.npiAdminRiskService.showDataMessage) {
      this.showNotConfiguredMessage = true;
    } else {
      this.getRiskInfo();
      // this.showNotConfiguredMessage = false
    }
  }

  validateCompleteionDate() {
    this.data.stages.forEach((element, index) => {
      if (index > 0) {
        if (moment.isMoment(element.completionDate)) {
          if ((DateUtils.getUTCTimeByMoment(element.completionDate.clone()) < DateUtils.getUTCTimeByMoment(this.data.stages[index - 1].completionDate)) && !this.data.stages[index]['isDefault']) {
            this.data.stages[index]['error'] = true;
            if (!this.npiAdminRiskService.errors['stage'].includes(index)) {
              this.npiAdminRiskService.errors['stage'].push(index);
            }
          } else {
            this.data.stages[index]['error'] = false;
            if (this.npiAdminRiskService.errors['stage'].includes(index)) {
              const presenIndex = this.npiAdminRiskService.errors['stage'].findIndex(
                item => item === index
              );
              this.npiAdminRiskService.errors['stage'].splice(presenIndex, 1)
            }
          }
        }
      }
    });
    this.npiAdminRiskService.saveButtonStatus()
  }

  public getRiskInfo() {
    if (this.npiAdminRiskService.programKeyInfo !== undefined) {
      if (this.npiCommonApiService.validateServiceCallData(this.npiAdminRiskService.riskInfo) !== 'error') {
        this.data = this.npiAdminRiskService.riskInfo['result'];
        this.plotArray = this.data.stages.sort(function (a, b) {
          return a.stageLevel - b.stageLevel
        })
        this.data.stages.forEach(eachStage => {
          if (!(eachStage.hasOwnProperty('completionDate'))) {
            eachStage['completionDate'] = ''
            eachStage['isDefault'] = false;
          } else {
            if (eachStage['completionDate'] !== 0) {
              if (moment.isMoment(eachStage['completionDate'])) {
                eachStage['completionDate']
              } else {
                eachStage['completionDate'] = DateUtils.getMomentFromEpoch(eachStage['completionDate']);
                eachStage['isDefault'] = true;
              }
            } else {
              eachStage['completionDate'] = ''
            }

          }
        })
        this.showNotConfiguredMessage = false;
        this.updateMinMaxValues()
      }
      else {
        this.showNotConfiguredMessage = true;
      }
    } else {
      this.showNotConfiguredMessage = true;
    }
  }

  updateDate(event, i) {

    if (moment.isMoment(event)) {
      const savePayload = {}
      const stageSaveArray = []
      savePayload['stageId'] = this.data.stages[i].stageId;
      savePayload['completionDate'] = DateUtils.getUTCTimeByMoment(event.clone().endOf('day'))
      this.data.stages[i].completionDate = event;
      this.data.stages[i].dateTouched = true;
      if (!this.data.stages[i]['isDefault']) {
        this.npiAdminRiskService.enableReset = true;
        this.npiAdminRiskService.stageRiskInfoUpdated = true;
        if (this.npiAdminRiskService.stageRiskInfo.length >= 1) {
          this.npiAdminRiskService.stageRiskInfo.forEach((element, index) => {
            if (element['stageId'] === this.data.stages[i].stageId) {
              this.npiAdminRiskService.stageRiskInfo[index] = savePayload
            } else {
              this.npiAdminRiskService.stageRiskInfo.push(savePayload)
            }
          })
        } else {
          this.npiAdminRiskService.stageRiskInfo.push(savePayload)
        }
        this.updateMinMaxValues();

      } else {
        this.data.stages[i]['isDefault'] = false;
      }

    }
  }

  updateMinMaxValues() {
    this.minValuesArray = {};
    this.data.stages.forEach((element, index) => {
      if (index === 0) {
        this.minValuesArray[index] = DateUtils.getCurrentDateAsMoment();
      } else {
        if (moment.isMoment(this.data.stages[index - 1].completionDate)) {
          this.minValuesArray[index] = this.data.stages[index - 1].completionDate;
        } else {
          this.minValuesArray[index] = DateUtils.getCurrentDateAsMoment();
        }
      }
    });
    this.getDateConfig()
  }

  public getDateConfig() {
    this.data.stages.forEach((element, index) => {
      const config = {
        format: DATE_PROPERTIES.FORMAT,
        returnedValueType: ECalendarValue.Moment,
        drops: 'down'
      } as IDatePickerConfig;
      config['min'] = this.minValuesArray[index]
      config['max'] = DateUtils.getMomentFromEpoch(this.data.generalInfo.expectedRollOutDate);
      this.configsArray[index] = config;
      this.validateCompleteionDate()
    });
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    if (this.npiAdminRiskService.riskInfo instanceof Object) {
      this.npiAdminRiskService.riskInfo['result'].stages.forEach(element => {
        element['isDefault'] = true
      });
    }
  }
}

