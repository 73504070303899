import { GridOptions, Module } from '@ag-grid-community/core';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  ViewEncapsulation,
} from '@angular/core';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { LdCustomTagTypes } from 'app/modules/leva-ui-library/components/custom-tag/custom-tag.service';
import { CommentsTooltipComponent } from 'app/modules/npi-bom-repository/npi-bom-repository-overview/comments-tooltip.component';
import * as _ from 'lodash';
import { DateUtils } from '../../common/utills/DateUtils.service';
import { NpiBomManagementService } from '../../npi/npi-program-profile/npi-bom-management/shared/npi-bom-management.service';
import { DATE_PROPERTIES } from '../../npi/npiconstants';
import { ExpandCollapseAllComponent } from './expand-collapse-all-filter.component';
import { BomTreeGridService } from './shared/bom-tree-grid.service';
import { CheckboxTooltipComponent } from './utils/checkbox-tooltip.component';
import {
  convertToCPN,
  getContextMenuItems,
  gridReady,
  makeSubAssemblyYes,
  onClickYesDeleteRow,
  quickFilter,
  retainSubAssembly,
  rowDragEnd,
} from './utils/tree-action';
@Component({
  moduleId: module.id,
  encapsulation: ViewEncapsulation.None,
  selector: 'bom-tree-grid',
  templateUrl: './bom-tree-grid.component.html',
  styleUrls: [
    './bom-tree-grid.component.sass',
    './bom-tree-grid.component.css',
  ],
  providers: [BomTreeGridService],
})
export class BomTreeGridComponent implements OnInit, OnDestroy {
  public modules: Module[] = AllModules;
  public overlayNoRowsTemplate =
    '<span style="padding: 10px; border: 2px solid #444; background: white;">There are no results that match your Search Criteria</span>';
  @Input() customOverlay;
  get getStyleClass(): string {
    const classList = [];
    if (this.styleClass) {
      for (const c in this.styleClass) {
        if (this.styleClass[c]) {
          classList.push(c);
        }
      }
    } else {
      classList.push('ag-theme-balham');
    }
    return classList.join(' ');
  }
  @Output() public checkboxinputChange: EventEmitter<any> =
    new EventEmitter<any>();
  @Input() public title;
  @Input() public gridOptions: GridOptions = {} as GridOptions;
  @Input() public customOptions;
  @Input()
  public id = 'agGridTableId';
  @Input() public showErrorsChanges;
  @Input() public deleteConfirmationMsg;
  @Input()
  public styleClass: object = {};
  public prevState;
  @Output() public rowGroupOpenedEvent: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() public columnChanged: EventEmitter<any> = new EventEmitter<any>();
  public treeTitle = {};

  public dragOptions = {
    MPN: 'MPN',
    PRODUCT: 'PRODUCT',
  };
  public changes = false;
  public errorsFlag = false;
  @Input() set clearFilters(val) {
    this.changes = false;
    this.errorsFlag = false;
  }
  @Input() set setFilters(val) {
    this.changes = val.changes;
    this.errorsFlag = val.errorsFlag;
  }
  public KEY_LEFT = 37;
  public KEY_UP = 38;
  public KEY_RIGHT = 39;
  public KEY_DOWN = 40;
  public applyStyling = true;
  @Input() public showLegends = false;
  @Input() public expandCollapseNodes; // list of collapsable nodes should be passed to bom-tree-grid
  @Input() public infoMessage = false;
  @Input() public bomGridType;
  @Input() public iconlegends = true;
  public attributeColors = {
    PRODUCT: 'green',
    CPN: 'blue',
    MPN: 'grey',
    SUB_ASSEMBLY: 'orange',
  };
  public attributeClasses = {
    PRODUCT: 'legends-bom-tree-grid-bg-1',
    'SUB ASSEMBLY': 'legends-bom-tree-grid-bg-4',
    CPN: 'legends-bom-tree-grid-bg-2',
    MPN: 'legends-bom-tree-grid-bg-3',
  };
  public attributeClasses2 = {
    PRODUCT: LdCustomTagTypes.TAG_CONTEXT_PRODUCT,
    SUB_ASSEMBLY: LdCustomTagTypes.TAG_CONTEXT_SUB_ASSEMBLY,
    CPN: LdCustomTagTypes.TAG_CONTEXT_CPN,
    MPN: LdCustomTagTypes.TAG_CONTEXT_MPN,
  };
  public navigateToNextCell;
  private gridApi;

  private components;

  private treeConsistent = {
    BOM_STRUCTURE: 'bomStructure',
  };
  private KEYS = {
    FIELd: 'field',
    HEADER_NAME: 'headerName',
    LEVEL: 'Level',
  };
  public sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressSideButtons: true,
          // suppressColumnExpandAll: true
        },
      },
    ],
    // defaultToolPanel: "filters"
  };
  public frameworkComponents = {
    checkboxTooltipComponent: CheckboxTooltipComponent,
    commentsTooltipComponent: CommentsTooltipComponent,
  };

  /*
   * Base default tree gridOptions
   */
  public defaultTreeGridOptions: GridOptions = {
    treeData: true,
    icons: {
      groupExpanded:
        '<em class="grid-nav-icon expanded ld-icon-arrow-down"></em> ',
      groupContracted: '<em class="grid-nav-icon ld-icon-right"></em> ',
    },
    // animateRows: true,
    defaultColDef: {
      filter: true,
      resizable: true,
      // sortable: true
    },
    groupDefaultExpanded: -1,
    // suppressRowClickSelection: true,
    onGridReady: gridReady,
    // onCellMouseOver: cellMouseOver,
    onRowDragEnd: rowDragEnd,

    autoGroupColumnDef: {
      rowDrag: (params) => {
        let colDef;
        if (this.npibomManagemetService.bomHeaders) {
          colDef = this.npibomManagemetService.bomHeaders.find((i) => i.type);
        }
        if (params && colDef && params.data && params.data.componentType) {
          if (
            colDef.type === 'VIEW_BOM' ||
            colDef.type === 'BOM_COMPARE' ||
            colDef.type === 'EDIT_PARTS_MASTER'
          ) {
            return false;
          } else {
            return (
              params.data.componentType !== 'MPN' &&
              params.data.componentType !== 'PRODUCT'
            );
          }
        }
      },
      headerName: 'BOM Structure',
      width: 250,
      menuTabs: [],
      editable: false,
      lockPosition: true,
      // pinned: 'left',
      lockVisible: true,
      lockPinned: true,
      cellStyle: (params) => {
        if (
          params &&
          params.data &&
          params.data.componentType &&
          this.applyStyling
        ) {
          if (
            this.bomGridType !== 'BOM_COMPARE' ||
            (this.bomGridType === 'BOM_COMPARE' &&
              params.data.diffType !== 'NOT_AVAILABLE')
          ) {
            return { color: this.attributeColors[params.data.componentType] };
          } else {
            if (params.colDef.headerName === 'BOM Structure') {
              return { color: '#fff', opacity: 0.05 };
            }
          }
        }
      },
      cellClass: (params) => {
        if (this.iconlegends) {
          let cellClass = '';
          if (
            params &&
            params.data &&
            params.data.componentType === 'SUB_ASSEMBLY'
          ) {
            cellClass = 'icon-before-group-subassembly';
          } else if (
            params &&
            params.data &&
            params.data.componentType === 'PRODUCT'
          ) {
            cellClass = 'icon-before-group-product';
          } else if (
            params &&
            params.data &&
            params.data.componentType === 'CPN'
          ) {
            cellClass = 'icon-before-group-cpn';
          } else if (
            params &&
            params.data &&
            params.data.componentType === 'MPN'
          ) {
            cellClass = 'icon-before-group-mpn';
          }
          return cellClass;
        }
      },
      cellRendererParams: {
        suppressCount: true,
        // innerRenderer: "fileCellRenderer"
      },
      tooltipValueGetter: (params) => {
        if (params && params.data && params.data.diffType) {
          if (params.data.diffType !== 'NOT_AVAILABLE') {
            return params;
          } else {
            return '';
          }
        } else if (params && params.data) {
          return params.data.componentType;
        }
      },
    },
    // groupSuppressAutoColumn: true,
    isRowSelectable: (rowNode) => {
      return rowNode.data
        ? rowNode['data']['nodeType'] === 'PART' ||
            rowNode['data']['nodeType'] === 'CPN' ||
            rowNode['data']['nodeType'] === 'PRODUCT' ||
            rowNode['data']['nodeType'] === 'SUB_ASSEMBLY'
        : false;
    },
    getDataPath(data) {
      return data.nodePath;
    },
    getRowNodeId(data) {
      return data.id ? data.id : data.path || data.nodePath;
    },
    onCellEditingStarted(event) {
      // console.log('cellEditingStarted');
    },
    onCellEditingStopped(event) {
      // console.log('cellEditingStopped');
    },
  };

  constructor(
    public npibomManagemetService: NpiBomManagementService,
    public dataStorageService: DataStorageService
  ) {
    // this.components = {
    //   // fileCellRenderer: getFileCellRenderer(this.rowAction)
    // };
    // this.navigateToNextCell = navigateToNextCell.bind(this);
  }
  public onQuickFilterChanged($event) {
    quickFilter($event.target.value);
  }
  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    if (changes && changes.iconlegends && changes.iconlegends.currentValue) {
      this.attributeClasses = null;
    }
  }
  public ngOnInit() {
    if (this.customOverlay) {
      this.npibomManagemetService.getCustomOverlay().subscribe((val) => {
        if (val && this.customOverlay) {
          this.overlayNoRowsTemplate = val;
        }
      });
    }
    if (this.expandCollapseNodes) {
      this.defaultTreeGridOptions.autoGroupColumnDef.menuTabs = [
        'filterMenuTab',
      ];
      this.defaultTreeGridOptions.autoGroupColumnDef['filterFramework'] =
        ExpandCollapseAllComponent;
      this.defaultTreeGridOptions.autoGroupColumnDef['filterParams'] = {
        datatype: 'STRING',
        nodes: this.expandCollapseNodes,
      };
    }
    let fatalColumns;
    if (this.dataStorageService.getSDBTemplateFatalColumns()) {
      fatalColumns = this.dataStorageService.getSDBTemplateFatalColumns();
    } else {
      fatalColumns = this.npibomManagemetService.getSDBTemplateFatalColumns();
    }
    this.defaultTreeGridOptions.getContextMenuItems = getContextMenuItems.bind(
      this.npibomManagemetService.bomHeaders,
      this,
      this.npibomManagemetService.getChekoutDetails() || {},
      fatalColumns || {},
      this.dataStorageService.getPwbQuartersandSourcingHeaders() || {}
    );
    let treeHeader;
    if (this.gridOptions.columnDefs instanceof Array) {
      treeHeader = this.gridOptions.columnDefs.find(
        (val) => val[this.KEYS.FIELd] === this.treeConsistent.BOM_STRUCTURE
      );
      this.gridOptions.columnDefs.forEach((colDef) => {
        let columnData;
        if (this.npibomManagemetService.bomHeaders instanceof Array) {
          columnData = this.npibomManagemetService.bomHeaders.find(
            (orignalHeaders) => colDef['field'] === orignalHeaders.field
          );
        }

        if (
          columnData &&
          columnData.formatting &&
          columnData.formatting.type === 'DATEPICKER'
        ) {
          if (
            typeof colDef['cellRenderer'] !== 'function' &&
            !colDef['cellRendererFramework']
          ) {
            colDef['cellRenderer'] = (params) => {
              return `<span>
         ${this.getformattedDate(params)}
          </span>`;
            };
          }
          colDef['filter'] = 'set';
          colDef['filterParams'] = {
            cellRenderer: (params) => {
              if (params) {
                return this.getformattedDate(params);
              } else {
                return '-';
              }
            },
            cellHeight: 20,
          };
        }
      });
    }

    if (treeHeader instanceof Object) {
      this.defaultTreeGridOptions.autoGroupColumnDef.type = treeHeader.type;
      this.treeTitle = _.clone(treeHeader);
    }

    _.remove(this.gridOptions.columnDefs, {
      field: this.treeConsistent.BOM_STRUCTURE,
    });
    _.remove(this.gridOptions.columnDefs, {
      field: 'level',
    });

    this.defaultTreeGridOptions.autoGroupColumnDef.headerName =
      this.treeTitle[this.KEYS.HEADER_NAME] || 'BOM Structure';
    if (
      this.bomGridType === 'partsMaster' ||
      this.bomGridType === 'EDIT_PARTS_MASTER' ||
      this.bomGridType === 'bomSubsystem'
    ) {
      this.defaultTreeGridOptions.autoGroupColumnDef.width = 200;
      this.defaultTreeGridOptions.autoGroupColumnDef.headerName = 'Structure';
      delete this.defaultTreeGridOptions.autoGroupColumnDef.rowDrag;
      if (this.bomGridType === 'bomSubsystem') {
        this.defaultTreeGridOptions.autoGroupColumnDef.headerName = 'Level';
      }
    } else if (
      this.bomGridType === 'VIEW_BOM' ||
      this.bomGridType === 'FLAT_BOM'
    ) {
      this.defaultTreeGridOptions.autoGroupColumnDef.pinned = 'left';
      delete this.defaultTreeGridOptions.autoGroupColumnDef.lockPosition;
      if (this.bomGridType === 'FLAT_BOM') {
        delete this.defaultTreeGridOptions.autoGroupColumnDef.rowDrag;
      }
    } else if (
      this.bomGridType === 'bomRepo' ||
      this.bomGridType === 'pwbGrid' ||
      this.bomGridType === 'pwbEditGrid'
    ) {
      this.defaultTreeGridOptions.autoGroupColumnDef.width = 200;
      this.defaultTreeGridOptions.autoGroupColumnDef.headerName = 'Structure';
      delete this.defaultTreeGridOptions.autoGroupColumnDef.rowDrag;
      if (this.bomGridType === 'pwbEditGrid') {
        this.defaultTreeGridOptions.autoGroupColumnDef.pinned = 'left';
        delete this.defaultTreeGridOptions.autoGroupColumnDef.lockPosition;
      } else {
        delete this.defaultTreeGridOptions.autoGroupColumnDef.pinned;
        delete this.defaultTreeGridOptions.autoGroupColumnDef.lockPinned;
      }
    } else {
      this.defaultTreeGridOptions.autoGroupColumnDef.pinned = 'left';
    }
    if (
      this.gridOptions.columnDefs &&
      this.gridOptions.columnDefs[this.KEYS.LEVEL]
    ) {
      delete this.gridOptions.columnDefs[this.KEYS.LEVEL];
    }
    if (this.gridOptions && this.gridOptions.alignedGrids) {
      const defGridOptions = Object.assign({}, this.defaultTreeGridOptions);
      delete defGridOptions.onGridReady;
      this.gridOptions = Object.assign(this.gridOptions, defGridOptions);
    } else {
      this.gridOptions = Object.assign(
        this.defaultTreeGridOptions,
        this.gridOptions
      );
    }
  }

  public ngOnDestroy() {
    // restUserAction();
  }
  public getformattedDate(params) {
    if (params.value) {
      try {
        if (!Number.isNaN(parseInt(params.value, 0))) {
          params.value = parseInt(params.value, 0);
          params.value = DateUtils.format(params.value, DATE_PROPERTIES.FORMAT);
        }
      } catch (e) {
        console.log('error while parsing the epoch ', params.value);
      }
      return params.value;
    } else {
      return params.value || '';
    }
  }
  public makeSubAssemblyYes() {
    makeSubAssemblyYes();
  }
  public RetainSubAssembly() {
    retainSubAssembly();
  }
  public convertToComponent() {
    convertToCPN();
  }
  public makeSubAssemblyNo(id) {
    document.getElementById(id).classList.remove('show');
  }
  public isForceDeleteCancelClick() {
    document.getElementById('tree_message').classList.add('hide');
  }
  public onColumnsChanged(event) {
    const currState = _.cloneDeep(this.gridOptions.columnApi.getColumnState());
    currState.forEach((col) => delete col.width);
    if (JSON.stringify(currState) === JSON.stringify(this.prevState)) {
      return;
    } else {
      this.columnChanged.emit(this.gridOptions);
    }
    this.prevState = _.cloneDeep(currState);
  }
  public onclickNoDeleteRow() {
    document.getElementById('confirmation-msg-delete').classList.remove('show');
  }
  public onclickDeleteRow() {
    onClickYesDeleteRow();
  }
  public checkboxInputChange(val) {
    this.checkboxinputChange.emit(val);
  }
  public onclickMaxSourceLimitOk() {
    document
      .getElementById('npi_sdb_checkout_maxsource_limit')
      .classList.remove('show');
  }
  previousPage() {
    const payload = {
      pagingConfiguration: this.customOptions.pageConfiguration,
    };
    payload.pagingConfiguration.pageNumber =
      payload.pagingConfiguration.pageNumber - 1;
    payload.pagingConfiguration.pullRecordCount =
      this.customOptions.pageConfiguration.pullRecordCount;
    payload.pagingConfiguration.totalRecords =
      this.customOptions.pageConfiguration.totalRecords;
    this.getData(payload);
  }

  public nextPage() {
    const payload = {
      pagingConfiguration: this.customOptions.pageConfiguration,
    };
    payload.pagingConfiguration.pageNumber =
      +payload.pagingConfiguration.pageNumber + 1;
    payload.pagingConfiguration.pullRecordCount =
      this.customOptions.pageConfiguration.pullRecordCount;
    payload.pagingConfiguration.totalRecords =
      this.customOptions.pageConfiguration.totalRecords;
    this.getData(payload);
  }

  public first() {
    const payload = {
      pagingConfiguration: this.customOptions.pageConfiguration,
    };
    payload.pagingConfiguration.pageNumber = 1;
    payload.pagingConfiguration.pullRecordCount =
      this.customOptions.pageConfiguration.pullRecordCount;
    payload.pagingConfiguration.totalRecords =
      this.customOptions.pageConfiguration.totalRecords;
    this.getData(payload);
  }
  public onRowGroupExpandedOrCollapsed(event) {
    this.rowGroupOpenedEvent.emit(event);
  }

  public last() {
    const payload = {
      pagingConfiguration: this.customOptions.pageConfiguration,
    };
    payload.pagingConfiguration.pageNumber =
      this.customOptions.pageConfiguration.pageCount;
    payload.pagingConfiguration.pullRecordCount =
      this.customOptions.pageConfiguration.pullRecordCount;
    payload.pagingConfiguration.totalRecords =
      this.customOptions.pageConfiguration.totalRecords;
    this.getData(payload);
  }

  public goToPage() {
    const payload = {
      pagingConfiguration: this.customOptions.pageConfiguration,
    };
    payload.pagingConfiguration.pageNumber =
      this.customOptions.pageConfiguration.pageNumber;
    payload.pagingConfiguration.pullRecordCount =
      this.customOptions.pageConfiguration.pullRecordCount;
    payload.pagingConfiguration.totalRecords =
      this.customOptions.pageConfiguration.totalRecords;
    this.getData(payload);
  }

  public itemsPerPage() {
    const payload = {
      pagingConfiguration: this.customOptions.pageConfiguration,
    };
    payload.pagingConfiguration.pageNumber = 1;
    payload.pagingConfiguration.pageSize =
      this.customOptions.pageConfiguration.pageSize;
    payload.pagingConfiguration.pullRecordCount =
      this.customOptions.pageConfiguration.pullRecordCount;
    payload.pagingConfiguration.totalRecords =
      this.customOptions.pageConfiguration.totalRecords;
    this.getData(payload);
  }
  public getData(payload: any) {
    if (this.gridOptions['onServerSidePaginationChanged'] instanceof Function) {
      this.gridOptions['onServerSidePaginationChanged'](
        payload.pagingConfiguration.pageNumber,
        payload.pagingConfiguration.pageSize,
        payload.pagingConfiguration.pullRecordCount,
        payload.pagingConfiguration.totalRecords
      );
    }
  }
}
