import { BaseService } from 'app/modules/common/base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

import { ApiServiceProvider } from 'app/api-service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class OpportunitiesListService {
  constructor(
    private http: HttpClient,
    private apiService: ApiServiceProvider,
    private baseService: BaseService
  ) {}

  type: string;
  filterBy: any;
  rawmaterialName: string;

  opportunitiesList;

  selectedItemId = new BehaviorSubject<any>(undefined);

  public getOpportunityListData(scope: string, orderBy?: any) {
    if (scope === 'Commodity') {
      scope = 'COMMODITY';
    }
    if (scope === 'Product') {
      scope = 'PRODUCT';
    }

    const object: any = {
      targetedType: 'LevaAlertsService',
      formParams: {
        scope,
        orderBy: 'OPP_AMOUNT'
      },
      method: 'POST',
      servicePath: '/getCommList'
    };

    if (orderBy !== undefined) {
      object['formParams']['orderBy'] = orderBy;
    }
    return this.apiService.post(object);
  }
  public getReadData(alertId: number) {
    const userSessionData = this.baseService.getUserInformation();
    const object = {
      targetedType: 'CommonService',
      method: 'GET',
      servicePath: '/markAsRead',
      formParams: {
        userEmail: userSessionData.email,
        classification: 2,
        classficationItemId: alertId,
        itemRead: 1
      }
    };
    return this.apiService.post(object);
  }
  public getRelevantData(alertId: number, isRelevant: number) {
    const userSessionData = this.baseService.getUserInformation();
    const object = {
      targetedType: 'CommonService',
      formParams: {
        userEmail: userSessionData.email,
        classification: 2,
        classficationItemId: alertId,
        isRelevant
      },
      method: 'GET',
      servicePath: '/savePertinence'
    };
    return this.apiService.post(object);
  }

  public getOpportunitiesList() {
    return this.opportunitiesList;
  }

  setCurrentOpportunityId(item: any) {
    if (item !== undefined) {
      this.type = item.timeStamp['0'].timeRange;
      this.filterBy = item.filters;
      this.rawmaterialName = item.DRIVER_ITEM_NAME;
    }
    this.selectedItemId.next(item);
  }

  getCurrentOpportunityId() {
    return this.selectedItemId.asObservable();
  }

  setCurrentOpportunityIdSubject(item: any) {
    this.selectedItemId.next(item);
  }

  getCurrentOpportunityIdSubject() {
    return this.selectedItemId.asObservable();
  }
}
