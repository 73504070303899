import { Component, OnInit } from '@angular/core';
import { IFilterAngularComp } from '@ag-grid-community/angular';
import { IDoesFilterPassParams, IFilterParams, IAfterGuiAttachedParams } from '@ag-grid-community/all-modules';
import { IDatePickerConfig, ECalendarValue } from 'ng2-date-picker';
import { DateUtils } from 'app/modules/common/utills/DateUtils.service';
import { SrfqService } from 'app/modules/srfq/shared/srfq.service';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import * as moment from 'moment-timezone-all';
import { DropDownListService } from 'app/modules/common/dropdown-list.service';
@Component({
  moduleId: module.id,
  selector: 'date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.sass']
})

export class DateFilterComponent implements IFilterAngularComp {
  fromDateConfig: IDatePickerConfig = {
    disableKeypress: true,
    showNearMonthDays: false,
    showTwentyFourHours: true,
    returnedValueType: ECalendarValue.Moment,
    drops: 'down',
    format: 'MM-DD-YYYY hh:ss A'
  };
  dropdownValues: any = [
    {
      view: 'equalsTo',
      text: 'Equals To',
      isdefault: 'true',

    },
    {
      view: 'inRange',
      text: 'In Range',
      isdefault: 'false',
    },
  ];
  public applyDisable = true;
  public fromValue: any;
  public bindingFromValue: any;
  public bindingToValue: any;;
  public toValue: any;
  public params: any = {};
  public dropdownValue = '';
  private test: Function;
  public text = '';
  public plotArray = [];
  createGrid = '';
  enableClearFilter = false;
  constructor(private dropDownListService: DropDownListService,
    private srfqService: SrfqService,
    private dataStorageService: DataStorageService) {
      this.createGrid = this.dataStorageService.getcreateELS();
      this.dropDownListService.getSelectedDropDownValue().subscribe((item) => {
        if (item) {
          this.dropdownValue = item.view.toLowerCase();
          if (this.dropdownValue === 'inrange') {
          if (this.bindingToValue && this.bindingFromValue) {
            this.applyDisable = true;
          } else {
            this.applyDisable = false;
          }
          }
        }
        });
  }
  isFilterActive(): boolean {
    throw new Error('Method not implemented.');
  }
  doesFilterPass(params: IDoesFilterPassParams): boolean {
    throw new Error('Method not implemented.');
  }
  public getModel(): any {
    return { value: this.text };
  }

  public setModel(model: any): void {
    this.text = model ? model.value : '';
  }
  onNewRowsLoaded?(): void {
    throw new Error('Method not implemented.');
  }
  getFrameworkComponentInstance?() {
    throw new Error('Method not implemented.');
  }
  getModelAsString?(model: any): string {
    throw new Error('Method not implemented.');
  }
  agInit(params: IFilterParams) {
    console.log(params)
    this.params = params;
    if (this.createGrid) {
      if (
        this.srfqService.partialMatchFilterColumnData instanceof Object &&
        Object.keys(this.srfqService.partialMatchFilterColumnData).length
      ) {
        if (
          Object.keys(this.srfqService.partialMatchFilterColumnData).includes(
            params.colDef.headerName
          )
        ) {
          this.enableClearFilter = true;
          this.applyDisable = false;
        }
      }
    }

  }
  changeDate(event, val) {
    console.log(event)
    if(moment.isMoment(event)){
      switch (val) {
        case 'fromDate':
          if(moment.isMoment(event)){
            this.fromValue= event;
            this.bindingFromValue=DateUtils.getUTCTimeByMoment(event)
          }else{
            this.fromValue = event
          }
          break;
        case 'toDate':
          if(moment.isMoment(event)){
            this.toValue= event;
            this.bindingToValue=DateUtils.getUTCTimeByMoment(event)
          }else{
            this.toValue = event
          }
          break;
      }
    }

  }
  cancelFilterBox() {
    this.text = '';
    this.test();
  }
  public pushFilterColumn(obj, headerName) {
    // To push Column names into array to check whether filter is applied on that column or not
    this.srfqService.partialMatchFilterColumnData[headerName] = obj;
  }
  public onApply() {
    this.plotArray = [];
    const obj = {
      colDef: this.params.colDef,
      fromValue: this.bindingFromValue,
      selected: true,
    }
    if (this.dropdownValue === 'inrange') {
      obj['toValue'] = this.bindingToValue,
        this.plotArray.push(obj);
    } else {
      this.plotArray.push(obj);
    }
    if (this.createGrid) {
      this.pushFilterColumn(obj, this.params.colDef.headerName)
      this.dataStorageService.setCustomNumericalMatchFilter(this.plotArray);
      // } else {
      //   if(!(this.srfqService.filtersMap[this.getRoutePath()] instanceof Object)){
      // 	this.srfqService.filtersMap[this.getRoutePath()] = {}
      //   }
      //   this.srfqService.filtersMap[this.getRoutePath()][this.params.colDef.headerName] = this.plotArray;
      //   this.params.filterChangedCallback();
    }
    this.test();
  }

  public clearFilters() {
    this.plotArray = [];
    if (this.createGrid) {
      delete this.srfqService.partialMatchFilterColumnData[this.params.colDef.headerName];
      const obj = {
        selected: false,
        clearedFilter: true,
        colDef: this.params.colDef,
      }
      this.plotArray.push(obj)
      this.dataStorageService.setCustomNumericalMatchFilter(this.plotArray);
    }
  }

  plotFilteredValues(input){
    if (input[this.params.colDef.headerName].hasOwnProperty('toValue')) {
      this.dropDownListService.setSelectedValue(this.dropdownValues[1]);
      this.toValue = DateUtils.getMomentFromEpoch(
            input[this.params.colDef.headerName]['toValue']);
    } else {
      this.dropDownListService.setSelectedValue(this.dropdownValues[0]);
    }
    }

  public afterGuiAttached(params) {
    /* To hide toggle of filter*/
    if (this.createGrid) {
      if (
      this.srfqService.partialMatchFilterColumnData instanceof Object &&
      Object.keys(this.srfqService.partialMatchFilterColumnData).length
      ) {
      if (
        Object.keys(this.srfqService.partialMatchFilterColumnData).includes(
        this.params.colDef.headerName
        )
      ) {
        this.plotFilteredValues(this.srfqService.partialMatchFilterColumnData)
        this.fromValue = DateUtils.getMomentFromEpoch(this.srfqService.partialMatchFilterColumnData[this.params.colDef.headerName]['fromValue']
          )
        this.enableClearFilter = true;
        this.applyDisable = false;
      }
      }
    }
    this.test = params.hidePopup;
    }

}
