import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-application-maintenance-banner',
  templateUrl: './application-maintenance-banner.component.html',
  styleUrls: ['./application-maintenance-banner.component.sass']
})
export class ApplicationMaintenanceBannerComponent implements OnInit {

  @Input() maintenanceMessages: any;
  @Output() minimize = new EventEmitter();
  showBanner = true;
  constructor() { }

  ngOnInit() {
  }

  onMinimize($event){
    this.showBanner = !this.showBanner;
    this.minimize.emit(false);
  }

}
