import { FileAttachModel } from 'app/modules/core/collaborations/shared/fileattach-model';
import { FromUserModel } from 'app/modules/core/collaborations/shared/fromuser-model';
import { MentionedUsersModel } from 'app/modules/core/collaborations/shared/mentionedusers-model';
import { TaskAttachModel } from 'app/modules/core/collaborations/shared/taskattach-model';
import { ToUserModel } from 'app/modules/core/collaborations/shared/touser-model';

export class ConversationModel {

    fileAttaches : FileAttachModel[];
    fromUser : FromUserModel;
    hasFiles: boolean;
    mentionedUsers: MentionedUsersModel[];
    task: boolean;
    taskAttach: TaskAttachModel;
    text: string;
    timeStamp: any;
    toUser: ToUserModel;

    public setFileAttaches(fileAttaches: FileAttachModel[]) {
        this.fileAttaches = fileAttaches;
    }
    public getFileAttaches() {
        return this.fileAttaches;
    }
    public setFromUser(fromUser: FromUserModel) {
        this.fromUser = fromUser;
    }
    public getFromUser() {
        return this.fromUser;
    }
    public setHasFiles(hasFiles: boolean) {
        this.hasFiles = hasFiles;
    }
    public getHasFiles() {
        return this.hasFiles;
    }
    public setMentionedUsers(mentionedUsers: MentionedUsersModel[]) {
        this.mentionedUsers = mentionedUsers;
    }
    public getMentionedUsers() {
        return this.mentionedUsers;
    }
    public setTask(task: boolean) {
        this.task = task;
    }
    public getTask() {
        return this.task;
    }
    public setTaskAttach(taskAttach: TaskAttachModel) {
        this.taskAttach = taskAttach;
    }
    public getTaskAttach() {
        return this.taskAttach;
    }
    public setText(text: string) {
        this.text = text;
    }
    public getText() {
        return this.text;
    }
    public setTimeStamp(timeStamp: any) {
        this.timeStamp = timeStamp;
    }
    public getTimeStamp() {
        return this.timeStamp;
    }
    public setToUser(toUser: ToUserModel) {
        this.toUser = toUser;
    }
    public getToUser() {
        return this.toUser;
    }
}
