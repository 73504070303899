import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from 'app/modules/core/core.module';
import { BaseAppComponent } from 'app/modules/base-app/base-app.component';
import { LeftNavComponent } from 'app/modules/base-app/left-nav/left-nav.component';
import { AppHeaderComponent } from 'app/modules/base-app/app-header/app-header.component';
import { BotComponent } from 'app/modules/base-app/bot/bot.component';
import { FormsModule } from '@angular/forms';
import { LoginAuthentication } from 'app/modules/authentication/login_auth.module';
import { HelpCategoryComponent } from 'app/modules/base-app/bot/help/help-category/help-category.component';
import { HelpMessageComponent } from 'app/modules/base-app/bot/help/help-message/help-message.component';
import { HelpOptionsListComponent } from 'app/modules/base-app/bot/help/help-options-list/help-options-list.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { MomentModule } from 'angular2-moment';
import { TooltipModule } from 'ngx-tooltip';
import { Routes, RouterModule } from '@angular/router';
import { BotQuestionComponent } from 'app/modules/base-app/bot/help/bot-question/bot-question.component';
import { AppBotQuesitionComponent } from 'app/modules/base-app/bot/templates/app-bot-quesition/app-bot-quesition.component';
import { AppBotViewdetailsComponent } from 'app/modules/base-app/bot/templates/app-bot-viewdetails/app-bot-viewdetails.component';
import { AppBotLinkComponent } from 'app/modules/base-app/bot/templates/app-bot-link/app-bot-link.component';
import { MsgListComponent } from './app-header/msg-list/msg-list.component';
import { AboutLevadataComponent } from './about-levadata/about-levadata.component';
import { SharedModule } from '../shared/shared.module';
import { SCROLL_BAR_CONFIGURATION } from '../common/scroll-config.service';
import { ChatBotModule } from '../chatbot/chatbot.module';
import { LevaUiLibraryModule } from '../leva-ui-library/leva-ui-library.module'
import { ContextLandingPageModule } from '../context-landing-page/context-landing-page.module';
import { ReverseArray } from '../common/Pipe/ReverseArray';

@NgModule({
  imports: [
    CoreModule,
    FormsModule,
    BrowserModule,
    MomentModule,
    RouterModule,
    LoginAuthentication,
    ClickOutsideModule,
    TooltipModule,
    SharedModule,
    ChatBotModule,
    LevaUiLibraryModule,
    ContextLandingPageModule,
  ],
  declarations: [
    BaseAppComponent,
    AboutLevadataComponent,
    LeftNavComponent,
    AppHeaderComponent,
    BotComponent,
    HelpCategoryComponent,
    HelpMessageComponent,
    HelpOptionsListComponent,
    BotQuestionComponent,
    AppBotQuesitionComponent,
    AppBotViewdetailsComponent,
    AppBotLinkComponent,
    MsgListComponent,
    ReverseArray
  ],
  providers: [],
  exports: [
    RouterModule,
    BaseAppComponent,
    HelpCategoryComponent,
    AboutLevadataComponent,
    HelpMessageComponent,
    HelpOptionsListComponent,
    BotQuestionComponent,
    AppBotQuesitionComponent,
    AppBotViewdetailsComponent,
    AppBotLinkComponent
  ]
})
export class BaseAppModule {
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
}
