import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { ObjectUtils } from '../common/utills/ObjectUtils.service';

@Pipe({ name: 'jsonFilter', pure: false })
@Injectable()
export class JSONFilterPipe implements PipeTransform {
  private static compareValuesWithOperator(val1?, val2?, operator?) {
    switch (operator) {
      case COMPARISON_OPERATORS.STRICT_EQUALS:
        return val1 === val2;
      case COMPARISON_OPERATORS.EQUALS:
        // tslint:disable-next-line:triple-equals
        return val1 === val2;
      case COMPARISON_OPERATORS.LESS_THAN:
        return val1 < val2;
      case COMPARISON_OPERATORS.GREATER_THAN:
        return val1 > val2;
      case COMPARISON_OPERATORS.LESS_THAN_OR_EQUAL:
        return val1 <= val2;
      case COMPARISON_OPERATORS.GREATER_THAN_OR_EQUAL:
        return val1 >= val2;
      case COMPARISON_OPERATORS.STRICT_NOT_EQUALS:
        return val1 !== val2;
      case COMPARISON_OPERATORS.SUBSTRACT:
        return val1 - val2;
      case COMPARISON_OPERATORS.ADD:
        return val1 + val2;
    }
  }
  public transform(json: any[], searchVal, jsonKey, operator): any[] {
    operator = operator || COMPARISON_OPERATORS.STRICT_EQUALS;

    if (
      !(typeof jsonKey === 'string') ||
      jsonKey.length === 0 ||
      !(json instanceof Array)
    ) {
      return json;
    }

    return json.filter(i => {
      return JSONFilterPipe.compareValuesWithOperator(
        ObjectUtils.deepFind(i, jsonKey),
        searchVal,
        operator
      );
    });
  }
}

const COMPARISON_OPERATORS = {
  STRICT_EQUALS: '===',
  EQUALS: '===',
  LESS_THAN: '<',
  GREATER_THAN: '>',
  LESS_THAN_OR_EQUAL: '<=',
  GREATER_THAN_OR_EQUAL: '>=',
  STRICT_NOT_EQUALS: '!==',
  NOT_EQUALS: '!=',
  SUBSTRACT: '-',
  ADD: '+'
};
