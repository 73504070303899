import { Component } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';

@Component({
  template: `
    <a
      *ngIf="showLink"
      href="javascript:void(0)"
      style="text-decoration: none;"
      (click)="onClickItem()"
      >{{ params.value }}</a
    >
    <div *ngIf="!showLink">{{ params.value }}</div>
  `,
})
export class RouterLinkRendererComponent implements AgRendererComponent {
  public params: any;
  showLink = true;
  constructor() {}

  agInit(params: any): void {
    this.params = params;

    if (
      params.disableParams !== undefined &&
      params.disableParams.key === params.colDef.field
    ) {
      this.showLink =
        params.data[params.colDef.field] === params.disableParams.value
          ? false
          : true;
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  onClickItem() {
    this.params.context.componentParent.navigateToAnotherRoute(
      this.params.data,
      this.params.colDef.field
    );
  }
}
