import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnChanges
} from '@angular/core';
import { FileDownloadService } from './shared/file-download.service';
@Component({
  moduleId: module.id,
  selector: 'file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.sass']
})
export class FileDownloadComponent implements OnInit, OnChanges {
  @Output() public downloadClicked: EventEmitter<any> = new EventEmitter<any>();
  @Input('payLoadJson') public payLoadJson: any;
  @Input('postExcelPayload') public postExcelPayload: any;
  @Input('id') public id: string;
  @Input('excelInfo') public excelInfo = {
    method: 'POST',
    type: 'CMS',
    emit: true,
    title: '',
    toolTip: null
  };
  @Input() public downloadType;
  @Input('disabled') public disabled = false;
  constructor(private fileDownloadService: FileDownloadService) {}

  public ngOnChanges() {
    if (this.postExcelPayload instanceof Object && this.fileDownloadService.postDownloadClicked) {
      this.processPostExcel();
    }
    if (this.payLoadJson instanceof Object && this.fileDownloadService.getDownloadClicked) {
      this.processGetExcel();
    }
  }
  public ngOnInit() {}

  // fires when get download button is clicked
  public getDownloadExcel() {
    if (this.excelInfo && this.excelInfo.emit) {
      this.fileDownloadService.getDownloadClicked = true;
      this.downloadClicked.emit();
    } else {
      this.processGetExcel();
    }
  }
  // fires when post download button is clicked
  public postDownloadExcel() {
    if (this.excelInfo && !this.excelInfo.emit) {
      this.processPostExcel();
    } else {
      this.fileDownloadService.postDownloadClicked = true;
      this.downloadClicked.emit();
    }
  }
  private async processGetExcel() {
    const url = await this.fileDownloadService.formDownloadUrl(
      this.payLoadJson
    );
    const aTag = document.createElement('a');
    aTag.setAttribute('href', url);
    aTag.click();
    this.fileDownloadService.getDownloadClicked = false;
  }
  private processPostExcel() {
    this.fileDownloadService.postDownloadClicked = false;
    this.fileDownloadService.processPostExcel(this.postExcelPayload);
  }
}
