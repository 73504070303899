import { PayLoad } from 'app/modules/common/models/payload.model';
import { Injectable } from '@angular/core';
import { ApiServiceProvider } from 'app/api-service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

export interface DashboardDataChange {
  type: string;
  data: any;
  isData?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CoverageDashboardService extends ApiServiceProvider {

  private dashboardDataChange$: Subject<any> = new Subject();

  public getDashboardDataChangeObservable(): Observable<DashboardDataChange> {
    return this.dashboardDataChange$.asObservable();
  }

  public setDashboardDataChangeObservable(data) {
    this.dashboardDataChange$.next(data);
  }

  public getGlobalFilters(): any {
    const globalFiltersPayload = {
      targetedType: 'ContentManagementService',
      method: 'GET',
      servicePath: `ContentManagementService/coverage/filters`,
    };
    return this.post(globalFiltersPayload);

  }

  public getCoverageDashboardCardDetails(params): any {
    const coveragePayload = {
      targetedType: 'ContentManagementService',
      method: 'POST',
      servicePath: `ContentManagementService/coverage?chart-title=DATA_QUALITY`,
      payload: this.getPayload(params)
    };
    return this.post(coveragePayload);

  }

  public getMpnCoverageDetails(params, type: string): any {
    const mpnByCountPayload = {
      targetedType: 'ContentManagementService',
      method: 'POST',
      servicePath: `ContentManagementService/coverage?chart-title=${type}`,
      payload: this.getPayload(params)
    };
    return this.post(mpnByCountPayload);
  }

  public getGridData(payloadData?): Observable<any> {
    const gridPayload = {
      targetedType: 'ContentManagementService',
      method: 'POST',
      servicePath: `ContentManagementService/coverage/metrics-grid`,
      payload: payloadData
    };

    return this.post(gridPayload);
  }

  public getPayload(selectedFilters: any[]) {
    const payload = {
      excludeCpnMissingMpnEnabled: false,
      excludeMpnMissingSpendEnabled: false,
      onlyCustomerControlledMpnEnabled: false,
      excludeCustomPartsEnabled: false,
    }
    if (selectedFilters && selectedFilters.length) {
      selectedFilters.forEach(filter => {
        payload[filter] = true
      });
    } else {
      for (const filter in payload) {
        if (Object.prototype.hasOwnProperty.call(payload, filter)) {
          payload[filter] = false;
        }
      }
    }
    return payload;
  }

}
