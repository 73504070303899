import { DataVisualizationService } from './data-visualization.service';
import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { QuickSightDashboardService } from 'app/modules/core/quicksight-dashboard/shared/quicksight-dashboard.service';
import { SubscriptionService } from 'app/modules/common/subscription.service';
import { SubscriptionLike } from 'rxjs';

@Component({
  selector: 'app-data-visualization',
  templateUrl: './data-visualization.component.html',
  styleUrls: ['./data-visualization.component.sass'],
  providers: [DataVisualizationService, QuickSightDashboardService],
})
export class DataVisualizationComponent
  implements OnInit, OnChanges, OnDestroy {
  constructor(
    private dataService: DataVisualizationService,
    private dataStorageService: DataStorageService,
    private scopeDataService: ScopeDataService,
    private quickSightDashboardService: QuickSightDashboardService,
    private subscriptionService: SubscriptionService
  ) {}
  @Input() dashboardObject = null;
  @Input() disableReset = true;
  loading = true;
  noDashboard = false;

  subscriptions = {};
  appliedScopeFilters = {};
  dashboard: any;

  reset = 'enable';
  parameters = [
    'CMODM',
    'CommodityManager',
    'Commodity',
    'CPN',
    'Manufacturer',
    'MPN',
    'SubCommodity',
    'Source',
    'Supplier',
    'CPNDescription',
    'businessunit',
    'Product',
    'ProductFamily',
    'Control',
  ];

  applicationScopeSubscription: SubscriptionLike;
  scopeSelectedSubscription: SubscriptionLike;
  clearScopeFiltersSubscription: SubscriptionLike;

  ngOnInit() {
    this.applicationScopeSubscription = this.scopeDataService
      .getApplicationScope()
      .subscribe((data) => {
        if (data) {
          this.appliedScopeFilters = {};
          if (
            data.selectedScopeData[this.scopeDataService.getFocusValue()]
              .dimensions !== undefined
          ) {
            const dimensions =
              data.selectedScopeData[this.scopeDataService.getFocusValue()]
                .dimensions;
            this.parameters.forEach((val) => {
              this.appliedScopeFilters[val] = '[ALL]';
            });
            for (const item of Object.keys(dimensions)) {
              let dimName = this.quickSightDashboardService.retriveQuickSightParameterNames(
                item
              );
              if (dimName === undefined) {
                dimName = item;
              }
              this.parameters.forEach((val) => {
                if (val === dimName) {
                  if (
                    dimName === 'Manufacturer' ||
                    dimName === 'Supplier' ||
                    dimName === 'CMODM' ||
                    dimName === 'Commodity' ||
                    dimName === 'SubCommodity'
                  ) {
                    this.appliedScopeFilters[dimName] = Object.keys(
                      dimensions[item]
                    ).map((i) => i.toUpperCase());
                  } else {
                    this.appliedScopeFilters[dimName] = Object.keys(
                      dimensions[item]
                    );
                  }
                }
              });
            }
          }
          if (
            data.selectedScopeData[this.scopeDataService.getFocusValue()]
              .dimensions
          ) {
            if (this.dashboard) {
              this.setParametersForQuickSight(this.appliedScopeFilters);
            }
          }
        }
      });
    this.scopeSelectedSubscription = this.scopeDataService
      .getFocus()
      .subscribe((scope) => {
        if (scope) {
          this.parameters.forEach((val) => {
            this.appliedScopeFilters[val] = '[ALL]';
          });
          if (this.dashboard) {
            this.setParametersForQuickSight(this.appliedScopeFilters);
          }
        }
      });
    this.clearScopeFiltersSubscription = this.subscriptionService
      .getClearScope()
      .subscribe((value) => {
        if (value !== undefined) {
          if (value) {
            this.parameters.forEach((val) => {
              this.appliedScopeFilters[val] = '[ALL]';
            });
            if (this.reset === 'enable') {
              this.reset = 'disable';
            } else {
              this.reset = 'enable';
            }
            this.appliedScopeFilters['reset'] = this.reset;
            if (this.dashboard) {
              this.setParametersForQuickSight(this.appliedScopeFilters);
            }
          }
        }
      });
  }

  ngOnChanges() {
    this.loading = true;
    this.dataStorageService.setStatusOfQSDashboard(false);
    this.dataService
      .getDashboardUrl(this.dashboardObject.dashboard_id, this.disableReset)
      .subscribe((data) => {
        if (data.result !== undefined && data.result !== null) {
          this.noDashboard = false;
          const containerDiv = document.getElementById('dashboardContainer');
          containerDiv.innerHTML = '';
          const options = {
            url: data && data.result,
            container: containerDiv,
            scrolling: 'no',
            parameters: {},
          };
          if (this.dashboardObject.parameters !== null) {
            options['parameters'] = this.dashboardObject.parameters;
          }
          if (Object.keys(this.appliedScopeFilters).length > 0) {
            options['parameters'] = Object.assign(
              {},
              options['parameters'],
              this.appliedScopeFilters
            );
          }
          let path = window.location.pathname;
          path = path.replace(/[\/\\]/g, '');
          if (path.length > 0) {
            path = window.location.host + '/' + path;
          } else {
            path = window.location.host;
          }
          options['parameters']['hostName'] = path;
          this.dashboard = QuickSightEmbedding.embedDashboard(options);
          this.dashboard.on('error', (error: any) => {
            console.log('Error', error);
            this.loading = false;
          });
          this.dashboard.on('load', (res: any) => {
            console.log('Data', res);
            this.loading = false;
            this.dataStorageService.setStatusOfQSDashboard(true);
          });
        } else {
          this.noDashboard = true;
          this.loading = false;
        }
      });
  }

  setParametersForQuickSight(parameters) {
    // console.log(parameters);
    if (!this.loading) {
      this.dashboard.setParameters(parameters);
    } else {
      setTimeout(() => {
        this.dashboard.setParameters(parameters);
      }, 5000);
    }
  }

  ngOnDestroy() {
    this.dataStorageService.setStatusOfQSDashboard(undefined);
    if (this.applicationScopeSubscription) {
      this.applicationScopeSubscription.unsubscribe();
    }
    if (this.scopeSelectedSubscription) {
      this.scopeSelectedSubscription.unsubscribe();
    }
    if (this.clearScopeFiltersSubscription) {
      this.clearScopeFiltersSubscription.unsubscribe();
    }
  }
}
