import {
  ChangeDetectionStrategy,
  /*OnChanges, SimpleChanges*/
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ObjectUtils } from 'app/modules/common/utills/ObjectUtils.service';
import * as _ from 'lodash';
import { StringUtils } from './../select-dropdown/select-dropdown.component';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';

const dropdownHeight = 180;
const rowHeight = 28;
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'create-dropdown',
  templateUrl: './create-dropdown.component.html',
  styleUrls: ['./create-dropdown.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CreateDropdownComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateDropdownComponent
  implements OnInit, /* OnChanges,*/ OnDestroy, ControlValueAccessor
{
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  // setters
  @Input()
  set defaultSelected(value) {
    this._defaultSelect = value;
    this.setDefaultValue();
  }
  get defaultSelected() {
    return this._defaultSelect;
  }

  @Input('keepSelection') keepSelection = false;
  @Input('stopPropagateOnDefaultSelection') stopPropagateOnDefaultSelection =
    false;
  @Input() partsCatalog;
  @Input() styleChange;
  @Input() customStyle?;
  @Input() enableToolTip = true;
  @Input()
  set items(value) {
    this._items = value;
    // console.log('recieved value', value);
    if (value instanceof Array) {
      if (value.length > 0) {
        value = _.differenceWith(value, this.filterItems, _.isEqual);
        if ((this.getText(this.items[0]) || '').length > 0) {
          this.empty = false;
          if (this.disableSelection === undefined) {
            this.disabled = false;
          }
          /* if (this.fromWriteValue) {
            this.setDefaultValue();
            this.fromWriteValue = false;
            this._defaultSelect = null;
          } else {
            this.setDefaultValue();
          } */
          this.setDefaultValue();
        } else {
          this.empty = true;
        }
      } else {
        this.empty = true;
      }
    } else {
      this.empty = true;
    }
  }
  get items() {
    return this._items;
  }
  private readKeyValue;

  @Input()
  set readKey(val) {
    this.readKeyValue = val;
    this.items = this._items;
    this.setDefaultValue();
  }
  get readKey() {
    return this.readKeyValue;
  }
  @Input()
  set disableOnEmpty(value) {
    this._disableOnEmpty = value;
    if (this.empty) {
    }
  }

  @Input()
  set disabledText(value) {
    this._disabledText = value;
  }
  get disabledText() {
    return this._disabledText;
  }

  @Input()
  set emptyText(value) {
    this._emptyText = value;
  }
  get emptyText() {
    return this._emptyText;
  }

  @Input()
  set dropdownTitle(value) {
    this._dropdownTitle = value;
  }
  get dropdownTitle() {
    return this._dropdownTitle;
  }

  set empty(value) {
    this._empty = value;
    if (!value) {
      if (this.disableOnEmpty) {
        this.disabled = true;
      }
    }
  }
  get empty() {
    return this._empty;
  }

  set disabled(value) {
    this._disabled = value;
  }
  get disabled() {
    return this._disabled;
  }

  @Input()
  set disableSelection(val) {
    this._disableSelection = val;
    this.disabled = val;
    if (this.keepSelection) {
    } else {
      this.setDefaultValue();
    }
  }
  get disableSelection() {
    return this._disableSelection;
  }

  @Input()
  set defaultItemKey(value) {
    this._defaultItemKey = value;
    this.setDefaultValue();
  }
  get defaultItemKey() {
    return this._defaultItemKey;
  }

  @Input()
  set resetToDefault(bool) {
    this._resetToDefault = bool;
    if (bool) {
      this.setDefaultValue();
    }
  }
  get resetToDefault() {
    return this._resetToDefault;
  }

  constructor(private elRef: ElementRef) {}

  private get selectedDropdownItem() {
    return this._selectedDropdownItem;
  }

  private set selectedDropdownItem(item: any) {
    if (this.items instanceof Array) {
      if (this.items.length > 0) {
        this._selectedDropdownItem = item;
        this.propagateChange(this._selectedDropdownItem);
        if (item && this.partsCatalog) {
          item['id'] = this.partsCatalog;
        }
        if (!this.stopPropagateOnDefaultSelection) {
          this.change.emit(item);
        }
      }
    }
    if (this.selectedDropdownItem === null) {
      // this.selectedDropdownItem = null;
    }
  }
  // local variables
  private _dropdownTitle = '';
  public dropdownVisible = false;
  private defaultReadKey = 'title';
  private _items: any = [];
  private _selectedDropdownItem: any = null;
  private _disabledText =
    'You are not able to select any value in this dropdown';
  private _emptyText = 'No results match your search criteria';
  private _disableOnEmpty = true;
  private changes;
  private _empty = true;
  private _disabled = true;
  private _defaultSelect: any = null;
  private _defaultItemKey = 'defaultVal';
  private _resetToDefault = false;
  private _disableSelection: boolean;
  // private fromWriteValue = false;

  // input variables

  // @Input() readKey = null;
  @Input() upDirection = false;
  @Input() disabledKey = 'disabled';
  @Input() dropdownId = StringUtils.getRandomString();

  @Input() filterDisabled = false;
  @Input() disableFilterDropdown = false;
  @Input() filterItems = [];
  showDropUp = false;

  // output variables

  // tslint:disable-next-line: no-output-native
  @Output() change = new EventEmitter<any>();

  @Output() dropdownClicked = new EventEmitter<any>();
  // tslint:disable-next-line: no-shadowed-variable
  private propagateChange = (_: any) => {};

  ngOnInit() {
    this.setDefaultValue();
    if (this.filterDisabled) {
      this.items = this.items.filter(
        (item) => item.disabledSelection === false
      );
    }
  }

  public onAutoSearch(event) {
    const clientPos =
      event.clientY -
      (event.offsetY - 4) +
      event.currentTarget.clientHeight +
      rowHeight;
    if (window.innerHeight - clientPos > dropdownHeight) {
      this.showDropUp = false;
    } else {
      this.showDropUp = true;
    }
  }

  /* ngOnChanges(changes: SimpleChanges) {
    for (let prop in changes) {
      let change = changes[prop];

      let currentValue = change.currentValue;
      let previosValue = change.previousValue;

      console.log(currentValue, ", ", previosValue);
    }
  } */

  setDefaultValue() {
    if (this.defaultSelected === null) {
      if (typeof this.defaultItemKey === 'string') {
        if (this.defaultItemKey.length > 0) {
          if (this.items instanceof Array) {
            const defaultItem = this.items.filter((i) => {
              return i[this.defaultItemKey];
            });
            if (defaultItem instanceof Array && defaultItem.length > 0) {
              this._defaultSelect = defaultItem[0];
            }
          }
        }
      }
      if (this.defaultSelected === null) {
        if (this.items instanceof Array) {
          if (this.items.length > 0) {
            if (
              JSON.stringify(this.selectedDropdownItem) !==
              JSON.stringify(this.items[0])
            ) {
              this.setSelectedItem(this.items[0]);
            }
          }
        }
      } else {
        if (
          JSON.stringify(this.selectedDropdownItem) !==
          JSON.stringify(this.defaultSelected)
        ) {
          this.setSelectedItem(this.defaultSelected);
        }
      }
    } else {
      if (
        JSON.stringify(this.selectedDropdownItem) !==
        JSON.stringify(this.defaultSelected)
      ) {
        this.setSelectedItem(this.defaultSelected);
      }
    }
  }

  toggleDropdownVisibility(event) {
    this.dropdownClicked.emit(event);
    // this.dropdownClick(event)
  }

  getText(item: any) {
    if (item instanceof Object) {
      let readKey = this.defaultReadKey;
      if (typeof this.readKey === 'string') {
        readKey = this.readKey;
      }
      const text = ObjectUtils.deepFind(item, readKey);
      return text;
    } else {
      return item;
    }
  }

  isItemDisabled(item: any) {
    if (item instanceof Object) {
      if (typeof item[this.disabledKey] === 'boolean') {
        return item[this.disabledKey];
      }
    }
    return false;
  }

  dropDownClick(event) {
    this.dropdownClicked.emit(event);
  }

  itemClicked(event, item) {
    // event.stopImmediatePropagation();
    this.dropdownVisible = false;
    this.stopPropagateOnDefaultSelection = false;
    this.setSelectedItem(item);
  }

  setSelectedItem(item: any) {
    this.selectedDropdownItem = item;
  }

  getDropdownText() {
    let text = '';
    if (this.disableSelection) {
      if (this.selectedDropdownItem instanceof Object) {
        if (this.getText(this.selectedDropdownItem).length > 0) {
          text = this.getText(this.selectedDropdownItem);
        } else {
          // text = 'Selected Dropdown Item is having empty text';
          text = this.disabledText;
        }
      } else {
        text = this.disabledText;
      }
    } else {
      if (this.disabled) {
        text = this.disabledText;
      } else {
        if (this.selectedDropdownItem instanceof Object) {
          if (
            this.getText(this.selectedDropdownItem[this.readKey]) !== undefined
          ) {
            if (this.getText(this.selectedDropdownItem).length > 0) {
              text = this.getText(this.selectedDropdownItem);
            }
          } else {
            // text = 'Selected Dropdown Item is having empty text';
            text = this.disabledText;
          }
        } else {
          if (this._defaultSelect === null) {
            text = this.emptyText;
          } else {
            text = this._defaultSelect;
          }
        }
      }
    }
    return text;
  }

  getDropdownTitle() {
    let text = '';
    if (this.disableSelection && this.enableToolTip) {
      if (this.selectedDropdownItem instanceof Object) {
        if (this.getText(this.selectedDropdownItem).length > 0) {
          text = this.getText(this.selectedDropdownItem);
        } else {
          // text = 'Selected Dropdown Item is having empty text';
          text = this.disabledText;
        }
      } else {
        text = this.disabledText;
      }
    } else if (this.enableToolTip) {
      if (this.disabled) {
        text = this.disabledText;
      } else {
        if (this.selectedDropdownItem instanceof Object) {
          if (
            this.getText(this.selectedDropdownItem[this.readKey]) !== undefined
          ) {
            if (this.getText(this.selectedDropdownItem).length > 0) {
              text = this.getText(this.selectedDropdownItem);
            }
          } else {
            // text = 'Selected Dropdown Item is having empty text';
            text = this.disabledText;
          }
        } else {
          if (this._defaultSelect === null) {
            text = this.emptyText;
          } else {
            text = this._defaultSelect;
          }
        }
      }
    }
    return text;
  }

  /*  ControlValueAccessorFunctionImplementation */
  writeValue(value: any) {
    if (this.items instanceof Array) {
      if (this.items.length > 0) {
        this.items.forEach((item) => {
          if (JSON.stringify(item) === JSON.stringify(value)) {
            return this.setSelectedItem(value);
          }
        });
      } /* else {
        this.fromWriteValue = true;
        this.defaultSelected = value;
      }
    } else {
      this.fromWriteValue = true;
      this.defaultSelected = value; */
    }
    // this.setSelectedItem(value);
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  ngOnDestroy() {}
}
