export class CollaborationSummaryModel {
    public archived: boolean;
    public category: string;
    public collaborationId: string;
    public collaborationType: string;
    public displayTopic: string;
    public frontEndId: string;
    public frontEndTracker: string;
    public hasWaitingTask: boolean;
    public subCategory: string;
    public taskDueDate: any;
    public tenantId: any;
    public topic: string

    public setArchived(archived: boolean) {
        this.archived = archived;
    }

    public getArchived() {
        return this.archived;
    }

    public setCategory(category: string) {
        this.category = category;
    }

    public getCategory() {
        return this.category;
    }

    public setCollaborationId(collaborationId: string) {
        this.collaborationId = collaborationId;
    }

    public getCollaborationId() {
        return this.collaborationId;
    }

    public setCollaborationType(collaborationType: string) {
        this.collaborationType = collaborationType;
    }

    public getCollaborationType() {
        return this.collaborationType;
    }

    public setDisplayTopic(displayTopic: string) {
        this.displayTopic = displayTopic;
    }

    public getDisplayTopic() {
        return this.displayTopic;
    }

    public setFrontEndId(frontEndId: string) {
        this.frontEndId = frontEndId;
    }

    public getFrontEndId() {
        return this.frontEndId;
    }

    public setFrontEndTracker(frontEndTracker: string) {
        this.frontEndTracker = frontEndTracker;
    }

    public getFrontEndTracker() {
        return this.frontEndTracker;
    }

    public setHasWaitingTask(hasWaitingTask: boolean) {
        this.hasWaitingTask = hasWaitingTask;
    }

    public getHasWaitingTask() {
        return this.hasWaitingTask;
    }

    public setSubCategory(subCategory: string) {
        this.subCategory = subCategory;
    }

    public getSubCategory() {
        return this.subCategory;
    }

    public setTaskDueDate(taskDueDate: any) {
        this.taskDueDate = taskDueDate;
    }

    public getTaskDueDate() {
        return this.taskDueDate;
    }

    public setTenantId(tenantId: any) {
        this.tenantId = tenantId;
    }

    public getTenantId() {
        return this.tenantId;
    }

    public setTopic(topic: string) {
        this.topic = topic;
    }

    public getTopic() {
        return this.topic;
    }

}
