import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { History } from './shared/history.model';
import { HistoryService } from './shared/history.service';
import { DateUtils } from 'app/modules/common/utills/DateUtils.service';
import { UploadDownloadService } from '../upload-download.services';
import { RequestData } from '../../common/models/request.model';
import { PayLoad } from '../../common/models/payload.model';

@Component({
  moduleId: module.id,
  selector: 'history',
  templateUrl: 'history.component.html',
  styleUrls: ['./history.component.sass'],
  providers: [HistoryService, DateUtils]
})
export class HistoryComponent implements OnInit {
  @Input() set tenantUsers(input) {
    if (input instanceof Array) {
      this._tenantUsers = input;
      const allUserFullNames = this._tenantUsers.map(
        tenantUser => tenantUser.fullName
      );
      this.allUserFullNamesOfTenant = allUserFullNames;
      this.createRequestPayLoad(this.allUserFullNamesOfTenant);
    }
  }
  get tenantUsers() {
    return this._tenantUsers;
  }

  constructor(
    private historyService: HistoryService,
    private dateUtils: DateUtils,
    private uploadDownloadService: UploadDownloadService
  ) {}
  @Input() public type;
  @Input() public data;
  @Input() public input;
  @Output() public onExpandOrCollapse = new EventEmitter<any>();
  @Output() public onDelCollab = new EventEmitter<any>();
  @Output() onCollabPost = new EventEmitter<any>();
  public isExpanded = false;
  isEdited = false;
  postText: string;
  disableSaveEdit = true;
  disableEditClose = false;
  clickedOnSubItems = false;
  public selectedMentions = [];
  public _tenantUsers;
  public allUserFullNamesOfTenant: string[] = [];
  public requestData: RequestData;
  public mentionConfig = {
    triggerChar: '@',
    labelKey: 'fullName',
    mentionSelect: this.formatter.bind(this)
  };
  ajaxRequests: any = {};

  public ngOnInit() {}
  public async filterInput(input) {
    const url = await this.parseDownloadUrl(input);
    const aTag = document.createElement('a');
    aTag.setAttribute('href', url);
    aTag.click();
  }
  public formatter($event) {
    this.selectedMentions.push($event);
    return `@${$event.fullName}` || '';
  }
  public async parseDownloadUrl(input) {
    const url = await this.uploadDownloadService.downloadToExcel(
      '',
      {
        downloadUrl: input.downloadUrl.replace('<', '').replace('>', ''),
        fileName: input.name
      },
      'download'
    );
    return url;
  }
  actionIconClick(event) {
    if (this.data.expanded) {
      this.clickedOnSubItems = true;
    }
  }
  public expandOrCollapse() {
    if (!this.clickedOnSubItems) {
      this.data.expanded = !this.data.expanded;
      this.isEdited = false;
      this.onExpandOrCollapse.emit(this.data);
    } else {
      this.clickedOnSubItems = false;
    }
  }
  public deleteCollaboration(input) {
    this.onDelCollab.emit(input);
  }
  EditCollaboration(input, event) {
    this.disableSaveEdit = true;
    if (this.data.expanded) {
      this.clickedOnSubItems = true;
    }
    this.isEdited = input;
    this.postText = input.text;
  }
  EditConversation(input, event) {
    event.stopImmediatePropagation();
    this.isEdited = input;
  }
  validateServiceData(data) {
    if (data instanceof Object) {
      if (data['responseStatus'] instanceof Object) {
        if (data['result'] instanceof Array || data['result'] === true) {
          return data;
        } else {
          return 'error';
        }
      }
    }
  }
  changeText(event) {
    this.disableSaveEdit = false;
    this.postText = event;
    if (this.postText.length > 0) {
      this.disableSaveEdit = false;
    } else {
      this.disableSaveEdit = true;
    }
  }

  clearEditedCollaboration(event) {
    this.stopPropagation(event);
    this.isEdited = false;
  }

  saveEditedCollaboration(conversation, collaborations, event) {
    this.stopPropagation(event);
    this.disableEditActions();
    this.clearAjaxCalls('editCollaboration');
    this.ajaxRequests['editCollaboration'] = this.historyService
      .editCollab(this.postText, this.input, conversation, collaborations)
      .subscribe((data: any) => {
        if (this.validateServiceData(data) !== 'error') {
          this.onCollabPost.emit(data);
        }
      });
  }
  disableEditActions() {
    this.disableSaveEdit = true;
    this.disableEditClose = true;
  }
  clearAjaxCalls(key?: string) {
    if (typeof key === 'string') {
      if (this.ajaxRequests[key] instanceof Object) {
        this.ajaxRequests[key].unsubscribe();
        delete this.ajaxRequests[key];
      }
      return;
    }
    for (const req in this.ajaxRequests) {
      if (this.ajaxRequests[req] instanceof Object) {
        this.ajaxRequests[req].unsubscribe();
        delete this.ajaxRequests[req];
      }
    }
  }
  deleteConversation(conversations, conversation, event) {
    event.stopImmediatePropagation();
    this.deleteConv(conversations, conversation);
  }
  stopPropagation(event) {
    event.stopImmediatePropagation();
  }

  deleteConv(conversations, conversation) {
    this.clearAjaxCalls('delConversation');
    this.ajaxRequests[
      'delConversation'
    ] = this.historyService
      .deleteConversation(conversation, conversations)
      .subscribe((data: any) => {
        if (this.validateServiceData(data) !== 'error') {
          this.onCollabPost.emit(data);
        }
      });
  }
  private createRequestPayLoad(options: string[]) {
    const payLoad = new PayLoad();
    payLoad.isWildCardSearch = true;
    this.requestData = new RequestData();
    this.requestData.placeHolder = 'Enter Value';
    this.requestData.type = 'multi';
    this.requestData.options = options;
    this.requestData.id = 'usernames';
    this.requestData.typeOfSelectSingle = true;
    this.requestData.payload = payLoad;
  }
}
