
import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild } from '@angular/core';
import { DashboardFiltersComponent } from '../dashboard-filters/dashboard-filters.component';
import { DashboardMetricTrendsComponent } from '../dashboard-metric-trends/dashboard-metric-trends.component';
import { DashboardPieChartComponent } from '../dashboard-pie-chart/dashboard-pie-chart.component';
import { GraphContainerComponent } from '../graph-container/graph-container.component';
import { IndustryAlertsComponent } from '../industry-alerts/industry-alerts.component';
import { LegendKpiComponent } from '../legend-kpi/legend-kpi.component';
import { MetricKpiComponent } from '../metric-kpi/metric-kpi.component';
import { RadialChartComponent } from '../radial-chart/radial-chart.component';
import { AddItem, TileType } from './add-items';
import { AddComponent } from './add.component';
import { AddDirective } from './add.directive';

@Component({
  selector: 'app-dynamic-load',
  templateUrl: './dynamic-load.component.html',
  styleUrls: ['./dynamic-load.component.sass']
})
export class DynamicLoadComponent implements OnInit {

  @Input() configuration;

  componentList: AddItem[] = []
  currentAdIndex = -1;
  @ViewChild(AddDirective, { static: true }) addHost!: AddDirective;
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver
  ) { }

  ngOnInit() {
    // console.log(this.configuration);
    this.addComponentList(this.configuration);
    this.loadComponent();
  }

  addComponentList(component) {
    if (component && component.id && component.data && component.data.type && component.data.data) {
      switch (component.data.type.toLowerCase().trim()) {
        case (TileType.KPI_TILE):
          this.componentList.push(new AddItem(MetricKpiComponent, component.data));
          break;
        case (TileType.LEGEND_TILE):
          this.componentList.push(new AddItem(LegendKpiComponent, component.data));
          break;
        case (TileType.GRAPH_TILE):
          this.componentList.push(new AddItem(GraphContainerComponent, component));
          break;
        case (TileType.INDUSTRY_TOP_ALERTS):
        case (TileType.INDUSTRY_RECENT_ALERTS):
          this.componentList.push(new AddItem(IndustryAlertsComponent, component.data));
          break;
        case (TileType.PIE_GRAPH_TILE):
          this.componentList.push(new AddItem(DashboardPieChartComponent, component));
          break;
        case (TileType.METRIC_TRENDS):
          this.componentList.push(new AddItem(DashboardMetricTrendsComponent, component));
          break;
        case (TileType.GLOBAL_FILTER):
          this.componentList.push(new AddItem(DashboardFiltersComponent, component));
          break;
        case (TileType.RADIAL_GRAPH_TILE):
          this.componentList.push(new AddItem(RadialChartComponent, component));
          break;
      }
    }
  }

  loadComponent() {
    this.componentList.forEach(
      (tile) => {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(tile.component);
        const viewContainerRef = this.addHost.viewContainerRef;
        const componentRef = viewContainerRef.createComponent<AddComponent>(componentFactory);
        componentRef.instance.configuration = tile.configuration;
      }
    );

  }

}
