import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs/Rx';
import { WebsocketService } from 'app/modules/common/socket/webscoket.service';
import { BaseService } from 'app/modules/common/base.service';
import { environment } from 'environments/environment';

// const CHAT_URL = 'ws://cmsdev.levadata.net:8080/LevaAlertsService/notificationSocketHandler?userEmail=';

export interface Message {
  author: string,
  message: string
}

@Injectable()
export class ChatService {

  public messages: Subject<Message>;
  userEmail: string;
  CHAT_URL= environment.scoket.path;
  constructor(
    wsService: WebsocketService,
    private baseService: BaseService
  ) {
    this.userEmail = this.baseService.getUserInformation().email !== undefined ? this.baseService.getUserInformation().email : undefined;
    if (this.userEmail !== undefined) {
      if (environment && environment['webSocke']) {
        this.messages = (wsService
          .connect(this.CHAT_URL + this.userEmail)
          .map((response: MessageEvent): Message => {
            const data = JSON.parse(response.data);
            // console.log(data);
            if (data instanceof Object) {
              return data;
            }
          }, (error) => {
            console.log(error);
          }) as Subject<Message>);
      }
    }
  }
}
