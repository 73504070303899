import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
    selector: 'child-cell',
    template: `<div *ngIf="latestResponse" class="dropdown full-width">
    <select [(ngModel)]="statusSelected" (change)="onChange($event)" class="form-control curator-select">
        <option *ngFor="let status of statusDropDownArray[val]">{{status}}</option>
    </select>
</div>
<div style="font-size: 14px;padding-left: 11px;padding-right: 11px" *ngIf="!latestResponse" class="dropdown full-width">
           {{statusDropDownArray[val][0]}}
</div>`,
})
export class ResponsesStatusComponent implements ICellRendererAngularComp, OnDestroy {
    private subject$: Subject<any> = new Subject();
    constructor(private dataStorageService: DataStorageService) {
        dataStorageService.getDropdownStatusModifier()
            .pipe(takeUntil(this.subject$))
            .subscribe(statusIdModifierMap => {
                if (statusIdModifierMap !== undefined) {
                    this.statusModifierArray = statusIdModifierMap
                    for (const statusKey of Object.keys(statusIdModifierMap)) {
                        this.statusCodeMap[this.statusModifierArray[statusKey]['id']] = this.statusModifierArray[statusKey]['value']
                        this.statusDropDownArray[this.statusModifierArray[statusKey]['id']] = this.getDropDownValues(this.statusModifierArray[statusKey]['statusModifierArray'])
                    }
                }
            }
            )
    }
    public params: any;
    val: any;
    changedFlag = false;
    statusArray: any;
    statusSelected = null;
    statusModifierArray: any;
    statusDropDownArray: any = {}
    statusCodeMap = {}
    latestResponse = true
    selectedStatusId: any;
    agInit(params: any): void {
        this.val = params.value;
        this.params = params;
        if (params['data']['is_latest_resp'] !== undefined && params['data']['is_latest_resp'] === 0) {
            this.latestResponse = false
        }
        const modifiedDropDownObject = this.dataStorageService.getChangedDropDownValues(params['data']['cmspkId'])
        if (modifiedDropDownObject !== undefined) {
            this.val = modifiedDropDownObject['statusIdValue']
            this.selectedStatusId = modifiedDropDownObject['selectedStatusId']
            this.params = params;
            this.statusSelected = this.selectedStatusId;
            this.params['data']['STATUS_ID'] = parseInt(this.getSelectedStatusKey(this.statusSelected));
        }
        else {
            this.val = params.value;
            this.params = params;
            this.selectedStatusId = this.val;
            this.statusSelected = ((this.statusDropDownArray || [])[this.val] || [])[0];
        }
    }

    refresh(): boolean {
        return true;
    }

    onChange(event) {
        const statusValue = event.currentTarget.value
        if (this.statusCodeMap !== undefined) {
            for (const key of Object.keys(this.statusCodeMap)) {
                if (this.statusCodeMap[parseInt(key)] === statusValue) {
                    const changedStatusParams = this.params
                    changedStatusParams['data']['OLD_STATUS_ID'] = this.params['data']['STATUS_ID']
                    changedStatusParams['data']['STATUS_ID'] = parseInt(key)
                    this.dataStorageService.setChangedStatusRow(changedStatusParams)
                    const changedDropdownObject = {}
                    changedDropdownObject['selectedStatusId'] = this.statusSelected
                    changedDropdownObject['statusIdValue'] = this.val
                    this.dataStorageService.setChangedDropdownValues(changedStatusParams['data']['cmspkId'], changedDropdownObject)
                }
            }
        }
    }

    getDropDownValues(sampleArray) {
        const dropdownArray = []
        if (sampleArray !== undefined || sampleArray !== null) {
            for (const i of Object.keys(sampleArray)) {
                dropdownArray.push(sampleArray[i].value)
            }
        }
        return dropdownArray
    }

    getSelectedStatusKey(status) {
        let statusKey: any = 0
        for (const k of Object.keys(this.statusCodeMap)) {
            if (this.statusCodeMap[k] === status) {
                statusKey = k
            }
        }
        return statusKey;
    }

    ngOnDestroy() {
        this.subject$.next();
        this.subject$.complete();
    }

}
