import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { RfqConfigService } from '../../../shared/rfq-config.service';
import { HEADER_VISIBILITY, HEADER_DEFAULT_OPTIONS, RFQ_CUSTOMER_CONTEXTS, INITIAL_PERMISSION_OBJ } from '../../../shared/constants';
import * as _ from 'lodash';
@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.sass']
})
export class PermissionsComponent implements OnInit {
  constructor(private rfqConfigService: RfqConfigService, private _cdr: ChangeDetectorRef) {}
  @Input() dataType;
  @Input()
  set headerType(val) {
    this._headerType = val;
  }
  get headerType() {
    return this._headerType;
  }
  @Input()
  set permissions(value) {
    if(value){
      this._permissions = value;
    }else {
      this._permissions = this.getNewPermission();
    }
    this.updateViewPermissions();
    this._cdr.detectChanges();
  }
  @Output() onPermissionChange : EventEmitter<any> = new EventEmitter<any>();
  public visibilityItems ;
  public defaultValuesItems;
  public customerViews: any = [] ;
  public allCustomerViews: any = [];
  public loaded = false;
  public supplierViewPermissions = [];
  private _headerType;
  _permissions;
  ngOnInit() {
    const permKey = `${HEADER_VISIBILITY},${HEADER_DEFAULT_OPTIONS},${RFQ_CUSTOMER_CONTEXTS}`;
    this.rfqConfigService.getRefSetConstants(permKey).subscribe(res=>{
      this.visibilityItems = res[0].refSetTerms;
      this.defaultValuesItems = res[1].refSetTerms;
      this.allCustomerViews = res[2].refSetTerms;
      this.loaded = true;
      this.updateViewPermissions();
      this._cdr.detectChanges();
    });
  }
  getNewPermission(){
    return INITIAL_PERMISSION_OBJ();
  }
  selectedCust(cust){
    return this.visibilityItems.find(x=> x.refTermActualValue === this._permissions.customerPrivileges[cust.refTermActualValue]);
  }
  selectedSup(){
    return this.visibilityItems.find(x => x.refTermActualValue === this._permissions.supplierPrivileges.SUPPLIER);
  }
  custChange(evt,cust){
    this._permissions.customerPrivileges[cust.refTermActualValue] = evt.refTermActualValue;
    this.onPermissionChange.emit(this._permissions);
  }
  supChange(evt){
    this._permissions.supplierPrivileges.SUPPLIER = evt.refTermActualValue;
    this.onPermissionChange.emit(this._permissions);
  }
  supDefault(evt){
    this._permissions.defaults.supplier.defaulValueOption = evt.refTermActualValue;
    this.onPermissionChange.emit(this._permissions);
  }
  custDefault(evt){
    this._permissions.defaults.customer.defaulValueOption = evt.refTermActualValue;
    this.onPermissionChange.emit(this._permissions);
  }
  getSupDefaultOption(){
    return this.defaultValuesItems.find(x => x.refTermActualValue === this._permissions.defaults.supplier.defaulValueOption);
  }
  getCustDefaultOption(){
    return this.defaultValuesItems.find(x => x.refTermActualValue === this._permissions.defaults.customer.defaulValueOption);
  }
  onBlur(){
    this.onPermissionChange.emit(this._permissions);
  }
  updateViewPermissions(){
    if(!this.visibilityItems){
      return
    }
    if(this._permissions.customerOptions){
      const custOptionsKeys = Object.keys(this._permissions.customerOptions);
      const custOptions = this._permissions.customerOptions;
      this.customerViews = this.allCustomerViews.filter( x=> custOptionsKeys.includes(x.refTermActualValue) )
      for(let i =0; i <this.customerViews.length; i++){
        this.customerViews[i].options = this.visibilityItems.filter(x =>
           custOptions[this.customerViews[i].refTermActualValue].includes(x.refTermActualValue)
        );
      }
    }else{
      this.customerViews = _.cloneDeep(this.allCustomerViews);
      for(let i =0; i <this.customerViews.length; i++){
        this.customerViews[i].options = this.visibilityItems;
      }
    }
    if(this._permissions.supplierOptions){
        this.supplierViewPermissions = this.visibilityItems.filter(x =>
          this._permissions.supplierOptions['SUPPLIER'].includes(x.refTermActualValue));
    }else if(this._headerType === 'CUSTOM' && !this._permissions.supplierOptions){
        this.supplierViewPermissions = this.visibilityItems;
    }else {
      this.supplierViewPermissions = this.visibilityItems;
    }
  }
}
