import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core'
import {
  DialogBoxService,
  DialogButtonName,
  DialogCurrentState,
  DialogInfo,
  DialogSize,
  DialogStatus,
  DialogType,
  LdDialog,
} from './dialog-box.service'
import * as $ from 'jquery'
import { Subject, Subscription } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

@Component({
  selector: 'ld-dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogBoxComponent implements OnInit, OnDestroy {
  @Input() id: string
  @Input() configuration: LdDialog
  @Output() dialogClick: EventEmitter<any> = new EventEmitter<any>()
  @ViewChild('uiHeader', { static: false }) uiHeader: ElementRef
  default = {
    SIZE: null,
    TYPE: DialogType.CONFIRMATION,
  }

  private clicks = new Subject();
  debounceTime = 100;
  private $subscription: Subscription;

  private element: any
  dialogOpenStatus = false
  zIndex = 2100
  renderElement = false
  $openDialogById: Subscription

  defaultBeforeCloseConfiguration: LdDialog = {
    title: 'Title',
    id: 'ui-before-close-dialog',
    type: DialogType.CONFIRMATION,
    message: 'Message',
    buttons: [
      {
        title: DialogButtonName.CANCEL,
        id: 'ok',
      },
      {
        title: DialogButtonName.NO,
        id: 'no',
      },
      {
        title: DialogButtonName.YES,
        id: 'yes',
        primary: true,
      },
    ],
  }
  @HostListener('document:keydown', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (this.dialogOpenStatus) {
      if (event.code === 'Escape') {
        // this.buttonClick({ id: DialogButtonName.CANCEL })
        this.clicks.next({ id: DialogButtonName.CANCEL });
      }
      if (event.code === 'Enter') {
        if (this.configuration.buttons && this.configuration.buttons.length) {
          const primaryButton = this.configuration.buttons.filter((button) => {
            return button.primary
          })
          if (primaryButton) {
            if(!(document && document.activeElement && String(document.activeElement.nodeName).toLowerCase() === 'textarea')) {
              this.clicks.next(primaryButton[0]);
            }
          }
        }
      }
    }
  }

  constructor(
    private dialogBoxService: DialogBoxService,
    private el: ElementRef
  ) {
    this.element = el.nativeElement

    this.$openDialogById = this.dialogBoxService.openDialogById
      .asObservable()
      .subscribe((dialogStatus: DialogCurrentState) => {
        try {
          if (this.configuration && this.configuration.id) {
            if (dialogStatus && this.configuration.id === dialogStatus.id) {
              if (dialogStatus.status === DialogStatus.OPEN) {
                this.renderElement = true
              } else if (dialogStatus.status === DialogStatus.CLOSE) {
                this.renderElement = false
              }
            }
          }
        } catch (e) {
          console.log(e);
        }
      });

    this.$subscription = this.clicks.pipe(
      debounceTime(this.debounceTime)
    ).subscribe(e => this.buttonClick(e));
  }

  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
      console.error('dialog must have an id')
      return
    }
    if (this.configuration.id !== this.defaultBeforeCloseConfiguration.id) {
      document.body.appendChild(this.element)
    }
    if (!document.getElementById(this.defaultBeforeCloseConfiguration.id)) {
      this.dialogBoxService.add(this)
    }
    // add self (this dialog instance) to the dialog service so it's accessible from controllers

    this.default.SIZE = this.configuration.customSize ? null : DialogSize.SMALL
  }

  // remove self from dialog service when component is destroyed
  ngOnDestroy(): void {
    this.dialogBoxService.remove(this.id)
    this.element.remove()
    if (this.$openDialogById) {
      this.$openDialogById.unsubscribe()
    }
    if (this.$subscription) {
      this.$subscription.unsubscribe();
    }
  }

  hideBackdrop(event) {
    if (
      !$(event.target).closest('.dialog-content')[0] &&
      this.configuration.modal
    ) {
      this.clicks.next({
        id: DialogButtonName.CLOSE,
        eventType: DialogInfo.NONMODAL,
      });
      /* this.buttonClick({
        id: DialogButtonName.CLOSE,
        eventType: DialogInfo.NONMODAL,
      }) */
    }
  }

  // open dialog
  open(): void {
    this.zIndex = this.dialogBoxService.getZIndex() + 1
    this.element.style.display = 'block'
    document.body.classList.add('ui-dialog-open')
    this.dialogOpenStatus = true
    this.dialogBoxService.setZIndex(this.zIndex)
  }

  // close dialog
  close(): void {
    setTimeout(() => {
      this.element.style.display = 'none'
      document.body.classList.remove('ui-dialog-open')
      this.dialogOpenStatus = false
    }, 0)
  }

  closeDialog(): void {
    this.clicks.next({ id: DialogButtonName.CLOSE });
    /* this.buttonClick({
      id: DialogButtonName.CLOSE
    }), */
  }

  openBeforeClosePopup() {
    if (this.configuration.discardConfiguration) {
      this.defaultBeforeCloseConfiguration = {
        ...this.defaultBeforeCloseConfiguration,
        ...this.configuration.discardConfiguration,
      }
    }
    this.defaultBeforeCloseConfiguration.parentId = this.configuration.id
    try {
      delete this.defaultBeforeCloseConfiguration.beforeClose;
      delete this.defaultBeforeCloseConfiguration.template;
      delete this.defaultBeforeCloseConfiguration.discardConfiguration
    } catch (e) { }
    setTimeout(() => {
      this.dialogBoxService.open(this.defaultBeforeCloseConfiguration.id)
    }, 0);
  }

  closeDialogById(buttonObject) {
    this.dialogClick.emit(buttonObject)
    this.dialogBoxService.close(this.configuration.id)
  }


  dialogButtonClick(event) {
    this.clicks.next(event);
  }

  buttonClick(button): void | boolean {
    if (!button) {
      return false
    }
    this.dialogBoxService.selectedButton = button;
    if (this.configuration.parentId && button.primary) {
      this.dialogBoxService.close(this.configuration.id)
      button.childId = this.configuration.id
      this.dialogClick.emit(button)
    } else {
      const buttonObject = {
        id: this.configuration.id,
        button,
      }
      if (this.configuration.parentId) {
        buttonObject['childId'] = this.configuration.id
        this.closeDialogById(buttonObject)
        return true;
      }
      if (button.childId && !button.primary) {
        this.dialogBoxService.close(button.childId)
        return true;
      }

      if (this.configuration.beforeClose) {
        if (button.primary) {
          if (button.childId) {
            buttonObject.button = {
              id: DialogButtonName.CLOSE,
            }
            this.closeDialogById(buttonObject)
            return true
          }
          if (this.configuration.beforeClose(button)) {
            if (button.callback) {
              if (button.callback()) {
                this.closeDialogById(buttonObject)
              }
            } else {
              this.closeDialogById(buttonObject)
            }
          }
        } else {
          if (!button.callback) {
            if (this.configuration.beforeClose()) {
              this.openBeforeClosePopup()
            } else {
              this.closeDialogById(buttonObject)
            }
          } else {
            button.callback()
          }
        }
      } else {
        this.closeDialogById(buttonObject)
      }
    }
  }
}
