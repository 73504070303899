import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { TimeSelection } from './shared/time-selection.model';
import { TimeSelectionService } from './shared/time-selection.service';
import { TimeSelectionDataService } from 'app/modules/core/time-selection/shared/time-selectiondata.service';
import { BaseService } from 'app/modules/common/base.service';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { Subscription, SubscriptionLike } from 'rxjs';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { runInThisContext } from 'vm';

@AutoUnsubscribe()
@Component({
  selector: 'time-selection',
  templateUrl: 'time-selection.component.html',
  styleUrls: ['./time-selection.component.sass'],
  providers: [TimeSelectionService]
})
export class TimeSelectionComponent implements OnInit,OnDestroy {
  public timeSelection: TimeSelection[] = [];
  public AllObject: any;
  public AllQuarterObject: any;
  public timePeriodYears: any;
  public timePeriodYearsArray: any[] = [];
  public timePeriodYearsLength: any;
  public timePeriodYearsObject: any;
  public currentYear: any;
  public currentYearIndex: any;
  public displayYears: any[] = [];
  public hides = true;
  public firstLoad = false;
  public title: string;
  public option = false;
  public options: object[] = [];
  public hide = false;
  public val= 'Months';
  public viewType= 'Quarters';
  public timePeriodSelection: any;
  public currentDisplayYear: any;
  routeSubscription$:Subscription;
  public subscription: SubscriptionLike;
  timePeriodChanged: boolean;
  showNext4Quarters: boolean;
  constructor(
    private timeSelectionService: TimeSelectionService,
    private scopeDataService: ScopeDataService,
    private timeSelectionDataService: TimeSelectionDataService,
    private baseService: BaseService,
    private router: Router,
    private dataStorageService: DataStorageService,
  ) {
    this.timeSelectionDataService.getTimePeriodObject().subscribe(data => {
      if (data !== undefined) {
        this.timePeriodYears = this.timeSelectionDataService.getTimePeriod();
        this.timePeriodYearsArray = Object.keys(this.timePeriodYears);
        this.currentYear = this.timeSelectionDataService.getCurrentYear();
        this.options = this.timeSelectionDataService.getOptions();

        this.currentYearIndex = this.timePeriodYearsArray.indexOf(
          this.currentYear.toString()
        );
        if (this.currentYearIndex === -1) {
          return;
        }
        this.timePeriodYearsLength = this.timePeriodYearsArray.length - 1;
      }
    });

    this.baseService.geturlarrray().subscribe(val => {
      if (val instanceof Array) {
        if (val.length > 0) {
          if (val !== undefined) {
            if (
              val.includes('cost') ||
              val.includes('spend') ||
              (val.length === 3 && val[val.length - 1] === 'spendanalytics')
            ) {
              if (this.scopeDataService.gettype() !== 'chatbot') {
                if (this.viewType === 'Months') {
                  this.onClearAll();
                  this.onSelectDefaultYear();
                  // this.onUpdateMonthBySelectedYears('Quarters');
                }
              }

              this.viewType = 'Quarters';
              this.timeSelectionDataService.updateTitle();
            }
          }
        }
      }
    });
  }

  public ngOnInit() {
    this.routeSubscription$ = this.dataStorageService.getRouteNameForRO().subscribe(val =>{
       if(val !== 'metriccard'){
        this.onSelectDefaultYear();
        this.showNext4Quarters = false;
       }
     else{
      this.showNext4Quarters = true;
      this.onSelectNext4Quarters();
    }
    })
    this.subscription = this.baseService.geturlarrray().subscribe(val => {
      if (val && val.includes('forecast')) {
        this.val = 'Months';
        this.option = true;
      } else {
        this.option = false;
      }
    });

    this.timeSelectionDataService.timePeriodYearsObject.subscribe(data => {
      if (data !== undefined) {
        this.timePeriodYearsObject = data;
        if (!this.firstLoad) {
          this.loadDefault();
        } else {
          this.dynamicLoad();
        }
        if (!this.scopeDataService.getTime()) {
          if(!this.showNext4Quarters){
          this.onSelectDefaultYear();
          }else{
            this.onSelectNext4Quarters();
          }
          this.scopeDataService.setTime(false);
        }
      }
    });
    /* this.scopeDataService.getFocus().subscribe((value) => {
			this.onSelectDefaultYear();
		}); */
    // this.baseService.getScopeAvailability().subscribe(val => {
    //   if (val === 'notavailable') {
    //     this.hide = true;
    //   } else {
    //     this.hide = false;
    //   }
    // });
    this.timeSelectionDataService.getTitle().subscribe(title => {
      if (title !== undefined) {
        this.title = title;
      }
    });
    this.timeSelectionDataService.getDataCheck().subscribe(data => {
      if (data instanceof Boolean) {
        if (data) {
          this.updateTimeSelection();
        }
      }
    });
  }
  public onTimeViewChange(val) {
    try {
      if (val === 'Quarters') {
        this.val = 'Months';
        this.onUpdateMonthBySelectedYears('Quarters');
        this.timeSelectionDataService.updateTitle();
      } else {
        this.val = 'Quarters';
        this.onUpdateMonthBySelectedYears('Months');
        this.timeSelectionDataService.updateTitle('month');
      }
      this.scopeDataService.setCalendarValue(val);
      this.viewType = val;
    } catch (error) {
      console.log('error in onTimeViewChange ' + error);
    }
  }

  public onUpdateMonthBySelectedYears(val) {
    try {
      const localTimePeriodYearObject: {} = this.timeSelectionDataService.getLocalTimePeriodYearObject();

      if (localTimePeriodYearObject instanceof Object) {
        const finalMonthObject: any[] = [];

        for (const year of Object.keys(localTimePeriodYearObject)) {
          if (localTimePeriodYearObject[year] instanceof Object) {
            if (localTimePeriodYearObject[year].year.isSelect === true) {
              const monthObject: {} = localTimePeriodYearObject[year].month;
              const quarterobj: {} = localTimePeriodYearObject[year].quarter;
              const allobject: any[] = [];
              const allquarterObject: any[] = [];
              const timePeriod = this.timePeriodYears[year][0];
              for (const quarter of Object.keys(timePeriod)) {
                if (val === 'Quarters') {
                  if (timePeriod[quarter]) {
                    const time =
                      timePeriod[quarter].qsdate !== undefined
                        ? timePeriod[quarter].qsdate
                        : undefined;
                    const quarterObject = {
                      from_time_period: time,
                      type: 'quarter'
                    };
                    finalMonthObject.push(quarterObject);
                    allquarterObject.push(quarterObject);
                    this.AllQuarterObject[year] = allquarterObject;
                    this.scopeDataService.selectedQuarterObject = this.AllQuarterObject;
                  }
                }
                if (val === 'Months') {
                  for (const year of Object.keys(
                    this.scopeDataService.selectedMonthObject
                  )) {
                    this.scopeDataService.selectedMonthObject[year] = [];
                  }
                  if (timePeriod[quarter]) {
                    if (timePeriod[quarter]['months'] !== null) {
                      const monthArray: any[] = timePeriod[quarter]['months'];
                      for (let index = 0; index < monthArray.length; index++) {
                        const timeObject = {
                          from_time_period:
                            monthArray[index].date !== undefined
                              ? monthArray[index].date
                              : undefined,
                          type: 'month'
                        };
                        finalMonthObject.push(timeObject);
                        allobject.push(timeObject);
                      }
                    }
                  }

                  this.scopeDataService.selectedMonthObject[year] = [];
                  this.AllObject[year] = allobject;
                }
              }

              for (const month of Object.keys(monthObject)) {
                localTimePeriodYearObject[year].month[month].isSelect = false;
              }
              for (const quarter of Object.keys(quarterobj)) {
                localTimePeriodYearObject[year].quarter[
                  quarter
                ].isSelect = false;
              }
            }
          }
        }

        this.scopeDataService.allObj = this.AllObject;
        this.scopeDataService.onMonthBulkUpdate(finalMonthObject);

        // this.timeSelectionDataService.updateBulkMonthUpdate(localTimePeriodYearObject,this.timePeriodYears);
      }
    } catch (error) {
      console.log('error in onUpdateMonthBySelectedYears ' + error);
    }
  }

  public dynamicLoad() {
    try {
      this.currentYear = this.timeSelectionDataService.getBotUpdatedYear();
      this.currentYearIndex = this.timePeriodYearsArray.indexOf(
        String(this.currentYear)
      );
      if (this.currentYearIndex === -1) {
        this.onClearAll();
        return;
      }
      this.loadDefault();
    } catch (error) {
      console.log('error in dynamicLoad ' + error);
    }
  }

  public updateTimeSelection() {
    try {
      this.timePeriodYears = this.timeSelectionDataService.getTimePeriod();
      if (this.timePeriodYears !== undefined) {
        this.timePeriodYearsArray = Object.keys(this.timePeriodYears);
      }
      this.currentYear = this.timeSelectionDataService.getCurrentYear();
      this.options = this.timeSelectionDataService.getOptions();
      this.currentYearIndex = this.timePeriodYearsArray.indexOf(
        String(this.currentYear)
      );
      if (this.currentYearIndex === -1) {
        return;
      }
      this.timePeriodYearsLength = this.timePeriodYearsArray.length - 1;
      /* console.log(this.firstLoad );
			if (this.firstLoad === false) {
				this.loadDefault();
			} */

      this.loadDefault();
    } catch (error) {
      console.log('error in updateTimeSelection ' + error);
    }
  }

  public loadDefault() {
    try {
      this.firstLoad = true;
      if (this.currentYearIndex === this.timePeriodYearsLength) {
        if(this.timePeriodYearsLength === 0){
          this.currentDisplayYear =  this.currentYearIndex;
        }else{
          this.currentDisplayYear = this.currentYearIndex - 1;
        }
      } else {
        this.currentDisplayYear = this.currentYearIndex;
      }
      if (this.timePeriodYearsLength !== 0) {
        if(this.currentDisplayYear ===-1)
        {
          this.currentDisplayYear=0;
        }
        this.displayYears = [
          this.timePeriodYearsArray[this.currentDisplayYear],
          (
            Number(this.timePeriodYearsArray[this.currentDisplayYear]) + 1
          ).toString()
        ];
      }else{
        this.displayYears = [
          this.timePeriodYearsArray[this.currentDisplayYear]]
      }
    } catch (error) {
      console.log('error in loadDefault ' + error);
    }
  }
  quarterChanged(event){
if(event){
  this.timePeriodChanged = true;
}
  }
  public onSelectDefaultYear() {
    try {
      if (this.timePeriodYearsObject !== undefined) {
        this.scopeDataService.clearSelectedQuarterObject();
        Object.keys(this.timePeriodYearsObject).forEach((year) => {
          this.timePeriodYearsObject[year].year.isSelect = false;
          for(const quarter in this.timePeriodYearsObject[year].quarter){
            if(quarter){
            this.timePeriodYearsObject[year]['quarter'][quarter].isSelect = false;
            }
          }
        })
        const defaultTimeObject: any = {
          yearObject: {},
          timePeriod: []
        };
        const currentYear =
          this.timeSelectionDataService.getCurrentYear() !== undefined
            ? this.timeSelectionDataService.getCurrentYear()
            : undefined;
        const routeURLArray: Array<string> = this.router['url'].split('/');
        const laststring = routeURLArray[routeURLArray.length - 1];
        // this.timeSelectionDataService.getCurrentQuarter().quarter = 'Q4'
        if (this.timeSelectionDataService.getCurrentQuarter().quarter === 'Q4' && laststring === 'insights') {
          const currentYearObject: {} = this.timeSelectionDataService.getLocalTimePeriodYearObject()[
            currentYear + 1
          ];
          const currentYearTimePeriod: any[] = this.timePeriodYears[currentYear + 1];
          if (currentYearObject instanceof Object) {
            defaultTimeObject.yearObject = currentYearObject;
          }

          if (currentYearTimePeriod instanceof Array) {
            defaultTimeObject.timePeriod = currentYearTimePeriod;
          }
          // year Selecting
          defaultTimeObject.yearObject['year'].isSelect = true;
          this.scopeDataService.updateYearSelection(defaultTimeObject, 'Quarters');
        } else {
          const currentYearObject: {} = this.timeSelectionDataService.getLocalTimePeriodYearObject()[currentYear]
          const currentYearTimePeriod: any[] = this.timePeriodYears[currentYear];
          if (currentYearObject !== undefined && currentYearTimePeriod !== undefined) {
            if (currentYearObject instanceof Object) {
              defaultTimeObject.yearObject = currentYearObject;
            }
            if (currentYearTimePeriod instanceof Array) {
              defaultTimeObject.timePeriod = currentYearTimePeriod;
            }
            // year Selecting
            defaultTimeObject.yearObject['year'].isSelect = true;
            this.scopeDataService.updateYearSelection(defaultTimeObject, 'Quarters');
          }
else
{
  this.onClearAll();
}
        }
        // Quarter  selecting
        // defaultTimeObject.yearObject.quarter[currentQuarter].isSelect = true;
        // this.timeSelectionDataService.updateTitle();
      }

    } catch (error) {
      console.log('error in onSelectDefaultYear ' + error);
    }
  }

  public onSelectNext4Quarters(){
    try {
      if (this.timePeriodYearsObject !== undefined) {
        Object.keys(this.timePeriodYearsObject).forEach((year) => {
          this.timePeriodYearsObject[year].year.isSelect = false;
          for(const quarter in this.timePeriodYearsObject[year].quarter){
            if(quarter){
            this.timePeriodYearsObject[year]['quarter'][quarter].isSelect = false;
            }
          }
        })
        const defaultTimeObject: any = {
          yearObject: {},
          timePeriod: []
        };
        const next4QuartersObj = this.timeSelectionDataService.getFullTimePeriodObject().next4Quarters;
        for(const year in next4QuartersObj){
          if(year){
          const defaultTimePeriodObject: any = {
            yearObject: {},
            timePeriod: []
          };
          const currentYearObject: {} = this.timeSelectionDataService.getLocalTimePeriodYearObject()[year]
          const currentYearTimePeriod: any[] = this.timePeriodYears[year];
          if (currentYearObject !== undefined && currentYearTimePeriod !== undefined) {
            if (currentYearObject instanceof Object) {
              for(const quarter of this.timeSelectionDataService.getFullTimePeriodObject().next4Quarters[year]){
                currentYearObject['quarter'][quarter].isSelect = true;
              }
              defaultTimePeriodObject.yearObject = currentYearObject;
            }
            if (currentYearTimePeriod instanceof Array) {
              defaultTimePeriodObject.timePeriod = currentYearTimePeriod;
            }
            // year Selecting
            defaultTimePeriodObject.yearObject['year'].isSelect = true;
            this.scopeDataService.updateYearSelection(defaultTimePeriodObject, 'Quarters');
        }
        }

      }
    }
  } catch(e){
    console.log(e)
  }
}

  public onPrevious() {
    try {
      this.currentDisplayYear = this.currentDisplayYear - 1;

      if (this.currentDisplayYear >= 0) {
      } else {
        this.currentDisplayYear++;
      }
      this.displayYears = [
        this.timePeriodYearsArray[this.currentDisplayYear],
        (
          Number(this.timePeriodYearsArray[this.currentDisplayYear]) + 1
        ).toString()
      ];
    } catch (error) {
      console.log('error in onPrevious  ' + error);
    }
  }
  public onNext() {
    try {
      this.currentDisplayYear = this.currentDisplayYear + 1;
      if (this.currentDisplayYear >= 0) {
      } else {
        this.currentDisplayYear--;
      }
      this.displayYears = [
        this.timePeriodYearsArray[this.currentDisplayYear],
        (
          Number(this.timePeriodYearsArray[this.currentDisplayYear]) + 1
        ).toString()
      ];
    } catch (error) {
      console.log('error in onNext  ' + error);
    }
  }
  public onOptionSelection(option) {
    try {
      this.timeSelectionDataService.setOptionSelected(option);
    } catch (error) {
      console.log('error in onOptionSelection ' + error);
    }
  }
  public onClearAll() {
    try {
      this.timeSelectionDataService.clearLocalTimePeriodYearsObject();
      this.timeSelectionDataService.setClearAll('clear');
      this.scopeDataService.setTime(false);
      this.scopeDataService.clearTimeSelectionData();
    } catch (error) {
      console.log('error in onClearAll ' + error);
    }
  }

  public ngOnDestroy() {
    this.scopeDataService.setTime(false);
  }
}
