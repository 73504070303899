import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

import { Collaborations } from './collaborations.model';
import { ApiServiceProvider } from 'app/api-service';

@Injectable()
export class CollaborationsService {
  constructor(
    private http: HttpClient,
    private apiService: ApiServiceProvider
  ) {}

  getExistingCollab(input) {
    let payload;
    if (input.FE_ID === 'NONE') {
      payload = {
        targetedType: 'Collaboration',
        method: 'GET',
        servicePath: 'usercollaboration'
      };
    } else {
      payload = {
        targetedType: 'Collaboration',
        formParams: {
          feId: input.FE_ID
        },
        method: 'GET',
        servicePath: 'fecollaboration'
      };
    }
    return this.apiService.post(payload);
  }
  deleteCollab(input) {
    const payload = {
      targetedType: 'Collaboration',
      formParams: {
        collaborationId: input
      },
      method: 'PUT',
      servicePath: 'collaboration'
    };

    return this.apiService.post(payload);
  }
  getFilteredCollab(input) {
    const inp = {
      targetedType: 'Collaboration',
      payload: input,
      method: 'POST',
      servicePath: 'collaborations'
    };
    return this.apiService.post(inp);
  }
  putTask(input, data, action) {
    const payload = {
      targetedType: 'Collaboration',
      formParams: {
        action,
        actionBy: input.userEmail,
        collaborationId: data.collaborationSummary.collaborationId
      },
      method: 'PUT',
      servicePath: 'task'
    };
    return this.apiService.post(payload);
  }
  getMetaTypes() {
    const payload = {
      targetedType: 'Collaboration',
      formParams: {},
      method: 'GET',
      servicePath: 'type'
    };

    return this.apiService.post(payload);
  }
  getType2Actions(input) {
    const payload = {
      targetedType: 'Collaboration',
      formParams: {
        collaborationType: input
      },
      method: 'GET',
      servicePath: 'action'
    };

    return this.apiService.post(payload);
  }
  getType3Actions(input) {
    return this.apiService.post(input);
  }
  getTenantUsers() {
    const payload: any = {
      targetedType: 'Collaboration',
      formParams: {
        personaType: 'USER'
      },
      method: 'GET',
      servicePath: 'userorgroup'
    };
    return this.apiService.post(payload);
  }
}
