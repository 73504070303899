export const CUSTOMER_SESSION_EXPIRED = 'customer_isSessionExpired';
export const CUSTOMER_NAVIGATE_ROUTE_EXISTS = 'customer_isNavigateRouteExists';
export const CUSTOMER_TOKEN_INFO = 'customer_tokenInfo';
export const CUSTOMER_LOGIN_RESPONSE = 'customer_loginResponse';
export const CUSTOMER_CURRENT_USER = 'customer_currentUser';
export const CUSTOMER_CUSTOMER_DATA = 'customer_customerdata';
export const CUSTOMER_TOKEN_EXPIRY = 'customer_tokenExpiry';
export const CUSTOMER_FOCUS = 'customer_focus';
export const CUSTOMER_ENCRYPTED_STRING_TASK_ID = 'customer_encryptedStringTaskId';
export const CUSTOMER_USER_GROUP = 'customer_usergroup';
export const CUSTOMER_CELLS = 'customer_cells';
export const CUSTOMER_AUDIT_ID = 'customer_auditId';
export const CUSTOMER_AUDIT_ID_COPY = 'customer_auditIdCopy';
export const CUSTOMER_IS_SUPER_USER = 'customer_isSuperUser';
export const VENDOR_NAME = 'vendorName';
export const IS_PDM_ORIGIN = 'isPdmOrigin';
export const CUSTOMER_REVISION_SELECTED = 'customer_RevisionSelected';
export const CUSTOMER_PROGRAM_SELECTED = 'customer_ProgramSelected';
export const GRID_VIEW_TYPE = 'gridViewType';
export const CUSTOMER_COLUMN_CONFIGURATIONS = 'customer_columnConfigurations';
export const CUSTOMER_CS_APP = 'customer_csApp';
export const CUSTOMER_EMAIL = 'customer_email';
export const CUSTOMER_PASSWORD_EXPIRY_INFO = 'customer_PasswordExpiryInfo';
export const CLICK_RFQ = 'ClickRFQ';
export const PROGRAM_CONFIG = 'programConfig';


export class SharedConsts {


}
