
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { BehaviorSubject } from 'rxjs';
import { ApiServiceProvider } from '../../../../api-service';
import { BaseService } from '../../../common/base.service';
import { CustomerConfigurationService } from '../../../common/customerConfigurationService';

@Injectable()
export class ScopeService {

    constructor(private apiService: ApiServiceProvider, private baseService: BaseService, private customerConfigurationService: CustomerConfigurationService) {

    }

    clickOnList = new BehaviorSubject<any>(undefined);
    autosearchdata = new BehaviorSubject<any>('');
    clear = new BehaviorSubject<any>(undefined);
    payLoadJSON: object = {
        targetedType: 'ELS',
        payload: {
            dimensionName: undefined,
        },
        method: 'POST',
        servicePath: 'getDimensionData'
    };

    getDimensionData(target) {
        this.payLoadJSON['payload']['dimensionName'] = target.trim();
        return this.apiService.post(this.payLoadJSON);
    }
    getGlobalSearchData(globalInput: string) {
        this.payLoadJSON['payload']['inputs'] = [globalInput];
        this.payLoadJSON['payload']['isWildCardSearch'] = true;
        this.payLoadJSON['servicePath'] = 'MultiValueSearch';
        this.payLoadJSON['method'] = 'POST';
        return this.apiService.post(this.payLoadJSON);
    }
    setClickOnList(val) {
        this.clickOnList.next(val);
    }
    getClickOnList() {
        return this.clickOnList.asObservable();
    }
    setautosearchdata(val) {
        this.autosearchdata.next(val);
    }
    getclearall() {
        return this.clear.asObservable();
    }
    setclearall(val) {
        this.clear.next(val);
    }
    getautosearchdata() {

        return this.autosearchdata.asObservable();
    }

    getTimePeriod() {
        const object: any = {
            targetedType: 'CMS',
            formParams: {
            },
            method: 'GET',
            servicePath: 'timeselection'
        };
        this.apiService.post(object);
    }

    /**
     * Manage Scope Filter
     * Start
     */

    getUserScopeFilters(scope: string, filterType: string) {
        const userEmail = this.baseService.getUserInformation().email;
        const object: any = {
            targetedType: 'UserService',
            method: 'GET',
            servicePath: `/${scope}/${userEmail}/${filterType}/scopefilters`,
        }
        return this.apiService.post(object);
    }

    deleteUserScopeFilter(scopeFilterId: number) {
        const object: any = {
            targetedType: 'UserService',
            method: 'DELETE',
            servicePath: `/${scopeFilterId}/deletescopefilter`,
        }
        return this.apiService.post(object);
    }

    updateUserScopeFilter(scopeFilterId: number, scopeFilterName: string, scopeFilterObject: Object) {
        const object: any = {
            targetedType: 'UserService',
            method: 'POST',
            payload: JSON.stringify(scopeFilterObject),
            servicePath: `/${scopeFilterId}/${scopeFilterName}/updatescopefilter`,
        }
        return this.apiService.post(object);
    }

    createUserScopeFilter(scope: string, filterType: string, scopeFilterName: string, scopeFilterObject: Object) {
        const userEmail = this.baseService.getUserInformation().email;
        const tenantId = this.customerConfigurationService.getEnvironmentDetails().tenantId;
        const object: any = {
            targetedType: 'UserService',
            method: 'POST',
            payload: JSON.stringify(scopeFilterObject),
            servicePath: `/${scope}/${userEmail}/${tenantId}/${filterType}/${scopeFilterName}/createscopefilter`,
        }
        return this.apiService.post(object);
    }

    /**
     * Manage Scope Filter
     * End
     */

}
