import { cloneDeep } from 'lodash'
import { Component, Input, OnInit } from '@angular/core'
import { PayLoad } from 'app/modules/common/models/payload.model'
import { RequestData } from 'app/modules/common/models/request.model'
import { LevaQuickFilterService } from '../../leva-quick-filter.service'
import { EACH_ITEM } from '../configuration'
import { Options } from 'ng5-slider';
import moment from 'moment';
import { FILTERCONDITIONS } from '../filter.constants';
import { DateUtils } from 'app/modules/common/utills/DateUtils.service';

@Component({
  selector: 'app-each-filter',
  templateUrl: './each-filter.component.html',
  styleUrls: ['./each-filter.component.sass'],
})
export class EachFilterComponent implements OnInit {
  openTextValue = null;
  dimensionListConfiguration
  openOptions = false
  openType = ''
  request: any
  filterGroupId = ''
  filterHeaderId = ''
  filterMetaInfo = {};
  lowValue: any;
  highValue: any;
  selectedValue = null;
  public resetVal
  filters = {
    label: 'Select Dimension',
    type: 'static',
    val: 'more-filters',
    id: 'morefilters',
    hideClearSelection: true,
    filterConfig: {
      multiSelect: false,
    },
    defaultSelectedView: {
      displayName: '',
      val: '',
      selected: true,
    },
    hideTooltip: true,
    hideClearItem: true,
    data: [],
    specialVersion: true,
    isDisable: false,
    show: false,
    isSelectAll: false,
  }
  filterValue: any = {
    label: 'filter',
    type: 'newautosearch',
    filterConfig: {
      multiSelect: false,
      targetedType: '',
      servicePath: '',
      defaultItemSelection: false,
      defaultSelectedItem: null,
    },
    hideLabel: true,
    val: 'filter',
    data: [],
    specialVersion: true,
    id: `filter-auto-select`,
    defaultParameter: null,
    isDisable: false,
    reset: this.generateRandomKey()
  }
  conditionConfiguration = {
    type: 'static',
    hideLabel: true,
    val: 'val',
    hideClearSelection: true,
    id: `condition-conf`,
    defaultItemSelection: true,
    defaultSelectedItem: '',
    data: [
      {
        val: 'true',
        name: 'True',
        displayName: 'True'
      },
      {
        val: 'false',
        name: 'False',
        displayName: 'False'
      },
    ],
    reset: this.generateRandomKey()
  }
  options : Options
  maxDate: string;

  rangeFilters = {
    sliderOptions: {
      floor: 0,
      ceil: 0,
      translate: (value) => {
        return `${window['currencySymbol']}${value.toLocaleString()}`
      },
    },
    expand: false,
    resetSlider: null,
  }
  selectType = ''
  filterConditions
  @Input() submitted: boolean
  @Input() configuration
  constructor(public levaQuickFilterService: LevaQuickFilterService) {}
  ngOnInit() {
    this.filterConditions = FILTERCONDITIONS
    this.dimensionListConfiguration = {
      label: 'dirupted',
      type: 'static',
      hideLabel: true,
      val: 'False',
      id: `filter-${this.configuration.headerName}`,
      data: [
        {
          val: true,
          displayName: 'True',
        },
        {
          val: false,
          displayName: 'False',
        },
      ],
    }
    this.options = this.getRangeOption(0, 0, this.configuration.metaInfo.fieldName);
    this.lowValue = this.rangeFilters.sliderOptions.floor;
    this.highValue = this.rangeFilters.sliderOptions.ceil;

    if (this.configuration.headerName !== '') {
      this.filters.defaultSelectedView.displayName = this.configuration.metaInfo.displayName
      this.filters.defaultSelectedView.val = this.configuration.metaInfo.fieldName
      this.filters.defaultSelectedView.selected = true
      this.selectType = this.configuration.metaInfo.type
    }
    if (this.selectType === 'boolean') {
      this.configuration.isSelected = true
    }
    this.levaQuickFilterService.getKeyInfo().subscribe((val) => {
      if (val) {
        this.filters.data = []
        if (this.configuration.selectedArea === 'inc') {
          this.levaQuickFilterService.includeKeyslist.forEach((ele) => {
            if (!ele.selected) {
              this.filters.data.push({
                displayName: ele.metaInfo.displayName,
                val: ele.metaInfo.fieldName,
                keyInfo: ele.metaInfo.type,
              })
            }
          })
        } else {
          this.levaQuickFilterService.excludeKeyslist.forEach((ele) => {
            if (!ele.selected) {
              this.filters.data.push({
                displayName: ele.metaInfo.displayName,
                val: ele.metaInfo.fieldName,
                keyInfo: ele.metaInfo.type,
              })
            }
          })
        }
        /*  this.levaQuickFilterService.keyslist.forEach((ele) => {
          if (!ele.selected) {
            this.filters.data.push({
              displayName: ele.key.toString().toUpperCase(),
              val: ele.key,
              keyInfo: ele.info.valueType,
            })
          }
        }) */
      }
    })
  }
  autoSearchRequest() {
    const payload = new PayLoad()
    payload.isWildCardSearch = true
    this.request = new RequestData()
    this.request.searchCustomization = null
    this.request.targetedType = this.configuration.metaInfo.targetType || this.levaQuickFilterService.moduleConfig.targetedType
    this.request.method = 'GET'
    this.request.inputHeight = '24px'
    if (this.openType === 'singleselect') {
      this.request.typeOfSelectSingle = true
    }
    this.request.servicePath = this.levaQuickFilterService.moduleConfig.moduleName==='NPA'?'ContentManagementService/pwb/attribute/search': this.configuration.metaInfo.servicePath
    this.request.type = 'multi'
    this.request.payload = payload
    this.request.unmatchedSearch = false
    this.request.restrictedVals = ['_NA']
    this.request.defaultSelected = this.configuration.filterValues
    this.request.Placeholder = 'Enter value'
    this.request.target=this.configuration.headerName.toLowerCase()
    this.request.formParams = {
      dimension: this.configuration.metaInfo.fieldName
    }
    if (this.configuration.metaInfo.values && this.configuration.metaInfo.values.length > 0) {
      this.request.options = this.configuration.metaInfo.values
      this.request.selectAll = false
    }
  }
  onAutoSelect($event) {
    if(!(this.request.defaultSelected.length>0))
    {
      this.configuration.filterValues = []
    }
    if ($event.length) {
      this.configuration.filterValues = []
      this.configuration.filterGroupId = this.filterGroupId
      this.configuration.filterHeaderId = this.filterHeaderId
      $event.forEach((element) => {
        if (element instanceof Object) {
          this.configuration.filterValues.push(
            element.value.replace(/^"(.+(?="$))"$/, '$1')
          )
        } else {
          this.configuration.filterValues.push(
            element.replace(/^"(.+(?="$))"$/, '$1')
          )
        }
      })
    }
    this.configuration.isSelected=this.configuration.filterValues.length>0?true:false
    this.levaQuickFilterService.checkSaveValidation()
  }

  setFilterValues(val1, val2 = null) {
    this.configuration.filterValues = []
    this.configuration.filterGroupId = this.filterGroupId
    this.configuration.filterHeaderId = this.filterHeaderId
    this.configuration.filterValues.push(val1)
    if(val2) {
      this.configuration.filterValues.push(val2)
    }
  }
  onFilterConditionSelect($event) {
    if (this.configuration.metaInfo.type === 'date' && $event.selectedItem.val !== 'In Range') {
      this.highValue = 0
    }
  }
  onFilterValueSelect(event) {
    if (event.selectedItems) {
      this.configuration.isSelected= true
      this.configuration.isSelected= !!event.selectedItems.length
      this.configuration.filterValues = event.selectedItems
      this.configuration.filterGroupId = this.filterGroupId
      this.configuration.filterHeaderId = this.filterHeaderId
    } else if (event.selectedItem) {
      this.configuration.isSelected= true
      this.configuration.filterValues = [event.selectedItem.val]
      this.configuration.filterGroupId = this.filterGroupId
      this.configuration.filterHeaderId = this.filterHeaderId
    } else {
      this.configuration.isSelected= false
    }
    this.levaQuickFilterService.checkSaveValidation()
  }
  onFilterSelect($event) {
    if ($event.selectedItem.displayName !== '') {
      this.filters.isDisable = true
      this.openOptions = true
      if (this.configuration.headerName !== '') {
        if (this.configuration.filterType) {
          this.conditionConfiguration.data = this.filterConditions[this.configuration.metaInfo.type]
          this.conditionConfiguration.defaultSelectedItem = this.configuration.filterType
        }
        /* if (this.configuration.selectedArea === 'inc') {
        this.levaQuickFilterService.includeKeyslist.forEach((ele) => {
          if (ele.metaInfo.fieldName === this.configuration.headerName) {
            ele.selected = false
          }
        })

        this.configuration.headerName = $event.selectedItem.val
        this.levaQuickFilterService.includeKeyslist.forEach((ele) => {
          if (ele.key === $event.selectedItem.val) {
            ele.selected = true
          }
        })
      } else {
        this.levaQuickFilterService.excludeKeyslist.forEach((ele) => {
          if (ele.metaInfo.fieldName === this.configuration.headerName) {
            ele.selected = false
          }
        })

        this.configuration.headerName = $event.selectedItem.val
        this.levaQuickFilterService.excludeKeyslist.forEach((ele) => {
          if (ele.key === $event.selectedItem.val) {
            ele.selected = true
          }
        })
      } */
      } else {
        this.configuration.headerName = $event.selectedItem.val
        if (this.configuration.selectedArea === 'inc') {
          this.levaQuickFilterService.includeKeyslist.forEach((ele) => {
            if (ele.metaInfo.fieldName === this.configuration.headerName) {
              this.filterGroupId = ele.filterGroupId
              this.filterHeaderId = ele.filterHeaderId
              this.configuration.metaInfo = ele.metaInfo
              ele.selected = true
              this.conditionConfiguration.reset = this.generateRandomKey()
              this.conditionConfiguration.data = this.filterConditions[this.configuration.metaInfo.type]
              this.conditionConfiguration.defaultSelectedItem = this.filterConditions[this.configuration.metaInfo.type] && this.filterConditions[this.configuration.metaInfo.type][0].val
              this.configuration.filterType = this.filterConditions[this.configuration.metaInfo.type] && this.filterConditions[this.configuration.metaInfo.type][0].val
            }
          })
        } else {
          this.levaQuickFilterService.excludeKeyslist.forEach((ele) => {
            if (ele.metaInfo.fieldName === this.configuration.headerName) {
              this.filterGroupId = ele.filterGroupId
              this.filterHeaderId = ele.filterHeaderId
              this.configuration.metaInfo = ele.metaInfo
              ele.selected = true
            }
          })
        }
      }
      this.filterValue = {
        label: 'filter',
        type: this.configuration.metaInfo.type,
        filterConfig: {
          multiSelect: !!this.configuration.metaInfo.multiSelect,
          targetedType: this.configuration.metaInfo.targetType || this.levaQuickFilterService.moduleConfig.targetedType,
          servicePath:  this.levaQuickFilterService.moduleConfig.moduleName==='NPA'?'ContentManagementService/pwb/attribute/search': this.configuration.metaInfo.servicePath,
          defaultItemSelection: this.configuration.filterValues.length ? true : false,
          defaultSelectedItem: this.configuration.metaInfo.type === 'static' ? this.configuration.filterValues && this.configuration.filterValues[0] : this.configuration.filterValues,
        },
        hideLabel: true,
        val: 'filter',
        data: [],
        specialVersion: false,
        id: `filter-auto-select`,
        defaultParameter: {
          dimension: this.configuration.metaInfo.fieldName,
          ...this.configuration.metaInfo.defaultParameter
        },
        isDisable: false,
        reset: this.generateRandomKey()
      }
      if (this.configuration.metaInfo.type === 'static') {
        this.filterValue.defaultItemSelection = this.configuration.filterValues ? true : false
        this.filterValue.defaultSelectedItem = this.configuration.filterValues && this.configuration.filterValues[0]
      }
      this.openType = $event.selectedItem.keyInfo
      if (this.selectType !== '') {
        this.openType = this.selectType
      }

      if (this.configuration && this.configuration.filterValues && this.configuration.filterValues.length !== 0) {
        this.filterGroupId = this.configuration.filterGroupId
        this.filterHeaderId = this.configuration.filterHeaderId
        this.configuration.isSelected = true
      }
      switch(this.openType) {
        case 'range':
          let lowRange = -1
          let highRange = -1
          if (
            this.configuration.filterValues &&
            this.configuration.filterValues.length !== 0
          ) {
            lowRange = this.configuration.filterValues[0]
            highRange = this.configuration.filterValues[1]
          }

          if (this.configuration && this.configuration.metaInfo && this.configuration.metaInfo.values) {
            this.options = this.getRangeOption(this.configuration.metaInfo.values[0], this.configuration.metaInfo.values[1], this.configuration.metaInfo.fieldName);
            this.lowValue = lowRange === -1 ? this.configuration.metaInfo.values[0] : lowRange;
            this.highValue = highRange === -1 ? this.configuration.metaInfo.values[1] : highRange
          } else {
            this.levaQuickFilterService
              .getRangeDimensionLimit(`ContentManagementService/pwb${this.configuration.metaInfo.servicePath}`, this.configuration.metaInfo.fieldName)
              .subscribe((data) => {
                if (data && data.responseStatus.code === 200) {
                  this.options = this.getRangeOption(data.result.min, data.result.max, this.configuration.metaInfo.fieldName);
                  this.lowValue = lowRange === -1 ? data.result.min : lowRange;
                  this.highValue = highRange === -1 ? data.result.max : highRange
                }
              });
          }
          if (this.configuration && this.configuration.filterValues && this.configuration.filterValues.length !== 0) {
            this.lowValue = this.configuration.filterValues[0]
            this.highValue = this.configuration.filterValues[1]
          }
          break
          case 'boolean':
            if (
              this.configuration &&
              this.configuration.filterValues &&
              this.configuration.filterValues.length !== 0
            ) {
              this.selectedValue = this.configuration.filterValues[0] === 'true' ? 'True' : 'False'
            }
            break
        case 'textmatch':
        case 'text':
          if (this.configuration && this.configuration.filterValues && this.configuration.filterValues.length !== 0) {
            this.openTextValue = this.configuration.filterValues[0]
          }
          break
        case 'singleselect':
          this.autoSearchRequest()
          break
        case 'multiselect':
          this.autoSearchRequest()
          break
        case 'date':
          if (
            this.configuration.filterValues &&
            this.configuration.filterValues.length !== 0
          ) {
            this.lowValue = moment(Number(this.configuration.filterValues[0] * 1000) ).format('YYYY-MM-DD')
            this.highValue = this.configuration.filterValues[1] ? moment(Number(this.configuration.filterValues[1] * 1000)).format('YYYY-MM-DD') : null
          }
          break
        default:
          break
      }
    }
  }
  getRangeOption(floor, ceil, field) {
    const opts: Options = {
      floor,
      ceil,
      disabled: ceil === 0,
      translate: (value) => {
        if (field === 'mpn_cost' || field === 'cpn_cost') {
          return `${window['currencySymbol']}${value.toLocaleString()}`
        }
        return `${value.toLocaleString()}`
      },
    };
    return opts;
  }

  addNew() {
    this.levaQuickFilterService.enableSave=false
    EACH_ITEM.selectedArea = this.configuration.selectedArea
    if (this.configuration.selectedArea === 'inc') {
      EACH_ITEM.index =
        this.configuration.selectedArea +
        '-' + (this.levaQuickFilterService.filtersList.include.length + 1 )
      this.levaQuickFilterService.incCount =
        this.levaQuickFilterService.incCount + 1
      this.levaQuickFilterService.incIndex =
        this.levaQuickFilterService.incIndex + 1
      this.levaQuickFilterService.lastIncKey = EACH_ITEM.index
      this.levaQuickFilterService.filtersList.include.push(cloneDeep(EACH_ITEM))
    } else {
      EACH_ITEM.index =
        this.configuration.selectedArea +
        '-' + (this.levaQuickFilterService.filtersList.exclude.length + 1)
      this.levaQuickFilterService.excCount =
        this.levaQuickFilterService.excCount + 1
      this.levaQuickFilterService.excIndex =
        this.levaQuickFilterService.excIndex + 1
      this.levaQuickFilterService.lastExcKey = EACH_ITEM.index
      this.levaQuickFilterService.filtersList.exclude.push(cloneDeep(EACH_ITEM))
    }
  }

  onDelete() {
    if (this.configuration.selectedArea === 'inc') {
      this.levaQuickFilterService.filtersList.include.splice(
        this.levaQuickFilterService.filtersList.include.indexOf(
          this.configuration
        ),
        1
      )
      this.levaQuickFilterService.filtersList.include.forEach((element,index) => {
        element.index = 'inc' + (index + 1)
        if((index+1)===this.levaQuickFilterService.filtersList.include.length)
        {
          this.levaQuickFilterService.lastIncKey= element.index
        }
      });
      this.levaQuickFilterService.includeKeyslist.forEach((ele) => {
        if (ele.metaInfo.displayName === this.configuration.metaInfo.displayName) {
          ele.selected = false
        }
      })
    } else {
      this.levaQuickFilterService.filtersList.exclude.splice(
        this.levaQuickFilterService.filtersList.exclude.indexOf(
          this.configuration
        ),
        1
      )
      this.levaQuickFilterService.filtersList.exclude.forEach((element,index) => {
        element.index = 'exc' + (index + 1)
        if((index+1)===this.levaQuickFilterService.filtersList.exclude.length)
        {
          this.levaQuickFilterService.lastExcKey= element.index
        }
      });
      this.levaQuickFilterService.excludeKeyslist.forEach((ele) => {
        if (ele.metaInfo.displayName  === this.configuration.metaInfo.displayName) {
          ele.selected = false
        }
      })
    }
    this.levaQuickFilterService.checkSaveValidation()
    /* this.levaQuickFilterService.keyslist.forEach((ele) => {
      if (ele.key === this.configuration.key) {
        ele.selected = false
        ele.selectedArea = ''
      }
    }) */
    if(
      this.levaQuickFilterService.filtersList.exclude.length===0 &&
      this.levaQuickFilterService.filtersList.include.length ===0
      )
      {
        this.levaQuickFilterService.enableSave=false
      }
    if (this.configuration.selectedArea === 'inc') {
      this.levaQuickFilterService.incCount =
        this.levaQuickFilterService.incCount - 1
    } else {
      this.levaQuickFilterService.excCount =
        this.levaQuickFilterService.excCount - 1
    }
  }

  onUserChangeStart(event) {
    // console.log(event);
  }
  onUserChange(event) {
    // console.log(event);
  }
  onUserChangeEnd(event) {
    // console.log('Range Changed', event);
  }
  onValueChange(event) {
    if(!(this.lowValue===this.highValue && this.lowValue===0 && this.highValue===0))
    {
      this.configuration.isSelected= true
    }
    this.levaQuickFilterService.checkSaveValidation()
    this.setFilterValues(this.lowValue, this.highValue)
  }

  onDateChange() {
    let dateFrom, dateTo
    if (this.lowValue) {
      dateFrom = DateUtils.getEpochTime(this.lowValue) / 1000
      this.configuration.isSelected= true
    }
    if (this.highValue) {
      dateTo = DateUtils.getEpochTime(this.highValue) / 1000

    }
    this.setFilterValues(dateFrom, dateTo)
    this.levaQuickFilterService.checkSaveValidation()
  }

  selectedItem(item) {
    this.selectedValue = item.displayName
    this.setFilterValues(item.val)
    this.configuration.isSelected = this.configuration.filterValues.length>0?true:false
  }

  removeItem($event) {
    this.selectedValue = null
    this.configuration.filterValues = []
    this.configuration.isSelected=false
    $event.stopPropagation()
  }

  generateRandomKey() {
    const crypto = window.crypto
    const array = new Uint32Array(1)
    return crypto.getRandomValues(array)[0]
  }

  onTextValueChange() {
    this.setFilterValues(this.openTextValue)
    this.configuration.isSelected = !!this.openTextValue
  }
}
