import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
@AutoUnsubscribe()
@Component({
  selector: 'app-show-my-alerts',
  templateUrl: './show-my-alerts.component.html',
  styleUrls: ['./show-my-alerts.component.sass']
})
export class ShowMyAlertsComponent implements OnInit, OnDestroy {

  showSummary = false;
  summaryOptions = [{
    title: 'Opportunities',
    total: 0,
    icon: 'ld-icon-currency',
    class: 'opportunities'
  },
  {
    title: 'Risk',
    total: 0,
    icon: 'ld-icon-alert',
    class: 'risk'

  },
  {
    title: 'Notifications',
    total: 0  ,
    icon: 'ld-icon-notepad',
    class: 'notification'
  }];
  scope$: Subscription;
  scope = 'Commodity';
  reload = false;
  @Output() collapseViewClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private scopeDataService: ScopeDataService
  ) {
    this.scope$ = this.scopeDataService.getFocus().subscribe(
      (data) =>{
        if(data){
          if(this.scope !== data){
          this.scope = data;
          this.reload = true;
          setTimeout(()=>{
            this.reload = false;
          },10)
          }
        }
      }
    );
  }

  ngOnInit() {}
  ngOnDestroy(){}

  totalValues($event){
    if($event){
    this.summaryOptions[0].total = Math.round(Number($event.opportunityAmount || 0));
    this.summaryOptions[1].total = Math.round(Number($event.riskAmount || 0));
    this.showSummary = true;
    }
  }

  notificationAlertCount($event){
    if($event){
    this.summaryOptions[2].total = Number($event.length  || 0);
    }
  }
}
