import { Component, OnInit } from '@angular/core';
import { NpiCommonApiService } from '../../npi-common-api.service';
import { NpiAdminRiskService } from '../../npi-admin/npi-admin-risk/shared/npi-admin-risk.service';
import * as moment from 'moment-timezone-all';
import { DateUtils } from '../../../common/utills/DateUtils.service';
import { DATE_PROPERTIES } from '../../npiconstants';
import { ECalendarValue, IDatePickerConfig } from 'ng2-date-picker';

@Component({
  selector: 'app-checkpoint-risk',
  templateUrl: './checkpoint-risk.component.html',
  styleUrls: ['./checkpoint-risk.component.sass']
})
export class CheckpointRiskComponent implements OnInit {
  public data: any;
  public minValuesArray = {};
  public configsArray = [];
  public displayMessage= 'No Program Configured Yet ';
  public showNotConfiguredMessage = false;

  constructor(
    private npiCommonApiService: NpiCommonApiService,
    private npiAdminRiskService: NpiAdminRiskService
  ) {
    moment.tz.setDefault(DateUtils.getUserTimeZone());
  }

  public ngOnInit() {
    this.getRiskInfo();
  }
  public getRiskInfo() {
    if (this.npiAdminRiskService.programKeyInfo !== undefined) {
      if (
        this.npiCommonApiService.validateServiceCallData(
          this.npiAdminRiskService.riskInfo
        ) !== 'error'
      ) {
        this.data = this.npiAdminRiskService.riskInfo['result'];
        this.data.checkpoints.forEach(checkpoint => {
          if (!moment.isMoment(checkpoint['riskDate'])) {
            checkpoint.risk = DateUtils.getMomentFromEpoch(
              checkpoint['riskDate']
            );
          }

          checkpoint['isDefault'] = true;
        });
        this.showNotConfiguredMessage = false;
        this.updateMinMaxValues();
      } else {
        this.showNotConfiguredMessage = true;
      }
    } else {
      this.showNotConfiguredMessage = true;
    }
  }
  public getDateConfig() {
    this.data.checkpoints.forEach((element, index) => {
      const config = {
        format: DATE_PROPERTIES.FORMAT,
        returnedValueType: ECalendarValue.Moment,
        drops: 'down'
      } as IDatePickerConfig;
      config['min'] = this.minValuesArray[index];
      config['max'] = DateUtils.getMomentFromEpoch(
        this.data.generalInfo.expectedRollOutDate
      );
      this.configsArray[index] = config;
      this.validateCompleteionDate();
    });
  }
  public updateMinMaxValues() {
    this.minValuesArray = {};
    this.data.checkpoints.forEach((element, index) => {
      if (this.data.checkpoints[index].checkpointDate) {
        this.minValuesArray[index] = DateUtils.getMomentFromEpoch(
          this.data.checkpoints[index].checkpointDate
        );
      } else {
        this.minValuesArray[index] = DateUtils.getCurrentDateAsMoment();
      }
    });
    this.getDateConfig();
  }
  public validateCompleteionDate() {
    this.data.checkpoints.forEach((element, index) => {
      if (DateUtils.getUTCTimeByMoment(this.data.checkpoints[index].riskDate)) {
        if (
          element.checkpointDate >
          DateUtils.getUTCTimeByMoment(this.data.checkpoints[index].riskDate)
        ) {
          this.data.checkpoints[index]['error'] = true;
          if (!this.npiAdminRiskService.errors['checkpoint'].includes(index)) {
            this.npiAdminRiskService.errors['checkpoint'].push(index);
          }
        } else {
          this.data.checkpoints[index]['error'] = false;
          if (this.npiAdminRiskService.errors['checkpoint'].includes(index)) {
            const presenIndex = this.npiAdminRiskService.errors[
              'checkpoint'
            ].findIndex(item => item === index);
            this.npiAdminRiskService.errors['checkpoint'].splice(
              presenIndex,
              1
            );
          }
        }
      } else {
        if (element.checkpointDate > this.data.checkpoints[index].riskDate) {
          this.data.checkpoints[index]['error'] = true;
          if (!this.npiAdminRiskService.errors['checkpoint'].includes(index)) {
            this.npiAdminRiskService.errors['checkpoint'].push(index);
          }
        } else {
          this.data.checkpoints[index]['error'] = false;
          if (this.npiAdminRiskService.errors['checkpoint'].includes(index)) {
            const presenIndex = this.npiAdminRiskService.errors[
              'checkpoint'
            ].findIndex(item => item === index);
            this.npiAdminRiskService.errors['checkpoint'].splice(
              presenIndex,
              1
            );
          }
        }
      }
    });
  }
  // checkpintInfoUpdated
  public updateDate(event, i) {
    if (moment.isMoment(event)) {
      const savePayload = {};
      savePayload['checkpointId'] = this.data.checkpoints[i].checkpointId;
      savePayload['riskDate'] = DateUtils.getUTCTimeByMoment(event.clone());
      this.data.checkpoints[i].riskDate = DateUtils.getUTCTimeByMoment(
        event.clone()
      );
      this.data.checkpoints[i].dateTouched = true;
      if (!this.data.checkpoints[i]['isDefault']) {
        this.npiAdminRiskService.enableReset = true;
        this.npiAdminRiskService.checkpintInfoUpdated = true;
        if (this.npiAdminRiskService.checkpointRiskInfo.length >= 1) {
          this.npiAdminRiskService.checkpointRiskInfo.forEach(
            (element, index) => {
              if (
                element['checkpointId'] ===
                this.data.checkpoints[i].checkpointId
              ) {
                this.npiAdminRiskService.checkpointRiskInfo[
                  index
                ] = savePayload;
              } else {
                this.npiAdminRiskService.checkpointRiskInfo.push(savePayload);
              }
            }
          );
        } else {
          this.npiAdminRiskService.checkpointRiskInfo.push(savePayload);
        }
        this.updateMinMaxValues();
      } else {
        this.data.checkpoints[i]['isDefault'] = false;
      }
    }
  }
}
