import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'excel-export-loader',
  templateUrl: './excel-export-loader.component.html',
  styleUrls: ['./excel-export-loader.component.sass']
})

export class ExcelExportLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit() { }
}
