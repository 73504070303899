export const typeProduct = 'Product';
export const typeCommodity = 'Commodity';
export const rfxList = 'RFX_LIST';
export const rfxSummary = 'RFX_SUMMARY';
export const column = 'COLUMN';
export const vendorQuote = 'RFX_VENDOR_QUOTE';
export const supplier = 'Vendor';
export const sendMessage = 'Are you sure you want to send?';
export const CONFIRMATION = 'CONFIRMATION';
export const DISCARD_SAVE = ' There are unsaved changes, Are you sure you want to save?';
export const PREFAPPLY = 'There are unsaved changes, Are you sure you want to continue apply preference?';
export const filterArray = [{ dbName: '', endVal: null, columnDataType: 'STRING', startVal: [] }]
export const RFQ_RECIPIENT_LIST = [{ displayValue: 'SUPPLIER', apiKey: 'SUPPLIER', uiKey: 'SUPPLIER' }, { displayValue: 'CM/ODM', apiKey: 'CM_ODM', uiKey: 'CM_ODM' }];
export const READ_KEY = 'displayValue';
export const PRODUCT_OPTION_STATUS = [4,5,6]