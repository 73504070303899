import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import * as _ from 'lodash';
import { VALIDATIONS, numberKeys, dateKeys } from '../../../shared/validationUtility';
import { TYPES } from '../../../shared/constants';
@Component({
  selector: 'app-validations',
  templateUrl: './validations.component.html',
  styleUrls: ['./validations.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidationsComponent implements OnInit {
  @Input() validations = [];
  @Input() isEditing = false;
  @Input()
  set selectedFieldType(value) {
    this._selectedFieldType = value;
    this.init();
  }
  public items = [];
  public filterItems;
  public _allItems = [];
  public _selectedFieldType;
  public types = TYPES;
  public invalid = false;
  createNewValidation() {
    return {
      type: null,
      selectedtype: null,
      info: {},
      errorMessage: ''
    };
  }
  createNewItems() {
    switch (this._selectedFieldType) {
      case this.types.TEXT:
        return VALIDATIONS.text;
      case this.types.NUMBER:
        return VALIDATIONS.number;
      case this.types.MULTISELECT:
      case this.types.DROPDOWN:
        return VALIDATIONS.dropdown;
      case this.types.DATE:
        return VALIDATIONS.date;
      default:
        return VALIDATIONS.text;
    }
  }
  constructor(private _cdr: ChangeDetectorRef) {}

  ngOnInit() {
    // this.init();
  }
  init() {
    this.filterItems = [];
    this.items = this.createNewItems();
    this._allItems = this.createNewItems();
    if (!this.isEditing || !this.validations || (this.validations instanceof Array && this.validations.length === 0)) {
      this.validations = [];
      this.addNew();
      this.validations[0].selectedtype = this.items[0];
      this.validations[0].type = this.validations[0].selectedtype.key;
    }else {
      for (let i = 0 ; i < this.validations.length ; i++) {
        this.validations[i].selectedtype = this.selectedValidationType(this.validations[i].type);
      }
    };
  }
  pushNewValidation(){
    if(this.isValid()){
      this.addNew();
      this.validations[this.validations.length - 1].selectedtype = this.items[0];
      this.validations[this.validations.length - 1].type = this.validations[this.validations.length - 1].selectedtype.key;
    }
    this._cdr.detectChanges();
  }
  addNew() {
    this.validations.push(this.createNewValidation());
  }
  isDisabled() {
    return (
      this._allItems.length === this.validations.length ||
      !this._selectedFieldType
    );
  }

  onChange(evt, currentIndex) {
    const prevValidationType = this.validations[currentIndex].type;
    if (this.validations.length !== this._allItems.length) {
      this.updateFilterItems(evt, prevValidationType);
      this.updateAllItems();
    }
    if (prevValidationType !== evt.key) {
      this.validations[currentIndex] = this.createNewValidation();
      this.validations[currentIndex].type = evt.key;
      this.validations[currentIndex].selectedtype = evt;
    }

  }
  /**
   * Add current selection , remove deselected one
   */
  updateFilterItems(newItem, prevItem) {
    const index = this.filterItems.indexOf(this.selectedValidationType(prevItem));
    if (index !== -1) {
      this.filterItems.splice(index, 1);
    }
    this.filterItems.push(newItem);
  }

  /**
   * Update items array
   */
  updateAllItems() {
    this.items = _.differenceWith(this._allItems, this.filterItems, _.isEqual);
  }

  removeValidation(index){
       const removedValidation = this.validations.splice(index,1);
      index = this.filterItems.indexOf(this.selectedValidationType(removedValidation[0].type));
      if (index !== -1) {
        this.filterItems.splice(index, 1);
      }
      this.updateAllItems();
  }
  selectedValidationType(selected){
    return this._allItems.find(x=> x.key === selected);
  }

  isValid(){
    this.invalid = false;
    this.validations.forEach(validation => {
        if(!validation.errorMessage){
          validation.emptyMessage = true;
          this.invalid  = true;
        }else{
          validation.emptyMessage = false;
        }
        this.isItemValid(validation);
    });
    return !this.invalid;
  }
  isItemValid(item){
    switch (this._selectedFieldType) {
      case this.types.NUMBER:
        this.isNumberTypeValid(item);
      case this.types.DATE:
        this.isDateTypeValid(item);
      default:
        return true;
    }
  }
  isNumberTypeValid(item){
    switch(item.type){
      case numberKeys.MIN:
      case numberKeys.MAX:
        if(!item.info.value){
          item.info.valueError = true;
          this.invalid = true;
        }else{
          item.info.valueError = false;
        }
        break;
      case numberKeys.RANGE:
        if(!item.info.min){
          item.info.minError = true;
          this.invalid = true;
        }else{
          item.info.minError = false;
        }
        if(!item.info.max){
          item.info.maxError = true;
          this.invalid = true;
        }else{
          item.info.maxError = false;
        }
        break;
      case numberKeys.ALLOWALPHA:
        if(!item.info.value || !isNaN(item.info.value) || item.info.value.length > 1){
          item.info.valueError = true;
          this.invalid = true;
        }else{
          item.info.valueError = false;
        }
      default:
      return true;
    }
    }
    isDateTypeValid(item){
      switch(item.type){
        case dateKeys.lessThan:
        case dateKeys.greaterThan:
        if(!item.info.value){
          item.info.valueError = true;
          this.invalid = true;
        }else{
          item.info.valueError = false;
        }
        break;
        case dateKeys.range:
        if(!item.info.min){
          item.info.minError = true;
          this.invalid = true;
        }else{
          item.info.minError = false;
        }
        if(!item.info.max){
          item.info.maxError = true;
          this.invalid = true;
        }else{
          item.info.maxError = false;
        }
        break;
        default:
        return true;
      }
    }
    onSave(){
      const isValid = this.isValid();
      this._cdr.detectChanges();
      if(!isValid && this.validations.length > 1){
        return isValid;
      } else if(!isValid && this.validations.length === 1){
        this.validations = this.deleteErrorKeys();
        this._cdr.detectChanges();
        return [];
      } else{
        return this.deleteErrorKeys();
      }
    }
    /**
     * Remove error fields
     */
    deleteErrorKeys(){
      const validations = _.cloneDeep(this.validations);
      validations.forEach(validation => {
        if(validation instanceof Object && Object.keys(validation).includes('emptyMessage')){
          delete validation.emptyMessage;
        }
        if(validation.info instanceof Object) {
          const infoKeys = Object.keys(validation.info);
          if(infoKeys.length ){
            if(infoKeys.includes('minError')) {
            delete validation.info.minError;
            }
            if(infoKeys.includes('maxError')) {
            delete validation.info.maxError;
            }
            if(infoKeys.includes('valueError')) {
            delete validation.info.valueError;
            }
          }
        }
      });
      return validations;
    }
}
