import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Location } from '@angular/common';
import { ISingeItem } from 'app/modules/common/models/scope/singleItem';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs-compat';

@Pipe({ name: 'replaceLineBreaks' })
export class ReplaceLineBreaks implements PipeTransform {
  transform(value: string): string {
    return value.replace(/\n/g, '<br/>');
  }
}
@Injectable()
export class UtilService {
  supportedEnvironments = {
    headers: [
      {
        title: 'Chrome',
        imageName: 'chrome',
      },
      {
        title: 'Firefox',
        imageName: 'firefox',
      },
      {
        title: 'Safari',
        imageName: 'safari',
      },
      {
        title: 'Edge',
        imageName: 'edge-chromium',
      },
    ],
    rowData: [
      {
        title: 'Windows',
        image: 'windows',
        chrome: {
          status: true,
          version: '79+',
        },
        firefox: {
          status: true,
          version: '73+',
        },
        safari: {
          status: false,
          version: 'NA',
        },
        'edge-chromium': {
          status: true,
          version: '79+',
        },
      },
      {
        title: 'Mac',
        image: 'osx',
        chrome: {
          status: true,
          version: '79+',
        },
        firefox: {
          status: false,
          version: 'NA',
        },
        safari: {
          status: true,
          version: '13+',
        },
        'edge-chromium': {
          status: true,
          version: '79+',
        },
      },
      {
        title: 'Linux',
        image: 'linux',
        chrome: {
          status: true,
          version: '80+',
        },
        firefox: {
          status: false,
          version: 'NA',
        },
        safari: {
          status: false,
          version: 'NA',
        },
        'edge-chromium': {
          status: false,
          version: 'NA',
        },
      },
    ],
  }
  route: string
  constructor(private location: Location, private router: Router) { }
  public getpath(target: string) {
    this.route = this.location.path()
    const routeURLArray: Array<string> = this.route.split('/')
    const routeURLArrayLength = routeURLArray.length
    const path = routeURLArray[routeURLArrayLength - 1]
    if (path !== undefined) {
      return path
    }
  }
  getsortedArray(x: Array<any>, key) {
    let swap: any
    for (let i = 0; i < x.length; i++) {
      for (let j = i + 1; j < x.length; j++) {
        if (x[j][key] < x[i][key]) {
          swap = x[j]
          x[j] = x[i]
          x[i] = swap
        }
      }
    }
    return x
  }
  getsortedDscArray(x: Array<any>, key) {
    let swap: any;
    for (let i = 0; i < x.length; i++) {
      for (let j = i + 1; j < x.length; j++) {
        if (x[j][key] > x[i][key]) {
          swap = x[j];
          x[j] = x[i];
          x[i] = swap;
        }
      }
    }
    return x;
  }

  onConvertArrayObjectKeys(source) {
    let newObject: any;
    for (const item of source) {
      let list_items: any;
      for (const list of item['list']) {
        let singleItem: ISingeItem<any>;
        singleItem[list];
        Object.assign(list_items, singleItem);
      }
      newObject[item['internalName']] = list_items;
    }
    return newObject;
  }

  /**
   * added to format bigger numbers into short form to display in the UI.
   * eg:2618931.71985804000000=  $22.26 M
   * @param input
   */
  shortNumbers(input, decimalValue = 2) {
    let returnVal;
    let isNegative = false;
    if (input < 0) {
      isNegative = true;
      input = -input;
    } else {
      isNegative = false;
    }
    if (input !== undefined) {
      if (input >= 1000) {
        let units = ['K', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'],
          decimal;
        for (let i = units.length - 1; i >= 0; i--) {
          decimal = Math.pow(1000, i + 1);
          if (input <= -decimal || input >= decimal) {
            if (isNegative) {
              return `-$${(input / decimal).toFixed(decimalValue)}${units[i]}`;
            } else {
              return `$${(input / decimal).toFixed(decimalValue)}${units[i]}`;
            }
          }
        }
        // returnVal = input;
      } else {
        if (isNegative) {
          returnVal = '-$' + Number(input).toFixed(decimalValue);
        } else {
          returnVal = '$' + Number(input).toFixed(decimalValue);
        }
      }
      return returnVal;
    }
  }



  supportedBrowser() {
    return this.supportedEnvironments || {};
  }

  detectBrowser() {
    const { detect } = require('detect-browser')
    const browser = detect()
    // handle the case where we don't detect the browser
    let status = true
    this.supportedEnvironments.rowData.some((element) => {
      if (
        browser.os
          .toLocaleLowerCase()
          .indexOf(element.title.toLocaleLowerCase()) >= 0
      ) {
        if (
          element[browser.name] &&
          element[browser.name].status &&
          Number(element[browser.name].version.split('+')[0]) <=
          Number(browser.version.split('.')[0])
        ) {
          status = true
          return true
        } else {
          status = false
          return true
        }
      }
    })
    return status
  }

  public oppFormatter = function () {

    if (Math.abs(this.value) >= 1000000000) {
      return (
        window['currencySymbol'] + (this.value / 1000000000).toFixed(1) + 'B'
      );
    }

    if (Math.abs(this.value) >= 1000000) {
      return window['currencySymbol'] + (this.value / 1000000).toFixed(1) + 'M';
    }

    if (Math.abs(this.value) >= 1000) {
      return window['currencySymbol'] + (this.value / 1000).toFixed(1) + 'K';
    }

    return window['currencySymbol'] + this.value.toFixed(1);
  };
  public columnLabelFormatter = function () {
    if (Math.abs(this.y) >= 1000000000) {
      return window['currencySymbol'] + (this.y / 1000000000).toFixed(1) + 'B';
    }

    if (Math.abs(this.y) >= 1000000) {
      return window['currencySymbol'] + (this.y / 1000000).toFixed(1) + 'M';
    }

    if (Math.abs(this.y) >= 1000) {
      return window['currencySymbol'] + (this.y / 1000).toFixed(1) + 'K';
    }

    return window['currencySymbol'] + this.y.toFixed(1);
  };

  public navBarStatus = new BehaviorSubject<string>(null);

  setNavBarStatus(target) {
    this.navBarStatus.next(target);
  }

  getNavBarStatus() {
    return this.navBarStatus.asObservable();
  }

  queryStringToJSON(queryString) {
    if (queryString.indexOf('?') > -1) {
      queryString = queryString.split('?')[1];
    }
    const pairs = queryString.split('&');
    const result = {};
    pairs.forEach((pair) => {
      pair = pair.split('=');
      result[pair[0]] = decodeURIComponent(pair[1] || '');
    });
    return result;
  }
}
