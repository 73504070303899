import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChildren,
  OnDestroy,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ScopeDataService } from '../common/scope.data.service';
import { BaseService } from '../common/base.service';
import { DataStorageService } from '../common/data-storage.service';
import { CustomerConfigurationService } from '../common/customerConfigurationService';
import { TimeSelectionDataService } from '../core/time-selection/shared/time-selectiondata.service';
import { DropDownListService } from '../common/dropdown-list.service';
import { FeatureService } from '../common/feature.service';
import { BotService } from '../base-app/bot/shared/bot.service';
import { AutoSearchService } from '../core/autosearch/shared/autosearch.service';
import { ApplicationURLS } from '../common/applicationURL';
import { ChatBotService } from './chatbot.service';
import { BodyOutputType, Toast, ToasterService } from 'angular2-toaster';
import _ from 'lodash';
import { ngxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import { SCROLL_BAR_CONFIGURATION } from '../common/scroll-config.service';
import { FileDownloadService } from '../core/file-download/shared/file-download.service';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { CUSTOMER_CURRENT_USER, CUSTOMER_FOCUS } from '../shared/services/shared-consts';
interface QuarterObject {
  startQuarter: number;
  endQuarter: number;
  year: number;
}
@AutoUnsubscribe()
@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.sass'],
})
export class ChatBotComponent implements OnInit, OnDestroy {
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  showChatBotErrorMessage = false;
  alertsCount =0;
  alertsCount$: Subscription;
  constructor(
    private router: Router,
    private scopeDataService: ScopeDataService,
    private baseService: BaseService,
    private customerConfigurationService: CustomerConfigurationService,
    private timeSelectionDataService: TimeSelectionDataService,
    private dataStorageService: DataStorageService,
    private dropDownListService: DropDownListService,
    private featureService: FeatureService,
    private botService: BotService,
    private searchService: AutoSearchService,
    private applicationURl: ApplicationURLS,
    private chatBotService: ChatBotService,
    private fileDownloadService: FileDownloadService,
    private toasterService: ToasterService,
    private ngxZendeskWebwidgetService: ngxZendeskWebwidgetService
  ) {
    // this.favoriteOptions.forEach((favourite) => {
    //   if (this.activeList.indexOf(favourite.displayName) === -1) {
    //     favourite.disabled = true;
    //   }
    // });
  }

  showChatBotHistory = false;
  chatBotMessageContainer = false;
  scopeList;
  setDefaultYear: Array<any> = [
    'spendanalytics/spend',
    'spendanalytics/cost',
    'spendanalytics/forecast',
  ];
  finalChatBotObject: any = {};
  notApplicableRoutes: Array<any> = [];
  observableList = [];

  dimensionsList: Array<string> = [
    'COMMODITY_NAME',
    'SUB_COMMODITY_NAME',
    'PRODUCT_NAME',
    'PRODUCT_FAMILY_NAME',
    'SUPPLIER_NAME',
    'MANUFACTURER_NAME',
    'CPN',
    'MPN_DESC',
    'CPN_DESC',
    'PRODUCT_DESCRIPTION',
    'MPN',
    'COMMODITY_MANAGER_NAME',
    'CPN_STRATEGY',
    'CM_ODM_NAME',
    'CONTROL',
  ];

  subNavTabBar = [
    {
      title: 'Favorites',
      view: 'favorites',
    },
    {
      title: 'Recent Activities',
      view: 'recentActivities',
    },
  ];
  tooltipOptions = [
    {
      displayName: 'Show spend',
      type: 'chatbot',
      otherInformation: {
        command: 'show spend',
      },
    },
    {
      displayName: 'Part matching',
      type: 'chatbot',
      otherInformation: {
        command: 'show Part matching',
      },
    },
    {
      displayName: 'Lookup for [CPN/MPN]',
      type: 'chatbot',
      otherInformation: {
        command: 'Show costlookup',
      },
    },
    {
      displayName: '...',
      type: 'custom',
      otherInformation: {
        type: 'more',
      },
    },
  ];
  customDimensionData;
  onSend = false;
  showPanel = 'hide';
  userInput;
  sendReceivedObject;
  //  dateArray;
  botHistory: any = {};
  showTooltip = false;
  recentActivitiesHistory = [];
  favouriteList = [];
  userInformation: any = {};

  welcomeMessageArray = ['Good Morning', 'Good Afternoon', 'Good Evening'];

  welcomeMessage = 'Good Morning';
  alertFavoriteObj = {
    displayName: 'Show Alerts',
    class: 'padding-30',
    type: 'custom',
    otherInformation: {
      message: '',
      type: 'page',
      target: '/app/dashboard',
      pageOptions: {
        expand: 'alertSummary',
      },
    },
  };
  activeList = [];
  favoriteOptions = [
    /* {
      displayName: 'Dashboard',
      disabled: false,
      class: '',
      type: 'custom',
      iconClass: 'ld-collapse-icon-spend-analytics ld-white font-size-28',
      otherInformation: {
        message: '',
        type: 'page',
        target: '/app/spendanalytics',
        pageOptions: null,
      }
    } */
  ];

  favouritesList = [
    {
      displayName: 'Favorite 1',
      disabled: false,
      class: '',
      icon: 'database.png',
      iconPath: 'assets/images',
      type: 'custom',
      otherInformation: {
        message: '',
        type: 'page',
        target: '/app/loadingestiondata',
        pageOptions: null,
      }
    }
  ];

  chatBotCommandsList = [
    {
      groupTitle: 'Simple requests to take you to certain pages',
      subTitles: [
        'Show spend',
        'Part matching',
        'go to benchmark',
        'show opportunities',
        'Navigate to Negotiation playbook',
      ],
    },
    {
      groupTitle: 'Requests with scope context',
      subTitles: [
        'Show David’s opportunities for cable',
        'Show spend of plastics and cable',
        'Show benchmark  for \'<MPN>\'',
        'Create negotiation playbook for alpha networks',
        'Np for <manufacturer>',
        '<cm odm> negotiation package',
        'Show part matching for BAT54C',
        'Show copper trend ',
        'Show trend of <raw material>',
        'Show benchmark for BAT54C',
        'Show spend of cables and capacitor from Q2 2020 to Q4 2020',
        'Show spend of plastics and cable in Q4 2020',
        'Show Next Quarter Opportunities for capacitor',
        'Show current quarter spend',
        'Show cost of MPN BAT54C in 2020',
        'What is the cost of product sound bar in the current quarter',
      ],
    },
  ];
  favButtonFromFavouriteList = [];
  url;
  tenantId;
  ajaxRequests: any = {};
  @ViewChildren('search') SearchElement;
  @Output() onFavorite: EventEmitter<any> = new EventEmitter<any>();

  selectedTabName = 'favorites';

  ngOnInit() {
    // this.favoriteOptions =  this.favoriteOptions.concat(this.favouritesList);
    this.tenantId = this.customerConfigurationService.getEnvironmentDetails().tenantId
    this.getLogoURL();
    this.getNotificationCount();
    this.getBookmarks(false);
    this.chatBotService.getLandingPageButtons(this.tenantId).subscribe(
      (data) => {
        if (data && data.result) {
          const buttonList = [];
          (data.result || []).forEach(
            (val) => {
              switch (val.type.trim().toLowerCase()) {
                case 'navigation':
                  const buttonObject = {
                    displayName: '',
                    disabled: false,
                    class: '',
                    type: 'custom',
                    iconClass: '',
                    otherInformation: {
                      message: '',
                      type: 'page',
                      target: '',
                      pageOptions: null,
                    }
                  };

                  if (val.navigation !== 'string' && val.navigation !== null) {
                    val.navigation = JSON.parse(val.navigation)
                  } else {
                    return false;
                  }
                  buttonObject.displayName = val.name;
                  if (this.getIcon(val.navigation.link)) {
                    buttonObject.iconClass = this.getIcon(val.navigation.link);
                  } else {
                    delete buttonObject.iconClass;
                    buttonObject['iconPath'] = 'assets/images';
                    switch (val.navigation.link.trim().toLowerCase()) {
                      case 'scenariocomparison':
                        buttonObject['icon'] = 'board.png';
                        break;
                      case 'loadingestiondata':
                        buttonObject['icon'] = 'database.png';
                        break;
                      case 'risk-rfi':
                        buttonObject['icon'] = 'risk-rfi.png';
                        break;
                      case 'contentlabinsights':
                        buttonObject['icon'] ='idea.png'
                        break;
                      case 'scenarioworkbench':
                        buttonObject['icon'] = 'refresh.png';
                        break;
                      case 'mi':
                        buttonObject['icon'] = 'binoculars.png';
                        break;
                      case 'npi':
                        buttonObject['icon'] = 'rocket.png';
                        break;
                        case 'spendintelligence':
                          buttonObject['icon'] = 'bar-graph.png';
                        break;
                        case 'np':
                          buttonObject['icon'] = 'negotiation-playbook.png';
                        break;
                        case 'srfx':
                          buttonObject['icon'] = 'network.png';
                          break;
                        case 'costlookup':
                          buttonObject['icon'] ='search-white.png'
                          break;
                        case 'reports':
                          buttonObject['icon'] ='list-white.png'
                          break;
                        case 'interactiveedit':
                          buttonObject['icon'] ='pencil.png'
                          break;
                        case 'savingsopportunities':
                          buttonObject['icon'] ='business-and-finance.png'
                          break;
                          case 'opportunities':
                            buttonObject['icon'] ='business-and-finance.png'
                            break;
                        case 'supplyrisk':
                          buttonObject['icon'] = 'radiation.png';
                        break;
                      default:
                        buttonObject['icon'] = 'navigation.png';
                        break;


                    }
                  }
                  buttonObject.otherInformation.target = `app/${val.navigation.link}`;
                  buttonObject.otherInformation['scope'] = val.navigation.scope;
                  buttonList.push(buttonObject);
                  break;
                case 'chatbotcommand':
                  const chatbotObject = {
                    displayName: val.name,
                    disabled: false,
                    class: '',
                    type: 'chatbot',
                    icon: 'robot.png',
                    iconPath: 'assets/images',
                    otherInformation: {
                      message: '',
                      command: val.chatbotCommand,
                      pageOptions: null,
                    }
                  }
                  buttonList.push(chatbotObject);
                  break;
                case 'prefilltext':
                  const preFillText = {
                    displayName: val.name,
                    disabled: false,
                    class: '',
                    type: 'custom',
                    icon: 'edit.png',
                    iconPath: 'assets/images',
                    otherInformation: {
                      message: val.preFillText,
                      pageOptions: null,
                      type: 'fillinput'
                    }
                  }

                  buttonList.push(preFillText);
                  break;
              }
            }
          );
          this.favoriteOptions = buttonList || [];
          console.log(this.favoriteOptions);
        }
      }
    );

    this.ngxZendeskWebwidgetService.show();
    const currentTime = new Date().getHours();
    if (currentTime >= 5 && currentTime < 12) {
      this.welcomeMessage = 'Good Morning';
    } else if (currentTime >= 12 && currentTime < 18) {
      this.welcomeMessage = 'Good Afternoon';
    } else {
      this.welcomeMessage = 'Good Evening';
    }

    this.userInformation = JSON.parse(
      localStorage.getItem(CUSTOMER_CURRENT_USER)
    );
    this.scopeList = this.customerConfigurationService.getScopeList();
    this.notApplicableRoutes = this.featureService.getfeaturesArray();
  }

  getNotificationCount(){

    this.alertsCount$ = this.chatBotService.getAlertsCount(JSON.parse(localStorage.getItem(CUSTOMER_FOCUS))).subscribe(
      (data)=>{
        if(data && data.result){
          this.alertsCount = data.result || 0;
        }else{
          this.alertsCount =0;
        }
      }
    );
  }

  public async getLogoURL() {
    if (this.baseService.getCsApp()) {
      this.url = 'assets/images/logo.png';
    } else {
      const data = {
        targetedType: 'CustomerConfigService',
        formParams: {
          tenantId: this.tenantId
        },
        servicePath: '/downloadCustomerLogo'
      };
      this.url = await this.fileDownloadService.formDownloadUrl(data);
    }
  }

  public errorMethod() {
    this.url = 'assets/images/logo.png';
    // this.baseService.setLogoURL(this.url);
  }

  getIcon(target) {
    switch (target.trim().toLowerCase()) {
      default:
        return null;
        break;
    }
  }

  getBookmarks(showError?) {
    const payload = {
      oneDayHistory: true,
      scope: this.baseService.getCurrentApplicationScope(),
      userId: this.baseService.getUserInformation().userId,
      tenantId: this.customerConfigurationService.getEnvironmentDetails()
        .tenantId,
      userEmail: this.baseService.getUserInformation().email,
      fullName: this.baseService.getUserInformation().fullName,
    };
    this.ajaxRequests['bookmarks'] = this.chatBotService
      .bookMarks(payload)
      .subscribe((val) => {
        if (val && val.result) {
          this.recentActivitiesHistory = val.result.recentChatHistoryList || [];
          this.favouriteList = (
            val.result.chatFavouriteModelList || []
          ).reverse();
          this.generateFavButtonsFromFavouriteList(this.favouriteList);
          if (showError === undefined) {
            this.showChatBotHistory = true;
          }
        }
      });
  }

  generateFavButtonsFromFavouriteList(favouriteList) {

    this.favButtonFromFavouriteList = [];
    (favouriteList || []).forEach(
      (val) => {
        const buttonObject = {
          displayName: val.displayCommand,
          disabled: false,
          class: '',
          type: 'chatbot',
          icon: 'star.png',
          iconPath: 'assets/images',
          otherInformation: {
            message: '',
            command: val.displayCommand,
            pageOptions: null,
          }
        }
        this.favButtonFromFavouriteList.push(buttonObject);
      }
    );
  }

  addAsFavorites(item) {
    item.favourite = !item.favourite;
    if (item.favouriteId === -1) {
      if (this.ajaxRequests['addNewFavorites']) {
        this.ajaxRequests['addNewFavorites'] = null;
      }
      this.ajaxRequests[
        'addNewFavorites'
      ] = this.chatBotService.addNewFavorites(item.command).subscribe((val) => {
        if (val && val.result) {
          const toast: Toast = {
            type: 'success',
            showCloseButton: true,
            body: `<div class="toast-msg success-msg">${val.result}</div>`,
            bodyOutputType: BodyOutputType.TrustedHtml,
          };
          this.toasterService.pop(toast);
          this.getBookmarks();
        }
      });
    } else {
      this.updateFavorites(item.command, item.favouriteId, item.favourite);
    }
  }

  deSelectAsFavorites(item) {
    item.active = !item.active;
    this.updateFavorites(item.command, item.favouriteId, item.favourite);
  }

  updateFavorites(command, favouriteId, favourite) {
    if (this.ajaxRequests['updateFavorites']) {
      this.ajaxRequests['updateFavorites'] = null;
    }
    this.ajaxRequests['updateFavorites'] = this.chatBotService
      .updateFavorites(command, favouriteId, favourite)
      .subscribe((val) => {
        if (val && val.result) {
          const toast: Toast = {
            type: 'success',
            showCloseButton: true,
            body: `<div class="toast-msg success-msg">${val.result}</div>`,
            bodyOutputType: BodyOutputType.TrustedHtml,
          };
          this.toasterService.pop(toast);
          this.getBookmarks();
        }
      });
  }

  clearAllFavourites() {
    if (this.ajaxRequests['clearAllFavourites']) {
      this.ajaxRequests['clearAllFavourites'] = null;
    }
    this.ajaxRequests[
      'clearAllFavourites'
    ] = this.chatBotService
      .clearAllFavourites(_.map(this.favouriteList, 'favouriteId'))
      .subscribe((val) => {
        if (val && val.result) {
          this.favouriteList = [];
          this.getBookmarks();
        }
      });
  }
  onChatBotCommand(command) {
    this.send(command);
  }

  clearAjaxCalls(key?: string) {
    if (typeof key === 'string') {
      if (this.ajaxRequests[key] instanceof Object) {
        this.ajaxRequests[key].unsubscribe();
        delete this.ajaxRequests[key];
      }
      return;
    }
    for (const req in this.ajaxRequests) {
      if (this.ajaxRequests[req] instanceof Object) {
        this.ajaxRequests[req].unsubscribe();
        delete this.ajaxRequests[req];
      }
    }
  }
  onFavoriteClick(target) {
    if (target && target.type) {
      switch (target.type.trim().toLowerCase()) {
        case 'chatbot':
          this.send(target.otherInformation.command);
          break;
        case 'custom':
          if (target.otherInformation) {
            switch (target.otherInformation.type.trim().toLowerCase()) {
              case 'fillinput':
                this.userInput = `${target.otherInformation.message} ` || '';
                this.SearchElement.first.nativeElement.focus();
                break;
              case 'page':
                if (target.otherInformation.target && !target.disabled) {
                  this.chatBotService.setCustomChatBotActions(
                    target.otherInformation.pageOptions
                  );
                  if (this.scopeDataService.getFocusValue() !== target.otherInformation.scope) {
                    this.scopeDataService.setFocus(target.otherInformation.scope || 'Commodity');
                  }
                  this.toNavigate(target.otherInformation.target);
                }
                break;
              case 'more':
                this.chatBotMessageContainer = true;
                this.showChatBotHistory = false;
                break;
            }
          }
          break;
      }
    }
  }

  recentActivities() {
    this.getBookmarks();
    this.chatBotMessageContainer = false;
    this.selectedTabName = 'favorites';
  }

  navigateToDashboard() {
    this.toNavigate('app/dashboard');
  }

  hideTooltip($event) {
    this.showTooltip = false;
  }

  toNavigate(target) {
    this.router.navigate([target]);
    if (window.location.hash.indexOf(target) > -1) {
      this.pageLeave();
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.pageLeave();
      }
    });
  }
  showAlertPage() {
    this.pageLeave();
    sessionStorage.setItem('ENLP', 'true');
    this.router.navigate(['/app/alerts']);
  }

  onDismiss() {
    this.pageLeave();
    sessionStorage.setItem('ENLP', 'true');
    if(!this.baseService.getCurrentLandingPageURL()){
      this.baseService.setCurrentLandingPageURL(`/app/${this.redirectTorespectivePage()}`)
    }
    this.router.navigate([this.baseService.getCurrentLandingPageURL()]);
  }
  redirectTorespectivePage() {
    let homepage = '';
    try {
      let defaultScope: any;
      const scopeList = this.customerConfigurationService.getScopeList();
      if (scopeList) {
        defaultScope = scopeList.filter((val) => val.defaultValue);
        if (defaultScope) {
          defaultScope = defaultScope[0].view;
          if (defaultScope) {
            this.scopeDataService.setFocus(defaultScope);
          }
        }
      }

      if (
        this.customerConfigurationService.getConfData(defaultScope) &&
        this.customerConfigurationService.getConfData(defaultScope).features
      ) {
        homepage =
          this.customerConfigurationService.getConfData(defaultScope).features
            .homePage || '';
      }
    } catch (error) {}
    return homepage;
  }

  closeChatBotMessageContainer() {
    this.chatBotMessageContainer = false;
  }

  closeChatHistory() {
    this.showChatBotHistory = false;
  }

  mouseEnter() {
    this.showTooltip = true;
  }

  mouseLeave() {
    this.showTooltip = false;
  }

  setAutoSuggestion(findWord) {
    let currentService: any;
    const obs = this.botService
      .getBotAutoPopulatedData(findWord, this.dimensionsList)
      .debounceTime(500)
      .subscribe(
        (val) => {
          if (val['result'] !== undefined) {
            if (val['result'] instanceof Array) {
              if (val['result'].length > 0) {
                if (val['result'][0].hits !== undefined) {
                  if (val['result'][0].hits.length > 0) {
                    this.customDimensionData = this.searchService.onConvertArrayForAutoDimnsionSelect(
                      val['result'][0].hits,
                      this.dimensionsList
                    );
                    console.log(this.customDimensionData);
                    if (!this.onSend) {
                      this.showPanel = 'show';
                    }
                  } else {
                    this.showPanel = 'hide';
                  }
                }
              }
            }
          }
        },
        (error) => {
          currentService.unsubscribe();
        },
        () => { }
      );
    this.observableList.push(obs);
  }

  autosuggestionPanel(val) {
    if (val !== undefined) {
      const wordsArray: Array<any> = val.split(' ');
      if (wordsArray.length > 0) {
        const findWord = wordsArray[wordsArray.length - 1];
        // tslint:disable-next-line: prefer-for-of
        for (let index = 0; index < this.observableList.length; index++) {
          this.observableList[index].unsubscribe();
        }
        if (findWord !== '' && findWord.length > 2) {
          this.customDimensionData = {};
          this.setAutoSuggestion(findWord);
        } else {
          this.showPanel = 'hide';
        }
      }
    }
  }

  onAutoDimensionSelect(key, value) {
    const customInput = this.userInput;
    const wordsArray: Array<any> = customInput.split(' ');
    const findWord = wordsArray[wordsArray.length - 1];
    const removedArray: Array<any> = [];
    if (wordsArray.length > 0) {
      const replaceWord = value;
      if (replaceWord !== undefined) {
        this.userInput = this.userInput.replace(
          findWord,
          key + ' ' + replaceWord
        );
        this.SearchElement.first.nativeElement.focus();
      }
    }
    this.showPanel = 'hide';
  }

  private onGenerateQuarters(obj: QuarterObject) {
    const quartersObject: Array<object> = [];
    for (let index = obj.startQuarter; index <= obj.endQuarter; index++) {
      const quarter = {
        year: obj.year,
        quarter: 'Q' + index,
      };
      quartersObject.push(quarter);
    }
    return quartersObject;
  }
  private onGenerateDateFilter(dateFilter) {
    if (dateFilter !== undefined) {
      const targetSelection = Object.keys(dateFilter)[0];
      if (targetSelection !== undefined) {
        let targetTimeObject: any;
        this.scopeDataService.resetTimeDimensionDataObject();
        this.scopeDataService.clearSelectedQuarterObject();
        let timeObject: Array<object> = [];
        switch (targetSelection) {
          case 'others':
            targetTimeObject = dateFilter.others.timePeriod;
            for (const year of targetTimeObject) {
              if (year !== undefined) {
                let yearObject: any = {};
                switch (typeof year) {
                  case 'object':
                    yearObject = this.timeSelectionDataService.onCheckTimeSelectionDataKey(
                      year.year
                    );
                    if (yearObject) {
                      yearObject.quarter = year.quarter;
                    }
                    break;
                  case 'string':
                  case 'number':
                    yearObject = this.timeSelectionDataService.onCheckTimeSelectionDataKey(
                      year
                    );
                    break;
                }
                if (yearObject !== undefined) {
                  if (yearObject instanceof Array) {
                    timeObject = timeObject.concat(yearObject);
                  } else {
                    if (yearObject.quarter !== undefined) {
                      timeObject.push(Object.assign({}, yearObject));
                    } else {
                      timeObject.push(yearObject);
                    }
                  }
                }
              }
            }

            break;
          case 'range':
            targetTimeObject = dateFilter.range.timePeriod;
            let startVal: any;
            let endVal: any;
            const yearCount = 0;
            const yearsLength = targetTimeObject.length;
            let findDiff: number;
            startVal = targetTimeObject[0];
            endVal = targetTimeObject[yearsLength - 1];
            if (typeof startVal === 'string' && typeof endVal === 'string') {
              findDiff = Number(endVal) - Number(startVal);
              for (let i = 0; i < findDiff + 1; i++) {
                // year
                const yearObject: object = {};
                yearObject['year'] = Number(startVal) + i;
                timeObject.push(yearObject);
              }
            } else if (
              typeof startVal === 'object' &&
              typeof endVal === 'object'
            ) {
              findDiff = Number(endVal.year) - Number(startVal.year);
              if (findDiff === 0) {
                // Same Year
                const startQuarter: any =
                  String(targetTimeObject[0].quarter) !== undefined
                    ? String(targetTimeObject[0].quarter)
                    : undefined;
                const endQuarter: any =
                  String(
                    targetTimeObject[targetTimeObject.length - 1].quarter
                  ) !== undefined
                    ? String(
                      targetTimeObject[targetTimeObject.length - 1].quarter
                    )
                    : undefined;
                const findDiffQuarter: any =
                  endQuarter.charAt(1) - startQuarter.charAt(1);
                if (findDiffQuarter === 0) {
                  const startQuarterObj: object = {
                    year: Number(startVal.year),
                    quarter: startQuarter,
                  };
                  timeObject.push(startQuarterObj);
                } else {
                  const quaterArray: Array<object> = this.onGenerateQuarters({
                    startQuarter: startQuarter.charAt(1),
                    endQuarter: Number(endQuarter.charAt(1)),
                    year: Number(startVal.year),
                  });
                  timeObject = timeObject.concat(
                    quaterArray !== undefined ? quaterArray : []
                  );
                }
              } else {
                for (let i = 0; i < findDiff + 1; i++) {
                  const yearObject: object = {};
                  yearObject['year'] = Number(startVal.year) + i;
                  if (Number(startVal.year) + i === startVal.year) {
                    if (startVal.quarter !== undefined) {
                      if (startVal.quarter.charAt(1) !== undefined) {
                        const quaterArray = this.onGenerateQuarters({
                          startQuarter: startVal.quarter.charAt(1),
                          endQuarter: 4,
                          year: Number(startVal.year) + i,
                        });
                        timeObject = timeObject.concat(
                          quaterArray !== undefined ? quaterArray : []
                        );
                      }
                    }
                  } else if (Number(startVal.year) + i === endVal.year) {
                    if (endVal.quarter !== undefined) {
                      if (endVal.quarter.charAt(1) !== undefined) {
                        const quaterArray: Array<object> = this.onGenerateQuarters(
                          {
                            startQuarter: 1,
                            endQuarter: Number(endVal.quarter.charAt(1)),
                            year: Number(startVal.year) + i,
                          }
                        );
                        timeObject = timeObject.concat(
                          quaterArray !== undefined ? quaterArray : []
                        );
                      }
                    }
                  } else {
                    const quaterArray: Array<object> = this.onGenerateQuarters({
                      startQuarter: 1,
                      endQuarter: 4,
                      year: Number(startVal.year) + i,
                    });
                    timeObject = timeObject.concat(
                      quaterArray !== undefined ? quaterArray : []
                    );
                  }
                }
              }
            } else if (
              typeof startVal === 'object' &&
              typeof endVal === 'string'
            ) {
              findDiff = Number(endVal) - Number(startVal.year);
              if (findDiff === 0) {
                // Same Year
                const startQuarter: any =
                  String(startVal.quarter) !== undefined
                    ? String(startVal.quarter)
                    : undefined;
                const quaterArray: Array<object> = this.onGenerateQuarters({
                  startQuarter: Number(startQuarter.charAt(1)),
                  endQuarter: 4,
                  year: Number(startVal.year),
                });
                timeObject = timeObject.concat(
                  quaterArray !== undefined ? quaterArray : []
                );
              } else {
                for (let i = 0; i < findDiff + 1; i++) {
                  const yearObject: object = {};
                  yearObject['year'] = Number(startVal.year) + i;
                  if (Number(startVal.year) + i === startVal.year) {
                    if (startVal.quarter !== undefined) {
                      if (startVal.quarter.charAt(1) !== undefined) {
                        const quaterArray = this.onGenerateQuarters({
                          startQuarter: Number(startVal.quarter.charAt(1)),
                          endQuarter: 4,
                          year: Number(startVal.year) + i,
                        });
                        timeObject = timeObject.concat(
                          quaterArray !== undefined ? quaterArray : []
                        );
                      }
                    }
                  } else if (Number(startVal.year) + i === Number(endVal)) {
                    const quaterArray: Array<object> = this.onGenerateQuarters({
                      startQuarter: 1,
                      endQuarter: 4,
                      year: Number(startVal.year) + i,
                    });
                    timeObject = timeObject.concat(
                      quaterArray !== undefined ? quaterArray : []
                    );
                  } else {
                    if (startVal.year !== undefined) {
                      const quaterArray: Array<object> = this.onGenerateQuarters(
                        {
                          startQuarter: 1,
                          endQuarter: 4,
                          year: Number(startVal.year) + i,
                        }
                      );
                      timeObject = timeObject.concat(
                        quaterArray !== undefined ? quaterArray : []
                      );
                    }
                  }
                }
              }
            } else if (
              typeof startVal === 'string' &&
              typeof endVal === 'object'
            ) {
              findDiff = Number(endVal.year) - Number(startVal);
              if (findDiff === 0) {
                // Same Year
                const quaterArray: Array<object> = this.onGenerateQuarters({
                  startQuarter: 1,
                  endQuarter: Number(endVal.quarter.charAt(1)),
                  year: Number(startVal),
                });
                timeObject = timeObject.concat(
                  quaterArray !== undefined ? quaterArray : []
                );
              } else {
                for (let i = 0; i < findDiff + 1; i++) {
                  const yearObject: object = {};
                  yearObject['year'] = Number(startVal) + i;
                  if (Number(startVal) + i === Number(startVal)) {
                    const quaterArray: Array<object> = this.onGenerateQuarters({
                      startQuarter: 1,
                      endQuarter: 4,
                      year: Number(startVal) + i,
                    });
                    timeObject = timeObject.concat(
                      quaterArray !== undefined ? quaterArray : []
                    );
                  } else if (Number(startVal) + i === Number(endVal.year)) {
                    const quaterArray: Array<object> = this.onGenerateQuarters({
                      startQuarter: 1,
                      endQuarter: Number(endVal.quarter.charAt(1)),
                      year: Number(startVal) + i,
                    });
                    timeObject = timeObject.concat(
                      quaterArray !== undefined ? quaterArray : []
                    );
                  } else {
                    if (startVal !== undefined) {
                      const quaterArray: Array<object> = this.onGenerateQuarters(
                        {
                          startQuarter: 1,
                          endQuarter: 4,
                          year: Number(startVal) + i,
                        }
                      );
                      timeObject = timeObject.concat(
                        quaterArray !== undefined ? quaterArray : []
                      );
                    }
                  }
                }
              }
            }
            // this.finalChatBotObject['time'] = timeObject;
            break;
        }
        this.scopeDataService.setTime(true);
        this.finalChatBotObject['time'] = timeObject;
      }
    }
  }

  private onNavigateFormChatBot(sendReceivedObject) {
    if (sendReceivedObject instanceof Object) {
      let targetComponent =
        sendReceivedObject.component !== undefined
          ? sendReceivedObject.component
          : undefined;
      const scope =
        sendReceivedObject.scope !== undefined
          ? sendReceivedObject.scope
          : undefined;

      /* ------------------------- Scope Switch base on chat bot response----------------------- */
      if (scope !== undefined) {
        const currentScope = this.baseService.getCurrentApplicationScope();
        if (currentScope.toLowerCase() !== scope) {
          if (this.scopeList.length !== 1) {
            this.scopeDataService.setFocus(scope);
          }
        }
      }

      /* --------------------------- Spend Element Checking -------------------------------------- */
      if (targetComponent !== undefined) {
        switch (targetComponent.toLowerCase()) {
          case 'spendanalytics/spend/spendelements':
            targetComponent = 'spendanalytics/spend';
            this.dataStorageService.setSpendElementCheck(true);
            break;
          case 'mi/benchmark/transformationcost':
            this.dataStorageService.setBenchmarkTransformationCost(true);
            break;
        }
      }

      /* ------------------------- Apply Filter base on chat bot response----------------------- */

      if (Object.keys(sendReceivedObject.parameters).length >= 0) {
        // tslint:disable-next-line: no-shadowed-variable
        const path = sendReceivedObject.component.split('/');
        if (path[path.length - 1] !== 'summary') {
          if (sendReceivedObject.parameters.dateFilter !== undefined) {
            this.timeSelectionDataService.clearLocalTimePeriodYearsObject();
            // this.onGenerateDateFilter(sendReceivedObject);

            switch (targetComponent.toLowerCase()) {
              case 'opportunities':
                if (
                  sendReceivedObject.parameters.dateFilter.others !== undefined
                ) {
                  // tslint:disable-next-line: no-shadowed-variable
                  const chatBotTime =
                    sendReceivedObject.parameters.dateFilter.others
                      .timePeriod || undefined;
                  if (chatBotTime !== undefined) {
                    this.scopeDataService.setOpportunitiesTimeFromChatBot(
                      chatBotTime[0]
                    );
                  }
                }
                break;
              case 'spendanalytics/forecast/s':
                let chatBotTime;
                if (
                  sendReceivedObject.parameters.dateFilter.range !== undefined
                ) {
                  chatBotTime =
                    sendReceivedObject.parameters.dateFilter.range.timePeriod ||
                    undefined;
                } else if (
                  sendReceivedObject.parameters.dateFilter.others !== undefined
                ) {
                  chatBotTime =
                    sendReceivedObject.parameters.dateFilter.others
                      .timePeriod || undefined;
                }
                if (chatBotTime !== undefined) {
                  this.scopeDataService.setForecastChangeTimeFromChatBot(
                    chatBotTime
                  );
                }
                break;
              case 'spendanalytics/forecast/forecastvsactual':
                break;
              default:
                this.onGenerateDateFilter(
                  sendReceivedObject.parameters.dateFilter
                );
                break;
            }
          } else {
            /* ----------------------- Check and add time base on component ------------------------- */

            const urlArray: Array<any> = sendReceivedObject.component
              .split('/', 2)
              .join('/');

            if (this.setDefaultYear.indexOf(urlArray) > -1) {
              this.timeSelectionDataService.clearLocalTimePeriodYearsObject();
              const dateFilter: {} = {
                others: {
                  timePeriod: [
                    this.timeSelectionDataService.getCurrentYear() !== undefined
                      ? this.timeSelectionDataService.getCurrentYear()
                      : 2018,
                  ],
                },
              };

              this.onGenerateDateFilter(dateFilter);
            }
          }

          const botScopeItems: any = {};
          if (sendReceivedObject.parameters.filter !== undefined) {
            for (const dimension of Object.keys(
              sendReceivedObject.parameters.filter
            )) {
              for (const item of sendReceivedObject.parameters.filter[
                dimension
              ]) {
                const singleItem = {};
                singleItem[item] = { isSelected: true };
                if (botScopeItems[dimension] === undefined) {
                  botScopeItems[dimension] = {};
                }
                Object.assign(botScopeItems[dimension], singleItem);
              }
            }
            this.scopeDataService.setFilter(true);
            this.finalChatBotObject['filter'] = botScopeItems;
          } else {
            this.scopeDataService.setFilter(false);
          }
          if (sendReceivedObject.parameters.viewBy !== undefined) {
            const url = sendReceivedObject.component.split('/');
            const target = url[url.length - 1];
            this.dropDownListService.setSelectedValue(
              {
                target,
                selectedList: sendReceivedObject.parameters.viewBy[0],
              },
              true
            );
          }
          if (this.finalChatBotObject['time'] !== undefined) {
            this.timeSelectionDataService.updateYearThroughChatBot(
              this.finalChatBotObject['time']
            );
          }
        }
      }

      /* --------------------- Checking Predictive cost ------------------- */
      if (sendReceivedObject.enablePredictiveCost !== undefined) {
        const enablePredictiveCost = sendReceivedObject.enablePredictiveCost;
        if (enablePredictiveCost) {
          this.finalChatBotObject['isPredictive'] = true;
        }
      }
      const path = sendReceivedObject.component.split('/');
      this.scopeDataService.onUpdateChatBotApplication(this.finalChatBotObject);
      this.finalChatBotObject = {};

      this.scopeDataService.settype('chatbot');

      if (targetComponent !== 'help') {
        if (!this.notApplicableRoutes.includes(path[path.length - 1])) {
          if (path.length >= 2) {
            if (!this.notApplicableRoutes.includes(path[path.length - 2])) {
              this.scopeDataService.setBotMethod(true);
            } else {
              this.scopeDataService.setBotMethod(false);
              this.router.navigate(['/app/' + targetComponent]);
            }
          } else {
            this.scopeDataService.setBotMethod(true);
          }
        } else {
          this.scopeDataService.setBotMethod(false);
          this.router.navigate(['/app/' + targetComponent]);
        }
        if (path[path.length - 1] === 'predictivecostopportunities') {
          this.router.navigate(['/app/opportunities']);
        }
      }
      if (window.location.hash.substr(6) === targetComponent) {
        this.pageLeave();
      }
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.pageLeave();
        }
      });
    }
  }

  pageLeave() {
    // this.onFavorite.emit(true);
    this.ngxZendeskWebwidgetService.hide();
    this.chatBotMessageContainer = false;
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.send();
      this.onSend = true;
      this.showPanel = 'hide';
    }
  }

  private send(value?: any) {
    let message: any;
    if (value !== undefined) {
      message = value;
    } else {
      message = this.userInput;
    }

    const customerMessageObject = {
      CHAT_CONVERSATION_ID: '1a5918bd-b334-4747-8d71-627e1dfaeb79',
      CHAT_FROM: 'U',
      CREATED_DATE: 1505733828604,
      MESSAGE: message,
      response: '',
    };
    this.botSend(customerMessageObject);
    this.showChatBotErrorMessage = false;
  }

  private botSend(obj) {
    if (obj instanceof Object) {
      // Get Chat bot URL
      const targetURLObject = this.applicationURl.getURLObject({
        target: 'bot',
        service: 'send',
      });
      if (targetURLObject instanceof Object) {
        if (targetURLObject.url !== undefined) {
          this.userInput = null;
          const payload = {
            availableScopes: this.availableScopes(),
            scope: this.baseService.getCurrentApplicationScope(),
            userId: this.baseService.getUserInformation().userId,
            tenantId: this.customerConfigurationService.getEnvironmentDetails()
              .tenantId,
            query: obj.MESSAGE,
            chatId: obj.chatId !== undefined ? obj.chatId : '',
          };

          const sendPayload = {
            payload,
          };
          this.botService.send(sendPayload).subscribe(
            (data) => {
              if (data instanceof Object) {
                if (data.responseStatus instanceof Object) {
                  switch (data.responseStatus.code) {
                    case 200:
                    case 500:
                      this.onSend = false;
                      this.sendReceivedObject = Object.assign({}, data, {
                        payload,
                      });
                      this.dataStorageService.setBotServiceName(
                        this.sendReceivedObject
                      );
                      this.checkView(this.sendReceivedObject);
                      break;
                    default:
                      break;
                  }
                }
              }
            },
            (error) => {
              console.log('Send Error');
            },
            () => {
              this.userInput = null;
            }
          );
          // this.scrollBottom = true;
        }
      }
    }
  }

  private checkView(sendReceivedObject) {
    if (sendReceivedObject instanceof Object) {
      if (sendReceivedObject.inlineView === false) {
        this.scopeDataService.clearPredictiveCostFlag();
        this.scopeDataService.clearDefaultData();
        if (sendReceivedObject.component !== undefined) {
          this.onNavigateFormChatBot(sendReceivedObject);
        }
      } else {
        if (sendReceivedObject.action === 'undefined.action') {
          this.showChatBotErrorMessage = true;
        }
      }
    }
  }

  availableScopes() {
    const listArray: Array<string> = [];
    for (const i of this.scopeList) {
      listArray.push(i.view);
    }
    return listArray;
  }

  messageClick(message) {
    this.send(message);
  }

  onClickedOutside($event) {
    this.showPanel = 'hide';
  }

  searchButtonClick() {
    this.userInput = 'lookup for ';
    this.SearchElement.first.nativeElement.focus();
  }
  tabClick($event) {
    this.selectedTabName = $event ? $event.view : 'favorites';
  }

  ngOnDestroy() { }
}
