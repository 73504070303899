import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class RiskConfigurationEmitterService {
    riskConfigObject: EventEmitter<any> = new EventEmitter<any>();

    emitConfiguredRiskObject(value) {
        this.riskConfigObject.emit(value)
    }
    getConfiguredRiskObject() {
        return this.riskConfigObject
    }
}
