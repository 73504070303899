import { ColDef } from '@ag-grid-community/all-modules';
import { Injectable } from '@angular/core';
import { ApiServiceProvider } from 'app/api-service';
import { DateUtils } from 'app/modules/common/utills/DateUtils.service';
import { CustomFilterComponent } from 'app/modules/core/custom-filter/custom-filter.component';
import { DateRenderComponent } from 'app/modules/core/grid/date.render.component';
import { GridDropdownComponent } from 'app/modules/core/grid/grid-dropdown/grid-dropdown.component';
import { GridnumericEditorComponent } from 'app/modules/core/grid/gridNumericEditor/gridNumericeditor.component';
import { HTTP_REQUEST_TYPES } from 'app/modules/npi/npiconstants';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

const INITIAL_VALUE = 'initialValue';
const LATEST_VALUE = 'latestValue';
@Injectable({
  providedIn: 'root',
})
export class IndustryNewsEventsService {
  apiServicePath = '/IndustryNewsPlatformService';
  uniqueKey = 'eventId';
  env = environment;
  countryRegionMap = null;
  mappedRegion = '';
  mappedLatLongs = null;
  latLongMap = null;
  changedValMap = {};
  editedRows = [];
  errorList = [];
  headers = [];
  eventTypes = [];
  statusList = ['Active', 'Inactive'];
  tenantId = null;
  tenants = []
  constructor(private apiServiceProvider: ApiServiceProvider) { }

  execute(payLoad: any) {
    const defaultPayLoadJSON = {
      targetedType: 'IndustryNews',
      formParams: {},
      method: 'GET',
      pathParams: {},
      payload: {},
      servicePath: this.apiServicePath,
    };
    const payLoadJSON = Object.assign(defaultPayLoadJSON, payLoad);
    return this.apiServiceProvider.post(payLoadJSON);
  }

  getNewsEvents(params: any) {
    const payLoad = {
      formParams: params ? { ...params } : {},
      method: 'GET',
      servicePath: `${this.apiServicePath}/events`,
    };
    return this.execute(payLoad);
  }

  saveNewsEventById(body: any) {
    const payLoad = {
      payload: body ? body : {},
      method: 'POST',
      servicePath: `${this.apiServicePath}/events/${body.id}`,
    };
    return this.execute(payLoad);
  }

  getFilterDimensions() {
    const requestPayload = {
      targetedType: 'riskManagementService',
      method: 'GET',
      servicePath: `risk/event/filters`,
    };
    return this.apiServiceProvider.post(requestPayload);
  }

  getEventData(filterPayload) {
    const requestPayload = {
      targetedType: 'riskManagementService',
      method: 'POST',
      servicePath: `events/list`,
      payload: filterPayload,
    };
    return this.apiServiceProvider.post(requestPayload);
  }

  getEventGridHeaders(queryParams) {
    const payload = {
      targetedType: 'CustomerConfigService',
      method: 'POST',
      generic: true,
      formParams: queryParams,
      servicePath: `/widgetManagement/getWidgetTabHeaders`,
    };
    return this.apiServiceProvider.post(payload);
  }

  parseHeaders(data, headerMap, showMenuTabs = true) {
    const agGridHeaders = [];
    (data || []).forEach((header) => {
      let headerObj: ColDef = {
        field: header.headerInfo.field,
        headerName: header.headerInfo.displayName,
        tooltipField: header.headerInfo.field,
        // editable: header.headerInfo.editable || false,
        editable: false,
        headerComponentParams: header,
        cellClass: this.getCellClass.bind(this),
      };
      if (!showMenuTabs) {
        headerObj.menuTabs = [];
      }
      if (!headerMap.hasOwnProperty(header.headerInfo.field)) {
        return;
      }
      headerObj = {
        ...headerObj,
        ...headerMap[header.headerInfo.field],
      };

      switch (headerObj.field) {
        case 'title':
          headerObj.filterFramework = CustomFilterComponent;
          headerObj.filterParams = {
            applyButton: true,
            stringModifier: false,
          };
          // headerObj.editable = true;
          break;
        case 'description':
          headerObj.width = 300;
          headerObj.filter = 'agTextColumnFilter';
          headerObj.filterParams = {
            suppressAndOrCondition: true,
            clearButton: true,
          };
          break;
        case 'severity':
          headerObj.width = 115;
          headerObj.filter = 'agNumberColumnFilter';
          headerObj.filterParams = {
            suppressAndOrCondition: true,
            clearButton: true,
          };
          headerObj.cellEditorFramework = GridnumericEditorComponent;
          break;
        case 'createdDate':
        case 'updatedDate':
          headerObj.tooltipField = undefined;
          headerObj.cellRendererFramework = DateRenderComponent;
          headerObj.cellRendererParams = {
            format: {
              dateFormat: 'MMM d, y, h:mm a',
            },
          };
          headerObj.filter = 'agDateColumnFilter';
          headerObj.filterParams = {
            comparator: (filterLocalDateAtMidnight, cellValue) => {
              const dateAsString = DateUtils.getDatewithDefaultTimeZone(
                cellValue
              ).toDate();

              if (dateAsString === null) {
                return 0;
              }

              const day = dateAsString.getDate();
              const month = dateAsString.getMonth();
              const year = dateAsString.getFullYear();
              const cellDate = new Date(year, month, day);

              // Now that both parameters are Date objects, we can compare
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            },
            browserDatePicker: true,
            suppressAndOrCondition: true,
            clearButton: true,
          };
          if (headerObj.field === 'createdDate') {
            headerObj.width = 160;
          }
          if (headerObj.field === 'updatedDate') {
            headerObj.width = 175;
          }
          break;
        case 'type':
        case 'status':
          headerObj.filterFramework = CustomFilterComponent;
          headerObj.filterParams = {
            applyButton: true,
            stringModifier: false,
          };
          if (headerObj.field === 'type') {
            headerObj.width = 160;
          }
          if (headerObj.field === 'status') {
            headerObj.width = 110;
          }
          /* headerObj.cellEditor = 'agRichSelectCellEditor';
          headerObj.cellEditorParams = {
            values: [],
          };
          headerObj.cellRenderer = (params) => {
            return `<div class="full-width-10 text-style pull-left">${params.value || ''
              }</div>
            <div class="pull-right">
                <i class="ld-icon-arrow-down ld-blue font-size-10" id="dropdownArrow"></i>
            </div>`;
          }; */
          break;
        case 'country':
        case 'state':
        case 'city':
        case 'siteImpacted':
          headerObj.filter = 'agTextColumnFilter';
          headerObj.filterParams = {
            suppressAndOrCondition: true,
            clearButton: true,
          };

          /* headerObj.cellRenderer = (params) => {
            return `<div class="full-width-10 text-style pull-left">${params.value}</div>
                <div class="pull-right">
                    <i class="ld-icon-arrow-down ld-blue font-size-10" id="dropdownArrow"></i>
                </div>`;
          };

          headerObj.cellEditorFramework = GridDropdownComponent;
          headerObj.cellEditorParams = {
            url: header.headerInfo.url,
            values: [],
            searchOnInit: true,
            type: 'risk',
          };
          if (header.headerInfo.defaultParameter) {
            headerObj.cellEditorParams['defaultParameter'] =
              header.headerInfo.defaultParameter;
          } else {
            headerObj.cellEditorParams['defaultParameter'] = {
              tenantId: this.tenantId,
            };
          } */
          break;
        case 'vendorImpacted':
          headerObj.cellRenderer = (params) => {
            params.value =
              params.value instanceof Array
                ? params.value.join(', ') || null
                : params.value || null;
            return `<div class="full-width-10 text-style pull-left">${params.value || ''}</div>`;
            // return `<div class="full-width-10 text-style pull-left">${params.value || ''
            //   }</div>
            //   <div class="pull-right">
            //       <i class="ld-icon-arrow-down ld-blue font-size-10" id="dropdownArrow"></i>
            //   </div>`;
          };
          /* headerObj.cellEditorFramework = GridDropdownComponent;
          headerObj.cellEditorParams = {
            url: header.headerInfo.url,
            values: [],
            searchOnInit: true,
            type: 'risk',
            itemType: 'array',
            multiselect: true,
          };
          if (header.headerInfo.defaultParameter) {
            headerObj.cellEditorParams['defaultParameter'] =
              header.headerInfo.defaultParameter;
          } else {
            headerObj.cellEditorParams['defaultParameter'] = {
              tenantId: this.tenantId,
            };
          } */
          break;
      }

      if (header.headerInfo.hasOwnProperty('hidden')) {
        headerObj['hide'] = header.headerInfo.hidden;
      }

      if (headerObj.editable) {
        if (!headerObj.hasOwnProperty('cellEditorParams')) {
          headerObj.cellEditorParams = {};
        }

        if (header.headerInfo.validations) {
          headerObj.cellEditorParams['isMandatory'] =
            header.headerInfo.validations.required;
        }
      }
      agGridHeaders.push(headerObj);
    });
    return agGridHeaders;
  }

  parseFilterDimensions(filterDims) {
    // const eventFilters = [];
    filterDims.forEach((filterDim) => {
      switch (filterDim.view) {
        case 'vendor_impacted':
          filterDim['defaultParameter'] = {
            tenantId: -100
          }
          break;
        case 'type':
          filterDim['data'] = this.eventTypes.map((eventType) => ({
            val: eventType,
            displayName: eventType,
          }));
          break;
        case 'status':
          filterDim['data'] = this.statusList.map((eventStatus) => ({
            val: eventStatus,
            displayName: eventStatus,
          }));
          break;
        case 'state':
        case 'city':
          filterDim.type = 'text';
          break;
        case 'source_id':
          filterDim['data'] = this.tenants.map((eventType) => ({
            val: eventType,
            displayName: eventType,
          }));
          break
      }
    });
    return filterDims;
  }

  getPrimaryCategories() {
    const payLoad = {
      formParams: {},
      method: 'GET',
      servicePath: `${this.apiServicePath}/news/categories/primary`
    };
    return this.execute(payLoad);
  }

  getSecondaryCategories() {
    const payLoad = {
      formParams: {},
      method: 'GET',
      servicePath: `${this.apiServicePath}/news/categories/secondary`
    };
    return this.execute(payLoad);
  }

  getSourceDetails() {
    const payLoad = {
      formParams: {},
      method: 'GET',
      servicePath: `${this.apiServicePath}/news/categories/source`
    };
    return this.execute(payLoad);
  }

  getEventTypeList(): Observable<any> {
    const payload = {
      targetedType: `riskManagementService`,
      method: 'GET',
      servicePath: `risk/search/type`,
    };
    return this.apiServiceProvider.post(payload);
  }

  getTenantIdList(): Observable<any> {
    const payload = {
      targetedType: `riskManagementService`,
      method: 'GET',
      servicePath: `/risk/search/srn/tenantIds`,
    };
    return this.apiServiceProvider.post(payload);
  }

  getCellClass(params) {
    let cellClass = '';
    const errorFlag = `${params.colDef.field}_valid`;
    const modifiedFlag = `${params.colDef.field}_modified`;
    if (params.colDef.editable !== undefined && params.colDef.editable) {
      if (
        params.data[errorFlag] !== undefined &&
        params.data[errorFlag] !== null
      ) {
        cellClass = 'grid-cell-value-error';
      } else {
        if (
          params.data[modifiedFlag] !== undefined &&
          params.data[modifiedFlag]
        ) {
          cellClass = 'grid-cell-value-changed';
        } else {
          cellClass = 'grid-cell-editable';
        }
      }
    }
    return cellClass;
  }

  getCountryRegionMap(countryRegionMap?) {
    const payload = {
      targetedType: `${this.env.content.contentManagementService}`,
      method: HTTP_REQUEST_TYPES.GET,
      servicePath: `${this.env.content.contentManagementService}/vendor/search/countrymap`,
    };
    this.apiServiceProvider.post(payload).subscribe((regionResponse) => {
      if (regionResponse && regionResponse.result) {
        if (countryRegionMap) {
          countryRegionMap = regionResponse.result;
        }
        this.countryRegionMap = regionResponse.result;
      }
    });
  }

  getCityMap(latLongMap?) {
    const payload = {
      targetedType: `${this.env.content.contentManagementService}`,
      method: HTTP_REQUEST_TYPES.GET,
      servicePath: `${this.env.content.contentManagementService}/vendor/search/citymap`,
    };
    this.apiServiceProvider.post(payload).subscribe((latLongResponse) => {
      if (latLongResponse && latLongResponse.result) {
        if (latLongMap) {
          latLongMap = latLongResponse.result;
        }
        this.latLongMap = latLongResponse.result;
      }
    });
  }

  setRegion(value) {
    this.mappedRegion = this.countryRegionMap[value] || '';
  }

  getRegion() {
    return this.mappedRegion;
  }

  setLatLongs(value) {
    this.mappedLatLongs = this.latLongMap[value] || null;
  }

  getLatLongs() {
    return this.mappedLatLongs;
  }

  createNewEvent(newEventPayload) {
    const requestPayload = {
      targetedType: 'riskManagementService',
      method: 'POST',
      servicePath: `events/createEvent`,
      payload: newEventPayload,
    };
    return this.apiServiceProvider.post(requestPayload);
  }

  updateEvent(eventPayload) {
    const requestPayload = {
      targetedType: 'riskManagementService',
      method: 'PUT',
      servicePath: `events/list`,
      payload: eventPayload,
    };
    return this.apiServiceProvider.post(requestPayload);
  }

  calculateErrors(params, keyVariable) {
    const editedColumn = params.colDef.field;
    const errorFlag = editedColumn + '_valid';

    const errObj: object = {};
    errObj[keyVariable] = params.data[keyVariable];
    errObj[editedColumn] = params.newValue;
    errObj[errorFlag] = params.data[errorFlag];

    if (this.errorList.length) {
      const matchedErrors = this.errorList.filter(
        (error) =>
          error[keyVariable] === errObj[keyVariable] &&
          error.hasOwnProperty(errorFlag)
      );

      if (!matchedErrors.length) {
        this.errorList.push(errObj);
      }
    } else {
      this.errorList.push(errObj);
    }
  }

  removeErrorsFromList(params, keyVariable) {
    const errorFlag = params.colDef.field;
    const keyColumn = params.data[keyVariable];
    const keyColumnErrors: any[] = this.errorList.filter(
      (error) =>
        error[keyVariable] === keyColumn && error.hasOwnProperty(errorFlag)
    );
    keyColumnErrors.filter((keyColumnError) => {
      const errorIndex = this.errorList.findIndex((element, index, array) => {
        if (
          element[keyVariable] === keyColumnError[keyVariable] &&
          element.hasOwnProperty(errorFlag)
        ) {
          return true;
        }
      });
      if (errorIndex > -1) {
        this.errorList.splice(errorIndex, 1);
      }
    });
  }

  mandatoryValidation(params, keyVariable) {
    const modifiedColumnDefObj = this.headers.filter(
      (header) => header.field === params.colDef.field
    )[0];
    const errorFlag = `${params.colDef.field}_valid`;
    const isMandatory = modifiedColumnDefObj.cellEditorParams.hasOwnProperty(
      'isMandatory'
    )
      ? modifiedColumnDefObj.cellEditorParams.isMandatory
      : false;
    if (isMandatory) {
      const mandatoryError = {
        type: 'NOTEMPTY',
        info: null,
        errorMessage: `${modifiedColumnDefObj.headerName} should not be empty.`,
      };
      if (params.newValue !== null && params.newValue !== '') {
        params.data[errorFlag] = null;
        this.removeErrorsFromList(params, keyVariable);
      } else {
        params.data[errorFlag] = mandatoryError;
        this.calculateErrors(params, keyVariable);
      }
    }
  }

  prepareEditedRows(editedRow, keyVariable) {
    const editedRowId = editedRow[keyVariable];

    if (!this.editedRows.length) {
      this.editedRows.push(editedRow);
    } else {
      const filteredEditedRow = this.editedRows.filter(
        (match) => match[keyVariable] === editedRowId
      );
      if (filteredEditedRow.length) {
        filteredEditedRow[0] = editedRow;
      } else {
        this.editedRows.push(editedRow);
      }
    }
  }

  prepareChangedValueMap(params) {
    if (params.newValue === params.oldValue) {
      return;
    }
    const columnName = params.field;
    const rowPrimaryKeyValue = params.keyId;
    const currChangedRow = this.changedValMap[rowPrimaryKeyValue];

    if (!!currChangedRow) {
      const currColumn = currChangedRow[columnName];
      if (!!currColumn) {
        currColumn[LATEST_VALUE] =
          params.newValue !== null ? params.newValue : '';
      } else {
        currChangedRow[columnName] = {};
        currChangedRow[columnName][INITIAL_VALUE] =
          params.oldValue !== null ? params.oldValue : '';
        currChangedRow[columnName][LATEST_VALUE] =
          params.newValue !== null ? params.newValue : '';
      }
    } else {
      const currEditedObj = {};
      currEditedObj[rowPrimaryKeyValue] = {};
      currEditedObj[rowPrimaryKeyValue][columnName] = {};
      currEditedObj[rowPrimaryKeyValue][columnName][INITIAL_VALUE] =
        params.oldValue !== null ? params.oldValue : '';
      currEditedObj[rowPrimaryKeyValue][columnName][LATEST_VALUE] =
        params.newValue !== null ? params.newValue : '';
      this.changedValMap = Object.assign({}, this.changedValMap, currEditedObj);
    }
  }

  updateModifiedRows(params, keyVariable) {
    const editRow = params.data;
    if (Object.keys(this.changedValMap).length > 0) {
      for (const rowKeyValue in this.changedValMap) {
        if (this.changedValMap[rowKeyValue] !== undefined) {
          const currRowMapValues = this.changedValMap[rowKeyValue];
          if (!!currRowMapValues && Object.keys(currRowMapValues).length > 0) {
            let isAnyActualChangeExistInEntireRow = false;
            for (const colKeyValue in currRowMapValues) {
              if (currRowMapValues[colKeyValue] !== undefined) {
                const modifiedFlag = colKeyValue + '_modified';
                if (
                  currRowMapValues[colKeyValue][LATEST_VALUE] !==
                  currRowMapValues[colKeyValue][INITIAL_VALUE]
                ) {
                  isAnyActualChangeExistInEntireRow = true;
                  editRow[modifiedFlag] = true;
                  break;
                } else {
                  editRow[modifiedFlag] = false;
                }
              }
            }
            if (!isAnyActualChangeExistInEntireRow) {
              const filteredEditedRows = this.editedRows.filter(
                (editedRow) => editedRow[keyVariable] !== rowKeyValue
              );
              this.editedRows = filteredEditedRows;
            }
            for (const colKeyValue in currRowMapValues) {
              if (currRowMapValues[colKeyValue] !== undefined) {
                const modifiedFlag = colKeyValue + '_modified';
                if (
                  currRowMapValues[colKeyValue][LATEST_VALUE] !==
                  currRowMapValues[colKeyValue][INITIAL_VALUE]
                ) {
                  editRow[modifiedFlag] = true;
                } else {
                  editRow[modifiedFlag] = false;
                }
              }
            }
          }
        }
      }
    }
  }

  updateEventsData(editedPayload) {
    const requestPayload = {
      targetedType: 'riskManagementService',
      method: 'PUT',
      servicePath: `events/list`,
      payload: editedPayload,
    };
    return this.apiServiceProvider.post(requestPayload);
  }

  createManualNews(requestPayload) {
    const payLoad = {
      method: 'POST',
      payload: requestPayload,
      servicePath: `${this.apiServicePath}/news/create`
    }
    return this.execute(payLoad);
  }

  syncEvents() {
    const requestPayload = {
      targetedType: 'riskManagementService',
      method: 'POST',
      servicePath: `jobs/async`,
      formParams: {
        jobName: 'GNOW_IT_EVENTS'
      },

    };
    return this.apiServiceProvider.post(requestPayload);
  }

  getEventsJobStatus() {
    const requestPayload = {
      targetedType: 'riskManagementService',
      method: 'GET',
      servicePath: `jobs/status`,
      formParams: {
        jobName: 'GNOW_IT_EVENTS'
      },

    };
    return this.apiServiceProvider.post(requestPayload);
  }
}
