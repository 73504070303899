import * as _ from 'lodash';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';
import { SearchListService } from './search-list.service';

export interface SearchListInfo {
  type: string,
  additionalFilters: any[],
  servicePath?: string,
  targetedType?: string,
  method?: string,
  noDataMessage?: string,
  staticItems?: any[],
  defaultSelectedItems?: []
}
@Component({
  selector: 'cprt-search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.sass'],
  providers: [SearchListService]
})
export class SearchListComponent implements OnInit {

  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION
  @Output() onSelectionChange = new EventEmitter()
  @Input() listMetaInfo = this.initListMetaInfo({} as SearchListInfo)
  searchList = {
    data: [],
    totalRecords: 0,
    selectAll: false,
    showTitle: false,
    showSearchBox: true
  }
  selectedItems = []
  searchItem = ''
  showPreloader = true
  additionalFilterSelected = false
  showSelectAllSection = false
  additionalFilterObject = []
  enableClearAll = true

  loaderConfig = {
    backdropBackgroundColour: 'rgba(255,255,255,0.3)',
    fullScreenBackdrop: 'true',
    primaryColour: '#1C4E84',
    secondaryColour: '#1C4E84',
    tertiaryColour: '#1C4E84'
  }

  constructor(private searchListService: SearchListService) { }

  ngOnInit() {
    if (this.listMetaInfo.type === 'dynamic') {
      this.listMetaInfo.defaultSelectedItems.forEach((selectedItem: any) => {
        if(!this.selectedItems.find(x => x.displayName === selectedItem.displayName) ) {
          this.setDefaultSelection(selectedItem.displayName)
        }
      })
    }
    this.findListItems()
  }

  initListMetaInfo(listMetaInfo: SearchListInfo) {
    listMetaInfo.type = 'static'
    listMetaInfo.additionalFilters = []
    listMetaInfo.servicePath = null
    listMetaInfo.method = null
    listMetaInfo.targetedType = null
    listMetaInfo.noDataMessage = 'No items found on the searched criteria'
    return listMetaInfo
  }

  findListItems() {
    switch (this.listMetaInfo.type) {
      case 'static':
        this.getStaticListItems()
        break
      case 'dynamic':
        this.getDynamicListItems()
        break
    }
  }

  getStaticListItems() {
    this.showPreloader = true
    this.searchList.data = _.cloneDeep(this.listMetaInfo.staticItems)
    this.searchList.totalRecords = this.searchList.data.length
    this.setSelectedItems(this.searchList.data)
    this.showSelectAllSection = true
    setTimeout(() => {
      this.showPreloader = false
    }, 0)
  }

  setSelectedItems(data) {
    data.forEach(listItem => {
      this.listMetaInfo.defaultSelectedItems.forEach((selectedItem: any) => {
        let expression = null
        switch (this.listMetaInfo.type) {
          case 'static':
            expression = listItem.displayName === selectedItem.displayName
            break
          case 'dynamic':
            expression = listItem === selectedItem.displayName
            break
        }
        if (expression) {
          this.selectedItems.push(_.cloneDeep(selectedItem))
        }
      })
    })
  }

  onSearchItem() {
    this.findListItems()
  }

  getDynamicListItems() {
    const requestParams = {
      targetedType: this.listMetaInfo.targetedType,
      method: this.listMetaInfo.method,
      servicePath: this.listMetaInfo.servicePath,
      payload: {
        filter: this.isAdditionalFiltersAdded() ? this.getAdditionalFilters() : [],
        limit: 500,
        offset: 0,
        key: this.searchItem
      }
    }
    this.additionalFilterSelected = this.isAdditionalFiltersAdded() ? true : false
    this.showPreloader = true
    this.searchList.data = []
    this.searchListService.getListItems(requestParams).subscribe(
      (supplierListResponse) => {
        if (supplierListResponse && supplierListResponse.result) {
          const value = supplierListResponse.result.suggestions

          this.searchList.data = this.convertToLocalFormat(value)
          this.searchList.totalRecords = supplierListResponse.result.numberOfHits
          this.showSelectAllSection
            = (this.additionalFilterSelected || this.searchItem.length)
              ? this.searchList.totalRecords
                ? true
                : false
              : false
          setTimeout(() => {
            this.showPreloader = false
          }, 0)
        } else {
          this.additionalFilterSelected = false
        }
      }
    )
  }

  isAdditionalFiltersAdded() {
    return this.listMetaInfo.additionalFilters.filter(item => item.filterCriteria !== null).length ? true : false
  }

  getAdditionalFilters() {
    return this.listMetaInfo.additionalFilters.filter(item => item.filterCriteria !== null)
  }

  convertToLocalFormat(data) {
    const localArray = []
    if (this.selectedItems) {
      data.forEach((val) => {
        const selectedItem: any[] = this.selectedItems.filter(
          (item) => item.displayName === val
        )
        const isSelected = selectedItem.length ? true : false
        localArray.push({
          displayName: val,
          selected: isSelected,
        })
      })
    } else {
      data.forEach((val) => {
        localArray.push({
          displayName: val,
          selected: false,
        })
      })
    }
    return localArray
  }

  setDefaultSelection(selectedItem) {
    this.selectedItems.push({
      val: selectedItem,
      displayName: selectedItem,
      selected: true,
    })
  }

  onClearAll(event) {
    event.stopPropagation()

    if (!this.searchItem.length) {
      this.selectedItems = []
      this.searchList.data.forEach(supplier => supplier.selected = false)
    } else {
      this.searchList.selectAll = false
      this.searchList.data.forEach(supplier => supplier.selected = false)

      const sourceList = this.searchList.data.map(sup => sup.displayName)
      this.selectedItems = this.selectedItems.filter(sup => !sourceList.includes(sup.displayName))
    }
    this.onSelectionChange.emit(this.selectedItems)
  }

  onSelectAll(event) {
    event.stopPropagation()
    this.searchList.selectAll = true
    this.searchList.data.forEach(supplier => supplier.selected = true)

    this.selectedItems = _.cloneDeep(this.searchList.data)
    this.onSelectionChange.emit(this.selectedItems)
  }

  onSelectItem(event, supplier) {
    event.stopPropagation()
    if (supplier.selected) {
      this.selectedItems.push(JSON.parse(JSON.stringify(supplier)))
    } else {
      this.selectedItems = this.selectedItems.filter(
        (filterItem) => filterItem.displayName !== supplier.displayName
      )
    }
    this.onSelectionChange.emit(this.selectedItems)
  }


  clearSelection() {
    this.selectedItems = this.selectedItems.filter(supplier => supplier.selected === true)
    this.onSelectionChange.emit(this.selectedItems)
  }

}
