export const FILTERCONDITIONS = {
  date: [
    {
      displayName: 'Equal',
      filterOperatorEnum: 'equals',
    },
    {
      displayName: 'Not Equal',
      filterOperatorEnum: 'notEqual',
    },
    {
      displayName: 'Less than',
      filterOperatorEnum: 'lessThan',
    },
    {
      displayName: 'Greater than',
      filterOperatorEnum: 'greaterThan',
    },
    {
      displayName: 'In Range',
      filterOperatorEnum: 'inRange',
    },
  ],
  varchar: [
    {
      displayName: 'Equal',
      filterOperatorEnum: 'equals',
    },
    {
      displayName: 'Not Equal',
      filterOperatorEnum: 'notEqual',
    },
    {
      displayName: 'Contains',
      filterOperatorEnum: 'contains',
    },
    {
      displayName: 'Not Contains',
      filterOperatorEnum: 'notContains',
    },
    {
      displayName: 'Starts with',
      filterOperatorEnum: 'startsWith',
    },
    {
      displayName: 'Ends with',
      filterOperatorEnum: 'endsWith',
    },
  ],
  text: [
    {
      displayName: 'Equal',
      filterOperatorEnum: 'equals',
    },
    {
      displayName: 'Not Equal',
      filterOperatorEnum: 'notEqual',
    },
    {
      displayName: 'Contains',
      filterOperatorEnum: 'contains',
    },
    {
      displayName: 'Not Contains',
      filterOperatorEnum: 'notContains',
    },
    {
      displayName: 'Starts with',
      filterOperatorEnum: 'startsWith',
    },
    {
      displayName: 'Ends with',
      filterOperatorEnum: 'endsWith',
    },
  ],
  set: [
    {
      view: 'isequalto',
      filterOperatorEnum: 'equals',
      displayName: 'Equal'
    },
    {
      view: 'isnotequalto',
      filterOperatorEnum: 'notEqual',
      displayName: 'Not Equal'
    },
    {
      view: 'isin',
      filterOperatorEnum: 'contains',
      displayName: 'Is in'
    },
    {
      view: 'isnotin',
      filterOperatorEnum: 'notContains',
      displayName: 'Is not in'
    }
  ],
  boolean: [
    {
      displayName: 'true',
      filterOperatorEnum: true,
    },
    {
      displayName: 'false',
      filterOperatorEnum: false,
    },
  ],
  number: [
    {
      displayName: 'Equal',
      filterOperatorEnum: 'equals',
    },
    {
      displayName: 'Not Equal',
      filterOperatorEnum: 'notEqual',
    },
    {
      displayName: 'Less than',
      filterOperatorEnum: 'lessThan',
    },
    {
      displayName: 'Less than or Equal',
      filterOperatorEnum: 'lessThanOrEqual',
    },
    {
      displayName: 'Greater than',
      filterOperatorEnum: 'greaterThan',
    },
    {
      displayName: 'Greater than or Equal',
      filterOperatorEnum: 'greaterThanOrEqual',
    },
    {
      displayName: 'In Range',
      filterOperatorEnum: 'inRange',
    },
  ],
  predefined: [
    {
      view: 'isequalto',
      filterOperatorEnum: 'equals',
      displayName: 'Equal'
    },
    {
      view: 'isnotequalto',
      filterOperatorEnum: 'notEqual',
      displayName: 'Not Equal'
    },
    {
      view: 'isin',
      filterOperatorEnum: 'contains',
      displayName: 'Is in'
    },
    {
      view: 'isnotin',
      filterOperatorEnum: 'notContains',
      displayName: 'Is not in'
    }
  ]
};
