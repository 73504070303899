import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnChanges
} from '@angular/core';
import { DataStorageService } from 'app/modules/common/data-storage.service'
import { LeftNavService } from './shared/left-nav.service';
import { BaseService } from 'app/modules/common/base.service';
import { UtilService } from 'app/modules/common/utills/util.service';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { CustomerConfigurationService } from '../../common/customerConfigurationService';
import { CsAppFeatures } from '../../common/CSAppConfiguration.constants';
import { Router } from '@angular/router';
@Component({
  selector: 'left-nav',
  moduleId: module.id,
  templateUrl: 'left-nav.component.html',
  styleUrls: ['./left-nav.component.sass'],
  providers: [LeftNavService]
})
export class LeftNavComponent implements OnInit, OnChanges {
  constructor(
    private scopeDataService: ScopeDataService,
    private customerConfigurationService: CustomerConfigurationService,
    private dataStorageService : DataStorageService,
    private baseService: BaseService,
    private utilService: UtilService,
    private router: Router
  ) { }
  csApp = false;
  scope: string;
  menuList: Array<object> = [];
  obj: any;
  menuMap: Array<object> = [];
  features: Array<object> = [];
  featuresarray: Array<string> = [];
  @Input() toggleBotAction: boolean;
  @Input() collapsableButton: string;
  type: string;
  @Output() openBot: EventEmitter<boolean> = new EventEmitter<boolean>();



  ngOnInit() {
    if (this.baseService.getCsApp()) {
      this.menuList = CsAppFeatures;
      return;
    }
    this.toggleBotAction = false;
    this.obj = this.baseService.getUserInformation();
    this.scope = this.scopeDataService.getFocusValue();
    if (this.customerConfigurationService.getConfData(this.scope)) {
      this.menuMap = this.customerConfigurationService.getConfData(
        this.scope
      ).leftNavbarFeatures;

    }
    // this.features = this.obj.userFeatureDetailsDTO.features;
    this.getMenuList();
    this.scopeDataService.getFocus().subscribe(val => {
      if (val !== undefined) {
        this.scope = val;
        if (this.customerConfigurationService.getConfData(this.scope)) {
          this.menuMap = this.customerConfigurationService.getConfData(
            this.scope
          ).leftNavbarFeatures;
        }
        this.getMenuList();
      }
    });
  }

  getStatus(target: string): boolean {
    return window.location.hash.split('/')[2] === target;
  }
  getMenuList() {
    this.menuList = [];
    this.menuList = this.menuMap.filter(
      (item) => item['view'] !== 'null' && item['level'] === 0
    );

    this.menuList = this.utilService.getsortedArray(this.menuList, 'order');
    for (const item of this.menuList) {
      switch (item['view']) {
        case 'spendintelligence':
          item['icon'] = 'bar-graph';
          break;
        case 'mi':
          item['icon'] = 'binoculars';
          break;
        case 'np':
          item['icon'] = 'negotiation-playbook';
          break;
        case 'srfx':
          item['icon'] = 'network';
          break;
        case 'npi':
          item['icon'] = 'rocket';
          break;
        case 'contentlabinsights':
          item['icon'] = 'idea';
          break;
        case 'augmentedopportunities':
          item['icon'] = 'labyrinth';
          break;
        case 'supplyrisk':
          item['icon'] = 'radiation';
          break;
        case 'scenariocomparison':
          item['icon'] = 'board';
          break;
        case 'loadingestiondata':
          item['icon'] = 'database';
          break;
        case 'scenarioworkbench':
          item['icon'] = 'refresh';
          break;
        case 'risk-rfi':
          item['icon'] = 'risk-rfi';
          break;
        case 'costlookup':
          item['icon'] = 'search-white';
          break
        case 'reports':
          item['icon'] = 'list-white';
          break;
        case 'interactiveedit':
          item['icon'] = 'pencil';
          break;
        case 'savingsopportunities':
          item['icon'] = 'business-and-finance';
          break;
        case 'opportunities':
          item['icon'] = 'business-and-finance';
          break;
        default:
          item['icon'] = 'navigation';
          break;

      }
    }
     /*this.menuList.unshift({
      id: 999,
      title: 'Dashboard-Metrics',
      view: 'dashboard-metrics'
    });*/
  }
  onTabClick(target) {
    this.scopeDataService.settype('manual');
    this.scopeDataService.setSelectedNavFeature(target);
    this.scopeDataService.setManualSelection(true);
    const urlHash = window.location.hash.split('/');
    // if(!window.location.hash.includes(target)){
    if (urlHash[2] && urlHash[2] !== target) {
      setTimeout(() => {
        if(target === 'srfx'){
          this.dataStorageService.setRfqAccessed(true);
        }
        this.router.navigateByUrl(`/app/${target}`, { skipLocationChange: false }).then(() =>
          this.router.navigate([`/app/${target}`]));
      }, 0);
    }
  }
  public toggleBot() {
    this.toggleBotAction = true;
    this.openBot.emit(this.toggleBotAction);
  }
  ngOnChanges() {
    this.type = this.collapsableButton;
  }
}
