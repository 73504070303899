import { Component, OnInit, Input } from '@angular/core';

import { HelpOptionsList } from './shared/help-options-list.model';
import { HelpOptionsListService } from './shared/help-options-list.service';

@Component({
  selector: 'help-options-list',
  templateUrl: 'help-options-list.component.html',
  styleUrls : ['./help-options-list.component.sass'],
  providers: [HelpOptionsListService]
})

export class HelpOptionsListComponent implements OnInit {
  helpOptionsList: HelpOptionsList[] = [];

  @Input('commandformat') commandformat: Array<any> ;
  constructor(private helpOptionsListService: HelpOptionsListService) { }

  ngOnInit() {

  }
}
