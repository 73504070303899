import { Injectable } from '@angular/core';
import { ApiServiceProvider } from 'app/api-service';

@Injectable({
  providedIn: 'root'
})
export class CreateEventService {

  constructor(private apiServiceProvider: ApiServiceProvider) { }

  getEventDetails(filterPayload) {
    const requestPayload = {
      targetedType: 'riskManagementService',
      method: 'POST',
      servicePath: `insight/eventdetails/list`,
      payload: filterPayload,
    };
    return this.apiServiceProvider.post(requestPayload);
  }

  getNewsArticles(params: any) {
    const payLoad = {
      targetedType: 'riskManagementService',
      method: 'GET',
      servicePath: `events/news`,
      formParams: params ? { ...params } : {},
    };
    return this.apiServiceProvider.post(payLoad);
  }

}
