import { Router, NavigationEnd } from '@angular/router';
import { Injectable, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CustomerConfigurationService } from './customerConfigurationService';
import {CUSTOMER_CS_APP} from '../shared/services/shared-consts';

const scopeFiltersEnabledRoutes = [
  'spendintelligence',
  'savingsopportunities',
  'opportunities',
  'supplyrisk',
  'portfolio-risk',
  'product',
  'bom',
  'transformation',
];
@Injectable()
export class BaseService {
  public scopeList: any[];
  public creatRFQFilter = new BehaviorSubject<any>(undefined);
  public url = new BehaviorSubject<any>(undefined);
  public alertPopupAfterTimeout = new BehaviorSubject<any>(false);
  public scopeVisibility = new BehaviorSubject<any>(undefined);
  public tooltipSubject = new BehaviorSubject<any>(undefined);
  public appLoader = new Subject<boolean>();
  public tooltip: string;
  public csApp = false;
  public scopevsibleArray: any[] = [
    'competitiveness',
    'partmatching',
    'rawmaterial',
    'costlookup',
    'reports',
    'np',
    'driver',
    'detailsspend',
    'details',
    'risk',
    'opprisks',
    'basicalerts',
    'alerts',
    'advancedalerts',
    'negotiationlevers',
    'levers',
    'metadata',
    'settings',
  ];

  currentLandingPageURL: string;
  public userInformation: any;
  public isLogout = true;
  public currentApplicationScope = 'Commodity';
  public navBarStatus = new BehaviorSubject<string>(null);
  logoURL = new BehaviorSubject<any>(null);

  @Output() public userLoggedIn: EventEmitter<any> = new EventEmitter<any>();
  public hideProgressBar: any = new BehaviorSubject<boolean>(true);
  public isRefreshingToken = false;
  public currentRequestsCount = 0;
  public unAuthrizePopup = new BehaviorSubject<any>(false);
  public refreshPage = new BehaviorSubject<any>(false);
  constructor(
    private router: Router,
    private customerConfigurationService: CustomerConfigurationService
  ) {
    if (
      typeof JSON.parse(localStorage.getItem(CUSTOMER_CS_APP)) === 'boolean'
    ) {
      this.csApp = JSON.parse(localStorage.getItem(CUSTOMER_CS_APP));
    }
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        // const routeURLArray: string[] = val['url'].split('/');
        const routeURLArray: string[] = val['urlAfterRedirects'].split('/');
        const laststring = routeURLArray[routeURLArray.length - 1];
        const lastbutonestring = routeURLArray[routeURLArray.length - 2];

        this.scopeList = this.customerConfigurationService.getScopeList();
        if (this.scopeList instanceof Array && this.scopeList.length > 0) {
          const defaultScope = this.scopeList.filter(
            (item) => item.defaultValue
          );
          this.setCurrentApplicationScope(defaultScope[0].title);
        }

        const parentRoute = routeURLArray[2] || null;
        const childRoute = routeURLArray[3] || null;
        const routeSize = routeURLArray.length;

        const currentRouteName =
          routeSize === 3
            ? parentRoute
            : routeSize > 3
            ? childRoute
            : parentRoute;

        this.setPath(currentRouteName);
        if (scopeFiltersEnabledRoutes.indexOf(currentRouteName) !== -1) {
          this.setScopeAvailability(undefined);
          this.seturlarray(routeURLArray);
        } else {
          this.setScopeAvailability('notavailable');
        }
      }
    });
  }

  public setCsApp(val) {
    this.csApp = val;
  }
  public getCsApp() {
    return this.csApp;
  }

  public setProgressBar(val) {
    this.hideProgressBar.next(val);
  }

  public getProgressBar() {
    return this.hideProgressBar.asObservable();
  }

  setLogoURL(url) {
    this.logoURL.next(url);
  }
  getLogoUrl() {
    return this.logoURL.asObservable();
  }

  public scopeavailability(path, path2) {
    let index;
    if (path === 'opprisks' && path2 === 'curatorengine') {
      this.setPath('ceopprisks');
      this.setScopeAvailability('notavailable');
    } else if (path2 === 'np') {
      this.setPath('np');
      this.setScopeAvailability('notavailable');
    }
    if (path === 'detailsspend' || path === 'details' || path === 'summary') {
      if (path2 === 'opprisks') {
        this.setPath('rawmaterial');
        this.setScopeAvailability('notavailable');
      } else {
        this.setPath('driver');
        this.setScopeAvailability('notavailable');
      }
    } else if (path === 'benchmark' || path === 'mi') {
      this.setScopeAvailability('available');
    } else {
      if (
        this.scopevsibleArray.indexOf(path, 0) >= 0 ||
        this.scopevsibleArray.indexOf(path2, 0) >= 0
      ) {
        if (this.scopevsibleArray.indexOf(path, 0) >= 0) {
          index = this.scopevsibleArray.indexOf(path, 0);
          this.setPath(path);
        } else {
          index = this.scopevsibleArray.indexOf(path2, 0);
          this.setPath(path2);
        }
        if (index >= 0) {
          this.setScopeAvailability('notavailable');
        }
      } else {
        this.setScopeAvailability(undefined);
      }
    }
  }
  public seturlarray(arr) {
    this.url.next(arr);
  }
  public geturlarrray() {
    return this.url.asObservable();
  }
  public setPath(val) {
    this.tooltip = val;
  }
  public gettooltip() {
    return this.tooltip;
  }
  public setScopeAvailability(val) {
    this.scopeVisibility.next(val);
  }
  public getScopeAvailability() {
    return this.scopeVisibility.asObservable();
  }
  public setAppLoader(val) {
    this.appLoader.next(val);
  }
  public getAppLoader() {
    return this.appLoader.asObservable();
  }

  public setUserInformation(value) {
    this.userInformation = value;
    this.userLoggedIn.emit(value);
  }
  public setLogout(value) {
    this.isLogout = value;
  }
  public getLogout() {
    return this.isLogout;
  }

  public getUserInformation() {
    return this.userInformation;
  }

  public setCurrentApplicationScope(type: string) {
    this.currentApplicationScope = type;
  }
  public getCurrentApplicationScope() {
    return this.currentApplicationScope;
  }

  public setCreateRFQFilter(target) {
    this.creatRFQFilter.next(target);
  }
  public getCreateRFQFilter() {
    return this.creatRFQFilter.asObservable();
  }
  public setUnAuthrizePopup(val) {
    this.unAuthrizePopup.next(val);
  }

  public getUnAuthrizePopup() {
    return this.unAuthrizePopup.asObservable();
  }

  public setAlertPopupAfterTimeout(val) {
    this.alertPopupAfterTimeout.next(val);
  }
  public getAlertPopupAfterTimeout() {
    return this.alertPopupAfterTimeout.asObservable();
  }
  public setRefreshPage(val) {
    this.refreshPage.next(val);
  }

  public getRefreshPage() {
    return this.refreshPage.asObservable();
  }
  /* to check whether cookie is present in the storage*/
  public isCookiePresent(cname) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i of ca) {
      while (i.charAt(0) === ' ') {
        i = i.substring(1);
      }
      if (i.indexOf(name) === 0) {
        return true;
      }
    }
    return false;
  }
  public setCookie(name: string, value: string, expireDays: number, path = '') {
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie =
      name +
      '=' +
      value +
      '; ' +
      expires +
      (path.length > 0 ? '; path=' + path : '');
  }

  // tslint:disable-next-line: member-ordering
  currencySymbol = '$';
  setCurrencySymbol(currencySymbol = '$') {
    this.currencySymbol = currencySymbol;
    window['currencySymbol'] = currencySymbol;
  }
  getCurrencySymbol() {
    return this.currencySymbol;
  }
  // tslint:disable-next-line: member-ordering
  currencyObject = {
    currencyCode: '',
  };

  setCurrencyObject(currency) {
    this.currencyObject = currency;
    this.setCurrencySymbol(this.currencyObject['currencySymbol']);
  }
  getCurrencyObject() {
    return this.currencyObject;
  }

  setCurrentLandingPageURL(url: string): void {
    this.currentLandingPageURL = url || '';
  }
  getCurrentLandingPageURL(): string {
    return this.currentLandingPageURL;
  }

  setNavBarStatus(target) {
    this.navBarStatus.next(target);
  }

  getNavBarStatus() {
    return this.navBarStatus.asObservable();
  }
}
