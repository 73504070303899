import { Component, Input, OnInit } from '@angular/core';
import { CoverageDashboardService } from 'app/modules/coverage-dashboard/coverage-dashboard.service';
import { data } from 'app/modules/scenario-comparison/graphObjects';
import { Subject } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-filters',
  templateUrl: './dashboard-filters.component.html',
  styleUrls: ['./dashboard-filters.component.sass']
})
export class DashboardFiltersComponent implements OnInit {
  private subject$: Subject<any> = new Subject();
  filterConfig;
  get configuration() {
    return this.filterConfig
  }
  @Input() set configuration(value) {
    this.filterConfig = value.data.data || {}
    this.setFilterData(value);
  }
  filterDefaultValue;
  constructor(private _coverageDashboardService: CoverageDashboardService) {
  }

  ngOnInit() {
    this._coverageDashboardService.getDashboardDataChangeObservable()
      .pipe(takeUntil(this.subject$), throttleTime(1000)).subscribe((globalFilter: any) => {
        if (globalFilter.type === 'GLOBAL_FILTER_CHANGE') {
          this.filterConfig.forEach(filter => {
            if (filter.field === globalFilter.data.field) {
              filter.defaultValue = !filter.defaultValue;
            }
          });
          this.filterDefaultValue = this.filterConfig.filter(i => i.defaultValue == true);
          this._coverageDashboardService.setDashboardDataChangeObservable({
            type: 'FILTER',
            data: this.filterDefaultValue
          });
        }
      });
  }

  setFilterData(value) {
    {
      setTimeout(() => {
        this.filterConfig = value.data.data;
      }, 2000)
    }
  }
  public filterSelected(item) {
    this._coverageDashboardService.setDashboardDataChangeObservable({
      type: 'GLOBAL_FILTER_CHANGE',
      data: item
    });
  }

  ngOnDestroy() {
    this.subject$.next();
    this.subject$.complete();
  }

}
