import { Injectable } from '@angular/core';
import { ApiServiceProvider } from 'app/api-service';
import { Observable } from 'rxjs';
import { RequestData } from 'app/modules/common/models/request.model';
import { ISingeItem } from 'app/modules/common/models/scope/singleItem';



@Injectable()
export class RmAutosearchService {

  constructor(private apiServiceProvider: ApiServiceProvider) { }

  searchMultiELS(terms: Observable<string>, requestData: RequestData) {
    return terms.debounceTime(400)
      .distinctUntilChanged()
      .switchMap(term => this.searchMultiEntriesELS(term, requestData));
  }

  searchMultiEntriesELS(term, requestData) {
    requestData.payload.inputs = [term];
    return this.apiServiceProvider.post(requestData)
  }

  searchCustom(terms: Observable<string>, requestData: RequestData) {
    return terms.debounceTime(400)
      .distinctUntilChanged()
      .switchMap(term => this.searchCustomEntries(term, requestData));
  }

  searchCustomEntries(term, requestData) {
    if (term === '') {
      term = '55'
    }
    if (requestData.formParams !== undefined) {
      requestData.formParams['userInput'] = term;
    }
    return this.apiServiceProvider.post(requestData)
  }


  onConvertArrayForAutoDimnsionSelect(source, dimension: Array<any>) {
    let newObject: any ;
    if (dimension.length > 0) {
      for (const item of source) {
        for (let i = 0; i < dimension.length; i++) {
          const dimensionName = dimension[i];
          if (item['internalName'] === dimensionName) {
            let list_items: any ;
            for (const list of item['list']) {
              let singleItem: ISingeItem<any> ;
              singleItem[list] ;
              Object.assign(list_items, singleItem);
            }
            newObject[item['externalName']] = list_items;
          } else {
            // console.log("dimension name not matched")
          }
        }
      }
    }
    return newObject;
  }


  onConvertArrayObjectKeysWithDimension(source, dimension: Array<any>) {
    let newObject: any ;
    const valuArray: any[] = new Array;
    if (dimension.length > 0) {
      for (const item of source) {
        for (let i = 0; i < dimension.length; i++) {
          const dimensionName = dimension[i];
          if (item['internalName'] === dimensionName) {
            let list_items: any ;
            for (const list of item['list']) {
              const sing = {
                value: '',
                check: false
              }
              sing.value = list;
              valuArray.push(sing)
            }
          } else {
            // "dimension name not matched"
          }
        }
      }
    } else {
      return this.onConvertArrayObjectKeys(source)
    }
    return valuArray;
  }

  onConvertArrayObjectKeys(source) {
    let newObject: any ;
    const valuArray: any[] = new Array;
    for (const item of source) {
      let list_items: any ;
      for (const list of item['list']) {
        const sing = {
          value: '',
          check: false
        }
        sing.value = list;
        valuArray.push(sing)
      }
    }
    return valuArray;
  }

  onConvertCustomMulti(source) {
    const valuArray: any[] = new Array;
    let i = 0;
    for (const item of source) {
      const obj = {
        value: '',
        check: false
      }
      obj.value = item;
      valuArray.push(obj)
      i = i + 1;
      if (i === source.length) {
        break;
      }
    }
    return valuArray;
  }

  onConvertTree(source) {
    const valuArray: any[] = new Array;
    let i = 0;
    for (const result of source) {
      const parent = {
        value: '',
        check: false,
        items: []
      }
      parent.value = result.name;
      if (result.items instanceof Array) {
        for (const item of result.items) {
          const child = {
            value: '',
            check: false,
          }
          child.value = item
          parent.items.push(child)
        }
      } else {
        const child = {
          value: '',
          check: false,
        }
        child.value = result.items
        parent.items.push(child)
      }

      valuArray.push(parent)
      i = i + 1;
      if (i === 100) {
        break;
      }
    }

    return valuArray;
  }

  onConvertMultiTree(source) {
    const valuArray: any[] = new Array;
    let i = 0;
    for (const result of source) {
      const grandParent = {
        value: '',
        check: false,
        parent: []
      }
      const parent = {
        value: '',
        check: false,
        items: []
      }
      grandParent.value = result.name;
      parent.value = result['items'].name
      if (result['items'].items instanceof Array) {
        for (const item of result['items'].items) {
          const child = {
            value: '',
            check: false,
          }
          child.value = item
          parent.items.push(child)
        }
      } else {
        const child = {
          value: '',
          check: false,
        }
        child.value = result.items
        parent.items.push(child)
      }
      grandParent.parent.push(parent)
      valuArray.push(grandParent)
      i = i + 1;
      if (i === 100) {
        break;
      }
    }

    return valuArray;
  }
}
