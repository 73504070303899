import { Injectable } from '@angular/core'
import { ApiServiceProvider } from 'app/api-service'

@Injectable({
  providedIn: 'root',
})
export class FilterElementService extends ApiServiceProvider {
  getFilterData(requestPayload, method = 'GET') {
    const obj = {
      targetedType: requestPayload.targetedType,
      servicePath: requestPayload.servicePath,
      formParams: requestPayload.formParams,
      method
    }
    return this.post(obj)
  }
}
