import {Component} from '@angular/core';

@Component({
    selector: 'child-cell',
    template: `<span *ngIf="hide"><button type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button></span>`,
})
export class DismissComponent  {
    public params: any;
    hide = true;

    agInit(params: any): void {
        this.params = params;
        if(this.params['data']['rfq_state'] !== 'Autonomous'){
            this.hide = false;
        }

    }
}
