import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'ld-dashboard-tile',
  templateUrl: './dashboard-tile.component.html',
  styleUrls: ['./dashboard-tile.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardTileComponent implements OnInit {
  @Input() configuration;
  constructor() { }
  ngOnInit() {
    // console.log(this.configuration);
  }
}
