import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { BaseService } from '../common/base.service';
import { HttpClient } from '@angular/common/http';
import { CustomerConfigurationService } from '../common/customerConfigurationService';
import { CUSTOMER_TOKEN_INFO } from '../shared/services/shared-consts';

@Injectable()
export class UploadDownloadService {
  public envDetails: any;
  constructor(
    private http: HttpClient,
    private baseService: BaseService,
    private customerConfigurationService: CustomerConfigurationService
  ) {
    this.baseService = baseService;
    this.envDetails = this.customerConfigurationService.getEnvironmentDetails();
  }

  public downloadToExcel(servicePath, inputData, requestType?) {
    const finalLink = '';
    const downloadData = {
      clientName: this.envDetails.RFQ,
      isFileDownload: true,
      formParams: inputData,
      headerParams: {
        // "UUID": encodeURIComponent(this.baseService.getUserInformation().auth_token),
        userEmail: this.baseService.getUserInformation().email,
        Accept: '*/*'
      },
      method: 'GET',
      pathParams: {},
      payload: {},
      servicePath: this.envDetails.crmSrvcPath + servicePath
    };

    downloadData.servicePath = `/${
      this.envDetails['collabService']
    }/downloadFile`;
    return this.getUrl(downloadData);
  }

  public getUrl(payLoadJSON) {
    const params = payLoadJSON.formParams;
    const headersData = payLoadJSON.headerParams;
    if (
      payLoadJSON instanceof Object &&
      payLoadJSON['headerParams'] instanceof Object
    ) {
      for (const key in payLoadJSON['headerParams']) {
        payLoadJSON['formParams'][key] = payLoadJSON['headerParams'][key];
      }
    }
    if (!payLoadJSON['formParams']['Authorization']) {
      payLoadJSON['formParams']['Authorization'] = `Bearer ${
        JSON.parse(localStorage.getItem(CUSTOMER_TOKEN_INFO)).access_token
      }`;
    }
    let url = '';
    if (payLoadJSON.servicePath.search('\\?') > -1) {
      url = this.envDetails.zuulApiEndPointPath + payLoadJSON.servicePath + '&';
    } else {
      url = this.envDetails.zuulApiEndPointPath + payLoadJSON.servicePath + '?';
    }
    // if (payLoadJSON.servicePath.includes('/CollaborationService')) {
    //     url = 'http://172.16.0.80:8080' + payLoadJSON.servicePath + '?';
    // }

    for (const key in params) {
      if (params[key] === null || params[key] === undefined) {
        params[key] = '';
      }
      url = url.concat(key + '=' + params[key] + '&');
    }
    return url;
  }
}
