import { Component, OnInit, Input } from '@angular/core';
import { numberDropdownConstants } from '../../../shared/validationUtility';
import { ECalendarValue } from 'ng2-date-picker';
import { DateUtils } from '../../../../common/utills/DateUtils.service';
@Component({
  selector: 'app-validation-item',
  templateUrl: './validation-item.component.html',
  styleUrls: ['./validation-item.component.sass']
})
export class ValidationItemComponent implements OnInit {
  @Input() validation;
  public numbersDropdown = numberDropdownConstants;
  minDateCalendarConfig = this.generateDateObj();
  maxDateCalendarConfig = this.generateDateObj();
  dateRange =  {
    minDateCalendarConfig: this.generateDateObj(),
    maxDateCalendarConfig: this.generateDateObj()
  }
  constructor() {}

  ngOnInit() {
  }
  numberChange(event) {
    this.validation.info.value = event.label;
  }
  generateDateObj(){
    return {
      disableKeypress: true,
      showNearMonthDays: false,
      showTwentyFourHours: true,
      returnedValueType: ECalendarValue.Moment,
      drops: 'down',
      displayFormat: 'MM-DD-YYYY',
      closeOnSelect: true

    };
  }
  onDateChange(evt, type){
    if(evt && typeof this.validation.info === 'object') {
      this.validation.info[type] = evt;
    }
  }
  getSelectedNumber(value){
    return this.numbersDropdown.find(x => x.label === value);
  }
}
