import { Injectable } from '@angular/core'
import { ApiServiceProvider } from 'app/api-service'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class LevaQuickFilterService extends ApiServiceProvider {
  public keyslist = []
  public excludeKeyslist = []
  public includeKeyslist = []
  public showAddPopup = false
  public isEdit = false
  public filtersList = {
    include: [],
    exclude: [],
    filterModuleId: 0,
    filterScopeId: 0,
    filterName: '',
    filterType: '',
    tenantFilterId: 0,
  }
  public incIndex = 1
  public excIndex = 1
  public incCount = 0
  public excCount = 0
  public lastIncKey = ''
  public lastExcKey = ''
  public moduleConfig = {
    id: 'filter-id',
    moduleName: 'NPA',
    scope: 'STANDARD',
    targetedType: 'ContentManagementService',
    moduleType: 'FILTER',
    showCondition: false
  }
  public onUpdate = false
  public enableSave = false
  public onDelete = false
  public updateKeys = new BehaviorSubject<boolean>(undefined)
  public updateMainKeys = new BehaviorSubject<boolean>(undefined)
  public dimensionDisplayNames = {
    ld_manufacturer: 'Manufacturer',
    ld_manufacturer_preference: 'Manufacturer',
    manufacturer_preference: 'Manufacturer',
    ld_supplier: 'Supplier',
    ld_supplier_preference: 'Supplier',
    ld_cm_odm: 'CM/ODM',
    ld_cm_odm_preference: 'CM/ODM',
    mpn: 'MPN',
    mpn_preference: 'MPN',
    site_name_preference: 'Manufacturer Site',
    site_name: 'Manufacturer Site',
    impacted: 'Disrupted',
    country: 'Country',
    city: 'City',
    cost: 'Cost Impact',
    state: 'State',
    cost_impact: 'Cost Impact',
    lead_time_impact: 'Lead Time Impacted Days',
    cost_impact_pct: 'Cost Impact',
    leadtime_impact_days: 'Lead Time Impacted Days',
    covid19_index: 'Covid19 Index',
    lead_time: 'Lead Time Impacted Days',
    risk_index: 'Risk Index',
  }
  newFilter = null

  public setKeyInfo(val) {
    this.updateKeys.next(val)
  }
  public getKeyInfo() {
    return this.updateKeys.asObservable()
  }

  public setMainKeyInfo(val) {
    this.updateMainKeys.next(val)
  }
  public getMainKeyInfo() {
    return this.updateMainKeys.asObservable()
  }

  setNewFilter(val) {
    this.newFilter = val
  }
  getNewFilter() {
    return this.newFilter
  }

  getQuickFilterKeys() {
    const requestPayload: any = {
      targetedType: 'CustomerConfigService',
      method: 'GET',
      generic: false,
      formParams: {
        moduleName: this.moduleConfig.moduleName,
        moduleType: this.moduleConfig.moduleType,
        scope: this.moduleConfig.scope,
      },
      servicePath: `/filter/dimensions`,
    }
    return this.post(requestPayload)
  }

  setModule(moduleConfig) {
    this.moduleConfig = moduleConfig
  }

  getRangeDimensionLimit(servicePath, dimension) {
    const requestPayload: any = {
      targetedType: 'ContentManagementService',
      method: 'GET',
      generic: false,
      servicePath,
      formParams: {
        dimension
      }
    }
    return this.post(requestPayload)
  }

  saveFilters(payload) {
    const requestPayload: any = {
      targetedType: 'CustomerConfigService',
      method: 'POST',
      generic: false,
      payload,
      servicePath: `/filter`,
    }
    return this.post(requestPayload)
  }
  updateFilter(payload) {
    const requestPayload: any = {
      targetedType: 'CustomerConfigService',
      method: 'PUT',
      generic: false,
      payload,
      servicePath: `/filter/${payload.tenantFilterId}`,
    }
    return this.post(requestPayload)
  }

  deleteFilter(payload) {
    const requestPayload: any = {
      targetedType: 'CustomerConfigService',
      method: 'DELETE',
      generic: false,
      payload,
      servicePath: `/filter/${payload}`,
    }
    return this.post(requestPayload)
  }
  checkSaveValidation()
  {
    this.enableSave= !this.filtersList.include.length && !this.filtersList.exclude.length ? false : true
    this.filtersList.exclude.forEach(element => {
      if(!element.isSelected)
      {
        this.enableSave=false
      }
    });
    this.filtersList.include.forEach(element => {
      if(!element.isSelected)
      {
        this.enableSave=false
      }
    });
  }
}
