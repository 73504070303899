import { AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, Component, DoCheck, Input, OnChanges, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UtilService } from 'app/modules/common/utills/util.service';

@Component({
  selector: 'ld-metric-kpi',
  templateUrl: './metric-kpi.component.html',
  styleUrls: ['./metric-kpi.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class MetricKpiComponent {
  _configuration: any;
  get configuration(){
    return this._configuration;
  }
  @Input() set configuration(value){
    this._configuration = value || {};
  }
  constructor(
    public utilService: UtilService
  ) { }
}

