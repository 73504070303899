import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-custom-popup',
  templateUrl: './custom-popup.component.html',
  styleUrls: ['./custom-popup.component.sass']
})
export class CustomPopupComponent implements OnInit {

  // Message for alert popup
  message= 'Are you sure?';
  // two types are supported: confirm and alert. Default - Alert
  type= 'confirm';
  // Alert button Text
  successMsg= 'OK';
  successId= 'success';
  // Confirm type button Text
  acceptMsg= 'Yes';
  acceptId= 'accept';
  discardMsg= 'No';
  discardId= 'discard';
  popupId= 'confirmation-msg'
  popupCustomClass: string;

  @Input()
  set configuration(val) {
    if(!val || !Object.keys(val).length) { return; }
    for(const key in val) {
      switch(key) {
        case 'message':
        case 'type':
        case 'successMsg':
        case 'successId':
        case 'acceptMsg':
        case 'acceptId':
        case 'discardMsg':
        case 'discardId':
        case 'popupId':
        case 'popupCustomClass':
          this[key] = val[key];
          break;
        default:
          break;
      }
    }
  }

  @Output() onYesClicked: EventEmitter<Object> = new EventEmitter<Object>();
  @Output() onNoClicked: EventEmitter<Object> = new EventEmitter<Object>();

  public showAlertPopup = false;

  constructor() { }

  ngOnInit() {
    if(this.type === 'confirm') { this.showAlertPopup = false; }
    else { this.showAlertPopup = true }
  }

  confirmYesClick($event) {
    this.onYesClicked.emit($event);
  }

  confirmNoClick($event) {
    this.onNoClicked.emit($event);
  }

}
