import { Component, OnInit, Input } from '@angular/core';

import { Sorting } from './shared/sorting.model';
import { SortingService } from './shared/sorting.service';
import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService';

@Component({
  selector: 'sorting',
  templateUrl: 'sorting.component.html',
  styleUrls: ['./sorting.component.sass'],
  providers: [SortingService]
})

export class SortingComponent implements OnInit {
  sorting: Sorting[] = [];
  @Input() heading : string;
  @Input() position: string;

  custConfname: any;



  constructor(private sortingService: SortingService,private customerConfigurationService: CustomerConfigurationService) { }

  ngOnInit() {

  }
}
