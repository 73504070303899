import { Component, ViewChild } from '@angular/core';

import { IDatePickerConfig } from 'ng2-date-picker';
import { ECalendarValue } from 'ng2-date-picker';
import { ObjectUtils } from '../../../common/utills/ObjectUtils.service';
import { updateUserAction } from './tree-track-action';
import { NpiBomManagementService } from '../../../npi/npi-program-profile/npi-bom-management/shared/npi-bom-management.service';
import { NpiCommonApiService } from '../../../npi/npi-common-api.service';
import { DateUtils } from '../../../common/utills/DateUtils.service';

@Component({
  selector: 'date-picker',
  template: `
    <div [ngClass]="[!isValid() ? 'isError' : '', edited ? 'isEdited' : '']">
      <dp-date-picker
        #datePicker
        theme="dp-material grid-date"
        mode="day"
        [config]="datePickerConfig"
        [(ngModel)]="params.value"
        [disabled]="headerType === 'readonly'"
        (onSelect)="onDateSelect()"
      ></dp-date-picker>
    </div>
  `
})
export class DatePickerComponent {
  public datePickerConfig: IDatePickerConfig = {
    format: 'MM/DD/YYYY',
    disableKeypress: true,
    closeOnSelect: true,
    returnedValueType: ECalendarValue.String,
    drops: 'down',
    opens: 'right'
  };
  public params: any;
  @ViewChild('datePicker', { static: false }) public datePicker;
  public value: any;
  public paramsCopy: string;
  public edited = false;
  public headerType;
  public type: any;
  constructor(
    private npiBomManagementService: NpiBomManagementService,
    private npiCommonApiService: NpiCommonApiService
  ) {}

  public agInit(params: any): void {
    if (params.colDef) {
      const colDef = this.npiCommonApiService.getColumnDefObject(
        this.npiBomManagementService.bomHeaders,
        params.colDef.field
      );
      this.type = colDef.type;
      let componentType;
      if (params.value) {
        try {
          if (!Number.isNaN(parseInt(params.value, 0))) {
            params.value = parseInt(params.value, 0);
            params.value = DateUtils.getMomentFromEpoch(params.value);
          }
        } catch (e) {
          console.log('error while parsing the epoch ', params.value);
        }
      }
      if (params.data) {
        componentType = params.data.componentType || '';
      }
      let headerType;
      if (colDef.visibility && colDef.visibility[colDef.type]) {
        headerType = colDef.visibility[colDef.type][componentType];
      }
      if (headerType && headerType.toLowerCase() === 'readonly') {
        this.headerType = 'readonly';
      } else {
        this.headerType = 'editable';
      }
    }
    this.params = params;
    if (
      params &&
      params.colDef &&
      params.data &&
      this.npiBomManagementService.editedCells.includes(
        params.colDef.field + params.data.id
      )
    ) {
      this.edited = true;
    }
    // try {
    //   this.value = params.value;
    // } catch (e) {
    //   console.log(e);
    // }
    this.updateRowStatus(this.params);
  }

  public isValid() {
    let dbColumn;
    if (this.params && this.params.colDef) {
      dbColumn = this.params.colDef.field;
    }

    if (
      this.params &&
      this.params.data &&
      this.params.data.info &&
      this.params.data.info[dbColumn] &&
      this.params.data.info[dbColumn].validations instanceof Array &&
      this.params.data.info[dbColumn].validations.length
    ) {
      return false;
    } else {
      return true;
    }
  }
  public onDateSelect() {
    this.edited = true;
    if (this.params && this.params.data && this.params.colDef) {
      if (!this.params.data.info[this.params.colDef.field]) {
        this.params.data.info[this.params.colDef.field] = {};
      }
      if (
        !this.params.data.info[this.params.colDef.field].hasOwnProperty(
          'oldValue'
        )
      ) {
        try {
          this.params.data.info[this.params.colDef.field][
            'oldValue'
          ] = ObjectUtils.copy(this.params.data[this.params.colDef.field]);
        } catch (e) {
          console.log(e);
        }
      }
      this.params.data.info[this.params.colDef.field]['updateType'] = 'MANUAL';
      this.params.data.info[this.params.colDef.field][
        'newValue'
      ] = this.params.value;
      this.params.data[this.params.colDef.field] = this.params.value;
      updateUserAction({ type: 'update', data: this.params.data });
      this.updateRowStatus(this.params);
      this.npiBomManagementService.setSelectedDate(this.params);
    }

    console.log(this.params);
  }
  private updateRowStatus(params) {
    try {
      if (params && params.data) {
        if (this.isValid()) {
          if (
            this.npiBomManagementService.editedCells.includes(
              params.colDef.field + params.data.id
            )
          ) {
            if (params.data['fieldStatus'] instanceof Array) {
              if (!params.data['fieldStatus'].includes('modified')) {
                params.data['fieldStatus'].push('modified');
              }
              const errorsInRow = Object.getOwnPropertyNames(
                params.data.info
              ).some(
                i =>
                  params.data.info[i] &&
                  params.data.info[i].validations &&
                  params.data.info[i].validations.length
              );
              if (
                params.data['fieldStatus'].includes('error') &&
                !errorsInRow
              ) {
                params.data['fieldStatus'].splice(
                  params.data['fieldStatus'].indexOf('error'),
                  1
                );
              }
            } else {
              params.data['fieldStatus'] = ['modified'];
            }
          }
        } else {
          if (params.data['fieldStatus'] instanceof Array) {
            if (!params.data['fieldStatus'].includes('error')) {
              params.data['fieldStatus'].push('error');
            }
          } else {
            params.data['fieldStatus'] = ['error'];
          }
        }
      }
    } catch (e) {
      console.log(params);
    }
  }
}
