import { Component, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core'
import Highcharts from 'highcharts'
import * as _ from 'lodash'
import { GenerateGraphObject } from './GenerateGraphObject'
import { GraphUtilService } from './Graph-util.service'
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CoverageDashboardService } from 'app/modules/coverage-dashboard/coverage-dashboard.service';
const WORDCLOUD = require('highcharts/modules/wordcloud')
WORDCLOUD(Highcharts)
@Component({
  selector: 'app-graph-container',
  templateUrl: './graph-container.component.html',
  styleUrls: ['./graph-container.component.sass'],
  providers: [GenerateGraphObject, GraphUtilService],
  encapsulation: ViewEncapsulation.None,
})
export class GraphContainerComponent implements OnInit {
  graphRenderObject: any = {}
  private _configuration: any
  get configuration() {
    return this._configuration
  }
  @Input() set configuration(value) {
    this.findTheData(value)
    this._configuration = value || {}
  }
  private graphData: any
  private xAxis: any = {}
  private seriesData = [];
  private colorsList = {
    draft: '#FFC31A',
    info: '#0597C3',
    success: '#009336',
    error: '#B71B17',
    conformation: '#006196',
    default: '#F2F3F4',
    warning: '#E5AC3A',
    gray: '#BFBFBF',
    primary: '#18426f',
  };
  dataFindInterval;
  that: any;
  constructor(
    private generateGraphObject: GenerateGraphObject,
    private graphUtilService: GraphUtilService,
    private coverageGridService: CoverageDashboardService
  ) {
    window['that'] = this;
  }
  private subject$: Subject<any> = new Subject();

  ngOnInit(): void {
    this.subscribeToBarEvents();
  }

  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    this.graphData = null;
    this.seriesData = [];
    if (this.configuration && this.configuration.data && this.configuration.data.data.length
      && this.configuration.data.data[0].value
      && Object.keys(this.configuration.data.data[0].value).length) {
      this.load();
    }
  }

  private subscribeToBarEvents(): void {
    this.coverageGridService.getDashboardDataChangeObservable()
      .pipe(takeUntil(this.subject$))
      .subscribe((response: any) => {
        if (response.type === 'BAR_UPDATE' || response.type === 'BUBBLE_UPDATE' || response.type === 'XY_UPDATE') {
          this.reloadChartAfterUpdating(response.isData);
        }
      })
  }

  private reloadChartAfterUpdating(hasData: boolean): void {
    this.seriesData = [];
    this.graphData = null;
    if (hasData) {
      this.findTheData(this.configuration);
    } else {
      this.generateGraphObject.setSeriesData(this.seriesData);
      this.generateGraphObject.addOtherInformation(undefined);
      this.graphRenderObject = null;
    }
  }


  findTheData(path) {
    if (
      path &&
      path.data &&
      path.data.data &&
      path.data.data.length > 0 &&
      Object.keys(path.data.data[0]).length > 0 &&
      Object.keys(path.data.data[0].value).length > 0
    ) {
      this.load()
    }
  }
  clearDataInterval() {
    clearInterval(this.dataFindInterval)
  }

  generateBarChartObject() {

    if (!Object.keys(this.graphData).length) { return; }
    if (!this.graphData.data) { return; }
    if (!this.graphData.metaData || !this.graphData.metaData.options || !this.graphData.metaData.options.keyOrder) { return; }

    Highcharts.setOptions({
      colors: [
        '#BFBFBF',
        '#009336',
        '#5CA904',
        '#E5Ac3A',
        '#E56717',
        '#B71B17'
      ],
    })
    /* try {
      this.xAxis = {
        categories: _.uniq(_.map(this.graphData.metaData.values, 'key')),
      }
    } catch (e) {} */

    const keyMap = {
      key: 'name',
      values: 'data',
    }
    var graphdataReverse = (this.graphData && this.graphData.data) ? [...this.graphData.data] : [];
    graphdataReverse = graphdataReverse.reverse();

    this.seriesData =
      graphdataReverse.map((obj) => {
        return _.mapKeys(obj, (value, key) => {
          return keyMap[key]
        })
      })

    if (this.graphData && this.graphData.metaData && this.graphData.metaData.options && this.graphData.metaData.options.keyOrder && this.graphData.kpiInformation != undefined && this.graphData.kpiInformation.data[0] != undefined
      && this.graphData.kpiInformation.data[0].values != undefined
      && this.graphData.kpiInformation.data[0].values.length > 0) {
      var sizeDataPt = this.graphData.kpiInformation.data[0].values.length;
      var alphas: string[] = new Array(sizeDataPt);
      for (let i = 0; i < sizeDataPt; i++) {
        alphas[i] = (this.graphData.kpiInformation.data[0].values[i] * 100).toFixed(2) + '%$$'
          + this.graphData.metaData.options.keyOrder[i];
      }
      var visibleNoCoverage = true;
      if (alphas && alphas.length > 0)
        visibleNoCoverage = false;

      this.xAxis = [{
        visible: visibleNoCoverage,
        categories: this.graphData && this.graphData.metaData && this.graphData.metaData.options && this.graphData.metaData.options.keyOrder ? this.graphData.metaData.options.keyOrder : []
      },
      {

        categories: alphas ? alphas : [],
        lineWidth: 0,
        labels: {
          style: {
            fontSize: '10px !important',

          },
          align: 'right',
          x: -5,
          y: -13,
          useHTML: true,
          formatter: function () {
            if (this.value)
              return '<div class="tip" style="visibility:hidden" >' + this.value.split("$$")[0] + '<span><a class="tooltip-title">' + this.value.split("$$")[1] + '</a><br><a class="tooltip-content">Coverage&nbsp; &nbsp; &nbsp;' + this.value.split("$$")[0] + '</a></span></div>';
          }
        },
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        opposite: true
      }];
      this.seriesData.push({
        data: [0, 0, 0, 0, 0, 0],
        name: 'perc',
        xAxis: 1
      });
    }
    else {
      this.xAxis = {
        categories: this.graphData && this.graphData.metaData && this.graphData.metaData.options && this.graphData.metaData.options.keyOrder ? this.graphData.metaData.options.keyOrder : []
      }
    }
    this.generateGraphObject.addOtherInformation({
      plotOptions: {
        series: {
          visible: visibleNoCoverage,
          minPointLength: 1,
          pointWidth: 18,
          pointPadding: 0.1,
          groupPadding: 0.00,
          stacking: 'percent',
          borderColor: '#FFFFFF',
          borderWidth: 1,
          dataSorting: {
            enabled: false,
            sortKey: 'y',
          },

        },
      },
    })
    this.generateGraphObject.setGraphType('bar')
    this.generateGraphObject.setXAxis(this.xAxis)
    const barGraphYAxis: any = {
      gridLineColor: '#FFFFFF',
      lineColor: '#FFFFFF',
      gridLineWidth: 0,
      lineWidth: 0,
      labels: {
        enabled: false,
      },
      title: {
        text: '',
      },

      visible: true,
    }
    this.generateGraphObject.setYAxis(barGraphYAxis);

    const networkProductTooltip = {
      tooltip: {
        useHTML: true,
        borderWidth: 0,
        borderRadius: 5,
        outside: true,
        formatter: function () {
          if (this.x.includes(("$$")))
            return false;
          const scope = window['that'];
          const methodPayload = {
            title: this.x,
            targetName: this.series.name,
            indicatorColor: this.color,
            data: [
              { title: this.series.name, value: `${(this.point.y * 100).toFixed(2)}%` },
              { title: '', value: '' }
            ]
          }
          scope.graphUtilService.setTooltip(methodPayload);
          return scope.graphUtilService.getTooltip();
        }
      }
    };
    this.generateGraphObject.addOtherInformation(networkProductTooltip);
  }

  load() {
    if (
      this.configuration &&
      this.configuration.id &&
      this.configuration.data &&
      this.configuration.data.data[0] &&
      this.configuration.data.data[0].type !== undefined
    ) {
      this.graphData = { ...this.configuration.data.data[0].value };

      const targetGraphId = this.configuration.id || ''
      this.seriesData = []
      switch (this.configuration.data.data[0].type.toLowerCase().trim()) {
        case 'bubble':
          if (targetGraphId.trim() === 'NETWORK_COLLABORATION' && this.graphData && this.graphData.metaData && this.graphData.metaData.options && this.graphData.metaData.options.keyOrder) {
            this.xAxis = {
              categories: this.graphData.metaData.options.keyOrder.filter(value => _.uniq(_.map(this.graphData.data, 'key')).includes(value))
            },
              (this.xAxis.categories || []).map((xAxisName) => {
                this.seriesData.push({
                  name: xAxisName,
                  data: [],
                })
              })
          } else {
            this.xAxis = {
              categories: _.uniq(_.map(this.graphData.data, 'key')),
            }
              ; (this.xAxis.categories || []).map((xAxisName) => {
                this.seriesData.push({
                  name: xAxisName,
                  data: [],
                })
              })
          }

          this.generateGraphObject.setGraphType('bubble')
          this.xAxis['alternateGridColor'] = this.colorsList.default
          this.xAxis['lineWidth'] = 0
          this.generateGraphObject.setXAxis(this.xAxis);

          let yAxis: any = {
            gridLineColor: '#ffffff',
            gridLineWidth: 0,
            lineColor: this.colorsList.gray,
            lineWidth: 1,
            labels: {
              enabled: false,
            },
            title: {
              text: '',
              x: 0,
              y: 0,
              align: 'low',
              style: {
                fontSize: '12px',
                fontFamily: 'Roboto !important',
                color: this.colorsList.gray,
              },
              userHTML: true,
            },
            visible: true,
          }

          this.generateGraphObject.addOtherInformation({
            plotOptions: {
              bubble: {
                minSize: 20,
                borderWidth: 0
              },
              series: {
                marker: {
                  lineWidth: 0,
                  lineColor: null
                }
              },
            },
          })

          switch (targetGraphId.trim()) {
            case 'NEW_PRODUCT_ACCELERATOR':
              yAxis = [
                {
                  gridLineColor: '#ffffff',
                  gridLineWidth: 0,
                  lineColor: this.colorsList.gray,
                  lineWidth: 1,
                  labels: {
                    enabled: false,
                  },
                  title: {
                    text:
                      `<p style='float:left'>Under Target Cost </p> | <p style='float:right'> Over Target Cost </p>`,
                    x: 0,
                    y: 0,
                    align: 'middle',
                    style: {
                      fontSize: '12px',
                      fontFamily: 'Roboto !important',
                      color: this.colorsList.gray,
                    },
                    userHTML: true,
                  },
                  visible: true,
                  plotLines: [
                    {
                      value: 0,
                      color: this.colorsList.gray,
                      width: 1,
                      label: {
                        text: '',
                        align: 'left',
                        style: {
                          color: this.colorsList.gray,
                        },
                      },
                    },
                  ],
                },
              ];
              (this.graphData.data || []).forEach((bubble, i) => {
                const index = _.findIndex(
                  this.xAxis.categories,
                  (el) => el === bubble.key
                )
                this.seriesData[index]['data'].push({
                  x: ((Math.random() * (Math.random() * (0.3 - 0.3) + 0.3)) + index - (Math.random() * (0.2 - 0.1) + 0.1)) >= 0 ? ((Math.random() * (Math.random() * (0.3 - 0.3) + 0.3)) + index - (Math.random() * (0.2 - 0.1) + 0.1)) : 0, // (Math.random() * (0.4 - 0.4) - 0.4) + index,
                  y: bubble.value[0],
                  z: bubble.value[1],
                  name: `Program-${i}`,
                  color:
                    bubble.value[2] === true
                      ? this.colorsList.error
                      : this.colorsList.gray,
                })
              });

              const productAccelerationTooltip = {
                tooltip: {
                  useHTML: true,
                  borderWidth: 0,
                  borderRadius: 5,
                  outside: true,
                  formatter: function () {
                    const scope = window['that'];
                    const methodPayload = {
                      title: this.series.xAxis.categories[Math.round(this.x)],
                      targetName: this.key,
                      indicatorColor: this.color,
                      data: [
                        { title: 'Over Target Cost', value: `${window['currencySymbol']}${scope.graphUtilService.numberAsItIs(this.point.y.toFixed(6))}` },
                        { title: 'BOM Risk', value: `${this.point.z}` }
                      ]
                    };
                    scope.graphUtilService.setTooltip(methodPayload);
                    return scope.graphUtilService.getTooltip();
                  }
                }
              };
              this.generateGraphObject.addOtherInformation(productAccelerationTooltip);


              break
            case 'NETWORK_COLLABORATION':
              yAxis['legend'] = {
                reversed: true,
                align: 'right',
                verticalAlign: 'middle',
                layout: 'vertical'
              }
              yAxis['reversed'] = true
              yAxis['title'] = {
                text: `<p style='float:left'> age </p>`,
                x: 0,
                y: 0,
                align: 'high',
                style: {
                  fontSize: '12px',
                  fontFamily: 'Roboto !important',
                  color: this.colorsList.gray,
                },
                userHTML: true,
              }
              yAxis['plotLines'] = [
                {
                  value: 30,
                  width: 1,
                  label: {
                    text: '30 days',
                    align: 'left',
                  },
                },
                {
                  value: 60,
                  width: 1,
                  label: {
                    text: '60 days',
                    align: 'left',
                  },
                },
              ];
              (this.graphData.data || []).forEach((bubble, i) => {
                const index = _.findIndex(
                  this.xAxis.categories,
                  (el) => el === bubble.key
                )
                if (this.seriesData && index != -1) {
                  let color: any;
                  const todayEpoch = new Date().getTime();
                  switch (bubble.key) {
                    case 'Draft':
                      if(bubble.rfxConfigData.rfqConfirmDate === null){
                        color = this.colorsList.success;
                      }else{
                        if(Number(bubble.rfxConfigData.rfqConfirmDate) >= todayEpoch ){
                          color = this.colorsList.success;
                        }else{
                          color = this.colorsList.error;
                        }
                      }
                      
                      break;
                    case 'Sent':
                      if(bubble.rfxConfigData.rfqSubmissionDate === null){
                        color = this.colorsList.success;
                      }else{
                        if(Number(bubble.rfxConfigData.rfqSubmissionDate) >= todayEpoch ){
                          color = this.colorsList.success;
                        }else{
                          color = this.colorsList.error;
                        }
                      }
                      break;
                    case 'Negotiation In-Progress':
                    case 'Award In-Progress':
                      if(bubble.rfxConfigData.rfqAwardDate === null){
                        color = this.colorsList.success;
                      }else{
                        if(Number(bubble.rfxConfigData.rfqAwardDate) >= todayEpoch ){
                          color = this.colorsList.success;
                        }else{
                          color = this.colorsList.error;
                        }
                      }
                      break;
                    case 'Awarded':
                      color = this.colorsList.success;
                      break;
                    case 'Closed':
                    case 'Revoked':
                      color = this.colorsList.gray;
                      break;

                  }
                  this.seriesData[index]['data'].push({
                    x: ((Math.random() * (Math.random() * (0.3 - 0.3) + 0.3)) + index - (Math.random() * (0.2 - 0.1) + 0.1)),
                    y: bubble.value[0],
                    z: bubble.value[1],
                    name: bubble.value[2],
                    color: color
                  });
                }
                const networkCustomTooltip = {
                  tooltip: {
                    useHTML: true,
                    borderWidth: 0,
                    borderRadius: 5,
                    outside: true,
                    formatter: function () {
                      const scope = window['that'];
                      const methodPayload = {
                        title: this.series.xAxis.categories[Math.round(this.x)],
                        targetName: this.key,
                        indicatorColor: this.color,
                        data: [
                          { title: 'Age', value: `${this.point.y} days` },
                          { title: 'RFX Amount', value: `${window['currencySymbol']}${scope.graphUtilService.numberWithCommas(this.point.z.toFixed(2))}` }
                        ]
                      }
                      scope.graphUtilService.setTooltip(methodPayload);;
                      return scope.graphUtilService.getTooltip();
                    }
                  }
                };
                this.generateGraphObject.addOtherInformation(networkCustomTooltip);
              })
              break
            case 'SAVING_OPPORTUNITIES':
              yAxis.title.text =
                `<p class='saving_opportunity_legend'> Spend </p>`;
              const colorIndex = [
                this.colorsList.info,
                this.colorsList.success,
                this.colorsList.error,
              ];
              (this.graphData.data || []).forEach((bubble) => {
                const index = _.findIndex(
                  this.xAxis.categories,
                  (el) => el === bubble.key
                )
                if (index > -1) {
                  this.seriesData[index]['data'].push({
                    x: ((Math.random() * (Math.random() * (0.3 - 0.3) + 0.3)) + index - (Math.random() * (0.2 - 0.1) + 0.1)),
                    y: Number(bubble.value[0]),
                    z: Number(bubble.value[1]),
                    color: colorIndex[index],
                    name: bubble.value[2],
                    // dataType:
                    // (this.graphData && this.graphData.metaData && this.graphData.metaData.values && this.graphData.metaData.values.length > 0 &&  this.graphData.metaData.values[index]['dataType']) ? this.graphData.metaData.values[index]['dataType'] : 'string'
                  })
                }
              });
              const savingOpportunitiesCustomTooltip = {
                tooltip: {
                  useHTML: true,
                  borderWidth: 0,
                  borderRadius: 5,
                  outside: true,
                  formatter: function () {
                    const scope = window['that'];
                    const methodPayload = {
                      title: this.series.xAxis.categories[Math.round(this.x)],
                      targetName: this.key,
                      indicatorColor: this.color,
                      data: [
                        { title: 'Spend', value: `${window['currencySymbol']}${scope.graphUtilService.numberWithCommas(this.point.y.toFixed(2))}` },
                        { title: 'Savings', value: `${window['currencySymbol']}${scope.graphUtilService.numberWithCommas(this.point.z.toFixed(2))}` }
                      ]
                    }
                    scope.graphUtilService.setTooltip(methodPayload);
                    return scope.graphUtilService.getTooltip();
                  }
                }
              };
              this.generateGraphObject.addOtherInformation(savingOpportunitiesCustomTooltip);
              break
          }
          this.generateGraphObject.setYAxis(yAxis)
          break
        case 'bar':
          this.generateBarChartObject()
          break
        case 'xy':
          this.graphData.data = _.sortBy(this.graphData.data, [
            (o) => {
              return o['value'][2]
            },
          ]);
          this.xAxis = {
            categories: _.uniq(_.map(this.graphData.data, 'key')),
            labels: {
              rotation: -90,
            },
          };

          const seriesType = _.uniq(
            _.map(this.graphData.metaData.values, 'key')
          )
          const lineColorList = [
            this.colorsList.error,
            this.colorsList.success,
            this.colorsList.gray,
          ]
          this.seriesData.push(
            {
              name: seriesType[1],
              type: 'column',
              data: [],
            },
            {
              name: seriesType[0],
              type: 'line',
              color: lineColorList[0],
              yAxis: 1,
              data: [],
              zoneAxis: 'x',
              zones: [],
              lineWidth: 3
            }
          )
          const barColorList = [
            this.colorsList.primary,
            this.colorsList.info,
            this.colorsList.gray,
          ]
          const lineValues = [];
          let currentQuarterIndex = null;
          if (!this.graphData.data.length) { return }
          (this.graphData.data || []).forEach((seriesValue, index) => {
            if (Number(seriesValue.value[2]) === 0) {
              currentQuarterIndex = index;
            }
            this.seriesData[0].data.push({
              y: Number(seriesValue.value[1]),
              color:
                seriesValue.value[2] === -1
                  ? barColorList[0]
                  : barColorList[Number(seriesValue.value[2]) + 1], // Add condition -1,0 and 1
            })
            lineValues.push(seriesValue.value[0])
            this.seriesData[1].data.push(Number(seriesValue.value[0]))
          });
          const zones = [];

          (lineValues || []).forEach((seriesPoint, index) => {
            if (lineValues.length > index + 1) {
              zones.push(
                {
                  value: index + 1,
                  dashStyle: currentQuarterIndex > index ? 'solid' : 'dash',
                  color: lineValues[index] > lineValues[index + 1] ? this.colorsList.error : this.colorsList.success
                }
              );
            }
          });


          this.seriesData[1].zones = zones
          this.generateGraphObject.setGraphType('xy')
          this.generateGraphObject.setXAxis(this.xAxis)
          this.generateGraphObject.addOtherInformation({
            plotOptions: {
              series: {
                pointWidth: 25
              },
              spline: {
                lineWidth: 4,
                states: {
                  hover: {
                    lineWidth: 2,
                  },
                },
                marker: {
                  enabled: false,
                },
              },
            },
          });
          const xyGraphYAxis: any = [
            {
              gridLineWidth: 0,
              lineWidth: 0,
              labels: {
                enabled: false,
              },
              title: {
                text: '',
              },
              type: 'logarithmic',
              max: _.maxBy(this.seriesData[0].data, 'y').y,
              visible: true,
            },
            {
              gridLineWidth: 0,
              lineWidth: 0,
              labels: {
                enabled: false,
              },
              title: {
                text: '',
              },
              visible: true,
              // type: 'logarithmic',
              min: Math.min(...this.seriesData[1].data),
              max: Math.max(...this.seriesData[1].data),
              opposite: true,
            },
          ];
          this.generateGraphObject.setYAxis(xyGraphYAxis);
          const networkProductTooltip = {
            tooltip: {
              useHTML: true,
              borderWidth: 0,
              borderRadius: 5,
              outside: true,
              formatter: function () {
                const scope = window['that'];
                let percentage = '%';
                let currency = window['currencySymbol'];

                if (this.series.name === 'Savings') {
                  currency = '';
                } else {
                  percentage = '';
                }
                const methodPayload = {
                  title: this.x,
                  targetName: this.series.name,
                  indicatorColor: this.color,
                  data: [
                    { title: this.series.name, value: `${currency}${scope.graphUtilService.numberWithCommas(this.point.y.toFixed(2))}${percentage}` },
                    { title: '', value: '' }
                  ]
                }
                scope.graphUtilService.setTooltip(methodPayload);;
                return scope.graphUtilService.getTooltip();
              }
            }
          };
          this.generateGraphObject.addOtherInformation(networkProductTooltip);
          break
        case 'pie':
          this.seriesData.push({
            name: '',
            data: [],
          })
          let date = '';

          (this.graphData.data || []).forEach((seriesValue) => {
            if (seriesValue.key === 'Fresh') {
              date = seriesValue.date || ''
              this.seriesData[0].data.push({
                name: seriesValue.key,
                y: seriesValue.value[0],
                color:
                  this.colorsList.success,
              })
            } else {
              this.seriesData[0].data.push({
                name: seriesValue.key,
                y: seriesValue.value[0],
                color:
                  this.colorsList.error,
              })
            }
          })

          this.generateGraphObject.addOtherInformation({
            title: {
              text: '',
              useHTML: true,
              verticalAlign: 'bottom',
              margin: 0,
              y: 60,
              style: {
                fontSize: '12px',
                fontFamily: 'Roboto !important',
                color: this.colorsList.conformation,
              },
            },
            plotOptions: {
              pie: {
                innerSize: '75%',
                depth: 50,
                center: ['50%', '50%'],
                dataLabels: {
                  enabled: false,
                },
              },
            },
          })
          this.generateGraphObject.setGraphType('pie')
          this.generateGraphObject.setXAxis(this.xAxis)
          break
        case 'wordcloud':
          Highcharts.setOptions({
            colors: [
              this.colorsList.primary,
              this.colorsList.conformation,
              '#009336',
              '#5CA904',
              '#E5Ac3A',
              '#E56717',
              '#B71B17'
            ],
          })
          const worldCloudData = []

          this.graphData.data.forEach((val) => {
            worldCloudData.push({
              name: val.key,
              weight: val.value[0],
            })
          })

          this.seriesData.push({
            type: 'wordcloud',
            name: 'Count',
            rotation: {
              from: 0,
              to: 0,
            },
            spiral: 'rectangular',
            placementStrategy: 'center',
            data: worldCloudData || [],
          });

          const customTooltip = {
            tooltip: {
              useHTML: true,
              borderWidth: 0,
              borderRadius: 5,
              outside: true,
              formatter: function () {
                const scope = window['that'];
                const methodPayload = {
                  title: 'Industry Alerts',
                  targetName: this.key,
                  indicatorColor: this.color,
                  data: [
                    { title: 'Count', value: this.point.weight },
                    { title: '', value: '' }
                  ]
                }
                scope.graphUtilService.setTooltip(methodPayload);
                return scope.graphUtilService.getTooltip();
              }
            }
          };
          this.generateGraphObject.addOtherInformation(customTooltip);


          break
        default:
          console.log('No Graph Configured yet.')
          break
      }
      this.generateGraphObject.setSeriesData(this.seriesData);
      this.graphRenderObject = this.generateGraphObject.getGraphData();
      this.configuration.data.status = 'done';

    }
  }

  ngOnDestroy() {
    this.subject$.next();
    this.subject$.complete();
    this.clearDataInterval();
  }
}
