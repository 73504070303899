import { Component, OnInit, Input } from '@angular/core';

import { InsightsNotes } from './shared/insights-notes.model';
import { InsightsNotesService } from './shared/insights-notes.service';

@Component({
  selector: 'insights-notes',
  templateUrl: 'insights-notes.component.html',
  styleUrls: ['./insights-notes.component.sass'],
  providers: [InsightsNotesService]
})
export class InsightsNotesComponent implements OnInit {
  insightsNotes: InsightsNotes[] = [];
  @Input() height: string;
  @Input() insights: any[];
  @Input() recommendations = [];
  @Input() insightnotes: any[];
  @Input() insightsId: string;
  @Input() recommendationsId: string;
  @Input() insightnotesId: string;

  constructor(private insightsNotesService: InsightsNotesService) {}

  ngOnInit() {
    if (this.insights instanceof Array && this.insights.length > 0) {
      this.insights.forEach(note => {
        if (
          note &&
          note.insightsDesc &&
          note.insightsDesc.toString().includes('�')
        ) {
          note.insightsDesc = note.insightsDesc.replace(/�/g, '0');
        }
        if (
          note &&
          note.insightsDesc &&
          note.insightsDesc.toString().includes('∞')
        ) {
          note.insightsDesc = note.insightsDesc.replace(/∞/g, '0');
        }
      });
    }

    if (this.recommendations instanceof Array && this.recommendations.length > 0) {
      this.recommendations.forEach(note => {
        if (
          note &&
          note.insightsDesc &&
          note.insightsDesc.toString().includes('�')
        ) {
          note.insightsDesc = note.insightsDesc.replace(/�/g, '0');
        }
        if (
          note &&
          note.insightsDesc &&
          note.insightsDesc.toString().includes('∞')
        ) {
          note.insightsDesc = note.insightsDesc.replace(/∞/g, '0');
        }
      });
    }
  }
}
