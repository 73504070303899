import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService'
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { BaseService } from 'app/modules/common/base.service'
import { AuthenticationService } from 'app/modules/authentication/shared/authentication.service'
import { LoginService } from 'app/modules/authentication/login/login.component.service'
import { environment } from '../../../../environments/environment'
import { UtilService } from 'app/modules/common/utills/util.service'
import { ConfigService } from 'app/modules/common/configservice'
import { csAppHomePage } from '../../common/CSAppConfiguration.constants'
import { DateUtils } from 'app/modules/common/utills/DateUtils.service'
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service'
import { DataStorageService } from 'app/modules/common/data-storage.service'
import { takeWhile } from 'rxjs/operators'
import { AUTHENTICATION_CONSTANT } from '../shared/authentication.constants'
import { SharedService } from 'app/modules/shared/services/shared.service'
import { UserInfo } from '../shared/authentication.interface'
import {
  CUSTOMER_CURRENT_USER,
  CUSTOMER_CUSTOMER_DATA,
  CUSTOMER_LOGIN_RESPONSE,
  CUSTOMER_NAVIGATE_ROUTE_EXISTS,
  CUSTOMER_SESSION_EXPIRED,
  CUSTOMER_TOKEN_INFO,
  CUSTOMER_CS_APP, CUSTOMER_EMAIL, CUSTOMER_PASSWORD_EXPIRY_INFO
} from '../../shared/services/shared-consts';
import { AppVersionService } from '../../base-app/app-version/app-version';
import { ApiServiceProvider } from 'app/api-service'
@Component({
  selector: 'cprt-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass'],
  providers: [AuthenticationService],
})
export class LoginComponent implements OnInit, OnDestroy {
  public authConst = AUTHENTICATION_CONSTANT;
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION
  showSupportedBrowserInformation = false
  scheduleMaintenance = false
  startTime: any
  endTime: any
  showQrCode = false;
  showLoginForm = true;
  qrCodeImage = '';
  currentUserId = ''
  currentUserEmail = ''
  loginForm: FormGroup;
  email = '';
  password = '';
  code2fa = '';
  returnUrl = this.authConst.urls.dashboard;
  userInfoData: UserInfo;
  successMsg = ''
  errorMsg = ''
  changePasswordUrl = this.authConst.urls.changePassword;
  errorMsg2Fa = this.authConst.errors.invalidAuthCode;
  showLogin = false;
  successMsgShow = false;
  errorMsgShow = false;
  showErrorMsg2Fa = false;
  customerObj: any;
  public showFormBasedFields = false;
  public ssoEnabledApp = environment.ssoEnabledApp;
  private _compActive = true;
  @ViewChild('loginForm', { static: false }) currentForm: FormGroup;
  // val = false

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private baseService: BaseService,
    private customerConfigurationService: CustomerConfigurationService,
    private loginService: LoginService,
    private utilService: UtilService,
    private configService: ConfigService,
    private dataStorageService: DataStorageService,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private appVersionService: AppVersionService,
    private apiService: ApiServiceProvider
  ) {
  }

  ngOnInit() {
    this.createLoginForm();
    this.redirectUserBasedOnData();
    if (!this.utilService.detectBrowser()) {
      this.showSupportedBrowserInformation = true
    }
    this.getMaintenanceStatus();
    this.refreshPage();
  }

  private createLoginForm(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    })
  }

  private redirectUserBasedOnData(): void {
    if (
      this.sharedService.getLocalStorageData(CUSTOMER_TOKEN_INFO) &&
      this.sharedService.getLocalStorageData(CUSTOMER_CURRENT_USER) &&
      this.sharedService.getLocalStorageData(CUSTOMER_LOGIN_RESPONSE) &&
      this.sharedService.getLocalStorageData(CUSTOMER_SESSION_EXPIRED) === false &&
      this.sharedService.getLocalStorageData(CUSTOMER_CUSTOMER_DATA) &&
      this.sharedService.getLocalStorageData(CUSTOMER_NAVIGATE_ROUTE_EXISTS)
    ) {
      this.userRedirection()
    } else {
      // reset login status
      this.clearAllData()
    }
  }

  private refreshPage(): void {
    this.baseService.getRefreshPage()
      .pipe(takeWhile(() => this._compActive))
      .subscribe((data) => {
        if (data === true) {
          this.userInfoData = this.sharedService.getLocalStorageData(CUSTOMER_LOGIN_RESPONSE)
          if (this.sharedService.getLocalStorageData(CUSTOMER_CURRENT_USER) &&
            (this.sharedService.getLocalStorageData(CUSTOMER_CUSTOMER_DATA) ||
              this.sharedService.getLocalStorageData(CUSTOMER_CS_APP) === true ||
              this.userInfoData['user'].new)
          ) {
            this.userRedirection()
          }
        }
      });
  }

  private getMaintenanceStatus(): void {
    this.authenticationService.scheduledMaintainanceCall()
      .pipe(takeWhile(() => this._compActive))
      .subscribe((val) => {
        if (val && val.responseStatus && val.responseStatus.code === 200) {
          if (val.result instanceof Object) {
            if (val.result.scheduledStartdate && val.result.scheduledEndtdate) {
              const startTime = DateUtils.format(
                val.result.scheduledStartdate,
                undefined,
                true
              )
              const edTime = DateUtils.format(
                val.result.scheduledEndtdate,
                undefined,
                true
              )
              this.startTime = this.formatDate(startTime)
              this.endTime = this.formatDate(edTime)
              const interval = setInterval(() => {
                const localTime = DateUtils.format(
                  DateUtils.getDate(null, true),
                  undefined,
                  true
                )
                const localDateObject = new Date(localTime)
                const endDateObject = new Date(edTime)
                if (
                  +localDateObject === +endDateObject &&
                  localDateObject.getTime() === endDateObject.getTime()
                ) {
                  this.scheduleMaintenance = false
                } else if (localDateObject > endDateObject) {
                  this.scheduleMaintenance = false
                  clearInterval(interval)
                } else {
                  this.scheduleMaintenance = true
                }
              }, 10000)
            }
          }
        }
      });
  }
  formatDate(time) {
    try {
      const myDate = new Date(time),
        locale = 'en-us',
        month = myDate.toLocaleString(locale, { month: 'short' })
      let hours = myDate.getHours()
      let minutes = myDate.getMinutes().toString()
      const ampm = hours >= 12 ? 'PM' : 'AM'
      hours = hours % 12
      hours = hours ? hours : 12
      minutes = +minutes < 10 ? '0' + minutes : minutes
      const strTime = hours + ':' + minutes + ' ' + ampm
      const finalDate =
        myDate.getDate() +
        '-' +
        month +
        '-' +
        myDate.getFullYear() +
        ' ' +
        strTime
      return finalDate
    } catch (e) {
      console.log('error in formatting date', +e)
    }
  }
  onClosePopup() {
    this.scheduleMaintenance = false
  }

  clearAllData() {
    if (this.sharedService.getLocalStorageData(CUSTOMER_TOKEN_INFO)) {
      this.authenticationService.clearCustomerLocalStorageItems()
    }
    this.baseService.setAlertPopupAfterTimeout(false)
    console.log("monitoring the session expired pop up issue, 5")
    this.baseService.setUnAuthrizePopup(false)
    setTimeout(() => {
      this.baseService.setUserInformation('')
      this.showLogin = this.baseService.getLogout() ? true : false
    }, 100)
  }

  userRedirection() {
    this.showLogin = false
    this.baseService.setAlertPopupAfterTimeout(false)
    console.log("monitoring the session expired pop up issue, 6")
    this.baseService.setUnAuthrizePopup(false)
    this.baseService.setUserInformation(this.sharedService.getLocalStorageData(CUSTOMER_CURRENT_USER));
    this.userInfoData = this.sharedService.getLocalStorageData(CUSTOMER_LOGIN_RESPONSE);
    const customerData = this.sharedService.getLocalStorageData(CUSTOMER_CUSTOMER_DATA);
    if (this.userInfoData['user'].new) {
      this.router.navigate([this.changePasswordUrl])
    } else {
      if (this.userInfoData['customer']['tenantId'] !== 0) {
        this.customerConfigurationService.setLoginCustResponse(customerData)
        this.customerObj = customerData
        this.getHomePage()
      } else {
        this.setCSApp()
      }
    }
  }

  public validateData() {
    if (
      this.userInfoData instanceof Object &&
      this.userInfoData.user instanceof Object
    ) {
      // this.userInfoData["users"]["auth_token"] = this.userInfoData["userInfo"]["authToken"];
      this.sharedService.setLocalStorageData(CUSTOMER_SESSION_EXPIRED, false)
      this.userInfoData.userInfo = Object.assign(
        this.userInfoData.user,
        this.userInfoData.customer
      )
      this.setValues()
      if (this.userInfoData['user'].new) {
        this.router.navigate([this.changePasswordUrl]);
      } else {
        try {
          const todayEpoch = new Date().getTime();
          const storedData = this.sharedService.getLocalStorageData(CUSTOMER_PASSWORD_EXPIRY_INFO);
          if (!storedData.ssoUser) {
            if (this.userInfoData.user.lastPasswordChangedDate !== null && this.userInfoData.user.lastPasswordChangedDate !== 0) {
              if (storedData.passwordExpiryDays !== null && +storedData.passwordExpiryDays > 0) {
                let checkingDate: any;
                checkingDate = this.userInfoData.user.lastPasswordChangedDate + (storedData.passwordExpiryDays * 24 * 60 * 60 * 1000)
                if (new Date(checkingDate) <= new Date(todayEpoch)) {
                  this.router.navigate([this.changePasswordUrl])
                } else {
                  this.getCustomerData();
                }
              } else {
                this.getCustomerData();
              }
            } else {
              this.getCustomerData();
            }
          } else {
            this.getCustomerData();
          }
        } catch (e) {
          this.getCustomerData();
        }
      }
    } else {
      this.showLogin = true
      this.errorMsgShow = true
      this.errorMsg = this.userInfoData.message
    }
  }
  private setValues(): void {
    this.baseService.setUserInformation(this.userInfoData.userInfo)
    this.sharedService.setLocalStorageData(CUSTOMER_LOGIN_RESPONSE, this.userInfoData);
    this.sharedService.setLocalStorageData(CUSTOMER_CURRENT_USER, this.userInfoData.userInfo);
    this.baseService.setCookie('username', encodeURIComponent(this.userInfoData.user.email), 30, '/');
  }

  onNextClick() {
    let decryptText;
    if (!this.loginForm.controls.email.valid) {
      this.loginForm.controls.email.markAsDirty();
      const errors = this.loginForm.controls.email.errors;
      if (errors && !errors['required']) {
        this.loginForm.controls.email.setErrors({ invalidEmail: true });
      }
      return;
    }
    this.email = this.loginForm.controls.email.value;
    this.sharedService.setLocalStorageData(CUSTOMER_EMAIL, this.email)
    this.authenticationService
      .checkSSOUserAuthentication(this.email)
      .pipe(takeWhile(() => this._compActive))
      .subscribe((response) => {
        if (response instanceof Object) {
          if (
            response.hasOwnProperty('responseStatus') &&
            response.responseStatus.hasOwnProperty('code')
          ) {
            if (response.responseStatus.code === 200) {
              decryptText = this.apiService.getDecryptedText(response.result.toString());
              this.loginForm.controls.email.disable();
              this.currentUserId = decryptText.user.userId;
              this.currentUserEmail = decryptText.user.email;
              this.errorMsgShow = false
              if (
                decryptText instanceof Object &&
                decryptText.user instanceof Object &&
                decryptText.user.hasOwnProperty('ssoUser')
              ) {
                const obj = {};
                obj['lastPasswordChangedDate'] = decryptText.user.lastPasswordChangedDate;
                obj['notifyPasswordExpiryDays'] = decryptText.user.notifyPasswordExpiryDays;
                obj['passwordExpiryDays'] = decryptText.user.passwordExpiryDays;
                obj['email'] = decryptText.user.email;
                obj['userId'] = decryptText.user.userId;
                obj['ssoUser'] = decryptText.user.ssoUser;
                this.sharedService.setLocalStorageData(CUSTOMER_PASSWORD_EXPIRY_INFO, obj);
                if ('activationType' in decryptText.user && decryptText.user.activationType === 'Mail' &&
                  'new' in decryptText.user && decryptText.user.new === true) {
                  this.errorMsgShow = true;
                  this.errorMsg = this.authConst.errors.userActivationPending;
                } else {
                  if (decryptText.user.ssoUser) {
                    window.location.href =
                      `${environment.zuulApiEndPointPath}${environment.authenticationServiceApiPath}/authconfiguration?userEmail=${this.email}`
                  } else {
                    this.loginForm.addControl('password', new FormControl('', Validators.required));
                    this.showFormBasedFields = true
                  }
                }
              }
            } else {
              this.errorMsgShow = true
              if (
                response.hasOwnProperty('message') &&
                typeof response.message === 'string'
              ) {
                this.errorMsg = response.message
              }
            }
          }
        }
      })
  }

  public submit2FaCode() {
    this.showErrorMsg2Fa = false;
    this.configService.validate2FaCode(this.currentUserEmail, this.currentUserId, this.code2fa)
      .pipe(takeWhile(() => this._compActive))
      .subscribe(data => {
        if (data) {
          if (data.result.authenticated.toLowerCase() === 'true') {
            this.getUserInfo()
          } else {
            // show error message
            this.showErrorMsg2Fa = true;
          }
        }
      })
  }

  public submitInvalidForm() {
    sessionStorage.removeItem('ENLP')
    if (!this.loginForm.valid) {
      this.loginForm.controls.email.markAsDirty();
      this.loginForm.controls.password.markAsDirty();
      return;
    }

    this.getAuthenticationToken();
  }

  public getAuthenticationToken() {
    this.configService.getAuthenticationToken(
      'password',
      false,
      this.email,
      this.loginForm.controls.password.value,
      null
    )
      .pipe(takeWhile(() => this._compActive))
      .subscribe(
        (data) => {
          if (data instanceof Object && data.hasOwnProperty('access_token')) {
            this.sharedService.setLocalStorageData(CUSTOMER_TOKEN_INFO, data);
            this.configService.caluculateTokenExpiryTime()
            if (data instanceof Object && data.hasOwnProperty('status_2fa') && (data.status_2fa === 'ENABLED' || data.status_2fa === 'ACTIVATED')) {
              this.showLoginForm = false;
              if (data.status_2fa === 'ACTIVATED') {
                this.showQrCode = true;
                this.qrCodeImage = data.qr_code;
              }
            }
            else {
              this.getUserInfo()
            }
          } else {
            this.showLogin = true
            this.errorMsgShow = true
            this.errorMsg = data.message
          }
        },
        (error) => {
          this.showLogin = true
          this.errorMsgShow = true
          this.errorMsg = error
        }
      )
  }
  public getUserInfo() {
    this.authenticationService.getUserInfo()
      .pipe(takeWhile(() => this._compActive))
      .subscribe((data) => {
        if (data) {
          this.userInfoData = data
          this.validateData()
        }
      })
  }

  redirectedFromTaskEmailLink() {
    return (this.dataStorageService.encrptedStringTaskId &&
      this.dataStorageService.encrptedStringTaskId !== '');
  }
  redirectToContextLandingPage() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['../contextlandingpage', this.dataStorageService.encrptedStringTaskId.taskId], { relativeTo: this.activatedRoute }
    ).then(() => {
      this.router.onSameUrlNavigation = 'ignore';
    });
  }
  getHomePage() {
    try {
      const localStorageCurrencyObject = this.sharedService.getLocalStorageData(CUSTOMER_CUSTOMER_DATA);
      const homepage = this.loginService.setupHomePage();

      if (this.redirectedFromTaskEmailLink()) {
        this.redirectToContextLandingPage();
      }
      else {
        if (localStorageCurrencyObject.baseCusomerInfo.isLandingEnabled) {
          this.returnUrl = this.authConst.urls.landingPage;
        }
        else {
          this.returnUrl = `/app/${homepage}`;
        }

        this.router.navigate([this.returnUrl]);
      }
    }
    catch (error) {
      console.error('Error ingetHomePage ', error);
    }
  }
  addInsightsLeftNavForSuperAdmin(leftNavFeatures) {
    const filteredTabs = leftNavFeatures.filter(
      (feature) => feature.view === 'contentlabinsights'
    )
    const insightsLeftNav = {
      id: 581,
      order: 5,
      title: 'Insights Beta',
      view: 'contentlabinsights',
      level: 0,
      privilegeMap: null,
    }
    if (!filteredTabs.length) {
      leftNavFeatures.push(insightsLeftNav)
    }
  }
  getCustomerData() {
    if (this.userInfoData['customer']['tenantId'] !== 0) {
      this.configService
        .getUserConfiguration(
          this.userInfoData['customer']['tenantId'],
          this.userInfoData['user']['userId']
        )
        .pipe(takeWhile(() => this._compActive))
        .subscribe(
          (val) => {
            if (val && val.responseStatus && val.responseStatus.code === 200) {
              if (val.status === 500 || val.result === null) {
                this.showLogin = true
                this.errorMsgShow = true
                this.errorMsg = this.authConst.errors.userConfig;
                return
              }
              if (this.userInfoData.userInfo.superAdmin) {
                if (
                  val.result.configurationData.hasOwnProperty(
                    'commodityConfigurationData'
                  ) &&
                  val.result.configurationData.commodityConfigurationData.hasOwnProperty(
                    'leftNavbarFeatures'
                  )
                ) {
                  this.addInsightsLeftNavForSuperAdmin(
                    val.result.configurationData.commodityConfigurationData
                      .leftNavbarFeatures
                  )
                }
                if (
                  val.result.configurationData.hasOwnProperty(
                    'productConfigurationData'
                  ) &&
                  val.result.configurationData.productConfigurationData.hasOwnProperty(
                    'leftNavbarFeatures'
                  )
                ) {
                  this.addInsightsLeftNavForSuperAdmin(
                    val.result.configurationData.productConfigurationData
                      .leftNavbarFeatures
                  )
                }
              }
              if (
                val.result.configurationData.hasOwnProperty(
                  'commodityConfigurationData'
                )
              ) {
                const subTabFeatures =
                  val.result.configurationData.commodityConfigurationData
                    .subTabFeatures
                for (const key of Object.keys(subTabFeatures)) {
                  if (
                    Object.keys(subTabFeatures[key])[0] === 'Partner Score Card'
                  ) {
                    subTabFeatures[key]['partnerscorecard'] =
                      subTabFeatures[key]['Partner Score Card']
                    delete subTabFeatures[key]['Partner Score Card']
                  }
                }
              }
              if (
                val.result.configurationData.hasOwnProperty(
                  'productConfigurationData'
                )
              ) {
                const subTabFeatures =
                  val.result.configurationData.productConfigurationData
                    .subTabFeatures
                for (const key of Object.keys(subTabFeatures)) {
                  if (
                    Object.keys(subTabFeatures[key])[0] === 'Partner Score Card'
                  ) {
                    subTabFeatures[key]['partnerscorecard'] =
                      subTabFeatures[key]['Partner Score Card']
                    delete subTabFeatures[key]['Partner Score Card']
                  }
                }
              }
              this.baseService.setAppLoader(false)
              val = this.loginService.setupUserConfiguration(
                val,
                this.userInfoData
              )
              this.customerConfigurationService.setLoginCustResponse(val.result)
              this.customerObj = val.result

              this.sharedService.setLocalStorageData(CUSTOMER_CUSTOMER_DATA, val.result);
              if (localStorage.getItem(CUSTOMER_NAVIGATE_ROUTE_EXISTS)) {
                const navigateRouteObj = JSON.parse(localStorage.getItem(CUSTOMER_NAVIGATE_ROUTE_EXISTS))
                this.dataStorageService.setQueryParams(navigateRouteObj.queryParams)
                if (navigateRouteObj && String(navigateRouteObj.route).trim().length > 0) {
                  this.router.navigate([navigateRouteObj.route])
                }
                else {
                  this.getHomePage()
                }
              } else {
                this.getHomePage()
              }
            } else {
              this.showLogin = true
              this.errorMsgShow = true
              this.errorMsg = this.authConst.errors.userConfig;
            }
          },
          (error) => {
            console.log(error)
          }
        )
    } else {
      this.setCSApp()
    }
  }

  setCSApp() {
    if (this.userInfoData['user']['superAdmin']) {
      this.baseService.setCsApp(true)
      this.sharedService.setLocalStorageData(CUSTOMER_CS_APP, true)
      if (this.baseService.getCsApp()) {
        this.router.navigate([`/app/${csAppHomePage}`])
        this.baseService.setAppLoader(false)
        return
      }
    } else {
      this.showLogin = true
      alert('user is not mapped to any tenant and user is not  superAdmin')
    }
  }

  showSupportedBrowser() {
    this.showSupportedBrowserInformation = true
  }

  onDismissEvent($event) {
    this.showSupportedBrowserInformation = false
  }
  ngOnDestroy() {
    this.dataStorageService.setNavigateRoute(undefined)
    this._compActive = false;
  }
}
