import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'preload-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.sass']
})

export class PreloadTableComponent implements OnInit {

  constructor() { }

  ngOnInit() { }
}
