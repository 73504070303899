import { Component, OnInit } from '@angular/core';

import { AppFilter } from './shared/app-filter.model';
import { AppFilterService } from './shared/app-filter.service';
import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService';

@Component({
  selector: 'app-filter',
  templateUrl: 'app-filter.component.html',
  styleUrls: ['./app-filter.component.sass'],
  providers: [AppFilterService]
})

export class AppFilterComponent implements OnInit {
  appFilter: AppFilter[] = [];
  custConfname: any;
  supName: string;



  constructor(private appFilterService: AppFilterService,private customerConfigurationService: CustomerConfigurationService
  ) { }

  ngOnInit() {
    // this.custConfname= this.customerConfigurationService.getCommodityConfData();
    // this.supName =this.custConfname..supplier;

  }
}
