import { DataStorageService } from 'app/modules/common/data-storage.service';
import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { DetailViewService } from 'app/modules/srfq/detail/detail-view/shared/detail-view.service';
import { AlertsService } from 'app/modules/core/alerts/shared/alerts.service';



@Component({
  selector: 'child-cell',
  template: `<span class="cursor-pointer">
	           <button type="button" class="btn btn-link padding-top-2" title="View">
			   <b>view</b>
				</button>
				</span>&nbsp;&nbsp;&nbsp;
				<span class="cursor-pointer text-success" (click)="acknowledge($event)" >
				<em [ngClass]="{'ld-grey': flag, 'ld-green' : !flag}" class="{{img}} font-size-15  font-bold" title="{{alertmsg}}" ></em> 
				</span>&nbsp;&nbsp;&nbsp;
				<span class="text-danger cursor-pointer" (click)="clear($event)">
					<em class="ld-icon-dismiss font-size-15 ld-danger font-bold" title="Clear this alert"></em> 
				</span>`

})
export class RfxAlertsComponent {
  public params: any;
  rfqId: number;
  public responseObject: any;
  public customPayLoadObject: any; ng
  public alertIdArray = [];
  public alertResponseObject: any;
  public clearAlertResponseObject: any;
  public img= '';
  public flag = false;
  public alertmsg= '';

  constructor(private dataStorageService: DataStorageService, private router: Router,
    private detailViewService: DetailViewService,
    private alertsService: AlertsService) {

  }
  agInit(params: any): void {
    // console.log(params["data"].acknowledged);
    if (params['data'].acknowledged === true) {
      this.img = 'ld-icon-check';
      this.flag = true;
      this.alertmsg = '';
    } else {
      this.img = 'ld-icon-check';
      this.alertmsg = 'Acknowledge this alert';
    }

  }
  acknowledge(event) {
    const alertId = this.params.data.alertId;
    this.replyAlert(alertId);

  }
  clear(event) {
    const alertId = this.params.data.alertId;
    this.clearAlert(alertId);

  }
  public replyAlert(alertId) {
    this.alertIdArray.push(alertId);
    this.alertsService.replyAlert(this.alertIdArray).subscribe(
      (data) => this.alertResponseObject = data,
      (error) => console.log('Unable to reply for alert id in expand view:' + alertId),
      () => this.checkAlertStatus()
    );
  }
  public checkAlertStatus() {
    if (this.alertResponseObject['responseStatus'].code === 200) {
    }
  }

  public clearAlert(alertId) {
    this.alertIdArray.push(alertId);
    this.alertsService.ClearAlert(this.alertIdArray).subscribe(
      (data) => this.clearAlertResponseObject = data,
      (error) => console.log('Unable to reply for alert id in expand view:' + alertId),
      () => this.checkClearAlertStatus()
    );
  }

  public checkClearAlertStatus() {
    if (this.clearAlertResponseObject['responseStatus'].code === 200) {
    }
  }


}
