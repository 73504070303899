import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import * as moment from 'moment';

import { FILTERCONDITIONS } from './filter.constants';

@Component({
  selector: 'cprt-event-filters',
  templateUrl: './event-filters.component.html',
  styleUrls: ['./event-filters.component.sass'],
})
export class EventFiltersComponent implements OnInit {
  @Input('defaultDimensions') defaultDimensions = []
  @Input('filterType') filterType = null
  @Output() onFilterChange = new EventEmitter<any>()

  selectedDimensions = []
  filterDimensions = []
  filterRows = []
  filterConditions: any = []
  maxDate;
  constructor() { }

  ngOnInit() {
    this.filterConditions = FILTERCONDITIONS
    this.filterDimensions = JSON.parse(JSON.stringify(this.defaultDimensions))
    const filterObject = {
      dimensions: this.filterDimensions,
      selectedDim: null,
      selectedDimType: null,
      dimensionValues: null,
      dimensionListConfiguration: null,
      resetDimensionValues: this.generateRandomKey(),
      selectedCondition: this.filterConditions[this.filterDimensions[0].type][0]
      // selectedCondition: this.filterConditions[this.filterDimensions[0].columnDataType][0]
    }
    this.filterRows.push(JSON.parse(JSON.stringify(filterObject)))
  }

  generateRandomKey() {
    const crypto = window.crypto
    const array = new Uint32Array(1)
    return crypto.getRandomValues(array)[0]
  }

  onDimensionValueSelect(filterRow, item, index) {
    if (item.configuration.filterConfig && item.configuration.filterConfig.dependencies) {
      const dependencyRowIndex = this.filterRows.findIndex(row => row.dimensionListConfiguration && row.dimensionListConfiguration.val === item.configuration.filterConfig.dependencies)
      if (dependencyRowIndex > -1 && dependencyRowIndex > index) {
        const dependencyRow = this.filterRows[dependencyRowIndex]
        dependencyRow.resetDimensionValues = this.generateRandomKey()
        dependencyRow.dimensionListConfiguration.defaultParameter = {}
        dependencyRow.dimensionListConfiguration.defaultParameter[dependencyRow.dimensionListConfiguration.filterConfig.dependencies] = item.selectedItems
      }
    }
    const selectedItems = item.configuration.type === 'static' ? item.selectedItem.val : item.selectedItems
    switch (filterRow.selectedDimType) {
      case 'set':
      case 'text':
        filterRow.dimensionValues = !selectedItems.length
          ? null
          : selectedItems
        break
      case 'boolean':
        filterRow.dimensionValues =
          item.actionType === 'add' ? JSON.parse(item.selectedItem.val) : null
        break
      case 'date':
        filterRow.dimensionValues = !selectedItems.length
          ? null
          : selectedItems
        break
    }
    this.getFilterOutput()
  }

  onDimensionValueChange(filterRow) {
    this.getFilterOutput()
  }

  onAddFilterRow(rowIndex, event) {
    event.stopPropagation()
    const filterObject = {
      dimensions: this.filterDimensions,
      selectedDim: null,
      selectedDimType: null,
      dimensionValues: null,
      dimensionListConfiguration: null,
      resetDimensionValues: this.generateRandomKey(),
    }
    this.filterRows.push(JSON.parse(JSON.stringify(filterObject)))
    this.getFilterOutput()
  }

  onDeleteFilterRow(rowIndex, selectedRow, event) {
    event.stopPropagation()
    const findIndex = this.selectedDimensions.indexOf(
      selectedRow.selectedDim.view
    )
    if (findIndex !== -1) {
      this.selectedDimensions.splice(findIndex, 1)
    }

    const totalDimensions = JSON.parse(JSON.stringify(this.defaultDimensions))
    this.filterDimensions = []
    totalDimensions.forEach((dimension) => {
      if (this.selectedDimensions.indexOf(dimension.view) === -1) {
        this.filterDimensions.push(dimension)
      }
    })

    this.filterRows.splice(rowIndex, 1)
    this.getFilterOutput()
  }

  onClearAllFilters() {
    this.filterRows = []
    this.selectedDimensions = []
    this.filterDimensions = JSON.parse(JSON.stringify(this.defaultDimensions))
    const filterObject = {
      dimensions: this.filterDimensions,
      selectedDim: null,
      selectedDimType: null,
      dimensionValues: null,
      dimensionListConfiguration: null,
      resetDimensionValues: this.generateRandomKey(),
    }
    this.filterRows.push(JSON.parse(JSON.stringify(filterObject)))
    this.getFilterOutput()
  }

  onFilterDimensionChange(rowIndex, currentFilterRow, selectedItem) {
    if (
      currentFilterRow.selectedDim !== null &&
      currentFilterRow.selectedDim.view === selectedItem.view
    ) {
      return
    }
    currentFilterRow.selectedDim = selectedItem
    currentFilterRow.selectedDimType = selectedItem.type

    this.maxDate = ''
    if (selectedItem.disableFutureDate) {
      this.maxDate = moment().format('YYYY-MM-DD')
    }
    if (this.selectedDimensions.indexOf(selectedItem.view) === -1) {
      this.selectedDimensions = this.filterRows.map((filterRow) => {
        return filterRow.selectedDim.view
      })
    }

    const totalDimensions = JSON.parse(JSON.stringify(this.defaultDimensions))
    this.filterDimensions = []
    totalDimensions.forEach((dimension) => {
      if (this.selectedDimensions.indexOf(dimension.view) === -1) {
        this.filterDimensions.push(dimension)
      }
    })

    currentFilterRow.selectedCondition = this.filterConditions[selectedItem.type][0]
    currentFilterRow.resetDimensionValues = this.generateRandomKey()
    let dimensionListConfiguration = null
    switch (selectedItem.type) {
      case 'set':
        if (selectedItem.hasOwnProperty('servicePath')) {
          switch (selectedItem.inputType) {
            case 'multiSelectDropdown':
              dimensionListConfiguration = {
                label: selectedItem.displayName,
                type: 'newautosearch',
                filterConfig: {
                  multiSelect: true,
                  targetedType: 'riskManagementService',
                  servicePath: selectedItem.servicePath,
                  dependencies: selectedItem.dependencies,
                },
                defaultParameter: selectedItem.defaultParameter,
                hideLabel: true,
                val: selectedItem.view,
                data: [],
                id: `${selectedItem.view}-filter-${this.filterType}`,
              }
              break
            case 'singleSelectDropdown':
              break
          }
        } else {
          dimensionListConfiguration = {
            label: selectedItem.displayName,
            type: 'static',
            hideLabel: true,
            val: selectedItem.view,
            id: `${selectedItem.view}-filter-${this.filterType}`,
            data: selectedItem.data,
            enableCustomFilter: selectedItem.enableCustomFilter
          }
        }
        break
      case 'boolean':
        dimensionListConfiguration = {
          label: selectedItem.displayName,
          type: 'static',
          hideLabel: true,
          val: selectedItem.view,
          id: `${selectedItem.view}-filter-${this.filterType}`,
          data: [
            {
              val: 'true',
              displayName: 'True',
            },
            {
              val: 'false',
              displayName: 'False',
            },
          ],
        }
        break
      default:
        break
    }
    currentFilterRow.dimensionListConfiguration = dimensionListConfiguration
    if (dimensionListConfiguration && dimensionListConfiguration.filterConfig && dimensionListConfiguration.filterConfig.dependencies) {
      const dependentRow = this.filterRows.find(row => row.dimensionListConfiguration && row.dimensionListConfiguration.val === currentFilterRow.dimensionListConfiguration.filterConfig.dependencies)
      if (dependentRow) {
        currentFilterRow.dimensionListConfiguration.defaultParameter = {}
        currentFilterRow.dimensionListConfiguration.defaultParameter[currentFilterRow.dimensionListConfiguration.filterConfig.dependencies] = dependentRow.dimensionValues
      }
    }
    this.getFilterOutput()
  }

  getFilterOutput() {
    const addedFilters = {
      type: this.filterType,
      data: [],
    }
    this.filterRows.forEach((filterRow) => {
      let filter: any = {
        label: filterRow.selectedDim.view,
        key: filterRow.selectedDim.view,
        condition: filterRow.selectedCondition.filterOperatorEnum,
        selectedItems: filterRow.dimensionValues,
        type: filterRow.selectedDimType
      }
      switch (filterRow.selectedDimType) {
          case 'set':
            filter.selectedItems = typeof filterRow.dimensionValues  === 'string' ? [filterRow.dimensionValues] : filterRow.dimensionValues
            break;
          case 'date':
            if (filterRow.selectedConditionValue) {
              filter.dateFrom =
                moment(
                  new Date(filterRow.selectedConditionValue).toUTCString()
                ).valueOf() / 1000;
            }
            if (filterRow.selectedConditionValueTo) {
              filter.dateTo =
                moment(
                  new Date(filterRow.selectedConditionValueTo).toUTCString()
                ).valueOf() / 1000;
            }
            break;
          case 'boolean':
            filter.condition = 'equals'
            break;
          case 'number':
            filter.selectedItems = filterRow.selectedConditionValue
            if (filterRow.selectedCondition.filterOperatorEnum === 'inRange') {
              filter.filterTo = filterRow.selectedConditionValueTo;
            }
            break;
        default:
          if (filterRow.dimensionValues !== null) {
            filter = {
              label: filterRow.selectedDim.view,
              key: filterRow.selectedDim.view,
              condition: filterRow.selectedCondition.filterOperatorEnum,
              selectedItems: filterRow.dimensionValues,
              type: filterRow.selectedDimType
            }
          }
          break
      }
      addedFilters.data.push(filter)
    })
    this.onFilterChange.emit(addedFilters)
  }
}
