import { Injectable } from '@angular/core';


export interface EventMetaInfo {
  statusList?: any[]
  newsList?: any[]
  typesList?: any[]
  eventId: string,
  eventData?: any,
  isClone?: boolean
}
@Injectable({
  providedIn: 'root'
})
export class EventMetaInfoService {
  private eventMetaInfo = {
    statusList: [],
    newsList: [],
    typesList: [],
    eventId: null,
    eventData: null,
    isClone: false
  } as EventMetaInfo
  constructor() { }

  setStatusList(value: any[]) {
    this.eventMetaInfo.statusList = value
  }
  setNewsList(value: any[]) {
    this.eventMetaInfo.newsList = value
  }
  setTypesList(value: any[]) {
    this.eventMetaInfo.typesList = value
  }
  setEventId(value: any) {
    this.eventMetaInfo.eventId = value
  }
  setEventData(value: any) {
    this.eventMetaInfo.eventData = value
  }
  setCloneStatus(value: any) {
    this.eventMetaInfo.isClone = value
  }

  getEventId() {
    return this.eventMetaInfo.eventId
  }
  getStatusList() {
    return this.eventMetaInfo.statusList
  }
  getTypesList() {
    return this.eventMetaInfo.typesList
  }
  getNewsList() {
    return this.eventMetaInfo.newsList
  }
  getEventMetaInfo() {
    return this.eventMetaInfo
  }
  getCloneStatus() {
    return this.eventMetaInfo.isClone
  }
}
