import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AgGridModule } from '@ag-grid-community/angular';
import { RfqConfigComponent } from './rfq-config.component';
import { CoreModule } from '../core/core.module';
import { AppRoutingModule } from './rfq-config.routes';

import { CreateTemplateComponent } from './create-template/create-template.component';
import { ShowTemplatesComponent } from './show-templates/show-templates.component';
import { EditTemplateComponent } from './edit-template/edit-template.component';
import { TemplateFeildsComponent } from './edit-template/template-feilds/template-feilds.component';
import { MetricsComponent } from './edit-template/metrics/metrics.component';
import { addNewTemplateFeild } from './edit-template/add-new-template-feild/add-new-template-feild.component';
import { TemplateNavbarComponent } from './edit-template/template-navbar/template-navbar.component';
import { RfqConfigService } from './shared/rfq-config.service';
import { MetricItemComponent } from './edit-template/metric-item/metric-item.component';
import { RfqCalculationsComponent } from './edit-template/rfq-calculations/rfq-calculations.component';
import { FieldInfo } from './edit-template/add-new-template-feild/field-info/field-info.component';
import { Format } from './edit-template/add-new-template-feild/format/format.component';
import { OtherConfig } from './edit-template/add-new-template-feild/other-config/other-config.component';
import { Deviation } from './edit-template/add-new-template-feild/deviation/deviation.component';
import { Calculation } from './edit-template/add-new-template-feild/calculation/calculation.component';
import { PermissionsComponent } from './edit-template/add-new-template-feild/permissions/permissions.component';
import { fieldDependency } from './edit-template/add-new-template-feild/field-dependency/field-dependency.component';
import { FieldActions } from './shared/components/field-actions/field-actions.component';
import { ToastService } from 'app/modules/srfq/shared/toaster.service';
import { ToasterModule } from 'angular2-toaster';
import { UploadDownloadService } from 'app/modules/srfq/shared/upload-download.service';
import { TemplatePreviewComponent } from 'app/modules/rfq-configurations/edit-template/template-feilds/template-preview/template-preview.component';
import { ValidationsComponent } from './edit-template/add-new-template-feild/validations/validations.component';
import { ValidationItemComponent } from './edit-template/add-new-template-feild/validation-item/validation-item.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { CanNavigateAway } from 'app/modules/srfq/create/shared/can-navigate-guard.service';
import { SharedModule } from '../shared/shared.module';
import { ShowCustomerDataComponent } from './show-customer-data/show-customer-data.component';
import { DeriveValuesComponent } from './edit-template/add-new-template-feild/derive-values/derive-values.component';
@NgModule({
  imports: [
    FormsModule,
    CoreModule,
    CommonModule,
    AppRoutingModule,
    ToasterModule,
    AgGridModule.withComponents([FieldActions]),
    DpDatePickerModule,
    SharedModule
  ],
  declarations: [
    RfqConfigComponent,
    CreateTemplateComponent,
    ShowTemplatesComponent,
    EditTemplateComponent,
    TemplateFeildsComponent,
    MetricsComponent,
    TemplateNavbarComponent,
    addNewTemplateFeild,
    PermissionsComponent,
    MetricItemComponent,
    RfqCalculationsComponent,
    FieldInfo,
    OtherConfig,
    Deviation,
    Format,
    Calculation,
    fieldDependency,
    FieldActions,
    TemplatePreviewComponent,
    ValidationsComponent,
    ValidationItemComponent,
    ShowCustomerDataComponent,
    DeriveValuesComponent
  ],
  providers: [
    RfqConfigService,
    ToastService,
    UploadDownloadService,
    CanNavigateAway
  ],
  exports: [RfqConfigComponent]
})
export class RfqConfigModule {}
