import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { BaseService } from 'app/modules/common/base.service';

import { Np } from './np.model';
import { ApiServiceProvider } from 'app/api-service';
import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NpService {
  envDetails: any;
  targetType: any;
  summary: any;
  loading = new BehaviorSubject<boolean>(false);
  loadingnp = new BehaviorSubject<boolean>(false);
  constructor(
    private apiServiceProvider: ApiServiceProvider,
    private baseService: BaseService,
    private customerConfigurationService: CustomerConfigurationService
  ) {}

  public fetchDataOfSelectedSupplier(
    applicationScope,
    selectedName,
    selectedValue
  ) {
    this.envDetails = this.customerConfigurationService.getEnvironmentDetails();
    this.targetType = this.envDetails.coreServices;
    const userName = this.baseService.getUserInformation().email;
    const object: any = {
      targetedType: 'NegotiationService',
      formParams: {
        scope: applicationScope,
        userName,
        selectedName,
        selectedValue: encodeURIComponent(selectedValue)
      },
      method: 'GET',
      servicePath: '/npSummary'
    };
    return this.apiServiceProvider.post(object);
    // let data = {
    //     url : "/np/npSummary?scope="+applicationScope+"&userName="+userName+"&selectedName="+selectedName+"&selectedValue="+selectedValue

    //  }
    // return this.apiServiceProvider.npPost(data);
  }
  public fetchDataOfSelectedSupplierDetail(
    applicationScope,
    selectedName,
    selectedValue,
    invalidLabels,
    showNegativeValues
  ) {
    this.envDetails = this.customerConfigurationService.getEnvironmentDetails();
    this.targetType = this.envDetails.coreServices;
    const userName = this.baseService.getUserInformation().email;
    const object: any = {
      targetedType: 'NegotiationService',
      formParams: {
        scope: applicationScope,
        userName,
        selectedName,
        showNegativeValues,
        selectedValue: encodeURIComponent(selectedValue)
      },
      payload: invalidLabels,
      method: 'POST',
      servicePath: '/npDetailsData'
    };
    return this.apiServiceProvider.post(object);
    //   let data = {
    //       url : "/np/npDetailsData?scope="+applicationScope+"&userName="+userName+"&selectedName="+selectedName+"&selectedValue="+selectedValue

    //    }
    //    return this.apiServiceProvider.npPost(data);
  }
  setloadingdetail(val) {
    this.loading.next(val);
  }
  getLoadingdetail() {
    return this.loading.asObservable();
  }
  setloadingnp(val) {
    this.loadingnp.next(val);
  }
  getLoadingnp() {
    return this.loadingnp.asObservable();
  }

  public updatePertience(
    classificationId,
    classificationItemId,
    isLikedDisLiked
  ) {
    this.envDetails = this.customerConfigurationService.getEnvironmentDetails();
    this.targetType = this.envDetails.coreServices;
    const userEmail = this.baseService.getUserInformation().email;
    const object: any = {
      targetedType: 'CommonService',
      formParams: {
        userEmail,
        classification: classificationId,
        classficationItemId: classificationItemId,
        isRelevant: isLikedDisLiked
      },
      method: 'GET',
      servicePath: '/savePertinence'
    };
    return this.apiServiceProvider.post(object);
  }
  retriveCompanyProfile(formParams,tenantList){
    const payload = {
      method: 'POST',
      servicePath: '/profile-get',
      payload: tenantList,
      formParams,
      targetedType: 'NegotiationService'
    };
    return this.apiServiceProvider.post(payload);

  }


}
