import { Component, OnInit, OnDestroy } from '@angular/core';
import { RfqConfigService } from '../../shared/rfq-config.service';
import {
  METRIC_KEY_OBJECT,
  MODIFIED_METRIC_KEYS
} from 'app/modules/rfq-configurations/shared/constants';
import { mockMetricData } from 'app/modules/rfq-configurations/shared/mockData';
import { ToastService } from 'app/modules/srfq/shared/toaster.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ObjectType } from 'app/modules/common/interface/objectype';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';

@Component({
  selector: 'metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.sass']
})
export class MetricsComponent implements OnInit, OnDestroy {
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  templateId: number;
  public addNewMetricEnabled = false;
  public currentMetrics: any;
  public originalMetric: any;
  public metricsChanged = false;
  public createNewDisabled = true;
  public metricReady = false;
  public newMetric: any;
  private defaultMetric = {
    metricName: '',
    templateId: 1,
    metricType: '1',
    metricCategory: '1',
    screenLocation: '1',
    formula: ''
  };
  public metricKeys: any = [];
  public metricKeysObject: any = [];
  public metricItemData: any;
  public modifiedMetricKeys: any = [];
  public templateIdSubscription: Subscription;
  constructor(
    private rfqConfigService: RfqConfigService,
    private toaster: ToastService,
    private router: Router
  ) {
    this.metricKeys =
      METRIC_KEY_OBJECT instanceof Object ? Object.keys(METRIC_KEY_OBJECT) : {};
    this.metricKeysObject = METRIC_KEY_OBJECT;
    this.metricItemData = {
      metricKeys: this.metricKeysObject,
      refSet: {}
    };
    this.modifiedMetricKeys = MODIFIED_METRIC_KEYS;
  }

  ngOnInit() {
    this.templateIdSubscription = this.rfqConfigService
      .getTemplateId()
      .subscribe(id => {
        if (!id) {
          this.router.navigate([`app/templates`]);
          return;
        }
        this.templateId = id;
        this.initMetric();
      });
  }

  initMetric() {
    this.getRefSets();
  }

  getRefSets() {
    this.rfqConfigService
      .getRefSetConstants(this.metricKeys.join(','))
      .subscribe(res => {
        if (res && res instanceof Array) {
          const refSet: ObjectType = {};
          res.map(data => {
            refSet[data.refSetKey] = data.refSetTerms;
          });
          this.metricItemData.refSet = refSet;
          this.getMetricConfig();
        }
      });
  }

  getMetricConfig() {
    this.rfqConfigService
      .getRfqConfiqMetrics(this.templateId)
      .subscribe(res => {
        if (res && res.status !== 500) {
          this.originalMetric = JSON.parse(JSON.stringify(res));
          this.currentMetrics = this.processMetricResultObj(
            this.originalMetric
          );
          this.metricReady = true;
        } else {
          this.metricReady = false;
        }
      });
  }

  processMetricResultObj(obj) {
    const metricObj = JSON.parse(JSON.stringify(obj));
    metricObj.forEach(data => {
      this.metricKeys.forEach(key => {
        const keyObj = this.metricKeysObject[key];
        data[keyObj.actualValue] = this.getDefaultValue(
          this.metricItemData.refSet[key],
          data[keyObj.keyValue]
        );
      });
    });
    return metricObj;
  }

  getDefaultValue(refSetItems, value) {
    return refSetItems.find(x => x.refTermActualValue === value);
  }

  metricItemChanged(event) {
    if (this.modifiedMetricKeys.indexOf(event.type)) {
      event.item[event.type] = event.value;
    } else {
      return;
    }
    if (!event.value) {
      this.metricsChanged = false;
    } else {
      this.checkMetricUpdated();
    }
  }

  checkMetricUpdated() {
    let updated = false;
    for (let i = 0; i < this.originalMetric.length; i++) {
      for (const key in this.originalMetric[i]) {
        if (this.originalMetric[i][key] !== this.currentMetrics[i][key]) {
          updated = true;
          break;
        }
        if (updated) {
          break;
        }
      }
    }
    this.metricsChanged = updated;
  }

  addNewMetric() {
    this.addNewMetricEnabled = true;
    this.newMetric = this.processMetricResultObj([this.defaultMetric])[0];
  }

  saveMetric() {
    const payload = this.createMetricSavePayload(this.currentMetrics);
    this.rfqConfigService
      .saveMetricsChanges(payload, this.templateId)
      .subscribe(res => {
        if (res) {
          this.metricsChanged = false;
          this.toaster.populateToaster('success', 'Metrics Saved');
        }
      });
  }

  newMetricChanged(event, type) {
    if (event && event instanceof Object) {
      this.newMetric[type] = event.refTermActualValue;
    }
  }

  newMetricFormulaChanged(event) {
    if (event && typeof event === 'string' && event.trim().length > 0) {
      this.newMetric.formula = event;
    }
  }

  cancelNewMetric() {
    this.addNewMetricEnabled = false;
  }

  createNewMetric() {
    const payload = this.createMetricSavePayload([this.newMetric]);
    this.rfqConfigService
      .createNewMetric(payload, this.templateId)
      .subscribe(res => {
        if (res) {
          this.getMetricConfig();
          this.addNewMetricEnabled = false;
          this.metricsChanged = false;
          this.toaster.populateToaster(
            'success',
            'Created New Metric Successfully'
          );
        }
      });
  }

  createMetricSavePayload(metric) {
    const payload = JSON.parse(JSON.stringify(metric));
    payload.forEach(payloadItem => {
      this.metricKeys.forEach(key => {
        delete payloadItem[this.metricKeysObject[key].actualValue];
      });
    });
    return payload;
  }

  Navigate() {
    this.router.navigate(['app/templates']);
  }

  ngOnDestroy() {
    this.templateIdSubscription.unsubscribe();
  }
}
