import { Injectable } from '@angular/core';
import { ApiServiceProvider } from 'app/api-service';
import { BaseService } from 'app/modules/common/base.service';

@Injectable()
export class TaskElementService {

  constructor(
    private apiService: ApiServiceProvider,
    private baseService: BaseService
  ) { }
  sendEmailNotification(input,created) {
    const inp = {
      targetedType: 'Collaboration',
      payload: input,
      formParams: {
        isForCreate: created,
      },
      method: 'POST',
      servicePath: '/task/sendEmailNotification'
    };
    return this.apiService.post(inp);
  }
  updateTask(input) {
    const inp = {
      targetedType: 'Collaboration',
      payload: input,
      method: 'PUT',
      servicePath: '/task/updateTask'
    };
    return this.apiService.post(inp);
  }
}
