import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SelectionCriteria } from 'app/modules/interactive-edit/shared/selection.criteria.model';
import { UserStageModel } from '../shared/user-stage.model';
import { UserStageRequest } from '../shared/user-stage.request';
import { JobModel } from '../shared/job.model';
import { ApiServiceProvider } from 'app/api-service';
import { EditableEntityModel } from './editable-entity.model';
import {CUSTOMER_AUDIT_ID, CUSTOMER_AUDIT_ID_COPY, CUSTOMER_CELLS} from '../../shared/services/shared-consts';

@Injectable()
export class InteractiveEditService extends ApiServiceProvider {



  gridReadyToLoad = new Subject<any>();
  showSubTabs = new Subject<any>();
  loadApprovalMap = new Subject<any>();
  totalNotifications = new Subject<any>();
  systemApprovedEdits = new Subject<any>();
  systemApprovedEditSets = new Subject<any>();
  approveOrReject = new Subject<any>();
  showGridLoading = new Subject<any>();
  changesHasToApply = new Subject<any>();
  private ieHeaders: Headers;
  private gridHeaders: any;
  private gridData: any;
  private showTable = false;
  private entitySelected = new Subject<EditableEntityModel>();
  private entitySelectedFromDropDown: any;
  private fiscalYearSelected: any;
  private quartersSelected: any;
  private modifiedValue: any;
  private modifiedEntityDetail: any;
  private modifiedData: any;
  private userStageModel: UserStageModel[];
  private editedCells: string[];
  private dataModified = new Subject<boolean>();
  private currentQuarter: Date;

  quarterSelected: any;
  selectionCriteria: SelectionCriteria;
  private selectedEntity: EditableEntityModel;
  private editableEntities: EditableEntityModel[];
  private fiscalYears: any[];
  private quarters: any[];
  private lockedQuarter: any;
  entitySelectedObserver = new Subject<EditableEntityModel>();

  setGridReadyToLoad(obj) {
    this.gridReadyToLoad.next(obj);
  }

  getGridReadyToLoadResponse() {
    return this.gridReadyToLoad.asObservable();
  }

  setShowSubTabs(obj) {
    this.showSubTabs.next(obj);
  }

  getShowSubTabs() {
    return this.showSubTabs.asObservable();
  }

  setLoadApprovalMap(obj) {
    this.loadApprovalMap.next(obj);
  }

  getLoadApprovalMap() {
    return this.loadApprovalMap.asObservable();
  }

  setTotalNotifications(obj) {
    this.totalNotifications.next(obj);
  }

  setSystemApprovedEdits(obj) {
    this.systemApprovedEdits.next(obj);
  }

  setSystemApprovedEditSets(obj) {
    this.systemApprovedEditSets.next(obj);
  }

  getTotalNotifications() {
    return this.totalNotifications.asObservable();
  }
  setApproveOrReject(obj) {
    this.approveOrReject.next(obj);
  }

  getApproveOrReject() {
    return this.approveOrReject.asObservable();
  }

  setLockedQuarter(lockedQuarter: any) {
    this.lockedQuarter = lockedQuarter;
  }

  getLockedQuarter() {
    return this.lockedQuarter;
  }

  setShowGridLoading(obj) {
    this.showGridLoading.next(obj);
  }

  getShowGridLoading() {
    return this.showGridLoading.asObservable();
  }

  setChangesHasToApply(obj) {
    this.changesHasToApply.next(obj);
  }

  isChangesHasToApply() {
    return this.changesHasToApply.asObservable();
  }

  public getHeaders(selectionCriteria: SelectionCriteria) {
    // return this.http.get(this.headersUrl + environment['tenantId'] + '/' + selectionCriteria.entityName+ '/' + selectionCriteria.calendarLocked, {headers: this.headers})
    // .map(function (res: Response){ return res.json()});
    const object: any = {
      targetedType: 'IE',
      formParams: {
        entity: selectionCriteria.entityName,
        isCalendarLocked: selectionCriteria.calendarLocked
      },
      method: 'GET',
      servicePath: 'editUI/columnsStyle'
    };
    return this.post(object);
  }

  public getData(selectionCriteria: SelectionCriteria) {
    // return this.http.get(this.dataUrl + environment['tenantId'] + '/' + selectionCriteria.entityName + "/"
    //   + selectionCriteria.fiscalYear + "/" + selectionCriteria.quartersQueryString+"/", {headers: this.ieHeaders})
    //   .map(function (res: Response){ return res.json()})
    const object: any = {
      targetedType: 'IE',
      formParams: {
        entity: selectionCriteria.entityName,
        fiscalYear: selectionCriteria.fiscalYear,
        fiscalQuarters: selectionCriteria.quartersQueryString,
        lockedQuarter: this.lockedQuarter
      },
      method: 'GET',
      servicePath: 'editUI/data'
    };
    return this.post(object);
  }

  public getCurrentQuarter() {
    // return this.http.get(this.currentQuarterUrl + "/" + environment['tenantId'] + '/', {headers: this.ieHeaders})
    //   .map(function (res: Response){ return res.json()})
    const object: any = {
      targetedType: 'IE',
      formParams: {},
      method: 'GET',
      servicePath: 'editUI/currentQuarter'
    };
    return this.post(object);
  }

  public setCurrentQuarterValue(currentQuarter: any) {
    this.currentQuarter = currentQuarter;
  }

  public getCurrentQuarterValue() {
    return this.currentQuarter;
  }

  public saveUserEdits(
    userStageModel: UserStageModel[],
    auditId: any,
    auditIdCopy: any
  ) {
    // return this.http.post(this.saveUserEditsUrl + "/" + auditId + "/" + auditIdCopy, JSON.stringify(userStageModel), {headers: this.headers})
    /* .subscribe(res=>{
      const response = res.text();
    }) */
    this.userStageModel.forEach(element => {
      // console.log(element)
      element.tenantId = this.envDetails.ieTenantId;
    });
    const object: any = {
      targetedType: 'IE',
      formParams: {
        auditId,
        auditIdCopy
      },
      payload: new UserStageRequest(userStageModel),
      method: 'POST',
      servicePath: 'editUI/userEdits'
    };
    return this.post(object);
  }

  public createJob(jobModel: JobModel) {
    // return this.http.post(environment["createJobUrl"], JSON.stringify(jobModel), {headers: this.ieHeaders})
    const user = jobModel.userPk;
    jobModel = new JobModel(
      this.envDetails.ieTenantId,
      this.envDetails.jobType,
      user,
      localStorage.getItem(CUSTOMER_AUDIT_ID),
      null
    );
    const object: any = {
      targetedType: 'IE',
      payload: {
        tenantId: this.envDetails.ieTenantId,
        jobType: this.envDetails.jobType,
        userPk: user,
        startDt: null,
        info: localStorage.getItem(CUSTOMER_AUDIT_ID)
      },
      method: 'POST',
      servicePath: 'editUI/job'
    };
    return this.post(object);
  }

  public getUserEditedChanges() {
    // return this.http.get(this.editedChangesUrl + "/" + environment['tenantId'] + "/" + sessionStorage.getItem('auditIdCopy'),
    //  {headers: this.ieHeaders})
    const object: any = {
      targetedType: 'IE',
      formParams: {
        auditId: localStorage.getItem(CUSTOMER_AUDIT_ID_COPY)
      },
      method: 'GET',
      servicePath: 'editUI/editedChanges'
    };
    return this.post(object);
  }

  public getFieldValues() {
    // return this.http.get(this.fieldValuesUrl + "/" + environment['tenantId'] + "/" + this.getEntitySelected(), {headers: this.ieHeaders})
    // console.log(this.getEntitySelectedFromDropDown())
    const object: any = {
      targetedType: 'IE',
      formParams: {
        entity: this.getEntitySelectedFromDropDown()
      },
      method: 'GET',
      servicePath: 'editUI/fieldPossibleValues'
    };
    return this.post(object);
  }

  public getFieldTypes() {
    // return this.http.get(this.fieldTypesUrl + "/" + environment['tenantId'] + "/" + this.getEntitySelected(), {headers: this.ieHeaders})
    const object: any = {
      targetedType: 'IE',
      formParams: {
        entity: this.getEntitySelectedFromDropDown()
      },
      method: 'GET',
      servicePath: 'editUI/fieldTypes'
    };
    return this.post(object);
  }

  public isSuperUser() {
    // return this.http.get(this.userTypeUrl + "/" + environment['tenantId'] + "/" + sessionStorage.getItem("ieUser") + "/", {headers: this.ieHeaders})
    const object: any = {
      targetedType: 'IE',
      formParams: {},
      method: 'GET',
      servicePath: 'editUI/userType'
    };
    return this.post(object);
  }

  public getNotificationsAsApprover() {
    // return this.http.get(this.approvalsByApprover + "/" + environment['tenantId'] + "/" + sessionStorage.getItem("ieUser") + "/", {headers: this.ieHeaders})
    const object: any = {
      targetedType: 'IE',
      formParams: {},
      method: 'GET',
      servicePath: 'editUI/approvalsByApprover'
    };
    return this.post(object);
  }
  public getSystemApprovedEdits(auditId: any) {
    // return this.http.get(this.approvalsByRequester + "/" + environment['tenantId'] + "/" + sessionStorage.getItem("ieUser") + "/", {headers: this.ieHeaders})
    const object: any = {
      targetedType: 'IE',
      formParams: {
        auditId
      },
      method: 'GET',
      servicePath: 'editUI/editsDataForSystemApproval'
    };
    return this.post(object);
  }
  public getSystemApprovedEditSets() {
    // return this.http.get(this.approvalsByRequester + "/" + environment['tenantId'] + "/" + sessionStorage.getItem("ieUser") + "/", {headers: this.ieHeaders})
    const object: any = {
      targetedType: 'IE',
      formParams: {},
      method: 'GET',
      servicePath: 'editUI/systemApprovedEditSets'
    };
    return this.post(object);
  }
  public getNotificationsAsRequester() {
    // return this.http.get(this.approvalsByRequester + "/" + environment['tenantId'] + "/" + sessionStorage.getItem("ieUser") + "/", {headers: this.ieHeaders})
    const object: any = {
      targetedType: 'IE',
      formParams: {},
      method: 'GET',
      servicePath: 'editUI/approvalsByRequester'
    };
    return this.post(object);
  }

  public getEditsHeaderForApproval() {
    // return this.http.get(this.editsHeaderForApproval + "/" + environment['tenantId'] + "/", {headers: this.ieHeaders})
    const object: any = {
      targetedType: 'IE',
      formParams: {},
      method: 'GET',
      servicePath: 'editUI/editsHeaderForApproval'
    };
    return this.post(object);
  }

  public getEditsHeaderForSystemApproval() {
    // return this.http.get(this.editsHeaderForApproval + "/" + environment['tenantId'] + "/", {headers: this.ieHeaders})
    const object: any = {
      targetedType: 'IE',
      formParams: {},
      method: 'GET',
      servicePath: 'editUI/editsHeaderForSystemApproval'
    };
    return this.post(object);
  }

  public getEditsDataForApproval(approvalAuditId: any, process: string) {

    let requestPayload: any = {};
    if (process === 'download') {
      // return this.http.get(this.editsDataForApprovalForDownload + "/" + environment['tenantId'] + "/" + approvalAuditId + "/", {headers: this.ieHeaders})
      requestPayload = {
        targetedType: 'IE',
        formParams: {
          approvalAuditId
        },
        method: 'GET',
        servicePath: 'editUI/editsDataForApprovalForDownload'
      };
    } else {
      // return this.http.get(this.editsDataForApproval + "/" + environment['tenantId'] + "/" + approvalAuditId + "/", {headers: this.ieHeaders})
      requestPayload = {
        targetedType: 'IE',
        formParams: {
          approvalAuditId
        },
        method: 'GET',
        servicePath: 'editUI/editsDataForApproval'
      };
    }
    return this.post(requestPayload);
  }

  public approveEdits(approvalAuditId: any, auditId: any) {
    // return this.http.get(this.approveEditsURL + "/" + environment['tenantId'] + "/" + auditId + "/" + approvalAuditId + "/", {headers: this.ieHeaders})
    const object: any = {
      targetedType: 'IE',
      formParams: {
        auditId,
        approvalAuditId
      },
      method: 'GET',
      servicePath: 'editUI/approveRequest'
    };
    return this.post(object);
  }

  public rejectEdits(
    approvalAuditId: any,
    auditId: any,
    rejectionReasion: any
  ) {
    if (rejectionReasion === undefined) {
      rejectionReasion = 'No Rejection Message Recorded';
    } else if (rejectionReasion.trim() === '') {
      rejectionReasion = 'No Rejection Message Recorded';
    }
    const object: any = {
      targetedType: 'IE',
      formParams: {
        auditId,
        approvalAuditId,
        message: rejectionReasion
      },
      method: 'GET',
      servicePath: 'editUI/rejectRequest'
    };
    return this.post(object);
    // return this.http.get(this.rejectEditsURL + "/" + environment['tenantId'] + "/" + auditId + "/" + approvalAuditId + "/" + rejectionReasion + "/", {headers: this.ieHeaders})
  }

  public setGridHeaders(gridHeaders: any) {
    this.gridHeaders = gridHeaders;
  }

  public getGridHeaders() {
    return this.gridHeaders;
  }

  public setGridData(gridData: any) {
    this.gridData = gridData;
  }

  public getGridData() {
    return this.gridData;
  }

  public setShowTable(showTable: boolean) {
    // console.log("setting showTable: " + showTable);
    this.showTable = showTable;
  }

  public getShowTable() {
    return this.showTable;
  }

  public setEntitySelectedFromDropDown(entitySelectedFromDropDown: any) {
    this.entitySelectedFromDropDown = entitySelectedFromDropDown;
  }

  public getEntitySelectedFromDropDown() {
    return this.entitySelectedFromDropDown;
  }

  public setFiscalYearSelected(entitySelected: any) {
    this.fiscalYearSelected = entitySelected;
  }

  public getFiscalYearSelected() {
    return this.fiscalYearSelected;
  }

  public setQuartersSelected(entitySelected: any) {
    this.quartersSelected = entitySelected;
  }

  public getQuartersSelected() {
    return this.quartersSelected;
  }

  public setUserStageModel(userStageModel: UserStageModel) {
    this.userStageModel.push(userStageModel);
  }

  public setModifiedCells(editedCell: string) {
    this.editedCells.push(editedCell);
  }
  public resetModifiedCells() {
    this.sharedService.setLocalStorageData(CUSTOMER_CELLS, undefined);
    this.editedCells = [];
  }
  public getModifiedCells() {
    return JSON.stringify(this.editedCells);
  }

  public getUserStageModel() {
    return this.userStageModel;
  }

  public resetUserStageModel() {
    this.userStageModel = [];
  }

  public setDataModified(dataModified) {
    this.dataModified.next(dataModified);
  }

  public getDataModified() {
    return this.dataModified.asObservable();
  }

  getAuditId(userName: any) {
    return this.createAuditId(userName);
  }
  createAuditId(userName: any) {
    // return this.http.post(environment["auditIdUrl"], JSON.stringify({
    //   "tenantId": environment["tenantId"],
    //   "uidPk": userName,
    //   "action":null,
    //   "effectiveDt":null,
    //   "auditId":null
    //   }  ), {headers: this.ieHeaders})
    const object: any = {
      targetedType: 'IE',
      payload: {
        tenantId: this.envDetails.ieTenantId,
        uidPk: userName,
        action: null,
        effectiveDt: null,
        auditId: null
      },
      method: 'POST',
      servicePath: 'editUI/audit'
    };
    return this.post(object);
  }

  getEditableEntities() {
    return this.editableEntities;
  }
  // setSelectedEntity(selectedEntity:EditableEntityModel) {
  //   this.selectedEntity = selectedEntity;
  //   this.entitySelectedObserver.next(this.selectedEntity);
  // }
  // public getSelectedEntity() {
  //   return this.selectedEntity;
  // }

  // getEditableEntitiesList() {
  //   // return this.http.get(environment["editableEntitiesListUrl"] + "/" + environment['tenantId'], {headers: this.headers})
  //   //   .map(this.extractData);
  //   return this.post();
  // }

  public getEditableEntitiesList() {
    const object: any = {
      targetedType: 'IE',
      formParams: {},
      method: 'GET',
      servicePath: 'editUI/editableEntities'
    };
    return this.post(object);
  }

  getFiscalYearsList(selectedEntityUI: string) {
    // return this.http.get(environment["fiscalYearsListUrl"] + "/" + environment['tenantId']+"/"+selectedEntityUI,{headers: this.headers})
    //   .map(this.extractData);
    const object: any = {
      targetedType: 'IE',
      formParams: {
        entity: selectedEntityUI
      },
      method: 'GET',
      servicePath: 'editUI/fiscalYears'
    };
    return this.post(object);
  }

  getQuartersList(fiscalYear: any, selectedEntityUI: string) {
    // return this.http.get(environment["quartersListUrl"] + "/" + environment['tenantId']+"/"+fiscalYear+"/"+selectedEntityUI,{headers: this.headers})
    //   .map(this.extractData);
    const object: any = {
      targetedType: 'IE',
      formParams: {
        entity: selectedEntityUI,
        yearSelected: fiscalYear
      },
      method: 'GET',
      servicePath: 'editUI/fiscalQuarters'
    };
    return this.post(object);
  }

  //   private extractData(res: any) {
  //     try {
  //         let body: any = res.json();
  //         return body || {};
  //     } catch (e) {
  //         return res._body;
  //     }
  // }
}
