import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
@Injectable()
export class DataExporterService {
  constructor() { }

  public printGraphs(divId: string,graphName: string) {
    try {
      if (document.getElementsByClassName('cancel-footer')) {
        const elems = document.getElementsByClassName('cancel-footer');
        for (let k = elems.length - 1; k >= 0; k--) {
          const parent = elems[k].parentNode;
          parent.removeChild(elems[k]);
        }
      }
      let content = document.getElementById(divId).innerHTML;
      content = '<h2>' +graphName+ '</h2>'+ content;
      const mywindow = window.open('', 'Print', 'height=' + environment.printGraphsHeight +
        ',width=' + environment.printGraphsWidth);
      // size:landscape;
      mywindow.document.write('<style>@page{size: auto; margin: 0mm;}</style>');
      mywindow.document.write(content);
      mywindow.document.close();
      mywindow.focus()
      mywindow.print();
      mywindow.close();
      return true;
    }
    catch (Error) {
      console.log('Error in printGraphs  ' + Error);
    }
  }
}
