import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { AngularResizedEventModule } from 'angular-resize-event'
import { DialogBoxComponent } from './components/dialog-box/dialog-box.component';
import { MomentModule } from 'angular2-moment';
import { HightChartModule } from '../high-charts/high-charts.module';
import { DashboardTileComponent } from './components/dashboard-tile/dashboard-tile.component';
import { AddDirective } from './components/dynamic-load/add.directive';
import { DynamicLoadComponent } from './components/dynamic-load/dynamic-load.component';
import { GraphContainerComponent } from './components/graph-container/graph-container.component';
import { IndustryAlertsComponent } from './components/industry-alerts/industry-alerts.component';
import { MetricHeaderComponent } from './components/metric-header/metric-header.component';
import { MetricKpiComponent } from './components/metric-kpi/metric-kpi.component';
import { ResponsiveContainerComponent } from './components/responsive-container/responsive-container.component';
import { NgxLoadingModule } from 'ngx-loading';
import { DashboardPieChartComponent } from './components/dashboard-pie-chart/dashboard-pie-chart.component';
import { DashboardMetricTrendsComponent } from './components/dashboard-metric-trends/dashboard-metric-trends.component';
import { TaskLocationContextComponent } from './components/task-location-context/task-location-context.component';
import { CustomTagComponent } from './components/custom-tag/custom-tag.component';
import { AddTaskComponent } from './components/add-task/add-task.component';
import { CustomDropdownComponent } from './components/custom-dropdown/custom-dropdown.component';
import { GenericDashboardComponent } from './components/generic-dashboard/generic-dashboard.component';
import { DashboardFiltersComponent } from './components/dashboard-filters/dashboard-filters.component';
import { RadialChartModule } from './components/radial-chart/radial-chart.module';
import { RadialChartComponent } from './components/radial-chart/radial-chart.component';
import { DashboardGridComponent } from './components/dashboard-grid/dashboard-grid.component';
import { LdGridModule } from './modules/ld-grid/ld-grid.module';
import { LegendKpiComponent } from './components/legend-kpi/legend-kpi.component';

@NgModule({
  declarations: [
    DialogBoxComponent,
    DashboardTileComponent,
    AddDirective,
    DynamicLoadComponent,
    IndustryAlertsComponent,
    GraphContainerComponent,
    ResponsiveContainerComponent,
    MetricHeaderComponent,
    MetricKpiComponent,
    LegendKpiComponent,
    DashboardMetricTrendsComponent,
    DashboardPieChartComponent,
    TaskLocationContextComponent,
    CustomTagComponent,
    AddTaskComponent,
    CustomDropdownComponent,
    GenericDashboardComponent,
    DashboardFiltersComponent,
    DashboardGridComponent,
  ],
  imports: [
    CommonModule,
    HightChartModule,
    DragDropModule,
    AngularResizedEventModule,
    MomentModule,
    LdGridModule,
    NgxLoadingModule.forRoot({}),
    RadialChartModule
  ],
  exports: [DialogBoxComponent,
    DashboardTileComponent,
    CustomTagComponent,
    CustomDropdownComponent,
    TaskLocationContextComponent,
    AddTaskComponent,
    GenericDashboardComponent,
    DashboardFiltersComponent,
  ],
  entryComponents: [
    MetricKpiComponent,
    LegendKpiComponent,
    GraphContainerComponent,
    ResponsiveContainerComponent,
    DynamicLoadComponent,
    IndustryAlertsComponent,
    DashboardMetricTrendsComponent,
    DashboardPieChartComponent,
    DashboardFiltersComponent,
    RadialChartComponent,
    DashboardGridComponent
  ]
})
export class LevaUiLibraryModule { }
