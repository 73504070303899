import {
  Component,
  ViewChild,
  ViewContainerRef,
  AfterViewInit
} from '@angular/core';
import { DataStorageService } from 'app/modules/common/data-storage.service';

@Component({
  selector: 'child-cell',
  template: `
    <div tabindex="0">
      <multi-select-dropdown
        [items]="this.dropDownValues"
        [dropdownTitle]="'Please select User permissions'"
        readKey="groupName"
        dropdownId="params.data.userId"
        [defaultSelected]="defaultSelectedValues"
        [disabledText]="'Administrator'"
        (onSelectionChange)="onViewSelectionChange($event)"
        (onUnCheckAll)="multiselectCheckandUncheck()"
      ></multi-select-dropdown>
    </div>
  `
})
export class GroupsMultiSelectRendererComponent {
  private params: any;
  dropDownValues: any;
  defaultSelectedValues: Array<object> = [];
  private selectedGroups: object = {};
  private defaultSelectedObjects: any;
  private disableTextMsg: string;

  constructor(private dataStorageService: DataStorageService) {}

  agInit(params: any): void {
    this.defaultSelectedValues = [];

    params.data.groups.forEach(element => {
      element.checked = element.selected;
      if (element.selected) {
        this.defaultSelectedValues.push(element);
      }
    });

    this.dataStorageService.setDefaultSelectedGroups({
      [params.data.userId]: this.defaultSelectedValues
    });
    this.params = params;
    if (params.data.tenantAdmin === 'Yes' || params.data.superAdmin === 'Yes') {
      this.dropDownValues = [];
      // this.disableTextMsg = "No need to select a group"
    } else {
      this.dropDownValues = JSON.parse(JSON.stringify(params.data.groups));
    }
  }
  onViewSelectionChange(event) {
    if (event instanceof Array) {
      Object.assign(this.params.data.groups, event);
      const param1 = this.params;
      param1.value = event;
      this.selectedGroups[this.params.data.userId] = param1;
      this.dataStorageService.setCheckedUserGroupsInGrid(this.selectedGroups);
    }
  }
  multiselectCheckandUncheck() {}
  private setValue(params) {
    if (params.data.userId === this.params.data.userId) {
      this.defaultSelectedValues = [];
      if (
        params.data.tenantAdmin === 'Yes' ||
        params.data.superAdmin === 'Yes'
      ) {
        params.data.groups.forEach(element => {
          element.checked = false;
          this.defaultSelectedValues = [];
        });
        // this.disableTextMsg = "No need to select a group"
        this.dropDownValues = [];
      } else {
        this.dropDownValues = JSON.parse(JSON.stringify(params.data.groups));
        params.data.groups.forEach(element => {
          element.checked = element.selected;
          if (element.selected) {
            this.defaultSelectedValues.push(element);
          }
        });
      }
    }
  }
  ngOnInit() {
    this.dataStorageService.getTenantAdmin().subscribe(params => {
      if (params !== undefined) {
        this.setValue(params);
      }
    });
  }
}
