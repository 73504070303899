interface Toggle {
  name: string;
  enabled: boolean;
}

type FlagEvent = 'initialized' | 'error' | 'ready' | 'update';

const flagEvents = {
  Init: 'initialized',
  Error: 'error',
  Ready: 'ready',
  Update: 'update'
} as const;

export { Toggle, FlagEvent, flagEvents };


