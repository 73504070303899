import 'rxjs/add/operator/map';
import * as _ from 'lodash';
export class ApiCommonService {
  public getcommonobjects(payLoadJSON, data) {
    for (const type of Object.keys(data)) {
      let cmsServiceType;
      switch (type.trim()) {
        case 'formParams':
        case 'headerParams':
          return Object.assign(payLoadJSON[type], data[type]);

        case 'serviceType':
          return (cmsServiceType = data[type.trim()]);

        case 'servicePath':
          return (payLoadJSON[type] =
            '/' +
            payLoadJSON['clientName'] +
            '/REST/services/' +
            data['servicePath']);

        case 'method':
          return (payLoadJSON[type] = data['method']);
        case 'clientName':
          return (payLoadJSON[type] = data[type.trim()]);

        case 'userName':
          return (payLoadJSON[type] = data[type.trim()]);

        case 'password':
          return (payLoadJSON[type] = data[type.trim()]);

        case 'newPassword':
          return (payLoadJSON[type] = data[type.trim()]);

        case 'authToken':
          return (payLoadJSON[type] = data[type.trim()]);
      }
    }
  }
  getArrayDiff(selectedArray, toBeRemovedArray) {
    return _.differenceWith(
      selectedArray,
      toBeRemovedArray,
      _.isEqual
    );
  }
  removeArrayDuplicates(inputArray) {
    const uniqueArray = inputArray.filter((elem, index, self) => {
      return index === self.indexOf(elem);
    });
    return uniqueArray;
  }
  validateServiceCallData(data) {
    if (
      data !== undefined &&
      data instanceof Object &&
      data.responseStatus !== undefined &&
      data.responseStatus.code === 200
    ) {
      return data;
    } else {
      return 'error';
    }
  }
}
