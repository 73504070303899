import { SearchListInfo } from './search-list/search-list.component';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';
import { cityListConfiguration, countryListConfiguration, stateListConfiguration } from '../save-event.constants';
import { ImpactedSuppliersService } from './impacted-suppliers.service';

interface LocationFilter {
  country: any[]
  state: any[]
  city: any[]
}

@Component({
  selector: 'cprt-impacted-suppliers',
  templateUrl: './impacted-suppliers.component.html',
  styleUrls: ['./impacted-suppliers.component.sass'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ImpactedSuppliersComponent),
    multi: true,
  }]
})
export class ImpactedSuppliersComponent implements OnInit, ControlValueAccessor {

  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION

  supplierListMetaInfo = null
  selectedSupplierListMetaInfo = null
  @Output() filterMetaInfo = new EventEmitter()

  _defaultSelection: any[] = []
  get defaultSelection() {
    return this._defaultSelection
  }
  @Input() set defaultSelection(val) {
    this._defaultSelection = val
    this.setSelectedSuppliers(val)
    this.initLocationObject()
    this.getLocationDetails()
    // this.getSuppliersList()
    this.setSupplierListInfo()
    this.setSelectedSupplierListInfo()
  }
  loaderConfig = {
    backdropBackgroundColour: 'rgba(255,255,255,0.3)',
    fullScreenBackdrop: 'true',
    primaryColour: '#1C4E84',
    secondaryColour: '#1C4E84',
    tertiaryColour: '#1C4E84'
  }
  showPreloader = true
  availableFilters = [
    {
      key: 'location',
      displayName: 'Location',
      selected: false,
      filterKey: 'location',
      filterCriteria: null
    },
    {
      key: 'myPortfolio',
      displayName: 'Others',
      selected: false,
      filterKey: 'myPortfolio',
      filterCriteria: null
    }
  ]
  selectedFilter: any = { selected: true }
  searchLocationFilter: any = null
  portfolioFilter = false
  searchSupplier = ''
  searchSelectedSupplier = ''
  availableSuppliers = {
    data: [],
    totalRecords: 0,
    selectAll: false
  }
  selectedSuppliersObject = {
    data: [],
    totalRecords: 0,
    selectAll: false
  }
  selectedSuppliers: any = []
  additionalFilterObject = []
  additionalFilterSelected = false
  showLocationPreloader = true
  showSelectAllSection = false

  showSuppliersList = true
  showSelectedSupplierList = true

  propagateChange = (_: any) => { }
  constructor(private impactSupService: ImpactedSuppliersService) {
    this.initAdditionalFilters()
  }

  setSupplierListInfo() {
    const listMetaInfo = {} as SearchListInfo
    listMetaInfo.type = 'dynamic'
    listMetaInfo.additionalFilters = this.additionalFilterObject
    listMetaInfo.servicePath = 'events/suppliers'
    listMetaInfo.method = 'POST'
    listMetaInfo.targetedType = 'riskManagementService'
    listMetaInfo.noDataMessage = 'No suppliers found on the searched criteria'
    listMetaInfo.defaultSelectedItems = this.selectedSuppliers
    this.supplierListMetaInfo = Object.assign({} as SearchListInfo, listMetaInfo)
  }

  setSelectedSupplierListInfo() {
    const selectedListMetaInfo = {} as SearchListInfo
    selectedListMetaInfo.type = 'static'
    selectedListMetaInfo.additionalFilters = []
    selectedListMetaInfo.servicePath = null
    selectedListMetaInfo.method = null
    selectedListMetaInfo.targetedType = null
    selectedListMetaInfo.noDataMessage = 'No suppliers are selected'
    selectedListMetaInfo.defaultSelectedItems = JSON.parse(JSON.stringify(this.selectedSuppliers))
    selectedListMetaInfo.staticItems = JSON.parse(JSON.stringify(this.selectedSuppliers))
    this.selectedSupplierListMetaInfo = Object.assign({} as SearchListInfo, selectedListMetaInfo)
  }

  setSelectedSuppliers(data) {
    const localArray = []
    data.forEach((val) => {
      localArray.push({
        displayName: val,
        selected: true,
      })
    })
    this.selectedSuppliers = Object.assign([], localArray)
  }

  initAdditionalFilters() {
    this.additionalFilterObject = Object.assign([], this.availableFilters.map(({ filterKey, filterCriteria }) => ({ filterKey, filterCriteria })))
  }

  initLocationObject() {
    const listConfiguration = {
      country: JSON.parse(JSON.stringify(countryListConfiguration)),
      state: JSON.parse(JSON.stringify(stateListConfiguration)),
      city: JSON.parse(JSON.stringify(cityListConfiguration))
    }
    listConfiguration.city.filterConfig.multiSelect = true
    listConfiguration.city.showSelectAll = true
    listConfiguration.state.isDisable = listConfiguration.city.isDisable = false

    this.searchLocationFilter = {
      countryObject: {
        name: 'country',
        config: JSON.parse(JSON.stringify(listConfiguration.country)),
        resetValues: this.generateRandomKey()
      },
      stateObject: {
        name: 'state',
        config: JSON.parse(JSON.stringify(listConfiguration.state)),
        resetValues: this.generateRandomKey()
      },
      cityObject: {
        name: 'city',
        config: JSON.parse(JSON.stringify(listConfiguration.city)),
        resetValues: this.generateRandomKey()
      },
      properties: this.initLocationDetails({} as LocationFilter)
    }
  }

  getLocationDetails() {
    const location = this.searchLocationFilter
    this.showLocationPreloader = true
    const selectedLocationFilter
      = this.isAdditionalFiltersAdded()
        ? this.additionalFilterObject.filter(filterObj => filterObj.filterKey === 'location') : []
    if (selectedLocationFilter.length) {
      const locationData = Object.assign({}, selectedLocationFilter[0].filterCriteria)
      this.setSelectedValue(location.countryObject.config.filterConfig, locationData.country)
      this.setSelectedValue(location.stateObject.config.filterConfig, locationData.state)
      // this.setSelectedValue(location.cityObject.config.filterConfig, locationData.city)

      if (JSON.stringify(locationData.city) === JSON.stringify(['ALL'])) {
        location.cityObject.config.selectAllDefault = true
      } else {
        this.setSelectedValue(location.cityObject.config.filterConfig, locationData.city)
      }

      this.setDefaultParameter(location.stateObject.config, {
        country: locationData.country,
      })
      this.setDefaultParameter(location.cityObject.config, {
        country: locationData.state,
      })
      setTimeout(() => {
        this.showLocationPreloader = false
      }, 10)
    } else {
      this.showLocationPreloader = false
    }
  }

  setSelectedValue(filterConfig, value) {
    filterConfig.defaultItemSelection = true
    filterConfig.defaultSelectedItem = value
  }

  setDefaultParameter(config, paramValue) {
    config.defaultParameter = paramValue
    config.isDisable = true
  }

  ngOnInit() {
  }

  onSearchClick() {
    this.showSuppliersList = false
    setTimeout(() => {
      this.showSuppliersList = true
    }, 0)
  }

  getSuppliersList() {
    const payload = {
      filter: this.isAdditionalFiltersAdded() ? this.getAdditionalFilters() : [],
      limit: 500,
      offset: 0,
      key: this.searchSupplier
    }
    this.additionalFilterSelected = this.isAdditionalFiltersAdded() ? true : false
    this.showPreloader = true
    this.availableSuppliers.data = []
    this.impactSupService.getSupplierList(payload).subscribe(
      (supplierListResponse) => {
        if (supplierListResponse && supplierListResponse.result) {
          const value = supplierListResponse.result.suggestions
          this.availableSuppliers.data = this.convertToLocalFormat(value)
          this.availableSuppliers.totalRecords = supplierListResponse.result.numberOfHits
          this.showSelectAllSection
            = (this.additionalFilterSelected || this.searchSupplier.length)
              ? this.availableSuppliers.totalRecords
                ? true
                : false
              : false
          setTimeout(() => {
            this.showPreloader = false
          }, 0)
        } else {
          this.additionalFilterSelected = false
        }
      }
    )
  }

  convertToLocalFormat(data) {
    const localArray = []
    if (this.selectedSuppliers) {
      data.forEach((val) => {
        const selectedItem: any[] = this.selectedSuppliers.filter(
          (item) => item.displayName === val
        )
        const isSelected = selectedItem.length ? true : false
        localArray.push({
          displayName: val,
          selected: isSelected,
        })
      })
    } else {
      data.forEach((val) => {
        localArray.push({
          displayName: val,
          selected: false,
        })
      })
    }
    return localArray
  }

  setDefaultSelection(selectedItem) {
    this.selectedSuppliers.push({
      val: selectedItem,
      displayName: selectedItem,
      selected: true,
    })
  }

  isAdditionalFiltersAdded() {
    return this.additionalFilterObject.filter(item => item.filterCriteria !== null).length ? true : false
  }

  getAdditionalFilters() {
    return this.additionalFilterObject.filter(item => item.filterCriteria !== null)
  }

  initLocationDetails(location) {
    location.country = []
    location.state = []
    location.city = []
    return location
  }

  generateRandomKey() {
    const crypto = window.crypto
    const array = new Uint32Array(1)
    return crypto.getRandomValues(array)[0]
  }

  onSelectFilter(selectedFilter) {
    // event.stopPropagation()
    if (selectedFilter.selected) {
      return
    }
    selectedFilter.selected = !selectedFilter.selected
    this.availableFilters.forEach(filter => {
      filter.selected = selectedFilter.key === filter.key ? selectedFilter.selected : false
    })
    this.selectedFilter = selectedFilter
    this.additionalFilterSelected = false
    if (selectedFilter.key === 'location') {
      this.getLocationDetails()
    }
  }

  clearFilterSelection() {
    this.selectedFilter = { selected: false }
    this.availableFilters.forEach(filter => filter.selected = false)
    this.resetAdditionalFilters()
    this.additionalFilterSelected = false
  }

  onLocationPropSelect(params) {
    if (this.searchLocationFilter) {
      const selectedValues = params.selectedItem
        ? params.selectedItem.val === 'ALL'
          ? 'ALL'
          : [params.selectedItem.val]
        : params.selectedItems
          ? params.selectedItems
          : []
      if (this.searchLocationFilter.properties.hasOwnProperty(params.type)) {
        this.searchLocationFilter.properties[params.type] = selectedValues
      }
      switch (params.type) {
        case 'country':
          this.searchLocationFilter.stateObject.config.defaultParameter = {
            country: selectedValues,
          }
          this.searchLocationFilter.stateObject.config.isDisable = false
          this.searchLocationFilter.stateObject.resetValues = this.generateRandomKey()
          this.searchLocationFilter.cityObject.resetValues = this.generateRandomKey()

          break
        case 'state':
          this.searchLocationFilter.cityObject.config.defaultParameter = {
            state: selectedValues,
          }
          this.searchLocationFilter.cityObject.config.isDisable = false
          this.searchLocationFilter.cityObject.resetValues = this.generateRandomKey()
          break
        case 'city':
          break
      }
      const searchLocationProperties = JSON.parse(JSON.stringify(this.searchLocationFilter.properties))
      const filterObject = {
        filterKey: 'location',
        filterCriteria: searchLocationProperties
      }
      this.setAdditionalFilters(JSON.parse(JSON.stringify(filterObject)))
    }
  }

  onSelectItem(event, supplier) {
    event.stopPropagation()
    if (supplier.selected) {
      this.selectedSuppliers.push(JSON.parse(JSON.stringify(supplier)))
    } else {
      this.selectedSuppliers = this.selectedSuppliers.filter(
        (filterItem) => filterItem.displayName !== supplier.displayName
      )
    }
    this.propagateChanges()
  }

  portfolioFilterToggle(event) {
    const filterObject = {
      filterKey: 'myPortfolio',
      filterCriteria: {
        portfolio: this.portfolioFilter
      }
    }
    this.setAdditionalFilters(JSON.parse(JSON.stringify(filterObject)))
  }

  setAdditionalFilters(filterObject) {
    if (this.additionalFilterObject.length) {
      const currentFilterObject = this.additionalFilterObject.filter(currentFilter => currentFilter.filterKey === filterObject.filterKey)
      if (currentFilterObject.length) {
        Object.assign(currentFilterObject[0], ...filterObject)
      } else {
        this.additionalFilterObject.push(filterObject)
      }
    } else {
      this.additionalFilterObject.push(filterObject)
    }
    this.setSupplierListInfo()
  }

  resetAdditionalFilters() {
    this.portfolioFilter = false
    this.additionalFilterSelected = false
    this.initAdditionalFilters()
    this.initLocationObject()
    // this.getSuppliersList()
    this.setSupplierListInfo()
    this.showSuppliersList = false
    setTimeout(() => {
      this.showSuppliersList = true
    }, 0)
  }

  onSelectAll(event) {
    event.stopPropagation()
    this.availableSuppliers.selectAll = true
    this.availableSuppliers.data.forEach(supplier => supplier.selected = true)

    if (this.availableSuppliers.data.length > 500) {
      this.filterMetaInfo.emit({ payload: this.additionalFilterObject, count: this.availableSuppliers.totalRecords })
    } else {
      this.selectedSuppliers = [...this.selectedSuppliers, ...this.availableSuppliers.data]
    }
    this.propagateChanges()
  }

  onClearAll(event) {
    event.stopPropagation()

    if (!this.selectedFilter.selected && !this.searchSupplier.length) {
      this.selectedSuppliers = []
      this.availableSuppliers.data.forEach(supplier => supplier.selected = false)
    } else {
      if (this.availableSuppliers.selectAll && this.availableSuppliers.data.length > 500) {
        this.filterMetaInfo.emit({ payload: this.additionalFilterObject.filter(filterObj => filterObj.filterKey !== this.selectedFilter.filterKey), count: 0 })
      }
      this.availableSuppliers.selectAll = false
      this.availableSuppliers.data.forEach(supplier => supplier.selected = false)

      const sourceList = this.availableSuppliers.data.map(sup => sup.displayName)
      this.selectedSuppliers = this.selectedSuppliers.filter(sup => !sourceList.includes(sup.displayName))

      if (this.selectedFilter) {
        this.clearSelection()
      } else {
        if (!this.searchSupplier.length) {
          this.selectedSuppliers = []
          this.availableSuppliers.data.forEach(supplier => supplier.selected = false)
        } else {
          this.clearSelection()
        }
      }
    }
    this.propagateChanges()
  }

  onSupplierListSelectionChange(selectedItems) {
    this.selectedSuppliers = JSON.parse(JSON.stringify(selectedItems))
    this.setSelectedSupplierListInfo()
    this.showSelectedSupplierList = false
    setTimeout(() => {
      this.showSelectedSupplierList = true
    }, 0)
    this.propagateChanges()
  }

  onSelectedListSelectionChange(selectedItems) {
    this.selectedSuppliers = JSON.parse(JSON.stringify(selectedItems))
    this.setSupplierListInfo()
    this.showSuppliersList = false
    setTimeout(() => {
      this.showSuppliersList = true
    }, 0)
    this.propagateChanges()
  }

  clearSelection() {
    this.selectedSuppliers = this.selectedSuppliers.filter(supplier => supplier.selected === true)
  }

  propagateChanges() {
    this.propagateChange(this.selectedSuppliers.map(supplier => supplier.displayName))
  }

  writeValue(value: any) {
    return value
  }

  registerOnChange(fn) {
    this.propagateChange = fn
  }

  registerOnTouched() { }
}
