import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubscriptionService } from 'app/modules/common/subscription.service';
import { DataStorageService } from 'app/modules/common/data-storage.service';

@Component({
  selector: 'where-used',
  template: `
    <button
      *ngIf="showButton"
      class="btn btn-primary btn-xs dataSheetView"
      id="dataSheet"
      data-toggle="modal"
      data-target="#npiWhereUsedmodal"
      title="Where Used"
      (click)="onButtonClick()"
    >
      U
    </button>
  `
})
export class WhereUsedComponent implements OnInit, OnDestroy {
  params;
  showButton = true;
  constructor(private dataStorageService: DataStorageService) {}

  agInit(params: any): void {
    this.params = params;
    if (params && params.data && params.data.nodeType === 'SOURCE_GROUP') {
      this.showButton = true;
    } else {
      this.showButton = false;
    }
  }
  onButtonClick() {
    this.dataStorageService.setWhereUsedData(this.params.data);
  }

  ngOnInit() {}

  ngOnDestroy() {}
}
