import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import {
  riskTypes,
  riskTypesIds,
  timelineRisks,
  customRisks,
  bomRisks,
  subRiskTypes,
  riskConfigMap,
  bomRepoRisks,
} from './constants';
import { NpiCommonApiService } from '../../npi-common-api.service';
import { NpiAdminRiskService } from './shared/npi-admin-risk.service';
import { ObjectUtils } from '../../../common/utills/ObjectUtils.service';
import {
  API_SERVICE_CONSTANTS,
  TOASTER_MESSAGE_TYPES,
  NPI_ROUTING_PATHS,
} from '../../npiconstants';
import { NpiLoadingService } from '../../npi-loading/npi-loading.service';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { Subject } from 'rxjs';
import { BaseService } from '../../../common/base.service';
import { NpiToasterService } from '../../npi-toaster.service';
import { Router, NavigationEnd } from '@angular/router';

import * as moment from 'moment-timezone-all';
import { DateUtils } from '../../../common/utills/DateUtils.service';
import * as _ from 'lodash';
import { CustomerConfigurationService } from '../../../common/customerConfigurationService';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';

@Component({
  selector: 'npi-admin-risk',
  templateUrl: './npi-admin-risk.component.html',
  styleUrls: ['./npi-admin-risk.component.sass'],
  providers: [NpiAdminRiskService],
})
export class NpiAdminRiskComponent implements OnInit, OnDestroy {
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  public riskConfigTypes = riskTypes;
  public subTypesKeys = subRiskTypes;
  public selectedTab;
  public riskSubTypes;
  public data: any;
  public headers: any;
  public resetChanges = 'hide';
  public candeactivate = new Subject<any>();
  public showNotConfiguredMessage = false;
  @Input() bomRiskConfig;
  @Input() bomSubTypesKeys;
  public dataChangeInTab = {
    generalInfo: true,
    checkpoints: true,
    stages: true,
    programTeam: true,
  };
  public programKey;
  public popUp = true;
  public displayMessage = 'No Program Configured Yet ';
  public previousUrl: string;
  constructor(
    public npiCommonApiService: NpiCommonApiService,
    public npiAdminRiskService: NpiAdminRiskService,
    private baseService: BaseService,
    private router: Router,
    private customerConfigurationService: CustomerConfigurationService
  ) {
    this.npiAdminRiskService.programKeyInfo = this.npiCommonApiService.getSelectedDropdownObject();
    // if (!this.npiAdminRiskService.programKeyInfo) {
    //   this.setSelectedTab(this.riskConfigTypes[0]);
    //   this.riskSubTypes = this.getSubTypes();
    //   if (this.npiCommonApiService.getnoDataMessage()) {
    //     this.npiAdminRiskService.showDataMessage = true
    //   } else {
    //     this.router.navigate([NPI_ROUTING_PATHS.OVERVIEW_PATH])
    //   }
    // }
  }

  public ngOnInit() {
    if (this.bomSubTypesKeys) {
      this.subTypesKeys = this.bomSubTypesKeys;
    }
    if (this.bomRiskConfig) {
      this.riskConfigTypes = this.bomRiskConfig;
      this.setSelectedTab(this.riskConfigTypes[0]);
      this.riskSubTypes = this.getSubTypes();
      // this.npiCommonApiService.setnoDataMessage(true);
      this.npiAdminRiskService.programKeyInfo = {
        programId: -1,
      };
    }

    if (this.npiCommonApiService.getSelectedDropdownObject()) {
      this.programKey = this.npiCommonApiService.getSelectedDropdownObject().programKey;
    }
    if (this.bomRiskConfig) {
      this.getRiskInfo();
      this.fetchBomRisks();
    } else if (this.npiAdminRiskService.programKeyInfo) {
      this.setSelectedTab(this.riskConfigTypes[0]);
      this.riskSubTypes = this.getSubTypes();
      this.getRiskInfo();
      this.fetchBomRisks();
    } else {
      this.showNotConfiguredMessage = true;
      this.setSelectedTab(this.riskConfigTypes[0]);
      this.riskSubTypes = this.getSubTypes();
      if (this.npiCommonApiService.getnoDataMessage()) {
        this.npiAdminRiskService.showDataMessage = true;
      } else {
        this.router.navigate([NPI_ROUTING_PATHS.OVERVIEW_PATH]);
      }
    }
  }

  public setSelectedTab(selected) {
    this.selectedTab = selected;
    this.riskSubTypes = this.getSubTypes();
  }

  public getSubTypes() {
    switch (this.selectedTab.id) {
      case riskTypesIds.timeline:
        return timelineRisks;
      case riskTypesIds.bom:
        if (this.bomRiskConfig) {
          return bomRepoRisks;
        } else {
          return bomRisks;
        }
      case riskTypesIds.custom:
        return customRisks;
    }
  }

  public reset() {
    this.showNotConfiguredMessage = false;
    this.getRiskInfo();
    this.npiAdminRiskService.bomRiskInfo = this.npiAdminRiskService.data;
    this.npiAdminRiskService.enableReset = false;
    this.fetchBomRisks();
    Object.keys(this.npiAdminRiskService.errors).forEach((errorKey) => {
      this.npiAdminRiskService.errors[errorKey] = [];
    });
    this.npiAdminRiskService.stageRiskInfo = [];
    this.npiAdminRiskService.maturityRiskInfo = [];
    this.npiAdminRiskService.saveEnabler = false;
    this.npiAdminRiskService.bomRiskUpdated = false;
  }

  public getRiskInfo() {
    NpiLoadingService.showLoader(true);
    if (this.npiAdminRiskService.programKeyInfo !== undefined) {
      forkJoin(
        this.npiCommonApiService.loadWidgetHeaders(
          API_SERVICE_CONSTANTS.CONFIGURE_RISK
        ),
        this.npiAdminRiskService.retrieveRiskInfo(
          this.npiAdminRiskService.programKeyInfo['programKey']
        )
      ).subscribe(([headers, data]) => {
        if (
          headers &&
          headers['result'] instanceof Object &&
          data &&
          data['result']
        ) {
          headers = headers['result'];
          this.data = this.npiAdminRiskService.riskInfo = ObjectUtils.copy(
            data
          );
          this.npiAdminRiskService.headers = ObjectUtils.copy(headers);
          this.showNotConfiguredMessage = true;
          NpiLoadingService.showLoader(false);
        }
      });
    } else {
      this.showNotConfiguredMessage = true;
      NpiLoadingService.showLoader(false);
    }
  }

  public onSave() {
    let failCount = 0;
    let successCount = 0;
    let serviceCallCount = 0;
    const saveCallList = [];
    saveCallList.push(
      this.npiAdminRiskService.stageRiskInfoUpdated,
      this.npiAdminRiskService.bomRiskUpdated,
      this.npiAdminRiskService.maturityRiskUpdated,
      this.npiAdminRiskService.checkpintInfoUpdated
    );
    serviceCallCount = saveCallList.filter((i) => i === true).length;
    if (this.npiAdminRiskService.stageRiskInfoUpdated) {
      this.npiAdminRiskService
        .saveStageRiskInfo(this.npiAdminRiskService.stageRiskInfo)
        .subscribe((val) => {
          if (val.result) {
            successCount++;
            this.npiAdminRiskService.stageRiskInfo = [];
          } else {
            failCount++;
          }
          this.showToaster(serviceCallCount, successCount, failCount);
        });
    }
    if (this.npiAdminRiskService.checkpintInfoUpdated) {
      const checkpoints = _.cloneDeep(this.data.result.checkpoints);

      checkpoints.forEach((check) => {
        delete check.dateTouched;
        delete check.error;
        delete check.isDefault;
        delete check.risk;
      });
      const postObj = {
        checkpoints,
        programKey: this.programKey,
        tenantId: this.customerConfigurationService.getEnvironmentDetails()
          .tenantId,
        stages: [],
      };
      console.log(this.data.checkpoints);

      this.npiAdminRiskService
        .saveCheckpointRisk(this.programKey, postObj)
        .subscribe((res) => {
          if (
            this.npiCommonApiService.validateServiceCallData(res) !== 'error'
          ) {
            successCount++;
            this.npiAdminRiskService.checkpointRiskInfo = [];
            this.npiAdminRiskService.checkpintInfoUpdated = false;
          } else {
            failCount++;
          }
          this.showToaster(serviceCallCount, successCount, failCount);
        });
    }
    if (this.npiAdminRiskService.bomRiskUpdated) {
      const bomRiskInfo = ObjectUtils.copy(
        this.npiAdminRiskService.bomRiskInfo
      );
      if (!this.bomRiskConfig) {
        bomRiskInfo.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls.forEach(
          (element, i) => {
            if (element) {
              delete element['isDefault'];
              delete element['isAdded'];
              delete element['delete'];
              delete element['error'];
              if (
                moment.isMoment(
                  this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap
                    .NON_PREFERRED_SUPPLIER_RISK.psls[i]['approvedUntilDate']
                )
              ) {
                element['approvedUntilDate'] = DateUtils.getUTCTimeByMoment(
                  this.npiAdminRiskService.bomRiskInfo.result.riskConfigMap.NON_PREFERRED_SUPPLIER_RISK.psls[
                    i
                  ]['approvedUntilDate']
                    .clone()
                    .endOf('day')
                );
              }
              if (!element['approvedUntilDate']) {
                element['approvedUntilDate'] = '';
              }
              if (!element['comments']) {
                element['comments'] = '';
              }
            }
          }
        );
      }

      let bomRiskConfig = false;
      if (this.bomRiskConfig) {
        bomRiskConfig = true;
      }
      this.npiAdminRiskService
        .saveBomRiskInfo(
          this.npiAdminRiskService.programKeyInfo.programId,
          bomRiskInfo,
          bomRiskConfig
        )
        .subscribe((val) => {
          if (val.result) {
            successCount++;
          } else {
            failCount++;
          }
          this.showToaster(serviceCallCount, successCount, failCount);
        });
    }
    if (this.npiAdminRiskService.maturityRiskUpdated) {
      const payload = {
        generalInfo: {
          maturityLevelDefinition: this.npiAdminRiskService.maturityRiskInfo,
        },
        programId: this.npiAdminRiskService.programKeyInfo.programId,
      };
      this.npiAdminRiskService.saveMaturityRisk(payload).subscribe((val) => {
        if (val.result) {
          successCount++;
          this.npiAdminRiskService.maturityRiskInfo = [];
        } else {
          failCount++;
        }
        this.showToaster(serviceCallCount, successCount, failCount);
      });
    }

    this.npiAdminRiskService.saveEnabler = false;
    this.npiAdminRiskService.bomRiskUpdated = false;
  }
  public showToaster(
    serviceCallCount: number,
    successCount: number,
    failCount: number
  ) {
    if (serviceCallCount === successCount + failCount) {
      if (serviceCallCount === successCount) {
        NpiToasterService.populateToaster(
          TOASTER_MESSAGE_TYPES.SUCCESS,
          'Saved Successfully'
        );
        this.npiAdminRiskService.enableReset = false;
        Object.keys(this.npiAdminRiskService.errors).forEach((element) => {
          this.npiAdminRiskService[element] = [];
        });
        this.npiAdminRiskService.saveButtonStatus();
      } else {
        NpiToasterService.populateToaster(
          TOASTER_MESSAGE_TYPES.ERROR,
          'Failed while saving data , Try again'
        );
        this.npiAdminRiskService.enableReset = false;
        this.npiAdminRiskService.saveButtonStatus();
      }
    }
  }

  public fetchBomRisks() {
    NpiLoadingService.showLoader(true);
    if (this.npiAdminRiskService.programKeyInfo !== undefined) {
      let bomRiskConfig = false;
      if (this.bomRiskConfig) {
        bomRiskConfig = true;
      }
      this.npiAdminRiskService
        .retrieveBomRiskInfo(
          this.npiAdminRiskService.programKeyInfo.programId,
          bomRiskConfig
        )
        .subscribe((bomInfo) => {
          if (
            this.npiCommonApiService.validateServiceCallData(bomInfo) !== 'error'
          ) {
            if (Object.keys(bomInfo.result.riskConfigMap).length === 0) {
              bomInfo.result.riskConfigMap = riskConfigMap;
              this.npiAdminRiskService.bomData = this.npiAdminRiskService.bomRiskInfo = ObjectUtils.copy(
                bomInfo
              );
            } else {
              this.npiAdminRiskService.bomData = this.npiAdminRiskService.bomRiskInfo = ObjectUtils.copy(
                bomInfo
              );
            }
            if (this.bomRiskConfig) {
              this.showNotConfiguredMessage = true;
            }
          }
        });
    }
  }

  public canDeactivate() {
    if (!(this.checkSaveChangesExist() || this.checkErrors())) {
      return true;
    } else {
      this.baseService.setAppLoader(false);
      this.resetChanges = 'show';
      return this.candeactivate.asObservable();
    }
  }
  public onclickYes() {
    this.resetChanges = 'hide';
    this.candeactivate.next(true);
  }
  public onclickNo() {
    this.resetChanges = 'hide';
    this.candeactivate.next(false);
  }

  public checkSaveChangesExist() {
    if (
      this.npiAdminRiskService.stageRiskInfo.length > 0 ||
      this.npiAdminRiskService.maturityRiskInfo.length > 0 ||
      this.npiAdminRiskService.bomRiskUpdated ||
      this.npiAdminRiskService.checkpintInfoUpdated
    ) {
      return true;
    }
  }

  public checkErrors() {
    return Object.keys(this.npiAdminRiskService.errors).some(
      (errorKey) => this.npiAdminRiskService.errors[errorKey].length > 0
    );
  }
  public navigateToDashboard() {
    if(this.npiAdminRiskService.programKeyInfo &&  this.npiAdminRiskService.programKeyInfo.programName)
    {
      this.router.navigate([NPI_ROUTING_PATHS.SUMMARY_PATH]);
    }
    else
    {
      this.router.navigate([NPI_ROUTING_PATHS.OVERVIEW_PATH]);
    }
  }
  public navigateToBomManagement() {
    this.router.navigate([NPI_ROUTING_PATHS.BOM_MANAGEMENT_PATH]);
  }
  public ngOnDestroy() {
    this.npiAdminRiskService.stageRiskInfo = [];
    this.npiAdminRiskService.maturityRiskInfo = [];
    Object.keys(this.npiAdminRiskService.errors).forEach(
      (errorKey) => (this.npiAdminRiskService.errors[errorKey] = [])
    );
    this.npiAdminRiskService.bomRiskUpdated = false;
  }
}
