import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class AdhocSubscriptionService {
  /*--------------------- Setting Selected Values from Supplier DropDown  ------------------- */

  private supplierSelectedValues = new Subject<Array<object>>();

  /*--------------------- Setting Template Map for DropDowns  ------------------- */

  private adhocTemplateMap = new Map<string, string>();

  /*--------------------- Setting Row Index for Deletion  ------------------- */

  private rowIndex = new Subject<any>();

  /*--------------------- Setting Row Data on Selection  ------------------- */

  private paramsRowObject = new Subject<object>();

  /*--------------------- Hiding Drop down ------------------- */

  _dropDownStatus = new Subject<any>();

  /*--------------------- Create Payload ------------------- */

  _createPayload = new BehaviorSubject<any>(undefined);

  /*--------------------- Supplier Selected Value ------------------- */

  _supplierValue = new Subject<any>();

  /*----------------------- Primary Key ------------------ */

  primaryKey = new Subject<any>();

  /*-----------------------------Add New Supplier---------------------*/
  newSupplierList: any = [];

  /*--------------------- Setting Entered Values in Single Select  ------------------- */

  private singleSelectedValue = new Subject<any>();

  /*--------------------- Setting CM-ODM Values in Single Select  ------------------- */

  private cmODMValue = new Subject<any>();

  /*--------------------- Setting UID Map for CM-ODM  ------------------- */

  private cpnUIDMap = new Map<number, object>();

  public setSupplierSelectedValues(val) {
    this.supplierSelectedValues.next(val);
  }

  public getSupplierSelectedValues() {
    return this.supplierSelectedValues.asObservable();
  }

  public setAdhocTemplateMap(dbColumnName, templateField) {
    this.adhocTemplateMap.set(dbColumnName, templateField);
  }

  public getAdhocTemplateMap(dbColumnName) {
    return this.adhocTemplateMap.get(dbColumnName);
  }

  public clearAdhocTemplateMap() {
    this.adhocTemplateMap.clear();
  }

  public setRowIndex(val) {
    this.rowIndex.next(val);
  }
  public getRowIndex() {
    return this.rowIndex.asObservable();
  }

  public getParamsRowObject() {
    return this.paramsRowObject.asObservable();
  }

  public setParamsRowObject(rowObject: object) {
    this.paramsRowObject.next(rowObject);
  }

  setHidingDropDownStatus(status) {
    this._dropDownStatus.next(status);
  }

  getHidingDropDownStatus() {
    return this._dropDownStatus.asObservable();
  }

  setCreatePayload(status) {
    this._createPayload.next(status);
  }

  getCreatePayload() {
    return this._createPayload.asObservable();
  }

  setSupplierValue(status) {
    this._supplierValue.next(status);
  }

  getSupplierValue() {
    return this._supplierValue.asObservable();
  }

  setPrimaryKey(val) {
    this.primaryKey.next(val);
  }

  getPrimaryKey() {
    return this.primaryKey.asObservable();
  }

  setSupplierList(status) {
    this.newSupplierList.push(status);
  }

  getSupplierList() {
    return this.newSupplierList;
  }

  public setSingleSelectedValue(val) {
    this.singleSelectedValue.next(val);
  }

  public getSingleSelectedValue() {
    return this.singleSelectedValue.asObservable();
  }

  public setCMODMValue(val) {
    this.cmODMValue.next(val);
  }

  public getCMODMValue() {
    return this.cmODMValue.asObservable();
  }

  public setCPNUIDMap(UID, cpnObject) {
    this.cpnUIDMap.set(UID, cpnObject);
  }

  public getCPNUIDMap(UID) {
    return this.cpnUIDMap.get(UID);
  }

  public clearCPNUIDMap() {
    this.cpnUIDMap.clear();
  }
}
