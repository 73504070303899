import { Injectable, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import 'rxjs/add/operator/map';
@Injectable()
export class DataStorageService {
  proxyOptions: any[];
  constructor() {}
  public displayName = new BehaviorSubject<any>(undefined);
  public rfqAccessed = false;
  public rfqId: any = undefined;
  public allRfqData: any;
  public editedFields: any = [];
  public addTaskConfig: any;
  public cpnName = '';
  public permissionsField: any;
  public encrptedStringTaskId;
  showTaskLoader = false;
  public showDefault = true;
  public searchText = '';
  public hoveredItemPayload: any = null;
  public clickedTaskFilterValue = null;
  public deleteRfqFromNPI = new BehaviorSubject<any>(0);
  public showHeader = true;
  public validationMessages: Array<any> = [];
  public clikedRfqForDetails: any = undefined;
  public additionalInfo: any = undefined;
  public recipientModifiedList: any = undefined;
  insightClosed = new BehaviorSubject<any>(undefined);
  public jsonData = new BehaviorSubject<any>(0);
  public botInput = new BehaviorSubject<any>(0);
  public userInformation: object;
  public deviationMap: object;
  public payLoadJSON: object;
  public botParameters: object;
  public singePartCostLookupResult: any;
  public responseObject: any;
  public tcsubject = new Subject<any>();
  public editedFieldsByRow = new Subject<any>();
  public actionChanged = new Subject<any>();
  public deselectedIds = new Subject<any>();
  public showAwardedRules = new BehaviorSubject<any>(undefined);
  public routeChanged = new BehaviorSubject<any>(undefined);
  supplierName = new BehaviorSubject<any>(undefined);
  public refreshTask = new BehaviorSubject<any>(undefined);
  public selectionChanged = new BehaviorSubject<any>(undefined);
  public previewRounds: string;
  public selectedRFQName: string;
  negLevDes = new Subject<any>();
  public dashboardChanger: string;
  public createDashboard: string;
  public dimensionLeveltrgt = new Subject<any>();
  public selectedUserGroup: object;
  public supplierManagementData: any;
  public supUserGroup: any;
  public selectedRFQId: number;
  public editedData: any;
  public rowsForResend: any;
  public alertData: any;
  public rfxCOmpletePendingData: any;
  public adhocRowObject = {};
  private rowNode = new Subject<any>();
  private dropDownModifierMap = new Map<string, any>([
    ['PRODUCT', {}],
    ['BOM', {}],
    ['TRANSFORMATION', {}],
  ]);
  public filterPayload;
  public viewTypeClicked = new BehaviorSubject<any>(undefined);
  public viewTypePayload = {
    defaultView: false,
    viewDesc: 'View all the RFQs created by the user',
    viewName: 'Current User View',
    viewType: 'CREATED_BY_ME',
    viewTypeId: 2,
  };
  public openEdittaskPayload;
  public reportName = new BehaviorSubject<any>(undefined);
  public reportData = new BehaviorSubject<any>(undefined);
  public alertsMaxMinFlag = new BehaviorSubject<any>(undefined);
  public expandDashboard = new BehaviorSubject<any>(undefined);
  public selectedComponent = new BehaviorSubject<any>(undefined);
  public clickedState = new BehaviorSubject<any>(undefined);
  public editRfqResult: any = undefined;
  public openEditTask = new BehaviorSubject<any>(undefined);
  public addTaskClicked = new BehaviorSubject<any>(undefined);
  public seletedFocus = new BehaviorSubject<any>('Commodity');
  public rmList = new BehaviorSubject<Array<string>>(undefined);
  public uploadResponse = new BehaviorSubject<any>(undefined);
  public detailViewClicked = new BehaviorSubject<any>(undefined);
  public rmSummaryTable = new BehaviorSubject<any>(undefined);
  public botServiceName = new BehaviorSubject<any>(undefined);
  public fileuploadres = new BehaviorSubject<any>(undefined);
  public bomtransuploadres = new BehaviorSubject<any>(undefined);
  public autoSearchHit = new BehaviorSubject<any>(undefined);
  public riskOppRawMaterialOpps = new BehaviorSubject<any>(undefined);
  public fileName = new BehaviorSubject<any>(undefined);
  public rmCpnClicked: any;
  public showTaskPane = true;
  public payloadData = new BehaviorSubject<any>(undefined);
  public payloadForSummary: any = undefined;
  public changedDropDownValues: any = {};
  public spendImpactSummaryData = new BehaviorSubject<any>(undefined);
  public spendImpactDetailData = new BehaviorSubject<any>(undefined);
  public riskOppDetailsSpendImpact = new BehaviorSubject<any>(undefined);
  public riskOppDetailsMPN = new BehaviorSubject<any>(undefined);
  rmObject = new BehaviorSubject<any>(undefined);
  public rmGraph = new BehaviorSubject<any>(undefined);
  public rmPopUpData = new BehaviorSubject<any>(undefined);
  public futuresData = new BehaviorSubject<any>(undefined);
  public timePeriod = new BehaviorSubject<any>(undefined);
  public dimensionData = new BehaviorSubject<any>(undefined);
  public pertinentData = new BehaviorSubject<any>(undefined);
  public requestRating = new BehaviorSubject<any>(undefined);
  public rfqLeveltrgt = new Subject<any>();
  public rqPreviewload = new Subject<any>();
  public dropdownVal = new BehaviorSubject<any>(undefined);
  public isChecked = new Subject<any>();
  public negotiationLeverAttached = new Subject<any>();
  public filterStatus = new BehaviorSubject<any>(undefined);
  public previewTab = new BehaviorSubject<any>(undefined);
  public navigatedFromPreview = new BehaviorSubject<any>(undefined);
  public savedFilters = new BehaviorSubject<any>(undefined);
  public validateUploadErrorMap = {};
  public headerErrorMap = {};
  public failedTsDataStatus = new BehaviorSubject<any>(undefined);
  @Output() timePeriodService: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  columnLevelFilterService: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  gridCommentsRenderService: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  gridHeaderFilterService: EventEmitter<any> = new EventEmitter<any>();
  private stringModifierMap = new BehaviorSubject<any>(undefined);
  private commodityDropDownModifierMap = new Map<string, any>();
  private dropDownNode = new Subject<any>();
  public gridHeaderPayload = {};
  private usersTenantAdmin = new BehaviorSubject<any>(undefined);
  private approverEmails: Array<any> = [];
  public _isApprover = new BehaviorSubject<any>(undefined);
  private savedApproverEmails = {};
  private selectedApproverEmails = new BehaviorSubject<any>(undefined);
  private isUsersGridChangeSaved = false;
  private clearedDefaultFilters = false;
  private expandedViewFlag = new BehaviorSubject<any>(undefined);
  private selectedViewProduct = new BehaviorSubject<any>(undefined);
  public bomSubAssemblyCount = 0;
  private productCreateRFQModelData = null;
  public selectedDropDownVal = new BehaviorSubject<any>(undefined);
  public defaultSelValue: any;
  public clearSearch = new BehaviorSubject<any>(undefined);
  private modifiedPreviewGridData = new BehaviorSubject<any>(undefined);
  private selectedRowStatus = new BehaviorSubject<any>(undefined);
  private selectedTabInCreateFlow = new BehaviorSubject<any>(undefined);
  private storeIdsList = new BehaviorSubject<any>(undefined);
  private selectedScopeIds = new Subject<any>();
  private productEditRfq;
  private commodityMangersList = new Subject<any>();
  private datePickerNode = new Subject<any>();
  private defaultCmList = {};
  public totalCheckBox = new Subject<any>();
  public partSearchID = new BehaviorSubject<any>(undefined);
  public commoditySyncID = new Subject<any>();
  public redirectToPreview = false;
  public npiUniquePartsReq = new BehaviorSubject<any>(undefined);
  public costQTROptions = [];
  public riskRFICode = null;
  public templateId = null;
  public routingParam = null;
  public columnLevelFilterPayload;
  queryParams: any;
  navigateRoute = null;
  private custLevelView: any;
  public routeName;
  private changeView = true;
  public selectedOppView = new BehaviorSubject<string>(undefined);
  public linkFlag = new BehaviorSubject<any>(false);
  public fromManageVendorFlag = new BehaviorSubject<any>(false);
  public editedCells: string[] = [];
  public selectedGridData = new Subject<any>();
  public editedRows: any = [];
  public areaOfSelection = '';
  public sortingData = new BehaviorSubject<any>(undefined);
  public expandData = new BehaviorSubject<any>([]);
  public collapseData = new BehaviorSubject<any>(undefined);
  public editedCellsData = new BehaviorSubject<any>(undefined);
  public resultData = new BehaviorSubject<any>(undefined);
  public collapseFlag = false;
  public checkedArray: string[] = ['', '', '', '', '', 'checked', '', ''];
  public selectedRowsLength = new BehaviorSubject<any>(0);
  public linkValue = new BehaviorSubject<any>(undefined);
  public currentSelectedRow = new BehaviorSubject<any>(undefined);
  public npDetails = new BehaviorSubject<any>(undefined);
  public sortListing = new BehaviorSubject<any>(undefined);
  public statusValue = new BehaviorSubject<any>(undefined);
  public statusdbColumnName = new BehaviorSubject<any>(undefined);
  public newRow = new Subject<any>();
  public display: string;
  public primarykeyArray: any = [];
  public canLogOutList: any = [];
  public numericalData = new Subject<any>();
  public numericalCustomData = new Subject<any>();
  public uploadAlertsData = new Subject<any>();
  public errorRecords: any = [];
  public errorMessage = new BehaviorSubject<any>(undefined);
  public basicAlertsErrors: any = [];
  public uploadThresholdData = new Subject<any>();
  public actionCallBack = new Subject<any>();
  public multileveldata = new Subject<any>();
  public mpnChecked = new Subject<any>();
  public clickedSubjectRfqValues = new Subject<object>();
  public supplierManagementClickedData: any;

  private smBreadcrum = [];
  public statusModifier = new BehaviorSubject<any>(undefined);
  public dropdownStatusModifier = new BehaviorSubject<any>(undefined);
  public changedStatusRow = new BehaviorSubject<any>(undefined);
  public qualitativeInsightsData = new BehaviorSubject<any>(undefined);
  public clickedSupplierDetails = new BehaviorSubject<any>(undefined);
  public selectMasterCost = new BehaviorSubject<any>(undefined);
  public selectPPV = new BehaviorSubject<any>(undefined);
  public status = new BehaviorSubject<any>(undefined);
  public applyClickButton = new BehaviorSubject<any>(undefined);
  public excelPayload: any;
  private viewUserGroupFeatures = new BehaviorSubject<any>(false);
  private checkedUserGroupsInGrid = new BehaviorSubject<any>(undefined);
  private defaultSelectedGroups: object = {};
  smBreadCrum = [
    { title: 'Smart RFX', value: 'app/srfx' },
    { title: 'Manage Suppliers', value: 'app/srfx/suppliermanagment' },
    { title: 'Manage Users', value: 'app/srfx/suppliermanagment/manageusers' },
  ];
  public breadCrumValue = new BehaviorSubject<any>(this.smBreadcrum);
  public clickFunction = new BehaviorSubject<any>(undefined);
  public appliedQuickFilters = undefined;
  public appliedFilter = undefined;
  public createELS: string;
  public cpnPopUpData = new Subject<object>();
  public sortValuesStore = new BehaviorSubject<any>(undefined);
  public sortListArray = new BehaviorSubject<any>(undefined);
  public summaryData = new Subject<any>();
  public summaryHeaders = new BehaviorSubject<any>(undefined);
  public supplierQuoteData = new BehaviorSubject<any>(undefined);
  public supplierQuoteDataHeaders = new BehaviorSubject<any>(undefined);
  private dataFilter = new BehaviorSubject<any>(undefined);
  public partialFilterSet = new BehaviorSubject<any>(undefined);
  public collaborationInput: any;
  public tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public taskActionInput: any = false;
  public rowInfo;
  public rfqRouterSubject = new BehaviorSubject<any>(undefined);
  public rfqScopeSubject = new BehaviorSubject<any>(undefined);
  public rfqRouterData;
  public rfqScopeData;
  public rmOppsExcelPayload = new BehaviorSubject<any>(undefined);
  public onLeftNavToggle = new BehaviorSubject<any>(undefined);
  public spendImpactSummaryHeaders = new BehaviorSubject<any>(undefined);
  public spendImpactDetailHeaders = new BehaviorSubject<any>(undefined);

  public selectedRMTab = new BehaviorSubject<any>(undefined);
  public selectedSubNavTab = new BehaviorSubject<any>(undefined);
  public rmSelectedTableView: any = undefined;

  public rmSearchEvent = new BehaviorSubject<any>(undefined);

  selectedProductRFQInfo;

  dataExist = new BehaviorSubject<any>({
    dataExists: false,
    viewDetailed: undefined,
  });

  public selectedViewByExcel: any;
  public newsId: object;
  public subject = new Subject<any>();
  spendElement = new BehaviorSubject<any>(undefined);
  transformationCost = new BehaviorSubject<any>(undefined);
  private whereUsedData = new BehaviorSubject<any>(undefined);
  public levaFilterTab;
  public levaAutoSearchPayload;
  public quickFilterCount = new BehaviorSubject<any>(undefined);
  public pwbColumnMappings = {};
  public pwbQuartersandSourcingHeaders = new Map<string, any>();
  public levaColumnPDMIntegration = new BehaviorSubject<any>(undefined);
  public sdbFatalColumns;
  public chatBotEnable = new BehaviorSubject<any>(undefined);
  public isArchivedTab = new BehaviorSubject<any>(undefined);

  public insightGridViewColChange = new Subject<any>();
  private selectedRows = [];
  setSelectedRows(selectedRows) {
    this.selectedRows = selectedRows || [];
  }
  getSelectedRows() {
    return this.selectedRows;
  }

  public setInsightGridViewColChange(val) {
    this.insightGridViewColChange.next(val);
  }
  public getInsightGridViewColChange() {
    return this.insightGridViewColChange.asObservable();
  }

  public setChatBotEnable(val) {
    this.chatBotEnable.next(val);
  }
  public getChatBotEnable() {
    return this.chatBotEnable.asObservable();
  }

  public setIsArchivedTab(val) {
    this.isArchivedTab.next(val);
  }
  public getIsArchivedTab() {
    return this.isArchivedTab.asObservable();
  }

  public pricingReportRefreshDate = new BehaviorSubject<any>(undefined);
  public setPricingReportRefreshDate(val) {
    this.pricingReportRefreshDate.next(val);
  }
  public getPricingReportRefreshDate() {
    return this.pricingReportRefreshDate.asObservable();
  }

  public setLevaColumnPDMIntegration(val) {
    this.levaColumnPDMIntegration.next(val);
  }
  public getLevaColumnPDMIntegration() {
    return this.levaColumnPDMIntegration.asObservable();
  }
  public setLevaFilterTab(val) {
    this.levaFilterTab = val;
  }
  public getLevaFilterTab() {
    return this.levaFilterTab;
  }
  public setSelectedSubNavTab(val) {
    this.selectedSubNavTab.next(val);
  }
  public getSelectedSubNavTab() {
    return this.selectedSubNavTab.asObservable();
  }
  setLevaAutoSearchPayload(payload) {
    this.levaAutoSearchPayload = payload;
  }
  getLevaAutoSearchPayload() {
    return this.levaAutoSearchPayload;
  }
  setColumnLevelFilterPayload(payload) {
    this.columnLevelFilterPayload = payload;
  }
  getColumnLevelFilterPayload() {
    return this.columnLevelFilterPayload;
  }
  setRoutingParam(val) {
    this.routingParam = val || null;
  }
  getRoutingParam() {
    return this.routingParam;
  }
  setRiskRFICode(code) {
    this.riskRFICode = code || null;
  }
  getRiskRFICode() {
    return this.riskRFICode;
  }
  setTemplateId(code) {
    this.templateId = code || null;
  }
  getTemplateId() {
    return this.templateId;
  }
  public setRfqAccessed(data) {
    this.rfqAccessed = data;
  }
  public getRfqAccessed() {
    return this.rfqAccessed;
  }
  public setTotalCheckbox(val) {
    this.totalCheckBox.next(val);
  }
  public getTotalCheckBox() {
    return this.totalCheckBox.asObservable();
  }

  public setSelectedScopeIds(val) {
    this.selectedScopeIds.next(val);
  }

  public getSelectedScopeIds() {
    return this.selectedScopeIds.asObservable();
  }
  public setSelectedTabInCreateFlow(val) {
    this.selectedTabInCreateFlow.next(val);
  }
  public setQueryParams(target) {
    this.queryParams = target;
  }
  public getQueryParams() {
    return this.queryParams;
  }

  public setNavigateRoute(target) {
    this.navigateRoute = target;
  }
  public getNavigateRoute() {
    return this.navigateRoute;
  }

  public getSelectedTabInCreateFlow() {
    return this.selectedTabInCreateFlow.asObservable();
  }

  public setCustLevelView(val) {
    this.custLevelView = val;
  }

  public getCustLevelView() {
    return this.custLevelView;
  }

  public setView(val) {
    this.changeView = val;
  }

  public getView() {
    return this.changeView;
  }

  public getCommodityStringModifier(): any {
    return this.stringModifierMap.asObservable();
  }

  public setCommodityStringModifier(value: any) {
    this.stringModifierMap.next(value);
  }

  public setModifiedDropdownNode(field) {
    this.dropDownNode.next(field);
  }

  public getModifiedDropdownNode(): any {
    return this.dropDownNode.asObservable();
  }

  public setCommodityDropDownModifierMap(
    uniqueIdentifier,
    modifedDropDownObject
  ) {
    this.commodityDropDownModifierMap.set(
      uniqueIdentifier,
      modifedDropDownObject
    );
  }

  public getCommodityDropDownModifierMap(uniqueIdentifier) {
    return this.commodityDropDownModifierMap.get(uniqueIdentifier);
  }
  public clearCommodityDropDownModifierMap(): any {
    this.commodityDropDownModifierMap.clear();
  }

  public setSelectedRowStatus(val) {
    this.selectedRowStatus.next(val);
  }

  public getSelectedRowStatus() {
    return this.selectedRowStatus.asObservable();
  }

  public setModifiedPreviewGridData(val) {
    this.modifiedPreviewGridData.next(val);
  }

  public getModifiedPreviewGridData() {
    return this.modifiedPreviewGridData.asObservable();
  }

  public setDefaultSelValue(val) {
    this.defaultSelValue = val;
  }

  public getDefaultSelValue() {
    return this.defaultSelValue;
  }

  public setSelectedDropDownVal(val) {
    this.selectedDropDownVal.next(val);
  }

  public getSelectedDropDownVal() {
    return this.selectedDropDownVal.asObservable();
  }

  public setHeaderErrorMap(value: any) {
    this.headerErrorMap = value;
  }

  public getHeaderErrorMap() {
    return this.headerErrorMap;
  }
  public setSavedFilters(val) {
    this.savedFilters.next(val);
  }
  public setModifiedNode(field) {
    this.rowNode.next(field);
  }

  public getModifiedNode(): any {
    return this.rowNode.asObservable();
  }
  public setDropDownModifierMap(
    targetGrid,
    dbColumnName,
    modifedDropDownObject
  ) {
    this.dropDownModifierMap.get(targetGrid)[dbColumnName] =
      modifedDropDownObject;
  }

  public getDropDownModifierMap(targetGrid, dbColumnName) {
    return this.dropDownModifierMap.get(targetGrid)[dbColumnName];
  }

  public clearDropDownModifierMap(targetGrid) {
    this.dropDownModifierMap.set(targetGrid, {});
  }
  public getSavedFilter() {
    return this.savedFilters.asObservable();
  }
  public setValidateUploadError(cpnValue, displayName) {
    this.validateUploadErrorMap[cpnValue] = displayName;
  }

  public getValiDateUploadError(cpnValue) {
    return this.validateUploadErrorMap[cpnValue];
  }

  public setPertinentData(obj) {
    this.pertinentData.next(obj);
  }
  public getPertinentData() {
    return this.pertinentData.asObservable();
  }

  setSMBreadcrum(val) {
    if (this.smBreadcrum instanceof Array) {
      if (this.smBreadcrum.length > 0) {
        if (this.smBreadcrum.length === 2) {
          for (let i = 0; i <= this.smBreadcrum.length - 1; i++) {
            if (
              this.smBreadcrum[i].value.split('/')[
                this.smBreadcrum[i].value.split('/').length - 1
              ] !== val.title.replace(/\s/g, '').toLowerCase()
            ) {
              this.smBreadcrum.splice(i, 1);
            }
          }
        } else {
          for (let i = 0; i <= this.smBreadcrum.length - 1; i++) {
            if (
              this.smBreadcrum[i].value.split('/')[
                this.smBreadcrum[i].value.split('/').length - 1
              ] !== val.title.replace(/\s/g, '').toLowerCase()
            ) {
              this.smBreadcrum.push(val);
            }
          }
        }
      } else {
        this.smBreadcrum.push(val);
      }
      this.setBreadCrumValue(this.smBreadcrum);
    }
  }

  setSelectedProductRFQInformation(val) {
    this.selectedProductRFQInfo = val;
  }

  getSelectedProductRFQInformation() {
    return this.selectedProductRFQInfo;
  }

  public setChangedDropdownValues(cmspkId, dropDownObject) {
    this.changedDropDownValues[cmspkId] = dropDownObject;
  }

  public getChangedDropDownValues(cmspkId) {
    return this.changedDropDownValues[cmspkId];
  }

  public setValidationMessages(values) {
    this.validationMessages = values;
  }

  public getValidationMessages() {
    return this.validationMessages;
  }

  public setClickedSupplierDetails(val) {
    this.clickedSupplierDetails.next(val);
  }
  public getclickedSupplierDetails() {
    return this.clickedSupplierDetails.asObservable();
  }

  public setClickedSubjectRfqValues(val) {
    this.clickedSubjectRfqValues.next(val);
  }
  public getClickedSubjectRfqValues() {
    return this.clickedSubjectRfqValues.asObservable();
  }
  setAdhocRowObject(val) {
    this.adhocRowObject = val || null;
  }
  getAdhocRowObject() {
    return this.adhocRowObject;
  }
  setMultilevelData(val) {
    this.multileveldata.next(val);
  }
  getMultilevelData() {
    return this.multileveldata.asObservable();
  }
  setUploadThresholdData(field: any) {
    this.uploadThresholdData.next(field);
  }
  getUploadThresholdData() {
    return this.uploadThresholdData.asObservable();
  }

  public setBasicAlertsErrors(value: any) {
    if (value === null) {
      return (this.basicAlertsErrors = []);
    }
    this.basicAlertsErrors.push(value);
  }

  public getBasicAlertsErrors() {
    return this.basicAlertsErrors;
  }

  setErrorMessage(field: any) {
    this.errorMessage.next(field);
  }
  getErrorMessage() {
    return this.errorMessage.asObservable();
  }

  public setErrorRecords(value: any) {
    this.errorRecords = value;
  }

  public getErrorRecords() {
    return this.errorRecords;
  }

  public setPayloadForSummary(value: any) {
    this.payloadForSummary = value;
  }

  public getPayloadForSummary() {
    return this.payloadForSummary;
  }

  public setPayload(value: any) {
    this.payloadData.next(value);
  }

  public getPayload() {
    return this.payloadData.asObservable();
  }
  setUploadAlertsData(field: any) {
    this.uploadAlertsData.next(field);
  }
  getUploadAlertsData() {
    return this.uploadAlertsData.asObservable();
  }

  public setEditedCells(cell: string) {
    if (cell === null) {
      this.editedCells = [];
    } else {
      this.editedCells.push(cell);
    }
  }

  public getEditedCells() {
    return JSON.stringify(this.editedCells);
  }
  public setEditedRows(editedRow) {
    if (editedRow === null) {
      return (this.editedRows = []);
    }
    this.editedRows.push(editedRow);
  }
  setnewValue(field) {
    this.newRow.next(field);
  }
  getnewValue() {
    return this.newRow.asObservable();
  }
  public getEditedRows() {
    return this.editedRows;
  }

  public setSelectedGridData(obj) {
    this.selectedGridData.next(obj);
  }

  public getSelectedGridData() {
    return this.selectedGridData;
  }

  public setFailedTsDataStatus(val) {
    this.failedTsDataStatus.next(val);
  }
  public getFailedTsDataStatus() {
    return this.failedTsDataStatus;
  }

  /*RFQ */
  public setDimensionlevelChange(val) {
    this.dimensionLeveltrgt.next(val);
  }
  public getDimensionlevelChange() {
    return this.dimensionLeveltrgt.asObservable();
  }

  public setQualitativeInsightsData(val) {
    this.qualitativeInsightsData.next(val);
  }
  public getQualitativeInsightsData() {
    return this.qualitativeInsightsData.asObservable();
  }

  public setPreviewTab(val) {
    this.previewTab.next(val);
  }
  public getPreviewTab() {
    return this.previewTab.asObservable();
  }
  public setRfqLevelChange(val) {
    this.rfqLeveltrgt.next(val);
  }
  public getRfqLevelChange() {
    return this.rfqLeveltrgt.asObservable();
  }
  public setrqpreviewload(val) {
    this.rqPreviewload.next(val);
  }
  public getrqpreviewload() {
    return this.rqPreviewload.asObservable();
  }
  public getSelDropdownValue() {
    return this.dropdownVal.asObservable();
  }

  public setSelDropdownValue(value: any) {
    this.dropdownVal.next(value);
  }

  public getOpportunityViewDropDown() {
    return this.selectedOppView.asObservable();
  }

  public setOpportunityViewDropDown(value) {
    this.selectedOppView.next(value);
  }

  public setRouteName(value) {
    this.routeName = value;
  }

  public getRouteName() {
    return this.routeName;
  }

  public getEditRfqResult() {
    // return this.editRfqResult.asObservable();
    return this.editRfqResult;
  }

  public setEditRfqResult(value: any) {
    // this.editRfqResult.next(value);
    this.editRfqResult = value;
  }

  /*RFQ*/
  public getPrimaryKeyArray() {
    return this.primarykeyArray;
  }

  public setPrimarykeyArray(primaryArray) {
    this.primarykeyArray = primaryArray;
  }

  public setDisplayName(value: any) {
    this.display = value;
  }
  setStatusOfQSDashboard(field: any) {
    this.status.next(field);
  }
  getStatusOfQSDashboard() {
    return this.status.asObservable();
  }

  public getDisplayName() {
    return this.display;
  }

  public setStatusDBColumn(value: any) {
    this.statusdbColumnName.next(value);
  }

  public getStatusDBColumn() {
    return this.statusdbColumnName.asObservable();
  }

  public getChangedStatusRow() {
    return this.changedStatusRow.asObservable();
  }

  public setChangedStatusRow(value: any) {
    this.changedStatusRow.next(value);
  }

  public getStatusModifier() {
    return this.statusModifier.asObservable();
  }

  public setStatusModifier(value: any) {
    if (
      this.statusModifier.value instanceof Object &&
      value instanceof Object
    ) {
      for (const key in this.statusModifier.value) {
        if (value[key] === undefined) {
          value[key] = this.statusModifier.value[key];
        }
      }
    }
    this.statusModifier.next(value);
  }

  public getDropdownStatusModifier() {
    return this.dropdownStatusModifier.asObservable();
  }

  public setDropdownStatusModifier(value: any) {
    this.dropdownStatusModifier.next(value);
  }

  public getStatusValue() {
    return this.statusValue.asObservable();
  }

  public setStatusValue(value: any) {
    this.statusValue.next(value);
  }

  public getSortListing() {
    return this.currentSelectedRow.asObservable();
  }

  public setSortListing(value: any) {
    this.currentSelectedRow.next(value);
  }

  public getCurrentSelectedRow() {
    return this.currentSelectedRow.asObservable();
  }

  public setCurrentSelectedRow(value: any) {
    this.currentSelectedRow.next(value);
  }

  public getSelectedRowsLength() {
    return this.selectedRowsLength.asObservable();
  }

  public setSelectedRowsLength(value: number) {
    this.selectedRowsLength.next(value);
  }

  public getLinkValue() {
    return this.linkValue.asObservable();
  }

  public setLinkValue(value: any) {
    this.linkValue.next(value);
  }

  public getCheckedArray() {
    return this.checkedArray;
  }

  public setCheckedArray(checkedArray) {
    this.checkedArray = checkedArray;
  }

  public setCollapseFlag(collapseFlag) {
    this.collapseFlag = collapseFlag;
  }

  public getCollapseFlag() {
    return this.collapseFlag;
  }

  public setResultData(value: any) {
    this.resultData.next(value);
  }

  public getResultData() {
    return this.resultData.asObservable();
  }

  public setEditedCellsData(value: any) {
    this.editedCellsData.next(value);
  }

  public getEditedCellsData() {
    return this.editedCellsData.asObservable();
  }

  public setSelectedViewByExcel(obj) {
    this.selectedViewByExcel = obj;
  }
  public getSelectedViewByExcel() {
    return this.selectedViewByExcel;
  }
  public setDataExist(val: any) {
    this.dataExist.next(val);
  }
  public getDataExist() {
    return this.dataExist.asObservable();
  }

  setRmObject(val) {
    this.rmObject.next(val);
  }
  getRmObject() {
    return this.rmObject.asObservable();
  }
  setSpendImpactSummaryData(obj) {
    this.spendImpactSummaryData.next(obj);
  }
  getSpendImpactSummaryData() {
    return this.spendImpactSummaryData.asObservable();
  }

  setSpendImpactDetailData(obj) {
    this.spendImpactDetailData.next(obj);
  }
  getSpendImpactDetailData() {
    return this.spendImpactDetailData.asObservable();
  }

  public setRiskOppDetailsSpendImpact(obj) {
    this.riskOppDetailsSpendImpact.next(obj);
  }
  public getRiskOppDetailsSpendImpact() {
    return this.riskOppDetailsSpendImpact.asObservable();
  }

  public setRiskOppDetailsMPN(obj) {
    this.riskOppDetailsMPN.next(obj);
  }
  public getRiskOppDetailsMPN() {
    return this.riskOppDetailsMPN.asObservable();
  }

  public setRiskOppRawMaterialOpps(obj) {
    this.riskOppRawMaterialOpps.next(obj);
  }
  public getRiskOppRawMaterialOpps() {
    return this.riskOppRawMaterialOpps.asObservable();
  }

  /*RFQ*/
  public setCollapseData(value: any) {
    this.collapseData.next(value);
  }

  public getCollapseData() {
    return this.collapseData.asObservable();
  }

  public setExpandData(value: any) {
    this.expandData.next(value);
  }

  public getExpandData() {
    return this.expandData.asObservable();
  }

  public setSortingData(value: any) {
    this.sortingData.next(value);
  }

  public getSortingData() {
    return this.sortingData.asObservable();
  }

  /* public setEditedRows(editedRow) {
        this.editedRows.push(editedRow)
    }

    public getEditedRows() {
        return this.editedRows;
    }

    public setSelectedGridData(obj) {
        this.selectedGridData.next(obj);
    }

    public getSelectedGridData() {
        return this.selectedGridData;
    }

    public setEditedCells(cell: string) {
        this.editedCells.push(cell)
    }

    public getEditedCells() {
        return JSON.stringify(this.editedCells)
    } */

  /*RFQ*/

  public setCpnPopUpData(value: any) {
    this.cpnPopUpData.next(value);
  }
  public setEditTask(value: any) {
    this.openEditTask.next(value);
  }
  public getEditTask() {
    return this.openEditTask.asObservable();
  }
  public setAddTask(value: any) {
    if (!value && !this.showTaskPane) {
      this.taskActionInput = null;
    }
    this.addTaskClicked.next(value);
  }
  public getAddTask() {
    return this.addTaskClicked.asObservable();
  }
  public getCpnPopUpData() {
    return this.cpnPopUpData.asObservable();
  }
  public setDetailViewClicked(value: any) {
    this.detailViewClicked.next(value);
  }
  public getDetailViewClicked() {
    return this.detailViewClicked.asObservable();
  }
  public setUploadResponse(value: any) {
    this.uploadResponse.next(value);
  }
  public getUploadResponse() {
    return this.uploadResponse.asObservable();
  }
  public setAlertsMaxMinFlag(value: any) {
    this.alertsMaxMinFlag.next(value);
  }
  public getAlertsMaxMinFlag() {
    return this.alertsMaxMinFlag.asObservable();
  }

  public setAlertData(obj) {
    this.alertData = obj;
  }

  public getAlertData() {
    return this.alertData;
  }

  public setSelectedRFQId(obj) {
    this.selectedRFQId = obj;
  }

  public getSelectedRFQId() {
    return this.selectedRFQId;
  }

  public setSelectionChanged(value: any) {
    this.selectionChanged.next(value);
  }
  public setRefreshTask(value: any) {
    this.refreshTask.next(value);
  }
  public getRefreshTask() {
    return this.refreshTask.asObservable();
  }
  public getSelectionChanged() {
    return this.selectionChanged.asObservable();
  }
  setInsightClosed(value: any) {
    this.insightClosed.next(value);
  }
  public getInsightClosed() {
    return this.insightClosed.asObservable();
  }
  public setShowAwardedRules(value: any) {
    this.showAwardedRules.next(value);
  }
  setDeleteRfqFromNPI(obj) {
    this.deleteRfqFromNPI.next(obj);
  }
  getDeleteRfqFromNPI() {
    return this.deleteRfqFromNPI.asObservable();
  }
  public getShowAwardedRules() {
    return this.showAwardedRules.asObservable();
  }
  setJsonData(obj) {
    this.jsonData.next(obj);
  }
  getJsonData() {
    return this.jsonData.asObservable();
  }
  setSupplierName(name: string) {
    this.supplierName.next(name);
  }

  getSuppliername() {
    return this.supplierName.asObservable();
  }

  setNegLevDesSubject(field: any) {
    this.negLevDes.next(field);
  }
  getNegLevDesSubject() {
    return this.negLevDes.asObservable();
  }

  setBOTResponse(obj) {
    this.botInput.next(obj);
  }

  getBOTResponse() {
    return this.botInput.asObservable();
  }

  /* public setBOTResponse(obj) {
        this.responseObject = obj;
        this.setBOTResponseSubject(obj)
    }

    public setBOTResponseSubject(obj) {
        this.subject.next(obj);
    }

    public getBOTResponseSubject() {
        return this.subject.asObservable();
    }

    public getBOTResponse() {
        return this.responseObject;
    } */

  public setSingePartCostLookupResult(value) {
    this.singePartCostLookupResult = value;
  }
  public getSingePartCostLookupResult() {
    return this.singePartCostLookupResult;
  }
  public setClikedRFQforDetails(value) {
    this.clikedRfqForDetails = value;
  }
  public getClikedRFQforDetails() {
    return this.clikedRfqForDetails;
  }

  public setISAdditionalInfo(value) {
    this.additionalInfo = value;
  }
  public getISAdditionalInfo() {
    return this.additionalInfo;
  }

  public setRecipientModifiedList(value) {
    this.recipientModifiedList = value;
  }
  public getRecipientModifiedList() {
    return this.recipientModifiedList;
  }
  public setRowInfo(val) {
    this.rowInfo = val;
  }

  public getRowInfo() {
    return this.rowInfo;
  }

  public setSelectedNewsId(obj) {
    this.newsId = obj;
  }

  public getSelectedNewsId() {
    return this.newsId;
  }

  setBredcumNameSubject(field: any) {
    this.subject.next(field);
  }

  getSelectedTabName() {
    return this.subject.asObservable();
  }

  setSelectedTabName(field: any) {
    this.subject.next(field);
  }

  getBredcumNameSubject() {
    return this.subject.asObservable();
  }

  public setPreviewRounds(obj) {
    this.previewRounds = obj;
  }

  public getPreviewRounds() {
    return this.previewRounds;
  }

  public getSeletedRFQName() {
    return this.selectedRFQName;
  }

  public setSeletedRFQName(value) {
    this.selectedRFQName = value;
  }

  public setDashBoard(dashboardstring) {
    this.dashboardChanger = dashboardstring;
  }
  public getDashBoard() {
    return this.dashboardChanger;
  }
  setExcelPayload(val) {
    this.excelPayload = val;
  }
  getExcelPayload() {
    return this.excelPayload;
  }

  public setcreateDashboard(createDashboardstring) {
    this.createDashboard = createDashboardstring;
  }
  public getcreateDashboard() {
    return this.createDashboard;
  }
  setSelectedUserGroup(val) {
    this.selectionChanged = val;
  }
  getSelectedUserGroup() {
    return this.selectionChanged;
  }
  setSupplierManagementData(val) {
    this.selectionChanged = val;
  }
  getSupplierManagementData() {
    return this.selectionChanged;
  }
  setsupUserGroup(group) {
    this.supUserGroup = group;
  }
  getsupUserGroup() {
    return this.supUserGroup;
  }
  setEditedData(dataset) {
    this.editedData = dataset;
  }
  getEditedData() {
    return this.editedData;
  }
  setRowsForResend(val) {
    this.rowsForResend = val;
  }
  getRowsForResend() {
    return this.rowsForResend;
  }
  setTCLookupFileupload(field: any) {
    this.tcsubject.next(field);
  }
  getTCLookupFileupload() {
    return this.tcsubject.asObservable();
  }
  setEditedFieldsByRow(field: any) {
    this.editedFieldsByRow.next(field);
  }
  getEditedFieldsByRow() {
    return this.editedFieldsByRow.asObservable();
  }
  setRouteNameForRO(field: any) {
    this.routeChanged.next(field);
  }
  getRouteNameForRO() {
    return this.routeChanged.asObservable();
  }

  setActionChanged(field: any) {
    this.actionChanged.next(field);
  }
  getActionChanged() {
    return this.actionChanged.asObservable();
  }

  setDeselectedIds(field: any) {
    this.deselectedIds.next(field);
  }
  setRequestRating(val) {
    this.requestRating.next(val);
  }
  getRequestRating() {
    return this.requestRating.asObservable();
  }
  getDeselectedIds() {
    return this.deselectedIds.asObservable();
  }
  setEditedFields(value) {
    this.editedFields = value;
  }
  getEditedFields() {
    return this.editedFields;
  }

  setCostLookupFileupload(field: any) {
    this.subject.next(field);
  }
  getViewTypeClicked() {
    return this.viewTypeClicked.asObservable();
  }
  setViewTypeClicked(field: any) {
    this.viewTypeClicked.next(field);
  }
  getCostLookupFileupload() {
    return this.subject.asObservable();
  }
  setRFXCOmpletePendingData(val) {
    this.rfxCOmpletePendingData = val;
  }
  getRFXCOmpletePendingData() {
    return this.rfxCOmpletePendingData;
  }

  setExpandDashboard(field: any) {
    this.expandDashboard.next(field);
  }
  getExpandDashboard() {
    return this.expandDashboard.asObservable();
  }

  setSelectedComponent(field: any) {
    this.selectedComponent.next(field);
  }

  getSelectedComponent() {
    return this.selectedComponent.asObservable();
  }
  setClickedState(field: any) {
    this.clickedState.next(field);
  }

  getClickedState() {
    return this.clickedState.asObservable();
  }

  setSelectedFocus(field: any) {
    this.seletedFocus.next(field);
  }

  getSelectedFocus() {
    return this.seletedFocus.asObservable();
  }
  setRMList(obj) {
    this.rmList.next(obj);
  }

  getRMList() {
    return this.rmList.asObservable();
  }
  setRMSummaryTable(field: any) {
    this.rmSummaryTable.next(field);
  }
  getRMSummaryTable() {
    return this.rmSummaryTable.asObservable();
  }

  setRMCPNClicked(val) {
    this.rmSummaryTable = val;
  }
  getRMCPNClicked() {
    return this.rmSummaryTable;
  }
  setBotServiceName(field: any) {
    this.botServiceName.next(field);
  }
  getBotServiceName() {
    return this.botServiceName.asObservable();
  }
  setReportName(field: any) {
    this.reportName.next(field);
  }
  getReportName() {
    return this.reportName.asObservable();
  }

  setReportData(field: any) {
    this.reportData.next(field);
  }
  getReportData() {
    return this.reportData.asObservable();
  }
  setTransformationsBomuploadres(field: any) {
    this.bomtransuploadres.next(field);
  }
  getTransformationsBomuploadres() {
    return this.bomtransuploadres.asObservable();
  }
  setfileuploadres(field: any) {
    this.fileuploadres.next(field);
  }
  getFileUploadRes() {
    return this.fileuploadres.asObservable();
  }
  setautoSearchHit(field: any) {
    this.autoSearchHit.next(field);
  }
  getautoSearchHit() {
    return this.autoSearchHit.asObservable();
  }
  setfileName(field: any) {
    this.fileName.next(field);
  }
  getfileName() {
    return this.fileName.asObservable();
  }
  public setrmGraphdata(val: any) {
    this.rmGraph.next(val);
  }
  public getrmGraphdata() {
    return this.rmGraph.asObservable();
  }

  public setRMPopUpData(val: any) {
    this.rmPopUpData.next(val);
  }

  public getRMPopUpData() {
    return this.rmPopUpData.asObservable();
  }
  public setfuturesData(val: any) {
    this.futuresData.next(val);
  }
  public getfuturesData() {
    return this.futuresData.asObservable();
  }
  public settimePerioddata(val: any) {
    this.timePeriod.next(val);
  }
  public gettimePerioddata() {
    return this.timePeriod.asObservable();
  }
  public setrmDimensiondata(val: any) {
    this.dimensionData.next(val);
  }
  public getrmDimensiondata() {
    return this.dimensionData.asObservable();
  }

  public setSpendElementCheck(check: boolean) {
    // check = true || false
    this.spendElement.next(check);
  }

  public getSpendElementCheck() {
    return this.spendElement.asObservable();
  }

  public setBenchmarkTransformationCost(check: boolean) {
    this.transformationCost.next(check);
  }

  public getBenchmarkTransformationCost() {
    return this.transformationCost.asObservable();
  }

  /**
   * RFQ template Creation
   */
  public setActionCallBack(obj) {
    this.actionCallBack.next(obj);
  }
  public getActionCallBack() {
    return this.actionCallBack.asObservable();
  }
  public setSharedTargetChecked(val) {
    this.isChecked.next(val);
  }
  public getSharedTargetChecked() {
    return this.isChecked.asObservable();
  }
  public setMpnSharedChecked(val) {
    this.mpnChecked.next(val);
  }
  public getMpnSharedChecked() {
    return this.mpnChecked.asObservable();
  }
  public setNavigatedFromPreview(val) {
    this.navigatedFromPreview.next(val);
  }
  public getNavigatedFromPreview() {
    return this.navigatedFromPreview.asObservable();
  }
  setSupplierManagementClickedData(val) {
    this.supplierManagementClickedData = val;
  }
  getSupplierManagementClickedData() {
    return this.supplierManagementClickedData;
  }
  setDeviationMap(val) {
    this.deviationMap = val;
  }
  getDeviationMap() {
    return this.deviationMap;
  }
  setBreadCrumValue(val) {
    this.breadCrumValue.next(val);
  }
  getBreadCrumValue() {
    return this.breadCrumValue.asObservable();
  }
  public setLinkFlagValue(val) {
    this.linkFlag.next(val);
  }
  public getLinkFlagValue() {
    return this.linkFlag.asObservable();
  }
  public setManageVendorForLink(val) {
    this.fromManageVendorFlag.next(val);
  }
  public getManageVendorForLink() {
    return this.fromManageVendorFlag.asObservable();
  }
  setFilterStatus(filterObj) {
    this.filterStatus.next(filterObj);
  }

  getFilterStatus() {
    return this.filterStatus.asObservable();
  }

  public setPartialMatchFilter(val) {
    this.clickFunction.next(val);
  }

  public getPartialMatchFilter() {
    return this.clickFunction.asObservable();
  }

  public setAppliedQuickFilters(val) {
    this.appliedQuickFilters = val;
  }

  public getAppliedQuickFilters() {
    return this.appliedQuickFilters;
  }

  public setAppliedFilter(val) {
    this.appliedFilter = val;
  }

  public getAppliedFilter() {
    return this.appliedFilter;
  }

  public setcreateELS(obj) {
    this.createELS = obj;
  }

  public getcreateELS() {
    return this.createELS;
  }

  public setSelectMasterCost(val) {
    this.selectMasterCost.next(val);
  }
  public getSelectMasterCost() {
    return this.selectMasterCost.asObservable();
  }
  public setSelectPPV(val) {
    this.selectPPV.next(val);
  }
  public getSelectPPV() {
    return this.selectPPV.asObservable();
  }

  public setFiltersWhenApply(val) {
    this.applyClickButton.next(val);
  }
  public getFiltersWhenApply() {
    return this.applyClickButton.asObservable();
  }

  public setNumericalMatchFilter(val) {
    this.numericalData.next(val);
  }

  public getNumericalMatchFilter() {
    return this.numericalData.asObservable();
  }

  public setCustomNumericalMatchFilter(val) {
    this.numericalCustomData.next(val);
  }

  public getCustomNumericalMatchFilter() {
    return this.numericalCustomData.asObservable();
  }

  public setViewUserGroupFeatures(val) {
    this.viewUserGroupFeatures.next(val);
  }

  public getViewUserGroupFeatures() {
    return this.viewUserGroupFeatures.asObservable();
  }
  public setCheckedUserGroupsInGrid(groupsSelected: object) {
    this.checkedUserGroupsInGrid.next(groupsSelected);
  }
  public getCheckedUserGroupsInGrid() {
    return this.checkedUserGroupsInGrid.asObservable();
  }
  public setDefaultSelectedGroups(groups) {
    Object.assign(this.defaultSelectedGroups, groups);
  }
  public getDefaultSelectedGroups() {
    return this.defaultSelectedGroups;
  }
  public setTenantAdmin(val) {
    this.usersTenantAdmin.next(val);
  }
  public getTenantAdmin() {
    return this.usersTenantAdmin.asObservable();
  }
  public setApproverEmails(val) {
    this.approverEmails = val;
  }
  public getApproverEmails() {
    return this.approverEmails;
  }
  public setIsApprover(val) {
    this._isApprover.next(val);
  }
  public getIsApprover() {
    return this._isApprover.asObservable();
  }
  public setSavedApproverEmails(val) {
    Object.assign(this.savedApproverEmails, val);
  }
  public getSavedApproverEmails() {
    return this.savedApproverEmails;
  }
  public setSelectedApproverEmails(val) {
    this.selectedApproverEmails.next(val);
  }
  public getSelectedApproverEmails() {
    return this.selectedApproverEmails.asObservable();
  }
  public setIsUsersGridChangeSaved(val) {
    this.isUsersGridChangeSaved = val;
  }
  public getIsUsersGridChangeSaved() {
    return this.isUsersGridChangeSaved;
  }
  public setClearedDefaultFilters(val) {
    this.clearedDefaultFilters = val;
  }
  public getClearedDefaultFilters() {
    return this.clearedDefaultFilters;
  }

  setProductCreateRFQModelData(val) {
    this.productCreateRFQModelData = val;
  }

  getProductCreateRFQModelData() {
    return this.productCreateRFQModelData;
  }

  setSelectedViewInList(value) {
    this.selectedViewProduct.next(value);
  }

  getSelectedViewInList() {
    return this.selectedViewProduct.asObservable();
  }

  setSortingList(value) {
    this.sortValuesStore.next(value);
  }

  getSortingList() {
    return this.sortValuesStore.asObservable();
  }

  setSortListDisplayName(value) {
    this.displayName.next(value);
  }

  getSortListDisplayName() {
    return this.displayName.asObservable();
  }
  setDashboardexpandedFlag(value) {
    this.expandedViewFlag.next(value);
  }
  getDashboardexpandedFlag() {
    return this.expandedViewFlag.asObservable();
  }

  setSortListArray(value) {
    this.sortListArray.next(value);
  }
  getSortListArray() {
    return this.sortListArray.asObservable();
  }

  setRFQSummarydata(field) {
    this.summaryData.next(field);
  }
  getRFQSummarydata() {
    return this.summaryData.asObservable();
  }

  setRFXSummaryHeaders(field) {
    this.summaryHeaders.next(field);
  }

  getRFXSummaryHeaders() {
    return this.summaryHeaders.asObservable();
  }

  setSelectedDimensionDetails(value) {
    this.npDetails.next(value);
  }
  getSelectedDimensionDetails() {
    return this.npDetails.asObservable();
  }
  setSupplierQuotedata(field) {
    this.supplierQuoteData.next(field);
  }

  getSupplierQuotedata() {
    return this.supplierQuoteData.asObservable();
  }

  setSupplierQuoteHeaders(field) {
    this.supplierQuoteDataHeaders.next(field);
  }

  getSupplierQuoteHeaders() {
    return this.supplierQuoteDataHeaders.asObservable();
  }
  public setProductPartialMatchFilter(val) {
    this.partialFilterSet.next(val);
  }

  public getProductPartialMatchFilter() {
    return this.partialFilterSet.asObservable();
  }

  getProductEditRfqObject() {
    return this.productEditRfq;
  }

  setProductEditRfqObject(val) {
    this.productEditRfq = val;
  }

  public setProductDataFilter(val) {
    /*Used to set the elastic search value in the product dashboard */
    this.dataFilter.next(val);
  }

  public getProductDataFilter() {
    return this.dataFilter.asObservable();
  }

  public setClearSearch(val) {
    /*Used to know whether the clear search button should be disabled in product dashboard or not */
    this.clearSearch.next(val);
  }
  public getClearSerach() {
    return this.clearSearch.asObservable();
  }
  public setIdsList(val) {
    /*Used to set ids to be sent for dashboard api for filtering data for search */
    this.storeIdsList.next(val);
  }
  public getIdsList() {
    return this.storeIdsList.asObservable();
  }
  public setRmOppsExcelPayload(val) {
    this.rmOppsExcelPayload.next(val);
  }
  public getRmOppsExcelPayload() {
    return this.rmOppsExcelPayload.asObservable();
  }
  public setToggle(val) {
    this.onLeftNavToggle.next(val);
  }
  public getToggle() {
    return this.onLeftNavToggle.asObservable();
  }
  // public setCommodityMangersList(val) {
  //     this.commodityMangersList.next(val);
  // }

  // public getCommodityMangersList() {
  //     return this.commodityMangersList.asObservable();
  // }
  // public setDefaultSelectedCmList(val) {
  //     Object.assign(this.defaultCmList,val);
  // }

  // public getDefaultSelectedCmList() {
  //     return this.defaultCmList;
  // }
  public setRfqRouter(val) {
    this.rfqRouterSubject.next(val);
  }

  public getRfqRouter() {
    return this.rfqRouterSubject.asObservable();
  }
  public setRfqScope(val) {
    this.rfqScopeSubject.next(val);
  }

  public getRfqScope() {
    return this.rfqScopeSubject.asObservable();
  }
  public setSelectedRMTab(val) {
    this.selectedRMTab.next(val);
  }

  public getSelectedRMTab() {
    return this.selectedRMTab.asObservable();
  }

  public setRMSelectedTableView(val: any) {
    this.rmSelectedTableView = val;
  }

  public getRMSelectedTableView() {
    return this.rmSelectedTableView;
  }

  public setRMSearchEvent(val) {
    this.rmSearchEvent.next(val);
  }

  public getRMSearchEvent() {
    return this.rmSearchEvent.asObservable();
  }

  public setCommodityMangersList(val) {
    this.commodityMangersList.next(val);
  }

  public getCommodityMangersList() {
    return this.commodityMangersList.asObservable();
  }
  public setDefaultSelectedCmList(val) {
    Object.assign(this.defaultCmList, val);
  }

  public getDefaultSelectedCmList() {
    return this.defaultCmList;
  }

  /**
   * Changed Date Picker  Subscription
   */
  public setModifiedDatePickerNode(field) {
    this.datePickerNode.next(field);
  }

  public getModifiedDatePickerNode() {
    return this.datePickerNode.asObservable();
  }

  public setPartSearchID(val) {
    this.partSearchID.next(val);
  }
  public getPartSearchID() {
    return this.partSearchID.asObservable();
  }
  public setCommoditySyncID(val) {
    this.commoditySyncID.next(val);
  }

  public getCommoditySyncID() {
    return this.commoditySyncID.asObservable();
  }
  public setWhereUsedData(data) {
    this.whereUsedData.next(data);
  }
  public getWhereUsedData() {
    return this.whereUsedData.asObservable();
  }

  public setNpiUniquePartsReq(val) {
    this.npiUniquePartsReq.next(val);
  }

  public getNpiUniquePartsReq() {
    return this.npiUniquePartsReq.asObservable();
  }

  setPwbColumnMappings(key, value) {
    if (!(this.pwbColumnMappings instanceof Object)) {
      this.pwbColumnMappings = {};
    }
    this.pwbColumnMappings[key] = value;
  }

  getPwbColumnMappings() {
    return this.pwbColumnMappings;
  }
  setPwbQuartersandSourcingHeaders(value) {
    this.pwbQuartersandSourcingHeaders = value;
  }

  getPwbQuartersandSourcingHeaders() {
    return this.pwbQuartersandSourcingHeaders;
  }

  public setSDBTemplateFatalColumns(val) {
    this.sdbFatalColumns = val;
  }
  public getSDBTemplateFatalColumns() {
    return this.sdbFatalColumns;
  }
}
