import { PipeTransform, Pipe } from '@angular/core';
@Pipe({ name: 'AutoSearchPipe', pure: false })
export class AutoSearchPipe implements PipeTransform {

    transform(value: any[],limit=null): any[] {
      let finalResult : any[] = new Array;
      let k = 0;
        if(value.length>0) {
          for(let i = 0;i<limit;i++){
            finalResult.push(value[i])
          }
          for(let j=limit+1;j<=value.length;j++){
            k = k+1;
          }
          if(k>0){
            finalResult.push('+'+k)
          }
        }else{
          return ['Select'];
        }
        finalResult = finalResult.filter(function( element ) {
          return element !== undefined;
       });
        return finalResult;
    }
}
