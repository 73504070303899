import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'preload-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.sass']
})

export class PreloadListComponent implements OnInit {

  constructor() { }

  ngOnInit() { }
}
