import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { RfqConfigService } from '../../shared/rfq-config.service';

@Component({
  selector: 'metric-item',
  templateUrl: './metric-item.component.html',
  styleUrls: ['./metric-item.component.sass'],
  providers: []
})
export class MetricItemComponent implements OnInit {
  // public categories: any;
  // public locations: any;
  public metricKeysObject: any;
  public keys;
  @Input() metricItem;
  any;
  public type: any;
  public category: any;

  @Input()
  set metricData(val) {
    if (!val) {
      return;
    }
    this.keys = (val.metricKeys instanceof Object) ? Object.keys(val.metricKeys) : [];
    this.metricKeysObject = val.metricKeys;
    // this.categories = val.refSet[this.keys[2]];
    // this.locations = val.refSet[this.keys[1]];
  }
  @Output() itemChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(private rfqConfigService: RfqConfigService) {}

  ngOnInit() {
    this.type = this.getDisplayValue(this.keys[0])
    this.category = this.getDisplayValue(this.keys[1])
  }

  getDisplayValue(key) {
    if(key
      && this.metricKeysObject[key]
      && this.metricKeysObject[key].actualValue
      && this.metricItem[this.metricKeysObject[key].actualValue]
      && this.metricItem[this.metricKeysObject[key].actualValue]['refTermDisplayValue']
    ) {
      return this.metricItem[this.metricKeysObject[key].actualValue]['refTermDisplayValue']
    } else {
      return '';
    }
  }

  changedEvent(type, event) {
    const changes = {
      item: this.metricItem,
      type,
      value: ''
    };
    if (event instanceof Object) {
      changes.value = event.refTermActualValue;
      this.itemChanged.emit(changes);
    }
  }

  formulaChanged(event) {
    const changes = {
      item: this.metricItem,
      type: 'formula',
      value: event
    };
    this.itemChanged.emit(changes);
  }
}
