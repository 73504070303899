export class TaskAttachModel {

    action: string;
    actionBy: string;
    actionResponseUrl: string;
    actionTime: any;
    taskDueDate: any;
    taskFooter: string;
    taskHeader: string;
    taskPrefix: string;

    public setAction(action: string) {
        this.action = action;
    }
    public getAction() {
        return this.action;
    }
    public setActionBy(actionBy: string) {
        this.actionBy = actionBy;
    }
    public getActionBy() {
        return this.actionBy;
    }
    public setActionResponseUrl(actionResponseUrl: string) {
        this.actionResponseUrl = actionResponseUrl;
    }
    public getActionResponseUrl() {
        return this.actionResponseUrl;
    }
    public setActionTime(actionTime: any) {
        this.actionTime = actionTime;
    }
    public getActionTime() {
        return this.actionTime;
    }
    public setTaskDueDate(taskDueDate: any) {
        this.taskDueDate = taskDueDate;
    }
    public getTaskDueDate() {
        return this.taskDueDate;
    }
    public setTaskFooter(taskFooter: any) {
        this.taskFooter = taskFooter;
    }
    public getTaskFooter() {
        return this.taskFooter;
    }
    public setTaskHeader(taskHeader: any) {
        this.taskHeader = taskHeader;
    }
    public getTaskHeader() {
        return this.taskHeader;
    }
    public setTaskPrefix(taskPrefix: any) {
        this.taskPrefix = taskPrefix;
    }
    public getTaskPrefix() {
        return this.taskPrefix;
    }
}
