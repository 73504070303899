import { Component, Input, TemplateRef, ViewChild, HostListener } from '@angular/core'
import { SraEmitterService } from 'app/modules/supply-risk-assessment/sra-emitter.service';
import { LdDialog, DialogType, DialogBoxService } from 'app/modules/leva-ui-library/components/dialog-box/dialog-box.service';
@Component({
  selector: 'cprt-graph-wrapper',
  templateUrl: './graph-wrapper.component.html',
  styleUrls: ['./graph-wrapper.component.sass'],
})
export class GraphWrapperComponent {
  @Input() OPTIONS
  @ViewChild(TemplateRef, {static: false}) graphTemplate;
  showDialog = false
  selectedChartView: any = {}
  expandedSelectedChartView: any = {}
  expandDialogConfig: LdDialog = {
    title: 'Expand view',
    id: 'moduleConfigurationConfig',
    template: true,
    customSize: {
      width: 0,
      height: 0
    },
    type: DialogType.INFORMATION,
    beforeClose: () => {
      this.showDialog = false
      return false
    }
  };
  originalEndpoint: any;


  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    const EVENT_POPUP_HEIGHT = window.innerHeight - 20 * (window.innerHeight / 100)
    const EVENT_POPUP_WIDTH = window.innerWidth - 20 * (window.innerWidth / 100)
    this.expandDialogConfig.customSize.height = EVENT_POPUP_HEIGHT
    this.expandDialogConfig.customSize.width = EVENT_POPUP_WIDTH
  }

  constructor(
    private sraEmitterService: SraEmitterService,
    private dialogBoxService: DialogBoxService,
    ) { }
  ngOnInit() {
    this.expandDialogConfig.id = this.OPTIONS.visualCardKey
  }
  openDialog(id) {
    this.onResize(window)
    this.showDialog = !this.showDialog
    this.dialogBoxService.open(id)
  }
  onOptionChange(event, expand) {
    this.originalEndpoint = this.originalEndpoint || this.OPTIONS.endPoint
    if (this.showDialog && expand) {
      this.expandedSelectedChartView = event
      this.OPTIONS.endPoint = this.originalEndpoint + '?allSites=true'
      return
    } else {
      this.selectedChartView = event
      this.OPTIONS.endPoint = this.originalEndpoint
    }
    if (this.OPTIONS.metaData && this.OPTIONS.metaData.drillDown) {
      this.sraEmitterService.emitSelectedPointParams({
        reset: true
      })
    }
    this.OPTIONS.items = [];
  }

}
