import { DataStorageService } from 'app/modules/common/data-storage.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { LdCustomDrowpdown } from 'app/modules/leva-ui-library/components/custom-dropdown/custom-dropdown.service';
import { TaskElementService } from './shared/task-element.service';
import { BodyOutputType, Toast, ToasterService } from 'angular2-toaster';
import { LDTaskContentConfiguration, TaskContentType } from 'app/modules/leva-ui-library/components/task-location-context/task-location-context.service';
import { LdCustomTagTypes } from 'app/modules/leva-ui-library/components/custom-tag/custom-tag.service';

@Component({
  selector: 'app-task-element',
  templateUrl: './task-element.component.html',
  styleUrls: ['./task-element.component.sass'],
  encapsulation: ViewEncapsulation.None,
  providers: [TaskElementService]
})
export class TaskElementComponent implements OnInit {
  @Input() configuration; 
  @Input() viewType; 
  @Output() onCLick: EventEmitter<any> = new EventEmitter<any>();
  dateToshow;
  days
  initialLetter='';
  dateDays
  selectedId = null;
  isNegative =false
  dateViewNegative =false
  dateViewDate
  assigneeHeader = ''
  showIcon: boolean =false;
  assigneeHeaderTag: LDTaskContentConfiguration = {
    type: TaskContentType.LOCATION,
    tags: [
      {
        type: LdCustomTagTypes.TAG_CONTEXT_USER,
        displayName: '',
        otherInformation: [
          {
          }
        ]
      }
    ]
  }
  assigneeCustomTags= []
  dropdownConfig: LdCustomDrowpdown = {
    iconName: 'flash_on',
    defaultTooltip: 'Quick Action',
    denyIconSwitch: true,
    hideDownArrow: true,
    menuItems: [
      {
        viewIcon: 'timeline',
        displayName: 'Mark as Closed',
        defaultView: true,
        viewValue: 'MARK_AS_CLOSE',
        tooltipValue: 'Mark as Closed'
      }
    ]
  }
  iconOver: boolean = false;
  constructor( public dataStorageService: DataStorageService,
    private _toasterService: ToasterService, 
    private taskElementService: TaskElementService) { }

  ngOnInit() {
    if(this.viewType == 'DUE_DATE_VIEW') {
      this.assigneeCustomTags = [];
      let date = String(this.configuration.key)
      this.configuration.value.forEach(element => {
        if(element.toUser) {
          element.toUser.fullName = String(element.toUser.fullName).trim();
          let tag = [];
          if(element.toUser.fullName && element.toUser.fullName !== '') {
            tag.push({
              type: LdCustomTagTypes.TAG_CONTEXT_USER,
              displayName: element.toUser.fullName,
              otherInformation: [{}]
            })
            this.assigneeCustomTags.push(tag);
            element.toUser.initialName = String(element.toUser.fullName).substr(0,1).toUpperCase();
          }
          else {
            tag.push({
              type: LdCustomTagTypes.TAG_CONTEXT_USER,
              displayName: element.toUser.userEmail,
              otherInformation: [{}]
            })
            this.assigneeCustomTags.push(tag);
            element.toUser.initialEmail = String(element.toUser.userEmail).substr(0,1).toUpperCase(); 
          }
        }
      });
      const year = String(date).split("-")[0]
      const month = String(date).split("-")[1]
      const day = String(date).split("-")[2]
      let diffDate = new Date(Number(year),Number(month)-1,Number(day));
      this.calculateDiffInDate(diffDate ,date);
    }
    if(this.viewType == 'ASSIGNEE_VIEW') {
      this.assigneeHeader = this.configuration.key;
      this.assigneeHeaderTag.tags[0].displayName = this.configuration.key;
      this.configuration.value.forEach(element => {
        if(element.toUser && element.toUser.fullName) {
          element.toUser.fullName = String(element.toUser.fullName).trim();
        }
      });
      this.initialLetter = String(this.configuration.key).substr(0,1).toUpperCase();
      this.configuration.value.forEach(element => {
        let date = String(element.dueDate).split(" ")[0]
        let time = String(element.dueDate).split(" ")[1]
        const year = String(date).split("-")[0]
        const month = String(date).split("-")[1]
        const day = String(date).split("-")[2]
        const hour = String(time).split(":")[0]
        const mins = String(time).split(":")[1]
        const seconds = String(time).split(":")[2]
        let diffDate = new Date(Number(year),Number(month)-1,Number(day));
        let items= this.calculateDiff(diffDate ,date);
        element.days = items[0];
        element.isNegative = items[1];
        element.dateToShow = items[2];
      });
    }
    if(this.viewType == 'STATUS_VIEW') {
      this.assigneeHeader = this.configuration.key;
      this.assigneeCustomTags = [];
      this.configuration.value.forEach(element => {
        let tag = [];
        if(element.toUser) {
          element.toUser.fullName = String(element.toUser.fullName).trim();
          if(element.toUser.fullName && element.toUser.fullName !== '') {
            tag.push({
              type: LdCustomTagTypes.TAG_CONTEXT_USER,
              displayName: element.toUser.fullName,
              otherInformation: [{}]
            })
            this.assigneeCustomTags.push(tag);
            element.toUser.initialName = String(element.toUser.fullName).substr(0,1).toUpperCase();
          }
          else {
            tag.push({
              type: LdCustomTagTypes.TAG_CONTEXT_USER,
              displayName: element.toUser.userEmail,
              otherInformation: [{}]
            })
            this.assigneeCustomTags.push(tag);
            element.toUser.initialEmail = String(element.toUser.userEmail).substr(0,1).toUpperCase(); 
          }
        }
      });
      this.configuration.value.forEach(element => {
        let date = String(element.dueDate).split(" ")[0]
        let time = String(element.dueDate).split(" ")[1]
        const year = String(date).split("-")[0]
        const month = String(date).split("-")[1]
        const day = String(date).split("-")[2]
        const hour = String(time).split(":")[0]
        const mins = String(time).split(":")[1]
        const seconds = String(time).split(":")[2]
        let diffDate = new Date(Number(year),Number(month)-1,Number(day));
        let items= this.calculateDiff(diffDate ,date);
        element.days = items[0];
        element.isNegative = items[1];
        element.dateToShow = items[2];
      });
    }
  }
  onItemClick(item) {
    if(!this.iconOver) {
      this.onCLick.emit(item);
    }
  }
  changeStyle(event, item) {
    this.dataStorageService.hoveredItemPayload = item;
    this.selectedId = item.taskId
    this.showIcon = true
  }
  removeChangeStyle(item) {
    this.selectedId = null;
    this.showIcon = false;
  }
  onIconOn(item,event) {
    this.selectedId = item.taskId
    this.dataStorageService.hoveredItemPayload = item;
    this.iconOver = true;
  }
  onIconOver() {
    this.iconOver = false;
  }
  onDropdownClicked(event) {
    if(event.viewValue === 'MARK_AS_CLOSE') {
      this.dataStorageService.hoveredItemPayload.statusId = 2
      this.taskElementService.updateTask(this.dataStorageService.hoveredItemPayload).subscribe(data =>{
        if(data && data.result && data.responseStatus.code === 200) {
          this.popToastS('Task Updated Successfully');
          this.sendEmailNotification(this.dataStorageService.hoveredItemPayload,false);
         this.dataStorageService.taskActionInput = null;
         setTimeout(() => {
          this.dataStorageService.taskActionInput = true;
        },20);
         
        }
        else {
          this.popToastF('Something went wrong');
        }
      })
    }
  }
  sendEmailNotification(payload,created) {
    this.taskElementService.sendEmailNotification(payload,created).subscribe(data=> {
    
     })
   }
  popToastS(data) {
    const toast: Toast = {
      type: 'success',
      showCloseButton: true,
      body: '<div class="toast-msg success-msg">' + data + '</div>',
      bodyOutputType: BodyOutputType.TrustedHtml
    };
    this._toasterService.pop(toast);
  }
  
  popToastW(message) {
    const toast: Toast = {
      type: 'warning',
      showCloseButton: true,
      body: `<div class="toast-msg warning-msg">` + message + `</div>`,
      bodyOutputType: BodyOutputType.TrustedHtml
    };
    this._toasterService.pop(toast);
  }
  
  popToastF(message) {
    const toast: Toast = {
      type: 'error',
      showCloseButton: true,
      body:
        '<div vertical-align=middle class="toast-msg error-msg">' +
        message +
        '</div>',
      bodyOutputType: BodyOutputType.TrustedHtml
    };
    this._toasterService.pop(toast);
  }
  calculateDiff(date, actualDate){
    let items=[]
    let currentDate = new Date();
    this.days = Math.floor((date.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24);
    items.push(this.days)
    if((Math.abs(this.days) > 14)) {
      if(this.days < 0) {
        this.isNegative = true;
      }
      this.dateToshow = actualDate;
    }else {
      if (this.days < 0) {
        this.isNegative = true;
       if (Math.abs(this.days) === 1) {
         this.dateToshow = 'yesterday'
       }
       else {
        this.dateToshow = Math.abs(this.days) + ' days ago'
       }
      }
      else {
        if (this.days === 0) {
          this.dateToshow = 'today'
        }
        else if (this.days === 1) {
          this.dateToshow = 'tomorrow' 
        }
        else {
         this.dateToshow = 'in ' + this.days + ' days'
        }
      }
    }
    items.push(this.isNegative);
    items.push(this.dateToshow);
    return items;
  }
  calculateDiffInDate(date, actualDate){
    let currentDate = new Date();
    this.dateDays = Math.floor((date.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24);
    if((Math.abs(this.dateDays) > 14)) {
      if(this.dateDays < 0) {
        this.dateViewNegative = true;
      }
      this.dateViewDate = actualDate;
    }else {
      if (this.dateDays < 0) {
        this.dateViewNegative = true;
       if (Math.abs(this.dateDays) === 1) {
         this.dateViewDate = 'Yesterday'
       }
       else {
        this.dateViewDate = Math.abs(this.dateDays) + ' days ago'
       }
      }
      else {
        if (this.dateDays === 0) {
          this.dateViewDate = 'Today'
        }
        else if (this.dateDays === 1) {
          this.dateViewDate = 'Tomorrow' 
        }
        else {
         this.dateViewDate = 'In ' + this.dateDays + ' days'
        }
      }
    }
  }
}
