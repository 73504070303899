import { PipeTransform, Pipe } from '@angular/core';
@Pipe({ name: 'ObjectToArray', pure: false })
export class ObjectToArray implements PipeTransform {
    /* transform(value: any, args: any[] = null): any {
        return Object.keys(value)//.map(key => value[key]);
    } */

    transform(value: any, args: any[] = null): any {
        if (value !== undefined) {
            return Object.keys(value);// .map(key => value[key]);
        }
    }
}
