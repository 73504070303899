import { ApiServiceProvider } from 'app/api-service';
import { Commodity } from './../strategy/commodity/shared/commodity.model';
import { BaseService } from 'app/modules/common/base.service';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ScopeDataService } from './scope.data.service';
import {CUSTOMER_CURRENT_USER, CUSTOMER_CUSTOMER_DATA} from '../shared/services/shared-consts';
@Injectable()
export class CustomerConfigurationService {

    constructor() {
    }
    scope: string;
    scopeList: any[];
    yearList: any[];
    disableTransformation = false;
    commodityConfData: any;
    notApplicableRoutes: any;
    productConfData: any;
    dashboardConfData: any[];
    baseCusomerInfo: any;
    quicksightData: any;
    removeColumns: any;
    customReports: any;
    environmentDetails: any;
    loginCustResponse: any;
    innerData: any[];
    innerDataArray = [];
    opportunitiesData: any[];
    opportunitiesArray = [];
    productOpportunitiesData: any[];
    productOpportunitiesArray = [];
    npTableCustomizations: any;

    // Logic to add custom dimensions for different customers
    private rfqCustomDimension = {
        SUPPLIER: 'Supplier',
        CPN: 'CPN',
        CM_ODM: 'CM ODM'
    };
    setLoginCustResponse(value) {

        this.loginCustResponse = value;
    }

    getLoginCustResponse() {
        if (environment.dynamicData) {
            if (this.loginCustResponse === undefined) {
                this.loginCustResponse = JSON.parse(localStorage.getItem(CUSTOMER_CUSTOMER_DATA));
            }
        }
        return this.loginCustResponse;
    }
    getRemoveColumns() {
        if (this.getLoginCustResponse() !== null) {
            if (this.getLoginCustResponse() !== undefined) {

                this.removeColumns = this.getLoginCustResponse().generalInformation.removeColumns;
            }
        }

        return this.removeColumns;
    }
    getDisableTransformation() {
        if (this.getLoginCustResponse()) {
          if (
            this.getLoginCustResponse()['configurationData'] &&
            this.getLoginCustResponse()['configurationData'].hasOwnProperty(
              'productConfigurationData'
            ) &&
            this.getLoginCustResponse()[
              'configurationData'
            ].productConfigurationData.hasOwnProperty('mainTabFeatures')
          ) {
            const mainTabFeatures = this.getLoginCustResponse()['configurationData']
              .productConfigurationData.mainTabFeatures
            if (
              mainTabFeatures.filter((i) => i.hasOwnProperty('Spend Intelligence'))
                .length > 0
            ) {
              const values = mainTabFeatures
                .filter((i) => i.hasOwnProperty('Spend Intelligence'))[0]
                ['Spend Intelligence'].map((i) => i.title)
              if (
                values.includes('Spend Intelligence with Transformation') &&
                values.includes('Spend Intelligence without Transformation')
              ) {
                return true
              } else if (
                values.includes('Spend Intelligence without Transformation') &&
                !values.includes('Spend Intelligence with Transformation')
              ) {
                return false
              } else {
                return true
              }
            }
          }
        }
      }
    getEnvironmentDetails() {
        if (JSON.parse(localStorage.getItem(CUSTOMER_CURRENT_USER))) {
            const obj = JSON.parse(localStorage.getItem(CUSTOMER_CURRENT_USER));
            environment.environmentDetails.tenantId = obj.tenantId;
            environment.environmentDetails.customerId = obj.tenantId;
        }
        this.environmentDetails = environment['environmentDetails'];
        if (this.loginCustResponse && this.loginCustResponse.disTenantId) {
            environment.environmentDetails.ieTenantId = this.loginCustResponse.disTenantId;
        }
        return this.environmentDetails;
    }

    getBaseCustomerInfo() {

        if (this.getLoginCustResponse() !== null) {
            if (this.getLoginCustResponse() !== undefined) {
                this.baseCusomerInfo = this.getLoginCustResponse().baseCusomerInfo;
            }
        }
        else {
            this.baseCusomerInfo = environment['baseCusomerInfo'];

        }
        return this.baseCusomerInfo;
    }

    getQuicksightDashboardData(){
        if (this.getLoginCustResponse() !== null) {
            if (this.getLoginCustResponse() !== undefined) {
                this.quicksightData = this.getLoginCustResponse().quickSightData;
            }
        }
        else {
            this.quicksightData = environment['quickSightData'];

        }
        return this.quicksightData;
    }

    getyearList() {
        this.yearList = [
            {
                title: 'Rest Of Year',
                view: 'Rest Of Year',
                default: true
            },
            {
                title: 'Next Quarter',
                view: 'Next Quarter',
                default: false
            },
            {
                view: 'Next 4 Quarters',
                title: 'Next 4 Quarters',
                isdefault: false
            },
            {
                view: 'Next Fiscal year',
                title: 'Next Fiscal year',
                isdefault: false
            }
        ];

        return this.yearList;
    }

    getScopeList() {
        if (this.getLoginCustResponse() !== null) {

            if (this.getLoginCustResponse() !== undefined) {
                this.scopeList = this.getLoginCustResponse().scopeList;
            }

        }
        else {
            this.scopeList = environment['scopeList'];
        }
        return this.scopeList;

    }

    getCommodityConfData() {
        if (this.getLoginCustResponse() !== null) {

            if (this.getLoginCustResponse() !== undefined) {
                this.commodityConfData = this.getLoginCustResponse()['configurationData'].commodityConfigurationData;
            }

        }


        return this.commodityConfData;
    }
    getNpTableCustomizations() {
        if (this.getLoginCustResponse() !== null) {

            if (this.getLoginCustResponse() !== undefined) {
                this.npTableCustomizations = this.getLoginCustResponse().generalInformation.npTableCustomizations;
            }

        }


        return this.npTableCustomizations;
    }

    getProductConfData() {
        if (this.getLoginCustResponse() !== null) {

            if (this.getLoginCustResponse() !== undefined) {
                this.productConfData = this.getLoginCustResponse()['configurationData'].productConfigurationData;
            }

        }

        return this.productConfData;
    }

    getConfData(target: string) {
        if (target === 'Commodity') {
            return this.getCommodityConfData();
        }
        if (target === 'Product') {
            return this.getProductConfData();
        }
    }

    getDashboardConfigData() {
        if (this.getLoginCustResponse() !== null) {
            if (this.getLoginCustResponse() !== undefined) {
                this.dashboardConfData = this.getLoginCustResponse().dashboardConfData;
                this.innerDataArray = [];
                for (const item of this.dashboardConfData) {
                    if (item['type'] === 'dashboard') {
                        this.innerDataArray.push(item);
                        this.innerData = this.innerDataArray;
                    }
                }
            }
            return this.innerData;
        }
        else {
            this.dashboardConfData = environment['dashboardConfData'];
            this.innerDataArray = [];
            for (const item of this.dashboardConfData) {
                if (item['type'] === 'dashboard') {
                    this.innerDataArray.push(item);
                    this.innerData = this.innerDataArray;
                }
            }
        }

        return this.innerData;
    }
    getOpportunitiesConfigData() {
        if (this.getLoginCustResponse() !== null) {
            if (this.getLoginCustResponse() !== undefined) {
                this.dashboardConfData = this.getLoginCustResponse().dashboardConfData;
                this.opportunitiesArray = [];
                for (const item of this.dashboardConfData) {
                    if (item['type'] === 'opportunities_commodity') {
                        this.opportunitiesArray.push(item);
                        this.opportunitiesData = this.opportunitiesArray;
                    }
                }
            }

            return this.opportunitiesData;
        }
        else {
            this.dashboardConfData = environment['dashboardConfData'];
            this.opportunitiesArray = [];
            for (const item of this.dashboardConfData) {
                if (item['type'] === 'opportunities_commodity') {
                    this.opportunitiesArray.push(item);
                    this.opportunitiesData = this.opportunitiesArray;
                }
            }
        }

        return this.opportunitiesData;
    }
    getProductOpportunitiesConf() {
        if (this.getLoginCustResponse() !== null) {

            if (this.getLoginCustResponse() !== undefined) {
                this.dashboardConfData = this.getLoginCustResponse().dashboardConfData;
                this.productOpportunitiesArray = [];
                for (const item of this.dashboardConfData) {
                    if (item['type'] === 'opportunities_product') {
                        this.productOpportunitiesArray.push(item);
                        this.productOpportunitiesData = this.productOpportunitiesArray;

                    }
                }
            }
            return this.productOpportunitiesData;

        }
        else {
            this.productOpportunitiesArray = [];
            this.dashboardConfData = environment['dashboardConfData'];
            for (const item of this.dashboardConfData) {
                if (item['type'] === 'opportunities_product') {
                    this.productOpportunitiesArray.push(item);
                    this.productOpportunitiesData = this.productOpportunitiesArray;

                }
            }
        }
        return this.productOpportunitiesData;
    }

    getRFQCustomDimensions() {

        if (this.getLoginCustResponse() !== null) {
            if (this.getLoginCustResponse() !== undefined) {

                this.rfqCustomDimension = this.getLoginCustResponse().generalInformation.rfqDimensions;
            }
        }

        return this.rfqCustomDimension;
    }
}
