import { ICellRendererAngularComp } from '@ag-grid-community/angular'
import { Component } from '@angular/core'

@Component({
  selector: 'cprt-child-cell',
  template: `
    <a class="btn-link" (click)="invokeParentMethod()">{{ params.value }}</a>
  `,
})
export class ChildMessageRendererComponent implements ICellRendererAngularComp {
  public params: any

  agInit(params: any): void {
    this.params = params
  }

  public invokeParentMethod() {
    const invokeCriteria = {
      rowIndex: this.params.node.rowIndex,
      fieldName: this.params.colDef.field,
      currentRow: this.params.data,
    }
    this.params.context.componentParent.methodFromParent(invokeCriteria)
  }

  refresh(): boolean {
    return false
  }
}
