import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { ICellRendererParams } from '@ag-grid-community/core';
import { NumericUtils } from 'app/modules/common/utills/NumericUtils.service';

@Component({
  selector: 'child-cell',
  template: `
    <div class="full-width full-height" title="{{ renderCell.title }}">
      <span>{{ renderCell.value }}</span>
    </div>
  `
})
export class NumericRendererComponent implements ICellRendererAngularComp {
  public params: ICellRendererParams;
  public renderCell: any = {};
  public validationMap: Array<any> = [];
  public value: number;

  constructor(private dataStorageService: DataStorageService) {
    this.validationMap = this.dataStorageService.getValidationMessages();
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    const currentColumn= params.colDef.field;
    const errorFlag= currentColumn + '_valid';
    const currentRow: object = this.params.data;

    this.renderCell['value'] = params.value;
    this.renderCell['title'] = params.value;
    if (currentRow[errorFlag] !== undefined && currentRow[errorFlag] !== null) {
      this.renderCell['title'] = currentRow[errorFlag].errorMessage;
    }

    // this.value = this.getNumericValue(params.value);

    // if (currentRow[errorFlag] === undefined) {
    //     this.getErrorFlag(params);
    // }

    // if (currentRow[errorFlag] !== undefined) {
    //     this.renderCell["value"] = this.value;
    //     switch (currentRow[errorFlag]) {
    //         case 0:
    //         case 1:
    //             this.renderCell["value"] = this.value;
    //             this.renderCell["title"] = this.value;
    //             break;
    //         default:
    //             this.getCellError(currentRow[errorFlag]);
    //             break;
    //     }
    // } else {
    //     this.renderCell["title"] = this.value;
    //     if (this.value === null) {
    //         this.renderCell["value"] = this.value;
    //     } else {
    //         this.renderCell["value"] = NumericUtils.format(this.value, (this.params || {})['decimalPrecision']);
    //     }
    // }
  }

  getNumericValue(val) {
    if (val === '') {
      return null;
    }

    val = val || this.params.value;
    if (val === null || val === undefined || typeof val === 'boolean') {
      val = undefined;
    } else if (!(typeof val === 'object')) {
      val = parseFloat(val);
    }
    if (!(typeof val === 'number')) {
      val = undefined;
    } else if (isNaN(val)) {
      val = null;
    }
    return val;
  }

  getErrorFlag(params) {
    const currentColumn= params.colDef.field;
    const errorFlag= currentColumn + '_valid';
    const currentRow: object = params.data;

    if (this.isValidPrecision(params.value, params['decimalPrecision'])) {
      currentRow[errorFlag] = 0;
    } else {
      switch (params['decimalPrecision']) {
        case 2:
          currentRow[errorFlag] = 102;
          break;
        case 4:
          currentRow[errorFlag] = 103;
          break;
        case 8:
          currentRow[errorFlag] = 104;
          break;
        case 6:
          currentRow[errorFlag] = 106;
          break;
        default:
          break;
      }
    }
  }

  public isValidPrecision(currentModel, decimalPrecision) {
    let isValid = true;
    let patternString= '';
    switch (decimalPrecision) {
      case -1:
        break;
      case 0:
        patternString = '^[0-9]*$';
        break;
      default:
        patternString = '^[0-9]+(.[0-9]{1,' + decimalPrecision + '})?$';
        break;
    }
    // console.log(patternString);
    if (patternString.length) {
      const pattern = new RegExp(patternString);
      isValid = pattern.test(currentModel);
    } else {
      isValid = true;
    }
    return isValid;
  }

  getCellError(validFlag) {
    let matchedErrors: Array<any> = [];
    matchedErrors = this.validationMap.filter(
      matchedError => matchedError.VALIDATION_ID === validFlag
    );
    if (matchedErrors.length) {
      this.renderCell['title'] = matchedErrors[0]['VALIDATION_MSG'];
    }
  }

  refresh(): boolean {
    return false;
  }
}
