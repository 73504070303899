import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Router } from '@angular/router';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { ToastService } from 'app/modules/srfq/shared/toaster.service';

@Component({
    selector: 'child-cell',
    template: `
  <file-download (downloadClicked)="downloadExcel()" id="advanced_export"
  [excelInfo]="excelInfo" [payLoadJson]="partMatchAttributesExcel"></file-download>
  `
})
export class FileLinkComponent {
    public params: any;
    val: string;
    public disableLink = false;
    firstNode: any;
    secondNode: any
    public manageVendorLinkFlag = false;
    public excelInfo = {
        method: 'GET',
        type: 'CMS_Report',
        emit: 'true',
        title:''
    };
    partMatchAttributesExcel;
    constructor(
        private router: Router,
        private dataStorageService: DataStorageService,
        private toasterService: ToastService
    ) {

    }
    public agInit(params: any): void {
        this.val = params.value;
        this.params=params;
        this.excelInfo.title=this.val;
        let parser = new DOMParser();
        let doc = parser.parseFromString(this.val, 'text/html');
        if (doc.getElementsByTagName('i')[0]) {
          this.firstNode = doc.getElementsByTagName('i')[0].outerHTML;
        }
        if (doc.getElementsByTagName('span')[0]) {
          this.secondNode = doc.getElementsByTagName('span')[0].innerText;
        }
        if (
          params) {
          this.disableLink = true;
        }
    }

    public downloadExcel() 
    {
      try {
        this.toasterService.populateToaster(
          'info',
          'Working on your excel file. It may take few minutes to download the excel file.'
        );
        const object: any = {
          targetedType: 'CustomReports',
          method: 'GET',
          formParams: {
            fileName: this.val,
          },
          servicePath: '/downloadBenchmarkAndAttributesReport'
          };
          this.partMatchAttributesExcel = object;
        }
        catch (error) {
          console.error('Error in partMatchAttributesReportDownload ', error)
        }
    }
}
