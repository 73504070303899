import { Component, OnInit, Input } from '@angular/core';

import { Filters } from './shared/filters.model';
import { FiltersService } from './shared/filters.service';

@Component({
  selector: 'filters',
  templateUrl: 'filters.component.html',
  styleUrls: ['./filters.component.sass'],
  providers: [FiltersService]
})

export class FiltersComponent implements OnInit {
  @Input() position: string;
  filters: Filters[] = [];

  constructor(private filtersService: FiltersService) { }

  ngOnInit() {

  }
}
