import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

import { BaseApp } from './base-app.model';

@Injectable()
export class BaseAppService {

  constructor(private http: HttpClient) { }
}
