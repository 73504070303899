import { Injectable } from '@angular/core';
import { ApiServiceProvider } from 'app/api-service';

@Injectable()
export class UploadFileDataService {

  constructor(private apiServiceProvider: ApiServiceProvider) {}

  getTenantInfo() {
    const data = {
      targetedType: 'UserService',
      servicePath: '/tenants',
      method: 'GET',
    };
    return this.apiServiceProvider.post(data);
  }

  getLoadedFiles(requestPayload, shouldLoadDataForCustomer: boolean) {
    const data = {
      targetedType: 'Collaboration',
      servicePath: shouldLoadDataForCustomer ? `customer/savedLoads` : `self/unzip`,
      method: 'GET',
    };
    if (requestPayload) {
      data['formParams'] = {
        tenantId: requestPayload.tenantId,
      };
    }
    return this.apiServiceProvider.post(data);
  }

  deleteUploadedFile(requestPayload, shouldLoadDataForCustomer: boolean) {
    const data = {
      targetedType: 'Collaboration',
      servicePath:  shouldLoadDataForCustomer ? `customerFile/delete` : `self/delete`,
      method: 'GET',
      formParams: requestPayload,
    };
    return this.apiServiceProvider.post(data);
  }

  insertFileToS3(requestPayload) {
    const data = {
      targetedType: 'Collaboration',
      servicePath: `customer/insertFile`,
      method: 'POST',
      formParams: requestPayload,
    };
    return this.apiServiceProvider.post(data);
  }

  uploadFileToS3(formData, shouldLoadDataForCustomer: boolean) {
    const data = {
      targetedType: 'Collaboration',
      servicePath: shouldLoadDataForCustomer ? `customer/upload` : `data/upload`,
      method: 'POST',
    };
    return this.apiServiceProvider.multipleFileUploadWithProgress(
      data,
      formData
    );
  }

  discardUploadedFiles(requestPayload, shouldLoadDataForCustomer: boolean) {
    const data = {
      targetedType: 'Collaboration',
      servicePath: shouldLoadDataForCustomer ? `customer/discardLoad` : `self/discardLoad`,
      method: 'POST',
    };
    if (requestPayload) {
      data['formParams'] = {
        tenantId: requestPayload.tenantId,
      };
    }
    return this.apiServiceProvider.post(data);
  }

  triggerPreprocessing(requestData, shouldLoadDataForCustomer: boolean) {
    const payload = {
      method: 'GET',
      servicePath: shouldLoadDataForCustomer ? `customer/load` : `self/load`,
      targetedType: 'Collaboration',
      formParams: requestData,
    };
    return this.apiServiceProvider.post(payload);
  }

}
