import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  window.console.log = () => {};
  window.console.error = () => {};
  window.console.warn = () => {};
  window.console.info = () => {};
  window.console.debug = () => {};
}

LicenseManager.setLicenseKey(
  'CompanyName=Levadata, Inc.,LicensedApplication=LevaData Platform,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-031834,SupportServicesEnd=1_October_2023_[v2]_MTY5NjExNDgwMDAwMA==2ac655173e3d7bc1fd84dfee5d475333'
);

platformBrowserDynamic()
  .bootstrapModule(AppModule, { preserveWhitespaces: true })
  .catch((err) => console.error(err));
