import { SubscriptionService } from 'app/modules/common/subscription.service';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';

import { Widget } from './shared/widget.model';
import { WidgetService } from './shared/widget.service';

@Component({
  selector: 'widget',
  templateUrl: 'widget.component.html',
  providers: [WidgetService]
})
export class WidgetComponent implements OnInit, OnDestroy {
  // 	widget: Widget[] = [];
  // 	@Input() widgetTitle: string;
  widgetDisplayId =0;
  @Input() widgetComponent: any;
  @Output() expandDisplayView = new EventEmitter<any>();
  // @Input() widgetDisplayId:number=0;
  widgetExpandable = false;

  expandDisplay = false;
  selectedDashboard: any;
  componentName1: any;

  constructor(private widgetService: WidgetService, private subscriptionService: SubscriptionService) { }

  ngOnInit() {
    // 			this.widgetDisplayId = this.widgetComponent.id;
    this.widgetExpandable = this.widgetComponent.expandable;
  }

  onExpandView(event: object) {
    try {

      this.expandDisplay = true;
      // this.subscriptionService.setExpandDisplayComponentSubject(this.widgetComponent);
      this.expandDisplayView.emit(this.expandDisplay);

    } catch (error) {
      console.log('Error in widget onExpandView ' + error);
    }
  }

  onEvent(e) {

    if (this.widgetComponent.expandable === true) {
      this.onExpandView(e);

    }


  }
  ngOnDestroy(){
    this.widgetComponent = undefined;

  }
}
