import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

import { KeynoteComments } from './keynote-comments.model';

@Injectable()
export class KeynoteCommentsService {

  constructor(private http: HttpClient) { }


}
