import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

import { SearchBar } from './search-bar.model';

@Injectable()
export class SearchBarService {

  constructor(private http: HttpClient) { }


}
