import { Injectable } from '@angular/core';
import { ApiServiceProvider } from 'app/api-service';

@Injectable()
export class SaveEventService {

  constructor(private http: ApiServiceProvider) {

  }

  createNewEvent(newEventPayload) {
    const requestPayload = {
      targetedType: 'riskManagementService',
      method: 'POST',
      servicePath: `events/createEvent`,
      payload: newEventPayload,
    };
    return this.http.post(requestPayload);
  }

  updateEvent(eventPayload) {
    const requestPayload = {
      targetedType: 'riskManagementService',
      method: 'PUT',
      servicePath: `events/list`,
      payload: eventPayload,
    };
    return this.http.post(requestPayload);
  }

  getEventDetails(filterPayload) {
    const requestPayload = {
      targetedType: 'riskManagementService',
      method: 'POST',
      servicePath: `insight/eventdetails/list`,
      payload: filterPayload,
    };
    return this.http.post(requestPayload);
  }
}
