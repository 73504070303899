import { Injectable } from '@angular/core';
import { CustomerConfigurationService } from './customerConfigurationService';
import { environment } from '../../../environments/environment';

@Injectable()
export class ApiServicePathsService {
  private envDetails;
  private path;
  constructor(
    private customerConfigurationService: CustomerConfigurationService
  ) {
    this.envDetails = this.customerConfigurationService.getEnvironmentDetails();
  }
  public getApiServicePath(targetType) {
    return this.retriveApiServicePath(targetType);
  }
  private retriveApiServicePath(targetType) {
    switch (targetType) {
      case 'ZuulApiPath':
        this.path = this.envDetails.zuulApiEndPointPath;
        break;
      case 'Collaboration':
        this.path = this.envDetails.collabService;
        break;
      case 'productRFQ':
        this.path = this.envDetails.productRFQConfig.service;
        break;
      case 'RFQTemplateConfig':
        this.path = this.envDetails.templateConfig.service;
        break;
      case 'AlertsService':
        this.path = this.envDetails.alertsConfig.service;
        break;
      case 'RFQ':
        this.path = this.envDetails.RFQ;
        break;
      case 'Messages':
        this.path = this.envDetails.Messages;
        break;
      case 'SchedulerService':
        this.path = this.envDetails.SchedulerService;
        break;
      case 'AnalyticsService':
        this.path = this.envDetails.analyticsServiceApiPath;
        break;
      case 'MIService':
        this.path = this.envDetails.miServiceApipath;
        break;
      case 'NegotiationService':
        this.path = this.envDetails.negotiationServiceApiPath;
        break;
      case 'CommonService':
        this.path = this.envDetails.commonServiceApipath;
        break;
      case 'UserService':
        this.path = environment.userServiceApiPath;
        break;
      case 'TokenService':
        this.path = environment.authenticationServiceApiPath;
        break;
      case 'CustomerConfigService':
        this.path = environment.customerConfigurationServiceApiPath;
        break;
      case 'AuthService':
        this.path = environment.authenticationService;
        break;
      case 'LevaAlertsService':
        this.path = this.envDetails.levaAlertsServices;
        break;
      case 'ChatbotServices':
        this.path = this.envDetails.chatbotService;
        break;
      case 'CustomReports':
        this.path = this.envDetails.customReports;
        break;
      case 'ELS':
        this.path = this.envDetails.elasticSearch;
        break;
      case 'IE':
        this.path = this.envDetails.ieService;
        break;
      case 'ProgramManagementService':
        this.path = this.envDetails.programManagementServicePath;
        break;
      case 'BomManagementService':
        this.path = this.envDetails.bomManagementServicePath;
        break;
      case 'StageGateManagementService':
        this.path = this.envDetails.stageGateManagementServicePath;
        break;
      case 'TemplateManagementService':
        this.path = this.envDetails.templateManagementServicePath;
        break;
      case 'sisenceSrc':
        this.path = this.envDetails.sisenceSrc;
        break;
      case 'ContentManagementService':
        this.path = this.envDetails.contentManagementService;
        break;
      default:
        this.path = this.envDetails[targetType];
    }
    return this.path;
  }
}
