import { Type } from '@angular/core';


export enum TileType {
    KPI_TILE = 'kpi_tile',
    GRAPH_TILE = 'graph_tile',
    RESPONSIVE_CONTAINER = 'responsive_container',
    INDUSTRY_ALERTS = 'industry_alerts',
    INDUSTRY_TOP_ALERTS = 'industry_top_alerts',
    INDUSTRY_RECENT_ALERTS = 'industry_recent_alerts',
    METRIC_TRENDS ='market_trends',
    PIE_GRAPH_TILE = 'pie_graph_tile',
    RADIAL_GRAPH_TILE = 'radial_graph_tile',
    RADIAL_GRAPH_TILE1 = 'radial_graph_tile1',
    GLOBAL_FILTER = 'global_filter',
    LEGEND_TILE = 'legend_tile'
}

export class AddItem {
    constructor(public component: Type<any>, public configuration: any) { }
}

