import { Injectable } from '@angular/core';
import {
  ColDef,
  GridOptions,
  ICellRendererParams,
} from '@ag-grid-community/core';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { environment } from '../../../../../../environments/environment';
import { ApiServiceProvider } from '../../../../../api-service';
import { ObjectUtils } from '../../../../common/utills/ObjectUtils.service';
import { ContentCheckboxComponent } from '../../../../core/grid/content-components/content-checkbox/content-checkbox.component';
import {
  HTTP_REQUEST_TYPES,
  TOASTER_MESSAGE_TYPES,
} from '../../../../npi/npiconstants';
import { ConflictComponent } from '../../components/conflict/conflict.component';
import { IsNewComponent } from '../../components/is-new/is-new.component';
import { PartApproveComponent } from '../../components/part-approve/part-approve.component';
import { IsTrainingComponent } from '../../components/training/training.component';
import { ViewPartComponent } from '../../components/view-part/view-part.component';
import { WIDGET_RESPONSE_KEYS } from './manufacturer-classification.service';
import { NpiLoadingService } from '../../../../npi/npi-loading/npi-loading.service';
import { NpiToasterService } from '../../../../npi/npi-toaster.service';
import { GridDropdownComponent } from '../../../../core/grid/grid-dropdown/grid-dropdown.component';
import { isEmpty } from 'lodash';
import { Observable, Subject } from 'rxjs';

const DIMENSION_MAP = {
  columns: {
    ldCategory: 'category',
    ldCommodity: 'commodity',
    ldSubCommodity: 'subCommodityLevel1',
    ldSub2Commodity: 'subCommodityLevel2',
    ldCommodityId: 'commodityId',
    category: 'ldCategory',
    commodity: 'ldCommodity',
    commodityId: 'ldCommodityId',
    subCommodityLevel1: 'ldSubCommodity',
    subCommodityLevel2: 'ldSub2Commodity',
  },
  requiredColumns: [
    'ldCategory',
    'ldCommodity',
    'ldSubCommodity',
    'ldSub2Commodity',
  ],
};

@Injectable()
export class ContentGridUtils {
  public gridOptions: GridOptions = {};
  public selectedRecordsCount = new Set<any>();
  public showGrid = false;
  public showDeleteConfirmationPopUp = false;
  public showClassificationConfrmPopup = false;
  public showUpdateCommodityPopUp = false;
  public showRemoveTrainingPopup = false;
  public newRows;
  public oldRows;
  public selectDropdownListData: any[] = [];
  showDeleteConfirmationPopUpSub = new Subject<any>();
  showClassificationConfrmPopUpSub = new Subject<any>();
  showUpdateCommodityPopUpSub = new Subject<any>();
  showRemoveTrainingPopUpSub = new Subject<any>();
  public CHECKBOX_OBJ = {
    headerName: '',
    lockPosition: true,
    cellClass: 'locked-col',
    checkboxSelection: true,
    field: '',
    width: 60,
    lockVisible: true,
    pinned: 'left',
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    menuTabs: [],
  };
  public paginationObject = {
    totalRecords: 1,
    pageNumber: 1,
    pageCount: 5,
    pageSize: 100,
    pullRecordCount: true,
  };
  viewPaginationObject: PAGINATION = {
    totalRecords: 1,
    pageNumber: 1,
    pageCount: 5,
    pageSize: 10,
    pullRecordCount: true,
  };
  public disable = {
    add: false,
    save: true,
    reset: true,
    discard: true,
    delete: true,
    changeHistory: true,
    newRowDelete: true,
    approve: true,
    updateCommodity: true,
    runClassifier: true
  };
  public selectedCommodityIdForUpdate = {};
  public changedRows = new Map();
  public errorRows = new Map();

  public defaultOptions: GridOptions = {
    suppressMenuHide: true,
    suppressPaginationPanel: true,
    suppressRowClickSelection: true,
    pagination: false,
    domLayout: '',
  };
  public componentService;
  public showChangeHistoryGrid: boolean;
  public changeHistoryGridData: GridOptions = {};
  public viewGridData: GridOptions = {};
  public selectedAll = false;
  public activeFilter: object = {};
  public showViewItemPopup = false;
  showViewItemPopupSub = new Subject<any>();
  public showViewItemGrid = false;
  public isNoAliasEnabled = false;
  public loadGridFlag = true;
  public duplicateRow = null
  // select all checkbox variables
  public isSelectAllEnabled = false;
  public selectAllTitle =
    'Please apply any one of the filter to enable "Select All" checkbox';

  public viewParts = false;
  public viewPartsResolveConflictBtn = false;
  public environment = environment;
  public serviceHeaders: any[] = [];
  constructor(private apiService: ApiServiceProvider) {
    this.gridOptions.onSelectionChanged = this.onGridSelectionChanged.bind(
      this
    );
    /* this.gridOptions.api.addEventListener(
      'selectionChanged',
      this.onGridSelectionChanged.bind(this)
    ); */
  }

  public addNewRow() {
    const newItem = {
      isNewRow: 'new',
      isModified: false,
    };
    this.gridOptions.columnDefs.forEach(
      (i: ColDef) => (newItem[i.field] = null)
    );
    // newItem.isNewRow = 'new';
    // newItem.isModified = false;
    this.gridOptions.rowData = [newItem].concat(this.gridOptions.rowData);
    this.gridOptions.api.setRowData(this.gridOptions.rowData);
    this.disable.changeHistory = true;
    this.disable.delete = true;
    this.disable.newRowDelete = true;
    this.disable.approve = true;
  }
  public checkSaveDisability(): boolean {
    if (
      this.gridOptions.rowData instanceof Array &&
      this.serviceHeaders instanceof Array
    ) {
      const newRows = this.gridOptions.rowData.filter(
        (i) => i.isNewRow || i.isModified
      );
      // const oldRows = this.gridOptions.rowData.filter(i => i.isNewRow !== 'new');
      const totalRows = [...newRows];
      if (totalRows.length <= 0) {
        return true;
      } else {
        return totalRows.some((newDataRow) => {
          return Object.keys(newDataRow).some((rowKey) => {
            if (
              this.serviceHeaders.find(
                (item) => item.headerInfo.field === rowKey
              ) instanceof Object
            ) {
              if (
                this.serviceHeaders
                  .find((item) => item.headerInfo.field === rowKey)
                  .headerInfo.hasOwnProperty('validations')
              ) {
                switch (newDataRow[rowKey]) {
                  case '':
                  case undefined:
                  case null:
                    return true;
                  default:
                    return false;
                }
              }
            }
          });
        });
      }
    }
  }

  public deleteRows(catName = '', from = '') {

    if (this.newRows.length > 0) {
      this.newRows.forEach((i: any) =>
        this.gridOptions.rowData.splice(this.gridOptions.rowData.indexOf(i), 1)
      );
      this.resetDisableButtons();
    }

    if (this.selectedAll && this.oldRows.length > 0 && from === 'training') {
      const filter = this.activeFilter;
      this.selectedRecordsCount.clear();

      console.log(filter);

      this.componentService.bulkDeleteByFilter(filter).subscribe(
        (res) => {
          this.showGrid = false;
          if (res && res.result && res.result.length > 0) {
            NpiToasterService.populateToaster(
              TOASTER_MESSAGE_TYPES.ERROR,
              res.message
            );
          } else {
            NpiToasterService.populateToaster(
              TOASTER_MESSAGE_TYPES.SUCCESS,
              res.message || 'Successfully deleted selected records'
            );
          }
          if (catName === '') {
            this.loadRecords();
          } else {
            this.loadRecords(catName);
          }
          this.resetDisableButtons();
        },
        (err) => {
          NpiToasterService.populateToaster(
            TOASTER_MESSAGE_TYPES.ERROR,
            'Delete Failed'
          );
          this.showGrid = true;
        }
      );
    } else if (this.oldRows.length > 0) {
      this.selectedRecordsCount.clear();
      this.gridOptions.api
        .getSelectedRows()
        .forEach((i) =>
          this.selectedRecordsCount.add(
            encodeURIComponent(i[this.componentService.uniqueKey])
          )
        );
      let subscription;
      if (catName === '') {
        subscription = this.componentService.deleteRows(
          Array.from(this.selectedRecordsCount)
        );
      } else {
        subscription = this.componentService.deleteRows(
          catName,
          Array.from(this.selectedRecordsCount)
        );
      }
      subscription.subscribe(
        (res) => {
          this.showGrid = false;
          if (res.result.length > 0) {
            NpiToasterService.populateToaster(
              TOASTER_MESSAGE_TYPES.ERROR,
              res.message
            );
          } else {
            NpiToasterService.populateToaster(
              TOASTER_MESSAGE_TYPES.SUCCESS,
              res.message || 'Successfully deleted selected records'
            );
          }
          if (catName === '') {
            this.loadRecords(
              '',
              null,
              {
                pullRecordCount: true,
                pageNumber: 1,
              }
            );
          } else {
            this.loadRecords(
              catName,
              null,
              {
                pullRecordCount: true,
                pageNumber: 1,
              }
            );
          }
          this.resetDisableButtons();
        },
        (err) => {
          NpiToasterService.populateToaster(
            TOASTER_MESSAGE_TYPES.ERROR,
            'Delete Failed'
          );
          this.showGrid = true;
        }
      );
    } else {
      this.gridOptions.api.setRowData(this.gridOptions.rowData);
    }
  }

  public runClassification() {
    this.showClassificationConfirmation(false);
    this.selectedRecordsCount.clear()
    // call bulk filter api instead of passing ids
    if (this.selectedAll) {
      const filter = this.activeFilter
      this.componentService.runClassifierGivenFilter(filter).subscribe(
        (res) => {
          if (res.message) {
            NpiToasterService.populateToaster(
              TOASTER_MESSAGE_TYPES.SUCCESS,
              res.message || 'SUCCESS'
            )
          } else {
            NpiToasterService.populateToaster(
              TOASTER_MESSAGE_TYPES.ERROR,
              'Operation Failed'
            )
          }
        },
        (err) => {
          NpiToasterService.populateToaster(
            TOASTER_MESSAGE_TYPES.ERROR,
            'Operation Failed'
          )
        }
      );
    } else {
      this.gridOptions.api
        .getSelectedRows()
        .filter((row) => row.isApproved === false)
        .forEach((i) =>
          this.selectedRecordsCount.add(
            encodeURIComponent(i[this.componentService.uniqueKey])
          )
        )
      this.componentService
        .runClassifierGivenIds(Array.from(this.selectedRecordsCount))
        .subscribe(
          (res) => {
            if (res.message) {
              NpiToasterService.populateToaster(
                TOASTER_MESSAGE_TYPES.SUCCESS,
                res.message || 'SUCCESS'
              )
            } else {
              NpiToasterService.populateToaster(
                TOASTER_MESSAGE_TYPES.ERROR,
                'Operation Failed'
              )
            }
          },
          (err) => {
            NpiToasterService.populateToaster(
              TOASTER_MESSAGE_TYPES.ERROR,
              'Operation Failed'
            )
          }
        )
    }
    this.disable = { ...this.disable, runClassifier: true }
  }

  public onGridSelectionChanged(params: ICellRendererParams) {
    this.selectedRecordsCount.clear();
    params.api
      .getSelectedRows()
      .forEach((i) =>
        this.selectedRecordsCount.add(
          encodeURIComponent(i[this.componentService.uniqueKey])
        )
      );
    const selectedRows = this.gridOptions.api.getSelectedRows();
    this.newRows = selectedRows.filter((i) => i.isNewRow === 'new');
    this.oldRows = selectedRows.filter((i) => i.isNewRow !== 'new');
    if (this.changedRows.size === 0 && this.errorRows.size === 0) {
      if (this.selectedRecordsCount.size > 0) {
        this.disable.delete = false;
        this.disable.updateCommodity = false;
        this.disable.runClassifier = false;
        if (this.oldRows.length === 1 && this.newRows.length <= 0) {
          this.disable.changeHistory = false;
        } else {
          this.disable.changeHistory = true;
        }
        if (this.oldRows.length > 0) {
          this.disable.newRowDelete = true;
        } else {
          this.disable.newRowDelete = false;
        }
        if (this.newRows.length <= 0) {
          this.disable.approve = false;
        } else {
          this.disable.approve = true;
        }
      } else {
        this.disable.delete = true;
        this.disable.approve = true;
        this.disable.newRowDelete = true;
        this.disable.changeHistory = true;
        this.disable.updateCommodity = true;
        this.disable.runClassifier = true;
      }
    } else {
      this.disable.delete = true;
      this.disable.approve = true;
      this.disable.newRowDelete = true;
      this.disable.changeHistory = true;
      this.disable.updateCommodity = true;
      this.disable.runClassifier = true;
    }
  }
  public saveGridChanges(catName = '') {
    const newRows = this.gridOptions.rowData.filter(
      (i) => i.isNewRow || i.isModified
    );
    // console.log(newRows);
    let subscription;
    if (catName === '') {
      subscription = this.componentService.saveChanges(newRows);
    } else {
      subscription = this.componentService.saveChanges(catName, newRows);
    }
    subscription.subscribe((response) => {
      this.showGrid = false;

      if (catName === '') {
        this.loadRecords();
      } else {
        this.duplicateRow = null
        if (catName === 'location-sites' || catName === 'vendor-sites' || catName === 'part-sites') {
          if (response.message && response.message.toLowerCase().includes('already exists')) {
            NpiToasterService.populateToaster(
              TOASTER_MESSAGE_TYPES.WARNING,
              response.message
            );
            this.disable.add = true
            this.disable.discard = false;
            this.disable.reset = true;
            this.disable.save = false
            const newRow = this.componentService.editedRows.find(x => x.isNewRow)
            this.componentService.editedRows = [newRow]
            this.duplicateRow = newRow
            this.loadRecords(catName);
            return
          }
          this.disable.add = false
          this.componentService.editedRows = [];
          this.componentService.errorList = [];
          this.componentService.changedValMap = {};
        }
        this.loadRecords(catName);
      }
      NpiToasterService.populateToaster(
        TOASTER_MESSAGE_TYPES.SUCCESS,
        response.message || 'Successfully saved selected records'
      );
      this.disable.save = true;
      this.disable.reset = true;
      this.disable.discard = true;
      this.changedRows.clear();
    });
  }

  public loadHeaders(param = '', scope?: string) {
    let subscription;
    if (param === '') {
      subscription = this.componentService.getHeaders();
    } else {
      subscription = this.componentService.getHeaders(param);
    }
    subscription.subscribe((headers) => {
      if (headers instanceof Object) {
        let gridHeaders = ObjectUtils.deepFind(
          headers,
          [
            WIDGET_RESPONSE_KEYS.RESULT,
            WIDGET_RESPONSE_KEYS.NO_SECTION,
            WIDGET_RESPONSE_KEYS.WIDGET_HEADERS,
          ].join('.')
        );
        this.serviceHeaders = gridHeaders;
        if (gridHeaders instanceof Array && gridHeaders.length > 0) {
          this.loadGridFlag = true;
          gridHeaders = gridHeaders.map((i) => {
            const obj = {
              headerName: i.headerInfo.displayName,
              field: i.headerInfo.field,
              // editable: i.headerInfo.editable,
              editable: this.checkEditFunction.bind(
                this,
                i.headerInfo.editable,
                i.headerInfo.newRowEditable
              ),
              cellClassRules: {
                'border-blue': (params) => {
                  return params.data[params.colDef.field + 'Modified'] === true;
                },
                'border-red': (params) => {
                  return params.data[params.colDef.field + 'Error'] === true;
                },
              },
              tooltipField: i.headerInfo.field,
              headerComponentParams: i,
              hide:
                typeof i.headerInfo.display === 'undefined'
                  ? false
                  : !i.headerInfo.display,
              menuTabs: ['generalMenuTab', 'columnsMenuTab'],
            } as ColDef;
            switch (i.headerInfo.inputType) {
              case 'dropdown':
                break;
              case 'singleSelectDropdown':
                this.loadOptionsForSingleSelect(obj, i, param);
                obj.cellRenderer = (params) => {
                  return `<div class="full-width-10 text-style pull-left">${params.value || ''
                    }</div>
                  <div class="pull-right">
                      <i class="ld-icon-arrow-down ld-blue font-size-10" id="dropdownArrow"></i>
                  </div>`;
                };
                break;
              case 'checkbox':
                obj.editable = false;
                obj.cellRendererFramework = ContentCheckboxComponent;
                break;
              case 'approvedComponent':
                obj.width = 100;
                obj.lockVisible = true;
                obj.cellRendererFramework = PartApproveComponent;
                break;
              case 'conflictComponent':
                obj.width = 100;
                obj.lockVisible = true;
                obj.cellRendererFramework = ConflictComponent;
                break;
              case 'trainingComponent':
                obj.width = 150;
                obj.lockVisible = true;
                obj.cellRendererFramework = IsTrainingComponent;
                break;
              case 'newComponent':
                obj.width = 100;
                obj.lockVisible = true;
                obj.cellRendererFramework = IsNewComponent;
                break;
              case 'viewComponent':
                obj.width = 100;
                obj.pinned = 'right';
                obj.lockVisible = true;
                obj.cellRendererFramework = ViewPartComponent;
                obj['menuTabs'] = [];
                break;
            }
            return obj;
          });
          if (this.componentService.uniqueKey === 'spId') {
            this.gridOptions.columnDefs = gridHeaders;
          } else {
            this.gridOptions.columnDefs = [this.CHECKBOX_OBJ].concat(
              gridHeaders
            );
          }
          NpiLoadingService.showLoader(false);

          if (this.loadGridFlag) {
            if (param === '') {
              this.loadRecords();
            } else {
              this.loadRecords(param);
            }
          }
        }
      }
    });
  }
  public checkEditFunction(
    editable: boolean | undefined,
    newRowEditable: boolean | undefined,
    params
  ) {
    if (editable) {
      return true;
    } else {
      return params.node.data.isNewRow === 'new' && newRowEditable;
    }
  }
  public loadRecords(
    catName = '',
    gridOptions?: GridOptions,
    paginationObject?,
    filterObj?: object,
    noalias?: boolean
  ) {
    this.paginationObject = paginationObject = {
      ...this.paginationObject,
      ...paginationObject,
    };
    this.showGrid = false;
    const requestPayload: any = {
      pageNumber: paginationObject.pageNumber,
      pageSize: parseInt(paginationObject.pageSize, 10),
      pullRecordCount: paginationObject.pageNumber === 1,
    };
    let subscription;
    if (catName === '') {
      if (typeof filterObj !== 'undefined' && filterObj !== null) {
        subscription = this.componentService.getRows(requestPayload, filterObj);
      } else {
        subscription = this.componentService.getRows(requestPayload);
      }
    } else {
      if (typeof filterObj !== 'undefined' && filterObj !== null) {
        if (typeof noalias !== 'undefined') {
          noalias = noalias ? noalias : this.isNoAliasEnabled;
          subscription = this.componentService.getRows(
            catName,
            requestPayload,
            filterObj,
            noalias
          );
        } else {
          subscription = this.componentService.getRows(
            catName,
            requestPayload,
            filterObj
          );
        }
      } else {
        if (typeof noalias !== 'undefined') {
          subscription = this.componentService.getRows(
            catName,
            requestPayload,
            filterObj,
            noalias
          );
        } else {
          subscription = this.componentService.getRows(catName, requestPayload);
        }
      }
    }
    subscription.subscribe((rowData) => {
      if (rowData instanceof Object && rowData.result instanceof Array) {
        this.loadGridData(rowData, gridOptions, paginationObject, catName);
      }
    });
  }

  public loadGridData(
    rowData,
    gridOptions?: GridOptions,
    paginationObject?,
    catName?: string
  ) {
    // gridOptions = gridOptions || this.gridOptions;
    // paginationObject = paginationObject || this.paginationObject;
    this.gridOptions = gridOptions = { ...this.gridOptions, ...gridOptions };
    this.paginationObject = paginationObject = {
      ...this.paginationObject,
      ...paginationObject,
    };
    if (!(rowData instanceof Object) || !(rowData.result instanceof Array)) {
      rowData = {
        paginationObject,
        result: [],
      };
    }
    if (
      paginationObject instanceof Object &&
      rowData.pagingConfiguration instanceof Object
    ) {
      // paginationObject.pageSize = rowData.pagingConfiguration.pageSize;
      paginationObject.pageNumber = rowData.pagingConfiguration.pageNumber;
      if (paginationObject.pullRecordCount === true) {
        paginationObject.totalRecords =
          rowData.pagingConfiguration.totalRecords;
        paginationObject.pullRecordCount = false;
      }
      paginationObject.pageCount = Math.ceil(
        paginationObject.totalRecords / rowData.pagingConfiguration.pageSize
      );
      // this.paginationObject = ObjectUtils.copy(paginationObject);
    }
    gridOptions.rowData = this.duplicateRow ? [this.duplicateRow, ...rowData.result] : rowData.result;
    this.duplicateRow = null
    if (
      gridOptions.api instanceof Object &&
      gridOptions.api.setRowData instanceof Function
    ) {
      gridOptions.api.setRowData(rowData.result.data);
    }
    this.gridOptions.onGridReady = () => {
      if (
        catName === 'entities' ||
        catName === 'mpns' ||
        catName === 'mpn-classification'
      ) {
        this.gridOptions.api.sizeColumnsToFit();
      }
      if (this.selectedAll) {
        this.gridOptions.api.selectAll();
      }
    };
    this.showGrid = true;
  }

  public paginationButtonsClick(
    paginationObject,
    assignValue,
    updateValue,
    event,
    catName = ''
  ) {
    this.resetDisableButtons();
    if (typeof assignValue === 'number') {
      paginationObject.pageNumber = assignValue;
    }
    if (typeof updateValue === 'number') {
      paginationObject.pageNumber += updateValue;
    }
    if (
      event instanceof Object &&
      event.currentTarget instanceof HTMLInputElement
    ) {
      if (
        event.currentTarget.value > 0 &&
        event.currentTarget.value <= paginationObject.pageCount
      ) {
        paginationObject.pageNumber = event.currentTarget.value;
      } else {
        paginationObject.pageNumber = event.currentTarget.value =
          paginationObject.pageNumber;
        event.currentTarget.blur();
        return;
      }
    }
    this.loadRecords(
      catName,
      null,
      paginationObject,
      this.activeFilter,
      this.isNoAliasEnabled
    );
  }

  public isStringArray(sourceArray: any[]) {
    if (Object.prototype.toString.call(sourceArray) === '[object Array]') {
      if (sourceArray.length < 1) {
        return false;
      } else {
        return sourceArray.every((d: any) => typeof d === 'string');
      }
    }
    return false;
  }

  public loadOptionsForSingleSelect(colDef: ColDef, header, param = '') {
    const cellRenderParamsObj = {
      values: [],
      url: '',
    };
    switch (header.key) {
      case 'PART_TYPE':
        if (!(colDef.cellEditorParams instanceof Object)) {
          colDef.cellEditorParams = cellRenderParamsObj;
        } else {
          colDef.cellEditorParams.values = [];
        }
        colDef.cellEditor = 'agRichSelectCellEditor';
        colDef.cellEditorParams.values = ['STANDARD', 'CUSTOM', 'UNKNOWN'];
        break;
      default:
        this.loadGridFlag = false;
        const payload = {
          targetedType: 'ContentManagementService',
          method: HTTP_REQUEST_TYPES.GET,
          servicePath: `${this.environment.content.contentManagementService}${header.headerInfo.url}`,
        };
        payload.method = !header.headerInfo.method
          ? HTTP_REQUEST_TYPES.GET
          : header.headerInfo.method;
        // colDef.cellRendererParams.options$ = new BehaviorSubject(undefined);
        this.apiService.post(payload).subscribe(
          (res) => {
            if (!(colDef.cellEditorParams instanceof Object)) {
              colDef.cellEditorParams = cellRenderParamsObj;
            } else {
              colDef.cellEditorParams.values = [];
            }
            colDef.cellEditorFramework = GridDropdownComponent;
            if (res instanceof Object && res.result instanceof Array) {
              if (this.isStringArray(res.result)) {
                colDef.cellEditorParams.values = res.result
                  .filter((i) => (i || '').toString().trim().length > 0)
                  .map((item) => ({ name: item }));
              } else {
                colDef.cellEditorParams.values = res.result.map((item) => {
                  return {
                    ...item,
                    ...{ name: item[DIMENSION_MAP.columns[colDef.field]] },
                  };
                });
              }
              if (header.headerInfo.mapColumn) {
                colDef.cellEditorParams.dimensionMap = DIMENSION_MAP;
              }
              colDef.cellEditorParams.url = header.headerInfo.url || '';
              this.selectDropdownListData = colDef.cellEditorParams.values;
            }
            this.loadGridFlag = true;
            if (param === '') {
              this.loadRecords();
            } else {
              this.loadRecords(param);
            }
          },
          (err) => {
            if (!(colDef.cellEditorParams instanceof Object)) {
              colDef.cellEditorParams = cellRenderParamsObj;
            } else {
              colDef.cellEditorParams.values = [];
              colDef.cellEditorParams.url = header.headerInfo.url || '';
            }
            colDef.cellEditor = 'agRichSelectCellEditor';
          }
        );
        break;
    }
  }
  public changeHistory(headerKey, uniqueKey) {
    this.showChangeHistoryGrid = false;
    const selectedRow = this.gridOptions.api.getSelectedRows()[0];
    const trackId = encodeURIComponent(selectedRow[uniqueKey]);
    if (typeof trackId !== 'undefined') {
      forkJoin([
        this.componentService.getHeaders(headerKey),
        this.componentService.getChangeHistory(trackId),
      ]).subscribe(([headers, rowData]) => {
        const gridHeaders = this.parseResponsesServiceHeaders(
          headers['result'].NO_SECTION.widgetHeaders
        );
        this.changeHistoryGridData.columnDefs = gridHeaders;
        this.changeHistoryGridData.rowData = rowData['result'];
        this.showChangeHistoryGrid = true;
      });
    }
  }
  public getChangedRows(params, uniqueKey: string) {
    const editedRow = this.changedRows.has(
      params.node.data[uniqueKey + 'Original'] || params.data[uniqueKey]
    )
      ? this.changedRows.get(
        params.node.data[uniqueKey + 'Original'] || params.data[uniqueKey]
      )
      : new Map();
    this.changedRows.set(
      params.node.data[uniqueKey + 'Original'] || params.data[uniqueKey],
      editedRow
    );
    if (!params.node.data[uniqueKey + 'Original']) {
      params.node.data[uniqueKey + 'Original'] = params.data[uniqueKey];
    }
    return editedRow;
  }

  public updateEditedRow(editedRow, params) {
    if (!editedRow.has(params.colDef.field + 'Original')) {
      editedRow.set(params.colDef.field + 'Original', params.oldValue);
      editedRow.set(params.colDef.field, params.newValue);
    } else {
      if (editedRow.get(params.colDef.field + 'Original') === params.newValue) {
        editedRow.delete(params.colDef.field);
        editedRow.delete(params.colDef.field + 'Original');
      } else {
        editedRow.set(params.colDef.field, params.newValue);
      }
    }
  }
  public updateErrors(
    editedRow,
    params,
    uniqueKey,
    scopeString = CONTENT_SCOPES.ENTITY_CLASSIFICATION
  ) {
    if (editedRow.has(params.colDef.field)) {
      if (
        this.isFieldInValid(
          params.colDef,
          params.newValue,
          scopeString,
          params.data
        )
      ) {
        this.errorRows.set(
          params.node.data[uniqueKey + 'Original'] + params.colDef.field,
          true
        );
        this.paramsDataUpdate(params, false);
      } else {
        this.errorRows.delete(
          params.node.data[uniqueKey + 'Original'] + params.colDef.field
        );
        this.paramsDataUpdate(params, true);
      }
    } else {
      if (
        this.errorRows.has(
          params.node.data[uniqueKey + 'Original'] + params.colDef.field
        )
      ) {
        this.errorRows.delete(
          params.node.data[uniqueKey + 'Original'] + params.colDef.field
        );
      }
      if (editedRow.size === 0) {
        this.changedRows.delete(
          params.node.data[uniqueKey + 'Original'] || params.data[uniqueKey]
        );
      }
      this.paramsDataUpdate(params, false, false);
    }
  }
  public paramsDataUpdate(params, isModified, isError?) {
    // params.node.data[params.colDef.field + 'Error'] = isModified;
    // params.node.data.isError = isModified;
    // if (typeof isError === 'undefined') {
    //     params.node.data[params.colDef.field + 'Modified'] = !isModified;
    //     params.node.data.isModified = !isModified;
    // } else {
    //     params.node.data[params.colDef.field + 'Modified'] = isModified;
    //     params.node.data.isModified = isModified;
    // }
    params.node.data[params.colDef.field + 'Error'] =
      isError === undefined ? !isModified : isError;
    params.node.data.isError = isError === undefined ? !isModified : isError;
    params.node.data[params.colDef.field + 'Modified'] = isModified;
    params.node.data.isModified = isModified;
  }
  public showDeleteConfirmation(deleteConcfirmationValue) {
    this.showDeleteConfirmationPopUp = deleteConcfirmationValue;
    this.showDeleteConfirmationPopUpSub.next(deleteConcfirmationValue)
  }
  public showClassificationConfirmation(value) {
    this.showClassificationConfrmPopup = value;
    this.showClassificationConfrmPopUpSub.next(value)
  }
  public onClickYesDelete(catName = '', from = '') {
    this.deleteRows(catName, from);
    this.showDeleteConfirmationPopUp = false;
    this.showDeleteConfirmationPopUpSub.next(false)
  }
  public showUpdateCommodity(confirmation) {
    this.showUpdateCommodityPopUp = confirmation;
    this.showUpdateCommodityPopUpSub.next(confirmation)
  }
  public showRemoveTrainingConfirmation(confirmation) {
    this.showRemoveTrainingPopup = confirmation;
    this.showRemoveTrainingPopUpSub.next(confirmation)
  }

  public parseResponsesServiceHeaders(serviceHeaders: any[]) {
    const gridHeaders: ColDef[] = [];
    for (const responsesServiceHeader of serviceHeaders) {
      let headerObject: ColDef = {
        headerName: responsesServiceHeader.headerInfo.headerName,
        field: responsesServiceHeader.headerInfo.field,
        headerTooltip: responsesServiceHeader.headerInfo.headerName,
        tooltipField: responsesServiceHeader.headerInfo.headerName,
        hide:
          typeof responsesServiceHeader.headerInfo.display === 'undefined'
            ? false
            : !responsesServiceHeader.headerInfo.display,
      };
      // headerObject['datatype'] = responsesServiceHeader.headerMetaInfo.dataType;
      headerObject['type'] = responsesServiceHeader.headerMetaInfo.dataType;
      let obj;
      switch (responsesServiceHeader.headerInfo.inputType) {
        case 'approvedComponent':
          obj = {
            width: 75,
            cellRendererFramework: PartApproveComponent,
          };
          obj['menuTabs'] = [];
          headerObject = { ...headerObject, ...obj };
          break;
        case 'conflictComponent':
          obj = {
            width: 75,
            cellRendererFramework: ConflictComponent,
          };
          obj['menuTabs'] = [];
          headerObject = { ...headerObject, ...obj };
          break;
        case 'trainingComponent':
          obj = {
            width: 75,
            cellRendererFramework: IsTrainingComponent,
          };
          obj['menuTabs'] = [];
          headerObject = { ...headerObject, ...obj };
          break;
        case 'newComponent':
          obj = {
            width: 100,
            cellRendererFramework: IsNewComponent,
          };
          obj['menuTabs'] = [];
          headerObject = { ...headerObject, ...obj };
          break;
        case 'viewComponent':
          obj = {
            pinned: 'right',
            lockVisible: true,
            width: 75,
            cellRendererFramework: ViewPartComponent,
          };
          obj['menuTabs'] = [];
          headerObject = { ...headerObject, ...obj };
          break;
      }
      gridHeaders.push(headerObject);
    }
    return gridHeaders;
  }

  public isFieldInValid(
    colDef: ColDef,
    value: any,
    scope: string,
    data?
  ): boolean {
    if (
      colDef.headerComponentParams instanceof Object &&
      colDef.headerComponentParams.headerInfo.validations instanceof Object
    ) {
      switch (scope) {
        case CONTENT_SCOPES.ENTITY_CLASSIFICATION:
          switch (colDef.field) {
            case 'ldNormalizedName':
              if (
                colDef.headerComponentParams.headerInfo.validations.required
              ) {
                if (
                  value === null ||
                  value === undefined ||
                  (typeof value === 'string' && value.trim().length === 0)
                ) {
                  return true;
                }
              }
              break;
            case 'customerProvidedName':
              if (
                colDef.headerComponentParams.headerInfo.validations.required
              ) {
                if (
                  value === null ||
                  value === undefined ||
                  (typeof value === 'string' && value.trim().length === 0)
                ) {
                  return true;
                }
              }
              break;
          }
          break;
        case CONTENT_SCOPES.MASTERDATA.COMMODITIES:
          switch (colDef.field) {
            case 'commodity':
            case 'subCommodityLevel1':
            case 'subCommodityLevel2':
              if (
                colDef.headerComponentParams.headerInfo.validations.required
              ) {
                if (
                  value === null ||
                  value === undefined ||
                  (typeof value === 'string' && value.trim().length === 0)
                ) {
                  return true;
                }
              }
              break;
          }
          break;
        case CONTENT_SCOPES.MASTERDATA.ENTITIES:
          switch (colDef.field) {
            case 'ldNormalizedName':
            case 'customerProvidedName':
              if (
                colDef.headerComponentParams.headerInfo.validations.required
              ) {
                if (
                  value === null ||
                  value === undefined ||
                  (typeof value === 'string' && value.trim().length === 0)
                ) {
                  return true;
                }
              }
              break;
          }
          break;
        case CONTENT_SCOPES.MASTERDATA.MPNS:
          switch (colDef.field) {
            case 'lmpn':
              if (
                colDef.headerComponentParams.headerInfo.validations.required
              ) {
                if (
                  value === null ||
                  value === undefined ||
                  (typeof value === 'string' && value.trim().length === 0)
                ) {
                  return true;
                }
              }
              break;
          }
          break;
        case CONTENT_SCOPES.PART_CLASSIFICATION:
          break;
        case CONTENT_SCOPES.MPN_CLASSIFICATION:
          switch (colDef.field) {
            case 'lmpn':
              break;
          }
          break;
        case CONTENT_SCOPES.VENDOR_SITES:
          switch (colDef.field) {
            case 'siteDescription':
            case 'url':
            case 'name':
            case 'streetAddress1':
            case 'country':
            case 'state':
            case 'city':
            case 'latitude':
            case 'longitude':
              if (
                colDef.headerComponentParams.headerInfo.validations.required
              ) {
                if (
                  value === null ||
                  value === undefined ||
                  (typeof value === 'string' && value.trim().length === 0)
                ) {
                  return true;
                }
              }
              break;
          }
          break;
      }
    }
  }
  public selectAll(ev): void {
    const isChecked = (this.selectedAll = ev.target.checked);
    if (isChecked) {
      this.gridOptions.api.selectAll();
      NpiToasterService.populateToaster(
        TOASTER_MESSAGE_TYPES.INFO,
        `All ${this.paginationObject.totalRecords} records selected`
      );
    } else {
      this.gridOptions.api.deselectAll();
      NpiToasterService.populateToaster(
        TOASTER_MESSAGE_TYPES.INFO,
        `All ${this.paginationObject.totalRecords} records unselected`
      );
    }
  }
  public approve(cat = '', idKey: string, approve = true): void {
    const dataObj = {
      is_approved: approve,
    };
    const filter = this.activeFilter;
    this.showGrid = false;
    if (this.selectedAll) {
      this.componentService
        .bulkUpdateByFilter(dataObj, filter)
        .subscribe((data) => {
          if (data && data.responseStatus) {
            if (
              data.responseStatus.code === 202 ||
              data.responseStatus.code === 200
            ) {
              NpiToasterService.populateToaster(
                TOASTER_MESSAGE_TYPES.SUCCESS,
                data.message
              );
            } else if (data.responseStatus.code === 500) {
              NpiToasterService.populateToaster(
                TOASTER_MESSAGE_TYPES.ERROR,
                data.message
              );
            }
          }
          this.resetDisableButtons();
          this.loadRecords(cat, null, null, filter);
        });
    } else {
      const selectedRows = this.gridOptions.api.getSelectedRows();
      const rowIds = selectedRows
        .filter((item) => {
          return (
            item[idKey] !== '' &&
            item[idKey] !== null &&
            item[idKey] !== undefined
          );
        })
        .map((item) => {
          return encodeURIComponent(item[idKey]);
        });
      this.componentService
        .bulkUpdateByIds(dataObj, rowIds)
        .subscribe((data) => {
          if (data && data.responseStatus) {
            if (
              data.responseStatus.code === 202 ||
              data.responseStatus.code === 200
            ) {
              NpiToasterService.populateToaster(
                TOASTER_MESSAGE_TYPES.SUCCESS,
                data.message
              );
            } else if (data.responseStatus.code === 500) {
              NpiToasterService.populateToaster(
                TOASTER_MESSAGE_TYPES.ERROR,
                data.message
              );
            }
          }
          this.resetDisableButtons();
          this.isNoAliasEnabled = false;
          this.loadRecords(cat, null, null, filter);
        });
    }
  }
  public curation(cat = '', idKey: string, curation = true): void {
    const dataObj = {
      is_curation: curation,
    };
    const filter = this.activeFilter;
    this.showGrid = false;
    if (this.selectedAll) {
      this.componentService
        .bulkUpdateByFilter(dataObj, filter)
        .subscribe((data) => {
          if (data && data.responseStatus) {
            if (
              data.responseStatus.code === 202 ||
              data.responseStatus.code === 200
            ) {
              NpiToasterService.populateToaster(
                TOASTER_MESSAGE_TYPES.SUCCESS,
                data.message
              );
            } else if (data.responseStatus.code === 500) {
              NpiToasterService.populateToaster(
                TOASTER_MESSAGE_TYPES.ERROR,
                data.message
              );
            }
          }
          this.resetDisableButtons();
          this.loadRecords(cat, null, null, filter);
        });
    } else {
      const selectedRows = this.gridOptions.api.getSelectedRows();
      const rowIds = selectedRows
        .filter((item) => {
          return (
            item[idKey] !== '' &&
            item[idKey] !== null &&
            item[idKey] !== undefined
          );
        })
        .map((item) => {
          return encodeURIComponent(item[idKey]);
        });
      this.componentService
        .bulkUpdateByIds(dataObj, rowIds)
        .subscribe((data) => {
          if (data && data.responseStatus) {
            if (
              data.responseStatus.code === 202 ||
              data.responseStatus.code === 200
            ) {
              NpiToasterService.populateToaster(
                TOASTER_MESSAGE_TYPES.SUCCESS,
                data.message
              );
            } else if (data.responseStatus.code === 500) {
              NpiToasterService.populateToaster(
                TOASTER_MESSAGE_TYPES.ERROR,
                data.message
              );
            }
          }
          this.resetDisableButtons();
          this.isNoAliasEnabled = false;
          this.loadRecords(cat, null, null, filter);
        });
    }
  }
  public moveToTraining(cat = '', idKey: string, moving = true): void {
    const dataObj = {
      is_training: moving,
    };
    const filter = this.activeFilter;
    this.showGrid = false;
    if (this.selectedAll) {
      this.componentService
        .bulkUpdateByFilter(dataObj, filter)
        .subscribe((data) => {
          if (data && data.responseStatus) {
            if (
              data.responseStatus.code === 202 ||
              data.responseStatus.code === 200
            ) {
              NpiToasterService.populateToaster(
                TOASTER_MESSAGE_TYPES.SUCCESS,
                data.message
              );
            } else if (data.responseStatus.code === 500) {
              NpiToasterService.populateToaster(
                TOASTER_MESSAGE_TYPES.ERROR,
                data.message
              );
            }
          }
          this.showRemoveTrainingPopup = false;
          this.loadRecords(cat, null, null, filter);
        });
    } else {
      const partIds = this.gridOptions.api.getSelectedRows().map((item) => {
        return encodeURIComponent(item[idKey]);
      });
      this.componentService
        .bulkUpdateByIds(dataObj, partIds)
        .subscribe((data) => {
          if (data && data.responseStatus) {
            if (
              data.responseStatus.code === 202 ||
              data.responseStatus.code === 200
            ) {
              NpiToasterService.populateToaster(
                TOASTER_MESSAGE_TYPES.SUCCESS,
                data.message
              );
            } else if (data.responseStatus.code === 500) {
              NpiToasterService.populateToaster(
                TOASTER_MESSAGE_TYPES.ERROR,
                data.message
              );
            }
          }
          this.showRemoveTrainingPopup = false;
          this.loadRecords(cat, null, null, filter);
        });
    }
  }

  public updateCommodity4Parts(cat = '', idKey: string): void {
    const filter = this.activeFilter;

    const dataObj = {
      ldCommodityId: this.selectedCommodityIdForUpdate['name'],
      // ldCommodityId: this.selectedCommodityIdForUpdate['commodityId']
    };

    this.showGrid = false;
    if (this.selectedAll) {
      this.componentService
        .bulkUpdateByFilter(dataObj, filter)
        .subscribe((data) => {
          if (data && data.responseStatus) {
            if (
              data.responseStatus.code === 202 ||
              data.responseStatus.code === 200
            ) {
              NpiToasterService.populateToaster(
                TOASTER_MESSAGE_TYPES.SUCCESS,
                data.message
              );
            } else if (data.responseStatus.code === 500) {
              NpiToasterService.populateToaster(
                TOASTER_MESSAGE_TYPES.ERROR,
                data.message
              );
            }
            this.showUpdateCommodity(false);
          }
          this.loadRecords(cat, null, null, filter);
        });
    } else {
      const partIds = this.gridOptions.api.getSelectedRows().map((item) => {
        return encodeURIComponent(item[idKey]);
      });
      this.componentService
        .bulkUpdateByIds(dataObj, partIds)
        .subscribe((data) => {
          if (data && data.responseStatus) {
            if (
              data.responseStatus.code === 202 ||
              data.responseStatus.code === 200
            ) {
              NpiToasterService.populateToaster(
                TOASTER_MESSAGE_TYPES.SUCCESS,
                data.message
              );
            } else if (data.responseStatus.code === 500) {
              NpiToasterService.populateToaster(
                TOASTER_MESSAGE_TYPES.ERROR,
                data.message
              );
            }
            this.showUpdateCommodity(false);
          }
          this.loadRecords(cat);
        });
    }
  }
  public openItemView(itemData: object, headerKey: string) {
    this.showViewItemPopup = true;
    this.showViewItemPopupSub.next(true)
    this.showViewItemGrid = false;
    let viewPaginationObject = this.viewPaginationObject;
    const ldNormalizedName = itemData[this.componentService.viewUniqueKey];
    if (typeof ldNormalizedName !== 'undefined') {
      let getViewPartsSubscription: Observable<any>;
      if (this.componentService.viewUniqueKey === 'spId') {
        getViewPartsSubscription = forkJoin([
          this.componentService.getHeaders(headerKey),
          this.componentService.getDetails(
            ldNormalizedName,
            viewPaginationObject
          ),
        ]);
      } else {
        getViewPartsSubscription = forkJoin([
          this.componentService.getHeaders(headerKey),
          this.componentService.getDetails(ldNormalizedName),
        ]);
      }
      getViewPartsSubscription.subscribe(
        ([headers, { pagingConfiguration, result }]) => {
          this.viewGridData.columnDefs = this.parseResponsesServiceHeaders(
            headers['result'].NO_SECTION.widgetHeaders
          );
          if (result instanceof Array) {
            this.viewGridData.rowData = result;
          }
          if (
            viewPaginationObject instanceof Object &&
            pagingConfiguration instanceof Object
          ) {
            if (viewPaginationObject.pullRecordCount) {
              viewPaginationObject.totalRecords =
                pagingConfiguration.totalRecords;
            }
            viewPaginationObject = {
              ...viewPaginationObject,
              ...pagingConfiguration,
            };
            viewPaginationObject.pageNumber = pagingConfiguration.pageNumber;
            viewPaginationObject.pageCount = Math.ceil(
              viewPaginationObject.totalRecords / pagingConfiguration.pageSize
            );
            this.viewPaginationObject = { ...viewPaginationObject };
          }
          this.showViewItemGrid = true;
        },
        (error) => {
          NpiToasterService.populateToaster(
            TOASTER_MESSAGE_TYPES.ERROR,
            `Unable to load data`
          );
          this.viewGridData.rowData = [];
          this.showViewItemGrid = true;
        }
      );
    }
  }
  public closeItemView() {
    this.showViewItemPopup = false;
  }
  public selectNoAliasRecords(event, cat = '') {
    this.isNoAliasEnabled = event.target.checked;
    if (event.target.checked) {
      this.loadRecords(
        cat,
        null,
        {
          pullRecordCount: true,
          pageNumber: 1,
        },
        this.activeFilter,
        true
      );
    } else {
      this.loadRecords(
        cat,
        null,
        {
          pullRecordCount: true,
          pageNumber: 1,
        },
        this.activeFilter,
        false
      );
    }
  }
  public resetDisableButtons(): void {
    this.disable = {
      add: false,
      save: true,
      discard: true,
      reset: true,
      delete: true,
      changeHistory: true,
      newRowDelete: true,
      approve: true,
      updateCommodity: true,
      runClassifier: true
    };
  }

  public onDropdownSelection($event) {
    Object.assign(this.selectedCommodityIdForUpdate, $event);
  }
  public onClickApply(cat: string, data: any): void {
    if (!isEmpty(data)) {
      this.isSelectAllEnabled = true;
      this.selectAllTitle = 'Select all records';
    } else {
      this.isSelectAllEnabled = false;
      this.selectAllTitle =
        'Please apply any one of the filter to enable "Select All" checkbox';
    }
    this.selectedAll = false;
    this.activeFilter = data;
    this.loadRecords(
      cat,
      null,
      {
        pullRecordCount: true,
        pageNumber: 1,
      },
      data,
      this.isNoAliasEnabled
    );
  }
  public resolveConflict(cat: string, partId: string): void {
    this.componentService
      .resolveConflicts(encodeURIComponent(partId))
      .subscribe(
        (res) => {
          NpiToasterService.populateToaster(
            TOASTER_MESSAGE_TYPES.SUCCESS,
            (res && res.message) || 'Conflict resolved successfully!'
          );
          this.viewPartsResolveConflictBtn = false;
          // this.viewParts = false;
          // this.loadRecords(cat);
        },
        (err) => {
          NpiToasterService.populateToaster(
            TOASTER_MESSAGE_TYPES.ERROR,
            'Unable to resolve Conflict!'
          );
        }
      );
  }
  getDeleteConfirmationPopUpObservable() {
    return this.showDeleteConfirmationPopUpSub.asObservable();
  }
}

export const CONTENT_SCOPES = {
  ENTITY_CLASSIFICATION: 'entityClassification',
  PART_CLASSIFICATION: 'partClassification',
  MPN_CLASSIFICATION: 'mpnClassification',
  VENDOR_SITES: 'vendorSites',
  MASTERDATA: {
    COMMODITIES: 'commodities',
    ENTITIES: 'entities',
    MPNS: 'mpns',
  },
};

export interface ICellValueChangedParams extends ICellRendererParams {
  newValue: any;
  oldValue: any;
}
export interface PAGINATION {
  totalRecords: number;
  pageNumber: number;
  pageCount: number;
  pageSize: number;
  pullRecordCount: boolean;
}
