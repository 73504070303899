import { DataStorageService } from './../../../common/data-storage.service';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { RfqConfigService } from '../../shared/rfq-config.service';
import { getMockTemplateFields } from '../../shared/mockData';
import { FieldActions } from '../../shared/components/field-actions/field-actions.component';
import { GridOptions } from '@ag-grid-community/core';
import { GridConfiguration } from 'app/modules/core/nxt-grid/gridconfiguration';
import { addNewTemplateFeild } from '../add-new-template-feild/add-new-template-feild.component';
import { NxtGridComponent } from '../../../core/nxt-grid/nxt-grid.component';
import { ToastService } from 'app/modules/srfq/shared/toaster.service';
import {
  TOASTER_MSG_SAVEFIELD_SUCCESS,
  TOASTER_MSG_SAVEFIELD_ERROR,
  TOASTER_MSG_TEMPLATE_FIELD_DELETE_SUCCESS,
  TOASTER_MSG_TEMPLATE_FIELD_DELETE_ERROR,
  TOASTER_MSG_TEMPLATE_SAVE_SUCCESS,
  TOASTER_MSG_TEMPLATE_SAVE_ERROR
} from 'app/modules/rfq-configurations/shared/constants';
import { Router } from '@angular/router';
import { Subscription, Observable, Subject } from 'rxjs';
import { BaseService } from './../../../common/base.service';
import * as _ from 'lodash';
@Component({
  selector: 'template-feilds',
  templateUrl: './template-feilds.component.html',
  styleUrls: ['./template-feilds.component.sass']
})
export class TemplateFeildsComponent implements OnInit, OnDestroy {
  constructor(
    private rfqConfigService: RfqConfigService,
    private toast: ToastService,
    private router: Router,
    public gridConfig: GridConfiguration,
    public baseService: BaseService,
    public dataStorageService: DataStorageService
  ) { }
  private ajaxRequests: object;
  templateId: number;
  private gridApi;
  public columnDefs = [];
  private columnDefsOld;
  public defaultColDef;
  private rows;
  private templateInfo;
  private templateName = '';
  private templateFeilds = [];
  public auditTrailPayload = []
  public rfqConfigSubscribe;
  public isAddingNewFeild = false;
  public isEditInterface = false;
  bool = new Subject<any>();
  public showSelectPreviewPopup = false;
  public gridOptions: GridOptions;
  public templatefieldData;
  templateIdSubscription: Subscription;
  private deleteTemplateHeaderId;
  private alertConfigObject: any;
  public alert: any;
  public popupConfiguration: any = {
    message: ''
  };
  public templates: any;
  private templateHeaderId = 0;

  OtherConfig = {
    noRowMessage: 'No fields added to the template',
    columnResize: true
  };

  customOptions = {
    isReorderingEnabled: true,
    deltaRowDataMode: true,
    animateRows: true
  };

  @ViewChild(addNewTemplateFeild, { static: false })
  addNewFieldComponent: addNewTemplateFeild;
  @ViewChild(NxtGridComponent, { static: false })
  nxtGridComponent: NxtGridComponent;
  _show = false;
  _headersLodaded = false;

  showSimulation = false;
  showAward = false;
  initialState = {
    showSimulation: false,
    showAward: false,
    templateFeilds: [],
    templateName: ''
  };

  actionType = '';

  hasConfirmed = false;

  disableNavigationPopup = true;

  public showPopup = false;
  ngOnInit() {
    this.alertConfigObject = {
      DELETE: {
        message: 'Do you want to delete?',
        successFunction: this.deleteField,
        discardFunction: this.cancelDeleteField
      },
      PUBLISH: {
        message:
          'You are trying to make changes to a published template.\nThis will create a new copy of the template and apply the changes.',
        successFunction: this.confirm,
        discardFunction: this.denied,
        configuration: {
          discardMsg: 'Cancel',
          acceptMsg: 'Confirm',
          popupCustomClass: 'height-145'
        }
      },
      UNSAVED: {
        message:
          'There are unsaved changes, Are you sure you want to navigate away from this page?',
        successFunction: this.cancelPopupOkClick,
        discardFunction: this.cancelPopupCancelClick
      },
      DRAFT: {
        message:
          'There is another template that is in DRAFT status, please consider updating it.',
        successFunction: this.closeCopyPopup,
        configuration: {
          type: 'alert'
        }
      },
      ADD: {
        message:
          'There are unsaved changes, Are you sure you want to navigate away from this page?',
        successFunction: this.addPopupOkClick
      },
      EDIT: {
        message:
          'There are unsaved changes, Are you sure you want to navigate away from this page?',
        successFunction: this.editPopupOkClick
      }
    };
    this.rfqConfigService.getTemplate().subscribe(template => {
      if (!template) {
        this.disableNavigationPopup = true;
        this.router.navigate([`app/templates`]);
        return;
      }
      this.templateName = template.name;
    });

    this.templateIdSubscription = this.rfqConfigService
      .getTemplateId()
      .subscribe(id => {
        if (!id) {
          this.router.navigate([`app/templates`]);
          return;
        }
        this.templateId = id;
        this.setupGrid();
      });
    this.rfqConfigService.getTemplateList().subscribe(rowData => {
      this.templates = rowData;
    });

    this.getGridHeaders();
  }

  addPopupOkClick() {
    this.createNewField();
  }
  editPopupOkClick() {
    this.editTemplate(this.templateHeaderId);
  }
  getGridHeaders() {
    const payload = {
      rfqScope: 'COMMODITY',
      widget: 'TEMPLATE_HEADER_LIST',
      entryType: 'COLUMN'
    };
    this.rfqConfigService.getGridHeaders(payload).subscribe(res => {
      if (
        res.responseStatus.code === 200 &&
        res.result.headers instanceof Array
      ) {
        res.result.headers.map(header => {
          header['headerName'] = header.displayName;
          header['field'] = header.dbColumnName;
        });
        this.columnDefs = res.result.headers;
        // Setting an action column for showing edit/delete buttons
        this.columnDefs[this.columnDefs.length] = {
          field: 'action',
          headerName: 'Actions',
          cellRendererFramework: FieldActions
        };
        // Which column to be draggable
        this.gridConfig.setPagination(false);
        this.columnDefs = this.columnDefs.slice(1); // because we don't want to show position column
        this.columnDefs[0]['rowDrag'] = true;
        this.gridConfig.setHeaderObject(this.columnDefs);
        this._headersLodaded = true;
      }
    });
  }
  setupGrid() {
    this.gridOptions = ({} as GridOptions);
    this.gridOptions.context = {
      componentParent: this
    };
    this.defaultColDef = { width: 200 };
    this.getTemplateFields();
  }
  setCheckboxes() {
    this.showSimulation =
      (this.templateInfo &&
        this.templateInfo.additionalInfo &&
        this.templateInfo.additionalInfo.showSimulation) ||
      false;
    this.showAward =
      (this.templateInfo &&
        this.templateInfo.additionalInfo &&
        this.templateInfo.additionalInfo.showAward) ||
      false;
  }

  setInitialState(data) {
    this.initialState.showAward = data['additionalInfo']['showAward'];
    this.initialState.showSimulation = data['additionalInfo']['showSimulation'];
    this.initialState.templateFeilds = data['templateHeaderList'].slice();
    this.initialState.templateName = data['name'];
  }

  hasOrderChanged() {
    const initialFieldOrderString = this.getFieldOrderString(
      this.initialState.templateFeilds
    );
    const modyfiedFieldOrderString = this.getFieldOrderString(
      this.nxtGridComponent && this.nxtGridComponent.getUpdatedRowOrder()
    );
    return modyfiedFieldOrderString !== initialFieldOrderString;
  }

  getFieldOrderString(fields = []) {
    let fieldOrderString = '';
    for (let iterator = 0; iterator < fields.length; iterator++) {
      fieldOrderString += fields[iterator]['metaInfo']['headerOrder'];
    }
    return fieldOrderString;
  }

  getTemplateFields() {
    this._show = false;
    this.rfqConfigSubscribe = this.rfqConfigService
      .getTemplateDetailsById(this.templateId)
      .subscribe(
        res => {
          if (res && !res.error) {
            this.templateFeilds =
              (res && res.templateHeaderList) || getMockTemplateFields();
            this.templateFeilds.sort(function (a, b) {
              return (
                a['metaInfo']['headerOrder'] - b['metaInfo']['headerOrder']
              );
            });
            this.templateInfo = res || {};
            this.templateName = this.templateInfo.name || '';
            this.setCheckboxes();
            this.gridOptions.rowData =
              (res && res.templateHeaderList) || getMockTemplateFields();
            this.gridConfig.setRowData(this.gridOptions.rowData);
            this._show = true;
            this.configureRowNodeId();
            this.configureGridOptions();
            this.setInitialState(res);
          }
        },
        err => {
          console.log(err);
        }
      );
  }

  configureGridOptions() {
    this.gridConfig.setPagination(false);
    this.gridConfig.setSuppressRowClickSelection(false);
    this.gridConfig.setRowSelection('');
    this.gridConfig.setPaginationWithGotoPage(false);
  }

  configureRowNodeId() {
    this.gridConfig.setRowNodeId(rowNodeData => {
      if (rowNodeData instanceof Object) {
        return rowNodeData['metaInfo']['headerOrder'];
      }
    });
    this.gridConfig.setHideCheckBoxForRowSelection(() => true);
  }
  action(type, tmplHeaderId = 0) {
    this.templateHeaderId = tmplHeaderId;
    this.actionType = type;
    if (type === 'edit') {
      if (!this.disable()) {
        this.showPopupBox('EDIT');
        return;
      }
      this.editTemplate(tmplHeaderId);
    }

    if (type === 'delete') {
      this.deleteTemplateHeaderId = tmplHeaderId;
      if (this.checkCopyOrPublished()) { return; }
      this.showPopupBox('DELETE');
    }
  }

  editTemplate(tmplHeaderId) {
    this.rfqConfigService.editTemplateField(tmplHeaderId).subscribe(res => {
      if (res && res.custTmplHeaderId) {
        res.isEditingField = true;
        this.templatefieldData = res;
        this.dataStorageService.permissionsField = JSON.parse(JSON.stringify(res.permissions))
        this.isAddingNewFeild = true;
        this.isEditInterface = true;
      }
    });
  }
  confirm() {
    this.hasConfirmed = true;
    if (this.actionType === 'delete') {
      this.showPopup = false;
      this.showPopupBox('DELETE');
      return;
    } else {
      this.addNewField();
    }
  }

  denied() {
    this.hasConfirmed = false;
  }

  deleteField() {
    this.actionType = '';
    this.rfqConfigService
      .deleteTemplateField(this.deleteTemplateHeaderId, this.templateId)
      .subscribe(res => {
        if (typeof res === 'number') {
          this.toast.populateToaster(
            'success',
            TOASTER_MSG_TEMPLATE_FIELD_DELETE_SUCCESS
          );
          this.rfqConfigService.setTemplateId(Number(res));
          this.getTemplateFields();
          this.deleteTemplateHeaderId = undefined;
        } else {
          this.toast.populateToaster(
            'error',
            TOASTER_MSG_TEMPLATE_FIELD_DELETE_ERROR
          );
        }
      });
  }

  cancelDeleteField() {
    this.deleteTemplateHeaderId = undefined;
  }
  clickAddNew() {
    if (this.checkCopyOrPublished()) { return; }
    this.addNewField();
  }
  addNewField() {
    if (!this.disable()) {
      this.showPopupBox('ADD');
      return;
    }
    this.createNewField();
  }

  createNewField() {
    this.isAddingNewFeild = true;
    this.isEditInterface = false;
    this.templatefieldData = undefined;
  }

  cancelNewFieldFormData() {
    this.isAddingNewFeild = false;
    this.isEditInterface = false;
    this.templatefieldData = undefined;
    this.hasConfirmed = false;
    this.getTemplateFields();
  }

  saveNewFieldFormData() {
    let saveSubscription;
    const newFieldData = this.addNewFieldComponent.getNewFieldFormData();
    if (!newFieldData) {
      return;
    }
    if (newFieldData.metaInfo && !newFieldData.metaInfo.derivedCol) {
      newFieldData.metaInfo.joinSrcColumnForDerviation = '';
      newFieldData.metaInfo.joinTrgtColumnForDerviation = '';
      newFieldData.metaInfo.queryToDerive = '';
    }
    if (this.addNewFieldComponent.isEditingField) {
      this.changesForAudit(newFieldData, this.templatefieldData)
      saveSubscription = this.rfqConfigService.updateTemplateField(
        newFieldData
      );
    } else {
      newFieldData.metaInfo['headerOrder'] = !this.templateFeilds.length
        ? 1
        : this.templateFeilds[this.templateFeilds.length - 1]['metaInfo'][
        'headerOrder'
        ] + 1;
      saveSubscription = this.rfqConfigService.saveNewCustomField(newFieldData);
    }

    saveSubscription.subscribe(res => {
      if (res.responseStatus && res.responseStatus.code === 200) {
        this.isAddingNewFeild = false;
        if (this.addNewFieldComponent.isEditingField) {
          this.saveAuditDetails()
        }
        this.rfqConfigService.setTemplateId(Number(res.result));
        this.getTemplateFields();
        this.toast.populateToaster('success', TOASTER_MSG_SAVEFIELD_SUCCESS);
      } else {
        this.toast.populateToaster('error', TOASTER_MSG_SAVEFIELD_ERROR);
      }
    });
  }
  saveAuditDetails() {
    this.rfqConfigService.saveAudits(this.auditTrailPayload, this.templateHeaderId, this.templateId).subscribe(data => {
      if (data) {
        this.auditTrailPayload = [];
        this.dataStorageService.permissionsField = null;
      }
    })
  }
  changesForAudit(newFields, templateFieldsData) {
    if (newFields && templateFieldsData) {
      const getDifference = (a, b) => Object.entries(b).filter(([key, val]) => key in a && a[key] !== val);
      if (newFields.hasOwnProperty('metaInfo') && templateFieldsData.hasOwnProperty('metaInfo') && getDifference(newFields.metaInfo, templateFieldsData.metaInfo).length > 0) {
        let res = getDifference(newFields.metaInfo, templateFieldsData.metaInfo);
        res.forEach(ele => {
          if (ele.hasOwnProperty('length') && ele.length > 1 && !(ele[1] === null && newFields.metaInfo[ele[0]] === "")) {
            this.auditTrailPayload.push({
              attributeName: String(ele[0]),
              actualAttributeValue: String(ele[1]),
              modifiedAttributeValue: String(newFields.metaInfo[ele[0]])
            })
          }
        })
      }
      if (newFields.hasOwnProperty('formattingOptions') &&
        newFields.formattingOptions.hasOwnProperty('customer') &&
        templateFieldsData.hasOwnProperty('formattingOptions') &&
        templateFieldsData.formattingOptions.hasOwnProperty('customer') &&
        getDifference(newFields.formattingOptions.customer, templateFieldsData.formattingOptions.customer).length > 0) {
        let res = getDifference(newFields.formattingOptions.customer, templateFieldsData.formattingOptions.customer);
        res.forEach(ele => {
          if (ele.hasOwnProperty('length') && ele.length > 1 && ele[0] !== 'selectedValues') {
            if (!(ele[1] === null && newFields.formattingOptions.customer[ele[0]] === "")) {
              this.auditTrailPayload.push({
                attributeName: String(ele[0]),
                actualAttributeValue: String(ele[1]),
                modifiedAttributeValue: String(newFields.formattingOptions.customer[ele[0]])
              })
            }
          }
        })
        if (newFields.formattingOptions.customer.hasOwnProperty('selectedValues') &&
          templateFieldsData.formattingOptions.customer.hasOwnProperty('selectedValues') &&
          getDifference(newFields.formattingOptions.customer.selectedValues, templateFieldsData.formattingOptions.customer.selectedValues).length > 0) {
          if (!(templateFieldsData.formattingOptions.customer.selectedValues === null && newFields.formattingOptions.customer.selectedValues === "")) {
            this.auditTrailPayload.push({
              attributeName: String('selectedValues'),
              actualAttributeValue: String(templateFieldsData.formattingOptions.customer.selectedValues),
              modifiedAttributeValue: String(newFields.formattingOptions.customer.selectedValues)
            })
          }
        }
      }
      if (newFields.hasOwnProperty('formattingOptions') &&
        newFields.formattingOptions.hasOwnProperty('supplier') &&
        templateFieldsData.hasOwnProperty('formattingOptions') &&
        templateFieldsData.formattingOptions.hasOwnProperty('supplier') &&
        getDifference(newFields.formattingOptions.supplier, templateFieldsData.formattingOptions.supplier).length > 0) {
        let res = getDifference(newFields.formattingOptions.supplier, templateFieldsData.formattingOptions.supplier);
        res.forEach(ele => {
          if (ele.hasOwnProperty('length') && ele.length > 1 && ele[0] !== 'selectedValues') {
            if (!(ele[1] === null && newFields.formattingOptions.supplier[ele[0]] === "")) {
              this.auditTrailPayload.push({
                attributeName: String(ele[0]),
                actualAttributeValue: String(ele[1]),
                modifiedAttributeValue: String(newFields.formattingOptions.supplier[ele[0]])
              })
            }
          }
        })
        if (newFields.formattingOptions.supplier.hasOwnProperty('selectedValues') &&
          templateFieldsData.formattingOptions.supplier.hasOwnProperty('selectedValues') &&
          getDifference(newFields.formattingOptions.supplier.selectedValues, templateFieldsData.formattingOptions.supplier.selectedValues).length > 0) {
          if (!(templateFieldsData.formattingOptions.supplier.selectedValues === null && newFields.formattingOptions.supplier.selectedValues === "")) {
            this.auditTrailPayload.push({
              attributeName: String('selectedValues'),
              actualAttributeValue: String(templateFieldsData.formattingOptions.supplier.selectedValues),
              modifiedAttributeValue: String(newFields.formattingOptions.supplier.selectedValues)
            })
          }
        }
      }
      if (newFields.hasOwnProperty('calculation') &&
        templateFieldsData.hasOwnProperty('calculation') &&
        getDifference(newFields.calculation, templateFieldsData.calculation).length > 0) {
        let res = getDifference(newFields.calculation, templateFieldsData.calculation);
        res.forEach(ele => {
          if (ele.hasOwnProperty('length') && ele.length > 1 && !(ele[1] === null && newFields.calculation[ele[0]] === "")) {
            this.auditTrailPayload.push({
              attributeName: String(ele[0]),
              actualAttributeValue: String(ele[1]),
              modifiedAttributeValue: String(newFields.calculation[ele[0]])
            })
          }
        })
      }
      if (this.dataStorageService.permissionsField &&
        this.dataStorageService.permissionsField.hasOwnProperty('customerPrivileges') &&
        templateFieldsData.hasOwnProperty('permissions') &&
        templateFieldsData.permissions.hasOwnProperty('customerPrivileges') &&
        getDifference(this.dataStorageService.permissionsField.customerPrivileges, templateFieldsData.permissions.customerPrivileges).length > 0) {
        let res = getDifference(this.dataStorageService.permissionsField.customerPrivileges, templateFieldsData.permissions.customerPrivileges);
        res.forEach(ele => {
          if (ele.hasOwnProperty('length') && ele.length > 1 && !(ele[1] === null && this.dataStorageService.permissionsField.customerPrivileges[ele[0]] === "")) {
            this.auditTrailPayload.push({
              attributeName: String(ele[0]),
              actualAttributeValue: String(ele[1]),
              modifiedAttributeValue: String(this.dataStorageService.permissionsField.customerPrivileges[ele[0]])
            })
          }
        })
      }
      if (this.dataStorageService.permissionsField &&
        this.dataStorageService.permissionsField.hasOwnProperty('supplierPrivileges') &&
        templateFieldsData.hasOwnProperty('permissions') &&
        templateFieldsData.permissions.hasOwnProperty('supplierPrivileges') &&
        getDifference(this.dataStorageService.permissionsField.supplierPrivileges, templateFieldsData.permissions.supplierPrivileges).length > 0) {
        let res = getDifference(this.dataStorageService.permissionsField.supplierPrivileges, templateFieldsData.permissions.supplierPrivileges);
        res.forEach(ele => {
          if (ele.hasOwnProperty('length') && ele.length > 1 &&
            !(ele[1] === null && this.dataStorageService.permissionsField.supplierPrivileges[ele[0]] === "")) {
            this.auditTrailPayload.push({
              attributeName: String(ele[0]),
              actualAttributeValue: String(ele[1]),
              modifiedAttributeValue: String(this.dataStorageService.permissionsField.supplierPrivileges[ele[0]])
            })
          }
        })
      }
      if (newFields.hasOwnProperty('deviations') &&
        templateFieldsData.hasOwnProperty('deviations') &&
        getDifference(newFields.deviations, templateFieldsData.deviations).length > 0) {
        let res = getDifference(newFields.deviations, templateFieldsData.deviations);
        res.forEach(ele => {
          if (ele.hasOwnProperty('length') && ele.length > 1 &&
            !(ele[1] === null && newFields.deviations[ele[0]] === "")) {
            this.auditTrailPayload.push({
              attributeName: String(ele[0]),
              actualAttributeValue: String(ele[1]),
              modifiedAttributeValue: String(newFields.deviations[ele[0]])
            })
          }
        })
      }
      if (newFields.hasOwnProperty('validations') &&
        newFields.validations.hasOwnProperty('length')) {
        for (let index = 0; index < newFields.validations.length; index++) {
          if ((newFields.validations[index] &&
            templateFieldsData.validations[index] &&
            getDifference(newFields.validations[index], templateFieldsData.validations[index]) &&
            getDifference(newFields.validations[index], templateFieldsData.validations[index]).length > 0)) {
            let res = getDifference(newFields.validations[index], templateFieldsData.validations[index]);
            res.forEach(ele => {
              if (ele.hasOwnProperty('length') && ele.length > 1 &&
                !(ele[1] === null && newFields.validations[index][ele[0]] === "")) {
                this.auditTrailPayload.push({
                  attributeName: String(ele[0]),
                  actualAttributeValue: String(ele[1]),
                  modifiedAttributeValue: String(newFields.validations[index][ele[0]])
                })
              }
            })
          }
        }
      }
    }
  }
  saveTemplate() {
    if (this.rfqConfigService.getTemplateNameError()) {
      return;
    }
    this.templateFeilds = this.nxtGridComponent.getUpdatedRowOrder();
    this.templateFeilds.forEach(function (data, index) {
      data.metaInfo.headerOrder = index + 1;
    });
    this.templateInfo.templateHeaderList = this.templateFeilds;
    this.templateInfo.name = this.templateName;
    this.templateInfo.additionalInfo.showSimulation = this.showSimulation;
    this.templateInfo.additionalInfo.showAward = this.showAward;
    this.rfqConfigService.saveTemplate(this.templateInfo).subscribe(res => {
      if (!res.error) {
        this.toast.populateToaster(
          'success',
          TOASTER_MSG_TEMPLATE_SAVE_SUCCESS
        );
        this.getTemplateFields();
        this.setInitialState(res);
      } else {
        this.toast.populateToaster('error', TOASTER_MSG_TEMPLATE_SAVE_ERROR);
      }
    });
  }

  viewPreview() {
    this.showSelectPreviewPopup = true;
  }

  onNewFieldAdded(event) {
    if (event.isNewFieldAdded || event.isFieldUpdated) {
      this.isAddingNewFeild = false;
      this.getTemplateFields();
    }
  }

  closePreviewPopup() {
    this.showSelectPreviewPopup = false;
  }

  Navigate() {
    this.router.navigate(['app/templates']);
  }

  ngOnDestroy() {
    this.rfqConfigSubscribe && this.rfqConfigSubscribe.unsubscribe();
    this.templateIdSubscription.unsubscribe();
  }

  canDeactivate() {
    if (!this.templateId) {
      return true;
    }
    if (this.isAddingNewFeild && !this.isEditInterface) {
      return this.checkFieldFormChanged();
    } else if (this.isAddingNewFeild && this.isEditInterface) {
      return this.editFormDataChanged();
    } else {
      return this.checkTemplateFieldsInterfaceChanged();
    }
  }

  checkTemplateFieldsInterfaceChanged() {
    if (
      this.showSimulation !== this.initialState.showSimulation ||
      this.showAward !== this.initialState.showAward ||
      (this.templateName &&
        this.templateName !== this.initialState.templateName) ||
      this.hasOrderChanged()
    ) {
      this.showPopupBox('UNSAVED');
      this.baseService.setAppLoader(false);
    } else {
      return true;
    }
    return this.bool.asObservable();
  }
  disable() {
    if (
      this.showSimulation !== this.initialState.showSimulation ||
      this.showAward !== this.initialState.showAward ||
      (this.templateName &&
        this.templateName !== this.initialState.templateName) ||
      this.hasOrderChanged()
    ) {
      return false;
    } else {
      return true;
    }
  }
  checkFieldFormChanged() {
    let noChanges = true;
    const newField = this.addNewFieldComponent.customFieldToBeAdded;
    const oldField = this.addNewFieldComponent.initialFormData;
    const keys = Object.keys(newField);
    for (let i = 0; i < keys.length; i++) {
      if (!_.isEqual(newField[keys[i]], oldField[keys[i]])) {
        if (keys[i] === 'metaInfo' && newField.metaInfo.datatype !== 'TEXT') {
          noChanges = false;
        }
        if (
          keys[i] === 'calculation' &&
          !(
            newField.calculation.calculationType === 'BLANK' &&
            newField.calculation.formula === ''
          )
        ) {
          noChanges = false;
        }
        if (!['metaInfo', 'calculation'].includes(keys[i])) {
          noChanges = false;
        }
      }
    }
    if (!noChanges) {
      this.showPopupBox('UNSAVED');
      this.baseService.setAppLoader(false);
    } else {
      return true;
    }
    return this.bool.asObservable();
  }
  editFormDataChanged() {
    let noChanges = true;
    const newField = this.addNewFieldComponent.customFieldToBeAdded;
    const oldField = this.addNewFieldComponent.initialFormData;
    const keys = Object.keys(newField);
    for (let i = 0; i < keys.length; i++) {
      if (!_.isEqual(newField[keys[i]], oldField[keys[i]])) {
        noChanges = false;
      }
    }
    if (!noChanges) {
      this.showPopupBox('UNSAVED');
      this.baseService.setAppLoader(false);
    } else {
      return true;
    }
    return this.bool.asObservable();
  }

  cancelPopupOkClick() {
    this.bool.next(true);
  }

  cancelPopupCancelClick() {
    this.bool.next(false);
  }

  checkCopyOrPublished() {
    let status = false;
    // check if already draft exists of the published template
    if (this.templateInfo.status === 'PUBLISHED') {
      status = true;
      const draftTemplate = this.templates.find(
        template => template.parentTemplateId === this.templateInfo.templateId
      );
      if (
        draftTemplate &&
        draftTemplate.status &&
        draftTemplate.status === 'DRAFT'
      ) {
        this.showPopupBox('DRAFT');
      } else {
        this.showPopupBox('PUBLISH');
      }
    }
    return status;
  }

  closeCopyPopup() {
    return;
  }

  getAlertConfiguration(alert) {
    const configure = alert.configuration ? alert.configuration : {};
    configure.message = alert.message;
    return configure;
  }

  showPopupBox(type) {
    this.showPopup = true;
    this.alert = this.alertConfigObject[type];
    this.popupConfiguration = this.getAlertConfiguration(this.alert);
  }

  yesClicked() {
    this.showPopup = false;
    this.alert.successFunction.call(this);
  }

  noClicked() {
    this.showPopup = false;
    if (this.alert.discardFunction) {
      this.alert.discardFunction.call(this);
    }
  }
}
