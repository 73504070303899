import { Component } from '@angular/core';

@Component({
	moduleId: module.id,
	selector: 'legend-kpi',
	templateUrl: './legend-kpi.component.html',
	styleUrls: ['./legend-kpi.component.sass']
})

export class LegendKpiComponent {

	constructor() { }

}