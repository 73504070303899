import { Component, forwardRef, Input, OnChanges, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { cityListConfiguration, countryListConfiguration, siteListConfiguration, stateListConfiguration } from '../save-event.constants';

export interface LocationDetails {
  country: string
  state: string
  city: string
  siteImpacted: any[]
  longitude: number
  latitude: number
  region: string
  eventId: string
  locationId: string
}

@Component({
  selector: 'cprt-affected-sites',
  templateUrl: './affected-sites.component.html',
  styleUrls: ['./affected-sites.component.sass'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AffectedSitesComponent),
    multi: true,
  }]
})
export class AffectedSitesComponent implements OnInit, OnChanges, ControlValueAccessor {

  _defaultSelection: any[] = []
  get defaultSelection() {
    return this._defaultSelection
  }
  @Input() set defaultSelection(val) {
    this._defaultSelection = val
  }

  searchLocations = []

  propagateChange = (_: any) => { }
  constructor() { }

  ngOnInit() { }

  ngOnChanges() {
    this.initSearchLocations()
    this.getLocationDetails(this.defaultSelection)
  }

  getLocationDetails(data) {
    const localArray = []
    data.forEach((locationData) => {
      // console.log(locationData)
      const location = JSON.parse(JSON.stringify(this.getLocationObject()))
      location.properties = { ...locationData }
      location.mandatoryFilledCheck = true

      this.setSelectedValue(location.countryObject.config.filterConfig, locationData.country)
      this.setSelectedValue(location.stateObject.config.filterConfig, locationData.state)
      this.setSelectedValue(location.cityObject.config.filterConfig, locationData.city)
      if (JSON.stringify(locationData.siteImpacted) === JSON.stringify(['ALL'])) {
        location.siteObject.config.selectAllDefault = true
      } else {
        this.setSelectedValue(location.siteObject.config.filterConfig, locationData.siteImpacted)
      }

      this.setDefaultParameter(location.stateObject.config, {
        country: locationData.country,
      })
      this.setDefaultParameter(location.cityObject.config, {
        country: locationData.state,
      })
      this.setDefaultParameter(location.siteObject.config, {
        country: locationData.country,
        state: locationData.state,
        city: locationData.city
      })
      localArray.push(location)
    })
    if (localArray.length) {
      this.searchLocations = Object.assign([], localArray)
    }
  }

  setSelectedValue(filterConfig, value) {
    filterConfig.defaultItemSelection = true
    filterConfig.defaultSelectedItem = value
  }

  setDefaultParameter(config, paramValue) {
    config.defaultParameter = paramValue
    config.isDisable = true
  }

  initLocationDetails(location) {
    location.country = null
    location.state = null
    location.city = null
    location.siteImpacted = []
    return location
  }

  initSearchLocations() {
    this.searchLocations = []
    const location = JSON.parse(JSON.stringify(this.getLocationObject()))
    this.searchLocations.push(location)
  }

  getLocationObject() {
    const locObj = {
      countryObject: {
        name: 'country',
        config: JSON.parse(JSON.stringify(countryListConfiguration)),
        resetValues: this.generateRandomKey()
      },
      stateObject: {
        name: 'state',
        config: JSON.parse(JSON.stringify(stateListConfiguration)),
        resetValues: this.generateRandomKey()
      },
      cityObject: {
        name: 'city',
        config: JSON.parse(JSON.stringify(cityListConfiguration)),
        resetValues: this.generateRandomKey()
      },
      siteObject: {
        name: 'siteImpacted',
        config: JSON.parse(JSON.stringify(siteListConfiguration)),
        resetValues: this.generateRandomKey()
      },
      properties: this.initLocationDetails({} as LocationDetails),
      mandatoryFilledCheck: false,
    }
    return locObj
  }

  generateRandomKey() {
    const crypto = window.crypto
    const array = new Uint32Array(1)
    return crypto.getRandomValues(array)[0]
  }

  addLocation(rowIndex, event) {
    event.stopPropagation()
    this.searchLocations.push(JSON.parse(JSON.stringify(this.getLocationObject())))
    this.mandatoryFilledCheck()
  }

  deleteLocation(rowIndex, selectedLocation, event) {
    event.stopPropagation()
    this.searchLocations.splice(rowIndex, 1)
    this.mandatoryFilledCheck()
  }

  onClearAllLocations() {
    this.searchLocations = []
    this.initSearchLocations()
    this.mandatoryFilledCheck()
  }

  onLocationPropSelect(params, locationRow?, locationIndex?) {
    if (locationRow && locationIndex !== undefined) {
      const selectedValues = params.selectedItem
        ? (params.selectedItem.val || null)
        : params.selectedItems
          ? params.selectedItems
          : null
      if (locationRow.properties.hasOwnProperty(params.type)) {
        locationRow.properties[params.type] = selectedValues
      }
      switch (params.type) {
        case 'country':
          locationRow.stateObject.config.defaultParameter = {
            country: selectedValues,
          }
          locationRow.stateObject.config.isDisable = false
          locationRow.stateObject.resetValues = this.generateRandomKey()
          locationRow.cityObject.resetValues = this.generateRandomKey()
          locationRow.siteObject.resetValues = this.generateRandomKey()
          break
        case 'state':
          locationRow.cityObject.config.defaultParameter = {
            state: selectedValues,
          }
          locationRow.cityObject.config.isDisable = false
          locationRow.cityObject.resetValues = this.generateRandomKey()
          locationRow.siteObject.resetValues = this.generateRandomKey()
          break
        case 'city':
          locationRow.siteObject.config.defaultParameter = {
            country: locationRow.properties.country,
            state: locationRow.properties.state,
            city: locationRow.properties.city,
          }
          locationRow.siteObject.config.isDisable = false
          locationRow.siteObject.resetValues = this.generateRandomKey()
          break
      }
      this.searchLocations[locationIndex].properties = JSON.parse(
        JSON.stringify(locationRow.properties)
      )
      this.searchLocations[locationIndex].mandatoryFilledCheck
        = !!locationRow.properties.country && !!locationRow.properties.city && !!locationRow.properties.state && locationRow.properties.siteImpacted.length
          ? true
          : false
      this.mandatoryFilledCheck()
    }
  }

  mandatoryFilledCheck() {
    const validLocations = this.searchLocations.filter(location => location.mandatoryFilledCheck).map(location => location.properties)
    // console.log(validLocations)
    this.propagateChange(validLocations)
  }

  writeValue(value: any) {
    return value
  }

  registerOnChange(fn) {
    this.propagateChange = fn
  }

  registerOnTouched() { }

}
