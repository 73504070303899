import { Component, OnInit, Input } from '@angular/core';
import { BotQuestionService } from './shared/bot-question.service';
import { Router } from '@angular/router';
import { BaseService } from 'app/modules/common/base.service';
import { BOTHelpService } from 'app/modules/base-app/bot/help/help.service';
import { ScopeDataService } from 'app/modules/common/scope.data.service';

@Component({
  selector: 'bot-question',
  templateUrl: 'bot-question.component.html',
  styleUrls: ['./bot-question.component.sass'],
  providers: [BotQuestionService]
})

export class BotQuestionComponent implements OnInit {
  @Input() parameters: any;

  question: string;
  options: Array<string> = [];
  constructor(
    private baseService: BaseService,
    private botHelpService: BOTHelpService,
    private scopeDataService: ScopeDataService,
    private botQuestionService: BotQuestionService,
    private router: Router) { }

  ngOnInit() {
    this.question = this.parameters.question;
    this.options = this.parameters.options;
  }
  onOptionClick(option){

  }

  onSelectOption(target: any) {

    /* switch (target['target']) {
			case ("opportunities"):
				let message = {
					"CHAT_CONVERSATION_ID": "1a5918bd-b334-4747-8d71-627e1dfaeb79",
					"CHAT_FROM": "B",
					"CREATED_DATE": new Date().getTime(),
					"MESSAGE": "Here are the Opportunities / Risks.",
					"response": ""
				};
				this.scopeDataService.clearApplicationScope();
				this.scopeDataService.setFocus("Commodity");
				this.botHelpService.setUpdateHistory(message);
				this.router.navigate(['/app/' + target['target']]);
				break;
			case ("srfx/create/schedule"):
				let rfqmessage = {
					"CHAT_CONVERSATION_ID": "1a5918bd-b334-4747-8d71-627e1dfaeb79",
					"CHAT_FROM": "B",
					"CREATED_DATE": new Date().getTime(),
					"MESSAGE": "RFQ Draft for Mechanical is ready.",
					"response": ""
				};
				this.botHelpService.setUpdateHistory(rfqmessage);
				this.baseService.setCreateRFQFilter("Mechanical");
				this.router.navigate(['/app/srfx/create/schedule']);
				break;
		} */


  }
}
