import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { ApiServiceProvider } from 'app/api-service';
import { BehaviorSubject } from 'rxjs';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService';

@Injectable()
export class LoginService {
  public loginVal = new BehaviorSubject<any>('');
  constructor(
    private customerConfigurationService: CustomerConfigurationService,
    private scopeDataService: ScopeDataService
  ) {}

  public setloginval(field) {
    this.loginVal.next(field);
  }
  public getloginval() {
    return this.loginVal.asObservable();
  }
  public setupHomePage() {
    let defaultScope: any;
    const scopeList = this.customerConfigurationService.getScopeList();
    if (scopeList) {
      defaultScope = scopeList.filter(val => val.defaultValue);
      if (defaultScope) {
        defaultScope = defaultScope[0].view;
        if (defaultScope) {
          this.scopeDataService.setFocus(defaultScope);
        }
      }
    }
    let homepage = '';
    if (
      this.customerConfigurationService.getConfData(defaultScope) &&
      this.customerConfigurationService.getConfData(defaultScope).features
    ) {
      homepage =
        this.customerConfigurationService.getConfData(defaultScope).features
          .homePage || '';
    }
    return homepage;
  }
  public setupUserConfiguration(val, responseData) {
    if (val.result && val.result.configurationData) {
      const data = val.result.configurationData;
      Object.getOwnPropertyNames(data).forEach(element => {
        const homepage = data[element]['features']['homePage'];
        const leftNavViews = data[element]['leftNavbarFeatures']
          .filter(
            item =>
              item['view'] && item['level'] === 0 && item['view'] != 'null'
          )
          .map(item => item['view']);
        if (!leftNavViews.includes(homepage)) {
          data[element]['features']['homePage'] = leftNavViews[0];
        }
      });
      // if (this.responseData['reponseStatus'] !== undefined) {
      // 	if (this.responseData['reponseStatus']['code'] == 200) {
      // 		if (this.responseData.userInfo) {
      // 			this.superAdmin = this.responseData.userInfo.superAdmin;

      // 		}
      // 	}
      // }
      Object.getOwnPropertyNames(data).forEach(element => {
        if (
          !responseData.userInfo.superAdmin &&
          !responseData.userInfo.tenantAdmin &&
          !responseData.userInfo.groupNames.includes('NPI Part Edit Access') &&
          !responseData.userInfo.groupNames.includes('NPI Source Edit Access')
        ) {
          if (data[element]['subTabFeatures']) {
            data[element]['subTabFeatures'].forEach(x => {
              if (x['NPI Parts Catalog'] instanceof Object) {
                x['NPI Parts Catalog'].forEach((i, index) => {
                  if (i.view === 'checkedoutparts') {
                    x['NPI Parts Catalog'].splice(index, 1);
                  }
                });
              }
            });
          }
        }
        if (responseData.userInfo.superAdmin === false) {
          if (data[element]['mainTabFeatures']) {
            data[element]['mainTabFeatures'].forEach(item => {
              if (
                Object.getOwnPropertyNames(item)[0].toLowerCase() === 'reports'
              ) {
                const reporttabs: any[] = item.Reports;
                reporttabs.forEach(tabdata => {
                  if (tabdata.view == 'configurablereports') {
                    item.Reports.splice(item.Reports.indexOf(tabdata), 1);
                  }
                });
                if (reporttabs.length == 0) {
                  data[element]['leftNavbarFeatures'].forEach(leftnavtabs => {
                    if (leftnavtabs.view.toLowerCase() === 'reports') {
                      data[element]['leftNavbarFeatures'].splice(
                        data[element]['leftNavbarFeatures'].indexOf(
                          leftnavtabs
                        ),
                        1
                      );
                    }
                  });
                }
              }
            });
          }
        }
      });

      // Object.getOwnPropertyNames(data).forEach(element => {
      //   let spendanalyticsData;
      //   let spendData;
      //   let spendanalyticsObject;
      //   if (
      //     data[element]['viewByData'] &&
      //     data[element]['viewByData'] instanceof Array &&
      //     data[element]['viewByData'].length > 0
      //   ) {
      //     spendanalyticsObject = data[element]['viewByData'].find(
      //       item => item['spendanalytics'] instanceof Object
      //     );
      //     spendanalyticsData = spendanalyticsObject['spendanalytics'];
      //     if (spendanalyticsData && spendanalyticsData['spend']) {
      //       spendData = spendanalyticsData['spend'];
      //     }
      //   }
      //   if (spendData) {
      //     Object.getOwnPropertyNames(spendData).forEach(spendKey => {
      //       if (spendData[spendKey] && spendData[spendKey][0]) {
      //         spendData[spendKey][0]['isdefault'] = true;
      //       }
      //     });
      //   }
      // });
    }
    return val;
  }
}
