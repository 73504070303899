import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { GridDropdown } from './shared/grid-dropdown.model';
import { GridDropdownService } from './shared/grid-dropdown.service';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { ObjectUtils } from '../../../common/utills/ObjectUtils.service';
import { StringUtils } from './shared/string-utils';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';

@Component({
  moduleId: module.id,
  selector: 'child-cell',
  templateUrl: 'grid-dropdown.component.html',
  styleUrls: ['./grid-dropdown.component.sass'],
  providers: [GridDropdownService],
})
export class GridDropdownComponent
  implements ICellEditorAngularComp, AfterViewInit {
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  isListType = false;
  inlineSearch = false;
  searchOnInit = false;
  displayValue = '';
  fillColumn = '';
  defaultPayload = {};
  showLoader = false;
  multiselect = false;
  loaderConfig = {
    backdropBackgroundColour: '#f5f7f7',
    fullScreenBackdrop: 'true',
    primaryColour: '#1C4E84',
    secondaryColour: '#1C4E84',
    tertiaryColour: '#1C4E84',
  };
  dropdownItems = [];
  selectedItems = [];
  itemsLoading = false;
  selectedItem: any = {};
  searchText = '';
  data = [];
  params: any;
  searchUrl = '';
  searchTimeout = null;
  @ViewChild('container', { read: ViewContainerRef, static: true })
  container;
  cancelBeforeStart = false;
  value;
  type = null;
  constructor(public dropdownService: GridDropdownService) {}
  agInit(params: any): void {
    this.params = params;
    this.itemsLoading = true;
    this.value = params.value || null;
    this.multiselect = params.multiselect || false;
    this.searchUrl = params.url || '';
    this.isListType = params.isListType || false;
    this.fillColumn = params.fillColumn || '';
    this.inlineSearch = params.inlineSearch || false;
    this.searchOnInit = params.searchOnInit || false;
    this.type = params.type || null;

    if (params.searchOnInit) {
      this.data = [];
      this.dropdownItems = [];
      if (params.defaultParameter) {
        // console.log(typeof params.defaultParameter);
        switch (typeof params.defaultParameter) {
          case 'object':
            if (params.defaultParameter instanceof Array) {
              params.defaultParameter.forEach((paramKey) => {
                this.defaultPayload[params.columnsMap[paramKey]] = this.params.data[
                  paramKey
                ]
              })
            } else {
              this.defaultPayload = params.defaultParameter;
            }
            break;
          default:
            const key = (params.columnsMap && params.columnsMap[params.defaultParameter]) || params.defaultParameter
            this.defaultPayload[key] = this.params.data[
              params.defaultParameter
            ];
            break;
        }
      }
      if (params.staticParams) {
        this.defaultPayload = {
          ...this.defaultPayload,
          ...params.staticParams
        }
      }
      this.showLoader = true;
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.dropdownService
          .getListBySearchText(
            this.searchUrl,
            '',
            500,
            this.defaultPayload,
            this.type
          )
          .subscribe((data) => {
            const suggestions = data.result.suggestions
              .filter((i) => (i || '').toString().trim().length > 0)
              if (this.multiselect) {
                this.dropdownItems = suggestions
                  .map((item) => ({
                    name: item,
                    selected: this.value.filter((param) => param === item).length
                      ? true
                      : false,
                  }));
                this.selectedItems = this.value.map((item) => ({
                  name: item,
                  selected: true,
                }));
              } else {
                this.dropdownItems = suggestions
                  .map((item) => ({
                    name: item,
                    selected: this.value === item
                  }));
                this.selectedItems = this.dropdownItems.filter(
                  (filterItem) => filterItem.selected
                );
              }
            this.data = JSON.parse(JSON.stringify(this.dropdownItems));
            this.showLoader = false;
          });
      }, 1000);
    } else {
      this.data = params.values || [];
      this.dropdownItems = params.values || [];
      this.dropdownItems.forEach(element =>{
        if(element.selected) {
          this.selectedItems.push(element);
        }
      })
    }

    if (this.isListType) {
      if (
        params.value !== null &&
        params.value !== undefined &&
        params.value !== ''
      ) {
        this.displayValue = params.values.filter(
          (item) => item.value === params.value
        )[0].name;
      } else {
        this.displayValue = params.value;
      }
    } else {
      this.displayValue = params.value || '';
    }
  }

  getValue(): any {
    return !this.selectedItem ? this.selectedItem.name : this.value;
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
    this.container.element.nativeElement.focus();
  }
  isPopup(): boolean {
    return true;
  }

  onInputSearch(event: any) {
    if (this.searchText.length > 0) {
      const searchText = StringUtils.quote(this.searchText.trim());
      if (this.inlineSearch) {
        const tempOptions = [];
        this.dropdownItems.forEach((element) => {
          if (element.name.toLowerCase().includes(searchText.toLowerCase())) {
            tempOptions.push(element);
          }
        });
        this.dropdownItems = JSON.parse(JSON.stringify(tempOptions));
      } else {
        this.showLoader = true;
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          if (this.params.searchOnInit) {
            this.dropdownService
              .getListBySearchText(
                this.searchUrl,
                searchText,
                500,
                this.defaultPayload,
                this.type
              )
              .subscribe((data) => {
                this.dropdownItems = data.result.suggestions
                  .filter((i) => (i || '').toString().trim().length > 0)
                  .map((item) => ({
                    name: item,
                    selected: this.multiselect ? this.value.filter((param) => param === item)
                      .length : this.value === item
                      ? true
                      : false,
                  }));
                if (this.multiselect) {
                  this.selectedItems = this.value.map((item) => ({
                    name: item,
                    selected: true,
                  }));
                }
                this.showLoader = false;
              });
          } else {
           this.dropdownItems = this.dropdownItems.filter(val => val.name.includes(searchText));
            this.dropdownService
              .getListBySearchText(this.searchUrl, searchText)
              .subscribe((data) => {
                this.dropdownItems = data.result
                  .filter((i) => (i || '').toString().trim().length > 0)
                  .map((item) => ({ name: item }));
              });
            this.showLoader = false;
          }
        }, 1000);
      }
    } else {
      this.dropdownItems = this.data;
    }
  }

  clearSearchText(event: any) {
    this.searchText = '';
    this.onInputSearch(event);
  }

  setValue(value?): void {
    if (this.multiselect) {
      this.value = this.selectedItems.map((item) => item.name);
    } else {
      this.value = value;
    }
  }

  onSelectItem(selectedItem) {
    this.selectedItem = selectedItem;
    if (this.isListType) {
      this.setValue(selectedItem[this.params.colDef.field]);
    } else {
      this.setValue(selectedItem.name);
    }
    if (this.fillColumn.length > 0) {
      this.params.node.data[this.fillColumn] = this.selectedItem.name;
    }
    this.displayValue = selectedItem.name;
    if (this.params.dimensionMap !== undefined) {
      const dimensionMap = this.params.dimensionMap;
      dimensionMap.requiredColumns.map((modCol) => {
        this.params.node.data[modCol] =
          selectedItem[dimensionMap.columns[modCol]];
      });
    }
    this.params.api.stopEditing();
  }

  onAutoSelectItem($event, selectedItem) {
    $event.stopPropagation();

    if (selectedItem.selected) {
      this.selectedItems.push(selectedItem);
    } else {
      this.selectedItems = this.selectedItems.filter(
        (item) => item.name !== selectedItem.name
      );
    }

    this.setValue();
    this.displayValue = this.value.join(', ');
  }

  onClearAll($event) {
    $event.stopPropagation();
    this.selectedItems = [];
    this.dropdownItems.forEach((filterItem) => (filterItem.selected = false));
    this.setValue();
    this.displayValue = this.value;
  }
}
