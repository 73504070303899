import { Injectable } from '@angular/core';
import { GridOptions, GridApi, ColDef } from '@ag-grid-community/core';
import { BomTreeGridConfig, GRID_CONSTANTS } from './bom-tree-grid.model';


@Injectable()
export class BomTreeGridService {
  public showGrid = false;
  public gridOptions: GridOptions = {} as GridOptions;
  public gridApi: GridApi;
  public detailGridApi: Map<string, GridApi> = new Map<string, GridApi>();
  public gridColumnApi: any;
  private _columnDefs;
  private _rowData;
  private _detailGridOptions: GridOptions = {} as GridOptions;
  private _detailRowDataFn;
  public detailCellRendererParams = {
    detailGridOptions: this.detailGridOptions,
    getDetailRowData: this.detailRowDataFn
  };
  public eventStack: Map<string, Set<Function>> = new Map();
  public detailEventStack: Map<string, Set<Function>> = new Map();
  private callBackListeners = {};

  // doc: gridOptions Configurations private variables with public setters & getters
  private _frameworkComponents = {
    // numericRendererComponent: NumericRendererComponent,
    // numericEditorComponent: NumericEditorComponent,
    // linkComponent: LinkComponent,
    // fontComponent: FontComponent,
    // currencyFormatterComponet: CurrencyFormatterComponent,
    // gridToolTipComponent: GridToolTipComponent,
    // placeHolderComponent: PlaceHolderComponent,
    // checkboxCellComponent: CheckboxCellComponent,
    // sharedTargetCostComponen: SharedTargetCostComponent,
    // integerEditorComponent: IntegerEditorComponent,
    // hybridEditorComponent: HybridEditorComponent,
    // showDeviationsComponent: ShowDeviationsComponent,
    // dropdownStatusComponent: DropdownStatusComponent,
    // enumStatusRendererCompon: EnumStatusRendererComponent,
    // insightComponent: InsightComponent
  } as any;
  private _rowNodeIdFn = null;
  private _context = null;
  private _rowSelectableFn = null;
  private _rowStyleFn = null;
  private _rowClassFn = null;
  private _uniqueKey = 'cmspkId';

  public set columnDefs(val: ColDef[]) {
    this.gridOptions.columnDefs = this._columnDefs = val;
    if (
      this.gridApi instanceof Object &&
      this.gridApi.setColumnDefs instanceof Function
    ) {
      this.gridApi.setColumnDefs(val);
    }
  }
  public get columnDefs() {
    return this._columnDefs;
  }

  public set rowData(val) {
    this.gridOptions.rowData = val;
    if (
      this.gridApi instanceof Object &&
      this.gridApi.setRowData instanceof Function
    ) {
      this.gridApi.setRowData(val);
    }
  }
  public get rowData() {
    return this._rowData;
  }

  // doc: gridOptions Configurations  public setters & getters
  public set frameworkComponents(val) {
    if (val instanceof Object) {
      for (const key in val) {
        this._frameworkComponents[key] = val;
      }
      this.gridOptions.frameworkComponents = this._frameworkComponents;
    }
  }

  public set rowNodeIdFn(val) {
    this._rowNodeIdFn = val;
    this.gridOptions.getRowNodeId = this._rowNodeIdFn;
  }

  public set context(context) {
    if (context instanceof Object) {
      const obj: any = {};
      if (this.gridOptions.context instanceof Object) {
        obj.oldContext = this.gridOptions.context;
      }
      obj.parent = context;
      this._context = obj;
      this.gridOptions.context = this._context;
    }
  }

  public get context() {
    return this._context;
  }

  public set rowSelectableFn(val) {
    this._rowSelectableFn = val;
    this.gridOptions.isRowSelectable = this._rowSelectableFn;
  }

  public get rowSelectableFn() {
    return this._rowSelectableFn;
  }

  public set rowStyleFn(val) {
    this._rowStyleFn = val;
    this.gridOptions.getRowStyle = this._rowStyleFn;
  }

  public get rowStyleFn() {
    return this._rowStyleFn;
  }

  public set rowClassFn(val) {
    this._rowClassFn = val;
    this.gridOptions.getRowClass = this._rowClassFn;
  }

  public get rowClassFn() {
    return this._rowClassFn;
  }

  public set detailRowDataFn(val) {
    this.detailCellRendererParams.getDetailRowData = this._detailRowDataFn = val;
    this.gridOptions.detailCellRendererParams = this.detailCellRendererParams;
  }

  public get detailRowDataFn() {
    return this._detailRowDataFn;
  }

  public set detailGridOptions(val) {
    this.detailCellRendererParams.detailGridOptions = this._detailGridOptions = val;
    this.gridOptions.detailCellRendererParams = this.detailCellRendererParams;
  }

  public get detailGridOptions() {
    return this._detailGridOptions;
  }

  constructor() {
    this.gridOptions.frameworkComponents = this._frameworkComponents;
  }

  addGridEventListener(event, cb, context?: object) {
    if (cb instanceof Function) {
      if (!(this.eventStack.get(event) instanceof Set)) {
        this.eventStack.set(event, new Set());
      }
      context =
        context instanceof Object
          ? context
          : this._context instanceof Object &&
            this._context.parent instanceof Object
          ? this._context.parent
          : this;
      this.eventStack.get(event).add(cb.bind(context));
      if (!(this.gridOptions[GRID_CONSTANTS[event]] instanceof Function)) {
        this.gridOptions[
          GRID_CONSTANTS[event]
        ] = this.callEventListenersForEvent.bind(this);
      }
    }
  }

  addDetailGridEventListener(event, cb, context?: object) {
    if (cb instanceof Function) {
      if (!(this.detailEventStack.get(event) instanceof Set)) {
        this.detailEventStack.set(event, new Set());
      }
      context =
        context instanceof Object
          ? context
          : this._context instanceof Object &&
            this._context.parent instanceof Object
          ? this._context.parent
          : this;
      this.eventStack.get(event).add(cb.bind(context));
      if (
        !(
          this.detailCellRendererParams.detailGridOptions[
            GRID_CONSTANTS[event]
          ] instanceof Function
        )
      ) {
        this.detailCellRendererParams.detailGridOptions[
          GRID_CONSTANTS[event]
        ] = this.callDetailGridEventListenersForEvent.bind(this);
      }
    }
  }

  callEventListenersForEvent(params) {
    if (this.eventStack.has(params.type)) {
      const onReadyEventStack = this.eventStack.get(params.type);
      onReadyEventStack.forEach(cb => cb(params));
    }
  }

  callDetailGridEventListenersForEvent(params) {
    if (this.detailEventStack.has(params.type)) {
      const onReadyDetailEventStack = this.detailEventStack.get(params.type);
      onReadyDetailEventStack.forEach(cb => cb(params));
    }
  }

  gridAutoSize(gridApi) {
    gridApi = gridApi || this.gridApi;
    if (gridApi instanceof Object && gridApi.api instanceof GridApi) {
      const api = gridApi.api;
      if (api.sizeColumnsToFit instanceof Function) {
        // api.sizeColumnsToFit();
        gridApi.columnApi.autoSizeColumns();
      } else {
        this.callBackFireForNotAvailableValues(
          () => {
            return !(
              api instanceof Object && api.sizeColumnsToFit instanceof Function
            );
          },
          this.gridAutoSize.bind(this, gridApi),
          5000,
          100
        );
      }
    }
  }

  private callBackFireForNotAvailableValues(condition, cb, time, ping) {
    if (this.callBackListeners[cb] instanceof Object) {    
      clearInterval(this.callBackListeners[cb].intervalId);
      delete this.callBackListeners[cb];
    }
    const intervalId = setInterval(() => {
      if (condition()) {
        cb();
        clearInterval(this.callBackListeners[cb].intervalId);
        delete this.callBackListeners[cb];
      } else if (
        this.callBackListeners[cb].ping * this.callBackListeners[cb].rounds++ >=
        this.callBackListeners[cb].time
      ) {
        clearInterval(this.callBackListeners[cb].intervalId);
        delete this.callBackListeners[cb];
      } else {
        ++this.callBackListeners[cb].rounds;
      }
    }, ping);
    this.callBackListeners[cb] = {
      cb,
      time,
      ping,
      intervalId,
      round: 0
    };
  }
  public setUniqueKey(val) {
    this._uniqueKey = val;
  }
  public getUniqueKey() {
    return this._uniqueKey;
  }
}
