import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';

import { ScopeFilters } from './shared/scope-filters.model';
import { ScopeFiltersService } from './shared/scope-filters.service';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService';
import { BaseService } from 'app/modules/common/base.service';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';

@Pipe({
  name: 'getTitle'
})
export class GetTitlePipe implements PipeTransform {
  transform(target: any, dimensionData: any, dimensionNames: any, fallback?: string): any {
    try {
      if (target !== 'RAW_MATERIAL') {
        if (dimensionData !== undefined) {
          if (Object.keys(dimensionData[target]).length > 0) {
            const value = dimensionNames[target];
            return value.charAt(0).toUpperCase() + value.slice(1);
          } else {
            return '';
          }
        } else {
          return '';
        }

      } else {
        return target;
      }
    } catch (e) {

    }
  }
}


@Component({
  selector: 'scope-filters',
  templateUrl: 'scope-filters.component.html',
  styleUrls: ['./scope-filters.component.sass'],
  providers: [ScopeFiltersService]
})

export class ScopeFiltersComponent implements OnInit {

  constructor(private scopeFiltersService: ScopeFiltersService,
    private scopeDataService: ScopeDataService,
    private customerConfigurationService: CustomerConfigurationService, private baseService: BaseService
  ) {





  }
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  scopeFilters: ScopeFilters[] = [];
  show = false;
  filtersData: any;
  focus: string;

  showMe = false;

  prevItem = false;
  nextItem = false;

  dimensionList: Array<any> = [];
  dimensionData: any;
  dimensionListData: Array<any> = [];

  dimensionListName = '';
  selectedFiltersArray: any = [];
  dimensionNames: any = {};

  /**
	 * Advanced Filters Changes
	 * Start
	 */

  showTooltipName = '';
  toolTipOffSetLeft = 0;
  formdimensionobject(focus) {
    this.selectedFiltersArray = [];
    if (this.customerConfigurationService.getConfData(focus) &&
      this.customerConfigurationService.getConfData(focus).scopeDimensionData) {
      this.selectedFiltersArray = this.customerConfigurationService.getConfData(focus).scopeDimensionData;
    }
    for (const i of this.selectedFiltersArray) {
      const dimensionName = i['dimensionName']
      const displayname = i['displayName']
      this.dimensionNames[dimensionName] = displayname;
    }
  }
  updateView() {

    const copyOfDimensionData: any = [];
    if (this.filtersData !== undefined) {
      if (this.filtersData['selectedScopeData'] !== undefined) {
        if (Object.keys(this.filtersData['selectedScopeData']).length > 0) {
          if (this.filtersData['selectedScopeData'][this.focus] !== undefined) {
            this.dimensionData = this.filtersData['selectedScopeData'][this.focus]['dimensions'] === undefined ? undefined : this.filtersData['selectedScopeData'][this.focus]['dimensions'];
            if (this.dimensionData !== undefined) {
              this.show = true;
              Object.assign(copyOfDimensionData, this.dimensionData);
              for (const item of Object.keys(copyOfDimensionData)) {
                if (Object.keys(copyOfDimensionData[item]).length === 0) {
                  delete this.dimensionData[item];
                }
              }
              if (this.dimensionData !== undefined) {
                this.dimensionList = Object.keys(this.dimensionData);
              } else {
                this.dimensionList = [];
              }
            } else {
              this.show = false;
            }

          }
        }
      }
    }
  }
  showList(target) {
    if (this.dimensionData !== undefined) {
      if (this.dimensionData[target] !== undefined) {
        if (Object.keys(this.dimensionData[target])[0] !== undefined) {
          this.dimensionListName = ' : ' + Object.keys(this.dimensionData[target])[0];
        } else {
          this.dimensionListName = ' : ' + ' - ';
        }
        /* if (Object.keys(this.dimensionData[target]).length > 1) {
					this.dimensionListName = '';
					this.dimensionListData = Object.keys(this.dimensionData[target]);
					return true;
				} else {
					this.dimensionListName = " - " + Object.keys(this.dimensionData[target])[0]
				} */
      } else {
        this.dimensionListName = '';
      }
    }
    else {
      this.dimensionListName = '';
    }
  }

  getTittle(target) {
    try {
      if (this.dimensionData !== undefined) {
        if (Object.keys(this.dimensionData[target]).length > 0) {
          const value = this.dimensionNames[target];
          return value.charAt(0).toUpperCase() + value.slice(1);
        } else {
          this.dimensionListName = '';
          return '';
        }

      } else {
        this.dimensionListName = '';
        return '';
      }
    } catch (e) {
      console.log(e);
    }
  }

  getKeyValues(dimension) {
    if (this.dimensionData !== undefined && this.dimensionData.hasOwnProperty(dimension)) {
      return Object.keys(this.dimensionData[dimension])
    }
    else { return []; }
  }

  ngOnInit() {
    this.focus = this.scopeDataService.getFocusValue();
    this.formdimensionobject(this.focus)

    this.prevItem = false;
    this.nextItem = true;
    if (this.filtersData !== undefined) {
      this.updateView();
    }



    this.scopeDataService.getApplicationScope().subscribe(
      (data) => {
        if (data !== undefined) {
          try {
          } catch (e) { }
          this.filtersData = data;
          this.updateView();
        }
      }
    );
    this.scopeDataService.getFocus().subscribe(
      (focus) => {
        this.focus = focus;
        this.selectedFiltersArray = []

        this.formdimensionobject(this.focus)
        this.updateView();
      }
    );
  }
  ngOnChanges() {

  }
  prevClick(event) {
    const item = document.getElementById('filtersBlk');
    const itemLeftVal = document.getElementById('filtersBlk').offsetLeft;
    item.getBoundingClientRect();
    if (itemLeftVal >= 20) {
      this.prevItem = false;
      this.nextItem = true;
    } else {
      item.style.left = itemLeftVal + 200 + 'px';
      this.nextItem = true;
    }
  }
  nextClick(event) {

    const item = document.getElementById('filtersBlk');
    const itemLeftVal = document.getElementById('filtersBlk').offsetLeft;
    if (itemLeftVal <= -330) {
      this.nextItem = false;
      this.prevItem = true;
    }
    else {
      item.style.left = itemLeftVal - 220 + 'px';
      this.prevItem = true;
    }
  }

  getAdvancedFilterTitle(dimension: string): string {
    if (this.filtersData instanceof Object && typeof this.focus === 'string' && this.filtersData.hasOwnProperty('selectedScopeData')) {
      if (this.filtersData['selectedScopeData'][this.focus].hasOwnProperty('filters')) {
        if (this.filtersData['selectedScopeData'][this.focus]['filters'].hasOwnProperty(dimension)) {
          return this.filtersData['selectedScopeData'][this.focus]['filters'][dimension]['advancedScopeFilterName'];
        }
      }
    }
  }

  tooltipMouseOver(event: Event, dimension: string) {
    this.showTooltipName = dimension;
    this.toolTipOffSetLeft = event['screenX'] + 8;
  }

  tooltipMouseLeave() {
    this.showTooltipName = '';
    this.toolTipOffSetLeft = 0;
  }

  /**
	 * Advanced Filters Changes
	 * End
	 */

}
