import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {Subject} from 'rxjs';
import { BaseService } from 'app/modules/common/base.service';
import { CustomerConfigurationService } from './customerConfigurationService';
import { ScopeDataService } from './scope.data.service';

@Injectable()
export class NavAdvBarService{
    scope: any;
    obj: any;
    subfeatures: Array<object> = [];
    ieJson: { title: string, name: string,badgeEnabled:boolean,counter:string };
    ieFeatures: Array<object> = [];
    menuMap = new Map();
    protected navBarList = new Map();
    constructor(private baseService: BaseService,
    private customerConfigurationService:CustomerConfigurationService,
    private scopedataService:ScopeDataService) {
        this.scope = this.scopedataService.getFocusValue();
        this.scopedataService.getFocus().subscribe((val) => {
            this.scope = val;
        });
        this.obj = this.baseService.getUserInformation();
        // this.subfeatures = this.obj.userFeatureDetailsDTO.features;
        this.menuMap['ietabs'] = [
                {
                    name: 'edit',
                    title: 'Edit',
                    badgeEnabled:false,
                    counter:0
                },
                {
                    name: 'notifications',
                    title: 'Notifications',
                    badgeEnabled:true,
                    counter:0
                }
            ];

        this.constructNavBar('ietabs','init',0);
    }
    public constructNavBar(target,tabName:any,counter:any) {
        if (target === 'ietabs') {
            this.menuMap['ietabs'].forEach(element => {
                if (tabName==='init') {
                    this.ieJson = {
                        name: element['name'],
                        title: element['title'],
                        badgeEnabled:element['badgeEnabled'],
                        counter
                    }
                    this.ieFeatures.push(this.ieJson);
                } else {
                    this.ieFeatures.forEach(elementInside => {
                        if (elementInside['name']===tabName){
                            elementInside['counter']=counter;
                        }
                    });
                }
            });
            this.navBarList[target] = this.ieFeatures;
        }
    }
    public getNavBarList(target) {
        let subTabs;
        if (this.customerConfigurationService.getConfData(this.scope)
        && this.customerConfigurationService.getConfData(this.scope).mainTabFeatures) {
        subTabs= this.customerConfigurationService.getConfData(this.scope).mainTabFeatures;
    }
        let ieFeatures = [];
        const ietabs = subTabs.filter(item => item.ietabs instanceof Object);
        if (ietabs instanceof Array && ietabs.length > 0) {
            ieFeatures=ietabs[0]['ietabs'].map(i=>i.name);
        }
        this.navBarList[target] =  this.navBarList[target].filter(i=>ieFeatures.includes(i.view));
        return this.navBarList[target];
    }

}
