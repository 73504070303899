import { GridOptions } from '@ag-grid-community/core';
import { GridConfiguration } from './../../../core/nxt-grid/gridconfiguration';
import { ApiServiceProvider } from 'app/api-service';
import { Injectable, EventEmitter, Output } from '@angular/core';
import { Moment } from 'moment/moment';
import * as _ from 'lodash';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { DataFilter } from '../../common/data-filter/shared/data-filter.model';
import { DateUtils } from 'app/modules/common/utills/DateUtils.service';
import { AdhocSubscriptionService } from 'app/modules/common/adhoc.subscription.service';
import { ToastService } from 'app/modules/srfq/shared/toaster.service';
import { PostRfxCreationService } from '../../detail/detail-view/shared/post.rfx.creation.service';
import { HEADER_OBJECT } from '../adhoc-rfx/shared/adhoc-rfx.constants';
import { ApiCommonService } from 'app/api-common-service';
import { ConfigureTenantService } from 'app/modules/customer-configuration/configure-tenant/shared/configure-tenant.service';
import { TimeSelectionDataService } from 'app/modules/core/time-selection/shared/time-selectiondata.service';
import { DialogBoxService } from 'app/modules/leva-ui-library/components/dialog-box/dialog-box.service';
import { CUSTOMER_CELLS } from '../../../shared/services/shared-consts';

export class NumberUtils {
  static prev;
  private static rand(min, max) {
    const num = Math.floor(Math.random() * (max - min + 1) + min);
    this.prev = num === this.prev && min !== max ? this.rand(min, max) : num;
    return this.prev;
  }
  public static getRandomNumber(len?: number) {
    if (typeof len !== 'number') {
      len = 9;
    }
    const min = 0;
    let maxStr = '';
    for (let i = 0; i < len; i++) {
      maxStr += 9;
    }
    // tslint:disable-next-line: radix
    const max = parseInt(maxStr);
    return this.rand(min, max);
  }
}

@Injectable()
export class Create {
  public set name(value) {
    this._name = value;
  }
  public get name() {
    return this._name;
  }
  public set showDeleteDraftPopup(value) {
    this._showDeleteDraftPopup = value;
  }
  public get showDeleteDraftPopup() {
    return this._showDeleteDraftPopup;
  }
  public set description(value) {
    this._description = value;
  }
  public get description() {
    return this._description;
  }

  public set sortedColomnModel(value) {
    this._sortedColomnModel = value;
  }
  public get sortedColomnModel() {
    return this._sortedColomnModel;
  }
  public set selectedChooseFrom(value) {
    this._selectedChooseFrom = value;
    this.showGrid = false;
    if (value instanceof Object) {
      if ((value.title || '').toString().toLowerCase() === 'template') {
        this.chosenList = this.templatesList;
        this.chosenListKey = 'tmplName';
      } else if ((value.title || '').toString().toLowerCase() === 'rfq') {
        this.chosenList = this.rfqsList;
        this.chosenListKey = 'rfqCustName';
      }
    }
    if (this.chosenList instanceof Array) {
      if (this.chosenList.length > 0) {
        this.chosenListValue = this.chosenList[0];
      } else {
        this.startQuotePeriods = [];
        this.endQuotePeriods = [];
      }
    } else {
      this.startQuotePeriods = [];
      this.endQuotePeriods = [];
    }
  }
  public get selectedChooseFrom() {
    return this._selectedChooseFrom;
  }
  public set startQuotePeriods(value) {
    this._startQuotePeriods = value;
  }
  public get startQuotePeriods() {
    return this._startQuotePeriods;
  }
  public set endQuotePeriods(value) {
    if (value instanceof Array && value.length > 4) {
      value = value.slice(0, 4);
    }
    this._endQuotePeriods = value;
  }
  public get endQuotePeriods() {
    return this._endQuotePeriods;
  }
  public set referenceBaselineQtr(value) {
    this._referenceBaselineQtr = value;
  }
  public get referenceBaselineQtr() {
    return this._referenceBaselineQtr;
  }
  public set selectedStartQuotePeriod(value) {
    if (
      (this.selectedChooseFrom.title || '').toString().toLowerCase() === 'rfq'
    ) {
      this.isRFQbasedRFQ = true;
    }
    if (value instanceof Object) {
      if (this.startQuotePeriods instanceof Array) {
        if (this.startQuotePeriods.indexOf(value) > -1) {
          this._selectedStartQuotePeriod = value;
          this.DataFilterModel.startQuoteIndex = value.id;
          this.DataFilterModel = JSON.parse(
            JSON.stringify(this.DataFilterModel)
          );
          // console.log(value);
          const filteredItems = this.startQuotePeriods.filter(
            match => match.id >= value.id
          );
          this.endQuotePeriods = [];
          if (filteredItems.length) {
            this.endQuotePeriods = filteredItems;
            if (this.rfqId > 0 && this.editRfq instanceof Object) {
              const selectItems = this.endQuotePeriods.filter(
                match => match.quotePeriod === this.editRfq.endQuotePeriod
              );
              // console.log(selectItems);
              this.selectedEndQuotePeriod = selectItems[0];
            } else {
              if (this.postRfxCreationService.isPostRFXCreation()) {
                this.selectedEndQuotePeriod = this.retrievePostRFXCreationQuotePeriods(
                  this.endQuotePeriods,
                  'endQuoteDate'
                )[0];
              } else {
                this.selectedEndQuotePeriod = this.endQuotePeriods[0];
              }
            }
          }
        }
      }
    }
  }
  public get selectedStartQuotePeriod() {
    return this._selectedStartQuotePeriod;
  }
  public set selectedEndQuotePeriod(value) {
    if (
      (this.selectedChooseFrom.title || '').toString().toLowerCase() === 'rfq'
    ) {
      this.isRFQbasedRFQ = true;
    }
    if (this.endQuotePeriods instanceof Array) {
      if (this.endQuotePeriods.indexOf(value) > -1) {
        this._selectedEndQuotePeriod = value;
        // console.log(value);
        if (this.isFirstTime) {
          this.loadDefaultObject();
        }
        // this.dataStorageService.setPrimarykeyArray([]);
        this.loadScopeHeaders();
      }
    }
  }
  public get selectedEndQuotePeriod() {
    return this._selectedEndQuotePeriod;
  }
  public set selectedreferenceBaselineQtr(value) {
    if (this.referenceBaselineQtr instanceof Array) {
      this._selectedreferenceBaselineQtr = value;
      if (this.isFirstTime) {
        this.loadDefaultObject();
      }
      this.loadScopeHeaders();
    }
  }
  public get selectedreferenceBaselineQtr() {
    return this._selectedreferenceBaselineQtr;
  }
  public set rfqsList(value) {
    this._rfqsList = value;
  }
  public get rfqsList() {
    return this._rfqsList;
  }

  public set chosenListValue(value) {
    if (this.selectedChooseFrom instanceof Object && value instanceof Object) {
      if (!this.isFirstTime && !(this.editRfq instanceof Object)) {
        this.selectedScopeIds = [];
        this.selectAllFlag = false;
        this.deSelectedScopeIds = [];
        this.showGrid = false;
      }
      let templateId;
      if (
        (this.selectedChooseFrom.title || '').toString().toLowerCase() ===
        'template'
      ) {
        this.chosenList = this.templatesList;
        this.chosenListKey = 'tmplName';
        templateId = value['tmplId'];
        this._chosenListValue = value;
        this.loadQuartersForSelectedTemplate();
      } else if (
        (this.selectedChooseFrom.title || '').toString().toLowerCase() === 'rfq'
      ) {
        this.isRFQbasedRFQ = true;
        this.chosenList = this.rfqsList;
        this.chosenListKey = 'rfqCustName';
        templateId = value['templateId'];
        this._chosenListValue = value;
        if (this.editRfq instanceof Object) {
          this.loadQuartersForSelectedTemplate();
        } else {
          this.loadRFQDataForSelectedRFQ().then(() => {
            this.loadQuartersForSelectedTemplate().then(() => {
              this.getSelectedScopeData(this.selectedScopeIds, 1, 2000)
            })
          });
        }
      }

      this.DataFilterModel.templateId = templateId;
      this.DataFilterModel = JSON.parse(JSON.stringify(this.DataFilterModel));
    }
  }
  public get chosenListValue() {
    return this._chosenListValue;
  }
  public dialogConfiguration: any = {};
  constructor(
    private apiCommonService: ApiCommonService,
    private apiService: ApiServiceProvider,
    public gridConfig: GridConfiguration,
    private dataStorageService: DataStorageService,
    public DataFilterModel: DataFilter,
    private adhocSubscriptionService: AdhocSubscriptionService,
    private toastService: ToastService,
    private postRfxCreationService: PostRfxCreationService,
    private _tenantService: ConfigureTenantService,
    private timeSelectionDataService: TimeSelectionDataService,
    private dialogBoxService: DialogBoxService,
  ) {
    /* ---------------------- Chat Bot Service Action Start Here ------------------- */
    this._tenantService
      .getCustomerConfigurationFieldsExistingCustomerData()
      .subscribe(data => {
        if (data && data.responseStatus && data.responseStatus.code === 401) {
          return;
        }
        if (data && data.customerInformation) {
          const baseline = data.customerInformation.commonInformation.find(element => element.name === 'BaselineQuarterCustomerInfo');
          if (baseline && baseline.value) {
            this.referenceBaseQtrCheck = baseline.value
          }
        }
      });
    this.dataStorageService.getBotServiceName().subscribe(data => {
      if (data) {
        if (data && data.parameters && data.parameters.filter) {
          const filterArrayObjects = [];
          const filterOption = data.parameters.filter;
          for (const filterObject of Object.keys(filterOption)) {
            const manageFilter = {};
            manageFilter['dbName'] = filterObject.toLocaleUpperCase();
            manageFilter['startVal'] = [];
            manageFilter['startVal'] = filterOption[filterObject];
            manageFilter['endVal'] = null;
            manageFilter['columnDataType'] = 'STRING';
            manageFilter['type'] = 'GridFil';
            filterArrayObjects.push(manageFilter);
          }
          this.chatBotFilerData = filterArrayObjects;

          this.dataStorageService.setPreviewRounds('Create');
        }
        setTimeout(() => {
          this.dataStorageService.setBotServiceName(undefined);
        }, 1000);
      }
    });

    /* ---------------------- Chat Bot Service Action End Here ------------------- */
    this.configureRowNodeId();
    this.gridOptions = {
      onCellClicked: this.onCellClicked.bind(this),
      onCellEditingStopped: this.cellEditStopped.bind(this)
    } as GridOptions;
    this.selectedScopeIds = [];

    const editRfq = this.dataStorageService.getEditRfqResult();
    if (editRfq instanceof Object) {
      this.loadEditRFQ(editRfq);
    } else {
      this.name = this.description =
        'RFQ_' +
        NumberUtils.getRandomNumber() +
        '_' +
        DateUtils.getDate(null, true).format('MM-DD-YYYY');
      this.selectedChooseFrom = this.chooseFromValues[this.chooseFromDefault];
    }
    this.getScheduleDate();
    this.gridOptions['paginationChanged'] = (pageNumber, pageSize, sortGroupCriteria) => {
      if (this.showSelectedFlag) {
        this.showOnlySelectedRows(this.selectedScopeIds, pageNumber, pageSize);
      } else {
        if (!this.showCurrentRFXItems && !this.showExistingRFXItems) {
          this.loadScopeData(pageNumber, pageSize, sortGroupCriteria);
        }
      }
      if (this.showCurrentRFXItems || this.showExistingRFXItems) {
        this.configurePostRFXCreation(pageNumber, pageSize);
      }
    };
    this.configureForEditRFQ();
  }
  public referenceBaseQtrCheck = false;
  previousQuarter: string;
  // refreshCache = true;
  pullRecordCountFromServer = true;
  rfqTitle = 'RFQ Draft';
  columnConfigApplied = false;
  editRfq = undefined;
  rfqId = null;
  deSelectedScopeIds: any = [];
  rfqSent = false;
  templateName;
  quotePeriod;
  rfxInformationAccordionTitle = 'RFX Information';
  rfxScopeAccordionTitle = 'RFX Scope';
  partOfRfqIds = [];
  partExistingRFQs: any = [];
  draftTitle = 'Please select at least one record from RFX Scope to Save';
  draftLabel = 'Draft';
  draftDisable = false;
  fromNpi = 0;
  previewSendObj: any = {};
  defaultFiltersApplied = false;
  sendWithoutPreviewTitle =
    'Please select at least one record from RFX Scope to distribute RFX';
  sendWithoutPreviewLabel = 'Send without Preview';
  sendWithoutPreviewDisable = false;
  isRFQbasedRFQ = false;
  previewDownload = false;
  nextTitle =
    'Please select at least one record from RFX Scope for navigating to Preview';
  nextLabel = 'Next';
  nextDisable = false;
  deletedCpnGroupIds = [];
  showNoRecordsMsg = false;
  cancelTitle = 'Close and Return to Homepage';
  cancelLabel = 'Cancel';
  cancelDisable = false;
  showPartOfRfqIdsMsg = false;
  namePlaceHolder = 'Enter RFQ Name';
  nameTitle = 'Enter name of the RFX which will be displayed to Suppliers';
  nameLabel = 'Name : ';
  // name = 'Mechanical RFQ';

  descriptionPlaceHolder = 'Enter RFQ Description';
  descriptionTitle =
    'Enter description of RFX which may explain more about RFX and displayed only to Buyers';
  descriptionLabel = 'Description :';
  // description = 'Mechanical RFQ for Q1-2018';

  forecastOnly = true;
  forecastOnlyLabel = 'Forecast Only';
  forecastOnlyTitle = 'If you turn this on, this rfq is just a sample one';
  public editedRows = new Map()
  public enableSaveBtn = false;
  selectedRowsData: any = [];
  chooseFromTitle = 'Choose';
  chooseFromValues = [
    {
      name: 'Template',
      title: 'Template',
      disabled: false
    },
    {
      name: 'RFQ',
      title: 'RFQ',
      disabled: false
    }
  ];
  chooseFromDefault = 0;
  chosenList = [];
  previewDefaultFilters = [];
  chosenListKey = 'tmplName';
  sourceType = 'ALL'
  selectedScopeIds: any = [];
  showSelectedFlag = false;
  selectAllFlag = false;
  showExistingRFXItems = false;
  showCurrentRFXItems = false;
  totalNotPartofRFXRecords: number;
  scopeDataFilters: any = [];
  selectAllWithScopeFilters = [];
  saveFilter: any = [];
  unSavedAdhoc = false;
  defaultFilters;
  scopeExcelPayload: any;
  showToasterPopup = false;
  selectedListForPopup = [];
  @Output()
  gridHeadersLoaded: EventEmitter<any> = new EventEmitter();

  ajaxRequests: any = {};
  totalRecords: any;

  private _name = '';
  private _description = '';
  public _showDeleteDraftPopup = false;
  private _sortedColomnModel = {};

  private _selectedChooseFrom: any = this.chooseFromValues[
    this.chooseFromDefault
  ];

  templatesList = [];
  private _referenceBaselineQtr = null;
  private _selectedreferenceBaselineQtr = null;
  private _startQuotePeriods = null;

  private _endQuotePeriods = null;

  private _selectedStartQuotePeriod = null;

  private _selectedEndQuotePeriod = null;

  private _rfqsList = [];
  public modelInfo = {};
  public sourceArray = [{ name: 'All', checked: true, dbValue: 'ALL' },
  { name: 'Single source', checked: false, dbValue: 'SINGLE_SOURCE' },
  { name: 'Multi source', checked: false, dbValue: 'MULTI_SOURCE' }]
  private _chosenListValue;

  minDifferenceBetweenDates = 7200;

  scheduleTitle = 'Schedule your rfq to supplier';
  scheduleLabel = 'Schedule :';

  confirmDate: Moment = DateUtils.getNextDayNextHour();
  questionsDate: Moment = this.confirmDate
    .clone()
    .add(this.minDifferenceBetweenDates, 'seconds');
  submissionDate: Moment = this.questionsDate
    .clone()
    .add(this.minDifferenceBetweenDates, 'seconds');
  expectedAwardedDate: Moment = this.submissionDate
    .clone()
    .add(this.minDifferenceBetweenDates, 'seconds');

  defaultObject;
  isFirstTime = true;
  schedule = [];
  gridOptions: GridOptions;
  showGrid = false;
  showPreloader = true;
  headersData: any;

  // fullGridData: any = [];
  formChanges: any;

  noRowsMessage = null;

  chatBotFilerData = undefined;

  /**
   * Post RFX Creation Changes
   * Start
   */

  cmsDataByRFXPayLoad: any;
  clearAjaxCalls(key?: string) {
    if (typeof key === 'string') {
      if (this.ajaxRequests[key] instanceof Object) {
        this.ajaxRequests[key].unsubscribe();
        delete this.ajaxRequests[key];
      }
      return;
    }
    for (const req in this.ajaxRequests) {
      if (this.ajaxRequests[req] instanceof Object) {
        this.ajaxRequests[req].unsubscribe();
        delete this.ajaxRequests[req];
      }
    }
  }

  private configureRowNodeId() {
    this.gridConfig.setRowNodeId(rowNodeData => {
      if (rowNodeData instanceof Object) {
        return typeof rowNodeData['cmspkId'] === 'number' ||
          typeof rowNodeData['cmspkId'] === 'string'
          ? rowNodeData['cmspkId']
          : rowNodeData;
      }
    });
  }

  public getScheduleDate() {
    this.schedule = [
      {
        formControlName: 'confirmDate',
        label: 'Confirm',
        id: 'rfqConfirmDate',
        title:
          'Date by when RFX to be distributed to Suppliers, visible only to Buyers',
        placeholder: 'Choose RFQ Created Date',
        disabled: false,
        value: this.confirmDate
      },
      {
        formControlName: 'questionsDate',
        label: 'Questions',
        id: 'rfqConfirmDate',
        title: 'Suppliers need to get clarifications on the RFQ by this date',
        placeholder: 'Choose RFQ Final Questions Date',
        disabled: false,
        value: this.questionsDate
      },
      {
        formControlName: 'submissionDate',
        label: 'Submission',
        id: 'rfqSubmissionDate',
        title: 'Date by which Suppliers need to send back the quote',
        placeholder: 'Choose RFQ Submission Date',
        disabled: false,
        value: this.submissionDate
      },
      {
        formControlName: 'expectedAwardedDate',
        label: 'Expected Award Date',
        id: 'rfqExpectedAwardedDate',
        title: ' Date by when RFX will be Awarded, visible only to Buyers',
        placeholder: 'Choose Expected Award Date',
        disabled: false,
        value: this.expectedAwardedDate
      }
    ];
  }

  public loadEditRFQ(editRfq) {
    this.selectedScopeIds = editRfq.scopeConfig.selectedIds;
    this.selectedListForPopup = editRfq.scopeConfig.selectedIds;
    this.deletedCpnGroupIds = editRfq.scopeConfig.deletedCpnGroupIds;
    this.partExistingRFQs = editRfq.scopeConfig.partOfRfqCpnGroupIds;
    this.rfqId = editRfq.rfqId;
    this.name = editRfq.name;
    this.description = editRfq.description;

    this.chooseFromDefault = this.chooseFromValues.findIndex(function (value) {
      return value.name.toLowerCase() === editRfq.source.toLowerCase();
    });

    this.selectedChooseFrom = this.chooseFromValues[this.chooseFromDefault];
    this.editRfq = editRfq;
    this.loadRFQDates();
  }
  onCellClicked(params: any) {
    if (params.colDef.field === 'MPN' || params.colDef.field === 'MANUFACTURER') {
      params.colDef.editable = false;
      if (params.data && params.data.IS_ADHOC_ITEM && params.data.restricted === 0) {
        params.colDef.editable = true;
      }
    }
    setTimeout(() => {
      params.colDef.editable = false;
    }, 100)
  }

  cellEditStopped(params: any) {
    params.colDef.editable = false;
    this.editedRows.set(params.data.CMS_PK_ID, params.data);
    if (this.editedRows.size > 0) {
      this.enableSaveBtn = true;
    }
  }
  public loadRFQDates() {
    const editRfqSchedule = this.editRfq.schedule;
    this.confirmDate = DateUtils.getMomentFromEpoch(
      editRfqSchedule.confirmTime
    );
    this.submissionDate = DateUtils.getMomentFromEpoch(
      editRfqSchedule.submissionTime
    );
    this.questionsDate = DateUtils.getMomentFromEpoch(
      editRfqSchedule.questionsTime
    );
    this.expectedAwardedDate = DateUtils.getMomentFromEpoch(
      editRfqSchedule.expectedAwardTime
    );
  }

  public loadGridData(data?: any, sortModel?) {
    if (!(data instanceof Array)) {
      data = [];
    }

    const status = this.headersData.result.headers.filter(val => {
      if (val['dbColumnName'] === 'lock-column') {
        return val;
      }
    });

    if (status.length === 0) {
      this.headersData['result']['headers'].unshift({
        dbColumnName: 'lock-column',
        displayName: '',
        tooltipField: 'Select All',
        headerTooltip: 'Select All',
        lockPosition: true,
        cellClass: 'locked-col'
      });
    }
    if (this.headersData.result.headers.some(item => item.hide === true)) {
      this.columnConfigApplied = true;
    } else {
      this.columnConfigApplied = false;
    }
    for (const item of this.headersData.result.headers) {
      // item['headerGroupComponent'] = CustomGridHeaderComponent;
      if (item['dbColumnName'] === 'CPN') {
        item['tooltipEnabled'] = true;
        item['toolTipWidgetId'] = 'RFX_SCOPE';
        item.cellStyle = function (params) {
          return {
            overflow: 'visible'
          };
        };
      }
      if (sortModel && sortModel.length && (item.dbColumnName === sortModel[0].colId)) {
        item.sort = sortModel[0].sort.toLowerCase()
      }
      else {
        item.sort = '';
      }
      if (item['dbColumnName'] === 'CALENDAR_DATE') {
        item['datatype'] = 'TEXT'
        item.displayModifier.dateModifier = 'MM/DD/YYYY'
      }
      if (
        item.hasOwnProperty('scopeDefaultHidden') &&
        !this.columnConfigApplied
      ) {
        if (item['scopeDefaultHidden']) {
          item['hide'] = true;
        }
      }
    }
    this.gridOptions.columnDefs = this.headersData;
    this.gridOptions.rowData = [];
    this.gridOptions.rowData = data;
    try {
      this.gridOptions.api.setRowData(data);
    } catch (e) {
      // console.log('grid setrowdata issue.' + e);
    }
  }

  public loadScopeHeaders() {
    if (this.chosenListValue instanceof Object) {
      this.showGrid = false;
      let templateId = this.chosenListValue['tmplId'];
      if (
        (this.selectedChooseFrom.title || '').toString().toLowerCase() ===
        'template'
      ) {
        templateId = this.chosenListValue['tmplId'];
      } else {
        templateId = this.chosenListValue['templateId'];
      }

      const payload = {
        formParams: {
          templateType: 'COMMODITY',
          page: 'CREATERFQ',
          tenantType: 'CUSTOMER',
          startQuoteDate: this.selectedStartQuotePeriod.startQuoteDate,
          startQuoteIndex: this.selectedStartQuotePeriod.id,
          endQuoteIndex: this.selectedEndQuotePeriod.id
        },
        method: 'POST',
        servicePath: 'common/templates/' + templateId + '/headerInfo',
        targetedType: 'RFQ'
      };
      this.clearAjaxCalls('scopeHeaders');
      this.ajaxRequests['scopeHeaders'] = this.apiService
        .post(payload)
        .subscribe(res => {
          if (res instanceof Object) {
            if (res.responseStatus instanceof Object) {
              switch (res.responseStatus.code) {
                case 200:
                  if (res.result instanceof Object) {
                    if (res.result.headers instanceof Array) {
                      if (res.result.headers.length > 0) {
                        res.result.headerGroups.splice(0, 1);
                        const headersResult = {
                          result: {
                            headerGroups: res.result.headerGroups,
                            headers: res.result.headers.filter(
                              match => match.useForScopeData === 1
                            )
                          }
                        };
                        this.headersData = headersResult;
                        payload.formParams['templateId'] = this.chosenListValue[
                          'tmplId'
                        ];
                        this.adhocSubscriptionService.setCreatePayload(payload);
                        this.pullRecordCountFromServer = true;
                        this.gridConfig.setHeaderObject(this.headersData);
                        if (this.defaultFiltersApplied && !this.isRFQbasedRFQ) {
                          this.loadScopeData();
                        }
                        else if (this.isRFQbasedRFQ) {
                          this.partOfRfqIds = [];
                          for (const item of this.partExistingRFQs) {
                            if (item.rfq_start_qp === this.selectedStartQuotePeriod.quotePeriod &&
                              item.rfq_end_qp === this.selectedEndQuotePeriod.quotePeriod) {
                              this.partOfRfqIds = JSON.parse(item.cpn_group_id.value);
                              break;
                            }
                          }
                          if (this.deletedCpnGroupIds.length > 0) {
                            if (this.deletedCpnGroupIds.length === this.selectedScopeIds.length) {
                              this.showNoRecordsMsg = true;
                              this.showPartOfRfqIdsMsg = false;
                              this.showToasterPopup = true;
                              this.dialogConfiguration.postRfxCreation.message = `${this.deletedCpnGroupIds.length} out of ${this.selectedListForPopup.length} parts are not there in the RFQ system`;
                              this.dialogBoxService.open(this.dialogConfiguration.postRfxCreation.id)

                            } else {
                              if (this.partOfRfqIds.length > 0) {
                                this.showNoRecordsMsg = true;
                                this.showPartOfRfqIdsMsg = true;
                                this.showToasterPopup = true;
                                this.dialogConfiguration.postRfxCreation.message = `${this.deletedCpnGroupIds.length} out of ${this.selectedListForPopup.length} parts are not there in the RFQ system.  ${this.partOfRfqIds.length} out of ${this.selectedListForPopup.length} parts are part of other active RFQ/RFQ's`
                                this.dialogBoxService.open(this.dialogConfiguration.postRfxCreation.id)
                              }
                            }
                          } else {
                            if (this.partOfRfqIds.length > 0) {
                              this.showNoRecordsMsg = false;
                              this.showPartOfRfqIdsMsg = true;
                              this.showToasterPopup = true;
                              this.dialogConfiguration.postRfxCreation.message = ` ${this.partOfRfqIds.length} out of ${this.selectedListForPopup.length} parts are part of other active RFQ/RFQ's`
                              this.dialogBoxService.open(this.dialogConfiguration.postRfxCreation.id)
                            }
                          }
                          this.getSelectedScopeData(this.selectedListForPopup, 1, 2000);
                        }
                        this.gridHeadersLoaded.emit(this);
                      } else {
                        this.headersData = [];
                      }
                    } else {
                      this.headersData = [];
                    }
                  } else {
                    this.headersData = [];
                  }
                  break;
                default:
                  this.headersData = [];
                  break;
              }
            } else {
              this.headersData = [];
            }
          } else {
            this.headersData = [];
          }
        });
    }
    // this.gridConfig.setHeaderObject(this.headersData);
  }

  getRandomSample(array, count) {
    const indices = [];
    const result = new Array(count);
    for (let i = 0; i < count; i++) {
      const j = Math.floor(Math.random() * (array.length - i) + i);
      result[i] = array[indices[j] === undefined ? j : indices[j]];
      indices[j] = indices[i] === undefined ? i : indices[i];
    }
    return result;
  }

  configureForEditRFQ() {
    if (this.editRfq instanceof Object) {
      const selectedChooseFrom = this.chooseFromValues.filter(
        e => e.title.toLowerCase() === this.editRfq.source.toLowerCase()
      );
      if (
        selectedChooseFrom instanceof Array &&
        selectedChooseFrom.length > 0
      ) {
        this.selectedChooseFrom = selectedChooseFrom[0];
      }
      this.gridConfig.setHideCheckBoxForRowSelection(editRowNode => {
        if (editRowNode instanceof Object) {
          if (editRowNode.data instanceof Object) {
            if (editRowNode.data.restricted === 1) {
              if (
                this.selectedScopeIds.indexOf(
                  parseInt(editRowNode.data.cmspkId)
                ) > -1
              ) {
                // editRowNode.data.restricted = 0;
                return true;
              } else {
                return false;
              }
            } else {
              if (
                this.selectedScopeIds.indexOf(
                  parseInt(editRowNode.data.cmspkId)
                ) > -1
              ) {
                return true;
              }
              return true;
            }
          }
        }
      });
    } else {
      this.gridConfig.setHideCheckBoxForRowSelection(editRowNode => {
        if (editRowNode instanceof Object) {
          if (editRowNode.data instanceof Object) {
            if (editRowNode.data.restricted === 1) {
              /* if (
                this.selectedChooseFrom.title === 'RFQ' &&
                this.selectedScopeIds.length > 0 &&
                this.selectedScopeIds.indexOf(editRowNode.id) > -1
              ) {
                //editRowNode.data.restricted = 0;
                return true;
              } */
              return false;
            } else {
              return true;
            }
          }
        }
      });
    }
  }

  // preserve current sorted column state.
  preserveSortedColumnModel() {
    if (this.gridOptions && this.gridOptions.api) {
      this.sortedColomnModel = this.gridOptions.api.getSortModel();
    }
  }

  public loadScopeData(pageNumber = 1, pageSize = 2000, obj?) {
    let payload
    // this.preserveSortedColumnModel();
    this.editedRows.clear();
    this.enableSaveBtn = false;
    localStorage.removeItem(CUSTOMER_CELLS);
    if (!this.isRFQbasedRFQ) {
      if (this.chosenListValue instanceof Object) {
        this.showGrid = false;
        this.showPreloader = true;
        let templateId = this.chosenListValue['tmplId'];
        if (
          (this.selectedChooseFrom.title || '').toString().toLowerCase() ===
          'template'
        ) {
          templateId = this.chosenListValue['tmplId'];
        } else {
          templateId = this.chosenListValue['templateId'];
        }
        if (obj && obj.length) {
          payload = {
            formParams: {
              templateType: 'COMMODITY',
              page: 'CREATERFQ',
              rfqCustId: this.rfqId,
              startQuoteIndex: this.selectedStartQuotePeriod.id,
              endQuoteIndex: this.selectedEndQuotePeriod.id,
              startQuoteDate: this.selectedStartQuotePeriod.startQuoteDate,
              endQuoteDate: this.selectedEndQuotePeriod.endQuoteDate,
              refreshCache: this.DataFilterModel.refreshCache
            },
            payload: {
              pagingConfiguration: {
                pageNumber: pageNumber || 1,
                pageSize: pageSize || 2000,
                pullRecordCount: this.pullRecordCountFromServer
              },
              sortGroupCriteria: {
                sortDirection: obj[0].sort.toUpperCase(),
                sortList: [obj[0].colId]
              },
              filter: this.scopeDataFilters,
              filterBySource: this.sourceType
            },
            method: 'POST',
            servicePath: 'common/templates/' + templateId + '/cmsData',
            targetedType: 'RFQ'
          };
        }
        else {
          payload = {
            formParams: {
              templateType: 'COMMODITY',
              page: 'CREATERFQ',
              rfqCustId: this.rfqId,
              startQuoteIndex: this.selectedStartQuotePeriod.id,
              endQuoteIndex: this.selectedEndQuotePeriod.id,
              startQuoteDate: this.selectedStartQuotePeriod.startQuoteDate,
              endQuoteDate: this.selectedEndQuotePeriod.endQuoteDate,
              refreshCache: this.DataFilterModel.refreshCache
            },
            payload: {
              pagingConfiguration: {
                pageNumber: pageNumber || 1,
                pageSize: pageSize || 2000,
                pullRecordCount: this.pullRecordCountFromServer
              },
              filter: this.scopeDataFilters,
              filterBySource: this.sourceType
            },
            method: 'POST',
            servicePath: 'common/templates/' + templateId + '/cmsData',
            targetedType: 'RFQ'
          };
        }


        if (this.chatBotFilerData) {
          payload.payload.filter = JSON.parse(
            JSON.stringify(this.chatBotFilerData)
          );
          this.chatBotFilerData = undefined;
        }
        this.clearAjaxCalls('scopeData');
        this.ajaxRequests['scopeData'] = this.apiService
          .post(payload)
          .subscribe(res => {
            if (res instanceof Object) {
              if (res.responseStatus instanceof Object) {
                switch (res.responseStatus.code) {
                  case 200:
                    if (res.result instanceof Array) {
                      /**
                       * Post RFX Creation Changes
                       * Start
                       */
                      if (res.result && res.result.length > 0 && res.result[0].CALENDAR_DATE) {
                        res.result.forEach(element => {
                          let latest_date = new Date(element.CALENDAR_DATE).getTime();
                          element.CALENDAR_DATE = latest_date;
                        });
                      }
                      if (
                        this.postRfxCreationService.isPostRFXCreation() &&
                        this.postRfxCreationService.retrievePostRFXCreationDetails()
                          .addedScopeIds.length > 0
                      ) {
                        res.result = this.configurePostRFXCreationSelection(
                          res.result,
                          this.postRfxCreationService.retrievePostRFXCreationDetails()
                            .addedScopeIds
                        );
                      }
                      /**
                       * Post RFX Creation Changes
                       * End
                       */

                      // this.gridConfig.setFrameworkcomponent({ "agColumnHeader":CustomGridHeaderComponent  })
                      // let records = this.getRandomSample(res.result, Math.ceil(Math.random() * 10));
                      // this.gridOptions.paginationPageSize = 20;
                      this.gridConfig.setClientSideRecords(2000000000);
                      this.gridConfig.setEnableServerSidePagination(true);
                      this.gridConfig.setRowGrouping(true);
                      // this.gridConfig.setHeaderObject(this.headersData);
                      // this.gridConfig.setColumnGrouping(true)
                      this.gridConfig.setRowData(res.result);
                      this.gridConfig.setPaginationWithGotoPage(true);
                      this.gridConfig.setCheckbox(true);
                      this.gridConfig.setPrimaryId('cmspkId');
                      this.gridConfig.setSuppressMenuhide(true);
                      // this.configureForEditRFQ();
                      this.loadGridData(res.result, obj);
                      this.gridConfig.setPaginationWithGotoPage(false);
                      this.gridConfig.setPagination(false);
                      this.showGrid = true;
                      this.showPreloader = false;
                      const pageJson = res.pagingConfiguration;
                      if (this.pullRecordCountFromServer) {
                        this.totalRecords = res.pagingConfiguration.totalRecords;
                        this.totalNotPartofRFXRecords =
                          res.pagingConfiguration.totalNotPartRFQRecords;
                        this.pullRecordCountFromServer = false;
                      } else {
                        pageJson.totalRecords = this.totalRecords;
                      }
                      pageJson.pageCount = Math.ceil(
                        pageJson.totalRecords / pageJson.pageSize
                      );
                      this.dialogConfiguration['select_all']['message'] = `Do you want to select all (${this.totalRecords}) parts?`;
                      this.gridConfig.setPayload(payload);
                      this.gridConfig.setPageConfiguration(pageJson);
                    } else {
                      this.showGrid = false;
                    }
                    break;
                  default:
                    const type = 'warning';
                    let title = 'There is an error while loading RFQ Data';
                    if (
                      typeof res.message === 'string' &&
                      res.message.length > 0
                    ) {
                      title = res.message;
                      this.noRowsMessage = res.message;
                      this.showGrid = true;
                      this.gridConfig.setRowData([]);
                      this.loadGridData([]);
                    } else {
                      this.showGrid = false;
                    }
                    this.toastService.populateToaster(type, title);
                    break;
                }
              } else {
                this.showGrid = false;
              }
            } else {
              this.showGrid = false;
            }
          });
      }
    } else {
      if (this.gridOptions.rowData && this.gridOptions.rowData.length > 0) {
        this.showGrid = true;
      }
    }
  }


  public getSelectedScopeData(selectedKeyArray, pageNumber, pageSize) {
    if (
      this.chosenListValue instanceof Object &&
      this.selectedStartQuotePeriod instanceof Object &&
      this.selectedEndQuotePeriod instanceof Object
    ) {
      if (this.isRFQbasedRFQ) {
        this.pullRecordCountFromServer = true
      }
      pageSize = 5000
      this.showGrid = false;
      const payload = {
        templateId: this.chosenListValue['tmplId'],
        formParams: {
          startQuoteIndex: this.selectedStartQuotePeriod.id,
          startQuoteDate: this.selectedStartQuotePeriod.startQuoteDate,
          endQuoteDate: this.selectedEndQuotePeriod.endQuoteDate,
          endQuoteIndex: this.selectedEndQuotePeriod.id,
          rfqCustId: this.rfqId
        },
        method: 'POST',
        payload: {
          startQuoteIndex: this.selectedStartQuotePeriod.id,
          startQuoteDate: this.selectedStartQuotePeriod.startQuoteDate,
          endQuoteDate: this.selectedEndQuotePeriod.endQuoteDate,
          rfqCustId: this.rfqId,
          selectedIds: selectedKeyArray,
          pagingConfiguration: {
            pageNumber: pageNumber || 1,
            pageSize: pageSize || 2000,
            pullRecordCount: this.pullRecordCountFromServer
          },
          filter: this.scopeDataFilters
        },
        servicePath:
          'common/templates/' +
          this.chosenListValue['tmplId'] +
          '/cmsDataFilter',
        targetedType: 'RFQ'
      };
      if (
        (this.selectedChooseFrom.title || '').toString().toLowerCase() ===
        'template'
      ) {
        payload.templateId = this.chosenListValue['tmplId'];
        payload.servicePath =
          'common/templates/' +
          this.chosenListValue['tmplId'] +
          '/cmsDataFilter';
      } else {
        if (this.isRFQbasedRFQ) {
          if (this.deletedCpnGroupIds.length > 0) {
            if (this.deletedCpnGroupIds.length === this.selectedScopeIds.length) {
              this.selectedScopeIds = payload.payload.selectedIds = [];
            } else {
              if (this.partOfRfqIds.length > 0) {
                let deletedCpnDiff = [];
                let arrayDiff = [];
                deletedCpnDiff = this.apiCommonService.getArrayDiff(selectedKeyArray, this.deletedCpnGroupIds)
                arrayDiff = this.apiCommonService.getArrayDiff(deletedCpnDiff, this.partOfRfqIds)
                this.selectedScopeIds = payload.payload.selectedIds = arrayDiff;
              }
            }
          } else {
            let arrayDiff = []
            arrayDiff = this.apiCommonService.getArrayDiff(selectedKeyArray, this.partOfRfqIds)
            this.selectedScopeIds = payload.payload.selectedIds = arrayDiff;
          }
          if (this.selectedScopeIds.length === 0) {
            this.isRFQbasedRFQ = false;
            this.loadScopeData();
            return;
          }
        }
        payload.templateId = this.chosenListValue['templateId'];
        payload.servicePath =
          'common/templates/' +
          this.chosenListValue['templateId'] +
          '/cmsDataFilter';
      }
      this.clearAjaxCalls('scopeData');
      this.ajaxRequests['scopeData'] = this.apiService
        .post(payload)
        .subscribe(res => {
          if (res instanceof Object) {
            if (res.responseStatus instanceof Object) {
              switch (res.responseStatus.code) {
                case 200:
                  if (res.result instanceof Object) {
                    /**
                   * Post RFX Creation Changes
                   * Start
                   */

                    if (
                      this.postRfxCreationService.isPostRFXCreation() &&
                      this.postRfxCreationService.retrievePostRFXCreationDetails()
                        .addedScopeIds.length > 0
                    ) {
                      res.result = this.configurePostRFXCreationSelection(
                        res.result,
                        this.postRfxCreationService.retrievePostRFXCreationDetails()
                          .addedScopeIds
                      );
                    }
                    if ((this.selectedScopeIds.length === 0 || res.result.length === 0) && this.isRFQbasedRFQ) {
                      this.isRFQbasedRFQ = false;
                      this.loadScopeData();
                    } else {
                      // this.gridOptions.paginationPageSize = 20;
                      this.gridConfig.setClientSideRecords(2000000000);
                      this.gridConfig.setEnableServerSidePagination(true);
                      this.gridConfig.setRowGrouping(true);
                      // this.gridConfig.setHeaderObject(this.headersData);
                      // this.gridConfig.setColumnGrouping(true)
                      this.gridConfig.setRowData(res.result);
                      this.gridConfig.setPaginationWithGotoPage(true);
                      this.gridConfig.setCheckbox(true);
                      this.gridConfig.setPrimaryId('cmspkId');
                      this.gridConfig.setSuppressMenuhide(true);
                      // this.configureForEditRFQ();
                      this.loadGridData(res.result);
                      this.gridConfig.setPaginationWithGotoPage(false);
                      this.gridConfig.setPagination(false);
                      this.showGrid = true;
                      this.showPreloader = false;
                      const pageJson = res.pagingConfiguration;
                      if (this.pullRecordCountFromServer) {
                        this.totalRecords = res.pagingConfiguration.totalRecords;
                        this.totalNotPartofRFXRecords =
                          res.pagingConfiguration.totalNotPartRFQRecords;
                        this.pullRecordCountFromServer = false;
                      } else {
                        pageJson.totalRecords = this.totalRecords;
                      }
                      pageJson.pageCount = Math.ceil(
                        pageJson.totalRecords / pageJson.pageSize
                      );
                      this.dialogConfiguration['select_all']['message'] = `Do you want to select all (${this.totalRecords}) parts?`;
                      this.gridConfig.setPayload(payload);
                      this.gridConfig.setPageConfiguration(pageJson);
                      if (this.isRFQbasedRFQ && res.result.length > 0) {
                        this.showSelectedFlag = true
                      }
                      this.isRFQbasedRFQ = false;
                    }
                  }
                  break;
                default:
                  break;
              }
            } else {
            }
          } else {
          }
        });
    }
  }

  public getSelectedScopeDataforSelectAll(
    deSelectedKeyArray,
    pageNumber = 1,
    pageSize = 2000
  ) {
    if (
      this.chosenListValue instanceof Object &&
      this.selectedStartQuotePeriod instanceof Object &&
      this.selectedEndQuotePeriod instanceof Object
    ) {
      this.showGrid = false;
      const payload = {
        templateId: this.chosenListValue['tmplId'],
        formParams: {
          startQuoteIndex: this.selectedStartQuotePeriod.id,
          startQuoteDate: this.selectedStartQuotePeriod.startQuoteDate,
          endQuoteDate: this.selectedEndQuotePeriod.endQuoteDate,
          endQuoteIndex: this.selectedEndQuotePeriod.id,
          rfqCustId: this.rfqId
        },
        method: 'POST',
        payload: {
          startQuoteIndex: this.selectedStartQuotePeriod.id,
          startQuoteDate: this.selectedStartQuotePeriod.startQuoteDate,
          endQuoteDate: this.selectedEndQuotePeriod.endQuoteDate,
          rfqCustId: this.rfqId,
          selectAllRec: true,
          selectedIds: [],
          // deSelectedIds: deSelectedKeyArray,
          pagingConfiguration: {
            pageNumber: pageNumber || 1,
            pageSize: pageSize || 2000,
            pullRecordCount: this.pullRecordCountFromServer
          },
          filter: this.scopeDataFilters
        },
        servicePath:
          'common/templates/' +
          this.chosenListValue['tmplId'] +
          '/cmsDataFilter',
        targetedType: 'RFQ'
      };
      if (
        (this.selectedChooseFrom.title || '').toString().toLowerCase() ===
        'template'
      ) {
        payload.templateId = this.chosenListValue['tmplId'];
        payload.servicePath =
          'common/templates/' +
          this.chosenListValue['tmplId'] +
          '/cmsDataFilter';
      } else {
        payload.templateId = this.chosenListValue['templateId'];
        payload.servicePath =
          'common/templates/' +
          this.chosenListValue['templateId'] +
          '/cmsDataFilter';
      }
      this.clearAjaxCalls('scopeData');
      this.ajaxRequests['scopeData'] = this.apiService
        .post(payload)
        .subscribe(res => {
          if (res instanceof Object) {
            if (res.responseStatus instanceof Object) {
              switch (res.responseStatus.code) {
                case 200:
                  if (res.result instanceof Object) {
                    this.gridConfig.setClientSideRecords(2000000000);
                    this.gridConfig.setRowGrouping(true);
                    // this.gridConfig.setHeaderObject(this.headersData);
                    // this.gridConfig.setColumnGrouping(true)
                    this.gridConfig.setPaginationWithGotoPage(true);
                    this.gridConfig.setCheckbox(true);
                    this.gridConfig.setPrimaryId('cmspkId');
                    // this.configureForEditRFQ();
                    this.gridConfig.setEnableServerSidePagination(true);
                    this.gridConfig.setRowData(res.result);
                    this.loadGridData(res.result);
                    this.gridConfig.setPaginationWithGotoPage(false);
                    this.gridConfig.setPagination(false);
                    this.showGrid = true;
                    const pageJson = res.pagingConfiguration;
                    if (this.pullRecordCountFromServer) {
                      this.totalRecords =
                        res.pagingConfiguration.totalNotPartRFQRecords;
                      this.totalNotPartofRFXRecords =
                        res.pagingConfiguration.totalNotPartRFQRecords;
                      this.pullRecordCountFromServer = false;
                    } else {
                      pageJson.totalRecords = this.totalRecords;
                    }
                    pageJson.pageCount = Math.ceil(
                      pageJson.totalRecords / pageJson.pageSize
                    );
                    this.gridConfig.setPayload(payload);
                    this.gridConfig.setPageConfiguration(pageJson);
                  }
                  break;
                default:
                  break;
              }
            } else {
            }
          } else {
          }
        });
    }
  }

  public loadTemplatesList(cb?: Function) {
    return new Promise((resolve, reject) => {
      const payload = {
        method: 'GET',
        servicePath: 'common/templates/COMMODITY',
        targetedType: 'RFQ'
      };
      this.clearAjaxCalls('templatesList');
      this.ajaxRequests['templatesList'] = this.apiService
        .post(payload)
        .subscribe(res => {
          if (res instanceof Object) {
            if (res.responseStatus instanceof Object) {
              switch (res.responseStatus.code) {
                case 200:
                  if (res.result instanceof Object) {
                    if (res.result instanceof Array) {
                      res.result = res.result.filter(function (i) {
                        return i.rfqTypeName.toString().toLowerCase() === 'rfq';
                      });
                    }
                    this.templatesList = res.result;

                    /**
                     * Post RFX Creation Changes
                     * Start
                     */

                    if (this.postRfxCreationService.isPostRFXCreation()) {
                      this.templatesList = this.retrievePostRFXCreationTemplate(
                        this.templatesList
                      );
                    }

                    /**
                     * Post RFX Creation Changes
                     * End
                     */
                    if (this.selectedChooseFrom instanceof Object) {
                      if (
                        (this.selectedChooseFrom.title || '')
                          .toString()
                          .toLowerCase() === 'template'
                      ) {
                        this.chosenList = this.templatesList;
                        this.chosenListKey = 'tmplName';
                      } else if (
                        (this.selectedChooseFrom.title || '')
                          .toString()
                          .toLowerCase() === 'rfq' &&
                        this.editRfq instanceof Object &&
                        typeof this.editRfq.sourceId === 'number'
                      ) {
                        const sourceRFq = this.rfqsList.filter(
                          i =>
                            (i.rfqCustId || i.rfqId) === this.editRfq.sourceId
                        );
                        const selectedScopeIds = this.selectedScopeIds;
                        this.chosenListValue =
                          sourceRFq instanceof Array && sourceRFq.length > 0
                            ? sourceRFq[0]
                            : this.rfqsList[0];
                        this.selectedScopeIds = selectedScopeIds;
                      }
                    }
                    if (
                      (this.selectedChooseFrom.title || '')
                        .toString()
                        .toLowerCase() === 'template' &&
                      this.editRfq instanceof Object &&
                      typeof this.editRfq.templateId === 'number' &&
                      this.rfqId > 0
                    ) {
                      const selectItems = this.chosenList.filter(
                        match =>
                          (match.tmplId || match.templateId) ===
                          this.editRfq.templateId
                      );
                      // console.log(selectItems);
                      this.chosenListValue = selectItems[0];
                    } else if (
                      (this.selectedChooseFrom.title || '')
                        .toString()
                        .toLowerCase() === 'template' &&
                      this.rfqId > 0
                    ) {
                      const selectItems = this.chosenList.filter(
                        match =>
                          (match.tmplId || match.templateId) ===
                          (this.chosenListValue.tmplId ||
                            this.chosenListValue.templateId)
                      );
                      if (selectItems.length > 0) {
                        this.chosenListValue = selectItems[0];
                      } else {
                        throw new Error(
                          ' Previously selected template is not available in the current list '
                        );
                      }
                    } else if (!(this.chosenListValue instanceof Object)) {
                      this.chosenListValue = this.chosenList[0];
                    }

                    if (cb instanceof Function) {
                      cb();
                      return;
                    }
                  }
                  break;
                default:
                  this.templatesList = [];
                  break;
              }
            } else {
              this.templatesList = [];
            }
          } else {
            this.templatesList = [];
          }
          resolve();
        });
    });
  }

  public loadTemplatesListForOrigin(origin: string, cb?: Function) {
    return new Promise((resolve, reject) => {
      const payload = {
        method: 'GET',
        servicePath: 'common/templates/COMMODITY/' + origin,
        targetedType: 'RFQ'
      };
      this.clearAjaxCalls('templatesList');
      this.ajaxRequests['templatesList'] = this.apiService
        .post(payload)
        .subscribe(res => {
          if (res instanceof Object) {
            if (res.responseStatus instanceof Object) {
              switch (res.responseStatus.code) {
                case 200:
                  if (res.result instanceof Object) {
                    if (res.result instanceof Array) {
                      res.result = res.result.filter(function (i) {
                        return i.rfqTypeName.toString().toLowerCase() === 'rfq';
                      });
                    }
                    this.templatesList = res.result;

                    /**
                     * Post RFX Creation Changes
                     * Start
                     */

                    if (this.postRfxCreationService.isPostRFXCreation()) {
                      this.templatesList = this.retrievePostRFXCreationTemplate(
                        this.templatesList
                      );
                    }

                    /**
                     * Post RFX Creation Changes
                     * End
                     */
                    if (this.selectedChooseFrom instanceof Object) {
                      if (
                        (this.selectedChooseFrom.title || '')
                          .toString()
                          .toLowerCase() === 'template'
                      ) {
                        this.chosenList = this.templatesList;
                        this.chosenListKey = 'tmplName';
                      } else if (
                        (this.selectedChooseFrom.title || '')
                          .toString()
                          .toLowerCase() === 'rfq' &&
                        this.editRfq instanceof Object &&
                        typeof this.editRfq.sourceId === 'number'
                      ) {
                        const sourceRFq = this.rfqsList.filter(
                          i =>
                            (i.rfqCustId || i.rfqId) === this.editRfq.sourceId
                        );
                        const selectedScopeIds = this.selectedScopeIds;
                        this.chosenListValue =
                          sourceRFq instanceof Array && sourceRFq.length > 0
                            ? sourceRFq[0]
                            : this.rfqsList[0];
                        this.selectedScopeIds = selectedScopeIds;
                      }
                    }
                    if (
                      (this.selectedChooseFrom.title || '')
                        .toString()
                        .toLowerCase() === 'template' &&
                      this.editRfq instanceof Object &&
                      typeof this.editRfq.templateId === 'number' &&
                      this.rfqId > 0
                    ) {
                      const selectItems = this.chosenList.filter(
                        match =>
                          (match.tmplId || match.templateId) ===
                          this.editRfq.templateId
                      );
                      // console.log(selectItems);
                      this.chosenListValue = selectItems[0];
                    } else if (
                      (this.selectedChooseFrom.title || '')
                        .toString()
                        .toLowerCase() === 'template' &&
                      this.rfqId > 0
                    ) {
                      const selectItems = this.chosenList.filter(
                        match =>
                          (match.tmplId || match.templateId) ===
                          (this.chosenListValue.tmplId ||
                            this.chosenListValue.templateId)
                      );
                      if (selectItems.length > 0) {
                        this.chosenListValue = selectItems[0];
                      } else {
                        throw new Error(
                          ' Previously selected template is not available in the current list '
                        );
                      }
                    } else if (!(this.chosenListValue instanceof Object)) {
                      this.chosenListValue = this.chosenList[0];
                    }

                    if (cb instanceof Function) {
                      cb();
                      return;
                    }
                  }
                  break;
                default:
                  this.templatesList = [];
                  break;
              }
            } else {
              this.templatesList = [];
            }
          } else {
            this.templatesList = [];
          }
          resolve();
        });
    });
  }

  public loadRFQsList() {
    return new Promise((resolve, reject) => {
      const payload = {
        formParams: {
          templateType: 'COMMODITY',
          rfqStatus: 'CLOSED',
          orderBy: 'name'
        },
        method: 'GET',
        servicePath: 'rfqlist',
        // servicePath: 'listrfq',
        targetedType: 'RFQ'
      };
      this.clearAjaxCalls('rfqsList');
      this.ajaxRequests['rfqsList'] = this.apiService
        .post(payload)
        .subscribe(res => {
          if (res instanceof Array) {
            if (res.length > 0) {
              this.rfqsList = res;
            } else {
              this.rfqsList = [];
            }
          } else {
            this.rfqsList = [];
          }
          if (this.rfqsList.length === 0) {
            this.chooseFromValues.forEach(i => {
              if (i.title === 'RFQ') {
                i.disabled = true;
              }
            });
          }
          if (this.selectedChooseFrom instanceof Object) {
            if (
              (this.selectedChooseFrom.title || '').toString().toLowerCase() ===
              'rfq'
            ) {
              this.chosenList = this.rfqsList;
              this.chosenListKey = 'rfqCustName';
            }
          }
          resolve();
        });
    });
  }
  getPreviousQuarter() {
    if (this.timeSelectionDataService.getFullTimePeriodObject()) {
      if (this.timeSelectionDataService.getFullTimePeriodObject().currentQuarter) {
        this.previousQuarter = 'FY' + this.timeSelectionDataService.getFullTimePeriodObject().currentQuarter.year.toString().substr(-2) + ' ' +
          this.timeSelectionDataService.getFullTimePeriodObject().currentQuarter.quarter;
      }
    }
  }
  public loadRFQDataForSelectedRFQ(cb?) {
    return new Promise((resolve, reject) => {
      if (this.chosenListValue instanceof Object) {
        const payload = {
          method: 'GET',
          servicePath:
            'rfq/' + (this.chosenListValue.rfqCustId || null) + '/basicData',
          targetedType: 'RFQ'
        };
        this.apiService.post(payload).subscribe(res => {
          if (res instanceof Object) {
            if (res.responseStatus instanceof Object) {
              switch (res.responseStatus.code) {
                case 200:
                  if (res.result instanceof Object) {
                    this.selectedListForPopup = this.selectedScopeIds = res.result.scopeConfig.selectedIds;
                    this.partExistingRFQs = res.result.scopeConfig.partOfRfqCpnGroupIds;
                    for (const item of res.result.scopeConfig.partOfRfqCpnGroupIds) {
                      if (item.rfq_start_qp === this.selectedStartQuotePeriod.quotePeriod &&
                        item.rfq_end_qp === this.selectedEndQuotePeriod.quotePeriod) {
                        this.partOfRfqIds = JSON.parse(item.cpn_group_id.value);
                        break;
                      }

                    }
                    this.deletedCpnGroupIds = res.result.scopeConfig.deletedCpnGroupIds;
                    if (this.deletedCpnGroupIds.length > 0) {
                      if (this.deletedCpnGroupIds.length === this.selectedScopeIds.length) {
                        this.showNoRecordsMsg = true;
                        this.showPartOfRfqIdsMsg = false;
                        this.showToasterPopup = true;
                        this.dialogConfiguration.postRfxCreation.message = `${this.deletedCpnGroupIds.length} out of ${this.selectedListForPopup.length} parts are not there in the RFQ system`;
                        this.dialogBoxService.open(this.dialogConfiguration.postRfxCreation.id)

                      } else {
                        if (this.partOfRfqIds.length > 0) {
                          this.showNoRecordsMsg = true;
                          this.showPartOfRfqIdsMsg = true;
                          this.showToasterPopup = true;
                          this.dialogConfiguration.postRfxCreation.message = `${this.deletedCpnGroupIds.length} out of ${this.selectedListForPopup.length} parts are not there in the RFQ system.  ${this.partOfRfqIds.length} out of ${this.selectedListForPopup.length} parts are part of other active RFQ/RFQ's`
                          this.dialogBoxService.open(this.dialogConfiguration.postRfxCreation.id)
                        }
                      }
                    } else {
                      if (this.partOfRfqIds.length > 0) {
                        this.showNoRecordsMsg = false;
                        this.showPartOfRfqIdsMsg = true;
                        this.showToasterPopup = true;
                        this.dialogConfiguration.postRfxCreation.message = ` ${this.partOfRfqIds.length} out of ${this.selectedListForPopup.length} parts are part of other active RFQ/RFQ's`
                        this.dialogBoxService.open(this.dialogConfiguration.postRfxCreation.id)
                      }
                    }
                    resolve();
                  }
                  break;
              }
            }
          }
        });
      }
    });
  }

  public loadQuartersForSelectedTemplate(cb?: Function) {
    this.getPreviousQuarter();
    return new Promise((resolve, reject) => {
      if (this.chosenListValue instanceof Object) {
        this.showGrid = false;
        const payload = {
          formParams: {
            templateType: 'COMMODITY',
            page: 'CREATERFQ'
          },
          method: 'POST',
          servicePath: 'common/templates/getQuarterList',
          targetedType: 'RFQ'
        };
        this.clearAjaxCalls('quartersForSelectedTemplate');
        this.ajaxRequests['quartersForSelectedTemplate'] = this.apiService
          .post(payload)
          .subscribe(res => {
            if (res instanceof Object) {
              if (res.responseStatus instanceof Object) {
                switch (res.responseStatus.code) {
                  case 200:
                    if (res.result instanceof Object) {
                      this.startQuotePeriods = res.result;
                      const refrray = [];
                      refrray.push({
                        quotePeriod: this.previousQuarter
                      }
                      )
                      this.referenceBaselineQtr = refrray
                      this._selectedreferenceBaselineQtr = this.previousQuarter
                      if (this.rfqId > 0 && this.editRfq instanceof Object) {
                        const selectItems = this.startQuotePeriods.filter(
                          match =>
                            match.quotePeriod === this.editRfq.startQuotePeriod
                        );
                        if (selectItems instanceof Array && selectItems.length > 0) {
                          this.selectedStartQuotePeriod = selectItems[0];
                          this.showDeleteDraftPopup = false;
                        } else {
                          this.showDeleteDraftPopup = true;
                          this.dialogBoxService.open(this.dialogConfiguration['deleteDraft'].id);
                        }
                      } else {
                        if (this.postRfxCreationService.isPostRFXCreation()) {
                          this.selectedStartQuotePeriod = this.retrievePostRFXCreationQuotePeriods(
                            this.startQuotePeriods,
                            'startQuoteDate'
                          )[0];
                        } else {
                          this.selectedStartQuotePeriod = this.startQuotePeriods[0];
                        }
                      }
                    }
                    break;
                  default:
                    this.startQuotePeriods = [];
                    this.endQuotePeriods = [];
                    break;
                }
              } else {
                this.startQuotePeriods = [];
                this.endQuotePeriods = [];
              }
            } else {
              this.startQuotePeriods = [];
              this.endQuotePeriods = [];
            }
            if (cb instanceof Function) {
              cb();
              return;
            }
            resolve();
          });
      }
    });
  }

  private loadDefaultObject() {
    if (this.isFirstTime) {
      const deSelectedScopeIds = JSON.parse(
        JSON.stringify(this.deSelectedScopeIds)
      );
      this.defaultObject = {
        type: 'sustain',
        rfqType: 'RFQ',
        source: this.selectedChooseFrom.title.toUpperCase(),
        sourceId: this.chosenListValue.rfqCustId,
        schedule: {
          confirmTime: DateUtils.getUTCTimeByMoment(this.confirmDate),
          questionsTime: DateUtils.getUTCTimeByMoment(this.questionsDate),
          submissionTime: DateUtils.getUTCTimeByMoment(this.submissionDate),
          expectedAwardTime: DateUtils.getUTCTimeByMoment(
            this.expectedAwardedDate
          )
        },
        scopeName: 'COMMODITY',
        templateId:
          (this.selectedChooseFrom.title || '').toString().toLowerCase() ===
            'template'
            ? this.chosenListValue.tmplId
            : this.chosenListValue.templateId,
        startQuotePeriod: this.selectedStartQuotePeriod.quotePeriod,
        startQuoteDate: this.selectedStartQuotePeriod.startQuoteDate,
        startQuoteIndex: this.selectedStartQuotePeriod.id,
        endQuotePeriod: this.selectedEndQuotePeriod.quotePeriod,
        endQuoteDate: this.selectedEndQuotePeriod.endQuoteDate,
        endQuoteIndex: this.selectedEndQuotePeriod.id,
        name: this.name,
        description: this.description,
        customerName: 'LEVADATA',
        scopeConfig: {
          // filter: JSON.parse(JSON.stringify(this.scopeDataFilters)),
          selectedIds: JSON.parse(JSON.stringify(this.selectedScopeIds)),
          selectAllRec: this.selectAllFlag,
          deSelectedIds: deSelectedScopeIds
        },
        rfqId: this.rfqId
      };
      this.isFirstTime = false;
    }
  }

  showOnlySelectedRows(rows?: any, pageNumber?: number, pageSize?: number) {
    if (this.selectAllFlag && this.showSelectedFlag) {
      this.getSelectedScopeDataforSelectAll(
        // this.deSelectedScopeIds,
        pageNumber,
        pageSize
      );
    } else {
      if (!rows) {
        // rows = this.fullGridData;
        this.loadScopeData();
      } else if (rows instanceof Array) {
        if (!this.selectAllFlag) {
          return this.getSelectedScopeData(
            this.selectedScopeIds,
            pageNumber,
            pageSize
          );
        } else {
          // rows here is deselected list
          return this.getSelectedScopeDataforSelectAll(
            // this.deSelectedScopeIds,
            pageNumber,
            pageSize
          );
        }
      }
    }

    // this.loadGridData(rows);
  }

  selectIdsForSelectAll(sourceType) {
    return new Promise((resolve, reject) => {
      if (this.chosenListValue instanceof Object) {
        let templateId = this.chosenListValue['tmplId'];
        if (
          (this.selectedChooseFrom.title || '').toString().toLowerCase() ===
          'template'
        ) {
          templateId = this.chosenListValue['tmplId'];
        } else {
          templateId = this.chosenListValue['templateId'];
        }
        const payload = {
          formParams: {
            templateType: 'COMMODITY',
            page: 'CREATERFQ',
            rfqCustId: this.rfqId,
            startQuoteIndex: this.selectedStartQuotePeriod.id,
            endQuoteIndex: this.selectedEndQuotePeriod.id,
            startQuoteDate: this.selectedStartQuotePeriod.startQuoteDate,
            endQuoteDate: this.selectedEndQuotePeriod.endQuoteDate,
            refreshCache: this.DataFilterModel.refreshCache
          },
          payload: {
            filter: this.scopeDataFilters,
            filterBySource: this.sourceType
          },
          method: 'POST',
          servicePath: 'common/templates/' + templateId + '/cmsDataIds',
          targetedType: 'RFQ'
        };
        this.clearAjaxCalls('selectAllIDs');
        this.ajaxRequests['selectAllIDs'] = this.apiService
          .post(payload)
          .subscribe(res => {
            if (res instanceof Object && res.responseStatus instanceof Object) {
              switch (res.responseStatus.code) {
                case 200:
                  if (res.result instanceof Array && res.result.length > 0) {
                    resolve(res.result);
                    return;
                  }
                  break;
              }
            }
            reject();
          });
      }
    });
  }

  configurePostRFXCreation(pageNumber?: number, pageSize?: number) {
    if (this.postRfxCreationService.isPostRFXCreation()) {
      const postRFXCreationDetails = this.postRfxCreationService.retrievePostRFXCreationDetails();
      this.retreiveScopeDataByRFQPayload(
        this.selectedScopeIds,
        postRFXCreationDetails,
        pageNumber,
        pageSize
      );
    }
  }

  cmsDataByRFXServiceCall(payload: any) {
    if (this.showExistingRFXItems) {
      payload.payload.selectedIds = [];
    }
    this.showGrid = false;
    this.clearAjaxCalls('scopeData');
    this.ajaxRequests['scopeData'] = this.apiService
      .post(payload)
      .subscribe(res => {
        if (res instanceof Object) {
          if (res.responseStatus instanceof Object) {
            switch (res.responseStatus.code) {
              case 200:
                if (res.result instanceof Object) {
                  /**
                  * Post RFX Creation Changes
                  * Start
                  */

                  if (
                    this.postRfxCreationService.isPostRFXCreation() &&
                    this.postRfxCreationService.retrievePostRFXCreationDetails()
                      .addedScopeIds.length > 0
                  ) {
                    res.result = this.configurePostRFXCreationSelection(
                      res.result,
                      this.postRfxCreationService.retrievePostRFXCreationDetails()
                        .addedScopeIds
                    );
                  }

                  /**
                   * Post RFX Creation Changes
                   * End
                   */
                  this.gridConfig.setClientSideRecords(2000000000);
                  this.gridConfig.setRowGrouping(true);
                  // this.gridConfig.setHeaderObject(this.headersData);
                  // this.gridConfig.setColumnGrouping(true)
                  this.gridConfig.setPaginationWithGotoPage(true);
                  this.gridConfig.setCheckbox(true);
                  this.gridConfig.setPrimaryId('cmspkId');
                  // this.configureForEditRFQ();
                  this.gridConfig.setEnableServerSidePagination(true);
                  this.gridConfig.setRowData(res.result);
                  this.loadGridData(res.result);
                  this.gridConfig.setPaginationWithGotoPage(false);
                  this.gridConfig.setPagination(false);
                  this.showGrid = true;
                  const pageJson = res.pagingConfiguration;
                  if (this.pullRecordCountFromServer) {
                    this.totalRecords = res.pagingConfiguration.totalRecords;
                    this.totalNotPartofRFXRecords =
                      res.pagingConfiguration.totalNotPartRFQRecords;
                    this.pullRecordCountFromServer = false;
                  } else {
                    pageJson.totalRecords = res.pagingConfiguration.totalRecords;
                  }
                  pageJson.pageCount = Math.ceil(
                    pageJson.totalRecords / pageJson.pageSize
                  );
                  this.gridConfig.setPayload(payload);
                  this.gridConfig.setPageConfiguration(pageJson);
                }
                break;
              default:
                break;
            }
          } else {
          }
        } else {
        }
      });
  }

  retreiveScopeDataByRFQPayload(
    selectedKeyArray: number[],
    postRFXCreationDetails: any,
    pageNumber: number,
    pageSize: number
  ) {
    const payload = {
      templateId: this.chosenListValue['tmplId'],
      formParams: {
        startQuoteIndex: this.selectedStartQuotePeriod.id,
        startQuoteDate: this.selectedStartQuotePeriod.startQuoteDate,
        endQuoteDate: this.selectedEndQuotePeriod.endQuoteDate,
        endQuoteIndex: this.selectedEndQuotePeriod.id,
        rfqCustId: postRFXCreationDetails.rfqDetails.rfqCustId
      },
      method: 'POST',
      payload: {
        startQuoteIndex: this.selectedStartQuotePeriod.id,
        startQuoteDate: this.selectedStartQuotePeriod.startQuoteDate,
        endQuoteDate: this.selectedEndQuotePeriod.endQuoteDate,
        rfqCustId: postRFXCreationDetails.rfqDetails.rfqCustId,
        selectedIds: selectedKeyArray,
        pagingConfiguration: {
          pageNumber: pageNumber || 1,
          pageSize: pageSize || 2000,
          pullRecordCount: true
        },
        filter: []
      },
      servicePath:
        'common/templates/' +
        this.chosenListValue['tmplId'] +
        '/cmsdatafilterbyrfq',
      targetedType: 'RFQ'
    };
    this.cmsDataByRFXServiceCall(payload);
  }

  private retrievePostRFXCreationTemplate(templatesList: any[]) {
    if (
      this.postRfxCreationService
        .retrievePostRFXCreationDetails()
        .rfqDetails.hasOwnProperty('tmplId')
    ) {
      return templatesList.filter(
        template =>
          template.tmplId ===
          this.postRfxCreationService.retrievePostRFXCreationDetails()
            .rfqDetails.tmplId
      );
    }
  }

  private retrievePostRFXCreationQuotePeriods(
    quotePeriods: any[],
    quoteDateType: string
  ) {
    if (
      this.postRfxCreationService
        .retrievePostRFXCreationDetails()
        .rfqDetails.hasOwnProperty(quoteDateType)
    ) {
      return quotePeriods.filter(
        quotePeriod =>
          quotePeriod[quoteDateType] ===
          this.postRfxCreationService.retrievePostRFXCreationDetails()
            .rfqDetails[quoteDateType]
      );
    }
  }

  private configurePostRFXCreationSelection(
    scopeRowData: any[],
    addedScopeIds: number[]
  ) {
    addedScopeIds.forEach(addedScopeId => {
      const addedScopeDataObject = scopeRowData.find(
        scopeDataRow => scopeDataRow.cmspkId === addedScopeId
      );
      if (addedScopeDataObject instanceof Object) {
        addedScopeDataObject.restricted = 0;
        const scopeDataIndex = this.findObjectIndex(
          scopeRowData,
          addedScopeDataObject,
          HEADER_OBJECT.CMSPKID
        );
        if (scopeDataIndex !== -1) {
          scopeRowData[scopeDataIndex] = addedScopeDataObject;
        }
      }
    });
    return scopeRowData;
  }

  findObjectIndex(rowArray: Object[], rowObject: Object, key: string): number {
    return rowArray.findIndex(x => x[key] === rowObject[key]);
  }

  /**
   * Post RFX Creation Changes
   * End
   */
}
