import { Injectable } from '@angular/core';

export class PreviewSend {
  disableSave = true;
  disableSend = false;
  showSend = true;
  disableDelete = false;
  clearDraftedTargetCosts = false;

  defaultPayload: any = {
    listOfSupplierLeverInfo: [],
    messageToSupplierDTO: [],
    templateRows: [],
    shareTrgtleverDTO: []
  };
  isShareTargetCostError = false;
  processerPayload: any = {
    listOfSupplierLeverInfo: [],
    messageToSupplierDTO: [],
    templateRows: [],
    shareTrgtleverDTO: []
  };

  sharedStatusLTC: any = {};

  mappedLeversInfo: any = [];
  mpnLevelLevers: any = [];

  rfqStatsDimensions: any = [];
  rfqPreviewDimensions: any = [];
  selectedDimen= undefined;
  selectedDimenValue= undefined;
  activeTab= 'STATS';

  uploadIdentifier: any;
}
