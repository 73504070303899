import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { SubNavBarService } from 'app/modules/common/sub-nav-bar.service';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { SubscriptionLike } from 'rxjs';
import { BaseService } from 'app/modules/common/base.service';
import { Location } from '@angular/common';

@Component({
  selector: 'sub-nav-tab-bar',
  templateUrl: 'sub-nav-tab-bar.component.html',
  styleUrls: ['./sub-nav-tab-bar.component.sass'],
  providers: [SubNavBarService],
})
export class SubNavTabBarComponent implements OnInit, OnDestroy, OnChanges {
  subNavTabBar: any;
  scopeSubscription: SubscriptionLike;
  datastoragesub: SubscriptionLike;
  focus: string;
  @Input() target: string;
  routerarray: Array<string> = [];
  constructor(
    private route: ActivatedRoute,
    private subNavBarService: SubNavBarService,
    private scopeDataService: ScopeDataService,
    private router: Router,
    private dataStorageService: DataStorageService,
    private baseService: BaseService
  ) {
    this.route.queryParams.subscribe((params) => {
      if (Object.keys(params)[0] === 'manufacturer') {
        this.router.navigate(['/app/mi/partnerscorecard/manufacturerscorecard'])
      }
    });
    this.scopeSubscription = this.dataStorageService
      .getSelectedFocus()
      .subscribe((value) => {
        if (value !== undefined) {
          this.updateTabs(value);
        }
      });
  }
  ngOnInit() {
    this.routerarray = this.router.url.split('/');
    this.focus = this.scopeDataService.getFocusValue();
    if (this.focus !== undefined) {
      this.dataStorageService.setSelectedFocus(this.focus);
    }

    this.scopeDataService.getFocus().subscribe((value) => {
      this.dataStorageService.setSelectedFocus(value);
    });
  }

  updateTabs(value) {
    if (value === 'Commodity') {
      this.subNavTabBar = this.subNavBarService.getSubNavBarList(this.target);
    } else if (value === 'Product') {
      this.subNavTabBar = this.subNavBarService.getProductSubNavBarList(
        this.target
      );
    }
    let newTabs: any[] = [];
    switch (this.target) {
      case 'curator-industry':
        newTabs = [
          {
            id: 98,
            level: 0,
            order: 0,
            title: 'Feed',
            view: 'feed',
          },
          {
            id: 99,
            level: 0,
            order: 0,
            title: 'Category',
            view: 'category',
          },
          {
            id: 100,
            level: 0,
            order: 0,
            title: 'Events',
            view: 'ai-events',
          },
        ];
        break;
      case 'curator-leva':
        newTabs = [
          {
            id: 95,
            level: 0,
            order: 0,
            title: 'Prod Usage',
            view: 'product-usage',
          },
          {
            id: 96,
            level: 0,
            order: 0,
            title: 'Training',
            view: 'training',
          },
          {
            id: 97,
            level: 0,
            order: 0,
            title: 'Dashboard',
            view: 'levadashboard',
          },
        ];
        break;
      case 'news-content':
        newTabs = [
          {
            id: 101,
            level: 0,
            order: 0,
            title: 'News',
            view: 'home',
          },
          {
            id: 102,
            level: 0,
            order: 0,
            title: 'Events',
            view: 'events',
          },
        ];
        break;
      case 'Super Admin Configuration':
        newTabs = [
          // {
          //   id: 95,
          //   level: 0,
          //   order: 0,
          //   title: 'CREATED',
          //   view: 'ums-success',
          // }
          // {
          //   id: 95,
          //   level: 0,
          //   order: 1,
          //   title: 'FAILED',
          //   view: 'ums-failed',
          // },

          // {
          //   id: 95,
          //   level: 0,
          //   order: 2,
          //   title: 'CREATE',
          //   view: 'create-superadmin'
          // }

        ];
        break;
      case 'Vendor Sites Management':
        newTabs = [
          {
            id: 640,
            order: 0,
            title: 'My Vendor Sites',
            view: 'my-vendor-sites',
            level: 0,
            privilegeMap: null,
          },
          {
            id: 641,
            order: 0,
            title: 'Community Vendor Sites',
            view: 'community-vendor-sites',
            level: 0,
            privilegeMap: null,
          }
        ];
        break;
      case 'Risk Summary':
        newTabs = [
          {
            id: 1040,
            order: 0,
            title: 'Product Risk',
            view: 'product',
            level: 0,
            privilegeMap: null,
          },
          {
            id: 1041,
            order: 0,
            title: 'Commodity Risk',
            view: 'commodity',
            level: 0,
            privilegeMap: null,
          }
        ]
        break;
    }
    if (newTabs.length) {
      this.subNavTabBar = newTabs;
    }
  }

  ngOnChanges() {
    this.routerarray = this.router.url.split('/');
    this.datastoragesub = this.scopeDataService
      .getFocus()
      .subscribe((value) => {
        this.dataStorageService.setSelectedFocus(value);
      });
    this.updateTabs(this.focus);
  }

  ngOnDestroy() {
    if (this.scopeSubscription) {
      this.scopeSubscription.unsubscribe();
    }
    if (this.datastoragesub) {
      this.datastoragesub.unsubscribe();
    }
  }

  onTabClick(event) {
    this.dataStorageService.setSelectedRMTab(event.target.innerHTML);
    this.scopeDataService.setManualSelection(true);
    this.scopeDataService.settype('manual');
    const obj = {
      type : 'manual',
      selectedTab : event.target.innerHTML
    }
    this.dataStorageService.setSelectedSubNavTab(obj);
  }
}
