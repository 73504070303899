import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { WindowService } from '../../common/window.service'


@Injectable()
export class ConfigLocator {
    constructor(private http: HttpClient, private windowService: WindowService) {
    }

  getConfiguration(): Observable<Configuration> {
      return this.http.get<Configuration>(`${this.windowService.nativeWindow.location.pathname}assets/config.json`).pipe(
      catchError(error => {
        throw new Error('Config file could not be found');
      }));
  }
}

export type FeatureFlagConfiguration = {
  serverUrl: string;
  clientKey: string;
}
export type Configuration = {
  featureFlags: FeatureFlagConfiguration;
}
