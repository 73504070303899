import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy
} from '@angular/core';
import { Subject } from 'rxjs';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { Create } from 'app/modules/srfq/create/shared/create.model';
import { NegotiationLeversService } from 'app/modules/srfq/common/negotiation-levers/shared/negotiation-levers.service';
import { PreviewSend } from 'app/modules/srfq/create/preview-send/shared/preview-send.model';
import { RfqPreview } from 'app/modules/srfq/common/rfq-preview/shared/rfq-preview.model';
import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'negotiation-levers',
  templateUrl: 'negotiation-levers.component.html',
  styleUrls: ['./negotiation-levers.component.sass'],
  providers: []
})
export class NegotiationLeversComponent implements OnInit, OnChanges, OnDestroy {
  private subject$: Subject<any> = new Subject();
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  newLeverName: any;
  newLeverDescription: any;
  negotiationLevers: any = [];
  isClassVisible = false;
  leversLoaded: any = false;
  showNoData: any = false;
  supplierList: any = [];
  newLeverSupplier: any;
  defaultSelectedSup: any;
  tabValue: string;
  showForm: boolean;
  firstLoad = false;
  titleObj: any;

  @Input() selectedView: any;
  @Input() selectedViewValue: any;

  @Output() leverSelectionChange = new EventEmitter<any>();
  @Output() customLeverAdded = new EventEmitter<any>();
  dropdownResetVar = Math.random();
  constructor(
    private negotiationLeversService: NegotiationLeversService,
    private dataStorageService: DataStorageService,
    private rfqInformation: Create,
    private previewModel: PreviewSend,
    public previewTabModel: RfqPreview,
    private customerConfigurationService: CustomerConfigurationService
  ) {
    this.titleObj = this.customerConfigurationService.getRFQCustomDimensions();
  }

  ngOnInit() {
    // this.firstLoad = true;
    // this.getLeverList();
  }

  ngOnChanges() {
    this.firstLoad = true;
    this.getLeverList();
  }

  getLeverList() {
    this.leversLoaded = false;
    this.negotiationLevers = [];

    const custViewType = {
      custViewTypeVal: [this.selectedViewValue],
      dispMapping: this.selectedView.dispMapping
    };
    const payload = {
      targetedType: 'RFQ',
      formParams: {
        rfqCustId: this.rfqInformation.rfqId
      },
      method: 'POST',
      payload: custViewType,
      servicePath: 'getLeversByCustView'
    };
    // console.log(payload);

    this.negotiationLeversService.getLeversList(payload)
      .pipe(takeUntil(this.subject$))
      .subscribe(data => {
        // console.log(data);
        if (
          data instanceof Object &&
          data.responseStatus instanceof Object &&
          data.responseStatus.code === 200 &&
          data.result instanceof Array
        ) {
          this.negotiationLevers = data.result;
          this.previewTabModel.supplierLevers = this.negotiationLevers;
          this.leversLoaded = true;
          if (this.negotiationLevers.length > 0) {
            this.showNoData = false;
          } else {
            this.showNoData = true;
          }
          this.createMPNLeverMap();

          if (
            this.previewModel.processerPayload.listOfSupplierLeverInfo.length > 0
          ) {
            this.negotiationLevers.forEach(i => (i.leverMapped = false));
            for (const plLever of this.previewModel.processerPayload
              .listOfSupplierLeverInfo) {
              const payloadLevers = this.negotiationLevers.filter(
                match =>
                  match.leverId === plLever.leverId &&
                  match.supId === plLever.supId
              );
              if (payloadLevers.length > 0) {
                payloadLevers[0].leverMapped = plLever.leverMapped;
              }
            }
          } else {
            /*  this.negotiationLevers.forEach(i => {
              this.onLeverClick(i);
            }); */
          }
          // console.log(this.previewModel.processerPayload.listOfSupplierLeverInfo)
        }
      });
  }

  onClickAddLever() {
    this.leversLoaded = false;
    const payload = {
      targetedType: 'RFQ',
      formParams: {
        rfqId: this.rfqInformation.rfqId,
        leverName: this.newLeverName,
        leverText: this.newLeverDescription,
        supId: this.newLeverSupplier.supplierId
      },
      method: 'GET',
      servicePath: 'levers/addCustomlevers'
    };

    this.negotiationLeversService.addLeverToSupplier(payload)
      .pipe(takeUntil(this.subject$))
      .subscribe(ack => {
        if (ack.responseStatus.code === 200) {
          this.customLeverAdded.emit();
          this.getLeverList();
          this.onClickCancel();
        } else {
        }
      });
  }

  onClickCancel() {
    this.newLeverName = null;
    this.newLeverDescription = null;
    this.defaultSelectedSup = this.supplierList[0];
    this.newLeverSupplier = this.defaultSelectedSup;
    this.dropdownResetVar = Math.random();
  }

  onLeverClick(selectedLever) {
    if (selectedLever.leverMapped) {
      selectedLever.modifiedLeverText = selectedLever.leverText;
      if (this.previewModel.processerPayload.listOfSupplierLeverInfo.length) {
        const supplierLeverInfo = this.previewModel.processerPayload.listOfSupplierLeverInfo.filter(
          leverInfo =>
            leverInfo.leverId === selectedLever.leverId &&
            leverInfo.supId === selectedLever.supId
        );
        if (supplierLeverInfo.length) {
          supplierLeverInfo[0].leverMapped = selectedLever.leverMapped;
        } else {
          this.previewModel.processerPayload.listOfSupplierLeverInfo.push(
            selectedLever
          );
        }
      } else {
        this.previewModel.processerPayload.listOfSupplierLeverInfo.push(
          selectedLever
        );
        const supplierLeverInfo = this.previewModel.processerPayload.listOfSupplierLeverInfo.filter(
          leverInfo =>
            leverInfo.leverId === selectedLever.leverId &&
            leverInfo.supId === selectedLever.supId
        );
        if (supplierLeverInfo.length) {
          supplierLeverInfo[0].leverMapped = selectedLever.leverMapped;
        } else {
          this.previewModel.processerPayload.listOfSupplierLeverInfo.push(
            selectedLever
          );
        }
      }
      this.leverSelectionChange.emit(selectedLever);
      this.createMPNLeverMap();
    } else {
      if (
        this.previewModel.processerPayload.listOfSupplierLeverInfo.length === 0
      ) {
        this.previewModel.processerPayload.listOfSupplierLeverInfo.push(
          selectedLever
        );
      } else {
        const extIndex = this.previewModel.processerPayload.listOfSupplierLeverInfo.findIndex(
          function (element, index, array) {
            if (element.leverId === selectedLever.leverId) {
              return true;
            }
          }
        );
        selectedLever.modifiedLeverText = '';
        if (extIndex > -1) {
          this.previewModel.processerPayload.listOfSupplierLeverInfo.splice(
            extIndex,
            1
          );
          this.removeListForMpn(selectedLever);
        } else {
          this.previewModel.processerPayload.listOfSupplierLeverInfo.push(
            selectedLever
          );
        }
      }
    }
    // console.log(this.previewModel.processerPayload.listOfSupplierLeverInfo);
    this.leverSelectionChange.emit(selectedLever);
  }

  createMPNLeverMap() {
    const allLevers = this.previewModel.processerPayload.listOfSupplierLeverInfo;
    const mappedLevers = allLevers.filter(
      mappedLever => mappedLever.leverMapped === true
    );
    mappedLevers.forEach(element => {
      this.createLeversforMPN(element);
    });
    this.previewModel.mappedLeversInfo = mappedLevers;
  }

  createLeversforMPN(currentLever) {
    if (currentLever instanceof Object) {
      if (currentLever.mpnLeverInfo instanceof Array) {
        if (currentLever.mpnLeverInfo.length > 0) {
          currentLever.mpnLeverInfo.forEach(element => {
            let obj: object;
            obj['leverId'] = currentLever.leverId;
            obj['cmspkId'] = element.cmspkId;
            obj['leverText'] = element.leverText;
            this.previewModel.mpnLevelLevers.push(obj);
          });
        }
      }
    }
  }

  removeListForMpn(currentLever) {
    const foundItems = this.previewModel.mpnLevelLevers.filter(
      mappedLever => mappedLever.leverId === currentLever.leverId
    );

    foundItems.forEach(obj => {
      const extIndex = this.previewModel.mpnLevelLevers.findIndex(function (
        element,
        index,
        array
      ) {
        if (element.leverId === obj.leverId) {
          return true;
        }
      });
      this.previewModel.mpnLevelLevers.splice(extIndex, 1);
    });
  }

  onSupplierChange(supplier) {
    this.newLeverSupplier = supplier;
  }

  getAddButtonDisability() {
    return (
      !((this.newLeverName || '').trim().length > 0) ||
      !((this.newLeverDescription || '').trim().length > 0)
    );
  }
  ngOnDestroy() {
    this.subject$.next();
    this.subject$.complete();
  }
}
