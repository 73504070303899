import { Injectable } from '@angular/core';
import { ApiServiceProvider } from 'app/api-service';

@Injectable()
export class SrfxDataProvider {

    status: string;

    constructor(public apiServiceProvider: ApiServiceProvider) {

    }

    setRFQStatus(status: string) {
        this.status = status;
    }

    getRFQStatus() {
        return this.status;
    }

    /*----------------- GET RFQ INFORMATION -----------------  */
    getDetailedRFXInfo(request) {
        const payload = {
            method: 'GET',
            targetedType: 'RFQ',
            formParams: {}
        };

        switch (request.type) {
            case 'E':
            case 'R':
                payload['formParams']['rfqId'] = request.rfxId;
                payload['servicePath'] = 'common/getRFQNameByRfqId';
                break;
            default:
                payload['servicePath'] = 'rfx/' + request.rfxId;
                break;
        }
        return this.apiServiceProvider.post(payload);
    }

    public validateServiceData(data) {
        if (data instanceof Object) {
            if (data['responseStatus'] instanceof Object) {
                switch (data['responseStatus']['code']) {
                    case 200:
                        return data;
                    default:
                        return 'error';
                }
            }
        }
    }

}
