import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnChanges
} from '@angular/core';
import { RmAutosearchService } from 'app/modules/core/rm-autosearch/shared/rm-autosearch.service';
import { RequestData } from 'app/modules/common/models/request.model';
import { SubscriptionLike } from 'rxjs';
import { Subject } from 'rxjs';
import { UtilService } from 'app/modules/common/utills/util.service';
import { SubscriptionService } from 'app/modules/common/subscription.service';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { DropDownListService } from 'app/modules/common/dropdown-list.service';
import { ApplicationURLS } from 'app/modules/common/applicationURL';
import { RawmaterialnewService } from 'app/modules/mi/rawmaterialnew/shared/rawmaterialnew.service';
import { RmOpprisksService } from 'app/modules/mi/rawmaterialnew/rmOpprisks/shared/rmOpprisks.service';
import { request } from 'https';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';

@Component({
  selector: 'rm-autosearch',
  templateUrl: 'rm-autosearch.component.html',
  styleUrls: ['./rm-autosearch.component.sass'],
  providers: [RmAutosearchService]
})
export class RmAutosearchComponent implements OnInit, OnChanges {
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  @Input() requestData: RequestData;
  private autosearchSubscription: SubscriptionLike;
  _customDimensionData: any[] = new Array();
  fullData: any = [];
  tempArray: any[] = new Array();
  selectedArray: any[] = new Array();
  inputOptions: any[] = new Array();
  multiType: boolean;
  type: any;
  selArray = [];
  typeOfSelectSingle: boolean;
  searchTerm$ = new Subject<string>();
  search: any = null;
  autoSearchSelectedValue: any = '';
  singleSearchSelectedValue: any = 'Select';
  showDiv: any = 'hide';
  count = 0;
  mutliSelectCrossIcon: any = 'hide';
  multiSelectSearchIcon: any = 'show';
  autoSearchCrossIcon: any = 'hide';
  autoSearchSearchIcon: any = 'show';
  manualOptionsView: any = 'show';
  searchResult: any = [];
  searchInputLimit: Number = 1;
  @Input() public removeOrShowItem: any;
  showOrHideDropdown = 'hide';
  showSelectedValue = false;
  rmdata: any;
  bhanuCall;
  selectedDropDown: any;
  dimensitionValue: any;
  disbleCheckAll: any = false;
  disableClearAll: any = false;
  custData: any = [];
  custDropdownData: any = [];
  set customDimensionData(val) {
    this._customDimensionData = val;
    if ((this.search || '').length === 0) {
      this.fullData = JSON.parse(JSON.stringify(val));
    }
  }

  get customDimensionData() {
    return this._customDimensionData;
  }

  constructor(
    private searchService: RmAutosearchService,
    private utilService: UtilService,
    private subscriptionService: SubscriptionService,
    private dataStorageService: DataStorageService,
    private ref: ChangeDetectorRef,
    private applicationURLS: ApplicationURLS,
    private rawMaterial: RawmaterialnewService,
    private rmOppriskService: RmOpprisksService,
    private dropDownlistService: DropDownListService
  ) { }

  ngOnInit() { }

  ngOnChanges(changes) {
    if (this.requestData instanceof Object) {
      this.onLoad();
    }
  }
  onClickOutside(event: Object) {
    this.showOrHideDropdown = 'hide';
    this.showDiv = 'hide';
  }

  onDropDownClick() {
    if (this.showOrHideDropdown === 'hide') {
      this.showOrHideDropdown = 'show';
    } else if (this.showOrHideDropdown === 'show') {
      this.showOrHideDropdown = 'hide';
    }
  }

  processSelectedText() {
    this.tempArray = [];
    this.searchResult = [];
    for (const item of this.requestData.options) {
      const filteredItem = this.customDimensionData.filter(custItem => {
        if (custItem.value.toLowerCase() === item.toLowerCase()) {
          return custItem;
        }
      });
      if (filteredItem.length) {
        filteredItem[0].check = true;
      }
      this.tempArray.push(filteredItem[0]);
    }
    switch (this.requestData.options.length) {
      case 1:
        this.searchResult.push(this.requestData.options[0]);
        break;
      default:
        if (this.requestData.selectAll == true) {
          for (const data of this.customDimensionData) {
            data.check = true;
            this.selectedArray.push(data);
            this.searchResult = this.searchResult.slice();
            this.ref.markForCheck();
          }
          this.searchResult.push(
            'All Raw Materials Selected ' +
            '(' +
            this.customDimensionData.length +
            ')'
          );
        }
        else {
          if (
            this.customDimensionData.length === this.requestData.options.length
          ) {
            this.searchResult.push(
              this.requestData.selectAllText +
              ' (' +
              this.requestData.options.length +
              ')'
            );
          } else {
             
            this.searchResult.push(
              ' selected ' + '(' + this.requestData.options.length + ')'
            );
          }
        }

        break;
    }
  }

  onLoad() {
    this.disbleCheckAll = false;
    this.disableClearAll = false;

    this.search = null;
    // this.searchResult.length = 0;
    this.selectedArray.length = 0;
    this.searchResult = [];
    this.singleSearchSelectedValue = 'Select';
    this.customDimensionData = [];
    this.searchInputLimit = this.requestData.searchInputLimit;
    this.type = this.requestData.type;
    this.typeOfSelectSingle = this.requestData.typeOfSelectSingle;
    this.inputOptions = [];
    this.customDimensionData = [];
    this.showSelectedValue = this.requestData.showSelectedValue;
    if (this.type === 'multi') {
      if (this.requestData.options.length >= 0) {
        // If options are provided manually .. this case will execute

        this.searchService
          .searchCustomEntries('0', this.requestData)
          .subscribe(results => {
            if (results instanceof Object) {
              if (results['result'] instanceof Object) {
                if (results['result'].rmSet !== undefined) {
                  if (results['result'].rmSet instanceof Array) {
                    this.customDimensionData = this.searchService.onConvertCustomMulti(
                      results['result'].rmSet
                    );
                  } else {
                    this.customDimensionData = this.searchService.onConvertCustomMulti(
                      [results['result'].rmSet]
                    );
                  }
                  this.processSelectedText();
                  // --
                  if (
                    this.removeOrShowItem && this.customDimensionData.length > 0) {
                    const optionsObject = {
                      value: this.removeOrShowItem.displayName,
                      check: this.removeOrShowItem.selected,
                      dimension: this.removeOrShowItem.dimensionName,
                    };
                    if (
                      this.customDimensionData.filter(
                        (i) => i.value === optionsObject.value
                      ).length === 0
                    ) {
                      this.customDimensionData.unshift({
                        value: this.removeOrShowItem.displayName,
                        check: this.removeOrShowItem.selected,
                        dimension: this.removeOrShowItem.dimensionName,
                      });
                    }
                    const event = {
                      target: { checked: this.removeOrShowItem.selected },
                    };
                    //  this.searchedItem.length = 0;
                    this.selectedArray.length = 0;
                    this.searchResult.length = 0;
                    this.onCheckBoxSelect(event, optionsObject);
                    this.customDimensionData.forEach((element) => {
                      if (element.value === this.removeOrShowItem.displayName) {
                        element.check = true;
                      } else {
                        element.check = false;
                      }
                    });
                  }

                  //--
                  if (!this.requestData.isSubscribed) {
                    this.subscriptionService.setDpdwnRequestData({
                      val: this.customDimensionData.filter(function (i) {
                        return i.check;
                      }),
                      target: this.requestData.target
                    });
                  }
                } else if (results['result'].list !== undefined) {
                  this.subscriptionService.setDpdwnData({
                    val: results['result'].list
                  });
                  this.customDimensionData = this.searchService.onConvertCustomMulti(
                    results['result'].list
                  );
                  this.customDimensionData.forEach(item => {
                    item.source = item.value;
                    item.check = false;
                    item.value = item.source.name;
                  });
                  this.processSelectedText();
                  if (!this.requestData.isSubscribed) {
                    this.subscriptionService.setDpdwnRequestData({
                      val: this.customDimensionData.filter(function (i) {
                        return i.check;
                      }),
                      target: this.requestData.target
                    });
                  }
                }
              }
            }
            this.count = this.customDimensionData.length;
          });
        if (this.requestData.options.length === 0 && this.removeOrShowItem === undefined) {
          this.searchService
            .searchCustomEntries('0', this.requestData)
            .subscribe(results => {
              if (results instanceof Object) {
                if (results['result'] instanceof Object) {
                  if (results['result'].rmSet !== undefined) {
                    if (results['result'].rmSet instanceof Array) {
                      this.customDimensionData = this.searchService.onConvertCustomMulti(
                        results['result'].rmSet
                      );
                    } else {
                      this.customDimensionData = this.searchService.onConvertCustomMulti(
                        [results['result'].rmSet]
                      );
                    }
                    // setTimeout(()=>{},1200);
                    this.searchResult = [];
                    if (this.requestData.selectAll) {
                      this.customDimensionData.forEach(item => {
                        item.check = true;
                      });
                      this.tempArray = JSON.parse(
                        JSON.stringify(this.customDimensionData)
                      );
                      this.customDimensionData = JSON.parse(
                        JSON.stringify(this.customDimensionData)
                      );
                      if (results['result'].rmSet.length > 0) {
                        this.searchResult.push(
                          'All Raw Materials Selected ' +
                          '(' +
                          results['result'].rmSet.length +
                          ')'
                        );
                      }
                    } else {
                      this.tempArray = [];
                      if (this.requestData.payload.rmList.length === 0) {
                        this.requestData.payload.rmList = this.tempArray;
                      }
                      if (this.requestData.hasOwnProperty('defaultValue')) {
                        this.customDimensionData.forEach(item => {
                          if (item.value === this.requestData.defaultValue) {
                            item.check = true;
                          }
                        });
                      } else {
                        this.customDimensionData.forEach(item => {
                          if (
                            this.requestData.payload.rmList.indexOf(item.value) !=
                            -1
                          ) {
                            item.source = item.value;
                            item.check = true;
                            item.value = item.source.name
                              ? item.source.name
                              : item.source;
                          }
                        });
                      }
                      this.customDimensionData = JSON.parse(
                        JSON.stringify(this.customDimensionData)
                      );
                      this.custData = [];
                      this.tempArray = this.custData = this.customDimensionData.filter(
                        i => i.check === true
                      );
                      const count = this.custData.length;
                      if (
                        this.tempArray.length === this.customDimensionData.length
                      ) {
                        this.searchResult.push(
                          ' All Raw Materials Selected  ' + '(' + count + ')'
                        );
                      } else {
                        switch (this.custData.length) {
                          case 0:
                            this.searchResult.push('Select');
                            break;
                          case 1:
                            this.searchResult.push(this.custData[0].value);
                            break;
                          default:
                             
                            this.searchResult.push(
                              ' Selected ' + '(' + count + ')'
                            );
                            break;
                        }
                      }
                    }
                    this.inputOptions = this.customDimensionData;
                    this.subscriptionService.setDpdwnRequestData({
                      val: this.customDimensionData,
                      target: this.requestData.target
                    });
                  } else if (results['result'].list !== undefined) {
                    this.subscriptionService.setDpdwnData({
                      val: results['result'].list
                    });
                    if (results['result'].list.length === undefined) {
                      this.customDimensionData = this.searchService.onConvertCustomMulti(
                        [results['result'].list]
                      );
                    } else {
                      this.customDimensionData = this.searchService.onConvertCustomMulti(
                        results['result'].list
                      );
                    }

                    this.searchResult = [];
                    if (this.requestData.selectAll) {
                      this.customDimensionData.forEach(item => {
                        item.source = item.value;
                        item.check = true;
                        item.value = item.source.name;
                      });
                      if (results['result'].list.length === undefined) {
                        this.searchResult.push(
                          'All Values Selected ' + '(' + 1 + ')'
                        );
                      } else {
                        this.searchResult.push(
                          'All Values Selected ' +
                          '(' +
                          results['result'].list.length +
                          ')'
                        );
                      }
                      this.tempArray = JSON.parse(
                        JSON.stringify(this.customDimensionData)
                      );
                      // this.subscriptionService.setAutoSearchText({ "val": this.customDimensionData, "title": this.requestData.title });
                    } else if (!this.requestData.selectAll) {
                      this.tempArray = [];

                      const selection = [];
                      if (this.requestData.hasOwnProperty('defaultValue')) {
                        this.customDimensionData.forEach(item => {
                          if (item.value.name === this.requestData.defaultValue) {
                            item.source = item.value;
                            item.check = true;
                            item.value = item.source.name;
                            selection.push(item);
                          } else {
                            item.source = item.value;
                            item.check = false;
                            item.value = item.source.name;
                          }
                        });
                      } else {
                        this.customDimensionData.forEach(item => {
                          item.source = item.value;
                          item.check = true;
                          item.value = item.source.name;
                          selection.push(item);
                        });
                      }
                      this.custData = [];
                      this.tempArray = this.custData = this.customDimensionData.filter(
                        i => i.check === true
                      );
                      const count = this.custData.length;
                      if (
                        this.tempArray.length === this.customDimensionData.length
                      ) {
                        this.searchResult.push(
                          ' All Raw Materials Selected  ' + '(' + count + ')'
                        );
                      } else {
                         
                        switch (this.custData.length) {
                          case 0:
                            this.searchResult.push('Select');
                            break;
                          case 1:
                            this.searchResult.push(this.custData[0].value);
                            break;
                          default:
                             
                            this.searchResult.push(
                              ' Selected ' + '(' + count + ')'
                            );
                            break;
                        }
                      }

                    } else {
                      const selection = [];
                      this.customDimensionData.forEach(item => {
                        if (
                          this.requestData.payload.rmList.indexOf(
                            item.value.rmList
                          ) !== -1
                        ) {
                          item.source = item.value;
                          item.check = true;
                          item.value = item.source.name;
                          selection.push(item);
                        } else {
                          item.source = item.value;
                          item.check = false;
                          item.value = item.source.name;
                        }
                      });
                      this.tempArray = JSON.parse(
                        JSON.stringify(this.customDimensionData)
                      );
                      // this.subscriptionService.setAutoSearchText({ "val": selection, "title": this.requestData.title });
                    }
                    this.fullData = this.customDimensionData;
                    this.inputOptions = this.customDimensionData;
                  } else {
                    this.customDimensionData = this.searchService.onConvertCustomMulti(
                      results['result'].items
                    );
                  }
                } else {
                  this.customDimensionData = [];
                  this.inputOptions = this.customDimensionData;

                  // this.subscriptionService.setAutoSearchText({ "val": this.customDimensionData, "title": this.requestData.title });
                }
              } else {
                this.customDimensionData = [];
                this.inputOptions = this.customDimensionData;
                // this.subscriptionService.setAutoSearchText({ "val": this.customDimensionData, "title": this.requestData.title });
              }
              this.count = this.customDimensionData.length;
            });

        }
        this.count = this.customDimensionData.length;
      }



    } else if (this.type === 'single') {
      // write logic
      if (this.requestData.options.length > 0) {
        // If options are provided manually .. this case will execute
        if (this.requestData.defaultValue !== undefined) {
          this.singleSearchSelectedValue = this.requestData.defaultValue;
        } else {
          const optionsObject = {
            value: ''
          };
          optionsObject.value = 'Select';
          this.singleSearchSelectedValue = optionsObject;
        }
        this.manualOptionsView = 'hide';
        for (const option of this.requestData.options) {
          // optionsObject.value =(option.title==='undefined')? option : option.title;
          this.customDimensionData.push(option);
        }
        this.count = this.customDimensionData.length;
      } else {
        // if data need to be fetched from elastic search or custom service below code will execute
        if (this.requestData.targetedType === 'ELS') {
          this.searchService
            .searchMultiEntriesELS('', this.requestData)
            .subscribe(results => {
              if (!results['result']) {
                return false;
              }
              this.customDimensionData = this.searchService.onConvertArrayObjectKeysWithDimension(
                results['result'][0].hits,
                this.requestData.payload.dimension
              );
              this.count = this.customDimensionData.length;
            });
        } else {
          this.searchService
            .searchCustomEntries('0', this.requestData)
            .subscribe(results => {
              if (results['result'].rmSet !== undefined) {
                if (results['result'].rmSet instanceof Array) {
                  this.inputOptions = this.customDimensionData = this.searchService.onConvertCustomMulti(
                    results['result'].rmSet
                  );
                } else {
                  this.inputOptions = this.customDimensionData = this.searchService.onConvertCustomMulti(
                    [results['result'].rmSet]
                  );
                }
              } else {
                this.customDimensionData = this.searchService.onConvertCustomMulti(
                  results['result'].items
                );
              }

              this.count = this.customDimensionData.length;
            });
        }
      }
    } else if (this.type === 'tree') {
      // write logic
      this.searchService
        .searchCustomEntries('55', this.requestData)
        .subscribe(results => {
          this.customDimensionData = this.searchService.onConvertTree(
            results['result']
          );
          this.count = this.customDimensionData.length;
          if (this.requestData.defaultSelection !== undefined) {
            this.searchResult.push(this.requestData.defaultSelection);
          }
        });
    } else if (this.type === 'multitree') {
      // write logic
      this.searchService
        .searchCustomEntries('55', this.requestData)
        .subscribe(results => {
          this.customDimensionData = this.searchService.onConvertMultiTree(
            results['result']
          );
          this.count = this.customDimensionData.length;
          if (this.requestData.defaultSelection !== undefined) {
            this.searchResult.push(this.requestData.defaultSelection);
          }
        });
    }
  }

  // Auto Search Related Code

  onAutoSearchKey(event: any) {
    this.autoSearchSelectedValue = event.target.value;
    if (this.autoSearchSelectedValue === '') {
      this.showDiv = 'hide';
      this.autoSearchCrossIcon = 'hide';
      this.autoSearchSearchIcon = 'show';
      this.customDimensionData.length = 0;
      return;
    } else {
      this.showDiv = 'show';
      this.autoSearchSearchIcon = 'hide';
      this.autoSearchCrossIcon = 'show';
    }
    this.searchTerm$.next(event.target.value);
    this.searchService
      .searchMultiELS(this.searchTerm$, this.requestData)
      .subscribe(results => {
        if (!results['result']) {
          return false;
        }
        if (this.requestData.targetedType === 'ELS') {
          this.customDimensionData = this.searchService.onConvertArrayObjectKeysWithDimension(
            results['result'][0].hits,
            this.requestData.payload.dimension
          );
        } else if (this.requestData.targetedType === 'MANUAL') {
        } else {
          this.customDimensionData.length = 0;
        }
      });
  }

  onAutoSearchKeyDimension(event: any) {
    this.autoSearchSelectedValue = event.target.value;
    if (this.autoSearchSelectedValue === '') {
      this.showDiv = 'hide';
      this.autoSearchCrossIcon = 'hide';
      this.autoSearchSearchIcon = 'show';
      this.customDimensionData.length = 0;
      return;
    } else {
      this.showDiv = 'show';
      this.autoSearchSearchIcon = 'hide';
      this.autoSearchCrossIcon = 'show';
    }
    this.searchTerm$.next(event.target.value);
    this.searchService
      .searchMultiELS(this.searchTerm$, this.requestData)
      .subscribe(results => {
        if (this.requestData.targetedType === 'ELS') {
          this.customDimensionData = this.searchService.onConvertArrayForAutoDimnsionSelect(
            results['result'][0].hits,
            this.requestData.payload.dimension
          );
        } else if (this.requestData.targetedType === 'MANUAL') {
        } else {
          this.customDimensionData.length = 0;
        }
      });
  }

  onSelectOfAutoSeachValue(val: String) {
    this.autoSearchSelectedValue = val;
    this.showDiv = 'hide';
  }

  onAutoDimensionSelect(key: any, keyValue: any) {
    this.autoSearchSelectedValue = keyValue;
    setTimeout(() => {
      this.autoSearchSelectedValue = '';
    }, 500);
    this.showDiv = 'hide';
    const val = {
      dimension: '',
      value: ''
    };
    val.dimension = key;
    val.value = keyValue;
    this.subscriptionService.setAutoSearchText(val);
  }

  clearAutoSearchText(event: any) {
    this.autoSearchSelectedValue = '';
    this.showDiv = 'hide';
    this.autoSearchCrossIcon = 'hide';
    this.autoSearchSearchIcon = 'show';
  }

  onAutoSearchClickOnSearchIcon() {
    this.subscriptionService.setAutoSearchText(this.autoSearchSelectedValue);
  }

  // autosearch related code end

  // MultiSelect code start

  onMultiSelectKey(event: any) {
    this.search = event.target.value;
    this.searchTerm$.next(event.target.value);
    //  
    if (this.requestData.targetedType === 'ELS') {
      this.onMultiELS();
    } else {
      this.onMultiCustom();
    }
  }

  onMultiELS() {
    this.searchService
      .searchMultiELS(this.searchTerm$, this.requestData)
      .subscribe(results => {
        if (!results['result']) {
          return false;
        }
        this.customDimensionData = this.searchService.onConvertArrayObjectKeysWithDimension(
          results['result'][0].hits,
          this.requestData.payload.dimension
        );
        if (this.search === '') {
          this.multiSelectSearchIcon = 'show';
          this.mutliSelectCrossIcon = 'hide';
          for (const selv of this.selectedArray) {
            for (const k of this.customDimensionData) {
              if (k.value === selv.value) {
                this.customDimensionData.splice(
                  this.customDimensionData.indexOf(k),
                  1
                );
              }
            }
            this.customDimensionData.unshift(selv);
          }
        } else {
          this.mutliSelectCrossIcon = 'show';
          this.multiSelectSearchIcon = 'hide';
          for (const selv of this.selectedArray) {
            for (const k of this.customDimensionData) {
              if (k.value === selv.value) {
                k.check = true;
              }
            }
          }
        }
        this.count = this.customDimensionData.length;
        if (this.requestData.unmatchedSearch) {
          const unMatchedObject = {
            value: '',
            check: false
          };
          unMatchedObject.value = this.search;
          if (this.count === 0) {
            this.customDimensionData.push(unMatchedObject);
            this.count = 1;
          } else {
            let valueExists = false;
            for (const res of this.customDimensionData) {
              if (this.search.toUpperCase() === res.value.toUpperCase()) {
                valueExists = true;
                break;
              }
            }
            if (!valueExists) {
              if (unMatchedObject.value !== '') {
                this.customDimensionData.unshift(unMatchedObject);
                this.count = this.count + 1;
              }
            }
          }
        }
      });
  }
  quote(regex) {
    return regex.replace(/([()[{*+.$^\\|?])/g, '\\$1');
  }

  onMultiCustom() {
    if (this.requestData.options.length > 0) {
      this.customDimensionData = [];
      this.inputOptions = [];
      if (this.selectedArray.length > 0) {
        for (const i of this.selectedArray) {
          this.selArray.push(i);
        }
      }
      this.selArray = this.removeArrayDuplicates(this.selArray);
      if (this.search === '') {
        this.multiSelectSearchIcon = 'show';
        this.mutliSelectCrossIcon = 'hide';
      } else {
        this.mutliSelectCrossIcon = 'show';
        this.multiSelectSearchIcon = 'hide';
      }

      for (const data of this.requestData.options) {
        if (data.toLowerCase().includes(this.search.toLowerCase())) {
          const optionsObject = {
            value: '',
            check: false
          };
          optionsObject.value = data;

          if (this.requestData.selectAll) {
            // this.inputOptions.push(optionsObject);
            optionsObject.check = true;
          } else {
            optionsObject.check = false;
          }
          for (const val of this.selArray) {
            if (data.includes(val.value)) {
              optionsObject.check = val.check;
            }
          }
          // optionsObject.value = data;
          this.customDimensionData.push(optionsObject);
        }
      }
      this.count = this.customDimensionData.length;
    } else {
      if (this.requestData.title === 'RM') {
        let dimData = JSON.parse(JSON.stringify(this.fullData));
        if (this.search === '') {
          for (const selv of this.selectedArray) {
            for (const k of dimData) {
              if (k.value === selv.value) {
                dimData.splice(dimData.indexOf(k), 1);
              }
            }
            dimData.unshift(selv);
          }
          this.inputOptions = this.customDimensionData = dimData;
          this.count = this.customDimensionData.length;
          this.multiSelectSearchIcon = 'show';
          this.mutliSelectCrossIcon = 'hide';
        } else {
          if (dimData instanceof Array) {
            if (dimData.length > 0) {
              dimData.forEach(i => {
                const key = 'value';
                const value = false;
                if (i[key] instanceof Object) {
                  i[key] = i[key]['name'];
                }
              });
            }
          }
          this.inputOptions = this.customDimensionData;

          this.mutliSelectCrossIcon = 'show';
          this.multiSelectSearchIcon = 'hide';

          const arr = [];
          const text = this.quote(this.search.trim());
          const re = new RegExp(text, 'i');
          /* let searchResults = this.searchResult;
          if (searchResults.indexOf('All') > -1) {
            searchResults = this.fullData.map((i) => {
              return i.value;
            });
          } */
          if (dimData instanceof Array) {
            if (dimData.length > 0) {
              dimData = dimData.forEach(i => {
                const key = 'value';
                let value: any = false;
                if (i[key] instanceof Object) {
                  value = i[key]['name'];
                } else {
                  value = i[key];
                }
                if ((value || '').search(re) > -1) {
                  /* if (searchResults.indexOf(value) > -1) {
                    i.check = true;
                  } else {
                    i.check = false;
                  } */
                  arr.push(i);
                }
              });
            }
          }
          for (const selv of arr) {
            for (const k of this.customDimensionData) {
              if (k.value === selv.value) {
                selv.check = k.check;
              }
            }
          }
          this.inputOptions = this.customDimensionData = arr;
          this.count = this.customDimensionData.length;
        }
      } else if (this.requestData.title === 'DIMEN') {
        let dimData = JSON.parse(JSON.stringify(this.fullData));
        if (this.search === '') {
          for (const selv of this.selectedArray) {
            for (const k of dimData) {
              if (k.value === selv.value) {
                dimData.splice(dimData.indexOf(k), 1);
              }
            }
            dimData.unshift(selv);
          }
          this.inputOptions = this.customDimensionData = dimData;
          this.count = this.customDimensionData.length;
          this.multiSelectSearchIcon = 'show';
          this.mutliSelectCrossIcon = 'hide';
        } else {
          if (dimData instanceof Array) {
            if (dimData.length > 0) {
              dimData.forEach(i => {
                const key = 'value';
                const value = false;
                if (i[key] instanceof Object) {
                  i[key] = i[key]['name'];
                }
              });
            }
          }
          this.inputOptions = this.customDimensionData;

          this.mutliSelectCrossIcon = 'show';
          this.multiSelectSearchIcon = 'hide';

          const arr = [];
          const text = this.quote(this.search.trim());
          const re = new RegExp(text, 'i');
          /* let searchResults = this.searchResult;
          if (searchResults.indexOf('All') > -1) {
            searchResults = this.fullData.map((i) => {
              return i.value;
            });
          } */
          if (dimData instanceof Array) {
            if (dimData.length > 0) {
              dimData = dimData.forEach(i => {
                const key = 'value';
                let value: any = false;
                if (i[key] instanceof Object) {
                  value = i[key]['name'];
                } else {
                  value = i[key];
                }
                if ((value || '').search(re) > -1) {
                  // if (this.searchResult.indexOf(value) > -1) {
                  // 	i.check = true;
                  // } else {
                  // 	i.check = false;
                  // }
                  arr.push(i);
                }
              });
            }
          }
          for (const selv of arr) {
            for (const k of this.customDimensionData) {
              if (k.value === selv.value) {
                selv.check = k.check;
              }
            }
          }
          this.inputOptions = this.customDimensionData = arr;
          this.count = this.customDimensionData.length;
        }
      }
    }
  }
  removeArrayDuplicates(inputArray) {
    const unique_array = inputArray.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    return unique_array;
  }
  onCheckBoxSelect(event, valueObj) {
    if (event.target.checked) {
      this.tempArray.push(valueObj);
      this.ref.markForCheck();
      this.selectedArray.push(valueObj);
      this.subscriptionService.setAutoSearchSelected(this.inputOptions);
      for (const selv of this.selectedArray) {
        for (const k of this.fullData) {
          if (k.value === selv.value) {
            k.check = selv.check;
          }
        }
      }
    } else {
      this.tempArray.splice(
        this.tempArray.findIndex(x => x.value === valueObj.value),
        1
      );
      this.selectedArray.splice(
        this.selectedArray.findIndex(x => x.value === valueObj.value),
        1
      );
      this.selectedArray.push(valueObj);
      for (const selv of this.selectedArray) {
        for (const k of this.fullData) {
          if (k.value === selv.value) {
            k.check = selv.check;
          }
        }
      }
    }
    this.searchResult = [];
    if (!this.tempArray.length) {
      this.searchResult.push('Select');
    } else {
      switch (this.tempArray.length) {
        case 1:
          this.searchResult.push(this.tempArray[0].value);
          break;
        default:
          if (this.customDimensionData.length === this.tempArray.length) {
            if (this.requestData.title === 'RM') {
              this.searchResult.push(
                'All Raw Materials Selected ' +
                '(' +
                this.tempArray.length +
                ')'
              );
            } else {
              this.searchResult.push(
                'All Values Selected ' + '(' + this.tempArray.length + ')'
              );
            }
          } else {
             
            this.searchResult.push(
              ' selected ' + '(' + this.tempArray.length + ')'
            );
          }
          break;
      }
    }
    // this.subscriptionService.setDpdwnRequestData({ "val": this.customDimensionData, "title": this.requestData.title });
    this.subscriptionService.setDpdwnRequestData({
      val: this.tempArray,
      target: this.requestData.target
    });
  }

  checkAll() {
    this.disbleCheckAll = true;
    this.disableClearAll = false;
    this.selectedArray = [];
    this.searchResult = [];
    for (const data of this.customDimensionData) {
      data.check = true;
      this.selectedArray.push(data);
      // this.selectedArray.unshift(data)
      // this.searchResult.push(data.value)
      this.searchResult = this.searchResult.slice();
      this.ref.markForCheck();
    }
    this.customDimensionData = JSON.parse(
      JSON.stringify(this.customDimensionData)
    );
    this.tempArray = JSON.parse(JSON.stringify(this.customDimensionData));
    if (this.requestData.title === 'RM') {
      // + this.requestData.payload.rmList.length + ")"
      this.searchResult.push(
        'All Raw Materials Selected ' + '(' + this.selectedArray.length + ')'
      );
    } else if (this.requestData.title === 'DIMEN') {
      this.searchResult.push(
        'All Values Selected' + '(' + this.selectedArray.length + ')'
      );
    }
    // this.customDimensionData = this.fullData;
    this.subscriptionService.setAutoSearchSelected(this.searchResult);
    this.subscriptionService.setDpdwnRequestData({
      val: this.customDimensionData.filter(function (i) {
        return i.check;
      }),
      target: this.requestData.target
    });
  }

  unCheckAll(event?: any) {
    if (!!event) {
      this.clearSearchText(event)
    }
    this.disbleCheckAll = false;
    this.disableClearAll = false;
    for (const data of this.customDimensionData) {
      data.check = false;
    }
    this.customDimensionData = JSON.parse(
      JSON.stringify(this.customDimensionData)
    );
    this.inputOptions = JSON.parse(JSON.stringify(this.customDimensionData));
    this.tempArray = [];
    this.selectedArray = [];
    this.searchResult = [];
    this.subscriptionService.setAutoSearchSelected(this.selectedArray);
    if (this.requestData.selectAll !== undefined) {
      this.requestData.selectAll = false;
    }
    // this.subscriptionService.setAutoSearchText({ "val": this.selectedArray, "title": this.requestData.title });
    this.subscriptionService.setDpdwnRequestData({
      val: this.selectedArray,
      target: this.requestData.target
    });
  }

  clearSearchText(event: any) {
    this.search = null;
    event.target.value = '';
    this.onMultiSelectKey(event);
  }

  onSingleSelectMulti(childInput) {
    this.searchResult.length = 0;
    this.selectedArray.length = 0;
    this.searchResult.push(childInput.value);
    this.selectedArray.unshift(childInput);
    this.showOrHideDropdown = 'hide';
  }
  onMultiSelectSearch() {
    this.dataStorageService.setautoSearchHit(true);
    this.showOrHideDropdown = 'hide';
    this.subscriptionService.setAutoSearchText({
      val: this.selectedArray,
      title: this.requestData.title
    });
    if (this.showSelectedValue) {
      this.selectedArray.length = 0;
      this.searchResult.length = 0;
      for (const data of this.customDimensionData) {
        data.check = false;
      }
    }
  }

  // MultiSelect code end

  // Tree view code start
  onTreeCustomKey(event: any) {
    this.search = event.target.value;
    this.searchTerm$.next(event.target.value);
    this.searchService
      .searchCustom(this.searchTerm$, this.requestData)
      .subscribe(results => {
        if (results['result'] === undefined) {
          this.customDimensionData.length = 0;
        } else {
          let resultsArr: any[] = new Array();
          if (!(results['result'] instanceof Array)) {
            resultsArr.push(results['result']);
          } else {
            resultsArr = results['result'];
          }
          this.customDimensionData = this.searchService.onConvertTree(
            resultsArr
          );
          this.count = this.customDimensionData.length;
        }
        if (this.search === '') {
          this.multiSelectSearchIcon = 'show';
          this.mutliSelectCrossIcon = 'hide';
          // Commenting for now .. Will check later and add the functionality
          // for(let selv of this.selectedArray){
          //   for(let parent of this.customDimensionData){
          //     for(let child of parent.items){
          //       if(child.value === selv.value){
          //         child.ckheck = true;
          //         this.customDimensionData.splice(this.customDimensionData.indexOf(parent),1)
          //       }
          //     }
          //     this.customDimensionData.unshift(parent)
          //   }
          // }
        } else {
          this.mutliSelectCrossIcon = 'show';
          this.multiSelectSearchIcon = 'hide';
        }
        for (const selv of this.selectedArray) {
          for (const parent of this.customDimensionData) {
            for (const child of parent.items) {
              if (child.value === selv.value) {
                child.check = true;
              }
            }
            let parentInputCheck: boolean;
            for (const child of parent.items) {
              if (child.check) {
                parentInputCheck = true;
                parent.check = parentInputCheck;
              } else {
                parentInputCheck = false;
                parent.check = parentInputCheck;
                break;
              }
            }
          }
        }
        this.subscriptionService.setDpdwnRequestData({
          val: this.customDimensionData.filter(function (i) {
            return i.check;
          }),
          target: this.requestData.target
        });
        this.count = this.customDimensionData.length;
      });
  }

  onCheckBoxCustomParentSelect(event, parentInput) {
    if (event.target.checked) {
      for (const child of parentInput.items) {
        let isExists = false;
        for (const selectedValue of this.selectedArray) {
          if (selectedValue.value === child.value) {
            isExists = true;
          }
        }
        if (!isExists) {
          this.searchResult.push(child.value);
          this.selectedArray.unshift(child);
          child.check = true;
        }
      }
    } else {
      for (const child of parentInput.items) {
        child.check = false;
        this.selectedArray.splice(this.selectedArray.indexOf(child), 1);
        this.searchResult.splice(this.searchResult.indexOf(child.value), 1);
      }
    }
  }

  onSingleSelectTree(childInput) {
    this.searchResult.length = 0;
    this.selectedArray.length = 0;
    this.searchResult.push(childInput.value);
    this.selectedArray.unshift(childInput);
    this.showOrHideDropdown = 'hide';
  }

  onCheckBoxChildSelect(event, childInput, parentInput) {
    if (event.target.checked) {
      this.searchResult.push(childInput.value);
      this.selectedArray.unshift(childInput);
      let parentInputCheck: boolean;
      for (const child of parentInput.items) {
        if (child.check) {
          parentInputCheck = true;
          parentInput.check = parentInputCheck;
        } else {
          parentInputCheck = false;
          parentInput.check = parentInputCheck;
          break;
        }
      }
    } else {
      // Logic is bad... but facing issues. need to check how to resolve it
      // childInput.check = true;
      const newSelectedArray: any[] = new Array();
      for (const s of this.selectedArray) {
        if (s.value === childInput.value) {
          // do nothing
        } else {
          newSelectedArray.unshift(s);
        }
      }
      this.selectedArray.length = 0;
      this.selectedArray = newSelectedArray;
      // this.selectedArray.splice(this.selectedArray.indexOf(childInput),1)
      this.searchResult.splice(this.searchResult.indexOf(childInput.value), 1);
      parentInput.check = false;
    }
  }

  checkAllTree() {
    this.selectedArray.length = 0;
    this.searchResult.length = 0;
    for (const parent of this.customDimensionData) {
      parent.check = true;
      for (const child of parent.items) {
        child.check = true;
        this.selectedArray.unshift(child);
        this.searchResult.push(child.value);
      }
    }
    this.subscriptionService.setAutoSearchSelected(this.selectedArray);
  }

  unCheckAllTree() {
    for (const parent of this.customDimensionData) {
      parent.check = false;
      for (const child of parent.items) {
        child.check = false;
      }
      this.selectedArray.length = 0;
      this.searchResult.length = 0;
    }
    this.subscriptionService.setAutoSearchSelected(this.selectedArray);
  }

  clearSearchTextTree(event: any) {
    this.search = '';
    event.target.value = '';
    this.onTreeCustomKey(event);
  }

  onTreeSelectSearch() {
    this.showOrHideDropdown = 'hide';
    this.subscriptionService.setAutoSearchText(this.selectedArray);

    if (this.showSelectedValue) {
      this.selectedArray.length = 0;
      this.searchResult.length = 0;
      for (const parent of this.customDimensionData) {
        parent.check = false;
        for (const child of parent.items) {
          child.check = false;
        }
      }
    }
  }

  // Tree view code end

  // multitree view code

  onMultiTreeCustomKey(event: any) {
    this.search = event.target.value;
    this.searchTerm$.next(event.target.value);
    this.searchService
      .searchCustom(this.searchTerm$, this.requestData)
      .subscribe(results => {
        if (results['result'] === undefined) {
          this.customDimensionData.length = 0;
        } else {
          let resultsArr: any[] = new Array();
          if (!(results['result'] instanceof Array)) {
            resultsArr.push(results['result']);
          } else {
            resultsArr = results['result'];
          }
          this.customDimensionData = this.searchService.onConvertMultiTree(
            resultsArr
          );
          this.count = this.customDimensionData.length;
        }
        if (this.search === '') {
          this.multiSelectSearchIcon = 'show';
          this.mutliSelectCrossIcon = 'hide';
          // for(let selv of this.selectedArray){
          //   for(let k of this.customDimensionData){
          //     if(k.value === selv.value){
          //       this.customDimensionData.splice(this.customDimensionData.indexOf(k),1)
          //     }
          //   }
          //   this.customDimensionData.unshift(selv)
          // }
        } else {
          this.mutliSelectCrossIcon = 'show';
          this.multiSelectSearchIcon = 'hide';
        }
        for (const selv of this.selectedArray) {
          for (const gp of this.customDimensionData) {
            for (const p of gp.parent) {
              for (const c of p.items) {
                if (c.value === selv.value) {
                  c.check = true;
                }
              }
              let parentInputCheck: boolean;
              for (const child of p.items) {
                if (child.check) {
                  parentInputCheck = true;
                  p.check = parentInputCheck;
                } else {
                  parentInputCheck = false;
                  p.check = parentInputCheck;
                  break;
                }
              }
            }
          }
        }
        this.count = this.customDimensionData.length;
      });
  }

  checkAllMultiTree() {
    this.selectedArray.length = 0;
    this.searchResult.length = 0;
    for (const grandParent of this.customDimensionData) {
      for (const parent of grandParent.parent) {
        parent.check = true;
        for (const child of parent.items) {
          child.check = true;
          this.selectedArray.unshift(child);
          this.searchResult.push(child.value);
        }
      }
    }
  }

  onSingleSelectMultiTree(childInput) {
    this.searchResult.length = 0;
    this.selectedArray.length = 0;
    this.searchResult.push(childInput.value);
    this.selectedArray.unshift(childInput);
    this.showOrHideDropdown = 'hide';
  }

  unCheckAllMultiTree() {
    for (const grandParent of this.customDimensionData) {
      for (const parent of grandParent.parent) {
        parent.check = false;
        for (const child of parent.items) {
          child.check = false;
        }
      }
    }
    this.selectedArray.length = 0;
    this.searchResult.length = 0;
  }

  clearSearchTextMultiTree(event: any) {
    this.search = '';
    event.target.value = '';
    this.onMultiTreeCustomKey(event);
  }

  onMultiTreeSelectSearch() {
    this.showOrHideDropdown = 'hide';
    this.subscriptionService.setAutoSearchText(this.selectedArray);

    if (this.showSelectedValue) {
      this.selectedArray.length = 0;
      this.searchResult.length = 0;
      for (const grandParent of this.customDimensionData) {
        for (const parent of grandParent.parent) {
          parent.check = false;
          for (const child of parent.items) {
            child.check = false;
          }
        }
      }
    }
  }

  // multitree code end

  // Single select code start

  onSingleSelect(val: any) {
    this.singleSearchSelectedValue = val;
    this.subscriptionService.setSingleSearchText(val);
    this.dropDownlistService.setSelectedDropDownValue(
      this.singleSearchSelectedValue
    );
  }

  onSingleSelectSearch() {
    this.showOrHideDropdown = 'hide';
    this.dropDownlistService.setSelectedDropDownValue(
      this.singleSearchSelectedValue
    );
  }

  ngOnDestroy() {
    this.subscriptionService.setPMRequestData('');
    if (this.autosearchSubscription) {
      this.autosearchSubscription.unsubscribe();
    }
  }
}
