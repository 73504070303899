import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import * as VERSION from '../../../../assets/version.json'; // if you get an error on importing this line, you might need to run npm install
import { FeatureFlagService } from '../../core/feature-flags/feature-flag.service';

@Injectable({
  providedIn: 'root'
})
export class AppVersionService {
  constructor(
    private meta: Meta,
    private featureFlagService: FeatureFlagService
  ) {
    this.loadVersion();
  }

  private loadVersion() {
    this.featureFlagService.isEnabled('customer-portal.main-page.show-app-version').subscribe(value => {
      if (value) {
        this.meta.addTags([
          { name: 'version', content: VERSION.raw }
        ]);
      }
    });
  }
}
