import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

import { Alerts } from './alerts.model';
import { ApiServiceProvider } from 'app/api-service';
import { environment } from 'environments/environment';
import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService';

@Injectable()
export class AlertsService {
  envDetails: any;
  alerts: any;
  alertsServicePath: any;
  constructor(private apiServiceProvider: ApiServiceProvider, private customerConfigurationService: CustomerConfigurationService) {
    this.envDetails = this.customerConfigurationService.getEnvironmentDetails();

    this.alerts = this.envDetails.ALERTS;
    this.alertsServicePath = this.envDetails.alertsServicePath;

  }
  public getAlertsInfo(customPayLoadObject) {
    const object: any = {
      targetedType: '',
      clientName: this.alerts,
      formParams: {

      },
      payload: {
        rfqId: customPayLoadObject.rfqId
      },
      method: 'GET',
      servicePath: '/' + this.alertsServicePath + '/user/rfqalerts'
    };

    return this.apiServiceProvider.post(object);
  }
  public replyAlert(customPayLoadObject) {
    const object: any = {
      targetedType: '',
      clientName: this.alerts,
      formParams: {

      },
      payload: customPayLoadObject,
      method: 'PUT',
      servicePath: '/' + this.alertsServicePath + '/user/alerts/acknowledge'

    };
    return this.apiServiceProvider.post(object);
  }

  public ClearAlert(customPayLoadObject) {
    const object: any = {
      targetedType: '',
      clientName: this.alerts,
      formParams: {

      },
      payload: customPayLoadObject,
      method: 'PUT',
      servicePath: '/' + this.alertsServicePath + '/user/alerts/clean'
    };
    return this.apiServiceProvider.post(object);
  }
}
