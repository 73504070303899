import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { environment } from 'environments/environment';
import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  constructor(
    private customerConfigurationService: CustomerConfigurationService
  ) {
    this.customerInfo = this.customerConfigurationService.getBaseCustomerInfo();
    if (this.customerInfo) {
      this.isPredictiveCostEnabled.next(this.customerInfo.enablePredictiveCost);
    }
  }
  public accordionState = {};
  public customerInfo: any;
  public tempArray: any[] = [];
  public storePreviousArray: any[] = [];
  public storeArrayValues = new Subject<any>();
  public selectedBomFilters = new BehaviorSubject<any>(undefined);
  public clearFlag = new Subject<any>();
  public isPredictiveCostEnabledSubject = new Subject<any>();
  public isPredictiveCostEnabled = new BehaviorSubject<any>(false);
  public subject = new BehaviorSubject<any>('graph');
  // public subject = new Subject<any>();
  public expandDisplayComponentSubject = new Subject<any>();
  public searchText = new BehaviorSubject<any>('');
  public autoSearchText = new BehaviorSubject<any>('');
  public npAutoSearchTextRO = new BehaviorSubject<any>(undefined);
  public roMPNTextRO = new BehaviorSubject<any>(undefined);
  public oppActiveClass = new BehaviorSubject<any>(undefined);
  public autoSearchDimensionText = new BehaviorSubject<any>('');
  public singleSelectSearchText = new BehaviorSubject<any>('');
  public npSupplierData = new BehaviorSubject<any>(undefined);
  public rawMaterailTrendData = new BehaviorSubject<any>('');
  public rawMaterailOppRisksData = new BehaviorSubject<any>('');
  public rawMaterailMappingData = new BehaviorSubject<any>('');
  public rmTrendGraph = new BehaviorSubject<any>('');
  public path = new BehaviorSubject<any>('');
  public selectedSubCommodity = new ReplaySubject<any>();
  public selectedTimePeriod = new BehaviorSubject<any>('');
  public pmAccData = new BehaviorSubject<any>('');
  public pmRequestData = new BehaviorSubject<any>('');
  public dpdwnRequestData = new BehaviorSubject<any>(undefined);
  public dpdwnData = new BehaviorSubject<any>(undefined);
  public rmpmRequestData = new BehaviorSubject<any>('');
  public scopeRequestData = new BehaviorSubject<any>('');
  public AutoSearchSelected = new BehaviorSubject<any>(undefined);
  public onBackView = new BehaviorSubject<any>(undefined);
  public fcQuarterValue = new BehaviorSubject<any>(undefined);
  public expandAllData = new BehaviorSubject<any>(undefined);
  public fcDropDrownValue = new BehaviorSubject<any>(undefined);
  public fcPath = new BehaviorSubject<any>(undefined);
  public fcdefaultddValue = new BehaviorSubject<any>(undefined);
  public suppInfo = new BehaviorSubject<any>(undefined);
  public fcdefaultddToValue = new BehaviorSubject<any>(undefined);
  public fcFromDropDownValue = new BehaviorSubject<any>(undefined);
  public quarters = new BehaviorSubject<any>(undefined);
  public currentQuarter = new BehaviorSubject<any>(undefined);
  public opportunityDetails = new Subject<any>();
  public MetricsDetails = new Subject<any>();
  public oppGraphCompetetiveness = new Subject<any>();
  public appIconactive = new Subject<any>();
  public clearScope = new BehaviorSubject<any>(undefined);
  public opportunityExpandviewCheckbox = new BehaviorSubject<any>(undefined);
  public opportunityExpandviewCheckboxSubject = new Subject<any>();
  public supplierDetail = new BehaviorSubject<any>(undefined);
  public OppValue = new BehaviorSubject<any>(undefined);
  public filters = new BehaviorSubject<boolean>(undefined);
  public viewClick = new BehaviorSubject<any>(undefined);
  public enableSave = new BehaviorSubject<any>(false);
  public enabledelete = new BehaviorSubject<any>(false);
  public enablepublish = new BehaviorSubject<any>(true);
  public timetext = new BehaviorSubject<any>(undefined);
  public clear = new BehaviorSubject<any>(undefined);
  public clearLeversTest = new BehaviorSubject<any>(undefined);
  public pushtochatbotArray: any[] = [];
  public deleteArray: any[] = [];
  public saveArray: object[] = [];
  public saveobj: object = {};
  public newRowsArray: any[] = [];
  public drivertext = new BehaviorSubject<any>(undefined);
  public rmautosearch = new BehaviorSubject<any>(undefined);
  public predictivecostFilter = new BehaviorSubject<any>(undefined);
  public costTopAutoSearchValue = new BehaviorSubject<any>(undefined);
  public unReadSubject = new BehaviorSubject<any>(undefined);
  // predictivecostFilter = new Subject<any>();
  public filtersForReports: any = new BehaviorSubject<any>(undefined);
  public selectedApply = new Subject<string>();
  // 	selectedDrilldownTab = new Subject<String>();
  public selectedDrilldownTab = new BehaviorSubject<String>('default');
  public selectedBomTab = new Subject<any>();
  public selectedPSL = new BehaviorSubject<any>(undefined);
  public lifeCycleSelection = new Subject<any>();
  public checkboxRenderer = new BehaviorSubject<any>(undefined);
  public newOppViewSubject = new Subject<String>();
 
  public bulkBomUrl;

  scopeName: any;

  private supplierScoreAlert = new BehaviorSubject<any>(undefined);

  public checkboxGetRenderder() {
    return this.checkboxRenderer.asObservable();
  }
  public checkboxSetRenderder(value) {
    this.checkboxRenderer.next(value);
  }


  public getSelectedOppView() {
    return this.newOppViewSubject.asObservable();
  }
  public setSelectedOppView(value) {
    this.newOppViewSubject.next(value);
  }

  public getSelectedBomTab() {
    return this.selectedBomTab.asObservable();
  }
  public setSelectedBomtab(value) {
    this.selectedBomTab.next(value);
  }
  public getStoreArrayValues() {
    return this.storeArrayValues.asObservable();
  }
  public setStoreArrayValues(value) {
    this.storeArrayValues.next(value);
  }
  public getSelectedBomFilters() {
    return this.selectedBomFilters.asObservable();
  }
  public setSelectedBomFilters(value: any) {
    this.selectedBomFilters.next(value);
  }
  public getSelectedDrilldownTab() {
    return this.selectedDrilldownTab.asObservable();
  }
  public setSelectedDrilldownTab(val) {
    this.selectedDrilldownTab.next(val);
  }
  public setFiltersForReports(value) {
    this.filtersForReports.next(value);
  }
  public getFiltersForReports() {
    return this.filtersForReports.asObservable();
  }
  public setUnreadSubject(val) {
    this.unReadSubject.next(val);
  }
  public getUnreadSubject() {
    return this.unReadSubject.asObservable();
  }
  public setCostTopAutoSearchValue(val) {
    this.costTopAutoSearchValue.next(val);
  }
  public setSuppInfo(val) {
    this.suppInfo.next(val);
  }
  public getSuppInfo() {
    return this.suppInfo.asObservable();
  }
  public getCostTopAutoSearchValue() {
    return this.costTopAutoSearchValue.asObservable();
  }
  public setPredictiveCostFilter(val) {
    this.predictivecostFilter.next(val);
  }
  public getClearflag() {
    return this.clearFlag.asObservable();
  }
  public setClearflag(val) {
    this.clearFlag.next(val);
  }
  public getSelectedApply() {
    return this.selectedApply.asObservable();
  }
  public setselectedApply(val) {
    this.selectedApply.next(val);
  }
  public getPredictiveCostFilter() {
    return this.predictivecostFilter.asObservable();
  }
  public setrmautosearch(val) {
    this.rmautosearch.next(val);
  }
  public getrmautosearch() {
    return this.rmautosearch.asObservable();
  }
  public setclearText(val) {
    this.clear.next(val);
  }
  public getClearText() {
    return this.clear.asObservable();
  }
  public setClearLeversText(val) {
    this.clearLeversTest.next(val);
  }
  public getClearLeversText() {
    return this.clearLeversTest.asObservable();
  }
  public setDriverText(val) {
    this.drivertext.next(val);
  }
  public getDriverText() {
    return this.drivertext.asObservable();
  }
  public settimetext(val) {
    this.timetext.next(val);
  }
  public gettimetext() {
    return this.timetext.asObservable();
  }
  public setenablesave(val) {
    this.enableSave.next(val);
  }
  public getenablesave() {
    return this.enableSave.asObservable();
  }
  public setdisableDelete(val) {
    this.enabledelete.next(val);
  }
  public getdisableDelete() {
    return this.enabledelete.asObservable();
  }
  public setenablePublish(val) {
    this.enablepublish.next(val);
  }
  public getenablePublish() {
    return this.enablepublish.asObservable();
  }
  public setViewClick(val) {
    this.viewClick.next(val);
  }
  public getViewClick() {
    return this.viewClick.asObservable();
  }
  public setFilters(val) {
    this.filters.next(val);
  }
  public getFilters() {
    return this.filters.asObservable();
  }

  public setPredictiveCostEnabledSubject(field: boolean) {
    this.isPredictiveCostEnabledSubject.next(field);
  }

  public getPredictiveCostEnabledSubject() {
    return this.isPredictiveCostEnabledSubject.asObservable();
  }

  public setPredictiveCostEnabled(field: boolean) {
    this.isPredictiveCostEnabled.next(field);
  }

  public getPredictiveCostEnabled() {
    return this.isPredictiveCostEnabled.asObservable();
  }
  public setViewNameSubject(field: any) {
    this.subject.next(field);
  }

  public getViewNameSubject() {
    return this.subject.asObservable();
  }
  public setExpandDisplayComponentSubject(field: any) {
    this.expandDisplayComponentSubject.next(field);
  }

  public getExpandDisplayComponentSubject() {
    return this.expandDisplayComponentSubject.asObservable();
  }
  public setOpportunityClick(value) {
    this.OppValue.next(value);
  }
  public getOpportunityClick() {
    return this.OppValue.asObservable();
  }

  public setNpSupplierData(field: any) {
    this.npSupplierData.next(field);
  }
  public setNpsupplierDetail(val) {
    this.supplierDetail.next(val);
  }
  public setOppActiveClass(val) {
    this.oppActiveClass.next(val);
  }
  public setNPScopeName(val) {
    this.scopeName = val;
  }
  public getNPScopeName() {
    return this.scopeName;
  }

  public getOppActiveClass() {
    return this.oppActiveClass.asObservable();
  }
  public getNpsupplierDetail() {
    return this.supplierDetail.asObservable();
  }

  public getNpSupplierData() {
    return this.npSupplierData.asObservable();
  }

  public setSearchText(field: any) {
    this.searchText.next(field);
  }

  public getSearchText() {
    return this.searchText.asObservable();
  }

  public setAutoSearchText(field: any) {
    this.autoSearchText.next(field);
  }

  public getAutoSearchText() {
    return this.autoSearchText.asObservable();
  }

  public setNPAutoSearchTextFromRO(field: any) {
    this.npAutoSearchTextRO.next(field);
  }

  public getNPAutoSearchTextFromRO() {
    return this.npAutoSearchTextRO.asObservable();
  }

  public setMPNFromRO(field: any) {
    this.npAutoSearchTextRO.next(field);
  }

  public getMPNFromRO() {
    return this.npAutoSearchTextRO.asObservable();
  }


  public setAutoSearchDimensionText(field: any) {
    this.autoSearchDimensionText.next(field);
  }

  public getAutoSearchDimensionText() {
    return this.autoSearchDimensionText.asObservable();
  }

  public setAutoSearchSelected(field: any) {
    this.AutoSearchSelected.next(field);
  }

  public getAutoSearchSelected() {
    return this.AutoSearchSelected.asObservable();
  }
  public setSingleSearchText(field: any) {
    this.singleSelectSearchText.next(field);
  }

  public getSingleSearchText() {
    return this.singleSelectSearchText.asObservable();
  }

  public setRawMaterailTrendData(field: any) {
    this.rawMaterailTrendData.next(field);
  }

  public getRawMaterailTrendData() {
    return this.rawMaterailTrendData.asObservable();
  }

  public setRawMaterailOppRisksData(field: any) {
    this.rawMaterailOppRisksData.next(field);
  }

  public getRawMaterailOppRisksData() {
    return this.rawMaterailOppRisksData.asObservable();
  }

  public setRawMaterailMappingData(field: any) {
    this.rawMaterailMappingData.next(field);
  }

  public getRawMaterailMappingData() {
    return this.rawMaterailMappingData.asObservable();
  }

  public setRMTrendGraphData(field: any) {
    this.rmTrendGraph.next(field);
  }

  public getRMTrendGraph() {
    return this.rmTrendGraph.asObservable();
  }

  public setPath(field: any) {
    this.path.next(field);
  }

  public getPath() {
    return this.path.asObservable();
  }

  public setSelectedSubCommodity(field: any) {
    this.selectedSubCommodity.next(field);
  }

  public getSelectedSubCommodity() {
    return this.selectedSubCommodity.asObservable();
  }

  public setSelectedTimePeriod(field: any) {
    this.selectedTimePeriod.next(field);
  }

  public getSelectedTimePeriod() {
    return this.selectedTimePeriod.asObservable();
  }

  public setPMAccData(field: any) {
    this.pmAccData.next(field);
  }

  public getPMAccData() {
    return this.pmAccData.asObservable();
  }

  public setPMRequestData(field: any) {
    this.pmRequestData.next(field);
  }

  public getPMRequestData() {
    return this.pmRequestData.asObservable();
  }
  public setDpdwnRequestData(field: any) {
    this.dpdwnRequestData.next(field);
  }

  public getDpdwnRequestData() {
    return this.dpdwnRequestData.asObservable();
  }
  public setDpdwnData(field: any) {
    this.dpdwnData.next(field);
  }

  public getDpdwnData() {
    return this.dpdwnData.asObservable();
  }
  public getscopeRequestData() {
    return this.scopeRequestData.asObservable();
  }
  public setscopeRequestData(data) {
    this.scopeRequestData.next(data);
  }
  public setOpportunityDetails(field: any) {
    this.opportunityDetails.next(field);
  }
  public getOpportunityDetails() {
    return this.opportunityDetails.asObservable();
  }

  public setMetricsDetails(field: any) {
    this.MetricsDetails.next(field);
  }
  public getMetricsDetails() {
    return this.MetricsDetails.asObservable();
  }
  public setExpandAllData(field: any) {
    this.expandAllData.next(field);
  }
  public getexpandAllData() {
    return this.expandAllData.asObservable();
  }
  public setOnBackView(field: any) {
    this.onBackView.next(field);
  }
  public getOnBackView() {
    return this.onBackView.asObservable();
  }

  public setOppGraphCompetetiveness(field: any) {
    this.oppGraphCompetetiveness.next(field);
  }
  public getOppGraphCompetetiveness() {
    return this.oppGraphCompetetiveness.asObservable();
  }
  public setAppIconActive(field: any) {
    this.appIconactive.next(field);
  }
  public getAppIconActive() {
    return this.appIconactive.asObservable();
  }
  public setOpportunityExpandviewCheckboxSubject(field: any) {
    this.opportunityExpandviewCheckboxSubject.next(field);
  }
  public getOpportunityExpandviewCheckboxSubject() {
    return this.opportunityExpandviewCheckboxSubject.asObservable();
  }

  public setOpportunityExpandviewCheckbox(field: any) {
    this.opportunityExpandviewCheckbox.next(field);
  }
  public getOpportunityExpandviewCheckbox() {
    return this.opportunityExpandviewCheckbox.asObservable();
  }
  public setFcDropdownValue(field: any) {
    this.fcDropDrownValue.next(field);
  }
  public getFcDropdownValue() {
    return this.fcDropDrownValue.asObservable();
  }
  public setfcPath(field: any) {
    this.fcPath.next(field);
  }
  public getfcPath() {
    return this.fcPath.asObservable();
  }
  public setfcDefaultdropdownValue(field: any) {
    this.fcdefaultddValue.next(field);
  }
  public getfcDefaultdropdownValue() {
    return this.fcdefaultddValue.asObservable();
  }
  public setfcDefaultTodropdownValue(field: any) {
    this.fcdefaultddToValue.next(field);
  }

  public getfcDefaultTodropdownValue() {
    this.fcdefaultddToValue.asObservable();
  }
  public setFcFromDropDownValue(field) {
    this.fcFromDropDownValue.next(field);
  }
  public getFcFromDropDownValue() {
    return this.fcFromDropDownValue.asObservable();
  }
  public setClearScope(val) {
    this.clearScope.next(val);
  }
  public getClearScope() {
    return this.clearScope.asObservable();
  }
  public setCurrentQuarter(field) {
    this.currentQuarter.next(field);
  }
  public getCurrentQuarter() {
    return this.currentQuarter.asObservable();
  }

  public setQuarters(field) {
    this.quarters.next(field);
  }
  public getQuarters() {
    return this.quarters.asObservable();
  }
  public setQuarterValue(field) {
    this.fcQuarterValue.next(field);
  }
  public getQuarterValue() {
    return this.fcQuarterValue.asObservable();
  }
  public setSelectedPSL(val) {
    this.selectedPSL.next(val);
  }

  public getSelectedPSL() {
    return this.selectedPSL.asObservable();
  }

  public setLifeCycleSelection(val) {
    this.lifeCycleSelection.next(val);
  }

  public getLifeCycleSelection() {
    return this.lifeCycleSelection.asObservable();
  }

  public setSupplierScoreAlert(selectAlert) {
    this.supplierScoreAlert.next(selectAlert);
  }

  public getSupplierScoreAlert() {
    return this.supplierScoreAlert.asObservable();
  }
}
