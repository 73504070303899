import { OnInit, Component, AfterViewInit } from '@angular/core';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';

@Component({
  moduleId: module.id,
  selector: 'child-cell',
  templateUrl: './multiselect-grid-dropdown.component.html',
  styleUrls: ['./multiselect-grid-dropdown.component.sass']
})
export class MultiselectGridDropdownComponent
  implements ICellEditorAngularComp, AfterViewInit {
  public params: any = {};
  public dropdownTitle = '';
  public options: any[] = [];
  public selectedValues: any[] = [];
  public defaultSelectedValues: any[] = [];
  private cancelBeforeStart = false;

  constructor() {}

  public agInit(params: any): void {
    this.params = params;
    this.dropdownTitle = this.params.context.defaultLabel;
    this.options = this.params.context.options;

    this.defaultSelectedValues = params.value;

    this.defaultSelectedValues = this.defaultSelectedValues.reduce(
      (ops, el, index) => {
        ops.push({
          dispValue: el,
          dispId: el,
          defaultVal: false,
          checked: true
        });
        return ops;
      },
      []
    );
  }

  public getValue(): any {
    return this.getChangedValues();
  }

  public getChangedValues() {
    let cellValue = this.params.value;
    if (this.selectedValues) {
      cellValue = this.selectedValues.reduce((ops, el, index) => {
        if (el && el !== '') {
          ops.push(el.dispValue);
        }
        return ops;
      }, []);
    }
    return cellValue;
  }

  public isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  public ngAfterViewInit() {}

  public isPopup(): boolean {
    return true;
  }

  public onCheckAll($event) {
    // console.log($event);
  }
  public inputChangeOptions($event) {
    this.selectedValues = $event;
  }
  public onUnCheckAll($event) {
    // console.log($event);
  }
}
