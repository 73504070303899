import { EventEmitter, Injectable } from '@angular/core'
@Injectable()
export class SraEmitterService {
  itemSelectionChange: EventEmitter<any> = new EventEmitter<any>()
  pointOptions: EventEmitter<any> = new EventEmitter<any>()
  exploreAlternateParams: EventEmitter<any> = new EventEmitter<any>()
  addEventParams: EventEmitter<any> = new EventEmitter<any>()
  resetMap: EventEmitter<any> = new EventEmitter<any>()

  constructor() {}

  emitSelectionChangeEvent(value) {
    this.itemSelectionChange.emit(value)
  }
  getSelectionChangeEmitter() {
    return this.itemSelectionChange
  }

  emitSelectedPointParams(value) {
    this.pointOptions.emit(value)
  }
  getSelectionPointParams() {
    return this.pointOptions
  }

  emitExploreAlternateParams(value) {
    this.exploreAlternateParams.emit(value)
  }
  getExploreAlternateParams() {
    return this.exploreAlternateParams
  }

  emitAddEventsParams(value) {
    this.addEventParams.emit(value)
  }
  getAddEventsParams() {
    return this.addEventParams
  }

  emitResetMapEvent(value) {
    this.resetMap.emit(value)
  }

  getResetMapEmitter() {
    return this.resetMap
  }
}
