import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges
} from '@angular/core';

@Component({
  selector: 'other-config',
  templateUrl: './other-config.component.html',
  styleUrls: ['./other-config.component.sass'],
  providers: []
})

export class OtherConfig {
  @Input() otherConfiguration: any ;
  @Input() disabled;
  @Output() otherConfigurationChanged: EventEmitter<Object> = new EventEmitter<Object>();

  otherConfigChange(key, value) {
    this.otherConfiguration[key] = value
    this.otherConfigurationChanged.emit(true);
  }

  onBlur() {
    this.otherConfigurationChanged.emit(true);
  }

}
