import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Router } from '@angular/router';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'child-cell',
  template: `
    <div title="{{ statusMap[val] }}">{{ statusMap[val] }}</div>
  `
})
export class StatusModifierComponent implements OnDestroy {
  private subject$: Subject<any> = new Subject();
  public params: any;
  val: any;
  statusTemplate: any;
  colorCode: any = '';
  supplierStatusArray: any = [];
  statusMap: object = {};
  statuskeyMap: any;
  constructor(
    private router: Router,
    private dataStorageService: DataStorageService
  ) {
    this.dataStorageService.getStatusModifier().pipe(takeUntil(this.subject$)).subscribe(value => {
      this.statuskeyMap = value;
      for (const key of Object.keys(value)) {
        this.supplierStatusArray = value[key];
      }
    });
  }
  agInit(params: any): void {
    // console.log(params);
    this.supplierStatusArray = this.statuskeyMap[params.colDef.field];
    if (
      this.supplierStatusArray !== undefined &&
      this.supplierStatusArray.length > 0
    ) {
      for (const i of this.supplierStatusArray) {
        this.statusMap[i['id']] = i['value'];
      }
    }
    this.val = params.data[params.colDef.field];
    this.params = params;
  }

  ngOnDestroy() {
    this.subject$.next();
    this.subject$.complete();
  }
}
