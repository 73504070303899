import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  OnInit
} from '@angular/core';
import { FIELD_CATEGORY, FIELD_TYPES, TYPES } from '../../../shared/constants';

@Component({
  selector: 'field-info',
  templateUrl: './field-info.component.html',
  styleUrls: ['./field-info.component.sass'],
  providers: []
})

export class FieldInfo implements OnInit, OnChanges {
  @Input() errorClass;
  @Input() fields = [];
  @Input() reservedColumnsList = [];
  @Input() fieldInfo;
  @Input() disabled = false;
  @Input() isMapping = false;
  @Output() onFieldChange: EventEmitter<Object> = new EventEmitter<Object>();
  @Output() onFieldInfoChange: EventEmitter<Object> = new EventEmitter<Object>();
  public fieldTypes = FIELD_TYPES;
  public fieldCategory = FIELD_CATEGORY;
  public selectedFieldCategory;
  public selectedReservedColumn;
  public sampleData;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.reservedColumnsList && changes.reservedColumnsList.currentValue.length) {
      this.selectedReservedColumn = this.reservedColumnsList.find(col => col.refTermActualValue === this.fieldInfo.dbColumn)
    }
  }

  ngOnInit() {
    this.sampleData = this.getFieldInfoSample();
    if (this.fieldInfo && this.fieldInfo.fieldType) {
      const index = FIELD_CATEGORY.findIndex(category => category.refTermActualValue === this.fieldInfo.fieldType)
      this.selectedFieldCategory = FIELD_CATEGORY[index];
    } else {
      this.selectedFieldCategory = FIELD_CATEGORY[0];
    }
  }

  fieldChange(type) {
    this.onFieldChange.emit(type);
  }

  fieldTypeChange(field) {
    this.fieldInfo.datatype = field.refTermActualValue;
    this.sampleData = this.getFieldInfoSample();
  }

  fieldInfoChange(key, value) {
    this.fieldInfo[key] = value;
    if (key === 'custDisplayName') {
      this.fieldInfo['supDisplayName'] = value;
    } else if (key === 'dbColumn') {
      this.fieldInfo['dbColumn'] = value && value.refTermActualValue ? value.refTermActualValue : '';
    } else if (key === 'fieldType') {
      if (value && value.refTermActualValue) {
        this.fieldInfo['fieldType'] = value.refTermActualValue;
        const index = FIELD_CATEGORY.findIndex(category => category.refTermActualValue === this.fieldInfo.fieldType)
        this.selectedFieldCategory = FIELD_CATEGORY[index];
      } else {
        this.fieldInfo['fieldType'] = '';
      }
    }
    this.onFieldInfoChange.emit(true);
  }

  fieldDbColumnChange(value) {
    this.fieldInfo['dbColumn'] = value;
    this.onFieldInfoChange.emit(true);
  }

  getFieldInfo() {
    return this.fieldTypes.find(x => x.refTermActualValue === this.fieldInfo.datatype);
  }
  getFieldInfoSample() {
    switch (this.fieldInfo.datatype) {
      case TYPES.TEXT:
        return 'ABCD';
      case TYPES.NUMBER:
        return '1234';
      case TYPES.DATE:
        return '13/05/2018';
      case TYPES.DROPDOWN:
        return 'option-1';
    }
  }
}
