import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

import { Summary } from './Summary.model';

@Injectable()
export class SummaryService {

  constructor(private http: HttpClient) { }

  ForexSummary: Array<object> = [
        {
            id: 1,
            displayName: 'Total Spend',
            value: '$1.68 Billion',
    },
    {
            id: 2,
            displayName: 'Total opportunity',
            value: '$134.92 Million',
    },
    {
            id: 3,
            displayName: 'Forex',
            value: '$9.43 Million (6.06 % of total opportunity)  ',
    },
    {
            id: 4,
            displayName: 'Related News',
            value: '10 News Found',
        },
  ];

  public getSummaryList(target: string) {

        let targetObject: Array<object> = [];
        switch (target) {
          case ('Forex'):
            targetObject = this.ForexSummary;
            break;
          case ('supplierfinancial'):
            targetObject = this.ForexSummary;
            break;
        }

        return targetObject;

      }
}
