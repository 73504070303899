
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChartModule } from 'angular-highcharts';
import { HightChartModule } from 'app/modules/high-charts/high-charts.module';
import { NgxLoadingModule } from 'ngx-loading';
import { RadialChartComponent } from './radial-chart.component';
@NgModule({
  declarations: [RadialChartComponent],
  imports: [
      CommonModule,
      ChartModule,
      HightChartModule,
      NgxLoadingModule.forRoot({}),
   
  ]
})
export class RadialChartModule { }