import { Injectable } from '@angular/core';
import { ApiServiceProvider } from 'app/api-service';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { DateUtils } from 'app/modules/common/utills/DateUtils.service';

@Injectable()
export class RfqConfigService {
  public template = new BehaviorSubject<any>(undefined);
  public templateId = new BehaviorSubject<any>(undefined);
  public templateNameError = false;

  constructor(
    private apiService: ApiServiceProvider
  ) { }

  getTemplateHeaders(): Observable<any> {
    const formObj = {
      rfqScope: 'COMMODITY',
      widget: 'TEMPLATE_LIST',
      entryType: 'COLUMN'
    };
    return this.getGridHeaders(formObj);
  }

  public getGridHeaders(formObj): Observable<any> {
    const payload = {
      targetedType: 'RFQTemplateConfig',
      method: 'POST',
      servicePath: '/widgetC/getWidgetHeaders',
      formParams: formObj
    };
    return this.apiService.post(payload);
  }

  getTemplateList(): Observable<any> {
    const payload = {
      targetedType: 'RFQTemplateConfig',
      method: 'GET',
      servicePath: '/templates'
    }
    return this.apiService.post(payload);
  }
  public saveNewTemplate(postData): Observable<any> {
    const payload = {
      targetedType: 'RFQTemplateConfig',
      method: 'POST',
      servicePath: '/templates',
      payload: postData
    }
    return this.apiService.post(payload);
  }
  public getTemplateDetailsById(templateId): Observable<any> {
    const payload = {
      targetedType: 'RFQTemplateConfig',
      method: 'GET',
      servicePath: `/templates/${templateId}`,
      payload: {}
    }
    return this.apiService.post(payload);
  }

  public getPreviewHeaderInfo(): Observable<any> {
    const formObj = {
      rfqScope: 'COMMODITY',
      widget: 'TEMPLATE_PREVIEW',
      entryType: 'COLUMN'
    };
    return this.getGridHeaders(formObj);
  }

  public getPreviewGridData(templateId, view) {
    const payload = {
      targetedType: 'RFQTemplateConfig',
      method: 'GET',
      servicePath: `/templates/${templateId}/view/${view}/preview`
    };
    return this.apiService.post(payload);
  }

  public getRfqConfiqMetrics(templateId) {
    const payload = {
      targetedType: 'RFQTemplateConfig',
      method: 'GET',
      servicePath: `/templates/${templateId}/metrics`
    };
    return this.apiService.post(payload);
  }

  public saveMetricsChanges(payloadObj, templateId) {
    const payload = {
      targetedType: 'RFQTemplateConfig',
      method: 'PUT',
      servicePath: `/templates/${templateId}/metrics`,
      payload: payloadObj
    };
    return this.apiService.post(payload);
  }

  public createNewMetric(payloadObj, templateId) {
    const payload = {
      targetedType: 'RFQTemplateConfig',
      method: 'POST',
      servicePath: `/templates/${templateId}/metrics`,
      payload: payloadObj
    };
    return this.apiService.post(payload);
  }

  public getExistingDBFields(templateId = 0) {
    const formObj = { custTmplId: templateId };
    const payload = {
      targetedType: 'RFQTemplateConfig',
      method: 'GET',
      servicePath: '/templates/headers/default',
      formParams: formObj
    }

    return this.apiService.post(payload);
  }
  public saveAudits(payloadObj, headerId, templateId) {
    const payload = {
      targetedType: 'RFQTemplateConfig',
      method: 'POST',
      servicePath: `/templates/headers/${templateId}/${headerId}`,
      payload: payloadObj
    };
    return this.apiService.post(payload);
  }
  getFieldDetails(headerId) {
    let formObj;
    const payload = {
      targetedType: 'RFQTemplateConfig',
      method: 'GET',
      servicePath: '/templates/headers/default/' + headerId,
      formParams: formObj
    }

    return this.apiService.post(payload);
  }

  public getDropdownValuesForfield(tmplID) {
    const formObj = {
      custTmplId: tmplID
    };
    const payload = {
      targetedType: 'RFQTemplateConfig',
      method: 'GET',
      servicePath: '/templates/headers',
      formParams: formObj
    }
    return this.apiService.post(payload);
  }
  public saveNewCustomField(newFieldObject) {
    const payload = {
      targetedType: 'RFQTemplateConfig',
      method: 'POST',
      servicePath: '/templates/headers',
      payload: newFieldObject
    }
    return this.apiService.post(payload);
  }

  public editTemplateField(custTmplHeaderId) {
    const payload = {
      targetedType: 'RFQTemplateConfig',
      method: 'GET',
      servicePath: '/templates/headers/' + custTmplHeaderId,
      formParams: {}
    }
    return this.apiService.post(payload);
  }

  public updateTemplateField(newFieldObject) {
    newFieldObject.custId = 380;
    const payload = {
      targetedType: 'RFQTemplateConfig',
      method: 'PUT',
      servicePath: '/templates/headers',
      payload: newFieldObject
    }
    return this.apiService.post(payload);
  }

  public saveTemplate(templateInfo) {
    const payload = {
      targetedType: 'RFQTemplateConfig',
      method: 'PUT',
      servicePath: '/templates/' + templateInfo.templateId,
      payload: templateInfo
    }
    return this.apiService.post(payload);
  }

  public deleteTemplateField(headerId = 0, templateId = 0) {
    const formObj = {
      custTmplId: templateId
    };

    const payload = {
      targetedType: 'RFQTemplateConfig',
      method: 'DELETE',
      servicePath: `/templates/headers/${headerId}`,
      formParams: formObj
    }
    return this.apiService.post(payload);
  }

  public setTemplate(val) {
    this.template.next(val);
  }

  public getTemplate() {
    return this.template.asObservable();
  }

  public setTemplateId(val) {
    this.templateId.next(val);
  }

  public getTemplateId() {
    return this.templateId.asObservable();
  }

  public setTemplateStatus(templateId, status) {
    const payload = {
      targetedType: 'RFQTemplateConfig',
      method: 'POST',
      servicePath: `/templates/${templateId}/status/${status}`,
      payload: {}
    }
    return this.apiService.post(payload);
  }
  public getRefSetConstants(keys) {
    const formObj = { keys };
    const payload = {
      targetedType: 'RFQTemplateConfig',
      method: 'GET',
      servicePath: '/refSets',
      formParams: formObj
    }

    return this.apiService.post(payload);
  }

  public getRfxCronTime() {
    const payload = {
      targetedType: 'SchedulerService',
      method: 'GET',
      servicePath: '/nvidia/schedule'
    };
    return this.apiService.post(payload);
  }

  public updateRfxCronTime(schedule) {
    const payload = {
      targetedType: 'SchedulerService',
      method: 'PUT',
      formParams: { schedule },
      servicePath: '/nvidia/schedule'
    };
    return this.apiService.post(payload);
  }
  saveRfqCloseDays(rfqCloseDays, userEmail) {
    const payload = {
      targetedType: 'RFQ',
      method: 'GET',
      formParams: {
        rfqCloseDays,
        userEmail
      },
      servicePath: 'createOrUpdateRfqConfiguration'
    };
    return this.apiService.post(payload);
  }
  getRfqCloseDays(userEmail) {
    const payload = {
      targetedType: 'RFQ',
      method: 'GET',
      formParams: {
        userEmail
      },
      servicePath: 'getRfqConfiguration'
    };
    return this.apiService.post(payload);
  }
  deleteRfqCloseDays(userEmail) {
    const payload = {
      targetedType: 'RFQ',
      method: 'GET',
      formParams: {
        userEmail
      },
      servicePath: 'deleteRfqConfiguration'
    };
    return this.apiService.post(payload);
  }
  public getValuesForSelectedField(custId, dbColumn) {
    const payload = {
      targetedType: 'RFQTemplateConfig',
      method: 'GET',
      servicePath: `/templates/headers/customer/${custId}/dbColumn/${dbColumn}`,
      formParams: {}
    }

    return this.apiService.post(payload);
  }
  public setTemplateNameError(bool) {
    this.templateNameError = bool;
  }
  public getTemplateNameError() {
    return this.templateNameError;
  }

  public getReservedColumnsData() {
    const payload = {
      targetedType: 'RFQTemplateConfig',
      method: 'GET',
      servicePath: `/templates/config/tenant/reservedcolumns`,
      formParams: {}
    }
    return this.apiService.post(payload);
  }

  public getAutofillStatus(userEmail: string) {
    const payload = {
      targetedType: 'RFQTemplateConfig',
      method: 'POST',
      servicePath: `/templates/config/tenant/reservedcolumns/autofill`,
      formParams: {
        userEmail
      }
    }
    return this.apiService.post(payload);
  }

}
