import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Router } from '@angular/router';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { DateUtils } from '../../common/utills/DateUtils.service';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { typeProduct } from 'app/modules/srfq/common/constants';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';

@Component({
    selector: 'child-cell',
    template: `
             <div class="position-relative status-tooltip-value">
                <span style="width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                display: block;">{{val}}</span>
                <div class="custom-tooltip right" *ngIf="enableTooltip">
                  <ul class="tip-content" [perfectScrollbar]="scrollBarConfiguration">
                    <li class="supplier-details" *ngIf="disable">
                       <a href="javscript:void(0)" (click)="Navigation(rfqCustId)" title={{rfq}} style="display:inline-block;cursor:not-allowed;">{{rfq}},</a>
                       <span style="display:inline-block;">{{displayTitle}},&nbsp;</span>
                    </li>
                    <li class="supplier-details" *ngIf="!disable">
                       <a href="javscript:void(0)" (click)="Navigation(rfqCustId)" title={{rfq}} style="display:inline-block;">{{rfq}},</a>
                       <span style="display:inline-block;">{{displayTitle}},&nbsp;</span>
                    </li>

                    <li class="supplier-details">
                       <div>{{createdDate}},&nbsp; {{totalQuotePeriod}}</div>
                    </li>
                  </ul>
                 </div>
                </div>

    `,
    styleUrls: ['grid.tooltip.sass'],
})
export class GridToolTipComponent {
    scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
    public params: any;
    val: any;
    tooltipData: any = '';
    showTip = false;
    displayTitle: any;
    rfq: any;
    rfqCustId: any;
    totalQuotePeriod: string;
    obj: Array<any> = [];
    createdDate: any;
    disable = false;
    enableTooltip = false;
    focus: any;
    public productType = typeProduct;
    constructor(private router: Router, private dataStorageService: DataStorageService, private scopeDataService: ScopeDataService) {
        this.focus = this.scopeDataService.getFocusValue();
        this.scopeDataService.getFocus().subscribe(val => {
            this.focus = val;
        })
    }
    agInit(params: any): void {
        this.val = params.value;
        this.params = params;
        let obj: any;
        if (this.params.data['isOwner'] === 1) {
            this.disable = false;
        } else {
            this.disable = true;
        }
        if (this.focus === this.productType) {
            obj = {
                displayName: this.params.data['creatorName'],
                startQuotePeriod: this.params.data['startQp'],
                endQuotePeriod: this.params.data['endQp']
            }
        } else {
            obj = {
                displayName: this.params.data['COMMODITY_MANAGER'],
                startQuotePeriod: this.params.data['startQP'],
                endQuotePeriod: this.params.data['endQp']
            }
        }
        if (obj.displayName !== '') {
            this.displayTitle = 'By ' + obj.displayName
        }
        this.rfq = this.params.data['rfq_cust_name']
        if (obj.startQuotePeriod !== undefined && obj.endQuotePeriod !== undefined) {
            this.totalQuotePeriod = obj.startQuotePeriod + ' to ' + obj.endQuotePeriod
        }
        this.createdDate = this.params.data['createdDate'];
        const date = DateUtils.getDate(this.createdDate);
        this.createdDate = 'On ' + (DateUtils.format(date) || '--');
        this.rfqCustId = this.params.data['rfq_cust_id']
        this.rfqCustId = +this.rfqCustId;
        if (params.data.restricted === 1) {
            this.tooltipData = params.value;
            this.enableTooltip = true;
            this.obj.push(this.createdDate, this.totalQuotePeriod)
        }
    }
    Navigation(rfq) {
        let obj = {}
        obj = { rfqCustId: this.rfqCustId }
        this.dataStorageService.setClikedRFQforDetails(obj)
        if (this.params.data['isOwner'] === 1) {
            if (this.focus === this.productType) {
                this.router.navigate(['/app/srfx/product/pr/details']);
            } else {
                this.router.navigate(['/app/srfx/r/details']);
            }
        }
    }
}
