import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { LDTaskContentConfiguration, TaskContentIcons, TaskContentType, TaskLocationContextService } from './task-location-context.service';

@Component({
  selector: 'ld-task-location-context',
  templateUrl: './task-location-context.component.html',
  styleUrls: ['./task-location-context.component.sass'],
  encapsulation: ViewEncapsulation.None,
  providers: [TaskLocationContextService]
})
export class TaskLocationContextComponent implements OnInit {

  icon: string;
  iconTitle: string;
  @Input() configuration: LDTaskContentConfiguration
  @Output() onTagClick: EventEmitter<any> = new EventEmitter<any[]>();
  constructor(
    private taskLocationContextService: TaskLocationContextService
  ) { }

  ngOnInit() {
    if(this.configuration){
      this.icon = TaskContentIcons[this.configuration.type];
      if(this.configuration.type === TaskContentType.LOCATION) {
        this.iconTitle = 'Location';
      } else if (this.configuration.type === TaskContentType.CONTEXT){
       this.iconTitle = 'Context';

      }
    }
  }
  onTagClicked(event) {
    this.onTagClick.emit(event);
  }
}
