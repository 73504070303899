import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  OnInit,
  ChangeDetectorRef
} from '@angular/core';

import { FORMAT_INPUT_TYPES, FORMAT_DEFAULT, FORMAT_USERS, DATE_FORMAT, INIT_FORMAT_DATA } from 'app/modules/rfq-configurations/shared/constants'
import { RfqConfigService } from 'app/modules/rfq-configurations/shared/rfq-config.service';
import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService';
// import { RfqConfigService } from '../../../shared/rfq-config.service';

@Component({
  selector: 'format',
  templateUrl: './format.component.html',
  styleUrls: ['./format.component.sass'],
  providers: []
})

export class Format implements OnInit {
  @Input() mappingExistingField = false;
  @Input()
  set format(value) {
    if (value) {
      this.formatInfo = value;
    } else {
      this.formatInfo;
    }
  }
  @Input()
  set dropdownValues(value) {
    if (!value || !value.DECIMAL_PLACES || !value.UNITS || !value.HEADER_ADHOC_CELLTYPE) { return; }
    this.decimalPlaces = value.DECIMAL_PLACES;
    this.units = value.UNITS;
    this.adHocCell = value.HEADER_ADHOC_CELLTYPE;
  }

  @Input() fieldInputs: Array<object> = [];
  @Input()
  set fieldType(val) {
    if (!val) { return; }
    this._fieldType = val;
    if (this.init && !this.mappingExistingField) {
      this.resetFormatData(val);
    }
  }
  get fieldType() {
    return this._fieldType;
  }
  @Output() fieldSelectionChange: EventEmitter<Object> = new EventEmitter<Object>();
  @Output() onFormatChange: EventEmitter<Object> = new EventEmitter<Object>();
  public formatInfo;
  private _fieldType = '';
  public decimalPlaces: Array<Object>;
  public units: Array<Object>;
  public adHocCell: Array<any>;
  public adhocItems: Array<any>;
  public inputs = FORMAT_INPUT_TYPES;
  public defaults = FORMAT_DEFAULT;
  public users = FORMAT_USERS;
  public dateFormats = DATE_FORMAT;
  public default: any;
  public apiText = '';
  public envDetails: any;
  userType: string;
  inputType: string;
  selectedValues = [];
  selectedValuesString = '';
  private init = false;
  public adhocEligible = false;
  public bothAdhocEligible = false;
  constructor(
    private rfqConfigService: RfqConfigService,
    private _cdr: ChangeDetectorRef,
    private customerConfigurationService: CustomerConfigurationService) {
    this.envDetails = this.customerConfigurationService.getEnvironmentDetails();
  }

  ngOnChanges(changes: SimpleChanges) {
    /*  below conditions are to make sure that we
      got all our async inputs values in the component and then we set the defaults   */
    if (this.units && this.units.length &&
      this.decimalPlaces && this.decimalPlaces.length &&
      this.dateFormats && this.dateFormats.length &&
      this.adHocCell && this.adHocCell.length &&
      this.fieldInputs && this.fieldInputs.length
    ) {
      this.setDefaultsForChosenUser();
    }
  }

  ngOnInit() {
    this.userType = this.users[0].refTermActualValue;
  }

  userTypeChange(event) {
    if (!event || !event.refTermActualValue) { return; }
    const prevType = this.userType !== event.refTermActualValue ? this.userType : undefined;
    this.userType = event.refTermActualValue;
    this.setDefaultsForChosenUser(prevType);
  }

  setDefaultsForChosenUser(prevType?) {
    if (!(this.units && this.units.length &&
      this.decimalPlaces && this.decimalPlaces.length &&
      this.dateFormats && this.dateFormats.length &&
      this.adHocCell && this.adHocCell.length &&
      this.defaults && this.defaults.length && this.userType
    )) { return; }
    this.default = this.initDefault();
    this.setAdhocEligible();
    this.formAdhocCellListItems();
    if (this.formatInfo[this.userType]['type'] === 'NUMERIC') {
      this.default['decimalPlace'] = this.selectDefaultDropdownValue(this.decimalPlaces, 'decimalPlaces');
      this.default['unit'] = this.selectDefaultDropdownValue(this.units, 'units');
    }
    else if (this.formatInfo[this.userType]['type'] === 'DATE') {
      this.default['dateFormat'] = this.selectDefaultDropdownValue(this.dateFormats, 'dateFormat');
    }
    else if (this.formatInfo[this.userType]['type'] === 'DROPDOWN' || this.formatInfo[this.userType]['type'] === 'MULTISELECT'
      || this.formatInfo[this.userType]['type'] === 'SINGLESELECT') {
      this.selectedValues = this.formatInfo[this.userType]['selectedValues'];
      if (this.selectedValues && this.selectedValues.length === 1 && this.formatInfo[prevType] &&
        this.formatInfo[prevType]['selectedValues'] && this.formatInfo[prevType]['selectedValues'].length > 0) {
        this.selectedValues = this.formatInfo[prevType]['selectedValues'];
      }
      this.selectedValuesString = this.setSelectedValuesString();
      /*  if (this.selectedValuesString && this.selectedValuesString.length) {
          this.formatInfo[this.userType]['defaultValue'] = 'Input values';
        } */
      this.default['value'] = this.selectDefaultDropdownValue(this.defaults, 'defaultValue');
    } else if (this.formatInfo[this.userType]['type'] === 'API') {
      this.selectedValues = this.formatInfo[this.userType]['selectedValues'];
      this.selectedValuesString = this.setSelectedValuesString();
      this.formatInfo[this.userType]['apiText'] = this.formatInfo[this.userType]['defaultValue'];
    }

    if (this.formatInfo[this.userType]['adhocCellType'] !== undefined) {
      this.default['adHocCell'] = this.selectDefaultDropdownValue(this.adHocCell, 'adhocCellType');
    } else {
      this.default['adHocCell'] = null;
    }
    /**
     * If tooltip is not present for current type
     * then copy it from previous type
     */
    if (prevType && this.formatInfo[prevType].toolTip && this.formatInfo[this.userType].toolTip.trim().length === 0) {
      this.formatInfo[this.userType].toolTip = this.formatInfo[prevType].toolTip;
    }
    this._cdr.detectChanges();
    this.init = true;
  }

  selectDefaultDropdownValue(fromArray, value) {
    if (!fromArray || !fromArray.length) {
      return;
    }
    const selected = fromArray.find((item) => {
      return (item.refTermActualValue == this.formatInfo[this.userType][value]);
    });
    return selected || fromArray[0];
  }

  inputTypeChange(event) {
    if (!event || !event.refTermActualValue) { return; }
    this.inputType = event.refTermActualValue;
    // if(this.inputType==='Custom') {
    // 	this.selectedValues = [];
    // 	this.selectedValuesString = "";
    // }
  }

  formatChange(key, event) {
    let value;
    if (!event || !this.init) { return; }
    if (key === 'selectedValues') {
      value = event.trim().split('\n');
      this.selectedValues = value.map(element => {
        return element = element.trim();
      });
      value = this.selectedValues;
    } else {
      value = event.refTermActualValue;
    }
    this.formatInfo[this.userType][key] = value;
    this.onFormatChange.emit(true);
  }

  formatAPIChange(key, event) {
    this.formatInfo[this.userType]['apiText'] = event.target.value;
    this.formatInfo[this.userType][key] = event.target.value;
    this.onFormatChange.emit(true);
  }
  setSelectedValuesString() {
    const selectedValuesString = this.selectedValues && this.selectedValues.toString().trim().replace(/,/g, '\n') || '';
    return selectedValuesString;
  }

  getFieldValues(field = {}) {
    const dbName = field && field['metaInfo'] && field['metaInfo']['dbColumn'] || '';
    if (dbName) {
      this.rfqConfigService.getValuesForSelectedField(this.envDetails.tenantId, dbName).subscribe((values) => {
        this.selectedValues = values instanceof Array && values || [];
        this.formatInfo[this.userType]['selectedValues'] = this.selectedValues;
        this.selectedValuesString = this.setSelectedValuesString();
        this._cdr.detectChanges();
      });
    }
  }

  resetFormatData(type) {
    this.formatInfo['supplier'] = JSON.parse(JSON.stringify(INIT_FORMAT_DATA[type]));
    this.formatInfo['customer'] = JSON.parse(JSON.stringify(INIT_FORMAT_DATA[type]));
    this.setDefaultsForChosenUser();
  }

  initDefault() {
    return {
      decimalPlace: null,
      unit: null,
      dateFormat: null,
      value: null,
      adhocCell: null
    };
  }
  setAdhocEligible() {
    if (this.formatInfo && this.userType) {
      this.adhocEligible = this.formatInfo[this.userType].adhocEligible;
      this.bothAdhocEligible = this.formatInfo[this.users[0].refTermActualValue].adhocEligible || this.formatInfo[this.users[1].refTermActualValue].adhocEligible;
    }
  }
  formAdhocCellListItems() {
    if (this.adhocEligible) {
      const options = this.formatInfo[this.userType].adhocCellOptions;
      this.adhocItems = this.adHocCell.filter(x => options.includes(x.refTermActualValue));
    }
  }
}
