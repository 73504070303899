import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';

import { DetailView } from './detail-view.model';
import { ApiServiceProvider } from 'app/api-service';
import { BaseService } from 'app/modules/common/base.service';

@Injectable()
export class DetailViewService {
  constructor(
    private apiService: ApiServiceProvider,
    private baseService: BaseService
  ) {}

  /* getDetailedRFQInfo(payLoad) {
		return this.apiService.post(payLoad);
	} */

  /*----------------- GET RFQ INFORMATION -----------------  */
  getDetailedRFQInfo(request) {
    const payload = {
      method: 'GET',
      servicePath: 'common/getRFQNameByRfqId',
      targetedType: 'RFQ',
      formParams: {
        rfqId: request.rfqId
      }
    };
    return this.apiService.post(payload);
  }

  /*----------------- UPDATE RFQ FORECAST UPDATE ----------------- */
  updateRFQForecast(request) {
    const payload = {
      method: 'GET',
      servicePath: 'updateRFxForecastFields',
      targetedType: 'RFQ',
      formParams: {
        rfqCustId: request.rfqId
      }
    };
    return this.apiService.post(payload);
  }

  /*----------------- GET RFQ FORECAST UPDATE VERSIONS ----------------- */
  getForecastUpdateVersions(request) {
    const payload = {
      method: 'GET',
      servicePath: 'getRfxForecastUpdateVersion',
      targetedType: 'RFQ',
      formParams: {
        rfqCustId: request.rfqId
      }
    };
    return this.apiService.post(payload);
  }

  /*----------------- GET SELECTED FORECAST VERSION ROW DETAILS ----------------- */
  getRfxForecastLog(request) {
    const payload = {
      method: 'POST',
      servicePath: 'getRfxForeccastLog',
      targetedType: 'RFQ',
      formParams: {
        rfqCustId: request.rfqCustId
      },
      payload: request
    };
    return this.apiService.post(payload);
  }

  /*----------------- DOWNLOAD EXCEL USING POST METHOD -----------------  */
  downloadForecastLogExcel(request) {
    const payload = {
      targetedType: 'RFQ',
      method: 'POST',
      servicePath: '/downloadRFXForeCastLog',
      payload: request.payload
    };
    return payload;
    // return this.apiService.downloadExcelUsingPost(payload);
  }

  /*----------------- Utill Methods ----------------------- */
  public filterLogGridHeaders(headersArray) {
    if (headersArray !== undefined) {
      if (headersArray instanceof Array) {
        let finalArrayHeaderArrayObject: Array<any> = [];

        finalArrayHeaderArrayObject = headersArray.filter(function(header) {
          return header.dtlVisible === 1;
        });
        return finalArrayHeaderArrayObject;
      }
    }
  }
  setRfqDates(rfqId: number, submissionDate: number, awardedDate: number) {
    const data = {
      targetedType: 'RFQ',
      servicePath: 'updateRfxSchedulerDates',
      method: 'GET',
      formParams: {
        rfqCustId: rfqId,
        submissionDate,
        awardDate: awardedDate,
      },

    }
    return this.apiService.post(data);
  }
  getRFQSummary(payLoad) {
    return this.apiService.post(payLoad);
  }
  getSupplierSummary(payLoad) {
    return this.apiService.post(payLoad);
  }
  getCPNSummary(payLoad) {
    return this.apiService.post(payLoad);
  }
  getRFXLegends(payLoad) {
    return this.apiService.post(payLoad);
  }
  getResponseHistory(payLoad) {
    return this.apiService.post(payLoad);
  }

  updateRowData(rfqId, editedRows, splitCpns) {
    let supIds = JSON.stringify(splitCpns.filter(onlyUnique));
    supIds = supIds.substring(supIds.indexOf('[') + 1, supIds.indexOf(']'));
    const payload = {
      targetedType: 'RFQ',
      method: 'POST',
      formParams: {
        cpns: supIds
      },
      payload: editedRows,
      servicePath: '/rfq/' + rfqId + '/manualUpdate'
    };
    return this.apiService.post(payload);
  }

  getRFXResponsesHeaders(rfqId) {
    const payload = {
      targetedType: 'RFQ',
      formParams: {
        tenantType: 'DETAIL'
      },
      method: 'POST',
      payload: {},
      servicePath: 'common/templates/rfq/' + rfqId + '/headerInfo'
    };
    return this.apiService.post(payload);
  }

  // getRFXResponsesData(rfqId, selectedView, selectedViewValues, appliedFilters) {
    getRFXResponsesData(rfqId, selectedView, appliedFilters, selectedSource) {
      /* selectedView = JSON.parse(JSON.stringify(selectedView));
      selectedView['custViewTypeVal'] = selectedViewValues;
      if (appliedFilters instanceof Array && appliedFilters.length > 0) {
        selectedView['scopeConfig'] = {
          filter: appliedFilters
        };
      } */

      selectedView = JSON.parse(JSON.stringify(selectedView));
      if (appliedFilters instanceof Array && appliedFilters.length > 0) {
        selectedView['scopeConfig'] = {
          filter: appliedFilters,
         filterBySource: selectedSource
        };
      }else{
        selectedView['scopeConfig'] = {
         filterBySource: selectedSource
        };
      }

      selectedView['target'] = 'DETAIL';
      selectedView['targetId'] = rfqId;
      const payload = {
        targetedType: 'RFQ',
        formParams: {
          rfqCustId: rfqId
        },
        method: 'POST',
        payload: selectedView,
        servicePath: 'getRfxResponsesAnalytics'
      };
      return this.apiService.post(payload);
    }

  /* getRFXSelectedResponsesData(
    rfqId,
    selectedView,
    selectedViewValues,
    cpnDispIds
  ) */
  getRFXSelectedResponsesData(rfqId, selectedView, cpnDispIds) {
    selectedView = JSON.parse(JSON.stringify(selectedView));
    // selectedView["custViewTypeVal"] = selectedViewValues;
    selectedView['scopeConfig'] = { selectedIds: cpnDispIds };
    selectedView['target'] = 'DETAIL';
    selectedView['targetId'] = rfqId;

    const payload = {
      targetedType: 'RFQ',
      formParams: {
        rfqCustId: rfqId
      },
      method: 'POST',
      payload: selectedView,
      servicePath: 'getRfxResponsesAnalytics'
    };
    return this.apiService.post(payload);
  }

  getQualitativeInsights() {
    const payload = {
      targetedType: 'RFQ',
      method: 'GET',
      servicePath: 'getInsightsMeta'
    };
    return this.apiService.post(payload);
  }
  undoRevoke(rfqId) {
    const payload = {
      targetedType: 'RFQ',
      method: 'GET',
      formParams: {
        rfqCustId: rfqId
      },
      servicePath: '/rfq/undoRevoke'
    };
    return this.apiService.post(payload);
  }
  revoke(rfqId) {
    const payload = {
      targetedType: 'RFQ',
      method: 'GET',
      formParams: {
        rfqCustId: rfqId
      },
      servicePath: 'revokerfq'
    };
    return this.apiService.post(payload);
  }
  close(rfqId) {
    const payload = {
      targetedType: 'RFQ',
      method: 'GET',
      formParams: {
        rfqCustId: rfqId
      },
      servicePath: 'closerfq'
    };
    return this.apiService.post(payload);
  }

  deleteRFQ(rfqId) {
    const payload = {
      targetedType: 'RFQ',
      method: 'GET',
      formParams: {
        rfqCustId: rfqId
      },
      servicePath: 'deleteRFQ'
    };
    return this.apiService.post(payload);
  }

  compareResponses(comparePayload, supId) {
    const payload = {
      targetedType: 'RFQ',
      method: 'GET',
      formParams: {
        input: encodeURIComponent(JSON.stringify(comparePayload)),
        supplierId: supId
      },
      servicePath: 'compareSupplierResponses'
    };
    return this.apiService.post(payload);
  }
  getrfqSupId(custid,supId) {
    const payload = {
      targetedType: 'RFQ',
      method: 'GET',
      formParams: {
        customerRfqId: custid,
        supplierId: supId
      },
      servicePath: 'rfq/get/supplierRfqId'
    };
    return this.apiService.post(payload);
  }
  getSupplierData() {
    const payload = {
      targetedType: 'RFQ',
      method: 'GET',
      servicePath: 'getsupplierslist'
    };
    return this.apiService.post(payload);
  }
  getResponseData(rfqSupId) {
    const payload = {
      clientName: 'SRMService',
      method: 'GET',
      formParams: {
        rfqSupId,
      },
      encryptionType: 'formParams',
      servicePath: `/SRMService/getsuprfqresponse/list`,
    };
    return this.apiService.post(payload);
  }
  sendSupplierRFQ(supRfqId, payloadObj, respId, name, description, date) {
    if (respId == 0) {
      respId = null;
    }
    const payload = {
      clientName:'SRMService',
      method: 'POST',
      formParams: {
        rfqSupId: supRfqId,
        rfqSupRespId: respId,
        responseName: encodeURIComponent(name),
        responseDesc: encodeURIComponent(description),
        lastUpdateTime: date,
      },
      payload: payloadObj,
      servicePath: `/SRMService/sendSupplierRFQ`,
    };
    return this.apiService.post(payload);
  }

  getDetailsHeader(id) {
    const payload = {
      targetedType: 'SRMService',
      clientName: 'SRMService',
      method: 'POST',
      encryptionType: 'formParams',
      servicePath: `SRMService/common/templates/rfq/COMMODITY/headerInfoByTenantTypes`,
      tenantType: 'SUPPLIER',
      formParams: {
        rfqId: id,
        tenantTypes: 'SUPPLIER',
      },
    };
    return this.apiService.post(payload);
  }

  getDetailsData(supId, custRfqId, templateType, templId) {
    const payload = {
      clientName: 'SRMService',
      method: 'GET',
      formParams: {
        supplierId: supId,
        customerRFQId: custRfqId,
        templateTypeName: templateType,
      },
      payload: {
        templateId: templId,
        customerRFQId: custRfqId,
        supplierId: supId,
        templateType,
      },
      servicePath: `/SRMService/getrfqdata`,
    };
    return this.apiService.post(payload);
  }
  getRfxTimelineResponses(rfqCustId: any) {
    const payload = {
      targetedType: 'RFQ',
      method: 'GET',
      formParams: {
        rfqCustId
      },
      servicePath: 'getRfqNotifications'
    };
    return this.apiService.post(payload);
  }

  getPostRFXSelectionDetails(rfqCustId){
    const payload = {
      targetedType: 'RFQ',
      method: 'GET',
      servicePath: 'rfq/addedscopeids',
      formParams: {
        rfqCustId
      },
    };
    return this.apiService.post(payload);
  }
  public validateServiceData(data) {
    if (data instanceof Object) {
      if (data['responseStatus'] instanceof Object) {
        switch (data['responseStatus']['code']) {
          case 200:
            return data;
          default:
            return 'error';
        }
      }
    }
  }
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
