import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'reverse', pure: false })
export class ReverseArray implements PipeTransform {

    public transform(value: any) {
        if (!value) return;

        return value.reverse();
    }
}