export class ObjectUtils {
  /**
   *  Get a value deep inside an object or array
   *
   * @param {object | array} object or array to get the values inside
   * @param {path} location of key to read from the object
   * @returns {string|number}
   *
   */
  public static deepFind(obj: object, path: string): any {
    if (obj instanceof Object && obj !== null) {
      try {
        if (typeof path === 'string') {
          if (path.length > 0) {
            let paths = path.split('.'),
              current = obj,
              i;
            for (i = 0; i < paths.length; ++i) {
              if (current && current.hasOwnProperty(paths[i]) === undefined) {
                return undefined;
              } else {
                current = current[paths[i]];
              }
            }
            return current;
          }
        }
      } catch (e) {}
    }
    return null;
  }

  /**
   *  Set a value deep inside an object or array
   *
   * @param {object | array} object or array to get the values inside
   * @param {any} any datatype which is used to set for given key
   * @param {path} location of key to set a value
   *
   */
  public static setToValue(obj, value, path) {
    path = path.split('.');
    let index = 0;
    for (let i = 0; i < path.length - 1; i++) {
      obj = obj[path[i]];
      index = i;
    }
    obj[path[index]] = value;
  }

  public static copy(value) {
    return JSON.parse(JSON.stringify(value));
  }

  public static objSort(...allArguments) {
    const args = allArguments;
    const array = args[0];
    let case_sensitive;
    let keys_length;
    let key;
    let desc;
    let a;
    let b;
    let i;

    if (typeof allArguments[allArguments.length - 1] === 'boolean') {
      case_sensitive = allArguments[allArguments.length - 1];
      keys_length = allArguments.length - 1;
    } else {
      case_sensitive = false;
      keys_length = allArguments.length;
    }

    return array.sort((obj1, obj2) => {
      for (i = 1; i < keys_length; i++) {
        key = args[i];
        if (typeof key !== 'string') {
          desc = key[1];
          key = key[0];
          a = obj1[args[i][0]];
          b = obj2[args[i][0]];
        } else {
          desc = false;
          a = obj1[args[i]];
          b = obj2[args[i]];
        }

        if (case_sensitive === false && typeof a === 'string') {
          a = a.toLowerCase();
          b = b.toLowerCase();
        }

        if (!desc) {
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
        } else {
          if (a > b) {
            return -1;
          }
          if (a < b) {
            return 1;
          }
        }
      }
      return 0;
    });
  }

  public static getKeyByValue(obj, val) {
    if (obj instanceof Object) {
      const entry = Object.entries(obj).find((i) => i[1] === val);
      if (entry instanceof Array && entry.length > 0) {
        return entry[0];
      }
    }
  }
}
