import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';

@Component({
  selector: 'child-cell',
  template: `
    <div *ngFor="let actionBtn of buttonList" class="leva-margin-seperator">
      <button
        id="{{ actionBtn.id }}"
        [attr.data-ld-automation-id]="actionBtn.id"
        class="btn btn-link"
        (click)="onActionClick(actionBtn)"
        [disabled]="actionBtn?.isDisable"
      >
        <span title="{{ actionBtn.title }}">{{ actionBtn.text }}</span>
      </button>
    </div>
  `
})
export class ActionCLRendererComponent implements ICellRendererAngularComp {
  params: any;
  buttonList = [];

  agInit(params: any): void {
    this.params = params;
    this.buttonList = JSON.parse(
      JSON.stringify(params.context.componentParent.buttonList)
    );

    this.buttonList.forEach(actionButton => {
      if (actionButton.ableToDisable) {
        const statusValue = this.params.data[actionButton.disableKey];
        actionButton['isDisable'] =
          statusValue === actionButton.disableKeyValue;
        if (statusValue !== null) {
          actionButton.title = actionButton.titleSource[statusValue];
        }
      } else {
        actionButton['isDisable'] = false;
      }
    });
  }

  refresh(): boolean {
    return false;
  }

  onActionClick(action) {
    const type = action.purpose;
    if (action.ableToDisable ) {
      action.isDisable = true;
      action.title = action.titleSource[action.disableKeyValue];
    }
    this.params.context.componentParent.invokeParentMethod(
      type,
      this.params.data
    );
  }
}
