import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { QuickSightDashboardService } from './shared/quicksight-dashboard.service';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { TimeSelectionDataService } from '../time-selection/shared/time-selectiondata.service';
import { SubscriptionLike } from 'rxjs';
import { ScopeDataService } from 'app/modules/common/scope.data.service';
import { SubscriptionService } from 'app/modules/common/subscription.service';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'quicksight-dashboard',
  templateUrl: 'quicksight-dashboard.component.html',
  providers: [QuickSightDashboardService],
})
export class QuickSightDashboardComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() currentQuarter = '';
  @Input() dashboardId = null;
  @Input() viewByCost = null
  @Input() target = null;
  @Input() renderDashboardId = false;
  @Input() dropdownData = null;
  reset = 'enable';
  selectedQuarters = [];
  quickSightSubscription: SubscriptionLike;
  dropDownSubscription: SubscriptionLike;
  pricingReportSubscription: SubscriptionLike;
  selQuarters = [];
  dashboardLoaded = false;
  defaultQuarters = [];
  appliedTimePeriodForOpp = {};
  dashboard: any;
  appliedScopeFilters = {};
  selectedDropValue = null;
  timePeriodFlag = false;
  defaultTimePeriodForOpp = 'Rest Of The Year';
  timeSelectionClearSubscription: SubscriptionLike;
  scopeSubscription: SubscriptionLike;
  failedTsDataSubscription: SubscriptionLike;
  appscopeSubscription: SubscriptionLike;
  clearscopeSubscription: SubscriptionLike;
  filterSubscription: SubscriptionLike;
  dropDownOppSubscription: SubscriptionLike;
  timeStampSelectionSubscription: SubscriptionLike;
  parameters = [
    'CMODM',
    'CommodityManager',
    'Commodity',
    'CPN',
    'Manufacturer',
    'MPN',
    'SubCommodity',
    'Source',
    'Supplier',
    'CPNDescription',
    'businessunit',
    'Product',
    'ProductFamily',
    'Control',
    'cmodmregion',
    'cmodmlocation',
    'division'
  ];
  loading = true;
  noDashboard = false;
  npParams: any = {};
  gPRDetails: any = {};
  constructor(
    private quickSightDashboardService: QuickSightDashboardService,
    private timeSelectionDataService: TimeSelectionDataService,
    private scopeDataService: ScopeDataService,
    private dataStorageService: DataStorageService,
    private subscriptionService: SubscriptionService
  ) { }

  ngOnInit() {
    this.dropDownOppSubscription = this.dataStorageService
      .getOpportunityViewDropDown()
      .subscribe((val) => {
        if (val) {
          this.selectedDropValue = val;
          if (this.dashboard && this.target === 'savingsopportunities') {
            this.setParametersForQuickSight({
              SpendViewBy: this.selectedDropValue,
              octapartPublicUrl: environment.octapartDistributorLink.replace('https://', '')
            });
          }
        }
      });

    this.dropDownSubscription = this.dataStorageService
      .getSelDropdownValue()
      .subscribe((val) => {
        if (val) {
          this.selectedDropValue = val;
          if (this.dashboard && this.target === 'spendintelligence') {
            this.setParametersForQuickSight({
              SpendViewBy: this.selectedDropValue,
            });
          }
        }
      });

    this.pricingReportSubscription = this.dataStorageService.getPricingReportRefreshDate().subscribe((val) => {
      if (val) {
        this.gPRDetails['LastRefreshDate'] = val;
        this.setParametersForQuickSight(this.gPRDetails);
        this.embedQuickSightDashboard();
      }
    })
    this.filterSubscription = this.dataStorageService
      .getSelectedDimensionDetails()
      .subscribe((filterValue) => {
        let dim;
        if (filterValue) {
          dim = this.quickSightDashboardService.retriveQuickSightParameterNames(
            filterValue['dimName']
          );
          if (dim === undefined) {
            dim = filterValue['dimName'];
          }
          if (dim.toLowerCase() === 'supplier') {
            this.npParams['Manufacturer'] = '[ALL]';
          } else if (dim.toLowerCase() === 'manufacturer') {
            this.npParams['Supplier'] = '[ALL]';
          }
          this.npParams[dim] = filterValue['value'].toString().toUpperCase();
          this.npParams['SpendViewBy'] = dim;
          if (
            this.dashboard &&
            (this.target === 'npspendandsavings' ||
              this.target === 'npsavingsopportunities')
          ) {
            this.setParametersForQuickSight(this.npParams);
          }
        }
      });

    this.appscopeSubscription = this.scopeDataService
      .getApplicationScope()
      .subscribe((data) => {
        if (data) {
          this.appliedScopeFilters = {};
          if (
            data.selectedScopeData[this.scopeDataService.getFocusValue()]
              .dimensions !== undefined
          ) {
            const dimentions =
              data.selectedScopeData[this.scopeDataService.getFocusValue()]
                .dimensions;
            this.parameters.forEach((val) => {
              this.appliedScopeFilters[val] = '[ALL]';
            });
            for (const item of Object.keys(dimentions)) {
              let dimName = this.quickSightDashboardService.retriveQuickSightParameterNames(
                item
              );
              if (dimName === undefined) {
                dimName = item;
              }
              this.parameters.forEach((val) => {
                if (val === dimName) {
                  if (
                    dimName === 'Manufacturer' ||
                    dimName === 'Supplier' ||
                    dimName === 'CMODM' ||
                    dimName === 'Commodity' ||
                    dimName === 'SubCommodity' ||
                    dimName === 'CPN' ||
                    dimName === 'MPN' ||
                    dimName === 'Control' ||
                    dimName === 'CommodityManager' ||
                    dimName === 'division'
                  ) {
                    this.appliedScopeFilters[dimName] = Object.keys(
                      dimentions[item]
                    )
                      .map((i) => i.toUpperCase())
                  } else {
                    this.appliedScopeFilters[dimName] = Object.keys(
                      dimentions[item]
                    )
                  }
                }
              });
            }
          }
          if (
            data.selectedScopeData[this.scopeDataService.getFocusValue()]
              .timeDuration
          ) {
            if (
              data.selectedScopeData[this.scopeDataService.getFocusValue()]
                .timeDuration.length === 0
            ) {
              this.appliedScopeFilters['CalendarFilter'] = '[ALL]';
            }
            this.selectedQuarters = [];
            this.selQuarters = [];
            this.timeSelectionDataService.setClearAll(undefined);
            for (const date of data.selectedScopeData.Commodity.timeDuration) {
              this.selectedQuarters.push(date.from_time_period);
            }
            this.getSelectedTimePeriod();
          } else {
            if (!this.dashboard) {
              this.embedQuickSightDashboard();
            }
          }
          if (
            data.selectedScopeData[this.scopeDataService.getFocusValue()]
              .dimensions ||
            data.selectedScopeData[this.scopeDataService.getFocusValue()]
              .timeDuration
          ) {
            if (this.selQuarters.length > 0) {
              this.defaultQuarters = this.selQuarters;
              this.appliedScopeFilters['CalendarFilter'] = this.selQuarters;
            }
            if (
              this.dashboard &&
              (this.target !== 'reports' ||
                this.target !== 'npspendandsavings' ||
                this.target !== 'npsavingsopportunities')
            ) {
              this.setParametersForQuickSight(this.appliedScopeFilters);
            } else {
              if (this.target === 'spendintelligence') {
                this.embedQuickSightDashboard();
              }
            }
          }
        }
      });
    this.scopeSubscription = this.scopeDataService
      .getFocus()
      .subscribe((scope) => {
        if (scope) {
          this.parameters.forEach((val) => {
            this.appliedScopeFilters[val] = '[ALL]';
          });
          if (
            this.dashboard &&
            (this.target !== 'reports' ||
              this.target === 'npspendandsavings' ||
              this.target === 'npsavingsopportunities')
          ) {
            this.setParametersForQuickSight(this.appliedScopeFilters);
          }
        }
      });
    this.clearscopeSubscription = this.subscriptionService
      .getClearScope()
      .subscribe((value) => {
        if (value !== undefined) {
          if (value) {
            this.parameters.forEach((val) => {
              this.appliedScopeFilters[val] = '[ALL]';
            });
            if (
              this.dashboard &&
              (this.target !== 'reports' ||
                this.target === 'npspendandsavings' ||
                this.target === 'npsavingsopportunities')
            ) {
              if (this.reset === 'enable') {
                this.reset = 'disable';
              } else {
                this.reset = 'enable';
              }
              this.appliedScopeFilters['reset'] = this.reset;
              this.setParametersForQuickSight(this.appliedScopeFilters);
            }
          }
        }
      });
    this.timeStampSelectionSubscription = this.scopeDataService
      .getTimeStampSelection()
      .subscribe((value) => {
        if (value) {
          if (value === 'Rest Of Year') {
            value = 'Rest Of The Year';
          }
          this.defaultTimePeriodForOpp = value;
          this.appliedTimePeriodForOpp['timeperiod'] = value;
          if (
            this.dashboard &&
            (this.target !== 'reports' ||
              this.target === 'npspendandsavings' ||
              this.target === 'npsavingsopportunities')
          ) {
            this.setParametersForQuickSight(this.appliedTimePeriodForOpp);
          }
        }
      });
    this.timeSelectionClearSubscription = this.timeSelectionDataService
      .getClearAll()
      .subscribe((val) => {
        if (val === 'clear') {
          this.selQuarters = [];
          this.defaultQuarters = [];
          if (
            this.dashboard &&
            (this.target !== 'reports' ||
              this.target === 'npspendandsavings' ||
              this.target === 'npsavingsopportunities')
          ) {
            this.setParametersForQuickSight({ CalendarFilter: '[ALL]' });
          }
        }
      });
    this.failedTsDataSubscription = this.dataStorageService
      .getFailedTsDataStatus()
      .subscribe((val) => {
        if (val && this.target === 'spendintelligence') {
          this.embedQuickSightDashboard();
        }
      });
  }

  ngOnChanges() {
    this.dataStorageService.setStatusOfQSDashboard(false);
    if (this.dropdownData) {
      if (
        this.dashboard &&
        (this.target !== 'reports' ||
          this.target === 'npspendandsavings' ||
          this.target === 'npsavingsopportunities')
      ) {
        this.setParametersForQuickSight({ SpendViewBy: this.dropdownData });
      }
    }
    if (this.viewByCost) {
      this.setParametersForQuickSight({
        viewByCost: this.viewByCost,
      })
    }
    this.dashboardLoaded = false;
    this.loading = true;
    if (this.target !== 'spendintelligence') {
      this.embedQuickSightDashboard();
    }
    if (this.renderDashboardId && this.target === 'spendintelligence') {
      this.embedQuickSightDashboard();
    }
  }

  embedQuickSightDashboard() {
    let resetDisable = this.target === 'reports' ? false : true;
    if (this.dashboardId === 'ecc8f563-a4b5-4b70-92d2-266d81edbf1a') {
      resetDisable = true;
    }
    this.quickSightSubscription = this.quickSightDashboardService
      .getDashboardUrl(this.dashboardId, resetDisable)
      .subscribe((data) => {
        if (data.result !== undefined && data.result !== null) {
          this.noDashboard = false;
          this.loading = false;
          const containerDiv = document.getElementById('dashboardContainer');
          containerDiv.innerHTML = '';
          const options = {
            url: data && data.result,
            container: containerDiv,
            scrolling: 'no',
          };
          options['parameters'] = {};
          if (this.target === 'PricingReport' || this.dashboardId === '1c8ce18e-44ab-4faf-88b1-8291b01d322b') {
            options['parameters'] = Object.assign(
              {},
              options['parameters'],
              this.gPRDetails
            );
          }
          if (
            this.target === 'npspendandsavings' ||
            this.target === 'npsavingsopportunities'
          ) {
            options['parameters'] = Object.assign(
              {},
              options['parameters'],
              this.npParams
            );
          }
          if (this.target === 'spendintelligence') {
            if (this.defaultQuarters.length > 0) {
              options['parameters']['CalendarFilter'] = this.defaultQuarters;
            }
            if (this.selectedDropValue && this.selectedDropValue.length > 0) {
              options['parameters']['SpendViewBy'] = this.selectedDropValue;
            }
            if (this.viewByCost) {
              options['parameters']['viewByCost'] = this.viewByCost

            }
          }
          if (this.target === 'reports') {
            options['parameters']['FyQtr'] = this.currentQuarter;
          }
          if (this.target === 'savingsopportunities') {
            options['parameters']['timeperiod'] = this.defaultTimePeriodForOpp;
            options['parameters']['octapartPublicUrl'] = environment.octapartDistributorLink.replace('https://', '');
          }
          if (this.target === 'offeredopportunities') {
            options['parameters']['octapartPublicUrl'] = environment.octapartDistributorLink.replace('https://', '');
          }
          if (
            Object.keys(this.appliedScopeFilters).length > 0 &&
            (this.target !== 'reports' &&
              this.target !== 'npspendandsavings' &&
              this.target !== 'npsavingsopportunities')
          ) {
            options['parameters'] = Object.assign(
              {},
              options['parameters'],
              this.appliedScopeFilters
            );
          }
          else {
            options['parameters'] = Object.assign(
              {},
              options['parameters'], {}
            );
          }
          this.dashboard = QuickSightEmbedding.embedDashboard(options);
          this.dashboard.on('error', (error: any) => {
            console.log('Error', error);
            this.loading = false;
            this.dashboardLoaded = true;
          });
          this.dashboard.on('load', (res: any) => {
            // this.loading = false;
            this.dashboardLoaded = true;
            this.dataStorageService.setStatusOfQSDashboard(true);
          });
        } else {
          this.noDashboard = true;
          this.loading = false;
          this.dashboardLoaded = true;
        }
      });
  }
  setParametersForQuickSight(parameters) {
    if (this.dashboard) {
      this.dashboard.setParameters(parameters);
      this.dataStorageService.setStatusOfQSDashboard(true)
    }
  }
  getSelectedTimePeriod() {
    try {
      if (
        this.timeSelectionDataService.getFullTimePeriodObject() !== undefined
      ) {
        if (
          this.timeSelectionDataService.getFullTimePeriodObject()[
          'timePeriod'
          ] !== undefined
        ) {
          for (const yearArray of Object.keys(
            this.timeSelectionDataService.getFullTimePeriodObject()[
            'timePeriod'
            ]
          )) {
            for (const qtrs of Object.keys(
              this.timeSelectionDataService.getFullTimePeriodObject()[
              'timePeriod'
              ][yearArray][0]
            )) {
              if (
                this.timeSelectionDataService.getFullTimePeriodObject()[
                'timePeriod'
                ][yearArray][0][qtrs] !== null
              ) {
                let val: any;
                val = this.timeSelectionDataService.getFullTimePeriodObject()[
                  'timePeriod'
                ][yearArray][0][qtrs].qsdate;
                if (this.selectedQuarters.length > 0) {
                  this.selectedQuarters.filter((element) => {
                    if (
                      this.timeSelectionDataService.getFullTimePeriodObject()[
                        'timePeriod'
                      ][yearArray][0][qtrs].qsdate === element
                    ) {
                      this.selQuarters.push(val.substr(0, 4) + '\'' + qtrs);
                    }
                  });
                }
              }
            }
          }
        }
      }
    } catch (error) {
      console.log('error in get selectedquarters method    ', +error);
    }
  }
  ngOnDestroy() {
    this.dataStorageService.setStatusOfQSDashboard(undefined);
    this.subscriptionService.setClearScope(undefined);
    this.timeSelectionDataService.setClearAll(undefined);
    // if(this.pricingReportSubscription)
    // {
    //   this.pricingReportSubscription.unsubscribe();
    // }
    if (this.failedTsDataSubscription) {
      this.failedTsDataSubscription.unsubscribe();
    }
    if (this.appscopeSubscription) {
      this.appscopeSubscription.unsubscribe();
    }
    if (this.timeStampSelectionSubscription) {
      this.timeStampSelectionSubscription.unsubscribe();
    }
    if (this.clearscopeSubscription) {
      this.clearscopeSubscription.unsubscribe();
    }
    if (this.timeSelectionClearSubscription) {
      this.timeSelectionClearSubscription.unsubscribe();
    }
    if (this.scopeSubscription) {
      this.scopeSubscription.unsubscribe();
    }
    if (this.quickSightSubscription) {
      this.quickSightSubscription.unsubscribe();
    }
    if (this.dropDownSubscription) {
      this.dropDownSubscription.unsubscribe();
    }
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
    if (this.dropDownOppSubscription) {
      this.dropDownOppSubscription.unsubscribe();
    }
  }
}
