import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'preload-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.sass']
})

export class PreloadNewsComponent implements OnInit {

  constructor() { }

  ngOnInit() { }
}
