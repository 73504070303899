import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { InteractiveEditService } from 'app/modules/interactive-edit/shared/interactive-edit.service';
import {
  ToasterService,
  ToasterConfig,
  Toast,
  BodyOutputType
} from 'angular2-toaster';
// import { environment } from '../../../../environments/environment';

@Component({
  selector: 'integer-cell',
  template: `
    <input
      #input
      (keydown)="onKeyDown($event)"
      [(ngModel)]="value"
      style="width: 100%"
    />
  `
})
export class IntegerEditorComponent
  implements ICellEditorAngularComp, AfterViewInit {

  constructor(
    private interactiveEditService: InteractiveEditService,
    toasterService: ToasterService
  ) {
    this.toasterService = toasterService;
  }
  private params: any;
  public value: any;
  private cancelBeforeStart = false;
  toasterService: ToasterService;
  public oldValue: any;
  toast: Toast = {
    type: 'error',
    // title: 'Warning',
    // body: 'Only integers are allowed !!',
    body: 'Only Integers Are Allowed',
    bodyOutputType: BodyOutputType.TrustedHtml
  };

  blankToast: Toast = {
    type: 'error',
    // title: 'Warning',
    // body: 'Only numerics are allowed !!',
    body: 'Blank Values Are Not Allowed',
    bodyOutputType: BodyOutputType.TrustedHtml
  };

  @ViewChild('input', { read: ViewContainerRef, static: true }) public input;

  public config1: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-right',
    timeout: 2000
  });

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value;
    this.oldValue = this.params.value;
    // only start edit if key pressed is a number, not a letter
    this.cancelBeforeStart =
      params.charPress && '1234567890'.indexOf(params.charPress) < 0;
  }

  getValue(): any {
    this.toasterService.clear();
    if (this.value !== '') {
      return this.value;
    } else {
      this.toasterService.pop(this.blankToast);
      return this.oldValue;
    }
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  // will reject the number if it greater than 1,000,000
  // not very practical, but demonstrates the method.
  isCancelAfterEnd(): boolean {
    return this.value < 0;
  }

  onKeyDown(event): void {
    this.toasterService.clear();
    const key = event.which || event.keyCode;
    if (key === 37 || key === 39) {
      event.stopPropagation();
    } else {
      if (!this.isKeyPressedNumeric(event)) {
        // this.loadEntitiesService.setNaiFound(true);
        this.toasterService.pop(this.toast);
        if (event.preventDefault) { event.preventDefault(); }
      } else {
        // this.loadEntitiesService.setNaiFound(false);
      }
    }
  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
    setTimeout(() => {
      this.input.element.nativeElement.focus();
    });
  }

  private getCharCodeFromEvent(event): any {
    event = event || window.event;
    return typeof event.which === 'undefined' ? event.keyCode : event.which;
  }

  private isCharNumeric(charStr): boolean {
    return !!/\d/.test(charStr);
  }

  private isKeyPressedNumeric(event): boolean {
    const charCode = this.getCharCodeFromEvent(event);
    const charStr = event.key ? event.key : String.fromCharCode(charCode);
    if (charStr === 'Backspace') {
      return this.isCharNumeric(8);
    } else if (charStr === 'Delete') {
      return this.isCharNumeric(46);
    } else if (charStr === 'Enter') {
      return this.isCharNumeric(13);
    } else if (charStr === 'Tab') {
      return this.isCharNumeric(9);
    } else if (charStr === 'Control') {
      return this.isCharNumeric(17);
    } else if (charStr === 'Alt') {
      return this.isCharNumeric(18);
    } else {
      return this.isCharNumeric(charStr);
    }
  }
}
