import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewChild,
  AfterViewChecked
} from '@angular/core';

import { MessageToSupplier } from './shared/message-to-supplier.model';
import { MessageToSupplierService } from './shared/message-to-supplier.service';
import { RecommendationsMessagesComponent } from 'app/modules/core/recommendations-messages/recommendations-messages.component';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';

@Component({
  selector: 'message-to-supplier',
  templateUrl: 'message-to-supplier.component.html',
  styleUrls: ['./message-to-supplier.component.sass'],
  providers: [MessageToSupplierService]
})
export class MessageToSupplierComponent implements OnInit, AfterViewChecked {
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  @Input() messages: Array<string> = [];
  @ViewChild('scrollMe', { static: false })
  private myScrollContainer: ElementRef;

  constructor() {}

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }
  ngAfterViewChecked() {
    this.scrollToBottom();
  }
  ngOnInit() {}
  ngOnChanges() {}
}
