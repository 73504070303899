import { orderBy } from 'lodash';
import { Component, forwardRef, Input, OnInit } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { DateUtils } from 'app/modules/common/utills/DateUtils.service'
import { EventMetaInfoService } from 'app/modules/curator/industry-news/industry-news-events/components/event-management/event-meta-info.service'
import { AssociatedNewsService } from './associated-news.service'

export interface NewsArticle {
  documentId?: string
  resourceId?: string
  articleId: string
  provider: string
  url: string
  publishedDate: string
  articleTitle: string
  associated?: boolean
  associatedCode?: number
}

@Component({
  selector: 'cprt-associated-news',
  templateUrl: './associated-news.component.html',
  styleUrls: ['./associated-news.component.sass'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AssociatedNewsComponent),
    multi: true,
  }]
})
export class AssociatedNewsComponent implements OnInit, ControlValueAccessor {

  _defaultSelection: any[] = []
  get defaultSelection() {
    return this._defaultSelection
  }
  @Input() set defaultSelection(val) {
    this._defaultSelection = val
  }
  searchNews = ''
  newsArticles = []
  showLoader = false
  taggedNews: NewsArticle[] = []
  metaInfo = null
  noDataMsg = `No news found of the search criteria`

  loaderConfig = {
    backdropBackgroundColour: 'rgba(255,255,255,0.3)',
    fullScreenBackdrop: 'true',
    primaryColour: '#1C4E84',
    secondaryColour: '#1C4E84',
    tertiaryColour: '#1C4E84'
  }
  filterOptions: any = {}
  selectedTime = null
  filterPayload: any = {}

  propagateChange = (_: any) => { }

  constructor(
    private eventMetaInfoService: EventMetaInfoService,
    private associatedNewsService: AssociatedNewsService) {
    this.metaInfo = this.eventMetaInfoService.getEventMetaInfo()
  }

  ngOnInit() {
    this.setFilterOptions()
    this.taggedNews = Object.assign([], this._defaultSelection)
    this.getRelevantNews()
  }

  setFilterOptions() {
    this.filterOptions = {
      time: [
        {
          label: 'All',
          value: 'all',
        },
        {
          label: 'Last Week',
          value: 'week',
        },
        {
          label: 'Last Month',
          value: 'month',
        },
        {
          label: 'Last Quarter',
          value: 'quarter',
        },
        {
          label: 'Older Than Quarter',
          value: 'quarterplus',
        },
      ],
      sentiment: [
        {
          label: 'All',
          value: 'all',
        },
        {
          label: 'Severe',
          value: 'Severe',
        },
        {
          label: 'High',
          value: 'High',
        },
        {
          label: 'Medium',
          value: 'Medium',
        },
        {
          label: 'Low',
          value: 'Low',
        },
      ],
      bookmark: [
        {
          label: 'All',
          value: 'false',
        },
        {
          label: 'Bookmarked',
          value: 'true',
        },
      ]
    }
  }

  getRelevantNews() {
    const formParams = {
      limit: 50,
      search: false
    }
    this.showLoader = true
    if (this.metaInfo.eventId !== null) { formParams['eventId'] = this.metaInfo.eventId }
    if (Object.keys(this.filterPayload).length > 0) { formParams['published'] = encodeURIComponent(JSON.stringify(this.filterPayload.published)) }
    this.associatedNewsService.getNewsArticles(formParams).subscribe((articlesResponse) => {
      if (articlesResponse && articlesResponse.articles.length > 0) {
        this.newsArticles = Object.assign([], articlesResponse.articles)
        this.processNewsList()
        // There are ## News articles that match your search criteria over the selected date range
        const dataMsg
          = `${this.newsArticles.length !== 50 ? `There are ` + this.newsArticles.length : `Top 50`} News articles that match your search criteria ${this.selectedTime === 'all' || this.selectedTime === null ? `over the past 48 hours` : this.selectedTime === 'quarterplus' ? `over the selected date range` : `over the selected date range`}`
        this.noDataMsg = dataMsg
      } else {
        if (!articlesResponse.articles.length) {
          this.newsArticles = []
          this.noDataMsg = `There is no news found of the search criteria.`
        }
      }
      setTimeout(() => {
        this.showLoader = false
      }, 100)
    })
  }

  onSearch() {
    this.showLoader = true
    if (this.searchNews && this.searchNews.length > 0) {
      const sortArray = [{ colId: 'published', order: 'desc' }]
      const articleQueryParams = {
        eventId: this.eventMetaInfoService.getEventId(),
        q: this.searchNews,
        search: true,
        sort: encodeURIComponent(JSON.stringify(sortArray)),
      }

      if (this.filterPayload !== null) { articleQueryParams['published'] = JSON.stringify(this.filterPayload.published) }
      this.associatedNewsService.getNewsArticles(articleQueryParams).subscribe((articlesResponse) => {
        if (articlesResponse && articlesResponse.articles.length > 0) {
          this.newsArticles = Object.assign([], articlesResponse.articles)
          this.noDataMsg = `${this.newsArticles.length} News articles that match your search criteria`
          this.processNewsList()
        } else {
          this.newsArticles = []
          this.noDataMsg = `There is no news found of the search criteria.`
        }
        setTimeout(() => { this.showLoader = false }, 100)
      })
    } else {
      this.getRelevantNews()
    }
  }

  filterNewsArticles(selectedFilter, type) {
    this.selectedTime = selectedFilter.value
    console.log(this.selectedTime)
    if (selectedFilter.value === 'all') {
      delete this.filterPayload[type]
    } else {
      switch (type) {
        case 'published':
          this.filterPayload[type] = this.prepareDataPayloadForFilter(selectedFilter.value).published
          break
        case 'sentiment':
          this.filterPayload[type] = {
            type: 'equals',
            filter: selectedFilter.value,
            filterType: 'text',
          }
          break
        case 'bookmark':
          this.filterPayload[type] = {
            type: 'equals',
            filter: true,
            filterType: 'boolean',
          }
          break
      }
    }
    this.getRelevantNews()
  }

  prepareDataPayloadForFilter(selectedTimeValue) {
    const currentDate = DateUtils.format(new Date(), 'Y-MM-DD')
    let payloadObject: any = {}
    switch (selectedTimeValue) {
      case 'week':
        payloadObject = {
          published: {
            type: 'inRange',
            dateFrom: DateUtils.format(
              new Date().setDate(new Date().getDate() - 7),
              'Y-MM-DD'
            ),
            dateTo: currentDate,
            filterType: 'date',
          },
        }
        break
      case 'month':
        payloadObject = {
          published: {
            type: 'inRange',
            dateFrom: DateUtils.format(
              new Date().setMonth(new Date().getMonth() - 1),
              'Y-MM-DD'
            ),
            dateTo: currentDate,
            filterType: 'date',
          },
        }
        break
      case 'quarter':
        payloadObject = {
          published: {
            type: 'inRange',
            dateFrom: DateUtils.format(
              new Date().setMonth(new Date().getMonth() - 3),
              'Y-MM-DD'
            ),
            dateTo: currentDate,
            filterType: 'date',
          },
        }
        break
      case 'quarterplus':
        payloadObject = {
          published: {
            type: 'lessThan',
            dateFrom: DateUtils.format(
              new Date().setMonth(new Date().getMonth() - 3),
              'Y-MM-DD'
            ),
            filterType: 'date',
          },
        }
        break
    }

    if (Object.keys(payloadObject).length) {
      return payloadObject
    } else {
      return null
    }
  }

  processNewsList() {
    const assArticleIds = this.taggedNews.map(news => news.articleId)
    this.newsArticles.forEach(article => {
      article['associated'] = assArticleIds.indexOf(article.documentId) === -1 ? false : true
      article['associatedCode'] = Number(article['associated'])
    })
    this.newsArticles = orderBy(this.newsArticles, ['associatedCode', 'published'], ['desc', 'desc'])
  }

  toggleLink(article, index) {
    article.associated = !article.associated
    article.associatedCode = Number(article.associated)
    if (article.associated) {
      const newsArticle = {} as NewsArticle
      newsArticle.articleId = article.documentId
      newsArticle.documentId = article.documentId
      newsArticle.resourceId = article.documentId
      newsArticle.articleTitle = article.title
      newsArticle.publishedDate = article.published
      newsArticle.provider = article.provider
      newsArticle.associated = article.associated
      newsArticle.associatedCode = Number(article.associated)
      newsArticle.url = article.newsUrl
      this.taggedNews.push(newsArticle)
    }
    else {
      this.taggedNews = this.taggedNews.filter(news => news.articleId !== article.documentId)
    }
    this.propagateChange(this.taggedNews)
  }

  writeValue(value: any) {
    return value
  }

  registerOnChange(fn) {
    this.propagateChange = fn
  }

  registerOnTouched() { }
}
