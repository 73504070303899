import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiServiceProvider } from './../../../../api-service';

@Injectable()
export class NotificationService extends ApiServiceProvider {
  public getAlertsListData(scope?: string) {
    let Scope = 'COMMODITY';
    if (scope === 'Commodity') {
      Scope = 'COMMODITY';
    } else if (scope === 'Product') {
      Scope = 'PRODUCT';
    }

    const object: any = {
      targetedType: 'AlertsService',
      formParams: {
        alertType: 'RFQ',
        templateType: Scope,
        tenantType: 'CUSTOMER',
        sort: '-DATE'
      },
      method: 'GET',
      servicePath: '/user/alerts'
    };
    return this.post(object);
  }

  public clearNotification(alertId) {
    const object: any = {
      targetedType: 'AlertsService',
      method: 'PUT',
      payload: [alertId],
      servicePath: '/user/alerts/clean'
    };
    return this.post(object);
  }

  public viewNotification(alertId) {
    const object: any = {
      targetedType: 'AlertsService',
      method: 'PUT',
      payload: [alertId],
      servicePath: '/user/alerts/acknowledge'
    };
    return this.post(object);
  }

  public callUpdateAlert(request) {
    const object: any = {
      targetedType: 'RFQ',
      method: 'GET',
      formParams: {
        rfqCustId: request.targetId
      },
      servicePath: 'updateRFxForecastFields'
    };
    return this.post(object);
  }

  public validateServiceData(data) {
    if (data instanceof Object) {
      if (data['responseStatus'] instanceof Object) {
        switch (data['responseStatus']['code']) {
          case 200:
            return data;
          default:
            return 'error';
        }
      }
    }
  }
}
