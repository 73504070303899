import * as _ from 'lodash';
import {
  RowDragEnterEvent,
  RowDragMoveEvent,
  RowDragLeaveEvent,
  GridApi,
} from '@ag-grid-community/core';
import { isSelectionParentOfTarget } from './drag-drop';
import {
  updateUserAction,
  updateFatalErrors,
  deleteFatalError,
} from './tree-track-action';
import { Subject } from 'rxjs';
import { CustomerConfigurationService } from '../../../common/customerConfigurationService';
import { BaseService } from 'app/modules/common/base.service';
import { CUSTOMER_CURRENT_USER } from '../../../shared/services/shared-consts';
const subAssemblyCount = 0;
let cpnCount = 0;
let mpnCount = 0;
let sourceCount = 0;
let keyCount = 0;
let gridApi;
let event;
let params1;
let combination;
export let gridReadyObs = new Subject<any>();

const menuType = {
  MPN: 'MPN',
  CPN: 'CPN',
  SUB_ASSEMBLY: 'SUB_ASSEMBLY',
  PRODUCT: 'PRODUCT',
  PART: 'PART',
  SOURCE_GROUP: 'SOURCE_GROUP',
  SOURCE: 'SOURCE',
};

const rowTypes = {
  MPN: 'New MPN',
  CPN: 'New CPN',
  SUB_ASSEMBLY: 'New Sub-assembly',
};

/**
 * called gridReady(event)
 * @param {event: GridReadyEvent}
 * @returns {void}
 */
export const gridReady = (params) => {
  gridReadyObs.next(params);
  gridApi = params.api;
};

/**
 * called rowDragEnter(event)
 * @param {event: RowDragEnterEvent}
 * @returns {void}
 */
export const rowDragEnter = (event: RowDragEnterEvent) => {
  console.log(event);
};

/**
 * called rowDragMove(event)
 * @param {event: RowDragMoveEvent}
 * @returns {void}
 */
export const rowDragMove = (event: RowDragMoveEvent) => {
  // console.log(event);
};

/**
 * called rowDragLeave(event)
 * @param {event: RowDragLeaveEvent}
 * @returns {void}
 */
export const rowDragLeave = (event: RowDragLeaveEvent) => {
  console.log(event);
};

/**
 * called rowDragEnd(event)
 * @param {event: RowDragEndEvent}
 * @returns {void}
 */
export const rowDragEnd = (event1) => {
  event = event1;
  combination = false;
  const overNode = event.overNode;
  if (!overNode) {
    return;
  }
  const selectedNode = event.node;
  if (!selectedNode) {
    console.warn('No nodes selected!');
    return;
  }
  const targetNode = gridApi.getRowNode(overNode.data.id);

  const invalidMove =
    selectedNode.key === overNode.key ||
    isSelectionParentOfTarget(selectedNode, targetNode);
  if (invalidMove) {
    document.getElementById('tree_message').classList.remove('hide');
    document.getElementById('bom_drag_message').innerHTML = 'Invalid move';
    return;
  }

  if (
    (selectedNode.parent.data.componentType === menuType.SUB_ASSEMBLY &&
      typeof selectedNode.parent.data.enableRfx === 'boolean' &&
      selectedNode.parent.data.enableRfx === false) ||
    (overNode.parent.data.componentType === menuType.SUB_ASSEMBLY &&
      typeof overNode.parent.data.enableRfx === 'boolean' &&
      overNode.parent.data.enableRfx === false) ||
    (overNode.data.componentType === menuType.SUB_ASSEMBLY &&
      typeof overNode.data.enableRfx === 'boolean' &&
      overNode.data.enableRfx === false) ||
    (overNode.data.componentType === 'CPN' &&
      ((!Object.values(overNode.childrenMapped).every(
        (i) => typeof i['data']['enableRfx'] === 'boolean'
      ) &&
        typeof overNode.data.enableRfx === 'boolean' &&
        overNode.data.enableRfx === false) ||
        (!Object.values(overNode.childrenMapped).every(
          (i) => typeof i['data']['enableRfx'] === undefined
        ) &&
          Object.values(overNode.childrenMapped).every(
            (i) => typeof i['data']['enableRfx'] === 'boolean'
          ) &&
          !Object.values(overNode.childrenMapped).every(
            (i) => i['data']['enableRfx']
          ))))
  ) {
    document.getElementById('tree_message').classList.remove('hide');
    document.getElementById('bom_drag_message').innerHTML =
      'Invalid move. Selected node are part of existing RFI.';
    return;
  }
  if (
    overNode.data.componentType === menuType.CPN &&
    (selectedNode.data.componentType === menuType.CPN ||
      selectedNode.data.componentType === menuType.SUB_ASSEMBLY ||
      selectedNode.parent.data.componentType === menuType.SUB_ASSEMBLY)
  ) {
    if (selectedNode.parent.data.componentType === menuType.SUB_ASSEMBLY) {
      if (
        selectedNode.parent.childrenMapped instanceof Object &&
        Object.values(selectedNode.parent.childrenMapped).filter((i) => i)
          .length === 1
      ) {
        combination = true;
        const br = document.createElement('break');
        document.getElementById('CPN_message').innerHTML = `${
          Object.getOwnPropertyNames(selectedNode.parent.childrenMapped)[0]
        }
           is the only child for
          ${selectedNode.parent.data.partNumber}
            Do you wish to retain
          ${selectedNode.parent.data.partNumber}
           as a SubAssembly?`;
        document.getElementById('note_message').innerHTML = `
          Note : This action will convert
          ${overNode.data.partNumber}
           into SubAssembly.`;
        document
          .getElementById('confirmation-msg-cpn-to-CPN')
          .classList.add('show');
        return;
      }
    }
    document.getElementById('Sub_message').innerHTML =
      'This will convert ' +
      overNode.data.partNumber +
      ' into SubAssembly. Do you wish to Continue?';
    document.getElementById('confirmation-msg-cpn-to-SA').classList.add('show');
    // document.getElementById('CPN_message').innerHTML =
    //   Object.getOwnPropertyNames(selectedNode.parent.childrenMapped)[0] +
    //   ' is the only child for ' +
    //   overNode.data.partNumber +
    //   ' . Do you wish to retain <parentname> as a SubAssembly?';
    return;
  }
  if (
    selectedNode.parent.data.componentType === menuType.SUB_ASSEMBLY &&
    selectedNode.parent.childrenMapped instanceof Object &&
    Object.values(selectedNode.parent.childrenMapped).filter((i) => i)
      .length === 1
  ) {
    document.getElementById('CPN_message').innerHTML =
      Object.getOwnPropertyNames(selectedNode.parent.childrenMapped)[0] +
      ' is the only child for ' +
      selectedNode.parent.data.partNumber +
      '  Do you wish to retain ' +
      selectedNode.parent.data.partNumber +
      ' as a SubAssembly?';
    document.getElementById('note_message').innerHTML = '';
    document
      .getElementById('confirmation-msg-cpn-to-CPN')
      .classList.add('show');
    return;
  }

  if (invalidMove || overNode.data.componentType === menuType.MPN) {
    document.getElementById('tree_message').classList.remove('hide');
    document.getElementById('bom_drag_message').innerHTML =
      'MPN can not have childs';

    return;
  }

  if (!selectedNode.data.info.nodePath) {
    selectedNode.data.info.nodePath = {
      oldPath: undefined,
      newPath: undefined,
    };
  }
  if (selectedNode.data.info.nodePath.oldPath === undefined) {
    const myClonedArray = Object.assign([], selectedNode.data.nodePath);
    selectedNode.data.info.nodePath.oldPath = myClonedArray.toString();
  }
  const rowsToUpdate = getRowsToUpdate(selectedNode, overNode.data.nodePath);
  selectedNode.data.info.nodePath.newPath =
    selectedNode.data.nodePath.toString();

  selectedNode.data.parentPath = overNode.data.path;

  updateUserAction({ type: 'update', data: selectedNode.data });
  gridApi.updateRowData({ update: rowsToUpdate });
  gridApi.clearFocusedCell();
};

function addNewLevel(
  target,
  nodePath,
  level,
  uniqueId,
  parentPath,
  parentLevelId
) {
  const rowObject = JSON.parse(JSON.stringify(target.node.data)); // target.data
  const prefix = 'Level ';
  Object.keys(target.node.data).forEach((element) => {
    switch (element.trim()) {
      case 'level':
        rowObject[element] = prefix + level;
        break;
      case 'levelId':
        rowObject[element] = +level;
        break;
      case 'nodePath':
        rowObject[element] = nodePath;
        break;
      case 'parentLevelId':
        rowObject[element] = parentLevelId;
        break;
      case 'path':
        rowObject[element] = parentPath + prefix + level + '-' + uniqueId;
        break;
      case 'tagId':
        rowObject[element] = 0;
        break;
      case 'tagName':
        rowObject[element] = null;
        break;
      case 'levelName':
        rowObject[element] = '';
        break;
      case 'levelOrder':
        rowObject[element] = +level;
        break;
      default:
        rowObject[element] = null;
        break;
    }
  });
  updateUserAction({ type: 'add', data: rowObject });
  setTimeout(() => {
    target.api.updateRowData({ add: [rowObject] });
    gridReadyObs.next({ add: [rowObject] });
    target.api.clearFocusedCell();
  }, 20);
}

function addNewRow(
  path,
  target,
  countNumber,
  componentType,
  partNum,
  headers,
  type?,
  sdbfatalErrorObjects?,
  insertKey?,
  partId?,
  partCheckoutId?,
  partTimeseriesId?,
  gridType?,
  quarterSourceHeaders?
) {
  const rowObject = JSON.parse(JSON.stringify(target.node.data)); // target.data
  if (insertKey) {
    rowObject[insertKey] = null;
  }
  (Object.keys(rowObject) || []).forEach((element) => {
    const colDef = headers.find((i) => i.field === element);
    switch (element.trim()) {
      case 'partId':
        rowObject[element] = partId;
        break;
      case 'partCheckoutId':
        rowObject[element] = partCheckoutId;
        break;
      case 'partTimeseriesDataId':
        rowObject[element] = partTimeseriesId;
        break;
      case 'id':
        rowObject[element] = countNumber;
        break;
      case 'nodePath':
        rowObject[element] = path;
        break;
      case 'info':
        rowObject[element] = {};
        break;
      case 'componentType':
        rowObject[element] = componentType;
        break;
      case 'nodeType':
        rowObject[element] = componentType;
        break;
      case 'partNumber':
        rowObject[element] = partNum;
        break;
      case 'parentPath':
      case 'path':
        if (type === 'sibling') {
          if (rowObject.path) {
            rowObject.parentPath = rowObject.path.substring(
              0,
              rowObject.path.lastIndexOf('.')
            );
            rowObject.parentPath = JSON.parse(
              JSON.stringify(rowObject.parentPath)
            );
          } else {
            rowObject.parentPath = JSON.parse(
              JSON.stringify(rowObject.parentPath)
            );
          }
        } else {
          rowObject.parentPath = JSON.parse(JSON.stringify(rowObject.path));
        }
        break;
      default:
        rowObject[element] = null;
        if (
          colDef &&
          colDef.visibility &&
          colDef.visibility[colDef.type] &&
          colDef.visibility[colDef.type][componentType] === 'EDITABLE'
        ) {
          if (colDef.validations && colDef.validations instanceof Array) {
            const obj = colDef.validations.find(
              (validation) => validation.severity === 'FATAL'
            );
            if (obj) {
              updateFatalErrors(colDef.dbColumn + `**${countNumber}`);
            }
          }
        }
        break;
    }
  });
  if (gridType === 'pwbEditGrid' && componentType === 'SOURCE') {
    const timeSeriesData = {};
    quarterSourceHeaders.forEach((value, key) => {
      timeSeriesData[key] = _.cloneDeep(value);
    });
    rowObject['timeSeriesData'] = _.cloneDeep(timeSeriesData);
  }
  if (sdbfatalErrorObjects && sdbfatalErrorObjects[componentType]) {
    sdbfatalErrorObjects[componentType].forEach((column) => {
      const colDef = headers.find((i) => i.field === column);
      if (
        colDef &&
        colDef.validations &&
        colDef.validations[componentType] &&
        (componentType === 'PART' ||
          componentType === 'SOURCE_GROUP' ||
          componentType === 'SOURCE')
      ) {
        colDef.validations[componentType].filter((fatalObj) => {
          if (
            fatalObj.severity === 'FATAL' ||
            fatalObj.severity === 'WARNING'
          ) {
            fatalObj.errorMessage = fatalObj.errorMessage
              .replace('{min}', fatalObj.info.min)
              .replace('{max}', fatalObj.info.max);
            if (!(rowObject.validations instanceof Object)) {
              rowObject.validations = {};
            }
            if (
              rowObject.validations[colDef.field] instanceof Array &&
              rowObject.validations[colDef.field].length > 0
            ) {
              rowObject.validations[colDef.field].push(fatalObj);
            } else {
              rowObject.validations[colDef.field] = [];
              rowObject.validations[colDef.field].push(fatalObj);
            }
            if (fatalObj.severity === 'FATAL') {
              updateFatalErrors(colDef.dbColumn + `**${countNumber}`);
            }
          }
        });
      }
    });
  }
  if (insertKey === 'partNumber') {
    rowObject['uiPartId'] = countNumber;
  } else if (insertKey === 'cmOdm') {
    rowObject['uiPartId'] = target.node.data['partId']
      ? target.node.data['partId']
      : target.node.data['uiPartId'];
    rowObject['uiGroupId'] = countNumber;
    const header = headers.find((i) => i.field === 'controlledBy');
    if (header) {
      rowObject['controlledBy'] = '';
    }
    const componentControlHeader = headers.find(
      (i) => i.field === 'componentControl'
    );
    if (componentControlHeader) {
      rowObject['componentControl'] = '';
    }
  } else if (insertKey === 'mpn') {
    rowObject[insertKey] = path[path.length - 1];
    rowObject['uiPartId'] = target.node.data['partId']
      ? target.node.data['partId']
      : target.node.data['uiPartId'];
    rowObject['uiGroupId'] = target.node.data['partTimeseriesDataId']
      ? target.node.data['partTimeseriesDataId']
      : target.node.data['uiGroupId'];
  }
  console.log(rowObject);
  rowObject.path = '';
  rowObject.userAdded = true;
  rowObject.lastModified = new Date().getTime();
  rowObject.fieldStatus = ['modified'];
  updateUserAction({ type: 'add', data: rowObject });

  setTimeout(() => {
    target.api.updateRowData({ add: [rowObject] });

    gridReadyObs.next({ add: [rowObject] });
    target.api.clearFocusedCell();
  }, 20);
}

export const addNewSibling = (target, headers) => {
  switch (target.node.data.componentType) {
    case menuType.SUB_ASSEMBLY:
      addSubAssembly(target, headers, 'sibling');
      break;
    case menuType.CPN:
      addCPN(target, headers, 'sibling');
      break;
    case menuType.MPN:
      addMPN(target, headers, 'sibling');
      break;
  }
};
export const retainSubAssembly = () => {
  document
    .getElementById('confirmation-msg-cpn-to-CPN')
    .classList.remove('show');
  if (combination) {
    const overNode = event.overNode;
    if (!overNode) {
      return;
    }
    // if (this.removingLastChild) {
    //   // selectedNode.
    // }
    overNode.data.componentType = 'SUB_ASSEMBLY';
    overNode.data.nodeType = 'SUB_ASSEMBLY';
    if (overNode.data && overNode.data.isSubAssembly) {
      overNode.data.isSubAssembly = 'Y';
    }
    updateUserAction({ type: 'update', data: overNode.data });
    gridApi.redrawRows();
  }
  actionsAfterDrag();
};
export const convertToCPN = () => {
  document
    .getElementById('confirmation-msg-cpn-to-CPN')
    .classList.remove('show');
  event.node.parent.data.componentType = 'CPN';
  if (event.node.parent.data && event.node.parent.data.isSubAssembly) {
    event.node.parent.data.isSubAssembly = 'N';
  }
  if (combination) {
    const overNode = event.overNode;
    if (!overNode) {
      return;
    }
    // if (this.removingLastChild) {
    //   // selectedNode.
    // }
    overNode.data.componentType = 'SUB_ASSEMBLY';
    overNode.data.nodeType = 'SUB_ASSEMBLY';
    if (overNode.data && overNode.data.isSubAssembly) {
      overNode.data.isSubAssembly = 'Y';
    }
    updateUserAction({ type: 'update', data: overNode.data });
  }
  updateUserAction({ type: 'update', data: event.node.parent.data });
  gridApi.redrawRows();
  actionsAfterDrag();
};
export const makeSubAssemblyYes = () => {
  document
    .getElementById('confirmation-msg-cpn-to-SA')
    .classList.remove('show');
  const overNode = event.overNode;
  if (!overNode) {
    return;
  }
  const selectedNode = event.node;
  // if (this.removingLastChild) {
  //   // selectedNode.
  // }
  overNode.data.componentType = 'SUB_ASSEMBLY';
  overNode.data.nodeType = 'SUB_ASSEMBLY';
  if (overNode.data && overNode.data.isSubAssembly) {
    overNode.data.isSubAssembly = 'Y';
  }
  updateUserAction({ type: 'update', data: overNode.data });
  gridApi.redrawRows();
  actionsAfterDrag();
};
export const actionsAfterDrag = () => {
  const overNode = event.overNode;
  if (!overNode) {
    return;
  }
  const selectedNode = event.node;
  if (!selectedNode.data.info.nodePath) {
    selectedNode.data.info.nodePath = {
      oldPath: undefined,
      newPath: undefined,
    };
  }
  if (selectedNode.data.info.nodePath.oldPath === undefined) {
    const myClonedArray = Object.assign([], selectedNode.data.nodePath);
    selectedNode.data.info.nodePath.oldPath = myClonedArray.toString();
  }
  const rowsToUpdate = getRowsToUpdate(selectedNode, overNode.data.nodePath);
  selectedNode.data.info.nodePath.newPath =
    selectedNode.data.nodePath.toString();

  selectedNode.data.parentPath = overNode.data.path;

  updateUserAction({ type: 'update', data: selectedNode.data });
  gridApi.updateRowData({ update: rowsToUpdate });
  gridApi.clearFocusedCell();
};

export const quickFilter = (val) => {
  gridApi.setQuickFilter(val);
  if (gridApi.getDisplayedRowCount() === 0) {
    gridApi.showNoRowsOverlay();
  } else {
    gridApi.hideOverlay();
  }
};

export const addCPN = (target, headers, type?) => {
  const path = JSON.parse(JSON.stringify(target.node.data.nodePath)); // target.data.nodeData.path
  if (type === 'sibling') {
    path.pop();
  }
  cpnCount++;
  const cc = new CustomerConfigurationService();
  const prefix = cc.getBaseCustomerInfo().npipartprefix || 'LEVA-PART';
  const delimiter = cc.getBaseCustomerInfo().npimpnlabeldelimiter || '-';
  path.push(`${prefix} ${cpnCount}`.toString());
  addNewRow(
    path,
    target,
    new Date().getTime(),
    menuType.CPN,
    `${prefix}${delimiter}${new Date().getTime()}`,
    headers,
    type || undefined
  );
};

export const addSubAssembly = (target, headers, type?) => {
  const path = JSON.parse(JSON.stringify(target.node.data.nodePath)); // target.data.nodeData.path
  if (type === 'sibling') {
    path.pop();
  }
  const cc = new CustomerConfigurationService();
  const prefix = cc.getBaseCustomerInfo().npipartprefix || 'LEVA-PART';
  const delimiter = cc.getBaseCustomerInfo().npimpnlabeldelimiter || '-';
  cpnCount++;
  path.push(`${prefix} ${cpnCount}`.toString());
  addNewRow(
    path,
    target,
    new Date().getTime(),
    menuType.SUB_ASSEMBLY,
    `${prefix}${delimiter}${new Date().getTime()}`,
    headers,
    type || undefined
  );
};

export const isComponentEditable = (params) => {
  if (
    (params &&
      params.node.data &&
      params.node.data.componentType !== 'CPN' &&
      ((typeof params.node.data.enableRfx === 'boolean' &&
        params.node.data.enableRfx === false) ||
        (params.node.data.hasOwnProperty('enableRfx') &&
          params.node.data.enableRfx === undefined))) ||
    (params.node.data.componentType === 'CPN' &&
      ((!Object.values(params.node.childrenMapped).every(
        (i) => typeof i['data']['enableRfx'] === 'boolean'
      ) &&
        typeof params.node.data.enableRfx === 'boolean' &&
        params.node.data.enableRfx === false) ||
        (!Object.values(params.node.childrenMapped).every(
          (i) => typeof i['data']['enableRfx'] === undefined
        ) &&
          Object.values(params.node.childrenMapped).every(
            (i) => typeof i['data']['enableRfx'] === 'boolean'
          ) &&
          !Object.values(params.node.childrenMapped).every(
            (i) => i['data']['enableRfx']
          )))) ||
    isParentIncludedInRfi(params)
  ) {
    return true;
  }
  return false;
};

export const isParentIncludedInRfi = (param) => {
  if (
    (param.node &&
      param.node.parent &&
      param.node.parent.data &&
      param.node.parent.data.componentType !== 'CPN' &&
      typeof param.node.parent.data.enableRfx === 'boolean' &&
      !param.node.parent.data.enableRfx) ||
    (param.parent &&
      param.parent.data &&
      typeof param.parent.data.enableRfx === 'boolean' &&
      !param.parent.data.enableRfx)
  ) {
    return true;
  } else if (param.node && param.node.parent && param.node.parent.parent) {
    return isParentIncludedInRfi(param.node.parent);
  } else if (param.parent && param.parent.parent) {
    return isParentIncludedInRfi(param.parent);
  } else {
    return false;
  }
};

export const addMPN = (target, headers, type?) => {
  const cc = new CustomerConfigurationService();
  const prefix = cc.getBaseCustomerInfo().npimpnprefix || 'LEVA-MPN';
  const delimiter = cc.getBaseCustomerInfo().npimpnlabeldelimiter || '-';
  const path = JSON.parse(JSON.stringify(target.node.data.nodePath)); // target.data.nodeData.path
  if (type === 'sibling') {
    path.pop();
  }
  mpnCount++;
  path.push(`${prefix} ${mpnCount}`.toString());
  addNewRow(
    path,
    target,
    new Date().getTime(),
    menuType.MPN,
    null,
    headers,
    type || undefined
  );
  updateSourceOrder(target.node.childrenMapped);
};
export const addPartRow = (target, headers, sdbfatalErrorObjects, type?) => {
  // const cc = new CustomerConfigurationService();
  const prefix = 'LEVA-PART';
  const insertKey = 'partNumber';
  const path = JSON.parse(JSON.stringify(target.node.data.nodePath)); // target.data.nodeData.path
  if (type === 'sibling') {
    path.pop();
  }
  const uniqueId = new Date().getTime();
  keyCount++;
  path.push(`${prefix} ${uniqueId}`.toString());
  addNewRow(
    path,
    target,
    uniqueId,
    menuType.PART,
    null,
    headers,
    type || undefined,
    sdbfatalErrorObjects,
    insertKey
  );
};

export const addLevelRow = (target, type?) => {
  const nodePath = JSON.parse(JSON.stringify(target.node.data.nodePath));
  let level;
  let parentPath = '';
  let parentLevelId;
  const prefix = 'Level ';
  if (type === 'sibling') {
    nodePath.pop();

    if (target.node.data.levelOrder > 0) {
      const deletePath = target.node.data.path.slice(
        target.node.data.path.lastIndexOf('.')
      );
      parentPath = target.node.data.path.replace(deletePath, '') + '.';
    }
    parentLevelId = target.node.data.parentLevelId;
    level = target.node.data['levelOrder'].toString();
  } else if (type === 'child') {
    level = target.node.data['levelOrder'];
    parentLevelId = target.node.data['levelId'];
    level = level + 1;
    level = level.toString();
    parentPath = target.node.data.path + '.';
  }

  const uniqueId = Math.floor(Math.random() * 100000000 + 1);
  nodePath.push(`${prefix}${level}-${uniqueId}`.toString());
  addNewLevel(target, nodePath, level, uniqueId, parentPath, parentLevelId);
};

export const addNewPartRow = (target, headers, sdbfatalErrorObjects, type?) => {
  const prefix = 'LEVA-PART';
  const insertKey = 'partNumber';
  // const path = JSON.parse(JSON.stringify(target.node.data.nodePath));
  const path = [];
  if (type === 'sibling') {
    path.pop();
  }
  const uniqueId = new Date().getTime();
  keyCount++;
  path.push(`${prefix} ${uniqueId}`.toString());
  target['node'] = {};
  target['node']['data'] = {
    levelId: null,
    componentId: null,
    path: null,
    level: null,
    pathSeparator: null,
    nodeType: 'PART',
    partId: null,
    partCheckoutId: null,
    keyHash: null,
    validations: null,
    uiGroupId: null,
    uiPartId: uniqueId,
    action: null,
    nodePath: [],
    maturity: null,
    split: null,
    commodity: null,
    uom: '',
    commodityManager: null,
    subCommodity: null,
    description: null,
    componentPhase: '',
    partNumber: null,
    partType: '',
    commodityManagerEmail: null,
    subCommodityNumber: '',
    componentType: 'PART',
    id: uniqueId,
  };
  addNewRow(
    path,
    target,
    uniqueId,
    menuType.PART,
    null,
    headers,
    type || undefined,
    sdbfatalErrorObjects,
    insertKey
  );
};
export const addKeyRow = (
  target,
  headers,
  sdbfatalErrorObjects,
  type?,
  gridType?
) => {
  // const cc = new CustomerConfigurationService();
  let prefix;
  if (gridType === 'pwbEditGrid') {
    prefix = 'LEVA-SOURCE-GROUP-KEY';
  } else {
    prefix = 'LEVA-SOURCE-KEY';
  }
  const insertKey = 'cmOdm';
  const path = JSON.parse(JSON.stringify(target.node.data.nodePath)); // target.data.nodeData.path
  if (type === 'sibling') {
    path.pop();
  }
  keyCount++;
  const uniqueId = new Date().getTime();
  path.push(`${prefix} ${uniqueId}`.toString());
  addNewRow(
    path,
    target,
    uniqueId,
    menuType.SOURCE_GROUP,
    null,
    headers,
    type || undefined,
    sdbfatalErrorObjects,
    insertKey,
    target.node.data.partId,
    target.node.data.partCheckoutId
  );
};
export const validateMaxSourceRows = (sourceRowsList) => {
  let returnValue = false;
  if (sourceRowsList && Object.keys(sourceRowsList).length >= 5) {
    document
      .getElementById('npi_sdb_checkout_maxsource_limit')
      .classList.add('show');
    returnValue = true;
  }
  return returnValue;
};
export const addSourceRow = (
  target,
  headers,
  sdbfatalErrorObjects,
  type?,
  gridType?,
  quarterSourceHeaders?
) => {
  const sourceRowsList =
    type === 'sibling'
      ? target.node.parent.childrenMapped
      : target.node.childrenMapped;
  if (validateMaxSourceRows(sourceRowsList)) {
    return;
  }
  // const cc = new CustomerConfigurationService();
  const prefix = 'LEVA-SOURCE';
  const insertKey = 'mpn';
  if (!target.node.data.cost) {
    target.node.data.cost = null;
  }
  const path = JSON.parse(JSON.stringify(target.node.data.nodePath)); // target.data.nodeData.path
  if (type === 'sibling') {
    path.pop();
  }
  const uniqueId = new Date().getTime();
  sourceCount++;
  path.push(`${prefix} ${uniqueId}`.toString());
  addNewRow(
    path,
    target,
    uniqueId,
    menuType.SOURCE,
    null,
    headers,
    type || undefined,
    sdbfatalErrorObjects,
    insertKey,
    target.node.data.partId,
    target.node.data.partCheckoutId,
    target.node.data.partTimeseriesDataId,
    gridType,
    quarterSourceHeaders
  );
};
export const updateSourceOrder = (target) => {
  const orderedMpns = Object.getOwnPropertyNames(target)
    .filter((i) => {
      return (
        target[i] && target[i].data && target[i].data.componentType === 'MPN'
      );
    })
    .sort(
      (a, b) =>
        target[b].data.sourceOrder || 0 - target[a].data.sourceOrder || 0
    );
  if (orderedMpns instanceof Array && orderedMpns.length > 0) {
    let initialOrder = target[orderedMpns[0]].data.sourceOrder || null;
    if (initialOrder === null) {
      initialOrder = 0;
    }
    orderedMpns.forEach((mpn) => {
      target[mpn].data.sourceOrder = initialOrder;
      updateUserAction({
        type: 'update',
        data: target[mpn].data,
      });
      initialOrder = initialOrder + 1;
    });
  }
};

export const getRowsToUpdate = (node, parentPath) => {
  let res = [];
  const newPath = parentPath.concat([node.key]);
  if (node.data) {
    node.data.nodePath = newPath;
    node.data.fieldStatus = ['modified'];
  }
  for (let i = 0; i < node.childrenAfterGroup.length; i++) {
    if (node.childrenAfterGroup[i]) {
      const updatedChildRowData = getRowsToUpdate(
        node.childrenAfterGroup[i],
        newPath
      );
      res = res.concat(updatedChildRowData);
    }
  }
  return node.data ? res.concat([node.data]) : res;
};

function getRowsToRemove(node) {
  let res = [];
  for (let i = 0; i < node.childrenAfterGroup.length; i++) {
    res = res.concat(getRowsToRemove(node.childrenAfterGroup[i]));
  }
  return node.data ? res.concat([node.data]) : res;
}

export const deleteRow = (target) => {
  document.getElementById('confirmation-msg-delete').classList.add('show');
  params1 = target;
  /* switch (target.node.data.componentType) {
    case menuType.CPN:
      cpnCount--;
      break;
    case menuType.MPN:
      mpnCount--;
      break;
    case menuType.SUB_ASSEMBLY:
      subAssemblyCount--;
      break;
  } */
};

export const onClickYesDeleteRow = () => {
  const target = params1;
  document.getElementById('confirmation-msg-delete').classList.remove('show');
  target.node.lastModified = new Date().getTime();
  const deleteNode = getRowsToRemove(target.node);
  if (
    target.node.data.userAdded &&
    Object.keys(target.node.childrenMapped).length > 0
  ) {
    deleteNode.forEach((i) => {
      updateUserAction({ type: 'delete', data: i });
    });
  } else {
    updateUserAction({ type: 'delete', data: target.node.data });
  }
  // target['node']['parent']['childrenMapped'].remove();
  updateSourceOrder(target['node']['parent']['childrenMapped']);
  deleteFatalError(deleteNode);
  target.api.updateRowData({ remove: deleteNode });
  gridReadyObs.next({ delete: deleteNode });
  target.api.clearFocusedCell();
};

export const navigateToNextCell = (params) => {
  const previousCell = params.previousCellDef;
  const suggestedNextCell = params.nextCellDef;
  let nextRowIndex;
  switch (params.key) {
    case this.KEY_DOWN:
      nextRowIndex = previousCell.rowIndex - 1;
      if (nextRowIndex < 0) {
        return null;
      } else {
        return {
          rowIndex: nextRowIndex,
          column: previousCell.column,
          floating: previousCell.floating,
        };
      }
    case this.KEY_UP:
      nextRowIndex = previousCell.rowIndex + 1;
      const renderedRowCount = gridApi.getModel().getRowCount();
      if (nextRowIndex >= renderedRowCount) {
        return null;
      } else {
        return {
          rowIndex: nextRowIndex,
          column: previousCell.column,
          floating: previousCell.floating,
        };
      }
    case this.KEY_LEFT:
    case this.KEY_RIGHT:
      return suggestedNextCell;
    default:
      throw new Error(
        'this will never happen, navigation is always one of the 4 keys above'
      );
  }
};

export const getContextMenuItems = (
  val,
  checkedoutDetails,
  sdbfatalErrorObjects,
  quarterSourceHeaders,
  params
) => {
  if (val.bomGridType && val.bomGridType !== 'bomRepo') {
    let result = [];
    if (
      params &&
      params.column &&
      params.column.colDef &&
      params.api.getColumnDef('levelName')
    ) {
      if (params.node.data.default) {
        result = [
          {
            name: 'Add Sibling',
            action() {
              addLevelRow(params, 'sibling');
            },
          },
          {
            name: 'Add Child',
            action() {
              addLevelRow(params, 'child');
            },
          },
        ];
      } else if (params.node.data.levelOrder <= 3) {
        result = [
          {
            name: 'Add Sibling',
            action() {
              addLevelRow(params, 'sibling');
            },
          },
          {
            name: 'Add Child',
            action() {
              addLevelRow(params, 'child');
            },
          },
          {
            name: 'Delete Row',
            action() {
              deleteRow(params);
            },
            cssClasses: ['dismiss'],
          },
        ];
      } else if (params.node.data.levelOrder === 4) {
        result = [
          {
            name: 'Add Sibling',
            action() {
              addLevelRow(params, 'sibling');
            },
          },
          {
            name: 'Delete Row',
            action() {
              deleteRow(params);
            },
            cssClasses: ['dismiss'],
          },
        ];
      }

      return result.concat(['copy']);
    }
    if (
      params &&
      params.column &&
      params.column.colDef &&
      val.npibomManagemetService.bomHeaders
    ) {
      const colDef = val.npibomManagemetService.bomHeaders.find((i) => i.type);
      if (
        !colDef ||
        colDef.type === 'VIEW_BOM' ||
        colDef.type === 'BOM_COMPARE'
      ) {
        return result.concat(['copy']);
      } else {
        switch (params.node.data.componentType) {
          case menuType.PRODUCT:
            result = [
              {
                name: 'Add Sub-assembly',
                disabled: val.bomGridType === 'FLAT_BOM' ? true : false,
                action() {
                  addSubAssembly(params, val.npibomManagemetService.bomHeaders);
                },
              },
              {
                name: 'Add Component',
                disabled: val.bomGridType === 'FLAT_BOM' ? true : false,
                action() {
                  addCPN(params, val.npibomManagemetService.bomHeaders);
                },
              },
              {
                name: 'Add Source',
                disabled: true,
                action() {
                  addMPN(params, val.npibomManagemetService.bomHeaders);
                },
              },
              {
                name: 'Add Sibling',
                disabled: true,
                action() {
                  addNewSibling(params, val.npibomManagemetService.bomHeaders);
                },
              },
              'separator',
              {
                name: 'Delete Row',
                disabled: true,
                action() {
                  deleteRow(params);
                },
                cssClasses: ['dismiss'],
              },
              'copy',
            ];

            break;
          case menuType.SUB_ASSEMBLY:
            result = [
              {
                name: 'Add Sub-assembly',
                disabled: isComponentEditable(params),
                action() {
                  addSubAssembly(params, val.npibomManagemetService.bomHeaders);
                },
              },
              {
                name: 'Add Component',
                disabled: isComponentEditable(params),
                action() {
                  addCPN(params, val.npibomManagemetService.bomHeaders);
                },
              },
              {
                name: 'Add Source',
                disabled: isComponentEditable(params),
                action() {
                  addMPN(params, val.npibomManagemetService.bomHeaders);
                },
              },
              {
                name: 'Add Sibling',
                disabled: isComponentEditable(params),
                action() {
                  addNewSibling(params, val.npibomManagemetService.bomHeaders);
                },
              },
              'separator',
              {
                name: 'Delete Row',
                disabled: isComponentEditable(params),
                action() {
                  deleteRow(params);
                },
                cssClasses: ['dismiss'],
              },
              'copy',
            ];
            break;

          case menuType.CPN:
            result = [
              {
                name: 'Add Sub-assembly',
                disabled: true,
                action() {
                  addSubAssembly(params, val.npibomManagemetService.bomHeaders);
                },
              },
              {
                name: 'Add Component',
                disabled: true,
                action() {
                  addCPN(params, val.npibomManagemetService.bomHeaders);
                },
              },
              {
                name: 'Add Source',
                disabled: isComponentEditable(params),
                action() {
                  addMPN(params, val.npibomManagemetService.bomHeaders);
                },
              },
              {
                name: 'Add Sibling',
                disabled: val.bomGridType === 'FLAT_BOM' ? true : false,
                action() {
                  addNewSibling(params, val.npibomManagemetService.bomHeaders);
                },
              },
              'separator',
              {
                name: 'Delete Row',
                disabled: val.bomGridType === 'FLAT_BOM' ? true : false,
                action() {
                  deleteRow(params);
                },
                cssClasses: ['dismiss'],
              },
              'copy',
            ];
            break;
          case menuType.MPN:
            result = [
              {
                name: 'Add Sub-assembly',
                disabled: true,
                action() {
                  addSubAssembly(params, val.npibomManagemetService.bomHeaders);
                },
              },
              {
                name: 'Add Component',
                disabled: true,
                action() {
                  addCPN(params, val.npibomManagemetService.bomHeaders);
                },
              },
              {
                name: 'Add Source',
                disabled: true,
                action() {
                  addMPN(params, val.npibomManagemetService.bomHeaders);
                },
              },
              {
                name: 'Add Sibling',
                disabled: isComponentEditable(params),
                action() {
                  addNewSibling(params, val.npibomManagemetService.bomHeaders);
                },
              },
              'separator',
              {
                name: 'Delete Row',
                disabled: isComponentEditable(params),
                action() {
                  deleteRow(params);
                },
                cssClasses: ['dismiss'],
              },
              'copy',
            ];
            break;
          case menuType.PART:
            if (val.bomGridType === 'pwbEditGrid') {
              result = [
                {
                  name: 'Add Source Key',
                  action() {
                    addKeyRow(
                      params,
                      val.npibomManagemetService.bomHeaders,
                      sdbfatalErrorObjects,
                      null,
                      val.bomGridType
                    );
                  },
                  disabled: checkAddPermission(
                    menuType.SOURCE_GROUP,
                    params,
                    checkedoutDetails
                  ),
                },
                'separator',
                'copy',
              ];
            } else {
              result = [
                {
                  name: 'Add Part',
                  action() {
                    addPartRow(
                      params,
                      val.npibomManagemetService.bomHeaders,
                      sdbfatalErrorObjects,
                      'sibling'
                    );
                  },
                  disabled: checkAddPermission(
                    menuType.PART,
                    params,
                    checkedoutDetails
                  ),
                },
                {
                  name: 'Add Source Key',
                  action() {
                    addKeyRow(
                      params,
                      val.npibomManagemetService.bomHeaders,
                      sdbfatalErrorObjects,
                      null,
                      val.bomGridType
                    );
                  },
                  disabled: checkAddPermission(
                    menuType.SOURCE_GROUP,
                    params,
                    checkedoutDetails
                  ),
                },
                'separator',
                'copy',
              ];
            }
            break;
          case menuType.SOURCE_GROUP:
            result = [
              {
                name: 'Add Sibling',
                action() {
                  addKeyRow(
                    params,
                    val.npibomManagemetService.bomHeaders,
                    sdbfatalErrorObjects,
                    'sibling',
                    val.bomGridType
                  );
                },
                disabled: checkAddPermission(
                  menuType.SOURCE_GROUP,
                  params,
                  checkedoutDetails
                ),
              },
              {
                name: 'Add Source',
                action() {
                  addSourceRow(
                    params,
                    val.npibomManagemetService.bomHeaders,
                    sdbfatalErrorObjects,
                    null,
                    val.bomGridType,
                    quarterSourceHeaders
                  );
                },
                disabled: checkAddPermission(
                  menuType.SOURCE,
                  params,
                  checkedoutDetails
                ),
              },
              'separator',
              {
                name: 'Delete Row',
                action() {
                  deleteRow(params);
                },
                cssClasses: ['dismiss'],
              },
              'copy',
            ];
            break;
          case menuType.SOURCE:
            result = [
              {
                name: 'Add Sibling',
                action() {
                  addSourceRow(
                    params,
                    val.npibomManagemetService.bomHeaders,
                    sdbfatalErrorObjects,
                    'sibling',
                    val.bomGridType,
                    quarterSourceHeaders
                  );
                },
                disabled: checkAddPermission(
                  menuType.SOURCE,
                  params,
                  checkedoutDetails
                ),
              },
              'separator',
              {
                name: 'Delete Row',
                action() {
                  deleteRow(params);
                },
                cssClasses: ['dismiss'],
              },
              'copy',
            ];
            break;
        }
        return result;
      }
    } else {
      if (
        params.node === null &&
        params.context.componentParent.LOCALCONFIG === 'NPI_CHECKEDOUT_PARTS'
      ) {
        result = [
          {
            name: 'Add Part',
            action() {
              addNewPartRow(
                params,
                val.npibomManagemetService.bomHeaders,
                sdbfatalErrorObjects
              );
            },
          },
        ];
        return result.concat(['copy']);
      }
    }
  } else {
    return ['copy'];
  }
};
export const checkAddPermission = (key, params, checkedoutDetails) => {
  let userGroups;
  let disableButton = true;

  let hasEditPermission = false;
  let hasSdbUserGroup = false;

  const disableAcess = true;
  let gridType;

  if (params && params.context && params.context.componentParent) {
    checkedoutDetails = params.context.componentParent.checkoutDetails;
    gridType = params.context.componentParent.bomGridType;
  }
  const userInfo = JSON.parse(localStorage.getItem(CUSTOMER_CURRENT_USER));
  if (userInfo instanceof Object && userInfo.groupNames) {
    userGroups = userInfo.groupNames;
  }
  if (userInfo.superAdmin || userInfo.tenantAdmin) {
    hasSdbUserGroup = true;
  } else if (key === menuType.SOURCE_GROUP || key === menuType.SOURCE) {
    hasSdbUserGroup = userGroups.includes('NPI Source Edit Access');
  } else if (key === menuType.PART) {
    hasSdbUserGroup = userGroups.includes('NPI Part Edit Access');
  }
  if (hasSdbUserGroup) {
    const keyCheckedout =
      params.node.data.componentType === 'PART' ? 'part' : 'source';
    if (key === menuType.PART) {
      hasEditPermission = true;
    } else if (key === menuType.SOURCE_GROUP || key === menuType.SOURCE) {
      if (gridType === 'pwbEditGrid') {
        if (
          key === menuType.SOURCE_GROUP &&
          ((params.node.data.componentType === 'PART' &&
            params.node.data.globalPriceInd === 'Y') ||
            (params.node.data.componentType === 'SOURCE_GROUP' &&
              params.node.parent.data.globalPriceInd === 'Y'))
        ) {
          hasEditPermission = false;
        } else {
          hasEditPermission = true;
        }
      } else if (
        (params.node.data.partId &&
          checkedoutDetails[params.node.data.partId] &&
          checkedoutDetails[params.node.data.partId]['sourceCheckedOut'] &&
          checkedoutDetails[params.node.data.partId]['sourceCheckedOutBy'] &&
          checkedoutDetails[params.node.data.partId][
            'sourceCheckedOutBy'
          ].toLowerCase() === userInfo.email.toLowerCase()) ||
        params.node.data.userAdded
      ) {
        hasEditPermission = true;
      }
    }
  }
  disableButton = !(hasSdbUserGroup && hasEditPermission);

  return disableButton;
};
