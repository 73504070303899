import { Router } from '@angular/router';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { Component } from '@angular/core';


@Component({
  selector: 'child-cell',
  template: '<span (click)="openMPNDetails(params.data)" data-toggle="modal" class="mpnlink" data-target="#RMSummaryDetailsPopup">this.params.data.cpn</span>'

})
export class CostLookupComponent {
  public params: any;


  constructor(private dataStorageService: DataStorageService, private router: Router
  ) {

  }
  agInit(params: any): void {
    this.params = params;
  }
  openMPNDetails(event) {
    this.dataStorageService.setRMCPNClicked(this.params);
  }



}
