import { Component } from '@angular/core';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rfqconfig',
  templateUrl: './rfq-config.component.html',
  styleUrls: ['./rfq-config.component.sass']
})
export class RfqConfigComponent {
  public bredCrumValue= '';
  configToaster: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-right',
    timeout: 5000,
    limit: 1
  });
  constructor(private router: Router) {
    this.router.events.subscribe((val) => {
      const urlArr = this.router.url.split('/');
      const checkPath = urlArr[urlArr.length - 1];
      switch (checkPath) {
        case('fields'):
          this.bredCrumValue = 'Fields';
          break;
        case('metrics'):
          this.bredCrumValue = 'Metrics'
          break;
        default:
          this.bredCrumValue = ''
          break;
      }
    });
  }

  Navigate() {
    this.router.navigate(['app/templates']);
  }
}
