import { Component, OnInit, Input, OnDestroy } from '@angular/core'
import { EventsMitigationModel } from '../supply-risk-assessment.model'
import { GridOptions } from '@ag-grid-community/all-modules'
import { SraAltSuppliersService } from './sra-alt-suppliers.service'
import { BaseService } from 'app/modules/common/base.service'
import { zip } from 'rxjs'
import { DataStorageService } from 'app/modules/common/data-storage.service'
import { ToasterService, Toast, BodyOutputType } from 'angular2-toaster'
import { ActivatedRoute, Router } from '@angular/router'
import {
  FilterOptions,
  LevaQuickFilter,
  LevaModuleConfig,
} from '../leva-quick-filter/leva-quick-filter.interface'
import { LevaQuickFilterService } from '../leva-quick-filter/leva-quick-filter.service'
import { BreadCrumbService } from '../bread-crumb.service'
import _ from 'lodash'

@Component({
  selector: 'cprt-sra-alt-suppliers',
  templateUrl: './sra-alt-suppliers.component.html',
  styleUrls: ['./sra-alt-suppliers.component.sass'],
  providers: [SraAltSuppliersService, LevaQuickFilterService],
})
export class SraAltSuppliersComponent implements OnInit, OnDestroy {
  @Input() quickFilters = []
  @Input() staticBasic: any = null
  @Input() alternateForMetaInfo: any = {}
  showLoader = true
  baseOptions: GridOptions
  customBaseOptions: GridOptions
  alternateOptions: GridOptions
  customAlternateOptions: GridOptions
  baseGridApi = null
  alternateGridApi = null
  showBaseTable = false
  showAlternateTable = false
  userSessionData = null
  tenantId = null
  ajaxRequests = {}
  selectedRowsInformation = {
    basicSuppliers: [],
    alternateSuppliers: [],
  }
  filterComponent = null
  filterComponent1 = null
  quickScenarioList: LevaQuickFilter = {
    type: 'filter',
    label: 'Scenarios',
    disableEdit: true,
    options: [],
  }
  filterObject = {
    quickFilters: [],
    mapFilterInfo: null,
  }
  quickFilterList: LevaQuickFilter = {
    type: 'filter',
    label: 'Filters',
    options: [],
  }
  selectedScenarios = []
  options: FilterOptions[] = []
  moduleConfig: LevaModuleConfig = {
    id: 'srn-filter',
    moduleName: 'SRN',
    scope: 'COMMODITY',
    targetedType: 'riskManagementService',
    moduleType: 'FILTER',
  }
  additionalGridConfig = null
  selectedFilterMap = null
  moreFilterMap = null
  defaultTemplates: any = {}
  disabledRFITitle = 'RFI templates have not been enabled for your company. If you would like to activate this feature, please contact your customer success representative'
  resetScenarioFilter = this.generateRandomKey()
  scenarioObj = {
    label: 'Scenarios',
    type: 'multiselect',
    val: 'scenarioLabel',
    id: 'scenarioId',
    clearButtonText: 'Delete Scenario',
    hideClearItem: true,
    isSelectAll: true,
    data: [],
    right: '700px',
    arrowClass: 'left'
  }
  riskMainService: any = {}
  selectAll = false
  showAlternateSites = false

  constructor(
    private baseService: BaseService,
    private eventsModel: EventsMitigationModel,
    private allSupplierService: SraAltSuppliersService,
    private dataStorageService: DataStorageService,
    private toasterService: ToasterService,
    public levaQuickFilterService: LevaQuickFilterService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private breadCrumbService: BreadCrumbService
  ) {
    this.selectedFilterMap = new Map()
    this.moreFilterMap = new Map()
    this.userSessionData = this.baseService.getUserInformation()
    if (this.userSessionData) {
      this.tenantId = this.userSessionData.tenantId
    }

    this.selectionBehavior()
  }

  setAdditionalGridConfig() {
    this.additionalGridConfig = {
      enableCustomPaginationPanel: true,
      enableServerSidePagination: false,
      pageConfig: null,
    }
  }

  selectionBehavior() {
    this.ajaxRequests[
      'selectionChange'
    ] = this.dataStorageService.getSelectionChanged().subscribe((data) => {
      if (data && data.type) {
        this.selectedRowsInformation[data.type.trim()] = data.rows
        if (data.type === 'basicSuppliers') {
          if (data.rows.length > 0) {
            const keys = []
            data.rows.forEach((key) => {
              keys.push(key.baseRowKey)
            })
            const modelValue = {
              type: 'set',
              values: keys,
            }
            this.filterComponent.setModel(modelValue)
            this.alternateGridApi.onFilterChanged()

            // this.gridApi.onFilterChanged()
            if (this.alternateGridApi.getDisplayedRowCount()) {
              this.alternateGridApi.hideOverlay()
            } else {
              this.alternateOptions.overlayNoRowsTemplate =
                'No alternate parts are available for searched criteria'
              this.alternateGridApi.showNoRowsOverlay()
            }
          } else {
            this.filterComponent.setModel(null)
            this.alternateGridApi.onFilterChanged()
            this.alternateGridApi.hideOverlay()
          }
        }
      }
    })
  }

  ngOnInit() {
    // console.log(this.eventsModel)
    this.setAdditionalGridConfig()
    this.allSupplierService.getDefaultTemplates().subscribe((data) => {
      this.defaultTemplates = data.result
      if (data.result && data.result.base && data.result.alternate) {
        return
      }
      const toast: Toast = {
        type: 'warning',
        showCloseButton: true,
        body: '<div class="toast-msg warning-msg">' + this.disabledRFITitle + '</div>',
        bodyOutputType: BodyOutputType.TrustedHtml,
      }
      this.toasterService.pop(toast)
    })
    this.initBaseGridOptions()
    if (this.staticBasic) {
      if (this.staticBasic[1].selectAll) {
        this.getRFIBaseScenarioData()
      } else {
        this.setScenarioBlockData(this.staticBasic, this.baseOptions)
        this.getAlternateScenarioData()
        setTimeout(() => {
          this.showBaseTable = true
        }, 100)
      }
    } else {
      this.getBaseScenarioData()
      this.getAlternateScenarioData()
    }
    this.levaQuickFilterService.getMainKeyInfo().subscribe(() => {
      this.allSupplierService
        .getQuickFilterData()
        .subscribe((data) => {
          this.quickFilterList.options = []
          this.options = []
          if (data && data.result) {
            const addedFilter = this.levaQuickFilterService.getNewFilter()
            if (addedFilter !== null) {
              this.selectNewFilter(data.result, addedFilter)
            }
            data.result.forEach((element) => {
              this.options.push({
                label: element.filterName,
                value: element.value,
                selected: this.selectedFilterMap.has(element.filterName)
                  ? true
                  : this.moreFilterMap.has(element.filterName)
                    ? true
                    : false,
                exclude: element.exclude,
                include: element.include,
                filterModuleId: element.filterModuleId,
                filterType: element.filterType,
                tenantFilterId: element.tenantFilterId,
              })
            })
            this.quickFilterList.options = this.options

            this.levaQuickFilterService.setKeyInfo(true)
          }
        })
    })
    this.getScenarios()
  }

  getScenarios() {
    this.ajaxRequests['getScenarios'] = this.allSupplierService
      .getScenarios(this.tenantId)
      .subscribe((val) => {
        if (val) {
          this.scenarioObj.isSelectAll = false
          this.scenarioObj.data =
            val.result instanceof Array
              ? this.convertToLocalMultiSelectFormat(
                val.result,
                this.scenarioObj
              )
              : []
          this.resetScenarioFilter = this.generateRandomKey()
          this.getAlternateScenarioData()
        }
      })
  }


  convertToLocalMultiSelectFormat(data, filter) {
    const localArray = []
    const selectedItems = []
    data.forEach((val) => {
      const item  = {
        val,
        displayName: val[filter.val],
        selected: val.defaultSelection,
        id: val[filter.id],
      }
      localArray.push(item)
      if (val.defaultSelection) {
        selectedItems.push(item)
      }
    })
    this.selectedScenarios = selectedItems
    return localArray
  }

  selectNewFilter(allFilters, addedFilter) {
    const filteredList = allFilters.filter(
      (filter) => filter.filterName === addedFilter
    )
    if (filteredList.length > 0) {
      if (this.selectedFilterMap && !this.selectedFilterMap.has(filteredList[0].filterName)) {
        this.selectedFilterMap.set(filteredList[0].filterName, {
          id: filteredList[0].tenantFilterId,
          selected: true,
        })
      }
      const quickFilters = this.filterObject.quickFilters
      quickFilters.push(filteredList[0])
      this.filterObject = { ...this.filterObject, ...{ quickFilters } }
      this.getAlternateScenarioData()
    }
    this.selectAll = false
    this.onSelectAll()
    this.levaQuickFilterService.setNewFilter(null)
  }

  formatCriteria(criteria) {
    return Object.keys(criteria).map((key) => {
      return {
        headerName: this.levaQuickFilterService.dimensionDisplayNames[key],
        filterValues: criteria[key],
      }
    })
  }

  generateRandomKey() {
    const crypto = window.crypto
    const array = new Uint32Array(1)
    return crypto.getRandomValues(array)[0]
  }

  initBaseGridOptions() {
    this.baseOptions = {
      rowData: [],
      columnDefs: [],
      onGridReady: (params) => {
        this.baseGridApi = params.api
      },
    }
    this.customBaseOptions = {
      pagination: true,
      paginationPageSize: 20,
      domLayout: '',
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
    }
    this.alternateOptions = {
      rowData: [],
      columnDefs: [],
      onGridReady: (params) => {
        this.alternateGridApi = params.api
        this.filterComponent = this.alternateGridApi.getFilterInstance(
          'baseRowKey'
        )
        this.filterComponent1 = this.alternateGridApi.getFilterInstance(
          'scenario'
        )
      },
    }
    this.customAlternateOptions = {
      pagination: true,
      paginationPageSize: 20,
      domLayout: '',
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
    }
  }

  onBaseSelectionChanged(params) { }

  getRFIBaseScenarioData() {
    const baseScenarioPayload = {
      headers: {
        tenantId: this.tenantId,
        view: `Base`,
        widgetTabKey: `SCENARIO_WORKBENCH`,
      },
      data: {
        scenarioId: [],
        scope: this.staticBasic[1].scope,
        unSelectedParts: [],
        selectAll: true
      },
    }
    this.generateScenarioPayload(baseScenarioPayload.data, 'base')
    this.allSupplierService.getRFIBaseData(baseScenarioPayload.data, this.tenantId).subscribe((baseScenarioResponse) => {
      this.staticBasic[1] = baseScenarioResponse
      this.setScenarioBlockData(this.staticBasic, this.baseOptions)
      this.getAlternateScenarioData()
      setTimeout(() => {
        this.showBaseTable = true
      }, 100)
    })
  }

  getBaseScenarioData() {
    const baseScenarioPayload = {
      headers: {
        tenantId: this.tenantId,
        view: `Base`,
        widgetTabKey: `SCENARIO_WORKBENCH`,
      },
      data: {},
      servicePath: this.alternateForMetaInfo.type === `portfolio` ? `portfolio/data/base` : `insight/data/base`
    }
    this.generateScenarioPayload(baseScenarioPayload.data, 'base')
    console.log(baseScenarioPayload.data)
    zip(
      this.allSupplierService.getWidgetHeaders(
        baseScenarioPayload.headers
      ),
      this.allSupplierService.getBaseData(baseScenarioPayload.data, baseScenarioPayload.servicePath)
    ).subscribe((baseScenarioResponse) => {
      this.setScenarioBlockData(baseScenarioResponse, this.baseOptions)
      setTimeout(() => {
        this.showBaseTable = true
      }, 100)
    })
  }
  getAlternateScenarioData() {
    this.showAlternateTable = false
    const altScenarioPayload = {
      headers: {
        tenantId: this.tenantId,
        view: `Alternate`,
        widgetTabKey: `SCENARIO_WORKBENCH`,
      },
      data: {},
      servicePath: this.alternateForMetaInfo.type === `portfolio` ? `portfolio/alternates` : `insight/data`
    }
    this.generateScenarioPayload(altScenarioPayload.data, 'alternate')
    let alternateSubscribe
    if (this.staticBasic) {
      alternateSubscribe = this.allSupplierService.getRFIAlternateData(altScenarioPayload.data)
    } else {
      alternateSubscribe = this.allSupplierService.getAlternateData(altScenarioPayload.data, altScenarioPayload.servicePath)
    }
    zip(
      this.allSupplierService.getWidgetHeaders(
        altScenarioPayload.headers
      ),
      alternateSubscribe
    ).subscribe((alternateScenarioResponse) => {
      this.setScenarioBlockData(
        alternateScenarioResponse,
        this.alternateOptions
      )
        this.showAlternateTable = false
        this.showLoader = false
      setTimeout(() => {
        this.showAlternateTable = true
        setTimeout(() => {
          this.onScenarioSelect({
            actionType:'selection',
            selectedItems: this.selectedScenarios
          })
        }, 100)
      }, 100)
    })
  }

  generateScenarioPayload(payload, type, download?) {
    switch (this.alternateForMetaInfo.type) {
      case 'portfolio':
        for (const key in this.alternateForMetaInfo.payload) {
          if (this.alternateForMetaInfo.payload.hasOwnProperty(key)) {
            payload[key] = this.alternateForMetaInfo.payload[key]
          }
        }
        break
      default:
        if (!this.staticBasic) {
          payload.eventId = this.eventsModel.events.selectedItems[0].eventId
          payload.vendorSiteList = this.eventsModel.vendorSites.selectedItems.map(
            (item) => item.vendorSiteId
          )
          payload.filter = []
          payload.partsAffected = this.eventsModel.partsAffected.selectedItems.map(
            (item) => item.baseRowKey
          )
        }
        if (type !== undefined && type !== 'base') {
          if (this.staticBasic) {
            payload.partsList = !this.staticBasic[1].selectAll ? this.staticBasic[1].result.map(
              (item) => item.baseRowKey
            ) : []
            payload.selectAll = !!this.staticBasic[1].selectAll
            payload.unSelectedParts = this.staticBasic[1].selectAll ? this.staticBasic[1].unselectedBaseRows.map(
              (item) => item.baseRowKey
            ) : []
          }
          payload.filter = [...this.quickFilters, ...this.filterObject.quickFilters]
          if (download) {
            payload.scenarioId = this.selectedScenarios.map(
              (scenario) => scenario.displayName
            )
          }
          if (this.showAlternateSites) {
            payload.includeSites = true
            payload.scenarioId = []
          }
        } else if (this.staticBasic) {
          payload.partsList = !this.staticBasic[1].selectAll ? this.staticBasic[1].result.map(
            (item) => item.baseRowKey
          ) : []
          payload.unSelectedParts = this.staticBasic[1].selectAll ? this.staticBasic[1].unselectedBaseRows.map(
            (item) => item.baseRowKey
          ) : []
          payload.filter = this.staticBasic[1].filter
        }
        break
    }
    // console.log(payload)
  }

  downloadExcel(event, type) {
    event.stopPropagation()
    const requestParams = {
      payload: {},
      servicePath: null
    }
    switch (type) {
      case 'base':
        requestParams.servicePath = this.alternateForMetaInfo.type === `portfolio` ? `portfolio/download/data/base` : `insight/data/base/download`
        break
      case 'alternate':
        requestParams.servicePath = this.alternateForMetaInfo.type === `portfolio` ? `portfolio/download/data/alternate` : `insight/data/download`
        break
    }
    this.generateScenarioPayload(requestParams.payload, type, true)
    this.allSupplierService.download(requestParams)
  }

  setScenarioBlockData(response, currentBlock) {
    if (response instanceof Array) {
      this.setScenarioGridHeaders(response[0], currentBlock)
      this.setScenarioGridData(response[1], currentBlock)
    }
  }

  setScenarioGridHeaders(headersResponse, currentBlock) {
    if (headersResponse && headersResponse.result) {
      currentBlock.columnDefs = this.allSupplierService.parseHeaders(
        headersResponse.result.NO_SECTION.widgetHeaders || []
      )
    } else {
      currentBlock.columnDefs = []
    }
  }

  setScenarioGridData(itemsResponse, currentBlock) {
    if (itemsResponse && itemsResponse.result) {
      currentBlock.rowData = itemsResponse.result
    } else {
      currentBlock.rowData = []
    }
  }

  deselectBasicAll() {
    this.baseGridApi.deselectAll()
  }

  deselectAll() {
    this.selectAll = false
    this.alternateGridApi.deselectAll()
  }

  createRFI(target) {
    const selectedRows = []
    switch (target.trim()) {
      case 'basicSuppliers':
        this.selectedRowsInformation.basicSuppliers.forEach((row, index) => {
          const rowObject = {
            cpn: row.cpn || null,
            mpn: row.referenceMpn || null,
            supplier: row.supplier || null,
            manufacture: row.manufacturer || null,
            cmodm: row.cmOdm || null,
          }
          selectedRows.push(rowObject)
          if (
            this.selectedRowsInformation.basicSuppliers.length - 1 ===
            index
          ) {
            this.dataStorageService.setTemplateId(this.defaultTemplates.base.tmplId)
            this.generateRelations(selectedRows, false)
          }
        })

        break
      case 'alternateSuppliers':
        this.selectedRowsInformation.alternateSuppliers.forEach(
          (row, index) => {
            const rowObject = {
              cpn: row.cpn || null,
              mpn: row.referenceMpn || null,
              supplier: row.ldSupplier || null,
              manufacture: row.ldManufacturer || null,
              cmodm: row.ldCmOdm || null,
            }
            selectedRows.push(rowObject)
            if (
              this.selectedRowsInformation.alternateSuppliers.length - 1 ===
              index
            ) {
              this.dataStorageService.setTemplateId(this.defaultTemplates.alternate.tmplId)
              this.generateRelations(selectedRows, true)
            }
          }
        )

        break
    }
  }

  generateRelations(selectedRows, type) {
    let createRFIObservable
    if (type) {
      let payload = {}
      if (this.selectAll) {
        this.generateScenarioPayload(payload, type)
      } else {
        payload = { cpnGrps: selectedRows }
      }
      createRFIObservable = this.allSupplierService
        .createRFIFromAlternate(payload, this.tenantId, this.selectAll)
    } else {
      createRFIObservable = this.allSupplierService
        .createRFI(selectedRows, this.tenantId, type)
    }
    createRFIObservable
      .subscribe((data) => {
        if (data && data.responseStatus.code === 200 && data.result !== null) {
          this.dataStorageService.setRiskRFICode(data.result)
          const breadCrumbData = this.breadCrumbService.buildBreadCrumb(this.activatedRoute.root)
          this.breadCrumbService.setMainRoute(breadCrumbData)
          this.router.navigate(['app/srfx/information-exchange/riskschedule'])
        } else {
          this.message('warning', data.message)
        }
      })
  }

  message(type: string, message: string) {
    const toast: Toast = {
      type,
      body: '<div vertical-align=middle>' + message + '</div>',
      bodyOutputType: BodyOutputType.TrustedHtml,
    }
    this.toasterService.pop(toast)
  }
  clearAjaxCalls(key?: string) {
    if (typeof key === 'string') {
      if (
        this.ajaxRequests[key] instanceof Object &&
        this.ajaxRequests[key].unsubscribe instanceof Function
      ) {
        this.ajaxRequests[key].unsubscribe()
        delete this.ajaxRequests[key]
      }
      return
    }
    for (const req in this.ajaxRequests) {
      if (this.ajaxRequests[req] instanceof Object) {
        if (this.ajaxRequests[req].unsubscribe instanceof Function) {
          this.ajaxRequests[req].unsubscribe()
        }
        delete this.ajaxRequests[req]
      }
    }
  }

  clearScenario(scenario) {
    this.showLoader = true
    this.ajaxRequests['clearScenario'] = this.allSupplierService
      .removeScenario(scenario.id, this.tenantId)
      .subscribe((val) => {
        const toast: Toast = {
          type: 'success',
          showCloseButton: true,
          body:
            '<div class="toast-msg success-msg">Successfully deleted Scenario.</div>',
          bodyOutputType: BodyOutputType.TrustedHtml,
        }
        this.toasterService.pop(toast)
        this.resetScenarios()
        this.getScenarios()
        this.showLoader = false
      })
  }

  onScenarioSelect(scenarioParams) {
    switch (scenarioParams.actionType) {
      case 'selection':
        this.selectedScenarios = scenarioParams.selectedItems
        const selectedScenarios = this.selectedScenarios.map((scenario) => scenario.displayName)
        if (selectedScenarios.length > 0) {
          const modelValue = {
            type: 'set',
            values: selectedScenarios,
          }
          this.filterComponent1.setModel(modelValue)
          this.alternateGridApi.onFilterChanged()
          if (this.alternateGridApi.getDisplayedRowCount()) {
            this.alternateGridApi.hideOverlay()
          } else {
            this.alternateOptions.overlayNoRowsTemplate =
              'No alternate parts are available for searched criteria'
            this.alternateGridApi.showNoRowsOverlay()
          }
        } else {
          this.filterComponent1.setModel(null)
          this.alternateGridApi.onFilterChanged()
          this.alternateGridApi.hideOverlay()
        }
        break
      case 'remove':
        this.clearScenario(scenarioParams.selectedItem)
        break
    }
    this.selectAll = false
    this.onSelectAll()
  }

  onQuickFilterSelect(filterParams) {
    const quickFilters = this.filterObject.quickFilters
    if (filterParams.selected) {
      quickFilters.push(filterParams)
      if (!this.selectedFilterMap.has(filterParams.label)) {
        this.selectedFilterMap.set(filterParams.label, {
          id: filterParams.tenantFilterId,
          selected: true,
        })
      }
    } else {
      quickFilters.splice(quickFilters.indexOf(filterParams), 1)
      if (this.selectedFilterMap.has(filterParams.label)) {
        this.selectedFilterMap.delete(filterParams.label)
      }
    }

    this.selectAll = false
    this.filterObject = { ...this.filterObject, ...{ quickFilters } }
    this.getAlternateScenarioData()
  }

  onQuickFilterClearSelection(clearFilterParams) {
    const quickFilters = []
    this.filterObject = { ...this.filterObject, ...{ quickFilters } }
    this.selectedFilterMap = new Map()
    this.selectAll = false
    this.getAlternateScenarioData()
  }

  onMoreFilterSelect(moreFilterParams) {
    this.resetTheSelectedFilters()
    const selectedFilterItems = moreFilterParams.selectAll
      ? JSON.parse(JSON.stringify(moreFilterParams.options))
      : JSON.parse(JSON.stringify(moreFilterParams.selectedItems))
    selectedFilterItems.forEach((option) => {
      this.moreFilterMap.set(option.label, {
        id: option.tenantFilterId,
        selected: true,
      })
    })

    const quickFilters = this.filterObject.quickFilters
    this.filterObject = {
      ...this.filterObject,
      ...{ quickFilters: quickFilters.concat(selectedFilterItems) },
    }
    this.getAlternateScenarioData()
  }

  resetTheSelectedFilters() {
    this.moreFilterMap.forEach((key, filterLabel) => {
      this.filterObject.quickFilters = this.filterObject.quickFilters.filter(
        (quickFilter) => quickFilter.label !== filterLabel
      )
    })
    this.selectAll = false
    this.onSelectAll()
    this.moreFilterMap = new Map()
  }

  resetScenarios() {
    this.scenarioObj.isSelectAll = true
    this.scenarioObj.data = []
    this.selectedScenarios = []
    this.selectAll = false
  }


  onSelectAll() {
    if (this.selectAll) {
      this.alternateOptions.api.selectAll()
    } else {
      this.alternateOptions.api.deselectAll()
    }
  }

  ngOnDestroy() {
    this.clearAjaxCalls()
  }
}
