import { Component } from '@angular/core'
import { SummaryExpandViewService } from './summary-expand-view.service'
import * as _ from 'lodash'

@Component({
  selector: 'app-summary-expand-view',
  templateUrl: './summary-expand-view.component.html',
  styleUrls: ['./summary-expand-view.component.sass'],
})
export class SummaryExpandViewComponent {
  slideConfig = { slidesToShow: 3, slidesToScroll: 3, infinite: false }
  showData = 'loading'
  graphList = []
  lastItem = 0

  constructor(public summaryExpandViewService: SummaryExpandViewService) { }
}
