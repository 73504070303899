import { environment } from './../environments/environment';
import { Injectable } from '@angular/core';
import { BaseService } from 'app/modules/common/base.service';
import { ApiCommonService } from 'app/api-common-service';
import { CustomerConfigurationService } from 'app/modules/common/customerConfigurationService';
import { DownloadExcelService } from 'app/modules/common/utills/download-excel.service';
import { CONFIG_TEMPLATE_IMPORT } from 'app/modules/rfq-configurations/shared/constants';
import { ADHOC_RFX } from 'app/modules/srfq/create/adhoc-rfx/shared/adhoc-rfx.constants';
import {
  HttpHeaders,
  HttpParams,
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { typeProduct } from 'app/modules/srfq/common/constants';
import { map, catchError } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { UtilService } from './modules/common/utills/util.service';
import { throwError } from 'rxjs';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { SharedService } from './modules/shared/services/shared.service';
import { CUSTOMER_CURRENT_USER, CUSTOMER_SESSION_EXPIRED, CUSTOMER_TOKEN_INFO } from './modules/shared/services/shared-consts';


@Injectable()
export class ApiServiceProvider {
  public fromTaskManagement = false;
  public userService: string;
  public userServiceApiPath: string;
  public commonServiceApipath: string;
  public commonService: string;
  public customerConfigServiceApiPath: string;
  public customerConfigService: string;
  public analyticsServiceApiPath: string;
  public analyticsService: string;
  public miServiceApiPath: string;
  public miService: string;
  public negotiationService: any;
  public negotiationServiceApiPath: any;
  public messages: string;
  public envDetails: any;
  public cms: any;
  public rfq: any;
  public tenantId: any;
  public ieTenantId: any;
  public cmsapigateway: any;
  public tsEndpointPath: any;
  public newsApiPath: any;
  public newsApiEndpointPath: any;
  public botApiEndpointPath: any;
  public elsApiEndpointPath: any;
  public levaAlertsServices: any;
  public levaAlertsServicesApiEndpointPath: any;
  public levaAlertsServiceApiPath: any;
  public alertsServicePath: any;
  public alertsServicesApiEndpointPath: any;
  public alertsServiceApiPath: any;
  public alertsServices: any;
  public coreServicesApiEndpointPath: any;
  public coreServicesApiPath: any;
  public coreServices: any;
  public chatbotServices: any;
  public chatbotServicesApiEndpointPath: any;
  public chatbotServicesApiPath: any;
  public customReportsApiEndpointPath: any;
  public customReportsApiPath: any;
  public customReports: any;
  public elsApiPath: any;
  public cmsApiPath: any;
  public apiPath: any;
  public scheduler: string;
  public elasticSearch: any;
  sharedSecretKey: any;
  // IE
  public ieApiEndpointPath: any;
  public ieApiPath: any;
  public ieService: any;
  public productType = typeProduct;
  public API_ENDPOINT;
  public cmsApiEndpointPath: any;
  public apiEndPointPath: any;
  public loginapigateway: any;
  public serviceObject = new DownloadExcelService();
  public userInf: any;
  public serviceObjectReturned: any;
  public cmsApiPathFileDownload: any;
  public rfqApiPathFileDownload: any;
  public exportExcelResult: any;

  public collabApiEndpointPath: any;
  public collabService: any;
  public riskManagementService: any;
  public eBenchmarkService: any;
  /*------ For RFQ Template Configuration Local Variables---------*/

  public templateConfigApiEndPoint;
  public templateConfigApiPath;
  public templateServicePath;

  /* ---------------- For Product RFQ Configuration variable ----------------- */

  public productRFQServicePath;

  // public API_ENDPOINT_PATH = this.cmsApiEndpointPath;
  public API_ENDPOINT_PATH;
  public API_ENDPOINT_LOCAL_PATH = environment.API_ENDPOINT_LOCAL_PATH;
  public API_ENDPOINT_CONFIG_PATH = environment.API_ENDPOINT_CONFIG_PATH;
  // public API_PATH = this.apiPath;
  public API_PATH;
  public authenticationServicePath = environment.authenticationService;

  private apiCommonService: ApiCommonService;
  private headers: HttpHeaders;
  private programManagementService: string;
  private bomManagementService: string;
  private bomManagementFlattenedService: string;
  private stageGateManagementService: string;
  private templateManagementService: string;
  private servicePathsConstants: object = {};
  private tokenServiceApiPath: string;

  constructor(
    private http: HttpClient,
    private baseService: BaseService,
    private customerConfigurationService: CustomerConfigurationService,
    protected utilService: UtilService,
    private loadingBarService: LoadingBarService,
    public sharedService: SharedService
  ) {
    this.headers = new HttpHeaders();
    this.baseService = baseService;
    this.headers.set('Content-Type', 'application/json; charset=utf-8');
    this.headers.set('Accept', 'application/json');
  }

  public getEnvironmentDetails() {
    this.envDetails = this.customerConfigurationService.getEnvironmentDetails();
    if (this.envDetails !== undefined) {
      this.cmsApiEndpointPath = this.envDetails.cmsApiEndpointPath;
      this.API_ENDPOINT_PATH = this.cmsApiEndpointPath;
      this.cms = this.envDetails.CMS;
      this.rfq = this.envDetails.RFQ;
      this.servicePathsConstants['SchedulerService'] = this.scheduler =
        this.envDetails.SchedulerService;
      this.messages = this.envDetails.Messages;
      this.cmsapigateway = this.envDetails.cmsapigateway;
      this.tenantId = this.envDetails.tenantId;
      this.ieTenantId = this.envDetails.ieTenantId;
      this.tsEndpointPath = this.envDetails.tsEndpointPath;
      this.newsApiEndpointPath = this.envDetails.newsApiEndpointPath;
      this.newsApiPath = this.envDetails.newsApiPath;
      this.botApiEndpointPath = this.envDetails.botApiEndpointPath;
      this.cmsApiPath = this.envDetails.cmsApiPath;
      this.elsApiEndpointPath = this.envDetails.elsApiEndpointPath;
      this.elsApiPath = this.envDetails.elsApiPath;
      this.elasticSearch = this.envDetails.elasticSearch;

      // IE
      this.ieApiEndpointPath = this.envDetails.ieApiEndpointPath;
      this.ieApiPath = this.envDetails.ieApiPath;
      this.ieService = this.envDetails.ieService;

      this.apiPath = this.envDetails.apiPath;
      this.API_PATH = this.apiPath;
      this.apiEndPointPath = this.envDetails.apiEndpointPath;
      this.loginapigateway = this.envDetails.loginapigateway;
      this.coreServicesApiEndpointPath =
        this.envDetails.coreServicesApiEndpointPath;
      this.coreServicesApiPath = this.envDetails.coreServicesApiPath;
      this.coreServices = this.envDetails.coreServices;
      this.levaAlertsServices = this.envDetails.levaAlertsServices;
      this.levaAlertsServiceApiPath = this.envDetails.levaAlertsServicesApiPath;
      this.templateConfigApiEndPoint = this.envDetails.templateConfig.endPoint;
      this.templateConfigApiPath = this.envDetails.templateConfig.endPath;
      this.templateServicePath = this.envDetails.templateConfig.service;
      this.levaAlertsServicesApiEndpointPath =
        this.envDetails.levaAlertsServicesApiEndpointPath;
      this.alertsServicePath = this.envDetails.alertsConfig.service;
      this.alertsServiceApiPath = this.envDetails.alertsConfig.endPath;
      this.alertsServicesApiEndpointPath =
        this.envDetails.alertsConfig.endPoint;
      this.customReports = this.envDetails.customReports;
      this.customReportsApiEndpointPath =
        this.envDetails.customReportsApiEndpointPath;
      this.customReportsApiPath = this.envDetails.customReportsApiPath;
      this.chatbotServices = this.envDetails.chatbotService;
      this.chatbotServicesApiEndpointPath =
        this.envDetails.chatbotServicesApiEndpointPath;
      this.chatbotServicesApiPath = this.envDetails.chatbotServicesApiPath;
      this.negotiationService = this.envDetails.negotiationService;
      this.negotiationServiceApiPath =
        this.envDetails.negotiationServiceApiPath;
      this.analyticsServiceApiPath = this.envDetails.analyticsServiceApiPath;
      this.analyticsService = this.envDetails.analyticsService;
      this.miServiceApiPath = this.envDetails.miServiceApipath;
      this.miService = this.envDetails.miService;
      this.commonService = this.envDetails.commonService;
      this.commonServiceApipath = this.envDetails.commonServiceApipath;
      this.customerConfigServiceApiPath =
        environment.customerConfigurationServiceApiPath;
      this.customerConfigService = environment.customerConfigurationService;
      this.userServiceApiPath = environment.userServiceApiPath;
      this.userService = environment.userService;
      this.tokenServiceApiPath = environment.authenticationServiceApiPath;
      if (environment['environmentDetails'].hasOwnProperty('sharedSecretKey')) {
        this.sharedSecretKey = CryptoJS.enc.Base64.parse(
          environment.environmentDetails.sharedSecretKey
        );
      }
      // Product RFQ
      this.productRFQServicePath = this.envDetails.productRFQConfig.service;
      this.servicePathsConstants['ProgramManagementService'] =
        this.programManagementService =
        this.envDetails.programManagementServicePath;
      this.servicePathsConstants['BomManagementService'] =
        this.bomManagementService = this.envDetails.bomManagementServicePath;
      this.servicePathsConstants['BomManagementFlattenedService'] =
        this.bomManagementFlattenedService =
        this.envDetails.bomManagementFlattenedServicePath;
      this.servicePathsConstants['StageGateManagementService'] =
        this.stageGateManagementService =
        this.envDetails.stageGateManagementServicePath;
      this.servicePathsConstants['TemplateManagementService'] =
        this.templateManagementService =
        this.envDetails.templateManagementServicePath;
      this.servicePathsConstants['sisenseService'] =
        this.envDetails.sisenseSevicePath;
      this.servicePathsConstants['postDataRefreshService'] =
        this.envDetails.postDataRefreshService;

      this.servicePathsConstants['partsMasterService'] =
        this.envDetails.partsMasterService;
      // collaboration
      // this.collabApiEndpointPath = this.envDetails.collabApiEndpointPath;
      this.collabService = this.envDetails.collabService;
      this.riskManagementService = this.envDetails.riskManagementService;
      this.eBenchmarkService = this.envDetails.eBenchmarkService;
    }
  }

  public get(apiPath) {
    this.getEnvironmentDetails();
    if (apiPath.source === 'local') {
      this.API_ENDPOINT = this.API_ENDPOINT_LOCAL_PATH;
    } else if (apiPath.source === 'config') {
      this.API_ENDPOINT = this.API_ENDPOINT_CONFIG_PATH;
    } else {
      this.API_ENDPOINT = this.apiEndPointPath;
    }
    return this.http
      .get(this.API_ENDPOINT + apiPath.url, { headers: this.headers })
      .pipe(
        map(this.extractData),
        catchError((error) => {
          return this.handleError(
            error || 'Error while executing POST for route ' + this.API_PATH
          );
        })
      );
  }

  public fileUpload(
    formData,
    servicePath,
    quarter,
    title,
    input?: any,
    custrfqId?,
    tempId?,
    supId?
  ) {
    this.getEnvironmentDetails();
    let payLoad: any;
    // const myheaders = new HttpHeaders();
    // myheaders.set('Accept', '*/*');
    const userSessionData = this.baseService.getUserInformation();
    if (title === 'SIMULATION') {
      payLoad = {
        clientName: this.rfq, // 'Levadata26', // Levadata 26
        formParams: input,
        method: 'POST',
        headerParams: {
          userEmail: userSessionData.email,
        },
        pathParams: {},
        servicePath: this.envDetails.crmSrvcPath + servicePath,
      };
    } else if (title === 'PREVIEW') {
      payLoad = {
        clientName: this.rfq,
        formParams: input,
        method: 'POST',
        headerParams: {
          userEmail: userSessionData.email,
        },
        pathParams: {},
        servicePath: this.envDetails.crmSrvcPath + servicePath,
      };
    } else if (title === 'RFQ') {
      payLoad = {
        clientName: this.rfq, // 'Levadata26', // Levadata 26
        formParams: input,
        method: 'POST',
        headerParams: {
          userEmail: userSessionData.email,
        },
        pathParams: {},
        servicePath: this.envDetails.crmSrvcPath + servicePath,
      };
    } else if (title === 'PRODUCT_RFQ') {
      payLoad = {
        clientName: 'productRFQ', // 'Levadata26', // Levadata 26
        formParams: input,
        method: 'POST',
        headerParams: {
          userEmail: userSessionData.email,
        },
        pathParams: {},
        servicePath: this.productRFQServicePath + servicePath,
      };
    } else if (title === 'DETAILS') {
      payLoad = {
        clientName: this.rfq, // 'Levadata26', // Levadata 26
        formParams: input,
        method: 'POST',
        headerParams: {
          userEmail: userSessionData.email,
        },
        pathParams: {},
        servicePath: this.envDetails.crmSrvcPath + servicePath,
      };
    } else if (title === ADHOC_RFX) {
      payLoad = {
        clientName: this.rfq, // 'Levadata26', // Levadata 26
        formParams: input,
        method: 'POST',
        headerParams: {
          userEmail: userSessionData.email,
        },
        pathParams: {},
        servicePath: this.envDetails.crmSrvcPath + servicePath,
      };
    } else if (title === 'Messages') {
      payLoad = {
        clientName: this.rfq, // 'Levadata26', // Levadata 26
        formParams: {
          // 'userEmail': userSessionData.email,
          moduleName: 'CRM',
        },
        method: 'POST',
        headerParams: {
          userEmail: userSessionData.email,
        },
        pathParams: {},
        servicePath: '/' + this.rfq + servicePath,
      };
    } else if (title === 'curator' || title === 'thresholdUpload') {
      payLoad = {
        clientName: this.cms, // 'Levadata26', // Levadata 26
        formParams: {
          userEmail: userSessionData.email,
        },
        headerParams: {
          userEmail: userSessionData.email,
        },
        method: 'POST',
        servicePath,
      };
    } else if (title === CONFIG_TEMPLATE_IMPORT) {
      payLoad = {
        clientName: '',
        formParams: input,
        headerParams: {
          userEmail: userSessionData.email,
        },
        method: 'POST',
        servicePath: this.templateServicePath + servicePath,
      };
    } else if (title === 'costlookup') {
      payLoad = {
        clientName: this.cms,
        headerParams: {
          userEmail: userSessionData.email,
          timePeriod: quarter,
        },
        method: 'POST',
        servicePath,
      };
    } else if (title === 'customerConfiguration') {
      payLoad = {
        clientName: this.cms,
        formParams: input,
        headerParams: {
          userEmail: userSessionData.email,
        },
        method: 'POST',
        servicePath,
      };
    } else if (title === 'PRODUCT_PREVIEW') {
      payLoad = {
        clientName: 'CustomerRfxService',
        formParams: input,
        headerParams: {
          userEmail: userSessionData.email,
        },
        method: 'POST',
        servicePath: '/CustomerRfxService' + servicePath,
      };
    } else if (title === 'PRODUCT_DETAILS') {
      payLoad = {
        formParams: input,
        headerParams: {
          userEmail: userSessionData.email,
        },
        method: 'POST',
        servicePath: `${this.productRFQServicePath}${servicePath}`,
      };
    } else if (title === 'NPI') {
      payLoad = {
        formParams: input === undefined ? input : input.formParams,
        headerParams: {
          userEmail: userSessionData.email,
        },
        method: 'POST',
        servicePath: `${servicePath}`,
      };
    } else if (title === 'Collaboration') {
      payLoad = {
        clientName: 'Collaboration',
        headerParams: {
          userEmail: userSessionData.email,
        },
        formParams: input || {},
        method: 'POST',
        servicePath: `zuul/${this.collabService}/${servicePath}`,
      };
    } else if (title === 'bm-percentile') {
      payLoad = {
        clientName: this.cms, // 'Levadata26', // Levadata 26
        formParams: input || {},
        headerParams: {
          tenantId: userSessionData.tenantId,
          userName: userSessionData.email, // devtest //'eswar@levadata.com'
          roleId: userSessionData.roleId,
          userId: userSessionData.userId,
          userEmail: userSessionData.email,
          quarter: 'Current Quarter',
        },
        method: 'POST',
        servicePath,
      };
    } else if (title === 'SUPPLIER_RFX_DETAILS') {
      payLoad = {
        clientName: 'CRMService', // 'Levadata26', // Levadata 26

        formParams: {
          templateId: tempId,
          rfqCustId: custrfqId,
          supplierId: supId,
        },
        headerParams: {
          // UUID: this.userInfo['auth_token'],
          userName: userSessionData.email, // devtest //'eswar@levadata.com'
          roleId: 1,
          userId: userSessionData.userId,
          userEmail: userSessionData.email,
          quarter: 'Current Quarter',
          Accept: '*/*',
        },
        method: 'POST',
        servicePath,
      };
    } else if (title === 'VENDOR_RFX_DETAILS') {
      payLoad = {
        clientName: 'CRMService', // 'Levadata26', // Levadata 26
        headerParams: {
          // UUID: this.userInfo['auth_token'],
          userEmail: userSessionData.email,
          Accept: '*/*',
        },
        method: 'POST',
        servicePath,
      };
    } else {
      payLoad = {
        clientName: this.cms, // 'Levadata26', // Levadata 26
        formParams: input || {},
        headerParams: {
          userName: userSessionData.email, // devtest //'eswar@levadata.com'
          roleId: userSessionData.roleId,
          userId: userSessionData.userId,
          userEmail: userSessionData.email,
          quarter: 'Current Quarter',
        },
        method: 'POST',
        servicePath,
      };
    }

    // const options = new RequestOptions({ headers: myheaders });
    const cmsApiEndpointPath = this.cmsApiEndpointPath;
    if (cmsApiEndpointPath !== '' && cmsApiEndpointPath !== undefined) {
      this.API_ENDPOINT = cmsApiEndpointPath;
    } else {
      this.API_ENDPOINT = '';
    }

    const cmsapigateway = this.cmsapigateway;
    if (cmsapigateway !== '' && cmsapigateway !== undefined) {
      this.API_PATH = cmsapigateway;
    } else {
      this.API_PATH = '';
    }
    const queryParameters = '';
    if (payLoad.method.toString().toLowerCase() === 'get') {
      return this.getCallThroughZuul(payLoad);
    } else {
      return this.directPostforFileUpload(payLoad, formData);
    }
  }

  public multipleFileUploadWithProgress(payload, formData) {
    this.getEnvironmentDetails();
    const userSessionData = this.baseService.getUserInformation();
    let requestPayLoad = null;
    switch (payload.targetedType) {
      case 'Collaboration':
        requestPayLoad = {
          clientName: 'Collaboration',
          headerParams: {
            userEmail: userSessionData.email,
          },
          formParams: {},
          method: payload.method,
          servicePath: `zuul/${this.collabService}/${payload.servicePath}`,
        };
        break;
    }

    const cmsApiEndpointPath = this.cmsApiEndpointPath;
    if (cmsApiEndpointPath !== '' && cmsApiEndpointPath !== undefined) {
      this.API_ENDPOINT = cmsApiEndpointPath;
    } else {
      this.API_ENDPOINT = '';
    }

    const cmsapigateway = this.cmsapigateway;
    if (cmsapigateway !== '' && cmsapigateway !== undefined) {
      this.API_PATH = cmsapigateway;
    } else {
      this.API_PATH = '';
    }
    const queryParameters = '';
    if (requestPayLoad.method.toString().toLowerCase() === 'post') {
      return this.directPostforFileUploadWithProgress(requestPayLoad, formData);
    }
  }

  public timePeriod(data) {
    this.getEnvironmentDetails();
    const userSessionData = this.baseService.getUserInformation();
    const payLoadJSON = {
      sessionId: '1',
      roleId: userSessionData.roleId,
      userId: userSessionData.userId,
      userEmail: userSessionData.email,
      tenantId: this.tenantId,
    };
    // payLoadJSON.userEmail
    const coreBasePath =
      'CoreServices/' +
      data.servicePath +
      '?userEmail=' +
      payLoadJSON.userEmail;
    //  var payLoadJSON = {'lastChatId':'2806' ,'chatsLimit':'20', 'userId':'1', 'tenantId':'299'}
    Object.assign(payLoadJSON, data);
    delete payLoadJSON['url'];
    const tsApiEndpointPath = this.tsEndpointPath;
    if (tsApiEndpointPath !== '' && tsApiEndpointPath !== undefined) {
      this.API_ENDPOINT = tsApiEndpointPath;
    }

    // let tsApiPath = environment['tsApiPath'];
    // console.log(tsApiPath);
    // if (tsApiPath !== '' && tsApiPath !== undefined)
    //     this.API_PATH = tsApiPath;
    // else
    //     this.API_PATH = basePath;

    return this.http
      .get(this.API_ENDPOINT + coreBasePath, { headers: this.headers })
      .pipe(
        map(this.extractData),
        catchError((error) => {
          return this.handleError(
            error || 'Error while executing POST for route ' + this.API_PATH
          );
        })
      );
  }

  public npPost(data?: any) {
    this.getEnvironmentDetails();
    const tsApiEndpointPath = this.tsEndpointPath;
    if (tsApiEndpointPath !== '' && tsApiEndpointPath !== undefined) {
      this.API_ENDPOINT = tsApiEndpointPath;
    }

    // this.API_ENDPOINT='http://localhost:2222/';

    return this.http
      .get(this.API_ENDPOINT + data.url, { headers: this.headers })
      .pipe(
        map(this.extractData),
        catchError((error) => {
          return this.handleError(
            error || 'Error while executing POST for route ' + this.API_PATH
          );
        })
      );
  }

  public downloadPreviewExcel(data?: any) {
    this.userInf = this.baseService.getUserInformation();
    const form = document.createElement('form');
    const formParams = {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem(CUSTOMER_TOKEN_INFO)).access_token
        }`,
      result: this.exportExcelResult,
      userEmail: this.userInf['email'],
      Accept: '*/*',
      payload: JSON.stringify(data.input),
    };

    if (data.formParams instanceof Object) {
      // tslint:disable-next-line: forin
      for (const key in data.formParams) {
        formParams[key] = data.formParams[key];
      }
    }
    form.setAttribute('method', 'post');
    form.setAttribute(
      'action',
      this.envDetails.zuulApiEndPointPath +
      '/CustomerRfxService' +
      `${data.servicePath}`
    );

    // tslint:disable-next-line: forin
    for (const key in formParams) {
      const keyData = document.createElement('input');
      keyData.setAttribute('type', 'hidden');
      keyData.setAttribute('name', key);
      keyData.value = formParams[key];
      form.appendChild(keyData);
    }
    document.body.appendChild(form);
    form.submit();
  }

  public downloadExcel(data?: any) {
    this.userInf = this.baseService.getUserInformation();
    const form = document.createElement('form');
    const formParams = {
      result: this.exportExcelResult,
      Authorization: `Bearer ${JSON.parse(localStorage.getItem(CUSTOMER_TOKEN_INFO)).access_token
        }`,
      userEmail: this.userInf['email'],
      Accept: '*/*',
    };
    if (data.formParams instanceof Object) {
      for (const key in data.formParams) {
        if (data.formParams.hasOwnProperty(key)) {
          formParams[key] = data.formParams[key];
        }
      }
    }
    form.setAttribute('method', 'post');
    form.setAttribute('action', this.envDetails.zuulApiEndPointPath + data.url);
    for (const key in formParams) {
      if (formParams.hasOwnProperty(key)) {
        const keyData = document.createElement('input');
        keyData.setAttribute('type', 'hidden');
        keyData.setAttribute('name', key);
        keyData.value = formParams[key];
        form.appendChild(keyData);
      }
    }
    document.body.appendChild(form);
    form.submit();

    return 'SUCCESS';
  }

  public downloadExcelUsingPost(data?: any, type?: string) {
    this.loadingBarService.start();
    this.getEnvironmentDetails();
    this.userInf = this.baseService.getUserInformation();
    const form = document.createElement('form');
    const formParams = {
      result: this.exportExcelResult,
      Authorization: `Bearer ${JSON.parse(localStorage.getItem(CUSTOMER_TOKEN_INFO)).access_token
        }`,
      userEmail: this.userInf['email'],
      Accept: '*/*',
      payload: JSON.stringify(data.payload),
    };
    let servicePath = '';
    if (data.formParams instanceof Object) {
      for (const key in data.formParams) {
        if (data.formParams.hasOwnProperty(key)) {
          formParams[key] = data.formParams[key];
        }
      }
    }

    if (!!data.targetedType && data.targetedType === 'productRFQ') {
      servicePath = `${this.envDetails.zuulApiEndPointPath}${this.productRFQServicePath}/${data.servicePath}`;
    } else {
      if (data.targetedType === 'platform') {
        servicePath = `${this.envDetails.zuulApiEndPointPath}/PlatformServices/${data.servicePath}`;
      } else if (data.targetedType === 'riskManagementService') {
        servicePath = `${this.envDetails.zuulApiEndPointPath}${this.riskManagementService}/${data.servicePath}`;
      } else if (data.targetedType === 'eBenchmark') {
        servicePath = `${this.envDetails.zuulApiEndPointPath}${this.eBenchmarkService}/${data.servicePath}`;
      } else if (
        data.targetedType === 'VendorRfxService' ||
        data.targetedType === 'SRMService'
      ) {
        servicePath = `${this.envDetails.zuulApiEndPointPath}/${data.servicePath}`;
      } else if (data.targetedType === 'MIService') {
        servicePath = `${this.envDetails.zuulApiEndPointPath}${this.miServiceApiPath}/${data.servicePath}`;
      } else {
        servicePath = `${this.envDetails.zuulApiEndPointPath}/${this.rfq}/${data.servicePath}`;
      }

      form.setAttribute('method', 'post');
      form.setAttribute('action', servicePath);
      for (const key in formParams) {
        if (formParams.hasOwnProperty(key)) {
          const keyData = document.createElement('input');
          keyData.setAttribute('type', 'hidden');
          keyData.setAttribute('name', key);
          keyData.value = formParams[key];
          form.appendChild(keyData);
        }
      }
      document.body.appendChild(form);
      form.submit();
      this.loadingBarService.stop();
    }
  }

  public downloadExcelUsingPostAnalytics(
    servicePath: string,
    queryString: string
  ) {
    this.loadingBarService.start();
    let userSessionData;
    userSessionData = this.baseService.getUserInformation();
    let apiEndpoint = this.API_ENDPOINT;
    apiEndpoint = apiEndpoint.replace('cmsdev', 'ncmsdev');
    const apiPath = this.analyticsServiceApiPath;
    this.userInf = this.baseService.getUserInformation();
    const form = document.createElement('form');
    const formParams = {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem(CUSTOMER_TOKEN_INFO)).access_token
        }`,
      userEmail: this.userInf['email'],
      userId: userSessionData.userId,
      Accept: '*/*',
    };
    form.setAttribute('method', 'post');
    form.setAttribute(
      'action',
      this.envDetails.zuulApiEndPointPath +
      '/' +
      apiPath +
      servicePath +
      (queryString ? `?${queryString}` : '')
    );
    // tslint:disable-next-line: forin
    for (const key in formParams) {
      const keyData = document.createElement('input');
      keyData.setAttribute('type', 'hidden');
      keyData.setAttribute('name', key);
      keyData.value = formParams[key];
      form.appendChild(keyData);
    }
    document.body.appendChild(form);
    form.submit();
    this.loadingBarService.stop();
    return 'SUCCESS';
  }

  public post(data?: any) {
    this.getEnvironmentDetails();
    let payLoadJSON;

    let userSessionData = this.baseService.getUserInformation();
    if (
      this.fromTaskManagement &&
      !userSessionData &&
      String(userSessionData).trim() !== ''
    ) {
      userSessionData = localStorage.getItem(CUSTOMER_CURRENT_USER);
    }
    switch (data.targetedType) {
      case 'riskManagementService':
        payLoadJSON = {
          clientName: 'RiskManagementService',
          headerParams: {
            'content-type': 'application/json',
            userEmail: userSessionData['email'],
          },
          formParams: {
            requester: userSessionData['email'],
            requesterId: userSessionData['email'],
            approverId: userSessionData['email'],
          },
          method: '',
          servicePath: '',
          payload: {},
        };

        for (const type of Object.keys(data)) {
          switch (type.trim()) {
            case 'formParams':
              if (data[type.trim()] === null) {
                payLoadJSON[type] = {};
              } else {
                Object.assign(payLoadJSON[type], data[type]);
              }
              break;
            case 'headerParams':
              Object.assign(payLoadJSON[type], data[type]);
              break;
            case 'servicePath':
              payLoadJSON[
                type
              ] = `${this.riskManagementService}/${data['servicePath']}`;
              break;
            case 'method':
              payLoadJSON[type] = data['method'];
              break;
            case 'payload':
              payLoadJSON[type] = data[type];
              break;
          }
        }

        break;
      case 'eBenchmark':
        payLoadJSON = {
          clientName: 'EBenchMarkService',
          headerParams: {
            'content-type': 'application/json',
            userEmail: userSessionData['email'],
          },
          formParams: {
            requester: userSessionData['email'],
            requesterId: userSessionData['email'],
            approverId: userSessionData['email'],
          },
          method: '',
          servicePath: '',
          payload: {},
        };

        for (const type of Object.keys(data)) {
          switch (type.trim()) {
            case 'formParams':
              if (data[type.trim()] === null) {
                payLoadJSON[type] = {};
              } else {
                Object.assign(payLoadJSON[type], data[type]);
              }
              break;
            case 'headerParams':
              Object.assign(payLoadJSON[type], data[type]);
              break;
            case 'servicePath':
              payLoadJSON[
                type
              ] = `${this.eBenchmarkService}/${data['servicePath']}`;
              break;
            case 'method':
              payLoadJSON[type] = data['method'];
              break;
            case 'payload':
              payLoadJSON[type] = data[type];
              break;
          }
        }

        break;
      case 'platform':
        payLoadJSON = {
          clientName: 'PlatformServices',
          headerParams: {
            'content-type': 'application/json',
            userEmail: userSessionData['email'],
          },
          formParams: {},
          method: '',
          servicePath: '',
          payload: {},
        };

        for (const type of Object.keys(data)) {
          switch (type.trim()) {
            case 'formParams':
              if (data[type.trim()] === null) {
                payLoadJSON[type] = {};
              } else {
                Object.assign(payLoadJSON[type], data[type]);
              }
              break;
            case 'headerParams':
              Object.assign(payLoadJSON[type], data[type]);
              break;
            case 'servicePath':
              payLoadJSON[type] = `PlatformServices/${data['servicePath']}`;
              break;
            case 'method':
              payLoadJSON[type] = data['method'];
              break;
            case 'payload':
              payLoadJSON[type] = data[type];
              break;
          }
        }
        break;
      case 'PartWorkbenchService':
        payLoadJSON = {
          clientName: 'PartWorkbenchService',
          headerParams: {
            'content-type': 'application/json',
            userEmail: userSessionData['email'],
          },
          formParams: {},
          method: '',
          servicePath: '',
          payload: {},
        };
        for (const type of Object.keys(data)) {
          switch (type.trim()) {
            case 'formParams':
              if (data[type.trim()] === null) {
                payLoadJSON[type] = {};
              } else {
                Object.assign(payLoadJSON[type], data[type]);
              }
              break;
            case 'headerParams':
              Object.assign(payLoadJSON[type], data[type]);
              break;
            case 'servicePath':
              payLoadJSON[
                type
              ] = `${environment.partWorkbenchServiceApiPath}${data['servicePath']}`;
              break;
            case 'method':
              payLoadJSON[type] = data['method'];
              break;
            case 'payload':
              payLoadJSON[type] = data[type];
              break;
          }
        }
        break;
      case 'Collaboration':
        payLoadJSON = {
          clientName: this.collabService,
          headerParams: {
            'content-type': 'application/json',
            userEmail: userSessionData['email'],
          },
          formParams: {
            // tenantId': 193,
            /*  requester: userSessionData['email'],
             requesterId: userSessionData['email'],
             approverId: userSessionData['email'], */
          },
          method: '',
          servicePath: '',
          payload: {},
        };

        for (const type of Object.keys(data)) {
          switch (type.trim()) {
            case 'formParams':
              if (data[type.trim()] === null) {
                payLoadJSON[type] = {};
              } else {
                Object.assign(payLoadJSON[type], data[type]);
              }
              break;
            case 'headerParams':
              Object.assign(payLoadJSON[type], data[type]);
              break;
            case 'servicePath':
              payLoadJSON[
                type
              ] = `/${this.collabService}/${data['servicePath']}`;
              break;
            case 'method':
              payLoadJSON[type] = data['method'];
              break;
            case 'payload':
              payLoadJSON[type] = data[type];
              break;
          }
        }

        break;

      case 'productRFQ':
        payLoadJSON = {
          clientName: this.productRFQServicePath,
          headerParams: {
            'content-type': 'application/json;charset=utf-8',
            userEmail: userSessionData['email'],
          },
          payload: data.payload !== undefined ? data.payload : {},
          method: data.method,
          servicePath: `${this.productRFQServicePath}/${data.servicePath}`,
          formParams: data.formParams ? data.formParams : {},
        };

        for (const type of Object.keys(data)) {
          switch (type.trim()) {
            case 'formParams':
              payLoadJSON[type] = data[type];
              break;
            default:
              break;
          }
        }
        break;

      case 'RFQTemplateConfig':
        this.API_ENDPOINT =
          this.templateConfigApiEndPoint !== undefined
            ? this.templateConfigApiEndPoint
            : undefined;

        this.API_PATH = this.templateConfigApiPath;
        payLoadJSON = {
          clientName: this.templateServicePath,
          headerParams: {
            'Content-Type': 'application/json',
            userEmail: userSessionData.email,
          },
          payload: {},
          method: '',
          servicePath: '',
        };

        for (const type of Object.keys(data)) {
          switch (type.trim()) {
            case 'servicePath':
              payLoadJSON[
                type
              ] = `${this.templateServicePath}/${data.servicePath}`;
              break;
            case 'method':
              payLoadJSON[type] = data['method'];
              break;
            case 'payload':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'formParams':
              payLoadJSON[type] = data[type];
              break;
            default:
              break;
          }
        }

        break;

      case 'AlertsService':
        this.API_ENDPOINT =
          this.alertsServicesApiEndpointPath !== undefined
            ? this.alertsServicesApiEndpointPath
            : undefined;

        this.API_PATH = this.alertsServiceApiPath;
        payLoadJSON = {
          clientName: 'AlertsService',
          fileDownload: false,
          fileUpload: false,
          formParams: {},
          headerParams: {
            'Content-Type': 'application/json',
            userEmail: userSessionData.email,
          },
          pathParams: {},
          payload: {},
          method: 'GET',
          servicePath: '',
        };

        for (const type of Object.keys(data)) {
          switch (type.trim()) {
            case 'servicePath':
              payLoadJSON[type] = this.alertsServicePath + data['servicePath'];
              break;
            case 'method':
              payLoadJSON[type] = data['method'];
              break;
            case 'payload':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'formParams':
              payLoadJSON[type] = data[type];
              break;
            default:
              break;
          }
        }

        break;

      case 'CMS':
        const cmsApiEndpointPath = this.cmsApiEndpointPath;
        // console.log('cmsApiEndpointPath::' + cmsApiEndpointPath);
        if (cmsApiEndpointPath !== '' && cmsApiEndpointPath !== undefined) {
          this.API_ENDPOINT = cmsApiEndpointPath;
        }

        const cmsApiPath = this.cmsApiPath;
        // console.log('cmsApiPath::' + cmsApiPath);
        if (cmsApiPath !== '' && cmsApiPath !== undefined) {
          this.API_PATH = cmsApiPath;
        }

        payLoadJSON = {
          clientName: this.cms, // 'Levadata26', // Levadata 26
          formParams: {
            userName: userSessionData.userName, // devtest
            roleId: userSessionData.roleId,
            userId: userSessionData.userId,
            quarter: '',
            input: '',
          },
          headerParams: {
            userEmail: userSessionData.email,
            'Content-Type': 'application/json',
          },
          method: 'GET',
          servicePath: '', // /Levadata26/REST/services/SearchBasedCommodityCostLookup/search`
        };
        let cmsServiceType;
        for (const type of Object.keys(data)) {
          switch (type.trim()) {
            case 'formParams':
              if (data[type.trim()] === null) {
                payLoadJSON[type] = {};
              } else {
                Object.assign(payLoadJSON[type], data[type]);
              }
              payLoadJSON['formParams'].quarter = data['formParams'].quarter;
              payLoadJSON['formParams'].input = data['formParams'].input;
              payLoadJSON['formParams'].username = userSessionData['email'];
              break;
            case 'headerParams':
              Object.assign(payLoadJSON[type], data[type]);
              break;
            case 'serviceType':
              cmsServiceType = data[type.trim()];
              break;
            case 'servicePath':
              if (cmsServiceType === 'login') {
                payLoadJSON[type] = '/REST/services/' + data['servicePath'];
              } else {
                payLoadJSON[type] =
                  '/' + this.cms + '/REST/services/' + data['servicePath'];
              }
              break;
            case 'method':
              payLoadJSON[type] = data['method'];
              break;
            case 'clientName':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'userName':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'password':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'newPassword':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'authToken':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'payload':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'env':
              payLoadJSON[type] = data[type.trim()];
              break;
          }

          if (cmsServiceType === 'login') {
            delete payLoadJSON['formParams'];
            delete payLoadJSON['headerParams'];
            delete payLoadJSON['clientName'];
            // let splittedPath = this.API_PATH.split('/');
            // console.log(splittedPath[0]);
            // this.API_PATH = splittedPath[0] + '/login';
            // this.API_ENDPOINT = 'http://10.0.2.107:8161';
            this.API_ENDPOINT = environment['apiEndpointPath'];
            this.API_PATH = environment['loginapigateway']; // 'cmsapigateway/userLogin'

            // this.API_ENDPOINT = 'http://qatomcat.levadata.net:8084/'
            // this.API_PATH = 'apigateway4/userLogin'
          } else if (cmsServiceType === 'forgotpassword') {
            delete payLoadJSON['formParams'];
            // delete payLoadJSON['headerParams'];

            const splittedPath = this.API_PATH.split('/');
            // console.log(splittedPath[0]);
            this.API_PATH = splittedPath[0] + '/forgotpassword';
          } else if (cmsServiceType === 'changepassword') {
            delete payLoadJSON['formParams'];
            // delete payLoadJSON['headerParams'];
            const splittedPath = this.API_PATH.split('/');
            // console.log(splittedPath[0]);
            this.API_PATH = splittedPath[0] + '/changepassword';
          } else if (cmsServiceType === 'logOut') {
            payLoadJSON['authToken'] = userSessionData.auth_token;
            // payLoadJSON['clientName'] = this.cms;
            payLoadJSON['userName'] = userSessionData.email;
            delete payLoadJSON['formParams'];
            delete payLoadJSON['method'];
            this.API_ENDPOINT = environment['apiEndpointPath'];
            this.API_PATH = 'apigateway/cmslogout';
            delete payLoadJSON['headerParams'];
            // delecr
            console.log(payLoadJSON);
          }
        }
        delete payLoadJSON['targetedType'];
        break;
      case 'RFQ':
        const rfqApiPath = this.cmsApiPath;
        if (rfqApiPath !== '' && rfqApiPath !== undefined) {
          this.API_PATH = rfqApiPath;
        }

        const rfqApiEndpointPath = this.cmsApiEndpointPath;

        if (rfqApiEndpointPath !== '' && rfqApiEndpointPath !== undefined) {
          this.API_ENDPOINT = rfqApiEndpointPath;
        }
        // this.API_ENDPOINT = 'http://10.0.1.14:8161/';
        // this.API_PATH = 'generic';

        payLoadJSON = {
          clientName: this.rfq, // 'Levadata26', // Levadata 26
          fileDownload: false,
          fileUpload: false,
          formParams: data.formParams,
          headerParams: {
            'content-type': 'application/json;charset=utf-8',
            userEmail: userSessionData['email'],
            loginEmail: userSessionData['email'],
            email: userSessionData['email'],
          },
          method: data.method,
          servicePath: `${this.rfq}/${data.servicePath}`,
        };
        if (data.headerParams !== undefined) {
          Object.assign(payLoadJSON['headerParams'], data['headerParams']);
        }
        if (data.hasOwnProperty('encryptionType')) {
          payLoadJSON.encryptionType = data.encryptionType;
        }
        if (data.fileUpload) {
          payLoadJSON.fileUpload = true;
        }
        if (data.payload !== undefined) {
          payLoadJSON.payload = data.payload;
        }
        if (data.templateId !== undefined) {
          payLoadJSON.templateId = data.templateId;
        }
        if (data.contextName !== undefined) {
          payLoadJSON.contextName = data.contextName;
        }
        if (
          data.serviceType === 'deleteUser' ||
          data.serviceType === 'deactivateUser' ||
          data.serviceType === 'activateUser'
        ) {
          // payLoadJSON.servicePath = data.servicePath;
          delete payLoadJSON['clientName'];
          // delete payLoadJSON['headerParams'];
          delete payLoadJSON['fileDownload'];
          delete payLoadJSON['fileUpload'];
          const apiPostheaders = new HttpHeaders();
          apiPostheaders.set('userEmail', userSessionData.email);
          this.headers = apiPostheaders;
          // let splittedPath = this.API_PATH.split('/');
          // this.API_PATH =data.servicePath;
        }
        break;
      case 'Messages':
        const messageApiPath = this.cmsApiPath;
        // console.log('cmsApiPath::' + cmsApiPath);
        if (messageApiPath !== '' && messageApiPath !== undefined) {
          this.API_PATH = messageApiPath;
        }

        const messagesApiEndpointPath = this.cmsApiEndpointPath;
        // console.log('cmsApiEndpointPath::' + cmsApiEndpointPath);
        //  messagesApiEndpointPath = 'http://10.0.1.46:8161/';
        if (
          messagesApiEndpointPath !== '' &&
          messagesApiEndpointPath !== undefined
        ) {
          this.API_ENDPOINT = messagesApiEndpointPath;
        }

        payLoadJSON = {
          clientName: this.rfq, // 'Levadata26', // Levadata 26
          fileDownload: false,
          fileUpload: false,
          formParams: data.formParams,
          headerParams: {
            'content-type': 'application/json;charset=utf-8',
            userEmail: userSessionData['email'],
            loginEmail: userSessionData['email'],
            email: userSessionData['email'],
          },
          method: data.method,
          servicePath: '/' + this.messages + '/' + data.servicePath, // /Levadata26/REST/services/SearchBasedCommodityCostLookup/search`
        };
        if (data.headerParams !== undefined) {
          Object.assign(payLoadJSON['headerParams'], data['headerParams']);
        }
        if (data.fileUpload) {
          payLoadJSON.fileUpload = true;
        }
        if (data.payload !== undefined) {
          payLoadJSON.payload = data.payload;
        }
        break;
      case 'AnalyticsService':
        if (
          this.cmsApiEndpointPath !== '' &&
          this.cmsApiEndpointPath !== undefined
        ) {
          this.API_ENDPOINT = this.cmsApiEndpointPath;
        }
        if (this.cmsApiPath !== '' && this.cmsApiPath !== undefined) {
          this.API_PATH = this.cmsApiPath;
        }

        payLoadJSON = {
          clientName: this.analyticsService,
          formParams: {
            userName: userSessionData.userName,
            roleId: userSessionData.roleId,
            userId: userSessionData.userId,
          },
          headerParams: {
            userEmail: userSessionData.email,
            'Content-Type': 'application/json',
          },
          method: 'GET',
          servicePath: '',
        };

        for (const type of Object.keys(data)) {
          switch (type.trim()) {
            case 'formParams':
              if (data[type.trim()] === null) {
                payLoadJSON[type] = {};
              } else {
                Object.assign(payLoadJSON[type], data[type]);
              }
              break;
            case 'headerParams':
              Object.assign(payLoadJSON[type], data[type]);
              break;
            case 'serviceType':
              cmsServiceType = data[type.trim()];
              break;
            case 'servicePath':
              payLoadJSON[type] =
                '/' + this.analyticsServiceApiPath + data['servicePath'];
              break;
            case 'method':
              payLoadJSON[type] = data['method'];
              break;
            case 'clientName':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'userName':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'password':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'newPassword':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'authToken':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'payload':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'encryptionType':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'env':
              payLoadJSON[type] = data[type.trim()];
              break;
          }
        }
        delete payLoadJSON['targetedType'];
        break;
      case 'MIService':
        if (
          this.cmsApiEndpointPath !== '' &&
          this.cmsApiEndpointPath !== undefined
        ) {
          this.API_ENDPOINT = this.cmsApiEndpointPath;
        }
        if (this.cmsApiPath !== '' && this.cmsApiPath !== undefined) {
          this.API_PATH = this.cmsApiPath;
        }

        // this.API_ENDPOINT = 'http://10.0.1.116:8161/';
        payLoadJSON = {
          clientName: this.miService,
          formParams: {
            userName: userSessionData.userName,
            roleId: userSessionData.roleId,
            userId: userSessionData.userId,
            userEmail: userSessionData.email,
          },
          headerParams: {
            userEmail: userSessionData.email,
            'Content-Type': 'application/json',
          },
          method: 'GET',
          servicePath: '',
        };

        for (const type of Object.keys(data)) {
          switch (type.trim()) {
            case 'formParams':
              if (data[type.trim()] === null) {
                payLoadJSON[type] = {};
              } else {
                Object.assign(payLoadJSON[type], data[type]);
              }
              break;
            case 'headerParams':
              Object.assign(payLoadJSON[type], data[type]);
              break;
            case 'serviceType':
              cmsServiceType = data[type.trim()];
              break;
            case 'servicePath':
              payLoadJSON[type] =
                '/' + this.miServiceApiPath + data['servicePath'];
              break;
            case 'method':
              payLoadJSON[type] = data['method'];
              break;
            case 'clientName':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'userName':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'password':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'newPassword':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'authToken':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'payload':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'env':
              payLoadJSON[type] = data[type.trim()];
              break;
          }
        }
        delete payLoadJSON['targetedType'];
        break;
      case 'NegotiationService':
        if (
          this.cmsApiEndpointPath !== '' &&
          this.cmsApiEndpointPath !== undefined
        ) {
          this.API_ENDPOINT = this.cmsApiEndpointPath;
        }
        if (this.cmsApiPath !== '' && this.cmsApiPath !== undefined) {
          this.API_PATH = this.cmsApiPath;
        }

        payLoadJSON = {
          clientName: this.negotiationService,
          formParams: {
            userName: userSessionData.userName,
            roleId: userSessionData.roleId,
            userId: userSessionData.userId,
          },
          headerParams: {
            userEmail: userSessionData.email,
            'Content-Type': 'application/json',
          },
          method: 'GET',
          servicePath: '',
        };

        for (const type of Object.keys(data)) {
          switch (type.trim()) {
            case 'formParams':
              if (data[type.trim()] === null) {
                payLoadJSON[type] = {};
              } else {
                Object.assign(payLoadJSON[type], data[type]);
              }
              break;
            case 'headerParams':
              Object.assign(payLoadJSON[type], data[type]);
              break;
            case 'serviceType':
              cmsServiceType = data[type.trim()];
              break;
            case 'servicePath':
              payLoadJSON[type] =
                '/' + this.negotiationServiceApiPath + data['servicePath'];
              payLoadJSON[type] = payLoadJSON[type].toString().trim();

              break;
            case 'method':
              payLoadJSON[type] = data['method'];
              break;
            case 'clientName':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'userName':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'password':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'newPassword':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'authToken':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'payload':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'env':
              payLoadJSON[type] = data[type.trim()];
              break;
          }
        }
        delete payLoadJSON['targetedType'];

        break;
      case 'CommonService':
        if (
          this.cmsApiEndpointPath !== '' &&
          this.cmsApiEndpointPath !== undefined
        ) {
          this.API_ENDPOINT = this.cmsApiEndpointPath;
        }
        if (this.cmsApiPath !== '' && this.cmsApiPath !== undefined) {
          this.API_PATH = this.cmsApiPath;
        }

        payLoadJSON = {
          clientName: this.commonService,
          formParams: {
            userName: userSessionData.userName,
            roleId: userSessionData.roleId,
            userId: userSessionData.userId,
          },
          headerParams: {
            userEmail: userSessionData.email,
            'Content-Type': 'application/json',
          },
          method: 'GET',
          servicePath: '',
        };

        for (const type of Object.keys(data)) {
          switch (type.trim()) {
            case 'formParams':
              if (data[type.trim()] === null) {
                payLoadJSON[type] = {};
              } else {
                Object.assign(payLoadJSON[type], data[type]);
              }
              break;
            case 'headerParams':
              Object.assign(payLoadJSON[type], data[type]);
              break;
            case 'serviceType':
              cmsServiceType = data[type.trim()];
              break;
            case 'servicePath':
              payLoadJSON[type] =
                '/' + this.commonServiceApipath + data['servicePath'];
              break;
            case 'method':
              payLoadJSON[type] = data['method'];
              break;
            case 'clientName':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'userName':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'password':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'newPassword':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'authToken':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'payload':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'env':
              payLoadJSON[type] = data[type.trim()];
              break;
          }
        }
        delete payLoadJSON['targetedType'];
        break;
      case 'UserService':
      case 'TokenService':
        if (
          this.cmsApiEndpointPath !== '' &&
          this.cmsApiEndpointPath !== undefined
        ) {
          this.API_ENDPOINT = this.cmsApiEndpointPath;
        }
        if (this.cmsApiPath !== '' && this.cmsApiPath !== undefined) {
          this.API_PATH = this.cmsApiPath;
        }
        payLoadJSON = {
          clientName: this.userService,
          formParams: {
            // userName:
            //   userSessionData !== undefined ? userSessionData.userName : '',
            // roleId: userSessionData !== undefined ? userSessionData.roleId : '',
            // userId: userSessionData !== undefined ? userSessionData.userId : ''
          },
          headerParams: {
            userEmail:
              userSessionData !== undefined ? userSessionData.email : '',
            'Content-Type': 'application/json',
          },
          payload: data.payload,
          method: 'GET',
          servicePath: '',
        };

        for (const type of Object.keys(data)) {
          switch (type.trim()) {
            case 'formParams':
              if (data[type.trim()] === null) {
                payLoadJSON[type] = {};
              } else {
                Object.assign(payLoadJSON[type], data[type]);
              }
              break;
            case 'headerParams':
              Object.assign(payLoadJSON[type], data[type]);
              break;
            case 'serviceType':
              cmsServiceType = data[type.trim()];
              break;
            case 'servicePath':
              payLoadJSON[type] =
                data['targetedType'] === 'UserService'
                  ? this.userServiceApiPath + data['servicePath']
                  : this.tokenServiceApiPath + data['servicePath'];
              break;
            case 'method':
              payLoadJSON[type] = data['method'];
              break;
            case 'clientName':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'userName':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'password':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'newPassword':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'authToken':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'payload':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'env':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'encryptionType':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'supplier':
              payLoadJSON[type] = data[type.trim()];
              break;
          }
        }
        delete payLoadJSON['targetedType'];
        break;
      case 'CustomerConfigService':
        if (
          this.cmsApiEndpointPath !== '' &&
          this.cmsApiEndpointPath !== undefined
        ) {
          this.API_ENDPOINT = this.cmsApiEndpointPath;
        }
        if (this.cmsApiPath !== '' && this.cmsApiPath !== undefined) {
          this.API_PATH = this.cmsApiPath;
        }

        payLoadJSON = {
          clientName: this.customerConfigService,
          formParams: {
            userName: userSessionData.userName,
            roleId: userSessionData.roleId,
            userId: userSessionData.userId,
          },
          headerParams: {
            userEmail: userSessionData.email,
            'Content-Type': 'application/json',
          },
          method: 'GET',
          servicePath: '',
        };

        for (const type of Object.keys(data)) {
          switch (type.trim()) {
            case 'formParams':
              if (data[type.trim()] === null) {
                payLoadJSON[type] = {};
              } else {
                Object.assign(payLoadJSON[type], data[type]);
              }
              break;
            case 'headerParams':
              Object.assign(payLoadJSON[type], data[type]);
              break;
            case 'serviceType':
              cmsServiceType = data[type.trim()];
              break;
            case 'servicePath':
              if (data.generic) {
                payLoadJSON[
                  type
                ] = `${environment.customerConfigurationGenericServiceApiPath}${data['servicePath']}`;
              } else {
                payLoadJSON[
                  type
                ] = `${this.customerConfigServiceApiPath}${data['servicePath']}`;
              }
              if (data.custom) {
                payLoadJSON[type] = data['servicePath'];
              }
              break;
            case 'method':
              payLoadJSON[type] = data['method'];
              break;
            case 'clientName':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'userName':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'password':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'newPassword':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'authToken':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'payload':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'env':
              payLoadJSON[type] = data[type.trim()];
              break;
          }
        }
        delete payLoadJSON['targetedType'];
        break;
      case 'AuthService':
        payLoadJSON = {
          clientName: this.customerConfigService,
          formParams: {
            userName: userSessionData.userName,
            roleId: userSessionData.roleId,
            userId: userSessionData.userId,
          },
          headerParams: {
            userEmail: userSessionData.email,
            'Content-Type': 'application/json',
          },
          method: 'GET',
          servicePath: '',
        };

        for (const type of Object.keys(data)) {
          switch (type.trim()) {
            case 'formParams':
              if (data[type.trim()] === null) {
                payLoadJSON[type] = {};
              } else {
                Object.assign(payLoadJSON[type], data[type]);
              }
              break;
            case 'headerParams':
              Object.assign(payLoadJSON[type], data[type]);
              break;
            case 'serviceType':
              cmsServiceType = data[type.trim()];
              break;
            case 'servicePath':
              payLoadJSON[type] =
                this.authenticationServicePath + data['servicePath'];
              break;
            case 'method':
              payLoadJSON[type] = data['method'];
              break;
            case 'clientName':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'userName':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'payload':
              payLoadJSON[type] = data[type.trim()];
              break;
          }
        }
        delete payLoadJSON['targetedType'];
        break;
      case 'LevaAlertsService':
        const levaAlertsServicesApiEndpointPath =
          this.levaAlertsServicesApiEndpointPath;
        if (
          levaAlertsServicesApiEndpointPath !== '' &&
          levaAlertsServicesApiEndpointPath !== undefined
        ) {
          this.API_ENDPOINT = levaAlertsServicesApiEndpointPath;
        }

        const levaAlertsServiceApiPath = this.levaAlertsServiceApiPath;
        if (
          levaAlertsServiceApiPath !== '' &&
          levaAlertsServiceApiPath !== undefined
        ) {
          this.API_PATH = levaAlertsServiceApiPath;
        }

        payLoadJSON = {
          clientName: this.levaAlertsServices,
          formParams: {
            userName: userSessionData.userName,
            roleId: userSessionData.roleId,
            userId: userSessionData.userId,
          },
          headerParams: {
            userEmail: userSessionData.email,
            'Content-Type': 'application/json',
          },
          method: 'GET',
          servicePath: '',
        };

        for (const type of Object.keys(data)) {
          switch (type.trim()) {
            case 'formParams':
              if (data[type.trim()] === null) {
                payLoadJSON[type] = {};
              } else {
                Object.assign(payLoadJSON[type], data[type]);
              }
              break;
            case 'headerParams':
              Object.assign(payLoadJSON[type], data[type]);
              break;
            case 'serviceType':
              cmsServiceType = data[type.trim()];
              break;
            case 'servicePath':
              payLoadJSON[type] =
                '/' + this.levaAlertsServices + data['servicePath'];
              break;
            case 'method':
              payLoadJSON[type] = data['method'];
              break;
            case 'clientName':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'userName':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'password':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'newPassword':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'authToken':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'payload':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'isFileDownload':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'encryptionType':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'env':
              payLoadJSON[type] = data[type.trim()];
              break;
          }
        }
        delete payLoadJSON['targetedType'];
        break;
      case 'ChatbotServices':
        const chatbotServicesApiEndpointPath =
          this.chatbotServicesApiEndpointPath;
        if (
          chatbotServicesApiEndpointPath !== '' &&
          chatbotServicesApiEndpointPath !== undefined
        ) {
          this.API_ENDPOINT = chatbotServicesApiEndpointPath;
        }

        const chatbotServicesApiPath = this.chatbotServicesApiPath;
        if (
          chatbotServicesApiPath !== '' &&
          chatbotServicesApiPath !== undefined
        ) {
          this.API_PATH = chatbotServicesApiPath;
        }

        payLoadJSON = {
          clientName: this.chatbotServices,
          headerParams: {
            userEmail: userSessionData.email,
            'Content-Type': 'application/json',
          },
          method: 'GET',
          formParams: {},
          servicePath: '',
        };

        for (const type of Object.keys(data)) {
          switch (type.trim()) {
            case 'formParams':
              if (data[type.trim()] === null) {
                payLoadJSON[type] = {};
              } else {
                Object.assign(payLoadJSON[type], data[type]);
              }
              break;
            case 'headerParams':
              Object.assign(payLoadJSON[type], data[type]);
              break;
            case 'serviceType':
              cmsServiceType = data[type.trim()];
              break;
            case 'servicePath':
              payLoadJSON[
                type
              ] = `${this.chatbotServices}${data['servicePath']}`;
              // payLoadJSON[type] = '/' + this.chatbotServices + data['servicePath'];
              break;
            case 'method':
              payLoadJSON[type] = data['method'];
              break;
            case 'clientName':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'userName':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'password':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'newPassword':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'authToken':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'payload':
              payLoadJSON[type] = data[type.trim()];
              payLoadJSON[type]['userEmail'] =
                this.baseService.getUserInformation().email || '';
              payLoadJSON[type]['fullName'] =
                this.baseService.getUserInformation().fullName || '';
              break;
            case 'env':
              payLoadJSON[type] = data[type.trim()];
              break;
          }
        }

        delete payLoadJSON['targetedType'];
        break;
      case 'CustomReports':
        const customeReportsApiEndpointPath = this.customReportsApiEndpointPath;
        if (
          customeReportsApiEndpointPath !== '' &&
          customeReportsApiEndpointPath !== undefined
        ) {
          this.API_ENDPOINT = customeReportsApiEndpointPath;
        }

        const customReportsApiPath = this.customReportsApiPath;
        if (customReportsApiPath !== '' && customReportsApiPath !== undefined) {
          this.API_PATH = customReportsApiPath;
        }

        payLoadJSON = {
          clientName: this.customReports,
          formParams: {
            userName:
              userSessionData instanceof Object &&
                userSessionData.userName instanceof String
                ? userSessionData.userName
                : '',
            roleId:
              userSessionData instanceof Object &&
                userSessionData.roleId instanceof String
                ? userSessionData.roleId
                : '',
            userId:
              userSessionData instanceof Object &&
                userSessionData.userId instanceof String
                ? userSessionData.userId
                : '',
          },
          headerParams: {
            userEmail:
              userSessionData instanceof Object &&
                userSessionData.email instanceof String
                ? userSessionData.email
                : '',
            'Content-Type': 'application/json',
          },
          method: 'GET',
          servicePath: '',
        };

        for (const type of Object.keys(data)) {
          switch (type.trim()) {
            case 'formParams':
              if (data[type.trim()] === null) {
                payLoadJSON[type] = {};
              } else {
                Object.assign(payLoadJSON[type], data[type]);
              }
              break;
            case 'headerParams':
              Object.assign(payLoadJSON[type], data[type]);
              break;
            case 'serviceType':
              cmsServiceType = data[type.trim()];
              break;
            case 'servicePath':
              payLoadJSON[type] = this.customReports + data['servicePath'];
              break;
            case 'method':
              payLoadJSON[type] = data['method'];
              break;
            case 'clientName':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'userName':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'password':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'newPassword':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'authToken':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'payload':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'env':
              payLoadJSON[type] = data[type.trim()];
              break;
          }
        }
        delete payLoadJSON['targetedType'];
        break;

      case 'ELS':
        const elsApiEndpointPath = this.elsApiEndpointPath;
        // console.log('elsApiEndpointPath::' + elsApiEndpointPath);
        if (elsApiEndpointPath !== '' && elsApiEndpointPath !== undefined) {
          this.API_ENDPOINT = elsApiEndpointPath;
        }

        const elsApiPath = this.elsApiPath;
        // console.log('elsApiPath ::' + elsApiPath);
        if (elsApiPath !== '' && elsApiPath !== undefined) {
          this.API_PATH = elsApiPath;
        }

        payLoadJSON = {
          clientName: this.elasticSearch, // 'ElasticSearch',
          fileDownload: false,
          fileUpload: false,
          headerParams: {
            userEmail: userSessionData.email,
            'content-type': 'application/json',
          },
          payload: { tenantId: this.tenantId },

          method: 'POST',
          servicePath: '',
        };

        for (const type of Object.keys(data)) {
          switch (type.trim()) {
            case 'formParams':
            case 'headerParams':
              Object.assign(payLoadJSON[type], data[type]);
              break;
            case 'servicePath':
              // payLoadJSON[type] =
              //   '/' + this.elasticSearch + '/' + data['servicePath'];
              payLoadJSON[
                type
              ] = `${this.elasticSearch}/${data['servicePath']}`;
              break;
            case 'method':
              payLoadJSON[type] = data['method'];
              break;
            case 'clientName':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'payload':
              Object.assign(payLoadJSON[type], data[type]);
              break;
          }
        }

        break;

      case 'IE':
        const ieApiEndpointPath = this.ieApiEndpointPath;
        if (ieApiEndpointPath !== '' && ieApiEndpointPath !== undefined) {
          this.API_ENDPOINT = ieApiEndpointPath;
        }

        const ieApiPath = this.ieApiPath;
        if (ieApiPath !== '' && ieApiPath !== undefined) {
          this.API_PATH = ieApiPath;
        }

        payLoadJSON = {
          clientName: this.ieService,
          headerParams: {
            'content-type': 'application/json',
            userEmail: userSessionData['email'],
          },
          formParams: {
            tenantId: this.envDetails.ieTenantId,
            requester: userSessionData['email'],
            requesterId: userSessionData['email'],
            approverId: userSessionData['email'],
          },
          method: '',
          servicePath: '',
          payload: {},
        };

        for (const type of Object.keys(data)) {
          switch (type.trim()) {
            case 'formParams':
              if (data[type.trim()] === null) {
                payLoadJSON[type] = {};
              } else {
                Object.assign(payLoadJSON[type], data[type]);
              }
              break;
            case 'headerParams':
              Object.assign(payLoadJSON[type], data[type]);
              break;
            case 'servicePath':
              payLoadJSON[type] =
                '/' + this.ieService + '/' + data['servicePath'];
              break;
            case 'method':
              payLoadJSON[type] = data['method'];
              break;
            // case ('clientName'):
            //     payLoadJSON[type] = data[type.trim()];
            //     break;
            case 'payload':
              Object.assign(payLoadJSON[type], data[type]);
              break;
          }
        }

        break;
      case 'ProgramManagementService':
      case 'BomManagementService':
      case 'BomManagementFlattenedService':
      case 'StageGateManagementService':
      case 'TemplateManagementService':
      case 'SchedulerService':
      case 'partsMasterService':
      case 'sisenseService':
      case 'postDataRefreshService':
        payLoadJSON = {
          headerParams: {
            'content-type': 'application/json',
            userEmail: userSessionData['email'],
          },
          formParams: {},
          method: '',
          servicePath: '',
          payload: {},
        };

        for (const type of Object.keys(data)) {
          switch (type.trim()) {
            case 'formParams':
              Object.assign(payLoadJSON[type], data[type]);
              break;
            case 'headerParams':
              Object.assign(payLoadJSON[type], data[type]);
              break;
            case 'servicePath':
              payLoadJSON[type] =
                this.servicePathsConstants[data.targetedType] +
                data['servicePath'];
              break;
            case 'method':
              payLoadJSON[type] = data['method'];
              break;
            case 'payload':
              payLoadJSON[type] = data[type];
              break;
          }
        }
        break;
      case 'ContentManagementService':
        payLoadJSON = {
          formParams: data.formParams,
          headerParams: {
            UUID: userSessionData.auth_token,
            'content-type': 'application/json;charset=utf-8',
            userEmail: userSessionData['email'],
            loginEmail: userSessionData['email'],
            email: userSessionData['email'],
          },
          method: data.method,
          servicePath: `${data.servicePath}`,
          payload: data.payload,
        };
        break;
      case 'DataSegmentationService':
        payLoadJSON = {
          formParams: data.formParams,
          headerParams: {
            UUID: userSessionData.auth_token,
            'content-type': 'application/json;charset=utf-8',
            userEmail: userSessionData['email'],
            loginEmail: userSessionData['email'],
            email: userSessionData['email'],
          },
          method: data.method,
          servicePath: `${data.targetedType}/${data.servicePath}`,
          payload: data.payload,
        };
        break;
      case 'VendorRfxService':
      case 'SRMService':
        payLoadJSON = {
          clientName: 'CRMService',
          fileDownload: false,
          fileUpload: false,
          formParams: {},
          headerParams: {
            // UUID: userSessionData['auth_token'],
            'content-type': 'application/json;charset=utf-8',
            userEmail: userSessionData['email'],
            //  loginEmail: userSessionData['email'],
            email: userSessionData['email'],
          },
          method: 'POST',
          pathParams: {},
          payload: {},
          servicePath: '',
        };
        for (const type of Object.keys(data)) {
          switch (type.trim()) {
            case 'formParams':
              {
                payLoadJSON['formParams'] = data['formParams'];
              }
              break;
            case 'headerParams':
              Object.assign(payLoadJSON[type], data[type]);
              if (data['payload'] !== undefined && data['payload'].rfqId) {
                payLoadJSON['headerParams'].rfqId = data['payload'].rfqId;
              }
              payLoadJSON['formParams'].userEmail = userSessionData['email'];
              payLoadJSON['formParams'] = data[type.trim()];
              break;
            case 'servicePath':
              payLoadJSON[type] = data['servicePath'];
              break;
            case 'encryptionType':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'method':
              payLoadJSON[type] = data['method'];
              break;
            case 'clientName':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'payload':
              payLoadJSON[type] = data[type.trim()];
              if (
                data.serviceType !== undefined &&
                data.serviceType !== 'messages'
              ) {
                payLoadJSON['payload'].supplierEmail = userSessionData['email'];
              }
              break;
          }
        }
        break;
      default:
        const cmsApiEndpointPath1 = this.cmsApiEndpointPath;
        // console.log('cmsApiEndpointPath' + cmsApiEndpointPath1);
        if (cmsApiEndpointPath1 !== '' && cmsApiEndpointPath1 !== undefined) {
          this.API_ENDPOINT = cmsApiEndpointPath1;
        }

        const cmsApiPath1 = this.cmsApiPath;
        // console.log('cmsApiPath1::' + cmsApiPath1);
        if (cmsApiPath1 !== '' && cmsApiPath1 !== undefined) {
          this.API_PATH = cmsApiPath1;
        }

        payLoadJSON = {
          clientName: this.rfq, // 'CRMService161',
          fileDownload: false,
          fileUpload: false,
          formParams: {},
          headerParams: {
            'content-type': 'application/json;charset=utf-8',
            userEmail: userSessionData['email'],
            loginEmail: userSessionData['email'],
            email: userSessionData['email'],
          },
          method: 'POST',
          pathParams: {},
          payload: {},
          servicePath: '', // '/CRMService161/getDashBoardOpportunityGraphs'
        };

        for (const type of Object.keys(data)) {
          switch (type.trim()) {
            case 'formParams':
            case 'headerParams':
              Object.assign(payLoadJSON[type], data[type]);
              payLoadJSON['formParams'].loginEmail = userSessionData['email'];
              break;
            case 'servicePath':
              payLoadJSON[type] = data['servicePath'];
              break;
            case 'method':
              payLoadJSON[type] = data['method'];
              break;
            case 'clientName':
              payLoadJSON[type] = data[type.trim()];
              break;
            case 'payload':
              payLoadJSON[type] = data[type.trim()];
              break;
          }
        }
        break;
    }

    switch (payLoadJSON.method.toString().toLowerCase()) {
      case 'get':
        return this.getCallThroughZuul(payLoadJSON);

      case 'post':
        return this.postCallThroughZuul(payLoadJSON);

      case 'delete':
        return this.delCallThroughZuul(payLoadJSON);

      case 'put':
        return this.putCallThroughZuul(payLoadJSON);
    }
  }

  public delCallThroughZuul(payLoadJSON) {
    const params = payLoadJSON.formParams;
    const headers = this.getValidHeaders(payLoadJSON.headerParams);
    const options = {
      headers: new HttpHeaders(headers),
      params: new HttpParams(),
      body: payLoadJSON.payload,
    };
    let url = '';
    url = `${this.envDetails.zuulApiEndPointPath}${payLoadJSON.servicePath}?`;
    // for (const key in headers) {
    //   options.headers.append(key, headers[key]);
    // }
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        if (params[key] === null || params[key] === undefined) {
          params[key] = '';
        }
        url = url.concat(key + '=' + params[key] + '&');
      }
    }
    return this.http.delete(url, options).pipe(
      map(this.extractData),
      catchError((error) => {
        return this.handleError(
          error || 'Error while executing POST for route ' + this.API_PATH
        );
      })
    );
  }

  public getCallThroughZuul(payLoadJSON) {
    const params =
      payLoadJSON.formParams !== undefined ? payLoadJSON.formParams : undefined;
    const headers = this.getValidHeaders(payLoadJSON.headerParams);
    const options = {
      headers: new HttpHeaders(headers),
      /*Confirmed this function owner and commenting this part . May need it in future. Hence not removing it.*/
      // params: new HttpParams()
    };
    let url = '';
    if (params !== undefined) {
      url = this.envDetails.zuulApiEndPointPath + payLoadJSON.servicePath + '?';
    } else {
      url = this.envDetails.zuulApiEndPointPath + payLoadJSON.servicePath;
    }
    // if (payLoadJSON.servicePath.includes('/CollaborationService')) {
    //   url = this.collabApiEndpointPath + payLoadJSON.servicePath + '?';
    // }
    /*Confirmed this function owner and commenting this part . May need it in future. Hence not removing it.*/
    // if (payLoadJSON.formParams !== undefined) {
    //   for (let key in payLoadJSON.formParams) {
    //     if (payLoadJSON.formParams[key]) {
    //       options.params.append(key, payLoadJSON.formParams[key]);
    //     }
    //   }
    // }
    // for (const key in headers) {
    //   options.headers.set(key, headers[key]);
    // }
    if (payLoadJSON.hasOwnProperty('encryptionType') && this.sharedSecretKey) {
      if (params && payLoadJSON.encryptionType === 'formParams') {
        url = url.concat(
          'q=',
          this.getEncryptedText(this.formTextForApiCalls(params))
        );
      } else {
        if (params) {
          url = url.concat(this.formTextForApiCalls(params));
        }
      }
    } else {
      if (params) {
        url = url.concat(this.formTextForApiCalls(params));
      }
    }
    return this.http.get(url, options).pipe(
      map(this.extractData),
      catchError((error) => {
        return this.handleError(
          error || 'Error while executing POST for route ' + this.API_PATH
        );
      })
    );
  }

  public postCallThroughZuul(payLoadJSON) {
    const params = payLoadJSON.formParams;
    const headers = this.getValidHeaders(payLoadJSON.headerParams);
    const options = {
      headers: new HttpHeaders(headers),
      params: new HttpParams(),
    };
    let url = '';
    let body;

    let needsToBypass = false;
    if (environment.bypassedServices.length > 0) {
      for (const service of environment.bypassedServices) {
        if (payLoadJSON.servicePath.includes(service)) {
          needsToBypass = true;
          break;
        }
      }
    }
    if (needsToBypass) {
      url = 'http://localhost:8081/ZuulService/' + payLoadJSON.servicePath;
    } else {
      url = this.envDetails.zuulApiEndPointPath + payLoadJSON.servicePath;
    }
    url += `${payLoadJSON.servicePath.search('\\?') > -1 ? '&' : '?'}`;
    // if (payLoadJSON.servicePath.includes('/CollaborationService')) {
    //   url = this.collabApiEndpointPath + payLoadJSON.servicePath + '?';
    // }
    // for (const key in headers) {
    //   options.headers.append(key, headers[key]);
    // }
    if (payLoadJSON.hasOwnProperty('encryptionType') && this.sharedSecretKey) {
      const text = '';
      switch (payLoadJSON.encryptionType) {
        case 'formParams':
          url = url.concat(
            'q=',
            this.getEncryptedText(this.formTextForApiCalls(params))
          );
          body = payLoadJSON.payload;
          break;
        case 'payload':
          url = url.concat(this.formTextForApiCalls(params));
          body = {
            input: this.getEncryptedText(JSON.stringify(payLoadJSON.payload)),
          };
          break;
        case 'both':
          url = url.concat(
            'q=',
            this.getEncryptedText(this.formTextForApiCalls(params))
          );
          body = {
            input: this.getEncryptedText(JSON.stringify(payLoadJSON.payload)),
          };
          break;
        default:
          break;
      }
    } else {
      url = url.concat(this.formTextForApiCalls(params));
      body = payLoadJSON.payload;
    }
    return this.http.post(url, body, options).pipe(
      map(this.extractData),
      catchError((error) => {
        return this.handleError(
          error || 'Error while executing POST for route ' + this.API_PATH
        );
      })
    );
  }

  public putCallThroughZuul(payLoadJSON) {
    const params = payLoadJSON.formParams;
    const headers = this.getValidHeaders(payLoadJSON.headerParams);
    const options = {
      headers: new HttpHeaders(headers),
    };
    let url = '';
    const body = payLoadJSON.payload;
    url = `${this.envDetails.zuulApiEndPointPath}${payLoadJSON.servicePath}?`;

    // if (payLoadJSON.servicePath.includes('/CollaborationService')) {
    //   url = this.collabApiEndpointPath + payLoadJSON.servicePath + '?';
    // }
    // for (const key in headers) {
    //   options.headers.append(key, headers[key]);
    // }
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        if (params[key] === null || params[key] === undefined) {
          params[key] = '';
        }
        url = url.concat(key + '=' + params[key] + '&');
      }
    }
    return this.http.put(url, body, options).pipe(
      map(this.extractData),
      catchError((error) => {
        return this.handleError(
          error || 'Error while executing POST for route ' + this.API_PATH
        );
      })
    );
  }

  public directPostforFileUpload(payLoadJSON, formData) {
    const params = payLoadJSON.formParams;
    const headers = this.getValidHeaders(payLoadJSON.headerParams);

    const options = {
      headers: new HttpHeaders(headers),
      params: new HttpParams(),
    };
    let url = '';
    const body = formData;
    for (const key in payLoadJSON.formParams) {
      if (payLoadJSON.formParams.hasOwnProperty(key)) {
        options.params.append(key, payLoadJSON.formParams[key]);
      }
    }

    if (payLoadJSON.servicePath.search('\\?') > -1) {
      url = this.envDetails.zuulApiEndPointPath + payLoadJSON.servicePath + '&';
    } else {
      url = this.envDetails.zuulApiEndPointPath + payLoadJSON.servicePath + '?';
    }
    // if (payLoadJSON.servicePath.includes('/CollaborationService')) {
    //   url = this.collabApiEndpointPath + payLoadJSON.servicePath + '?';
    // }

    // for (const key in headers) {
    //   options.headers.append(key, headers[key]);
    // }
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        if (params[key] === null || params[key] === undefined) {
          params[key] = '';
        }
        url = url.concat(key + '=' + params[key] + '&');
      }
    }
    return this.http.post(url, body, options).pipe(
      map(this.extractData),
      catchError((error) => {
        return this.handleError(
          error || 'Error while executing POST for route ' + this.API_PATH
        );
      })
    );
  }

  public directPostforFileUploadWithProgress(payLoadJSON, formData) {
    const params = payLoadJSON.formParams;
    const headers = this.getValidHeaders(payLoadJSON.headerParams);

    const options: any = {
      headers: new HttpHeaders(headers),
      params: new HttpParams(),
      observe: 'events',
      reportProgress: true,
    };
    let url = '';
    const body = formData;
    for (const key in payLoadJSON.formParams) {
      if (payLoadJSON.formParams.hasOwnProperty(key)) {
        options.params.append(key, payLoadJSON.formParams[key]);
      }
    }

    if (payLoadJSON.servicePath.search('\\?') > -1) {
      url = this.envDetails.zuulApiEndPointPath + payLoadJSON.servicePath + '&';
    } else {
      url = this.envDetails.zuulApiEndPointPath + payLoadJSON.servicePath + '?';
    }
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        if (params[key] === null || params[key] === undefined) {
          params[key] = '';
        }
        url = url.concat(key + '=' + params[key] + '&');
      }
    }
    return this.http.post(url, body, options);
  }

  public directPost(url?, body?, formParams?, headers?) {
    const options = {
      headers: new HttpHeaders(this.getValidHeaders(headers)),
      params: new HttpParams(),
    };
    const formBody = [];
    // tslint:disable-next-line:forin
    for (const property in formParams) {
      formBody.push(property + '=' + formParams[property]);
    }
    body = formBody.join('&');
    const apiPath = environment.zuulApiEndPointPath;
    return this.http.post(apiPath + url, body, options).pipe(
      map(this.extractData),
      catchError((error) => {
        return this.handleError(
          error || 'Error while executing POST for route ' + this.API_PATH
        );
      })
    );
  }

  private getEncryptedText(text) {
    const encryptedData = CryptoJS.AES.encrypt(text, this.sharedSecretKey, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encodeURIComponent(encryptedData.toString());
  }

  // public getDecryptedText(encryptedText) {
  //   const decryptedText = CryptoJS.AES.decrypt(
  //     encryptedText,
  //     this.sharedSecretKey,
  //     {
  //       mode: CryptoJS.mode.ECB,
  //       padding: CryptoJS.pad.Pkcs7,
  //     }
  //   );
  //   return decryptedText.toString(CryptoJS.enc.Utf8);
  // }

  public getDecryptedText(encryptedText) {
    let encryptedBase64Key = 'TGV2QGRAdEBhczNjcjN0MQ==';
    let parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);
    var decryptedData = CryptoJS.AES.decrypt(decodeURIComponent(encryptedText), parsedBase64Key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    var decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedText);
  }

  private formTextForApiCalls(params) {
    let text = '';
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        if (params[key] === null || params[key] === undefined) {
          params[key] = '';
        } else if (Array.isArray(params[key])) {
          if (params[key].length > 0) {
            params[key].forEach((val) => {
              text = text.concat(key + '=' + val + '&');
            });
          } else {
            text = text.concat(key + '=' + '&');
          }
          continue;
        }
        text = text.concat(key + '=' + params[key] + '&');
      }
    }
    return text;
  }

  public cognitivePost(data) {
    this.getEnvironmentDetails();
    const userSessionData = this.baseService.getUserInformation();
    const rfqApiPath = this.cmsApiPath;

    if (rfqApiPath !== '' && rfqApiPath !== undefined) {
      this.API_PATH = rfqApiPath;
    }

    const rfqApiEndpointPath = this.cmsApiEndpointPath;

    if (rfqApiEndpointPath !== '' && rfqApiEndpointPath !== undefined) {
      this.API_ENDPOINT = rfqApiEndpointPath;
    }
    const apiPostheaders = new HttpHeaders();
    if (
      data.serviceType === 'deleteUser' ||
      data.serviceType === 'deactivateUser'
    ) {
      // apiPostheaders.set('UUID', userSessionData.auth_token);
      apiPostheaders.set('userEmail', userSessionData.email);
    }
    const splittedPath = this.API_PATH.split('/');
    this.API_PATH =
      splittedPath[0] +
      data.servicePath +
      '?userName=' +
      data.formParams.userName;
    return this.http
      .post(this.API_ENDPOINT + this.API_PATH, data, {
        headers: apiPostheaders,
      })
      .pipe(
        map(this.extractData),
        catchError((error) => {
          return this.handleError(
            error || 'Error while executing POST for route ' + this.API_PATH
          );
        })
      );
  }

  protected NewsPost(data?: any) {
    this.getEnvironmentDetails();
    // console.log('data url :', data.url);
    // var basePath = 'NewsService/'+data.url;
    const basePath = 'NewsService/' + data.url;
    const userSessionData = this.baseService.getUserInformation();

    const payLoadJSON = {
      sessionId: '1',
      roleId: userSessionData.roleId,
      userId: userSessionData.userId,
      tenantId: this.tenantId,
    };
    //  var payLoadJSON = {'lastChatId':'2806' ,'chatsLimit':'20', 'userId':'1', 'tenantId':'299'}
    Object.assign(payLoadJSON, data);
    delete payLoadJSON['url'];
    const newsApiEndpointPath = this.newsApiEndpointPath;
    if (newsApiEndpointPath !== '' && newsApiEndpointPath !== undefined) {
      this.API_ENDPOINT = newsApiEndpointPath;
    }

    const newsApiPath = this.newsApiPath;
    // console.log(newsApiPath);
    if (newsApiPath !== '' && newsApiPath !== undefined) {
      this.API_PATH = newsApiPath;
    } else {
      this.API_PATH = basePath;
    }

    return this.http
      .get(this.API_ENDPOINT + this.API_PATH, { headers: this.headers })
      .pipe(
        map(this.extractData),
        catchError((error) => {
          return this.handleError(
            error || 'Error while executing POST for route ' + this.API_PATH
          );
        })
      );
  }

  protected BotPost(data?: any) {
    // this.getEnvironmentDetails();
    // // var API_PATH = this.chatBotService + data.url;
    // var userSessionData = this.baseService.getUserInformation();
    // let botApiEndpointPath = this.botApiEndpointPath;
    // if (botApiEndpointPath !== '' && botApiEndpointPath !== undefined)
    //     this.API_ENDPOINT = botApiEndpointPath;
    // // return this.http.post(this.API_ENDPOINT + API_PATH, data.payload, { headers: this.headers })
    //     .map(this.extractData)
    //     .catch(error => {
    //         return this.handleError(error || 'Error while executing POST for route ' + API_PATH);
    //     });
  }

  private handleError(error: any) {
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      try {
        const body = error || '';
        // const err = body.error || JSON.stringify(body);
        errMsg = `${error.status} -${error.url} - ${error.statusText || ''}`;
      } catch (e) {
        const body = error;
        if (
          (body !== undefined &&
            body['responseStatus'] &&
            body['responseStatus']['code'] === 401) ||
          (body.status !== undefined && body.status === 401)
        ) {
          console.log("monitoring the session expired pop up issue, 1")
          this.baseService.setUnAuthrizePopup(true);
          this.baseService.setAppLoader(false);
          this.sharedService.setLocalStorageData(
            CUSTOMER_SESSION_EXPIRED,
            true
          );
        }
        errMsg = error.toString();
      }
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(error);
    // return Observable.throw(error);
  }

  private extractData(res: any) {
    try {
      const body = res.json();
      if (
        (body.responseStatus !== undefined &&
          body.responseStatus.code === 401) ||
        (body.status !== undefined && body.status === 401)
      ) {
        this.sharedService.setLocalStorageData(CUSTOMER_SESSION_EXPIRED, true);
        console.log("monitoring the session expired pop up issue, 2")
        this.baseService.setUnAuthrizePopup(true);
      }

      return body || {};
    } catch (e) {
      return res;
    }
  }

  private getValidHeaders(headerObj) {
    const headers = {};
    if (headerObj instanceof Object && Object.keys(headerObj).length > 0) {
      Object.keys(headerObj).forEach((key) => {
        if (headerObj[key] && typeof headerObj[key] === 'string') {
          headers[key] = headerObj[key];
        } else if (
          typeof headerObj[key] === 'number' ||
          typeof headerObj[key] === 'boolean'
        ) {
          headers[key] = headerObj[key].toString();
        }
      });
    }
    return headers;
  }
}
