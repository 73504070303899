import { Injectable } from '@angular/core';

export interface LdCustomTag {
  type: string | any;
  displayName: string;
  [propName: string]: any;
}
export enum TaskPrefix {
  DEFAULT = 'LEVA',
  PLAT = 'PLAT',
  TM = 'TM',
  NPA = 'NPA',
  PDM = 'PDM',
  EVT = 'EVT',
  SRFX = 'SRFX',
}
export enum LdCustomTagTypes {
  DEFAULT = 'apps',
  PART = 'ac_unit',
  LOCATION_DEFAULT = 'web_asset',
  FILTER = 'filter_list_alt',
  TAG_USER_ASSIGNEE = 'circle',
  TAG_LOCATION_TABLE = 'grid_on',
  TAG_LOCATION_CHART = 'bar_chart',
  TAG_CONTEXT_PRODUCT = 'device_hub',
  TAG_CONTEXT_SUPPLIER = 'local_shipping',
  TAG_CONTEXT_MANUFACTURER = 'business',
  TAG_CONTEXT_BOM = 'account_tree',
  TAG_CONTEXT_PROGRAM = 'group_work',
  TAG_CONTEXT_SUB_ASSEMBLY = 'category',
  TAG_CONTEXT_CPN = 'circle',
  TAG_CONTEXT_MPN = 'filter_tilt_shift',
  TAG_CONTEXT_USER = 'person',
  TAG_CONTEXT_DATE = 'today',
  TAG_CONTEXT_FLAT = 'view_headline',
  TAG_FLASH_ON = 'flash_on',
  TAG_FILE_DOWNLOAD = 'file_download',
  TAG_NEW_FILE_DOWNLOAD = 'ld-icon-export-stylesheet2',
  TAG_FILE_UPLOAD = 'file_upload',
  TAG_NEW_FILE_UPLOAD = 'ld-icon-import-stylesheet',
  TAG_NEW_EXCEL = 'ld-icon-export-stylesheet',
  TAG_SAVE = 'save',
  TAG_RESET = 'replay',
  TAG_SETTINGS = 'settings',
  TAG_VIEW = 'remove_red_eye',
}
@Injectable({ providedIn: 'root' })
export class CustomTagService {}
