import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Subject} from 'rxjs';
import 'rxjs/add/operator/map';

import { CreateSchedule } from './create-schedule.model';
import { ApiServiceProvider } from 'app/api-service';
import { BaseService } from 'app/modules/common/base.service';

@Injectable()
export class CreateScheduleService {

  searchedTerm$: Subject<any> = new Subject<any>();
  suggestions$: Subject<any> = new Subject<any>();
  constructor(private http: HttpClient, private apiService: ApiServiceProvider, private baseService: BaseService) { }

  setSearchedTerm(searchTerm: string) {
        if (searchTerm) {
        this.searchedTerm$.next(searchTerm);
        }
  }

  setSuggestions(suggestions) {
    this.suggestions$.next(suggestions);
  }

  public fetchPartCheckoutIds(){
    const payload = {
      method: 'POST',
      servicePath: 'PartWorkbenchService/pwb/rfx/current/selected/scope/GET',
      formParams : {
        userEmail : this.baseService.getUserInformation().email,
      },
      payload : []
    }
    return this.apiService.post(payload);
  }
  createRFX(payload){
		const payloadObject = {
			method: 'POST',
			servicePath: 'PartWorkbenchService/create/pwb/rfx',
			payload
		  };
		  return this.apiService.post(payloadObject);
	}

}
