import { Injectable } from '@angular/core';
import * as Rx from 'rxjs/Rx';
import { BehaviorSubject } from 'rxjs/Rx';
import { environment } from 'environments/environment';
import { ApiServiceProvider } from 'app/api-service';
import { BaseService } from '../base.service';


@Injectable()
export class SocketService {
    constructor(private apiServiceProvider: ApiServiceProvider,
        private baseService: BaseService) {
        this.userInfo = this.baseService.getUserInformation()
    }
    userInfo: any;

    /* ------------------- Sending Message to Chatbot Component -------------------- */

    private chatbotNotificationAlert: any = new BehaviorSubject<any>(undefined);

    // public getChatBotNotificationAlert() {
    //     if (environment && environment['webSocke']) {
    //         return this.chatbotNotificationAlert.asObservable();
    //     }
    //     else {
    //         let obj = {
    //             targetedType: "LevaAlertsService",
    //             formParams: {
    //                 "userEmail": this.userInfo.email
    //             },
    //             servicePath: "/getNotifications",
    //             method: "get"
    //         }
    //         return this.apiServiceProvider.post(obj);
    //     }


    // }


    /* ------------------- Sending Message to News Component -------------------------------- */

    /* ------------------- Sending Message to Opportunities Component ----------------------- */


    /* Update Opportunited View */

    private selectedOpportunites = new BehaviorSubject<any>(undefined);

    // Static Varaible

    private normalSelectedOpportunites: {} = undefined;

    // set alert ID from chatbot

    private normalAlertId=undefined;

    // Set BehaviorSubject alert ID from Chatbot

    private alertId = new BehaviorSubject<any>(undefined);

    public setChatBotNotificationAlert(alert) {
        if (alert !== undefined) {
            if (alert instanceof Object) {
                this.chatbotNotificationAlert.next(alert);
            }
        }
    }

    // RX  Variable
    public onSetSelectedOpportunites(message) {
        this.selectedOpportunites.next(message);
    }

    public onGetSelectedOpportunites() {
        return this.selectedOpportunites.asObservable();
    }

    public onNormalSetSelectedOpportunites(message) {
        this.normalSelectedOpportunites = message;
    }

    public onNormalGetSelectedOpportunites() {
        return this.normalSelectedOpportunites;
    }

    public setNormalAlertIdFromChatBot(alertId) {
        this.normalAlertId = alertId;
    }

    public getNormalAlertIdFromChatBot() {
        return this.normalAlertId;
    }

    public setAlertIdFromChatBot(alertId) {
        this.alertId.next(alertId);
    }
    public getAlertIdFromChatBot() {
        return this.alertId.asObservable();
    }

}
