export const NPI_ROUTING_PATHS = {
  PARTSMASTER: 'npipartsmaster',
  OVERVIEW: 'npidashboard',
  PROGRAM_PROFILE: 'programprofile',
  PROGRAM_PROFILE_SUMMARY: 'ppsummary',
  BOM_MANGEMENT: 'ppbommanagement',
  PROGRAM_TRENDS: 'ppprogramtrends',
  BOM_COMPARE: 'ppbomcompare',
  BOM_ANALYTICS: 'ppbomanalytics',
  ADMIN: 'npiadmin',
  CREATE_PROGRAM: 'createprogram',
  OVERALL_STATUS_EXPANDVIEW: 'overallstatusexpand',
  NOTIFICATIONS_EXPAND_VIEW: 'notificationsexpand',
  PROGRAM_COST_EXPAND_VIEW: 'programcostexpand',
  PROGRAM_RISK_EXPAND_VIEW: 'programriskexpand',
  PROGRAM_STATUS_EXPAND_VIEW: 'programstatusexpand',
  PP_COST_EXPAND_VIEW: 'costexpand',
  BOM_RISK_EXPAND_VIEW: 'bomriskexpand',
  ADMIN_PROGRAM: 'adminprogram',
  ADMIN_RISK: 'adminrisk',
  ADMIN_RFQ: 'adminrfq',
  ADMIN_OPPORTUNITY: 'adminopportunities',
  CREATE_BOM_REVISION: 'createbomrevision',
  PREVIEW_BOM_TRANSFORMATIONS: 'transformationbom',
  PREVIEW_BOM_REVISION: 'previewbomrevision',
  BOM_REVISION: 'bomrevision',
  VIEW_BOM: 'viewbom',
  VIEW_HISTORY: 'viewhistory',
  DATA_HEALTH: 'datahealth',
  PROGRAM_GENERAL: 'programgeneral',
  PROGRAM_CHECKPOINTS: 'programcheckpoints',
  PROGRAM_STAGE: 'programstage',
  PROGRAM_TEMPLATE: 'programtemplate',
  PROGRAM_USER_MANAGEMENT: 'programusermanagement',
  EXPAND: 'expand',
  SUMMARY_PATH: '/app/npi/ppsummary/',
  OVERVIEW_PATH: '/app/npi/npidashboard/',
  CREATE_PROGRAM_PATH: '/app/npi/adminprogram/',
  CREATE_REVISION_PATH: '/app/npi/ppbommanagement/createbomrevision',
  BOM_MANAGEMENT_PATH: '/app/npi/ppbommanagement/',
  ADMIN_PROGRAM_PATH: '/app/npi/adminprogram/',
  AFTER_CREATING_PROGRAM: '/app/npi/ppbommanagement/createbomrevision',
  CONFIGURE_RISK: '/app/npi/adminrisk/',
  NPI_PARTSMASTER: 'app/npi/npipartsmaster',
  BOM_REPO_DETAILS: 'app/npi/bomrepository/details',
};

export const OVERVIEW_WIDGET_TYPES = {
  STATUS: 'STATUS',
  NOTIFICATION: 'NOTIFICATION',
  COST: 'COST',
  RISK: 'RISK',
};
export const DEFAULT_NPI_DASHBOARD_TITLE = 'NPI Dashboard';
export const DEFAULT_WIDGET_TITLES = {
  [OVERVIEW_WIDGET_TYPES.STATUS]: 'Overall Status',
  [OVERVIEW_WIDGET_TYPES.NOTIFICATION]: 'Notifications',
  [OVERVIEW_WIDGET_TYPES.COST]: 'Cost',
  [OVERVIEW_WIDGET_TYPES.RISK]: 'Risk',
};

export const OVERVIEW_WIDGET_EXPAND_ROUTING_URLS = {
  [OVERVIEW_WIDGET_TYPES.STATUS]: NPI_ROUTING_PATHS.OVERALL_STATUS_EXPANDVIEW,
  [OVERVIEW_WIDGET_TYPES.NOTIFICATION]:
    NPI_ROUTING_PATHS.NOTIFICATIONS_EXPAND_VIEW,
  [OVERVIEW_WIDGET_TYPES.COST]: NPI_ROUTING_PATHS.PROGRAM_COST_EXPAND_VIEW,
  [OVERVIEW_WIDGET_TYPES.RISK]: NPI_ROUTING_PATHS.PROGRAM_RISK_EXPAND_VIEW,
};

export const PROGRAM_PROFILE_WIDGET_TYPES = {
  STATUS: 'STATUS',
  COST: 'COST',
  RISK: 'RISK',
};
export const DEFAULT_PROGRAM_PROFILE_WIDGET_TITLES = {
  [PROGRAM_PROFILE_WIDGET_TYPES.STATUS]: 'Program Status',
  [PROGRAM_PROFILE_WIDGET_TYPES.COST]: 'Cost',
  [PROGRAM_PROFILE_WIDGET_TYPES.RISK]: 'BOM Risk',
};

export const PROGRAM_PROFILE_EXPAND_ROUTING_URLS = {
  [PROGRAM_PROFILE_WIDGET_TYPES.STATUS]:
    NPI_ROUTING_PATHS.PROGRAM_STATUS_EXPAND_VIEW,
  [PROGRAM_PROFILE_WIDGET_TYPES.COST]: NPI_ROUTING_PATHS.PP_COST_EXPAND_VIEW,
  [PROGRAM_PROFILE_WIDGET_TYPES.RISK]: NPI_ROUTING_PATHS.BOM_RISK_EXPAND_VIEW,
};

export const WIDGET_SIZES = {
  MEDIUM: 'MEDIUM',
  SMALL: 'SMALL',
  LARGE: 'LARGE',
  XLARGE: 'X-LARGE',
};

export const WIDGET_CLASSES = {
  [WIDGET_SIZES.SMALL]: 'npi-widget-smallwidth',
  [WIDGET_SIZES.MEDIUM]: 'npi-widget-mediumwidth',
  [WIDGET_SIZES.LARGE]: 'npi-widget-largewidth',
  [WIDGET_SIZES.XLARGE]: 'npi-widget-fullwidth',
};

export const TOASTER_MESSAGE_TYPES = {
  WARNING: 'warning',
  SUCCESS: 'success',
  INFO: 'info',
  ERROR: 'error',
};

export const WIDGET_READ_KEYS = {
  NPI_DASHBOARD_TITLE: 'groupName',
  WIDGETS_LIST: 'widgets',
  WIDGET_TITLE: 'widgetInfo.displayName',
  WIDGET_TYPE: 'widgetInfo.type',
  WIDGET_KEY: 'key',
  WIDGET_SIZE: 'widgetInfo.size',
  WIDGET_DISPLAY_ORDER: 'widgetInfo.displayOrder',
};

export const API_SERVICE_CONSTANTS = {
  CUSTOMER_CONFIG_TARGETED_TYPE: 'CustomerConfigService',
  PROGRAM_MANAGEMENT_TARGETED_TYPE: 'ProgramManagementService',
  BOM_ANALYTICS_TARGETED_TYPE: 'BomManagementService',
  BOM_ANALYTICS_FLATTENED_TARGETED_TYPE: 'BomManagementFlattenedService',
  SUPPLIER_SCORECARD_TARGETED_TYPE: 'ContentManagementService',
  STAGE_GATE_MANAGEMENT_TARGETED_TYPE: 'StageGateManagementService',
  TEMPLATE_MANAGEMENT_SERVICE: 'TemplateManagementService',
  WIDGET_SECTION: {
    EXPANDED: 'Expanded',
    SUMMARY: 'Summary',
    DETAILS: 'Details',
  },
  WIDGET_MANAGEMENT_API_NAME: 'widgetManagement',
  PATH_SEPARATOR: '/',
  DASHBOARD_PAGES: {
    OVERVIEW: 'NPI Dashboard',
    PROGRAM_PROFILE: 'NPI Program Profile Summary',
  },
  CREATE_PROGRAM: 'NPI_CREATE_PROGRAM',
  CONFIGURE_RISK: 'NPI_TIMELINE_RISK',
  BOM_RISK: 'NPI_BOM_RISK',
  NPI_DOWNLOAD_TYPE: 'bomDownload',
};

export const REST_END_POINT_URLS = {
  GET_ALL_WIDGETS: 'getAllWidgets',
  GET_HEADERS: 'getWidgetTabHeaders',
  MINIMIZED_COST_VIEW: 'getprogramcostview',
  MAXIMIZED_COST_VIEW: 'getprogramcostview',
  MINIMIZED_PROGRAM_COST: 'programcostviewbypgmkey',
  GET_WIDGET_HEADERS: 'getWidgetTabHeaders',
  OVERALL_STATUS_MIN_VIEW: 'minviewoverallstatuses',
  OVERALL_STATUS_MAX_VIEW: 'maxviewoverallstatuses',
  PROGRAM_STATUS_MIN_VIEW_GRAPH: 'stagecheckpointgraph',
  PROGRAM_STATUS_STAGE_INFO: 'stagedetails',
  PROGRAM_STATUS_MAX_VIEW_CHECKPOINT_INFO: 'checkpointdetails',
  PROGRAM_STATUS_UPDATE_STAGE_INFORMATION: 'updatestagedetails',
  PROGRAM_STATUS_UPDATE_CHECKPOINT_INFORMATION: 'updatecheckpointdetails',
  PROGRAMS_LIST: 'programs',
  NEW_PROGRAM_DATA: 'program/config/default',
  CREATE_PROGRAM: 'program/config',
  PREDICTIVE_COST: 'predictivecostview',
  BOM_CHECKOUT: 'bomcheckout',
  BOM_CHECKIN: 'bomcheckin',
  BOM_CONFIGURATION: 'risks/configuredbomrisks',
  UPDATE_STAGE_RISK: 'updatestagerisk',
  CHECKPOINT_RISK: 'stagescheckpoints/config',
  MATURITY_RISK: 'program/maturityrisk',
  RISK_EXPANDED_VIEW_TREE_STRUCTURE: 'risks/getprogramrisktreebyparts',
  RISK_BY_PARTS: 'risks/getprogramriskbyparts',
  RISK_BY_PROGRAM_KEY: 'risks/getriskbyprogramkey',
  RISK_BY_CATEGORY: 'risks/getprogramriskbycategories',
  RISK_WHERE_USED_CPNS: 'risks/getwhereusedcpns',
  BOM_ANALYTICS_SUMMARY: 'analytics/summary',
  BOM_COMPARE_DOWNLOAD: '/compare',
  BOM_ANALYTICS_DRIVER_INFO: 'analytics/summary/',
  DOWNLOAD_CONFIGURE_PROGRAM_USER_MANAGEMENT: 'downloadusermanagementexcel',
  DOWNLOAD_BOM_ANALYTICS_SUMMARY: '/download',
  DOWNLOAD_PROGRAM_RISK_BY_CATEGORY: 'risks/downloadprogramrisksbycategory',
  DOWNLOAD_PROGRAM_RISK_BY_PART: '/risks/downloadprogramrisksbyparts',
};

export const HTTP_REQUEST_TYPES = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const EMPTY_STRING = '';
export const WIDGET_FEATURES_KEY = {
  OVERVIEW: 'NPI Dashboard',
  PROGRAM_PROFILE: 'Summary',
  READ_KEYS: {
    WIDGET_TITLE: 'title',
  },
};

export const STATUS_CODES = {
  1: 'red',
  0: 'green',
};

export const GRAPH_CONSTANTS = {
  CIRCLE_COLORS: {
    LINECOLOR: {
      COMPLETED: '#b3c5d6',
      IN_PROGRESS: '#1C4E84',
      PENDING_FOR_APPROVAL: '#1C4E84',
      TBD: '#b3c5d6',
    },
    FILLCOLOR: {
      COMPLETED: '#b3c5d6',
      IN_PROGRESS: '#1C4E84',
      PENDING_FOR_APPROVAL: '#1C4E84',
      TBD: '#fff',
    },
  },
  CIRCLE_RADIUS: {
    STAGE: 20,
    CHECKPOINT: 10,
  },
  CIRCLE_TYPE: {
    STAGE: 'STAGE',
    CHECKPOINT: 'CHECKPOINT',
  },
  STATE_VALUES: {
    TBD: 'TBD',
    INPROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
    PENDINGFORAPPROVAL: 'PENDING_FOR_APPROVAL',
  },
};

export const DATE_PROPERTIES = {
  KEY: 'Date',
  FORMAT: 'MM-DD-YYYY',
  FORMATWITHTIME: 'MM-DD-YYYY HH:mm:ss',
};

export const TYPE_CHECKING_KEYS = {
  BOOLEAN: 'boolean',
};
export const ORDER_KEYS = {
  GRID_ORDER: 'gridorder',
};
export const COST_DATA_MAP_KEYS = {
  PROGRAM_NAME: 'programname',
  COST_OPPORTUNITIES: 'COST_OPPORTUNITIES',
  TOTAL_COST_RECOMMENDATIONS: 'RECOMMENDATIONS',
  DASHBOARD_MISSING_PARTS_PROGRAMS: 'MISSING_PROGRAMS',
  DASHBOARD_TOP_PERFORMING_PROGRAMS: 'TOP_PERFORMING_PROGRAMS',
  DASHBOARD_UNDER_PERFORMING_PROGRAMS: 'UNDER_PERFORMING_PGMS',
  DASHBOARD_COST: 'DASHBOARD_COST',
  PROGRAM_COST_OPPORTUNITIES: 'OPPORTUNITIES',
  PROGRAM_COST_RECOMMENDATIONS: 'RECOMMENDATIONS',
  PROGRAM_COST_RISK: 'Risks',
  PROGRAM_TARGET_COST: 'targetCost',
  PROGRAM_CURRENT_COST: 'currentCost',
  PROGRAM_DATA: 'PROGRAM_DATA',
};

export const TABS_KEYS = {
  CREATE_PROGRAM: 'NPI Create Program',
  EXISTING_PROGRAM_TABS: 'NPI Program Profile',
  NPI: 'NPI',
  ROUTES_COMPARISION: {
    NEW_PROGRAM: 'admin',
    EXISTING_PROGRAM: 'pp',
  },
};

export const PAGE_HEADING_TITLES = {
  PROGRAM_PROFILE: 'Summary',
  BOM_REVISION: 'BOM Revision List',
  PROGRAM_STATUS_EXPAND: 'Program Status',
  BOM_COMPARE: 'BOM Compare',
  PROGRAM_RISK_EXPAND: 'Program Risk',
  BOM_ANALYTICS: 'BOM Analytics',
  BOM_REPOSITORY: 'BOM Repository',
  SUB_SYSTEM_TAGGING: 'Subsystem Tagging',
};
export const HEADER_CONSTANTS = {
  BOM_REVISION_LIST: 'NPI_PROGRAM_PROFILE_BOM_MGMT',
};

export const DYNAMIC_INPUT_VALIDATION_MESSAGES = {
  maturityRequired:
    '[[FIELD_MODIFIED_DISPLAY_NAME]] is a mandatory field (minimum [[MIN_CHARACTERS]] characters)',
  required:
    '[[FIELD_DISPLAY_NAME]] is a mandatory field (minimum [[MIN_CHARACTERS]] characters).',
  minlength:
    '[[FIELD_DISPLAY_NAME]] should be minimum [[MIN_CHARACTERS]] characters',
  maxlength:
    '[[FIELD_DISPLAY_NAME]] has wrong length! Maximum length: [[MAX_CHARACTERS]]',
  pattern: '[[FIELD_DISPLAY_NAME]] should be numeric',
};

export const COMPARISON_OPERATORS = {
  STRICT_EQUALS: '===',
  EQUALS: '===',
  LESS_THAN: '<',
  GREATER_THAN: '>',
  LESS_THAN_OR_EQUAL: '<=',
  GREATER_THAN_OR_EQUAL: '>=',
  STRICT_NOT_EQUALS: '!==',
  NOT_EQUALS: '!=',
  SUBSTRACT: '-',
  ADD: '+',
};

export const VALIDATION_MESSAGES_REPLACERS = {
  FIELD_MODIFIED_DISPLAY_NAME: 'headerInfo.modifiedHeadername',
  FIELD_DISPLAY_NAME: 'headerInfo.headerName',
  MIN_CHARACTERS: 'headerInfo.validations.minLength',
  MAX_CHARACTERS: 'headerInfo.validations.maxLength',
};
export const STATUS_CONSTANTS = {
  COMPLETED: 'Completed',
  IN_PROGRESS: 'In Progress',
  TBD: 'Not Started',
  PENDING_FOR_APPROVAL: 'Pending For Approval',
  Done: 'DONE',
  'Mark Done': 'MARK DONE',
  APPROVED: 'APPROVED',
  APPROVE: 'APPROVE',
  ROLL_BACK: 'ROLLBACK',
  'Sent For Approval': 'Sent For Approval',
};
export const DATA_TYPES = {
  NUMBER: 'number',
  STRING: 'string',
  ARRAY: 'object',
  OBJECT: 'object',
  UNDEFINED: 'undefined',
  NULL: 'object',
  FUNCTION: 'function',
};
export const MESSAGES = {
  PLACEHOLDER_MESSAGE:
    ' Please Select Base Program and Compare Program revisions.. then  click on compare button to see comparsion',
  DASHBOARD_OVERALL_STATUS: 'No Programs Available',
  BOM_COMPARE_LOADING:
    'Please wait while we are comparing the revisions you have chosen , this may take few minutes to complete',
  BOM_COMPARE_ERROR_MESSAGE:
    "Sorry we are unable to compare your BOM's please contact administrator",
  columnConfigSuccess: 'Grid changes successfully saved',
  columnConfigFailure: 'There is an error while saving configurations',
  resetColumnConfiguration: 'Successfully reset to default grid view',
};
export const ALTERNATE_PARTS_CONSTANTS = {
  RESULT_OBJECT: 'result',
  PART_MATCHES_LIST: 'result.0.pmResultList',
  PART_MATCH_SOURCE: 'source',
  INTERNAL_SOURCE: 'Internal',
  EXTERNAL_SOURCE: 'External',
  MPN_KEY: 'mpn',
  MPN_HEADER_NAME: 'MPN',
  MANUFACTURER_KEY: 'mfr',
  MANUFACTURER_HEADER_NAME: 'MFR',
  COST_KEY: 'mpnCost',
  COST_HEADER_NAME: 'Cost',
  DS_URL_HEADER_NAME: '',
  DS_URL_KEY: 'dsUrl',
  COST_NOT_AVAILABLE: 'Cost Not Available',
  AVERAGE_BENCHMARK_COST_KEY: 'percentile50',
  SEVENTY_PERCENTILE_COST_KEY: 'percentile70',
  BEST_IN_CLASS_BENCHMARK_COST_KEY: 'percentile90',
  AVERAGE_BENCHMARK_COST_HEADER: 'Average Benchmark Cost',
  SEVENTY_PERCENTILE_COST_HEADER: '70th Percentile Cost',
  BEST_IN_CLASS_BENCHMARK_COST_HEADER: 'Best In Class Cost',
};
export const BOM_MANAGEMENT_CONSTANTS = {
  FLAT_BOM: 'FLAT_BOM',
  EDIT_BOM: 'EDIT_BOM',
};
export function GENERATE_DOWNLOAD_ANCHOR(url) {
  const aTag = document.createElement('a');
  aTag.setAttribute('href', url);
  return aTag;
}
export const MATURITY_LEVEL = {
  values: {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
  },
};
