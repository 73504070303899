import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Collaborations } from './shared/collaborations.model';
import { CollaborationsService } from './shared/collaborations.service';
import { COLLABORATION_TYPES } from 'app/modules/base-app/base-app.component';
import { DataStorageService } from 'app/modules/common/data-storage.service';
import { ObjectUtils } from '../../common/utills/ObjectUtils.service';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';

@Component({
  moduleId: module.id,
  selector: 'collaborations',
  templateUrl: 'collaborations.component.html',
  styleUrls: ['./collaborations.component.sass'],
  providers: [CollaborationsService]
})
export class CollaborationsComponent implements OnInit {

  @Input() set data(input) {
    this._input = input;
  }
  get data() {
    return this._input;
  }

  constructor(
    private collaborationsService: CollaborationsService,
    private dataStorageService: DataStorageService
  ) {}
  public COLLABORATION_TYPES = COLLABORATION_TYPES;
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  collaborations: Collaborations[] = [];
  _input;
  views: Array<object> = [];
  create_views: Array<object> = [];
  actions: Array<object> = [];
  selectedType: string;
  existingCollab: Array<object> = [{}];
  showLoading = false;
  type2Label= '';
  type2ActionValue= '';
  isFirstTimeChange = false;
  showPreviewConfirmationPopup = false;
  showCancelForNewCollab= 'NC';
  tenantUsers: Array<object> = [];
  showConfirmationPopup = false;
  changesExists = false;
  prevExpanded;
  defaultFilter= 'All';
  noShow = false;
  showConfirmationDelPopup = false;
  expandCollabId= '';

  fieldFilterObject = {
    fieldType: 'TYPE',
    fieldValue: '',
    queryType: 'FILTER'
  };
  taskStatusFilterObject = {
    fieldType: 'TASK_STATUS',
    fieldValue: '',
    queryType: 'FILTER'
  };
  feIdFilterObject = {
    fieldType: 'FE_ID',
    fieldValue: '',
    queryType: 'FILTER'
  };
  selectorFilterObject = {
    fieldType: 'FE_ID',
    fieldValue: '',
    queryType: 'SELECTOR'
  };
  filterInputArr: Array<any> = [];
  delInput: any;

  @Input() providedType: string;
  public type= COLLABORATION_TYPES.DEFAULT;
  selectedAction= 'Resolve';
  public create_type= 'TYPE_1';
  noData= 'No matching collaborations found.';

  ajaxRequests: any = {};

  ngOnInit() {
    this.showLoading = true;
    this.getCollaborations(true);
    this.fetchTypes();
    this.fetchToUserDetails();
  }
  filterView(mainView, subView, subValue) {
    this.defaultFilter = mainView.text;
    if (this.defaultFilter === 'All') {
      this.filterInputArr = [];
      this.getCollaborations(true);
    } else {
      this.formFilterObject(mainView, subView, subValue);
    }
  }
  formFilterObject(mainView, subView, subValue) {
    this.filterInputArr = [];
    if (this._input.FE_ID !== 'NONE') {
      this.feIdFilterObject.fieldValue = this._input.FE_ID;
      this.selectorFilterObject.fieldValue = this._input.FE_ID;
      this.filterInputArr.push(this.feIdFilterObject);
      this.filterInputArr.push(this.selectorFilterObject);
    }
    this.fieldFilterObject.fieldValue = mainView.value;
    this.filterInputArr.push(this.fieldFilterObject);
    if (subValue.length > 0) {
      this.taskStatusFilterObject.fieldValue = subValue;
      this.filterInputArr.push(this.taskStatusFilterObject);
    }
    this.getFilteredCollaborations(this.filterInputArr);
  }

  deleteCollab(input) {
    this.showConfirmationDelPopup = true;
    this.delInput = input;
  }

  delCancelPopupOkClick() {
    this.showConfirmationDelPopup = false;
    this.clearAjaxCalls('deleteCollab');
    this.ajaxRequests['deleteCollab'] = this.collaborationsService
      .deleteCollab(this.delInput)
      .subscribe((data: any) => {
        if (this.validateServiceData(data) !== 'error') {
          if (this.filterInputArr.length > 0) {
            this.getFilteredCollaborations(this.filterInputArr);
          } else {
            this.getCollaborations(true);
          }
        }
      });
  }

  delCancelPopupCancelClick() {
    this.showConfirmationDelPopup = false;
  }

  closeCollaborationPanel() {
    if (this.changesExists) {
      this.showConfirmationPopup = true;
    } else {
      this.showConfirmationPopup = false;
      this.dataStorageService.collaborationInput = null;
    }
  }
  cancelPopupOkClick() {
    this.showConfirmationPopup = false;
    this.dataStorageService.collaborationInput = null;
  }
  cancelPopupCancelClick() {
    this.showConfirmationPopup = false;
  }

  createNewCollaboration(input) {
    this.create_type = input.value;
    this.showCancelForNewCollab = 'NC';
    // this.filterInputArr = [];
    if (this.noShow) { this.noShow = false; }
  }
  clickActions(input, data) {
    this.selectedAction = input.value;
    this.resolveTask(data, this.selectedAction);
  }
  cancelEmitter(cancelCollboration: any) {
    if (this.existingCollab.length > 0) {
      this.create_type = '';
    }
    this.showCancelForNewCollab = 'NC';
  }
  resolveAction(input) {
    this.resolveTask(input, this.selectedAction);
  }
  resolveTask(input, action) {
    // this.showLoading = true;
    this.clearAjaxCalls('puttask');
    this.ajaxRequests['puttask'] = this.collaborationsService
      .putTask(this._input, input, action)
      .subscribe((data: any) => {
        if (this.validateServiceData(data) !== 'error') {
          // this.getCollaborations(false);
          this.getFilteredOrAllCollab();
        }
      });
  }

  fetchTypes() {
    this.clearAjaxCalls('fetchMetaTypes');
    this.views.push({ text: 'All', value: 'default' });
    this.ajaxRequests[
      'fetchMetaTypes'
    ] = this.collaborationsService.getMetaTypes().subscribe((data: any) => {
      if (this.validateServiceData(data) !== 'error') {
        for (let i = 0; i < data.result.length; i++) {
          const temp = { text: '', value: '', subTypes: [] };
          temp.text = data['result'][i].label;
          temp.value = data['result'][i].collaborationType;
          temp.subTypes =
            data['result'][i].collaborationSubTypes !== undefined
              ? data['result'][i].collaborationSubTypes
              : [];
          this.views.push(temp);
          this.create_views.push(temp);
        }
        this.create_views = [...this.create_views];
        this.fetchActionsForType2(this.views[2]['value']);
      }
    });
  }

  fetchActionsForType2(input) {
    this.clearAjaxCalls('fetchType2Actions');
    this.ajaxRequests[
      'fetchType2Actions'
    ] = this.collaborationsService
      .getType2Actions(input)
      .subscribe((data: any) => {
        if (this.validateServiceData(data) !== 'error') {
          for (let i = 0; i < data.result.length; i++) {
            this.type2Label = data['result'][i].actionLable;
            this.type2ActionValue = data['result'][i].actionValue;
          }
          this.fetchActionsForType3(this.views[3]['value']);
        }
      });
  }

  fetchActionsForType3(input) {
    this.clearAjaxCalls('fetchType2Actions');
    this.ajaxRequests[
      'fetchType2Actions'
    ] = this.collaborationsService
      .getType2Actions(input)
      .subscribe((data: any) => {
        if (this.validateServiceData(data) !== 'error') {
          for (let i = 0; i < data.result.length; i++) {
            const temp = { text: '', value: '' };
            temp.text = data['result'][i].actionLable;
            temp.value = data['result'][i].actionValue;
            this.actions.push(temp);
          }
        }
      });
  }

  changeType(event) {
    this.type = event.value;
  }
  changeCreateType(event) {
    if (!this.isFirstTimeChange) {
      this.isFirstTimeChange = true;
      return;
    }

    this.create_type = event.value;
  }
  changeAction(event) {
    this.selectedAction = event.value;
  }

  getCollaborations(input: boolean) {
    this.clearAjaxCalls('getFeCollab');
    this.ajaxRequests[
      'getFeCollab'
    ] = this.collaborationsService
      .getExistingCollab(this._input)
      .subscribe((data: any) => {
        if (this.validateServiceData(data) !== 'error') {
          this.appendExpandCollapse(data.result);
          this.existingCollab = data.result;
          setTimeout(() => {
            if (this.existingCollab.length === 0) {
              this.create_type = this.providedType;
              this.noShow = true;
            } else {
              this.create_type = '';
            }
            this.showLoading = false;
          }, 500);
        } else {
          this.existingCollab = [];
        }
      });
  }
  appendExpandCollapse(input) {
    // console.log(this.expandCollabId)
    if (this.expandCollabId.length > 0) {
      for (let i = 0; i < input.length; i++) {
        if (
          input[i].collaborationSummary.collaborationId === this.expandCollabId
        ) {
          input[i].expanded = true;
          this.prevExpanded = input[i];
        }
      }
    }
  }

  getFilteredCollaborations(input) {
    this.clearAjaxCalls('getFilteredCollab');
    this.ajaxRequests[
      'getFilteredCollab'
    ] = this.collaborationsService
      .getFilteredCollab(input)
      .subscribe((data: any) => {
        if (this.validateServiceData(data) !== 'error') {
          this.appendExpandCollapse(data.result);
          this.existingCollab = data.result;
          setTimeout(() => {
            if (this.existingCollab.length === 0) {
              // this.create_type = this.providedType;
              this.noShow = true;
            } else {
              this.create_type = '';
            }
            this.showLoading = false;
          }, 500);
        } else {
          this.existingCollab = [];
        }
      });
  }
  clearAjaxCalls(key?: string) {
    if (typeof key === 'string') {
      if (this.ajaxRequests[key] instanceof Object) {
        this.ajaxRequests[key].unsubscribe();
        delete this.ajaxRequests[key];
      }
      return;
    }
    for (const req in this.ajaxRequests) {
      if (this.ajaxRequests[req] instanceof Object) {
        this.ajaxRequests[req].unsubscribe();
        delete this.ajaxRequests[req];
      }
    }
  }
  validateServiceData(data) {
    if (data instanceof Object) {
      if (data['responseStatus'] instanceof Object) {
        if (data['result'] instanceof Array || data['result'] === true) {
          return data;
        } else {
          return 'error';
        }
      }
    }
  }
  getFilteredOrAllCollab() {
    if (this.filterInputArr.length > 0) {
      this.getFilteredCollaborations(this.filterInputArr);
    } else {
      this.getCollaborations(true);
    }
  }

  onCollabPost(input: any) {
    // console.log(input)
    // if(this.expandCollabId.length === 0){
    // this.expandCollabId = input.result;
    // }
    // console.log(this.expandCollabId)
    this.create_type = '';
    this.showCancelForNewCollab = 'NC';
    this.changesExists = false;
    // console.log(this.filterInputArr)
    this.getFilteredOrAllCollab();
    // this.getCollaborations(false);
  }

  onChangeDetected(input: any) {
    this.changesExists = input;
  }

  fetchToUserDetails() {
    this.clearAjaxCalls('getTenantUsers');
    this.ajaxRequests[
      'getTenantUsers'
    ] = this.collaborationsService.getTenantUsers().subscribe((data: any) => {
      if (this.validateServiceData(data) !== 'error') {
        this.tenantUsers = data.result;
      }
    });
  }
  onCollabExpandOrCollapse(input: any) {
    // console.log(input.collaborationSummary.collaborationId)
    if (input.expanded) {
      this.expandCollabId = input.collaborationSummary.collaborationId;
    }
    const index = this.existingCollab.indexOf(input);
    if (this.prevExpanded instanceof Object) {
      if (
        this.existingCollab.indexOf(this.prevExpanded) > -1 &&
        index !== this.existingCollab.indexOf(this.prevExpanded)
      ) {
        this.prevExpanded.expanded = false;
        this.existingCollab = ObjectUtils.copy(this.existingCollab);
      }
    }
    this.prevExpanded = this.existingCollab[index];
  }
  onDeleteCollaboration(input: any) {
    this.deleteCollab(input.collaborationSummary.collaborationId);
  }
}
