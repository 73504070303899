import { Component, OnInit } from '@angular/core';
import { Toast, BodyOutputType, ToasterService } from 'angular2-toaster';
import { PartSitesService } from '../../part-sites.service';
import {
  cmOdmConfiguration,
  columnsMap,
  datePickerConfig,
  defaultParameterMap,
  manufacturerConfiguration,
  mpnConfiguration,
  objectTypeDisableMap,
  objectTypeResetMap,
  primaryFlagConfiguration,
  sitesConfiguration,
  sourceIdConfiguration,
  supplierConfiguration
} from './add-part-site.constants';
import { AddPartSiteService } from './add-part-site.service';

export interface SiteInfoPayLoad {
  ldCmOdm: string
  ldManufacturer: string
  ldSupplier: string
  mpn: string
  isPrimary: boolean
  siteNames: string[]
  sourceId: number
  validityDate: string
}

export interface PrimaryKeyMap {
  ldCmOdm: string
  ldManufacturer: string
  ldSupplier: string
  mpn: string
  sourceId: number,
  keyIndex: number
}

export interface PartMetaInfo {
  sites: any[]
  mandatoryFilledCheck: boolean
}

export interface DialogResult {
  modified: boolean
  invalid: boolean
  payload: any
}
@Component({
  selector: 'cprt-add-part-site',
  templateUrl: './add-part-site.component.html',
  styleUrls: ['./add-part-site.component.sass'],
  providers: [AddPartSiteService]
})
export class AddPartSiteComponent implements OnInit {
  partMetaInfo = {} as PartMetaInfo
  showPreloader = true
  showLoader = false
  loaderConfig = {
    backdropBackgroundColour: 'rgba(255,255,255,0.3)',
    fullScreenBackdrop: 'true',
    primaryColour: '#1C4E84',
    secondaryColour: '#1C4E84',
    tertiaryColour: '#1C4E84'
  }
  primaryKeySiteMap: PrimaryKeyMap[] = []
  constructor(
    private addPartSiteService: AddPartSiteService,
    private toasterService: ToasterService,
    private partSiteService: PartSitesService,
  ) { }

  ngOnInit() {
    this.addPartSiteService.getTenantInfo().subscribe((tenantResponse) => {
      if (
        tenantResponse &&
        tenantResponse.result instanceof Array &&
        tenantResponse.result.length > 0
      ) {
        sourceIdConfiguration.data =
          tenantResponse.result instanceof Array
            ? this.convertToLocalFormat(tenantResponse.result)
            : []
        this.initModule()
        this.initSiteList()
        this.mandatoryFilledCheck()
        setTimeout(() => {
          this.showPreloader = false
        }, 100)
      }
    })

    this.partSiteService.getLoaderInfo().subscribe((loaderInfo) => {
      if (loaderInfo !== undefined) {
        console.log(loaderInfo)
        this.showLoader = loaderInfo
      }
    })
  }

  initModule() {
    this.partMetaInfo.mandatoryFilledCheck = false
    this.partMetaInfo.sites = []
  }

  initSiteList() {
    const site = {
      sourceIdObject: {
        name: 'sourceId',
        config: JSON.parse(JSON.stringify(sourceIdConfiguration)),
        resetValues: this.generateRandomKey(),
        value: null
      },
      mpnObject: {
        name: 'mpn',
        config: JSON.parse(JSON.stringify(mpnConfiguration)),
        resetValues: this.generateRandomKey(),
        value: null
      },
      cmOdmObject: {
        name: 'ldCmOdm',
        config: JSON.parse(JSON.stringify(cmOdmConfiguration)),
        resetValues: this.generateRandomKey(),
        value: null
      },
      supplierObject: {
        name: 'ldSupplier',
        config: JSON.parse(JSON.stringify(supplierConfiguration)),
        resetValues: this.generateRandomKey(),
        value: null
      },
      manufacturerObject: {
        name: 'ldManufacturer',
        config: JSON.parse(JSON.stringify(manufacturerConfiguration)),
        resetValues: this.generateRandomKey(),
        value: null
      },
      siteObject: {
        name: 'siteNames',
        config: JSON.parse(JSON.stringify(sitesConfiguration)),
        resetValues: this.generateRandomKey(),
        value: null
      },
      primaryFlagObject: {
        name: 'isPrimary',
        config: JSON.parse(JSON.stringify(primaryFlagConfiguration)),
        resetValues: this.generateRandomKey(),
        value: null
      },
      datePickerObject: {
        name: 'validityDate',
        config: datePickerConfig,
        value: null
      },
      properties: this.initSiteInfoPayload({} as SiteInfoPayLoad),
      primaryKeyCheck: true
    }
    this.partMetaInfo.sites.push(site)
  }

  initSiteInfoPayload(site) {
    site.ldCmOdm = null
    site.ldManufacturer = null
    site.ldSupplier = null
    site.mpn = null
    site.isPrimary = null
    site.siteNames = []
    site.sourceId = null
    site.validityDate = null
    return site
  }

  addSiteInfo(rowIndex, event) {
    event.stopPropagation()
    this.initSiteList()
    this.mandatoryFilledCheck()
    this.partSiteService.setMetaInfo({ name: 'partToSite' })
  }

  deleteSiteInfo(rowIndex, selectedSiteInfo, event) {
    event.stopPropagation()
    this.partMetaInfo.sites.splice(rowIndex, 1)
    this.mandatoryFilledCheck()
    this.primaryKeySiteMap = this.primaryKeySiteMap.filter(prop => prop.keyIndex !== rowIndex)
  }

  onClearAllSiteInfo() {
    this.partMetaInfo.sites = []
    this.initSiteList();
    this.mandatoryFilledCheck()
    this.primaryKeySiteMap = []
  }

  convertToLocalFormat(data) {
    const localArray = []
    data.forEach((val) => {
      localArray.push({
        val,
        displayName: val['tenantName'],
        customLabel: val['tenantId'],
      })
    })

    return localArray
  }

  generateRandomKey() {
    const crypto = window.crypto
    const array = new Uint32Array(1)
    return crypto.getRandomValues(array)[0]
  }

  onSiteInfoPropSelect(params, siteInfoRow?, siteInfoIndex?) {
    if (siteInfoRow && siteInfoIndex !== undefined) {
      const selectedValues = this.getSelectedValuesForList(params)
      siteInfoRow.properties[params.type] = selectedValues
      this.setDefaultParameter(params.type, siteInfoRow, selectedValues)
      this.disableOtherList(params, siteInfoRow, selectedValues === null)
      this.resetOtherList(params.type, siteInfoRow)
      this.checkPrimaryKeyValidation(siteInfoRow, siteInfoIndex)
      this.mandatoryFilledCheck()
    }
  }

  checkPrimaryKeyValidation(site, keyIndex) {
    if (
      site.ldCmOdm !== null &&
      site.ldManufacturer !== null &&
      site.ldSupplier !== null &&
      site.mpn !== null &&
      site.sourceId !== null && site.sourceId !== 0
    ) {
      if (!this.primaryKeySiteMap.length) {
        this.setPrimaryKeyMap(site, keyIndex)
        site.primaryKeyCheck = true
      } else {
        this.primaryKeySiteMap.forEach(primaryKey => {
          if (
            primaryKey.ldCmOdm === site.properties.ldCmOdm &&
            primaryKey.ldManufacturer === site.properties.ldManufacturer &&
            primaryKey.ldSupplier === site.properties.ldSupplier &&
            primaryKey.mpn === site.properties.mpn &&
            primaryKey.sourceId === site.properties.sourceId
          ) {
            if (primaryKey.keyIndex !== keyIndex) {
              site.primaryKeyCheck = false
              return
            }
          } else {
            this.setPrimaryKeyMap(site, keyIndex)
            site.primaryKeyCheck = true
          }
        })
      }
    }
  }

  setPrimaryKeyMap(site, siteInfoIndex) {
    const keyMap = {
      ldCmOdm: null,
      ldManufacturer: null,
      ldSupplier: null,
      mpn: null,
      sourceId: null
    } as PrimaryKeyMap
    Object.keys(keyMap).forEach(prop => {
      keyMap[prop] = site.properties[prop]
    })
    if (
      keyMap.ldCmOdm !== null &&
      keyMap.ldManufacturer !== null &&
      keyMap.ldSupplier !== null &&
      keyMap.mpn !== null &&
      keyMap.sourceId !== null
    ) {
      keyMap['keyIndex'] = siteInfoIndex
      this.primaryKeySiteMap.push(JSON.parse(JSON.stringify(keyMap)))
    }
  }

  onDateSelect(siteInfoRow) {
    siteInfoRow.properties[siteInfoRow.datePickerObject.name] = siteInfoRow.datePickerObject.value
    this.mandatoryFilledCheck()
  }

  getSelectedValuesForList(params) {
    let selectedValues = null
    switch (params.configuration.type) {
      case 'static':
        selectedValues
          = params.actionType === 'remove'
            ? null
            : params.selectedItem.val
        break;
      case 'dynamic':
        selectedValues
          = params.actionType === 'remove'
            ? null
            : params.selectedItem.val[params.configuration.val] || null
        break;
      case 'newautosearch':
        if (params.configuration.filterConfig.multiSelect) {
          selectedValues = params.selectedItems || []
        } else {
          selectedValues = params.selectedItem
            ? params.selectedItem.val || null
            : null
        }
        break;
    }
    if (selectedValues !== null && typeof selectedValues === 'string') {
      selectedValues = selectedValues.trim()
    }
    return selectedValues
  }

  setDefaultParameter(paramsType, siteInfoRow, selectedValues) {
    const objectType = objectTypeResetMap[paramsType] || []
    const parameterObj = {}
    const parameterList = defaultParameterMap[paramsType] || []
    parameterList.forEach(param => {
      const payloadParam = columnsMap[param] || param

      parameterObj[payloadParam]
        = param === paramsType
          ? selectedValues
          : siteInfoRow.properties[param]
    })
    objectType.forEach(objType => {
      if (siteInfoRow[objType].config.defaultParameter === null) {
        siteInfoRow[objType].config.defaultParameter = JSON.parse(JSON.stringify(parameterObj))
      } else {
        Object.assign(siteInfoRow[objType].config.defaultParameter, parameterObj)
      }
    })
  }

  disableOtherList(params, siteInfoRow, disableStatus) {
    const actionType = params.actionType
    const paramsType = params.type
    const objectType
      = (disableStatus
        ? objectTypeDisableMap[actionType].disable[paramsType]
        : objectTypeDisableMap[actionType].enable[paramsType]) || []
    objectType.forEach(objType => {
      siteInfoRow[objType].config.isDisable = disableStatus
    })
  }

  resetOtherList(paramsType, siteInfoRow) {
    const objectType = objectTypeResetMap[paramsType] || []
    objectType.forEach(objType => {
      siteInfoRow[objType].resetValues = this.generateRandomKey()
    })
  }

  mandatoryFilledCheck() {
    let validSiteCount = 0
    let invalidSiteInfo = false
    const addedSites = []
    this.partMetaInfo.sites.forEach((site) => {
      const props = site.properties
      if (
        props.ldCmOdm !== null &&
        props.ldManufacturer !== null &&
        props.ldSupplier !== null &&
        props.mpn !== null &&
        props.isPrimary !== null &&
        props.siteNames !== [] && props.siteNames !== null &&
        props.sourceId !== null &&
        props.validityDate !== null &&
        site.primaryKeyCheck) {
        addedSites.push(props)
        validSiteCount++
      } else {
        invalidSiteInfo = true
        return
      }
    })
    if (invalidSiteInfo) {
      this.partMetaInfo.mandatoryFilledCheck = false
    } else if (this.partMetaInfo.sites.length === validSiteCount) {
      this.partMetaInfo.mandatoryFilledCheck = true
    } else {
      this.partMetaInfo.mandatoryFilledCheck = false
    }
    this.setDialogResult(addedSites)
  }

  setDialogResult(addedSites) {
    const dialogResult = {} as DialogResult
    dialogResult.invalid = !this.partMetaInfo.mandatoryFilledCheck
    dialogResult.payload = addedSites
    dialogResult.modified = addedSites.length > 0
    this.partSiteService.setMetaInfo(dialogResult)
  }

  message(type: string, message: string) {
    const toast: Toast = {
      type,
      body: '<div vertical-align=middle>' + message + '</div>',
      bodyOutputType: BodyOutputType.TrustedHtml,
    }
    this.toasterService.pop(toast)
  }
}
