import { Injectable } from '@angular/core';

interface Scripts {
  name: string;
  src: string;
}

export const SCRIPT_STORE: Scripts[] = [
  { name: 'walkmejs_prod', src: 'assets/js/walkme_prod.js' },
  { name: 'walkmejs_demo', src: 'assets/js/walkme_demo.js' },
  { name: 'walkmejs_stage', src: 'assets/js/walkme_stage.js' },
  { name: 'walkmejs_uat', src: 'assets/js/walkme_uat.js' }
];

declare var document: any;

@Injectable()
export class DynamicScriptLoaderService {
  private scripts: any = {
    walkmejs_prod: undefined,
    walkmejs_stage: undefined,
    walkmejs_uat: undefined,
    walkmejs_demo: undefined
  };

  constructor() {
    SCRIPT_STORE.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach(script => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded) {
        // load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {
          // IE
          script.onreadystatechange = () => {
            if (
              script.readyState === 'loaded' ||
              script.readyState === 'complete'
            ) {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {
          // Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = (error: any) =>
          resolve({ script: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
    });
  }
}
