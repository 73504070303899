import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { EventMetaInfoService } from 'app/modules/curator/industry-news/industry-news-events/components/event-management/event-meta-info.service';
import { NewsArticlesService } from './news-articles.service';
import { DialogBoxService, DialogSize, DialogType, LdDialog, DialogButtonName } from 'app/modules/leva-ui-library/components/dialog-box/dialog-box.service'
import { CreateEventComponent } from 'app/modules/curator/industry-news/industry-news-events/components/create-event/create-event.component'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface NewsArticle {
  documentId?: string
  resourceId?: string
  articleId: string
  provider: string
  url: string
  publishedDate: string
  articleTitle: string
  associated?: boolean
}
@Component({
  selector: 'cprt-news-articles',
  templateUrl: './news-articles.component.html',
  styleUrls: ['./news-articles.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewsArticlesComponent),
      multi: true
    }
  ]
})
export class NewsArticlesComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Output() onSubmitLinkNews: EventEmitter<any[]> = new EventEmitter<any[]>()
  @Input('associatedNewsList') associatedNewsList = []
  @Input('isExpanded')
  set isExpanded(val) {
    this._isExpanded = val
    this.searchNews = ''
    this.newsArticles = Object.assign([], this.eventMetaInfoService.getNewsList())
  }
  get isExpanded() {
    return this._isExpanded
  }
  _isExpanded: boolean
  newsArticles = []
  showLoader = true;
  searchNews = ''
  loaderConfig = {
    backdropBackgroundColour: 'rgba(255,255,255,0.3)',
    fullScreenBackdrop: 'true',
    primaryColour: '#1C4E84',
    secondaryColour: '#1C4E84',
    tertiaryColour: '#1C4E84'
  }
  taggedNews: NewsArticle[] = []
  disableSubmit = true
  eventDialog: LdDialog = {
    title: 'Event',
    id: 'event-creation',
    template: true,
    customSize: {
      height: 0,
      width: 0
    },
    type: DialogType.CONFIRMATION,
    beforeClose: (params) => {
      if (params && params.primary) {
        if (this.createEventComponent.isValidEventForm()) {
          return true
        }
        this.createEventComponent.newEventForm.markAllAsTouched()
        return false
      }
      this.showEventDetails = false
      return false
    },
    discardConfiguration: {
      title: 'Changes',
      size: DialogSize.MEDIUM,
      draggable: true,
      modal: true,
      id: '123',
      message:
        'You have unsaved changes. Would you like to discard the changes?',
    },
    buttons: [
      {
        title: DialogButtonName.CANCEL,
        id: 'cancel',
        primary: false,
      },
      {
        title: 'Create',
        id: 'create',
        primary: true,
        callback: () => {
          this.createEventComponent.onClickSubmit()
          return false
        }
      }
    ]
  }
  showEventDetails = false

  @ViewChild(CreateEventComponent, { static: false })
  createEventComponent: CreateEventComponent

  propagateChange = (_: any) => { }
  constructor(
    private eventMetaInfoService: EventMetaInfoService,
    private dialogBoxService: DialogBoxService,
    private newsArticleService: NewsArticlesService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.showLoader = true;
    this.taggedNews = JSON.parse(JSON.stringify((this.associatedNewsList)))
    this.taggedNews.forEach(news => {
      news['associated'] = true
    })
    this.processNewsList()
    setTimeout(() => { this.showLoader = false }, 50)
  }

  processNewsList() {
    const assArticleIds = this.taggedNews.map(news => news.articleId)
    this.newsArticles.forEach(article => {
      article['associated'] = assArticleIds.indexOf(article.documentId) === -1 ? false : true
    })
  }

  toggleLink(article, rowIndex) {
    if (!this.isExpanded) {
      this.taggedNews.splice(rowIndex, 1)
      this.onSubmitLinkNews.emit(this.taggedNews)
      this.propagateChange(this.taggedNews)
    }
    article.associated = !article.associated

    if (article.associated) {
      const newsArticle = {} as NewsArticle
      newsArticle.articleId = article.documentId
      newsArticle.documentId = article.documentId
      newsArticle.resourceId = article.documentId
      newsArticle.articleTitle = article.title
      newsArticle.publishedDate = article.published
      newsArticle.provider = article.provider
      newsArticle.associated = article.associated
      newsArticle.url = article.newsUrl
      this.taggedNews.push(newsArticle)
    }
    else {
      this.taggedNews = this.taggedNews.filter(news => news.articleId !== article.documentId)
    }
    this.toggleConfirmButton()
  }

  onSearch() {
    this.showLoader = true;
    if (this.searchNews && this.searchNews.length > 0) {
      const sortArray = [{ colId: 'published', order: 'desc' }]
      const articleQueryParams = {
        eventId: this.eventMetaInfoService.getEventId(),
        q: this.searchNews,
        search: true,
        sort: encodeURIComponent(JSON.stringify(sortArray)),
      };

      this.newsArticleService.getNewsArticles(articleQueryParams).subscribe((articlesResponse) => {
        if (articlesResponse && articlesResponse.articles) {
          this.newsArticles = Object.assign([], articlesResponse.articles)
          this.processNewsList()
        } else {
          this.newsArticles = []
        }
        setTimeout(() => { this.showLoader = false }, 100)
      })
    } else {
      this.newsArticles = Object.assign([], this.eventMetaInfoService.getNewsList())
      this.processNewsList()
      setTimeout(() => { this.showLoader = false }, 100)
    }
  }

  onConfirmLinkNews() {
    this.taggedNews.sort((a: any,b: any) => {
      return new Date(b.publishedDate).valueOf() - new Date(a.publishedDate).valueOf();
    });
    this.onSubmitLinkNews.emit(this.taggedNews)
    this.propagateChange(this.taggedNews)
  }

  toggleConfirmButton() {
    const defaultLinkedArticleIds = this.associatedNewsList.map(news => news.articleId)
    const selectedToBeLinkedIds = this.taggedNews.map(news => news.articleId)
    this.disableSubmit
      = (JSON.stringify(defaultLinkedArticleIds) === JSON.stringify(selectedToBeLinkedIds))
        ? true : false
  }

  openDialog(id: string) {
    this.showEventDetails = true
    this.eventMetaInfoService.setEventId(null)
    this.dialogBoxService.open(id)
  }

  /*  ControlValueAccessorFunctionImplementation */
  writeValue(value: any) {
    return value
  }

  registerOnChange(fn) {
    this.propagateChange = fn
  }

  registerOnTouched() { }
}
