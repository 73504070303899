import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { DecimalPipe } from '@angular/common';
import { RmOpprisksService } from 'app/modules/mi/rawmaterialnew/rmOpprisks/shared/rmOpprisks.service';
import { SCROLL_BAR_CONFIGURATION } from 'app/modules/common/scroll-config.service';

@Component({
  selector: 'child-cell',
  template: `
    <div #container tabindex="0" *ngIf="isRowGroup">
      <div *ngFor="let item of val; let i = index">
        <em
          *ngIf="class !== 'none'"
          class="{{ class }}"
          style="cursor: auto;"
          aria-hidden="true"
        ></em> 
        <span *ngIf="class !== 'none'" class="{{ type }}">
          {{ item.formattedVal }}</span
        >
        <span *ngIf="class === 'none'">
          {{ item.formattedVal }}
        </span>
      </div>
    </div>
    <div
      #container
      tabindex="0"
      class="position-relative status-tooltip-value"
      *ngIf="!isRowGroup"
    >
      <em
        *ngIf="class !== 'none'"
        class="{{ class }}"
        style="cursor: auto;"
        aria-hidden="true"
      ></em> 
      <span *ngIf="class !== 'none'" class="{{ type }}"> {{ val }}</span>
      <span *ngIf="class === 'none'">
        {{ val }}
      </span>
      <div class="custom-tooltip right" *ngIf="showTooltip">
        <ul class="tip-content" [perfectScrollbar]="scrollBarConfiguration">
          <li class="supplier-details">
            <p>
              RM Change % : <br />
              {{ params?.data?.toolTipInfo?.formulaExpr }}
              <br />
              =
              {{ params?.data?.toolTipInfo?.formulaExprValue }}
              <br />
              =
              {{ params?.data?.toolTipInfo?.formulaValue }}
            </p>
          </li>
        </ul>
      </div>
    </div>
  `,
  styleUrls: ['grid.tooltip.sass']
})
export class RowFormatter {
  scrollBarConfiguration = SCROLL_BAR_CONFIGURATION;
  public params: any;
  val: any;
  message = '';
  isRowGroup = false;
  class = 'none';
  type = '';
  showTooltip = false;
  constructor(public rmOppriskService: RmOpprisksService) {}
  agInit(params: any): void {
    this.params = params;
    this.refineTableHeaders(this.params.colDef);
    this.val = params.value;
    if (this.val instanceof Array) {
      this.isRowGroup = true;
    } else {
      this.isRowGroup = false;
    }
    if (this.params.hasOwnProperty('showToolTip')) {
      if (!!this.params.data['toolTipInfo']) {
        this.showTooltip = true;
      } else {
        this.showTooltip = false;
      }
    } else {
      this.showTooltip = false;
    }
  }

  refineTableHeaders(i) {
    if (i['field'] === null) {
      delete i['field'];
    } else {
      delete i['children'];
    }

    if (this.params.value instanceof Array) {
      const k = [];
      this.params.value.forEach((val, index) => {
        const obj = {};
        obj['val'] = val;
        obj['formattedVal'] = val;
        if (this.params.colDef['applyColorCode']) {
          if (Number(obj['val']) < 0) {
            this.type = 'text-success';
            this.class = 'fa fa-long-arrow-down text-success';
          } else if (Number(obj['val']) > 0) {
            this.type = 'text-danger';
            this.class = 'fa fa-long-arrow-up text-danger';
          } else {
            this.type = 'none';
          }
        }
        if (typeof val === 'number') {
          obj['formattedVal'] = Math.abs(obj['formattedVal']);
        }
        if (typeof val === 'number') {
          const num = new DecimalPipe('en-US');
          obj['formattedVal'] = num.transform(obj['formattedVal']);
        }
        if (typeof obj['formattedVal'] === 'string') {
          if (this.params.colDef['displayFormat']) {
            if (
              this.params.colDef['displayFormat'] === '$' &&
              !obj['formattedVal'].includes('$')
            ) {
              obj['formattedVal'] = this.params.colDef['displayFormat'].concat(
                ' ' + obj['formattedVal']
              );
            } else if (
              this.params.colDef['displayFormat'] === '%' &&
              !obj['formattedVal'].includes('%')
            ) {
              obj['formattedVal'] =
                obj['formattedVal'] + ' ' + this.params.colDef['displayFormat'];
            }
          }
        }
        k.push(obj);
      });
      this.params.value = [];
      this.params.value = k;
    } else {
      if (!!this.params.value) {
        if (this.params.colDef['applyColorCode']) {
          if (Number(this.params.value) < 0) {
            this.type = 'text-success';
            this.class = 'fa fa-long-arrow-down text-success';
          } else if (Number(this.params.value) > 0) {
            this.type = 'text-danger';
            this.class = 'fa fa-long-arrow-up text-danger';
          } else {
            this.type = 'none';
          }
        }
        if (typeof this.params.value === 'string') {
          this.params.value = +this.params.value;
        }
        if (typeof this.params.value === 'number') {
          this.params.value = Math.abs(this.params.value);
        }
        if (typeof this.params.value === 'number' && this.params.value !== 0) {
          const num = new DecimalPipe('en-US');
          this.params.value = num.transform(
            this.params.value,
            `1.0-${this.params.colDef['displayDecimals']}`
          );
        }
        if (this.params.colDef['displayFormat']) {
          if (this.params.colDef['displayFormat'] === '$') {
            this.params.value = this.params.colDef['displayFormat'].concat(
              ' ' + this.params.value
            );
          } else if (this.params.colDef['displayFormat'] === '%') {
            this.params.value =
              this.params.value + ' ' + this.params.colDef['displayFormat'];
          }
        }

        return this.params.value;
      }
    }
  }
}
