import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

import { BotQuestion } from './bot-question.model';

@Injectable()
export class BotQuestionService {

  constructor(private http: HttpClient) { }

}
