import { Injectable } from '@angular/core';
import { BaseService } from '../../../common/base.service';
import { ConfigService } from '../../../common/configservice';
import { ApiServicePathsService } from '../../../common/apiservicepaths.service';
import { Observable } from 'rxjs';
import { LoadingBarService } from '@ngx-loading-bar/core';
import {CUSTOMER_TOKEN_INFO} from '../../../shared/services/shared-consts';
@Injectable()
export class FileDownloadService {
  public getDownloadClicked = false;
  public postDownloadClicked = false;
  private zuulPath: string;
  constructor(
    private baseService: BaseService,
    private configService: ConfigService,
    private apiServicePathsService: ApiServicePathsService,
    private loadingBarService: LoadingBarService
  ) {
    this.zuulPath =
      this.apiServicePathsService.getApiServicePath('ZuulApiPath');
  }

  public async formDownloadUrl(payLoadJson, refreshToken?) {
    this.loadingBarService.start();
    await this.configService.updateAccessTokenInfo();
    payLoadJson.formParams =
      payLoadJson === undefined
        ? {}
        : payLoadJson.formParams === undefined
        ? {}
        : payLoadJson.formParams;
    payLoadJson.headerParams =
      payLoadJson === undefined
        ? {}
        : payLoadJson.headerParams === undefined
        ? {}
        : payLoadJson.headerParams;
    const params =
      Object.assign(payLoadJson.formParams, payLoadJson.headerParams) || {};
    params.userEmail = this.baseService.getUserInformation().email;
    params.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem(CUSTOMER_TOKEN_INFO)).access_token
    }`;
    params.Accept = '*/*';
    const servicePath =
      this.apiServicePathsService.getApiServicePath(payLoadJson.targetedType) +
      payLoadJson.servicePath;
    let url = '';
    if (servicePath.search('\\?') > -1) {
      url = this.zuulPath + servicePath + '&';
    } else {
      url = this.zuulPath + servicePath + '?';
    }
    for (const key of Object.keys(params)) {
      if (params[key] === null || params[key] === undefined) {
        params[key] = '';
      }
      url = url.concat(key + '=' + params[key] + '&');
    }
    this.loadingBarService.stop();
    return url;
  }
  public async processPostExcel(postExcelPayload) {
    if (postExcelPayload) {
      this.loadingBarService.start();
      await this.configService.updateAccessTokenInfo();
      const data = postExcelPayload;
      data.formParams =
        data == undefined
          ? {}
          : data.formParams == undefined
          ? {}
          : data.formParams;
      data.headerParams =
        data == undefined
          ? {}
          : data.headerParams == undefined
          ? {}
          : data.headerParams;
      const params = Object.assign(data.formParams, data.headerParams) || {};
      const servicePath =
        this.apiServicePathsService.getApiServicePath(data.targetedType) +
        data.servicePath;
      let url = '';
      if (servicePath.search('\\?') > -1) {
        url = this.zuulPath + servicePath + '&';
      } else {
        url = this.zuulPath + servicePath + '?';
      }
      for (const key of Object.keys(params)) {
        if (key !== 'payload') {
          if (params[key] === null || params[key] === undefined) {
            params[key] = '';
          }
          url = url.concat(key + '=' + params[key] + '&');
        }
      }
      const form = document.createElement('form');
      const formParams = {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem(CUSTOMER_TOKEN_INFO)).access_token
        }`,
        userEmail: this.baseService.getUserInformation().email,
        Accept: '*/*',
        payload: JSON.stringify(data.payload),
      };
      if (data.formParams instanceof Object) {
        for (const key of Object.keys(data.formParams)) {
          formParams[key] = data.formParams[key];
        }
      }
      form.setAttribute('method', 'post');
      form.setAttribute('action', url);
      for (const key of Object.keys(formParams)) {
        const keyData = document.createElement('input');
        keyData.setAttribute('type', 'hidden');
        keyData.setAttribute('name', key);
        keyData.value = formParams[key];
        form.appendChild(keyData);
      }
      document.body.appendChild(form);
      form.submit();
      this.loadingBarService.stop();
      return 'SUCCESS';
    }
  }
}
